import React from "react";
import { useSelector } from "react-redux";
import { selectAdtAssetsDetails } from "../store/adt/adtSlice";

const useADTPdpTitle = (): string => {
  const assetDetailsData = useSelector(selectAdtAssetsDetails);

  return assetDetailsData?.assetName ?? "";
};

export default useADTPdpTitle;
