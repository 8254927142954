import React, { useEffect } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import CustomText from "../../styled-UI/CustomText";
import { useDispatch, useSelector } from "react-redux";
import { submitSimpleSR } from "../../../store/serviceRequests/serviceRequestSagas";
import { selectSubmitSRStatus } from "../../../store/serviceRequests/serviceRequestSlice";
import { Door } from "../../../store/user/userInterfaces";
import Loader from "../../styled-UI/Loader";

interface Props {
  selectedDoor: Door;
  serviceRequestIdentifier: string | null;
  setHasSubmittedRequest: (val: boolean) => void;
}

const ServiceRequestPopupSR11 = ({
  selectedDoor,
  serviceRequestIdentifier,
  setHasSubmittedRequest,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const submitStatus = useSelector(selectSubmitSRStatus);
  const { translateLabel } = useTranslation();

  useEffect(() => {
    if (submitStatus === "SUCCESS") {
      setHasSubmittedRequest(true);
    }
  }, [submitStatus]);

  //Get service request id at opening of popup
  useEffect(() => {
    if (selectedDoor && serviceRequestIdentifier)
      dispatch(
        submitSimpleSR({
          doorId: selectedDoor.orgentityId,
          serviceRequestIdentifier: serviceRequestIdentifier,
          serviceRequestTypeIdentifier: "SR11_RECEIVE_PATTERNS",
        })
      );
  }, [selectedDoor, serviceRequestIdentifier]);

  return (
    <Container>
      {submitStatus === "ERROR" && (
        <CustomText
          as="p"
          fontSizePx={18}
          lineHeightPx={22}
          marginTopPx={16}
          color="red"
          isFlex={false}
          textAlign="center"
        >
          {translateLabel("ERROR_POPUP_DEFAULT_MESSAGE")}
        </CustomText>
      )}

      {submitStatus === "LOADING" && <Loader sizePx={48} />}

      {submitStatus === "SUCCESS" && (
        <CustomText
          as="p"
          fontSizePx={18}
          lineHeightPx={22}
          marginTopPx={16}
          color="green"
          isFlex={false}
          textAlign="center"
        >
          {translateLabel("SERVICE_REQUEST_POPUP_REQUEST_SENT")}
        </CustomText>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 14rem;
`;

export default ServiceRequestPopupSR11;
