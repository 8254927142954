import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import {
  LXConfiguration,
  MultipleRequestStatus,
  RequestStatus,
  SetMultipleRequestStatus,
} from "./../../interfaces/mainInterfaces";
import {
  AdtCategories,
  AdtState,
  AssetPlatformSocialMedia,
  AssetPlatformStatus,
  AssetData,
  AssetsPreviewStatus,
  AssetsAccountStatus,
  AssetsList,
  AssetsPdpStatus,
  AssetsUpdateStatus,
  PLPStatus,
  AssetFilterAny,
  TemplateCacheFile,
  TemplateSavedFile,
  AdtViewType,
  AssetsProductRequestImmagesStatus,
  AssetsMetaDataProperty,
  ProductImageData,
  ADTFirstLoginStatus,
} from "./adtInterfaces";
import { RootState } from "../storeConfig";
import { CustomOptions } from "../../components/styled-UI/CustomSelect";
import { selectLxConfigurations } from "../store/storeSlice";

export const sliceName = "adt";

const DEFAULT_ASSET_PLATFORM_STATUS: MultipleRequestStatus<AssetPlatformStatus> = {
  getWebsiteUrl: "IDLE",
  updateWebsiteUrl: "IDLE",
  getSocialMedia: "IDLE",
  updateSocialMedia: "IDLE",
  setPopup: "IDLE",
  socialMediaOptions: "IDLE",
};

const DEFAULT_ASSET_UPDATE_STATUS: MultipleRequestStatus<AssetsUpdateStatus> = {
  updateSavedAssetStatus: "IDLE",
  updateDownloadedAssetStatus: "IDLE",
};

const DEFAULT_ASSET_LP_STATUS: MultipleRequestStatus<AssetsPreviewStatus> = {
  getSavedAssetsStatus: "IDLE",
  getDownloadedAssetsStatus: "IDLE",
  getRecentlyAddedAssetsStatus: "IDLE",
};

const DEFAULT_ASSET_ACCOUNT_STATUS: MultipleRequestStatus<AssetsAccountStatus> = {
  getSavedAssetsStatus: "IDLE",
  getDownloadedAssetsStatus: "IDLE",
  savedFiltersStatus: "IDLE",
  downloadedFiltersStatus: "IDLE",
  sendRequestDownloadStatus: "IDLE",
};

const DEFAULT_ASSET_PDP_STATUS: MultipleRequestStatus<AssetsPdpStatus> = {
  getAssetsDetails: "IDLE",
  getAssetsRelated: "IDLE",
  getFeaturedProducts: "IDLE",
};

const DEFAULT_ASSET_REQUEST_PRODUCT_IMAGES_STATUS: MultipleRequestStatus<AssetsProductRequestImmagesStatus> = {
  sendRequest: "IDLE",
};

const DEFAULT_META_DATA_STATUS: MultipleRequestStatus<AssetsMetaDataProperty> = {
  getViewsTypes: "IDLE",
};

const DEFAULT_ASSETS_LIST: AssetsList = {
  assetList: [],
  scrollIds: ["init"],
};

const DEFAULT_ASSET_PLP_STATUS: MultipleRequestStatus<PLPStatus> = {
  assets: "IDLE",
  filters: "IDLE",
};

const initialState: AdtState = {
  /////////////////// common
  adtFirstLogin: { isAccepted: false, status: "IDLE" },
  assetPlaformWebsiteUrl: null,
  assetPlatformSocialMedia: [],
  assetPlatformStatus: DEFAULT_ASSET_PLATFORM_STATUS,
  assetMetadataProperty: DEFAULT_META_DATA_STATUS,
  adtPlatformSocialMediaOptions: [],
  categories: [],
  viewsTypes: [],
  assetsUpdateStatus: DEFAULT_ASSET_UPDATE_STATUS,

  /////////////////// landing page
  assetsPreviewStatus: DEFAULT_ASSET_LP_STATUS,
  assetsSavedPreview: [],
  assetsDownloadedPreview: [],
  assetsRecentlyAddedPreview: DEFAULT_ASSETS_LIST,
  categoryLoadingStatus: "IDLE",

  /////////////////// plp
  plpAssets: DEFAULT_ASSETS_LIST,
  plpFilters: [],
  plpStatus: DEFAULT_ASSET_PLP_STATUS,

  /////////////////// pdp
  assetsPdpStatus: DEFAULT_ASSET_PDP_STATUS,
  assetsDetails: null,
  assetsRelated: [],
  featuredProductsImages: [],

  /////////////////// account
  assetsAccountStatus: DEFAULT_ASSET_ACCOUNT_STATUS,
  assetsSavedAccount: DEFAULT_ASSETS_LIST,
  assetsDownloadedAccount: DEFAULT_ASSETS_LIST,
  assetsSavedAccountFilters: [],
  assetsDownloadedAccountFilters: [],

  /////////////////// request image
  assetsRequestProductImagesStatus: DEFAULT_ASSET_REQUEST_PRODUCT_IMAGES_STATUS,
  templatesRequestProductImages: {},
};

export const adtSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    /////////////////// common
    saveIsFirstADTLogin: (state, action: PayloadAction<ADTFirstLoginStatus>) => {
      state.adtFirstLogin = action.payload;
    },
    setAssetsMetaDataStatus: (
      state,
      action: PayloadAction<SetMultipleRequestStatus<AssetsMetaDataProperty>>
    ) => {
      state.assetMetadataProperty = {
        ...state.assetMetadataProperty,
        [action.payload.type]: action.payload.value,
      };
    },

    setAdtViewTypes: (state, action: PayloadAction<AdtViewType[]>) => {
      state.viewsTypes = action.payload;
    },

    saveAssetPlatformWebsiteUrl: (state, action: PayloadAction<string | null>) => {
      state.assetPlaformWebsiteUrl = action.payload;
    },

    saveAssetPlatformSocialMedia: (state, action: PayloadAction<AssetPlatformSocialMedia[]>) => {
      state.assetPlatformSocialMedia = action.payload;
    },
    setAssetPlatformStatus: (
      state,
      action: PayloadAction<SetMultipleRequestStatus<AssetPlatformStatus>>
    ) => {
      state.assetPlatformStatus = {
        ...state.assetPlatformStatus,
        [action.payload.type]: action.payload.value,
      };
    },

    saveAdtPlatformSocialMediaOptions: (state, action: PayloadAction<CustomOptions[]>) => {
      state.adtPlatformSocialMediaOptions = action.payload;
    },
    saveCategories: (state, action: PayloadAction<AdtCategories[]>) => {
      state.categories = action.payload;
    },
    setAssetsUpdateRequestStatus: (
      state,
      action: PayloadAction<SetMultipleRequestStatus<AssetsUpdateStatus>>
    ) => {
      state.assetsUpdateStatus = {
        ...state.assetsUpdateStatus,
        [action.payload.type]: action.payload.value,
      };
    },

    /////////////////// landing page
    setAssetsPreviewRequestStatus: (
      state,
      action: PayloadAction<SetMultipleRequestStatus<AssetsPreviewStatus>>
    ) => {
      state.assetsPreviewStatus = {
        ...state.assetsPreviewStatus,
        [action.payload.type]: action.payload.value,
      };
    },
    setAssetsSavedPreview: (state, action: PayloadAction<Array<AssetData>>) => {
      state.assetsSavedPreview = action.payload;
    },
    setAssetsDownloadedPreview: (state, action: PayloadAction<Array<AssetData>>) => {
      state.assetsDownloadedPreview = action.payload;
    },

    addAssetsRecentlyAddedPreview: (state, action: PayloadAction<AssetsList>) => {
      state.assetsRecentlyAddedPreview = {
        assetList: [...state.assetsRecentlyAddedPreview.assetList, ...action.payload.assetList],
        scrollIds: [...state.assetsRecentlyAddedPreview.scrollIds, ...action.payload.scrollIds],
      };
    },

    clearAssetsRecentlyAddedPreview: (state) => {
      state.assetsRecentlyAddedPreview = DEFAULT_ASSETS_LIST;
    },

    updateSavedAssetPreview: (
      state,
      { payload }: PayloadAction<Pick<AssetData, "isSaved" | "assetId">>
    ) => {
      const assetToBeUpdated = state.assetsSavedPreview.find((_) => _.assetId === payload.assetId);
      if (assetToBeUpdated) {
        if (assetToBeUpdated.isSaved !== payload.isSaved)
          assetToBeUpdated.isSaved = payload.isSaved;
      }
    },
    updateDownloadedAssetPreview: (
      state,
      { payload }: PayloadAction<Pick<AssetData, "isDownloaded" | "assetId">>
    ) => {
      const assetToBeUpdated = state.assetsSavedPreview.find((_) => _.assetId === payload.assetId);
      if (assetToBeUpdated) {
        if (assetToBeUpdated.isDownloaded !== payload.isDownloaded)
          assetToBeUpdated.isDownloaded = payload.isDownloaded;
      }
    },

    setCategoryLoadingStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.categoryLoadingStatus = action.payload;
    },

    /////////////////// plp
    addPlpAssets: (state, action: PayloadAction<AssetsList>) => {
      state.plpAssets = {
        assetList: [...state.plpAssets.assetList, ...action.payload.assetList],
        scrollIds: [...state.plpAssets.scrollIds, ...action.payload.scrollIds],
      };
    },

    clearPlpAssets: (state) => {
      state.plpAssets = DEFAULT_ASSETS_LIST;
    },

    clearPlpFilters: (state) => {
      state.plpFilters = [];
    },

    setPlpFilters: (state, action: PayloadAction<AssetFilterAny[]>) => {
      state.plpFilters = action.payload;
    },

    setPlpStatus: (state, action: PayloadAction<SetMultipleRequestStatus<PLPStatus>>) => {
      state.plpStatus = {
        ...state.plpStatus,
        [action.payload.type]: action.payload.value,
      };
    },

    /////////////////// pdp

    setAdtPdpStatus: (state, action: PayloadAction<SetMultipleRequestStatus<AssetsPdpStatus>>) => {
      state.assetsPdpStatus = {
        ...state.assetsPdpStatus,
        [action.payload.type]: action.payload.value,
      };
    },

    setAdtAssetsDetails: (state, action: PayloadAction<AssetData | null>) => {
      state.assetsDetails = action.payload;
    },

    setAdtAssetsRelated: (state, action: PayloadAction<AssetData[]>) => {
      state.assetsRelated = action.payload;
    },

    setAdtFeaturedProducts: (state, action: PayloadAction<ProductImageData[]>) => {
      state.featuredProductsImages = action.payload;
    },

    resetPdPData: (state) => {
      state.assetsDetails = null;
      state.assetsRelated = [];
      state.featuredProductsImages = [];
      state.assetsPdpStatus = DEFAULT_ASSET_PDP_STATUS;
    },

    /////////////////// myaccount
    setAssetsAccountRequestStatus: (
      state,
      action: PayloadAction<SetMultipleRequestStatus<AssetsAccountStatus>>
    ) => {
      state.assetsAccountStatus = {
        ...state.assetsAccountStatus,
        [action.payload.type]: action.payload.value,
      };
    },

    addAssetsSavedAccount: (state, action: PayloadAction<AssetsList>) => {
      state.assetsSavedAccount = {
        assetList: [...state.assetsSavedAccount.assetList, ...action.payload.assetList],
        scrollIds: [...state.assetsSavedAccount.scrollIds, ...action.payload.scrollIds],
      };
    },

    addAssetsDownloadedAccount: (state, action: PayloadAction<AssetsList>) => {
      state.assetsDownloadedAccount = {
        assetList: [...state.assetsDownloadedAccount.assetList, ...action.payload.assetList],
        scrollIds: [...state.assetsDownloadedAccount.scrollIds, ...action.payload.scrollIds],
      };
    },

    setAssetsSavedAccountFilters: (state, action: PayloadAction<AssetFilterAny[]>) => {
      state.assetsSavedAccountFilters = action.payload;
    },

    setAssetsDownloadedAccountFilters: (state, action: PayloadAction<AssetFilterAny[]>) => {
      state.assetsDownloadedAccountFilters = action.payload;
    },

    updateDownloadedAssetAccount: (
      state,
      { payload }: PayloadAction<Pick<AssetData, "isDownloaded" | "assetId">>
    ) => {
      const assetToBeUpdated = state.assetsSavedAccount.assetList.find(
        (_) => _.assetId === payload.assetId
      );
      if (assetToBeUpdated) {
        if (assetToBeUpdated.isDownloaded !== payload.isDownloaded)
          assetToBeUpdated.isDownloaded = payload.isDownloaded;
      }
    },

    updateSavedAssetAccount: (
      state,
      { payload }: PayloadAction<Pick<AssetData, "isSaved" | "assetId">>
    ) => {
      const assetToBeUpdated = state.assetsSavedAccount.assetList.find(
        (_) => _.assetId === payload.assetId
      );
      if (assetToBeUpdated) {
        if (assetToBeUpdated.isSaved !== payload.isSaved)
          assetToBeUpdated.isSaved = payload.isSaved;
      }
    },

    resetSavedAccount: (state) => {
      state.assetsSavedAccount = DEFAULT_ASSETS_LIST;
      state.assetsAccountStatus.getSavedAssetsStatus =
        DEFAULT_ASSET_ACCOUNT_STATUS.getSavedAssetsStatus;
      // state.assetsSavedAccountFilters = [];
    },

    resetDownloadedAccount: (state) => {
      state.assetsDownloadedAccount = DEFAULT_ASSETS_LIST;
      state.assetsAccountStatus.getDownloadedAssetsStatus =
        DEFAULT_ASSET_ACCOUNT_STATUS.getDownloadedAssetsStatus;
      // state.assetsDownloadedAccountFilters = [];
    },

    resetSendRequestDownloadStatus: (state) => {
      state.assetsAccountStatus.sendRequestDownloadStatus =
        DEFAULT_ASSET_ACCOUNT_STATUS.sendRequestDownloadStatus;
    },

    /////////////////// request image

    setAssetRequestProductImagesStatus: (
      state,
      action: PayloadAction<SetMultipleRequestStatus<AssetsProductRequestImmagesStatus>>
    ) => {
      state.assetsRequestProductImagesStatus = {
        ...state.assetsRequestProductImagesStatus,
        [action.payload.type]: action.payload.value,
      };
    },

    saveTemplateRequestProductImages: (state, action: PayloadAction<TemplateCacheFile>) => {
      state.templatesRequestProductImages = {
        ...state.templatesRequestProductImages,
        [action.payload.id]: action.payload.file,
      };
    },
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  /////////////////// common
  saveAssetPlatformWebsiteUrl,
  saveAssetPlatformSocialMedia,
  setAssetPlatformStatus,
  saveIsFirstADTLogin,
  saveAdtPlatformSocialMediaOptions,
  saveCategories,
  setAdtViewTypes,
  setAssetsUpdateRequestStatus,
  setAssetsMetaDataStatus,

  /////////////////// landing page
  setAssetsPreviewRequestStatus,
  setAssetsSavedPreview,
  setAssetsDownloadedPreview,
  addAssetsRecentlyAddedPreview,
  clearAssetsRecentlyAddedPreview,

  updateDownloadedAssetPreview,
  updateSavedAssetPreview,

  setCategoryLoadingStatus,

  /////////////////// plp
  addPlpAssets,
  clearPlpAssets,
  clearPlpFilters,
  setPlpFilters,
  setPlpStatus,

  /////////////////// pdp
  setAdtPdpStatus,
  setAdtAssetsDetails,
  setAdtAssetsRelated,
  setAdtFeaturedProducts,
  resetPdPData,

  /////////////////// myaccount
  setAssetsAccountRequestStatus,
  addAssetsSavedAccount,
  addAssetsDownloadedAccount,
  setAssetsSavedAccountFilters,
  setAssetsDownloadedAccountFilters,
  updateDownloadedAssetAccount,
  updateSavedAssetAccount,
  resetSavedAccount,
  resetDownloadedAccount,
  resetSendRequestDownloadStatus,

  /////////////////// request
  setAssetRequestProductImagesStatus,
  saveTemplateRequestProductImages,
} = adtSlice.actions;

/////////////////// common

export const selectAdtFileUrl = createSelector(
  selectLxConfigurations,

  (lxConfigurations: LXConfiguration[]) => {
    const adtFileUrl =
      lxConfigurations.length > 0 &&
      lxConfigurations.filter((element) => element.key === "com.luxottica.adt.akamay.host")?.[0]
        ?.value;
    return adtFileUrl;
  }
);

export const selectAssetMetaDataStatus = (
  state: RootState
): MultipleRequestStatus<AssetsMetaDataProperty> => {
  return state.adt.assetMetadataProperty;
};

export const selectAdtViewsTypes = (state: RootState): AdtViewType[] => {
  return state.adt.viewsTypes;
};

export const selectAssetPlaformWebsiteUrl = (state: RootState): string | null => {
  return state.adt.assetPlaformWebsiteUrl;
};

export const selectAssetPlatformSocialMedia = (state: RootState): AssetPlatformSocialMedia[] => {
  return state.adt.assetPlatformSocialMedia;
};

export const selectAssetPlatformStatus = (
  state: RootState
): MultipleRequestStatus<AssetPlatformStatus> => {
  return state.adt.assetPlatformStatus;
};

export const selectIsFirstADTLogin = (state: RootState): ADTFirstLoginStatus => {
  return state.adt.adtFirstLogin;
};

export const selectAdtPlatformSocialMediaOptions = (state: RootState): CustomOptions[] => {
  return state.adt.adtPlatformSocialMediaOptions;
};

export const selectAdtCategories = (state: RootState): AdtCategories[] => {
  return state.adt.categories;
};

export const selectAssetsUpdateRequestStatus = (
  state: RootState
): MultipleRequestStatus<AssetsUpdateStatus> => {
  return state.adt.assetsUpdateStatus;
};

/////////////////// landing page
export const selectAssetsPreviewRequestStatus = (
  state: RootState
): MultipleRequestStatus<AssetsPreviewStatus> => {
  return state.adt.assetsPreviewStatus;
};

export const selectCategoryLoadingStatus = (state: RootState): RequestStatus => {
  return state.adt.categoryLoadingStatus;
};
export const selectAssetsSavedPreview = (state: RootState): AssetData[] => {
  return state.adt.assetsSavedPreview;
};

export const selectAssetsDownloadedPreview = (state: RootState): AssetData[] => {
  return state.adt.assetsDownloadedPreview;
};

export const selectAssetsRecentlyAddedPreview = (state: RootState): AssetsList => {
  return state.adt.assetsRecentlyAddedPreview;
};

/////////////////// plp
export const selectPlpAssets = (state: RootState): AssetsList => {
  return state.adt.plpAssets;
};

export const selectPlpFilters = (state: RootState): AssetFilterAny[] => {
  return state.adt.plpFilters;
};

export const selectPlpStatus = (state: RootState): MultipleRequestStatus<PLPStatus> => {
  return state.adt.plpStatus;
};

/////////////////// pdp
export const selectAdtPdpStatus = (state: RootState): MultipleRequestStatus<AssetsPdpStatus> => {
  return state.adt.assetsPdpStatus;
};

export const selectAdtAssetsDetails = (state: RootState): AssetData | null => {
  return state.adt.assetsDetails;
};

export const selectAdtAssetsRelated = (state: RootState): AssetData[] => {
  return state.adt.assetsRelated;
};

export const selectAdtFeaturedProducts = (state: RootState): ProductImageData[] => {
  return state.adt.featuredProductsImages;
};

/////////////////// myaccount
export const selectAssetsAccountRequestStatus = (
  state: RootState
): MultipleRequestStatus<AssetsAccountStatus> => {
  return state.adt.assetsAccountStatus;
};

export const selectAssetsSavedAccount = (state: RootState): AssetsList => {
  return state.adt.assetsSavedAccount;
};

export const selectAssetsDownloadedAccount = (state: RootState): AssetsList => {
  return state.adt.assetsDownloadedAccount;
};

export const selectAssetsSavedAccountFilters = (state: RootState): AssetFilterAny[] => {
  return state.adt.assetsSavedAccountFilters;
};

export const selectAssetsDownloadedAccountFilters = (state: RootState): AssetFilterAny[] => {
  return state.adt.assetsDownloadedAccountFilters;
};

/////////////////// request image
export const selectAssetRequestProductImagesStatus = (
  state: RootState
): MultipleRequestStatus<AssetsProductRequestImmagesStatus> => {
  return state.adt.assetsRequestProductImagesStatus;
};

export const selectTemplatesRequestProductImages = (state: RootState): TemplateSavedFile => {
  return state.adt.templatesRequestProductImages;
};

export default adtSlice.reducer;
