import React, { useEffect, useState } from "react";
import { Route, RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveDoor,
  selectGetPrivilegesStatus,
  selectIsLogged,
  selectLocale,
  selectStoreIdentifier,
} from "../../store/user/userSlice";
import {
  getLoginPagePath,
  parseUrlInfo,
  replaceLocaleInUrl,
  replaceStoreIdInUrl,
} from "../../routing/routesUtils";
import {
  saveRedirectPrivateLocale,
  selectCurrentLang,
  selectRedirectPrivateLocale,
  selectSupportedLanguages,
} from "../../store/store/storeSlice";
import { updatePrivateLanguage } from "../../store/store/storeSagas";
import { isSetAsLanguage, isSetLanguageSupported } from "../../utils/languageUtils";
import LoadingBackground from "../styled-UI/LoadingBackground";
import { areEqualString } from "../../utils/utils";

interface Props extends RouteComponentProps<any> {
  path: string;
  exact: boolean;
  render: (props: any) => JSX.Element;
}

const PrivateRoute = ({ render, history, path, exact }: Props) => {
  const [canAccess, setCanAccess] = useState<boolean>(false);
  const isLogged = useSelector(selectIsLogged);
  const supportedLanguages = useSelector(selectSupportedLanguages);
  const storeIdentifier = useSelector(selectStoreIdentifier);
  const location = useLocation();
  const currentLocale = useSelector(selectLocale);
  const dispatch = useDispatch();
  const currentLang = useSelector(selectCurrentLang);
  const redirectPrivateLocale = useSelector(selectRedirectPrivateLocale);
  // const atLeastOneTruePrivileges = useSelector(selectAtLeastOneTruePrivileges);
  const getUserPrivilegesStatus = useSelector(selectGetPrivilegesStatus);

  //CHECK STORE ID AND LOCALE
  useEffect(() => {
    if (isLogged && storeIdentifier && currentLocale && supportedLanguages.length > 0) {
      const site = parseUrlInfo();

      // console.log(
      //   `[DEBUG][SITESETTER][PRIVATE LANG] url changed: { ${location.pathname} } dispatching updatePrivateLanguage`
      // );

      if (site.storeIdentifier && site.locale && site.pageType) {
        if (site.storeIdentifier !== storeIdentifier) {
          return history.push(replaceStoreIdInUrl(storeIdentifier));
        }
        dispatch(updatePrivateLanguage());
      }
    }
  }, [isLogged, location, currentLocale, storeIdentifier, supportedLanguages]);

  //REDIRECT BASED ON REDUX'S LOCALE (b/c of unsupported locale in the url)
  useEffect(() => {
    if (isLogged) {
      const site = parseUrlInfo();

      // console.log(
      //   `[DEBUG][SITESETTER][PRIVATE LANG] redirectPrivateLocale changed: { ${redirectPrivateLocale} } current site.locale: { ${site.locale} }`,
      //   redirectPrivateLocale &&
      //     redirectPrivateLocale != site.locale &&
      //     `redirecting to ${replaceLocaleInUrl(redirectPrivateLocale)}`
      // );

      if (
        redirectPrivateLocale &&
        site.locale &&
        !areEqualString(redirectPrivateLocale, site.locale)
      ) {
        history.push(replaceLocaleInUrl(redirectPrivateLocale));
        dispatch(saveRedirectPrivateLocale(null));
      }
    }
  }, [redirectPrivateLocale, isLogged]);

  const activeDoor = useSelector(selectActiveDoor);

  //REDIRECT IF NOT LOGGED IN AND SHOW PAGE IF EVERYTHING IS OKAY
  useEffect(() => {
    if (!isLogged) {
      //if user has clicked on logout button, do not redirect to specific page after login (it will fallback on hp)
      const hasClickedOnLogout = sessionStorage.getItem("MYL_clicked_logout");
      const urlToRedirect =
        hasClickedOnLogout && hasClickedOnLogout === "true"
          ? getLoginPagePath()
          : getLoginPagePath() + "?redirectUrl=" + location.pathname + location.search;
      history.push(urlToRedirect);
      sessionStorage.removeItem("MYL_clicked_logout");
    } else {
      const site = parseUrlInfo();
      // console.log(
      //   site.locale,
      //   site.storeIdentifier,
      //   storeIdentifier,
      //   supportedLanguages,
      //   isSetLanguageSupported(supportedLanguages),
      //   isSetAsLanguage(site.locale)
      // );
      if (
        currentLang &&
        site.locale &&
        site.storeIdentifier &&
        supportedLanguages.length > 0 &&
        site.storeIdentifier === storeIdentifier &&
        isSetLanguageSupported(currentLang, supportedLanguages) &&
        isSetAsLanguage(currentLang, site.locale) &&
        activeDoor &&
        getUserPrivilegesStatus === "SUCCESS"
      ) {
        if (!canAccess) setCanAccess(true);
      } else {
        if (canAccess) setCanAccess(false);
      }
    }
  }, [
    isLogged,
    currentLang,
    supportedLanguages,
    location,
    storeIdentifier,
    activeDoor,
    getUserPrivilegesStatus,
  ]);

  return (
    <>
      {canAccess ? (
        <Route exact={exact} path={path} render={(props) => render(props)} />
      ) : (
        <LoadingBackground />
      )}
    </>
  );
};

export default withRouter(PrivateRoute);
