import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../storeConfig";

import {
  WarrantyWizardAlternativeVariants,
  WarrantyWizardState,
  WarrantyWizardStepStructure,
  WarrantyWizardOptionsStepDamageInfo,
  WarrantyWizardRecap,
  ModelDetailsWarranty,
} from "./warrantyWizardInterface";
import { QueryParams } from "../search/searchInterfaces";
import { PlpCatalogueNoAdv } from "../catalogue/catalogueInterface";
import { Product, Sku } from "../../interfaces/productInterface";
import { FacetView } from "../../interfaces/facetInterfaces";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import { CustomOptions } from "../../components/styled-UI/CustomSelect";

export const sliceName = "warrantyWizard";

const initialState: WarrantyWizardState = {
  warrantyIdentifier: "",
  warrantyWizardProductDetails: null,
  availableInActiveCatalog: null,
  warrantyWizardStepStructure: [],
  isStepLoading: false,
  loadingWarrantyProductDetails: "IDLE",

  /////////////////// damage info step
  warrantyWizardOptionsStepDamageInfo: null,

  /////////////////// alternative steps
  productPerPage: 24,
  alternativeModelsCatalogue: null,
  alternativeVariants: {
    items: [],
    recordSetTotal: 0,
    recordSetCount: 0,
  },
  loadingAltModelsCatalogue: "IDLE",
  loadingAltVariants: "IDLE",
  selectedFilters: {},
  warrantyWizardSearch: "",
  warrantyWizardPage: "",
  altModelsCatalogueCurrentPage: 1,
  selectedModel: null,
  selectedSku: null,
  modelDetailsWarranty: null,
  warrantyLowerSizeCheckbox: "",

  /////////////////// delivery address step
  warrantyWizardOptionsStepDeliveryAddress: [],

  /////////////////// recap step
  warrantyWizardRecap: null,
};

export const warrantyWizardSlice = createSlice({
  name: sliceName,
  initialState,

  reducers: {
    saveWarrantyIdentifier: (state, action: PayloadAction<string>) => {
      state.warrantyIdentifier = action.payload;
    },

    saveWarrantyWizardProductDetails: (state, action: PayloadAction<Sku | null>) => {
      state.warrantyWizardProductDetails = action.payload;
    },

    saveAvailableInActiveCatalog: (state, action: PayloadAction<boolean | null>) => {
      state.availableInActiveCatalog = action.payload;
    },

    saveWarrantyWizardStepStructure: (
      state,
      action: PayloadAction<WarrantyWizardStepStructure[]>
    ) => {
      state.warrantyWizardStepStructure = action.payload;
    },

    setIsStepLoading: (state, action: PayloadAction<boolean>) => {
      state.isStepLoading = action.payload;
    },

    saveLoadingWarrantyProductDetails: (state, action: PayloadAction<RequestStatus>) => {
      state.loadingWarrantyProductDetails = action.payload;
    },

    /////////////////// damage info step
    saveWarrantyWizardOptionsStepDamageInfo: (
      state,
      action: PayloadAction<WarrantyWizardOptionsStepDamageInfo>
    ) => {
      state.warrantyWizardOptionsStepDamageInfo = action.payload;
    },

    /////////////////// alternative steps
    saveAlternativeModelsCatalogue: (state, action: PayloadAction<PlpCatalogueNoAdv | null>) => {
      state.alternativeModelsCatalogue = action.payload;
    },

    saveLoadingAltModelsCatalogue: (state, action: PayloadAction<RequestStatus>) => {
      state.loadingAltModelsCatalogue = action.payload;
    },

    saveLoadingAltVariants: (state, action: PayloadAction<RequestStatus>) => {
      state.loadingAltVariants = action.payload;
    },

    saveSelectedFilters: (state, action: PayloadAction<QueryParams>) => {
      state.selectedFilters = action.payload;
      state.altModelsCatalogueCurrentPage = 1;
    },

    saveWarrantyWizardSearch: (state, action: PayloadAction<string>) => {
      state.warrantyWizardSearch = action.payload;
    },

    saveWarrantyWizardLowerSizeCheckbox: (state, action: PayloadAction<string>) => {
      state.warrantyLowerSizeCheckbox = action.payload;
    },

    saveWarrantyWizardPage: (state, action: PayloadAction<string>) => {
      state.warrantyWizardPage = action.payload;
    },

    saveAltModelsCatalogueCurrentPage: (state, action: PayloadAction<number>) => {
      state.altModelsCatalogueCurrentPage = action.payload;
    },

    saveWarrantySelectedModel: (state, action: PayloadAction<Product | null>) => {
      state.selectedModel = action.payload;
    },

    saveWarrantySelectedSku: (state, action: PayloadAction<Sku>) => {
      state.selectedSku = action.payload;
    },

    saveAlternativeVariants: (state, action: PayloadAction<WarrantyWizardAlternativeVariants>) => {
      state.alternativeVariants = action.payload;
    },

    saveModelDetailsWarranty: (state, action: PayloadAction<ModelDetailsWarranty | null>) => {
      state.modelDetailsWarranty = action.payload;
    },

    /////////////////// delivery address step
    saveWarrantyWizardOptionsStepDeliveryAddress: (
      state,
      action: PayloadAction<CustomOptions[]>
    ) => {
      state.warrantyWizardOptionsStepDeliveryAddress = action.payload;
    },

    /////////////////// recap step
    saveWarrantyWizardRecap: (state, action: PayloadAction<WarrantyWizardRecap | null>) => {
      state.warrantyWizardRecap = action.payload;
    },

    resetWarrantyWizardState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  saveWarrantyIdentifier,
  saveWarrantyWizardProductDetails,
  saveAvailableInActiveCatalog,
  saveWarrantyWizardStepStructure,
  setIsStepLoading,
  saveLoadingWarrantyProductDetails,

  /////////////////// damage info step
  saveWarrantyWizardOptionsStepDamageInfo,

  /////////////////// alternative steps
  saveAlternativeModelsCatalogue,
  saveLoadingAltModelsCatalogue,
  saveLoadingAltVariants,
  saveSelectedFilters,
  saveWarrantyWizardSearch,
  saveWarrantyWizardPage,
  saveAltModelsCatalogueCurrentPage,
  saveWarrantySelectedModel,
  saveWarrantySelectedSku,
  saveAlternativeVariants,
  saveModelDetailsWarranty,
  saveWarrantyWizardLowerSizeCheckbox,

  /////////////////// delivery address step
  saveWarrantyWizardOptionsStepDeliveryAddress,

  /////////////////// recap step
  saveWarrantyWizardRecap,

  resetWarrantyWizardState,
} = warrantyWizardSlice.actions;

export const selectWarrantyIdentifier = (state: RootState): string => {
  return state.warrantyWizard.warrantyIdentifier;
};

export const selectWarrantyWizardProductDetails = (state: RootState): Sku | null => {
  return state.warrantyWizard.warrantyWizardProductDetails;
};

export const selectAvailableInActiveCatalog = (state: RootState): boolean | null => {
  return state.warrantyWizard.availableInActiveCatalog;
};

export const selectWarrantyWizardStepStructure = (
  state: RootState
): WarrantyWizardStepStructure[] => {
  return state.warrantyWizard.warrantyWizardStepStructure;
};

export const selectIsStepLoading = (state: RootState): boolean => {
  return state.warrantyWizard.isStepLoading;
};

export const selectLoadingWarrantyProductDetails = (state: RootState): RequestStatus => {
  return state.warrantyWizard.loadingWarrantyProductDetails;
};

/////////////////// damage info step
export const selectWarrantyWizardOptionsStepDamageInfo = (
  state: RootState
): WarrantyWizardOptionsStepDamageInfo | null => {
  return state.warrantyWizard.warrantyWizardOptionsStepDamageInfo;
};

/////////////////// alternative steps
export const selectWarrantyProductPerPage = (state: RootState): number => {
  return state.warrantyWizard.productPerPage;
};

export const selectAlternativeModelsCatalogue = (state: RootState): PlpCatalogueNoAdv | null => {
  return state.warrantyWizard.alternativeModelsCatalogue;
};

export const selectAlternativeModelsFilters = (state: RootState): FacetView[] | null => {
  return state.warrantyWizard.alternativeModelsCatalogue?.facetView ?? null;
};

export const selectLoadingAltModelsCatalogue = (state: RootState): RequestStatus => {
  return state.warrantyWizard.loadingAltModelsCatalogue;
};

export const selectLoadingAltVariants = (state: RootState): RequestStatus => {
  return state.warrantyWizard.loadingAltVariants;
};

export const selectTotalPagesModelsCatalogue = (state: RootState): number => {
  const resultTotal: number = state.warrantyWizard.alternativeModelsCatalogue?.resultsTotal ?? 0;
  return Math.ceil(resultTotal / state.warrantyWizard.productPerPage);
};

export const selectAlternativeModelsSelectedFilters = (state: RootState): QueryParams => {
  return state.warrantyWizard.selectedFilters;
};

export const selectWarrantyWizardSearch = (state: RootState): string => {
  return state.warrantyWizard.warrantyWizardSearch;
};

export const selectWarrantyLowerSizeCheckbox = (state: RootState): string => {
  return state.warrantyWizard.warrantyLowerSizeCheckbox;
};

export const selectWarrantyWizardPage = (state: RootState): string => {
  return state.warrantyWizard.warrantyWizardPage;
};

export const selectAltModelsCatalogueCurrentPage = (state: RootState): number => {
  return state.warrantyWizard.altModelsCatalogueCurrentPage;
};

export const selectWarrantySelectedModel = (state: RootState): Product | null => {
  return state.warrantyWizard.selectedModel;
};

export const selectWarrantySelectedSku = (state: RootState): Sku | null => {
  return state.warrantyWizard.selectedSku;
};

export const selectWarrantyWizardAlternativeVariants = (
  state: RootState
): WarrantyWizardAlternativeVariants => {
  return state.warrantyWizard.alternativeVariants;
};

export const selectTotalPagesAlternativeVariants = (state: RootState): number => {
  const resultTotal: number = state.warrantyWizard.alternativeVariants?.recordSetTotal ?? 0;
  return Math.ceil(resultTotal / state.warrantyWizard.productPerPage);
};

export const selectModelDetailsWarranty = (state: RootState): ModelDetailsWarranty | null => {
  return state.warrantyWizard.modelDetailsWarranty;
};

/////////////////// delivery address step
export const selectWarrantyWizardOptionsStepDeliveryAddress = (
  state: RootState
): CustomOptions[] => {
  return state.warrantyWizard.warrantyWizardOptionsStepDeliveryAddress;
};

/////////////////// recap step

export const selectWarrantyWizardRecap = (state: RootState): WarrantyWizardRecap | null => {
  return state.warrantyWizard.warrantyWizardRecap;
};

export default warrantyWizardSlice.reducer;
