import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import { getDelivery, saveDelivery } from "../../../store/cart/cartSagas";
import Popup from "../../styled-UI/Popup";
import Button from "../../styled-UI/Button";
import CustomText from "../../styled-UI/CustomText";
import {
  clearDelivery,
  selectDeliveryDates,
  selectGetDeliveryDatesStatus,
  selectOrderId,
  selectSaveDeliveryDatesStatus,
} from "../../../store/cart/cartSlice";
import { selectLocale } from "../../../store/user/userSlice";
import { formatDate } from "../../../utils/dateUtils";
import Loader from "../../styled-UI/Loader";
import { DeliveryDates } from "../../../store/cart/cartInterfaces";
import { cloneDeep } from "lodash";
import DeliveryTable from "./DeliveryTable";

interface Props {
  isPopupOpen: boolean;
  closePopup: () => void;
}

const SetDeliveryPopup = ({ isPopupOpen, closePopup }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const currentLocale = useSelector(selectLocale);
  const orderId = useSelector(selectOrderId);
  const deliveryDates = useSelector(selectDeliveryDates);
  const getDeliveryStatus = useSelector(selectGetDeliveryDatesStatus);
  const saveDeliveryStatus = useSelector(selectSaveDeliveryDatesStatus);

  const [updatedDeliveryDates, setUpdatedDeliveryDates] = useState<DeliveryDates[]>([]);

  useEffect(() => {
    if (isPopupOpen) dispatch(getDelivery({ checkout: false }));
  }, [isPopupOpen]);

  useEffect(() => {
    if (saveDeliveryStatus === "SUCCESS") {
      closePopup();
      dispatch(clearDelivery());
      setUpdatedDeliveryDates([]);
    }
  }, [saveDeliveryStatus]);

  useEffect(() => {
    setUpdatedDeliveryDates(deliveryDates);
  }, [deliveryDates]);

  const updateDate = (orgentityName: string, requestedShipDate: string, date: Date) => {
    const newUpdatedDates = cloneDeep(updatedDeliveryDates);

    newUpdatedDates.forEach((door) => {
      if (door.orgentityName === orgentityName) {
        door.delivery.forEach((del) => {
          if (del.requestedShipDate === requestedShipDate) {
            del.cancellationDate = formatDate(date, "yyyy-MM-dd", currentLocale);
          }
        });
      }
    });

    setUpdatedDeliveryDates(newUpdatedDates);
  };

  const onConfirm = () => {
    dispatch(
      saveDelivery({ orderId: orderId, expectedCancellation: { doors: updatedDeliveryDates } })
    );
  };

  return (
    <Popup isOpen={isPopupOpen} close={closePopup} isCentered>
      <ModalContainer>
        {getDeliveryStatus === "LOADING" && (
          <LoaderContainer>
            <Loader sizePx={30} />
          </LoaderContainer>
        )}
        {getDeliveryStatus === "ERROR" && (
          <CustomText as="span" color="red">
            {translateLabel("ERROR_POPUP_DEFAULT_MESSAGE")}
          </CustomText>
        )}
        {getDeliveryStatus === "SUCCESS" && (
          <DeliveryTable updatedDeliveryDates={updatedDeliveryDates} updateDate={updateDate} />
        )}
        <PopupButtonContainer>
          {saveDeliveryStatus === "ERROR" && (
            <CustomText as="span" color="red">
              {translateLabel("ERROR_POPUP_DEFAULT_MESSAGE")}
            </CustomText>
          )}
          <Button type="primary" onClick={onConfirm} isLoading={saveDeliveryStatus === "LOADING"}>
            {translateLabel("CONFIRM")}
          </Button>
        </PopupButtonContainer>
      </ModalContainer>
    </Popup>
  );
};

const ModalContainer = styled.div`
  padding: 2rem;
  min-width: 42.375rem;
`;

const PopupButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SetDeliveryPopup;
