import React from "react";
import styled from "styled-components/macro";
import useDataHref from "../../hooks/useDataHref";
import { LpTitle as LpTitleInterface } from "../../interfaces/cmsInterfaces";
import CustomText from "../styled-UI/CustomText";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  data: LpTitleInterface;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LpTitle = ({ data, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  const { description, description1Colour } = data;
  const descrWithHref = useDataHref({ content: description });
  return (
    <OuterBox>
      {description && (
        <CustomText
          as="span"
          fontSizePx={36}
          font={customFont ? "custom-medium" : "font-medium"}
          fontFamily={customFont}
          color={isDarkMode ? "white" : "primary"}
          lineHeightPx={36}
          textAlign="center"
          customColor={description1Colour}
        >
          <SetInnerHTMLWithSanitize text={descrWithHref.updatedContent ?? description} />
        </CustomText>
      )}
    </OuterBox>
  );
};

export default LpTitle;

const OuterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px auto;
  height: 100%;
  width: 80%;
`;
