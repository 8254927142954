import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import clsx from "clsx";

import { selectBestSellerRequestStatus } from "../../store/catalogue/catalogueSlice";
import { Product } from "../../interfaces/productInterface";
import WrapperLayout from "../layouts/WrapperLayout";
import { getPagePath } from "../../routing/routesUtils";
import HomeCarousel from "../styled-UI/HomeCarousel";
import { MemoizedLoaderTileWithBackgroud } from "../styled-UI/loader/LoaderTile";
import LoaderCarousel from "../styled-UI/loader/LoaderTileCarousel";
import Tile from "./Tile/Tile";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  bestSellers: Product[];
  bestsellerHomepage?: boolean;
  hideDisplay?: boolean;
  enableDataDescriptionNavigation?: boolean;
  hideWrapper?: boolean;
  dataElementIdSlider?: string;
  currentPage: number;
  totalSlides: number;
  getNextCarousel: (page: "prev" | "next") => void;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

export const BestSellers = ({
  bestSellers,
  bestsellerHomepage = false,
  hideDisplay = false,
  enableDataDescriptionNavigation = false,
  hideWrapper = false,
  dataElementIdSlider,
  currentPage,
  totalSlides,
  getNextCarousel,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const requestStatus = useSelector(selectBestSellerRequestStatus);

  const renderTiles = (): JSX.Element[] => {
    const tiles: JSX.Element[] = [];

    for (let i = 0; i < totalSlides; i++) {
      const product = bestSellers[i];

      if (product) {
        const tileNumber = i + 1;
        const modelCode = product.productCode;
        tiles.push(
          <Slide key={i}>
            <TileContainer>
              <Tile
                data={product}
                hideSeeVariants
                data-element-id={`BestSellers_ProdTiles_Tile_${tileNumber}`}
                data-description={modelCode + "_" + product.productCode}
              />
            </TileContainer>
          </Slide>
        );
      } else {
        tiles.push(
          <Slide key={i}>
            <MemoizedLoaderTileWithBackgroud heightPx={648} />
          </Slide>
        );
      }
    }
    return tiles;
  };

  const renderBestSellers = () => {
    return (
      <HomeCarousel
        slidesToScroll={3}
        slidesToShow={3}
        title="BEST_SELLERS"
        colorSelect={bestsellerHomepage ? "gray-medium" : "bg-light-blue"}
        hideDisplay={hideDisplay}
        url={getPagePath("/plp/frames?BESTSELLER=TRUE")}
        data-element-id="BestSellers_Navigation"
        dataElementIdFilters="BestSellers_Filter"
        dataElementIdViewAll="BestSellers_ViewAll"
        enableDataDescriptionNavigation={enableDataDescriptionNavigation}
        dataElementIdSlider={dataElementIdSlider}
        callbackOnChangeSlide={(page: number) =>
          getNextCarousel(page >= currentPage * 3 ? "next" : "prev")
        }
        isDarkMode={isDarkMode}
        customFont={customFont}
      >
        {renderTiles()}
      </HomeCarousel>
    );
  };

  const renderBestSellersWithWrapper = (): JSX.Element => {
    return (
      <BestsellerSection
        className={clsx(bestsellerHomepage && "bestseller-homepage")}
        data-element-id="BestSellers"
        isDarkMode={isDarkMode}
      >
        <WrapperLayout>{renderBestSellers()}</WrapperLayout>
      </BestsellerSection>
    );
  };

  if (bestSellers.length > 0)
    return hideWrapper ? renderBestSellers() : renderBestSellersWithWrapper();

  if (requestStatus === "LOADING") {
    return hideWrapper ? (
      <LoaderWrapper>
        <LoaderCarousel />
      </LoaderWrapper>
    ) : (
      <WrapperLayout>
        <LoaderWrapper>
          <LoaderCarousel />
        </LoaderWrapper>
      </WrapperLayout>
    );
  }

  return <></>;
};

const BestsellerSection = styled.div<{ isDarkMode: boolean }>`
  padding: 3rem 0;

  &.bestseller-homepage {
    padding: 1.5625rem 0;
    background-color: ${(props) => (props.isDarkMode ? "" : props.theme.palette.gray.medium)};
  }
`;

const Slide = styled.div`
  padding-right: 1.25rem;
  box-sizing: border-box;

  @media ${(props) => props.theme.queries.md} {
    padding-right: 0;
  }
`;

const LoaderWrapper = styled.div`
  padding: 2rem 0;
`;

const TileContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default BestSellers;
