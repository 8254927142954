import { differenceWith, orderBy, uniqWith } from "lodash";
import { Attribute, ProductCategory, Variant, Product } from "../interfaces/productInterface";
import { OrderItem } from "../store/cart/cartInterfaces";
import {
  AFAAvailability,
  AFASku,
  AFAVariantList,
  AvailabilityInfoValue,
  AvailabilityStatusByDoor,
  CurrentCategoryPlp,
  TechnologyIcons,
} from "../store/catalogue/catalogueInterface";
import { compareDay } from "./dateUtils";
import { getAttribute } from "./productUtils";
import { MultiMediaType } from "../interfaces/cmsInterfaces";
import { useLocation } from "react-router-dom";

export const filterKeylookByAttribute = (
  keylook: MultiMediaType[],
  variant: Variant | Product
): MultiMediaType[] => {
  if (variant?.brand && variant?.attributes) {
    const keylookAttribute: Attribute | null = getAttribute(variant?.attributes, "KEYLOOK");
    if (keylook && keylookAttribute && keylookAttribute?.values) {
      const keylookContentsIds = keylookAttribute.values.map((el) => el.identifier);
      return (
        keylook.filter((el: any) => {
          if (el.subjectTaxonomy.length > 0 && el.subjectTaxonomy[0].externalReference) {
            return keylookContentsIds.includes(el.subjectTaxonomy[0].externalReference);
          }
          return false;
        }) ?? []
      );
    }
  }
  return [];
};

export const filterIconByAttribute = (
  techIcons: TechnologyIcons,
  variant: Variant | Product
): TechnologyIcons => {
  const marketingTechAttribute =
    variant?.brand && variant?.attributes && getAttribute(variant?.attributes, "MARKETING_TECH");
  if (marketingTechAttribute && marketingTechAttribute?.values) {
    const mappedIconIds = marketingTechAttribute.values.map((el) => el.identifier);
    return {
      "ksp-sustainability":
        techIcons?.["ksp-sustainability"]?.filter((el) =>
          mappedIconIds.includes(el.subjectTaxonomy?.[0]?.externalReference || el.name)
        ) ?? [],
      "ksp-technology":
        techIcons?.["ksp-technology"]?.filter((el) =>
          mappedIconIds.includes(el.subjectTaxonomy?.[0]?.externalReference || el.name)
        ) ?? [],
      tech:
        techIcons?.tech?.filter((el) =>
          mappedIconIds.includes(el.subjectTaxonomy?.[0]?.externalReference || el.name)
        ) ?? [],
    };
  }
  return {
    "ksp-sustainability": [],
    "ksp-technology": [],
    tech: [],
  };
};

export const mapProductsAFA = (
  pdpVariantsList: Variant[] | null,
  availability: AvailabilityStatusByDoor[],
  isMultidoor: boolean
): AFAVariantList[] => {
  if (pdpVariantsList && availability && availability.length > 0) {
    const variantList: AFAVariantList[] = [];

    //1) create variant object
    //CREA UN OGGETTO PER OGNI VARIANTE
    pdpVariantsList.forEach((_) => {
      const variantObj: AFAVariantList = {
        variantId: _.uniqueID,
        variant: _,
        availability: [],
      };

      variantList.push(variantObj);
    });

    //TODO se non abbiamo disponibilità?
    //2) create avaiability array

    //CREA UN OGGETTO PER OGNI AVAIABILITY UNIVOCA (DATA + STATUS + DOOR)
    const availabilitiesArray: AFAAvailability[] = [];

    //ELENCO DI SKU ID PER SINGOLA AVAIABILITY
    const availabilityWithSku: {
      [id: string]: { skuId: string; variantId?: string; quantity: string }[];
    } = {};

    if (availability) {
      availability.forEach((a) => {
        //GET ALL VALUES OF AVAILABILITY: COMBINATION OF DATE, AVAILABILITY AND STATUS FOR CURRENT DOOR
        const availabilityOfEachSku: AvailabilityInfoValue[] = [];

        if (a.availabilityInfo) {
          a.availabilityInfo.forEach((currentInfo) => {
            const checkForOutOfStock =
              isMultidoor || (!isMultidoor && currentInfo?.x_state !== "NOT_AVAILABLE");
            if (currentInfo && checkForOutOfStock) {
              availabilityOfEachSku.push(currentInfo);

              const id =
                currentInfo.x_state + "-" + currentInfo.availabilityDateTime + "-" + a.doorId; //ID THAT IDENTIFIES AVAIABILITY

              if (availabilityWithSku[id]) {
                availabilityWithSku[id].push({
                  skuId: currentInfo.uniqueId,
                  quantity: currentInfo.availableQuantity,
                });
              } else {
                availabilityWithSku[id] = [
                  {
                    skuId: currentInfo.uniqueId,
                    quantity: currentInfo.availableQuantity,
                  },
                ];
              }
            }
          });
        }

        //GET UNIQUE VALUES OF AVAILABILITY
        const uniqueAvailabilities = uniqWith(availabilityOfEachSku, (a, b) => {
          return a.availabilityDateTime === b.availabilityDateTime && a.x_state === b.x_state;
        });

        //CREATE OBJECT FOR EACH AVAILABILITY
        uniqueAvailabilities.forEach((_) => {
          const availabilityObj: AFAAvailability = {
            orgentityName: a.doorId,
            skus: [],
            firstShippingDate: _.x_startShipDate,
            avaiabilityDate: _.availabilityDateTime,
            availabilityStatus: _.x_state,
            availabilityId: _.x_state + "-" + _.availabilityDateTime + "-" + a.doorId,
          };

          availabilitiesArray.push(availabilityObj);
        });
      });
    }

    //3) create fitting array

    //CICLI PER AVAIABILITY E CREARE IL FITTING
    const availabilityPerVariant: { [variantId: string]: AFAAvailability[] } = {};
    availabilitiesArray.forEach((singleAvailability) => {
      const skusToSearch = availabilityWithSku[singleAvailability.availabilityId] ?? [];

      pdpVariantsList.forEach((_) => {
        const skus = _.skus;

        skus?.forEach((sku) => {
          //Controlli se sku è da inserire in quella availability
          const skuInfo = skusToSearch?.find((_: any) => _.skuId === sku.uniqueID);
          if (skuInfo) {
            const afaSku = {
              productAvailable: skuInfo.quantity,
              skuId: sku.uniqueID,
              sku: sku,
              sizeString: sku.sizeString ?? "",
            };

            if (!availabilityPerVariant[_.uniqueID]) {
              availabilityPerVariant[_.uniqueID] = [];
            }

            let index = availabilityPerVariant[_.uniqueID]?.findIndex(
              (_) => _.availabilityId === singleAvailability.availabilityId
            );

            if (index !== -1) {
              availabilityPerVariant[_.uniqueID][index].skus.push(afaSku);
            } else {
              availabilityPerVariant[_.uniqueID].push({ ...singleAvailability, skus: [afaSku] });
            }

            //ORDER SKU AFA
            if (index === -1)
              index = availabilityPerVariant[_.uniqueID]?.findIndex(
                (_) => _.availabilityId === singleAvailability.availabilityId
              );
            const orderedSize = orderAFASize(
              availabilityPerVariant[_.uniqueID][index].skus,
              "sizeString"
            );

            availabilityPerVariant[_.uniqueID][index].skus = orderedSize;
          }
        });
      });
    });

    const completedVariantList = variantList.map((_) => {
      //order avaiability
      const orderedAvailbility = orderAvailability(availabilityPerVariant[_.variantId]);

      return {
        ..._,
        availability: orderedAvailbility,
      };
    });
    return completedVariantList;
  }

  return [];
};

type OrderSizeKeys = keyof typeof orderSize;
const orderSize = {
  U: 0,
  CSTM: 0,
  LF: 0,
  ONE: 0,
  RT: 0,
  "4XS": 1,
  XXXS: 2,
  XXS: 3,
  XS: 4,
  "XS/S": 5,
  "XS-S": 5,
  S: 6,
  "S/M": 7,
  "S-M": 7,
  M: 8,
  "M/L": 9,
  L: 10,
  "L/XL": 11,
  "L-XL": 11,
  XL: 12,
  XXL: 13,
  XXXL: 14,
  XXXXL: 15,
  "4XL": 16,
  "5XL": 17,
  "6XL": 18,
  "7XL": 19,
};

//order AFA sizes
export const orderAFASize = (array: any[], propertyName: string): any[] => {
  return orderBy(array, (a: any) => {
    const sizeA: OrderSizeKeys = a[propertyName];
    let sizeOrder = orderSize[sizeA];

    if (!sizeOrder) {
      const number = parseInt(sizeA);
      sizeOrder = Number.isNaN(number) ? 100 : number; //PUT AT THE END IF IT IS A NEW SIZE
    }

    return sizeOrder;
  });
};

//Print quantity for AFA, per big numbers (> 100) print always 99+
export const printAvailableQuantity = (quantity: string): string => {
  const number = parseInt(quantity);

  if (Number.isNaN(number)) {
    return "quantity";
  }

  return number.toString();
};

export const checkIsAFAPLP = (currentCategory: CurrentCategoryPlp | null): boolean => {
  const categoryID = currentCategory?.slug;
  if (categoryID?.includes("apparel")) return true;
  else return false;
};

export const checkIsHelmetsOrGogglesPLP = (currentCategory: CurrentCategoryPlp | null): boolean => {
  const categoryID = currentCategory?.slug;
  const urlParams = new URLSearchParams(useLocation().search);
  const gogglesOrHelmets = urlParams.getAll("G_H_TYPE");
  if (categoryID?.includes("goggles-helmets"))
    return gogglesOrHelmets.length === 1 && ["H", "G"].includes(gogglesOrHelmets[0]);
  else return false;
};

export const checkisHelmetsGogglesPLP = (currentCategory: CurrentCategoryPlp | null): boolean => {
  const categoryID = currentCategory?.slug;
  const urlParams = new URLSearchParams(useLocation().search);
  const gogglesOrHelmets = urlParams.getAll("G_H_TYPE");
  if (categoryID?.includes("goggles-helmets")) {
    if (!urlParams.has("G_H_TYPE") || gogglesOrHelmets.length > 1) return true;
    else return false;
  } else return false;
};

export const checkIsAFAOrHelmet = (
  category: ProductCategory | ProductCategory[] | undefined
): boolean => {
  if (category && category.length > 0) {
    //This is an array of categories, which means we are in a PLP with mixed products, ex. Goggles+Helmets
    return category.includes("afa") || category.includes("helmet");
  }
  return category ? category === "afa" || category === "helmet" : false;
};

export const checkIsAFAFromAttributes = (attributes: Attribute[] | undefined): boolean => {
  const productType = getAttribute(attributes ?? [], "PRODUCT_TYPE");

  return !!productType?.values?.find(
    (_) =>
      _.identifier === "Afa Apparel" ||
      _.identifier === "Afa Footwear" ||
      _.identifier === "Afa Accessories"
  );
};

export const checkIsHelmetFromAttributes = (attributes: Attribute[] | undefined): boolean => {
  const productType = getAttribute(attributes ?? [], "PRODUCT_TYPE");

  return !!productType?.values?.find((_) => _.identifier === "Helmets");
};

export const checkIsAFAFootwearOrApparel = (attributes: Attribute[] | undefined): boolean => {
  const productType = getAttribute(attributes ?? [], "PRODUCT_TYPE");

  return !!productType?.values?.find(
    (_) => _.identifier === "Afa Apparel" || _.identifier === "Afa Footwear"
  );
};

export const checkIsAFAApparel = (attributes: Attribute[] | undefined): boolean => {
  const productType = getAttribute(attributes ?? [], "PRODUCT_TYPE");

  return !!productType?.values?.find((_) => _.identifier === "Afa Apparel");
};

export const checkIsAFAFootwear = (attributes: Attribute[] | undefined): boolean => {
  const productType = getAttribute(attributes ?? [], "PRODUCT_TYPE");

  return !!productType?.values?.find((_) => _.identifier === "Afa Footwear");
};

export const checkIsAFAAccessories = (attributes: Attribute[] | undefined): boolean => {
  const productType = getAttribute(attributes ?? [], "PRODUCT_TYPE");

  return !!productType?.values?.find((_) => _.identifier === "Afa Accessories");
};

export const filterNewSkus = (arrayOtherSkus: AFASku[], arraySku: OrderItem[]): AFASku[] => {
  return differenceWith(arrayOtherSkus, arraySku, (a, b) => {
    return a.sku.partNumber === b.skuCode;
  });
};

export const orderAvailability = (availability: AFAAvailability[]): AFAAvailability[] => {
  const sortedAvail = availability?.sort((a, b) => {
    //if avaiability is different, AVAILABLE is first
    if (a.availabilityStatus !== b.availabilityStatus) {
      if (a.availabilityStatus === "NOT_AVAILABLE") return 1;
      return a.availabilityStatus === "AVAILABLE" ? -1 : 1;
    }

    if (a.availabilityStatus === b.availabilityStatus) {
      if (a.availabilityStatus === "NOT_AVAILABLE") return 1;
      else return compareDay(new Date(a.avaiabilityDate), new Date(b.avaiabilityDate), "asc");

      // if (a.availabilityStatus === "AVAILABLE") return -1;

      // if (a.availabilityStatus === "PREORDER") {
      //   return compareDay(new Date(a.avaiabilityDate), new Date(b.avaiabilityDate), "asc");
      // }
    }
    return -1;
  });
  return sortedAvail;
};
