import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";
import { LanguageContext } from "../../language/languageContext";

import {
  selectSupportedLanguages,
  selectPublicSupportedLanguages,
} from "../../store/store/storeSlice";
import { parseUrlInfo, replaceLocaleInUrl, isPublicPage } from "../../routing/routesUtils";

import CustomSelect from "../styled-UI/CustomSelect";
import _ from "lodash";
import { localeToLanguageLabel } from "../../utils/languageUtils";

const LanguageSelector = (): JSX.Element => {
  const { translateLabel } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const supportedLanguages = isPublicPage()
    ? useSelector(selectPublicSupportedLanguages)
    : useSelector(selectSupportedLanguages);

  const [localeFromUrl, setLocaleFromUrl] = useState({ value: "", label: "" });

  const { labels } = useContext(LanguageContext);

  useEffect(() => {
    const site = parseUrlInfo();
    if (site.locale && labels)
      setLocaleFromUrl({
        value: site.locale,
        label: translateLabel(localeToLanguageLabel(site.locale)),
      });
  }, [location, labels]);

  const supportedLanguagesList = supportedLanguages.map((lang) => {
    return { value: lang.locale, label: translateLabel(localeToLanguageLabel(lang.locale)) };
  });

  const sortedSupportedLanguagesList = _.orderBy(
    supportedLanguagesList,
    ["label"],
    ["asc", "desc"]
  );

  return (
    <SelectWrapperFooter>
      <CustomSelect
        type="dark"
        defaultValue={localeFromUrl}
        value={localeFromUrl}
        options={sortedSupportedLanguagesList}
        onChange={(locale) => {
          history.push(replaceLocaleInUrl(locale.value as string));
          window.location.reload();
        }}
      ></CustomSelect>
    </SelectWrapperFooter>
  );
};

const SelectWrapperFooter = styled.div`
  width: 15rem;
  height: 2.5rem;
  display: inline-block;
`;

export default LanguageSelector;
