import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Item, Page } from "../interfaces/gridInterfaces";
import { getPartialPagePath, parseUrlInfo } from "../routing/routesUtils";
import { selectItemsMap, selectPagesLayout } from "../store/store/storeSlice";

const useStickyBarLp = (layoutVariant: string): { navbar: Item[] } => {
  //ITEMS
  const itemsMap = useSelector(selectItemsMap);
  //LAYOUT
  const pagesLayout: { [page: string]: Page } = useSelector(selectPagesLayout);
  const siteInfo = parseUrlInfo();
  const { pageName } = useParams() as {
    pageName: string;
  };
  const partialPagePath = getPartialPagePath(siteInfo.pageType ?? "", pageName);
  const layout = pagesLayout[partialPagePath];

  //NAV ELEMENTS
  const [navbar, setNavbar] = useState<Item[]>([]);

  useEffect(() => {
    if (layout !== undefined && Object.keys(itemsMap).length > 0) {
      const newNavbar: Item[] = [];
      layout?.rows?.map((row) => {
        row?.cols?.map((item: Col) => {
          if (item && item.id && itemsMap[item.id]) {
            const singleItem = itemsMap[item.id];
            if (singleItem?.layoutVariant?.name === layoutVariant) {
              newNavbar.push(singleItem);
            }
          }
        });
      });

      setNavbar(newNavbar);
    }
  }, [itemsMap, layout]);

  return { navbar: navbar };
};

export default useStickyBarLp;
