import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import _ from "lodash";

import useStatelessService from "../../../store/statelessServices/useStatelessService";
import statelessServices from "../../../store/statelessServices/statelessServices";
import { CountryOptions } from "../../../store/store/storeInterfaces";
import { selectCountries } from "../../../store/store/storeSlice";
import { getCountries } from "../../../store/store/storeSagas";
import { sendAnalyticsData } from "../../../store/analytics/analyticsService";
import { emailRegExp, mapTranslatedKeys, phoneRegExp } from "../../../utils/utils";
import CustomSelect, { CustomOptions } from "../../styled-UI/CustomSelect";
import InputComponent from "../../styled-UI/InputComponent";
import CustomText from "../../styled-UI/CustomText";
import Button from "../../styled-UI/Button";
import Popup from "../../styled-UI/Popup";

import { ReactComponent as CheckIcon } from "../../../assets/icons/circle-check-icon.svg";
import useReCaptchav3 from "../../../hooks/useReCaptchav3";

const subjectOptions = [
  "NEED_HELP_SUBJECT_LUXOTTICA_MYLUXOTTICA_CLIENT",
  "NEED_HELP_SUBJECT_LUXOTTICA_CLIENT",
  "NEED_HELP_SUBJECT_NOT_LUXOTTICA_CLIENT",
];

interface CustomSchema {
  required: SchemaValues;
  valid: SchemaValues;
}

interface SchemaValues {
  check: (value: string | CustomOptions | CountryOptions | undefined) => boolean;
  label: string;
}

type FormValues = keyof typeof CUSTOM_SCHEMA;

// custom schema that allows to check for each field if it respects its requirements (requiredness and validity)
// for each it has a check() function that returns true if the requirement is respected
// and a label to show if it's not
const CUSTOM_SCHEMA: { [key: string]: CustomSchema } = {
  subject: {
    required: {
      check: (value) => !!value,
      label: "REQUIRED_FIELD",
    },
    valid: {
      check: (value) => !!value,
      label: "REQUIRED_FIELD",
    },
  },
  customerCode: {
    required: {
      check: (value) => !!value,
      label: "REQUIRED_FIELD",
    },
    valid: {
      check: (value) => (value as string).length >= 6 && (value as string).length <= 10,
      label: "NEED_HELP_INVALID_CUSTOMER_CODE",
    },
  },
  country: {
    required: {
      check: (value) => !!value,
      label: "REQUIRED_FIELD",
    },
    valid: {
      check: (value) => !!value,
      label: "REQUIRED_FIELD",
    },
  },
  email: {
    required: {
      check: (value) => !!value,
      label: "REQUIRED_FIELD",
    },
    valid: {
      check: (value) => emailRegExp.test(value as string),
      label: "NEED_HELP_INVALID_EMAIL",
    },
  },
  phone: {
    required: {
      check: (value) => !!value,
      label: "REQUIRED_FIELD",
    },
    valid: {
      check: (value) => phoneRegExp.test(value as string) && (value as string).length <= 15,
      label: "NEED_HELP_INVALID_PHONE",
    },
  },
  message: {
    required: {
      check: (value) => !!value,
      label: "NEED_HELP_REQUIRED_MESSAGE",
    },
    valid: {
      check: (value) => (value as string).length <= 500,
      label: "NEED_HELP_MAX_CHARACTER",
    },
  },
};

// checks first if the value respects its requiredness and THEN its validity
// if any of those checks are not respected, then it returns the corresponding error label
// otherwise an empty string
const isFieldInvalid = (
  type: FormValues,
  value: string | CustomOptions | CountryOptions | undefined
): string => {
  if (!CUSTOM_SCHEMA[type].required.check(value)) return CUSTOM_SCHEMA[type].required.label;
  if (!CUSTOM_SCHEMA[type].valid.check(value)) return CUSTOM_SCHEMA[type].valid.label;
  return "";
};

const NeedHelp = (): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const { requestStatus, makeRequest, resetRequestState } = useStatelessService();

  const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptchav3();

  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  //////////////////////// subject
  const [subjectSelected, setSubjectSelected] = useState<CustomOptions | null>();
  const subjectOptionsTranslated = mapTranslatedKeys(subjectOptions, translateLabel);

  //////////////////////// customerCode
  const [customerCodeSelected, setCustomerCodeSelected] = useState<string>("");

  const isCustomerCodeRequired =
    (subjectSelected === null || subjectSelected?.value) != subjectOptions[2];

  //////////////////////// country
  const [countrySelected, setCountrySelected] = useState<CountryOptions | null>();

  const countries = useSelector(selectCountries);
  const countriesSortedList = _.sortBy(countries, "description").map((country) => {
    return {
      defaultLanguage: country.defaultLanguage,
      description: country.description,
      identifier: country.identifier,
      label: translateLabel(
        "CONTACT_US_STORE_" + country.identifier?.toUpperCase().replace("-", "_")
      ),
      locale: country.locale,
      storeId: country.storeId,
      value: country.value,
    };
  });

  useEffect(() => {
    if (countries.length === 0) dispatch(getCountries());
  }, [countries]);

  //////////////////////// email
  const [emailSelected, setEmailSelected] = useState<string>("");

  //////////////////////// phone
  const [phoneSelected, setPhoneSelected] = useState<string>("");

  //////////////////////// message
  const [messageSelected, setMessageSelected] = useState<string>("");

  //////////////////////// handle submit

  const isAnyFieldInvalid = () => {
    return (
      !!isFieldInvalid("subject", subjectSelected ?? "") ||
      (isCustomerCodeRequired && !!isFieldInvalid("customerCode", customerCodeSelected)) ||
      !!isFieldInvalid("country", countrySelected ?? "") ||
      !!isFieldInvalid("email", emailSelected) ||
      !!isFieldInvalid("phone", phoneSelected) ||
      !!isFieldInvalid("message", messageSelected)
    );
  };

  const onSubmit = () => {
    if (isAnyFieldInvalid()) {
      setShowErrors(true);
      return;
    }

    if (
      !!subjectSelected &&
      (!isCustomerCodeRequired || (!!customerCodeSelected && isCustomerCodeRequired)) &&
      !!countrySelected &&
      !!emailSelected &&
      !!phoneSelected &&
      !!messageSelected
    ) {
      generateReCaptchaToken("contactUs", (token: string) => {
        const newEmail = {
          locale: countrySelected.locale,
          customerCode: !isCustomerCodeRequired ? "" : customerCodeSelected,
          email: emailSelected,
          message: messageSelected,
          object: subjectSelected.label || subjectOptionsTranslated[0].label,
          phone: phoneSelected,
          storeIdentifier: countrySelected.identifier,
          captcha: token,
        };

        makeRequest(statelessServices.postNeedHelp, newEmail);

        sendAnalyticsData({
          id: "Error",
          Error_Source: "User",
          Error_Code: "Support Request Submitted",
        });
      });
    }
  };

  useEffect(() => {
    if (requestStatus.status === "SUCCESS" || requestStatus.status === "ERROR") {
      if (
        requestStatus.status === "SUCCESS" &&
        !(requestStatus?.data && JSON.stringify(requestStatus?.data)?.includes("rejected"))
      ) {
        setIsSuccess(true); // set state to show correct labels
        resetForm(); // reset form when request is successful
      }

      setShowPopup(true); // show popup when request is successful or has failed
      resetRequestState(); // reset request when it's finished (either successfully or not)
    }
  }, [requestStatus.status]);

  const resetForm = () => {
    setSubjectSelected(null);
    setCustomerCodeSelected("");
    setCountrySelected(null);
    setEmailSelected("");
    setPhoneSelected("");
    setMessageSelected("");
    setShowErrors(false);
  };

  const closePopupAndReset = () => {
    setShowPopup(false);
    setIsSuccess(false);
    resetRequestState();
  };

  return (
    <ContactUsForm>
      <CustomText as="h1" color="primary" fontSizePx={32} marginBottomPx={16} lineHeightPx={32}>
        {translateLabel("NEED_HELP_TITLE")}
      </CustomText>

      <CustomText as="span" color="primary" fontSizePx={12} marginBottomPx={12} font="font-regular">
        {translateLabel("NEED_HELP_SUBTITLE")}
      </CustomText>

      <Grid>
        <ItemGrid1>
          <CustomSelect
            options={subjectOptionsTranslated}
            value={subjectSelected}
            onChange={setSubjectSelected}
            placeholder={translateLabel("NEED_HELP_SUBJECT")}
            {...(showErrors && {
              isError: !!isFieldInvalid("subject", subjectSelected ?? ""),
              helperText: translateLabel(isFieldInvalid("subject", subjectSelected ?? "")),
            })}
            required
            type="white"
          />
        </ItemGrid1>

        <ItemGrid2>
          <InputComponent
            value={!isCustomerCodeRequired ? "" : customerCodeSelected}
            onChange={(e) => setCustomerCodeSelected(e.target.value)}
            placeholder={translateLabel("NEED_HELP_CUSTOMER_CODE")}
            {...(showErrors &&
              isCustomerCodeRequired && {
                colorStyle: !!isFieldInvalid("customerCode", customerCodeSelected)
                  ? "error"
                  : "enabled",
                helperText: translateLabel(isFieldInvalid("customerCode", customerCodeSelected)),
              })}
            disabled={!isCustomerCodeRequired}
            required={isCustomerCodeRequired}
            maxLength={10}
            type="text"
          />
        </ItemGrid2>

        <ItemGrid3>
          <CustomSelect
            options={countriesSortedList}
            value={countrySelected}
            onChange={(e) => setCountrySelected(e as CountryOptions)}
            placeholder={translateLabel("NEED_HELP_SELECT_COUNTRY")}
            {...(showErrors && {
              isError: !!isFieldInvalid("country", countrySelected ?? ""),
              helperText: translateLabel(isFieldInvalid("country", countrySelected ?? "")),
            })}
            required
            type="white"
          />
        </ItemGrid3>

        <ItemGrid4>
          <InputComponent
            value={emailSelected}
            onChange={(e) => setEmailSelected(e.target.value)}
            placeholder={translateLabel("NEED_HELP_EMAIL")}
            {...(showErrors && {
              colorStyle: !!isFieldInvalid("email", emailSelected) ? "error" : "enabled",
              helperText: translateLabel(isFieldInvalid("email", emailSelected)),
            })}
            required
            type="text"
          />
        </ItemGrid4>

        <ItemGrid5>
          <div>
            <InputComponent
              value={phoneSelected}
              onChange={(e) => setPhoneSelected(e.target.value)}
              placeholder={translateLabel("NEED_HELP_TELEPHONE")}
              {...(showErrors && {
                colorStyle: !!isFieldInvalid("phone", phoneSelected) ? "error" : "enabled",
                helperText: translateLabel(isFieldInvalid("phone", phoneSelected)),
              })}
              required
              maxLength={15}
              type="text"
            />
          </div>
        </ItemGrid5>

        <ItemGrid6>
          <InputComponent
            value={messageSelected}
            onChange={(e) => setMessageSelected(e.target.value)}
            placeholder={translateLabel("NEED_HELP_INSERT_MESSAGE")}
            {...(showErrors && {
              colorStyle: !!isFieldInvalid("message", messageSelected) ? "error" : "enabled",
              helperText: translateLabel(isFieldInvalid("message", messageSelected)),
            })}
            required
            type="text"
            as="textarea"
          />
        </ItemGrid6>
      </Grid>

      <Button
        fullWidth
        type="primary"
        onClick={() => onSubmit()}
        disabled={requestStatus.status === "LOADING" || !reCaptchaLoaded}
      >
        {translateLabel("NEED_HELP_SEND")}
      </Button>
      {showPopup && (
        <Popup
          title={
            isSuccess
              ? translateLabel("NEED_HELP_POPUP_SUCCESS_TITLE")
              : translateLabel("NEED_HELP_POPUP_ERROR_TITLE")
          }
          isOpen
          close={() => closePopupAndReset()}
        >
          <PopupContent>
            <CustomText as="span" font="font-bold" fontSizePx={18}>
              {isSuccess && (
                <IconContainer>
                  <CheckIcon />
                </IconContainer>
              )}
              {isSuccess
                ? translateLabel("NEED_HELP_POPUP_SUCCESS_BODY")
                : translateLabel("NEED_HELP_POPUP_ERROR_BODY")}
            </CustomText>
          </PopupContent>
        </Popup>
      )}
    </ContactUsForm>
  );
};

const ContactUsForm = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
  padding: 3rem;
  min-width: 33.625rem;
  max-width: 40.625rem;
  min-height: 26.63rem;
  box-sizing: border-box;
`;

const Grid = styled.div`
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: auto;
  grid-template-rows: minmax(3.5rem, auto) minmax(3.5rem, auto) minmax(3.5rem, auto) 8.125rem;
  grid-template-areas:
    "problems problems"
    "customerCode country"
    "mail phone"
    "message message";
`;

const ItemGrid1 = styled.div`
  grid-area: problems;
`;

const ItemGrid2 = styled.div`
  grid-area: customerCode;
`;

const ItemGrid3 = styled.div`
  grid-area: country;
`;

const ItemGrid4 = styled.div`
  grid-area: mail;
`;

const ItemGrid5 = styled.div`
  grid-area: phone;
`;

const ItemGrid6 = styled.div`
  grid-area: message;
  margin-bottom: 1rem;
`;

const PopupContent = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 3rem;
`;

const IconContainer = styled.div`
  margin-right: 1.5rem;
  svg {
    height: 3rem;
    width: 3rem;
  }
`;

export default NeedHelp;
