import clsx from "clsx";
import React, { useEffect } from "react";
import styled from "styled-components/macro";

interface Props {
  children: any;
  maxWidth?: string;
  minWidth?: string;
  isNavbar?: boolean;
  width100Sm?: boolean; //set width to 100% in media query sm
  landingNavbar?: boolean;
  isWrappedLanding?: boolean;
}

const WrapperLayout = ({
  children,
  maxWidth,
  minWidth,
  isNavbar = false,
  width100Sm = false,
  landingNavbar = false,
  isWrappedLanding = false,
}: Props): JSX.Element => {
  const isLandingPage = window.location.href.includes("contents");

  return (
    <Wrapper
      className={clsx(
        "print-ready",
        isLandingPage && !isNavbar && !landingNavbar && "wrapper-min-width",
        width100Sm && "wrapper-width-100"
      )}
      maxWidth={maxWidth}
      minWidth={minWidth}
      isWrappedLanding={isWrappedLanding}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<Props>`
  min-width: ${(props) =>
    !props.isWrappedLanding && (props.minWidth || props.maxWidth || props.theme.minWrapperWidth)};
  max-width: ${(props) => props.maxWidth || props.theme.maxWrapperWidth};
  margin: 0 auto;
  height: 100%;
  padding: 0 2.5rem;

  @media ${(props) => props.theme.queries.md} {
    padding: 0rem 1rem;
    max-width: ${(props) => props.theme.maxWrapperWithMD}; //100%;
    margin: 0 auto;

    &.wrapper-width-100 {
      max-width: 100%;
      padding: 0 0 0 1rem;
    }
  }

  /* &.wrapper-min-width {
    //min-width: 1440px;
  } */
`;

export default WrapperLayout;
