import React from "react";
import styled from "styled-components/macro";
import { MemoizedLoaderTileWithBackgroud } from "./LoaderTile";
import LoaderElement from "./LoaderElement";
import LoaderElementCircle from "./LoaderElementCircle";

interface Props {
  slidesToShow?: number;
}

const LoaderCarousel = ({ slidesToShow = 4 }: Props): JSX.Element => (
  <Row>
    {Array.from(Array(slidesToShow).keys()).map((_, i) => (
      <MemoizedLoaderTileWithBackgroud key={i.toString()} heightPx={648} />
    ))}
  </Row>
);

export const LoaderCarouselWithTitle = (): JSX.Element => {
  return (
    <CarouselWrapper>
      <Title>
        <LoaderElement height={50} width={350} />
        <LoaderElement height={30} width={200} />
      </Title>
      <LoaderCarousel />
      <Arrows>
        <LoaderElementCircle height={50} width={50} radius={25} />
        <LoaderElementCircle height={50} width={50} radius={25} />
      </Arrows>
    </CarouselWrapper>
  );
};
1;

const Row = styled.div`
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Arrows = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
  margin-top: 1rem;
`;

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export default LoaderCarousel;
