import React from "react";
import styled from "styled-components/macro";

import useStickyBar from "../../../../hooks/useStickyBarLp";
import useTranslation from "../../../../hooks/useTranslation";

import LinkButton from "../../../styled-UI/LinkButton";
import ScrollToButton from "../../ScrollToButton";
import { FontFamilies } from "../../../../store/store/storeInterfaces";

interface Props {
  customFont?: FontFamilies | null;
}

const LpStickyMenuCentral = ({ customFont = null }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const { navbar } = useStickyBar("LP-logo-text");

  return (
    <StickyMenu>
      {navbar &&
        navbar.length > 0 &&
        navbar.map((_) => (
          <ScrollToButton key={_.id} toId={_.id}>
            <LinkButton as="div" underline={false} customFont={customFont}>
              {translateLabel(_.content.brand[0].externalReference + "_LABEL")}
            </LinkButton>
          </ScrollToButton>
        ))}
    </StickyMenu>
  );
};
const StickyMenu = styled.div`
  width: 100%;
  display: flex;
  gap: 2.5rem;
  justify-content: center;
  padding: 1.5rem 1.125rem;
`;
export default LpStickyMenuCentral;
