import React, { useEffect, useRef } from "react";
import styled from "styled-components/macro";
import FlipEditorialTile from "./FlipSocialProductTile";
import EditorialTileContent from "./SocialProductTileContent";
import { SocialProductTile as Interface } from "../../../interfaces/cmsInterfaces";
import { useSelector } from "react-redux";
import { selectStickyBarType } from "../../../store/store/storeSlice";
import { showTileCms } from "../../../utils/cmsUtils";

interface Props {
  data: Interface;
  position?: number;
  rowNumber?: number;
}

const SocialProductTile = ({ data, position, rowNumber }: Props): JSX.Element => {
  const stickyBarType = useSelector(selectStickyBarType);
  const ref: any = useRef();

  useEffect(() => {
    if (data.model) {
      const showTile = showTileCms(stickyBarType, data.model);
      if (showTile === false) {
        ref.current.parentElement.parentElement.style = "display: none";
      } else {
        ref.current.parentElement.parentElement.style = "display: block";
      }
    }
  }, [stickyBarType]);

  return (
    <div ref={ref}>
      {data.cover ? (
        <FlipEditorialTile data={data} />
      ) : (
        <OuterTile>
          <EditorialTileContent data={data} position={position} rowNumber={rowNumber} />
        </OuterTile>
      )}
    </div>
  );
};

export default SocialProductTile;

const OuterTile = styled.div`
  width: 19.94rem;
  height: 30.63rem;
  background-color: white;
  padding: 1.5rem 0.125rem 2.875rem 0.1875rem;
`;
