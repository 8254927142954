import React from "react";
import styled from "styled-components/macro";

import LoaderElement from "../LoaderElement";
import SeeAllVariantLoader from "./SeeAllVariantLoader";

import { TileStyle } from "../LoaderTile";

interface Props {
  style: TileStyle;
}

const SeeAllLoader = ({ style }: Props): JSX.Element => {
  return (
    <SeeAll className={style}>
      {style === "default" && (
        <Column>
          <LoaderElement height={30} width={50} />
          <LoaderElement height={30} width={60} />
        </Column>
      )}
      <Column>
        <SeeAllVariantLoader />
      </Column>
    </SeeAll>
  );
};

const SeeAll = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem 0;

  &.default {
    border-top: 1px solid ${(props) => props.theme.palette.gray.medium};
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
  }
`;

const Column = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`;

export default SeeAllLoader;
