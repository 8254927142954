import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components/macro";

import { Col, Item } from "../../interfaces/gridInterfaces";
import {
  saveRequestedFonts,
  selectItemsMap,
  selectRequestedFonts,
} from "../../store/store/storeSlice";
import { cmMetaData } from "../../utils/previewUtils";
import { getPagePath } from "../../routing/routesUtils";
import { MemoizedLoaderHomeTile } from "../styled-UI/loader/home-page/LoaderHomeTile";
import PLPTopBanner from "../widgets/PLP/PLPTopBanner";
import { MemoizedPromotionsCarousel } from "../widgets/promotions-carousel/PromotionsCarousel";
import { MemoizedHighlightsSection } from "../widgets/highlights-section/HighlightsSection";
import { MemoizedHomepageNews } from "../widgets/HomepageNews";
import PrePLPLensesSection from "../widgets/PrePLPLensesSection";
import LatestTrendSection from "../widgets/LatestTrendsSection";
import DangerouslySetHtmlContent from "../widgets/DangerouslySetHtmlContent";
import BannerLandingPage from "../widgets/BannerLandingPage";
import LpTitleText from "../widgets/LpTitleText";
import SocialProductTile from "../widgets/social-product-tile/SocialProductTile";
import LpTitle from "../widgets/LpTitle";
import EditorialProductTile from "../widgets/EditorialProductTile";
import LpText from "../widgets/LpText";
import { MemoizedLpCatalogueProductTile } from "../widgets/LpCatalogueProductTile";
import LpCarouselBanner from "../widgets/LpCarouselBanner";
import { MemoizedLpCta } from "../widgets/LpCta";
import LpSingleText from "../widgets/LpSingleText";
import BestSellersContainer from "../widgets/BestSellersContainer";
import LpTextualBanner from "../widgets/LpTextualBanner";
import LpBasicBanner from "../widgets/LpBasicBanner";
import LpInformationSheet from "../widgets/LpInformationSheet/LpInformationSheet";
import LpBannerQrCode from "../widgets/LpBannerQrCode";
import BulletPointList from "../widgets/BulletPointList";
import LpTextList from "../widgets/LpTextList/LpTextList";
import LpVideoBanner from "../widgets/LpVideoBanner";
import LpLogoText from "../widgets/landing-page-widgets/LpLogoText";
import LpHorizontalTitleText from "../widgets/landing-page-widgets/LpHorizontalTitleText";
import LpTextAndColourProductTile from "../widgets/landing-page-widgets/LpTextAndColourProductTile";
import LpStickyMenuLeft from "../widgets/landing-page-widgets/landing-page-sticky-bar/LpStickyMenuLeft";
import LpStickyMenuCentral from "../widgets/landing-page-widgets/landing-page-sticky-bar/LpStickyMenuCentral";
import LpColourProductTile from "../widgets/landing-page-widgets/LpColourProductTile";
import OptionCard from "../widgets/OptionCard";
import IconCardVertical from "../widgets/icon-card/IconCardVertical";
import IconCardHorizontal from "../widgets/icon-card/IconCardHorizontal";
import LpTitleCard from "../widgets/TitleCard";
import ShoppableImage from "../widgets/shoppable-image/ShoppableImage";
import FeatureCarouselHorizontal from "../widgets/feature-carousel-horizontal/FeatureCarouselHorizontal";
import FeatureCarouselVertical from "../widgets/feature-carousel-vertical/FeatureCarouselVertical";
import LpHeroVideoFull from "../widgets/LpHeroVideoFull";
import LensShowcase from "../widgets/LensShowcase";
import NavigationTabVertical from "../widgets/NavigationTabVertical";
import NavigationTabHorizontal from "../widgets/navigation-tab/NavigationTabHorizontal";
import LogoShowcase from "../widgets/LogoShowcase";
import SideAnchor from "../widgets/SideAnchor";
import { FontFamilies } from "../../store/store/storeInterfaces";
import { searchFontFamily } from "../../utils/utils";

interface Props {
  item: Col;
  position?: number;
  rowNumber?: number;
  setRowBackgroundColor?: (backgroundColor: string) => void;
  setRowBackgroundImage?: (backgroundImage: string) => void;
  setRowIsDarkMode?: (isDarkMode: boolean) => void;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const ItemComponent = ({
  item,
  position,
  rowNumber,
  setRowBackgroundColor,
  setRowBackgroundImage,
  setRowIsDarkMode,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const itemsMap = useSelector(selectItemsMap);
  const history = useHistory();
  const dispatch = useDispatch();
  const requestedFonts = useSelector(selectRequestedFonts);

  useEffect(() => {
    if (customFont) {
      if (!requestedFonts?.some((_) => _ === customFont)) {
        if (requestedFonts) {
          dispatch(saveRequestedFonts(customFont ? [...requestedFonts, customFont] : null));
        } else {
          dispatch(saveRequestedFonts(customFont ? [customFont] : null));
        }
      }
    }
  }, [customFont, requestedFonts]);

  React.useEffect(() => {
    function messageHandler(event: any) {
      if (event.data == "leonardo") {
        window.open(globalEnvVariables.leonardoUrl, "_blank");
      } else if (event.data == "rx-prescription") {
        history.push(getPagePath("/rx-prescription?rxFlow=Authentics"));
      }
    }
    window.addEventListener("message", messageHandler);
    return () => window.removeEventListener("message", messageHandler);
  }, []);

  const setBackgroundAndFont = (item: any): void => {
    //The first item of the row defines the background color, image, and font of the row
    if (item) {
      if (item.backgroundImage && setRowBackgroundImage) {
        setRowBackgroundImage(
          item.backgroundImage[0].data.uri ? `url(${item.backgroundImage[0].data.uri})` : ""
        );
      }
      if (item.backgroundColour && setRowBackgroundColor) {
        setRowBackgroundColor(item.backgroundColour);
      }
      if (item.font) {
        customFont = searchFontFamily(item.font);
      }
      if (item.subjectTaxonomy && setRowIsDarkMode) {
        const isRowDarkMode = item.subjectTaxonomy.some(
          (_: any) => _.externalReference === "dark-mode"
        );
        setRowIsDarkMode(isRowDarkMode);
      }
    }
  };

  const Content = useCallback(() => {
    if (item && item.id && itemsMap[item.id]) {
      const singleItem = itemsMap[item.id];
      const itemContent =
        singleItem?.result?.length > 0 ? singleItem?.result : [singleItem?.content];

      //BackgroundImage, backgroundColor, font, and darkMode
      if (itemContent) {
        setBackgroundAndFont(itemContent);
      }
      //Sometimes the background comes in the content variable, if it doesn't nothing changes
      if (singleItem?.content) {
        setBackgroundAndFont(singleItem?.content);
      }
      //In case of a collection, we find the information in the first item of the collection
      if (itemContent[0]) {
        setBackgroundAndFont(itemContent[0]);
      }

      if (item.type === "CMHTML" && singleItem) {
        if (singleItem.html) return <DangerouslySetHtmlContent html={singleItem.html} />;
        if (singleItem.content.teaserTitle)
          return (
            <iframe
              style={{
                height: `${
                  singleItem.content.description ? singleItem.content.description : "100vh"
                }`,
                width: "100%",
              }}
              src={singleItem.content.teaserTitle}
            />
          );
      }

      if (item.type === "CMRCInstagramLink") {
        return (
          <SocialProductTile data={singleItem.content} position={position} rowNumber={rowNumber} />
        );
      }

      // ----- CHOOSE ITEM LAYOUT
      switch (singleItem?.layoutVariant?.name) {
        case "Promotions":
          return (
            <MemoizedPromotionsCarousel
              autoplay
              autoplaySpeed={5000}
              arrows
              slides={singleItem.result}
              customFont={customFont}
            />
          );
        case "Highlights":
          return <MemoizedHighlightsSection slides={singleItem.result} customFont={customFont} />;
        case "News":
          return (
            <MemoizedHomepageNews
              news={singleItem.result}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Latest-Trends":
          return (
            <LatestTrendSection
              trendCarousel={singleItem}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-carousel-banner":
        case "Lp-carousel-banner-half":
          return <LpCarouselBanner data={itemContent} layout={singleItem.layoutVariant} />;

        case "Lp-cover-banner-1":
        case "Lp-cover-banner-2":
        case "Lp-cover-banner-3":
          return <BannerLandingPage bannerData={singleItem.content} customFont={customFont} />;
        case "Lp-titletext-h1":
          return (
            <LpTitleText
              data={singleItem.content}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-title":
          return (
            <LpTitle data={singleItem.content} isDarkMode={isDarkMode} customFont={customFont} />
          );
        case "Lp-text":
          return (
            <LpText data={singleItem.content} isDarkMode={isDarkMode} customFont={customFont} />
          );
        case "Lp-editorial-product-tile-1":
        case "Lp-editorial-product-tile-2":
        case "Lp-editorial-product-tile-3":
        case "Lp-editorial-product-tile-4":
          return (
            <EditorialProductTile
              data={singleItem.content}
              position={position}
              rowNumber={rowNumber}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-catalogue-product-tile":
          return (
            <MemoizedLpCatalogueProductTile
              data={singleItem.content}
              position={position}
              rowNumber={rowNumber}
              customFont={customFont}
            />
          );
        case "Lp-single-text":
          return (
            <LpSingleText
              data={singleItem.content}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "LP-cta":
          return (
            <MemoizedLpCta
              data={singleItem.content}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-icon-card-h":
          return (
            <IconCardHorizontal
              data={singleItem.content}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-feature-carousel-h":
          return (
            <FeatureCarouselHorizontal
              items={singleItem.result}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-feature-carousel-v":
          return (
            <FeatureCarouselVertical
              items={singleItem.result}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-lens-showcase":
          return (
            <LensShowcase
              items={singleItem.result}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-navigation-tab-h":
          return (
            <NavigationTabHorizontal
              items={singleItem.result}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-navigation-tab-v":
          return (
            <NavigationTabVertical
              items={singleItem.result}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-hero-video":
          return (
            <LpHeroVideoFull
              data={singleItem.content}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-navigation-logo-h":
          return (
            <LogoShowcase
              logoTabs={singleItem.result}
              layout={"tabs"}
              isDarkMode={isDarkMode}
              customFont={customFont}
            ></LogoShowcase>
          );
        case "Lp-logo-carousel":
          return (
            <LogoShowcase
              logoTabs={singleItem.result}
              layout={"carousel"}
              isDarkMode={isDarkMode}
            ></LogoShowcase>
          );
        case "Lp-navigation-menu-v":
          return (
            <SideAnchor
              data={singleItem.result}
              headerTitle={singleItem.collectionTitle || ""}
              isDarkMode={isDarkMode}
              customFont={customFont}
            ></SideAnchor>
          );
      }

      switch (itemContent?.[0]?.layoutVariant?.[0]?.name) {
        case "Preplp-header-1":
        case "Plp-header":
          return <PLPTopBanner bannerData={itemContent?.[0]} customFont={customFont} />;
        case "Preplp-mid-banner":
          return <PrePLPLensesSection data={itemContent?.[0]} customFont={customFont} />;
        case "Lp-link-banner":
          return <LpBasicBanner size="full" data={itemContent?.[0]} customFont={customFont} />;
        case "Lp-link-banner-half":
          return <LpBasicBanner size="small" data={itemContent?.[0]} customFont={customFont} />;
        case "Lp-walkthrough":
          return (
            <LpTextList data={singleItem.content} isDarkMode={isDarkMode} customFont={customFont} />
          );
        case "Lp-info-card":
          return (
            <LpInformationSheet
              data={singleItem.content}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-list":
          return (
            <BulletPointList
              data={singleItem.content}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-Text-QR Code":
          return (
            <LpBannerQrCode
              data={singleItem.content}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-texual-banner-center":
          return (
            <LpTextualBanner
              position="center"
              data={singleItem.content}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-texual-banner-left":
          return (
            <LpTextualBanner
              position="left"
              data={singleItem.content}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "LP-logo-text":
          return (
            <LpLogoText
              data={itemContent?.[0]}
              id={itemContent?.[0].id}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-video-banner":
          return <LpVideoBanner size="full" data={itemContent?.[0]} customFont={customFont} />;
        case "Lp-video-banner-half":
          return <LpVideoBanner size="small" data={itemContent?.[0]} customFont={customFont} />;
        case "Lp-titletext-h2":
          return (
            <LpHorizontalTitleText
              data={itemContent?.[0]}
              id={itemContent?.[0].id}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "LP-title-text-vertical":
          return (
            <LpTextAndColourProductTile
              data={itemContent?.[0]}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-stickymenu-left":
          return <LpStickyMenuLeft customFont={customFont} />;
        case "Lp-stickymenu-central":
          return <LpStickyMenuCentral customFont={customFont} />;
        case "Lp-colour-variants-product-tile":
          return (
            <LpColourProductTile
              data={itemContent?.[0]}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        case "Lp-option-card":
          return (
            <OptionCard data={itemContent?.[0]} isDarkMode={isDarkMode} customFont={customFont} />
          );
        case "Lp-title-card":
          return (
            <LpTitleCard data={itemContent?.[0]} isDarkMode={isDarkMode} customFont={customFont} />
          );
        case "Lp-icon-card-v":
          return (
            <IconCardVertical data={itemContent} isDarkMode={isDarkMode} customFont={customFont} />
          );
        case "Lp-shoppable-image":
          return (
            <ShoppableImage
              data={itemContent?.[0]}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
      }

      // ----- FALLBACK: print empty element
      return <div data-item-id={item.id}></div>;
    }

    // ----- SPECIAL CASE: print bestseller
    if (item && item.name === "Best Sellers Placeholder") {
      setBackgroundAndFont(item);
      return (
        <BestSellersContainer
          bestsellerHomepage
          enableDataDescriptionNavigation
          isDarkMode={isDarkMode}
          customFont={customFont}
        />
      );
    }

    // ----- UNTIL CONTENT IS LOADED: print loader
    return (
      <div>
        <MemoizedLoaderHomeTile />
      </div>
    );
  }, [itemsMap[item.id], item, position, rowNumber]);

  return (
    <Container {...cmMetaData(item.id)}>
      <Content />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;

  a {
    text-decoration: underline;
  }
`;

export default ItemComponent;

export const MemoizedItemComponent = React.memo(ItemComponent);
