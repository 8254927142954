import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import useTranslation from "../../../../../../hooks/useTranslation";
import { getPagePath } from "../../../../../../routing/routesUtils";
import CustomText from "../../../../../styled-UI/CustomText";

import useCanRender from "../../../../../../hooks/useCanRender";

interface Props {
  tileName?: string;
  setToggleMenu: (identifier: string) => void;
}

const OrderNowColumn = ({ tileName, setToggleMenu }: Props): JSX.Element | null => {
  const { translateLabel } = useTranslation();
  const canRender = useCanRender();

  if (canRender("RX")) {
    return (
      <UrlList>
        <li>
          <CustomText fontSizePx={18} as="span" marginBottomPx={20} data-element-id="Lenses">
            {translateLabel("ORDER_NOW")}
          </CustomText>
        </li>
        <li
          onClick={() => {
            setToggleMenu("");
          }}
        >
          <Link
            to={getPagePath("/rx-prescription") + "?rxFlow=Authentics"}
            data-element-id={`MainNav_Products_${tileName}_OrderNow_RXPrescription`}
          >
            <CustomText
              fontSizePx={14}
              as="span"
              marginBottomPx={20}
              font="font-regular"
              lineHeightPx={28}
            >
              {translateLabel("RX_PRESCRIPTION_TITLE")}
            </CustomText>
          </Link>
        </li>
      </UrlList>
    );
  }
  return null;
};

const UrlList = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem;
  padding: 1.875rem 0.5rem 0rem;
`;

export default OrderNowColumn;
