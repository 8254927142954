import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams, useRouteMatch } from "react-router";
import styled from "styled-components/macro";
import useCategoryTitle from "../../../hooks/useCategoryTitle";
import usePrePlpTitle from "../../../hooks/usePrePlpTitle";
import useQuery from "../../../hooks/useQuery";
import useTranslation from "../../../hooks/useTranslation";

import useADTPdpTitle from "../../../hooks/useADTPdpTitle";
import useADTPlpTitle from "../../../hooks/useADTPlpTitle";
import { authRoutes } from "../../../routing/authRoutes";
import { getPagePath, parseUrlInfo } from "../../../routing/routesUtils";
import { selectUrlDigitalCatalogue } from "../../../store/catalogue/catalogueSlice";
import { selectResultsCount, selectSearchedTerm } from "../../../store/search/searchSlice";
import { selectPagesLayout } from "../../../store/store/storeSlice";
import DisplayPrices from "../DisplayPrices";
import Breadcrumb from "./Breadcrumb";

export interface Route {
  name: string;
  path: string;
  disabled?: boolean;
  pathForLink: string;
  fakePath?: string;
}

const BreadcrumbSection = (): JSX.Element => {
  const { translateLabel } = useTranslation();
  const query = useQuery();
  const location = useLocation();
  const params: any = useParams();

  const plpCategoryTitle = useCategoryTitle();
  const prePlpTitle = usePrePlpTitle();
  const ADTPlpTitle = useADTPlpTitle();
  const ADTPdpTitle = useADTPdpTitle();

  const searchedTerm = useSelector(selectSearchedTerm);
  const searchResultsCount = useSelector(selectResultsCount);
  const pagesLayout = useSelector(selectPagesLayout);
  const digitalCatalogue = useSelector(selectUrlDigitalCatalogue);

  const rxStep = query.get("step");
  const rxFlow = query.get("rxFlow");

  const crumbs: Route[] = authRoutes
    // Get all routes that contain the current one.
    .filter(({ path }) => useRouteMatch(path))
    // Swap out any dynamic routes with their param values.
    // E.g. "/pizza/:pizzaId" will become "/pizza/1"
    .map(({ breadCrumbPath, name, path, ...rest }, index) => {
      let printParam = "";
      let fakePath = "";

      // check urls
      if (breadCrumbPath.includes("/plp")) printParam = plpCategoryTitle?.title ?? "";
      if (breadCrumbPath.includes("/preplp")) printParam = prePlpTitle?.title ?? "";
      if (breadCrumbPath.includes("/asset-downloads/asset-list/"))
        printParam = ADTPlpTitle?.title ?? "";
      if (breadCrumbPath.includes("/asset-downloads/asset-detail/")) printParam = ADTPdpTitle;

      if (breadCrumbPath.includes("/search-results") && searchResultsCount !== null) {
        printParam =
          (searchResultsCount
            ? translateLabel("SEARCH_RESULTS")
            : translateLabel("SEARCH_SORRY_NO_RESULTS")) +
          " " +
          searchedTerm;
      }
      if (path.includes("/contents")) {
        printParam = "";
        Object.keys(params).map((key) => {
          if (breadCrumbPath?.includes(`:${key}`)) {
            const layoutKey = params[key];
            const layout = pagesLayout[layoutKey];

            printParam = layout?.title ?? "";
          }
        });
      }

      //add fake path for breadcrumb in services others menu
      if (
        breadCrumbPath.includes("/pos-source-file") ||
        breadCrumbPath.includes("/item-master-data") ||
        breadCrumbPath.includes("/transport-documents") ||
        breadCrumbPath.includes("/price-list")
      ) {
        fakePath = translateLabel("OTHER_SECTION");
      }

      // check parameters
      if (Object.keys(params).length > 0 && !printParam) {
        Object.keys(params).map((key) => {
          if (breadCrumbPath?.includes(`:${key}`)) {
            // add custom titles for special ids
            switch (params[key]) {
              case "add-new-user":
                printParam = translateLabel("ADD_NEW_USER_TITLE");
                break;
              default:
                printParam = params[key];
                break;
            }
          }
        });
      }

      //generate path for the link in breadcrumb, by replacing parametric values
      let pathForLink = getPagePath(path.replace(":storeIdentifier/:locale/", ""));
      if (Object.keys(params).length > 0) {
        Object.keys(params).map((key) => {
          if (pathForLink?.includes(`:${key}`)) {
            pathForLink = pathForLink.replace(`:${key}`, printParam);
          }
        });
      }

      return {
        pathForLink: pathForLink,
        name: printParam ? printParam : translateLabel(name),
        disabled:
          breadCrumbPath === "/account" ||
          breadCrumbPath === "/thank-you-page" ||
          breadCrumbPath === "/request-warranty",
        path,
        fakePath: fakePath,
        ...rest,
      };
    })
    .sort((routeA, routeB) => (routeA.pathForLink.length > routeB.pathForLink.length ? 1 : -1))
    .filter((_) => !_.pathForLink.includes(":"));

  crumbs.unshift({
    path: "/homepage",
    name: translateLabel("HOMEPAGE_TITLE"),
    pathForLink: getPagePath("/homepage"),
  });

  // crumbs.map(({ name, breadCrumbPath }: any) => console.log({ name, breadCrumbPath }));

  const urlInfo = parseUrlInfo();

  const showDisplayPrice =
    location.pathname.includes("/plp") ||
    location.pathname.includes("/preplp") ||
    location.pathname.includes("/pdp") ||
    location.pathname.includes("/cart") ||
    location.pathname.includes("/spare-parts/") ||
    location.pathname.includes("/cart/order-confirmation") ||
    (location.pathname.includes("/rx-prescription") && rxStep && +rxStep >= 3) ||
    (location.pathname.includes("/rx-prescription") && rxFlow === "Essilor") ||
    location.pathname.includes("/search-results") ||
    location.pathname.includes("/digital-screen") ||
    location.pathname.includes("/orders-to-be-approved/approval-simulation") ||
    location.pathname.includes("/account/availability") ||
    location.pathname.includes("/alternative-product");

  // delete params (eg. id in Warranty Wizard) when required
  const hideParam =
    location.pathname.includes("/request-warranty") ||
    (location.pathname.includes("/thank-you-page") &&
      !location.pathname.includes("/orders-to-be-approved/thank-you-page"));
  hideParam && crumbs.pop();

  const hideBreadCrumbs = location.pathname.includes("homepage") || urlInfo.pageType === "news";

  const hasDarkBackground = location.pathname.includes("plp-stars");

  return (
    <Section
      className={clsx(
        "print-hide",
        hideBreadCrumbs && "hide",
        hasDarkBackground && "breadcrumb-dark"
      )}
    >
      <Container>
        <Breadcrumb
          routes={crumbs}
          style={hasDarkBackground ? "dark" : "default"}
          url={digitalCatalogue}
        />
        {showDisplayPrice && (
          <div className="d-flex align-items-center">
            <DisplayPrices style={hasDarkBackground ? "dark" : "default"} />
          </div>
        )}
      </Container>
    </Section>
  );
};

const Section = styled.div`
  height: 3.625rem;
  width: 100%;
  z-index: 10;

  &.hide {
    display: none;
  }

  /* &.breadcrumb-dark {
    background-color: ${(props) => props.theme.palette.primary};
  } */
`;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default BreadcrumbSection;

export const MemoizedBreadcrumbSection = React.memo(BreadcrumbSection);
