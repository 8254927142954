import React from "react";
import styled from "styled-components/macro";
import { LpTextList as Interface } from "../../../interfaces/cmsInterfaces";
import LpTextListCard from "./LpTextListCard";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  data: Interface;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LpTextList = ({ data, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  return (
    <Container>
      {data?.result && data?.result?.length > 0 ? (
        data?.result.map((item) => {
          return (
            <LpTextListCard
              title={item.title}
              titleColour={item.titleColour}
              description={item.description}
              description1Colour={item.description1Colour}
              description2={item.description2}
              description2Colour={item.description2Colour}
              key={item.id}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        })
      ) : (
        <LpTextListCard
          title={data.title}
          titleColour={data.titleColour}
          description={data.description}
          description1Colour={data.description1Colour}
          description2={data.description2}
          description2Colour={data.description2Colour}
          isDarkMode={isDarkMode}
          customFont={customFont}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 7rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export default LpTextList;
