import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";

import LoaderElement from "../LoaderElement";
import { TileStyle } from "../LoaderTile";

interface Props {
  style: TileStyle;
  unsetHeight?: boolean;
}

export const TileTagsLoader = ({ style, unsetHeight }: Props): JSX.Element => {
  return (
    <Tags className={clsx(style, unsetHeight && "unset-height")}>
      <LoaderElement height={30} width={100} radius={15} />
      <LoaderElement height={30} width={120} radius={15} />
      <LoaderElement height={30} width={150} radius={15} />
      <LoaderElement height={30} width={100} radius={15} />
    </Tags>
  );
};

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  column-gap: 0.5rem;

  &.default {
    height: 7.15rem;
    row-gap: 0.5rem;
  }

  &.unset-height {
    height: unset;
  }
`;
