import { sendAnalyticsData } from "../store/analytics/analyticsService";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCommonPageData,
  selectCommonUserData,
  selectSearchData,
  selectOrderData,
  selectPrePlpData,
  selectPdpData,
  selectSparePartsPdpData,
  selectCheckoutData,
  selectWarrantyWizardProductData,
  selectWarrantyRequestType,
} from "../store/analytics/analyticsSlice";
import { AnalyticsData, ErrorData, PageData } from "../store/analytics/analyticsInterfaces";
import { useDebouncedEffect } from "./useDebouncedEffect";
import { useEffect, useRef } from "react";

export const useTrackView = (
  data: PageData | null = {},
  dependencies: any = {},
  waitTime?: number
): void => {
  const commonPageData = useSelector(selectCommonPageData);
  const commonUserData = useSelector(selectCommonUserData);

  //#region Non-common
  const searchData = useSelector(selectSearchData);
  const orderData = useSelector(selectOrderData);
  const checkoutData = useSelector(selectCheckoutData);

  const pdpData = useSelector(selectPdpData);
  const sparePartsPdpData = useSelector(selectSparePartsPdpData);
  const warrantyWizardProductData = useSelector(selectWarrantyWizardProductData);
  const warrantyRequestType = useSelector(selectWarrantyRequestType);
  //#endregion

  const location = useLocation();

  const _dependencies = dependencies ? Object.values(dependencies) : [];
  const DEBOUNCE_TIME = waitTime ? waitTime : _dependencies?.length > 0 ? 2000 : 0;

  useTraceUpdate(dependencies);

  useDebouncedEffect(
    () => {
      const notValid =
        dependencies && Object.keys(dependencies).some((key: string) => !dependencies[key]);

      if (notValid) return;
      // if (localStorage.getItem("tealium_log"))
      //   console.log("#Analytics trigger dependencies:", dependencies);

      try {
        const data2track = createData2TrackObject();
        // console.log(data2track);
        sendAnalyticsData(data2track);
      } catch (error) {
        console.error(`Error tracking VirtualPage-View`, error);
        return;
      }
    },
    _dependencies,
    DEBOUNCE_TIME
  );

  const createData2TrackObject = () => {
    let obj: AnalyticsData = {
      id: "VirtualPage-View",
    };

    // Order matters
    obj = {
      ...obj,
      ...commonPageData,
      ...getPageData(location.pathname),
      ...getErrorPageData(),
      ...data,
      ...commonUserData,
    };

    return obj;
  };

  const getPageData = (pathname: string): PageData | null => {
    // console.log("pathname: ", pathname);

    if (data?.Page_Type === "Search") {
      return {
        Page_Design: Number(searchData.Search_ResultItemsQnt) > 0 ? "" : "Empty",
        ...searchData,
      };
    }

    if (pathname.includes("/pdp/")) {
      return {
        ...pdpData,
      };
    }

    // PDP Spare Parts
    if (pathname.includes("/spare-parts/")) {
      return {
        ...sparePartsPdpData,
      };
    }

    if (pathname.includes("/account/")) {
      let Page_Type = "Static";
      if (pathname.includes("/profile")) {
        Page_Type = "Profile";
      }
      if (pathname.includes("/order-history")) {
        Page_Type = "History";
      }
      return {
        Page_Section1: "Account",
        Page_Type,
      };
    }

    if (data?.Page_Section2 === "Cart") {
      return {
        Page_Design: Object.keys(orderData.Products).length > 0 ? "" : "Empty",
        ...orderData,
      };
    }

    if (data?.Page_Type === "OrderSummary") {
      return {
        ...checkoutData,
      };
    }

    if (data?.Page_Type === "Thankyou") {
      return {
        ...checkoutData,
      };
    }

    if (pathname.includes("/request-warranty/")) {
      if (data?.Page_Type === "WDamageInfo") return { ...warrantyWizardProductData };
      else return { ...warrantyWizardProductData, Return_Reason: warrantyRequestType ?? "" };
    }

    return null;
  };

  const getErrorPageData = (): ErrorData | null => {
    const obj: ErrorData = {
      id: "Error",
    };

    if (data?.Page_Section2 === "ErrorHttp404") {
      return {
        ...obj,
        Error_Source: "404",
        Error_Code: "404 - page not found",
        Error_Details: location.pathname,
      };
    }

    return null;
  };
};

function useTraceUpdate(props: any) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps: any, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      // if (localStorage.getItem("tealium_log"))
      //   console.log("#Analytics changed dependencies:", changedProps);
    }
    prev.current = props;
  });
}
