import React, { useEffect } from "react";
import styled from "styled-components";
import CustomText from "../../../styled-UI/CustomText";
import { ReactComponent as Bag } from "../../../../assets/icons/bag-2-icon.svg";
import IconButton from "../../../styled-UI/IconButton";
import Button from "../../../styled-UI/Button";
import useTranslation from "../../../../hooks/useTranslation";
import { RequestStatus } from "../../../../interfaces/mainInterfaces";
import { useDispatch, useSelector } from "react-redux";
import {
  saveOrdersToApproveList,
  selectOrdersToApproveList,
} from "../../../../store/orders-to-approve/ordersToApproveSlice";
import { getOrdersToApprove } from "../../../../store/orders-to-approve/ordersToApproveSaga";
import useCanRender from "../../../../hooks/useCanRender";

interface User {
  orgentityName: string;
  orgentityId: string;
  firstName?: string;
  address1?: string;
  city?: string;
  state?: string;
  country?: string;
}
interface Props {
  hasOrderInApproval?: boolean;
  hasCta?: boolean;
  onClick?: () => void;
  selectCustomer?: (orgentityId: string) => void;
  isLoading?: boolean;
  user: User;
}

const PersonificationUser = ({
  hasOrderInApproval = false,
  hasCta = false,
  onClick,
  user,
  selectCustomer,
  isLoading = false,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const canRender = useCanRender();

  const ordersToApproveList = useSelector(selectOrdersToApproveList);

  useEffect(() => {
    if (!ordersToApproveList && canRender("CAN_APPROVE_ORDERS")) {
      dispatch(
        getOrdersToApprove({
          pageSize: 1,
          pageNumber: 1,
        })
      );
    }
  }, [ordersToApproveList]);

  useEffect(() => {
    return () => {
      dispatch(saveOrdersToApproveList(null));
    };
  }, []);

  return (
    <UserBox>
      <UserInfo>
        <CustomText as="span" fontSizePx={18} lineHeightPx={18} font="font-bold" marginBottomPx={4}>
          {user?.orgentityName + " - " + user?.firstName}
        </CustomText>
        <CustomText
          as="span"
          fontSizePx={14}
          lineHeightPx={24}
          font="font-regular"
          color="gray-dark"
        >
          {user?.address1 + ", " + user?.city + " " + user?.state + " - " + user?.country}
        </CustomText>
      </UserInfo>

      {canRender("CAN_APPROVE_ORDERS") && hasOrderInApproval && (
        <ButtonIconContainer onClick={onClick}>
          <IconButton size="lg" icon={<Bag />} type="white-no-border" />
          <CustomText as="span" color="white">
            {ordersToApproveList?.totalElements}
          </CustomText>
        </ButtonIconContainer>
      )}
      {hasCta && selectCustomer && (
        <div>
          <Button
            type="secondary"
            onClick={() => selectCustomer(user?.orgentityId)}
            isLoading={isLoading}
          >
            {translateLabel("PERSONIFICATION_POPUP_OK")}
          </Button>
        </div>
      )}
    </UserBox>
  );
};
const UserBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  margin: 0 2rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};

  &:last-child {
    border-bottom: 0;
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonIconContainer = styled.div`
  position: relative;

  svg {
    fill: ${(props) => props.theme.palette.primary};
    height: 1.5rem;
    width: 1.5rem;
  }

  span {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.white};
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: solid 0.25rem ${(props) => props.theme.palette.white};
    top: -0.375rem;
    right: -0.375rem;
    font-family: ${(props) => props.theme.fonts.fontHeavy};
    font-size: 0.625rem;
  }
`;

PersonificationUser.displayName = "PersonificationUser";
export default PersonificationUser;
