import { ContextTutorialPill } from "../store/catalogue/catalogueInterface";
import { AddressList } from "../store/profile/profileInterfaces";
import { Door, UserAttributes } from "../store/user/userInterfaces";

export const isMultidoor = (multiDoors: Door[], activeDoor: Door): boolean => {
  return (
    multiDoors?.length > 1 ||
    (multiDoors?.length === 1 && multiDoors?.[0]?.orgentityId !== activeDoor?.orgentityId)
  );
};

export const isShipToAddress = (address: AddressList[]): AddressList[] => {
  return address.filter((address) => address.addressType?.includes("S")).map((address) => address);
};

export const isSoldToAddress = (address: AddressList[]): AddressList[] => {
  return address.filter((address) => address.selfAddress === "1").map((address) => address);
};

export const computeSelectedDoor = (
  selectedDoor: Door | null,
  activeDoor: Door | null,
  multiDoors: Door[]
): Door | null => {
  //return selected door if present
  if (selectedDoor) return selectedDoor;

  //select first door of list
  if (activeDoor && multiDoors) {
    return multiDoors[0];
  }

  //else get active
  return activeDoor;
};

export const getDoorFromDoorIdOrOrgentity = (
  multiDoors: Door[],
  doorId: string | null | undefined,
  orgentityName: string | null | undefined
): Door | undefined => {
  return multiDoors.find((_) => _.orgentityId === doorId || _.orgentityName === orgentityName);
};

/**
 * Map Context tutorial pills items
 *
 * @param {any[]} items
 * @return {*}  {ContextTutorialPill[]}
 */
export const mapContextTutorialPills = (items: any[]): ContextTutorialPill | null => {
  // TUTORIAL PILLS IDS
  const tutorialPillsAttrs: UserAttributes =
    items?.find((attribute: UserAttributes) => attribute.key === "TUTORIAL_PILLS_IDS") ?? undefined;

  if (tutorialPillsAttrs) {
    const values: string[] = tutorialPillsAttrs?.value?.split(",");
    const contextPills: ContextTutorialPill = {};
    if (values.length) {
      values.forEach((element) => {
        contextPills[element] = element;
      });
      return contextPills;
    }
  }
  return null;
};
