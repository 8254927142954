import { RefObject, useEffect } from "react";
import { useHistory } from "react-router";

import { getPagePath } from "../routing/routesUtils";

export default function useLinkClickHandlers(
  ref: RefObject<HTMLDivElement>,
  callback?: () => void
): void {
  const history = useHistory();

  useEffect(() => {
    if (!ref?.current) return;

    const links = ref.current.querySelectorAll("a");
    links.forEach((link) => link.addEventListener("click", handleLinkClick));

    return () => {
      links.forEach((link) => link.removeEventListener("click", handleLinkClick));
    };

    function handleLinkClick(event: MouseEvent) {
      const link = event.currentTarget as HTMLAnchorElement;
      const href = link.getAttribute("href");
      const target = link.getAttribute("target");
      const url = new URL(href || "", window.location.origin);

      const isInternalLink = url.origin === window.location.origin;
      const isOpenedInSameWindow = !target || target === "_self";
      const isLeftButtonClick = event.button === 0;

      // E.g. Ctrl-clicking a link opens it in a new tab
      // so let the browser handle such clicks
      const isModifierKeyPressed = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;

      if (isInternalLink && isOpenedInSameWindow && isLeftButtonClick && !isModifierKeyPressed) {
        event.preventDefault();
        callback?.();
        history.push(getPagePath(url.pathname + url.search + url.hash));
      }
    }
  }, [history, ref]);
}
