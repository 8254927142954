import React from "react";
import { ReactComponent as RxIcon } from "../../assets/icons/rx-enabled.svg";
import { ReactComponent as DiscountIcon } from "../../assets/icons/sale-icon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as OSShippedIcon } from "../../assets/icons/one_shot_shipped.svg";
import { ReactComponent as OSPlannedIcon } from "../../assets/icons/one_shot_planned.svg";
import { ReactComponent as RTRIcon } from "../../assets/icons/rtr-enabled.svg";
import { Variant, Product, Sku } from "../../interfaces/productInterface";
import StarIconComponent from "./StarIconComponent";
import { useSelector } from "react-redux";
import { selectInstagramBadges } from "../../store/catalogue/catalogueSlice";
import { instanceOfProduct, instanceOfVariant } from "../../utils/productUtils";
import styled from "styled-components/macro";
import Tooltip from "../styled-UI/Tooltip";
import useTranslation from "../../hooks/useTranslation";

interface Props {
  data: Product | Variant | Sku;
  isPDP?: boolean;
}

const IconTile = ({ data, isPDP = false }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const instagramBadges = useSelector(selectInstagramBadges);

  const hasInstagramBadge = instagramBadges?.some((_) => {
    if (instanceOfProduct(data)) {
      return _.productId === data.productCode;
    } else if (instanceOfVariant(data)) {
      return _.productId === data.variantCode.split("_")[0];
    }
  });

  const renderOneShotIcon = (): JSX.Element => {
    const isShipped = data?.starsNew?.find((_) => _ === "One shot shipped");
    const isPlanned = data?.starsNew?.find((_) => _ === "One shot planned");

    if (isShipped || isPlanned) {
      return (
        <>
          {" "}
          <SvgContainer>
            <Tooltip
              content={
                <TooltipContent>
                  {translateLabel(
                    isShipped
                      ? translateLabel("STARS_ONE_SHOT_SHIPPED_TOOLTIP_LABEL")
                      : translateLabel("STARS_ONE_SHOT_PLANNED_TOOLTIP_LABEL")
                  )}
                </TooltipContent>
              }
            >
              {isShipped ? <OSShippedIcon /> : <OSPlannedIcon />}
            </Tooltip>
          </SvgContainer>
        </>
      );
    } else return <></>;
  };

  return (
    <div>
      <StarIconComponent data={data} />
      {!isPDP && data?.isRTR && <RTRIcon />}
      {data.isRxable && <RxIcon />}
      {data?.isPromo && <DiscountIcon />}
      {(data?.isSeeOnInstagram || hasInstagramBadge) && <InstagramIcon />}
      {data?.starsNew && renderOneShotIcon()}
    </div>
  );
};

const SvgContainer = styled.div`
  display: inline;
  margin: 0;
  .svg-container {
    &:after {
      content: "!";
      position: absolute;
      top: -7px;
      right: -7px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const TooltipContent = styled.div`
  max-width: 320px;
`;

export default IconTile;
