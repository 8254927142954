import { Product, Sku, Variant } from "../../interfaces/productInterface";
import { instanceOfSparePartsSku } from "../../utils/aftersalesUtils";
import { getAttribute } from "../../utils/productUtils";
import { OrderItem, OrderMultidoor } from "../cart/cartInterfaces";
import {
  AvailabilityStatus,
  AvailabilityStatusByDoor,
  PreCartProduct,
  PDPVariantsInfo,
} from "../catalogue/catalogueInterface";
import { Door } from "../user/userInterfaces";
import { ProductData, Products, SizesData } from "./analyticsInterfaces";

const rxFields = ["4", "5", "6"];
const categoriesToFallback = ["Nuance Audio"];

interface MapDefaultProductParams {
  availability?: string; // attention: not mapped status for analytics, but real availability value (eg. NOT_AVAILABLE, BACKORDER, etc.)
  category?: string; // generally computed with getProductCategory()
  vmUpc?: string; // we know if vm is supported only in pdp
  price?: number; // remember that SparePartsSku use SPprice
  discountedPrice?: number; // final price, not just the discount. only required if relevant, otherwise Price defaults to PriceFull.
  quantity?: number; // default is 1, it changes depending on the quantity set by the user where he can (eg: Cart, OrderConfirmation, TYP)
  LensType?: "PLANO" | "RX" | undefined; // can be computed with getLensType() where relevant, otherwise defaults to "PLANO"
  FrameType?: "CP" | "STD" | undefined; // defaults to "STD"
  status?: string;
  shippingTo?: "self" | string;
  insuranceAmount?: string;
  taxRate?: string;
  lensUPC?: string;
}

/**
 * This utility maps the basic Product object, by handling default cases and repeated logics.
 * Should be called from all other mappings, by simply integrating any other information not present here.
 *
 * @param {MapDefaultProductParams} {
 *   availability = "", // attention: not mapped status for analytics, but real availability value (eg. NOT_AVAILABLE, BACKORDER, etc.)
 *   category = "", // generally computed with getProductCategory()
 *   vmUpc = "", // we know if vm is supported only in pdp
 *   price, // remember that SparePartsSku use SPprice
 *   discountedPrice, // final price, not just the discount. only required if relevant, otherwise Price defaults to PriceFull.
 *   quantity = 1,  // default is 1, it changes depending on the quantity set by the user where he can (eg: Cart, OrderConfirmation, TYP)
 *   LensType = "PLANO", // can be computed with getLensType() where relevant, otherwise defaults to "PLANO"
 *   FrameType = "STD",  // defaults to "STD"
 * }
 * @return {*}  {ProductData}
 */
const mapDefaultProduct = ({
  availability = "",
  category = "",
  vmUpc = "",
  price,
  discountedPrice,
  quantity = 1,
  LensType = "PLANO",
  FrameType = "STD",
  status,
  shippingTo,
  insuranceAmount,
  taxRate,
  lensUPC,
}: MapDefaultProductParams): ProductData => {
  const defaultStatus = getStatusByAvailability(availability);

  const productData: ProductData = {
    Status: status ?? defaultStatus,
    Preorder: availability === "PREORDER" ? "1" : "0",
    Vm_IsUpcSupported: vmUpc ? "1" : "0",
    Category: category,
    Price: discountedPrice?.toString() || (price?.toString() ?? ""),
    PriceFull: price?.toString() ?? "",
    Units: quantity?.toString(),
    LensType: LensType,
    FrameType: FrameType,
  };

  if (shippingTo != undefined) productData.ShippingTo = shippingTo;
  if (insuranceAmount != undefined) productData.InsuranceAmount = insuranceAmount;
  if (taxRate != undefined) productData.TaxRate = taxRate;
  if (lensUPC != undefined) productData.LensUPC = lensUPC;

  return productData;
};

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////// PRODUCTS OBJECT MAPPINGS /////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const getProductsDataFromSku = (
  products: Sku[] | null | undefined,
  door: Door | null,
  reduxAvailability: AvailabilityStatusByDoor[] = [],
  availability?: string[], // availability can be passed from outside, otherwise it's calculated from reduxAvailability and door
  vmUpc = "",
  userHasStars = false,
  shippingTo?: "self" | string,
  insuranceAmount?: string,
  taxRate?: string,
  lensUPC?: string
): Products => {
  if (!products || products.length === 0) return {};

  const productsData: Products = {};
  let index = 0;

  products.forEach((sku) => {
    index++;
    const productKey = getProductKey(sku, index);

    productsData[productKey] = mapDefaultProduct({
      availability:
        availability?.[index] ??
        getAvailabilityByDoorAndSkuId(reduxAvailability, door, sku?.uniqueID),
      category: getProductCategory(sku),
      vmUpc,
      price: sku.price?.opt?.unitPrice,
      status: getStatusForStars(userHasStars, sku),
      shippingTo,
      insuranceAmount,
      taxRate,
      lensUPC,
    });
  });

  return productsData;
};

export const getProductsDataFromVariant = (
  products: Variant[] | null | undefined,
  door: Door | null,
  reduxAvailability: AvailabilityStatusByDoor[] = [],
  vmUpc = "",
  userHasStars = false
): Products => {
  if (!products || products.length === 0) return {};

  const productsData: Products = {};
  let index = 0;

  products.forEach((variant) => {
    // if skus are available, map those across all variants
    if (variant.skus && variant.skus?.length !== 0) {
      variant.skus.forEach((sku) => {
        index++;
        const productKey = getProductKey(sku, index);

        productsData[productKey] = mapDefaultProduct({
          availability: getAvailabilityByDoorAndSkuId(reduxAvailability, door, sku?.uniqueID),
          category: getProductCategory(sku, variant), // variant as fallback
          vmUpc,
          price: sku.price?.opt?.unitPrice ?? variant.price?.opt?.unitPrice,
          status: getStatusForStars(userHasStars, sku),
        });
      });
    } else {
      // else map variants directly (availability not provided)
      index++;
      const productKey = getProductKey(variant, index);

      productsData[productKey] = mapDefaultProduct({
        category: getProductCategory(null, variant),
        vmUpc,
        price: variant.price?.opt?.unitPrice,
      });
    }
  });

  return productsData;
};

export const getProductsDataFromProduct = (
  products: Product[] | null | undefined,
  door: Door | null,
  reduxAvailability: AvailabilityStatusByDoor[] = [],
  vmUpc: string | undefined = "",
  userHasStars: boolean | undefined = false
): Products => {
  if (!products || products.length === 0) return {};

  const productsData: Products = {};
  let index = 0;

  products.forEach((product) => {
    if (product.variants && product.variants?.length !== 0) {
      // if variants are available, map those across all products
      product.variants.forEach((variant) => {
        // if skus are available, map those across all variants
        if (variant.skus && variant.skus?.length !== 0) {
          variant.skus.forEach((sku) => {
            index++;
            const productKey = getProductKey(sku, index);

            productsData[productKey] = mapDefaultProduct({
              availability: getAvailabilityByDoorAndSkuId(reduxAvailability, door, sku?.uniqueID),
              category: getProductCategory(sku, variant), // variant as fallback
              vmUpc,
              price: sku.price?.opt?.unitPrice ?? variant.price?.opt?.unitPrice,
              status: getStatusForStars(userHasStars, sku),
            });
          });
        } else {
          // else map variants directly (availability not provided)
          index++;
          const productKey = getProductKey(variant, index);

          productsData[productKey] = mapDefaultProduct({
            category: getProductCategory(null, variant),
            vmUpc,
            price: variant.price?.opt?.unitPrice ?? product.price?.opt?.unitPrice,
          });
        }
      });
    } else {
      // else map products directly (availability not provided)
      index++;
      const productKey = getProductKey(product, index);

      productsData[productKey] = mapDefaultProduct({
        category: getProductCategory(null, product),
        vmUpc,
        price: product.price?.opt?.unitPrice,
      });
    }
  });

  return productsData;
};

// map single order item
const mapOrderItem = (
  orderItem: OrderItem,
  shippingTo?: string,
  userHasStars: boolean | undefined = false
): ProductData => {
  const availability = orderItem?.split?.[0]?.semaphore ?? ""; // TODO: what about splitframe?
  const discount = orderItem?.carnet?.lensDiscountValue ?? 0;
  const isStars =
    userHasStars && orderItem?.sku?.attributes?.find((_) => _.identifier === "STARS_NEW");

  const defaultProduct = mapDefaultProduct({
    availability: availability,
    category: getProductCategory(orderItem?.sku),
    price: orderItem?.price?.opt?.unitPrice,
    discountedPrice: orderItem?.price?.opt?.unitPrice - discount,
    quantity: orderItem?.quantity || orderItem?.split?.[0]?.quantity || 1,
    LensType: getLensType(orderItem?.xitemField1),
    FrameType: "STD",
    status: isStars ? "Stars" : undefined,
  });

  return {
    ...defaultProduct,
    ShippingTo: shippingTo,
  };
};

// called on landing in cart, order confirmation and thank you page
export const getProductsDataFromOrder = (
  doors: OrderMultidoor[],
  userHasStars: boolean
): Products => {
  const productsData: Products = {};
  let index = 0;

  if (doors.length === 0) return {};

  doors?.forEach((door) => {
    door?.categoryList?.forEach((category) => {
      category?.orderItemList?.forEach((order) => {
        index++;

        if (order.orders && order.orders.length > 0) {
          order.orders.forEach((singleOrder) => {
            const productKey = getProductKey(singleOrder?.sku, index);

            if (productKey)
              productsData[productKey] = mapOrderItem(
                singleOrder,
                door?.orgentityName,
                userHasStars
              );
          });
        } else {
          const productKey = getProductKey(order?.sku, index);

          if (productKey)
            productsData[productKey] = mapOrderItem(order, door?.orgentityName, userHasStars);
        }
      });
    });
  });

  return productsData;
};

// called at cart deletion
export const getProductsDataFromOrderItems = (
  orderItems: OrderItem[],
  userHasStars: boolean
): Products => {
  const productsData: Products = {};
  let index = 0;

  orderItems.map((orderItem: OrderItem) => {
    index++;

    if (orderItem.orders && orderItem.orders.length > 0) {
      orderItem.orders.forEach((singleOrder) => {
        const productKey = getProductKey(singleOrder?.sku, index);

        if (productKey)
          productsData[productKey] = mapOrderItem(singleOrder, undefined, userHasStars);
      });
    } else {
      const productKey = getProductKey(orderItem?.sku, index);

      if (productKey) productsData[productKey] = mapOrderItem(orderItem, undefined, userHasStars);
    }
  });

  return productsData;
};

// called when the Add to cart button is clicked
export const getDataForAddToCart = (products: PreCartProduct[]): Products => {
  const productsData: Products = {};
  if (products.length === 0) return {};

  products.forEach((product, index) => {
    const productKey = getProductKey(product?.sku, index);

    if (productKey) {
      const defaultProduct = mapDefaultProduct({
        availability: product?.availability, // we don't have info about avaiability and category for m4c products
        category: getProductCategory(product?.sku),
        quantity: product?.quantity || 1,
        price: product?.price,
        status: product?.isStars ? "Stars" : undefined,
      });

      productsData[productKey] = {
        ...defaultProduct,
        ShippingTo: product?.orgentityName,
      };
    }
  });

  return productsData;
};

// called in PDPList and PDPGrid - ad hoc b/c prices are saved in special PDPVariantsInfo object
export const getProductsDataFromPdp = (
  products: Variant[] | null | undefined,
  PDPVariantsInfo: PDPVariantsInfo,
  door: Door | null,
  reduxAvailability: AvailabilityStatusByDoor[] = [],
  vmUpc = "",
  userHasStars = false
): Products => {
  if (!products || products.length === 0) return {};

  const productsData: Products = {};
  let index = 0;

  products.forEach((variant) => {
    // if skus are available, map those across all variants
    if (variant.skus && variant.skus?.length !== 0) {
      const variantInfo = PDPVariantsInfo?.[variant?.uniqueID];
      const doorInfo = variantInfo?.doorInfo?.filter(
        (_) => _.doorId === variantInfo?.selectedDoor?.orgentityId
      )?.[0];

      variant.skus.forEach((sku) => {
        index++;
        const productKey = getProductKey(sku, index);

        productsData[productKey] = mapDefaultProduct({
          availability: getAvailabilityByDoorAndSkuId(reduxAvailability, door, sku?.uniqueID),
          category: getProductCategory(sku, variant), // variant as fallback
          vmUpc,
          price: doorInfo?.price?.opt?.unitPrice,
          status: getStatusForStars(userHasStars, sku),
        });
      });
    } else {
      // else map variants directly (availability not provided)
      index++;
      const productKey = getProductKey(variant, index);

      productsData[productKey] = mapDefaultProduct({
        category: getProductCategory(null, variant),
        vmUpc,
        price: variant.price?.opt?.unitPrice,
      });
    }
  });

  return productsData;
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// GENERIC UTILS //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

// get the product key (PRIORITY: sku's upc > sku's partNumber > product's partNumber)
// optional index can be passed, so that the final key will be {productKey}#{index}
export const getProductKey = (product: Product | Variant | Sku | null, index?: number): string => {
  const productKey =
    (product as Sku)?.upc ||
    (product as Sku)?.partNumber ||
    (product as Variant)?.variantCode ||
    (product as Product)?.productCode;

  return index !== undefined ? `${productKey}#${index}` : productKey;
};

// get lens' type based on xitemField1
const getLensType = (xitemField1?: string): "PLANO" | "RX" | undefined => {
  return rxFields?.includes(xitemField1 ?? "") ? "RX" : "PLANO";
};

// get availability from the global AvailabilityStatusByDoor[] based on the sku's uniqueId + selectedDoor
const getAvailabilityByDoorAndSkuId = (
  reduxAvailability: AvailabilityStatusByDoor[],
  door: Door | null,
  uniqueID: string
): string => {
  if (!uniqueID || !door?.orgentityName || reduxAvailability?.length === 0) return "";

  const availabilities = reduxAvailability?.find(
    (availabilityByDoor) => availabilityByDoor?.doorId === door?.orgentityName
  );

  return getAvailabilityFromSkuId(uniqueID, availabilities?.availabilityStatus);
};

// get availability from AvailabilityStatus[] by the sku's uniqueId
const getAvailabilityFromSkuId = (
  uniqueId: string,
  availabilities: AvailabilityStatus[] | undefined
): string => {
  if (!availabilities || availabilities.length === 0) return "";

  let skuStatus = "";

  for (let i = 0; i < availabilities.length; i++) {
    const status = availabilities[i][uniqueId];
    if (status) {
      skuStatus = status;
      break;
    }
  }

  return skuStatus;
};

// get analytics value from B2B availability
const getStatusByAvailability = (availability: string) => {
  const status: any = {
    PREORDER: "Available",
    AVAILABLE: "Available",
    LAST_PIECES: "Available-fewleft",
    BACKORDER: "SoldOut",
    BACKORDER_NOT_FOLLOWING: "SoldOut",
    BACKORDER_FOLLOWING: "SoldOutFollowing",
    NOT_AVAILABLE: "Out-of-stock",
  };

  return availability ? status[availability] : availability;
};

// get status for stars
const getStatusForStars = (userHasStars: boolean, sku: Sku | null): string | undefined =>
  userHasStars && sku && sku.isStarsNew ? "Stars" : undefined;

// get category from attribute PRODUCT_CATEGORY_CHIPS or PRODUCT_CATEGORY_FILTER
export const getProductCategory = (
  sku: Sku | null,
  fallback?: Product | Variant | Sku | null
): string => {
  let category: string | undefined = sku?.productCategoryFilter?.valuesIdentifier; // get category from appropriate object property

  // if not found, try and get it from sku's attributes
  if (!category && sku?.attributes) {
    const catValuesFilters = getAttribute(sku?.attributes, "PRODUCT_CATEGORY_FILTER")?.values;
    const catValuesChips = getAttribute(sku?.attributes, "PRODUCT_CATEGORY_CHIPS")?.values;
    category = catValuesFilters?.[0]?.identifier ?? catValuesChips?.[0]?.identifier;
  }

  // attempt at fallback, potentially to variant or product, if no attributes were found in sku
  if ((!category || categoriesToFallback.includes(category)) && fallback?.attributes) {
    const fallbackCategory = fallback?.productCategoryFilter?.valuesIdentifier;
    const fallbackChips = getAttribute(fallback?.attributes, "PRODUCT_CATEGORY_CHIPS")?.values;
    const fallbackFilters = getAttribute(fallback?.attributes, "PRODUCT_CATEGORY_FILTER")?.values;
    category =
      fallbackCategory ?? fallbackChips?.[0]?.identifier ?? fallbackFilters?.[0]?.identifier;
  }

  return getProductCategoryMappedValue(category ?? "", sku);
};

// get analytics value from B2B product category
const getProductCategoryMappedValue = (category: string, sku: Sku | null = null): string => {
  const isSpareParts = sku ? instanceOfSparePartsSku(sku) : false;
  const categoriesMap: { [key: string]: string } = {
    Eyeglasses: "OPTICS",
    "Eyeglasses Kids": "OPTICS",
    "Goggles&Helmets": "EYEWEAR",
    Sunglasses: "SUN",
    "Sunglasses Kids": "SUN",
    "Eyewear Accessories": "ACCESSORIES",
    SpareParts: "SPAREPARTS",
    "Smart Glasses": "ELECTRONICS",
    AFA: "AFA",
    Helmet: "Helmet",
    "Electronics Audio Optical": "ELECTRONICS",
    "Electronics Audio Nuance": "ELECTRONICS",
    "Electronics Accessories": "ACCESSORIES",
    Dummy: "ELECTRONICS",
  };

  const _category = isSpareParts ? "SpareParts" : category;
  return _category ? categoriesMap[_category] : category;
};

export const getFilters = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const filters: string[] = [];

  urlSearchParams.forEach((value, key) => {
    if (key !== "analytics_steps") {
      let filter = "";
      if (key === "FRAME_SIZE_FACET") {
        filter = `caliber=${value.replace("---", "-")}`;
      } else if (key === "BRIDGE_WIDTH") {
        filter = `BRIDGE_SIZE=${value}`;
      } else {
        filter = `${key}=${value}`;
      }
      filters.push(filter);
    }
  });

  const joinedFilters = filters.join("|");

  return joinedFilters.toLowerCase();
};

export const getSizes = (sizes: SizesData): string => {
  return `caliber=${sizes.Min}-${sizes.Max}`;
};

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////// PAGE-SPECIFIC UTILS ///////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Checks all order items for a carnet
 *
 * @param {OrderMultidoor[]} doors
 * @return {*}  {boolean}
 */
export const doesOrderHaveCarnets = (doors: OrderMultidoor[]): boolean => {
  let foundCarnet = false;

  for (let doorIndex = 0; doorIndex < doors.length; doorIndex++) {
    if (foundCarnet) break;
    const door = doors?.[doorIndex];

    for (let categoryIndex = 0; categoryIndex < door?.categoryList.length; categoryIndex++) {
      if (foundCarnet) break;
      const category = door?.categoryList?.[categoryIndex];

      for (let itemIndex = 0; itemIndex < category?.orderItemList.length; itemIndex++) {
        if (foundCarnet) break;
        if (category?.orderItemList?.[itemIndex]?.carnet?.carnetCode) foundCarnet = true;
      }
    }
  }

  return foundCarnet;
};
