export enum CustomFacet {
  TempleColor = "TEMPLE_COLOR_FACET",
  TempleColorGH = "G_H_TEMPLE_COLOR_FACET",
  LensColor = "LENS_COLOR_FACET",
  FrontColor = "FRONT_COLOR_FACET",
  FrameShape = "FRAME_SHAPE_FACET",
}

export enum ColorPalette {
  BLACK = "#111111",
  GREY = "#7b7b7b",
  GRAY = "#7b7b7b",
  SILVER = "linear-gradient(-27deg, white, #f3f3f3, white, #dadada, white)",
  WHITE = "#ffffff",
  BROWN = "#633a11",
  TORTOISE = "radial-gradient(#cc9b2e, #9c5b17, #734b0f, #ffd880)",
  BEIGE = "#dcc5a4",
  COPPER = "linear-gradient(-45deg, #cd8c7b, #f3f3f3, #cd8c7b)",
  GOLD = "linear-gradient(-27deg, yellow, #f3d63d, #fbee2e, #eaba4e)",
  YELLOW = "#efe439",
  GREEN = "#39b54a",
  BLUE = "#0072bc",
  VIOLET = "#993399",
  PINK = "#ff99cc",
  RED = "#f02e2e",
  ORANGE = "#ff9933",
  TRANSPARENT = "Transparent",
  CLEAR = "Transparent",
  MULTICOLOR = "linear-gradient(-27deg, green 20%, purple, red, orange, yellow)",
}

export enum FrameShape {
  CATEYE = "CatEye",
  GEOMETRICAL = "Geometrical",
  OVAL = "Oval",
  PILOT = "Pilot",
  RECTANGLE = "Rectangle",
  ROUND = "Round",
  SQUARE = "Square",
}
