import React, { useEffect, useRef } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomText from "../../styled-UI/CustomText";
import Button from "../../styled-UI/Button";
import CustomProgressiveImage from "../../styled-UI/CustomProgressiveImage";
import TileTags from "../Tile/TileTags";
import PriceTile from "../PriceTile";
import {
  resetLpVariantsList,
  togglePopupLpVariants,
  selectStickyBarType,
} from "../../../store/store/storeSlice";
import { LpColourProductTileInterface } from "../../../interfaces/cmsInterfaces";
import { getPagePath } from "../../../routing/routesUtils";
import { showTileCms } from "../../../utils/cmsUtils";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  data: LpColourProductTileInterface;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}
const LpColourProductTile = ({
  data,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const { model } = data;
  const history = useHistory();
  const dispatch = useDispatch();

  const { translateLabel } = useTranslation();
  const stickyBarType = useSelector(selectStickyBarType);
  const ref: any = useRef();

  useEffect(() => {
    const showTile = showTileCms(stickyBarType, model);
    if (showTile === false) {
      ref.current.parentElement.parentElement.style = "display: none";
    } else {
      ref.current.parentElement.parentElement.style = "display: block";
    }
  }, [stickyBarType]);

  const onClickSeeAllVariants = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(togglePopupLpVariants({ open: true, productCode: model.productCode }));
  };

  const handleClick = () => {
    if (data.inAssortment) {
      dispatch(togglePopupLpVariants({ open: false, productCode: null }));
      dispatch(resetLpVariantsList());
      history.push(getPagePath("/pdp" + model.seo?.href));
    }
  };

  return (
    <Container onClick={handleClick} ref={ref}>
      {model && ( //model is necessary to print the tile
        <>
          <InnerContainer>
            <ImageContainer>
              <CustomProgressiveImage src={model.img ?? ""} type="glasses" />
            </ImageContainer>
            <BoxProductName>
              <CustomText
                as="p"
                font={customFont ? "custom-medium" : "font-medium"}
                fontFamily={customFont}
                fontSizePx={24}
                lineHeightPx={24}
                marginTopPx={8}
                marginBottomPx={2}
                color={isDarkMode ? "white" : "primary"}
              >
                {model.brand && translateLabel(`${model.brand}_LABEL`) + " "}
              </CustomText>
              <CustomText
                as="p"
                font={customFont ? "custom-regular" : "font-regular"}
                fontFamily={customFont}
                fontSizePx={18}
                marginTopPx={2}
              >
                {model.productCode}
              </CustomText>
            </BoxProductName>
            <TagsContainer>
              <TileTags tags={model.tags} brand={model.brand ?? ""} />
            </TagsContainer>
          </InnerContainer>

          <InnerContainer>
            {data?.inAssortment ? (
              <PriceTile price={model.price} isDarkMode={isDarkMode} />
            ) : (
              <CustomText
                as="h1"
                color={isDarkMode ? "white" : "primary"}
                fontSizePx={13}
                font={customFont ? "custom-bold" : "font-bold"}
                fontFamily={customFont}
                textAlign="center"
              >
                {translateLabel("LANDING_PAGE_NO_ASSORTMENT")}
              </CustomText>
            )}

            {data?.inAssortment && (
              <FooterContainer>
                <Button
                  type={isDarkMode ? "primary-dark" : "primary"}
                  onClick={onClickSeeAllVariants}
                  fullWidth
                  customFont={customFont}
                >
                  {translateLabel("VIEW_ALL_COLORS")}
                </Button>
              </FooterContainer>
            )}
          </InnerContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  height: 100%; // 29.625rem;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.light};
  cursor: pointer;
  justify-content: space-between;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 100%;

  img {
    width: 100%;
    mix-blend-mode: multiply;
  }
`;

const BoxProductName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TagsContainer = styled.div`
  min-height: 3rem;
  max-height: 5rem;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1.625rem;
  flex-grow: 1;
`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.125rem;
`;

export default LpColourProductTile;
