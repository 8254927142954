import { BFFApiDomain } from "../serviceUtils";

export const submitBrandListUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/pos/file";

export const submitTransportDocumentsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/doors/{doorId}/deliveryDocs";

export const downloadTransportDocumentUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/doors/deliveryDocs/opentext";

export const itemMasterDataUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/itemMasterData/fields";

export const sendItemMasterDataUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/itemMasterData/report/send";

export const saveItemMasterDataUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/itemMasterData/report/save";

export const getArticlePriceInformationUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/articlePriceInformation";
export const getVideoPlaylistUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/digitalWindow/playlists";

export const getDigitalWindowProductsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/digitalWindow/products";

export const getDigitalDiscountsVoucherUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/sso/digitalDiscountVoucher";
