import React from "react";

import styled from "styled-components/macro";

import clsx from "clsx";

import { v4 as uuidv4 } from "uuid";
import useTranslation from "../../../../hooks/useTranslation";
import { FontFamilies } from "../../../../store/store/storeInterfaces";

export interface LpButtonGroupElement {
  value: string;
  label: string;
}

interface Props {
  variant: "medium-gray";
  buttonsLabels: LpButtonGroupElement[];
  selected: string;
  handleClick: (value: string) => void;
  customFont?: FontFamilies | null;
}

const LpButtonGroup = ({
  variant,
  buttonsLabels,
  selected,
  handleClick,
  customFont = null,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  return (
    <StyledButtonGroup className={clsx(variant === "medium-gray" && "medium-gray")}>
      {buttonsLabels.length &&
        buttonsLabels.map((button) => (
          <StyledButton
            key={uuidv4()}
            className={clsx(selected === button.value && "selected")}
            onClick={() => handleClick(button.value)}
            customFont={customFont}
          >
            {translateLabel(button.label)}
          </StyledButton>
        ))}
    </StyledButtonGroup>
  );
};

const StyledButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem;
  padding: 0.3rem 0.3125rem;

  &.medium-gray {
    background-color: ${(props) => props.theme.palette.gray.medium};
  }
`;

const StyledButton = styled.button<{ customFont: FontFamilies | null }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-family: ${(props) =>
    props.customFont
      ? props.customFont + " " + props.theme.fonts.customMedium
      : props.theme.fonts.fontMedium};
  text-transform: uppercase;
  border-radius: 1rem;
  padding: 0.2rem 1rem;
  background-color: transparent;
  color: ${(props) => props.theme.palette.primary};
  &.selected {
    background-color: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.white};
  }
`;

export default LpButtonGroup;
