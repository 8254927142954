import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest, delay } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/types";

import { handleError, showErrorPopup } from "../store/storeSagas";
import {
  addTemporaryPaymentMethodsACH,
  addTemporaryPaymentMethodsCC,
  clearPaymentManagementViewAll,
  saveDeselectionReasons,
  savePaymentManagementSelectedMethod,
  savePaymentConfirmationResponse,
  savePaymentDocumentHistory,
  savePaymentHistoryDetail,
  savePaymentHistoryList,
  savePaymentManagementDocumentsList,
  savePaymentManagementOverview,
  savePaymentMethodDetail,
  savePaymentMethods,
  savePaymentSimulationResponse,
  savePaymentStatement,
  selectDeselectedDocs,
  selectDeselectedDocsReason,
  selectPaymentCurrencyFormat,
  selectPaymentManagementSelected,
  selectPaymentSimulationResponse,
  selectSelectedDocs,
  setCurrency,
  setPaymentManagementInfoStatus,
  setPaymentMethodsStatus,
  setPaymentProcessStatus,
  setPaymentSelectLoaderStatus,
  setPaymentTableStatus,
  sliceName,
  savePaymentMethodListCountries,
  setPaymentErrors,
  setPaymentPolicyStatus,
} from "./paymentSlice";

import {
  mapDeselectionReasons,
  mapPaymentConfirmationResponse,
  mapPaymentDocumentHistoryList,
  mapPaymentHistoryDetails,
  mapPaymentHistoryList,
  mapPaymentManagementDocumentsList,
  mapPaymentManagementOverviewTotals,
  mapPaymentMethodDetailToModal,
  mapPaymentMethodOldB2B,
  mapPaymentSimulationRequest,
  mapPaymentSimulationResponse,
  mapPaymentStatementList,
  mapPaymentMethodCountriesList,
  checkForPaymentError,
  // mapPaymentManagementSimulationDocs,
  // mapPaymentMethodsV1,
} from "../../utils/paymentUtils";

import {
  PaymentMethodModalPayload,
  DeletePaymentMethodPayload,
  GetPaymentMethodDetailRequest,
  PaymentConfirmationSagaPayload,
  PaymentDeselectionReasons,
  PaymentFilterPayload,
  PaymentHistoryDetailRequestPayload,
  PaymentManagementOpenDocument,
  PaymentManagementSelected,
  PaymentManagementSimulationDoc,
  PaymentPayload,
  PaymentSimulationResponse,
  PaymentSimulationSagaPayload,
  EditPaymentMethodOldB2BServicePayload,
  AddPaymentMethodOldB2BServicePayload,
  GetPaymentMethodsPayload,
  PaymentMethodSubscriptionType,
  SavePaymentPolicyAgreementPayload,
  DownloadPaymentDocumentPayload,
  AddPaymentMethodOldB2BServiceRequest,
  EditPaymentMethodOldB2BServiceRequest,
} from "./paymentInterface";
import paymentService from "./paymentService";
import { selectIsBackOfficeUser } from "../user/userSlice";
import documentsService from "../documents/documentsService";
import { CurrencyFormat } from "../user/userInterfaces";
import profileService from "../profile/profileServices";
import { getDocumentFromType } from "../../utils/utils";
import { DEFAULT_PAYMENT_ERROR } from "../../components/pages/payment/payment-guard/PaymentErrorsConfig";
import { selectOrgentityDoor } from "../accounting/accountingSlice";

// import {
//   getDeselectionReasonsMock,
//   getPaymentStatementMock,
//   getPaymentDocumentHistoryMock,
//   getPaymentHistoryMock,
//   getPaymentHistoryDetailsMock,
// } from "./paymentMocks";
// import {
//   getPaymentMethodsOldB2BBank,
//   getPaymentMethodsOldB2BCard,
//   getPaymentMethodsV1CybersourceMock,
//   getPaymentMethodsV1Mock,
// } from "./paymentMethodsMocks";
// import { getPaymentManagementInformationMock } from "./paymentManagamentMocks";

/************************************ ACTIONS ************************************/

////////// PAYMENT MANAGEMENT
export const getPaymentManagementInformation = createAction<string>(
  sliceName + "/getPaymentManagementInformation"
);
export const getDeselectionReasons = createAction(sliceName + "/getDeselectionReasons");
export const simulatePayment = createAction<PaymentSimulationSagaPayload>(
  sliceName + "/simulatePayment"
);
export const confirmPayment = createAction<PaymentConfirmationSagaPayload>(
  sliceName + "/confirmPayment"
);

////////// PAYMENT METHODS
export const getPaymentMethodValidation = createAction(sliceName + "/getPaymentMethodValidation");
export const getPaymentMethods = createAction<GetPaymentMethodsPayload>(
  sliceName + "/getPaymentMethods"
);
export const gePaymentMethodDetail = createAction<GetPaymentMethodDetailRequest>(
  sliceName + "/gePaymentMethodDetail"
);
export const addPaymentMethod = createAction<PaymentMethodModalPayload>(
  sliceName + "/addPaymentMethod"
);
export const editPaymentMethod = createAction<PaymentMethodModalPayload>(
  sliceName + "/editPaymentMethod"
);
export const deletePaymentMethod = createAction<DeletePaymentMethodPayload>(
  sliceName + "/deletePaymentMethod"
);

////////// PAYMENT HISTORY
export const getPaymentHistoryList = createAction<PaymentFilterPayload>(
  sliceName + "/getPaymentHistoryList"
);

////////// PAYMENT HISTORY DETAILS
export const getPaymentHistoryDetails = createAction<PaymentHistoryDetailRequestPayload>(
  sliceName + "/getPaymentHistoryDetails"
);

////////// PAYMENT DOCUMENT HISTORY
export const getPaymentDocumentHistoryList = createAction<PaymentFilterPayload>(
  sliceName + "/getPaymentDocumentHistoryList"
);

////////// PAYMENT STATEMENT
export const getPaymentStatementList = createAction<PaymentPayload>(
  sliceName + "/getPaymentStatementList"
);

////////// MISC
export const getPaymentPolicyStatus = createAction(sliceName + "/getPaymentPolicyStatus");
export const savePaymentPolicyAgreement = createAction<SavePaymentPolicyAgreementPayload>(
  sliceName + "/savePaymentPolicyAgreement"
);
export const downloadPaymentDocument = createAction<DownloadPaymentDocumentPayload>(
  sliceName + "/downloadPaymentDocument"
);

/************************************ SAGAS ************************************/

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// PAYMENT MANAGEMENT //////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getPaymentManagementInformationSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setPaymentManagementInfoStatus("LOADING"));
    const { data } = yield call(paymentService.getPaymentManagementInformation, {});
    // const data = getPaymentManagementInformationMock;

    const paymentManagementOverview = mapPaymentManagementOverviewTotals(data?.data?.output);
    const { payNowList, statementList, netBalanceList } = mapPaymentManagementDocumentsList(
      data?.data?.output
    );

    const error = checkForPaymentError(
      data?.data,
      // { ...data?.data, error: { errorCode: "PAYMENT_LOCKED" } },
      "banner"
    );

    yield put(savePaymentManagementOverview(paymentManagementOverview));
    yield put(
      savePaymentManagementDocumentsList({ list: payNowList ?? [], listName: "payNowList" })
    );
    yield put(
      savePaymentManagementDocumentsList({ list: statementList ?? [], listName: "statementList" })
    );
    yield put(
      savePaymentManagementDocumentsList({ list: netBalanceList ?? [], listName: "netBalanceList" })
    );

    yield put(setCurrency(data?.data?.output?.Currency));

    if (!error) {
      // only if no error was mapped set status as SUCCESS
      yield put(setPaymentManagementInfoStatus("SUCCESS"));
    } else {
      // otherwise set status as ERROR and save Payment Error
      yield put(setPaymentManagementInfoStatus(error.stopExecution ? "ERROR" : "SUCCESS"));
      yield put(setPaymentErrors({ type: "banner", error: error }));
    }
  } catch (error) {
    yield put(setPaymentManagementInfoStatus("ERROR"));

    if (error?.code !== "ECONNABORTED")
      yield put(setPaymentErrors({ type: "banner", error: DEFAULT_PAYMENT_ERROR }));

    yield put(clearPaymentManagementViewAll());
    yield put(handleError(error));
  }
}

function* getDeselectionReasonsSaga(): SagaIterator {
  try {
    yield put(setPaymentSelectLoaderStatus("LOADING"));
    const orgentityDoor: string = yield select(selectOrgentityDoor);
    const { data } = yield call(paymentService.getDeselectionReasons, orgentityDoor);

    const deselectionReasons = mapDeselectionReasons(data?.data);
    yield put(saveDeselectionReasons(deselectionReasons));

    yield put(setPaymentSelectLoaderStatus("SUCCESS"));
  } catch (error) {
    if (error?.response?.status === 403) {
      yield put(
        showErrorPopup({
          status: 200,
          message: "PAYMENT_ERROR_DOUBLE_PAYMENT",
          showButton: true,
          descriptionButton: "PAYMENT_CONFIRM",
        })
      );
    }
    yield put(setPaymentSelectLoaderStatus("ERROR"));
    yield put(saveDeselectionReasons([]));
    yield put(handleError(error));
  }
}

function* simulatePaymentSaga(action: PayloadAction<PaymentSimulationSagaPayload>): SagaIterator {
  try {
    yield put(setPaymentProcessStatus({ type: "simulation", status: "LOADING" }));

    const savedSelection: PaymentManagementSelected | null = yield select(
      selectPaymentManagementSelected
    );
    const selectedDocs: PaymentManagementOpenDocument[] = yield select(selectSelectedDocs);
    const deselectedDocs: PaymentManagementSimulationDoc[] = yield select(selectDeselectedDocs);
    const deselectionReasonCode: PaymentDeselectionReasons[] = yield select(
      selectDeselectedDocsReason
    );
    const currency: CurrencyFormat = yield select(selectPaymentCurrencyFormat);
    const isBackOfficeUser: boolean = yield select(selectIsBackOfficeUser);
    const orgentityDoor: string = yield select(selectOrgentityDoor);

    const requestPayload = mapPaymentSimulationRequest(
      orgentityDoor,
      savedSelection?.selectedPaymentType,
      selectedDocs,
      deselectedDocs,
      deselectionReasonCode,
      action.payload.paymentAmount,
      action.payload.paymentInfo,
      savedSelection?.selectedAdvancedPayment,
      isBackOfficeUser,
      currency
    );

    const { data } = yield call(paymentService.postPaymentSimulation, requestPayload);

    // const data = {
    //   data: {
    //     data: {
    //       amounts: {
    //         // dati da visualizzare nel recap
    //         currency: currency,
    //         discountedAmount: action.payload.paymentAmount - 50,
    //         eomDiscount: action.payload.paymentAmount - 20,
    //         fixDiscount: action.payload.paymentAmount - 30,
    //         paymentAmount: action.payload.paymentAmount,
    //       },
    //       errorMessageDescription: "",
    //       errorMessageTitle: "",
    //       errorType: "",
    //       hasErrors: false,
    //       lockedPayment: true, // se true -> possiamo confermare
    //       lockingSimulationId: "IMMAEVENFAKER",
    //       lockingUserType: 1,
    //       lockingUsername: "this bad person",
    //       negativeAmountToPay: false, // non ce ne facciamo niente
    //       simulationId: "IMMAFAKE", // passato alla confirmation
    //     },
    //   },
    // };

    const response = mapPaymentSimulationResponse(
      data?.data,
      action.payload.paymentInfo.subscriptionId,
      action.payload.paymentInfo
    );

    const error = checkForPaymentError(
      data?.data,
      // { ...data?.data, error: { errorCode: "PAYMENT_LOCKED" } },
      "simulation"
    );

    if (response) {
      yield put(savePaymentSimulationResponse(response));

      if (!error)
        // only if no error was mapped, set status as SUCCESS
        yield put(setPaymentProcessStatus({ type: "simulation", status: "SUCCESS" }));
      else {
        // otherwise set status as ERROR and save Payment Error
        yield put(setPaymentProcessStatus({ type: "simulation", status: "ERROR" }));
        yield put(setPaymentErrors({ type: "simulation", error: error }));
      }
    } else {
      // if no response was mapped, clean response, set status as ERROR
      yield put(savePaymentSimulationResponse(null));
      yield put(setPaymentProcessStatus({ type: "simulation", status: "ERROR" }));

      // save either the mapped error or the default one
      yield put(
        setPaymentErrors({
          type: "simulation",
          error: error ?? DEFAULT_PAYMENT_ERROR,
        })
      );
    }
  } catch (error) {
    if (error?.response?.status === 403) {
      yield put(
        showErrorPopup({
          status: 200,
          message: "PAYMENT_ERROR_DOUBLE_PAYMENT",
          showButton: true,
          descriptionButton: "PAYMENT_CONFIRM",
        })
      );
    }
    yield put(savePaymentSimulationResponse(null));
    yield put(setPaymentProcessStatus({ type: "simulation", status: "ERROR" }));
    yield put(
      setPaymentErrors({
        type: "simulation",
        error: DEFAULT_PAYMENT_ERROR,
      })
    );
    yield put(handleError(error));
  }
}

function* confirmPaymentSaga(action: PayloadAction<PaymentConfirmationSagaPayload>): SagaIterator {
  try {
    yield put(setPaymentProcessStatus({ type: "confirmation", status: "LOADING" }));
    const simulationResponse: PaymentSimulationResponse | null = yield select(
      selectPaymentSimulationResponse
    );
    const orgentityDoor: string = yield select(selectOrgentityDoor);

    // TODO: handle what happens if we are missing the simulation response?
    if (!simulationResponse) throw Error;

    const requestPayload = {
      ...action.payload,
      simulationId: simulationResponse.simulationId,
      subscriptionId: simulationResponse.subscriptionId,
    };

    const { data } = yield call(paymentService.postPaymentConfirmation, {
      qparams: { door: orgentityDoor },
      payload: requestPayload,
    });
    // yield delay(2000);

    // const selectedDocs: PaymentManagementOpenDocument[] = yield select(selectSelectedDocs);
    // const data = {
    //   status: 200,
    //   message: "OK",
    //   data: {
    //     data: {
    //       confirmationError: false,
    //       currency: "USD",
    //       currencySymbol: "USD",
    //       eomDiscount: simulationResponse?.summary.eomDiscount,
    //       fixDiscount: simulationResponse?.summary.fixDiscount,
    //       netAmount: simulationResponse?.summary.paymentAmount,
    //       paidDocuments: mapPaymentManagementSimulationDocs(selectedDocs, "USD"),
    //       paymentMethod: simulationResponse?.paymentMethod?.paymentMethod,
    //       simulationId: simulationResponse?.simulationId,
    //       totalPaidAmount: simulationResponse?.summary.discountedAmount,
    //     },
    //   },
    // };

    const response = mapPaymentConfirmationResponse(data?.data);
    const error = checkForPaymentError(
      data?.data,
      // { ...data?.data, error: { errorCode: "SAP_COMMUNICATION_ERROR" } },
      "confirmation"
    );

    if (response) {
      yield put(savePaymentConfirmationResponse(response)); // if there is a response, save it

      if (!error)
        // only if no error was mapped, set status as SUCCESS
        yield put(setPaymentProcessStatus({ type: "confirmation", status: "SUCCESS" }));
      else {
        // otherwise set status as ERROR and save Payment Error
        yield put(setPaymentProcessStatus({ type: "confirmation", status: "ERROR" }));
        yield put(setPaymentErrors({ type: "confirmation", error: error }));
      }
    } else {
      // if no response was mapped, clean response, set status as ERROR
      yield put(savePaymentConfirmationResponse(null));
      yield put(setPaymentProcessStatus({ type: "confirmation", status: "ERROR" }));

      // save either the mapped error or the default one
      yield put(
        setPaymentErrors({
          type: "confirmation",
          error: error ?? DEFAULT_PAYMENT_ERROR,
        })
      );
    }

    action.payload.callback?.(!!response && !error?.stopExecution);
  } catch (error) {
    if (error?.response?.status === 403) {
      yield put(
        showErrorPopup({
          status: 200,
          message: "PAYMENT_ERROR_DOUBLE_PAYMENT",
          showButton: true,
          descriptionButton: "PAYMENT_CONFIRM",
        })
      );
    }
    yield put(setPaymentProcessStatus({ type: "confirmation", status: "ERROR" }));
    yield put(
      setPaymentErrors({
        type: "confirmation",
        error: DEFAULT_PAYMENT_ERROR,
      })
    );
    yield put(savePaymentConfirmationResponse(null));
    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// PAYMENT METHODS ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getPaymentMethodValidationSaga(): SagaIterator {
  try {
    const orgentityDoor: string = yield select(selectOrgentityDoor);
    const { data } = yield call(paymentService.getPaymentMethodValidation, orgentityDoor);
    yield put(
      savePaymentMethodListCountries(mapPaymentMethodCountriesList(data.data.listCountries))
    );
  } catch (error) {
    if (error?.response?.status === 403) {
      yield put(
        showErrorPopup({
          status: 200,
          message: "PAYMENT_ERROR_DOUBLE_PAYMENT",
          showButton: true,
          descriptionButton: "PAYMENT_CONFIRM",
        })
      );
    }
    yield put(handleError(error));
  }
}

// NOTE THAT 1 IS STILL MOCKED AND NOT FULLY INTEGRATED!! DO NOT ACTIVATE UNLESS IT'S COMPLETED
const ACTIVE_PAYMENT_METHOD = 2 as 1 | 2; // 1 for proposal #1, 2 for proposal #2

function* getPaymentMethodsSaga(action: PayloadAction<GetPaymentMethodsPayload>): SagaIterator {
  const { hideListLoading, resetAllLoading } = action.payload;

  try {
    if (!hideListLoading)
      yield put(setPaymentMethodsStatus({ type: "getList", status: "LOADING" }));

    //////// proposal #1 - going through cybersource for payment info

    // if (ACTIVE_PAYMENT_METHOD === 1) {
    // const { data } = yield call(paymentService.getPaymentMethod);
    // const { data } = getPaymentMethodsV1Mock;

    // console.log("[getPaymentMethods #1] BE data:", data);

    // const fullData = data.data?.paymentMethodList?.map((_: any) => {
    //   const fakeCybersourceData = getPaymentMethodsV1CybersourceMock[_.subscriptionId];
    //   return {
    //     ..._,
    //     ...fakeCybersourceData,
    //   };
    // });

    // console.log("[getPaymentMethods #1] full data (w/ fake cybersource):", fullData);

    // yield put(savePaymentMethods(mapPaymentMethodsV1(fullData))); // mapping for V1 (only tokens from BE, will have to call cybersource for the rest)
    // }

    //////// proposal #2 - old B2B methods
    if (ACTIVE_PAYMENT_METHOD === 2) {
      const orgentityDoor = yield select(selectOrgentityDoor);
      const { data: cardData } = yield call(
        paymentService.getPaymentMethodOldB2BCard,
        orgentityDoor
      );
      const { data: bankData } = yield call(
        paymentService.getPaymentMethodOldB2BBank,
        orgentityDoor
      );

      // const { data: cardData } = getPaymentMethodsOldB2BCard;
      // const { data: bankData } = getPaymentMethodsOldB2BBank;

      const paymentMethodsCC = mapPaymentMethodOldB2B(
        cardData?.data?.data?.creditCards,
        "CreditCard"
      );
      const paymentMethodsACH = mapPaymentMethodOldB2B(
        bankData?.data?.data?.bankingAccounts,
        "AchAccount"
      );

      yield put(savePaymentMethods({ paymentMethodsCC, paymentMethodsACH }));
    }

    if (!hideListLoading)
      yield put(setPaymentMethodsStatus({ type: "getList", status: "SUCCESS" }));
    if (resetAllLoading) {
      yield put(setPaymentMethodsStatus({ type: "addOrEdit", status: "IDLE" }));
      yield put(setPaymentMethodsStatus({ type: "delete", status: "IDLE" }));
    }
  } catch (error) {
    if (error?.response?.status === 403) {
      yield put(
        showErrorPopup({
          status: 200,
          message: "PAYMENT_ERROR_DOUBLE_PAYMENT",
          showButton: true,
          descriptionButton: "PAYMENT_CONFIRM",
        })
      );
    }
    yield put(setPaymentMethodsStatus({ type: "getList", status: "ERROR" }));
    yield put(savePaymentMethods({ paymentMethodsCC: [], paymentMethodsACH: [] }));
    yield put(handleError(error));
  }
}

function* gePaymentMethodDetailSaga(
  action: PayloadAction<GetPaymentMethodDetailRequest>
): SagaIterator {
  try {
    yield put(setPaymentMethodsStatus({ type: "getEditModalDetails", status: "LOADING" }));
    const { data } = yield call(paymentService.getPaymentMethodOldB2BDetails, action.payload);

    const response = mapPaymentMethodDetailToModal(data?.data?.data, action.payload);

    const error = checkForPaymentError(
      data?.data,
      // { ...data?.data, error: { errorCode: "ERROR_RETRIEVE_CREDIT_CARD" } },
      "paymentMethodEdit"
    );

    if (response) {
      yield put(savePaymentMethodDetail(response)); // if there is a response, save it

      if (!error)
        // only if no error was mapped, set status as SUCCESS
        yield put(setPaymentMethodsStatus({ type: "getEditModalDetails", status: "SUCCESS" }));
      else {
        // otherwise set status as ERROR and save Payment Error
        yield put(setPaymentMethodsStatus({ type: "getEditModalDetails", status: "ERROR" }));
        yield put(setPaymentErrors({ type: "paymentMethodEdit", error: error }));
      }
    } else {
      // if no response was mapped, clean response, set status as ERROR
      yield put(savePaymentMethodDetail(null));
      yield put(setPaymentMethodsStatus({ type: "getEditModalDetails", status: "ERROR" }));

      // save either the mapped error or the default one
      yield put(
        setPaymentErrors({
          type: "paymentMethodEdit",
          error: error ?? DEFAULT_PAYMENT_ERROR,
        })
      );
    }
  } catch (error) {
    if (error?.response?.status === 403) {
      yield put(
        showErrorPopup({
          status: 200,
          message: "PAYMENT_ERROR_DOUBLE_PAYMENT",
          showButton: true,
          descriptionButton: "PAYMENT_CONFIRM",
        })
      );
    }
    yield put(handleError(error));
    yield put(savePaymentMethodDetail(null)); // if there is a response, save it

    yield put(setPaymentMethodsStatus({ type: "getEditModalDetails", status: "ERROR" }));
    yield put(
      setPaymentErrors({
        type: "paymentMethodEdit",
        error: DEFAULT_PAYMENT_ERROR,
      })
    );
  }
}

function* addPaymentMethodSaga(action: PayloadAction<PaymentMethodModalPayload>): SagaIterator {
  const { data, callback, isTemporary, useType } = action.payload;

  try {
    yield put(setPaymentMethodsStatus({ type: "addOrEdit", status: "LOADING" }));
    if (!action.payload) throw Error;
    const orgentityDoor = yield select(selectOrgentityDoor);

    const { data: response } = yield call(paymentService.addPaymentMethodOldB2B, {
      qparams: { door: orgentityDoor },
      payload: data as AddPaymentMethodOldB2BServicePayload,
    } as AddPaymentMethodOldB2BServiceRequest);

    const method = mapPaymentMethodOldB2B(
      data?.subscriptionType === "CreditCard"
        ? response?.data?.data?.creditCards
        : response?.data?.data?.bankingAccounts,
      data?.subscriptionType as PaymentMethodSubscriptionType
    );

    const error = checkForPaymentError(
      response?.data,
      // { ...response?.data, error: { errorCode: "CANNOT_SUBSCRIBE_CARD" } },
      "paymentMethodSave"
    );

    if (method && method?.length > 0) {
      if (isTemporary) {
        // for temporary methods, which will not be returned by the API because isVisible = false,
        // we need to store them and show them manually

        if (method.length > 0)
          switch (data?.subscriptionType) {
            case "AchAccount":
              yield put(addTemporaryPaymentMethodsACH(method));
              break;
            case "CreditCard":
              yield put(addTemporaryPaymentMethodsCC(method));
              break;
          }
      }

      if (useType === "payment-management") {
        yield put(
          savePaymentManagementSelectedMethod({
            type: data?.subscriptionType as PaymentMethodSubscriptionType,
            method: method?.[0],
          })
        );
      }

      if (!error)
        // only if no error was mapped, set status as SUCCESS
        yield put(setPaymentMethodsStatus({ type: "addOrEdit", status: "SUCCESS" }));
      else {
        // otherwise set status as ERROR and save Payment Error
        yield put(setPaymentMethodsStatus({ type: "addOrEdit", status: "ERROR" }));
        yield put(setPaymentErrors({ type: "paymentMethodSave", error: error }));
      }
    } else {
      // if no response was mapped, set status as ERROR
      yield put(setPaymentMethodsStatus({ type: "addOrEdit", status: "ERROR" }));

      // save either the mapped error or the default one
      yield put(
        setPaymentErrors({
          type: "confirmation",
          error: error ?? DEFAULT_PAYMENT_ERROR,
        })
      );
    }

    callback?.(method && method?.length > 0 && !error?.stopExecution, method?.[0]);
  } catch (error) {
    if (error?.response?.status === 403) {
      yield put(
        showErrorPopup({
          status: 200,
          message: "PAYMENT_ERROR_DOUBLE_PAYMENT",
          showButton: true,
          descriptionButton: "PAYMENT_CONFIRM",
        })
      );
    }
    yield put(setPaymentMethodsStatus({ type: "addOrEdit", status: "ERROR" }));
    yield put(
      setPaymentErrors({
        type: "paymentMethodSave",
        error: DEFAULT_PAYMENT_ERROR,
      })
    );
    yield put(handleError(error));
    callback?.(false);
  }
}

function* editPaymentMethodSaga(action: PayloadAction<PaymentMethodModalPayload>): SagaIterator {
  const { data, callback, isSetPreferred } = action.payload;
  const errorLocation = isSetPreferred ? "paymentMethodPreferred" : "paymentMethodSave";

  try {
    yield put(setPaymentMethodsStatus({ type: "addOrEdit", status: "LOADING" }));
    if (!action.payload) throw Error;
    const orgentityDoor = yield select(selectOrgentityDoor);

    const { response } = yield call(paymentService.editPaymentMethodOldB2B, {
      qparams: { door: orgentityDoor },
      payload: data as EditPaymentMethodOldB2BServicePayload,
    } as EditPaymentMethodOldB2BServiceRequest);

    const error = checkForPaymentError(
      response?.data,
      // {
      //   ...response?.data,
      //   error: isSetPreferred
      //     ? { errorCode: "CANNOT_SET_PREFERRED_CARD" }
      //     : { errorCode: "CANNOT_SUBSCRIBE_CARD" },
      // },
      errorLocation
    );

    if (!error) {
      // only if no error was mapped, and we are editing the method (not just whether its preferred) set status as SUCCESS
      if (!isSetPreferred)
        yield put(setPaymentMethodsStatus({ type: "addOrEdit", status: "SUCCESS" }));
    } else {
      // otherwise set status as ERROR and save Payment Error
      yield put(setPaymentMethodsStatus({ type: "addOrEdit", status: "ERROR" }));
      yield put(setPaymentErrors({ type: errorLocation, error: error }));
    }

    callback?.(!error?.stopExecution);
  } catch (error) {
    if (error?.response?.status === 403) {
      yield put(
        showErrorPopup({
          status: 200,
          message: "PAYMENT_ERROR_DOUBLE_PAYMENT",
          showButton: true,
          descriptionButton: "PAYMENT_CONFIRM",
        })
      );
    }
    if (!isSetPreferred) yield put(setPaymentMethodsStatus({ type: "addOrEdit", status: "ERROR" }));
    yield put(
      setPaymentErrors({
        type: errorLocation,
        error: DEFAULT_PAYMENT_ERROR,
      })
    );
    yield put(handleError(error));
    callback?.(false);
  }
}

function* deletePaymentMethodSaga(action: PayloadAction<DeletePaymentMethodPayload>): SagaIterator {
  const { callback, ...rest } = action.payload.payload;

  try {
    yield put(setPaymentMethodsStatus({ type: "delete", status: "LOADING" }));
    const orgentityDoor: string = yield select(selectOrgentityDoor);
    const { response } = yield call(paymentService.deletePaymentMethodOldB2B, {
      qparams: { door: orgentityDoor },
      payload: rest,
    });

    const error = checkForPaymentError(
      response?.data,
      // { ...response?.data, error: { errorCode: "CANNOT_DELETE_CARD" } },
      "paymentMethodDelete"
    );

    if (!error) {
      // only if no error was mapped set status as SUCCESS
      yield put(setPaymentMethodsStatus({ type: "delete", status: "SUCCESS" }));
    } else {
      // otherwise set status as ERROR and save Payment Error
      yield put(setPaymentMethodsStatus({ type: "delete", status: "ERROR" }));
      yield put(setPaymentErrors({ type: "paymentMethodDelete", error: error }));
    }

    callback?.(!error?.stopExecution);
  } catch (error) {
    if (error?.response?.status === 403) {
      yield put(
        showErrorPopup({
          status: 200,
          message: "PAYMENT_ERROR_DOUBLE_PAYMENT",
          showButton: true,
          descriptionButton: "PAYMENT_CONFIRM",
        })
      );
    }
    yield put(setPaymentMethodsStatus({ type: "delete", status: "ERROR" }));
    yield put(setPaymentErrors({ type: "paymentMethodDelete", error: DEFAULT_PAYMENT_ERROR }));
    yield put(handleError(error));
    callback?.(false);
  }
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// PAYMENT HISTORY ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getPaymentHistoryListSaga(action: PayloadAction<PaymentFilterPayload>): SagaIterator {
  try {
    yield put(setPaymentTableStatus("LOADING"));
    const { data } = yield call(paymentService.postPaymentHistoryList, action.payload);
    // const data = getPaymentHistoryMock;

    const response = mapPaymentHistoryList(data?.data?.output);

    const error = checkForPaymentError(
      data?.data,
      // { ...data?.data, error: { errorCode: "PAYMENT_TO_BE_RESENT_DOC_HISTORY" } },
      "banner"
    );

    yield put(savePaymentHistoryList(response)); // save response

    // set table status based on response
    if (response?.length) yield put(setPaymentTableStatus("SUCCESS"));
    else yield put(setPaymentTableStatus("ERROR"));

    if (error) yield put(setPaymentErrors({ type: "banner", error: error })); // if there's an error, save it
  } catch (error) {
    yield put(setPaymentTableStatus("ERROR"));
    yield put(savePaymentHistoryList([]));

    if (error?.code !== "ECONNABORTED")
      yield put(setPaymentErrors({ type: "banner", error: DEFAULT_PAYMENT_ERROR })); // if there's an error, save it

    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////// PAYMENT HISTORY DETAILS ///////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getPaymentHistoryDetailsSaga(
  action: PayloadAction<PaymentHistoryDetailRequestPayload>
): SagaIterator {
  try {
    yield put(setPaymentTableStatus("LOADING"));
    const { data } = yield call(paymentService.postPaymentHistoryDetails, action.payload);
    // const data = getPaymentHistoryDetailsMock;

    const response = mapPaymentHistoryDetails(data?.data?.output);

    const error = checkForPaymentError(
      data?.data,
      // { ...data?.data, error: { errorCode: "PAYMENT_TO_BE_RESENT_DOC_HISTORY" } },
      "banner"
    );

    yield put(savePaymentHistoryDetail(response)); // save response

    // set table status based on response
    if (response) yield put(setPaymentTableStatus("SUCCESS"));
    else yield put(setPaymentTableStatus("ERROR"));

    if (error) yield put(setPaymentErrors({ type: "banner", error: error })); // if there's an error, save it
  } catch (error) {
    yield put(setPaymentTableStatus("ERROR"));
    yield put(savePaymentHistoryDetail(null));

    if (error?.code !== "ECONNABORTED")
      yield put(setPaymentErrors({ type: "banner", error: DEFAULT_PAYMENT_ERROR })); // if there's an error, save it

    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// DOCUMENT HISTORY ///////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getPaymentDocumentHistoryListSaga(
  action: PayloadAction<PaymentFilterPayload>
): SagaIterator {
  try {
    yield put(setPaymentTableStatus("LOADING"));
    const { data } = yield call(paymentService.postPaymentDocumentHistoryList, action.payload);
    // const data = getPaymentDocumentHistoryMock;

    const response = mapPaymentDocumentHistoryList(data?.data?.output);

    const error = checkForPaymentError(
      data?.data,
      // { ...data?.data, error: { errorCode: "PAYMENT_TO_BE_RESENT_DOC_HISTORY" } },
      "banner"
    );

    yield put(savePaymentDocumentHistory(response)); // save response

    // set table status based on response
    if (response?.length) yield put(setPaymentTableStatus("SUCCESS"));
    else yield put(setPaymentTableStatus("ERROR"));

    if (error) yield put(setPaymentErrors({ type: "banner", error: error })); // if there's an error, save it
  } catch (error) {
    yield put(setPaymentTableStatus("ERROR"));
    yield put(savePaymentDocumentHistory([]));

    if (error?.code !== "ECONNABORTED")
      yield put(setPaymentErrors({ type: "banner", error: DEFAULT_PAYMENT_ERROR })); // if there's an error, save it

    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// STATEMENT ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getPaymentStatementListSaga(action: PayloadAction<PaymentPayload>): SagaIterator {
  try {
    yield put(setPaymentTableStatus("LOADING"));
    const { data } = yield call(paymentService.postPaymentStatementList, action.payload);
    // const { data } = getPaymentStatementMock;
    const response = mapPaymentStatementList(data?.data?.output);

    const error = checkForPaymentError(
      data?.data,
      // { ...data?.data, error: { errorCode: "PAYMENT_TO_BE_RESENT_DOC_HISTORY" } },
      "banner"
    );

    yield put(savePaymentStatement(response)); // save response

    // set table status based on response
    if (response?.length) yield put(setPaymentTableStatus("SUCCESS"));
    else yield put(setPaymentTableStatus("ERROR"));

    if (error) yield put(setPaymentErrors({ type: "banner", error: error })); // if there's an error, save it
  } catch (error) {
    yield put(setPaymentTableStatus("ERROR"));

    if (error?.code !== "ECONNABORTED")
      yield put(setPaymentErrors({ type: "banner", error: DEFAULT_PAYMENT_ERROR })); // if there's an error, save it

    yield put(savePaymentStatement([]));
    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// PAYMENT POLICY ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getPaymentPolicyStatusSaga(): SagaIterator {
  const isBackOfficeUser = yield select(selectIsBackOfficeUser);
  try {
    if (isBackOfficeUser)
      // backoffice users ignore the policy
      yield put(setPaymentPolicyStatus({ isAccepted: true, status: "SUCCESS" }));
    else {
      yield put(setPaymentPolicyStatus({ isAccepted: false, status: "LOADING" }));

      const { data } = yield call(profileService.getPrivateUserPolicy);

      const policyStatus =
        data.data?.contextAttribute?.filter((_: any) => _.attributeName === "paymentPolicy")?.[0]
          ?.attributeValue?.[0]?.value?.[0] === "1";

      yield put(setPaymentPolicyStatus({ isAccepted: policyStatus, status: "SUCCESS" }));
    }
  } catch (error) {
    yield put(setPaymentPolicyStatus({ isAccepted: false, status: "ERROR" }));
    yield put(handleError(error));
  }
}

function* savePaymentPolicyAgreementSaga(
  action: PayloadAction<SavePaymentPolicyAgreementPayload>
): SagaIterator {
  const { value, callback } = action.payload;

  try {
    const payload = {
      attributName: "paymentPolicy",
      attributValue: value,
    };

    yield call(profileService.putGenericPrivateUserPolicy, [payload]);
    yield put(setPaymentPolicyStatus({ isAccepted: true, status: "SUCCESS" }));

    callback(true);
  } catch (error) {
    yield put(handleError(error));
    callback(false);
  }
}

function* downloadPaymentDocumentSaga(
  action: PayloadAction<DownloadPaymentDocumentPayload>
): SagaIterator {
  const { docType, callback } = action.payload;

  try {
    const { data } = yield call(documentsService.getDocumentList, {
      docType,
    });

    const documentsList = data.data.result ?? [];
    const document = getDocumentFromType(documentsList, docType)?.data.uri;

    callback(document !== undefined, document);
  } catch (error) {
    callback(false);
    yield put(handleError(error));
  }
}

export function* paymentSaga(): SagaIterator {
  ////////// PAYMENT MANAGEMENT
  yield takeLatest(getPaymentManagementInformation.type, getPaymentManagementInformationSaga);
  yield takeLatest(getDeselectionReasons.type, getDeselectionReasonsSaga);
  yield takeLatest(simulatePayment.type, simulatePaymentSaga);
  yield takeLatest(confirmPayment.type, confirmPaymentSaga);

  ////////// PAYMENT METHODS
  yield takeLatest(getPaymentMethodValidation.type, getPaymentMethodValidationSaga);
  yield takeLatest(getPaymentMethods.type, getPaymentMethodsSaga);
  yield takeLatest(gePaymentMethodDetail.type, gePaymentMethodDetailSaga);
  yield takeLatest(addPaymentMethod.type, addPaymentMethodSaga);
  yield takeLatest(editPaymentMethod.type, editPaymentMethodSaga);
  yield takeLatest(deletePaymentMethod.type, deletePaymentMethodSaga);

  ////////// PAYMENT HISTORY
  yield takeLatest(getPaymentHistoryList.type, getPaymentHistoryListSaga);

  /////////// PAYMENT HISTORY DETAILS
  yield takeLatest(getPaymentHistoryDetails.type, getPaymentHistoryDetailsSaga);
  ////////// PAYMENT DOCUMENT HISTORY
  yield takeLatest(getPaymentDocumentHistoryList.type, getPaymentDocumentHistoryListSaga);

  ////////// PAYMENT STATEMENT
  yield takeLatest(getPaymentStatementList.type, getPaymentStatementListSaga);

  ////////// MISC
  yield takeLatest(getPaymentPolicyStatus.type, getPaymentPolicyStatusSaga);
  yield takeLatest(savePaymentPolicyAgreement.type, savePaymentPolicyAgreementSaga);
  yield takeLatest(downloadPaymentDocument.type, downloadPaymentDocumentSaga);
}
