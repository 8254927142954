import React, { useEffect, useState } from "react";
import Slider, { LazyLoadTypes } from "react-slick";
import styled from "styled-components/macro";
import { Item } from "../../../interfaces/gridInterfaces";
import { selectPagesLayout } from "../../../store/store/storeSlice";
import CarouselNavBar from "../../styled-UI/CarouselNavBar";
import CustomText from "../../styled-UI/CustomText";
import NavigationSingleTab from "./NavigationSingleTab";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/arrowhead-left-icon.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrowhead-right-icon.svg";
import IconButton from "../../styled-UI/IconButton";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  items: any[];
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const NavigationTabHorizontal = ({
  items,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const [activeTab, setActiveTab] = useState(items[0]);
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const slidesToScroll = 1;
  const slidesToShow = 4;
  const currentPage = Math.ceil(currentSlide / slidesToScroll);
  const totalSlides = items?.length;
  const lastPage = Math.ceil((totalSlides - slidesToShow) / slidesToScroll);

  const defaultSettings = {
    autoplay: false,
    adaptiveHeight: true,
    dots: false,
    arrows: false,
    draggable: false, //to set it to true --> prevent click on drag https://github.com/akiran/react-slick/issues/848
    infinite: false,
    lazyLoad: "ondemand" as LazyLoadTypes,
    onSwipe: () => {
      console.log("swipe");
    },
    slidesToShow: slidesToShow < totalSlides ? slidesToShow : totalSlides,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    beforeChange: (_: any, next: any) => setCurrentSlide(next),
  };

  const changeSlide = (val: number) => {
    sliderRef.slickGoTo(val);
  };

  if (!items || totalSlides === 0) return <></>;
  else
    return (
      <Container>
        <TabsContainer>
          {totalSlides > 1 && totalSlides > slidesToShow ? (
            <IconButton
              size="xl"
              onClick={() => changeSlide(currentSlide - slidesToScroll)}
              disabled={currentPage === 0}
              icon={<ArrowLeft />}
              type="no-background-primary"
            />
          ) : (
            <div></div>
          )}
          <Slider ref={(val) => setSliderRef(val)} {...defaultSettings}>
            {items?.map((singleTab) => {
              return (
                <Tab
                  id={"slider-tab"}
                  key={singleTab?.id}
                  className={activeTab?.id === singleTab?.id ? "active" : ""}
                  onClick={() => setActiveTab(singleTab || "")}
                >
                  <CustomText
                    as="p"
                    fontSizePx={18}
                    font={customFont ? "custom-medium" : "font-medium"}
                    fontFamily={customFont}
                    uppercase
                    color={activeTab?.id === singleTab?.id ? "white" : "primary"}
                  >
                    {singleTab?.title}
                  </CustomText>
                </Tab>
              );
            })}
          </Slider>
          {totalSlides > 1 && totalSlides > slidesToShow ? (
            <IconButton
              size="xl"
              onClick={() => changeSlide(currentSlide + slidesToScroll)}
              disabled={currentPage === lastPage}
              icon={<ArrowRight />}
              type="no-background-primary"
            />
          ) : (
            <div></div>
          )}
        </TabsContainer>
        <ContentContainer>
          <NavigationSingleTab
            key={activeTab?.id}
            layout={"horizontal"}
            data={activeTab?.grid}
            isDarkMode={isDarkMode}
            customFont={customFont}
          ></NavigationSingleTab>
        </ContentContainer>
      </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tab = styled.button`
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.white};
  border-right: 1px solid ${(props) => props.theme.palette.gray.medium};
  width: 100%;
  height: 6.25rem;

  &.active {
    background-color: ${(props) => props.theme.palette.primary};
  }
`;

const TabsContainer = styled.div`
  display: grid;
  grid-template-columns: 4.25rem calc(100% - 8.5rem) 4.25rem;
  align-items: center;

  .slick-slider {
    box-shadow: 2px 2px 47px -20px rgba(0, 0, 0, 0.7);
  }
`;

const ContentContainer = styled.div``;

export default NavigationTabHorizontal;
