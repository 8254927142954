import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";

interface Props {
  text: string;
  onClick: (e: any) => void;
  icon?: any;
  iconEnd?: any;
  "data-element-id"?: string;
  "data-description"?: string;
  isInstagram?: boolean;
  disabled?: boolean;
  PDPmode?: boolean;
}

const LinkButtonIcon = ({
  text,
  icon,
  iconEnd,
  onClick,
  "data-element-id": dataElementId,
  "data-description": dataDescrption,
  isInstagram = false,
  PDPmode = false,
  disabled,
}: Props): JSX.Element => {
  return (
    <StyledButton
      onClick={onClick}
      {...(dataElementId ? { "data-element-id": dataElementId } : {})}
      {...(dataDescrption ? { "data-description": dataDescrption } : {})}
      className={clsx(isInstagram && "see-on-instagram", PDPmode && "adt-popup-pdp")}
      disabled={disabled}
      iconEnd={!!iconEnd}
    >
      {icon} {text} {iconEnd}
    </StyledButton>
  );
};

const StyledButton = styled.button<{ iconEnd?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.fontBold};
  color: ${(props) => props.theme.palette.primary};

  svg {
    fill: ${(props) => props.theme.palette.primary};
    height: 1.125rem;
    width: 1.125rem;
    margin: ${(props) => (props.iconEnd ? `0 0 0 0.5rem` : `0 1 0 0rem`)};
  }

  &.adt-popup-pdp {
    background-color: ${(props) => props.theme.palette.white};
    border: 1px solid #dbdce0;
    border-radius: 6.25rem;
    padding: 0.813rem;
  }

  &.see-on-instagram {
  }
`;

export default LinkButtonIcon;
