import React from "react";
import styled from "styled-components/macro";
import LoaderElement from "../LoaderElement";
import { MemoizedLoaderTileWithBackgroud } from "../LoaderTile";

const LoaderVariantCart = (): JSX.Element => (
  <OuterBoxLoader>
    <BoxLoader>
      <MemoizedLoaderTileWithBackgroud
        unsetHeight
        hideTags
        hideSeeAll
        hideActionButtons
        showAddSize
      />
      <MemoizedLoaderTileWithBackgroud
        unsetHeight
        hideTags
        hideSeeAll
        hideActionButtons
        showAddSize
      />
      <MemoizedLoaderTileWithBackgroud
        unsetHeight
        hideTags
        hideSeeAll
        hideActionButtons
        showAddSize
      />
      <MemoizedLoaderTileWithBackgroud
        unsetHeight
        hideTags
        hideSeeAll
        hideActionButtons
        showAddSize
      />
    </BoxLoader>
    <BoxButton>
      <LoaderElement height={55} width={155} />
    </BoxButton>
  </OuterBoxLoader>
);

const OuterBoxLoader = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxLoader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-height: calc(100vh - 85px - 77px - 3rem);
  overflow: auto;
`;

const BoxButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
`;

export default LoaderVariantCart;
