import { BFFApiDomain } from "../serviceUtils";

/////////////////// PRE-PLP

// get preplp catalogue
export const getPrePlpCatalogueUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/carousel/categories/{categoryId}/contents/{idCMS}";

// getPrePLPStarsContents
export const getPrePLPStarsContentsUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/brandgroups/{brandGroupId}/stars";

/////////////////// PLP

// get plp catalogue
export const getPlpCatalogueUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/categories/{categoryId}/contents/{contentId}";

export const getPlpRecommendedUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/recommendations";

// get variants for single product in expanded tile popup
export const getPlpVariantsUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/partnumber/{partNumber}/variants";

// get plp stars catalogue
export const getPlpStarsCatalogueUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/categories/{categoryId}/stars";

/////////////////// PDP

// get variant/moco for pdp page
export const getVariantDetailsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/variants/{variantId}";

// get video for SeeThemOn
export const getVideoAvailabilityUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/variants/{partNumber}/videoavailability";

// get variants given  a product
export const getProductWithVariantsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/{productId}/variants";

export const getSkusByVariantUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/variants/stars/{catentryId}/items";

// get RTR badge for other variants different fromm the current one
export const getRTRitem =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/partnumber/{partNumber}/rtr";

// get PDP cms contents
export const getPDPCmsContentsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/contenthub/contents";

// get values for dropdown "Compatible With" in ACCESSORIES ONLY
export const getProductMerchandisingUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/{productId}/merchandising";

// get attachments for variant
export const getAttachmentsVariantUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/variants/{variantId}/{attachments}";

export const QRUrl = globalEnvVariables.qrApi;

export const VMMVTransitionsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/virtualMirror/lensTypeAndColor?source=MyEl";

// get similar products
export const getSimilarProductsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/{id}/similar";

// get keylook products
export const getKeylookProductsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/carousel/keylook/{id}";

// get products compatible with accessories
export const getProductsCompatibleWithAccessoriesUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/partnumber/{partNumber}/compatible";

/////////////////// COMMONS

export const resolveSlugUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/pages/identifier/{slug}";

export const getPriceUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/prices";

export const getProductAvailabilityUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/availability";

export const searchByFacetUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/search/byFacets/{term}";

export const getSkuForVariantUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/variants/{catentryId}/items";

/////////////////// BESTSELLER
export const getBestSellerUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/term/{searchTerm}";

/////////////////// PRODUCT RECOMMENDATION
export const getProductRecommendationUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/recommendations";

// get variants for single product in landing page product popup
export const getLPVariantsUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/partnumber/{partNumber}/variants/landing";
