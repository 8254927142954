import React from "react";
import styled from "styled-components/macro";
import { MemoizedLoaderHomeTile } from "./loader/home-page/LoaderHomeTile";
import LoaderElement from "./loader/LoaderElement";

export const LoaderHomepage = (): JSX.Element => {
  return (
    <HomePageContainer>
      <Row>
        <LoaderElement height={550} />
        <LoaderElement height={550} />
        <LoaderElement height={550} />
      </Row>
      <MemoizedLoaderHomeTile />
    </HomePageContainer>
  );
};

const HomePageContainer = styled.div`
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 1rem;
  justify-content: space-between;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  min-width: 1024px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2.5rem;
  @media (max-width: 1440px) {
    padding: 0 1rem;
  }
  justify-content: space-between;
`;
