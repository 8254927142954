import { AxiosResponse } from "axios";
import { createAndExecuteService } from "../serviceUtils";
import {
  SubmitBrandListPayload,
  TransportDocumentsListPayload,
  DownloadDocPayload,
  SendItemMasterDataPayload,
  PostItemMasterDataPayload,
  ArticlePriceInformationPayload,
  GetItemMasterDataService,
} from "./servicesInterface";
import {
  submitBrandListUrl,
  submitTransportDocumentsUrl,
  downloadTransportDocumentUrl,
  getArticlePriceInformationUrl,
  getVideoPlaylistUrl,
  itemMasterDataUrl,
  sendItemMasterDataUrl,
  getDigitalWindowProductsUrl,
  getDigitalDiscountsVoucherUrl,
} from "./servicesUrls";

const servicesService = {
  postSubmitBrandList: async (payload: SubmitBrandListPayload): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ doorId: payload.doorId });
    const data = { brandIdentifierList: payload.brandIdentifierList, username: payload.username };
    return createAndExecuteService(submitBrandListUrl, "POST", qparams, data);
  },

  postSubmitTransportDocumentList: async (
    payload: TransportDocumentsListPayload
  ): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    const url = submitTransportDocumentsUrl.replace("{doorId}", payload.doorId);

    Object.entries(payload)
      .filter((_) => _[0] !== "doorId")
      .forEach(([key, value]) => value && qparams.append(key, value));

    return createAndExecuteService(url, "GET", qparams);
  },

  downloadTransportDocument: async (payload: DownloadDocPayload): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    qparams.append("contRep", payload.contRep);
    qparams.append("docid", payload.id);

    return createAndExecuteService(downloadTransportDocumentUrl, "GET", qparams);
  },

  getItemMasterData: async (params: GetItemMasterDataService): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => value && searchParams.append(key, value));

    return createAndExecuteService(itemMasterDataUrl, "GET", searchParams);
  },

  sendItemMasterData: async (payload: SendItemMasterDataPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(sendItemMasterDataUrl, "POST", undefined, payload);
  },

  postItemMasterData: async ({
    params,
    qparams,
  }: PostItemMasterDataPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ doorId: qparams.doorId });
    return createAndExecuteService(itemMasterDataUrl, "POST", searchParams, params);
  },
  getArticlePriceInformation: async (
    params: ArticlePriceInformationPayload
  ): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ doorId: params.doorId });

    const data = {
      brandIdentifierList: params.brandIdentifierList,
      username: params.username,
    };
    return createAndExecuteService(getArticlePriceInformationUrl, "POST", searchParams, data);
  },

  //Digital-screen
  getVideoPlaylist: async (params: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    params && qparams.append("doorId", params);

    return createAndExecuteService(getVideoPlaylistUrl, "GET", qparams);
  },
  getDigitalWindowProducts: async (params: string[]): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    params && params.forEach((param) => qparams.append("partNumber", param));

    return createAndExecuteService(getDigitalWindowProductsUrl, "GET", qparams);
  },
  //Digital Discounts Voucher
  getDigitalDiscountsVoucherLink: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getDigitalDiscountsVoucherUrl, "GET");
  },
};

export default servicesService;
