import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import { SecondLevelMenu } from "../../../../interfaces/menuInterfaces";
import { getPagePath } from "../../../../routing/routesUtils";
import BrandButton from "../../../styled-UI/BrandButton";
import CustomText from "../../../styled-UI/CustomText";
import WrapperLayout from "../../WrapperLayout";
import { useMenuRedirectMultidoorStars } from "./useMenuRedirectMultidoorStars";

import { ReactComponent as StarIcon } from "../../../../assets/icons/star-icon.svg";

interface Props {
  brandMenuData?: SecondLevelMenu[];
  setToggleMenu: (identifier: string) => void;
  enableDataDescription?: boolean;
  isEssilor?: boolean;
}

const BrandMenu = ({
  brandMenuData,
  setToggleMenu,
  enableDataDescription = false,
  isEssilor = false,
}: Props): JSX.Element => {
  const history = useHistory();

  const { translateLabel } = useTranslation();
  const [brands, setBrands] = useState<SecondLevelMenu[]>([]);
  const [starsBrands, setStarsBrands] = useState<SecondLevelMenu[]>([]);

  const redirectToPrePLP = (brand: SecondLevelMenu) => {
    //history.push brings to lens-configurator microFE when brands are essilor, to preplp otherwise
    if (isEssilor) {
      history.push(getPagePath(`/digital-catalog?brands=${brand.brandCodes?.join(",")}`));
    } else {
      history.push(getPagePath(`/preplp${brand.seo?.href ?? `/${brand.identifier}`}`));
    }
    setToggleMenu("");
  };

  // useMenuRedirectMultidoorStars isolates the logic required to redirect to the prePLP / PLP corresponding to a specific brand
  // refer to the hook's file for more info regarding this logic
  const { handleClick: handleBrandClick } = useMenuRedirectMultidoorStars({
    redirect: redirectToPrePLP,
  });

  const handleClick = (brand: SecondLevelMenu) =>
    handleBrandClick(brand, brand.identifier, brand.name, !!brand.isStars);

  useEffect(() => {
    if (brandMenuData) {
      const newBrands: SecondLevelMenu[] = [];
      const newStarsBrands: SecondLevelMenu[] = [];
      brandMenuData.map((_) => {
        if (_.isStars) {
          newStarsBrands.push(_);
        } else {
          newBrands.push(_);
        }
      });
      setBrands(newBrands);
      setStarsBrands(newStarsBrands);
    }
  }, [brandMenuData]);

  return (
    <MenuContainer>
      {starsBrands.length > 0 && (
        <StarsContainer>
          <WrapperLayout>
            {starsBrands.length > 0 && (
              <CustomText as="p">
                <IconContainer>
                  <StarIcon />
                </IconContainer>
                {translateLabel("MENU_BRAND_STARS")}
              </CustomText>
            )}
            <Container isStars={true}>
              {starsBrands &&
                starsBrands.map((brand: SecondLevelMenu) => {
                  return (
                    brand && (
                      <BrandButton
                        key={brand.identifier}
                        brandGroup={brand}
                        style="stars"
                        onClick={() => handleClick(brand)}
                        data-element-id="MainNav_Brands_Stars"
                        enableDataDescription={enableDataDescription}
                      />
                    )
                  );
                })}
            </Container>
          </WrapperLayout>
        </StarsContainer>
      )}
      {brands.length > 0 && (
        <div>
          <WrapperLayout>
            {starsBrands.length > 0 && (
              <CustomText as="p" marginTopPx={25} marginLeftPx={8}>
                {translateLabel("MENU_BRAND_OTHERS")}
              </CustomText>
            )}
            <Container isStars={false} className={isEssilor ? "essilor" : ""}>
              {brands &&
                brands.map((brand: SecondLevelMenu) => {
                  return (
                    brand && (
                      <BrandButton
                        key={brand.identifier}
                        brandGroup={brand}
                        onClick={() => handleClick(brand)}
                        data-element-id="MainNav_Brands_Brand"
                        enableDataDescription={enableDataDescription}
                        fallbackLabel={brand.name}
                      />
                    )
                  );
                })}
            </Container>
          </WrapperLayout>
        </div>
      )}
    </MenuContainer>
  );
};

const MenuContainer = styled.div``;

const StarsContainer = styled.div`
  background-color: ${(props) => props.theme.palette.gray.light};
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
  padding-top: 1.563rem;
`;

const IconContainer = styled.div`
  svg {
    height: 1.375rem;
    fill: ${(props) => props.theme.palette.primary};
  }
`;

const Container = styled.div<{ isStars?: boolean }>`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
  padding: 3rem 0rem;
  z-index: 100;
  box-sizing: border-box;

  @media ${(props) => props.theme.queries.md} {
    grid-template-columns: repeat(8, 1fr);
    padding: 1rem 0.5rem;
  }

  button {
    &:hover {
      background-color: ${(props) =>
        props.isStars ? props.theme.palette.white : props.theme.palette.primary};
      border: solid 1px
        ${(props) =>
          props.isStars ? props.theme.palette.primary : props.theme.palette.gray.medium};
      img {
        filter: ${(props) => (props.isStars ? "invert(0)" : "invert(1)")};
      }
      p {
        color: ${(props) =>
          props.isStars ? props.theme.palette.primary : props.theme.palette.white};
      }
    }
  }

  &.essilor {
    > :nth-child(10),
    > :nth-child(19) {
      grid-column-start: 1;
    }
  }
`;

export default BrandMenu;
