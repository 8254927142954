import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useBreakpoint } from "../../assets/theme/breakpoint";
import { detectScrollDirection } from "../../utils/utils";
import CustomText from "../styled-UI/CustomText";
import NavigationSingleTab from "./navigation-tab/NavigationSingleTab";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  items: any[];
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const NavigationTabVertical = ({
  items,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const id = "tab-vertical";
  const [activeTab, setActiveTab] = useState(items[0]);
  const tabRef: any = useRef();
  let lastScrollTop = 0;

  useEffect(() => {
    const scrollContainer = document.getElementById("root");
    const verticalTab = document.getElementById(id);
    const verticalTabHeight = verticalTab?.offsetHeight ?? 0;
    const bannerHeight = document.getElementById("banner")?.offsetHeight ?? 0;
    const smallerNavbarHeight = document.getElementById("header-navbar")?.offsetHeight ?? 0;
    const headerHeight = document.getElementById("header")?.offsetHeight ?? 0;
    const totalHeaderHeight = bannerHeight + smallerNavbarHeight + headerHeight + 70;

    const onScroll = (e?: any) => {
      const distanceFromTop = tabRef?.current.getBoundingClientRect().top;
      const distanceBottomFromTop = tabRef?.current.getBoundingClientRect().bottom;
      const lastPointBottom = distanceBottomFromTop - verticalTabHeight;
      const lastPoint = distanceBottomFromTop - totalHeaderHeight - verticalTabHeight;

      if (verticalTab && tabRef?.current) {
        const scrollInfo = detectScrollDirection(lastScrollTop);
        lastScrollTop = scrollInfo.newScrollTop;
        // console.log("lastPoint", lastPoint);
        // console.log("distanceFromTop", distanceFromTop);
        // console.log("lastPointBottom", lastPointBottom);

        if (scrollInfo.direction === "down") {
          const checkFinish = totalHeaderHeight;
          if (lastPointBottom < checkFinish) {
            // console.log("--STOP DOWN");
            verticalTab.style.position = "absolute";
            verticalTab.style.top = "auto";
            verticalTab.style.bottom = "10px";
            return;
          }
          if (distanceFromTop < checkFinish) {
            // console.log("--START DOWN");
            verticalTab.style.position = "fixed";
            verticalTab.style.top = totalHeaderHeight + "px";
            verticalTab.style.bottom = "auto";
            return;
          }
        }

        if (scrollInfo.direction === "up") {
          if (distanceFromTop > totalHeaderHeight) {
            // console.log("--STOP UP");
            verticalTab.style.position = "absolute";
            verticalTab.style.top = "0";
            verticalTab.style.bottom = "auto";
            return;
          }
          if (lastPoint > 0) {
            // console.log("--START UP");
            verticalTab.style.position = "fixed";
            verticalTab.style.top = totalHeaderHeight + "px";
            verticalTab.style.bottom = "auto";
            return;
          }
        }
      }
    };

    scrollContainer?.addEventListener("scroll", onScroll);
    return () => scrollContainer?.removeEventListener("scroll", onScroll);
  }, []);

  if (!items || items.length === 0) return <></>;
  else
    return (
      <Column ref={tabRef}>
        <Container id={id} className="position-top">
          {items?.map((singleTab) => {
            return (
              <Tab
                key={singleTab?.id}
                className={activeTab?.id === singleTab?.id ? "active" : ""}
                onClick={() => setActiveTab(singleTab || "")}
              >
                <CustomText
                  as="p"
                  fontSizePx={18}
                  font={customFont ? "custom-medium" : "font-medium"}
                  fontFamily={customFont}
                  uppercase
                  color={activeTab?.id === singleTab?.id ? "white" : "primary"}
                >
                  {singleTab?.title}
                </CustomText>
              </Tab>
            );
          })}
        </Container>
        <ContentContainer>
          <NavigationSingleTab
            key={activeTab?.id}
            layout={"vertical"}
            data={activeTab?.grid}
            isDarkMode={isDarkMode}
            customFont={customFont}
            isWrappedLanding={true}
          ></NavigationSingleTab>
        </ContentContainer>
      </Column>
    );
};

const Column = styled.div`
  height: 100%;
  position: relative;
`;

const ContentContainer = styled.div`
  height: 100%;
  width: 70%;
  position: relative;
  left: 27.5%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 47px -10px rgba(0, 0, 0, 0.68);
  z-index: 300;

  &.position-top {
    position: absolute;
    top: 0;
  }

  &.position-bottom {
    position: absolute;
    bottom: 0;
  }
`;

const Tab = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.white};
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
  height: 6.25rem;
  width: 20rem;

  &.active {
    background-color: ${(props) => props.theme.palette.primary};
  }
`;

export default NavigationTabVertical;
