import { AxiosResponse } from "axios";
import { createAndExecuteService } from "../serviceUtils";
import {
  ConfimationPayload,
  CustomDetailsPayload,
  DevicesPayload,
  EditPinPayload,
  StagingOrderNotePayload,
  StagingOrdersItemsToDeletePayload,
  StagingOrdersPayload,
} from "./smartshopperInterfaces";
import {
  deleteAllStagingOrdersUrl,
  deleteStagingOrdersUrl,
  getCustomDetailsStagingOrdersUrl,
  getDevicesUrl,
  getStagingOrdersCountUrl,
  getStagingOrdersUrl,
  postEditPin,
  putStagingOrderConfirmationUrl,
  putStagingOrderNoteUrl,
} from "./smartshopperUrls";

export default {
  /////// GET
  // STAGING ORDERS
  getStagingOrders: async (payload: StagingOrdersPayload): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    Object.entries(payload).forEach(([key, value]) => value && qparams.append(key, value));
    return createAndExecuteService(getStagingOrdersUrl, "GET", qparams);
  },
  getStagingOrderCount: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getStagingOrdersCountUrl, "GET");
  },
  // DETAILS
  getCustomDetails: async (payload: CustomDetailsPayload): Promise<AxiosResponse> => {
    const url = getCustomDetailsStagingOrdersUrl.replace("{recipeId}", payload.recipeId);
    const qparams = new URLSearchParams({ brand: payload.brand });
    return createAndExecuteService(url, "GET", qparams);
  },
  // DEVICES
  getDevices: async (payload: DevicesPayload): Promise<AxiosResponse> => {
    const url = getDevicesUrl.replace("{doorId}", payload.doorId);
    return createAndExecuteService(url, "GET");
  },
  /////// POST
  // EDIT PIN
  postEditPin: async (payload: EditPinPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(postEditPin, "POST", null, payload);
  },
  ////// PUT
  putStagingOrderNote: async (payload: StagingOrderNotePayload): Promise<AxiosResponse> => {
    const url = putStagingOrderNoteUrl
      .replace("{stagingOrderId}", payload.stagingOrderId)
      .replace("{itemId}", payload.itemId);
    return createAndExecuteService(url, "PUT", null, { note: payload.note });
  },
  putStagingOrderConfirmation: async (payload: ConfimationPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(putStagingOrderConfirmationUrl, "PUT", null, {
      items: payload.items,
    });
  },
  ////// DELETE
  deleteStaginOrderItems: async (
    payload: StagingOrdersItemsToDeletePayload
  ): Promise<AxiosResponse> => {
    return createAndExecuteService(deleteStagingOrdersUrl, "DELETE", null, payload);
  },

  deleteAllStaginOrderItems: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(deleteAllStagingOrdersUrl, "DELETE");
  },
};
