import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { getAcceptCookie, setAcceptCookie } from "../utils/cookieUtils";
import { selectLocale } from "../store/user/userSlice";

export type AcceptCookieType = "All" | "Technical";

const COOKIE_TYPE = {
  ALL: "All",
  TECHNICAL: "Technical",
};

const useCookies = (): {
  acceptAnalytics: boolean;
  acceptTechnical: boolean;
  refToggle: React.Ref<HTMLInputElement>;
  toggleAnalytics: () => void;
  setCookie: () => void;
  setCookieWithoutToggle: (acceptAll: boolean) => void;
} => {
  const acceptTechnical = true;
  const [acceptAnalytics, setAcceptAnalytics] = useState<boolean>(true);
  const refToggle = useRef<HTMLInputElement | null>(null);

  const currentLocale = useSelector(selectLocale);

  const setCookie = () => {
    const cookieValue = (refToggle.current?.checked
      ? COOKIE_TYPE.ALL
      : COOKIE_TYPE.TECHNICAL) as AcceptCookieType;

    setAcceptCookie(cookieValue, currentLocale);
  };

  const setCookieWithoutToggle = (acceptAll: boolean) => {
    const cookieValue = (acceptAll ? COOKIE_TYPE.ALL : COOKIE_TYPE.TECHNICAL) as AcceptCookieType;

    setAcceptCookie(cookieValue, currentLocale);
  };

  const toggleAnalytics = () => {
    setAcceptAnalytics((current) => !current);
  };

  // on first render checks the current value of stored cookie
  useEffect(() => {
    if (getAcceptCookie() == COOKIE_TYPE.TECHNICAL) {
      setAcceptAnalytics(false);
    }
  }, []);

  return {
    acceptAnalytics,
    acceptTechnical,
    refToggle,
    toggleAnalytics,
    setCookie,
    setCookieWithoutToggle,
  };
};

export default useCookies;
