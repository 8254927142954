import React from "react";
import styled from "styled-components/macro";
import CustomText from "../../../../../styled-UI/CustomText";
import { LensesColumn as LensesColumnInterface } from "../../../../../../store/store/storeInterfaces";
import { Link } from "react-router-dom";
import { getPagePath } from "../../../../../../routing/routesUtils";
import { LENSES_COLUMN_BASE_URL } from "../../../../../../utils/menuUtils";

interface Props {
  lensesMenu: LensesColumnInterface;
  identifier: string;
  tileName: string;
  setToggleMenu: (identifier: string) => void;
}

const LensesColumn = ({ lensesMenu, identifier, tileName, setToggleMenu }: Props): JSX.Element => {
  return (
    <Container>
      {lensesMenu && (
        <UrlList>
          <li>
            <CustomText fontSizePx={18} as="span" marginBottomPx={20} data-element-id="Lenses">
              {lensesMenu.collectionTitle}
            </CustomText>
          </li>
          {lensesMenu.links?.map((value, index: number) => (
            <>
              {index !== 0 && index % 6 === 0 && <br key={index + identifier} />}
              <li
                key={value.id + identifier}
                onClick={() => {
                  setToggleMenu("");
                }}
              >
                <Link
                  to={getPagePath(LENSES_COLUMN_BASE_URL + value.url)}
                  data-element-id={`MainNav_Products_${tileName}_${value.id}_${value.title}`}
                >
                  <CustomText
                    fontSizePx={14}
                    as="span"
                    marginBottomPx={20}
                    font="font-regular"
                    lineHeightPx={28}
                  >
                    {value.title}
                  </CustomText>
                </Link>
              </li>
            </>
          ))}
        </UrlList>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 1.875rem 0.5rem 0rem;
`;
const UrlList = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem;

  span {
    &:hover {
      font-weight: bold;
    }
  }
`;

export default LensesColumn;
