import React from "react";
import { formatCurrency } from "../../utils/utils";

interface Props {
  currency: string;
  locale: string;
  value?: number;
}

const CurrencyFormatter = ({ currency, locale, value }: Props): JSX.Element | null => {
  const formattedValue = formatCurrency(value, currency, locale);

  if (formattedValue === null) return null;
  else return <>{formattedValue}</>;
};

export default CurrencyFormatter;
