import React from "react";
import styled from "styled-components/macro";

import ServiceSectionColumn from "./ServiceSectionColumn";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/x-icon.svg";
import { serviceMenu } from "./serviceSections";

interface Props {
  hideServices: boolean;
  setToggle: (identifier: string) => void;
}

const ServicesMenu = ({ hideServices = false, setToggle }: Props): JSX.Element => {
  return (
    <MenuContainer>
      <WrapperButton>
        <CloseButton
          data-element-id={`MainNav_Menu_Close`}
          onClick={() => {
            setToggle("");
          }}
        >
          <CloseIcon />
        </CloseButton>
      </WrapperButton>
      <Menu>
        {serviceMenu.map((service) => (
          <ServiceSectionColumn
            service={service}
            key={service.sectionTitle}
            hideServices={hideServices}
          />
        ))}
      </Menu>
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  box-sizing: border-box;
  padding-bottom: 2rem;
`;

const Menu = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 1.813rem 0.5rem 0;
`;

const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0 0 0;
  cursor: pointer;
`;

const CloseButton = styled.button`
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export default ServicesMenu;
