import { AxiosResponse } from "axios";
import { createAndExecuteService } from "../serviceUtils";
import { appendFiltersToURLSearchParams } from "../../utils/filterUtils";
import { QueryParams } from "../search/searchInterfaces";

import {
  AftersalesSearchPayload,
  GetAftersalesItemPayload,
  GetAftersalesByUpcPayload,
  GetSparePartsByFamilyPayload,
  GetSparePartsFamiliesPayload,
  WarrantyReturnNotificationPayload,
  WarrantyReturnPayload,
  WarrantyDoorIndentifierPayload,
  WarrantyActionPayload,
  WarrantySparepartsActionPayload,
  GetWarrantyProductDetailsByPartNumberPayload,
  WarrantyInboxPayload,
  WarrantyTextActionPayload,
  PostWarrantyParcelDataSagaPayload,
  GetAftersalesPlpCataloguePayload,
} from "./aftersalesInterface";
import {
  aftersalesProductUrl,
  aftersalesBrandsUrl,
  aftersalesSearchProductsUrl,
  aftersalesVariantUrl,
  aftersalesSearchVariantsUrl,
  sparePartsByFamilyUrl,
  sparePartsFamiliesUrl,
  warrantyInboxCountUrl,
  warrantyReturnNotificationUrl,
  warrantyReturnUrl,
  warrantyInboxListUrl,
  getWarrantyInboxSearchIdentifiersUrl,
  warrantyInboxReadUrl,
  warrantyInboxDetailsUrl,
  sendWarrantyActionUrl,
  postWarrantySparepartsActionUrl,
  warrantyProductDetailsByPartNumberUrl,
  sendWarrantyTextActionUrl,
  warrantyFastTrackParcelDataUrl,
  warrantyReturnParcelDataUrl,
  aftersalesSearchByUPCModelUrl,
} from "./aftersalesUrls";
import { getSearchResultUrl } from "../search/searchUrls";

export default {
  /////////////////// conversational

  // Get conversational results from UPC search
  getAftersalesUpc: async (payload: GetAftersalesByUpcPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();
    Object.entries(payload).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(aftersalesSearchByUPCModelUrl, "GET", searchParams);
  },

  // Get list of brands for aftersales conversational
  getAftersalesBrands: async (payload: AftersalesSearchPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();
    Object.entries(payload).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(aftersalesBrandsUrl, "GET", searchParams);
  },

  // Get list of models from selected brand for aftersales conversational
  getAftersalesSearchProducts: async (payload: AftersalesSearchPayload): Promise<AxiosResponse> => {
    const { brandGroup, ...params } = payload;

    const searchParams: QueryParams = {};
    Object.entries(params).forEach(([key, value]) => (searchParams[key] = value));

    const filters: QueryParams = {};
    if (brandGroup) filters["brandGroup"] = [brandGroup];

    return createAndExecuteService(
      aftersalesSearchProductsUrl,
      "GET",
      appendFiltersToURLSearchParams(searchParams, filters)
    );
  },

  // Get list of variants from selected model for aftersales conversational
  getAftersalesSearchVariants: async (payload: AftersalesSearchPayload): Promise<AxiosResponse> => {
    const { productId, ...params } = payload;

    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(
      aftersalesSearchVariantsUrl.replace("{productId}", productId as string),
      "GET",
      searchParams
    );
  },

  // Get tile details for selected model for aftersales conversational
  getAftersalesProduct: async (payload: GetAftersalesItemPayload): Promise<AxiosResponse> => {
    const { productId, ...params } = payload;

    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(
      aftersalesProductUrl.replace("{productId}", productId as string),
      "GET",
      searchParams
    );
  },

  // Get tile details for selected variant for aftersales conversational
  getAftersalesVariant: async (payload: GetAftersalesItemPayload): Promise<AxiosResponse> => {
    const { variantId, ...params } = payload;

    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(
      aftersalesVariantUrl.replace("{variantId}", variantId as string),
      "GET",
      searchParams
    );
  },

  /////////////////// spare-parts PDP

  // Get list of families for spareparts based on productId
  getSparePartsFamilies: async (payload: GetSparePartsFamiliesPayload): Promise<AxiosResponse> => {
    const { itemIdentifier, ...params } = payload;

    const url = sparePartsFamiliesUrl.replace("{itemIdentifier}", itemIdentifier.toUpperCase());

    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(url, "GET", searchParams);
  },

  // Get list of spareparts based on productId and selected family
  getSparePartsByFamily: async (payload: GetSparePartsByFamilyPayload): Promise<AxiosResponse> => {
    const { itemIdentifier, idFamily, ...params } = payload;

    const url = sparePartsByFamilyUrl
      .replace("{itemIdentifier}", itemIdentifier.toUpperCase())
      .replace("{idFamily}", idFamily as string);

    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(url, "GET", searchParams);
  },

  /////////////////// warranty inbox & returns
  getWarrantyInboxList: async (payload: WarrantyInboxPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ doorId: payload.doorId });
    return createAndExecuteService(warrantyInboxListUrl, "POST", searchParams, payload);
  },

  getWarrantyInboxDetails: async (
    payload: WarrantyDoorIndentifierPayload
  ): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ doorId: payload.doorId });
    const url = warrantyInboxDetailsUrl.replace("{warrantyIdentifier}", payload.warrantyIdentifier);
    return createAndExecuteService(url, "GET", searchParams);
  },

  getWarrantyInboxSearchIdentifier: async (payload: string): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ doorId: payload });
    return createAndExecuteService(getWarrantyInboxSearchIdentifiersUrl, "GET", searchParams);
  },

  getWarrantyInboxCount: async (payload: string): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ doorId: payload });
    return createAndExecuteService(warrantyInboxCountUrl, "GET", searchParams);
  },

  postWarrantyInboxRead: async (
    payload: WarrantyDoorIndentifierPayload
  ): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ doorId: payload.doorId });
    const params = { warrantyIdentifier: payload.warrantyIdentifier };
    return createAndExecuteService(warrantyInboxReadUrl, "POST", searchParams, params);
  },

  getWarrantyReturn: async (payload: WarrantyReturnPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({
      pageNumber: String(payload.pageNumber),
      doorId: payload.doorId,
      pageSize: String(payload.pageSize) ?? "",
      sortBy: payload.sortBy ?? "",
    });

    const url = warrantyReturnUrl.replace("{warrantyIdentifier}", payload.warrantyIdentifier);
    return createAndExecuteService(url, "GET", searchParams);
  },

  getWarrantyReturnNotification: async (
    payload: WarrantyReturnNotificationPayload
  ): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ doorId: payload.doorId, pageSize: "10" });

    const url = warrantyReturnNotificationUrl.replace(
      "{warrantyIdentifier}",
      payload.warrantyIdentifier
    );
    return createAndExecuteService(url, "GET", searchParams);
  },

  sendWarrantyAction: async (payload: WarrantyActionPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ doorId: payload.doorId });
    const params = {
      warrantyIdentifier: payload.warrantyIdentifier,
      warrantyActionId: payload.warrantyActionId,
      username: payload.username,
    };

    return createAndExecuteService(sendWarrantyActionUrl, "POST", searchParams, params);
  },

  sendWarrantyTextAction: async (payload: WarrantyTextActionPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ doorId: payload.doorId });
    const params = {
      warrantyIdentifier: payload.warrantyIdentifier,
      warrantyActionId: payload.warrantyActionId,
      username: payload.username,
      text: payload.text,
    };

    return createAndExecuteService(sendWarrantyTextActionUrl, "POST", searchParams, params);
  },

  postWarrantySparepartsAction: async (
    payload: WarrantySparepartsActionPayload
  ): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ doorId: payload.doorId ?? "" });
    const params = {
      warrantyIdentifier: payload.warrantyIdentifier,
      warrantyActionId: payload.sparePartsItemIdentifier,
    };
    return createAndExecuteService(postWarrantySparepartsActionUrl, "POST", searchParams, params);
  },

  getWarrantyProductDetailsByPartNumber: async (
    payload: GetWarrantyProductDetailsByPartNumberPayload
  ): Promise<AxiosResponse> => {
    const { partNumber, callback, ...params } = payload;

    const url = warrantyProductDetailsByPartNumberUrl.replace(
      "{partNumber}",
      encodeURIComponent(partNumber)
    );

    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(url, "GET", searchParams);
  },

  postWarrantyFastTrackParcelDataSaga: async (
    payload: PostWarrantyParcelDataSagaPayload
  ): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ doorId: payload.doorId });
    const params = {
      warrantyIdentifier: payload.warrantyIdentifier,
      storeIdentifier: payload.storeIdentifier,
      customerIdentifier: payload.doorId,
    };

    return createAndExecuteService(warrantyFastTrackParcelDataUrl, "POST", searchParams, params);
  },

  postWarrantyReturnParcelData: async (
    payload: PostWarrantyParcelDataSagaPayload
  ): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ doorId: payload.doorId });
    const params = {
      warrantyIdentifier: payload.warrantyIdentifier,
      storeIdentifier: payload.storeIdentifier,
      customerIdentifier: payload.doorId,
    };

    return createAndExecuteService(warrantyReturnParcelDataUrl, "POST", searchParams, params);
  },

  /////////////////// aftersales catalogue
  getAftersalesPlpCatalogue: async (
    payload: GetAftersalesPlpCataloguePayload
  ): Promise<AxiosResponse> => {
    const url = getSearchResultUrl.replace("{term}", payload.term);

    const searchParams: { [key: string]: string } = {};
    Object.entries(payload.searchParams).forEach(([key, value]) => {
      if (value !== null) searchParams[key] = String(value);
    });

    return createAndExecuteService(
      url,
      "GET",
      appendFiltersToURLSearchParams(searchParams, payload.filters)
    );
  },
};
