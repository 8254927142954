import { BFFApiDomain } from "../serviceUtils";

export const loginKeysUrl = BFFApiDomain + "/fo-bff/api/pub/v1/auth/keys";
export const validationUrl = BFFApiDomain + "/fo-bff/api/pub/v1/users/auth/validation";
export const logoutUrl = BFFApiDomain + "/fo-bff/api/priv/v1/users/logout";
export const resetPasswordUrl = BFFApiDomain + "/fo-bff/api/pub/v1/users/password/reset";
export const changePasswordUrl = BFFApiDomain + "/fo-bff/api/pub/v1/users/{username}/password";
export const changePasswordPrivateUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/users/{username}/password";
export const userContextUrl = BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/usercontext";
export const getPrivilegesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/user/privileges";
export const getOrganizationDetailsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/organizations/details";
export const mostUsedUrl = BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/mostused";

/* Personification */
export const getPersonificationCustomersUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/personification/customers";
export const postPersonificationUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/personification/customers/{id}";
export const getPersonificationAdvancedSearchUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/personification/search/values";
export const getPersonificationStoresUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/personification/stores";
export const getPersonificationDummyUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/dummyuser";
export const userMailCheckDuplicationUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/user/mail/checkDuplication";

/* Display Prices */
export const updateUserAttributesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/usercontext/user/attribute/update";
