import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import clsx from "clsx";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x-icon.svg";
import CustomScrollbar from "../../components/styled-UI/CustomScrollbar";
import {
  selectSuggestions,
  saveSuggestions,
  saveIsFromSuggestion,
} from "../../store/search/searchSlice";
import { getSuggestionString } from "../../utils/utils";
import { getPagePath } from "../../routing/routesUtils";

import { SearchCategories, Suggestion } from "../../store/search/searchInterfaces";
import { getCategoryQueryParam, getSuggestionResults } from "../../utils/searchUtils";
import { appendFiltersToURLSearchParams } from "../../utils/filterUtils";
import { selectIsMultidoor } from "../../store/user/userSlice";
import useCanRender from "../../hooks/useCanRender";

interface SearchCustomProps {
  placeholder?: string;
  category: SearchCategories;
  value?: string;
  closeExpandMenu?: () => void;
  "data-element-id"?: string;
}

type SearchProps = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> & SearchCustomProps;

const Search = ({
  placeholder,
  category,
  value,
  closeExpandMenu,
  "data-element-id": dataElementId,
}: SearchProps): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const canRender = useCanRender();

  const [currentText, setCurrentText] = useState<string>("");
  const [timeoutSuggestion, setTimeoutSuggestion] = useState<NodeJS.Timeout>();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

  const suggestions = useSelector(selectSuggestions);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const isMultidoor = useSelector(selectIsMultidoor);

  useEffect(() => {
    function handler(event: MouseEvent) {
      if (elementRef.current) {
        const target = (event.target as Node) || null;
        elementRef.current.contains(target) ? null : setShowSuggestions(false);
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  //set value from props
  useEffect(() => {
    if (value) setCurrentText(value);
  }, [value]);

  const suggestionsLength = suggestions.reduce(
    (previousValue, currentValue, currentIndex, suggestions) => {
      return (
        previousValue +
        (getSuggestionString(suggestions, currentText.toLowerCase(), currentIndex) ? 1 : 0)
      );
    },
    0
  );

  const SearchClassNames = clsx(currentText.length >= 3 && "input-bigger-then-three");
  const ContainerClassName = clsx(suggestions.length > 0 && "suggestion-open");
  const DropdownClassName = clsx(
    suggestionsLength === 0 && "dropdown-zero-line",
    suggestionsLength === 1 && "dropdown-one-line",
    suggestionsLength === 2 && "dropdown-two-line",
    suggestionsLength === 3 && "dropdown-three-line",
    suggestionsLength === 4 && "dropdown-four-line",
    suggestionsLength === 5 && "dropdown-five-line"
  );

  const handleSuggestions = (term: string) => {
    const filters = getCategoryQueryParam(category);
    getSuggestionResults(term, dispatch, filters);
    setShowSuggestions(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentText(e.target.value);

    if (e.target.value.length >= 3) {
      if (timeoutSuggestion) {
        clearTimeout(timeoutSuggestion);
      }
      const newTimeoutSuggestion = setTimeout(() => {
        handleSuggestions(e.target.value.toLowerCase().trim());
      }, 500);
      setTimeoutSuggestion(newTimeoutSuggestion);
    } else {
      dispatch(saveSuggestions([]));
    }
  };

  const cleanInput = () => {
    setCurrentText("");
    dispatch(saveSuggestions([]));
    closeExpandMenu && closeExpandMenu();
  };

  const goToSearchPage = (text: string, isFromSuggestion = false) => {
    const filters = getCategoryQueryParam(category);
    const params = appendFiltersToURLSearchParams(filters);

    if (text.length > 0 && text.trim().length > 0) {
      const url = `/search-results/${encodeURIComponent(text.trim())}?${params.toString()}`;
      history.push(getPagePath(url));
      dispatch(saveIsFromSuggestion(isFromSuggestion));
      cleanInput();
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<Element>) => {
    if (e.key === "Enter") {
      const search =
        selectedSuggestionIndex > 0 && suggestions[selectedSuggestionIndex]
          ? suggestions[selectedSuggestionIndex]?.term
          : currentText;

      goToSearchPage(search);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<Element>) => {
    if (suggestions?.length > 0 && showSuggestions) {
      let index = selectedSuggestionIndex;
      if (e.key === "ArrowUp") {
        if (selectedSuggestionIndex === 0) {
          index = suggestions.length - 1;
        } else {
          index -= 1;
        }
      } else if (e.key === "ArrowDown") {
        if (selectedSuggestionIndex === suggestions.length - 1) {
          index = 0;
        } else {
          index += 1;
        }
      }

      setSelectedSuggestionIndex(index);
    }
  };
  const handleClickSearchIcon = () => goToSearchPage(currentText);
  const handleOnClickSuggestion = (suggestion: string) => goToSearchPage(suggestion, true);

  const renderSuggestion = () => {
    if (suggestions?.length > 0 && canRender("CATALOGUE")) {
      return suggestions.map((suggestion: Suggestion, index: number) => {
        const autocomplete = getSuggestionString(suggestions, currentText.toLowerCase(), index);
        const selected = selectedSuggestionIndex === index;

        if (autocomplete)
          return (
            <div
              className={clsx("result-line", { selected })}
              key={index}
              onClick={() => handleOnClickSuggestion(suggestion.term)}
              onMouseEnter={() => !selected && setSelectedSuggestionIndex(index)}
            >
              <span>{currentText}</span>
              <SuggestValue>{autocomplete}</SuggestValue>
            </div>
          );
      });
    }
  };

  return (
    <Container className={ContainerClassName}>
      <IconContainer>
        <StyledButton onClick={handleClickSearchIcon}>
          <SearchIcon />
        </StyledButton>
      </IconContainer>
      <InputWrapper>
        <StyledInput
          value={currentText}
          currentText={currentText}
          className={SearchClassNames}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyPress={handleKeypress}
          onKeyDown={handleKeyDown}
          {...(dataElementId ? { "data-element-id": dataElementId } : {})}
          autoFocus
        />
        {showSuggestions && (
          <Autocomplete>
            <SpaceSpan>{currentText}</SpaceSpan>
            {currentText.length > 3 &&
              suggestions?.length > 0 &&
              suggestions[0] &&
              getSuggestionString(suggestions, currentText, 0)}
          </Autocomplete>
        )}
      </InputWrapper>
      <IconContainerEnd>
        <StyledButton onClick={cleanInput}>
          <XIcon />
        </StyledButton>
      </IconContainerEnd>
      {suggestions?.length > 0 && showSuggestions && (
        <>
          <Overlay isMultidoor={isMultidoor} />
          <StyledScrollable className={DropdownClassName} ref={elementRef}>
            <CustomScrollbar>
              <StyledDropdown>{renderSuggestion()}</StyledDropdown>
            </CustomScrollbar>
          </StyledScrollable>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: inline-block;
  border-left: solid 1px ${(props) => props.theme.palette.gray.medium};
  border-right: 1px solid ${(props) => props.theme.palette.gray.medium};
  border-top: unset;
  position: relative;

  &.suggestion-open {
    border-bottom: 0;
  }
`;

const InputWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5.25rem;
  margin-right: 6.25rem;
  position: relative;
  max-width: 32.63rem; //TODO: temp not to overflow header content if input is too long
`;

const SpaceSpan = styled.span`
  user-select: none;
  color: transparent;
  display: inline-block;
  content: "";
`;

const Autocomplete = styled.div`
  color: ${(props) => props.theme.palette.gray.dark};
  font-family: ${(props) => props.theme.fonts.fontRegular};
  font-size: 1.25rem;
  position: relative;
  z-index: 1;
  border: 1px solid transparent;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledInput = styled.input<any>`
  height: 100%;
  width: 100%;
  border: 0px;
  box-sizing: border-box;
  font-size: 1.25rem;
  font-family: ${(props) => props.theme.fonts.fontRegular};
  position: absolute;
  z-index: 2;
  padding: 0px;
  background-color: transparent;
  font-family: ${(props) => props.theme.fonts.fontRegular};
  appearance: none;
  outline: none;
  border-color: transparent;
  border-radius: unset;

  &:hover {
    cursor: pointer;
  }

  &.input-bigger-then-three {
    width: 100%;
  }

  &&::-webkit-input-placeholder {
    font-family: ${(props) => props.theme.fonts.fontLight};
    color: ${(props) => props.theme.palette.primary};
  }
  &&::-moz-placeholder {
    font-family: ${(props) => props.theme.fonts.fontLight};
    color: ${(props) => props.theme.palette.primary};
  }
  &&:-ms-input-placeholder {
    font-family: ${(props) => props.theme.fonts.fontLight};
    color: ${(props) => props.theme.palette.primary};
  }
  &&:-moz-placeholder {
    font-family: ${(props) => props.theme.fonts.fontLight};
    color: ${(props) => props.theme.palette.primary};
  }
`;

const lineHeightLg = 2;

const StyledScrollable = styled.div`
  position: relative;
  height: calc(${lineHeightLg * 7 + "rem"} + 1px);
  margin-top: -1px;
  margin-left: -1px;
  border: solid 1px ${(props) => props.theme.palette.gray.medium};
  border-top: 0;
  border-radius: 0 0 0.25rem 0.25rem;
  background-color: ${(props) => props.theme.palette.white};
  box-sizing: border-box;
  /* padding-bottom: 2.25rem; TO NOT HAVE SCROLLBAR WHEN NOT NEEDED */
  z-index: 101;
  overflow-x: hidden;

  .ScrollbarsCustom-Track {
    right: 3px !important;
  }

  &.dropdown-zero-line {
    height: 1px;
  }
  &.dropdown-one-line {
    height: calc(${lineHeightLg * 2 + "rem"} + 1px);
  }
  &.dropdown-two-line {
    height: calc(${lineHeightLg * 3 + "rem"} + 1px);
  }
  &.dropdown-three-line {
    height: calc(${lineHeightLg * 4 + "rem"} + 1px);
  }
  &.dropdown-four-line {
    height: calc(${lineHeightLg * 5 + "rem"} + 1px);
  }
  &.dropdown-five-line {
    height: calc(${lineHeightLg * 6 + "rem"} + 1px);
  }
`;

const IconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 1.2rem;
  height: 1.2rem;
  top: 1.5rem;
  left: 1.5rem;

  svg {
    fill: ${(props) => props.theme.palette.primary};
    height: 1.2rem;
    width: 1.2rem;
  }
`;

const IconContainerEnd = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 1.2rem;
  height: 1.2rem;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
`;

const StyledDropdown = styled.div`
  box-sizing: border-box;
  text-align: left;
  text-transform: capitalize;

  .result-line {
    box-sizing: border-box;
    height: ${lineHeightLg + "rem"};
    line-height: ${lineHeightLg + "rem"};
    font-size: 0.875rem;
    padding-left: 5.3125rem;
    font-family: ${(props) => props.theme.fonts.fontRegular};
    cursor: pointer;

    .result-value {
      padding-right: 0.125rem;
      color: ${(props) => props.theme.palette.primary};
    }

    &.selected {
      background-color: #ccd8ff4f;
    }
  }
`;

interface OverlayProps {
  isMultidoor: boolean;
}

const Overlay = styled.div<OverlayProps>`
  position: fixed;
  /* top: ${(props) => (props.theme.headerHeight + props.isMultidoor ? 51 : 0)}; //TODO */
  left: 0;
  width: 100%;
  background-color: rgba(1, 9, 23, 0.8);
  height: 100vh;
`;

const SuggestValue = styled.span`
  margin-left: 1px;
  color: ${(props) => props.theme.palette.gray.dark};
  font-family: ${(props) => props.theme.fonts.fontRegular};
`;

const StyledButton = styled.button`
  cursor: pointer;
  svg {
    fill: ${(props) => props.theme.palette.primary};
    height: 1.2rem;
    width: 1.2rem;
  }
`;

export default Search;
