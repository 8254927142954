import { useContext } from "react";
import translateLabelFunc from "../language/translate";
import { LanguageContext } from "../language/languageContext";
import { Labels } from "../language/labelInterfaces";
import { Config } from "../interfaces/mainInterfaces";
import { parseUrlInfo } from "../routing/routesUtils";
import useQuery from "./useQuery";

const useTranslation = (): {
  translateLabel: (label: string, config?: Config) => string;
  labels: Labels;
} => {
  const site = parseUrlInfo();
  const { labels, status } = useContext(LanguageContext);
  const query = useQuery();

  const translateLabel = (label: string, config?: Config): string => {
    const labelMode = query.get("labelsMode");
    if (labelMode === "true") {
      return "PO_" + label;
    }

    //if locale is not defined, get locale from url or set default locale
    if (config?.plural && config?.plural?.locale === undefined) {
      config.plural.locale = site.locale ?? "en-US";
    }

    //translate label
    let translatedLabel = translateLabelFunc(labels, label, status, config);

    //if replace string is present, handle replace
    if (config && config.replace) {
      config.replace.forEach((r) => {
        translatedLabel = translatedLabel.replace(`{${r.placeholder}}`, r.replaceString);
      });
    }

    //return label
    return translatedLabel;
  };

  return { translateLabel, labels };
};

export default useTranslation;
