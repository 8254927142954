import { MutableRefObject, useEffect, useRef, useState } from "react";

// options example:
// {
//   root: null,
//   rootMargin: "0px",
//   threshold: 0.1, // how much of the container must be visible to trigger callback
// }

const useIntersectionObserver = <T extends Element>(
  options: IntersectionObserverInit
): {
  containerRef: MutableRefObject<T | null>;
  isVisible: boolean;
} => {
  const containerRef = useRef<T | null>(null);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const callbackFunction: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (containerRef.current && observer) observer.unobserve(containerRef.current);
    };
  }, [containerRef, isVisible, options]);

  return { containerRef, isVisible };
};

export default useIntersectionObserver;
