import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import clsx from "clsx";
import useTranslation from "../../../hooks/useTranslation";
import { CustomRx, OrderItem } from "../../../store/cart/cartInterfaces";
import { instanceOfSparePartsSku } from "../../../utils/aftersalesUtils";
import { filterRxPrescription } from "../../../utils/rxUtils";
import { getCartTileTitle } from "../../../utils/cartUtils";
import CustomProgressiveImage from "../../styled-UI/CustomProgressiveImage";
import CustomText from "../../styled-UI/CustomText";
import ButtonCartNote from "../../styled-UI/ButtonCartNote";
import CartTileDetails from "./cart-tile/CartTileDetails";
import { useBreakpoint } from "../../../assets/theme/breakpoint";
import TYPTileRow from "./TYPTileRow";

interface Props {
  orderItem: OrderItem;
  currencyTYP?: string;
}

const TYPTile = ({ orderItem, currencyTYP }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [rxMappedData, setRxMappedData] = useState<CustomRx>();
  const breakpoints = useBreakpoint();

  useEffect(() => {
    if (orderItem) {
      setRxMappedData(filterRxPrescription(orderItem));
    }
  }, [orderItem]);

  const renderCustomerRefButton = () => (
    <ButtonCartNoteContainer
      className={clsx(!orderItem.customerReference && "hide-customerReference")}
      id={"customer-reference"}
    >
      <ButtonCartNote
        type="light"
        disabled={true}
        text={orderItem.customerReference ?? ""}
        onClick={() => {
          return;
        }}
      />
    </ButtonCartNoteContainer>
  );

  return (
    <StyledTile openDetails={openDetails}>
      <TileHeader>
        <TileTitle>
          <CustomText as="span" fontSizePx={18} font="font-bold" lineHeightPx={24}>
            {getCartTileTitle(orderItem, translateLabel)}
          </CustomText>
          <CustomText as="span" fontSizePx={14} font="font-regular" lineHeightPx={24}>
            {orderItem.productName}
          </CustomText>
        </TileTitle>
        {orderItem.xitemM4CbagId && (
          <OuterBoxSmartShopper>
            <BoxSmartShopper>
              <CustomText as="span" fontSizePx={13} font="font-bold" lineHeightPx={24}>
                {translateLabel("CART_ITEM_SMART_SHOPPER_TAG")}
              </CustomText>
            </BoxSmartShopper>
            <CustomText
              as="span"
              fontSizePx={13}
              font="font-bold"
              lineHeightPx={24}
              marginLeftPx={14}
            >
              {translateLabel("CART_ITEM_SMART_SHOPPER_BAG_ID")} {orderItem.xitemM4CbagId}
            </CustomText>
          </OuterBoxSmartShopper>
        )}
      </TileHeader>

      <TileContent>
        <TileContentFlex>
          <ImageContainer>
            <CustomProgressiveImage type="glasses" src={orderItem.sku.img ?? ""} />
          </ImageContainer>
          {orderItem?.orders && orderItem?.orders?.length > 0 ? (
            <RowContainer>
              {orderItem?.orders?.map((order, index) => (
                <TYPTileRow
                  key={orderItem.orderItemId + order.orderItemId}
                  orderItem={order}
                  hideCustomerRef={index !== 0}
                  hideButton={index !== 0}
                  hidePrice={index !== 0}
                  openDetails={openDetails}
                  setOpenDetails={setOpenDetails}
                  currency={currencyTYP}
                  {...(index === 0 && { totalPrice: orderItem.price.opt.orderItemPrice })}
                />
              ))}
            </RowContainer>
          ) : (
            <TYPTileRow
              orderItem={orderItem}
              openDetails={openDetails}
              setOpenDetails={setOpenDetails}
              currency={currencyTYP}
            />
          )}
        </TileContentFlex>
        {breakpoints["md"] && renderCustomerRefButton()}
      </TileContent>

      <CartTileDetails
        currentOrderItem={orderItem}
        showDetails={openDetails}
        outOfStock={false}
        productId={orderItem.sku.uniqueID}
        category={orderItem.sku.productCategory}
        brand={orderItem?.xitemRXbrand ?? ""}
        brandGroup={orderItem?.xitemRXbrandGroup ?? ""}
        jobType={orderItem?.xitemField1 ?? ""}
        xitemRXfocalTypeName={orderItem?.xitemRXfocalTypeName ?? ""}
        rxMappedData={rxMappedData}
        {...(instanceOfSparePartsSku(orderItem.sku)
          ? { sparePartsDescription: orderItem.sku.sparePartsDescription }
          : {})}
      />
    </StyledTile>
  );
};

const StyledTile = styled.div<{ openDetails: boolean }>`
  background-color: ${(prop) => prop.theme.palette.white};
  width: 100%;
  border-radius: 0.25rem;
  flex-shrink: 0;

  ${(props) =>
    props.openDetails &&
    `
    border: 1px solid ${props.theme.palette.primary};
  `}

  img {
    width: 8.563rem;
  }
`;

const TileHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.light};
  padding: 1.75rem 1.5rem 1.125rem 1.875rem;
  box-sizing: border-box;
`;

const TileContent = styled.div`
  position: relative;

  margin: 0 1.875rem;
  padding: 0.75rem 0 1.5rem;

  @media ${(props) => props.theme.queries.md} {
    padding: 1rem;
    margin: 0;
  }
`;

const TileContentFlex = styled.div`
  display: flex;
  align-items: center;
`;

const RowContainer = styled.div`
  flex-grow: 1;
`;

const TileTitle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const ButtonCartNoteContainer = styled.div`
  &.hide-customerReference {
    visibility: hidden;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
`;

const OuterBoxSmartShopper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2rem;
`;

const BoxSmartShopper = styled.div`
  background-color: ${(props) => props.theme.palette.gray.medium};
  padding: 0.125rem 1rem;
  border-radius: 1.875rem;
`;

export default TYPTile;
