import React from "react";
import styled from "styled-components/macro";
import LoaderAddSize from "./LoaderAddSize";
import LoaderElement from "./LoaderElement";

interface Props {
  isMultidoor?: boolean;
  expandedPadding?: boolean;
}

const LoaderMultidoorSize = ({
  isMultidoor = false,
  expandedPadding = false,
}: Props): JSX.Element => {
  if (isMultidoor)
    return (
      <Column expandedPadding={expandedPadding}>
        <Row>
          <LoaderElement height={30} />
          <LoaderElement height={30} />
        </Row>
        <LoaderElement height={56} />
        <LoaderAddSize />
        <LoaderAddSize />
      </Column>
    );
  else
    return (
      <Column expandedPadding={expandedPadding}>
        <LoaderAddSize />
        <LoaderAddSize />
      </Column>
    );
};

const Column = styled.div<{ expandedPadding: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => (props.expandedPadding ? "2rem" : "1rem")};
  width: 100%;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
`;

export default LoaderMultidoorSize;
