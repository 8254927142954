import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../storeConfig";
import {
  CheckoutState,
  CheckoutOrderCategoryInfo,
  CheckoutOrderInfo,
  CheckoutOrderMultidoorInfo,
  CheckoutLoading,
  OrderItemParcel,
  CarnetMultidoor,
  RemoveCarnetPayload,
  CheckCarnetOutcome,
  CheckCarnetStatus,
  SubmitOrderPayload,
  SubmitOrderItem,
  repeatableOrders,
  VouchersOptions,
  OrderFieldsForVouchers,
} from "./checkoutInterfaces";
import { getCarnetExtendedAttributes } from "../../utils/checkoutUtils";

import {
  OrderCategory,
  OrderCategorySummary,
  OrderMultidoor,
  OrderMultidoorAddress,
  OrderMultidoorSummary,
  SubOrderPayload,
} from "../cart/cartInterfaces";
import {
  getTotalNumberCart,
  getTotalNumberCategory,
  getTotalNumberMultidoor,
  getTotalPriceCategory,
  getTotalPriceMultidoor,
} from "../../utils/cartUtils";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import { checkIsAFAOrHelmet } from "../../utils/AFAutils";
import {
  OrderConfirmationEssilorPayload,
  OrderConfirmationHeaderData,
  OrderConfirmationLensData,
  OrderTYPEssilorPayload,
} from "./orderConfirmationEssilorInterfaces";

const defaultCheckoutLoading = {
  submitOrder: false,
  submitEssilorOrder: false,
};

type DefaultCheckoutLoadingKeys = keyof typeof defaultCheckoutLoading;

export const sliceName = "checkout";
const initialState: CheckoutState = {
  /////////////// CHECKOUT

  checkoutOrderId: "",
  checkoutOrderStatus: "",
  checkoutOrderMultidoor: [],
  checkoutMultidoorAddress: [],
  checkoutTotalPrice: null,
  subOrdersNotesAndPO: [],
  checkoutLoading: { ...defaultCheckoutLoading },
  repeatableOrders: null,
  repeatedOrder: "",
  repeatOrderStatus: "IDLE",

  /////////////// ORDER CONFIRMATION ESSILOR
  lensDetails: null,
  headerData: null,

  /////////////// THANK YOU PAGE ESSILOR
  thankYouPageData: null,

  /////////////// carnet RX
  carnetMultidoor: [],
  carnetOutcome: [],
  checkCarnetStatus: null,

  /////////////// THANK YOU PAGE

  /////////////// parcel
  parcelIDarray: [],
  parcelArray: null,
  orderParcelDetailsStatus: "IDLE",

  /////////////// vouchers
  vouchersOptions: null,
  vouchersOptionsStatus: "IDLE",
  vouchersValidation: null,
  vouchersValidationStatus: "IDLE",
  orderFieldsForVouchers: null,
};

export const checkoutSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    /////////////// CHECKOUT

    saveCheckoutOrderId: (state, action: PayloadAction<string>) => {
      state.checkoutOrderId = action.payload;
    },
    saveCheckoutOrderStatus: (state, action: PayloadAction<string>) => {
      state.checkoutOrderStatus = action.payload;
    },
    saveCheckoutOrderMultidoor: (state, action: PayloadAction<OrderMultidoor[]>) => {
      state.checkoutOrderMultidoor = action.payload;
    },
    saveCheckoutMultidoorAddress: (state, action: PayloadAction<OrderMultidoorAddress[]>) => {
      state.checkoutMultidoorAddress = action.payload;
    },
    saveCheckoutTotalPrice: (state, action: PayloadAction<number>) => {
      state.checkoutTotalPrice = action.payload;
    },
    saveSubOrdersNotesAndPO: (state, action: PayloadAction<SubOrderPayload>) => {
      const newSubOrdersNotesAndPO = state.subOrdersNotesAndPO.filter(
        (_) => _.subOrderId !== action.payload.subOrderId
      ); // remove duplicates

      newSubOrdersNotesAndPO.push(action.payload); // add updated notes and PO
      state.subOrdersNotesAndPO = newSubOrdersNotesAndPO; // update redux state
    },
    replaceSubOrdersNotesAndPO: (state, action: PayloadAction<SubOrderPayload[]>) => {
      state.subOrdersNotesAndPO = action.payload;
    },
    saveCheckoutLoading: (
      state,
      action: PayloadAction<{ type: DefaultCheckoutLoadingKeys; value: boolean }>
    ) => {
      state.checkoutLoading = {
        ...state.checkoutLoading,
        [action.payload.type]: action.payload.value,
      };
    },
    setRepeatableOrders: (state, { payload }: PayloadAction<repeatableOrders | null>) => {
      state.repeatableOrders = payload;
    },

    setRepeatedOrder: (state, { payload }: PayloadAction<string>) => {
      state.repeatedOrder = payload;
    },
    setRepeatOrderStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.repeatOrderStatus = payload;
    },

    /////////////// JSON Order Essilor Data
    saveEssilorOrderConfirmationData: (
      state,
      { payload }: PayloadAction<OrderConfirmationEssilorPayload>
    ) => {
      state.lensDetails = payload.lensDetails;
      state.headerData = payload.headerData;
    },

    saveEssilorThankYouPageData: (state, { payload }: PayloadAction<OrderTYPEssilorPayload>) => {
      state.thankYouPageData = payload;
    },

    /////////////// carnet RX
    addCarnet: (state, action: PayloadAction<CarnetMultidoor[]>) => {
      state.carnetMultidoor = action.payload;
    },

    removeCarnet: (state, action: PayloadAction<RemoveCarnetPayload>) => {
      const { doorId, orderItemIdentifier } = action.payload;

      //////// remove from list of applied carnets
      const currentCarnetMultidoor = state.carnetMultidoor;

      // look for specified door
      const carnetDoorToModify = currentCarnetMultidoor.find((_) => _.doorId === doorId);

      // if found
      if (carnetDoorToModify) {
        const carnetOrderItems = carnetDoorToModify.orderItems.filter(
          (_) => _.orderItemIdentifier !== orderItemIdentifier
        ); // remove carnet for specified order item

        carnetDoorToModify.orderItems = carnetOrderItems; // replace list of order items for that door
      }

      //////// remove from list of carnet's outcomes
      const currentCarnetOutcome = state.carnetOutcome;
      state.carnetOutcome = currentCarnetOutcome.filter(
        (_) => _.orderItemIdentifier !== orderItemIdentifier
      ); // remove outcome for specified order item
    },

    saveCarnetOutcome: (state, action: PayloadAction<CheckCarnetOutcome[]>) => {
      state.carnetOutcome = action.payload;
    },

    removeCarnetOutcome: (state, action: PayloadAction<string>) => {
      state.carnetOutcome = state.carnetOutcome.filter(
        (_) => _.orderItemIdentifier !== action.payload
      ); // remove carnet for specified order item
    },

    saveCheckCarnetStatus: (state, action: PayloadAction<CheckCarnetStatus | null>) => {
      state.checkCarnetStatus = action.payload;
    },

    /////////////// THANK YOU PAGE

    /////////////// parcel
    saveParcelIDs: (state, action: PayloadAction<string[]>) => {
      state.parcelIDarray = action.payload;
    },
    saveOrderParcelDetails: (state, action: PayloadAction<OrderItemParcel[] | null>) => {
      state.parcelArray = action.payload;
    },
    setOrderParcelDetailsStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.orderParcelDetailsStatus = action.payload;
    },

    /////////////// vouchers
    saveVouchersOptions: (state, action: PayloadAction<VouchersOptions[] | null>) => {
      state.vouchersOptions = action.payload;
    },
    saveVouchersOptionsStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.vouchersOptionsStatus = action.payload;
    },
    saveVouchersValidationStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.vouchersValidationStatus = action.payload;
    },
    saveVouchersValidation: (state, action: PayloadAction<boolean | null>) => {
      state.vouchersValidation = action.payload;
    },
    saveOrderPromotionVoucher: (state, action: PayloadAction<OrderFieldsForVouchers | null>) => {
      state.orderFieldsForVouchers = action.payload;
    },

    resetCheckoutState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  /////////////// CHECKOUT

  saveCheckoutOrderId,
  saveCheckoutOrderStatus,
  saveCheckoutOrderMultidoor,
  saveCheckoutMultidoorAddress,
  saveCheckoutTotalPrice,
  saveSubOrdersNotesAndPO,
  replaceSubOrdersNotesAndPO,
  saveCheckoutLoading,
  setRepeatableOrders,
  setRepeatedOrder,
  setRepeatOrderStatus,

  /////////////// Order confirmation Essilor
  saveEssilorOrderConfirmationData,

  ////////////// Thank you page Essilor
  saveEssilorThankYouPageData,

  /////////////// carnet RX
  addCarnet,
  removeCarnet,
  saveCarnetOutcome,
  removeCarnetOutcome,
  saveCheckCarnetStatus,

  /////////////// parcel
  saveParcelIDs,
  saveOrderParcelDetails,
  setOrderParcelDetailsStatus,

  /////////////// vouchers
  saveVouchersOptions,
  saveVouchersOptionsStatus,
  saveVouchersValidationStatus,
  saveVouchersValidation,
  saveOrderPromotionVoucher,

  resetCheckoutState,
} = checkoutSlice.actions;

/////////////// CHECKOUT

export const selectCheckoutOrderId = (state: RootState): string => {
  return state.checkout.checkoutOrderId;
};

export const selectCheckoutOrderStatus = (state: RootState): string => {
  return state.checkout.checkoutOrderStatus;
};

export const selectCheckoutOrderMultidoor = (state: RootState): OrderMultidoor[] => {
  return state.checkout.checkoutOrderMultidoor;
};

export const selectCheckoutTotalPrice = (state: RootState): number | null => {
  return state.checkout.checkoutTotalPrice;
};

export const selectCheckoutOrderInfo = createSelector(
  selectCheckoutOrderMultidoor,
  (orderMultidoor: OrderMultidoor[]): CheckoutOrderInfo => {
    // map each door adding the number of total items in it
    const orderMultidoorInfo = orderMultidoor?.map(
      (orderDoor: OrderMultidoor): CheckoutOrderMultidoorInfo => {
        // map each category adding the number of total items in it
        const newOrderCategory = orderDoor.categoryList.map(
          (orderCategory: OrderCategory): CheckoutOrderCategoryInfo => {
            return {
              productCategory: orderCategory.productCategory,
              multidoorId: orderDoor.multidoorId,
              totalNumber: getTotalNumberCategory(orderCategory.orderItemList), // count total sum of quanitities in category
            };
          }
        );

        return {
          multidoorId: orderDoor.multidoorId,
          subOrderId: orderDoor.subOrderId,
          orgentityName: orderDoor.orgentityName,
          category: newOrderCategory,
          totalNumber: getTotalNumberMultidoor(newOrderCategory), // count total sum of quanitities in door
        };
      }
    );

    // return an object containing all doors' info + total counter for the whole cart
    return {
      multidoor: orderMultidoorInfo,
      totalNumber: getTotalNumberCart(orderMultidoorInfo), // count total sum of quantities in cart
    };
  }
);

export const selectCheckoutMultidoorAddress = (state: RootState): OrderMultidoorAddress[] => {
  return state.checkout.checkoutMultidoorAddress;
};

export const selectHaveAFAOrHelmetCatCheckout = createSelector(
  selectCheckoutOrderMultidoor,
  (checkout): boolean => {
    let haveAFAOrHelmetCat = false;

    if (checkout) {
      checkout?.forEach((door) => {
        door.categoryList.forEach((category) => {
          if (category.productCategoryIdentifier?.toLowerCase() === "afa") {
            haveAFAOrHelmetCat = true;
          }

          if (
            category.productCategoryIdentifier?.toLowerCase() === "goggles&helmets" ||
            category.productCategoryIdentifier?.toLowerCase() === "goggle" ||
            category.productCategoryIdentifier?.toLowerCase() === "helmet"
          ) {
            category.orderItemList.forEach((order) => {
              if (order.sku && checkIsAFAOrHelmet(order.sku.productCategory)) {
                haveAFAOrHelmetCat = true;
              }
            });
          }
        });
      });
    }

    return haveAFAOrHelmetCat;
  }
);

export const selectCheckoutSummary = createSelector(
  selectCheckoutOrderMultidoor,
  (orderMultidoorList: OrderMultidoor[]): OrderMultidoorSummary[] | null => {
    // map each door
    const orderMultidoorSummary = orderMultidoorList?.map(
      (orderMultidoor: OrderMultidoor): OrderMultidoorSummary => {
        // map each category
        const orderCategorySummary = orderMultidoor.categoryList.map(
          (orderCategory: OrderCategory): OrderCategorySummary => {
            return {
              productCategory: orderCategory.productCategory,
              productCategoryIdentifier: orderCategory.productCategoryIdentifier,
              multidoorId: orderMultidoor.multidoorId,
              subOrderId: orderMultidoor.subOrderId,
              totalPrice: getTotalPriceCategory(orderCategory.orderItemList),
            };
          }
        );

        return {
          multidoorId: orderMultidoor.multidoorId,
          subOrderId: orderMultidoor.subOrderId,
          totalPrice: getTotalPriceMultidoor(orderCategorySummary),
          categoryPrice: orderCategorySummary,
          currency: orderMultidoor?.categoryList[0]?.orderItemList[0]?.currency,
        };
      }
    );

    return orderMultidoorSummary ?? null;
  }
);

export const selectSubOrdersNotesAndPO = (state: RootState): SubOrderPayload[] => {
  return state.checkout.subOrdersNotesAndPO;
};

export const selectCheckoutLoading = (state: RootState): CheckoutLoading => {
  return state.checkout.checkoutLoading;
};

export const selectrepeatableOrders = (state: RootState): repeatableOrders | null => {
  return state.checkout.repeatableOrders;
};

export const selectRepeatedOrder = (state: RootState): string => {
  return state.checkout.repeatedOrder;
};

export const selectRepeatOrderStatus = (state: RootState): RequestStatus => {
  return state.checkout.repeatOrderStatus;
};

/////////////// Order confirmation Essilor
export const selectEssilorOrderConfirmationLensDetails = (
  state: RootState
): OrderConfirmationLensData | null => {
  return state.checkout.lensDetails;
};

export const selectEssilorOrderConfirmationHeaderData = (
  state: RootState
): OrderConfirmationHeaderData | null => {
  return state.checkout.headerData;
};

/////////////// Thank you page essilor
export const selectEssilorTYPData = (state: RootState): OrderTYPEssilorPayload | null => {
  return state.checkout.thankYouPageData;
};

/////////////// carnet RX
export const selectCarnetMultidoor = (state: RootState): CarnetMultidoor[] => {
  return state.checkout.carnetMultidoor;
};

export const selectCarnetOutcome = (state: RootState): CheckCarnetOutcome[] => {
  return state.checkout.carnetOutcome;
};

export const selectCheckCarnetStatus = (state: RootState): CheckCarnetStatus | null => {
  return state.checkout.checkCarnetStatus;
};

export const selectSubmitOrderPayload = createSelector(
  selectCheckoutOrderId,
  selectCarnetOutcome,
  (checkoutOrderId: string, carnetOutcome: CheckCarnetOutcome[]): SubmitOrderPayload => {
    const orderItems = carnetOutcome
      .filter((_: CheckCarnetOutcome) => _.outcomeStatus === "success") // only those that have been approved
      .map(
        (carnet): SubmitOrderItem => {
          return {
            orderItemId: carnet.orderItemIdentifier,
            orderItemExtendAttribute: getCarnetExtendedAttributes(carnet.checkCarnetOutcome),
          };
        }
      );

    return {
      orderId: ".", // checkoutOrderId
      orderItem: orderItems,
    };
  }
);

/////////////// parcel
export const selectParcelId = (state: RootState): string[] => {
  return state.checkout.parcelIDarray;
};

export const selectOrderParcelDetailsStatus = (state: RootState): RequestStatus => {
  return state.checkout.orderParcelDetailsStatus;
};

export const selectParcelArray = (state: RootState): OrderItemParcel[] | null => {
  return state.checkout.parcelArray;
};

/////////////// vouchers
export const selectVouchersOptions = (state: RootState): VouchersOptions[] | null => {
  return state.checkout.vouchersOptions;
};

export const selectVouchersOptionsStatus = (state: RootState): RequestStatus => {
  return state.checkout.vouchersOptionsStatus;
};

export const selectVouchersValidation = (state: RootState): boolean | null => {
  return state.checkout.vouchersValidation;
};

export const selectVouchersValidationStatus = (state: RootState): RequestStatus => {
  return state.checkout.vouchersValidationStatus;
};

export const selectOrderPromotionVoucher = (state: RootState): OrderFieldsForVouchers | null => {
  return state.checkout.orderFieldsForVouchers;
};

export default checkoutSlice.reducer;
