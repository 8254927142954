export const ALL_FILE_TYPES = [
  "image/jpeg",
  "image/gif",
  "image/x-png",
  "image/png",
  "application/pdf",
];

export const FILE_EXT_BY_TYPE = {
  "image/jpeg": "jpg",
  "image/gif": "gif",
  "image/x-png": "png",
  "image/png": "png",
  "application/pdf": "pdf",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/vnd.ms-excel": "xls",
  "text/csv": "csv",
  "text/plain": "txt",
};

export const isFileTypeSupported = (fileType: string, fileTypesSupported: string[]): boolean => {
  return fileTypesSupported.includes(fileType);
};

export const isFileTooBig = (file: File, maxSize?: number): boolean => {
  if (maxSize === undefined) return false;
  else return file.size > maxSize;
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
