import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { orderBy } from "lodash";

import useTranslation from "../../../hooks/useTranslation";
import usePrevious from "../../../hooks/usePrevious";
import Checkbox from "../../styled-UI/Checkbox";
import Chip from "../../styled-UI/Chip";
import { CustomOptions } from "../../styled-UI/CustomSelect";
import CustomText from "../../styled-UI/CustomText";
import RadioButton from "../../styled-UI/RadioButton";
import {
  selectGetLensesStatus,
  selectLensesSectionsOpts,
  selectRecivedLenses,
  selectSelectedLens,
  setSelectedLens,
} from "../../../store/rx/rxSlice";
import { getLenses, madeInAndItemsInPackage } from "../../../store/rx/rxSagas";
import {
  GetLensesPayload,
  Lens,
  LenseAttribute,
  LensFilter,
  LensFilterState,
  SelectList,
  SelectNameList,
} from "../../../store/rx/rxInterface";
import LoaderLensSelection from "../../styled-UI/loader/rx/LoaderLensSelection";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrowhead-down-icon.svg";
import { LensIcon } from "./colors/LensColors";
import { LensColorDot } from "./colors/FilterLensColors";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash-icon.svg";
import Button from "../../styled-UI/Button";
import useCanRender from "../../../hooks/useCanRender";
import { selectActiveDoor, selectIsSubuser } from "../../../store/user/userSlice";
import { Door } from "../../../store/user/userInterfaces";

interface Props {
  selectList: SelectList;
  selectedDoor: Door | null;
  handleSelectChange: (value: CustomOptions, type: SelectNameList) => void;
  getLensObjToSend: (lensCategoryIdentifier?: string) => GetLensesPayload;
}

export default function LensSelection({
  selectList,
  selectedDoor,
  handleSelectChange,
  getLensObjToSend,
}: Props): JSX.Element {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const prevSelectedType: CustomOptions | null = usePrevious(selectList.lensType);
  const canRender = useCanRender();

  const recivedLenses = useSelector(selectRecivedLenses);
  const lensesSectionsOpts = useSelector(selectLensesSectionsOpts);
  const getLensesStatus = useSelector(selectGetLensesStatus);
  const isSubuser = useSelector(selectIsSubuser);
  const mainDoor = useSelector(selectActiveDoor);

  useEffect(() => {
    if (!selectList.lensType) {
      dispatch(getLenses(getLensObjToSend(lensesSectionsOpts?.[0].value as string)));
      dispatch(
        madeInAndItemsInPackage({
          jobType: selectList.jobType?.value as string,
          brand: selectList.brand?.value as string,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (lensesSectionsOpts && !selectList.lensType) {
      handleSelectChange(lensesSectionsOpts[0], "lensType");
    }
  }, [lensesSectionsOpts]);

  useEffect(() => {
    if (
      (prevSelectedType !== undefined && prevSelectedType?.value !== selectList.lensType?.value) ||
      !recivedLenses?.lensCategory[0].filters.length
    ) {
      dispatch(getLenses(getLensObjToSend(selectList.lensType?.value as string)));
    }
    if (prevSelectedType !== undefined && prevSelectedType?.value !== selectList.lensType?.value) {
      dispatch(setSelectedLens(null));
    }
  }, [selectList.lensType]);

  const getSelectedResFilters = (): LensFilterState[] => {
    const filters = [...(recivedLenses?.lensCategory[0]?.filters as LensFilter[])];

    const selectedFilters: LensFilterState[] = [];
    filters.forEach((filter) => {
      const selectedFilterValues: string[] = [];
      filter.filterValues.forEach((filterValue) => {
        if (filterValue.selected) selectedFilterValues.push(filterValue.filterValueIdentifier);
      });
      if (selectedFilterValues.length)
        selectedFilters.push({
          selectedFilterIdentifier: filter.filterIdentifier,
          selectedFilterValues,
        });
    });
    return selectedFilters;
  };

  const sendFilters = (selectedFilters: LensFilterState[]): void => {
    const LensObj = getLensObjToSend(selectList.lensType?.value as string);
    LensObj.filters = selectedFilters;
    dispatch(getLenses(LensObj));
  };

  const handleFilterCheckboxChange = (filterName: string, filterValue: string) => {
    const selectedFilters = getSelectedResFilters();

    const filterToChangeI = selectedFilters.findIndex(
      (selFilter) => selFilter.selectedFilterIdentifier === filterName
    );

    if (filterToChangeI > -1) {
      const valueToChangeI = selectedFilters[filterToChangeI].selectedFilterValues.indexOf(
        filterValue
      );
      if (valueToChangeI > -1) {
        selectedFilters[filterToChangeI].selectedFilterValues.splice(valueToChangeI, 1);
      } else {
        selectedFilters[filterToChangeI].selectedFilterValues.push(filterValue);
      }
    } else {
      selectedFilters.push({
        selectedFilterIdentifier: filterName,
        selectedFilterValues: [filterValue],
      });
    }

    sendFilters(selectedFilters.filter((selFilter) => selFilter.selectedFilterValues.length));
  };

  const handleFilterRadioChange = (filterName: string, filterValue: string) => {
    const selectedFilters = getSelectedResFilters();

    const filterIndex = selectedFilters.findIndex(
      (selFilter) => selFilter.selectedFilterIdentifier === filterName
    );

    if (filterIndex !== -1) {
      selectedFilters[filterIndex].selectedFilterValues = [filterValue];
    } else {
      selectedFilters.push({
        selectedFilterIdentifier: filterName,
        selectedFilterValues: [filterValue],
      });
    }

    sendFilters(selectedFilters);
  };

  const filterList: { lensFilters: LensFilter[]; lensColorFilters: LensFilter[] } = {
    lensFilters: [],
    lensColorFilters: [],
  };

  recivedLenses?.lensCategory[0]?.filters.forEach((filter) => {
    if (filter.filterIdentifier.includes("COLOR")) {
      filterList.lensColorFilters.push(filter);
      return;
    }
    filterList.lensFilters.push(filter);
  });

  const handleClearLensFilters = () => {
    const LensObj = getLensObjToSend(selectList.lensType?.value as string);
    dispatch(getLenses(LensObj));

    dispatch(setSelectedLens(null));
  };

  return (
    <ContentContainer>
      <div className="d-flex justify-content-between">
        <CustomText as="h3" font="font-bold" fontSizePx={18} lineHeightPx={24} marginBottomPx={24}>
          {translateLabel("LENS_SECTION_TITLE")}
        </CustomText>
        <Button type="tertiary" onClick={() => handleClearLensFilters()} startIcon={<TrashIcon />}>
          {translateLabel("RX_CLEAR_FILTERS")}
        </Button>
      </div>
      {(isSubuser ? canRender("RX_CARNET", mainDoor) : true) &&
        canRender("RX_CARNET", selectedDoor) && (
          <div className="notice-container">
            <CustomText as="p" font="font-bold" fontSizePx={13} lineHeightPx={18}>
              {translateLabel("LENS_SECTION_NOTICE")}
            </CustomText>
          </div>
        )}
      <div className="lens-type-selection d-flex">
        {lensesSectionsOpts?.map((lens: CustomOptions) => (
          <div className="mr-3" key={lens.value}>
            <Chip
              text={lens.label !== "" ? lens.label : String(lens.value)}
              onClick={() => {
                handleSelectChange(lens, "lensType");
              }}
              active={lens.value === selectList.lensType?.value}
              hideIcon
            />
          </div>
        ))}
      </div>

      {(Object.keys(filterList) as Array<keyof typeof filterList>).map((filterListName) => (
        <div className="lens-type" key={filterListName}>
          {filterList[filterListName].map((filter) => (
            <div className="lens-opts-container" key={filter.filterIdentifier}>
              <CustomText
                as="h4"
                uppercase={true}
                font="font-bold"
                fontSizePx={13}
                lineHeightPx={18}
                marginBottomPx={16}
              >
                {filter.filterName}
                {filter.isMandatory ? " *" : ""}
              </CustomText>
              <div className="lens-opts">
                {filter.filterValues.map((filterValue) => {
                  return (
                    <div className="lens-opt" key={filterValue.filterValueIdentifier}>
                      {filter.multiselectionAllowed ? (
                        <div className="d-flex">
                          <Checkbox
                            label=""
                            value={filterValue.filterValueIdentifier}
                            name={filter.filterIdentifier}
                            disabled={filterValue.disabled || getLensesStatus === "LOADING"}
                            controlled
                            onChange={() => {
                              dispatch(setSelectedLens(null));
                              handleFilterCheckboxChange(
                                filter.filterIdentifier,
                                filterValue.filterValueIdentifier
                              );
                            }}
                            checked={filterValue.selected}
                          />
                          {filter.filterIdentifier?.includes("COLOR") && (
                            <LensColorDot
                              className={`${filter.filterIdentifier?.toUpperCase()}_${filterValue?.filterValueIdentifierKey
                                ?.toUpperCase()
                                .replace(" ", "_")}${filterValue.disabled ? " disabled" : ""}`}
                            />
                          )}
                          <CustomText
                            color={filterValue.disabled ? "gray-medium" : "primary"}
                            as="p"
                            font="font-medium"
                            fontSizePx={13}
                            lineHeightPx={18}
                          >
                            {filterValue?.filterValueIdentifier?.toUpperCase()}
                          </CustomText>
                        </div>
                      ) : (
                        <RadioButton
                          label={filterValue?.filterValueIdentifier?.toUpperCase()}
                          value={filterValue.filterValueIdentifier}
                          name={filter.filterIdentifier}
                          disabled={filterValue.disabled || getLensesStatus === "LOADING"}
                          onChange={() => {
                            dispatch(setSelectedLens(null));
                            handleFilterRadioChange(
                              filter.filterIdentifier,
                              filterValue.filterValueIdentifier
                            );
                          }}
                          checked={filterValue.selected}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      ))}
      {recivedLenses && recivedLenses.lensCategory[0]?.lenses.length > 0 && (
        <div className="lens-selection-container">
          <CustomText
            as="h3"
            font="font-bold"
            fontSizePx={18}
            marginTopPx={32}
            marginBottomPx={32}
            lineHeightPx={24}
          >
            {translateLabel("LENS_SECTION_SELECTION")}
          </CustomText>

          <LensSelectionList length={recivedLenses?.lensCategory[0]?.lenses.length}>
            <div className="scroller">
              {getLensesStatus === "SUCCESS" &&
                recivedLenses?.lensCategory[0]?.lenses.map((lens: Lens) => (
                  <LensSelectionItem key={lens.lensIdentifier} lens={lens} />
                ))}
              {getLensesStatus === "LOADING" && <LoaderLensSelection />}
            </div>
          </LensSelectionList>
        </div>
      )}
    </ContentContainer>
  );
}

interface LensItemProps {
  lens: Lens;
}

const LensSelectionItem = ({ lens }: LensItemProps): JSX.Element => {
  const dispatch = useDispatch();

  const selectedLens = useSelector(selectSelectedLens);

  const [isOpen, setIsOpen] = useState(false);

  const handleSelectLenses = (): void => {
    dispatch(setSelectedLens(lens));
  };

  const isLensChecked = (): boolean => {
    return selectedLens?.lensIdentifier === lens.lensIdentifier;
  };

  let firsAttrRow: LenseAttribute[] = [];
  let secondAttrRow: LenseAttribute[] = [];

  lens.attributes.forEach((attr) => {
    if (attr.showInFirstRow) {
      firsAttrRow.push(attr);
      return;
    }
    secondAttrRow.push(attr);
  });

  firsAttrRow = orderBy(firsAttrRow, ["showOrder"], ["asc"]);
  secondAttrRow = orderBy(secondAttrRow, ["showOrder"], ["asc"]);

  return (
    <LensSelectionItemContent isOpen={isOpen} onClick={handleSelectLenses}>
      <div className="first-row">
        <div className="checkbox-container">
          <Checkbox
            label=""
            value={lens.lensIdentifier}
            name="selected-lenses"
            controlled
            onChange={() => {
              return;
            }}
            checked={isLensChecked()}
          />
        </div>
        <div className="col" key={lens.lensIdentifier}>
          <div className="img-container">
            <LensIcon className={lens.colorIdentifier.toUpperCase().replace(/ /g, "_")} />
            <CustomText
              as="span"
              font="font-regular"
              uppercase={true}
              fontSizePx={11}
              lineHeightPx={16}
              marginTopPx={4}
            >
              {lens.lensIdentifier}
            </CustomText>
          </div>
        </div>
        {firsAttrRow.map((lensAttr) => (
          <div className="col" key={lensAttr.attributeIdentifier}>
            <CustomText
              as="span"
              font="font-medium"
              uppercase={true}
              fontSizePx={11}
              lineHeightPx={16}
            >
              {lensAttr.attributeName}:
            </CustomText>
            <CustomText
              as="span"
              uppercase={true}
              font="font-bold"
              fontSizePx={11}
              lineHeightPx={16}
              marginTopPx={4}
            >
              {lensAttr.attributeValue || "-"}
            </CustomText>
          </div>
        ))}
        {firsAttrRow.length < 6 && <div className="col"></div>}
        <div
          className="button-container"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <ArrowDownIcon />
        </div>
      </div>
      {isOpen && (
        <div className="second-row">
          {secondAttrRow.length < 6 && <div className="col"></div>}
          {secondAttrRow.map((lensFeature) => (
            <div className="col" key={lensFeature.attributeIdentifier}>
              <CustomText
                as="span"
                font="font-medium"
                fontSizePx={11}
                uppercase={true}
                lineHeightPx={16}
              >
                {lensFeature.attributeName}:
              </CustomText>
              <CustomText
                as="span"
                font="font-bold"
                fontSizePx={11}
                uppercase={true}
                lineHeightPx={16}
                marginTopPx={4}
              >
                {lensFeature.attributeValue}
              </CustomText>
            </div>
          ))}
        </div>
      )}
    </LensSelectionItemContent>
  );
};

const ContentContainer = styled.div`
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
  margin-bottom: 2rem;

  .mr-3 {
    margin-right: 1rem;
  }

  .notice-container {
    background-color: ${(props) => props.theme.palette.gray.light};
    padding: 1.5rem;
    margin-bottom: 2rem;
  }

  .lens-type-selection {
    margin-bottom: 2rem;
  }

  .lens-color {
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
  }

  .lens-type {
    display: flex;
    flex-wrap: wrap;
  }

  .lens-opts-container {
    width: calc(20% - 1.5rem);
    margin-bottom: 2rem;
    margin-right: 1.5rem;
    .lens-opt {
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: unset;
      }
    }
  }

  .lens-opts {
    max-height: 10.5rem;
    overflow: auto;
  }
`;

interface LensSelectionListProps {
  length?: number;
}

const LensSelectionList = styled.div<LensSelectionListProps>`
  padding: 1rem;
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.palette.gray.light};
  .scroller {
    padding-right: ${(props) => (props.length && props.length > 4 ? "1rem" : "unset")};
    max-height: 28rem;
    overflow: auto;
    &::-webkit-scrollbar-track {
      background-color: ${(props) => props.theme.palette.white};
    }
  }
`;

interface LensSelectionItemContentProps {
  isOpen: boolean;
}

const LensSelectionItemContent = styled.div<LensSelectionItemContentProps>`
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  border: 1px solid;
  border-color: ${(props) =>
    props.isOpen ? props.theme.palette.primary : props.theme.palette.white};

  &:last-child {
    margin-bottom: unset;
  }
  .first-row,
  .second-row {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    .col {
      flex: 0.1428 0;
    }
  }
  .first-row {
    .checkbox-container {
      margin-right: 0.75rem;
    }
    .button-container {
      border: 1px solid ${(props) => props.theme.palette.gray.medium};
      padding: 0.6875rem;
      padding-bottom: 0.5625rem;
      border-radius: 5rem;
      transform: rotate(${(props) => (props.isOpen ? "180deg" : "0")});
    }
    .img-container {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .second-row {
    padding-left: 3.5rem;
    padding: 0.75rem 4.125rem 1.25rem 4.25rem;
  }
`;
