import React from "react";
import styled from "styled-components/macro";
import { Course } from "../../../store/leonardo/leonardoInterface";
import { truncateLabel } from "../../../utils/leonardoUtils";
import CustomProgressiveImage from "../../styled-UI/CustomProgressiveImage";
import CustomText from "../../styled-UI/CustomText";

interface Props {
  course: Course;
  "data-element-id"?: string;
}

const LeonardoTile = ({ course, "data-element-id": dataElementId }: Props): JSX.Element => {
  const { title, courseLink, courseImage } = course;

  return (
    <BoxTile
      onClick={() => {
        window.open(
          `${globalEnvVariables.leonardoUrl}/newuni/bff/myel/sso?carouselUrl=${courseLink}`,
          "_blank"
        );
      }}
      {...(dataElementId ? { "data-element-id": dataElementId } : {})}
    >
      <CustomProgressiveImage src={courseImage ?? ""} type="portrait" />
      <InnerBox>
        <Divider />
        <TitleContainer>
          <CustomText as="span" fontSizePx={24} color="white" lineHeightPx={32}>
            <span>{truncateLabel(title, 50)}</span>
          </CustomText>
        </TitleContainer>
      </InnerBox>
    </BoxTile>
  );
};

const BoxTile = styled.button`
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TitleContainer = styled.div`
  min-height: 5rem;
  /* max-height: 5rem; */
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 0.313rem;
  overflow-wrap: break-word;
  overflow: hidden;
`;

const InnerBox = styled.div`
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #020814 100%);
`;

const Divider = styled.div`
  height: 1px;
  background-color: white;
  margin: 1.438rem 0 1.625rem 0;
  opacity: 0.3;
`;

export default LeonardoTile;
