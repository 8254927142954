import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { selectSelectedDoor } from "../../../store/user/userSlice";
import { getWarrantyProductDetailsReturnParcel } from "../../../store/aftersales/aftersalesSaga";
import CustomText from "../../styled-UI/CustomText";
import { WarrantyReturnFrame } from "../../../store/aftersales/aftersalesInterface";
import { getMCLFromPartnumber } from "../../../utils/rxUtils";
import useTranslation from "../../../hooks/useTranslation";
import CustomProgressiveImage from "../../styled-UI/CustomProgressiveImage";
import { selectProductDetailsReturnParcel } from "../../../store/aftersales/aftersalesSlice";
import { Sku } from "../../../interfaces/productInterface";
import Barcode from "../../widgets/Barcode";

interface Props {
  item: WarrantyReturnFrame;
  skuWithBarCode?: boolean;
}

export default function ParcelBox({ item, skuWithBarCode }: Props): JSX.Element {
  const dispatch = useDispatch();
  const selectedDoor = useSelector(selectSelectedDoor);

  const { translateLabel } = useTranslation();
  const productDetails = useSelector(selectProductDetailsReturnParcel);
  const [currentProduct, saveCurrentProduct] = useState<Sku | null>(null);
  const { modelId, colorId, sizeId } = getMCLFromPartnumber(
    item.warrantyProductItemIdentifier ?? "__"
  );

  useEffect(() => {
    if (selectedDoor && item.warrantyProductItemIdentifier !== "") {
      dispatch(
        getWarrantyProductDetailsReturnParcel({
          partNumber: item.warrantyProductItemIdentifier,
          doorId: selectedDoor.orgentityId,
          catalogType: "aftersales",
        })
      );
    }
  }, [item?.warrantyProductItemIdentifier]);

  useEffect(() => {
    const product = productDetails.find((_) => _.partNumber === item.warrantyProductItemIdentifier);

    if (product) {
      saveCurrentProduct(product);
    }
  }, [productDetails]);

  return (
    <Container>
      <ImageContainer>
        <CustomProgressiveImage type="glasses" src={currentProduct?.img ?? ""} />
      </ImageContainer>
      <BoxContent>
        <BoxText>
          <CustomText as={"p"} uppercase>
            {translateLabel("PARCEL_RETURN_MODEL")}: {modelId}
          </CustomText>

          <CustomText as={"p"} uppercase>
            Sku: {colorId + " " + sizeId}
          </CustomText>

          <CustomText as={"p"} uppercase>
            {translateLabel("PARCEL_RETURN_QTY")}: {item.quantity}
          </CustomText>

          <CustomText as={"p"} uppercase>
            Notification id: {item.notificationId}
          </CustomText>
        </BoxText>

        <BarcodeContainer>
          {skuWithBarCode && item.notificationId && <Barcode id={item.notificationId} />}
        </BarcodeContainer>
      </BoxContent>
    </Container>
  );
}

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.palette.gray.dark};
  width: 99%;
  height: 99%;
`;

const BoxContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  img {
    width: 14.25rem;
  }
`;

const BoxText = styled.div`
  padding: 1rem;
`;

const BarcodeContainer = styled.div`
  div {
    margin-bottom: 0.5rem;
  }

  svg {
    height: 6rem;
    width: 15rem;
  }
`;
