import React from "react";
import styled from "styled-components/macro";
import CustomText from "../styled-UI/CustomText";
import LinkButton from "../styled-UI/LinkButton";
import { OptionCardData } from "../../interfaces/cmsInterfaces";
import useDataHref from "../../hooks/useDataHref";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { getMultiMedia, useHandleCmsLink } from "../../utils/cmsUtils";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  data: OptionCardData;
  isDarkMode: boolean;
  customFont?: FontFamilies | null;
}

const OptionCard = ({ data, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  const {
    title,
    titleColour,
    description,
    description1Colour,
    linkLabelColour,
    linkLabel,
    linkString,
    externalLink,
    openInNewTab,
    multiMediaType,
  } = data;

  const descrWithHref = useDataHref({ content: description });
  const { srcImg } = getMultiMedia(multiMediaType);
  const { handleCmsLink } = useHandleCmsLink();

  const handleRedirect = (): void => {
    handleCmsLink(linkString, externalLink, openInNewTab);
  };

  return (
    <Container>
      <InnerContent>
        <div>
          <img src={srcImg} />
        </div>
        <DescriptionSection>
          <div>
            <CustomText
              as="p"
              color={isDarkMode ? "white" : "primary"}
              fontSizePx={24}
              font={customFont ? "custom-medium" : "font-medium"}
              fontFamily={customFont}
              marginBottomPx={16}
              customColor={titleColour}
            >
              {title}
            </CustomText>
            <CustomText
              as="p"
              color={isDarkMode ? "white" : "primary"}
              fontSizePx={14}
              lineHeightPx={24}
              font={customFont ? "custom-regular" : "font-regular"}
              fontFamily={customFont}
              customColor={description1Colour}
            >
              <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? description} />
            </CustomText>
          </div>
          <div className="link-margin">
            <LinkButton
              color={isDarkMode ? "white" : "primary"}
              uppercase
              underline
              onClick={handleRedirect}
              customColor={linkLabelColour}
            >
              {linkLabel}
            </LinkButton>
          </div>
        </DescriptionSection>
      </InnerContent>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 1rem 0;

  img {
    width: 6.625rem;
    height: 8.625rem;
  }

  .link-margin {
    margin-bottom: 0.125rem;
  }
`;

const InnerContent = styled.div`
  display: flex;
`;

const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin-left: 1rem;
`;

export default OptionCard;
