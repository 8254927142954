import { AxiosResponse } from "axios";

import { createAndExecuteService } from "../serviceUtils";
import { getCustomDetailsStagingOrdersUrl } from "../smartshopper/smartshopperUrls";
import {
  PostPrecartItemsPayload,
  DeletePrecartItemsPayload,
  PutPrecartItemsPayload,
  PutPrecartShippingInfoPayload,
  GetCartProductDetailsPayload,
  SubOrderPayload,
  CartAlternativeVariantsPayload,
  CustomDetailsPayload,
  CheckPrescriptionBrasilPayload,
  UploadPrescriptionPayload,
  SaveDeliveryPayload,
  GetDeliveryPayload,
  PostEssilorPrecartItemsPayload,
} from "./cartInterfaces";
import {
  precartUrl,
  precartCleanUrl,
  precartItemsUpdateUrl,
  precartShippingInfoUrl,
  precartCountUrl,
  cartProductDetailsUrl,
  precartUpdateUrl,
  precartSimulateUrl,
  cartAlternativeVariantsUrl,
  cartPrescriptionBrasilLoginUrl,
  cartPrescriptionBrasilDocumentsUrl,
  cartPrescriptionBrasilUploadUrl,
  deliveryUrl,
} from "./cartUrls";

export default {
  // adding, updating and deleting items from the pre-cart
  postPrecartItems: async (payload: PostPrecartItemsPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(precartItemsUpdateUrl, "POST", null, payload);
  },
  postEssilorPrecartItems: async (
    payload: PostEssilorPrecartItemsPayload
  ): Promise<AxiosResponse> => {
    const params = new URLSearchParams();
    params.append("type", "ONE_PORTAL");
    return createAndExecuteService(precartItemsUpdateUrl, "POST", params, payload);
  },
  putPrecartItems: async (payload: PutPrecartItemsPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(precartItemsUpdateUrl, "PUT", null, payload);
  },
  deletePrecartItems: async (payload: DeletePrecartItemsPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(precartItemsUpdateUrl, "DELETE", null, payload);
  },

  // getting or deleting all items from the pre-cart
  getPrecart: async (isEssilor?: boolean): Promise<AxiosResponse> => {
    const params = new URLSearchParams();
    if (isEssilor) {
      params.append("type", "ONE_PORTAL");
    }
    return createAndExecuteService(precartUrl, "GET", params);
  },
  deletePrecart: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(precartUrl, "DELETE");
  },

  deleteOutOfStockPrecart: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(precartCleanUrl, "DELETE");
  },

  // getting and updating shipping info for the pre-cart
  getPrecartShippingInfo: async (isEssilor?: boolean): Promise<AxiosResponse> => {
    const params = new URLSearchParams();
    if (isEssilor) {
      params.append("type", "ONE_PORTAL");
    }
    return createAndExecuteService(precartShippingInfoUrl, "GET", params);
  },
  putPrecartShippingInfo: async (
    payload: PutPrecartShippingInfoPayload,
    isEssilor?: boolean
  ): Promise<AxiosResponse> => {
    const queryParams = new URLSearchParams();
    if (isEssilor) {
      queryParams.append("type", "ONE_PORTAL");
    }
    return createAndExecuteService(precartShippingInfoUrl, "PUT", queryParams, payload);
  },

  // getting pre-cart count for icon in menu
  getPrecartCount: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(precartCountUrl, "GET");
  },

  // getting product details for productId (DOESN'T GO THROUGH REDUX -- CartTileDetails + useStatelessService)
  getCartProductDetails: async (payload: GetCartProductDetailsPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();
    Object.entries(payload)
      .filter((_) => _[0] !== "productId")
      .forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(
      cartProductDetailsUrl.replace("{productId}", payload.productId),
      "GET",
      searchParams
    );
  },

  // setting PO number and notes for each suborder
  updatePrecart: async (payload: SubOrderPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(
      precartUpdateUrl.replace("{subOrderId}", payload.subOrderId),
      "PATCH",
      null,
      payload.payload
    );
  },

  // simulate cart
  simulatePrecart: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(precartSimulateUrl, "POST");
  },

  // getting cart alternative variant
  getCartAlternativeVariants: async (
    payload: CartAlternativeVariantsPayload
  ): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();
    Object.entries(payload)
      .filter((_) => _[0] !== "id")
      .forEach(([key, value]) => searchParams.append(key, value));

    const url = cartAlternativeVariantsUrl.replace("{id}", payload.id);
    return createAndExecuteService(url, "GET", searchParams);
  },

  // get custom details m4c
  getCustomDetails: async (payload: CustomDetailsPayload): Promise<AxiosResponse> => {
    const url = getCustomDetailsStagingOrdersUrl.replace("{recipeId}", payload.recipeId);
    const qparams = new URLSearchParams({ brand: payload.brand });
    return createAndExecuteService(url, "GET", qparams);
  },

  // rx prescription brasil
  loginPrescriptionBrasil: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(cartPrescriptionBrasilLoginUrl, "GET");
  },

  postCheckPrescriptionBrasil: async (
    payload: CheckPrescriptionBrasilPayload
  ): Promise<AxiosResponse> => {
    return createAndExecuteService(cartPrescriptionBrasilDocumentsUrl, "POST", null, payload);
  },

  uploadPrescriptionBrasil: async (payload: UploadPrescriptionPayload): Promise<AxiosResponse> => {
    const { token, ...requestBody } = payload;

    return createAndExecuteService(
      cartPrescriptionBrasilUploadUrl,
      "POST",
      null,
      requestBody,
      undefined,
      undefined,
      token
    );
  },

  //set delivery
  getDelivery: async (payload: GetDeliveryPayload): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ checkout: payload.checkout.toString() });
    return createAndExecuteService(deliveryUrl, "GET", qparams);
  },

  saveDelivery: async (payload: SaveDeliveryPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(deliveryUrl, "PUT", null, payload);
  },
};
