import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import styled from "styled-components/macro";
import {
  selectLoginKeys,
  selectIsKeysLoading,
  loginSuccess,
  selectLocale,
} from "../../../store/user/userSlice";
import { loginKeys as getLoginKeys } from "../../../store/user/userSagas";
import Button from "../../styled-UI/Button";
import CustomText from "../../styled-UI/CustomText";
import { useTrackView } from "../../../hooks/useTrackView";
import Loader from "../../styled-UI/Loader";
import { cleanStringForLabel } from "../../../utils/utils";
import Logo from "../../layouts/Logo";
import { selectCurrentLang } from "../../../store/store/storeSlice";
import useStandaloneLabels from "../../../language/useStandaloneLabels";

const LoginPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const loginKeys = useSelector(selectLoginKeys);
  const loginKeysStatus = useSelector(selectIsKeysLoading);
  const currentLang = useSelector(selectCurrentLang);
  const currentLocale = useSelector(selectLocale);
  const queryParams = new URLSearchParams(useLocation().search);
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [showLoginError, setShowLoginError] = useState<boolean>(false);

  const { translateStandaloneLabel, getStandaloneLabels, requestStatus } = useStandaloneLabels();
  useTrackView({ Page_Type: "Login", Page_Section1: "Login" });

  useEffect(() => {
    //---LOGIN SUCCESSFULL
    if (
      queryParams.toString() &&
      queryParams.has("postLogin") &&
      queryParams.get("postLogin") === "true" &&
      queryParams.has("storeIdentifier")
    ) {
      const storeIdentifier = queryParams.get("storeIdentifier");
      const session = queryParams.get("sessionId");
      const redirectUrl = queryParams.get("redirectUrl");
      const regex = /fo-bff.*\/contents\/documents\//;

      if (redirectUrl && regex.test(redirectUrl)) {
        window.location.href = redirectUrl;
      } else
        dispatch(
          loginSuccess({
            storeIdentifier: storeIdentifier as string,
            session: session ?? "",
            redirectUrl: getParamToEnd(location.search, "redirectUrl") ?? "",
          })
        );

      return;
    }

    //---LOGIN NOT DONE OR WITH ERROR
    dispatch(getLoginKeys());

    //LOGIN WITH ERROR
    if (queryParams.toString()) {
      if (queryParams.has("postLogin") && queryParams.has("errorCode")) {
        const errorCode = queryParams.get("errorCode");

        setErrorCode("LOGIN_BO_USER" + cleanStringForLabel(errorCode ?? ""));
      }
    }
  }, []);

  useEffect(() => {
    //NOT LOGGED IN
    if (!queryParams.has("postLogin") && loginKeys?.urlFO) {
      goToLogin();
    }
  }, [loginKeys]);

  useEffect(() => {
    if (requestStatus.status === "SUCCESS" && !showLoginError) {
      setShowLoginError(true);
    }
  }, [requestStatus]);

  useEffect(() => {
    // workaround to get labels even when users directly land in a 404 page, and we don't have any information regarding the locale
    if (errorCode && !currentLang) getStandaloneLabels(currentLocale);
  }, [errorCode]);

  const getParamToEnd = (url: string, paramName: string) => {
    // find position of desired query param
    const paramIndex = url.indexOf(`${paramName}=`);
    if (paramIndex === -1) return ""; // not found, return empty string

    // take substring starting next to paramName
    return url.substring(paramIndex + paramName.length + 1);
  };

  const goToLogin = () => {
    if (loginKeys?.urlFO) {
      const substrings = loginKeys.urlFO.split("?");
      const urlFO = new URLSearchParams(substrings[1]);

      const stateUrlFO = urlFO.get("state");

      //add redirect url after login
      if (stateUrlFO && queryParams.has("redirectUrl")) {
        const decodedState = atob(stateUrlFO);
        const objectState = JSON.parse(decodedState);
        objectState.redirectUrl = getParamToEnd(location.search, "redirectUrl");

        const stringState = JSON.stringify(objectState);
        const encodedState = btoa(stringState);

        urlFO.set("state", encodedState);
      }

      window.location.replace(substrings[0] + "?" + urlFO.toString());
    }
  };

  return (
    <Page>
      {errorCode && showLoginError ? (
        <ErrorContent>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <Content>
            <CustomText as="h1" color="primary" fontSizePx={32} marginBottomPx={55}>
              {translateStandaloneLabel(errorCode)}
            </CustomText>

            {loginKeysStatus === "LOADING" && <Loader sizePx={65} styleLoader="light-background" />}
            {loginKeysStatus === "SUCCESS" && (
              <Button type="primary" onClick={goToLogin}>
                {translateStandaloneLabel("GO_TO_LOGIN_PAGE")}
              </Button>
            )}
          </Content>
        </ErrorContent>
      ) : (
        <Loader sizePx={65} styleLoader="light-background" />
      )}
    </Page>
  );
};

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.light};
  height: 100%;
`;

const ErrorContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.white};
  min-height: 80vh;
  min-width: 80vw;
  border-radius: 0.25rem;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 3rem;
  /* padding: 2rem; */
`;

export default LoginPage;
