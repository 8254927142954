import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {} from "react-router-dom";
import styled from "styled-components";
import { Row } from "../../../interfaces/gridInterfaces";
import { getNavigationTab } from "../../../store/store/storeSagas";
import {
  saveNavigationTabStatus,
  selectItemsMap,
  selectNavigationTabsStatus,
} from "../../../store/store/storeSlice";
import RowComponent from "../../cms-layouts/RowComponent";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  id?: string;
  layout: "horizontal" | "vertical";
  data: any;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
  isWrappedLanding?: boolean;
}

const NavigationSingleTab = ({
  layout,
  data,
  isDarkMode = false,
  customFont = null,
  isWrappedLanding = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const itemsMap = useSelector(selectItemsMap);
  const navigationTabStatus = useSelector(selectNavigationTabsStatus);
  let doesNeedData = false;

  const rows: Row[] = data.rows.map((_: any, index: number) => {
    return {
      rowNumber: index,
      layout: _.placements[0].viewtype,
      name: _.placements[0].name,
      cols: _.placements[0].items,
    } as Row;
  });

  useEffect(() => {
    rows.forEach((row) => {
      row.cols?.forEach((col) => {
        if (!itemsMap[col.id]) {
          doesNeedData = true;
        }
      });
    });
    if (data && rows && doesNeedData) {
      dispatch(getNavigationTab(rows));
    } else {
      dispatch(saveNavigationTabStatus("SUCCESS"));
    }
  }, [data]);

  const printContent = (): JSX.Element[] | JSX.Element => {
    const contentPage: JSX.Element[] = [];

    rows.map((row: Row) => {
      contentPage.push(
        <RowComponent
          row={row}
          key={row.name}
          landingNavbar
          isDarkMode={isDarkMode}
          customFont={customFont}
          isWrappedLanding={isWrappedLanding}
        />
      );
    });

    return contentPage;
  };

  return (
    <TabContainer layout={layout}>
      {navigationTabStatus === "SUCCESS" && printContent()}
    </TabContainer>
  );
};

export default NavigationSingleTab;

const TabContainer = styled.div<{ layout: string }>`
  //width: ${(props) => (props.layout === "vertical" ? "75%" : "100%")};
  width: 100%;
`;
