import { SagaIterator } from "@redux-saga/types";
import { PayloadAction, createAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  ADT_PLP_PAGE_SIZE,
  ADT_RECENTLY_ADDED_SIZE,
  getMappedAssetsList,
  mapAssetPlatformSocialMedia,
  mapAssetData,
  mapSocialMediaOptions,
  getMappedProductsImagesList,
} from "../../utils/adtUtils";
import { handleError } from "../store/storeSagas";
import {
  AssetPlatformPopup,
  AssetPlatformSocialMedia,
  GetAssetByPagePayload,
  GetAssetsPayload,
  GetPdpAssetPayload,
  UpdateAssetsPayload,
  GetOrderAssortmentPayload,
  GetPdpFeaturedProductsPayload,
  SendRequestDownloadAssetsPayload,
  UpdateAdtAssetPlatfomWebsite,
  UpdateAdtAssetPlatformSocialMedia,
} from "./adtInterfaces";
import adtService from "./adtService";
import profileService from "../profile/profileServices";
import {
  addAssetsRecentlyAddedPreview,
  addPlpAssets,
  saveAdtPlatformSocialMediaOptions,
  saveAssetPlatformSocialMedia,
  saveAssetPlatformWebsiteUrl,
  saveCategories,
  selectAssetsRecentlyAddedPreview,
  selectPlpAssets,
  setAdtAssetsDetails,
  setAdtAssetsRelated,
  setAssetPlatformStatus,
  setAssetsPreviewRequestStatus,
  addAssetsDownloadedAccount,
  setAssetsDownloadedPreview,
  setAdtPdpStatus,
  addAssetsSavedAccount,
  setAssetsSavedPreview,
  setCategoryLoadingStatus,
  sliceName,
  selectAssetsSavedAccount,
  selectAssetsDownloadedAccount,
  setAssetsAccountRequestStatus,
  setAssetsUpdateRequestStatus,
  selectAdtCategories,
  selectCategoryLoadingStatus,
  setPlpStatus,
  setPlpFilters,
  clearPlpAssets,
  selectAdtFileUrl,
  saveTemplateRequestProductImages,
  selectTemplatesRequestProductImages,
  setAdtViewTypes,
  setAssetRequestProductImagesStatus,
  setAssetsMetaDataStatus,
  setAssetsSavedAccountFilters,
  setAssetsDownloadedAccountFilters,
  setAdtFeaturedProducts,
  saveIsFirstADTLogin,
  selectIsFirstADTLogin,
} from "./adtSlice";
import { downloadBlobFile } from "../../utils/utils";
import { selectLxConfigurations } from "../store/storeSlice";
import { LXConfiguration } from "../store/storeInterfaces";

//MOCKED DATA
import assetsDetailsDataMock from "./mocks/assets-details.json";
import assetsSavedDataMock from "./mocks/assets-saved.json";
import assetsRecentlyAddedDataMock from "./mocks/recently-added.json";
import assetsRelatedDataMock from "./mocks/assets-related.json";
import assetCategoriesDataMock from "./mocks/categories.json";
import assetsSavedAccountDataMock from "./mocks/assets-saved-account.json";
import assetsDownloadedAccountDataMock from "./mocks/assets-downloaded-account.json";

const assetsSavedData = JSON.parse(JSON.stringify(assetsSavedDataMock));
const assetsRecentlyAddedData = JSON.parse(JSON.stringify(assetsRecentlyAddedDataMock));
const assetCategoriesData = JSON.parse(JSON.stringify(assetCategoriesDataMock));
const assetsDetailsData = JSON.parse(JSON.stringify(assetsDetailsDataMock));
const assetsRelatedData = JSON.parse(JSON.stringify(assetsRelatedDataMock));
const assetsSavedAccountData = JSON.parse(JSON.stringify(assetsSavedAccountDataMock));
const assetsDownloadedAccountData = JSON.parse(JSON.stringify(assetsDownloadedAccountDataMock));

/* ACTIONS */

/////////////////// common

export const getAdtIsFirstLogin = createAction(sliceName + "/getAdtIsFirstLogin");

export const getAdtViewsTypesProperty = createAction(sliceName + "/getAdtViewsTypesProperty");

export const getAdtAssetPlatformWebsite = createAction(sliceName + "/getAdtAssetPlatformWebsite");

export const updateAdtAssetPlatformWebsite = createAction<UpdateAdtAssetPlatfomWebsite>(
  sliceName + "/updateAdtAssetPlatformWebsite"
);

export const getAdtAssetPlatformSocialMedia = createAction(
  sliceName + "/getAdtAssetPlatformSocialMedia"
);

export const updateAdtAssetPlatformSocialMedia = createAction<UpdateAdtAssetPlatformSocialMedia>(
  sliceName + "updateAdtAssetPlatformSocialMedia"
);

export const getAdtCategories = createAction(sliceName + "/getAdtCategories");

export const getAdtSocialMediaOptions = createAction(sliceName + "/getAdtSocialMediaOptions");

export const updateAdtAssetPlatfomPopup = createAction<AssetPlatformPopup>(
  sliceName + "/updateAdtAssetPlatfomPopup"
);

export const addAdtAssetSaved = createAction<UpdateAssetsPayload>(sliceName + "/addAdtAssetSaved");

export const addAdtAssetDownloaded = createAction<UpdateAssetsPayload>(
  sliceName + "/addAdtAssetDownloaded"
);

export const removeAdtAssetSaved = createAction<UpdateAssetsPayload>(
  sliceName + "/removeAdtAssetSaved"
);

/////////////////// landing page

export const setCategoriesLoadingStatus = createAction(sliceName + "/setCategoriesLoadingStatus");

export const getAdtAssetsSavedPreview = createAction<GetAssetsPayload>(
  sliceName + "/getAdtAssetsSavedPreview"
);

export const getAdtAssetsDownloadedPreview = createAction<GetAssetsPayload>(
  sliceName + "/getAdtAssetsDownloadedPreview"
);

export const getAdtAssetsRecentlyAddedPreview = createAction<GetAssetByPagePayload>(
  sliceName + "/getAdtAssetsRecentlyAddedPreview"
);

/////////////////// plp

export const getAdtPlpAssets = createAction<GetAssetByPagePayload>(sliceName + "/getAdtPlpAssets");

////////////////// plp filters
export const getAdtPlpFilters = createAction<GetAssetByPagePayload>(
  sliceName + "/getAdtPlpFilters"
);

/////////////////// pdp
export const getAdtPdpAssetsDetails = createAction<GetPdpAssetPayload>(
  sliceName + "/getAdtPdpAssetsDetails"
);

export const getAdtPdpAssetsRelated = createAction<GetPdpAssetPayload>(
  sliceName + "getAdtPdpAssetsRelated"
);

export const getAdtPdpFeaturedProducts = createAction<GetPdpFeaturedProductsPayload>(
  sliceName + "getAdtPdpAssetsFeaturedProducts"
);
/////////////////// myaccount

export const getAdtAssetsSavedAccount = createAction<GetAssetByPagePayload>(
  sliceName + "/getAdtAssetsSavedAccount"
);

export const getAdtAssetsDownloadedAccount = createAction<GetAssetByPagePayload>(
  sliceName + "/getAdtAssetsDownloadedAccount"
);

export const getAdtAssetsSavedFilters = createAction<GetAssetsPayload>(
  sliceName + "/getAdtAssetsSavedFilters"
);

export const getAdtAssetsDownloadedFilters = createAction<GetAssetsPayload>(
  sliceName + "/getAdtAssetsDownloadedFilters"
);

export const sendAdtRequestAssetsDownload = createAction<SendRequestDownloadAssetsPayload>(
  sliceName + "/sendAdtRequestAssetsDownload"
);

/////////////////// request image
export const getAdtOrderAssortmentDownload = createAction<GetOrderAssortmentPayload>(
  sliceName + "/getAdtOrderAssortmentDownload"
);
export const getAdtTemplateRequestImagesDownload = createAction<string>(
  sliceName + "/getAdtTemplateRequestImagesDownload"
);

/* SAGAS */

/////////////////// common

function* getAdtIsFirstLoginSaga(): SagaIterator {
  try {
    const status = yield select(selectIsFirstADTLogin);
    if (status !== "SUCCESS" && status !== "LOADING") {
      yield put(saveIsFirstADTLogin({ isAccepted: false, status: "LOADING" }));

      const { data } = yield call(profileService.getPrivateUserPolicy);
      const policyStatus =
        data.data?.contextAttribute?.filter((_: any) => _.attributeName === "ADTFirstAccess")?.[0]
          ?.attributeValue?.[0]?.value?.[0] === "1";

      yield put(saveIsFirstADTLogin({ isAccepted: policyStatus, status: "SUCCESS" }));
    }
  } catch (error) {
    yield put(saveIsFirstADTLogin({ isAccepted: false, status: "ERROR" }));
  }
}

function* getAdtAssetPlatformWebsiteSaga(): SagaIterator {
  try {
    yield put(setAssetPlatformStatus({ type: "getWebsiteUrl", value: "LOADING" }));
    const { data } = yield call(adtService.getAdtAssetPlatformWebsite);
    yield put(saveAssetPlatformWebsiteUrl(data?.data?.websiteUrl || null));
    yield put(setAssetPlatformStatus({ type: "getWebsiteUrl", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setAssetPlatformStatus({ type: "getWebsiteUrl", value: "ERROR" }));
  }
}

function* updateAdtAssetPlatformWebsiteSaga(
  action: PayloadAction<UpdateAdtAssetPlatfomWebsite>
): SagaIterator {
  const { websiteUrl, callback } = action.payload;

  try {
    yield put(setAssetPlatformStatus({ type: "updateWebsiteUrl", value: "LOADING" }));
    yield call(adtService.postAdtAssetPlatformWebsite, websiteUrl);

    yield put(setAssetPlatformStatus({ type: "updateWebsiteUrl", value: "SUCCESS" }));
    yield put(getAdtAssetPlatformWebsite());
    callback?.(true);
  } catch (error) {
    yield put(handleError(error));
    yield put(setAssetPlatformStatus({ type: "updateWebsiteUrl", value: "ERROR" }));
    callback?.(false);
  }
}

function* getAdtAssetPlatformSocialMediaSaga(): SagaIterator {
  try {
    yield put(setAssetPlatformStatus({ type: "getSocialMedia", value: "LOADING" }));
    const { data } = yield call(adtService.getAdtAssetPlatformSocialMedia);

    if (data?.data?.socialPlatforms)
      yield put(
        saveAssetPlatformSocialMedia(mapAssetPlatformSocialMedia(data?.data?.socialPlatforms))
      );
    yield put(setAssetPlatformStatus({ type: "getSocialMedia", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setAssetPlatformStatus({ type: "getSocialMedia", value: "ERROR" }));
  }
}

function* updateAdtAssetPlatformSocialMediaSaga(
  action: PayloadAction<UpdateAdtAssetPlatformSocialMedia>
): SagaIterator {
  const { socialPlatforms, callback } = action.payload;
  try {
    yield put(setAssetPlatformStatus({ type: "updateSocialMedia", value: "LOADING" }));
    yield call(adtService.postAdtAssetPlatformSocialMedia, socialPlatforms);
    yield put(setAssetPlatformStatus({ type: "updateSocialMedia", value: "SUCCESS" }));
    yield put(getAdtAssetPlatformSocialMedia());
    callback?.(true);
  } catch (error) {
    yield put(handleError(error));
    yield put(setAssetPlatformStatus({ type: "updateSocialMedia", value: "ERROR" }));
    callback?.(false);
  }
}

function* updateAdtAssetPlatfomPopupSaga(action: PayloadAction<AssetPlatformPopup>): SagaIterator {
  const { socialPlatforms, websiteUrl, callback } = action.payload;
  try {
    yield put(setAssetPlatformStatus({ type: "setPopup", value: "LOADING" }));
    yield call(adtService.postAdtAssetPlatformWebsite, websiteUrl);
    yield call(adtService.postAdtAssetPlatformSocialMedia, socialPlatforms);

    yield call(profileService.putGenericPrivateUserPolicy, [
      {
        attributName: "ADTFirstAccess",
        attributValue: true,
      },
    ]);
    yield put(saveIsFirstADTLogin({ isAccepted: true, status: "SUCCESS" }));

    yield put(setAssetPlatformStatus({ type: "setPopup", value: "SUCCESS" }));

    callback?.(true);
  } catch (error) {
    yield put(handleError(error));
    yield put(setAssetPlatformStatus({ type: "setPopup", value: "ERROR" }));
    callback?.(false);
  }
}

function* getAdtCategoriesSaga(): SagaIterator {
  try {
    const currentCategories = yield select(selectAdtCategories);
    const status = yield select(selectCategoryLoadingStatus);

    if (currentCategories.length === 0 && status !== "LOADING") {
      yield put(setCategoryLoadingStatus("LOADING"));

      const { data } = yield call(adtService.getAdtCategories);
      // yield delay(1000);
      // const data = assetCategoriesData;
      if (data?.data?.categories) yield put(saveCategories(data?.data?.categories));

      yield put(setCategoryLoadingStatus("SUCCESS"));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setCategoryLoadingStatus("ERROR"));
  }
}

function* getAdtSocialMediaOptionsSaga(): SagaIterator {
  try {
    yield put(setAssetPlatformStatus({ type: "socialMediaOptions", value: "LOADING" }));
    const { data } = yield call(adtService.getMetaData, "socialNetwork");

    const lxConfiguration: LXConfiguration[] = yield select(selectLxConfigurations);

    const socialMediaOptions = mapSocialMediaOptions(lxConfiguration, data);

    if (socialMediaOptions) yield put(saveAdtPlatformSocialMediaOptions(socialMediaOptions));

    yield put(setAssetPlatformStatus({ type: "socialMediaOptions", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setAssetPlatformStatus({ type: "socialMediaOptions", value: "ERROR" }));
  }
}

function* getAdtViewsTypesPropertySaga(): SagaIterator {
  try {
    yield put(setAssetsMetaDataStatus({ type: "getViewsTypes", value: "LOADING" }));
    const { data } = yield call(adtService.getMetaData, "viewType");
    yield put(setAdtViewTypes(data.data.values));
    yield put(setAssetsMetaDataStatus({ type: "getViewsTypes", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setAssetsMetaDataStatus({ type: "getViewsTypes", value: "ERROR" }));
  }
}

////UPDATE ASSETS STATUS

function* addAdtAssetSavedSaga({ payload }: PayloadAction<UpdateAssetsPayload>): SagaIterator {
  const { assetIds, callback } = payload;
  try {
    yield put(setAssetsUpdateRequestStatus({ type: "updateSavedAssetStatus", value: "LOADING" }));
    yield call(adtService.addToSavedAssets, assetIds);
    yield put(setAssetsUpdateRequestStatus({ type: "updateSavedAssetStatus", value: "SUCCESS" }));
    callback?.(true);
  } catch (error) {
    yield put(setAssetsUpdateRequestStatus({ type: "updateSavedAssetStatus", value: "ERROR" }));
    yield put(handleError(error));
  }
}

function* removeAdtAssetSavedSaga({ payload }: PayloadAction<UpdateAssetsPayload>): SagaIterator {
  const { assetIds, callback } = payload;
  try {
    yield put(setAssetsUpdateRequestStatus({ type: "updateSavedAssetStatus", value: "LOADING" }));
    yield call(adtService.deleteFromSavedAssets, assetIds);
    callback?.(true);
    yield put(setAssetsUpdateRequestStatus({ type: "updateSavedAssetStatus", value: "SUCCESS" }));
  } catch (error) {
    yield put(setAssetsUpdateRequestStatus({ type: "updateSavedAssetStatus", value: "ERROR" }));
    yield put(handleError(error));
  }
}

function* addAdtAssetDownloadedSaga({ payload }: PayloadAction<UpdateAssetsPayload>): SagaIterator {
  const { assetIds, callback } = payload;
  try {
    yield put(
      setAssetsUpdateRequestStatus({ type: "updateDownloadedAssetStatus", value: "LOADING" })
    );
    yield call(adtService.addToDownloadedAssets, assetIds);
    yield put(
      setAssetsUpdateRequestStatus({ type: "updateDownloadedAssetStatus", value: "SUCCESS" })
    );
    callback?.(true);
  } catch (error) {
    yield put(
      setAssetsUpdateRequestStatus({ type: "updateDownloadedAssetStatus", value: "ERROR" })
    );
    yield put(handleError(error));
  }
}

/////////////////// landing page

////SAVED ASSETS
function* getAdtAssetsSavedPreviewSaga({ payload }: PayloadAction<GetAssetsPayload>): SagaIterator {
  try {
    const fileBasePath = yield select(selectAdtFileUrl);

    yield put(setAssetsPreviewRequestStatus({ type: "getSavedAssetsStatus", value: "LOADING" }));
    const { data } = yield call(adtService.getAdtAssetsSaved, payload);
    const mappedAssetsDataList = getMappedAssetsList(data?.data, fileBasePath, "saved");

    yield put(setAssetsSavedPreview(mappedAssetsDataList));
    yield put(setAssetsPreviewRequestStatus({ type: "getSavedAssetsStatus", value: "SUCCESS" }));
  } catch (error) {
    yield put(setAssetsPreviewRequestStatus({ type: "getSavedAssetsStatus", value: "ERROR" }));
    yield put(handleError(error));
  }
}

////DOWNLOADED ASSETS
function* getAdtAssetsDownloadedPreviewSaga({
  payload,
}: PayloadAction<GetAssetsPayload>): SagaIterator {
  try {
    const fileBasePath = yield select(selectAdtFileUrl);

    yield put(
      setAssetsPreviewRequestStatus({ type: "getDownloadedAssetsStatus", value: "LOADING" })
    );

    const { data } = yield call(adtService.getAdtAssetsDownloaded, payload);
    const mappedAssetsDataList = getMappedAssetsList(data?.data, fileBasePath, "downloaded");

    yield put(setAssetsDownloadedPreview(mappedAssetsDataList));
    yield put(
      setAssetsPreviewRequestStatus({ type: "getDownloadedAssetsStatus", value: "SUCCESS" })
    );
  } catch (error) {
    yield put(setAssetsPreviewRequestStatus({ type: "getDownloadedAssetsStatus", value: "ERROR" }));
    yield put(handleError(error));
  }
}

////RECENTLY ADDED ASSETS
function* getAdtAssetsRecentlyAddedPreviewSaga({
  payload,
}: PayloadAction<GetAssetByPagePayload>): SagaIterator {
  const { requestPayload, page } = payload;
  try {
    const fileBasePath = yield select(selectAdtFileUrl);

    const { scrollIds } = yield select(selectAssetsRecentlyAddedPreview);
    const scrollId = scrollIds[page - 1];
    yield put(
      setAssetsPreviewRequestStatus({ type: "getRecentlyAddedAssetsStatus", value: "LOADING" })
    );

    const getAssetsPayload = {
      ...requestPayload,
      scrollId: scrollId !== "init" ? scrollId : undefined,
      size: requestPayload.size ?? ADT_RECENTLY_ADDED_SIZE,
    };

    const { data } = yield call(adtService.getAdtAssets, getAssetsPayload);
    const mappedAssetsDataList = getMappedAssetsList(data?.data, fileBasePath);
    yield put(
      addAssetsRecentlyAddedPreview({
        assetList: mappedAssetsDataList,
        scrollIds: [data?.data?.scrollId ?? "end"],
      })
    );
    yield put(
      setAssetsPreviewRequestStatus({ type: "getRecentlyAddedAssetsStatus", value: "SUCCESS" })
    );
  } catch (error) {
    yield put(
      setAssetsPreviewRequestStatus({ type: "getRecentlyAddedAssetsStatus", value: "ERROR" })
    );
    yield put(handleError(error));
  }
}

/////////////////// plp

function* getAdtPlpAssetsSaga({ payload }: PayloadAction<GetAssetByPagePayload>): SagaIterator {
  const { requestPayload, page, resetRedux } = payload;
  try {
    const fileBasePath = yield select(selectAdtFileUrl);

    yield put(setPlpStatus({ type: "assets", value: "LOADING" }));
    // yield put(setPlpStatus({ type: "filters", value: "LOADING" }));

    let scrollIds: string[] = [];
    if (resetRedux) scrollIds = ["init"];
    else {
      const { scrollIds: scrollIdsAssets } = yield select(selectPlpAssets);
      scrollIds = scrollIdsAssets;
    }
    const scrollId: string = scrollIds[page - 1];

    const getAssetsPayload = {
      ...requestPayload,
      scrollId: scrollId !== "init" ? scrollId : undefined,
      size: requestPayload.size ?? ADT_PLP_PAGE_SIZE,
    };

    // const getFiltersPayload = {
    //   ...requestPayload,
    // };

    const { data: assetsData } = yield call(adtService.getAdtAssets, getAssetsPayload);
    // const { data: filtersData } = yield call(adtService.getAdtFilters, getFiltersPayload);

    const mappedAssetsDataList = getMappedAssetsList(assetsData?.data, fileBasePath);
    if (resetRedux) yield put(clearPlpAssets());

    yield put(
      addPlpAssets({
        assetList: mappedAssetsDataList,
        scrollIds: [assetsData?.data?.scrollId ?? "end"],
      })
    );

    // yield put(setPlpFilters(filtersData?.data?.filters));

    yield put(setPlpStatus({ type: "assets", value: "SUCCESS" }));
    // yield put(setPlpStatus({ type: "filters", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setPlpStatus({ type: "assets", value: "ERROR" }));
    // yield put(setPlpStatus({ type: "filters", value: "ERROR" }));
  }
}

/////////////////// plp filters
function* getAdtPlpFiltersSaga({ payload }: PayloadAction<GetAssetByPagePayload>): SagaIterator {
  const { requestPayload } = payload;
  try {
    yield put(setPlpStatus({ type: "filters", value: "LOADING" }));

    const getFiltersPayload = {
      ...requestPayload,
    };

    const { data: filtersData } = yield call(adtService.getAdtFilters, getFiltersPayload);

    yield put(setPlpFilters(filtersData?.data?.filters));
    yield put(setPlpStatus({ type: "filters", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setPlpStatus({ type: "filters", value: "ERROR" }));
  }
}

/////////////////// pdp
function* getAdtPdpAssetsDetailsSaga({ payload }: PayloadAction<GetPdpAssetPayload>): SagaIterator {
  try {
    const fileBasePath = yield select(selectAdtFileUrl);

    yield put(setAdtPdpStatus({ type: "getAssetsDetails", value: "LOADING" }));
    const { data } = yield call(adtService.getAdtAssetsDetails, payload);
    const mappedData = mapAssetData(data?.data.assets[0], fileBasePath);
    yield put(setAdtAssetsDetails(mappedData));
    yield put(setAdtPdpStatus({ type: "getAssetsDetails", value: "SUCCESS" }));
  } catch (error) {
    yield put(setAdtPdpStatus({ type: "getAssetsDetails", value: "ERROR" }));
    yield put(handleError(error));
  }
}

function* getAdtPdpAssetsRelatedSaga({ payload }: PayloadAction<GetPdpAssetPayload>): SagaIterator {
  try {
    const fileBasePath = yield select(selectAdtFileUrl);

    yield put(setAdtPdpStatus({ type: "getAssetsRelated", value: "LOADING" }));
    const { data } = yield call(adtService.getAdtAssetsRelated, payload);
    const mapAssetsRelated = getMappedAssetsList(data?.data, fileBasePath);
    yield put(setAdtAssetsRelated(mapAssetsRelated));
    yield put(setAdtPdpStatus({ type: "getAssetsRelated", value: "SUCCESS" }));
  } catch (error) {
    yield put(setAdtPdpStatus({ type: "getAssetsRelated", value: "ERROR" }));
    yield put(handleError(error));
  }
}

function* getAdtPdpFeaturedProductsSaga({
  payload,
}: PayloadAction<GetPdpFeaturedProductsPayload>): SagaIterator {
  try {
    const fileBasePath = yield select(selectAdtFileUrl);
    yield put(setAdtPdpStatus({ type: "getFeaturedProducts", value: "LOADING" }));
    const { data } = yield call(adtService.getAdtProductsImagesByMoco, payload);
    const mapFeaturedProducts = getMappedProductsImagesList(data.data, fileBasePath);
    yield put(setAdtFeaturedProducts(mapFeaturedProducts));
    yield put(setAdtPdpStatus({ type: "getFeaturedProducts", value: "SUCCESS" }));
  } catch (error) {
    yield put(setAdtPdpStatus({ type: "getFeaturedProducts", value: "ERROR" }));
    yield put(handleError(error));
  }
}

/////////////////// myaccount

function* getAdtAssetsSavedAccountSaga({
  payload,
}: PayloadAction<GetAssetByPagePayload>): SagaIterator {
  const { requestPayload, page } = payload;
  try {
    const fileBasePath = yield select(selectAdtFileUrl);

    const { scrollIds } = yield select(selectAssetsSavedAccount);
    const scrollId = scrollIds[page - 1];
    yield put(setAssetsAccountRequestStatus({ type: "getSavedAssetsStatus", value: "LOADING" }));
    const { data } = yield call(
      adtService.getAdtAssetsSaved,
      scrollId && scrollId != "init" ? { ...requestPayload, scrollId: scrollId } : requestPayload
    );
    const mappedAssetsDataList = getMappedAssetsList(data?.data, fileBasePath, "saved");
    yield put(
      addAssetsSavedAccount({
        assetList: mappedAssetsDataList,
        scrollIds: [data?.data?.scrollId ?? "end"],
      })
    );
    yield put(setAssetsAccountRequestStatus({ type: "getSavedAssetsStatus", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setAssetsAccountRequestStatus({ type: "getSavedAssetsStatus", value: "ERROR" }));
  }
}

function* getAdtAssetsDownloadedAccountSaga({
  payload,
}: PayloadAction<GetAssetByPagePayload>): SagaIterator {
  const { requestPayload, page } = payload;
  try {
    const fileBasePath = yield select(selectAdtFileUrl);

    const { scrollIds } = yield select(selectAssetsDownloadedAccount);
    const scrollId = scrollIds[page - 1];
    yield put(
      setAssetsAccountRequestStatus({ type: "getDownloadedAssetsStatus", value: "LOADING" })
    );
    const { data } = yield call(
      adtService.getAdtAssetsDownloaded,
      scrollId && scrollId != "init" ? { ...requestPayload, scrollId: scrollId } : requestPayload
    );
    const mappedAssetsDataList = getMappedAssetsList(data?.data, fileBasePath, "downloaded");
    yield put(
      addAssetsDownloadedAccount({
        assetList: mappedAssetsDataList,
        scrollIds: [data?.data?.scrollId ?? "end"],
      })
    );
    yield put(
      setAssetsAccountRequestStatus({ type: "getDownloadedAssetsStatus", value: "SUCCESS" })
    );
  } catch (error) {
    yield put(handleError(error));
    yield put(setAssetsAccountRequestStatus({ type: "getDownloadedAssetsStatus", value: "ERROR" }));
  }
}

function* getAdtAssetsSavedFiltersSaga({ payload }: PayloadAction<GetAssetsPayload>): SagaIterator {
  try {
    yield put(setAssetsAccountRequestStatus({ type: "savedFiltersStatus", value: "LOADING" }));
    const { data } = yield call(adtService.getAdtAssetsSavedFilters, payload);

    yield put(setAssetsSavedAccountFilters(data?.data?.filters));

    yield put(setAssetsAccountRequestStatus({ type: "savedFiltersStatus", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setAssetsAccountRequestStatus({ type: "savedFiltersStatus", value: "ERROR" }));
  }
}

function* getAdtAssetsDownloadedFiltersSaga({
  payload,
}: PayloadAction<GetAssetsPayload>): SagaIterator {
  try {
    yield put(setAssetsAccountRequestStatus({ type: "downloadedFiltersStatus", value: "LOADING" }));
    const { data } = yield call(adtService.getAdtAssetsDownloadedFilters, payload);

    yield put(setAssetsDownloadedAccountFilters(data?.data?.filters));

    yield put(setAssetsAccountRequestStatus({ type: "downloadedFiltersStatus", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setAssetsAccountRequestStatus({ type: "downloadedFiltersStatus", value: "ERROR" }));
  }
}

function* sendAdtRequestAssetsDownloadSaga({
  payload,
}: PayloadAction<SendRequestDownloadAssetsPayload>): SagaIterator {
  const { assetIds, callback } = payload;
  try {
    yield put(
      setAssetsAccountRequestStatus({ type: "sendRequestDownloadStatus", value: "LOADING" })
    );
    const { data } = yield call(adtService.sendAdtRequestAssetsDownload, payload);
    yield put(
      addAdtAssetDownloaded({
        assetIds: assetIds,
        callback: (isSuccess) => {
          if (isSuccess) {
            callback(true);
          }
        },
      })
    );
    yield put(
      setAssetsAccountRequestStatus({ type: "sendRequestDownloadStatus", value: "SUCCESS" })
    );
  } catch (error) {
    yield put(handleError(error));
    yield put(setAssetsAccountRequestStatus({ type: "sendRequestDownloadStatus", value: "ERROR" }));
  }
}

/////////////////// request image

function* getAdtOrderAssortmentDownloadSaga({
  payload,
}: PayloadAction<GetOrderAssortmentPayload>): SagaIterator {
  try {
    yield put(setAssetRequestProductImagesStatus({ type: "sendRequest", value: "LOADING" }));
    const { data } = yield call(adtService.getAdtOrderAssortmentDownload, payload);
    yield put(setAssetRequestProductImagesStatus({ type: "sendRequest", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setAssetRequestProductImagesStatus({ type: "sendRequest", value: "ERROR" }));
  }
}

function* getAdtTemplateRequestImagesDownloadSaga({
  payload,
}: PayloadAction<string>): SagaIterator {
  try {
    const templateFiles = yield select(selectTemplatesRequestProductImages);
    let templateFile = templateFiles[payload];
    if (!templateFile) {
      const res = yield call(adtService.getAdtTemplateDownload, payload);
      yield put(saveTemplateRequestProductImages({ id: payload, file: res.data }));
      templateFile = res.data;
    }
    downloadBlobFile(
      templateFile,
      `${payload}-products-images-template`,
      payload === "upc" ? "txt" : "csv"
    );
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* adtSaga(): SagaIterator {
  /////////////////// common
  yield takeLatest(getAdtIsFirstLogin.type, getAdtIsFirstLoginSaga);
  yield takeLatest(getAdtViewsTypesProperty.type, getAdtViewsTypesPropertySaga);
  yield takeLatest(getAdtAssetPlatformWebsite.type, getAdtAssetPlatformWebsiteSaga);
  yield takeLatest(getAdtAssetPlatformSocialMedia.type, getAdtAssetPlatformSocialMediaSaga);
  yield takeLatest(getAdtSocialMediaOptions.type, getAdtSocialMediaOptionsSaga);
  yield takeEvery(getAdtCategories.type, getAdtCategoriesSaga);
  yield takeLatest(updateAdtAssetPlatformWebsite.type, updateAdtAssetPlatformWebsiteSaga);
  yield takeLatest(updateAdtAssetPlatformSocialMedia.type, updateAdtAssetPlatformSocialMediaSaga);
  yield takeLatest(updateAdtAssetPlatfomPopup.type, updateAdtAssetPlatfomPopupSaga);
  yield takeLatest(addAdtAssetSaved.type, addAdtAssetSavedSaga);
  yield takeLatest(removeAdtAssetSaved.type, removeAdtAssetSavedSaga);
  yield takeLatest(addAdtAssetDownloaded.type, addAdtAssetDownloadedSaga);

  /////////////////// landing page
  yield takeLatest(getAdtAssetsSavedPreview.type, getAdtAssetsSavedPreviewSaga);
  yield takeLatest(getAdtAssetsDownloadedPreview.type, getAdtAssetsDownloadedPreviewSaga);
  yield takeLatest(getAdtAssetsRecentlyAddedPreview.type, getAdtAssetsRecentlyAddedPreviewSaga);

  /////////////////// plp
  yield takeLatest(getAdtPlpAssets.type, getAdtPlpAssetsSaga);

  ////////////////// filters plp
  yield takeLatest(getAdtPlpFilters.type, getAdtPlpFiltersSaga);

  /////////////////// pdp
  yield takeLatest(getAdtPdpAssetsDetails.type, getAdtPdpAssetsDetailsSaga);
  yield takeLatest(getAdtPdpAssetsRelated.type, getAdtPdpAssetsRelatedSaga);
  yield takeLatest(getAdtPdpFeaturedProducts.type, getAdtPdpFeaturedProductsSaga);

  /////////////////// myaccount
  yield takeLatest(getAdtAssetsSavedAccount.type, getAdtAssetsSavedAccountSaga);
  yield takeLatest(getAdtAssetsDownloadedAccount.type, getAdtAssetsDownloadedAccountSaga);
  yield takeLatest(getAdtAssetsSavedFilters.type, getAdtAssetsSavedFiltersSaga);
  yield takeLatest(getAdtAssetsDownloadedFilters.type, getAdtAssetsDownloadedFiltersSaga);
  yield takeLatest(sendAdtRequestAssetsDownload.type, sendAdtRequestAssetsDownloadSaga);

  /////////////////// request image
  yield takeLatest(getAdtOrderAssortmentDownload.type, getAdtOrderAssortmentDownloadSaga);
  yield takeLatest(
    getAdtTemplateRequestImagesDownload.type,
    getAdtTemplateRequestImagesDownloadSaga
  );
}
