import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectPrePLPBrandGroup } from "../store/catalogue/catalogueSlice";
import { selectBrandLabels } from "../store/store/storeSlice";
import { capitalizeFirstLetterForEachWord } from "../utils/utils";

const usePrePlpTitle = (): { title: string } => {
  const { brandName } = useParams() as {
    brandName: string | undefined;
  };

  const prePLPBrandGroup = useSelector(selectPrePLPBrandGroup);
  const labels = useSelector(selectBrandLabels);

  const [title, setTitle] = useState("");

  useEffect(() => {
    const newTitle = prePLPBrandGroup
      ? labels[prePLPBrandGroup]
      : brandName
      ? capitalizeFirstLetterForEachWord(brandName.replace("-", " "))
      : "";

    if (newTitle !== title) setTitle(newTitle);
  }, [prePLPBrandGroup, brandName]);

  return { title: title };
};

export default usePrePlpTitle;
