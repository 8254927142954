import React from "react";
import styled from "styled-components/macro";

import CustomText from "./CustomText";
import Button from "./Button";
import LinkButton from "./LinkButton";

interface Props {
  icon?: JSX.Element;
  text: string;
  dismissText?: string;
  dismissAction?: () => void;
  acceptText: string;
  acceptAction: () => void;
  "data-element-id"?: string | null;
}

export default function BottomBanner({
  icon,
  text,
  dismissText,
  dismissAction,
  acceptText,
  acceptAction,
  "data-element-id": dataElementId,
}: Props): JSX.Element {
  return (
    <BannerWrapper>
      <div className="d-flex">
        {icon && <IconContainer>{icon}</IconContainer>}
        <CustomText as="p" fontSizePx={13}>
          {text}
        </CustomText>
      </div>
      <div className="d-flex">
        {dismissText && (
          <DismissContainer>
            <LinkButton
              {...(dataElementId ? { "data-element-id": dataElementId } : {})}
              underline={false}
              onClick={() => {
                dismissAction?.();
              }}
            >
              {dismissText}
            </LinkButton>
          </DismissContainer>
        )}
        <Button type="primary" onClick={acceptAction}>
          {acceptText}
        </Button>
      </div>
    </BannerWrapper>
  );
}

const DismissContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 3.35rem;
  margin-left: 0.75rem;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    path {
      fill: ${(props) => props.theme.palette.primary};
    }
  }
`;

const BannerWrapper = styled.div`
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 0.75rem;
  width: 75rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 3.25rem 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  z-index: 1;
  @media ${(props) => props.theme.queries.md} {
    width: 95%;
  }
`;
