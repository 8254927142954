import { AxiosResponse } from "axios";
import { createAndExecuteService, getSearchParamsFromPayload } from "../serviceUtils";
import {
  GetSubuserListPayload,
  SendSubuserEmailPayloadService,
  CreateSubuserPayloadService,
  SubmitUserPrivilegesPayload,
  EditSubuserStatusServicePayload,
  UpdateSubuserPayloadServiceMyEL,
  UpdateSubuserPayloadServiceLeo,
} from "./subuserInterfaces";
import {
  subuserListUrl,
  subuserItemUrl,
  subuserCreateUrl,
  subuserStatusUrl,
  subuserSendEmailUrl,
  userPrivilegesUrl,
  getSubuserTemplatesUrl,
  newUserPrivilegesUrl,
  subuserListLeoOnlyUrl,
  subuserItemLeoOnlyUrl,
  subuserSendEmailLeoOnlyUrl,
} from "./subuserUrls";

export default {
  ///////////////// MyEL SUBUSERS
  getSubuserList: async (payload: GetSubuserListPayload): Promise<AxiosResponse> => {
    const qparams = getSearchParamsFromPayload(payload);

    return createAndExecuteService(subuserListUrl, "GET", qparams);
  },
  getSubuserItem: async (userId: string): Promise<AxiosResponse> => {
    const url = subuserItemUrl.replace("{userId}", userId);
    return createAndExecuteService(url, "GET");
  },
  putEditSubuser: async (payload: UpdateSubuserPayloadServiceMyEL): Promise<AxiosResponse> => {
    const url = subuserItemUrl.replace("{userId}", payload.userId as string);
    return createAndExecuteService(url, "PUT", null, payload.data);
  },
  postCreateSubuser: async (payload: CreateSubuserPayloadService): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ doorId: payload.doorId ?? "" });
    return createAndExecuteService(subuserCreateUrl, "POST", qparams, payload.data);
  },
  postEditSubuserStatus: async (
    payload: EditSubuserStatusServicePayload
  ): Promise<AxiosResponse> => {
    const url = subuserStatusUrl
      .replace("{userId}", payload.userId)
      .replace("{userStatus}", payload.userStatus);
    return createAndExecuteService(url, "POST", null);
  },
  sendSubuserEmailService: async (
    payload: SendSubuserEmailPayloadService
  ): Promise<AxiosResponse> => {
    const qparams = {
      email: payload.email,
    };
    const url = subuserSendEmailUrl.replace("{username}", payload.username);

    return createAndExecuteService(url, "POST", null, qparams);
  },
  getUserPrivileges: async (iduser: string): Promise<AxiosResponse> => {
    const url = userPrivilegesUrl.replace("{iduser}", iduser);
    return createAndExecuteService(url, "GET");
  },
  getNewUserPrivileges: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(newUserPrivilegesUrl, "GET");
  },
  submitUserPrivileges: async (payload: SubmitUserPrivilegesPayload): Promise<AxiosResponse> => {
    const url = userPrivilegesUrl.replace("{iduser}", payload.userId);

    return createAndExecuteService(url, "POST", undefined, payload.privilegeDiff);
  },
  getSubuserTemplates: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getSubuserTemplatesUrl, "GET");
  },

  ///////////////// LeoOnly SUBUSERS
  getSubuserListLeoOnly: async (payload: GetSubuserListPayload): Promise<AxiosResponse> => {
    const qparams = getSearchParamsFromPayload(payload);
    return createAndExecuteService(subuserListLeoOnlyUrl, "GET", qparams);
  },
  getSubuserItemLeoOnly: async (username: string): Promise<AxiosResponse> => {
    const url = subuserItemLeoOnlyUrl.replace("{username}", username as string);
    return createAndExecuteService(url, "GET");
  },
  putEditSubuserLeoOnly: async (
    payload: UpdateSubuserPayloadServiceLeo
  ): Promise<AxiosResponse> => {
    const url = subuserItemLeoOnlyUrl.replace("{username}", payload.username as string);
    return createAndExecuteService(url, "PUT", null, payload.data);
  },
  sendSubuserEmailLeoOnly: async (username: string): Promise<AxiosResponse> => {
    const url = subuserSendEmailLeoOnlyUrl.replace("{username}", username);
    return createAndExecuteService(url, "POST");
  },
};
