import { AxiosResponse } from "axios";

import { createAndExecuteService } from "../serviceUtils";
import {
  CheckFrameReq,
  GetLensesPayload,
  MadeInPayload,
  PostMakeabilityPayload,
} from "./rxInterface";
import {
  brandGroupsUrl,
  brandsUrl,
  checkFrameUrl,
  getLensesUrl,
  colorsUrl,
  getCartItemUrl,
  getFocalTypeUrl,
  getPricesUrl,
  jobTypeListUrl,
  madeInUrl,
  partnumbersUrl,
  sizeUrl,
  upcUrl,
  makeabilityUrl,
  collectionUrl,
} from "./rxUrls";

export const rxServices = {
  brandGroups: async (brandGroupsValue?: string, doorId?: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    doorId && qparams.append("doorId", doorId);
    const url = brandGroupsUrl + (brandGroupsValue || "*");

    return createAndExecuteService(url, "GET", qparams);
  },
  brands: async (
    brandGroupsValue: string,
    term?: string,
    doorId?: string
  ): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    doorId && qparams.append("doorId", doorId);
    const url = brandsUrl.replace("{brandGroupsValue}", brandGroupsValue) + (term || "*");

    return createAndExecuteService(url, "GET", qparams);
  },
  getCollections: async (brand: string, doorId?: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    doorId && qparams.append("doorId", doorId);
    qparams.append("facetName", "PRODUCT_TYPE");
    qparams.append("facet", "manufacturer.raw:" + brand);
    // qparams.append("facet", "RX_COMPLETE_JOB:TRUE");

    return createAndExecuteService(collectionUrl, "GET", qparams);
  },
  getJobTypeList: async (brand: string, doorId?: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    const url = jobTypeListUrl.replace("{brand}", brand);

    return createAndExecuteService(url, "GET", qparams);
  },
  getPartnumbers: async (
    manufacturer: string,
    jobType: string,
    doorId?: string
  ): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ jobType });
    doorId && qparams.append("doorId", doorId);
    const url = partnumbersUrl.replace("{manufacturer}", manufacturer);

    return createAndExecuteService(url, "GET", qparams);
  },
  getCollectionPartnumbers: async (
    manufacturer: string,
    collection: string,
    jobType: string,
    doorId?: string
  ): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ jobType });
    qparams.append("facet", `PRODUCT_TYPE:"${collection}"`);
    doorId && qparams.append("doorId", doorId);
    const url = partnumbersUrl.replace("{manufacturer}", manufacturer);

    return createAndExecuteService(url, "GET", qparams);
  },
  getColors: async (
    partNumber: string,
    jobType: string,
    doorId?: string
  ): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ jobType });
    doorId && qparams.append("doorId", doorId);
    const url = colorsUrl.replace("{partNumber}", partNumber);

    return createAndExecuteService(url, "GET", qparams);
  },
  getUpcCode: async (
    brand: string,
    jobType: string,
    term: string,
    doorId?: string
  ): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ jobType });
    doorId && qparams.append("doorId", doorId);
    const url = upcUrl.replace("{brand}", brand).replace("{term}", term);

    return createAndExecuteService(url, "GET", qparams);
  },
  getSize: async (jobType: string, catentryId: string, doorId?: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ jobType });
    doorId && qparams.append("doorId", doorId);
    const url = sizeUrl.replace("{catentryId}", catentryId);

    return createAndExecuteService(url, "GET", qparams);
  },
  getFocalType: async (brand: string, jobType: string, doorId?: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ jobType });
    doorId && qparams.append("doorId", doorId);
    const url = getFocalTypeUrl.replace("{brand}", brand);
    return createAndExecuteService(url, "GET", qparams);
  },
  checkFrame: async (payload: CheckFrameReq, doorId?: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    doorId && qparams.append("doorId", doorId);

    return createAndExecuteService(checkFrameUrl, "POST", qparams, payload);
  },
  getLenses: async (payload: GetLensesPayload, doorId?: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    doorId && qparams.append("doorId", doorId);

    const url = getLensesUrl.replace("{partnumber}", encodeURIComponent(payload.itemIdentifier));

    return createAndExecuteService(url, "POST", qparams, payload);
  },
  madeIn: async (payload: MadeInPayload, doorId?: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ jobType: payload.jobType });
    doorId && qparams.append("doorId", doorId);

    const url = madeInUrl.replace("{brand}", payload.brand);
    return createAndExecuteService(url, "GET", qparams, payload);
  },
  postMakeability: async (
    payload: PostMakeabilityPayload,
    doorId?: string
  ): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    doorId && qparams.append("doorId", doorId);

    return createAndExecuteService(makeabilityUrl, "POST", qparams, payload);
  },
  getPrices: async (items: string[], doorId?: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    doorId && qparams.append("doorId", doorId);
    items.forEach((item) => qparams.append("items", item));

    return createAndExecuteService(getPricesUrl, "GET", qparams);
  },
  getCartItem: async (payload: string): Promise<AxiosResponse> => {
    const url = getCartItemUrl.replace("{orderItemsId}", payload);

    return createAndExecuteService(url, "GET");
  },
};
