import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";
import cartService from "../../../../store/cart/cartService";
import useStatelessService from "../../../../store/statelessServices/useStatelessService";
import { Attribute, ProductCategory } from "../../../../interfaces/productInterface";
import { capitalizeFirstLetter } from "../../../../utils/utils";
import LoaderCartDetails from "../../../styled-UI/loader/cart/LoaderDetails";
import CustomText from "../../../styled-UI/CustomText";
import {
  CustomRx,
  OrderItem,
  RxPrescriptionBrasilStatus,
} from "../../../../store/cart/cartInterfaces";
import { isOrderItemRx } from "../../../../utils/cartUtils";
import CartTileRxDetails from "./CartTileRxDetails";
import { getCustomDetails } from "../../../../store/cart/cartSagas";
import { useDispatch, useSelector } from "react-redux";
import { selectDetails, selectDetailsStatus } from "../../../../store/cart/cartSlice";
import CartTileDetailsM4CCustom from "./CartTileDetailsM4CCustom";

interface Props {
  showDetails: boolean;
  outOfStock: boolean;
  productId: string;
  category?: ProductCategory;
  sparePartsDescription?: string;
  brand?: string;
  brandGroup?: string;
  jobType?: string;
  xitemRXfocalTypeName?: string;
  rxMappedData?: CustomRx;
  currentOrderItem: OrderItem;
  rxBrasilStatus?: RxPrescriptionBrasilStatus | null;
}

const CartTileDetails = ({
  showDetails,
  outOfStock,
  productId,
  category,
  sparePartsDescription,
  brandGroup,
  brand,
  jobType,
  xitemRXfocalTypeName,
  rxMappedData,
  currentOrderItem,
  rxBrasilStatus,
}: Props): JSX.Element | null => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const { requestStatus, makeRequest, resetRequestState } = useStatelessService();
  const detailsM4C = useSelector(selectDetails); // TODO: check if it can't be done locally the same way as normal details

  const [details, setDetails] = useState<Attribute[]>([]);
  const recipeId = currentOrderItem?.xitemM4CConfigurationId;
  const brandCode = currentOrderItem?.sku.brand;
  const detailsStatus = useSelector(selectDetailsStatus);

  useEffect(() => {
    if (details.length === 0 && showDetails && !sparePartsDescription && !recipeId)
      makeRequest(cartService.getCartProductDetails, { productId, category });
  }, [showDetails]);

  useEffect(() => {
    if (recipeId && brandCode && showDetails && !detailsM4C[recipeId]) {
      dispatch(
        getCustomDetails({
          recipeId: recipeId,
          brand: brandCode,
        })
      );
    }
  }, [recipeId, brandCode, showDetails]);

  useEffect(() => {
    if (requestStatus.status === "SUCCESS") {
      const { data } = requestStatus.data;

      const newDetails = data?.catalogEntryView?.[0]?.attributes;
      newDetails && setDetails(newDetails);
      resetRequestState();
    }
  }, [requestStatus.status]);

  const isOrderRx = isOrderItemRx(currentOrderItem);

  return showDetails ? (
    isOrderRx ? (
      <CartTileRxDetails
        xitemRXfocalTypeName={xitemRXfocalTypeName}
        rxMappedData={rxMappedData}
        currentOrderItem={currentOrderItem}
        brandGroup={brandGroup}
        brand={brand}
        jobType={jobType}
        outOfStock={outOfStock}
        rxBrasilStatus={rxBrasilStatus}
      />
    ) : recipeId ? (
      <OuterBoxM4CDetails>
        {detailsStatus && detailsStatus[recipeId] ? (
          <LoaderCartDetails />
        ) : (
          <CartTileDetailsM4CCustom recipeId={recipeId} />
        )}
      </OuterBoxM4CDetails>
    ) : (
      <TileDetailsContainer className={outOfStock ? "container-out-of-stock" : ""}>
        <TileDetails className={outOfStock ? "details-out-of-stock" : ""}>
          {requestStatus.status === "LOADING" ? (
            <LoaderCartDetails />
          ) : (
            <Content>
              {sparePartsDescription ? (
                <DetailLine>
                  <CustomText as="span" fontSizePx={13} lineHeightPx={18} color="primary">
                    {translateLabel("CART_SPAREPARTS_FAMILY_LABEL") + ":"}
                  </CustomText>
                  <CustomText
                    as="span"
                    font="font-bold"
                    fontSizePx={13}
                    color="primary"
                    lineHeightPx={18}
                  >
                    {capitalizeFirstLetter(sparePartsDescription)}
                  </CustomText>
                </DetailLine>
              ) : (
                details.map((attr) => (
                  <DetailLine key={attr.identifier}>
                    <CustomText as="span" fontSizePx={13} lineHeightPx={18} color="primary">
                      {capitalizeFirstLetter(attr.name)}
                    </CustomText>
                    <CustomText
                      as="span"
                      font="font-bold"
                      fontSizePx={13}
                      color="primary"
                      lineHeightPx={18}
                    >
                      {capitalizeFirstLetter(attr.values?.map((_) => _.value).join(", "))}
                    </CustomText>
                  </DetailLine>
                ))
              )}
            </Content>
          )}
        </TileDetails>
      </TileDetailsContainer>
    )
  ) : null;
};

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 0.5rem;

  @media ${(props) => props.theme.queries.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

const DetailLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;

  @media ${(props) => props.theme.queries.md} {
    grid-column-gap: 0.3rem;
  }

  & > span {
    align-self: start;
  }
`;

const TileDetailsContainer = styled.div`
  padding: 0 1rem 1.5rem 1rem;
  background-color: ${(props) => props.theme.palette.white};

  &.container-out-of-stock {
    background-color: ${(props) => props.theme.palette.gray.light};
  }
`;

const TileDetails = styled.div`
  padding: 1.5rem;
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.palette.gray.light};

  &.details-out-of-stock {
    background-color: ${(props) => props.theme.palette.white};
  }
`;

const OuterBoxM4CDetails = styled.div`
  margin: 1.5rem;
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.palette.gray.light};
  padding: 1rem;
`;

export default CartTileDetails;
