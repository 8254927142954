import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";
import CustomText from "../../../styled-UI/CustomText";
import Button from "../../../styled-UI/Button";
import { useSelector } from "react-redux";
import { selectAddToCartRequestStatus } from "../../../../store/cart/cartSlice";
import { capitalizeFirstLetter } from "../../../../utils/utils";

interface Props {
  addToCart?: () => void;
}

const TileFooterAddToCart = ({ addToCart }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  const addToCartStatus = useSelector(selectAddToCartRequestStatus);
  const [loading, setLoading] = useState<boolean>(false); //LOCAL LOADING
  const [printMessageLocal, setPrintMessageLocal] = useState<boolean>(false); //LOCAL LOADING
  const [message, setMessage] = useState<string>("");

  const printMessage = () => {
    switch (addToCartStatus) {
      case "SUCCESS":
        return "STICKY_BAR_PRODUCTS_ADDED";

      case "ERROR":
        return "STICKY_BAR_GENERIC_CART_ERROR";
    }
    return "";
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage("");
        printMessageLocal && setPrintMessageLocal(false);
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    if (addToCartStatus !== "LOADING" && loading) {
      setLoading(false);
    }

    if ((addToCartStatus === "SUCCESS" || addToCartStatus === "ERROR") && printMessageLocal) {
      setMessage(printMessage());
      setPrintMessageLocal(false);
    }
  }, [addToCartStatus]);

  return (
    <ButtonContainer>
      <CustomText
        as="span"
        isFlex={false}
        textAlign="center"
        color={
          (addToCartStatus === "SUCCESS" && "green") ||
          (addToCartStatus === "ERROR" && "red") ||
          "primary"
        }
        marginBottomPx={5}
      >
        {capitalizeFirstLetter(translateLabel(message))}
      </CustomText>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setLoading(true);
          setPrintMessageLocal(true);
          addToCart && addToCart();
        }}
        type="primary"
        fullWidth
        disabled={addToCartStatus === "LOADING"}
        isLoading={loading && addToCartStatus === "LOADING"}
        data-element-id="Products_SelectedForYou_AddToCart"
      >
        {translateLabel("ADD_TO_CART")}
      </Button>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  padding: 0.5rem 0 1rem 0;
`;

export default TileFooterAddToCart;
