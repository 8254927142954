import { BFFApiDomain } from "../serviceUtils";

export const getSuggestionsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/suggestion/{term}";

export const getSearchResultUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/search/{term}";

export const getSearchCategoriesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/search/categories";

export const getSearchTermAssociationUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/search/termAssociation";
