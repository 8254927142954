import React from "react";
import styled from "styled-components/macro";
import clsx from "clsx";

import CustomText from "../../components/styled-UI/CustomText";
import { Status } from "./Stepper";

import { ReactComponent as XIcon } from "../../assets/icons/x-icon.svg";
import { ReactComponent as Alert } from "../../assets/icons/alert-icon.svg";
import { ReactComponent as Checkmark } from "../../assets/icons/checkmark-icon.svg";

export type StepType = "active" | "next" | "prev";

interface Props {
  index: number;
  label: string;
  status?: Status;
  stepType: StepType;
  hasOnClick: boolean;
  onClick: () => void;
  maxWidth?: boolean;
  backgroundVariant?: boolean;
}

const Step = ({
  index,
  label,
  status,
  stepType,
  hasOnClick,
  onClick,
  maxWidth,
  backgroundVariant = false,
}: Props): JSX.Element => {
  const renderStepContent = () => {
    if (status === "warning")
      return (
        <IconContainer>
          <Alert />
        </IconContainer>
      );

    if (status === "error")
      return (
        <IconContainer>
          <XIcon />
        </IconContainer>
      );

    if (status === "complete")
      return (
        <IconContainer>
          <Checkmark />
        </IconContainer>
      );

    return (
      <CustomText as="span" fontSizePx={13} color={getStepTextColor(stepType)}>
        {index}
      </CustomText>
    );
  };

  const getStepTextColor = (stepType: string) => {
    if (backgroundVariant) {
      /** If background variant is active, the background of the active and previous steps is blue,
       *  so the active and previous steps text has white color. **/
      return stepType === "next" ? "primary" : "white";
    } else {
      /** If background variant is NOT active, the background of the active and next steps is white,
       *  so the active and next steps text has blue color. **/
      return stepType === "prev" ? "white" : "primary";
    }
  };

  return (
    <>
      <StepNumber
        className={clsx(
          status === "error" && "step-number-error",
          status === "warning" && "step-number-warning"
        )}
        stepType={stepType}
        backgroundVariant={backgroundVariant}
        hasOnClick={hasOnClick}
        onClick={onClick}
      >
        {renderStepContent()}
      </StepNumber>
      <LabelContainer className={maxWidth ? "max-width-label" : ""}>
        <CustomText as="span" fontSizePx={13}>
          {label}
        </CustomText>
      </LabelContainer>
    </>
  );
};

type StepNumAttr = {
  stepType: string;
  hasOnClick: boolean;
  backgroundVariant: boolean;
};

const StepNumber = styled.button<StepNumAttr>`
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.5rem;
  border-radius: 50%;
  border: solid 0.125rem ${(props) => props.theme.palette.primary};
  border-color: ${(props) =>
    props.stepType === "next" ? props.theme.palette.gray.medium : props.theme.palette.primary};
  background-color: ${(props) =>
    props.stepType === "prev" ? props.theme.palette.primary : props.theme.palette.white};
  background-color: ${(props) =>
    props.backgroundVariant && props.stepType === "active" ? props.theme.palette.primary : ""};

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: ${(props) => (props.stepType === "prev" && props.hasOnClick ? "pointer" : "default")};
  &span {
    display: block;
  }
  .step-number.active {
    background-color: ${(props) => props.theme.palette.white};
  }
  .step-number.next {
    background-color: ${(props) => props.theme.palette.white};
    border-color: ${(props) => props.theme.palette.gray.medium};
  }

  &.step-number-error {
    background-color: ${(props) => props.theme.palette.feedback.error};
    border: solid 0.125rem ${(props) => props.theme.palette.feedback.error};
  }

  &.step-number-warning {
    background-color: ${(props) => props.theme.palette.feedback.alert};
    border: solid 0.125rem ${(props) => props.theme.palette.feedback.alert};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  fill: ${(props) => props.theme.palette.white};

  svg {
    height: 1rem;
    width: 1rem;
  }
`;

const LabelContainer = styled.div`
  &.max-width-label {
    span {
      max-width: 7.5rem;
      text-align: center;
    }
  }
`;

export default Step;
