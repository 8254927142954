import { useMemo } from "react";

const compatibleKeyTypes = ["object", "function"];

function ReactKeyGen(this: any, { keyBaseName = "keyGen_" } = {}) {
  const keysMap = new WeakMap();

  const getUniqueKey = (() => {
    let cpt = -1;

    return () => {
      cpt += 1;
      return `${keyBaseName}${cpt}`;
    };
  })();

  this.getKey = (whatever: any): string => {
    if (!compatibleKeyTypes.includes(typeof whatever))
      return whatever.toString ? whatever.toString() : whatever;

    const key = keysMap.get(whatever);
    if (key) return key;
    const newKey = getUniqueKey();
    keysMap.set(whatever, newKey);

    return newKey;
  };
}

const useGenerateKeyFromObject = () => {
  const keyGen = useMemo(() => new (ReactKeyGen as any)(), []);
  return keyGen;
};

export default useGenerateKeyFromObject;
