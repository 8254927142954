import {
  CustomRxPrescription,
  OrderItem,
  PostRxPrecartOrderItemPayload,
} from "../store/cart/cartInterfaces";
import {
  RxSubmitError,
  FocalTypePrescription,
  FocalTypesDetail,
  Lens,
} from "../store/rx/rxInterface";
import { CustomRx } from "../store/cart/cartInterfaces";
import { GenericCustomObject } from "./../interfaces/mainInterfaces";
import { OrderPrescription } from "../store/order-history/orderHistoryInterface";

export const getJobTypeLabel: GenericCustomObject = {
  "1": "STANDARD",
  "2": "AFA",
  "3": "SPARE_PARTS",
  "4": "RX_FRAME_TO_COME",
  "5": "RX_LENS_ONLY",
  "6": "RX_COMPLETE_JOB",
};

export type RxLayoutType = "Authentics" | "Essilor";

export const getRxLayoutType = (layout: any): RxLayoutType => {
  if (layout === "Authentics" || layout == "Essilor") return layout;
  else return "Authentics";
};

export const getPrescriptionDetails = (
  focalTypeDetails: FocalTypesDetail,
  rxCartItem: PostRxPrecartOrderItemPayload | null,
  shouldPrefill = true
): {
  prescrDetails: FocalTypePrescription[];
  addPrescrDetails: FocalTypePrescription[];
} => {
  const prescrDetails: FocalTypePrescription[] = [];
  const addPrescrDetails: FocalTypePrescription[] = [];

  focalTypeDetails.parameterValues.forEach((focalCell) => {
    const preFill: { left?: string; right?: string } = { left: "", right: "" };
    const focalCellNew = {
      ...focalCell,
      parameterName:
        focalCell.parameterName === "NEARPD"
          ? "NEAR"
          : focalCell.parameterName === "DISTANCEPD"
          ? "PD"
          : focalCell.parameterName,
    };

    if (focalTypeDetails.focalTypeName === "PLANO") {
      if (focalCellNew.parameterName === "SPHERE") {
        preFill.left = "+0.00";
        preFill.right = "+0.00";
      }

      if (focalCellNew.parameterName === "PD") {
        preFill.left = "32";
        preFill.right = "32";
      }
      if (focalCellNew.parameterName === "OCHT") {
        preFill.left = "22";
        preFill.right = "22";
      }
    } else {
      if (rxCartItem) {
        (Object.keys(rxCartItem) as Array<keyof typeof rxCartItem>).forEach((item) => {
          if (
            item.includes("xitem_RXp") &&
            item.toLowerCase().includes(focalCellNew.parameterName.toLowerCase())
          ) {
            console.log("FOCAL CELL: ", focalCell.parameterName);
            if (
              (focalCell.parameterName === "PRISM" && !item.includes("prismUd")) ||
              (focalCell.parameterName === "DIRECTION" && !item.includes("directionUd")) ||
              (focalCell.parameterName !== "PRISM" && focalCell.parameterName !== "DIRECTION")
            ) {
              if (item.includes("xitem_RXpL")) {
                preFill.left = rxCartItem[item];
                return;
              }
              preFill.right = rxCartItem[item];
            }
          }
        });
      }

      if (focalCellNew.isAdditionalParameter) {
        addPrescrDetails.push({
          ...focalCellNew,
          right: preFill.right,
          left: preFill.left,
        });
        return;
      }
      if (shouldPrefill && focalCellNew.parameterName === "SPHERE") {
        prescrDetails.push({
          ...focalCellNew,
          right: preFill.right !== "" ? preFill.right : "+0.00",
          left: preFill.left !== "" ? preFill.left : "+0.00",
        });
        return;
      }
    }
    if (focalTypeDetails.focalTypeName === "BIFOCAL") {
      if (focalCell.parameterName === "NEARPD") {
        prescrDetails.push({
          ...focalCell,
          parameterName: "NEAR",
        });
        return;
      } else if (focalCell.parameterName === "DISTANCEPD") {
        prescrDetails.push({
          ...focalCell,
          parameterName: "PD",
        });
        return;
      }
    }
    prescrDetails.push({
      ...focalCellNew,
      right: preFill.right,
      left: preFill.left,
    });
  });
  return { prescrDetails, addPrescrDetails };
};

export const filterRxPrescription = (obj: any, leftStr = "left", rightStr = "right"): CustomRx => {
  return Object.keys(obj)
    .filter((key) => key.startsWith(leftStr) || key.startsWith(rightStr))
    .reduce(
      (acc: CustomRx, curr: string) => {
        if (curr.startsWith(rightStr)) {
          acc.right = {
            ...acc.right,
            [curr.replace(rightStr, "").toUpperCase()]: obj[curr as keyof OrderItem],
          };
        } else {
          acc.left = {
            ...acc.left,
            [curr.replace(leftStr, "").toUpperCase()]: obj[curr as keyof OrderItem],
          };
        }

        return acc;
      },
      { right: {}, left: {} } as CustomRx
    );
};

///// The introduction of cases "NEARPD" and "DISTANCEPD" is introduced with CR C394813L //////
///// in order to retrieve the prescription fields of bifocal lenses, that are changed   //////
///// in the prescription process beacuse SAP accept only "NEAR" instead of "NEARPD" and //////
///// "PD" instead of "DISTANCEPD"                                                       //////

export const mergeRxPrescription = (
  filtered: CustomRx,
  toApply: FocalTypePrescription[]
): FocalTypePrescription[] => {
  return toApply.map((item) => {
    return {
      ...item,
      right:
        filtered.right[
          item.parameterName === "NEARPD"
            ? "NEAR"
            : item.parameterName === "DISTANCEPD"
            ? "PD"
            : (item.parameterName as keyof CustomRxPrescription)
        ],
      left:
        filtered.left[
          item.parameterName === "NEARPD"
            ? "NEAR"
            : item.parameterName === "DISTANCEPD"
            ? "PD"
            : (item.parameterName as keyof CustomRxPrescription)
        ],
    };
  });
};

export const checkOrderHistoryRxPrescriptionEmpty = (
  prescription: OrderPrescription,
  type: string
): boolean => {
  const le = type === "details" ? "itemLeft" : "adjItemLeft";
  const re = type === "details" ? "itemRight" : "adjItemRight";
  const isEmptyLe = !Object.values(prescription[le as keyof OrderPrescription]).some(
    (x) => x !== null && x !== ""
  );
  if (isEmptyLe) {
    const isEmptyRe = !Object.values(prescription[re as keyof OrderPrescription]).some(
      (x) => x !== null && x !== ""
    );
    return !isEmptyRe;
  }
  return !isEmptyLe;
};

export const createOrderHistoryRxPrescription = (
  rxDetails: CustomRx,
  detailsList: FocalTypePrescription[]
): FocalTypePrescription[] => {
  return detailsList.map((detail) => {
    const left = rxDetails.left[detail.parameterName as keyof CustomRxPrescription];
    const right = rxDetails.right[detail.parameterName as keyof CustomRxPrescription];
    return { ...detail, left, right };
  });
};

export const getPartnumberFromMCL = (
  model: string,
  color: string,
  size: string,
  splitter = "__",
  colorSizeSplitter = ""
): string => {
  if (color.length < 6 && color !== "" && colorSizeSplitter === "") {
    const spaces = " ".repeat(6 - color.length);
    color = color + spaces;
  }
  if (!color && !size) return `${model}`;
  if (!size) return `${model}${splitter}${color}`;
  return `${model}${splitter}${color}${colorSizeSplitter}${size}`;
};

export const getMCLFromPartnumber = (
  partnumber: string,
  splitter = "__"
): { modelId: string; colorId: string; sizeId: string } => {
  const modelId = partnumber.split(splitter)[0];
  const colorId = partnumber.split(splitter)?.[1]?.slice(0, -2)?.trim()?.replace(/\_/g, "");
  const sizeId = partnumber.split(splitter)?.[1]?.slice(-2);

  return { modelId, colorId, sizeId };
};

export const getPartnumberForWarrantyProductDetails = (code: string): string => {
  const splitter = "__";
  const strArr = code.split(splitter);
  let color = strArr[1];

  if (color.length !== 6) {
    const spaces = " ".repeat(6 - color.length);
    color = color + spaces;
  }
  return `${strArr[0]}${splitter}${color}${strArr[2]}`;
};

export const replaceUnderscoreProductCode = (code: string): string => {
  const spacesCode = code.split("__").join(" ");
  return spacesCode;
};

export const cylinderIsFilled = (
  prescriptionDetails: FocalTypePrescription[] | null
): { left: boolean; right: boolean } => {
  const CYLINDER = prescriptionDetails?.find((presc) => presc.parameterName === "CYLINDER");
  return { left: CYLINDER?.left !== "", right: CYLINDER?.right !== "" };
};

export const checkRightLeftRequiredPrescription = (
  req: { left: boolean; right: boolean },
  presc: FocalTypePrescription
): boolean => {
  // for each lens, it's correctly evaluated if either it's not required, or it is and the prescription has been added
  const isLeftEvaluated = !req.left || (req.left && !!presc.left);
  const isRightEvaluated = !req.right || (req.right && !!presc.right);

  return isLeftEvaluated && isRightEvaluated;
};

export const getErrorLabel = (error: RxSubmitError, type: string): RxSubmitError => {
  let newError;
  if (error.errorCode) {
    newError = { ...error };
    newError.errorMessage = `TO_TRANSLATE:RX_${type}_ERROR_${error.errorCode.toString()}`;
  }
  return newError ?? error;
};

export const getLensAttr = (lens: Lens | null, attributeName: string): string => {
  return (
    lens?.attributes.find((attr) => attr.filterIdentifier === attributeName)
      ?.attributeValueIdentifier ?? ""
  );
};
