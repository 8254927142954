import React from "react";
import styled from "styled-components/macro";
import LoaderElement from "../LoaderElement";

export default function LoaderInputs(): JSX.Element {
  return (
    <Wrapper>
      <Column>
        <div className="col">
          <LoaderElement height={56} />
        </div>
        <div className="col">
          <LoaderElement height={56} />
        </div>
      </Column>
    </Wrapper>
  );
}

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  .col {
    padding: 0 0.8125rem;
  }
  .col:first-child {
    padding-left: unset;
  }
  .col:last-child {
    padding-right: unset;
  }
`;

const Wrapper = styled.div`
  width: 33%;
`;
