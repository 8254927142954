import React from "react";
import styled from "styled-components/macro";

import LoaderElement from "../LoaderElement";
import { TileStyle } from "../LoaderTile";

interface Props {
  style: TileStyle;
}

const SIZE_GUIDE: {
  [key in TileStyle]: {
    optLabel: number;
    optPrice: number;
    pubLabel: number;
    pubPrice: number;
    labelHeight: number;
    priceHeight: number;
  };
} = {
  default: {
    optLabel: 30,
    optPrice: 80,
    pubLabel: 150,
    pubPrice: 120,
    labelHeight: 20,
    priceHeight: 30,
  },
  horizontal: {
    optLabel: 30,
    optPrice: 80,
    pubLabel: 110,
    pubPrice: 80,
    labelHeight: 20,
    priceHeight: 30,
  },
};

export const PricesLoader = ({ style }: Props): JSX.Element => {
  const sizes = SIZE_GUIDE[style];

  return (
    <PricesWrapper className={style}>
      <PriceElement className={style}>
        <LoaderElement height={sizes.labelHeight} width={sizes.pubLabel} />
        <LoaderElement height={sizes.priceHeight} width={sizes.pubPrice} />
      </PriceElement>
      <PriceElement className={style}>
        <LoaderElement height={sizes.labelHeight} width={sizes.optLabel} />
        <LoaderElement height={sizes.priceHeight} width={sizes.optPrice} />
      </PriceElement>
    </PricesWrapper>
  );
};

const PricesWrapper = styled.div`
  display: flex;

  &.default {
    flex-direction: row;
    column-gap: 0.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
    justify-content: space-between;
    align-items: center;
  }

  &.horizontal {
    flex-direction: column-reverse;
    row-gap: 1rem;
    max-width: 7.1875rem;
    justify-items: flex-start;
  }
`;

const PriceElement = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  &.default {
    align-items: flex-start;
  }

  &.default:last-child {
    align-items: flex-end;
  }
`;
