import React, { useEffect, useState } from "react";
import useTranslation from "../../../hooks/useTranslation";
import CustomSelect, { CustomOptions } from "../../styled-UI/CustomSelect";
import InputComponent from "../../styled-UI/InputComponent";
import { useDispatch, useSelector } from "react-redux";
import { AddressOf } from "../../../interfaces/serviceRequests";
import { AddressList } from "../../../store/profile/profileInterfaces";
import ServiceRequestPopupSubmit from "./ServiceRequestPopupSubmit";
import { emailRegExp, phoneRegExpNotRequired } from "../../../utils/utils";
import { submitAddressSR } from "../../../store/serviceRequests/serviceRequestSagas";
import { Door } from "../../../store/user/userInterfaces";
import { selectSubmitAddressSRStatus } from "../../../store/serviceRequests/serviceRequestSlice";

interface Props {
  type: "add" | "modify";
  addressOf?: AddressOf | null;
  serviceRequestIdentifier: string | null;
  addressDetails?: AddressList | null;
  selectedDoor: Door;
  setHasSubmittedRequest: (val: boolean) => void;
}

interface Address {
  streetAddressLine1?: string;
  streetAddressLine2?: string;
  city?: string;
  zipCode?: string;
  provinceState?: string;
  phone?: string;
  fax?: string;
  email?: string;
  nickname?: string;
}

interface Error {
  key: string;
  error: string;
}
const ServiceRequestPopupAddress = ({
  type,
  addressOf,
  serviceRequestIdentifier,
  addressDetails,
  selectedDoor,
  setHasSubmittedRequest,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { translateLabel } = useTranslation();
  const submitStatus = useSelector(selectSubmitAddressSRStatus);
  const [addressOfListWithLabel, setAddressOfListWithLabel] = useState<CustomOptions[]>([]);
  const [selectedAddressOfList, setSelectedAddressOfList] = useState<CustomOptions | undefined>();
  const [addressField, setAddressField] = useState<Address>({});
  const [errors, setErrors] = useState<Error[]>([]);

  const requiredAddressField = {
    streetAddressLine1: false,
    city: true,
    zipCode: true,
    provinceState: true,
    phone: false,
    fax: false,
    email: true,
    addressOf: true,
  };

  const addressOfList = [
    { value: "SHIP_TO", label: "" },
    { value: "BILL_TO", label: "" },
  ];

  useEffect(() => {
    const list: CustomOptions[] = addressOfList.map((item) => {
      return {
        ...item,
        label: translateLabel("SERVICE_REQUEST_POPUP_" + item.value),
      };
    });
    if (addressOf) {
      const item = list.find((_) => _.value === addressOf);
      if (item) setSelectedAddressOfList(item);
    }

    setAddressOfListWithLabel(list);
  }, []);

  useEffect(() => {
    if (type === "modify" && addressDetails) {
      setAddressField({
        streetAddressLine1: addressDetails.address1,
        city: addressDetails.city,
        zipCode: addressDetails.zipCode,
        provinceState: addressDetails.country,
        phone: addressDetails.phone1,
        fax: addressDetails.fax1,
        email: addressDetails.email1,
        nickname: addressDetails.nickName,
      });
    }
  }, [addressDetails]);

  const handleSetAddress = (
    name: keyof typeof addressField,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newAddressField = { ...addressField };
    newAddressField[name] = event.target.value;
    setAddressField(newAddressField);

    //clean error
    const index = errors.findIndex((_) => _.key === name);
    if (index !== -1) {
      const newErrors = [...errors];
      newErrors.splice(index, 1);
      setErrors(newErrors);
    }
  };

  const submitRequest = () => {
    const newErrors = [...errors];
    console.log("newErrors", newErrors);

    Object.keys(requiredAddressField).forEach((key) => {
      //check required fields

      if (key === "addressOf") {
        if (!selectedAddressOfList) {
          newErrors.push({
            key: "addressOf",
            error: "REQUIRED",
          });
        }
        return;
      }

      if (
        requiredAddressField[key as keyof typeof requiredAddressField] &&
        !addressField[key as keyof typeof addressField]
      ) {
        const filter = newErrors.filter((_) => _.key === key);

        if (filter.length === 0)
          newErrors.push({
            key: key,
            error: "REQUIRED",
          });
      }
    });
    (Object.keys(addressField) as Array<keyof typeof addressField>).forEach((key) => {
      //check required fields
      // if (!addressField[key] && requiredAddressField[key as keyof typeof requiredAddressField]) {
      //   newErrors.push({
      //     key: key,
      //     error: "REQUIRED",
      //   });
      // }

      //check format error
      if (key === "email" && addressField[key]) {
        const test = emailRegExp.test(addressField[key] ?? "");
        if (!test) {
          const filter = newErrors.filter((_) => _.key === key);

          if (filter.length === 0)
            newErrors.push({
              key: key,
              error: "ERROR_FORMAT",
            });
        }
      }

      if (key === "phone" && addressField[key]) {
        const test = phoneRegExpNotRequired.test(addressField[key] ?? "");
        if (!test) {
          const filter = newErrors.filter((_) => _.key === key);

          if (filter.length === 0)
            newErrors.push({
              key: key,
              error: "ERROR_FORMAT",
            });
        }
      }
    });

    setErrors(newErrors);

    if (newErrors.length === 0 && selectedAddressOfList && serviceRequestIdentifier) {
      dispatch(
        submitAddressSR({
          doorId: selectedDoor.orgentityId,
          serviceRequestIdentifier: serviceRequestIdentifier,
          serviceRequestTypeIdentifier: type === "add" ? "SR9_NEW_ADDRESS" : "SR10_MODIFY_ADDRESS",
          userAddress: { ...addressField, addressOf: selectedAddressOfList.value as AddressOf },
        })
      );
    }
  };

  return (
    <div>
      <CustomSelect
        type="white"
        options={addressOfListWithLabel}
        value={selectedAddressOfList}
        onChange={setSelectedAddressOfList}
        required={requiredAddressField.addressOf}
      />
      <div className="mt-4">
        <InputComponent
          type="text"
          as="input"
          placeholder={translateLabel("SERVICE_REQUEST_POPUP_STREET_ADDRESS")}
          onChange={(e) => handleSetAddress("streetAddressLine1", e)}
          required={requiredAddressField.streetAddressLine1}
          value={addressField.streetAddressLine1}
          colorStyle={
            errors.filter((_) => _.key === "streetAddressLine1")?.length > 0 ? "error" : "enabled"
          }
          helperText={
            errors.filter((_) => _.key === "streetAddressLine1" && _.error === "REQUIRED")?.length >
            0
              ? translateLabel("REQUIRED_FIELD")
              : ""
          }
        />
      </div>
      <div className="row mt-4">
        <div className="col">
          <InputComponent
            type="text"
            as="input"
            placeholder={translateLabel("SERVICE_REQUEST_POPUP_CITY")}
            onChange={(e) => handleSetAddress("city", e)}
            required={requiredAddressField.city}
            value={addressField.city}
            colorStyle={errors.filter((_) => _.key === "city")?.length > 0 ? "error" : "enabled"}
            helperText={
              errors.filter((_) => _.key === "city" && _.error === "REQUIRED")?.length > 0
                ? translateLabel("REQUIRED_FIELD")
                : ""
            }
          />
        </div>
        <div className="col">
          <InputComponent
            type="text"
            as="input"
            placeholder={translateLabel("SERVICE_REQUEST_POPUP_ZIP_CODE")}
            required={requiredAddressField.zipCode}
            onChange={(e) => handleSetAddress("zipCode", e)}
            value={addressField.zipCode}
            colorStyle={errors.filter((_) => _.key === "zipCode")?.length > 0 ? "error" : "enabled"}
            helperText={
              errors.filter((_) => _.key === "zipCode" && _.error === "REQUIRED")?.length > 0
                ? translateLabel("REQUIRED_FIELD")
                : ""
            }
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <InputComponent
            type="text"
            as="input"
            placeholder={translateLabel("SERVICE_REQUEST_POPUP_PROVINCE_STATE")}
            required={requiredAddressField.provinceState}
            onChange={(e) => handleSetAddress("provinceState", e)}
            value={addressField.provinceState}
            maxLength={2}
            colorStyle={
              errors.filter((_) => _.key === "provinceState")?.length > 0 ? "error" : "enabled"
            }
            helperText={
              errors.filter((_) => _.key === "provinceState" && _.error === "REQUIRED")?.length > 0
                ? translateLabel("REQUIRED_FIELD")
                : ""
            }
          />
        </div>
        <div className="col">
          <InputComponent
            type="text"
            as="input"
            placeholder={translateLabel("SERVICE_REQUEST_POPUP_FAX")}
            onChange={(e) => handleSetAddress("fax", e)}
            required={requiredAddressField.fax}
            value={addressField.fax}
            colorStyle={errors.filter((_) => _.key === "fax")?.length > 0 ? "error" : "enabled"}
            helperText={
              errors.filter((_) => _.key === "fax" && _.error === "REQUIRED")?.length > 0
                ? translateLabel("REQUIRED_FIELD")
                : ""
            }
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <InputComponent
            type="text"
            as="input"
            placeholder={translateLabel("SERVICE_REQUEST_POPUP_PHONE")}
            onChange={(e) => handleSetAddress("phone", e)}
            required={requiredAddressField.phone}
            value={addressField.phone}
            colorStyle={errors.filter((_) => _.key === "phone")?.length > 0 ? "error" : "enabled"}
            helperText={
              errors.filter((_) => _.key === "phone" && _.error === "REQUIRED")?.length > 0
                ? translateLabel("REQUIRED_FIELD")
                : errors.filter((_) => _.key === "phone" && _.error === "ERROR_FORMAT")?.length > 0
                ? translateLabel("INVALID_PHONE")
                : ""
            }
          />
        </div>
        <div className="col">
          <InputComponent
            type="text"
            as="input"
            placeholder={translateLabel("SERVICE_REQUEST_POPUP_EMAIL")}
            onChange={(e) => handleSetAddress("email", e)}
            required={requiredAddressField.email}
            value={addressField.email}
            colorStyle={errors.filter((_) => _.key === "email")?.length > 0 ? "error" : "enabled"}
            helperText={
              errors.filter((_) => _.key === "email" && _.error === "REQUIRED")?.length > 0
                ? translateLabel("REQUIRED_FIELD")
                : errors.filter((_) => _.key === "phone" && _.error === "ERROR_FORMAT")?.length > 0
                ? translateLabel("INVALID_EMAIL")
                : ""
            }
          />
        </div>
      </div>
      <ServiceRequestPopupSubmit
        submitRequest={submitRequest}
        submitStatus={submitStatus.status}
        setHasSubmittedRequest={setHasSubmittedRequest}
        errorLabel={submitStatus.errorCode}
      />
    </div>
  );
};

export default ServiceRequestPopupAddress;
