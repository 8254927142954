import { Lang } from "../interfaces/mainInterfaces";

export const getSupportedLang = (
  locale: string | undefined,
  supportedLanguages: Lang[]
): { locale: string; languageId: number } | undefined => {
  const supportedLang = supportedLanguages.filter(
    (lang) => lang.locale.replace("_", "-") === locale
  );

  if (supportedLang.length > 0) {
    return {
      locale: supportedLang[0].locale.replace("_", "-"),
      languageId: supportedLang[0].languageId,
    };
  } else return;
};

export const getDefaultLocale = (supportedLanguages: Lang[]): string => {
  const browserLang = window.navigator.language;
  const supportedBrowserLang = getSupportedLang(browserLang, supportedLanguages);

  return supportedBrowserLang ? supportedBrowserLang.locale : supportedLanguages[0].locale;
};

export const isSetLanguageSupported = (
  currentLang: string,
  supportedLanguages: Lang[]
): boolean => {
  return getSupportedLang(currentLang, supportedLanguages) != undefined;
};

export const isSetAsLanguage = (currentLang: string, locale: string): boolean => {
  return locale === currentLang;
};

export const localeToLanguageLabel = (locale: string): string => {
  return "LANGUAGE_" + locale.replace("-", "_").toUpperCase();
};
