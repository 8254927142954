import { v4 as uuid } from "uuid";
import { CustomOptions } from "../components/styled-UI/CustomSelect";
import { LXConfiguration } from "../interfaces/mainInterfaces";
import {
  AdtSearchCriteria,
  AdtSearchCriteriaClassification,
  AdtSearchCriteriaField,
  AdtSearchFilter,
  AssetCategory,
  AssetClassification,
  AssetData,
  AssetPlatformSocialMedia,
  BadgeType,
  GetOrderAssortmentPayload,
  ProductCodes,
  ProductImageManualMode,
  ProductImageMode,
  assortmentItemMoco,
  assortmentItemUpc,
  ProductImageData,
  AdtSearchCriteriaStructuredElement,
} from "../store/adt/adtInterfaces";
import { QueryParams } from "../store/search/searchInterfaces";
import { UploadedFile } from "../store/warranty-wizard/warrantyWizardInterface";
import { compareDay, diffDay, getDateFromISOString } from "./dateUtils";
import { formatBytes } from "./uploadFileUtils";

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////// COMMON /////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const mapAssetPlatformSocialMedia = (data: any): AssetPlatformSocialMedia[] => {
  return data?.map((_: any) => {
    return {
      uuid: uuid(),
      platformName: _?.platformName,
      platformUserName: _?.platformUserName,
    };
  });
};

export const mapSocialMediaOptions = (lxConfig: any, data: any) => {
  const hiddenPlatforms =
    lxConfig
      ?.find((_: LXConfiguration) => _.key === "com.luxottica.adt.social.platforms.hidden")
      ?.value.split(",") ?? [];
  const visiblePlatforms = data?.data?.values?.filter((_: any) => !hiddenPlatforms.includes(_.id));

  const socialMediaOptions: CustomOptions[] = visiblePlatforms.map((_: any) => {
    return {
      label: _.id,
      value: _.name,
    };
  });

  return socialMediaOptions;
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////// ASSETS /////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const getAssetBadgeStatus = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): BadgeType => {
  const startDateISO = getDateFromISOString(startDate) as Date; //yyyy-MM-dd HH:mm:ss.SSSZ"
  const endDateISO = getDateFromISOString(endDate) as Date; //yyyy-MM-dd HH:mm:ss.SSSZ
  const todayDate = new Date(Date.now());
  const diffDaysStartDate = Math.abs(diffDay(todayDate, startDateISO));
  const diffDaysEndDate = Math.abs(diffDay(todayDate, endDateISO));
  //todayDate > endDate
  if (compareDay(todayDate, endDateISO, "asc") === 1 && diffDaysEndDate <= 30) return "expired";
  //todayDate <= endDate
  else if (compareDay(todayDate, endDateISO, "desc") in [0, 1]) {
    if (diffDaysEndDate <= 45) return "expires-soon";
    else if (diffDaysStartDate <= 15) return "new-release";
  }
  return undefined;
};

export const getMappedAssetsList = (
  data: any,
  fileBasePath: string,
  isDefault?: "saved" | "downloaded"
): AssetData[] => {
  return data?.assets.reduce((acc: AssetData[], asset: any) => {
    const mappedData = mapAssetData(asset, fileBasePath, isDefault);
    return mappedData ? [...acc, mappedData] : acc;
  }, []);
};

const getAssetClassification = (classification: any, id: string): AssetClassification[] => {
  const relevantClassification = classification?.filter((_: any) =>
    _?.identifierPath?.includes(id)
  );

  let assetClassification: AssetClassification[] = relevantClassification;
  if (id === "LuxotticaDAM|Brand|")
    assetClassification = relevantClassification?.filter((_: any) => _.type === "Direct");

  return assetClassification?.map((_: any) => {
    return {
      identifier: _?.identifier,
      name: _?.name,
    };
  });
};

export const mapAssetData = (
  data: any,
  fileBasePath: string,
  isDefault?: "saved" | "downloaded"
): AssetData => {
  return {
    assetId: data?.id,
    isSaved: data?.isSaved ?? isDefault === "saved",
    isDownloaded: data?.isDownloaded ?? isDefault === "downloaded",
    isUnauthorized: data?.unathorized ?? false,
    adtCategoryId: data?.adtCategoryId,
    url: `${fileBasePath}${data?.masterFileLatestVersionPath}`,
    urlPreview: `${fileBasePath}${data?.masterFileLatestVersionPreviewPath}`,
    created: data?.createdOnUtc,
    modified: data?.modifiedOnUtc,
    assetName: data?.fields?.name,
    badgeType: getAssetBadgeStatus({
      startDate: data?.fields?.rightsBeginDate,
      endDate: data?.fields?.expirationDateRightsEndDate,
    }),
    crop:
      data?.adtCategoryId === "campaignMaterial"
        ? `${data?.fields?.widthPixels} x ${data?.fields?.heightPixels} px`
        : `${data?.fields?.widthOfMaster} x ${data?.fields?.heightOfMaster} px`,
    documentType: data?.fields?.documentType,
    campaign: data?.fields?.campaign,
    //copyright: data?.fields?.copyright,
    expiration: data?.fields?.expirationDateRightsEndDate,
    size: data?.file?.latestVersionFilesize && formatBytes(data?.file?.latestVersionFilesize),
    format: data?.file?.latestVersionExtension.toLowerCase(),
    imageShape: data?.fields?.imageShape,
    localization: getAssetClassification(data?.classifications, "LuxotticaDAM|Localization|"),
    brand: getAssetClassification(data?.classifications, "LuxotticaDAM|Brand|"),
    productCategory: getAssetClassification(data?.classifications, "LuxotticaDAM|ProductCategory|"),
    campaignSeasonCollection: getAssetClassification(
      data?.classifications,
      "LuxotticaDAM|Campaign-Season-Collection|"
    ),
    language: getAssetClassification(data?.classifications, "LuxotticaDAM|Language|"),
    year: getAssetClassification(data?.classifications, "LuxotticaDAM|Year|"),
    subjectType: getAssetClassification(data?.classifications, "LuxotticaDAM|SubjectType|"),
    products: data?.fields?.products,
    width:
      data?.adtCategoryId === "campaignMaterial"
        ? `${data?.fields?.widthPixels} px`
        : `${data?.fields?.widthOfMaster} px`,
    height:
      data?.adtCategoryId === "campaignMaterial"
        ? `${data?.fields?.heightPixels} px`
        : `${data?.fields?.heightOfMaster} px`,
  };
};

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// FILTERS /////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const getAdtFiltersFromUrl = (
  filtersObj: QueryParams,
  categoryId: AssetCategory,
  searchTerm?: string
): AdtSearchFilter | undefined => {
  const appliedFilters: AdtSearchCriteria[] = [];

  Object.entries(filtersObj).forEach(([key, value]) => {
    const [facetType, facetKey, param] = decodeURIComponent(key).split(":");

    appliedFilters.push(...getAdtFilters(facetType, facetKey, param, value));
  });

  const searchFilters = getSearchFromTerm(categoryId, searchTerm);

  const fullCriterias = searchFilters ? [...appliedFilters, searchFilters] : appliedFilters;

  if (fullCriterias.length > 0)
    return {
      criteria: {
        $type: "AllOperation",
        criterias: fullCriterias,
      },
    };
};

export const getAdtFilters = (
  facetType: string,
  facetKey: string,
  param: string,
  value: string[]
): AdtSearchCriteria[] => {
  const appliedFilters: AdtSearchCriteria[] = [];

  if (facetType === "OptionList") {
    const criterias: (AdtSearchCriteriaField | AdtSearchCriteriaStructuredElement)[] = value.map(
      (_): AdtSearchCriteriaField | AdtSearchCriteriaStructuredElement => {
        return {
          $type: facetKey.includes("fields") || facetKey.includes("file") ? "Property" : "Field",
          name: facetKey,
          operator: "Equal",
          value: _,
        };
      }
    );

    if (criterias.length === 1) appliedFilters.push(criterias[0]);
    else if (criterias.length > 1)
      appliedFilters.push({
        $type: "AnyOperation",
        criterias,
      });
  }

  if (facetType.includes("Classification")) {
    const criterias: AdtSearchCriteriaClassification[] = value.map(
      (_): AdtSearchCriteriaClassification => {
        return {
          $type: "RecordClassification",
          ...{ [param ?? "identifier"]: _ },
          // isDirect: true,
        };
      }
    );

    if (criterias.length === 1) appliedFilters.push(criterias[0]);
    else if (criterias.length > 1)
      appliedFilters.push({
        $type: "AnyOperation",
        criterias,
      });
  }

  return appliedFilters;
};

type SearchSchemaField = keyof typeof SEARCH_SCHEMA_FIELD;

const SEARCH_SCHEMA_FIELD: { [key: string]: AdtSearchCriteria } = {
  name: {
    $type: "Field",
    name: "name",
    operator: "Equal",
    value: "",
  },
  documentType: {
    $type: "Field",
    name: "documentType",
    operator: "Equal",
    value: "",
  },
  genericCollection: {
    $type: "RecordClassification",
    isDirect: true,
  },
  // brand: {
  //   $type: "RecordClassification",
  //   // identifier: "brand",
  //   isDirect: true,
  // },
  // productCategory: {}
  // campaignSeasonCollection: {}
  products: {
    $type: "StructuredField",
    name: "products",
    Criteria: {
      $type: "AnyOperation",
      criterias: [
        {
          $type: "Property",
          name: "model",
          operator: "Equal",
          value: "",
        },
        {
          $type: "Property",
          name: "color",
          operator: "Equal",
          value: "",
        },
      ],
    },
  },
};

// NOTE: brand, productCategory, campaignSeasonCollection are RecordClassifications, but we were told by EPAM that
// RecordClassifications can only be searched among all of them, and not, for example, simply by "brand"
// To this end we've added a "fake" filter "genericCollection" wherever a classification is required,
// so that at least the end goal of being able to search for it is met.

const SEARCH_SCHEMA: { [key in AssetCategory]: SearchSchemaField[] } = {
  campaignMaterial: [
    "name",
    "documentType",
    "genericCollection",
    // "brand",
    // "productCategory",
    // "campaignSeasonCollection"
    "products",
  ],
  newsletterAndConsumerMaterial: [
    "name",
    "documentType",
    "genericCollection",
    // "brand",
    // "productCategory",
    "products",
  ],
  socialMediaMaterial: [
    "name",
    "documentType",
    "genericCollection",
    // "brand",
    // "productCategory",
    "products",
  ],
  logos: [
    "name",
    "genericCollection",
    // "brand"
  ],
  eCommercePackage: [
    "name",
    "documentType",
    "genericCollection",
    // "brand",
    // "productCategory",
    "products",
  ],
};

const getSearchFromTerm = (
  categoryId: AssetCategory,
  searchTerm?: string
): AdtSearchCriteria | undefined => {
  if (!searchTerm) return;

  const filters = SEARCH_SCHEMA[categoryId];
  const searchValue = `*${searchTerm}*`;

  const criterias = filters.map((_) => {
    const criteria = SEARCH_SCHEMA_FIELD[_];

    if (criteria.$type === "StructuredField")
      criteria.Criteria.criterias.forEach((_) => (_.value = searchValue));
    if (criteria.$type === "Field") criteria.value = searchValue;
    if (criteria.$type === "RecordClassification") criteria.name = searchValue;

    return criteria;
  });

  return {
    $type: "AnyOperation",
    criterias,
  };
};

export const getSearchForAccount = (searchTerm?: string): AdtSearchCriteria | undefined => {
  if (!searchTerm) return;

  const searchValue = `*${searchTerm}*`;

  const criteria = { ...SEARCH_SCHEMA_FIELD["name"], value: searchValue };
  return criteria;
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////// PDP ////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const getMappedProductsImagesList = (
  data: any,
  fileBasePath: string
): ProductImageData[] => {
  return data?.assets.reduce((acc: ProductImageData[], product: any) => {
    const mappedData = mapProductImageData(product, fileBasePath);
    return mappedData ? [...acc, mappedData] : acc;
  }, []);
};

export const mapProductImageData = (data: any, fileBasePath: string): ProductImageData => {
  return {
    productId: data?.id,
    brands: data?.brands,
    imageUrl: `${fileBasePath}${data?.masterFileLatestVersionPath}`,
    previewUrl: `${fileBasePath}${data?.masterFileLatestVersionPreviewPath}`,
    fields:
      data?.fields.length > 0
        ? {
            name: data?.fields?.[0].name,
            modelCode: data?.fields?.[0].featuredStyleModel,
            colorCode: data?.fields?.[0].featuredColorColorCode,
          }
        : {},
  };
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////// PLP ////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const ADT_PLP_PAGE_SIZE = 24;
export const ADT_RECENTLY_ADDED_SIZE = 3;

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// REQUEST IMAGE ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const mapAssortmentItems = (
  productCode: string,
  codeType: ProductImageManualMode
): ProductCodes => {
  if (codeType === "MOCO") {
    const splittedMOCO = productCode.split(" ");
    if (splittedMOCO.length === 2) {
      const [modelValue, colorValue] = splittedMOCO;
      return { model: modelValue, color: colorValue };
    } else if (splittedMOCO.length === 3) {
      const [modelValue1, modelValue2, colorValue] = splittedMOCO;
      return { model: modelValue1 + " " + modelValue2, color: colorValue };
    } else return { upc: productCode };
    // const [modelValue, colorValue, ...values] = productCode.split(" ");
    // return { model: modelValue, color: colorValue };
  } else return { upc: productCode };
};

export const mapPayloadOrderAssortment = (
  productMode: ProductImageMode,
  viewsTypes: string[],
  assortmentKeyType: ProductImageManualMode,
  assortmentItems: assortmentItemMoco[] | assortmentItemUpc[],
  recipients: string[],
  file: UploadedFile
): GetOrderAssortmentPayload => {
  switch (productMode) {
    case "FILE":
      return {
        req: {
          assortmentSource: "FILE",
          recipients: recipients,
          viewTypes: viewsTypes,
        },
        file: file,
      };
    case "MANUAL":
      return {
        req: {
          assortmentSource: "MANUAL",
          recipients: recipients,
          assortmentKeyType: assortmentKeyType,
          assortmentItems: assortmentItems,
          viewTypes: viewsTypes,
        },
      };
  }
};

//////////////////////////////////////////////////////////////////////////////////
//////////////////// MY ACCOUNT ASSET PLATFORM ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const websitePlatformConfig = (data: string | null, webSiteRowLabel: string) => {
  const tableID = "WEBSITE";
  if (!data) {
    return {
      row: [],
      errorMessage: tableID,
      title: tableID,
    };
  }

  return {
    tab: {
      platformNameTab: "PLATFORM",
      platformValueTab: "URL",
    },
    row: [
      {
        id: "userWebsiteID",
        platformName: webSiteRowLabel,
        platformValue: data,
      },
    ],
    title: tableID,
  };
};

export const socialMediaPlatformConfig = (data: AssetPlatformSocialMedia[]) => {
  const tableID = "SOCIALMEDIA";
  if (!data) {
    return {
      row: [],
    };
  }

  const tab = {
    platformNameTab: "PLATFORM",
    platformValueTab: "PROFILE",
  };

  const socialmediaPlatforms = data?.map((_) => {
    return {
      id: _?.uuid,
      platformName: _?.platformName,
      platformValue: _?.platformUserName,
    };
  });

  return {
    tab: tab,
    row: socialmediaPlatforms,
    title: tableID,
    errorMessage: tableID,
  };
};
