import { cloneDeep } from "lodash";
import { FacetView, FacetViewEntry } from "../interfaces/facetInterfaces";
import { QueryParams, QueryParamsWithString } from "../store/search/searchInterfaces";
import { partitionArray } from "./utils";

//////////////////////////////////////////////////////////////////////////////////
/////////////////////// GET OR UPDATE QUERYPARAMS FROM URL ///////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Returns list of filters found in current URL as a QueryParams object
 *
 * @return {*}  {QueryParams}
 */
export const getFiltersFromUrl = (): QueryParams => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search.slice(1));

  return getFiltersFromParams(params);
};

/**
 * Maps URLSearchParams into a QueryParams object
 *
 * @param {URLSearchParams} params
 * @return {*}  {QueryParams}
 */
export const getFiltersFromParams = (params: URLSearchParams): QueryParams => {
  const filtersObj: { [key: string]: string[] } = {};

  for (const p of params) {
    if (filtersObj[p[0]]) {
      const index = filtersObj[p[0]].indexOf(p[1]); // if key already exists, check if value exists
      if (index === -1) filtersObj[p[0]].push(p[1]); // if it doesn't, append it
    } else {
      filtersObj[p[0]] = [p[1]]; // if key doesn't exist, add new key-value pair
    }
  }
  return filtersObj;
};

/**
 * Updates the url adding and removing the filters in query string
 * Optionally redirects to first page by setting parameter 'pageNumber'
 *
 * @param {string} param
 * @param {string} value
 * @param {boolean} [toFirstPage]
 * @return {*}  {string}
 */
export const updateQueryParameters = (
  param: string,
  value: string,
  toFirstPage?: boolean
): string => {
  const currentUrlParams = new URLSearchParams(window.location.search);
  if (checkFilterIsInParams(param, value)) {
    /*
      if filter is present in params and need to be deleted,
      save all the other filters of same name in a new array,
      then delete all filters and append all but the one
    */
    const valuesFromParam: string[] = [];
    currentUrlParams.getAll(param).forEach((item) => {
      item !== value && valuesFromParam.push(item);
    });
    currentUrlParams.delete(param);
    valuesFromParam.forEach((value) => {
      currentUrlParams.append(param, value);
    });
  } else {
    currentUrlParams.append(param, value);
  }

  if (toFirstPage) currentUrlParams.set("pageNumber", "1");

  return window.location.pathname + "?" + currentUrlParams.toString();
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////// GET A SINGLE PARAMETER'S VALUE /////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Returns value of provided 'param' from current URL
 * Returns 'fallbackValue' if not present
 *
 * @return {*}  {string}
 */
export const getParamFromUrl = (param: string, fallbackValue = ""): string => {
  const currentUrlParams = new URLSearchParams(window.location.search);
  const paramValue = currentUrlParams.get(param);
  return paramValue || fallbackValue;
};

/**
 * Returns value of provided 'param' from current URL
 * Returns 'fallbackValue' if not present
 *
 * @return {*}  {string}
 */
export const getAllParamFromUrl = (param: string, fallbackValue = ""): string[] => {
  const currentUrlParams = new URLSearchParams(window.location.search);
  const paramValue = currentUrlParams.getAll(param);
  return paramValue.length > 0 ? paramValue : [fallbackValue];
};

/**
 * Return value of 'pageNumber' parameter from current URL
 * Returns 1 if not present
 *
 * @return {*}  {string}
 */
export const getPageNumberFromUrl = (): number => {
  const currentUrlParams = new URLSearchParams(window.location.search);
  const paramPage = currentUrlParams.get("pageNumber");
  const page = (paramPage && paramPage !== "0" && Number(paramPage)) || 1;
  return page || 1;
};

//////////////////////////////////////////////////////////////////////////////////
////////////// UPDATE PARAMETERS WITH SINGLE INSTANCE (returns URL) //////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Updates the current value of provided URL parameter 'param' to 'newValue'
 * NOTE: unlike updateQueryParameter, it assumes only ONE instance of each param
 * should be present --
 *  - if param doesn't exists, and newValue !== null, it adds param as newValue
 *  - if param exists, and newValue !== null, it replaces param with newValue
 *  - if param exists, and newValue === null, it deletes param
 * Returns destination url
 *
 * @param {string} param
 * @param {(string | number | null)} newValue
 * @param {boolean} [toFirstPage]
 * @return {*}  {string}
 */
export const replaceParam = (
  param: string,
  newValue: string | number | null,
  toFirstPage?: boolean
): string => {
  const currentUrlParams = new URLSearchParams(window.location.search);
  if (newValue !== null) {
    if (newValue === "") currentUrlParams.delete(param);
    else {
      currentUrlParams.set(param, newValue.toString());
      return window.location.pathname + "?" + currentUrlParams.toString();
    }
  }

  currentUrlParams.delete(param);
  if (toFirstPage) currentUrlParams.set("pageNumber", "1");

  return window.location.pathname + "?" + currentUrlParams.toString();
};

/**
 * Replaces the current value of URL parameter 'pageNumber' with 'newPage'
 * Adds it if not present
 * Returns destination URL
 *
 * @param {(string | number)} newPage
 * @return {*}  {string}
 */
export const updatePageNumberParam = (newPage: string | number): string => {
  const currentUrlParams = new URLSearchParams(window.location.search);
  currentUrlParams.set("pageNumber", newPage.toString());
  return window.location.pathname + "?" + currentUrlParams.toString();
};

/**
 * Replaces the current value of URL parameter 'orderBy' with 'newValue'
 * Adds it if not present
 * Optionally redirects to first page by setting parameter 'pageNumber'
 * Returns destination URL
 *
 * @param {string} newValue
 * @param {boolean} [toFirstPage]
 * @return {*}  {string}
 */
export const updateOrderByParam = (newValue: string, toFirstPage?: boolean): string => {
  const currentUrlParams = new URLSearchParams(window.location.search);

  if (newValue) {
    currentUrlParams.set("orderBy", newValue);
  } else {
    currentUrlParams.delete("orderBy");
  }
  if (toFirstPage) currentUrlParams.set("pageNumber", "1");

  return window.location.pathname + "?" + currentUrlParams.toString();
};

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////// RETURNS URLSearchParams /////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Format value for filters of type facet
 *
 * @param {string} key
 * @param {string} value
 * @return {*}
 */
const formatFacetFilter = (key: string, value: string) => {
  const formattedFilter = value.replace("---", "...");
  return `${key}:"${formattedFilter}"`;
};
/**
 * Create URLSearchParams object by appending all the params
 *
 * @param {({ [key: string]: string[] | string })} [params]
 * @param {{ [key: string]: string[] }} [filters]
 * @return {*}  {URLSearchParams}
 */
export const appendFiltersToURLSearchParams = (
  params?: QueryParamsWithString,
  filters?: QueryParams
): URLSearchParams => {
  const paramsUrl = new URLSearchParams();

  if (params)
    for (const [key, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        value?.forEach((_) => paramsUrl.append(key, _));
      } else {
        paramsUrl.append(key, value);
      }
    }

  if (filters)
    for (const [key, value] of Object.entries(filters)) {
      if (!isNotFilter(key)) {
        value.forEach((_) => {
          const value = formatFacetFilter(key, _);
          paramsUrl.append("facet", value);
        }); // one facet for each value
        // paramsUrl.append("facet", `${key}:${value.join(" ")}`); // one facet for each key, by joining values
      } else {
        value?.forEach((_) => paramsUrl.append(key, _)); // one facet for each value
        // paramsUrl.append(key, value.join(" ")); // one facet for each key, by joining values
      }
    }
  return paramsUrl;
};

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// CHECKS //////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Checks if a filter (checkbox in the filters bar in plp) is present in the url as query parameter
 *
 * @param {string} param
 * @param {string} filter
 * @return {*}  {boolean}
 */
export const checkFilterIsInParams = (param: string, value: string): boolean => {
  let isFound = false;
  if (param && value) {
    const params = new URLSearchParams(window.location.search);

    params.forEach((valueUrl, key) => {
      if (key === param && valueUrl === value) isFound = true;
    });
  }
  return isFound;
};

/**
 * Check if there are any applied filters in URL
 *
 * @return {*}  {boolean}
 */
export const checkAppliedFiltersExist = (): boolean => {
  let isFound = false;
  const params = new URLSearchParams(window.location.search);

  params.forEach((valueUrl, key) => {
    if (!isNotFilter(key)) {
      isFound = true;
    }
  });

  return isFound;
};

/**
 * Check if a filter is a brand filter, managed separately from other filters
 *
 * @param {string} filterId
 * @return {*}  {boolean}
 */
export const isBrandFilter = (filterId: string): boolean => {
  return filterId === "manufacturer.raw";
};

/**
 * Check if a filter is a product category filter, managed separately from other filters
 *
 * @param {string} filterId
 * @return {*}  {boolean}
 */
export const isChipFilter = (filterId: string): boolean => {
  return filterId === "PRODUCT_CATEGORY_CHIPS";
};

/**
 * Check if a filter is one that belongs to the fake Tags category, managed separately from other filters
 *
 * @param {string} filterId
 * @return {*}  {boolean}
 */
export const isTagFilter = (filterId: string): boolean => {
  return (
    filterId === "ADVERTISING" ||
    filterId === "BESTSELLER" ||
    filterId === "NEW" ||
    filterId == "RECOMMENDED"
  );
};

/**
 * Check if a filter is macrofamily one
 *
 * @param {string} filterId
 * @return {*}  {boolean}
 */
export const isMacroFamilyFilter = (filterId: string): boolean => {
  return filterId === "MACROFAMILY";
};

/**
 * Check if a filter is stars assorment
 * @param {string} filterId
 * @return {*}  {boolean}
 */
export const isStarsAssortmentFilter = (filterId: string): boolean => {
  return filterId === "STARS_ASSORTMENT";
};

/**
 * Check if a filter is stars assorment
 * @param {string} filterId
 * @return {*}  {boolean}
 */
export const isStarsNewFilter = (filterId: string): boolean => {
  return filterId === "STARS_NEW";
};

/**
 * Check if a filter is ad advanced one
 *
 * @param {FacetView} filter
 * @param {boolean} isSearch
 * @return {*}  {boolean}
 */
export const isAdvancedFilter = (filter: FacetView, isSearch: boolean): boolean => {
  // PRODUCT_CATEGORY_FILTER in search page will be displayed as advanced
  if (isSearch && filter.extendedData?.propertyvalue === "PRODUCT_CATEGORY_FILTER") return true;

  // filters with no facetPosition will be displayed as NOT advanced
  if (!filter.extendedData?.facetPosition) return false;

  // for all others, check the filter's facetPosition
  return filter.extendedData?.facetPosition === "1";
};

/**
 * Check if filter or params is a facet/filter or not
 *
 * @param {string} param
 * @return {*}  {boolean}
 */
export const isNotFilter = (param: string): boolean => {
  return (
    param === "pageNumber" ||
    param === "bestseller" ||
    param === "collection" ||
    param === "orderBy" ||
    param === "doorId" ||
    param === "searchTerm"
  );
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////// MISC ///////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Remove unwanted filters. Facets currently affected:
 *
 * value	"attributes.7741124012283370850.value.raw"
 * name	"BRAND"
 * extendedData.propertyvalue	"BRAND"
 *
 * value	"brandGroup"
 * name	"BrandGroup"
 * extendedData.propertyvalue	"brandGroup"
 *
 * @param {FacetView[]} facets
 * @return {*}  {FacetView[]}
 */
export const removeUnwantedFilters = (facets: FacetView[]): FacetView[] => {
  const isWanted = (facet: FacetView): boolean => {
    const propertyValue = facet.extendedData.propertyvalue;
    return (
      propertyValue !== "BRAND" &&
      propertyValue !== "brandGroup" &&
      propertyValue !== "INITIATIVE" &&
      !isChipFilter(propertyValue)
    );
  };

  return facets.filter((facet: FacetView) => isWanted(facet));
};

/**
 * Split filters array into two arrays: the ones displayed on the left side, and the chips
 *
 * @param {FacetView[]} facets
 * @return {*}  {FacetView[][]}
 */
export const splitChipFilters = (facets: FacetView[] | null): (FacetView[] | null)[] => {
  if (!facets) return [null, null];
  return partitionArray(
    facets,
    (filter: FacetView) => !isChipFilter(filter.extendedData?.propertyvalue)
  );
};

/**
 * Split filters array into two arrays: the normal and advanced ones
 *
 * @param {FacetView[]} facets
 * @param {boolean} isSearch
 * @return {*}  {FacetView[][]}
 */
export const splitAdvancedFilters = (
  facets: FacetView[],
  isSearch: boolean,
  isStars: boolean
): FacetView[][] => {
  let facetsArray = facets;
  let starsAssormentArray: FacetView[] = [];

  if (isStars) {
    const [starsAssorment, defaultFilters] = partitionArray(facets, (filter: FacetView) =>
      isStarsAssortmentFilter(filter.extendedData?.propertyvalue)
    );
    facetsArray = defaultFilters;
    starsAssormentArray = starsAssorment;
  }

  const [newDefaultFilters, advancedFilters] = partitionArray(
    facetsArray,
    (filter: FacetView) => !isAdvancedFilter(filter, isSearch)
  );

  //put stars assorment in first position if present
  if (starsAssormentArray && starsAssormentArray.length > 0)
    newDefaultFilters.unshift(starsAssormentArray[0]);
  return [newDefaultFilters, advancedFilters];
};

/**
 * Split filters array into two arrays: the standard ones, and the tags
 *
 * @param {FacetView[]} facets
 * @return {*}  {FacetView[][]}
 */
export const splitTagFilters = (facets: FacetView[]): FacetView[][] => {
  return partitionArray(
    facets,
    (filter: FacetView) => !isTagFilter(filter.extendedData?.propertyvalue)
  );
};

/**
 * Split filters array into two arrays: the standard ones, and the tags
 *
 * @param {FacetView[]} facets
 * @return {*}  {FacetView[][]}
 */
export const splitMacroFamilyAndStarsNewFilters = (facets: FacetView[]): FacetView[][] => {
  const [macroFamilies, defaultFilters] = partitionArray(facets, (filter: FacetView) =>
    isMacroFamilyFilter(filter.extendedData?.propertyvalue)
  );

  const [starsNew, newDefault] = partitionArray(defaultFilters, (filter: FacetView) =>
    isStarsNewFilter(filter.extendedData?.propertyvalue)
  );

  return [newDefault, macroFamilies, starsNew];
};

/**
 * Create a fake tag filters group with the provided FacetView(s)
 *
 * @param {FacetView[]} facets
 * @return {*}  {FacetView}
 */
export const createTagFiltersGroup = (facets: FacetView[]): FacetView => {
  const entries: FacetViewEntry[] = facets.map((facet: FacetView) => {
    return {
      label: facet.name, // string;
      count: facet.entry?.[0]?.count,
      identifier: facet.extendedData.propertyvalue, // string;
      identifierForFakeTagFilters: facet.entry?.[0].identifier, // string;
    };
  });

  const tagFilters: FacetView = {
    name: "TAGS_FILTERS",
    entry: entries, // FacetViewEntry[];
    extendedData: {
      propertyvalue: "TAGS",
      facetPosition: "0",
      name: "TAGS_FILTERS",
    }, // FacetViewExtendedData;
  };

  return tagFilters;
};

/**
 * Split facets by isolating tag filters into a fake standalone group
 *
 * @param {FacetView[]} facets
 * @return {*}  {FacetView[][]}
 */
export const groupFiltersTags = (facets: FacetView[]): FacetView[][] => {
  const [baseFilters, extraFilters] = splitTagFilters(facets);
  const tagFilters = createTagFiltersGroup(extraFilters);
  return [baseFilters, [tagFilters]];
};

export const addOrRemoveFilters = (
  selectedFilters: QueryParams,
  filterKey: string,
  filterValue: string
): QueryParams => {
  let index = -1;
  const newSelectedFilters: QueryParams = cloneDeep(selectedFilters);
  Object.keys(newSelectedFilters).forEach((key: string) => {
    if (key === filterKey) {
      index = newSelectedFilters[key].indexOf(filterValue);
    }
  });

  if (index !== -1) {
    if (newSelectedFilters[filterKey].length > 1) {
      newSelectedFilters[filterKey].splice(index, 1);
    } else {
      delete newSelectedFilters[filterKey];
    }
  } else {
    if (newSelectedFilters[filterKey]) {
      newSelectedFilters[filterKey].push(filterValue);
    } else {
      newSelectedFilters[filterKey] = [filterValue];
    }
  }

  return newSelectedFilters;
};

export const addOrRemoveWarrantyFilters = (
  selectedFilters: QueryParams,
  filterKey: string,
  filterValue: string
): QueryParams => {
  let index = -1;
  const newSelectedFilters: QueryParams = cloneDeep(selectedFilters);
  Object.keys(newSelectedFilters).forEach((key: string) => {
    if (key === filterKey) {
      index = newSelectedFilters[key].indexOf(filterValue);
    }
  });

  if (index !== -1) {
    if (newSelectedFilters[filterKey].length > 1) {
      newSelectedFilters[filterKey].splice(index, 1);
    } else {
      delete newSelectedFilters[filterKey];
    }
  } else {
    if (newSelectedFilters[filterKey]) {
      newSelectedFilters[filterKey].shift();
      newSelectedFilters[filterKey].push(filterValue);
    } else {
      newSelectedFilters[filterKey] = [filterValue];
    }
  }

  return newSelectedFilters;
};

/**
 * Get values of applied filters from URL
 *
 * @param {QueryParams} params
 * @return {*}  {QueryParams[]}
 */
export const getAppliedFiltersValues = (params: QueryParams): QueryParams[] => {
  const defaultFilters: QueryParams = {};
  const brandFilters: QueryParams = {};

  Object.keys(params).forEach((key) => {
    // as long as it's a recognized filter and a filter that can be showed
    if (
      !isNotFilter(key) &&
      !isChipFilter(key) &&
      !isMacroFamilyFilter(key) &&
      !isStarsNewFilter(key)
    ) {
      // add brands to specific list
      if (isBrandFilter(key)) brandFilters[key] = params[key];
      else defaultFilters[key] = params[key]; // add all others to the default filters
    }
  });

  return [defaultFilters, brandFilters];
};

/**
 * Get labels for applied filters' chips, ie. find label value for each key based on the facets passed as param.
 *
 * @param {FacetView[]} facets
 * @param {string} key
 * @param {string} value
 * @return {*}  {string}
 */
export const getChipLabel = (facets: FacetView[], key: string, value: string): string => {
  const currentFacet = facets.filter((_) => _.extendedData?.propertyvalue === key)?.[0]; // look for facet with current key
  const facetName = currentFacet?.extendedData?.name ?? key; // get its label (fallback: key in URL)
  const slider = currentFacet?.extendedData?.slider; // get slider property to display the entire chip label
  const entryName = currentFacet?.entry?.find((e) => e.identifier === value)?.label ?? value; // get the label of the current value (fallback: value in URL)

  if (isTagFilter(key)) return facetName; // tag filters display only the facet label
  if (value.toLowerCase() === "true" || value.toLowerCase() === "false" || slider === "true")
    return facetName + ": " + value?.toLowerCase()?.replace("---", "-"); // filters with true/false value or slider
  return entryName; // in all other cases display the value label
};

export const getFiltersForRestOfCatalogue = (
  params?: QueryParams,
  pageNumber?: number
): QueryParams => {
  const newParams: QueryParams = {};

  if (params?.["PRODUCT_CATEGORY_FILTER"])
    newParams["PRODUCT_CATEGORY_FILTER"] = params["PRODUCT_CATEGORY_FILTER"];

  if (params?.["manufacturer.raw"]) newParams["manufacturer.raw"] = params["manufacturer.raw"];

  if (pageNumber) newParams["pageNumber"] = [String(pageNumber)];

  return newParams;
};

/**
 * if the catalog request has failed,
 * only show filter column if one of the following is true:
 *    - there are filters returned by the API
 *    - there is at least one applied filter in the URL
 *    - the searchbox is present
 *
 * @param {(FacetView[] | null)} facetView
 * @param {boolean} hasSearchBox
 * @return {*}  {boolean}
 */
export const showFiltersColumnWhenError = (
  facetView: FacetView[] | null | undefined,
  hasSearchBox?: boolean
): boolean => {
  return (facetView && facetView.length > 0) || checkAppliedFiltersExist() || !!hasSearchBox;
};
