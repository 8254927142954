import React from "react";
import styled from "styled-components/macro";
import CustomText from "../styled-UI/CustomText";
import { LpText as Interface } from "../../interfaces/cmsInterfaces";
import useDataHref from "../../hooks/useDataHref";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  data: Interface;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LpText = ({ data, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  const { description, description1Colour, description2, description2Colour } = data;

  const descrWithHref = useDataHref({ content: description });
  const descrWithHref2 = useDataHref({ content: description2 });

  return (
    <Container>
      <OuterParagraph>
        <BoxParagraph>
          {description && (
            <CustomText
              font={customFont ? "custom-light" : "font-light"}
              fontFamily={customFont}
              color={isDarkMode ? "white" : "primary"}
              as="div"
              fontSizePx={18}
              lineHeightPx={24}
              customColor={description1Colour}
            >
              <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? description} />
            </CustomText>
          )}
        </BoxParagraph>
        <BoxParagraph>
          {description2 && (
            <CustomText
              font={customFont ? "custom-light" : "font-light"}
              fontFamily={customFont}
              color={isDarkMode ? "white" : "primary"}
              as="div"
              fontSizePx={18}
              lineHeightPx={24}
              customColor={description2Colour}
            >
              <SetInnerHTMLWithSanitize text={descrWithHref2?.updatedContent ?? description2} />
            </CustomText>
          )}
        </BoxParagraph>
      </OuterParagraph>
    </Container>
  );
};

export default LpText;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const OuterParagraph = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2.5rem;
  padding: 5rem 0;
  max-width: 80%;
`;

const BoxParagraph = styled.div``;
