import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components/macro";
import HighlightsSlide from "./HighlightsSlide";
import CarouselNavBar from "../../styled-UI/CarouselNavBar";
import { HighlightSlide } from "../../../interfaces/cmsInterfaces";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  slides: HighlightSlide[];
  customFont?: FontFamilies | null;
}

export const HIGHLIGHTS_MAX_NUMBER_SLIDES = {
  md: 2,
  bg: 3,
};

export const getHighlightsSlidesToShow = (breakpoint: "md" | "bg", slidesNumber: number): number =>
  slidesNumber > HIGHLIGHTS_MAX_NUMBER_SLIDES[breakpoint]
    ? HIGHLIGHTS_MAX_NUMBER_SLIDES[breakpoint]
    : slidesNumber;

const HighlightsCarousel = ({ slides, customFont = null }: Props): JSX.Element => {
  const startingSlide = 0;
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(startingSlide);

  const defaultSettings = {
    className: "center",
    dots: false,
    arrows: false,
    draggable: false, //to set it to true --> prevent click on drag https://github.com/akiran/react-slick/issues/848
    speed: 500,
    centerMode: true,
    infinite: true,
    onSwipe: () => {
      console.log("swipe");
    },
    slidesToShow: getHighlightsSlidesToShow("bg", slides.length),
    slidesToScroll: 1,
    initialSlide: startingSlide,
    beforeChange: (_: any, next: any) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: getHighlightsSlidesToShow("md", slides.length),
        },
      },
    ],
  };

  const changeSlide = (val: number) => {
    sliderRef.slickGoTo(val);
  };

  return (
    <>
      {slides.length > 1 && (
        <NavBarWrapper>
          <CarouselNavBar
            currentSlide={currentSlide}
            slidesToScroll={1}
            changeSlide={changeSlide}
            currentPage={currentSlide}
            lastPage={slides.length}
            navigationType="highlights"
            data-element-id="Highlights_Navigation"
            data-description="arrows"
          />
        </NavBarWrapper>
      )}
      <SliderWrapper>
        <Slider ref={(val) => setSliderRef(val)} {...defaultSettings}>
          {slides.map((slide, i) => {
            const tileNumber = i + 1;
            const tileId = slide.id;
            const titleName = slide.name;
            return (
              <HighlightsSlideWrapper
                className="align-center"
                key={slide.id}
                data-element-id={`Highlights_Tiles_Tile${tileNumber}`}
              >
                <HighlightsSlide
                  slide={slide}
                  tileNumber={tileNumber}
                  tileId={tileId}
                  titleName={titleName}
                  customFont={customFont}
                />
              </HighlightsSlideWrapper>
            );
          })}
        </Slider>
      </SliderWrapper>
    </>
  );
};

const NavBarWrapper = styled.div`
  position: relative;
  margin: auto;
  /* width: 385px; */
  width: 90%;
  max-width: 1440px;
`;

const HighlightsSlideWrapper = styled.div`
  height: 33.5rem;
  background-color: transparent;
  display: flex !important;
  justify-content: center;
  transform: scale(0.75);
`;

const SliderWrapper = styled.div`
  margin: auto;
  max-width: 1400px;
`;

export default HighlightsCarousel;
