import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import { Popup } from "../../interfaces/popupInterfaces";
import { saveUnavailableInfoInStagingOrder } from "../../utils/smartShopperUtils";
import { RootState } from "../storeConfig";
import {
  CustomDetails,
  Details,
  DetailsStatus,
  DetailsStatusPayload,
  Devices,
  OrderItem,
  SelectedItems,
  SmartShopperState,
  StagingOrder,
  StagingOrderNoteStatus,
  StagingOrdersFilters,
} from "./smartshopperInterfaces";

export const sliceName = "smartshopper";

const initialState: SmartShopperState = {
  error: null,
  stagingOrders: null,
  stagingOrdersLoading: "IDLE",
  selectedItems: [],
  stagingOrderCount: null,
  reloadStagingOrderCount: false,
  stagingOrderNoteStatus: null,
  stagingOrderFilters: null,
  stagingOrderNoteLoading: "IDLE",
  stagingOrderDeleteStatus: false,
  orderItem: null,
  selectedItemsIdInCart: null,
  confirmationStatus: false,
  loadingStatus: "IDLE",
  popupNotFoundItems: {
    open: false,
  },

  ///// DEVICE
  devices: null,
  devicesLoading: "IDLE",

  ///// EDIT PIN
  editPinLoading: "IDLE",
  editPinStatus: false,

  ///// CUSTOM DETAILS
  details: {},
  detailsStatus: {},
};

export const storeSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveStagingOrders: (state, action: PayloadAction<StagingOrder | null>) => {
      state.stagingOrders = action.payload;
    },
    setSelectedItems: (state, action: PayloadAction<SelectedItems[]>) => {
      state.selectedItems = action.payload;
    },
    setStagingOrderCount: (state, action: PayloadAction<number>) => {
      state.stagingOrderCount = action.payload;
    },
    setStagingOrderNoteStatus: (state, action: PayloadAction<DetailsStatusPayload>) => {
      state.stagingOrderNoteStatus = action.payload;
    },
    setStagingOrderFilters: (state, action: PayloadAction<StagingOrdersFilters | null>) => {
      state.stagingOrderFilters = action.payload;
    },
    setStagingOrderNoteLoading: (state, action: PayloadAction<RequestStatus>) => {
      state.stagingOrderNoteLoading = action.payload;
    },
    setStagingOrdersLoading: (state, action: PayloadAction<RequestStatus>) => {
      state.stagingOrdersLoading = action.payload;
    },
    setStagingOrderDeleteStatus: (state, action: PayloadAction<boolean>) => {
      state.stagingOrderDeleteStatus = action.payload;
    },
    setOrderItemId: (state, action: PayloadAction<OrderItem[] | null>) => {
      state.orderItem = action.payload;
    },
    setSelectedItemIdInCart: (state, action: PayloadAction<string[] | null>) => {
      state.selectedItemsIdInCart = action.payload;
    },
    setConfirmationStatus: (state, action: PayloadAction<boolean>) => {
      state.confirmationStatus = action.payload;
    },
    setLoadingStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.loadingStatus = action.payload;
    },
    saveReloadStagingOrderCount: (state, action: PayloadAction<boolean>) => {
      state.reloadStagingOrderCount = action.payload;
    },
    savePopupNotFoundItems: (state, action: PayloadAction<Popup>) => {
      state.popupNotFoundItems = action.payload;
    },
    saveUnavaiableItemsInfo: (state, action: PayloadAction<string[]>) => {
      if (state.stagingOrders) {
        const itemIds = action.payload;
        const newStagingOrder = saveUnavailableInfoInStagingOrder(state.stagingOrders, itemIds);
        state.stagingOrders = newStagingOrder;
      }

      state.popupNotFoundItems = {
        open: true,
      };
    },

    ////// DEVICES
    saveDevices: (state, action: PayloadAction<Devices | null>) => {
      state.devices = action.payload;
    },
    setDevicesLoading: (state, action: PayloadAction<RequestStatus>) => {
      state.devicesLoading = action.payload;
    },

    ////// EDIT PIN
    setEditPinLoading: (state, action: PayloadAction<RequestStatus>) => {
      state.editPinLoading = action.payload;
    },
    setEditPinStatus: (state, action: PayloadAction<boolean>) => {
      state.editPinStatus = action.payload;
    },
    ////// DETAILS
    saveDetails: (state, action: PayloadAction<CustomDetails>) => {
      const itemId = action.payload.itemId;
      state.details[itemId] = action.payload?.details;
    },

    setDetailsStatus: (state, action: PayloadAction<DetailsStatusPayload>) => {
      const itemId = action.payload.itemId;
      state.detailsStatus[itemId] = action.payload.status;
    },

    resetState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  saveStagingOrders,
  setSelectedItems,
  setStagingOrderCount,
  setStagingOrderNoteStatus,
  setStagingOrderFilters,
  setStagingOrderNoteLoading,
  setStagingOrdersLoading,
  setStagingOrderDeleteStatus,
  setOrderItemId,
  setSelectedItemIdInCart,
  setConfirmationStatus,
  setLoadingStatus,
  saveDevices,
  setDevicesLoading,
  setEditPinLoading,
  setEditPinStatus,
  saveDetails,
  setDetailsStatus,
  saveReloadStagingOrderCount,
  savePopupNotFoundItems,
  saveUnavaiableItemsInfo,
  resetState,
} = storeSlice.actions;

export const selectStagingOrders = (state: RootState): StagingOrder | null => {
  return state.smartshopper.stagingOrders;
};

export const selectSelectedItems = (state: RootState): SelectedItems[] => {
  return state.smartshopper.selectedItems;
};

export const selectStagingOrderCount = (state: RootState): number | null => {
  return state.smartshopper.stagingOrderCount;
};

export const selectStagingOrderNoteStatus = (state: RootState): StagingOrderNoteStatus | null => {
  return state.smartshopper.stagingOrderNoteStatus;
};

export const selectStagingOrderFilters = (state: RootState): StagingOrdersFilters | null => {
  return state.smartshopper.stagingOrderFilters;
};

export const selectStagingOrderNoteLoading = (state: RootState): RequestStatus => {
  return state.smartshopper.stagingOrderNoteLoading;
};

export const selectStagingOrdersLoading = (state: RootState): RequestStatus => {
  return state.smartshopper.stagingOrdersLoading;
};

export const selectStagingOrdersDeleteStatus = (state: RootState): boolean => {
  return state.smartshopper.stagingOrderDeleteStatus;
};

export const selectOrderItem = (state: RootState): OrderItem[] | null => {
  return state.smartshopper.orderItem;
};

export const selectSelectedItemsInCart = (state: RootState): string[] | null => {
  return state.smartshopper.selectedItemsIdInCart;
};

export const selectConfirmationStatus = (state: RootState): boolean => {
  return state.smartshopper.confirmationStatus;
};

export const selectLoadingStatus = (state: RootState): RequestStatus => {
  return state.smartshopper.loadingStatus;
};

export const selectDetails = (state: RootState): Details => {
  return state.smartshopper.details;
};

export const selectDetailsStatus = (state: RootState): DetailsStatus => {
  return state.smartshopper.detailsStatus;
};

export const selectReloadStagingOrderCount = (state: RootState): boolean => {
  return state.smartshopper.reloadStagingOrderCount;
};

export const selectPopupNotFoundItems = (state: RootState): Popup => {
  return state.smartshopper.popupNotFoundItems;
};

////// DEVICES
export const selectDevices = (state: RootState): Devices | null => {
  return state.smartshopper.devices;
};
export const selectDevicesLoading = (state: RootState): RequestStatus => {
  return state.smartshopper.devicesLoading;
};

////// EDIT PIN
export const selectEditPinLoading = (state: RootState): RequestStatus => {
  return state.smartshopper.editPinLoading;
};
export const selectEditPinStatus = (state: RootState): boolean => {
  return state.smartshopper.editPinStatus;
};

export default storeSlice.reducer;
