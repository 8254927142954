import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { postPersonification } from "../../../store/user/userSagas";
import {
  selectPostPersonificationStatus,
  setPostPersonificationStatus,
  selectOrganizationDetails,
  setPersonificationCustomers,
  savePersonificationDummy,
  setPersonificationCustomersStatus,
} from "../../../store/user/userSlice";
import PersonificationUser from "./personification-user/PersonificationUser";
import CustomText from "../../styled-UI/CustomText";
import Modal from "../../styled-UI/Modal";
import Button from "../../styled-UI/Button";
import useTranslation from "../../../hooks/useTranslation";
import PersonificationPopupDummy from "./PersonificationPopupDummy";
import PersonificationPopupSearch from "./PersonificationPopupSearch";
import { getPagePath } from "../../../routing/routesUtils";
import { PersonificationStoresOption } from "../../../store/user/userInterfaces";
import { LocalStorageUtils } from "../../../utils/storageUtils";

interface Props {
  isOpen: boolean;
  close: () => void;
}

const PersonificationPopup = ({ isOpen, close }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { translateLabel } = useTranslation();

  const [selectedCountry, setSelectedCountry] = useState<PersonificationStoresOption | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const orgentityDetails = useSelector(selectOrganizationDetails);
  const postPersonificationStatus = useSelector(selectPostPersonificationStatus);
  const [isChangedCustomer, setIsChangedCustomer] = useState<boolean>(false);
  const [submitPopup, setSubmitPopup] = useState<boolean>(false);

  //close popup at end of submit
  useEffect(() => {
    if (postPersonificationStatus === "SUCCESS") {
      closeModal();
      dispatch(setPostPersonificationStatus("IDLE"));
    }
  }, [postPersonificationStatus]);

  //select customer
  const selectCustomer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const orgentityId = e.target.value;
    setSelectedCustomer(orgentityId);
  };

  //submit customer
  const submitPersonificationCustomer = (selectedCustomer: string | null) => {
    if (selectedCustomer && selectedCountry) {
      dispatch(
        postPersonification({
          customerSelected: selectedCustomer,
          storeSelected: selectedCountry,
        })
      );
      setIsChangedCustomer(true);
    }
  };

  const closeModal = () => {
    if (postPersonificationStatus !== "LOADING") {
      close();
      if (isChangedCustomer && selectedCountry?.storeIdentifier) {
        // save in local storage new store identifier
        const localStorageUtils = new LocalStorageUtils();
        const currentLocalStorage = localStorageUtils.getState();
        currentLocalStorage.user = {
          ...currentLocalStorage.user,
          storeIdentifier: selectedCountry.storeIdentifier,
        };
        localStorageUtils.saveState(currentLocalStorage);

        // redirect to homepage
        window.location.replace(
          getPagePath(
            "/homepage",
            selectedCountry.storeIdentifier,
            selectedCountry.locale?.replace("_", "-")
          )
        );
      }

      setSelectedCountry(null);
      dispatch(setPersonificationCustomers(null));
      dispatch(setPersonificationCustomersStatus("IDLE"));
      dispatch(savePersonificationDummy(null));
    }
  };

  // useEffect used to manage the submit of the popup when is pressed Enter in the checkboxes list (in the component PersonificationPopupSearch)
  useEffect(() => {
    if (submitPopup) submitPersonificationCustomer(selectedCustomer);
  }, [submitPopup]);

  return (
    <Modal
      isOpen={isOpen}
      close={closeModal}
      title={translateLabel("PERSONIFICATION_POPUP_CHANGE_SHOP")}
      isCentered={false}
      top={78}
    >
      <CustomText as="span" fontSizePx={18} font="font-bold" marginBottomPx={14}>
        {translateLabel("PERSONIFICATION_POPUP_BEHALF")}
      </CustomText>
      {orgentityDetails && (
        <UserContainer>
          <PersonificationUser hasOrderInApproval user={orgentityDetails} />
        </UserContainer>
      )}
      <PersonificationPopupDummy
        selectedCountry={selectedCountry}
        selectCountry={setSelectedCountry}
        submitPersonificationCustomer={submitPersonificationCustomer}
      />
      <PersonificationPopupSearch
        selectedCountry={selectedCountry}
        selectCustomer={selectCustomer}
        selectedCustomer={selectedCustomer}
        setSubmitPopup={setSubmitPopup}
      />
      <Footer>
        <CustomText
          as="span"
          font="font-regular"
          fontSizePx={14}
          color="gray-dark"
          lineHeightPx={24}
          marginTopPx={32}
          marginBottomPx={32}
        >
          {translateLabel("PERSONIFICATION_POPUP_CART")}
        </CustomText>
        <CtaContainer>
          <Button type="secondary" onClick={closeModal}>
            {translateLabel("PERSONIFICATION_POPUP_CLOSE")}
          </Button>
          <Button
            type="primary"
            onClick={() => submitPersonificationCustomer(selectedCustomer)}
            disabled={!selectedCustomer}
            isLoading={postPersonificationStatus === "LOADING"}
          >
            {translateLabel("PERSONIFICATION_POPUP_OK")}
          </Button>
        </CtaContainer>
      </Footer>
    </Modal>
  );
};

const UserContainer = styled.div`
  border: 1px solid ${(props) => props.theme.palette.gray.medium};
  border-radius: 0.25rem;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button:first-child {
    margin-right: 1.5rem;
  }
`;

export default PersonificationPopup;
