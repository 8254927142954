import { BFFApiDomain } from "../serviceUtils";

export const getOrderHistoryUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/order/history";

export const getOrderDetailsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/order/{orderId}/details";

export const getOrderDetailsWithDeliveryUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/order/{orderId}/splitDetails";

export const getDeliveryNoteUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/doors/{doorId}/deliveryDocs";
