import { MultipleRequestStatus, RequestStatus } from "../../interfaces/mainInterfaces";

export type SubuserType = "MyELSubusers" | "LeoSubusers";

export interface SubuserState {
  subuserListMyEL: SubuserList<SubuserType> | null;
  subuserListLeo: SubuserList<SubuserType> | null;
  subuserItem: SubuserItem | null;
  subusersPerPage: number;
  subuserListLoading: MultipleRequestStatus<SubuserLoading>;
  isSubuserUpdateSuccess: boolean | null;
  subuserUpdateError: string | null;
  isEmailSuccessful: boolean | null;
  privilegeGroups: PrivilegeGroup[] | null;
  privilegesTemplatesList: PrivilegesTemplate[] | null;

  getUserPrivilegesStatus: RequestStatus;
}

export type SubuserLoading = SubuserType | "subuserItem" | "subuserUpdate";

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// SUBUSER LIST ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export interface SubuserList<T extends SubuserType> {
  pageNumber: number;
  pageSize: number;
  totSubusers: number;
  subusers: Subuser<T>[];
}

// there are two kinds of subusers with some different properties
export type Subuser<T extends SubuserType> = T extends "MyELSubusers" ? SubuserMyEL : SubuserLeo;

// core properties common to SubuserMyEL and SubuserLeo
export interface _Subuser {
  type: SubuserType;
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  locked: boolean;
  hasLoggedIn: boolean;
}

export interface SubuserMyEL extends _Subuser {
  type: "MyELSubusers";
  userId: string;
  emailFlag?: "RED" | "YELLOW";
  onlyLeoPrivilege: boolean;
}

export interface SubuserLeo extends _Subuser {
  type: "LeoSubusers";
  hasLoggedIn: boolean;
  door: string;
  suffix: string;
  phone: string;
}

//////////////////////////////// SAGAS & SERVICES PAYLOADS
export interface GetSubuserListPayload {
  pageNumber: number;
  pageSize?: number;
  search?: string;
  doorId?: string;
  leoOnly?: boolean;
  orderBy?: GetSubuserListSorting;
  orderDirection?: "DESC" | "ASC";
}

export type GetSubuserListSorting =
  | "USERID"
  | "USERNAME"
  | "FIRSTNAME"
  | "LASTNAME"
  | "EMAIL"
  | "STATUS"
  | "ONLY_LEONARDO_PRIVILEGE" // integrated
  | "LASTLOGIN"
  | "CREATIONDATE"; // integrated

export interface GetSubuserListByType {
  payload: GetSubuserListPayload;
  subuserType: SubuserType;
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// SUBUSER ITEM ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

//////////////////////////////// GET

export interface SubuserItem {
  userName: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  door?: string;
}

export interface GetSubuserItemPayload {
  userName: string;
  userId: string | null;
  redirect?: () => void;
}

//////////////////////////////// CREATE

export interface SubuserData {
  email1?: string;
  firstName?: string;
  lastName?: string;
  phone1?: string;
}

export interface CreateSubuserData extends SubuserData {
  logonId?: string;
  leo_only?: boolean;
  leo_only_upgrade?: boolean;
}

export interface CreateSubuserPayloadService {
  data: CreateSubuserData;
  doorId?: string;
}

export interface CreateSubuserPayload extends CreateSubuserPayloadService {
  privilegeDiff: PrivilegeDiff;
}

//////////////////////////////// UPDATE

export interface UpdateSubuserData extends SubuserData {
  userStatus?: SubuserStatusNumber; // userStatus = true asks to unlock the subuser while editing, = false to lock it
}

export interface UpdateSubuserPayloadServiceMyEL {
  subuserType: "MyELSubusers";
  data: UpdateSubuserData;
  userId: string;
}

export interface UpdateSubuserPayloadServiceLeo {
  subuserType: "LeoSubusers";
  data: UpdateSubuserData;
  username: string;
}

export type UpdateSubuserPayloadService =
  | UpdateSubuserPayloadServiceLeo
  | UpdateSubuserPayloadServiceMyEL;

export interface UpdateSubuserPayload {
  payload: UpdateSubuserPayloadService;
  privilegeDiff: PrivilegeDiff;
  callback?: (success?: boolean, error?: string) => void;
  isUnblockWithDuplicatedEmail?: boolean;
}

//////////////////////////////// LOCK/UNLOCK

// subuser status is updated with different parameters depending on the API used
export type SubuserStatusNumber = 1 | 0; // generic edit, for both MyEL and LeoOnly subusers
export type SubuserStatusString = "unblock" | "block"; // edit of status alone, only valid for MyEL subusers

export const getSubuserStatusStringFromNumber: {
  [key in SubuserStatusNumber]: SubuserStatusString;
} = {
  1: "unblock",
  0: "block",
};

export interface EditSubuserStatusPayload {
  user: Subuser<SubuserType>;
  userStatus: SubuserStatusNumber;
  callback?: (success?: boolean, error?: string) => void;
}

export interface EditSubuserStatusServicePayload {
  userId: string;
  userStatus: SubuserStatusString;
}

//////////////////////////////// EMAIL

export interface SendSubuserEmailPayload extends SendSubuserEmailPayloadService {
  subuserType: SubuserType;
}

export interface SendSubuserEmailPayloadService {
  email: string;
  username: string;
}

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// PRIVILEGE HANDLING ///////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export interface PrivilegeGroup {
  privilegeGroupId: number;
  privilegeGroupKey: string;
  privilegeGroupName: string;
  privileges: Privilege[];
  subGroups?: PrivilegeSubgroup[];
}

export interface PrivilegeSubgroup {
  privilegeSubGroupId: number;
  privilegeSubGroupKey: string;
  privilegeSubGroupType: string;
  privileges: number[];
}

export interface PrivilegesTemplate {
  templateId: number;
  templateName: string;
  selected: boolean;
  activePrivilegeIds: number[];
}

export interface Privilege {
  userPrivilegeId: number;
  userPrivilegeKey: string;
  brand: string | null;
  active: boolean;
  editable: boolean;
  dependOnPrivilegeIds: number[] | null;
}

export interface PrivilegeDiff {
  privilegeToEnable: number[];
  privilegeToDisable: number[];
}

export interface SubmitUserPrivilegesPayload {
  userId: string;
  privilegeDiff: PrivilegeDiff;
}
