import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { DotInterface, ShoppableImageData } from "../../../interfaces/cmsInterfaces";
import DotsWithPopup from "./DotsWithPopup";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface ShoppableProps {
  data: ShoppableImageData;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const ShoppableImage = ({
  data,
  isDarkMode = false,
  customFont = null,
}: ShoppableProps): JSX.Element => {
  const { multiMediaType } = data;
  const [imgSrc, setImgSrc] = useState<string>("");
  const [dots, setDots] = useState<DotInterface[]>([]);
  const [openedDot, setOpenedDot] = useState<string>("");
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [focusOnPopup, setFocusOnPopup] = useState<boolean>(false);

  useEffect(() => {
    if (multiMediaType && multiMediaType?.[0]) {
      setImgSrc(multiMediaType?.[0].picture?.data?.uri || "");
      if (multiMediaType?.[0]?.hotZones) {
        setDots(multiMediaType?.[0]?.hotZones);
      }
      if (multiMediaType?.[0]?.picture) {
        setImgWidth(multiMediaType?.[0]?.picture?.width || 0);
        setImgHeight(multiMediaType?.[0]?.picture?.height || 0);
      }
    }
  }, [multiMediaType]);

  const checkIfOpen = (dotCoords?: string): boolean => {
    return openedDot === dotCoords;
  };

  return (
    <Container onClick={() => !focusOnPopup && setOpenedDot("")}>
      <img id="img-container" src={imgSrc} />
      {dots.map((dot) => (
        <DotsWithPopup
          data={dot}
          key={dot?.coords}
          isOpen={checkIfOpen(dot?.coords)}
          setOpen={setOpenedDot}
          imgWidth={imgWidth}
          imgHeight={imgHeight}
          customFont={customFont}
          isDarkMode={isDarkMode}
          focusOnPopup={setFocusOnPopup}
        />
      ))}
    </Container>
  );
};

export default ShoppableImage;

const Container = styled.div`
  position: relative;
  padding: 1rem;

  img {
    width: 100%;
  }
`;
