import clsx from "clsx";
import React, { ChangeEvent, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../../../hooks/useTranslation";
import { AssetPlatformSocialMedia } from "../../../../../store/adt/adtInterfaces";
import { selectAdtPlatformSocialMediaOptions } from "../../../../../store/adt/adtSlice";
import CustomSelect, { CustomOptions } from "../../../../styled-UI/CustomSelect";
import CustomText from "../../../../styled-UI/CustomText";
import InputComponent from "../../../../styled-UI/InputComponent";

import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus-icon.svg";

interface Props {
  platformChange: (selectedOption: CustomOptions | null, id: string) => void;
  profileChange: (event: ChangeEvent<HTMLInputElement>, id: string) => void;
  onAdd: () => void;
  socialProfileList: AssetPlatformSocialMedia[];
  isLoading: boolean;
  showError: boolean;
  duplicatedPlatform: string[];
}

const ADTPlatformSelect = ({
  platformChange,
  profileChange,
  onAdd,
  socialProfileList,
  isLoading,
  showError,
  duplicatedPlatform,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  const dropDownOptions = useSelector(selectAdtPlatformSocialMediaOptions);

  const isButtonDisabled = useMemo(
    () =>
      !getShowButton(dropDownOptions, socialProfileList) || isLastElementEmpty(socialProfileList),
    [dropDownOptions, socialProfileList]
  );

  const renderSocialProfileBox = () => {
    return socialProfileList?.map((socialProfile) => (
      <Selection key={socialProfile.uuid}>
        <div>
          <CustomSelect
            options={dropDownOptions}
            value={dropDownOptions?.find((option) => option?.value === socialProfile?.platformName)}
            type="white"
            onChange={(selectedOption) => platformChange(selectedOption, socialProfile.uuid)}
            placeholder={translateLabel("ADT_PLATFORM_SOCIAL_SELECT_DEFAULT")}
            isLoading={isLoading}
            isError={showError && isPlatformDuplicated(socialProfile, duplicatedPlatform)}
          />
        </div>
        <div>
          <InputComponent
            type="text"
            placeholder={translateLabel("ADT_PLATFORM_SOCIAL_PLACEHOLDER")}
            value={socialProfile?.platformUserName ?? ""}
            onChange={(event) => profileChange(event, socialProfile.uuid)}
            disabled={socialProfile?.platformName === ""}
            {...(showError &&
              isPlatformDuplicated(socialProfile, duplicatedPlatform) && {
                colorStyle: "error",
              })}
          />
        </div>
      </Selection>
    ));
  };

  return (
    <>
      <CustomText as="h2" font="font-bold" fontSizePx={18} lineHeightPx={18} marginBottomPx={16}>
        {translateLabel("ADT_PLATFORM_SOCIAL_TITLE")}
      </CustomText>
      <CustomText as="p" fontSizePx={14} lineHeightPx={24}>
        {translateLabel("ADT_PLATFORM_SOCIAL_SUBTITLE")}
      </CustomText>
      {showError && duplicatedPlatform.length > 0 && (
        <CustomText as="p" fontSizePx={12} font={"font-regular"} color="red" lineHeightPx={24}>
          {translateLabel("ADT_PLATFORM_SOCIAL_DUPLICATE_ERROR")}
        </CustomText>
      )}

      <PlatformContainer>
        <PlatformItem className={clsx(socialProfileList?.length >= 1 && "platform-margin")}>
          {renderSocialProfileBox()}
        </PlatformItem>

        <AddPlatform onClick={() => !isButtonDisabled && onAdd()} isDisabled={isButtonDisabled}>
          <div>
            <PlusIcon />
          </div>
          <CustomText
            as="p"
            font="font-bold"
            fontSizePx={14}
            lineHeightPx={22}
            uppercase
            color={isButtonDisabled ? "gray-dark" : "primary"}
          >
            {translateLabel("ADT_PLATFORM_SOCIAL_ADD")}
          </CustomText>
        </AddPlatform>
      </PlatformContainer>
    </>
  );
};

const isLastElementEmpty = (socialProfileList: AssetPlatformSocialMedia[]) => {
  return socialProfileList[socialProfileList.length - 1]?.platformName === "";
};

const getShowButton = (
  originalOptions: CustomOptions[],
  socialProfileList: AssetPlatformSocialMedia[]
) => {
  return socialProfileList.length < originalOptions.length;
};

export const getDuplicatePlatforms = (socialProfileList: AssetPlatformSocialMedia[]): string[] => {
  const existingPlatforms: string[] = [];
  const duplicatedPlatforms: string[] = [];

  for (let i = 0; i < socialProfileList.length; i++) {
    if (existingPlatforms.includes(socialProfileList[i].platformName))
      duplicatedPlatforms.push(socialProfileList[i].platformName);
    else existingPlatforms.push(socialProfileList[i].platformName);
  }

  return duplicatedPlatforms;
};

const isPlatformDuplicated = (
  currentPlatform: AssetPlatformSocialMedia,
  duplicatedPlatform: string[]
) => {
  return duplicatedPlatform.includes(currentPlatform.platformName);
};

const Selection = styled.div`
  width: 30.625rem;
  display: flex;
  gap: 1rem;

  > div:first-child {
    width: 11.25rem;
  }
  > div:last-child {
    width: 18.375rem;
  }
`;

const PlatformItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
  &.platform-margin {
    margin-top: 1.5rem;
  }
`;

const PlatformContainer = styled.div``;
const AddPlatform = styled.button<{ isDisabled: boolean }>`
  width: max-content;
  display: flex;
  gap: 1rem;
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};

  > div {
    width: 1.375rem;
    height: 1.375rem;

    > svg {
      fill: ${(props) =>
        props.isDisabled ? props.theme.palette.gray.medium : props.theme.palette.primary};
    }
  }
`;

export default ADTPlatformSelect;
