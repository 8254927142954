import { useEffect, useRef, useState } from "react";

import { useBreakpoint } from "../../assets/theme/breakpoint";
import { breakpoints as bp } from "../../assets/theme/theme";

export const usePageContentDimensions = (): {
  refContent: any;
  heightContent: number;
  widthContent: number;
  scaleContent: number;
} => {
  const breakpoints = useBreakpoint();
  const refContent: any = useRef();

  const widthContent = window.innerWidth;
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (breakpoints["md"]) {
      const height = refContent.current?.getBoundingClientRect().height;
      if (height) setHeight(height);
    }
  }, []);

  return {
    refContent,
    heightContent: Number(Number(height).toFixed(3)),
    widthContent: widthContent,
    scaleContent: Number(Number(widthContent / bp.md).toFixed(3)),
  };
};
