import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import { cancelBestSellers } from "../../../store/catalogue/catalogueSaga";
import { setBestSeller } from "../../../store/catalogue/catalogueSlice";
import CustomText from "../../styled-UI/CustomText";
import BestSellersContainer from "../../widgets/BestSellersContainer";
import { useSelector } from "react-redux";
import {
  selectProductRecommendation,
  selectProductRecommendationStatus,
} from "../../../store/catalogue/catalogueSlice";
import useCanRender from "../../../hooks/useCanRender";
import { ReactComponent as Bag } from "../../../assets/icons/bag-2-icon.svg";
interface Props {
  isMultidoor: boolean;
}

const CartEmptyPage = ({ isMultidoor }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const productRecommendation = useSelector(selectProductRecommendation);
  const productRecommendationStatus = useSelector(selectProductRecommendationStatus);
  const canRender = useCanRender();
  const dispatch = useDispatch();

  //if product reccomandation is not visible or it's empty, show bestseller
  const showBestseller =
    !isMultidoor && canRender("ADD_TO_CART")
      ? (productRecommendationStatus === "SUCCESS" || productRecommendationStatus === "ERROR") &&
        (productRecommendation == null || productRecommendation?.catalogEntryView?.length === 0)
      : true;

  useEffect(() => {
    return () => {
      dispatch(cancelBestSellers());
      dispatch(setBestSeller({ type: "reset" }));
    };
  }, []);

  return (
    <div>
      <Header>
        <Title>
          <CustomText as="h1" fontSizePx={32} font="font-medium">
            {translateLabel("CART_TITLE")}
          </CustomText>
          <CustomText as="span" fontSizePx={32} font="font-medium" marginLeftPx={8}>
            (0)
          </CustomText>
        </Title>
      </Header>
      <EmptyCartContainer>
        <IconContainer>
          <Bag />
        </IconContainer>
        <TextContainer>
          <CustomText as="h1" fontSizePx={36} font="font-medium" lineHeightPx={36}>
            {translateLabel("CART_EMPTY_PAGE_MESSAGE")}
          </CustomText>
        </TextContainer>
      </EmptyCartContainer>
      {showBestseller && <BestSellersContainer hideDisplay hideWrapper />}
    </div>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
`;

const Title = styled.div`
  display: flex;
  align-items: flex-end;
`;

const EmptyCartContainer = styled.div`
  margin-bottom: 6rem;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.25rem;
  width: 3.25rem;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.palette.primary};
  margin: 0 auto 1.5rem;

  svg {
    height: 1.25rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default CartEmptyPage;
