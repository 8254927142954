import React from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";
import { useHandleCmsLink } from "../../../../utils/cmsUtils";
import CustomText from "../../../styled-UI/CustomText";
import LinkButton from "../../../styled-UI/LinkButton";
import SetInnerHTMLWithSanitize from "../../SetInnerHTMLWithSanitize";
interface Props {
  title?: string;
  description?: string;
  linkString?: string;
  linkLabel?: string;
  openInNewTab?: boolean;
  externalLink?: boolean;
  closePopup: () => void;
  slideNumber?: number;
  totalSlide?: number;
  "data-element-id"?: string;
}

const FeatureTileInfoSection = ({
  title,
  description,
  linkString,
  linkLabel,
  openInNewTab = false,
  externalLink = false,
  closePopup,
  slideNumber,
  totalSlide,
  "data-element-id": dataElementId,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const { handleCmsLink } = useHandleCmsLink();

  const handleRedirect = (): void => {
    handleCmsLink(linkString, externalLink, openInNewTab);
  };

  return (
    <Container>
      {title && (
        <TitleBox>
          <CustomText as="p" fontSizePx={18} fontWeight="bold" color="primary" lineHeightPx={16}>
            {title}
          </CustomText>
          {slideNumber && totalSlide && (
            <CustomText
              as="p"
              fontSizePx={13}
              fontWeight="bold"
              color="gray-neutral"
              lineHeightPx={17.94}
            >
              {slideNumber}/{totalSlide}
            </CustomText>
          )}
        </TitleBox>
      )}
      {description && (
        <TextBox>
          <CustomText
            as="span"
            fontSizePx={14}
            font="font-regular"
            fontWeight="normal"
            lineHeightPx={23.94}
            color="primary"
          >
            {description && <SetInnerHTMLWithSanitize text={description} />}
          </CustomText>
        </TextBox>
      )}
      {linkString && linkLabel && (
        <LinkButton
          {...(dataElementId ? { "data-element-id": `${dataElementId}_LearnMore` } : {})}
          bold
          marginTopUnderlinePx={-3.5}
          onClick={() => {
            closePopup();
            handleRedirect();
          }}
        >
          {translateLabel(linkLabel)}
        </LinkButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextBox = styled.div`
  margin: 1.5rem 0 4rem;
  min-height: 10.5rem;

  a {
    text-decoration: underline;
  }
`;

export default FeatureTileInfoSection;
