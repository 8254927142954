import {
  Locale,
  format,
  differenceInMinutes,
  compareAsc,
  compareDesc,
  startOfDay,
  sub,
  add,
  differenceInDays,
  parse,
  parseISO,
} from "date-fns";
import { enUS, it, fr, enGB } from "date-fns/locale";

interface DateLocale {
  [key: string]: Locale;
}

//TODO add all locales
const mapDates: DateLocale = {
  "en-US": enUS,
  "it-IT": it,
  "fr-FR": fr,
  "en-GB": enGB,
};

//TODO: possible dynamic import? verify if feasible
//const getLocale = (locale: string) => require(`date-fns/locale/${locale}`);

export const getMatchLocale = (locale: string): Locale => {
  return mapDates[locale as keyof typeof mapDates] ?? enGB;

  // TODO: possible way to dynamically map en-US to enUS and it-IT to it
  // const [first, second] = locale.split("-").map((_) => _.toLowerCase());
  // return { first === second ? first : first + second.toUpperCase() } as Locale;
};

/**
 * Get localized date pattern (eg. dd/MM/yyyy) based on given locale
 *
 * @param {string} locale
 * @return {*}  {string}
 */
export const getDateFormatString = (locale: string): string => {
  if (locale) {
    const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());

    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case "day":
            return "dd";
          case "month":
            return "MM";
          case "year":
            return "yyyy";
          default:
            return obj.value;
        }
      })
      .join("");
  } else return "";
};

/**
 *  Format CMS date to be displayed in pages based on locale
 *
 *
 * @param {string | Date} date Example: 2021-07-14T02:00+02:00[Europe/Berlin]
 * @param {string} dateFormat -> check https://date-fns.org/v2.22.1/docs/format
 * @param {string} localeExternale Locale returned from selectLocale selector
 * @return {*}  {string}
 */
export const formatDate = (
  date: string | Date,
  dateFormat = "P",
  localeExternal: string,
  timezone?: string
): string => {
  try {
    if (date) {
      const matchLocale: Locale = getMatchLocale(localeExternal);
      let dateToFormat: Date;

      if (date instanceof Date) {
        dateToFormat = date;
      } else {
        const apiDate = date.split("[")?.[0];
        dateToFormat = new Date(apiDate);
      }

      const option: any = { locale: matchLocale };

      if (timezone) {
        option.timeZone = timezone;
      }

      const newDate = format(dateToFormat, dateFormat, option);

      if (newDate.includes(".")) {
        return newDate.split(".").join("/");
      } else return newDate;
    } else {
      return "";
    }
  } catch (error) {
    console.log("[MYL] Error format date", error);
    return "";
  }
};

/**
 *  Format CMS date to be displayed in pages based on locale
 *
 *
 * @param {string | Date} date Example: 2021-07-14T02:00+02:00[Europe/Berlin]
 * @param {string} dateFormat -> check https://date-fns.org/v2.22.1/docs/format
 * @param {string} localeExternale Locale returned from selectLocale selector
 * @return {*}  {string}
 */
export const formatDateNoLocale = (date: string | Date, dateFormat = "P"): string => {
  try {
    if (date) {
      let dateToFormat: Date;

      if (date instanceof Date) {
        dateToFormat = date;
      } else {
        const apiDate = date.split("[")?.[0];
        dateToFormat = new Date(apiDate);
      }

      const newDate = format(dateToFormat, dateFormat);

      if (newDate.includes(".")) {
        return newDate.split(".").join("/");
      } else return newDate;
    } else {
      return "";
    }
  } catch (error) {
    console.log("[MYL] Error format date", error);
    return "";
  }
};

export const fromStringToDate = (
  date: string //YYYYMMDD
): Date | string => {
  try {
    const year = parseInt(date?.slice(0, 4));
    const month = parseInt(date?.slice(4, 6)) - 1;
    const day = parseInt(date?.slice(6, 8));

    return new Date(year, month, day);
  } catch (error) {
    console.log("[MYL] Error format date", error);
    return "";
  }
};

/**
 * Generic getter of Date object from string, by specifying the format as reported at:
 * https://date-fns.org/v2.28.0/docs/parse
 *
 * Used mostly to handle the exception and undefined string as a undefined date.
 *
 * @param {(string | undefined | null)} date
 * @param {string} format
 * @return {*}  {(Date | undefined)}
 */
export const getDateFromGenericString = (
  date: string | undefined | null,
  dateFormat: string // specify the format as eg. "yyyymmdd"
): Date | undefined => {
  if (!date) return undefined;

  try {
    return parse(date, dateFormat, new Date());
  } catch (error) {
    console.log("[MYL] Error format date", error);
    return undefined;
  }
};

/**
 * Generic getter of Date object from string in ISO 8601 format as reported at:
 * https://date-fns.org/v2.28.0/docs/parseISO
 *
 * Used mostly to handle the exception and undefined string as a undefined date.
 *
 * @param {(string | undefined | null)} date
 * @return {*}  {(Date | undefined)}
 */
export const getDateFromISOString = (date: string | undefined | null): Date | undefined => {
  if (!date) return undefined;

  try {
    return parseISO(date);
  } catch (error) {
    console.log("[MYL] Error format date", error);
    return undefined;
  }
};

/**
 * Generic getter of string from Date object, by specifying the format as reported at:
 * https://date-fns.org/v2.28.0/docs/parse
 *
 * Used mostly to handle the exception and undefined Date as an empty string.
 *
 * @param {(string | undefined | null)} date
 * @param {string} format
 * @return {*}  {(Date | undefined)}
 */
export const getStringFromDate = (
  date: Date | undefined | null,
  dateFormat: string // specify the format as eg. "yyyymmdd"
): string => {
  if (!date) return "";

  try {
    return format(date, dateFormat);
  } catch (error) {
    console.log(`[MYL] Error formatting date ${date}:`, error);
    return "";
  }
};

export const formatDateNews = (date: string | Date, localeExternal: string): string => {
  let dateToFormat: Date;

  if (date instanceof Date) dateToFormat = date;
  else {
    const apiDate = date?.split("[")[0];
    dateToFormat = new Date(apiDate);
  }

  const options = { year: "numeric", month: "long", day: "numeric" };

  return dateToFormat?.toLocaleDateString(
    localeExternal,
    options as { year: "numeric"; month: "long"; day: "numeric" }
  );
};

/**
 *  Check if current date (now) is over another date in terms of minutes
 *
 *
 * @param {Date} date
 * @param {number} minutes
 * @return {*}  {boolean}
 */

export const exceedsMinutes = (date: Date, minutes: number): boolean => {
  const now = new Date();
  const diff = differenceInMinutes(now, date);
  return diff > minutes;
};

/**
 * https://date-fns.org/v2.28.0/docs/compareAsc
 * type = "asc" return
 *    = 1   -> dateLeft > dateRight
 *    = -1  -> dateLeft < dateRight
 *    = 0   -> dateLeft = dateRight
 *
 * type = "desc" return
 *    = 1   -> dateLeft < dateRight
 *    = -1  -> dateLeft > dateRight
 *    = 0   -> dateLeft = dateRight
 *
 * @param {Date} dateLeft
 * @param {Date} dateRight
 * @param {("asc" | "desc")} type
 * @return {*}  {number}
 */
export const compareDay = (dateLeft: Date, dateRight: Date, type: "asc" | "desc"): number => {
  if (type === "asc") return compareAsc(startOfDay(dateLeft), startOfDay(dateRight));
  else return compareDesc(startOfDay(dateLeft), startOfDay(dateRight));
};

export const diffDay = (dateLeft: Date, dateRight: Date): number => {
  return differenceInDays(dateLeft, dateRight);
};

export const subtractDate = (date: Date, duration: Duration): Date => {
  return sub(date, duration);
};

export const addDate = (date: Date, duration: Duration): Date => {
  return add(date, duration);
};
