import { BFFApiDomain } from "../serviceUtils";

///////// LIST OF ORDERS TO BE APPROVED

// get list of orders to be approved/rejected
export const getOrdersToApproveUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/approval/suborders";

//////// APPROVING / REJECTING ORDERS

// approve/reject selected orders
export const updateOrdersToApproveUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/approval/suborders";

// run simulate on specified orders before approving
export const simulateOrdersToApproveUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/approval/suborders/simulate";

//////// ORDER DETAIL

// get order details header
export const getOrderToApproveHeaderUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/approval/suborders/header";

// get order details main content
export const getOrderToApproveDetailsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/approval/suborders/{subOrderId}";

// update/delete order items in suborder approval
export const approvalSubordersItemsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/approval/suborders/items";

//////// ORDER SIMULATION

/////// EXPORT CSV

export const exportOrderToApproveCSVUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/approval/orders/report";

export const exportOrderToApproveTYPCSVUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/approval/suborders/report";
