import { BFFApiDomain } from "../serviceUtils";

/////////////// CHECKOUT

// get and post cart items (ie. order confirmation page)
export const cartUrl = BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/cart";

// setting PO number and notes for each suborder
export const updateCartUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/cart/{orderId}/{subOrderId}";

// submit order (in order confirmation page)
export const checkoutUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/cart/checkout";

// check carnet
export const carnetCheckUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/carnet/check";

/////////////// THANK YOU PAGE

// order recap (ie. thank you page)
export const orderRecapUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/order/{checkoutOrderId}";

// export csv
export const exportCSVTYPUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/order/{id}/report";

// repeat order
export const repeatOrderUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/orders/{orderId}/repeat";

// get vouchers
export const retrieveVouchersUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/vouchers/retrieve";

// validate vouchers
export const validateVouchersUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/vouchers/validate";
