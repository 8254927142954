import { PaymentErrorAdditionalInfoType } from "../../components/pages/payment/payment-guard/PaymentError";
import { CustomOptions } from "../../components/styled-UI/CustomSelect";
import { RequestStatus } from "../../interfaces/mainInterfaces";

export interface PaymentState {
  ////////// MISC
  paymentPolicyStatus: PaymentPolicyStatus;
  currency: string | null;
  getPaymentTableStatus: RequestStatus;
  getPaymentSelectLoaderStatus: RequestStatus;
  getPaymentManagementInfoStatus: RequestStatus;
  paymentProcessStatus: PaymentProcessStatus;
  paymentErrors: PaymentErrors;

  ////////// PAYMENT MANAGEMENT
  paymentManagementOverview: PaymentManagementOverviewTotals | null;
  payNowList: PaymentManagementOpenDocument[]; // maps NetBalanceItems
  statementList: PaymentManagementOpenDocument[]; // maps PayNowItems
  netBalanceList: PaymentManagementOpenDocument[]; // maps StatBalanceItems
  deselectionReasons: PaymentDeselectionReasonsOptions[]; // options for deselection popup in PAY_NOW table
  deselectedDocs: PaymentManagementSimulationDoc[];
  paymentManagementSelected: PaymentManagementSelected | null;
  temporaryPaymentMethodsCC: PaymentMethod[];
  temporaryPaymentMethodsACH: PaymentMethod[];
  paymentManagementSelectedMethod: PaymentManagementSelectedMethod;

  paymentSimulationResponse: PaymentSimulationResponse | null;
  paymentConfirmationResponse: PaymentConfirmationResponse | null;

  /////////// PAYMENT METHODS
  paymentMethodsCC: PaymentMethod[];
  paymentMethodsACH: PaymentMethod[];
  paymentMethodDetail: PaymentMethodModalInfo | null;
  paymentMethodsStatus: PaymentMethodsStatus;
  paymentMethodListCountries: CustomOptions[];

  ////////// PAYMENT HISTORY
  paymentHistoryList: PaymentHistoryList[];
  paymentHistoryDetail: PaymentHistoryDetail | null;
  storedPaymentFilters: PaymentFilterPayload | null;

  ////////// PAYMENT DOCUMENT HISTORY
  paymentDocumentHistory: PaymentDocumentHistory[];

  ////////// PAYMENT STATEMENT
  paymentStatement: PaymentStatement[];
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// ERROR HANDLING ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const PAYMENT_ERROR_LOCATIONS = [
  "banner",
  "simulation",
  "confirmation",
  "paymentMethod",
  "paymentMethodEdit",
  "paymentMethodSave",
  "paymentMethodDelete",
  "paymentMethodPreferred",
] as const;
export type PaymentErrorLocation = typeof PAYMENT_ERROR_LOCATIONS[number];

// used by error config object, which lists all handled errors and their respective configurations
export type PaymentErrorConfigLocation = {
  [key in PaymentErrorLocation]?: PaymentErrorConfig;
};

export interface PaymentErrorConfig {
  titleLabelKey?: string;
  subtitleLabelKey?: string;
  contentLabelKey?: string;
  status: "success" | "alert" | "error"; // determines color of error UI element (eg. banner)
  enforcedPrivilege?: string;
  stopExecution: boolean; // whether this error should also hide other content
  additionalInfo?: PaymentErrorAdditionalInfoType;
}

// saved errors in slice, at most one for each location, null if no error
export type PaymentErrors = { [key in PaymentErrorLocation]: PaymentErrorConfig | null };

export interface SetPaymentError {
  type: PaymentErrorLocation | "default";
  error: PaymentErrorConfig | null;
}

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////// MISC /////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

////////// Payment methods types
export type PaymentMethodLabel = "PAYMENT_METHOD_CREDIT_CARD" | "PAYMENT_METHOD_BANK_ACCOUNT";
export const PAYMENT_METHOD_VALUES = ["CC", "ACH"] as const;
export type PaymentMethodValues = typeof PAYMENT_METHOD_VALUES[number];

export const paymentMethodLabelMapping: { [key in PaymentMethodValues]: PaymentMethodLabel } = {
  CC: "PAYMENT_METHOD_CREDIT_CARD", // Credit Card
  ACH: "PAYMENT_METHOD_BANK_ACCOUNT", // Bank Account
};

////////// Status labels
export type PaymentStatusLabel = "PAYMENT_STATUS_A" | "PAYMENT_STATUS_B" | "PAYMENT_STATUS_C";
export const PAYMENT_STATUS_VALUES = ["A", "B", "C"] as const;
export type PaymentStatusValues = typeof PAYMENT_STATUS_VALUES[number];

export const paymentStatusLabelMapping: { [key in PaymentStatusValues]: PaymentStatusLabel } = {
  A: "PAYMENT_STATUS_A", // Processed
  B: "PAYMENT_STATUS_B", // In process
  C: "PAYMENT_STATUS_C", // All
};

////////// Open Text Download
export interface OpenTextDownloadId {
  openTextId?: string; // maps Opentextid
  openTextArchId: string | null; // maps Archid
}

////////// Username
// 1 - mainuser, 2 - subuser, 3 - bo user
export type PaymentUserType = "1" | "2" | "3";

export interface PaymentUsername {
  username?: string;
  userType?: PaymentUserType;
}

////////// Payment Policy

export interface PaymentPolicyStatus {
  isAccepted: boolean;
  status: RequestStatus;
}

////////// Payloads

export interface SetPaymentRequestStatus<T> {
  type: keyof T | "default";
  status: RequestStatus;
}

export interface PaymentPayload {
  doorId: string;
  pageNumber?: number;
}

export interface PaymentFilterPayload extends PaymentPayload {
  Datefrom: string;
  Dateto: string;
  Status: PaymentStatusValues;
}

export interface SavePaymentPolicyAgreementPayload {
  value: boolean;
  callback: (success: boolean) => void;
}

export interface DownloadPaymentDocumentPayload {
  docType: string;
  callback: (success: boolean, url?: string) => void;
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// PAYMENT MANAGEMENT //////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export interface PaymentProcessStatus {
  simulation: RequestStatus;
  confirmation: RequestStatus;
}

//////////////////////////////// TYPES & MAPPINGS

// payment types for the landing page in Payment Management
//    - there are 5 payment types in total (PAYMENT_TYPES_FULL): "PAY_NOW", "STATEMENT", "NET_BALANCE", "PAY_SPECIFIC", "PAY_ADVANCED"
//    - of those, only the first 4 entail the selection of documents, and they are chosen by selecting the corresponding button (PAYMENT_TYPES)
//    - of those, only the first 3 correspond to a specific table (PAYMENT_TYPES_WITH_TABLE)

export const PAYMENT_TYPES_WITH_TABLE = ["PAY_NOW", "STATEMENT", "NET_BALANCE"] as const; // ["PAY_NOW", "STATEMENT", "NET_BALANCE"]
export const PAYMENT_TYPES = [...PAYMENT_TYPES_WITH_TABLE, "PAY_SPECIFIC"] as const; // ["PAY_NOW", "STATEMENT", "NET_BALANCE", "PAY_SPECIFIC"]
export const PAYMENT_TYPES_FULL = [...PAYMENT_TYPES, "PAY_ADVANCED"] as const; // ["PAY_NOW", "STATEMENT", "NET_BALANCE", "PAY_SPECIFIC", "PAY_ADVANCED"]
export const PAYMENT_TYPES_SAP = ["A", "B", "C", "D", "E"] as const;

export type PaymentManagementTypesWithTable = typeof PAYMENT_TYPES_WITH_TABLE[number]; // "PAY_NOW" | "STATEMENT" | "NET_BALANCE"
export type PaymentManagementTypes = typeof PAYMENT_TYPES[number]; // "PAY_NOW" | "STATEMENT" | "NET_BALANCE" | "PAY_SPECIFIC"
export type PaymentManagementTypesFull = typeof PAYMENT_TYPES_FULL[number]; // "PAY_NOW" | "STATEMENT" | "NET_BALANCE" | "PAY_SPECIFIC" | "PAY_ADVANCED"
export type PaymentManagementTypesSAP = typeof PAYMENT_TYPES_SAP[number]; // "A" | "B" | "C" | "D" | "E"

// to be sent to SAP, PAYMENT_TYPES_FULL needs to be mapped to PAYMENT_TYPES_SAP
export const SAPPaymentTypesMapping: {
  [key in PaymentManagementTypesFull]: PaymentManagementTypesSAP;
} = {
  PAY_NOW: "A",
  STATEMENT: "B",
  NET_BALANCE: "C",
  PAY_SPECIFIC: "D",
  PAY_ADVANCED: "E",
};

// possible deselection reasons for a PAY_NOW doc are fetched from API, but should be:
//      FR: "Freight",
//      PD: "Price difference",
//      PO: "POD/Tracer",
//      RM: "Return merchandise",
//      SH: "Shortages",
//      OT: "Other",
//      TX: "Tax",
export const PAYMENT_DESELECTION_REASONS = ["FR", "PD", "PO", "RM", "SH", "OT", "TX"] as const;
export type PaymentDeselectionReasons = typeof PAYMENT_DESELECTION_REASONS[number];

//////////////////////////////// SLICE

export interface PaymentManagementOverviewTotals {
  totalStatement: number; // maps Totalstatement
  totalPayNow: number; // maps Totalpaynow
  totalReceivables: number; // maps Totalreceivables
  totalFutureDue: number; // maps Totalfuturedue
  totalPastDue: number; // maps Totalpastdue
}

export interface PaymentManagementOpenDocument {
  documentTypeLabel?: string; // maps Documenttype into label
  soldTo?: string; // maps Soldto
  referencePoNumber?: string; // maps Reference/Ponumber
  // TODO: original doc number?
  documentId: string; // maps Documentid
  originalDocNumber?: string; // maps Reference
  documentDate?: Date; // maps Documentdate
  dueDate?: Date; // maps Duedate
  docAmount?: number; // maps Docamount
  openAmount?: number; // maps Openamount
  openTextDownload?: OpenTextDownloadId;

  reference?: string; // maps Reference
  poNumber?: string; // maps Ponumber
  position?: string;
  documentType?: string; // maps Documenttype
}

export interface PaymentManagementDocumentsList {
  payNowList: PaymentManagementOpenDocument[]; // maps NetBalanceItems
  statementList: PaymentManagementOpenDocument[]; // maps PayNowItems
  netBalanceList: PaymentManagementOpenDocument[]; // maps StatBalanceItems
}

export interface SavePaymentManagementDocumentsListPayload {
  list: PaymentManagementOpenDocument[];
  listName: "payNowList" | "statementList" | "netBalanceList";
}

export interface PaymentDeselectionReasonsOptions extends CustomOptions {
  value: PaymentDeselectionReasons;
}

export interface PaymentAdvancedSelected {
  advancedAmount: string;
  advancedReason: string;
}

export interface PaymentManagementSelected {
  selectedDocs: { [key in PaymentManagementTypesWithTable]: PaymentManagementOpenDocument[] };
  selectedPaymentType: PaymentManagementTypesFull;
  selectedAdvancedPayment?: PaymentAdvancedSelected;
}

export type PaymentManagementSelectedMethod = {
  [key in PaymentMethodSubscriptionType]: PaymentMethod | null;
};

export interface PaymentManagementSelectedMethodPayload {
  type: PaymentMethodSubscriptionType;
  method: PaymentMethod | null;
}

//////////////////////////////// SIMULATION

//////////////// Request
export interface PaymentSimulationSagaPayload {
  paymentInfo: PaymentMethod;
  paymentAmount: number;
}

export interface PaymentSimulationRequest {
  payload: {
    paymentAmount?: string;
    paymentType?: PaymentManagementTypesSAP; //  A,B,C etc per PAY_NOW, STATEMENT, etc..
    currency?: string;
    isBackOfficeUser?: boolean;

    // invoices
    paidInvoices?: PaymentManagementSimulationDoc[];
    deselectedInvoices?: PaymentManagementSimulationDoc[];
    deselectionReasonCode?: PaymentDeselectionReasons[];

    // advanced
    advancedPaymentReason?: string; // only advanced payment

    // payment method
    subscriptionId?: string; // token
    paymentMethod?: PaymentMethodValues; // CC o ACH
    paymentDescription?: string; // card/account description del metodo
    cardType?: string; // TODO: first 6 digits returned by Cybersource in the payment method's details API (paySubscriptionRetrieveReply_cardAccountNumber)
  };
  qparams: {
    door: string;
  };
}

export interface PaymentManagementSimulationDoc {
  documentType?: string;
  soldTo?: string;
  referencePoNumber?: string | null; // as-is arrivava, ma adesso no -> possiamo mandare quello che componiamo noi
  documentId: string;
  documentDate?: string; // change type
  dueDate?: string; // change type
  docAmount?: string; // change name
  openAmount?: string; // change type
  reference?: string;
  poNumber?: string;
  boxId?: string;
  position?: string;

  archiveId?: string; // different from PaymentManagementOpenDocument
  openTextId?: string; // different from PaymentManagementOpenDocument
  currency?: string; // different from PaymentManagementOpenDocument

  selected: boolean; // different from PaymentManagementOpenDocument
  deselectReason: PaymentDeselectionReasons | null; // different from PaymentManagementOpenDocument

  // "currencySymbol": "string",   // as-is arrivava, ma adesso no // Intnl.NumberFormat#formatToParts
  // "get$$HashKey": "string"       // credo sia una roba di angular, da togliere?
}

//////////////// Response

export interface PaymentSimulationResponse {
  simulationId: string;
  subscriptionId: string;
  summary: PaymentSimulationSummary;
  lock: PaymentSimulationResponseLock;
  paymentMethod: PaymentMethod;
}

export interface PaymentSimulationResponseLock {
  lockedPayment: boolean;
  lockingSimulationId?: string;
  lockingUsername?: PaymentUsername;
}

export interface PaymentSimulationSummary {
  paymentAmount?: number;
  fixDiscount?: number;
  eomDiscount?: number;
  discountedAmount?: number;
}

//////////////////////////////// CONFIRMATION

export interface PaymentConfirmationSagaPayload {
  achAgreement: boolean | undefined;
  callback?: (isSuccess?: boolean) => void;
}

export interface PaymentConfirmationRequest {
  qparams: {
    door: string;
  };
  payload: {
    achAgreement: boolean | undefined;
    simulationId: string;
    subscriptionId: string;
  };
}

export interface PaymentConfirmationResponse {
  simulationId: string;
  paidDocuments: PaymentManagementOpenDocument[];
  paymentMethod?: PaymentMethodLabel;
  totalPaidAmount?: number;
  netAmount?: number;
  fixDiscount?: number;
  eomDiscount?: number;
  errorType?: string;
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// PAYMENT METHODS /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export interface PaymentMethodsStatus {
  getList: RequestStatus;
  addOrEdit: RequestStatus;
  delete: RequestStatus;
  getEditModalDetails: RequestStatus;
}

//////////////////////////////// TYPES & MAPPINGS ////////////////////////////////

export type PaymentMethodSubscriptionType = "AchAccount" | "CreditCard";
export type UseType = "payment-management" | "payment-method";
export type PaymentMethodPopupAction = "add" | "edit";

export const mapPaymentMethodFromSubscriptionType: {
  [key in PaymentMethodSubscriptionType]: PaymentMethodValues;
} = {
  AchAccount: "ACH",
  CreditCard: "CC",
};

export const mapSubscriptionTypeFromPaymentMethod: {
  [key in PaymentMethodValues]: PaymentMethodSubscriptionType;
} = {
  ACH: "AchAccount",
  CC: "CreditCard",
};

////////////////////////////// READ METHOD INFO FLOW /////////////////////////////

export interface GetPaymentMethodsPayload {
  hideListLoading?: boolean;
  resetAllLoading?: boolean;
}

export interface PaymentMethod {
  subscriptionId: string;
  subscriptionType: PaymentMethodSubscriptionType;
  paymentMethod: PaymentMethodValues; // CC o ACH
  description?: string; // alternative to description if using proposal #1 for method integration
  preferred?: boolean;
  paymentMethodInfo: PaymentMethodInfoCC | PaymentMethodInfoACH;
  lastAdded?: boolean;
}

export interface PaymentMethodInfoCC {
  cardNumber: string;
  cardType: string; // TODO: first 6 digits returned by Cybersource in the payment method's details API (paySubscriptionRetrieveReply_cardAccountNumber)
  expirationMonth: string;
  expirationYear: string;
  expirationDate: string;
}

export interface PaymentMethodInfoACH {
  accountNumber: string;
  country: string;
  transitRoutingNumber: string;
}

export interface PaymentMethodError {
  fieldIdentifier: string;
  errorType: string;
}

///////////////////////////// ADD / EDIT METHOD FLOWS ////////////////////////////

///////////////// components data interfaces

// payload of the add/edit method modal method
export interface PaymentMethodModal {
  methodInfo: PaymentMethodModalCC | PaymentMethodModalBA;
  address: PaymentMethodModalAddress;
  subscriptionType: PaymentMethodSubscriptionType;
  subscriptionId: string;
}

// generalization of the possible key-value elements contained in the add/edit method modal form
export type PaymentMethodModalForm = {
  [key in keyof PaymentMethodModalInfo]: PaymentMethodModalInfo[keyof PaymentMethodModalInfo];
};

export interface PaymentMethodModalInfo
  extends Partial<PaymentMethodModalCC>,
    Partial<PaymentMethodModalBA>,
    Partial<PaymentMethodModalAddress> {}

export interface PaymentMethodModalAddress {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  country: string;
  city: string;
  zipCode: string;
  state: string;
}

export interface PaymentMethodModalCC {
  description: string;
  saveMethod: boolean; // mapped to isVisible
  creditCardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cvc: string;
}

export interface PaymentMethodModalBA {
  description: string;
  saveMethod: boolean; // mapped to isVisible
  transitNumber: string;
  accountNumber: string;
}

///////////////// saga and services interfaces

export interface PaymentMethodModalPayload {
  data: AddPaymentMethodOldB2BServicePayload | EditPaymentMethodOldB2BServicePayload | null;
  callback?: (success: boolean, method?: PaymentMethod) => void;
  isSetPreferred?: boolean;
  isTemporary?: boolean;
  useType: UseType;
}

export interface AddPaymentMethodOldB2BServiceRequest {
  qparams: {
    door: string;
  };
  payload: AddPaymentMethodOldB2BServicePayload;
}

export interface AddPaymentMethodOldB2BServicePayload {
  isPreferred?: boolean;
  isVisible: boolean; // TODO: fill in based on whether save this card is flagged or not (default true in Payment Method page)
  subscriptionType: PaymentMethodSubscriptionType; // AchAccount o CreditCard
  paymentMethodInfo: AddPaymentMethodOldB2BBank | AddPaymentMethodOldB2BCard;
}

export interface EditPaymentMethodOldB2BServiceRequest {
  qparams: {
    door: string;
  };
  payload: EditPaymentMethodOldB2BServicePayload;
}
export interface EditPaymentMethodOldB2BServicePayload {
  isPreferred?: boolean;
  subscriptionType: PaymentMethodSubscriptionType; // AchAccount o CreditCard
  paymentMethodInfo: EditPaymentMethodOldB2BBank | EditPaymentMethodOldB2BCredit;
}

export interface PaymentMethodOldB2BBank {
  bankCountry: string; // country in PaymentMethodModalAddress
  billingBankCity: string; // city in PaymentMethodModalAddress
  billingBankEmail: string; // email in PaymentMethodModalAddress
  billingBankLine1: string; // address in PaymentMethodModalAddress
  billingBankPostalCode: string; // zipCode in PaymentMethodModalAddress
  billingBankState: string; // state in PaymentMethodModalAddress
}

export interface AddPaymentMethodOldB2BBank extends PaymentMethodOldB2BBank {
  billingBankName: string; // firstName in PaymentMethodModalAddress
  billingBankSurname: string; // lastName in PaymentMethodModalAddress

  accountDescription: string; // description
  accountNumber: string; // accountNumber in PaymentMethodModalBA
  transitRoutingNumber: string; // transitNumber in PaymentMethodModalBA

  currencyCode: string; // currency from CurrencyFormat in slice
}

export interface EditPaymentMethodOldB2BBank extends Partial<PaymentMethodOldB2BBank> {
  subscriptionId: string;
  description?: string;
}

export interface PaymentMethodOldB2BCredit {
  ccCountry: string; // country in PaymentMethodModalAddress
  billingCity: string; // city in PaymentMethodModalAddress
  billingEmail: string; // email in PaymentMethodModalAddress
  billingLine1: string; // address in PaymentMethodModalAddress
  billingPostalCode: string; // zipCode in PaymentMethodModalAddress
  billingState: string; // state in PaymentMethodModalAddress

  description: string; // description in PaymentMethodModalCC
  expireMonth: string; // expirationMonth in PaymentMethodModalCC
  expireYear: string; // expirationYear in PaymentMethodModalCC
}

export interface AddPaymentMethodOldB2BCard extends PaymentMethodOldB2BCredit {
  billingName: string; // firstName in PaymentMethodModalAddress
  billingSurname: string; // lastName in PaymentMethodModalAddress

  cardNumber: string; // creditCardNumber in PaymentMethodModalCC
  cvv: string; // cvc in PaymentMethodModalCC

  currencyCode: string; // currency from CurrencyFormat in slice
}

export interface EditPaymentMethodOldB2BCredit extends Partial<PaymentMethodOldB2BCredit> {
  subscriptionId: string;
}

export interface GetPaymentMethodDetailRequest {
  qparams: {
    door: string;
  };
  payload: {
    type: "cardId" | "bankId";
    id: string;
  };
}

/////////////////////////////// DELETE METHOD FLOW ///////////////////////////////

export interface DeletePaymentMethodPayload {
  qparams: {
    door: string;
  };
  payload: {
    id: string;
    type: PaymentMethodSubscriptionType;
    callback?: (success?: boolean) => void;
  };
}

// slice
export interface SavePaymentMethodsPayload {
  paymentMethodsCC: PaymentMethod[];
  paymentMethodsACH: PaymentMethod[];
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// PAYMENT HISTORY ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

//////////////////////////////// HISTORY LIST

export interface PaymentHistoryList {
  paymentDescription?: string; // maps Paymentdescription
  processedDate?: Date; // maps Processeddate
  paymentAmount?: number; // maps Paymentamount
  discountAmount?: number; // maps Discountamount
  paymentMethod?: PaymentMethodLabel; // maps Paymentmethod // TODO: map directly to label key - receiving CC, BA (?)
  confirmationNumber: string; // maps Confirmationid
  username?: PaymentUsername; // maps Username
}

//////////////////////////////// HISTORY DETAILS

// TODO: verify w/ BFF swagger
export interface PaymentHistoryDetailRequestPayload {
  Confirmationid: string;
  Paymentdate: string; // format 20210431 // TODO: MI PREOCCUPA da thank you page
}

export interface PaymentHistoryDetail {
  paymentDescription?: string; // maps Paymentdescription
  processedDate?: Date; // maps Processeddate
  paidAmount?: number; // maps Paidamount
  detailItems?: PaymentHistoryDetailItem[]; // maps PaymentDetailItems
}

export interface PaymentHistoryDetailItem {
  documentTypeLabel?: string; // maps Documenttype into label
  documentNumber: string; // maps Documentid
  originalDocNumber?: string; // maps DocumentidAfs2
  documentAmount?: number; // maps Docamount
  paidAmount?: number; // maps Paidamount
  documentType?: string; // maps Documenttype
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// DOCUMENT HISTORY ///////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export interface PaymentDocumentHistory {
  documentTypeLabel?: string; // maps Documenttype into label
  documentNumber: string; // maps Documentid
  originalDocNumber?: string; // maps DocumentidAfs2
  paymentDate?: Date; // maps Paymentdate
  dueDate?: Date; // maps DueDate
  documentAmount?: number; // maps Docamount
  paidAmount?: number; // maps Paidamount
  status?: PaymentStatusLabel | undefined; // maps Status // TODO: map directly into label key (receiving A, B, C from API)
  paymentMethod?: PaymentMethodLabel | undefined; // maps Paymentmethod // TODO: map directly to label key - receiving CC, BA (?)
  confirmationId?: string; // maps Confirmationid
  userType?: string; // maps UserType // Asks informations about it there isn't in functional analysis
  username?: PaymentUsername; // maps Username
  openTextDownload?: OpenTextDownloadId; // maps Opentextid e Archid
  customerCode?: string; // maps Customercode // Asks informations about it there isn't in functional analysis
  documentType?: string; // maps Documenttype
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// STATEMENT ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export interface PaymentStatement {
  documentTypeLabel?: string; // maps Documenttype into label
  statementId: string; // Customercode+Statementdate b/c nothing else looks like a primary key
  statementDate?: Date; // maps Statementdate
  customerCode?: string; // maps Customercode
  customerName?: string; // maps Customerdesc
  openTextDownload?: OpenTextDownloadId; // maps Opentextid e Archid
  documentType?: string; // maps Documenttype
}
