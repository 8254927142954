import React from "react";
import ContentLoader from "react-content-loader";

interface Props {
  height: number;
  radius?: number;
  width?: number;
}

const LoaderElementCircle = ({ height, radius = 0, width }: Props): JSX.Element => {
  const r = radius || height / 2;

  return (
    <ContentLoader
      speed={2}
      width={width ? width : "100%"}
      height={height}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle cx={r} cy={r} r={r} />
    </ContentLoader>
  );
};

export default LoaderElementCircle;
