import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import useCanRender from "../../../../hooks/useCanRender";

import usePreviewMode from "../../../../hooks/usePreviewMode";
import useTranslation from "../../../../hooks/useTranslation";
import { getPagePath } from "../../../../routing/routesUtils";
import { selectCountQuantity } from "../../../../store/cart/cartSlice";
import CustomText from "../../../styled-UI/CustomText";
import ConditionalRender from "../../../widgets/ConditionalRender";
import AccountMenu from "../account-menu/AccountMenu";

import { ReactComponent as AccountIcon } from "../../../../assets/icons/account-icon.svg";
import { ReactComponent as BagIcon } from "../../../../assets/icons/bag-2-icon.svg";
import { getSectionsAlerts } from "../../../../store/accounting/accountingSagas";
import {
  selectNotificationAlerts,
  selectNotificationsAlertsStatus,
} from "../../../../store/accounting/accountingSlice";
import { getPictureAccountMenu } from "../../../../store/user/userSagas";
import {
  selectCanBuyModelsGlobal,
  selectCanBuySparepartsGlobal,
  selectEssilorBadgePicture,
} from "../../../../store/user/userSlice";
import { allowedServicesIds } from "../../../../utils/accountingUtils";
import Loader from "../../../styled-UI/Loader";

interface BagProps {
  count?: number | null;
}

const BagButtonIcon = ({ count }: BagProps): JSX.Element => {
  const canRender = useCanRender();
  return (
    <ButtonIconContainer className="button-dark">
      <BagIcon />
      {canRender("CART") && !!count && <span>{count}</span>}
    </ButtonIconContainer>
  );
};

interface Props {
  expanded: boolean;
}

const MainNavbar = ({ expanded }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const { isPreview } = usePreviewMode();
  const cartQuantity = useSelector(selectCountQuantity);
  const totalNumberNotifications = useSelector(selectNotificationAlerts);
  const canBuyModelsGlobal = useSelector(selectCanBuyModelsGlobal);
  const canBuySparesparepartsGlobal = useSelector(selectCanBuySparepartsGlobal);
  const [canBuyModelsAndSpareparts, setCanBuyModelsAndSpareparts] = useState<boolean>(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const essilorBadgePicture = useSelector(selectEssilorBadgePicture);
  const dispatch = useDispatch();
  const notificationsStatus = useSelector(selectNotificationsAlertsStatus);

  useEffect(() => {
    if (canBuyModelsGlobal || canBuySparesparepartsGlobal) {
      setCanBuyModelsAndSpareparts(canBuyModelsGlobal || canBuySparesparepartsGlobal);
    }
  }, [canBuyModelsGlobal, canBuySparesparepartsGlobal]);

  useEffect(() => {
    if (showUserMenu) {
      dispatch(getSectionsAlerts(allowedServicesIds)); // get sections counts
      !essilorBadgePicture && dispatch(getPictureAccountMenu());
    }
  }, [showUserMenu]);

  return (
    <>
      <HideableButtons hide={expanded}>
        {/* ORDER BUTTON */}
        <ConditionalRender privilege="ORDER_HISTORY">
          <TextButtonContainer
            to={getPagePath("/account/order-history")}
            data-element-id="MainNav_Orders"
          >
            <CustomText as="span" fontSizePx={13} color="primary" font="font-bold" uppercase>
              {translateLabel("ORDERS_SECTION")}
            </CustomText>
          </TextButtonContainer>
        </ConditionalRender>
        {/* end ORDER BUTTON */}
      </HideableButtons>

      {/* ACCOUNT BUTTON */}
      <AccountContainer isPreview={isPreview}>
        <div style={{ padding: "1rem 0" }} onClick={() => setShowUserMenu(!showUserMenu)}>
          <ButtonIconContainer>
            <AccountIcon />
            {notificationsStatus === "LOADING" ? (
              <LoaderContainer>
                <Loader sizePx={12} />
              </LoaderContainer>
            ) : (
              totalNumberNotifications > 0 && <span />
            )}
          </ButtonIconContainer>
        </div>

        {showUserMenu && <AccountMenu showAccountMenu={setShowUserMenu} />}
      </AccountContainer>
      {/* end ACCOUNT BUTTON */}

      {/* CART BUTTON */}
      {canBuyModelsAndSpareparts && (
        <ConditionalRender privilege="CART">
          {isPreview ? (
            <CartContainer data-element-id="MainNav_Bag">
              <BagButtonIcon count={cartQuantity?.count} />
            </CartContainer>
          ) : (
            <CartLink to={getPagePath("/cart")} data-element-id="MainNav_Bag">
              <BagButtonIcon count={cartQuantity?.count} />
            </CartLink>
          )}
        </ConditionalRender>
      )}
      {/* end CART BUTTON */}
    </>
  );
};

const HideableButtons = styled.div<{ hide: boolean }>`
  display: flex;

  @media ${(props) => props.theme.queries.minLaptop} {
    display: ${(props) => (props.hide ? "none" : "flex")};
  }
`;

const TextButtonContainer = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  flex-shrink: 0;

  &:hover {
    position: relative;

    :after {
      position: absolute;
      bottom: 1rem;
      border-bottom: solid 2px ${(props) => props.theme.palette.primary};
      content: "";
      width: 70%;
    }
  }
`;

const AccountContainer = styled.div<AccountContainerProps>`
  width: ${(props) => props.theme.headerHeightRem + "rem"};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    position: relative;

    :after {
      position: absolute;
      bottom: 1rem;
      border-bottom: solid 2px ${(props) => props.theme.palette.primary};
      content: "";
      width: 40%;
    }
  }
`;

const CartLink = styled(Link)`
  width: ${(props) => props.theme.headerHeightRem + "rem"};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.primary};
  cursor: pointer;

  &:hover {
    div svg {
      fill: ${(props) => props.theme.palette.primary};
    }
    div span {
      background-color: ${(props) => props.theme.palette.primary};
      border: solid 0.25rem ${(props) => props.theme.palette.white};
      color: ${(props) => props.theme.palette.white};
    }
    background-color: ${(props) => props.theme.palette.white};
  }
`;

const CartContainer = styled.div`
  width: ${(props) => props.theme.headerHeightRem + "rem"};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.primary};
  cursor: pointer;
`;

interface AccountContainerProps {
  isPreview: boolean;
}

const LoaderContainer = styled.div`
  position: absolute;
  top: -0.4rem;
  right: -0.125rem;
  background-color: ${(props) => props.theme.palette.white};
`;

const ButtonIconContainer = styled.div`
  position: relative;
  flex-shrink: 0;

  svg {
    fill: ${(props) => props.theme.palette.primary};
    height: 1.2rem;
    width: 1.2rem;
  }

  span {
    position: absolute;
    background-color: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.white};
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    border: solid 0.25rem ${(props) => props.theme.palette.white};
    top: -0.5rem;
    right: -0.325rem;
  }

  &.button-dark {
    svg {
      fill: ${(props) => props.theme.palette.white};
    }

    span {
      background-color: ${(props) => props.theme.palette.white};
      color: ${(props) => props.theme.palette.primary};
      border: solid 0.25rem ${(props) => props.theme.palette.primary};
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.8rem;
      width: 1.8rem;
      top: -1rem;
      right: -1.125rem;
      font-family: ${(props) => props.theme.fonts.fontHeavy};
      font-size: 0.6rem;
    }
  }
`;

const TextButtonChatContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 1rem;
  flex-shrink: 0;
`;

export default MainNavbar;
