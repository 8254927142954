import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import { RootState } from "../storeConfig";
import {
  ApproveRejectLoading,
  CarouselData,
  LeonardoState,
  SubuserApprovalLists,
} from "./leonardoInterface";

export const sliceName = "leonardo";

const initialState: LeonardoState = {
  subuserApproval: null,
  subuserApprovalStatus: "IDLE",
  //leonardo carousel
  courses: null,
  getCoursesStatus: "IDLE",
  updateSubuserStatus: null,
  approveError: null,
};

export const leonardoSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSubuserApproval: (state, action: PayloadAction<SubuserApprovalLists | null>) => {
      state.subuserApproval = action.payload;
    },

    setSubuserApprovalStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.subuserApprovalStatus = action.payload;
    },

    setUpdateSubuserStatus: (state, action: PayloadAction<ApproveRejectLoading>) => {
      state.updateSubuserStatus = action.payload;
    },

    setApproveError: (state, action: PayloadAction<string | null>) => {
      state.approveError = action.payload;
    },

    //leonardo carousel
    saveGetCoursesStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getCoursesStatus = action.payload;
    },
    saveCourses: (state, action: PayloadAction<CarouselData | null>) => {
      state.courses = action.payload;
    },

    resetState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  setSubuserApproval,
  setSubuserApprovalStatus,
  saveGetCoursesStatus,
  setUpdateSubuserStatus,
  saveCourses,
  setApproveError,
  resetState,
} = leonardoSlice.actions;

export const selectSubuserApproval = (state: RootState): SubuserApprovalLists | null => {
  return state.leonardo.subuserApproval;
};

export const selectSubuserApprovalStatus = (state: RootState): RequestStatus => {
  return state.leonardo.subuserApprovalStatus;
};

export const selectUpdateSubuserStatus = (state: RootState): ApproveRejectLoading => {
  return state.leonardo.updateSubuserStatus;
};

export const selectApproveError = (state: RootState): string | null => {
  return state.leonardo.approveError;
};

//leonardo carousel
export const selectGetCourses = (state: RootState): CarouselData | null => {
  return state.leonardo.courses;
};
export const selectGetCoursesStatus = (state: RootState): RequestStatus => {
  return state.leonardo.getCoursesStatus;
};

export default leonardoSlice.reducer;
