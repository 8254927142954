import React from "react";
import styled from "styled-components/macro";
import LoaderElement from "../LoaderElement";

const LoaderSRPopup = (): JSX.Element => (
  <Column>
    <Box>
      <LoaderElement height={30} />
    </Box>
    <Box>
      <LoaderElement height={200} />
    </Box>
  </Column>
);

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  margin: 1rem 0rem 1rem 0rem;
`;

export default LoaderSRPopup;
