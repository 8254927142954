import { BFFApiDomain } from "../serviceUtils";

export const getDocumentsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/contents/documents";

export const getPublicDocumentsUrl =
  BFFApiDomain + "/fo-bff/api/pub/v1/{locale}/contents/documents";

export const getLegalDocumentsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/weagree/documents/";

export const getUnsignedLegalDocumentsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/weagree/documents/unsigned";

export const saveUserActionUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/weagree/documents/saveUserAction";

export const downloadLegalDocumentsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/weagree/documents/download";

export const getCMSContentsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/weagree/contents";
