import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LogoPrimary } from "../../assets/images/logo-header.svg";
import { ReactComponent as LogoWhite } from "../../assets/images/logo-footer.svg";

import styled from "styled-components/macro";
import { getPagePath, getPublicPagePath } from "../../routing/routesUtils";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLogged } from "../../store/user/userSlice";
import { selectIsSearchOpen, setIsSearchOpen } from "../../store/search/searchSlice";

interface Props {
  color?: "blue" | "white";
  heightPx?: number;
  isLink?: boolean;
  maxWidthRem?: number;
}

const Logo = ({
  color = "blue",
  heightPx = 23,
  isLink = true,
  maxWidthRem = 14,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const isLogged = useSelector(selectIsLogged);
  const isSearchOpen = useSelector(selectIsSearchOpen);

  const handleChangeLink = () => {
    if (isSearchOpen) dispatch(setIsSearchOpen(false));
  };

  const Content = () => (
    <IconContainer heightPx={heightPx} isLink={isLink} maxWidth={maxWidthRem}>
      {color === "blue" ? <LogoPrimary /> : <LogoWhite />}
    </IconContainer>
  );

  return isLink ? (
    <Link
      to={isLogged ? getPagePath("/homepage") : getPublicPagePath("/login")}
      data-element-id="MainNav_Logo"
      onClick={handleChangeLink}
    >
      <Content />
    </Link>
  ) : (
    <Content />
  );
};

export default Logo;

const IconContainer = styled.span<{ heightPx: number; isLink: boolean; maxWidth: number }>`
  cursor: ${(props) => (props.isLink ? "pointer" : "default")};
  svg {
    height: ${(props) =>
      props.heightPx ? `${props.heightPx / props.theme.baseFontSize}rem` : "23px"};
    width: auto;
    max-width: ${(props) => props.maxWidth + "rem"};
  }
`;
