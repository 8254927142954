import React from "react";
import styled from "styled-components/macro";
import Loader from "./Loader";

interface Props {
  zindex?: number;
}

const LoaderOverlay = ({ zindex = 2000 }: Props): JSX.Element => {
  return (
    <BackgroundLayer zindex={zindex}>
      <Container>
        <Loader sizePx={40} />
      </Container>
    </BackgroundLayer>
  );
};

const BackgroundLayer = styled.div<{ zindex: number }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: ${(props) => props.zindex};
  padding-bottom: 2rem;
`;

const Container = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
`;

export default LoaderOverlay;
