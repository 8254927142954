import React from "react";
import styled from "styled-components/macro";
import ActionButtonsLoader from "./loader-tile/ActionButtonsLoader";
import { PricesLoader } from "./loader-tile/PricesLoader";
import SeeAllLoader from "./loader-tile/SeeAllLoader";
import TileHeaderLoader from "./loader-tile/TileHeaderLoader";
import { TileTagsLoader } from "./loader-tile/TileTagsLoader";

import LoaderElement from "./LoaderElement";
import LoaderMultidoorSize from "./LoaderMultidoorSize";

export type TileStyle = "default" | "horizontal";

interface CommonProps {
  hideBackground?: boolean;
  hideTags?: boolean;
  hidePrices?: boolean;
  hideActionButtons?: boolean;
  hideSeeAll?: boolean;
  unsetHeight?: boolean;
  showAddSize?: boolean;
  heightPx?: number;
}

interface Props extends CommonProps {
  style?: TileStyle;
}

export const VerticalTile = ({
  hideBackground,
  hideTags,
  hidePrices,
  hideSeeAll,
  hideActionButtons,
  unsetHeight,
  showAddSize,
  heightPx,
}: CommonProps): JSX.Element => {
  return (
    <Tile
      className={"default"}
      hideBackground={hideBackground}
      unsetHeight={unsetHeight}
      heightPx={heightPx}
    >
      <TileHeaderLoader style="default" />

      <LoaderElement height={130} />

      {!hideTags && <TileTagsLoader style="default" unsetHeight={unsetHeight} />}
      {!hideSeeAll && <SeeAllLoader style="default" />}

      {!hidePrices && <PricesLoader style="default" />}

      {showAddSize && <LoaderMultidoorSize expandedPadding />}

      {!hideActionButtons && <ActionButtonsLoader style="default" />}
    </Tile>
  );
};

export const HorizontalTile = ({
  hideBackground,
  hideTags,
  hidePrices,
  hideSeeAll,
  hideActionButtons,
  unsetHeight,
  showAddSize,
  heightPx,
}: CommonProps): JSX.Element => {
  return (
    <Tile
      className={"horizontal"}
      hideBackground={hideBackground}
      unsetHeight={unsetHeight}
      heightPx={heightPx}
    >
      <div className="main-content">
        <LoaderElement height={90} width={176} />
        <LoaderElement height={50} width={100} />

        <TileHeaderLoader style="horizontal" />

        <HorizontalTileColor>
          <LoaderElement height={20} width={50} />
          <LoaderElement height={20} width={70} />
        </HorizontalTileColor>

        {!hidePrices && <PricesLoader style="horizontal" />}

        {!hideActionButtons && <ActionButtonsLoader style="horizontal" />}

        {showAddSize && <LoaderMultidoorSize expandedPadding />}
      </div>

      <div className="footer">
        {!hideTags && <TileTagsLoader style="horizontal" unsetHeight={unsetHeight} />}
        {!hideSeeAll && <SeeAllLoader style="horizontal" />}
      </div>
    </Tile>
  );
};
// LoaderMultidoorSize;

const LoaderTileWithBackground = ({
  style = "default",
  hideBackground = false,
  hideTags = false,
  hidePrices = false,
  hideActionButtons = false,
  hideSeeAll = false,
  unsetHeight = false,
  showAddSize,
  heightPx,
}: Props): JSX.Element => {
  if (style === "default")
    return (
      <VerticalTile
        hideBackground={hideBackground}
        hideTags={hideTags}
        hidePrices={hidePrices}
        hideActionButtons={hideActionButtons}
        hideSeeAll={hideSeeAll}
        unsetHeight={unsetHeight}
        showAddSize={showAddSize}
        heightPx={heightPx}
      />
    );

  return (
    <HorizontalTile
      hideBackground={hideBackground}
      hideTags={hideTags}
      hidePrices={hidePrices}
      hideActionButtons={hideActionButtons}
      hideSeeAll={hideSeeAll}
      unsetHeight={unsetHeight}
      showAddSize={showAddSize}
      heightPx={heightPx}
    />
  );
};

const Tile = styled.div<{ hideBackground?: boolean; unsetHeight?: boolean; heightPx?: number }>`
  display: flex;
  background-color: ${(prop) => (prop.hideBackground ? "transparent" : prop.theme.palette.white)};
  flex-shrink: 0;

  &.default {
    padding: 2rem;
    min-height: ${(prop) => (prop.unsetHeight ? "unset" : prop.theme.tileHeight)};
    ${(prop) => prop.heightPx && `height: ${prop.heightPx}px; min-height: unset;`};
    width: 20.31rem;

    flex-direction: column;
    row-gap: 1rem;
  }

  &.horizontal {
    min-height: ${(prop) => (prop.unsetHeight ? "unset" : "15rem")};
    ${(prop) => prop.heightPx && `height: ${prop.heightPx}px; min-height: unset;`};
    width: 100%;
    flex-direction: column;

    & > .main-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      column-gap: 1.25rem;
      padding: 1rem 2rem 1rem 2rem;
      border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
    }

    & > .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0rem 2rem 0rem 2rem;
    }
  }
`;

const HorizontalTileColor = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  width: 5.3125rem;
`;

export default LoaderTileWithBackground;

export const MemoizedLoaderTileWithBackgroud = React.memo(LoaderTileWithBackground);
