import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useStickyBar from "../../../../hooks/useStickyBarLp";
import { selectStickyBarType, setStickyBarType } from "../../../../store/store/storeSlice";
import LinkButton from "../../../styled-UI/LinkButton";
import ScrollToButton from "../../ScrollToButton";
import LpButtonGroup from "../landing-page-button-group/LpButtonGroup";
import { FontFamilies } from "../../../../store/store/storeInterfaces";

const BUTTONS_VALUE = [
  { value: "ALL", label: "LP_ALL" },
  { value: "SUNGLASSES", label: "LP_SUNGLASSES" },
  { value: "EYEGLASSES", label: "LP_EYEGLASSES" },
];

interface Props {
  customFont?: FontFamilies | null;
}

const LpStickyMenuLeft = ({ customFont = null }: Props): JSX.Element => {
  const { navbar } = useStickyBar("Lp-titletext-h2");
  const stickyBarType = useSelector(selectStickyBarType);
  const dispatch = useDispatch();

  const handleClick = (value: string) => {
    dispatch(setStickyBarType(value));
  };

  return (
    <StickyMenu>
      {navbar && navbar.length > 0 && (
        <MenuItemsLeft>
          {navbar.map((_) => (
            <ScrollToButton key={_.id} toId={_.id}>
              <LinkButton as="div" underline={false} customFont={customFont}>
                {_.content.title}
              </LinkButton>
            </ScrollToButton>
          ))}
        </MenuItemsLeft>
      )}
      <MenuItemsRight>
        <LpButtonGroup
          variant="medium-gray"
          buttonsLabels={BUTTONS_VALUE}
          selected={stickyBarType}
          handleClick={handleClick}
          customFont={customFont}
        />
      </MenuItemsRight>
    </StickyMenu>
  );
};
const StickyMenu = styled.div`
  width: 100%;
  display: flex;
  gap: 13.6875rem;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.white};
  padding: 1.5rem 1.125rem 1.5rem 2.4375rem;
`;
const MenuItemsLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 1.75rem;
`;
const MenuItemsRight = styled.div``;
export default LpStickyMenuLeft;
