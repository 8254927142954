import { SingleRoute } from "./routesInterfaces";
import { lazy } from "react";

// main bundle
const CartPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackMode: "eager" */
      "../components/pages/cart/Cart"
    )
);
const HomepagePrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackMode: "eager" */
      "../components/pages/homepage/HomePage"
    )
);

// adt bundle
const ADTLandingPagePrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "adt" */
      "../components/pages/adt-landing-page/ADTLandingPage"
    )
);

const ADTProductRequestPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "adt" */
      "../components/pages/adt-request-image/ADTProductRequest"
    )
);

// adt pdp bundle
const ADTPDPPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "adtPDP" */
      "../components/pages/adt-pdp/ADTPDP"
    )
);

// adt plp bundle
const ADTPLPPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "adtPLP" */
      "../components/pages/adt-plp/AdtPlp"
    )
);

const SearchResultsPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "general" */
      "../components/pages/search-results/SearchResults"
    )
);

// general bundle
const AcceptanceAgreementPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "general" */
      "../components/pages/acceptance-agreement/AcceptanceAgreement"
    )
);

const DigitalScreenPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "general" */
      "../components/pages/digital-window/DigitalScreen"
    )
);

const FaqPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "general" */
      "../components/pages/faq/Faq"
    )
);
const PLPRecommendedPrefetch = lazy(
  //da mettere nel main?
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "general" */
      "../components/pages/for-you/PLPRecommended"
    )
);

const NewsArticlePrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "general" */
      "../components/pages/news-article/NewsArticle"
    )
);

const SimilarProductPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "general" */
      "../components/pages/similar-product/SimilarProduct"
    )
);

const LandingPagePrefetch = lazy(
  //da mettere nel main?
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "general" */
      "../components/pages/landing-page/LandingPage"
    )
);
const NewsPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "general" */
      "../components/pages/news-landing-page/NewsPage"
    )
);

// prescription bundle
const DraftOrdersEssilorPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "prescription" */
      "../components/pages/draft-orders-essilor/DraftOrdersEssilor"
    )
);

const DigitalCatalogPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "prescription" */
      "../components/pages/digital-catalog/DigitalCatalog"
    )
);
const RxPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "prescription" */
      "../components/pages/rx-prescription/RxPrescription"
    )
);

// orderConfirmationEssilor bundle
const OrderConfirmationEssilorPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "orderConfirmationEssilor" */
      "../components/pages/order-confirmation/order-confirmation-door/order-confirmation-essilor/OrderConfirmationEssilor"
    )
);

// orderConfirmation bundle
const OrderConfirmationPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "orderConfirmation" */
      "../components/pages/order-confirmation/OrderConfirmation"
    )
);

// thankYouPage bundle
const ThankYouPagePrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "thankYouPage" */
      "../components/pages/thank-you-page/ThankYouPage"
    )
);

// account bundle
const MyAccountPagePrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "account" */
      "../components/pages/my-account/MyAccountPage"
    )
);

const PaymentPolicyPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "account" */
      "../components/pages/payment/payment-policy/PaymentPolicy"
    )
);
const OrderToBeApprovedSimulationPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "account" */
      "../components/pages/my-account/order-to-be-approved/order-to-be-approved-simulation/OrderToBeApprovedSimulation"
    )
);
const OrderToBeApprovedTYPPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "account" */
      "../components/pages/my-account/order-to-be-approved/order-to-be-approved-typ/OrderToBeApprovedTYP"
    )
);

// pdp bundle
const PDPPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "PDP" */
      "../components/pages/PDP/PDP"
    )
);

// plp bundle
const PLPStarsPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "PLP" */
      "../components/pages/PLP-stars/PLPStars"
    )
);

const PLPPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "PLP" */
      "../components/pages/PLP/PLP"
    )
);

const PrePLPPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "PLP" */
      "../components/pages/pre-PLP/PrePLP"
    )
);

// service bundle
const SmartShopperPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "service" */
      "../components/pages/smart-shopper/SmartShopper"
    )
);

const POSSourceFilePrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "service" */
      "../components/pages/POS-source-file/POSSourceFile"
    )
);

const TransportDocumentsPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "service" */
      "../components/pages/transport-documents/TransportDocuments"
    )
);

const ItemMasterDataPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "service" */
      "../components/pages/item-master-data/ItemMasterData"
    )
);

const PriceListPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "service" */
      "../components/pages/price-list/PriceList"
    )
);

// aftersale bundle
const SparePartsPDPPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "aftersale" */
      "../components/pages/spare-parts-PDP/SparePartsPDP"
    )
);
const SparePartsConversationalPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "aftersale" */
      "../components/pages/spare-parts-conversational/SparePartsConversational"
    )
);
const PLPSparepartsPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "aftersale" */
      "../components/pages/PLPAftersales/PLPSpareparts"
    )
);

const PLPWarrantyPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "aftersale" */
      "../components/pages/PLPAftersales/PLPWarranty"
    )
);

const WarrantyConversationalPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "aftersale" */
      "../components/pages/warranty-conversational/WarrantyConversational"
    )
);
const WarrantyWizardPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "aftersale" */
      "../components/pages/warranty-wizard/WarrantyWizard"
    )
);

const AlternativeProductPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "aftersale" */
      "../components/pages/alternative-product/AlternativeProduct"
    )
);

const PlpCompatibleWithAccessoryPrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "aftersale" */
      "../components/pages/Plp-compatible-with/PlpCompatibleWithAccessory"
    )
);
const PlpCompatibleWithFramePrefetch = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "aftersale" */
      "../components/pages/Plp-compatible-with/PlpCompatibleWithFrame"
    )
);

export const authRoutes: SingleRoute[] = [
  {
    path: "/:storeIdentifier/:locale/account/legal-documents",
    breadCrumbPath: "account/legal-documents",
    name: "LEGAL_DOCUMENTS_TITLE",
    component: MyAccountPagePrefetch,
    privilege: "WE_AGREE",
    isNotSubuser: true,
    groupId: "ARA",
    externalReference: "pages.myaccount.legalDocuments",
  },
  {
    path: "/:storeIdentifier/:locale/account/manage-subuser/:id",
    breadCrumbPath: "/account/manage-subuser/:id",
    name: "MANAGE_USER_TITLE",
    privilege: "USER_MANAGEMENT_SECTION",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/warranty/:id",
    breadCrumbPath: "/account/warranty/:id",
    name: "WARRANTY_TITLE",
    privilege: "AFTER_SALES_REQUEST",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/service-request",
    breadCrumbPath: "/account/services-request",
    name: "SERVICE_REQUEST_TITLE",
    privilege: "SERVICE_REQUEST_PAGE",
    externalReference: "pages.myaccount.serviceRequest",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/notifications",
    breadCrumbPath: "/account/notifications",
    name: "NOTIFICATIONS_TITLE",
    component: MyAccountPagePrefetch,
    groupId: "MESSAGES",
    externalReference: "pages.myaccount.notifications",
  },
  {
    path: "/:storeIdentifier/:locale/account/notifications-preferences",
    breadCrumbPath: "/account/notifications-preferences",
    name: "NOTIFICATIONS_TITLE",
    component: MyAccountPagePrefetch,
    groupId: "MESSAGES",
  },
  {
    path: "/:storeIdentifier/:locale/account/availability",
    breadCrumbPath: "/account/availability",
    name: "GREEN_IS_BACK_TITLE",
    privilege: "GREEN_IS_BACK",
    component: MyAccountPagePrefetch,
    groupId: "MESSAGES",
    externalReference: "pages.myaccount.availability",
  },
  {
    path: "/:storeIdentifier/:locale/account/order-upload",
    breadCrumbPath: "/account/order-upload",
    name: "ORDER_UPLOAD_TITLE",
    privilege: "ORDER_CREATION",
    component: MyAccountPagePrefetch,
    groupId: "ORDER_DETAIL",
    externalReference: "pages.myaccount.orderUpload",
  },
  {
    path: "/:storeIdentifier/:locale/account/order-history/:id",
    breadCrumbPath: "/account/order-history/:id",
    name: "ORDER_HISTORY_TITLE",
    privilege: "ORDER_HISTORY",
    component: MyAccountPagePrefetch,
    groupId: "ORDER_DETAIL",
    externalReference: "pages.myaccount.orderDetails",
  },
  {
    path: "/:storeIdentifier/:locale/account/order-history/",
    breadCrumbPath: "/account/order-history",
    name: "ORDER_HISTORY_TITLE",
    privilege: "ORDER_HISTORY",
    externalReference: "pages.myaccount.orderHistory",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/orders-to-be-approved/thank-you-page/",
    breadCrumbPath: "/account/orders-to-be-approved/thank-you-page",
    name: "ORDERS_TO_BE_APPROVED_TYP_TITLE",
    privilege: "CAN_APPROVE_ORDERS",
    component: OrderToBeApprovedTYPPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/orders-to-be-approved/approval-simulation/",
    breadCrumbPath: "/account/orders-to-be-approved/approval-simulation",
    name: "ORDERS_TO_BE_APPROVED_SIMULATION_TITLE",
    privilege: "CAN_APPROVE_ORDERS",
    component: OrderToBeApprovedSimulationPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/orders-to-be-approved/:id",
    breadCrumbPath: "/account/orders-to-be-approved/:id",
    name: "ORDERS_TO_BE_APPROVED_TITLE",
    privilege: "CAN_APPROVE_ORDERS",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/orders-to-be-approved/",
    breadCrumbPath: "/account/orders-to-be-approved",
    name: "ORDERS_TO_BE_APPROVED_TITLE",
    privilege: "CAN_APPROVE_ORDERS",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/manage-subuser",
    breadCrumbPath: "/account/manage-subuser",
    name: "MANAGE_USER_TITLE",
    privilege: "USER_MANAGEMENT_SECTION",
    externalReference: "pages.myaccount.manageSubsers",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/warranty",
    breadCrumbPath: "/account/warranty",
    name: "WARRANTY_TITLE",
    privilege: "AFTER_SALES_REQUEST",
    externalReference: "pages.myaccount.warranty",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/credit",
    breadCrumbPath: "/account/credit-summary",
    name: "CREDIT_TITLE",
    privilege: "CREDIT_SECTION",
    groupId: "CREDIT",
    externalReference: "pages.myaccount.creditSummary",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/credit/:id",
    breadCrumbPath: "/account/credit/:id",
    name: "CREDIT_TITLE",
    privilege: "CREDIT_SECTION",
    groupId: "CREDIT",
    externalReference: "pages.myaccount.creditDetails",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/invoices",
    breadCrumbPath: "/account/invoices",
    name: "INVOICES_TITLE",
    privilege: "INVOICE_SECTION",
    externalReference: "pages.myaccount.invoicesSummary",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/invoices/download/:id",
    breadCrumbPath: "/account/invoices/:id",
    name: "INVOICES_TITLE",
    privilege: "INVOICE_SECTION",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/edit-password",
    breadCrumbPath: "/account/edit-password",
    name: "EDIT_PASSWORD_TITLE",
    externalReference: "pages.myaccount.editPassword",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/preferences",
    breadCrumbPath: "/account/preferences",
    name: "PREFERENCES_TITLE",
    privilege: "CUSTOMER_INFO",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/addresses",
    breadCrumbPath: "/account/addresses",
    name: "ADDRESSES_TITLE",
    privilege: "CUSTOMER_INFO",
    component: MyAccountPagePrefetch,
    externalReference: "pages.myaccount.profile",
  },
  {
    path: "/:storeIdentifier/:locale/account/approve-subuser",
    breadCrumbPath: "/account/approve-subuser",
    name: "APPROVE_SUBUSER_TITLE",
    privilege: "LEONARDO_SUBUSERS_APPROVAL",
    externalReference: "pages.myaccount.approveSubuser",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/contracts",
    breadCrumbPath: "/account/contracts",
    name: "POLICIES_PAGE_TITLE",
    externalReference: "pages.myaccount.contracts",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/multidoor",
    breadCrumbPath: "/account/multidoor",
    name: "MULTIDOOR_TITLE",
    privilege: "MULTIDOOR",
    externalReference: "pages.myaccount.multidoor",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/payment-typ",
    breadCrumbPath: "/account/payment-typ",
    name: "PAYMENT_MANAGEMENT_TYP_TITLE",
    privilege: "PAYMENT",
    externalReference: "pages.myaccount.payment",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/payment-management",
    breadCrumbPath: "/account/payment-management",
    name: "PAYMENT_MANAGEMENT_TITLE",
    privilege: "PAYMENT",
    externalReference: "pages.myaccount.payment",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/payment-method",
    breadCrumbPath: "/account/payment-method",
    name: "PAYMENT_METHOD_TITLE",
    privilege: "PAYMENT_FULL_MODE",
    isNotBOUser: true,
    externalReference: "pages.myaccount.payment",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/payment-history/:id",
    breadCrumbPath: "/account/payment-history/:id",
    name: "PAYMENT_HISTORY_DETAIL_TITLE",
    privilege: "PAYMENT",
    externalReference: "pages.myaccount.payment",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/payment-history",
    breadCrumbPath: "/account/payment-history",
    name: "PAYMENT_HISTORY_LIST_TITLE",
    privilege: "PAYMENT",
    externalReference: "pages.myaccount.payment",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/payment-document-history",
    breadCrumbPath: "/account/payment-document-history",
    name: "PAYMENT_DOCUMENT_HISTORY_TITLE",
    privilege: "PAYMENT",
    externalReference: "pages.myaccount.payment",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/payment-statement",
    breadCrumbPath: "/account/payment-statement",
    name: "PAYMENT_STATEMENT_TITLE",
    privilege: "PAYMENT",
    externalReference: "pages.myaccount.payment",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/payment-policy",
    breadCrumbPath: "/account/payment-policy",
    name: "PRIVACY_POLICY",
    privilege: "PAYMENT",
    component: PaymentPolicyPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account",
    breadCrumbPath: "/account",
    name: "ACCOUNT_SECTION",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/cart",
    breadCrumbPath: "/cart",
    name: "CART_TITLE",
    privilege: "CART",
    externalReference: "pages.cart",
    component: CartPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/cart/order-confirmation",
    breadCrumbPath: "/cart/order-confirmation",
    name: "ORDER_CONFIRMATION_TITLE",
    externalReference: "pages.orderConfirmation",
    component: OrderConfirmationPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/thank-you-page/:id",
    breadCrumbPath: "/thank-you-page/:id",
    name: "THANK_YOU_PAGE_TITLE",
    component: ThankYouPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/thank-you-page",
    breadCrumbPath: "/thank-you-page",
    name: "THANK_YOU_PAGE_TITLE",
    externalReference: "pages.thankYouPage",
    component: ThankYouPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/spare-parts/:productSlug",
    breadCrumbPath: "/spare-parts/:productSlug",
    name: "",
    privilege: "SPARE_PARTS_SECTION",
    groupId: "SPARE_PARTS",
    externalReference: "pages.spareparts.pdp",
    component: SparePartsPDPPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/spare-parts",
    breadCrumbPath: "/spare-parts",
    name: "SPAREPARTS_TITLE",
    privilege: "SPARE_PARTS_SECTION",
    externalReference: "pages.sparepartsConversational",
    component: SparePartsConversationalPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/rx-prescription",
    breadCrumbPath: "/rx-prescription",
    name: "RX_PRESCRIPTION_TITLE",
    privilege: "RX_WITH_ESSILOR",
    component: RxPrefetch,
    groupId: "RX_AFS1",
    externalReference: "pages.rxPrescription",
  },
  {
    path: "/:storeIdentifier/:locale/warranty",
    breadCrumbPath: "/warranty",
    name: "WARRANTY_TITLE",
    privilege: "AFTER_SALES_REQUEST",
    externalReference: "pages.warrantyConversational",
    component: WarrantyConversationalPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/request-warranty",
    breadCrumbPath: "/request-warranty",
    name: "REQUEST_OF_WARRANTY_TITLE",
    privilege: "AFTER_SALES_REQUEST",
    component: WarrantyWizardPrefetch,
    groupId: "WARRANTY",
    externalReference: "pages.warrantyWizard",
  },
  {
    path: "/:storeIdentifier/:locale/request-warranty/:uniqueID",
    breadCrumbPath: "/request-warranty/:uniqueID",
    name: "REQUEST_OF_WARRANTY_TITLE",
    privilege: "AFTER_SALES_REQUEST",
    component: WarrantyWizardPrefetch,
    groupId: "WARRANTY",
  },
  {
    path: "/:storeIdentifier/:locale/pos-source-file",
    breadCrumbPath: "/pos-source-file",
    name: "POS_SOURCE_FILE_TITLE",
    privilege: "DAT_CATALOGUE_DOCUMENT",
    externalReference: "pages.posSourceFile",
    component: POSSourceFilePrefetch,
  },

  {
    path: "/:storeIdentifier/:locale/price-list",
    breadCrumbPath: "/price-list",
    name: "PRICE_LIST_TITLE",
    privilege: "SUGGESTED_RETAIL_PRICE",
    onlySubuser: true,
    externalReference: "pages.priceList",
    component: PriceListPrefetch,
  },

  {
    path: "/:storeIdentifier/:locale/digital-screen",
    breadCrumbPath: "/digital-screen",
    name: "DIGITAL_SCREEN_TITLE",
    privilege: "DIGITAL_WINDOW",
    externalReference: "pages.DigitalScreen",
    component: DigitalScreenPrefetch,
  },

  {
    path: "/:storeIdentifier/:locale/transport-documents",
    breadCrumbPath: "/transport-documents",
    name: "TRANSPORT_DOCUMENTS_TITLE",
    privilege: "SHIP_DOCUMENTS",
    groupId: "DELIVERY_LOOKUP",
    externalReference: "pages.TransportDocuments",
    component: TransportDocumentsPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/search-results/:term",
    breadCrumbPath: "/search-results/:term",
    name: "SEARCH_RESULTS",
    externalReference: "pages.searchResults",
    component: SearchResultsPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/item-master-data",
    breadCrumbPath: "/item-master-data",
    name: "ITEMS_MASTER_DATA_TITLE",
    privilege: "ITEM_MASTER_DATA_DOCUMENT",
    externalReference: "pages.itemMasterData",
    component: ItemMasterDataPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/pdp/:productSlug",
    breadCrumbPath: "/pdp/:productSlug",
    name: "",
    privilege: "CATALOGUE",
    externalReference: "pages.pdp",
    component: PDPPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/plp/:productSlug",
    breadCrumbPath: "/plp/:productSlug",
    name: "",
    privilege: "CATALOGUE",
    externalReference: "pages.plp",
    component: PLPPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/plp-stars/:productSlug",
    breadCrumbPath: "/plp-stars/:productSlug",
    name: "",
    privilege: "STARS",
    externalReference: "pages.plpStars",
    component: PLPStarsPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/preplp/:brandName",
    breadCrumbPath: "/preplp/:brandName",
    name: "",
    privilege: "CATALOGUE",
    externalReference: "pages.preplp",
    component: PrePLPPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/spare-parts-catalogue",
    breadCrumbPath: "/spare-parts/spare-parts-catalogue",
    name: "SPARE_PARTS_CATALOGUE_TITLE",
    privilege: "SPARE_PARTS_SECTION",
    externalReference: "pages.spareparts.plp",
    component: PLPSparepartsPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/warranty-catalogue",
    breadCrumbPath: "/warranty/warranty-catalogue",
    name: "WARRANTY_CATALOGUE_TITLE",
    privilege: "AFTER_SALES_REQUEST",
    externalReference: "pages.plpWarranty",
    component: PLPWarrantyPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/news/:id",
    breadCrumbPath: "/news/:id",
    name: "",
    externalReference: "pages.newsArticle",
    component: NewsArticlePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/news",
    breadCrumbPath: "/news",
    name: "NEWS_TITLE",
    externalReference: "pages.news",
    component: NewsPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/faq",
    breadCrumbPath: "/faq",
    name: "FAQ",
    externalReference: "pages.faq",
    component: FaqPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/homepage",
    breadCrumbPath: "/homepage",
    name: "HOMEPAGE_TITLE",
    externalReference: "pages.homepage",
    component: HomepagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/contents-corporate/:pageName",
    breadCrumbPath: ":pageName",
    name: "",
    externalReference: "pages.landingpage",
    component: LandingPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/contenthub/contents",
    breadCrumbPath: "/contenthub/contents",
    name: "",
    externalReference: "pages.landingpage",
    component: LandingPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/contents-subsidiary/:pageName",
    breadCrumbPath: ":pageName",
    name: "",
    component: LandingPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/alternative-product/:productSlug",
    breadCrumbPath: "/alternative-product/:productSlug",
    name: "ALTERNATIVE_PRODUCT_TITLE",
    privilege: "CATALOGUE",
    externalReference: "pages.alternativeProduct",
    component: AlternativeProductPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/smart-shopper/staging-area",
    breadCrumbPath: "/smart-shopper/staging-area",
    name: "SMART_SHOPPER_STAGING_AREA",
    component: SmartShopperPrefetch,
    privilege: "SMART_SHOPPER_STAGING_AREA",
  },
  {
    path: "/:storeIdentifier/:locale/smart-shopper/devices",
    breadCrumbPath: "/smart-shopper/devices",
    name: "SMART_SHOPPER_DEVICES",
    component: SmartShopperPrefetch,
    privilege: "M4C",
  },
  {
    path: "/:storeIdentifier/:locale/smart-shopper/edit-pin",
    breadCrumbPath: "/smart-shopper/edit-pin",
    name: "SMART_SHOPPER_EDIT_PIN",
    component: SmartShopperPrefetch,
    privilege: "SMART_SHOPPER_QUICK_ORDER",
  },
  {
    path: "/:storeIdentifier/:locale/smart-shopper",
    breadCrumbPath: "/smart-shopper",
    name: "SMART_SHOPPER_TITLE",
    component: SmartShopperPrefetch,
    privilege: "M4C",
    externalReference: "pages.smartShopper",
  },
  {
    path: "/:storeIdentifier/:locale/acceptance-agreement",
    breadCrumbPath: "/acceptance-agreement",
    name: "ACCEPTANCE_AGREEMENT_TITLE",
    privilege: "WE_AGREE",
    externalReference: "pages.acceptanceAgreement",
    component: AcceptanceAgreementPrefetch,
  },

  {
    path: "/:storeIdentifier/:locale/plp-similar/:productSlug",
    breadCrumbPath: "/plp-similar/:productSlug",
    name: "PLP_SIMILAR",
    privilege: "CATALOGUE",
    externalReference: "pages.plpSimilarProducts",
    component: SimilarProductPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/for-you",
    breadCrumbPath: "/for-you",
    name: "RECOMMENDATION_PAGE_TITLE",
    privilege: "CATALOGUE",
    externalReference: "pages.plpRecommended",
    component: PLPRecommendedPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/plp-compatible-with-frames/:productSlug",
    breadCrumbPath: "/plp-compatible/:productSlug",
    name: "PLP_COMPATIBLE",
    privilege: "CATALOGUE",
    externalReference: "pages.plpCompatibleWithFrame",
    component: PlpCompatibleWithFramePrefetch,
  },

  {
    path: "/:storeIdentifier/:locale/plp-compatible-with-accessory/:productSlug",
    breadCrumbPath: "/plp-compatible/:productSlug",
    name: "PLP_COMPATIBLE",
    privilege: "CATALOGUE",
    externalReference: "pages.plpCompatibleWithAccessory",
    component: PlpCompatibleWithAccessoryPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/traffic-counter",
    breadCrumbPath: "/account/traffic-counter",
    name: "TRAFFIC_COUNTER_TITLE",
    privilege: "TRAFFIC_COUNTER",
    externalReference: "pages.trafficCounter",
    component: MyAccountPagePrefetch,
  },

  /////////////////////////////////////// ADT
  {
    path: "/:storeIdentifier/:locale/asset-downloads/product-images",
    breadCrumbPath: "/asset-downloads/product-images",
    name: "ADT_PRODUCT_IMAGES",
    privilege: "ADT_PRODUCT_IMAGE",
    externalReference: "pages.adt.productImages",
    component: ADTProductRequestPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/asset-downloads",
    breadCrumbPath: "/asset-downloads",
    name: "ADT_LANDING_PAGE",
    privilege: "ADT_CATEGORY",
    externalReference: "pages.adt.ladingPage",
    component: ADTLandingPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/asset-downloads/asset-list/:categorySlug",
    breadCrumbPath: "/asset-downloads/asset-list/:categorySlug",
    name: "ADT_PLP_CAMPAIGN_MATERIALS",
    privilege: "ADT_CATEGORY",
    externalReference: "pages.adt.PLP",
    component: ADTPLPPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/asset-downloads/asset-detail/:productSlug",
    breadCrumbPath: "/asset-downloads/asset-detail/:productSlug",
    name: "ADT_PDP_CAMPAIGN_MATERIALS",
    privilege: "ADT_CATEGORY",
    externalReference: "pages.adt.PDP",
    component: ADTPDPPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/saved-assets",
    breadCrumbPath: "/account/saved-assets",
    name: "ADT_SAVED_ASSETS_TITLE",
    privilege: "ADT_CATEGORY",
    externalReference: "pages.myaccount.adtSaved",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/downloaded-assets",
    breadCrumbPath: "/account/downloaded-assets",
    name: "ADT_DOWNLOADED_ASSETS_TITLE",
    privilege: "ADT",
    externalReference: "pages.myaccount.adtDownloaded",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/account/asset-platforms",
    breadCrumbPath: "/account/asset-platforms",
    name: "ADT_ASSET_PLATFORMS",
    privilege: "ADT_CATEGORY",
    externalReference: "pages.myaccount.assetPlatforms",
    component: MyAccountPagePrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/essilor-draft-orders",
    breadCrumbPath: "/draft-orders",
    name: "DRAFT_ORDERS_ESSILOR",
    privilege: "RX_WITH_ESSILOR",
    externalReference: "pages.essilor.draftOrders",
    component: DraftOrdersEssilorPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/digital-catalog",
    breadCrumbPath: "/digital-catalog",
    name: "DIGITAL_CATALOG",
    privilege: "RX_WITH_ESSILOR",
    externalReference: "pages.essilor.digitalCatalog",
    component: DigitalCatalogPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/digital-catalog/:brandId",
    breadCrumbPath: "/digital-catalog",
    name: "DIGITAL_CATALOG",
    privilege: "RX_WITH_ESSILOR",
    component: DigitalCatalogPrefetch,
  },
  {
    path: "/:storeIdentifier/:locale/cart/essilor-order-confirmation",
    breadCrumbPath: "/one-portal",
    name: "ONE_PORTAL",
    privilege: "RX_WITH_ESSILOR",
    externalReference: "pages.essilor.orderConfirmation",
    component: OrderConfirmationEssilorPrefetch,
  },
];
