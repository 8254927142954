import React from "react";
import styled from "styled-components/macro";
import CustomText from "../../styled-UI/CustomText";
import LinkButton from "../../styled-UI/LinkButton";
import { ReactComponent as Checkmark } from "../../../assets/icons/checkmark-icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x-icon.svg";
import IconButton from "../../styled-UI/IconButton";
import { FeatureCarouselHorizontalItem } from "../../../interfaces/cmsInterfaces";
import { getMultiMedia } from "../../../utils/cmsUtils";
import useDataHref from "../../../hooks/useDataHref";
import SetInnerHTMLWithSanitize from "../SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  isDarkMode?: boolean;
  item: FeatureCarouselHorizontalItem;
  customFont?: FontFamilies | null;
}

const FeatureHorizontalSlide = ({
  isDarkMode = false,
  item,
  customFont = null,
}: Props): JSX.Element => {
  const {
    multiMediaType,
    description,
    description1Colour,
    description2,
    description2Colour,
    title,
    titleColour,
  } = item;
  const { srcImg } = getMultiMedia(multiMediaType);

  const descrWithHref = useDataHref({ content: description });
  const descrWithHref2 = useDataHref({ content: description2 });

  const openPopup = (e: React.MouseEvent<any>) => {
    const popup = e.currentTarget.nextSibling;
    popup?.classList?.add("show");
  };

  const closePopup = (e: any) => {
    const popup = e.currentTarget.parentElement.parentElement.parentElement;
    popup?.classList?.remove("show");
  };

  return (
    <Slide>
      <ImgContainer>
        <img src={srcImg} />
      </ImgContainer>
      <ButtonContainer>
        <LinkButton
          underline
          uppercase
          onClick={openPopup}
          color={isDarkMode ? "white" : "primary"}
          customColor={titleColour}
          customFont={customFont}
        >
          {title}
        </LinkButton>
        <Popup className="lp-horizontal-slide-popup">
          <CloseContainer>
            <IconButton
              size="md"
              type={isDarkMode ? "white-no-border" : "primary"}
              icon={<CloseIcon />}
              onClick={closePopup}
            />
          </CloseContainer>
          <CustomText
            as="p"
            font={customFont ? "custom-medium" : "font-medium"}
            fontFamily={customFont}
            fontSizePx={13}
            lineHeightPx={18}
            customColor={description2Colour}
          >
            <SetInnerHTMLWithSanitize text={descrWithHref2?.updatedContent ?? description2} />
          </CustomText>
        </Popup>
      </ButtonContainer>

      <div>
        <CustomText
          as="p"
          fontSizePx={28}
          lineHeightPx={28}
          font={customFont ? "custom-medium" : "font-medium"}
          fontFamily={customFont}
          marginTopPx={20}
          marginBottomPx={42}
          textAlign="center"
          isFlex={false}
          color={isDarkMode ? "white" : "primary"}
          customColor={description1Colour}
        >
          <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? description} />
        </CustomText>
      </div>
    </Slide>
  );
};

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const ImgContainer = styled.div`
  margin-bottom: 2.5rem;
  img {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Popup = styled.div`
  display: none;
  position: absolute;
  top: 20%;
  width: 90%;
  background-color: ${(props) => props.theme.palette.white};
  box-shadow: 2px 2px 47px -10px rgba(0, 0, 0, 0.68);
  border-radius: 0.25rem;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;

  &.show {
    display: block;
  }
`;

const CloseContainer = styled.div`
  position: absolute;
  top: -1.25rem;
  right: -1.25rem;
`;

export default FeatureHorizontalSlide;
