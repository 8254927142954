import React from "react";
import styled from "styled-components/macro";

import LoaderElement from "../LoaderElement";
import { TileStyle } from "../LoaderTile";

interface Props {
  style: TileStyle;
}

const ActionButtonsLoader = ({ style }: Props): JSX.Element => {
  return (
    <ActionButtons className={style}>
      <LoaderElement height={40} />
      <LoaderElement height={40} />
    </ActionButtons>
  );
};

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  &.horizontal {
    width: 18.5rem;
  }
`;

export default ActionButtonsLoader;
