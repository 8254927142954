import React from "react";
import styled from "styled-components/macro";
import CustomText from "../../styled-UI/CustomText";
import useDataHref from "../../../hooks/useDataHref";
import SetInnerHTMLWithSanitize from "../SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  title?: string;
  titleColour?: string;
  description?: string;
  description1Colour?: string;
  description2?: string;
  description2Colour?: string;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LpTextListCard = ({
  title,
  titleColour,
  description,
  description1Colour,
  description2,
  description2Colour,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const descrWithHref = useDataHref({ content: description });
  const descrWithHref2 = useDataHref({ content: description2 });

  return (
    <BoxItem>
      <Column>
        <Divider />
        <CustomText
          as="span"
          color={isDarkMode ? "white" : "primary"}
          fontSizePx={32}
          marginBottomPx={18}
          marginTopPx={12}
          font={customFont ? "custom-regular" : "font-regular"}
          fontFamily={customFont}
          customColor={titleColour}
        >
          {title}
        </CustomText>
      </Column>
      {description && (
        <CustomText
          as="span"
          color={isDarkMode ? "white" : "primary"}
          fontSizePx={16}
          font={customFont ? "custom-bold" : "font-bold"}
          fontFamily={customFont}
          marginTopPx={18}
          uppercase
          customColor={description1Colour}
        >
          <SetInnerHTMLWithSanitize text={descrWithHref.updatedContent ?? description} />
        </CustomText>
      )}
      {description2 && (
        <BoxDescription>
          <CustomText
            as="span"
            color={isDarkMode ? "white" : "primary"}
            fontSizePx={14}
            text-align="center"
            marginTopPx={11}
            lineHeightPx={20}
            customColor={description2Colour}
            font={customFont ? "custom-regular" : "font-regular"}
            fontFamily={customFont}
          >
            <SetInnerHTMLWithSanitize text={descrWithHref2.updatedContent ?? description2} />
          </CustomText>
        </BoxDescription>
      )}
    </BoxItem>
  );
};

const BoxItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;

  span {
    justify-content: center;
    text-align: center;
  }
`;

const BoxDescription = styled.div`
  padding: 0 1rem;
`;

const Divider = styled.div`
  width: 1.9rem;
  height: 0.06rem;
  opacity: 0.4;
  background-color: ${(props) => props.theme.palette.primary};
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export default LpTextListCard;
