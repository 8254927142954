import React, { useState } from "react";
import styled from "styled-components/macro";
import Button from "../styled-UI/Button";
import CustomText from "../styled-UI/CustomText";
import { LpBanner } from "../../interfaces/cmsInterfaces";
import { getMultiMedia, useHandleCmsLink } from "../../utils/cmsUtils";
import IconButton from "../styled-UI/IconButton";
import { ReactComponent as PlayIcon } from "../../assets/icons/play-icon.svg";
import VideoPopup from "../styled-UI/VideoPopup";
import clsx from "clsx";
import useDataHref from "../../hooks/useDataHref";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../store/store/storeInterfaces";
interface Props {
  bannerData: LpBanner;
  customFont?: FontFamilies | null;
}

const BannerLandingPage = ({ bannerData, customFont = null }: Props): JSX.Element => {
  const [showVideo, setShowVideo] = useState<boolean>(false);

  const {
    title,
    titleColour,
    linkLabel,
    linkString,
    description,
    description1Colour,
    layoutVariant,
    description2,
    description2Colour,
    externalLink,
    openInNewTab,
    linkTarget,
  } = bannerData;
  const { srcImg, srcVideo, coverImg } = getMultiMedia(bannerData.multiMediaType);
  const { handleCmsLink } = useHandleCmsLink();
  const imageSource = srcVideo ? coverImg : srcImg;
  const layoutVariantName = layoutVariant?.[0]?.name;

  const handleRedirect = (): void => {
    handleCmsLink(linkString, externalLink, openInNewTab);
  };

  const classNames = clsx(
    layoutVariantName === "Lp-cover-banner-1" && "align-left",
    layoutVariantName === "Lp-cover-banner-3" && "align-center",
    layoutVariantName === "Lp-cover-banner-2" && "align-right"
  );
  let alignment: "center" | "left" | "right" | undefined = undefined;

  if (layoutVariantName === "Lp-cover-banner-1") alignment = "left";
  if (layoutVariantName === "Lp-cover-banner-3") alignment = "center";

  const descrWithHref = useDataHref({ content: description });
  const descrWithHref2 = useDataHref({ content: description2 });
  const type = bannerData?.multiMediaType?.[0].data?.contentType.includes("gif");

  return (
    <BoxImg
      image={type ? srcVideo : imageSource}
      data-element-id="CoverBanner"
      data-description={title}
      className={classNames}
      onClick={() => {
        if (!srcVideo && linkTarget && linkString && !linkLabel) {
          handleRedirect();
        }
      }}
      hasLink={!linkLabel && !!linkString}
    >
      <InnerText>
        <Box>
          {title && (
            <CustomText
              as="span"
              font={customFont ? "custom-light" : "font-light"}
              fontFamily={customFont}
              fontSizePx={20}
              marginBottomPx={20}
              color="white"
              customColor={titleColour}
              className={classNames}
            >
              <div className="title">{title}</div>
            </CustomText>
          )}
          {description && (
            <CustomText
              font={customFont ? "custom-light" : "font-light"}
              fontFamily={customFont}
              color="white"
              as="div"
              fontSizePx={60}
              lineHeightPx={60}
              marginBottomPx={24}
              textAlign={alignment}
              customColor={description1Colour}
            >
              <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? description} />
            </CustomText>
          )}
          {description2 && (
            <CustomText
              font={customFont ? "custom-light" : "font-light"}
              fontFamily={customFont}
              color="white"
              as="div"
              fontSizePx={18}
              lineHeightPx={28}
              marginBottomPx={10}
              textAlign={alignment}
              customColor={description2Colour}
            >
              <SetInnerHTMLWithSanitize text={descrWithHref2?.updatedContent ?? description2} />
            </CustomText>
          )}
        </Box>

        <OuterButton className={classNames}>
          {!srcVideo && linkTarget && linkLabel && (
            <Button type="primary-dark" onClick={handleRedirect} customFont={customFont}>
              {linkLabel}
            </Button>
          )}

          {srcVideo && !type && (
            <IconButton
              type="white-no-border"
              size="xl"
              icon={<PlayIcon />}
              onClick={() => setShowVideo(true)}
              data-description={srcVideo}
            />
          )}
        </OuterButton>
        {srcVideo && !type && (
          <VideoPopup isOpen={showVideo} close={() => setShowVideo(false)} srcVideo={srcVideo} />
        )}
      </InnerText>
    </BoxImg>
  );
};

export default BannerLandingPage;

const widthBox = "34rem";

const BoxImg = styled.div<{ image?: string; hasLink: boolean }>`
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-image: url(${(props) => props.image && props.image});
  margin-bottom: 2rem;
  padding: 3.375rem 5rem;
  height: fit-content;
  min-height: 32.5rem;

  &.align-center {
    justify-content: center;
  }

  &.align-right {
    justify-content: flex-end;
  }

  &:hover {
    ${(props) => props.hasLink && "cursor:pointer;"}
  }
`;

const InnerText = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: ${widthBox};
  .title .align-center {
    text-align: center;
  }
`;

const OuterButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;
  width: ${widthBox};

  &.align-center {
    justify-content: center;
  }
`;
