import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";
import { LpCatalogueProductTile as LpCatalogueProductTileInterface } from "../../interfaces/cmsInterfaces";
import { getPagePath } from "../../routing/routesUtils";
import { sendAnalyticsData } from "../../store/analytics/analyticsService";
import { selectStickyBarType } from "../../store/store/storeSlice";
import { getInfoFromModelOrVariant, showTileCms } from "../../utils/cmsUtils";
import CustomProgressiveImage from "../styled-UI/CustomProgressiveImage";
import CustomText from "../styled-UI/CustomText";
import PriceTile from "./PriceTile";
import TileHeader from "./Tile/TileHeader";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  data: LpCatalogueProductTileInterface;
  position?: number;
  rowNumber?: number;
  customFont?: FontFamilies | null;
}

const LpCatalogueProductTile = ({
  data,
  position,
  rowNumber,
  customFont = null,
}: Props): JSX.Element => {
  const history = useHistory();
  const { model, modelColor } = data;
  const { image, seo, price } = getInfoFromModelOrVariant(model, modelColor);

  const specificData = model ? model : modelColor;
  const code = model ? model?.productCode : modelColor?.variantCode;

  const { translateLabel } = useTranslation();

  const stickyBarType = useSelector(selectStickyBarType);
  const ref: any = useRef();

  useEffect(() => {
    const showTile = showTileCms(stickyBarType, model);
    if (ref.current) {
      if (showTile === false) {
        ref.current.parentElement.parentElement.style = "display: none";
      } else {
        ref.current.parentElement.parentElement.style = "display: block";
      }
    }
  }, [stickyBarType]);

  if (specificData)
    return (
      <BoxGlass
        onClick={() => {
          sendAnalyticsData({
            id: "Click",
            Tracking_Type: "link",
            data_element_id: `CatalogueProductTile_Tiles_Tile${rowNumber}x${position}`,
            data_description: code,
          });
          data?.inAssortment && history.push(getPagePath("/pdp" + seo?.href));
        }}
        ref={ref}
      >
        <HeaderPaddingContainer>
          <TileHeader
            data={specificData}
            isVariant={!model}
            url={getPagePath("/pdp" + seo?.href)}
            showCodeInVariant={true}
            customFont={customFont}
          />
        </HeaderPaddingContainer>
        <ImageContainer>
          <CustomProgressiveImage type="glasses" src={image ?? ""} />
        </ImageContainer>
        {data?.inAssortment ? (
          <PaddingContainer>
            <PriceTile price={price} customFont={customFont} />
          </PaddingContainer>
        ) : (
          <PaddingContainer className="no-assortment">
            <CustomText
              as="h1"
              color="primary"
              fontSizePx={13}
              font={customFont ? "custom-bold" : "font-bold"}
              fontFamily={customFont}
            >
              {translateLabel("LANDING_PAGE_NO_ASSORTMENT")}
            </CustomText>
          </PaddingContainer>
        )}
      </BoxGlass>
    );
  else return <></>;
};

export default LpCatalogueProductTile;

export const MemoizedLpCatalogueProductTile = React.memo(LpCatalogueProductTile);

const BoxGlass = styled.div`
  width: 19.94rem;
  height: 30.63rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
`;

const HeaderPaddingContainer = styled.div`
  padding: 1.75rem 1.75rem 0 1.75rem;
`;
const PaddingContainer = styled.div`
  margin: 0 1.75rem;
  min-height: 6rem;

  &.no-assortment {
    padding: 0.8125rem 0;
    border-top: 1px solid ${(props) => props.theme.palette.gray.medium};
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
`;
