import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components/macro";
import PromotionSlideComponent from "./PromotionSlide";
import CarouselDotBar from "../../styled-UI/CarouselDotBar";
import { PromotionSlide } from "../../../interfaces/cmsInterfaces";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  autoplay?: boolean;
  autoplaySpeed?: number;
  slides: PromotionSlide[];
  arrows?: boolean;
  customFont?: FontFamilies | null;
}

const PromotionsCarousel = ({
  slides,
  autoplay,
  autoplaySpeed,
  customFont = null,
}: Props): JSX.Element => {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  // const [, setTotalSlides] = useState<number>(0);

  const defaultSettings = {
    dots: false,
    arrows: false,
    draggable: true, //to set it to true --> prevent click on drag https://github.com/akiran/react-slick/issues/848
    speed: 500,
    infinite: true,
    autoplay: autoplay,
    autoplaySpeed: autoplaySpeed,
    onSwipe: () => {
      console.log("swipe");
    },
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: (_: any, next: any) => setCurrentSlide(next),
  };

  const changeSlide = (val: number) => {
    sliderRef.slickGoTo(val);
  };
  const goToPrevSlide = () => {
    sliderRef.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef.slickNext();
  };

  // useEffect(() => {
  //   if (
  //     sliderRef &&
  //     sliderRef.props &&
  //     sliderRef.props.children &&
  //     Array.isArray(sliderRef.props.children)
  //   )
  //     setTotalSlides(sliderRef.props.children.length);
  // }, [sliderRef]);

  return slides?.length > 0 ? (
    <SliderContainer>
      <Slider ref={(val) => setSliderRef(val)} {...defaultSettings}>
        {slides &&
          slides.map((slide, index: number) => {
            const sequenceNumber = index + 1;
            return (
              <PromotionSlideComponent
                key={index}
                slide={slide}
                tileNumber={sequenceNumber}
                goToPrevSlide={goToPrevSlide}
                goToNextSlide={goToNextSlide}
                arrows={slides?.length > 1}
                customFont={customFont}
              />
            );
          })}
      </Slider>
      <DotBarNavigation>
        <CarouselDotBar
          lastPage={slides && slides.length - 1}
          currentPage={currentSlide}
          changeSlide={changeSlide}
          navigationType="one-tile"
          isAnimated
          data-element-id="News_Navigation"
          data-description="dots"
        />
      </DotBarNavigation>
    </SliderContainer>
  ) : (
    <div></div>
  );
};

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DotBarNavigation = styled.div`
  position: absolute;
  bottom: 2.5rem;
  right: 2.5rem;
`;

export default PromotionsCarousel;

export const MemoizedPromotionsCarousel = React.memo(PromotionsCarousel);
