import React, { createContext, useEffect, useState } from "react";
import { ContextState, Labels } from "./labelInterfaces";
import { useSelector } from "react-redux";
import { selectCurrentLang } from "../store/store/storeSlice";
import usePrivateLabels from "./usePrivateLabel";
import usePublicLabels from "./usePublicLabels";
import { selectIsLogged } from "../store/user/userSlice";

const LanguageContext = createContext<ContextState>({} as ContextState);

interface ContextProps {
  children: any;
}

const LanguageProvider = ({ children }: ContextProps): JSX.Element => {
  const lang = useSelector(selectCurrentLang);
  const { privateLabels, privateRequestStatus } = usePrivateLabels();
  const { publicLabels, publicRequestStatus } = usePublicLabels();
  const isLogged = useSelector(selectIsLogged);

  const [globalLabels, setGlobalLabels] = useState({});

  //merge public and private label, with private overriding possible double labels key
  useEffect(() => {
    if (lang) {
      const publicL: Labels = publicLabels[lang] ?? {};
      const privateL: Labels = privateLabels[lang] ?? {};
      const globalLabels = { ...publicL, ...privateL };

      setGlobalLabels(globalLabels);
    }
  }, [privateLabels, publicLabels]);

  return (
    <LanguageContext.Provider
      value={{
        labels: globalLabels,
        status: isLogged ? privateRequestStatus.status : publicRequestStatus.status,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
