import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";
import useCanRender from "../../hooks/useCanRender";

import { Door } from "../../store/user/userInterfaces";
import { selectIsMultidoor, selectMultiDoors, setHasChangedDoor } from "../../store/user/userSlice";
import { setPDPVariantInfo } from "../../store/catalogue/catalogueSaga";
import CustomSelect, { CustomOptions } from "../styled-UI/CustomSelect";
import { updateSelectedDoor } from "../../store/user/userSagas";

interface DoorOption extends Door, CustomOptions {}

interface Props {
  uniqueID: string;
  selectedDoor: Door;
  marginTopPx?: number;
  marginLeftPx?: number;
  marginBottomPx?: number;
  marginRightPx?: number;
  callback?: (newDoor: Door) => void;
  privilege?: string | null;
  disabled?: boolean;
  responsive?: boolean;
}

const MultiDoorSelectPDP = ({
  uniqueID,
  selectedDoor,
  callback,
  marginTopPx = 0,
  marginLeftPx = 0,
  marginBottomPx = 0,
  marginRightPx = 0,
  privilege,
  disabled = false,
  responsive = false,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const { translateLabel } = useTranslation();
  const canRender = useCanRender();

  const isMultidoor = useSelector(selectIsMultidoor);
  const multiDoors = useSelector(selectMultiDoors);

  const [doorsOptions, setDoorsOption] = useState<DoorOption[]>([]);
  const [selectedDoorInternal, setSelectedDoorInternal] = useState<DoorOption | null>(null);

  const printLabelDoor = (door: Door) => {
    let label = door?.orgentityName;
    if (door?.customerName && label) {
      label += " - " + door?.customerName;
    }

    return label;
  };

  const getDoorOption = (door: Door): DoorOption => {
    return {
      label: printLabelDoor(door),
      value: door.orgentityId,
      ...door,
    };
  };

  const getDoorOptionsList = (multiDoors: Door[]): DoorOption[] => {
    if (privilege) {
      const doorOpts: DoorOption[] = [];

      multiDoors.forEach((door) => {
        canRender(privilege, door) && doorOpts.push(getDoorOption(door));
      });

      return doorOpts;
    } else return multiDoors.map((door) => getDoorOption(door));
  };

  useEffect(() => {
    if (multiDoors) setDoorsOption(getDoorOptionsList(multiDoors));
  }, [multiDoors]);

  useEffect(() => {
    if (selectedDoor) {
      if (selectedDoor.orgentityId === selectedDoorInternal?.orgentityId) return;

      if (privilege) {
        const doors = multiDoors.filter((door) => canRender(privilege, door));

        const findSelectedDoor = doors.find(
          (door) => door.orgentityId === selectedDoor.orgentityId
        ); // this version has more info (ie. the customer name shown in the label) then the one saved in redux as selectedDoor

        if (findSelectedDoor) {
          setSelectedDoorInternal({
            label: printLabelDoor(findSelectedDoor),
            value: findSelectedDoor.orgentityId,
            ...findSelectedDoor,
          });
        } else {
          const newDoor = doors?.[0];
          if (newDoor) {
            setSelectedDoorInternal({
              label: printLabelDoor(newDoor),
              value: newDoor.orgentityId,
              ...newDoor,
            });
          }
        }
      } else {
        setSelectedDoorInternal({
          label: printLabelDoor(selectedDoor),
          value: selectedDoor.orgentityId,
          ...selectedDoor,
        });
      }
    }
  }, [selectedDoor]);

  const handleDoorChange = (option: CustomOptions) => {
    const { label, value, ...door } = option;
    if (door && uniqueID) {
      dispatch(setHasChangedDoor(true));
      dispatch(
        updateSelectedDoor({
          door: door as Door,
          callback: callback,
        })
      );
      setSelectedDoorInternal(option as DoorOption);

      dispatch(
        setPDPVariantInfo({
          PDPVariantDoor: [
            {
              door: door as Door,
              uniqueID,
            },
          ],
          fallbackDoor: multiDoors.filter((door) => canRender(privilege, door))?.[0],
        })
      );

      callback?.(door as Door);
    }
  };

  return isMultidoor ? (
    <Container
      marginTopPx={marginTopPx}
      marginRightPx={marginRightPx}
      marginLeftPx={marginLeftPx}
      marginBottomPx={marginBottomPx}
      responsive={responsive}
    >
      <CustomSelect
        placeholder={translateLabel("SELECT_DOORS")}
        value={selectedDoorInternal}
        type="white"
        options={doorsOptions}
        disabled={multiDoors.length === 1 || disabled}
        onChange={handleDoorChange}
        isSearchable={false}
        noOrderOptions
        optionEndIcon="/images/star-icon.svg"
        showOptionEndIconFunc={(option: any) => option.stars}
        // menuIsOpen
      />
    </Container>
  ) : null;
};

const Container = styled.div<{
  marginTopPx: number;
  marginLeftPx: number;
  marginBottomPx: number;
  marginRightPx: number;
  responsive: boolean;
}>`
  width: ${(props) => (props.responsive ? "100%" : "19rem")};
  //MARGINS
  margin-top: ${(props) =>
    props.marginTopPx ? `${props.marginTopPx / props.theme.baseFontSize}rem` : "unset"};
  margin-left: ${(props) =>
    props.marginLeftPx ? `${props.marginLeftPx / props.theme.baseFontSize}rem` : "unset"};
  margin-bottom: ${(props) =>
    props.marginBottomPx ? `${props.marginBottomPx / props.theme.baseFontSize}rem` : "unset"};
  margin-right: ${(props) =>
    props.marginRightPx ? `${props.marginRightPx / props.theme.baseFontSize}rem` : "unset"};
`;

export default MultiDoorSelectPDP;
