import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/types";

import { handleError, showErrorPopup } from "../store/storeSagas";
import {
  sliceName,
  saveDoorList,
  startMultidoorLoading,
  stopMultidoorLoading,
  selectPageSize,
} from "./multidoorSlice";
import multidoorService from "./multidoorService";
import { getDoorListPayload, saveDoorListPayload } from "./multidoorInterface";
import { getUserContext } from "../user/userSagas";

/* ACTIONS */
export const getDoorList = createAction<getDoorListPayload>(sliceName + "/getDoorList");
export const saveSelectedDoorList = createAction<saveDoorListPayload>(
  sliceName + "/saveSelectedDoorList"
);

/* SAGAS */

/**
 * Get the entire list of doors
 *
 * @param {PayloadAction<any>} action
 * @return {*}  {SagaIterator}
 */
function* getDoorListSaga(action: PayloadAction<getDoorListPayload>): SagaIterator {
  try {
    yield put(startMultidoorLoading("doorList"));

    const pageSize = yield select(selectPageSize); // get default page size

    const qparams = {
      ...action.payload,
      size: pageSize,
    };
    const response = yield call(multidoorService.getDoorList, qparams);
    yield put(saveDoorList(response.data.data));
    yield put(stopMultidoorLoading("doorList"));
  } catch (error) {
    yield put(handleError(error));
    yield put(stopMultidoorLoading("doorList"));
  }
}

/**
 * Save selected doors
 *
 * @param {PayloadAction<any>} action
 * @return {*}  {SagaIterator}
 */
function* saveSelectedDoorListSaga(action: PayloadAction<saveDoorListPayload>): SagaIterator {
  try {
    if (action.payload.massiveOrderMode === undefined) yield put(startMultidoorLoading("doorList"));
    yield call(multidoorService.saveDoorList, action.payload);

    yield put(getUserContext());
  } catch (error) {
    const text = error.response.data.errors[0].message;

    if (error?.response?.status === 400) {
      const myArr = JSON.parse(text);
      if (myArr.errorMsg === "You must navigate at least for one door.") {
        yield put(
          showErrorPopup({
            status: error?.response?.status,
            message: "MULTIDOOR_NO_DOOR_SELECTED_ERROR",
          })
        );
      } else {
        yield put(showErrorPopup({ status: error?.response?.status }));
      }
    }

    yield put(handleError(error));
    yield put(stopMultidoorLoading("doorList"));
  }
}

export function* multidoorSaga(): SagaIterator {
  yield takeLatest(getDoorList.type, getDoorListSaga);
  yield takeLatest(saveSelectedDoorList.type, saveSelectedDoorListSaga);
}
