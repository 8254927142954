import { AxiosResponse } from "axios";
import { createAndExecuteService } from "../serviceUtils";
import { NeedHelpEmail, UploadServiceTokenPayload } from "./statelessInterfaces";
import {
  needHelpUrl,
  getLabelsPublicUrl,
  getLabelsPrivateUrl,
  getDecouplingResourceUrl,
  getTokenForUploadUrl,
  getTokenWCSUrl,
} from "./statelessUrls";

export default {
  postNeedHelp: async (postPayload: NeedHelpEmail): Promise<AxiosResponse> => {
    return createAndExecuteService(needHelpUrl, "POST", null, postPayload);
  },
  getLabelsPublic: async (lang: string): Promise<AxiosResponse> => {
    const url = getLabelsPublicUrl.replace("{locale}", lang);

    return createAndExecuteService(url, "GET");
  },
  getLabelsPrivate: async (lang: string): Promise<AxiosResponse> => {
    const url = getLabelsPrivateUrl.replace("{locale}", lang);

    return createAndExecuteService(url, "GET");
  },
  //api to get status of sap services
  getDecouplingResource: async (groupId: string): Promise<AxiosResponse> => {
    const url = getDecouplingResourceUrl.replace("{groupId}", groupId);
    return createAndExecuteService(url, "GET");
  },
  getTokenForUpload: async (payload: UploadServiceTokenPayload): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();

    (Object.keys(payload) as Array<keyof typeof payload>).forEach((key) => {
      qparams.append(key, payload[key]);
    });

    return createAndExecuteService(getTokenForUploadUrl, "GET", qparams);
  },
  getTokenWCS: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getTokenWCSUrl, "GET");
  },
};
