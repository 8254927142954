import { BFFApiDomain } from "../serviceUtils";

////////// PAYMENT MANAGEMENT
export const paymentManagementInformationUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/information";

export const deselectionReasonsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/reasons/deselection";

export const paymentSimulationUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/simulation";

export const paymentConfirmationUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/confirmation";

export const paymentConfirmationOldB2BUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/b2b20/confirmation";

////////// PAYMENT METHOD

export const getPaymentMethodValidationUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/country";

// proposal #1 - service to get/add/edit/delete main info from BE, but additional info is retrieved through cybersource
export const paymentMethodUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/method";

// proposal #2 - everything goes through BE

// generic (used only for DELETE)
export const paymentMethodOldB2BUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/b2b20/method";

// card specific (GET, POST, PUT)
export const paymentMethodOldB2BCardUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/b2b20/method/card";

// bank-account specific (GET, POST, PUT)
export const paymentMethodOldB2BBankUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/b2b20/method/bank";

// get details for card (POST)
export const paymentMethodOldB2BCardDetailsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/b2b20/method/card/details";

// get details for bank-account (POST)
export const paymentMethodOldB2BBankDetailsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/b2b20/method/bank/details";

////////// PAYMENT HISTORY
export const paymentHistoryUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/history";

////////// PAYMENT HISTORY DETAILS
export const paymentHistoryDetailsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/history/detail";

////////// PAYMENT DOCUMENT HISTORY
export const paymentDocumentHistoryListUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/status";

////////// PAYMENT STATEMENT
export const paymentStatementListUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/payment/statement/information";

////////// MISC
