import React from "react";
import ContentLoader from "react-content-loader";

interface Props {
  height: number;
  radius?: number;
  width?: number;
  style?: "default" | "dark";
}

const PALETTE = {
  default: {
    backgroundColor: "#f3f3f3",
    foregroundColor: "#ecebeb",
  },
  dark: {
    backgroundColor: "#b6b6ca",
    foregroundColor: "#9c9caa",
  },
};

const LoaderElement = ({ height, radius = 0, width, style = "default" }: Props): JSX.Element => (
  <ContentLoader
    speed={2}
    width={width ? width : "100%"}
    height={height}
    backgroundColor={PALETTE[style]?.backgroundColor}
    foregroundColor={PALETTE[style]?.foregroundColor}
  >
    <rect x="0" y="0" rx={radius} width="100%" height={height} />
  </ContentLoader>
);

export default LoaderElement;
