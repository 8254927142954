import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../storeConfig";
import {
  CartState,
  CartInfo,
  OrderCategory,
  OrderItem,
  OrderMultidoor,
  OrderMultidoorInfo,
  OrderCategoryInfo,
  OrderMultidoorSelected,
  OrderCategorySelected,
  OrderItemSelected,
  SelectedCartPayload,
  SelectedMultidoorPayload,
  SelectedCategoryPayload,
  OrderMultidoorSummary,
  OrderCategorySummary,
  OrderItemToKeep,
  ShippingAddressSummaryMultidoor,
  OrderMultidoorAddress,
  ShippingAddressSelectedMultidoor,
  PutPrecartShippingInfoPayload,
  CartCountQuantity,
  SubOrderPayload,
  CustomDetails,
  Details,
  DetailsStatusPayload,
  RxPrescriptionBrasilStatus,
  CartLoading,
  SaveOtherSkusPayload,
  OtherSkus,
  BackorderTrackedItems,
  DeliveryDates,
  OutOfStockItems,
} from "./cartInterfaces";
import {
  mapOrderItemSelected,
  mapOrderCategorySelected,
  mapOrderMultidoorSelected,
  getSelectedNumberMultidoor,
  getTotalNumberMultidoor,
  getSelectedNumberCart,
  getTotalNumberCart,
  getTotalPriceCategory,
  getTotalPriceMultidoor,
  isOrderItemToKeep,
  isOutOfStock,
  getSelectedNumberCategory,
  getTotalNumberCategory,
  getTotalOutOfStockNumberMultidoor,
  getOutOfStockNumberCart,
  isOrderItemRx,
} from "../../utils/cartUtils";
import { CheckoutItems } from "../checkout/checkoutInterfaces";
import { Variant } from "../../interfaces/productInterface";
import { DetailsStatus } from "../smartshopper/smartshopperInterfaces";
import { selectActiveDoor, selectUsersPrivileges } from "../user/userSlice";
import { RequestStatus } from "../../interfaces/mainInterfaces";

export const sliceName = "cart";
const initialState: CartState = {
  ////////// add to cart
  addToCartLoading: false,
  disabledAddToCart: false,
  addToCartRequestStatus: "IDLE",

  ////////// cart status
  cartLoading: [],
  isCartEmpty: false,
  countQuantity: null,

  ////////// cart content
  orderId: "$",
  orderMultidoorList: [],
  orderMultidoorAddress: [],
  shippingAddressList: [],
  shippingAddressSelected: [],
  subOrdersNotesAndPO: [],
  backorderItems: new Set(),
  backorderTrackedItems: {},
  cartTotalPrice: null,
  otherSkus: {},
  getOtherSkusStatus: "IDLE",
  outOfStockItems: null,
  ////////// handle selections
  selectedItems: [],
  selectedItemsBasedOnPrivilegs: [],

  ////////// alternative
  cartAlternativeVariants: null,
  cartAlternativeVariantsLoading: false,
  cartTotalAlternativeVariants: 0,

  ////////// m4c
  details: {},
  detailsStatus: {},

  ////////// rx prescription Brasil
  rxBrasilToken: "",
  rxBrasilStatus: [],

  ///////// set delivery
  deliveryDates: [],
  getDeliveryDatesStatus: "IDLE",
  saveDeliveryDatesStatus: "IDLE",
};

export const cartSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ////////// add to cart
    // setAddToCartLoading: (state, action: PayloadAction<boolean>) => {
    //   state.addToCartLoading = action.payload;
    // },

    setAddToCartRequestStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.addToCartRequestStatus = action.payload;
      state.addToCartLoading = action.payload === "LOADING";
    },

    setDisabledAddToCart: (state, action: PayloadAction<boolean>) => {
      state.disabledAddToCart = action.payload;
    },

    ////////// cart status
    startCartLoading: (state, action: PayloadAction<CartLoading>) => {
      const currentLoadingInfo = state.cartLoading.find((_) => _.id === action.payload.id);

      // the expectation is that no two actions on the same id should happen (ie. can't update quantity and customer reference at the same time)
      // (with the exception of subsequent quantity updates, which appear transparent to the user)
      // so it's not necessary to keep track of every single type (as the stop is handled irrespectively of type, anyway)
      // we replace it just to be safe, though it's probably fine to just ignore it if it's already present
      if (currentLoadingInfo) currentLoadingInfo.type === action.payload.type;
      else state.cartLoading.push(action.payload);
    },

    stopCartLoading: (state, action: PayloadAction<CartLoading>) => {
      state.cartLoading = state.cartLoading.filter((_) => _.id !== action.payload.id);
    },

    setIsCartEmpty: (state, action: PayloadAction<boolean>) => {
      state.isCartEmpty = action.payload;
    },

    saveCountQuantity: (state, action: PayloadAction<CartCountQuantity>) => {
      state.countQuantity = action.payload;
    },

    ////////// cart content
    saveOrderId: (state, action: PayloadAction<string>) => {
      state.orderId = action.payload;
    },

    saveOutOfStockItems: (state, action: PayloadAction<OutOfStockItems | null>) => {
      state.outOfStockItems = action.payload;
    },

    saveOrderMultidoorList: (state, action: PayloadAction<OrderMultidoor[]>) => {
      state.orderMultidoorList = action.payload;
    },

    saveOrderMultidoorAddress: (state, action: PayloadAction<OrderMultidoorAddress[]>) => {
      state.orderMultidoorAddress = action.payload;
    },

    saveShippingAddressList: (state, action: PayloadAction<ShippingAddressSummaryMultidoor[]>) => {
      state.shippingAddressList = action.payload;
    },

    saveShippingAddressSelected: (
      state,
      action: PayloadAction<ShippingAddressSelectedMultidoor>
    ) => {
      // delete previously selected address for specified door, if present
      const newList = state.shippingAddressSelected.filter(
        (_) => _.multidoorId !== action.payload.multidoorId
      );

      newList.push(action.payload); // add new selected address

      state.shippingAddressSelected = newList;
    },

    saveSubOrdersNotesAndPO: (state, action: PayloadAction<SubOrderPayload[]>) => {
      state.subOrdersNotesAndPO = action.payload;
    },

    saveCartTotalPrice: (state, action: PayloadAction<number>) => {
      state.cartTotalPrice = action.payload;
    },

    saveOtherSkus: (state, action: PayloadAction<SaveOtherSkusPayload>) => {
      state.otherSkus[action.payload.id] = action.payload.variantSkus;
    },

    saveGetOtherSkusStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getOtherSkusStatus = action.payload;
    },

    saveBackorderItems: (state, { payload }: PayloadAction<Set<string>>) => {
      state.backorderItems = payload;
    },

    saveBackorderTrackedItems: (state, { payload }: PayloadAction<BackorderTrackedItems>) => {
      state.backorderTrackedItems = payload;
    },

    initializeSelectedItems: (state) => {
      if (state.orderMultidoorList.length !== 0)
        state.selectedItems = mapOrderMultidoorSelected(state.orderMultidoorList);
    },

    toggleSelectedItems: (state, action: PayloadAction<string>) => {
      state.selectedItems?.forEach((orderMultidoor: OrderMultidoorSelected) => {
        orderMultidoor.categorySelected.forEach((orderCategory: OrderCategorySelected) => {
          orderCategory.orderItemSelected.forEach((orderItem: OrderItemSelected) => {
            // toggle select value for the orderItem specified as parameter
            if (orderItem.orderItemId == action.payload) orderItem.selected = !orderItem.selected;
          });
        });
      });
    },

    toggleSelectedCategory: (state, action: PayloadAction<SelectedCategoryPayload>) => {
      state.selectedItems?.forEach((orderMultidoor: OrderMultidoorSelected) => {
        // for the orderMultidoor specified as parameter
        if (orderMultidoor.multidoorId == action.payload.multidoorId) {
          orderMultidoor.categorySelected.forEach((orderCategory: OrderCategorySelected) => {
            // for the orderCategory specified as parameter
            if (orderCategory.productCategory == action.payload.productCategory) {
              orderCategory.orderItemSelected.forEach((orderItem: OrderItemSelected) => {
                // update select value for all orderItems
                orderItem.selected = !action.payload.currentStatus;
              });
            }
          });
        }
      });
    },

    toggleSelectedMultidoor: (state, action: PayloadAction<SelectedMultidoorPayload>) => {
      state.selectedItems?.forEach((orderMultidoor: OrderMultidoorSelected) => {
        // for the orderMultidoor specified as parameter
        if (orderMultidoor.multidoorId == action.payload.multidoorId) {
          orderMultidoor.categorySelected.forEach((orderCategory: OrderCategorySelected) => {
            if (isOutOfStock(orderCategory.productCategory)) return; // if outofstock, don't select items

            orderCategory.orderItemSelected.forEach((orderItem: OrderItemSelected) => {
              // update select value for all orderItems
              orderItem.selected = !action.payload.currentStatus;
            });
          });
        }
      });
    },

    toggleSelectedCart: (state, action: PayloadAction<SelectedCartPayload>) => {
      state.selectedItems?.forEach((orderMultidoor: OrderMultidoorSelected) => {
        orderMultidoor.categorySelected.forEach((orderCategory: OrderCategorySelected) => {
          if (isOutOfStock(orderCategory.productCategory)) return; // if outofstock, don't select items

          orderCategory.orderItemSelected.forEach((orderItem: OrderItemSelected) => {
            // update select value for all orderItems
            orderItem.selected = !action.payload.currentStatus;
          });
        });
      });
    },

    updateSelectedItems: (state, action: PayloadAction<OrderMultidoor[]>) => {
      // store all orderItems' ids that appear in OrderMultidoorList
      const orderItemsToKeep: OrderItemToKeep[] = [];

      action.payload.forEach((orderMultidoor: OrderMultidoor) => {
        // look for current orderMultidoor in selectedItems
        const currentMultidoorSelected = state.selectedItems.find(
          (orderMultidoorSelected: OrderMultidoorSelected) =>
            orderMultidoorSelected.multidoorId === orderMultidoor.multidoorId
        );

        // if multidoorId doesn't exists in selectedItems, add it and intitialize it
        if (!currentMultidoorSelected) {
          const newMultidoor = {
            multidoorId: orderMultidoor.multidoorId,
            orgentityName: orderMultidoor.orgentityName,
            categorySelected: mapOrderCategorySelected(orderMultidoor.categoryList),
          };
          state.selectedItems.push(newMultidoor);

          newMultidoor.categorySelected.forEach((orderCategory: OrderCategorySelected) => {
            orderCategory.orderItemSelected.forEach((orderItem: OrderItemSelected) => {
              orderItemsToKeep.push({
                orderItemId: orderItem.orderItemId,
                orderCategory: orderCategory.productCategory,
              });
            });
          });
        }
        // if it exists, check if all categories are in selectedItems
        else {
          orderMultidoor.categoryList.forEach((orderCategory: OrderCategory) => {
            // look for current orderCategory in current orderMultidoor
            const currentCategorySelected = currentMultidoorSelected.categorySelected.find(
              (orderCategorySelected: OrderCategorySelected) =>
                orderCategorySelected.productCategory === orderCategory.productCategory
            );

            // if category doesn't exists in selectedItems, add it and initialize it
            if (!currentCategorySelected) {
              const newCategory = {
                productCategory: orderCategory.productCategory,
                productCategoryIdentifier: orderCategory.productCategoryIdentifier,
                orderItemSelected: mapOrderItemSelected(orderCategory.orderItemList),
              };

              currentMultidoorSelected.categorySelected.push(newCategory);

              //TODO: do I need to do this for doors, too? or will it always have a different id if it switches doors?
              // add all orderItems present in orderCategory to list of the ones to keep
              orderCategory.orderItemList.forEach((orderItem: OrderItem) => {
                orderItemsToKeep.push({
                  orderItemId: orderItem.orderItemId,
                  orderCategory: orderCategory.productCategory,
                });
              });
            }
            // if it exists, check if all orderItems are in selectedItems
            else {
              orderCategory.orderItemList.forEach((orderItem: OrderItem) => {
                // add all orderItems present in orderCategory to list of the ones to keep
                orderItemsToKeep.push({
                  orderItemId: orderItem.orderItemId,
                  orderCategory: orderCategory.productCategory,
                });

                // look for current orderItem in current categorySelected
                const currentOrderItemSelected = currentCategorySelected.orderItemSelected.find(
                  (orderItemSelected: OrderItemSelected) =>
                    orderItemSelected.orderItemId === orderItem.orderItemId
                );

                // if orderItem doesn't exists in selectedItems, add it and initialize it
                if (!currentOrderItemSelected) {
                  const obj: OrderItemSelected = {
                    orderItemId: orderItem.orderItemId,
                    selected: true,
                    quantity: orderItem.quantity,
                  };

                  if (orderItem?.orders && orderItem?.orders.length > 0) {
                    // obj.availableQuantity = getAvailableQuantityTotal(orderItem?.orders);

                    if (!obj.singleOrderItemSelected) {
                      obj.singleOrderItemSelected = orderItem?.orders.map((_) => {
                        return {
                          orderItemId: _.orderItemId,
                          quantity: _.quantity,
                          availableQuantity: _.availableQuantity,
                        };
                      });
                    }
                  }

                  currentCategorySelected.orderItemSelected.push(obj);
                } else {
                  // if orderItem does exist, check that its quantity is correct
                  if (orderItem.quantity != currentOrderItemSelected.quantity)
                    currentOrderItemSelected.quantity = orderItem.quantity;

                  if (orderItem?.orders && orderItem?.orders.length > 0) {
                    if (!currentOrderItemSelected.singleOrderItemSelected) {
                      currentOrderItemSelected.singleOrderItemSelected = orderItem?.orders.map(
                        (_) => {
                          return {
                            orderItemId: _.orderItemId,
                            quantity: _.quantity,
                            availableQuantity: _.availableQuantity,
                          };
                        }
                      );
                    }
                  }
                }
              });
            }
          });
        }
      });

      // delete all orderItems whose id did not appear in OrderMultidoorList
      state.selectedItems?.forEach((orderMultidoor: OrderMultidoorSelected) => {
        orderMultidoor.categorySelected.forEach((orderCategory: OrderCategorySelected) => {
          orderCategory.orderItemSelected = orderCategory.orderItemSelected.filter(
            // (orderItem: OrderItemSelected) => orderItemsIds.includes(orderItem.orderItemId)
            (orderItem: OrderItemSelected) =>
              isOrderItemToKeep(
                orderItemsToKeep,
                orderItem.orderItemId,
                orderCategory.productCategory
              )
          );
        });

        // purge categories with 0 items
        orderMultidoor.categorySelected = orderMultidoor.categorySelected.filter(
          (orderCategory: OrderCategorySelected) => orderCategory.orderItemSelected.length > 0
        );
      });

      // purge doors with 0 items
      state.selectedItems &&
        (state.selectedItems = state.selectedItems.filter(
          (orderMultidoor: OrderMultidoorSelected) => orderMultidoor.categorySelected.length > 0
        ));
    },

    saveOrderItemsOnPrivileges: (state, action: PayloadAction<OrderMultidoorSelected[]>) => {
      state.selectedItemsBasedOnPrivilegs = action.payload;
    },

    ////////// alternative
    saveCartAlternativeVariants: (state, action: PayloadAction<Variant[] | null>) => {
      state.cartAlternativeVariants = action.payload;
    },

    saveCartTotalAlternativeVariants: (state, action: PayloadAction<number>) => {
      state.cartTotalAlternativeVariants = action.payload;
    },

    setCartAlternativeVariantsLoading: (state, action: PayloadAction<boolean>) => {
      state.cartAlternativeVariantsLoading = action.payload;
    },

    ////////// m4c
    saveDetails: (state, action: PayloadAction<CustomDetails>) => {
      const recipeId = action.payload.recipeId;
      state.details[recipeId] = action.payload?.details;
    },

    setDetailsStatus: (state, action: PayloadAction<DetailsStatusPayload>) => {
      const recipeId = action.payload.recipeId;
      state.detailsStatus[recipeId] = action.payload.status;
    },

    clearDetails: (state) => {
      state.details = {};
      state.detailsStatus = {};
    },

    ////////// rx prescription Brasil
    setRxBrasilToken: (state, action: PayloadAction<string>) => {
      state.rxBrasilToken = action.payload;
    },

    setRxBrasilStatus: (state, action: PayloadAction<RxPrescriptionBrasilStatus[]>) => {
      state.rxBrasilStatus = action.payload;
    },

    ///////// set delivery
    saveDeliveryDates: (state, action: PayloadAction<DeliveryDates[]>) => {
      state.deliveryDates = action.payload;
    },

    saveGetDeliveryDatesStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getDeliveryDatesStatus = action.payload;
    },

    saveSaveDeliveryDatesStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.saveDeliveryDatesStatus = action.payload;
    },

    ///////// clear

    // used only when something breaks at saga-level, and we need to clear everything
    clearCart: (state) => {
      state.orderId = "$";
      state.orderMultidoorList = [];
      state.selectedItems = [];
      state.orderMultidoorAddress = [];
      state.shippingAddressList = [];
      state.shippingAddressSelected = [];
      state.isCartEmpty = false;
      state.subOrdersNotesAndPO = [];
      state.deliveryDates = [];
      state.getDeliveryDatesStatus = "IDLE";
      state.saveDeliveryDatesStatus = "IDLE";
    },

    // used each time the cart page is exited: important that it doesn't clear EVERYTHING, as we need to remember some info (eg: selectedItems)
    clearCartWhenLeavingPage: (state) => {
      state.outOfStockItems = null;
      state.orderMultidoorList = []; // clear elements in cart, as precart() is called anyway, and you risk seeing old items for a moment when coming back
      state.shippingAddressList = []; // clear address list, as it's called again anyway
      state.subOrdersNotesAndPO = []; // clear customer notes, as it's called again anyway
      state.isCartEmpty = false; // clear empty cart status
      state.details = {}; // clear M4C details
      state.detailsStatus = {}; // clear M4C details
      state.shippingAddressSelected = []; // forget selected address
      state.rxBrasilToken = ""; // forget token to re-do login when coming back
      state.rxBrasilStatus = []; // forget info regarding rx brasil status
      state.deliveryDates = [];
      state.getDeliveryDatesStatus = "IDLE";
      state.saveDeliveryDatesStatus = "IDLE";
    },

    clearDelivery: (state) => {
      state.deliveryDates = [];
      state.getDeliveryDatesStatus = "IDLE";
      state.saveDeliveryDatesStatus = "IDLE";
    },
  },

  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  ////////// add to cart
  // setAddToCartLoading,
  setDisabledAddToCart,
  setAddToCartRequestStatus,

  ////////// cart status
  startCartLoading,
  stopCartLoading,
  setIsCartEmpty,
  saveCountQuantity,

  ////////// cart content
  saveOrderId,
  saveOrderMultidoorList,
  saveOutOfStockItems,
  saveOrderMultidoorAddress,
  saveShippingAddressList,
  saveShippingAddressSelected,
  saveSubOrdersNotesAndPO,
  saveCartTotalPrice,
  saveOtherSkus,
  saveGetOtherSkusStatus,
  saveBackorderItems,
  saveBackorderTrackedItems,

  ////////// handle selections
  initializeSelectedItems,
  toggleSelectedItems,
  toggleSelectedCategory,
  toggleSelectedMultidoor,
  toggleSelectedCart,
  updateSelectedItems,
  saveOrderItemsOnPrivileges,

  ////////// alternative
  saveCartAlternativeVariants,
  setCartAlternativeVariantsLoading,
  saveCartTotalAlternativeVariants,

  ////////// m4c
  saveDetails,
  setDetailsStatus,
  clearDetails,

  ////////// rx prescription Brasil
  setRxBrasilToken,
  setRxBrasilStatus,

  ///////// set delivery
  saveDeliveryDates,
  saveGetDeliveryDatesStatus,
  saveSaveDeliveryDatesStatus,

  ///////// clear
  clearCart,
  clearCartWhenLeavingPage,
  clearDelivery,
} = cartSlice.actions;

////////// add to cart
export const selectAddToCartLoading = (state: RootState): boolean => {
  return state.cart.addToCartLoading;
};

export const selectAddToCartRequestStatus = (state: RootState): RequestStatus => {
  return state.cart.addToCartRequestStatus;
};

export const selectDisabledAddToCart = (state: RootState): boolean => {
  return state.cart.disabledAddToCart;
};

////////// cart status
export const selectCartLoading = (state: RootState): CartLoading[] => {
  return state.cart.cartLoading;
};

export const selectIsCartInitialized = (state: RootState): boolean => {
  return (
    state.cart.isCartEmpty ||
    (state.cart.orderMultidoorList.length !== 0 &&
      state.cart.selectedItems.length !== 0 &&
      state.cart.shippingAddressList?.length !== 0)
  );
};

export const selectIsCartEmpty = (state: RootState): boolean => {
  return state.cart.orderMultidoorList.length === 0;
};

export const selectCountQuantity = (state: RootState): CartCountQuantity | null => {
  return state.cart.countQuantity;
};

////////// cart content
export const selectOrderId = (state: RootState): string => {
  return state.cart.orderId;
};

export const selectOrderMultidoorList = (state: RootState): OrderMultidoor[] => {
  return state.cart.orderMultidoorList;
};

export const selectOutOfStockItems = (state: RootState): OutOfStockItems | null => {
  return state.cart.outOfStockItems;
};
////////// handle selections
export const selectSelectedItems = (state: RootState): OrderMultidoorSelected[] => {
  return state.cart.selectedItems;
};

export const selectOrderItemsOnPrivileges = (state: RootState): OrderMultidoorSelected[] => {
  return state.cart.selectedItemsBasedOnPrivilegs;
};

export const selectSelectedOrderItemsIds = createSelector(
  selectSelectedItems,
  selectOrderMultidoorList,
  (selectedItems: OrderMultidoorSelected[], orderMultidoorList): string[] => {
    const selectedOrderItemsIds: string[] = []; // store all orderItems' ids that appear in selectedItems

    selectedItems.forEach((orderMultidoor: OrderMultidoorSelected) => {
      orderMultidoor.categorySelected.forEach((orderCategory: OrderCategorySelected) => {
        orderCategory.orderItemSelected.forEach((orderItem: OrderItemSelected) => {
          if (orderItem.selected) {
            if (orderItem.singleOrderItemSelected && orderItem.singleOrderItemSelected.length > 0) {
              const mainDoor = orderMultidoorList?.find(
                (door) => door.multidoorId === orderMultidoor.multidoorId
              );
              const category = mainDoor?.categoryList?.find(
                (category) => category.productCategory === orderCategory.productCategory
              );
              const mainOrderItem = category?.orderItemList?.find(
                (item) => item.orderItemId === orderItem.orderItemId
              );

              mainOrderItem?.orders?.forEach((_) => selectedOrderItemsIds.push(_.orderItemId));
            } else {
              selectedOrderItemsIds.push(orderItem.orderItemId); // save the id if item is selected
            }
          }
        });
      });
    });

    return selectedOrderItemsIds;
  }
);

export const selectCartInfo = createSelector(
  selectSelectedItems,
  (selectedItems: OrderMultidoorSelected[]): CartInfo => {
    // map each door adding the number of total and selected items in it
    const orderMultidoorInfo = selectedItems?.map(
      (orderMultidoor: OrderMultidoorSelected): OrderMultidoorInfo => {
        // map each category adding the number of total and selected items in it
        const newOrderCategorySelected = orderMultidoor.categorySelected.map(
          (orderCategory: OrderCategorySelected): OrderCategoryInfo => {
            return {
              productCategory: orderCategory.productCategory,
              productCategoryIdentifier: orderCategory.productCategoryIdentifier,
              multidoorId: orderMultidoor.multidoorId,
              orgentityName: orderMultidoor.orgentityName,
              orderItemSelected: orderCategory.orderItemSelected,
              totalNumber: getTotalNumberCategory(orderCategory.orderItemSelected), // count total items in category
              selectedNumber: getSelectedNumberCategory(orderCategory.orderItemSelected), // count selected items in category
            };
          }
        );

        return {
          multidoorId: orderMultidoor.multidoorId,
          subOrderId: orderMultidoor.subOrderId,
          categorySelected: newOrderCategorySelected,
          orgentityName: orderMultidoor.orgentityName,
          totalNumber: getTotalNumberMultidoor(newOrderCategorySelected), // count total items in multidoor
          selectedNumber: getSelectedNumberMultidoor(newOrderCategorySelected), // count selected items in multidoor
          outOfStockNumber: getTotalOutOfStockNumberMultidoor(newOrderCategorySelected), // count out of stock items in multidoor
        };
      }
    );

    // return an object containing all doors' info + total and selected counters for the whole cart
    return {
      multidoorSelected: orderMultidoorInfo,
      totalNumber: getTotalNumberCart(orderMultidoorInfo), // count total items in cart
      selectedNumber: getSelectedNumberCart(orderMultidoorInfo), // count selected items in cart
      outOfStockNumber: getOutOfStockNumberCart(orderMultidoorInfo), // count out of stock items in cart
    };
  }
);

export const selectHaveAFAOrHelmetCat = createSelector(selectCartInfo, (cartInfo): boolean => {
  let haveAFAOrHelmetCat = false;

  if (cartInfo) {
    cartInfo?.multidoorSelected?.forEach((door) => {
      door.categorySelected.forEach((category) => {
        if (category.productCategoryIdentifier?.toLowerCase() === "afa") {
          haveAFAOrHelmetCat = true;
        }

        if (
          category.productCategoryIdentifier?.toLowerCase() === "goggles&helmets" ||
          category.productCategoryIdentifier?.toLowerCase() === "goggle" ||
          category.productCategoryIdentifier?.toLowerCase() === "helmet"
        ) {
          category.orderItemSelected.forEach((order) => {
            if (order.singleOrderItemSelected && order.singleOrderItemSelected?.length > 0) {
              haveAFAOrHelmetCat = true;
            }
          });
        }
      });
    });
  }

  return haveAFAOrHelmetCat;
});

export const selectCartSummary = createSelector(
  selectOrderItemsOnPrivileges,
  selectOrderMultidoorList,
  (
    selectedItems: OrderMultidoorSelected[],
    orderMultidoorList: OrderMultidoor[]
  ): OrderMultidoorSummary[] | null => {
    const selectedOrderItemsIds: string[] = []; // store all orderItems' ids that appear in selectedItems

    selectedItems.forEach((orderMultidoor: OrderMultidoorSelected) => {
      orderMultidoor.categorySelected.forEach((orderCategory: OrderCategorySelected) => {
        orderCategory.orderItemSelected.forEach((orderItem: OrderItemSelected) => {
          if (orderItem.selected) selectedOrderItemsIds.push(orderItem.orderItemId); // save the id if item is selected
        });
      });
    });

    // map each door
    const orderMultidoorSummary = orderMultidoorList?.map(
      (orderMultidoor: OrderMultidoor): OrderMultidoorSummary => {
        // map each category
        const orderCategorySummary = orderMultidoor.categoryList.map(
          (orderCategory: OrderCategory): OrderCategorySummary => {
            return {
              productCategory: orderCategory.productCategory,
              productCategoryIdentifier: orderCategory.productCategoryIdentifier,
              multidoorId: orderMultidoor.multidoorId,
              subOrderId: orderMultidoor.subOrderId,
              totalPrice: getTotalPriceCategory(orderCategory.orderItemList, selectedOrderItemsIds),
              orgentityName: orderMultidoor.orgentityName,
            };
          }
        );

        return {
          multidoorId: orderMultidoor.multidoorId,
          totalPrice: getTotalPriceMultidoor(orderCategorySummary),
          categoryPrice: orderCategorySummary,
          subOrderId: orderMultidoor.subOrderId,
          orgentityName: orderMultidoor.orgentityName,
        };
      }
    );

    return orderMultidoorSummary ?? null;
  }
);

export const selectOrderMultidoorAddress = (state: RootState): OrderMultidoorAddress[] => {
  return state.cart.orderMultidoorAddress;
};

export const selectShippingAddressList = (state: RootState): ShippingAddressSummaryMultidoor[] => {
  return state.cart.shippingAddressList;
};

export const selectShippingAddressSelected = (
  state: RootState
): ShippingAddressSelectedMultidoor[] => {
  return state.cart.shippingAddressSelected;
};

export const selectSubOrdersNotesAndPO = (state: RootState): SubOrderPayload[] => {
  return state.cart.subOrdersNotesAndPO;
};

export const selectCartTotalPrice = (state: RootState): number | null => {
  return state.cart.cartTotalPrice;
};

export const selectOtherSkus = (state: RootState): OtherSkus => {
  return state.cart.otherSkus;
};

export const selectGetOtherSkusStatus = (state: RootState): RequestStatus => {
  return state.cart.getOtherSkusStatus;
};
export const selectBackorderItems = (state: RootState): Set<string> => {
  return state.cart.backorderItems;
};

export const selectBackorderTrackedItems = (state: RootState): BackorderTrackedItems => {
  return state.cart.backorderTrackedItems;
};

///////// checkout

export const selectPutPrecartShippingInfoPayload = createSelector(
  selectShippingAddressSelected,
  selectOrderMultidoorList,
  (
    shippingAddressSelected: ShippingAddressSelectedMultidoor[],
    orderMultidoorList: OrderMultidoor[]
  ): PutPrecartShippingInfoPayload => {
    const selectedAddresses = shippingAddressSelected.map((address) => {
      const orderItem = orderMultidoorList.filter((_) => _.multidoorId === address.multidoorId)?.[0]
        .categoryList?.[0]?.orderItemList?.[0];
      let orderItemId = orderItem?.orderItemId;

      //check for afa order items
      if (orderItem.orders && orderItem.orders.length > 0) {
        orderItemId = orderItem.orders[0].orderItemId;
      }

      return {
        addressId: address.addressId,
        orderItemId: orderItemId,
      };
    });

    return {
      orderItem: selectedAddresses,
    };
  }
);

export const selectCheckoutItems = createSelector(
  selectOrderId,
  selectSelectedOrderItemsIds,

  (orderId: string, selectedOrderItems: string[]): CheckoutItems => {
    return {
      orderId: orderId,
      orderItem: selectedOrderItems,
    };
  }
);

////////// alternative
export const selectCartAlternativeVariants = (state: RootState): Variant[] | null => {
  return state.cart.cartAlternativeVariants;
};

export const selectCartTotalAlternativeVariants = (state: RootState): number => {
  return state.cart.cartTotalAlternativeVariants;
};

export const selectCartAlternativeVariantsLoading = (state: RootState): boolean => {
  return state.cart.cartAlternativeVariantsLoading;
};

////////// m4c
export const selectDetails = (state: RootState): Details => {
  return state.cart.details;
};

export const selectDetailsStatus = (state: RootState): DetailsStatus => {
  return state.cart.detailsStatus;
};

////////// rx prescription Brasil
export const selectIsRxPrescriptionUploadActive = createSelector(
  selectActiveDoor,
  selectUsersPrivileges,
  (activeDoor, usersPrivileges): boolean => {
    const users = usersPrivileges?.filter(
      (user) => user?.orgentityName === activeDoor?.orgentityName
    );

    if (users.length > 0)
      return users?.[0]?.privileges?.some(
        (userPriv: string) => userPriv === "RX_PRESCRIPTION_UPLOAD"
      );
    else return false;
  }
);

export const selectRxBrasilCheckPayload = createSelector(
  selectOrderMultidoorList,
  selectIsRxPrescriptionUploadActive,
  (orderMultidoorList: OrderMultidoor[], isActive: boolean): string[] => {
    if (!isActive) return []; // avoid any operation if the result is not relevant

    const rxOrderItems: string[] = []; // store all orderItems' ids that correspond to RX items

    orderMultidoorList?.forEach((orderMultidoor: OrderMultidoor) => {
      orderMultidoor?.categoryList?.forEach((orderCategory: OrderCategory) => {
        orderCategory?.orderItemList?.forEach((orderItem: OrderItem) => {
          if (isOrderItemRx(orderItem) && !orderItem.outOfStock)
            rxOrderItems.push(`${orderMultidoor?.orgentityName}/${orderItem?.orderItemId}`);
        });
      });
    });

    return rxOrderItems;
  }
);

export const selectRxBrasilToken = (state: RootState): string => {
  return state.cart.rxBrasilToken;
};

export const selectRxBrasilStatus = (state: RootState): RxPrescriptionBrasilStatus[] => {
  return state.cart.rxBrasilStatus;
};

export const selectIsRxBrasilValidated = createSelector(
  selectRxBrasilStatus,
  selectSelectedOrderItemsIds,
  selectRxBrasilCheckPayload,
  selectIsRxPrescriptionUploadActive,
  (
    rxBrasilStatus: RxPrescriptionBrasilStatus[],
    selectedOrderItems: string[],
    rxOrderItems: string[],
    isActive: boolean
  ) => {
    if (!isActive) return true; // if it's not active, it's automatically validated

    // otherwise, for all selected order items, their status needs to be "SUCCESS" (ie. they have a prescription uploaded successfully)
    return rxOrderItems?.every((orderItemId) => {
      const isSelected = selectedOrderItems.includes(orderItemId?.split("/")?.pop() ?? ""); // check if current order item is selected
      if (!isSelected) return true; // if it's not, it's status is irrelevant

      // otherwise, check their status
      const status = rxBrasilStatus.filter((_) => _.rxBrasilId === orderItemId)?.[0];
      return status?.requestStatus === "SUCCESS";
    });
  }
);

//////////////////// AFA VALIDATION
export const selectIsAFAValidated = createSelector(selectSelectedItems, (selectedItems) => {
  let haveCorrectQuantity = true;

  selectedItems.forEach((multidoor) => {
    multidoor.categorySelected.forEach((category) => {
      category.orderItemSelected.forEach((orderItem) => {
        if (
          orderItem.selected &&
          orderItem.singleOrderItemSelected !== undefined &&
          orderItem.singleOrderItemSelected.length > 0
        ) {
          orderItem.singleOrderItemSelected.forEach((item) => {
            if (
              item.quantity &&
              item.availableQuantity !== undefined &&
              item.quantity > parseInt(item.availableQuantity)
            ) {
              haveCorrectQuantity = false;
            }
          });
        }
      });
    });
  });
  return haveCorrectQuantity;
});

/////////////////// SET DELIVERY

export const selectDeliveryDates = (state: RootState): DeliveryDates[] => {
  return state.cart.deliveryDates;
};

export const selectGetDeliveryDatesStatus = (state: RootState): RequestStatus => {
  return state.cart.getDeliveryDatesStatus;
};

export const selectSaveDeliveryDatesStatus = (state: RootState): RequestStatus => {
  return state.cart.saveDeliveryDatesStatus;
};

export default cartSlice.reducer;
