import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useCanRender from "../../../../hooks/useCanRender";
import useTranslation from "../../../../hooks/useTranslation";

import { MostUsedChip, MostUsedChipLocal } from "../../../../interfaces/mostUsedInterfaces";
import { getPagePath } from "../../../../routing/routesUtils";
import {
  selectActiveDoor,
  selectIsSubuser,
  selectMostUsedContent,
  selectMostUsedLoading,
} from "../../../../store/user/userSlice";
import { useCanUrlBeRendered } from "../../../../utils/cmsUtils";
import {
  generateServicesAndMyAccountChips,
  getBrandsChipFromId,
  getCatalogueChipFromMenu,
  getEssilorChipFromId,
  getLensesChipFromMenu,
  getSelectedChips,
  getServicesMyAccountChipFromId,
  sortChips,
} from "../../../../utils/mostUsedUtils";
import useCategoriesData from "./useCategoriesData";
import { selectBrandAndEssilor } from "../../../../store/store/storeSlice";

export interface ChipsHandler {
  availableChips:
    | {
        mostUsedAccount: MostUsedChipLocal[];
        mostUsedService: MostUsedChipLocal[];
      }
    | undefined;
  selectedChips: MostUsedChipLocal[];
  selectChip: (chip: MostUsedChip) => void;
  deselectChip: (chip: MostUsedChip) => void;
  isLoading: boolean;
}

const useSelectedMostUsed = (): ChipsHandler => {
  const { labels, translateLabel } = useTranslation();
  const canRender = useCanRender();

  const mostUsedChips = useSelector(selectMostUsedContent);
  const { get: mostUsedLoading } = useSelector(selectMostUsedLoading);

  const isSubuser = useSelector(selectIsSubuser);
  const mainDoor = useSelector(selectActiveDoor);
  const brandAndEssilor = useSelector(selectBrandAndEssilor);
  const { canUrlBeRendered } = useCanUrlBeRendered();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////// AVAILABLE SERVICES&MYACCOUNT CHIPS ///////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  /////////////////// Services&MyAccount chips available for selection in the popup
  /////////////////// (only those for which the privileges are active)
  const [availableServicesMyAccountChips, setAvailableServicesMyAccountChips] = useState<{
    mostUsedAccount: MostUsedChipLocal[];
    mostUsedService: MostUsedChipLocal[];
  }>();

  useEffect(() => {
    const { mostUsedAccount, mostUsedService } = generateServicesAndMyAccountChips(
      canRender,
      translateLabel,
      isSubuser,
      mainDoor
    ); //account e services
    setAvailableServicesMyAccountChips({
      mostUsedAccount: sortChips(mostUsedAccount),
      mostUsedService: sortChips(mostUsedService),
    });
  }, [labels]);

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// SELECTED CHIPS /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  // selected chips displayed in HP and in the selection area of the popup
  const [selectedChips, setSelectedChips] = useState<MostUsedChipLocal[]>([]);

  // // get third-level/lenses menu data for categories present in most used's bookmarks
  const categoriesData = useCategoriesData();

  useEffect(() => {
    if (
      mostUsedLoading !== "LOADING" && // got selected data from API
      availableServicesMyAccountChips && // computed possible services&myaccount bookmarks
      mostUsedChips.unused === false &&
      !categoriesData.isCategoriesLoading // downloaded third-level menu for all relevant categories
    ) {
      // generate local chips from the API's data by:
      //  - adding urls + labels for Services&MyAccount bookmarks
      //  - reverse-engineering the labels for Catalogue bookmarks from the third-level menu
      const newSelectedChips = getSelectedChips(
        mostUsedChips.bookmarks,
        [
          ...availableServicesMyAccountChips.mostUsedAccount,
          ...availableServicesMyAccountChips.mostUsedService,
        ],
        categoriesData,
        brandAndEssilor
      );

      const availableChipsToSelect = newSelectedChips
        .filter((item) => canUrlBeRendered(getPagePath(item.url)))
        .slice(0, 5);

      setSelectedChips(availableChipsToSelect);
      setIsLoading(false);
    }
  }, [
    mostUsedChips,
    mostUsedLoading,
    availableServicesMyAccountChips,
    categoriesData,
    brandAndEssilor,
  ]);

  /////////////////// exported helper functions
  const selectChip = (chip: MostUsedChip) => {
    let newChip: MostUsedChipLocal | undefined = undefined;
    switch (chip.type) {
      case "Catalogue":
        newChip = getCatalogueChipFromMenu(chip);
        break;
      case "Lenses":
        newChip = getLensesChipFromMenu(chip);
        break;
      case "Brands":
        newChip = getBrandsChipFromId(chip);
        break;
      case "Essilor":
        newChip = getEssilorChipFromId(chip);
        break;
      default:
        newChip = getServicesMyAccountChipFromId(
          chip.name,
          availableServicesMyAccountChips
            ? [
                ...availableServicesMyAccountChips.mostUsedAccount,
                ...availableServicesMyAccountChips.mostUsedService,
              ]
            : []
        );
    }

    setSelectedChips((prevItems) => {
      if (newChip)
        if (prevItems.some((chip) => chip.name === newChip?.name)) return prevItems;
        else return [...prevItems, newChip];
      return prevItems;
    });
  };

  const deselectChip = (chip: MostUsedChip) =>
    setSelectedChips((old) => old.filter((_) => _.name !== chip.name));

  return useMemo(() => {
    return {
      availableChips: availableServicesMyAccountChips,
      selectedChips,
      selectChip,
      deselectChip,
      isLoading: isLoading && mostUsedChips.unused === false,
    };
  }, [mostUsedChips, availableServicesMyAccountChips, selectedChips, selectChip, deselectChip]);
};

export default useSelectedMostUsed;
