import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import CustomText from "../styled-UI/CustomText";
import LinkButton from "../styled-UI/LinkButton";
import { PreplpContent } from "../../interfaces/cmsInterfaces";
import VideoComponent from "../styled-UI/VideoComponent";
import { getAnalyticsTagId, getMultiMedia, useHandleCmsLink } from "../../utils/cmsUtils";
import useDataHref from "../../hooks/useDataHref";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../store/store/storeInterfaces";
import { selectPrePLPBrandGroup } from "../../store/catalogue/catalogueSlice";
import { useSelector } from "react-redux";
import { selectBrandGroups } from "../../store/store/storeSlice";
import useCanRender from "../../hooks/useCanRender";
import useTranslation from "../../hooks/useTranslation";

interface Props {
  data: PreplpContent;
  customFont?: FontFamilies | null;
}

const PrePLPLensesSection = ({ data, customFont = null }: Props): JSX.Element => {
  const { handleCmsLink } = useHandleCmsLink();
  const {
    title,
    titleColour,
    linkLabel,
    linkString,
    linkLabelColour,
    externalLink,
    openInNewTab,
    multiMediaType,
    description,
    description1Colour,
  } = data;
  const { srcImg, srcVideo, coverImg, placement } = getMultiMedia(multiMediaType);
  const descrWithHref = useDataHref({ content: description });
  const analyticsTag = getAnalyticsTagId(data, data.id);
  const canRender = useCanRender();
  const { translateLabel } = useTranslation();

  //Privileges management
  const prePLPbrandGroup = useSelector(selectPrePLPBrandGroup);
  const brandGroups = useSelector(selectBrandGroups);
  const [isRxAble, setIsRxAble] = useState<boolean>(false);

  const handleRedirect = (): void => {
    handleCmsLink(linkString, externalLink, openInNewTab);
  };

  useEffect(() => {
    if (prePLPbrandGroup && brandGroups) {
      const brandIds = brandGroups[prePLPbrandGroup].map((brand) => brand.brand);
      const tempRxable = brandIds.some(
        (brandId) =>
          canRender("RX_COMPLETE_JOB_" + brandId) ||
          canRender("RX_FRAME_TO_COME_" + brandId) ||
          canRender("RX_LENS_ONLY_" + brandId)
      );
      tempRxable && setIsRxAble(tempRxable);
    }
  }, [prePLPbrandGroup, brandGroups]);

  const renderViewAll = () => {
    return linkLabel && linkString ? (
      <ViewAll>
        <LinkButton
          data-element-id="Categories_Lenses_ViewAll"
          uppercase
          onClick={handleRedirect}
          customColor={linkLabelColour}
          customFont={customFont}
        >
          {linkLabel}
        </LinkButton>
      </ViewAll>
    ) : null;
  };

  return isRxAble ? (
    <Container className="lenses-ref-div">
      {title && (
        <Title>
          <CustomText
            as="span"
            fontSizePx={28}
            font={customFont ? "custom-medium" : "font-medium"}
            fontFamily={customFont}
            customColor={titleColour}
          >
            {title}
          </CustomText>
        </Title>
      )}
      <Content>
        {multiMediaType && (
          <BigColumn>
            {srcImg && !srcVideo && <Image src={srcImg} />}
            {srcVideo && (
              <VideoComponent
                srcVideo={srcVideo}
                coverImage={coverImg}
                previewMode={coverImg ? "cover" : "first-frame"}
                placement={placement}
                data-element-id="LensBanners"
                data-description={`${analyticsTag}_${data.name}`}
              />
            )}
          </BigColumn>
        )}
        {description && (
          <TextContainer>
            <CustomText
              as="span"
              fontSizePx={14}
              font={customFont ? "custom-regular" : "font-regular"}
              fontFamily={customFont}
              marginBottomPx={16}
              customColor={description1Colour}
            >
              <SetInnerHTMLWithSanitize text={descrWithHref.updatedContent ?? description} />
            </CustomText>
            {renderViewAll()}
          </TextContainer>
        )}
      </Content>
    </Container>
  ) : (
    <></>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: 5.5rem;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.25rem;
`;

const ViewAll = styled.div`
  margin-right: 1.25rem;
  cursor: pointer;
  position: absolute;
  bottom: 1rem;

  button {
    font-family: ${(props) => props.theme.fonts.fontMedium};
  }
`;

const Content = styled.div`
  display: flex;
`;

const BigColumn = styled.div`
  width: 52rem;
  max-height: 39rem;

  @media ${(props) => props.theme.queries.md} {
    width: 70%;
  }
`;

const Image = styled.img`
  width: 52rem;
  height: 39rem;
  object-fit: cover;

  @media ${(props) => props.theme.queries.md} {
    width: 70%;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  margin-left: 1.5rem;
  padding-top: 2.9375rem;
  padding-left: 2.5rem;
  padding-right: 4.9375rem;
  border-top: solid 1px ${(props) => props.theme.palette.primary};
  border-bottom: solid 1px ${(props) => props.theme.palette.primary};
  box-sizing: border-box;
  position: relative;

  h3 {
    margin-bottom: 1.6875rem;
    font-family: "Gilmer Medium";
    font-size: 2rem;
    line-height: 1;
  }

  @media ${(props) => props.theme.queries.md} {
    padding-top: 1.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export default PrePLPLensesSection;
