import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import useCanRender from "../../../hooks/useCanRender";
import useDisplayPrice from "../../../hooks/useDisplayPrice";
import useTranslation from "../../../hooks/useTranslation";
import { SelectList } from "../../../store/rx/rxInterface";
import { selectRxPrices } from "../../../store/rx/rxSlice";
import {
  selectActiveDoor,
  selectCurrencyFormat,
  selectIsSubuser,
  selectSelectedDoor,
} from "../../../store/user/userSlice";
import { currencySymbol } from "../../../utils/utils";

import CustomText from "../../styled-UI/CustomText";
import CurrencyFormatter from "../CurrencyFormatter";
import Tooltip from "../../styled-UI/Tooltip";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info-icon-filled.svg";

interface Props {
  selectList: SelectList;
}

export default function AdditionalInfo({ selectList }: Props): JSX.Element | null {
  const { translateLabel } = useTranslation();
  const { hideAll, hideWholesale, hideSuggested } = useDisplayPrice();
  const isSubuser = useSelector(selectIsSubuser);
  const selectedDoor = useSelector(selectSelectedDoor);
  const mainDoor = useSelector(selectActiveDoor);
  const canRender = useCanRender();

  const rxPrices = useSelector(selectRxPrices);
  const currencyFormat = useSelector(selectCurrencyFormat);

  const [priceTypes, setPriceTypes] = useState<string[]>(["Lens"]);

  useEffect(() => {
    if (selectList.jobType?.value !== "RX_LENS_ONLY" && priceTypes.length < 2) {
      const newPriceTypes = priceTypes.concat("Frame");
      setPriceTypes(newPriceTypes);
    }
  }, []);

  const framePrices = rxPrices?.find((price) => !price.itemIdentifier.includes("LSA"));
  const lensPrices = rxPrices?.find((price) => price.itemIdentifier.includes("LSA"));

  const currencyFromPrice = framePrices?.currency || lensPrices?.currency;

  if (hideAll) return null;
  return (
    <ContentContainer>
      <CustomText as="h3" font="font-bold" fontSizePx={18} lineHeightPx={24} marginBottomPx={8}>
        {translateLabel("RX_PRICE_TITLE")}
      </CustomText>
      <div className="title-row d-flex">
        {!hideWholesale && (
          <div className="col-price">
            <CustomText
              as="p"
              font="font-regular"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={16}
            >
              {`${translateLabel("RX_WHS_PRICE")} (${currencySymbol(
                currencyFormat.currency.opt,
                currencyFormat.locale
              )})`}
            </CustomText>
          </div>
        )}
        {!hideSuggested && (
          <div className="col-price">
            <CustomText
              as="p"
              font="font-regular"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={16}
            >
              {`${translateLabel("RX_SR_PRICE")} (${currencySymbol(
                currencyFormat.currency.pub,
                currencyFormat.locale
              )})`}
              <SvgContainer>
                <Tooltip
                  content={
                    <TooltipContent>{translateLabel("SRP_DISCLAIMER_DESCRIPTION")}</TooltipContent>
                  }
                >
                  <div className="svg-container">
                    <InfoIcon />
                  </div>
                </Tooltip>
              </SvgContainer>
            </CustomText>
          </div>
        )}
      </div>
      {priceTypes.map((price) => {
        return (
          <div className="d-flex" key={price}>
            <div className="col-label">
              <CustomText
                as="p"
                font="font-medium"
                fontSizePx={13}
                lineHeightPx={18}
                marginBottomPx={8}
              >
                {translateLabel(`RX_${price.toUpperCase()}_PRICE_AMOUNT`)}:
              </CustomText>
            </div>
            {!hideWholesale && (
              <div className="col-price">
                <CustomText
                  as="p"
                  font="font-medium"
                  fontSizePx={13}
                  lineHeightPx={18}
                  marginBottomPx={8}
                >
                  <CurrencyFormatter
                    currency={currencyFromPrice || currencyFormat.currency.opt}
                    locale={currencyFormat.locale}
                    value={
                      price === "Frame"
                        ? selectList.jobType?.value === "RX_FRAME_TO_COME"
                          ? 0
                          : framePrices?.whsPrice ?? 0
                        : lensPrices?.whsPrice
                    }
                  />
                </CustomText>
              </div>
            )}
            {!hideSuggested && (
              <div className="col-price">
                <CustomText
                  as="p"
                  font="font-medium"
                  fontSizePx={13}
                  lineHeightPx={18}
                  marginBottomPx={8}
                >
                  <CurrencyFormatter
                    currency={currencyFromPrice || currencyFormat.currency.opt}
                    locale={currencyFormat.locale}
                    value={
                      price === "Frame"
                        ? selectList.jobType?.value === "RX_FRAME_TO_COME"
                          ? 0
                          : framePrices?.srPrice ?? 0
                        : lensPrices?.srPrice
                    }
                  />
                </CustomText>
              </div>
            )}
          </div>
        );
      })}
      <div className="d-flex">
        <div className="col-label">
          <CustomText
            as="p"
            font="font-bold"
            fontSizePx={13}
            lineHeightPx={18}
            marginBottomPx={32}
            marginTopPx={8}
          >
            {translateLabel("RX_PRICE_TOTAL")}
          </CustomText>
        </div>
        {!hideWholesale && (
          <div className="col-price">
            <CustomText
              as="p"
              font="font-bold"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={32}
            >
              {lensPrices && (
                <CurrencyFormatter
                  currency={currencyFromPrice || currencyFormat.currency.opt}
                  locale={currencyFormat.locale}
                  value={
                    framePrices?.whsPrice && selectList.jobType?.value === "RX_COMPLETE_JOB"
                      ? framePrices.whsPrice + lensPrices.whsPrice
                      : lensPrices.whsPrice
                  }
                />
              )}
            </CustomText>
          </div>
        )}

        {!hideSuggested && (
          <div className="col-price">
            <CustomText
              as="p"
              font="font-bold"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={32}
            >
              {lensPrices && (
                <CurrencyFormatter
                  currency={currencyFromPrice || currencyFormat.currency.opt}
                  locale={currencyFormat.locale}
                  value={
                    framePrices?.srPrice && selectList.jobType?.value === "RX_COMPLETE_JOB"
                      ? framePrices.srPrice + lensPrices.srPrice
                      : lensPrices.srPrice
                  }
                />
              )}
            </CustomText>
          </div>
        )}
      </div>
      {selectList.jobType?.value === "RX_FRAME_TO_COME" && (
        <CustomText
          as="p"
          font="font-light"
          color="gray-dark"
          fontSizePx={14}
          lineHeightPx={24}
          marginBottomPx={32}
        >
          {translateLabel("RX_PRICE_NOTE")}
        </CustomText>
      )}
      {(isSubuser ? canRender("RX_CARNET", mainDoor) : true) &&
        canRender("RX_CARNET", selectedDoor) && (
          <div className="notice-container">
            <CustomText as="p" font="font-bold" fontSizePx={13} lineHeightPx={18}>
              {translateLabel("RX_PRICE_NOTE2")}
            </CustomText>
          </div>
        )}
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  .title-row {
    margin-left: 13.5rem;
  }
  .col-label {
    width: 13.5rem;
    text-transform: capitalize;
  }
  .col-price {
    width: 11.5rem;
  }

  .notice-container {
    background-color: ${(props) => props.theme.palette.gray.light};
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
`;

const SvgContainer = styled.span`
  .svg-container {
    position: relative;

    :hover > svg {
      opacity: 1;
    }

    > svg {
      margin: 0 0 0 0.375rem;
      vertical-align: text-bottom;
      opacity: 0.4;
      width: 14px !important;
      height: 14px !important;
    }
  }
`;

const TooltipContent = styled.div`
  max-width: 180px;
  font-family: ${(props) => props.theme.fonts.fontMedium};
  font-size: 0.813rem;
`;
