import React from "react";
import styled from "styled-components/macro";
import SingleIconCard from "./SingleIconCard";
import { IconCardItem } from "../../../interfaces/cmsInterfaces";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  isDarkMode?: boolean;
  data: IconCardItem;
  customFont?: FontFamilies | null;
}

const IconCardHorizontal = ({
  isDarkMode = false,
  data,
  customFont = null,
}: Props): JSX.Element => {
  return (
    <Container isDarkMode={isDarkMode}>
      <SingleIconCard
        data={data}
        layout="horizontal"
        isDarkMode={isDarkMode}
        customFont={customFont}
      />
    </Container>
  );
};

export default IconCardHorizontal;

const Container = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 1rem 0;
`;
