import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CustomText from "./CustomText";
import styled from "styled-components/macro";
import clsx from "clsx";
import useTranslation from "../../hooks/useTranslation";
import useGenerateKeyFromObject from "../../hooks/useGenerateKeyFromObject";

import { UploadedFile } from "../../store/warranty-wizard/warrantyWizardInterface";
import { asyncConvertBase64 } from "../../utils/utils";
import {
  FILE_EXT_BY_TYPE,
  formatBytes,
  isFileTooBig,
  isFileTypeSupported,
} from "../../utils/uploadFileUtils";
import Button from "./Button";
import Chip, { ChipStyle } from "./Chip";

import { ReactComponent as UploadIcon } from "../../assets/icons/upload-icon.svg";

type Direction = "column" | "row";

interface Props {
  imageLabel: string;
  uploadedFile: UploadedFile[];
  setUploadedFile: Dispatch<SetStateAction<UploadedFile[]>>;
  showRequiredError: boolean;
  direction?: Direction;
  fileTypesSupported?: string[];
  maxFileNumber?: number;
  fileNameMaxLength?: number;
  fileMaxSize?: number;
  hasWhiteBackground?: boolean;
  isMandatory?: boolean;
  singleFileReplaceMode?: boolean;
  hideChips?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  showTypesSupported?: boolean;
  chipStyle?: ChipStyle;
  errorCallback?: (error: string) => void;
  "data-element-id"?: string;
}

//TODO GESTIONE ERRORI E FILE CON LO STESSO NOME
const UploadFiles = ({
  uploadedFile,
  setUploadedFile,
  imageLabel,
  direction = "row",
  fileTypesSupported,
  maxFileNumber,
  showRequiredError,
  fileNameMaxLength = 0,
  fileMaxSize,
  hasWhiteBackground = false,
  isMandatory = false,
  singleFileReplaceMode = false,
  hideChips = false,
  isLoading = false,
  disabled = false,
  showTypesSupported = false,
  chipStyle = "border",
  errorCallback,
  "data-element-id": dataElementId,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const keyGen = useGenerateKeyFromObject();

  const dropRef = React.useRef<HTMLDivElement>(null);
  const uploadRef = React.useRef<HTMLInputElement>(null);

  const [showErrorMessage, setShowErrorMessage] = useState<string>("");

  useEffect(() => {
    errorCallback?.(showErrorMessage);
  }, [showErrorMessage]);

  useEffect(() => {
    if (showRequiredError) setShowErrorMessage("REQUIRED_FIELD");
    else setShowErrorMessage("");
  }, [showRequiredError]);

  // if format is supported, set file data as File, blob url, and base64
  const setFileData = (file: File) => {
    if (!singleFileReplaceMode && maxFileNumber && uploadedFile.length >= maxFileNumber) {
      setShowErrorMessage("FILE_INPUT_ERROR_MAX_NUMBER");
      return;
    }

    if (
      fileTypesSupported &&
      fileTypesSupported?.length > 0 &&
      !isFileTypeSupported(file.type, fileTypesSupported)
    ) {
      setShowErrorMessage("FILE_INPUT_ERROR_FORMAT");
      // console.log("[debug][error] file type", file.type, "not supported");
      return;
    }

    if (isFileTooBig(file, fileMaxSize)) {
      setShowErrorMessage("FILE_INPUT_ERROR_SIZE");
      return;
    }

    if (fileNameMaxLength !== 0 && file.name.length > fileNameMaxLength) {
      setShowErrorMessage("FILE_INPUT_ERROR_TOO_LONG");
      return;
    }

    //clean errors
    // if (showErrorMessage) setShowErrorMessage("");

    const currentFile = {
      base64: "",
      blobSrc: URL.createObjectURL(file),
      file: file,
    };

    setUploadedFile((oldFiles): UploadedFile[] => {
      if (singleFileReplaceMode) return [currentFile];
      else return [...oldFiles, currentFile];
    });

    asyncConvertBase64(file, (base64) =>
      setUploadedFile((oldFiles): UploadedFile[] => {
        const fileToUpdate = oldFiles.find(
          (_) => _?.file?.name === currentFile.file.name && _?.blobSrc === currentFile.blobSrc
        );
        if (fileToUpdate) fileToUpdate.base64 = base64;
        return [...oldFiles];
      })
    );

    if (uploadRef?.current?.value) {
      uploadRef.current.value = "";
    }
  };

  ////////////////////// click handlers

  const handleFileUploadClick = () => {
    setShowErrorMessage("");
    uploadRef.current && uploadRef.current.click();
  };

  const handleFileDeleteClick = (uploadedFile: UploadedFile) => {
    setUploadedFile((oldFiles): UploadedFile[] => {
      const removeFile = oldFiles.filter(
        (_) => !(_?.file?.name === uploadedFile?.file?.name && _?.blobSrc === uploadedFile?.blobSrc)
      );
      //clean errors
      if (removeFile.length === 0) setShowErrorMessage("");
      return removeFile;
    });

    if (uploadRef.current) uploadRef.current.value = "";

    //clean errors
    if (showErrorMessage === "FILE_INPUT_ERROR_MAX_NUMBER") setShowErrorMessage("");
  };

  ////////////////////// event handlers

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.currentTarget?.files?.[0];
    file && setFileData(file);
  };

  return (
    <Container ref={dropRef}>
      <RowWrapper direction={direction}>
        <ButtonWrapper
          direction={direction}
          className={clsx(
            hasWhiteBackground && "button-wrapper-border",
            showErrorMessage && "button-wrapper-error"
          )}
        >
          <TitleWrapper>
            <CustomText as="span" font="font-medium" fontSizePx={13} color="primary">
              {imageLabel.includes("TRANSLATE:")
                ? translateLabel(imageLabel.replace("TRANSLATE:", ""))
                : imageLabel}{" "}
              {isMandatory && "*"}
            </CustomText>
          </TitleWrapper>

          <Button
            type="secondary"
            startIcon={<UploadIcon />}
            disabled={
              (!singleFileReplaceMode &&
                !!maxFileNumber &&
                uploadedFile.length === maxFileNumber) ||
              disabled
            }
            onClick={handleFileUploadClick}
            isLoading={isLoading}
            {...(dataElementId ? { "data-element-id": dataElementId } : {})}
          >
            {translateLabel("FILE_INPUT_UPLOAD_BUTTON")}
          </Button>

          <MaskedInput
            type="file"
            id="hidden-upload"
            ref={uploadRef}
            onChange={handleFileSelect}
            accept={fileTypesSupported?.join(", ")}
          />
        </ButtonWrapper>

        {showErrorMessage && (
          <CustomText as="span" font="font-medium" fontSizePx={13} color={"red"} uppercase>
            {translateLabel(showErrorMessage)
              .replace("{MAX_FILE_NAME_LENGTH}", String(fileNameMaxLength))
              .replace("{MAX_FILE_SIZE}", formatBytes(fileMaxSize ?? 0))}
          </CustomText>
        )}
        {showTypesSupported && (
          <CustomText as="p" fontSizePx={11} color="gray-dark">
            {translateLabel("SUPPORTED_FILE_TYPE_NOTICE").replace(
              "{SUPPORTED_FILE_TYPES}",
              fileTypesSupported
                ?.map((type) => "." + FILE_EXT_BY_TYPE[type as keyof typeof FILE_EXT_BY_TYPE])
                ?.join(",") ?? ""
            )}
          </CustomText>
        )}
      </RowWrapper>

      {!hideChips && uploadedFile.length > 0 && (
        <ChipsContainer direction={direction}>
          {uploadedFile.map((_) => (
            <Chip
              key={keyGen.getKey(_)}
              text={_?.file?.name ?? ""}
              onClick={() => handleFileDeleteClick(_)}
              size="sm"
              active={true}
              style={chipStyle}
            />
          ))}
        </ChipsContainer>
      )}
    </Container>
  );
};

const Container = styled.div``;

const RowWrapper = styled.div<{ direction: Direction }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  width: ${(props) => (props.direction === "row" ? "max-content" : "100%")};
  justify-content: space-between;
  column-gap: 2rem;
  row-gap: 0.5rem;
  align-items: ${(props) => (props.direction === "row" ? "center" : "start")};
`;

const ButtonWrapper = styled.div<{ direction: Direction }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
  align-items: center;
  padding: 6px;
  width: ${(props) => (props.direction === "row" ? "max-content" : "100%")};
  min-width: 20rem;

  &.button-wrapper-border {
    border: 1px solid ${(props) => props.theme.palette.gray.medium};
  }

  &.button-wrapper-error {
    border: solid 1px ${(props) => props.theme.palette.feedback.error};
  }
`;

const TitleWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 2rem;
`;

const MaskedInput = styled.input`
  display: none;
`;

const ChipsContainer = styled.div<{ direction: Direction }>`
  display: flex;
  flex-direction: ${(props) => (props.direction === "row" ? "column" : "row")};
  gap: 0.5rem;
  margin-top: ${(props) => (props.direction === "row" ? "2rem" : "0.5rem")};

  & > div {
    width: max-content;
  }
`;

export default UploadFiles;
