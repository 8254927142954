import React from "react";
import styled from "styled-components/macro";
import useDataHref from "../../../hooks/useDataHref";
import { Banner } from "../../../interfaces/cmsInterfaces";
import { getAnalyticsTagId, useHandleCmsLink } from "../../../utils/cmsUtils";
import CustomText from "../../styled-UI/CustomText";
import SetInnerHTMLWithSanitize from "../SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../../store/store/storeInterfaces";
import Button from "../../styled-UI/Button";
import useTranslation from "../../../hooks/useTranslation";

interface Props {
  bannerData: Banner;
  customFont?: FontFamilies | null;
}

const PLPTopBanner = ({ bannerData, customFont = null }: Props): JSX.Element => {
  const img = bannerData.multiMediaType ? bannerData.multiMediaType[0].data?.uri : "";
  const {
    description2,
    description2Colour,
    description,
    description1Colour,
    title,
    titleColour,
    name,
    id,
    linkString,
    linkLabel,
    linkLabelColour,
  } = bannerData;
  const contentWithHref = useDataHref({ content: description });
  const content2WithHref = useDataHref({ content: description2 });
  const analyticsTag = getAnalyticsTagId(bannerData, bannerData.id);
  const { handleCmsLink } = useHandleCmsLink();
  const { translateLabel } = useTranslation();

  return (
    <Container
      img={img}
      data-element-id={`Banners_${name}`}
      data-description={`${analyticsTag}_${name}`}
    >
      <Content>
        {description2 && (
          <CustomText
            as="div"
            font={customFont ? "custom-light" : "font-light"}
            fontFamily={customFont}
            fontSizePx={20}
            color="white"
            marginBottomPx={12}
            customColor={description2Colour}
          >
            <SetInnerHTMLWithSanitize text={content2WithHref.updatedContent ?? description2} />
          </CustomText>
        )}
        {description && (
          <CustomText
            as="div"
            font={customFont ? "custom-light" : "font-light"}
            fontFamily={customFont}
            fontSizePx={20}
            color="white"
            marginBottomPx={12}
            customColor={description1Colour}
          >
            <SetInnerHTMLWithSanitize text={contentWithHref.updatedContent ?? description} />
          </CustomText>
        )}
        {title && (
          <CustomText
            as="div"
            font={customFont ? "custom-light" : "font-light"}
            fontFamily={customFont}
            fontSizePx={60}
            color="white"
            lineHeightPx={62}
            customColor={titleColour}
          >
            {title}
          </CustomText>
        )}
      </Content>
      {linkString && (
        <ButtonContainer color={linkLabelColour}>
          <Button
            type="primary"
            onClick={() => handleCmsLink(linkString)}
            data-element-id={`Banners_Top-Banner${
              window.location.pathname.includes("preplp") ? "" : "-1"
            }`}
            data-description={`${id}_${name.replace(/ /g, "")}`}
          >
            {linkLabel || translateLabel("DISCOVER_MORE_CTA_LABEL")}
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{ img: string }>`
  height: 16.25rem;
  ${(props) => props.img && "background-image: url(" + props.img + ");"}
  background-size: cover;
`;

const Content = styled.div`
  padding-top: 4.625rem;
  padding-left: 4.25rem;
`;

const ButtonContainer = styled.div<{ color?: string }>`
  float: right;
  padding-right: 2rem;
  padding-top: 7rem;

  button {
    ${(props) => props.color && "background-color: " + props.color + " !important"}
  }
`;

export default PLPTopBanner;
