import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import CustomText from "../../styled-UI/CustomText";
import IconButton from "../../styled-UI/IconButton";
import { ReactComponent as MinusIcon } from "../../../assets/icons/minus-icon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus-icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x-icon.svg";
import clsx from "clsx";
import { AFASku } from "../../../store/catalogue/catalogueInterface";
import useTranslation from "../../../hooks/useTranslation";
import { OrderItem } from "../../../store/cart/cartInterfaces";
import {
  checkIsAFAAccessories,
  checkIsAFAApparel,
  checkIsAFAFootwear,
  checkIsHelmetFromAttributes,
  printAvailableQuantity,
} from "../../../utils/AFAutils";
import {
  saveIsUpdatingQuantityAFA,
  selectCurrentVariant,
  selectIsUpdatingQuantityAFA,
} from "../../../store/catalogue/catalogueSlice";
import { useDispatch, useSelector } from "react-redux";
import AvailabilityStatus from "../AvailabilityStatus";

const MAX_QUANTITY = 999;

const isQuantityBelowMax = (value: number, increment: number): boolean =>
  value + increment <= MAX_QUANTITY;

interface Props {
  AFAsku: AFASku;
  style: "box" | "line" | "cart";
  quantity: number;
  availabilityStatus?: string;
  handleOnClick: (newQuantity: number) => void;
  disabled?: boolean;
  hideAddButton?: boolean;
  hideProductAvailable?: boolean;
  availableQuantityToShow?: string;

  //FOR CART
  isCart?: boolean;
  handleDelete?: (orderItem: OrderItem) => void;
  orderItem?: OrderItem; //isCart
  isSingleSize?: boolean;
  showOtherSku?: boolean;
}
const AddSizeAFA = ({
  style = "line",
  AFAsku,
  quantity,
  availabilityStatus,
  handleDelete,
  handleOnClick,
  orderItem,
  isSingleSize = false,
  disabled = false,
  showOtherSku = false,
  hideAddButton = false,
  hideProductAvailable = false,
  availableQuantityToShow,
  isCart = false,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const isUpdatingQuantityAFA = useSelector(selectIsUpdatingQuantityAFA);
  const variantInfo = useSelector(selectCurrentVariant);

  const quantityInput = React.useRef<HTMLInputElement>(null);
  const quantityStep = 1;

  const [newQuantity, setNewQuantity] = useState<number>(quantity);
  const [timeout, setTimeoutState] = useState<NodeJS.Timeout>();
  const [error, setError] = useState<boolean>(false);
  const currentAvailabilityQuantity: number = isCart
    ? parseInt(AFAsku.productAvailable)
    : Number(availableQuantityToShow) - newQuantity > 0
    ? Number(availableQuantityToShow) - newQuantity
    : 0;

  const sizeLabel = checkIsAFAApparel(variantInfo?.attributes)
    ? "SIZE_APPAREL"
    : checkIsAFAFootwear(variantInfo?.attributes)
    ? "SIZE_FOOTWEAR"
    : checkIsAFAAccessories(variantInfo?.attributes)
    ? "SIZE_AFA_ACCESSORIES"
    : checkIsHelmetFromAttributes(variantInfo?.attributes)
    ? "SIZE_HELMETS"
    : "SIZE";

  useEffect(() => {
    if (isCart && quantity > currentAvailabilityQuantity) {
      setError(true);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  // keep quantity aligned with redux
  useEffect(() => {
    if (quantity !== newQuantity) setNewQuantity(quantity);
  }, [quantity]);

  // handle quantity changes when using + and - buttons
  const handleUpdateQuantity = (value: number, increment: boolean) => {
    if (error) setError(false);
    if (!isUpdatingQuantityAFA) dispatch(saveIsUpdatingQuantityAFA(true));
    if (timeout) clearTimeout(timeout); //clean if user is still updating

    let items = newQuantity ? newQuantity : 0;

    if (increment) {
      if (isQuantityBelowMax(items, value)) items += value;
    } else if (items > 0) items -= value;

    setNewQuantity(items);

    if (
      !showOtherSku &&
      currentAvailabilityQuantity !== undefined &&
      items > Number(availableQuantityToShow)
    ) {
      items = Number(availableQuantityToShow);
    }

    const newTimeout = setTimeout(() => {
      dispatch(saveIsUpdatingQuantityAFA(false));
      handleOnClick(items);
      setNewQuantity(items);
    }, 1500);

    setTimeoutState(newTimeout);
  };

  // handle quantity changes when inserting number via input
  const handleChangeQuantity = () => {
    if (error) setError(false);
    if (!isUpdatingQuantityAFA) dispatch(saveIsUpdatingQuantityAFA(true));
    if (quantityInput.current?.value) {
      const roundedQuantity =
        Math.ceil(Number(quantityInput.current.value) / quantityStep) * quantityStep;

      let maxedQuantity = isQuantityBelowMax(roundedQuantity, 0) ? roundedQuantity : MAX_QUANTITY;

      setNewQuantity(maxedQuantity);
      //check that quantity is below or equal available quantity
      if (
        !showOtherSku &&
        currentAvailabilityQuantity !== undefined &&
        maxedQuantity > Number(availableQuantityToShow)
      ) {
        maxedQuantity = Number(availableQuantityToShow);
      }

      const newTimeout = setTimeout(() => {
        dispatch(saveIsUpdatingQuantityAFA(false));
        handleOnClick(maxedQuantity);
        setNewQuantity(maxedQuantity);
      }, 1000);

      setTimeoutState(newTimeout);
    }
  };

  return (
    <Container
      className={clsx(
        style === "box" && "add-size-afa--box",
        style === "line" && "add-size-afa--line",
        style === "cart" && "add-size-afa--cart",
        isSingleSize && "add-size-afa-single",
        isCart && error && "add-size-afa--error"
      )}
    >
      <AvailabilityContainer>
        <AvailabilityStatus
          inventoryStatus={availabilityStatus as string}
          showRedSemaphore={isCart}
          id={AFAsku.sku.uniqueID}
          isCart={isCart}
          isAfa={true}
        />
        <CustomText as="p" font="font-bold" fontSizePx={14} marginLeftPx={8} marginRightPx={8}>
          {translateLabel(sizeLabel) + " " + AFAsku.sku?.size?.values?.[0]?.value}
        </CustomText>
      </AvailabilityContainer>

      {!hideAddButton ? (
        <AddSizeContainer className="add-size-afa-container">
          <IconButton
            icon={<MinusIcon />}
            size="sm"
            disabled={newQuantity == 0 || disabled || (isCart && newQuantity === 1)}
            onClick={() => handleUpdateQuantity(quantityStep, false)}
          />
          <ItemCount
            className={clsx(style === "cart" && "add-size-afa-count--cart")}
            ref={quantityInput}
            value={newQuantity}
            type="number"
            onChange={handleChangeQuantity}
            min={0}
            disabled={disabled}
            {...(error && { borderColor: "red" })}
          />
          <IconButton
            icon={<PlusIcon />}
            size="sm"
            onClick={() => handleUpdateQuantity(quantityStep, true)}
            disabled={
              !isQuantityBelowMax(newQuantity, quantityStep) ||
              disabled ||
              (!isCart && currentAvailabilityQuantity === 0)
            }
          />
        </AddSizeContainer>
      ) : (
        <div></div>
      )}

      {!showOtherSku && !isSingleSize && style === "cart" && handleDelete && (
        <div>
          <IconButton
            icon={<CloseIcon />}
            disabled={disabled}
            size="sm"
            type="no-background-primary"
            onClick={() => orderItem && handleDelete(orderItem)}
          />
        </div>
      )}

      <div className="add-size-afa--product-available">
        {!showOtherSku && !hideProductAvailable && currentAvailabilityQuantity !== undefined && (
          <CustomText as="p" font="font-regular" fontSizePx={12}>
            {translateLabel("AFA_PRODUCTS_AVAILABLE").replace(
              "{N_PRODUCTS}",
              printAvailableQuantity(currentAvailabilityQuantity.toString())
            )}
          </CustomText>
        )}
      </div>
    </Container>
  );
};
// AFA_PRODUCTS_AVAILABLE
const Container = styled.div`
  border-radius: 0.25rem;
  border: solid 1px ${(props) => props.theme.palette.gray.medium};
  background-color: ${(props) => props.theme.palette.white};
  padding: 1rem;

  &.add-size-afa--line {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .add-size-afa-container {
      grid-column-start: 2;
      grid-row-start: 1;
      grid-row-end: 3;
      justify-self: end;
    }
  }

  &.add-size-afa--box {
    display: grid;
    grid-template-columns: 1fr;
    width: 9.25rem;
    height: 10.1rem;

    .add-size-afa-container {
      margin: 1rem 0;
    }
  }

  &.add-size-afa--cart {
    display: grid;
    grid-template-columns: 1fr 1fr 2rem;
    border-width: 0 0 1px 0;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0.5rem;

    .add-size-afa-container {
      justify-self: end;
    }

    .add-size-afa--product-available {
      grid-column-start: 2;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    &.add-size-afa-single {
      border-width: 0;
    }
  }

  &.add-size-afa--error {
    input {
      border-color: ${(props) => props.theme.palette.feedback.error}!important;
    }
  }
`;

const AddSizeContainer = styled.span`
  display: flex;
  align-items: center;
`;

const ItemCount = styled.input<{ borderColor?: string }>`
  margin: 0 0.5625rem;
  width: 2rem;
  font-family: ${(props) => props.theme.fonts.fontMedium};
  text-align: center;
  border: 1px solid ${(props) => props.theme.palette.gray.medium};
  padding: 0.3125rem;
  height: auto;
  border-radius: 0.25rem;
  font-size: 0.8125rem;
  ${(props) => props.borderColor && `border-color:${props.borderColor}`}

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
    appearance: none !important;
    margin: 0 !important;
  }

  /* Firefox */
  [type="number"] {
    -moz-appearance: textfield !important;
  }
`;

const AvailabilityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2rem;
`;

export default AddSizeAFA;
