import { useDispatch, useSelector } from "react-redux";
import { SecondLevelMenu } from "../interfaces/menuInterfaces";
import { QueryParams } from "../store/search/searchInterfaces";
import {
  getLensesEssilorSubMenu,
  getLensesSubMenu,
  getThirdLevelMenu,
} from "../store/store/storeSagas";
import {
  selectLensesEssilorMenu,
  selectLensesMenu,
  selectLxConfigurations,
  selectThirdLevelMenu,
} from "../store/store/storeSlice";
import { getCompleteId } from "../utils/menuUtils";
import useCanRender from "./useCanRender";
import { deniedThirdMenuSection } from "../utils/mostUsedUtils";

export default function useGetAllThirdLevelMenu(): (
  open: boolean,
  menus?: SecondLevelMenu[]
) => void {
  const dispatch = useDispatch();
  const lensesMenu = useSelector(selectLensesMenu);
  const lensesEssilorMenu = useSelector(selectLensesEssilorMenu);
  const thirdLevelMenu = useSelector(selectThirdLevelMenu);
  const canRender = useCanRender();

  const LXConfiguration = useSelector(selectLxConfigurations);
  const isEssilorMode =
    LXConfiguration?.filter(
      (element) => element.key === "com.luxottica.oneportal.lenses.menu.enable"
    )?.[0]?.value === "Y";

  const tryGetThirdLevelMenu = (open: boolean, menus?: SecondLevelMenu[]): void => {
    menus?.map((menu) => {
      if (!menu) {
        return;
      }

      const { uniqueID, identifier: id } = menu;
      const identifier = id.toUpperCase();
      const completeId = getCompleteId(uniqueID, identifier);
      const currentMenu = thirdLevelMenu[completeId];

      //Get information for third level menu (not lenses)
      if (open && completeId && !currentMenu && !deniedThirdMenuSection.includes(identifier)) {
        const queryParams: QueryParams = {};
        if (menu.filters) {
          menu.filters.forEach((filter) => {
            if (filter.type && filter.identifier) {
              if (queryParams[filter.type]) {
                queryParams[filter.type].push(filter.identifier);
              } else {
                queryParams[filter.type] = [filter.identifier];
              }
            }
          });
        }
        dispatch(
          getThirdLevelMenu({
            id: uniqueID,
            customId: completeId,
            identifier: identifier,
            params: queryParams,
            starsRequired: canRender("STARS"),
            canRenderPrivileges: {
              AFA_SECTION: canRender("AFA_SECTION"),
            },
          })
        );
      }

      //Get information for third level menu for LENSES
      if (open && identifier === "LENSES") {
        if (isEssilorMode && lensesEssilorMenu.length === 0) dispatch(getLensesEssilorSubMenu());
        else if (!isEssilorMode && lensesMenu.length === 0) dispatch(getLensesSubMenu());
      }
    });
  };

  return tryGetThirdLevelMenu;
}
