import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useCanRender from "../../../../hooks/useCanRender";

import { getPagePath } from "../../../../routing/routesUtils";
import {
  getUrlLensSimulator,
  getUrlWithTokenEl360,
  getUrlWithTokenRewards,
  showErrorPopup,
} from "../../../../store/store/storeSagas";
import { selectChatUrl } from "../../../../store/store/storeSlice";
import { NavbarSectionLink } from "../../../pages/my-account/account-navbar/navbarSections";
import useAccountPinRedirect from "../accounting-pin/useAccountPinRedirect";
import { getDigitalDiscountsVoucher } from "../../../../store/services/servicesSaga";

const useNavbarSectionLinkHandler = (): {
  handleNavbarSectionLinkClick: (link: NavbarSectionLink) => void;
} => {
  const history = useHistory();
  const canRender = useCanRender();
  const dispatch = useDispatch();
  const { handleAccountPinClick } = useAccountPinRedirect();

  const chatUrl = useSelector(selectChatUrl);

  const handleNavbarSectionLinkClick = (link: NavbarSectionLink) => {
    switch (link.identifier) {
      // certain sections require specific urls that are received dynamically
      case "360_MEMBERS":
        canRender("EL_360") &&
          dispatch(
            getUrlWithTokenEl360({
              callback: (success?: boolean | undefined, data?: string | undefined) => {
                if (success && data) window.open(data, "_blank");
                else dispatch(showErrorPopup({}));
              },
            })
          );
        break;

      case "REWARDS":
        canRender("REWARDS") &&
          dispatch(
            getUrlWithTokenRewards({
              callback: (success?: boolean | undefined, data?: string | undefined) => {
                if (success && data) window.open(data, "_blank");
                else dispatch(showErrorPopup({}));
              },
            })
          );
        break;

      case "LENS_SIMULATOR":
        canRender("LENS_SIMULATOR") &&
          dispatch(
            getUrlLensSimulator({
              callback: (success?: boolean | undefined, data?: string | undefined) => {
                if (success && data) window.open(data, "_blank");
                else dispatch(showErrorPopup({}));
              },
            })
          );
        break;

      case "DIGITAL_DISCOUNT_VOUCHER":
        canRender("DIGITAL_DISCOUNT_VOUCHER") &&
          dispatch(
            getDigitalDiscountsVoucher({
              callback: (success?: boolean | undefined, data?: string | undefined) => {
                if (success && data) {
                  window.open(data, "_blank");
                } else {
                  dispatch(showErrorPopup({}));
                }
              },
            })
          );
        break;

      default:
        // certain sections require a PIN to be accessed
        // if so, a popup is opened inside handleAccountPinClick
        // otherwise, we handle the redirect to the correct page here
        const isPinRequired = handleAccountPinClick(link.title, link.url);
        !isPinRequired && history.push(getPagePath(link.url));
        break;
    }
  };

  return { handleNavbarSectionLinkClick };
};

export default useNavbarSectionLinkHandler;
