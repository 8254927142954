import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import styled from "styled-components/macro";
import IconButton from "./IconButton";
import { ReactComponent as PlayIcon } from "../../assets/icons/play-icon.svg";
import { ReactComponent as SoundIcon } from "../../assets/icons/sound-icon.svg";
import { toggleVideoPopup } from "../../store/store/storeSlice";
interface Props {
  coverImage?: string;
  previewMode: "cover" | "first-frame";
  placement: "inset" | "popup";
  srcVideo: string;
  withOverlay?: boolean;
  positionIcon?: "left" | "center";
  "data-element-id"?: string | null;
  "data-description"?: string;
  onEnded?: () => void;
  autoplay?: boolean;
  width?: string;
  height?: string;
  haveControls?: boolean;
  videoIdentifier?: string;
}

const VideoComponent = ({
  coverImage,
  srcVideo,
  withOverlay,
  previewMode,
  placement,
  positionIcon = "center",
  "data-element-id": dataElementId,
  "data-description": dataDescription,
  onEnded,
  autoplay = false,
  width = "100%",
  height = "100%",
  haveControls = false,
  videoIdentifier,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const refVideoContainer = useRef<HTMLButtonElement | null>(null);
  const refVideo = useRef<HTMLVideoElement | null>(null);
  const [hideLayer, setHideLayer] = useState<boolean>(false);
  const [isMuted, setMuted] = useState<boolean>(autoplay);

  useEffect(() => {
    autoplay && setHideLayer(true);
  }, []);

  const handleSound = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setMuted(false);
  };

  const openPopup = () => {
    if (placement === "inset" && refVideo.current) {
      const video = refVideo.current;
      if (video.paused) {
        video.play();
        setHideLayer(true);
      } else {
        video.pause();
        setHideLayer(false);
      }
    }

    if (placement === "popup" && refVideo.current) {
      const topPopup = refVideo.current?.getBoundingClientRect().top
        ? refVideo.current?.getBoundingClientRect().top
        : null;

      let total = window.pageYOffset;
      if (topPopup) total += topPopup;

      dispatch(toggleVideoPopup({ open: true, src: srcVideo, top: total }));
    }
  };

  return (
    <Box
      boxHeight={height}
      videoWidth={width}
      ref={refVideoContainer}
      onClick={openPopup}
      {...(dataElementId ? { "data-element-id": dataElementId } : {})}
      {...(dataDescription ? { "data-description": dataDescription } : {})}
    >
      <video
        src={srcVideo}
        ref={refVideo}
        onEnded={onEnded}
        autoPlay={autoplay}
        muted={isMuted}
        controls={haveControls}
      />
      {previewMode === "cover" && coverImage && (
        <ImageBox coverImage={coverImage} hideLayer={hideLayer} />
      )}
      {withOverlay && <Overlay />}
      {!haveControls && (
        <>
          <PlayButton position={positionIcon} hideLayer={hideLayer}>
            <IconButton
              type="white-no-border"
              size="xl"
              icon={<PlayIcon />}
              data-element-id="VideoPlay"
              data-description={videoIdentifier}
            />
          </PlayButton>
          {isMuted && (
            <div className="sound-icon-container" onClick={(e) => handleSound(e)}>
              <SoundIcon />
            </div>
          )}
        </>
      )}
    </Box>
  );
};

const Box = styled.button<{ boxHeight: string; videoWidth: string }>`
  height: ${(props) => props.boxHeight};
  position: relative;
  overflow: hidden;

  video {
    height: 100%;
    margin: 0 auto;
    width: ${(props) => props.videoWidth};
  }

  .sound-icon-container {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    cursor: pointer;
  }
`;

const ImageBox = styled.div<{ coverImage: string; hideLayer: boolean }>`
  position: absolute;
  top: 0;
  left: 0%;
  background-size: cover;
  background-image: url(${(props) => props.coverImage});
  height: 100%;
  width: 100%;
  display: ${(props) => (props.hideLayer ? "none" : "block")};
`;

const PlayButton = styled.div<{ hideLayer: boolean; position: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: ${(props) => (props.hideLayer ? "none" : "flex")};
  justify-content: ${(props) => (props.position === "left" ? "flex-start" : "center")};
  align-items: center;
  z-index: 10;
  cursor: pointer;

  button {
    z-index: 1;
  }
`;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 1) 100%);
  cursor: pointer;
  top: 0;
`;

export default VideoComponent;
