import { AxiosResponse } from "axios";
import { appendFiltersToURLSearchParams } from "../../utils/filterUtils";
import { createAndExecuteService } from "../serviceUtils";
import { SearchService, SuggestionService } from "./searchInterfaces";
import { getSuggestionsUrl, getSearchResultUrl, getSearchCategoriesUrl } from "./searchUrls";

export default {
  getSuggestions: async (payload: SuggestionService): Promise<AxiosResponse> => {
    const url = getSuggestionsUrl.replace("{term}", encodeURIComponent(payload.term));
    const qparams = appendFiltersToURLSearchParams(payload.searchParams, payload.filters);

    return createAndExecuteService(url, "GET", qparams);
  },
  getSearchResult: async (payload: SearchService): Promise<AxiosResponse> => {
    const url = getSearchResultUrl.replace("{term}", payload.term);
    const { pageNumber, pageSize, orderBy } = payload.searchParams;
    const params = {
      pageSize: pageSize,
      pageNumber: pageNumber.toString(),
      ...(orderBy && { orderBy: orderBy }),
    };

    return createAndExecuteService(
      url,
      "GET",
      appendFiltersToURLSearchParams(params, payload.filters)
    );
  },
  getSearchCategories: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getSearchCategoriesUrl, "GET");
  },
};
