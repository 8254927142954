import React, { useEffect, useState } from "react";
import Slider, { LazyLoadTypes } from "react-slick";
import styled from "styled-components/macro";
import CustomText from "../styled-UI/CustomText";
import VerticalCarousel from "./VerticalCarousel";
import { LensShowcaseData } from "../../interfaces/cmsInterfaces";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  items: LensShowcaseData[];
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LensShowcase = ({ items, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  const slidesToScroll = 1;
  const slidesToShow = 3;
  const lensType = items?.[0];
  const lensTech = items?.[1];
  const [bottomColors, setBottomColors] = useState(lensType?.items?.[0].items);
  const [selectedColor, setSelectedColor] = useState<string>(lensType?.items?.[0].items[0].id);
  const [selectedLensType, setSelectedLensType] = useState<string>(lensType?.items?.[0].id);
  const [selectedLensTech, setSelectedLensTech] = useState<string>("");
  const [totalCenterSlides, setTotalCenterSlides] = useState(bottomColors?.length || 0);

  //center slider
  const [centerSliderRef, setCenterSliderRef] = useState<any>(null);
  const [currentCenterSlide, setCurrentCenterSlide] = useState<number>(0);
  // const totalCenterSlides = main.length;
  // const currentCenterPage = Math.ceil(currentLeftSlide / slidesToScroll);
  // const lastCenterPage = Math.ceil((totalSlides - slidesToShow) / slidesToScroll);

  useEffect(() => {
    if (bottomColors) {
      setTotalCenterSlides(bottomColors.length || 0);
    }
  }, [bottomColors]);

  const defaultCenterSettings = {
    dots: false,
    arrows: false,
    draggable: false, //to set it to true --> prevent click on drag https://github.com/akiran/react-slick/issues/848
    speed: 500,
    infinite: false,
    lazyLoad: "ondemand" as LazyLoadTypes,
    onSwipe: () => {
      console.log("swipe");
    },
    slidesToShow: 1 < totalCenterSlides ? 1 : totalCenterSlides,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: (_: any, next: any) => setCurrentCenterSlide(next),
    adaptiveHeight: true,
  };

  const changeSlide = (val: number) => {
    centerSliderRef.slickGoTo(val);
  };

  return (
    <Container>
      <LeftColumn>
        <CustomText
          as="p"
          font={customFont ? "custom-bold" : "font-bold"}
          fontFamily={customFont}
          fontSizePx={18}
          marginBottomPx={24}
          isFlex={false}
          textAlign="center"
          color={isDarkMode ? "white" : "primary"}
        >
          {lensType?.collectionTitle}
        </CustomText>
        <VerticalCarousel
          slidesToScroll={slidesToScroll}
          slidesToShow={slidesToShow}
          isDarkMode={isDarkMode}
        >
          {lensType?.items?.map((type: any, index: number) => {
            return (
              <div key={type?.id + index}>
                <LeftSlide>
                  <Circle
                    className={type?.id === selectedLensType ? "active" : ""}
                    onClick={() => {
                      setSelectedLensType(type?.id);
                      setSelectedLensTech("");
                      setBottomColors(type?.items);
                      setSelectedColor(type?.items?.[0]?.id);
                      changeSlide(0);
                    }}
                  >
                    <img src={type?.pictures?.[0]?.data?.uri} />
                  </Circle>

                  <CustomText
                    as="p"
                    font={customFont ? "custom-bold" : "font-bold"}
                    fontFamily={customFont}
                    fontSizePx={14}
                    uppercase
                    marginTopPx={16}
                    isFlex={false}
                    textAlign="center"
                    color={isDarkMode ? "white" : "primary"}
                  >
                    {type?.collectionTitle}
                  </CustomText>
                </LeftSlide>
              </div>
            );
          })}
        </VerticalCarousel>
      </LeftColumn>
      <MainImageContainer>
        <Slider ref={(val) => setCenterSliderRef(val)} {...defaultCenterSettings}>
          {bottomColors?.map((color: any, index: number) => {
            return (
              <div key={color.id + index}>
                <MainImage>
                  <img src={color?.multiMediaType?.[0]?.data?.uri} />
                </MainImage>
                <CustomText
                  as="p"
                  font={customFont ? "custom-bold" : "font-bold"}
                  fontFamily={customFont}
                  fontSizePx={18}
                  marginBottomPx={24}
                  marginTopPx={24}
                  isFlex={false}
                  textAlign="center"
                  color={isDarkMode ? "white" : "primary"}
                >
                  {color?.title}
                </CustomText>
              </div>
            );
          })}
        </Slider>
        <ThumbnailContainer>
          {bottomColors?.map((color: any, index: number) => {
            return (
              <SmallCircle
                key={color?.id + index}
                onClick={() => {
                  changeSlide(index);
                  setSelectedColor(color?.id);
                }}
                className={color?.id === selectedColor ? "active" : ""}
              >
                <img src={color?.imageContent?.[0]?.data?.uri} />
              </SmallCircle>
            );
          })}
        </ThumbnailContainer>
      </MainImageContainer>
      <RightColumn>
        <CustomText
          as="p"
          font={customFont ? "custom-bold" : "font-bold"}
          fontFamily={customFont}
          fontSizePx={18}
          marginBottomPx={24}
          isFlex={false}
          textAlign="center"
          color={isDarkMode ? "white" : "primary"}
        >
          {lensTech?.collectionTitle}
        </CustomText>
        <VerticalCarousel
          slidesToScroll={slidesToScroll}
          slidesToShow={slidesToShow}
          isDarkMode={isDarkMode}
        >
          {lensTech?.items?.map((tech: any, index) => {
            return (
              <div key={tech?.id + index}>
                <LeftSlide>
                  <Circle
                    className={tech?.id === selectedLensTech ? "active" : ""}
                    onClick={() => {
                      setSelectedLensType("");
                      setSelectedLensTech(tech?.id);
                      setBottomColors(tech?.items);
                      setSelectedColor(tech?.items?.[0]?.id);
                      changeSlide(0);
                    }}
                  >
                    <img src={tech?.pictures?.[0]?.data?.uri} />
                  </Circle>

                  <CustomText
                    as="p"
                    font={customFont ? "custom-bold" : "font-bold"}
                    fontFamily={customFont}
                    fontSizePx={14}
                    uppercase
                    marginTopPx={16}
                    isFlex={false}
                    textAlign="center"
                    color={isDarkMode ? "white" : "primary"}
                  >
                    {tech?.collectionTitle}
                  </CustomText>
                </LeftSlide>
              </div>
            );
          })}
        </VerticalCarousel>
      </RightColumn>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: auto 20%;
  padding: 3rem 2rem;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainImageContainer = styled.div``;

const ThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  grid-column-end: 4;
  grid-column-start: 1;
  margin: 1rem 0;
`;

const LeftSlide = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
`;

const Circle = styled.div`
  height: 3.75rem;
  width: 3.75rem;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &.active {
    border: 2px solid ${(props) => props.theme.palette.white};
    outline: 2px solid ${(props) => props.theme.palette.primary};
    border-radius: 50%;
  }
`;

const SmallCircle = styled.button`
  height: 3.125rem;
  width: 3.125rem;
  margin-right: 1.5rem;

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &.active {
    border: 2px solid ${(props) => props.theme.palette.white};
    outline: 2px solid ${(props) => props.theme.palette.primary};
    border-radius: 50%;
  }
`;

const MainImage = styled.div`
  display: flex;
  justify-content: center;
  img {
    height: 500px;
  }
`;
export default LensShowcase;
