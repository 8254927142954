import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import { RootState } from "../storeConfig";
import {
  CompletedOrder,
  OrderAddressInfo,
  OrderUploarState,
  UploadedOrder,
  UploadedOrderProducts,
} from "./orderUploadInterfaces";

export const sliceName = "orderUpload";

const initialState: OrderUploarState = {
  massiveOrderTemplate: null,
  massiveOrder: null,
  uploadedProducts: null,
  completedOrder: null,
  customerAddressInfo: null,
  massiveOrdersStatus: "IDLE",
  downloadMassiveOrderTemplateStatus: "IDLE",
  uploadMassiveOrderStatus: "IDLE",
  getProductsErrorStatus: "IDLE",
  editProductStatus: "IDLE",
  postMassiveOrderStatus: "IDLE",
  customerAddressStatus: "IDLE",
  massiveOrderPricesStatus: "IDLE",
  massiveOrderCheckoutStatus: "IDLE",
};

export const orderUploadSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveMassiveOrderTemplate: (state, { payload }: PayloadAction<string[] | null>) => {
      state.massiveOrderTemplate = payload;
    },
    saveMassiveOrders: (state, { payload }: PayloadAction<UploadedOrder[] | null>) => {
      const orederedMassiveOrder = orderBy(payload, "date", "desc");
      state.massiveOrder = orederedMassiveOrder;
    },
    saveUploadedProducts: (state, { payload }: PayloadAction<UploadedOrderProducts | null>) => {
      state.uploadedProducts = payload;
    },
    setCompletedOrder: (state, { payload }: PayloadAction<CompletedOrder | null>) => {
      state.completedOrder = payload;
    },
    saveCustomerAddressInfo: (
      state,
      { payload }: PayloadAction<null | { [x: string]: OrderAddressInfo }>
    ) => {
      state.customerAddressInfo = payload;
    },
    setMassiveOrdersStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.massiveOrdersStatus = payload;
    },
    setDownloadMassiveOrderTemplateStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.downloadMassiveOrderTemplateStatus = payload;
    },
    setUploadMassiveOrderStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.uploadMassiveOrderStatus = payload;
    },
    setGetProductsErrorStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.getProductsErrorStatus = payload;
    },
    setEditProductStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.editProductStatus = payload;
    },
    setPostMassiveOrderStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.postMassiveOrderStatus = payload;
    },
    setMassiveOrderPricesStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.massiveOrderPricesStatus = payload;
    },
    setMassiveOrderCheckoutStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.massiveOrderCheckoutStatus = payload;
    },
    setCustomerAddressStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.customerAddressStatus = payload;
    },
    resetOrderUploadState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  saveMassiveOrders,
  saveMassiveOrderTemplate,
  saveUploadedProducts,
  setCompletedOrder,
  saveCustomerAddressInfo,
  setMassiveOrdersStatus,
  setDownloadMassiveOrderTemplateStatus,
  setUploadMassiveOrderStatus,
  setGetProductsErrorStatus,
  setEditProductStatus,
  setPostMassiveOrderStatus,
  setMassiveOrderPricesStatus,
  setMassiveOrderCheckoutStatus,
  setCustomerAddressStatus,
  resetOrderUploadState,
} = orderUploadSlice.actions;

export const selectMassiveOrderTemplate = (state: RootState): string[] | null =>
  state.orderUpload.massiveOrderTemplate;
export const selectMassiveOrders = (state: RootState): UploadedOrder[] | null =>
  state.orderUpload.massiveOrder;
export const selectUploadedProducts = (state: RootState): UploadedOrderProducts | null =>
  state.orderUpload.uploadedProducts;
export const selectCompletedOrder = (state: RootState): CompletedOrder | null =>
  state.orderUpload.completedOrder;
export const selectCustomerAddressInfo = (
  state: RootState
): null | { [x: string]: OrderAddressInfo } => state.orderUpload.customerAddressInfo;
export const selectMassiveOrdersStatus = (state: RootState): RequestStatus =>
  state.orderUpload.massiveOrdersStatus;
export const selectDownloadMassiveOrderTemplateStatus = (state: RootState): RequestStatus =>
  state.orderUpload.downloadMassiveOrderTemplateStatus;
export const selectUploadMassiveOrderStatus = (state: RootState): RequestStatus =>
  state.orderUpload.uploadMassiveOrderStatus;
export const selectGetProductsErrorStatus = (state: RootState): RequestStatus =>
  state.orderUpload.getProductsErrorStatus;
export const selectEditProductStatus = (state: RootState): RequestStatus =>
  state.orderUpload.editProductStatus;
export const selectPostMassiveOrderStatus = (state: RootState): RequestStatus =>
  state.orderUpload.postMassiveOrderStatus;
export const selectMassiveOrderPricesStatus = (state: RootState): RequestStatus =>
  state.orderUpload.massiveOrderPricesStatus;
export const selectMassiveOrderCheckoutStatus = (state: RootState): RequestStatus =>
  state.orderUpload.massiveOrderCheckoutStatus;
export const selectCustomerAddressStatus = (state: RootState): RequestStatus =>
  state.orderUpload.customerAddressStatus;

export default orderUploadSlice.reducer;
