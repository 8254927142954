import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";
import { TrendTile as TrendTileInterface } from "../../interfaces/cmsInterfaces";
import HomeCarousel from "../styled-UI/HomeCarousel";
import TrendTile from "./trend-tile/TrendTile";
import { FontFamilies } from "../../store/store/storeInterfaces";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import LoaderElement from "../styled-UI/loader/LoaderElement";

interface Props {
  trendCarousel: {
    id?: string;
    result?: TrendTileInterface[];
    title?: string;
  };
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LatestTrendSection = ({
  trendCarousel,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  // Lazy loading START
  const [isCarouselLoaded, setIsCarouselLoaded] = useState<boolean>(false);

  const { containerRef, isVisible: isCarouselVisible } = useIntersectionObserver<HTMLDivElement>({
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  useEffect(() => {
    if (!isCarouselLoaded && isCarouselVisible) setIsCarouselLoaded(true);
  }, [isCarouselVisible]);
  //Lazy loading END

  return (
    <div ref={containerRef}>
      {trendCarousel.result && trendCarousel.result.length > 0 ? (
        <Section data-element-id="Trends" isDarkMode={isDarkMode}>
          {isCarouselLoaded ? (
            <HomeCarousel
              slidesToScroll={1}
              slidesToShow={1}
              isTitleLabel={false}
              title={translateLabel("LATEST_TRENDS_SECTION_TITLE")}
              colorSelect="bg-light-blue"
              hideDisplay={false}
              showViewAll={false}
              data-element-id="Trends_Navigation"
              data-description="arrows"
              dataElementIdFilters="Trends_Filter"
              isDarkMode={isDarkMode}
              customFont={customFont}
            >
              {trendCarousel.result?.map((trendTile: TrendTileInterface, index) => {
                const numberTile = index + 1;

                return (
                  <div key={trendTile.id}>
                    <TrendTile
                      data={trendTile}
                      tileNumber={numberTile}
                      isDarkMode={isDarkMode}
                      customFont={customFont}
                    />
                  </div>
                );
              })}
            </HomeCarousel>
          ) : (
            <LoaderElement height={730} />
          )}
        </Section>
      ) : (
        <></>
      )}
    </div>
  );
};

const Section = styled.div<{ isDarkMode: boolean }>`
  background-color: ${(props) => !props.isDarkMode && props.theme.palette.background.lightBlue};
  padding: 1.5625rem 0;
`;

export default LatestTrendSection;
