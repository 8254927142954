import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPopupSapDecoupling, toggleSapDecouplingPopup } from "../../store/store/storeSlice";
import SapDecouplingSection from "./SapDecouplingSection";
import Popup from "../styled-UI/Popup";

const SapDecouplingConditionalPopup = (): JSX.Element => {
  const dispatch = useDispatch();
  const popup = useSelector(selectPopupSapDecoupling);

  useEffect(() => {
    return () => {
      close();
    };
  }, []);

  const close = () => {
    if (popup.open) dispatch(toggleSapDecouplingPopup({ open: false }));
  };

  return popup.open ? (
    <Popup
      isOpen
      hideCloseButton
      close={() => {
        return;
      }}
    >
      <SapDecouplingSection sapStatus={popup} close={close} />
    </Popup>
  ) : (
    <></>
  );
};

export default SapDecouplingConditionalPopup;
