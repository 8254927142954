import React from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../../../hooks/useTranslation";

import theme from "../../../../../assets/theme/theme";
import { MostUsedChip, MostUsedChipLocal } from "../../../../../interfaces/mostUsedInterfaces";
import Chip from "../../../../styled-UI/Chip";
import CustomText from "../../../../styled-UI/CustomText";
import { LoaderMostUsed } from "../../../../styled-UI/loader/most-used/LoaderCategoriesAndServices";
import { ChipsHandler } from "../useSelectedMostUsed";

type Props = {
  chipsHandler: ChipsHandler;
  isMaxChipsReached: boolean;
  isLoading: boolean;
  type: "mostUsedService" | "mostUsedAccount";
};

const MostUsedServiceAccount = ({
  chipsHandler,
  isMaxChipsReached,
  isLoading,
  type,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const isChipNotSelected = (x: MostUsedChip) =>
    !chipsHandler.selectedChips.find((y) => y.name === x.name);

  const handleChipClick = (chip: MostUsedChipLocal) => {
    isChipNotSelected(chip) && !isMaxChipsReached && chipsHandler.selectChip(chip);
    !isChipNotSelected(chip) && chipsHandler.deselectChip(chip);
  };

  if (!isLoading && chipsHandler.availableChips?.[type]?.length === 0) return <></>;
  return (
    <>
      <Wrapper>
        {!isLoading && chipsHandler.availableChips?.[type]?.length && (
          <CustomText
            as="span"
            font="font-bold"
            fontSizePx={18}
            marginBottomPx={8}
            lineHeightPx={32}
            uppercase
          >
            {type === "mostUsedAccount"
              ? translateLabel("MOST_USED_POPUP_MYACCOUNT")
              : translateLabel("MOST_USED_POPUP_SERVICES")}
          </CustomText>
        )}
        <ChipsContainer>
          {isLoading || !chipsHandler.availableChips?.[type]?.length ? (
            <LoaderMostUsed />
          ) : (
            chipsHandler.availableChips?.[type]?.map((chip) => (
              <ChipContainer key={chip.name}>
                <Chip
                  text={chip.label}
                  active={!isChipNotSelected(chip)}
                  onClick={() => handleChipClick(chip)}
                  showTitle
                  style={isChipNotSelected(chip) ? "border" : "blue-on-selected"}
                  borderRadiusPx={4}
                  widthPx={200}
                  heightPx={48}
                  paddingLeftPx={16}
                  paddingRightPx={16}
                  gapPx={16}
                  justifyContent="center"
                  fontSize={12}
                  lineHeightPx={18}
                  letterSpacingPx={0.2}
                  fontWeight={isChipNotSelected(chip) ? 400 : 700}
                  showFileIcon={!isChipNotSelected(chip)}
                  typeIcon="checkmark"
                  hideIcon
                  iconWithoutMargin
                  widthText={156}
                  spacedContent
                  border={`1px solid ${theme.palette.chip.blue}`}
                  noHover
                />
              </ChipContainer>
            ))
          )}
        </ChipsContainer>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div``;

export const ChipsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 0fr);
  gap: 1rem;

  @media ${(props) => props.theme.queries.md} {
    grid-template-columns: repeat(4, 0fr);
  }

  @media ${(props) => props.theme.queries.sm} {
    grid-template-columns: repeat(3, 0fr);
  }
`;

const ChipContainer = styled.div`
  display: inline-block;
`;

export default MostUsedServiceAccount;
