import React from "react";
import styled from "styled-components/macro";
import LoaderElement from "../LoaderElement";

export default function LoaderLensSelection(): JSX.Element {
  return (
    <Wrapper>
      <Column>
        <div className="col">
          <LoaderElement height={77} />
        </div>
        <div className="col">
          <LoaderElement height={77} />
        </div>
        <div className="col">
          <LoaderElement height={77} />
        </div>
      </Column>
    </Wrapper>
  );
}

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  .col {
    margin-bottom: 1rem;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;
