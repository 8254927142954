import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { orderBy, uniq } from "lodash";

import useTranslation from "../../../hooks/useTranslation";
import usePrevious from "../../../hooks/usePrevious";
import {
  getBrands,
  getColors,
  getFocalType,
  getJobTypeList,
  getPartnumbers,
  getSizes,
  getUpcsCode,
  selectMatchingPartnumber,
} from "../../../store/rx/rxSagas";
import {
  selectBrandGroupsOpts,
  selectBrands,
  selectColors,
  selectCompatibleFrames,
  selectCustomerOwnFrame,
  selectFocalTypeDetails,
  selectIsCollection,
  selectJobTypeList,
  selectLensImg,
  selectPartnumbers,
  selectSizes,
  selectUpcs,
  setCompatibleFrames,
  setCustomerOwnFrame,
  setLensImg,
  setPartnumbers,
  setRxSubmitError,
  setUpcs,
} from "../../../store/rx/rxSlice";
import CustomSelect, { CustomOptions } from "../../styled-UI/CustomSelect";
import CustomText from "../../styled-UI/CustomText";
import Checkbox from "../../styled-UI/Checkbox";
import Chip from "../../styled-UI/Chip";
import {
  Brand,
  Color,
  FocalTypePrescription,
  JobType,
  MatchingPartnumberPayload,
  SelectList,
  SelectNameList,
  Size,
  ResetFormParams,
  Frame,
} from "../../../store/rx/rxInterface";
import BrandImage from "../../styled-UI/BrandImage";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search-icon.svg";
import { getMCLFromPartnumber } from "../../../utils/rxUtils";
import InputComponent from "../../styled-UI/InputComponent";
import { printNonAdmittedSpeciaChar } from "../../../utils/utils";
import {
  selectActiveDoor,
  selectIsSubuser,
  selectSelectedDoor,
  selectUsersPrivileges,
} from "../../../store/user/userSlice";
import useCanRender from "../../../hooks/useCanRender";
import { selectBrandLabels } from "../../../store/store/storeSlice";

interface Props {
  activeStep: number;
  isFromCart: boolean;
  isFromPdpOrPlp: boolean;
  selectList: SelectList;
  handleSelectChange: (value: CustomOptions | null, ...types: SelectNameList[]) => void;
  handleMultiSelectChange: (changes: { [key in SelectNameList]?: CustomOptions | null }) => void;
  resetForm: ({ fields, preventModelSelectWipe }: ResetFormParams) => void;
  resetAll: () => void;
  handleClearPrescription: (...prescNames: string[]) => void;
  prescriptionDetails: FocalTypePrescription[] | null;
  selectedJobType: null | string;
  selectedBrand: string;
}

let isReturedFromCart = false;

export default function ModelSelection({
  activeStep,
  isFromCart,
  isFromPdpOrPlp,
  selectedJobType,
  selectList,
  handleSelectChange,
  handleMultiSelectChange,
  resetForm,
  resetAll,
  handleClearPrescription,
  prescriptionDetails,
  selectedBrand,
}: Props): JSX.Element {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const canRender = useCanRender();

  const brandGroupsOpts = useSelector(selectBrandGroupsOpts);
  const brands = useSelector(selectBrands);
  const jobTypeList = useSelector(selectJobTypeList);
  const partnumbers = useSelector(selectPartnumbers);
  const upcs = useSelector(selectUpcs);
  const colors = useSelector(selectColors);
  const focalTypeDetails = useSelector(selectFocalTypeDetails);
  const sizes = useSelector(selectSizes);
  const lensImg = useSelector(selectLensImg);
  const compatibleFrames = useSelector(selectCompatibleFrames);
  const customerOwnFrame = useSelector(selectCustomerOwnFrame);
  const isCollection = useSelector(selectIsCollection);
  const brandLabels = useSelector(selectBrandLabels);
  const usersPrivilege = useSelector(selectUsersPrivileges);
  const selectedDoor = useSelector(selectSelectedDoor);

  const [partnumberObj, setPartnumberObj] = useState<CustomOptions[]>([]);
  const [colorsObj, setColorsObj] = useState<CustomOptions[]>([]);
  const [sizeObj, setSizeObj] = useState<CustomOptions[]>([]);
  const [focalTypeOpts, setFocalTypeOpts] = useState<CustomOptions[]>([]);
  const [upc, setUpc] = useState<string>("");
  const [compatibleSelectedFrame, setCompatibleSelectedFrame] = useState<Frame[] | null>(null);

  const prevSelectedBrandGroup: CustomOptions | null | undefined = usePrevious(
    selectList.brandGroup
  );
  const prevSelectedBrand: CustomOptions | null | undefined = usePrevious(selectList.brand);
  const prevSelectedJobType: CustomOptions | null | undefined = usePrevious(selectList.jobType);
  const prevSelectedModel: CustomOptions | null | undefined = usePrevious(selectList.model);
  const prevSelectedColor: CustomOptions | null | undefined = usePrevious(selectList.color);
  const prevSelectedSize: CustomOptions | null | undefined = usePrevious(selectList.size);
  const prevSelectedUpc: CustomOptions | null | undefined = usePrevious(selectList.upc);

  useEffect(() => {
    if (selectedBrand && brandGroupsOpts) {
      handleSelectChange(printBrandOpt({ term: selectedBrand }), "brandGroup");
    }
  }, [selectedBrand, brandGroupsOpts]);

  useEffect(() => {
    if (
      selectList.brandGroup &&
      prevSelectedBrandGroup !== undefined &&
      prevSelectedBrandGroup?.value !== selectList.brandGroup.value
    ) {
      dispatch(getBrands({ brandGroupsValue: selectList.brandGroup.value as string }));
      resetForm({ fields: ["brand", "model", "jobType", "color", "size", "upc"] });
    }
    if (selectList.brandGroup === null && prevSelectedBrandGroup !== undefined) {
      resetAll();
    }
  }, [selectList.brandGroup]);

  useEffect(() => {
    if (
      selectList.brand &&
      prevSelectedBrand !== undefined &&
      prevSelectedBrand?.value !== selectList.brand.value
    ) {
      dispatch(
        getJobTypeList(
          isCollection
            ? (selectList.brandGroup?.value as string)
            : (selectList.brand.value as string)
        )
      );

      dispatch(setCompatibleFrames(null));
      dispatch(setRxSubmitError(null));
      dispatch(setLensImg(""));
    }
  }, [selectList.brand]);

  useEffect(() => {
    const jobTypeOpt = jobTypeOpts();
    if (jobTypeOpt !== undefined) {
      let completeJobIndex = jobTypeOpt?.findIndex((job) => job.identifier === "RX_COMPLETE_JOB");
      if (completeJobIndex === -1) {
        completeJobIndex = jobTypeOpt?.findIndex((job) => job.identifier === "RX_FRAME_TO_COME");
        if (completeJobIndex === -1)
          completeJobIndex = jobTypeOpt?.findIndex((job) => job.identifier === "RX_LENS_ONLY");
      }
      if (jobTypeOpt?.length) {
        handleSelectChange(
          {
            value: jobTypeOpt[completeJobIndex].identifier,
            label: jobTypeOpt[completeJobIndex].name,
          },
          "jobType"
        );
      }
    }
  }, [jobTypeList]);

  useEffect(() => {
    if (
      selectList.jobType &&
      prevSelectedBrand !== undefined &&
      (prevSelectedJobType === null || prevSelectedBrand?.value !== selectList.brand?.value)
    ) {
      dispatch(
        getFocalType({
          brandGroup: selectList.brandGroup?.value as string,
          brand: selectList.brand?.value as string,
          jobType: selectList.jobType.value as string,
        })
      );

      dispatch(
        getPartnumbers({
          brandGroup: selectList.brandGroup?.value as string,
          brand: selectList.brand?.value as string,
          jobType: selectList.jobType.value as string,
        })
      );

      resetForm({ fields: ["model", "color", "size", "upc"] });
      setUpc("");
      dispatch(setLensImg(""));
    }
  }, [selectList.jobType, selectList.brand]);

  useEffect(() => {
    if (
      selectList.jobType &&
      prevSelectedJobType !== undefined &&
      prevSelectedJobType !== null &&
      prevSelectedJobType.value !== selectList.jobType.value
    ) {
      resetForm({ fields: ["model", "color", "size", "upc"] });

      const objToSend: MatchingPartnumberPayload = {
        brandGroup: selectList.brandGroup?.value as string,
        brand: selectList.brand?.value as string,
        jobType: selectList.jobType?.value as string,
        handleMultiSelectChange,
      };
      let selectedModel;
      let selectedColor;
      let selectedSize;
      if (selectList?.model) {
        selectedModel = partnumbers?.find((model) => model?.partNumber === selectList.model?.value);
        if (selectedModel) objToSend.model = selectedModel.partNumber;
      }
      if (selectList?.color) {
        selectedColor = colors?.find((color) => color?.uniqueID === selectList.color?.value);
        if (selectedColor) objToSend.color = selectedColor.partNumber.split("_")[1];
      }
      if (selectList?.size) {
        selectedSize = sizes?.find((size) => size?.partNumber.slice(-2) === selectList.size?.value);
        if (selectedSize) objToSend.size = selectedSize.partNumber.slice(-2);
      }

      dispatch(selectMatchingPartnumber(objToSend));
      dispatch(setLensImg(""));
      setUpc("");
    }
  }, [selectList.jobType]);

  useEffect(() => {
    if (focalTypeDetails) {
      setFocalTypeOpts(
        focalTypeDetails.map((focalType, i) => ({
          value: focalType.focalTypeName,
          label: focalType.focaltypenametranslatedname || focalType.focalTypeName,
        }))
      );
    }
  }, [focalTypeDetails]);

  useEffect(() => {
    if (focalTypeOpts && !selectList.focalType) {
      let singleVisionIndex = focalTypeOpts.findIndex(
        (focalOpt) => focalOpt.value === "SINGLE VISION"
      );
      if (singleVisionIndex === -1) singleVisionIndex = 0;
      handleSelectChange(focalTypeOpts[singleVisionIndex], "focalType");
    }
  }, [focalTypeOpts]);

  useEffect(() => {
    dispatch(setUpcs(null));
  }, [upc]);

  useEffect(() => {
    if (partnumbers?.length) {
      const partnumberObj = partnumbers.map((item) => {
        return {
          value: item.partNumber,
          label: item.partNumber + (item.name ? "-" + item.name : ""),
        };
      });
      setPartnumberObj(partnumberObj);
    }
  }, [partnumbers]);

  useEffect(() => {
    if (prevSelectedUpc === undefined && isFromCart) {
      isReturedFromCart = true;
    }
    if (
      prescriptionDetails &&
      ((prevSelectedModel !== undefined && prevSelectedModel !== selectList.model) ||
        (prevSelectedColor !== undefined && prevSelectedColor !== selectList.color) ||
        (prevSelectedSize !== undefined && prevSelectedSize !== selectList.size))
    ) {
      !isReturedFromCart &&
        selectList.focalType?.value !== "PLANO" &&
        handleClearPrescription("SEGHT", "OCHT");
    }
    if (prevSelectedSize !== undefined && prevSelectedSize !== selectList.size && !isFromCart) {
      isReturedFromCart = false;
    }
    setUpc("");
  }, [selectList.model, selectList.color, selectList.size]);

  useEffect(() => {
    if (prevSelectedJobType !== undefined && selectList.model && selectList.jobType) {
      dispatch(
        getColors({
          partNumber: selectList.model.value as string,
          jobType: selectList.jobType.value as string,
        })
      );
      dispatch(setRxSubmitError(null));
      dispatch(setLensImg(""));
    }
  }, [selectList.model]);

  useEffect(() => {
    if (colors) {
      // if error non compatible model accours filter colors with compatible frames' colors
      let cColors: Color[] = [];
      if (compatibleFrames?.length) {
        const cSelectedFrames: Frame[] = compatibleFrames.filter(
          (frame) => getMCLFromPartnumber(frame.itemIdentifier).modelId === selectList.model?.value
        );
        cColors = colors.filter((color: Color) =>
          cSelectedFrames.some(
            (compatibleColor) =>
              getMCLFromPartnumber(compatibleColor.itemIdentifier).colorId ===
              color.partNumber.split("_")[1]
          )
        );
        setCompatibleSelectedFrame(cSelectedFrames);
      }
      const colorToConvert = compatibleFrames && compatibleFrames.length > 0 ? cColors : colors;
      const colorsObj = colorToConvert?.map((item: Color) => {
        return {
          value: item?.uniqueID,
          label:
            (item?.attributes?.[0]?.values[0]?.identifier
              ? item?.attributes?.[0]?.values[0]?.identifier + "-"
              : "") + item?.attributes?.[1]?.values?.[0]?.identifier ?? "",
        };
      });
      setColorsObj(colorsObj);
    }
  }, [colors]);

  useEffect(() => {
    if (prevSelectedJobType !== undefined && selectList.color && selectList.jobType) {
      dispatch(
        getSizes({
          catentryId: selectList.color.value as string,
          jobType: selectList.jobType.value as string,
        })
      );
      dispatch(setLensImg(""));
    }
  }, [selectList.color]);

  useEffect(() => {
    if (sizes && selectList.size) {
      dispatch(
        setLensImg(
          sizes
            ? sizes?.find((size) => {
                return size?.attributes?.[1]?.values[0]?.identifier === selectList?.size?.value;
              })?.fullImage || ""
            : upcs?.[0]?.fullImage || ""
        )
      );
    }
  }, [selectList.size, sizes]);

  useEffect(() => {
    if (selectList.upc) {
      const matchingUpc = upcs?.find((item) => item.upc === selectList.upc?.value);
      if (matchingUpc) {
        dispatch(
          selectMatchingPartnumber({
            brandGroup: selectList.brandGroup?.value as string,
            brand: selectList.brand?.value as string,
            jobType: selectList.jobType?.value as string,
            partNumber: matchingUpc.partNumber,
            handleMultiSelectChange,
          })
        );
      }
    }
  }, [selectList.upc]);

  useEffect(() => {
    if (upcs?.length === 1) {
      handleSelectChange({ label: upc, value: upc }, "upc");
    }
  }, [upcs]);

  useEffect(() => {
    if (sizes) {
      let compatibleSizes: Size[] = [];
      if (compatibleSelectedFrame) {
        const selectedCompatibleSizes: string[] = compatibleSelectedFrame.map((compatibleColor) => {
          return compatibleColor.itemIdentifier.slice(-2);
        });
        const uniqSelectedSizes = uniq(selectedCompatibleSizes);
        compatibleSizes = sizes.filter((size) =>
          uniqSelectedSizes.includes(size.partNumber.slice(-2))
        );
      }

      const sizeToConvert = compatibleFrames?.length ? compatibleSizes : sizes;

      const sizeObj = sizeToConvert.map((size: Size) => {
        const sizeId = size.partNumber.slice(-2);
        return {
          value: sizeId,
          label: sizeId,
        };
      });
      setSizeObj(sizeObj);
    }
  }, [sizes]);

  useEffect(() => {
    if (compatibleFrames && compatibleFrames.length > 1) {
      matchAttributes();
      resetForm({ fields: ["model", "color", "size"], preventModelSelectWipe: true });
      dispatch(setLensImg(""));
    }
  }, [compatibleFrames]);

  const matchAttributes = () => {
    const compatibleModels: string[] = [];

    compatibleFrames?.forEach((frame) => {
      const { modelId } = getMCLFromPartnumber(frame.itemIdentifier);
      compatibleModels.push(modelId);
    });

    const uniqModels = uniq(compatibleModels);

    dispatch(
      setPartnumbers(partnumbers?.filter((model) => uniqModels.includes(model.partNumber)) || null)
    );
  };

  const searchUpc = () => {
    dispatch(
      getUpcsCode({
        brandGroup: selectList.brandGroup?.value as string,
        brand: selectList.brand?.value as string,
        jobType: selectList.jobType?.value as string,
        term: upc,
      })
    );
  };

  const printBrandOpt = (brand: Brand): CustomOptions => {
    return {
      label: brand.label || translateLabel(brand.term + "_LABEL") || brand.term,
      value: brand.term,
    };
  };

  const brandsOpts = brands
    ? orderBy(
        brands?.map((brand: Brand) => printBrandOpt(brand)),
        ["label"]
      ).filter((brand: CustomOptions) => {
        if (isCollection) return true;
        return usersPrivilege
          .find((user) => user.orgentityName === selectedDoor?.orgentityName)
          ?.privileges.some((priv) => {
            if (priv.includes("RX_")) {
              const privBrand = priv.slice(-2);
              return brand.value === privBrand;
            }
          });
      })
    : null;

  const jobTypeOpts = (): JobType[] | undefined => {
    const brand = isCollection
      ? (selectList.brandGroup as CustomOptions)
      : (selectList.brand as CustomOptions);

    return jobTypeList?.filter((job) => {
      const COMPLETE_JOB =
        job.identifier === "RX_COMPLETE_JOB" &&
        canRender("RX_COMPLETE_JOB_" + brand.value, selectedDoor);
      const RX_FRAME_TO_COME =
        job.identifier === "RX_FRAME_TO_COME" &&
        canRender("RX_FRAME_TO_COME_" + brand.value, selectedDoor);
      const RX_LENS_ONLY =
        job.identifier === "RX_LENS_ONLY" && canRender("RX_LENS_ONLY_" + brand.value, selectedDoor);
      return COMPLETE_JOB || RX_FRAME_TO_COME || RX_LENS_ONLY;
    });
  };

  const showUpcError = (): true | undefined => {
    if (!upcs) {
      return undefined;
    }
    return upcs.length === 0 ? true : undefined;
  };

  const searchSpecialCharError = (e: { inputValue: string }): React.ReactNode => {
    let errorText = translateLabel("RX_SELECT_ERROR_NO_OPTS").replace(
      "{INPUT_VALUE}",
      e.inputValue
    );

    const specialChar = printNonAdmittedSpeciaChar(e.inputValue);
    if (specialChar !== "") {
      errorText = translateLabel("RX_SPECIAL_CHAR").replace(
        "{SPECIAL_CHAR}",
        printNonAdmittedSpeciaChar(e.inputValue)
      );
    }

    return (
      <CustomText as="p" color="red" fontSizePx={11}>
        {errorText}
      </CustomText>
    );
  };

  return (
    <ContentContainer data-element-id="LensPanel_Step1">
      <CustomText as="h3" font="font-bold" fontSizePx={18} lineHeightPx={24} marginBottomPx={24}>
        {translateLabel("RX_CHOOSE_BRAND")}
      </CustomText>
      <div className="brand-select-container d-flex">
        <div>
          <CustomSelect
            type="white"
            options={brandGroupsOpts}
            disabled={!Object.keys(brandLabels).length || !brandGroupsOpts}
            placeholder={translateLabel("RX_SEARCH_A_BRAND")}
            value={
              selectedBrand
                ? printBrandOpt({ term: selectedBrand })
                : selectList.brandGroup
                ? printBrandOpt({ term: selectList.brandGroup.value as string })
                : null
            }
            onChange={(e) => {
              handleSelectChange(e, "brandGroup");
            }}
            endIcon={<SearchIcon />}
            noOptionsMessage={searchSpecialCharError}
          />
        </div>
        <CustomSelect
          type="white"
          disabled={!brandsOpts}
          options={brandsOpts}
          placeholder={translateLabel("RX_CHOOSE_BRAND")}
          onChange={(e) => {
            handleSelectChange(e, "brand");
          }}
          value={
            selectList.brand
              ? printBrandOpt({
                  label: isCollection ? (selectList.brand.value as string) : undefined,
                  term: selectList.brand.value as string,
                })
              : null
          }
          noOptionsMessage={searchSpecialCharError}
        />
        {selectList.brandGroup && (
          <div className="brand-img-container">
            <BrandImage brandOrBrandGroup={selectList.brandGroup?.value as string} isBrandGroup />
          </div>
        )}
      </div>

      {!!selectList.brand && (
        <>
          <div className="job-type-container d-flex">
            <div className="job-chips-container">
              <CustomText
                as="h3"
                font="font-bold"
                fontSizePx={18}
                lineHeightPx={24}
                marginBottomPx={24}
              >
                {translateLabel("RX_JOB_TYPE_TITLE")}
              </CustomText>
              <div className="d-flex">
                {jobTypeOpts()?.map((job: JobType) => (
                  <div className="mr-3" key={job.identifier}>
                    <Chip
                      disabled={!!(selectedJobType && selectedJobType !== job.identifier)}
                      text={translateLabel(job.identifier).toUpperCase()}
                      onClick={() => {
                        handleSelectChange({ value: job.identifier, label: job.name }, "jobType");
                      }}
                      active={job.identifier === selectList.jobType?.value}
                      hideIcon={true}
                      noMaxWidth
                      style={"dark-on-selected"}
                    />
                  </div>
                ))}
              </div>
            </div>
            {selectList.jobType?.value === "RX_FRAME_TO_COME" && (
              <div className="job-frame-to-come">
                <CustomText
                  as="h3"
                  font="font-bold"
                  fontSizePx={18}
                  lineHeightPx={24}
                  marginBottomPx={24}
                >
                  {translateLabel("RX_SEND_CUSTOMER")}
                </CustomText>
                <div className="job-checkboxes-container d-flex">
                  <Checkbox
                    label={translateLabel("RX_YES")}
                    name="own-frame"
                    controlled
                    onChange={() => {
                      dispatch(setCustomerOwnFrame(true));
                    }}
                    checked={customerOwnFrame === true}
                  />
                  <Checkbox
                    label={translateLabel("RX_NO")}
                    name="own-frame"
                    controlled
                    onChange={() => {
                      dispatch(setCustomerOwnFrame(false));
                    }}
                    checked={customerOwnFrame === false}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="model-container">
            <div className="d-flex">
              <div className="model-form">
                <CustomText
                  as="h3"
                  font="font-bold"
                  fontSizePx={18}
                  lineHeightPx={24}
                  marginBottomPx={24}
                >
                  {translateLabel("RX_CHOOSE_A_SPECIFIC_MODEL")}
                </CustomText>

                <InputComponent
                  type="number"
                  id="upc"
                  placeholder={translateLabel("RX_UPC_CODE")}
                  name="pinCode"
                  value={upc}
                  onChange={(e) => {
                    setUpc(e.target.value);
                  }}
                  endIconDisabled={upc.length < 12}
                  endIcon={<SearchIcon />}
                  onClickEndIcon={searchUpc}
                  onKeyPress={(event) => event.key === "Enter" && upc.length >= 12 && searchUpc()}
                  helperText={showUpcError() && translateLabel("RX_UPC_NOT_FOUND")}
                  colorStyle={showUpcError() ? "error" : "enabled"}
                />

                <div className="text-center">
                  <CustomText
                    as="span"
                    font="font-medium"
                    fontSizePx={13}
                    lineHeightPx={18}
                    marginBottomPx={24}
                    marginTopPx={24}
                  >
                    {translateLabel("RX_OR")}
                  </CustomText>
                </div>
                <div className="model-form-custom d-flex">
                  <CustomSelect
                    type="white"
                    disabled={!partnumbers}
                    options={partnumberObj}
                    placeholder={translateLabel("RX_CHOOSE_MODEL")}
                    onChange={(e) => {
                      handleMultiSelectChange({ model: e, color: null, size: null });
                    }}
                    value={selectList.model}
                    noOptionsMessage={searchSpecialCharError}
                  />
                  <CustomSelect
                    type="white"
                    disabled={!colors}
                    options={colorsObj}
                    placeholder={translateLabel("RX_CHOOSE_COLOR")}
                    onChange={(e) => {
                      handleMultiSelectChange({ color: e, size: null });
                    }}
                    value={selectList.color}
                    noOptionsMessage={searchSpecialCharError}
                  />
                  <CustomSelect
                    type="white"
                    disabled={!sizes}
                    options={sizeObj}
                    placeholder={translateLabel("RX_CHOOSE_SIZE")}
                    onChange={(e) => {
                      handleSelectChange(e, "size");
                    }}
                    value={selectList.size}
                    noOptionsMessage={searchSpecialCharError}
                  />
                </div>
              </div>
              {lensImg !== "" && (
                <div className="model-preview d-flex">
                  <div className="model-preview-img-container">
                    <img src={lensImg + "?impolicy=MYL_EYE&wid=474"} alt="" />
                    <CustomText as="span" font="font-medium" fontSizePx={13} lineHeightPx={18}>
                      {translateLabel("RX_FRAME")}
                    </CustomText>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="focal-type-container">
            <CustomText
              as="h3"
              font="font-bold"
              fontSizePx={18}
              lineHeightPx={24}
              marginBottomPx={24}
            >
              {translateLabel("RX_FOCAL_TYPE_SECTION")}*
            </CustomText>
            <div className="d-flex">
              {focalTypeOpts?.map((focal: CustomOptions) => (
                <div className="mr-3" key={focal.value}>
                  <Chip
                    text={focal.label}
                    onClick={() => {
                      handleSelectChange(focal, "focalType");
                    }}
                    active={focal.value.toString() === selectList.focalType?.value.toString()}
                    hideIcon={true}
                    style={"dark-on-selected"}
                  />
                </div>
              ))}
            </div>
            {selectList.focalType?.value === "PLANO" && (
              <div className="focal-type-disclaimer-container">
                {" "}
                <CustomText
                  as="span"
                  font="font-light"
                  color="gray-dark"
                  fontSizePx={14}
                  lineHeightPx={24}
                >
                  {translateLabel("RX_FOCAL_TYPE_ZERO_POWER_DISCLAIMER")}
                </CustomText>
              </div>
            )}
          </div>
        </>
      )}
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  .brand-select-container {
    > div {
      width: 12rem;
      margin-right: 1.625rem;
    }
    .brand-img-container {
      display: flex;
      align-items: center;
      width: 8rem;
      height: 3.5rem;
      .brand-img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }
  }

  .job-type-container {
    .job-chips-container,
    .job-frame-to-come {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .job-checkboxes-container span {
        margin-right: 2.5rem;
      }
    }
  }

  .job-type-container,
  .focal-type-container {
    border-top: 1px solid ${(props) => props.theme.palette.gray.medium};
    padding-top: 2rem;
    margin-top: 2rem;
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    .mr-3 {
      margin-right: 1rem;
    }
    .focal-type-disclaimer-container {
      margin-top: 2rem;
    }
  }

  .model-container {
    .model-form,
    .model-preview {
      flex: 1;
      max-width: 50%;
    }
    .model-form-custom > div {
      padding-right: 1.5rem;
      flex: 1;
    }
    .model-form-custom > div:last-child {
      padding-right: unset;
    }
    .model-preview {
      justify-content: center;
      &-img-container {
        width: 28.25rem;
        height: 14.125rem;
        position: relative;
        img {
          height: 100%;
          width: 100%;
        }
        span {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
`;
