import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import { updatePrecart } from "../../../../store/cart/cartSagas";
import { SubOrderPayload } from "../../../../store/cart/cartInterfaces";
import InputComponent from "../../../styled-UI/InputComponent";
import Popup from "../../../styled-UI/Popup";
import Button from "../../../styled-UI/Button";
import CustomText from "../../../styled-UI/CustomText";

interface Props {
  isPopupOpen: boolean;
  closePopup: () => void;
  subOrderId: string;
  customerNotes?: string;
  setCustomerNotes: (customerNotes: string) => void;
}

const CartNotesPopup = ({
  isPopupOpen,
  closePopup,
  subOrderId,
  customerNotes,
  setCustomerNotes,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();

  // send updated notes
  const onConfirm = () => {
    const payload: SubOrderPayload = {
      subOrderId: subOrderId,
      payload: {
        customerServiceNote: customerNotes,
      },
    };

    dispatch(updatePrecart(payload));
    closePopup();
  };

  return (
    <Popup
      isOpen={isPopupOpen}
      close={() => closePopup()}
      title={translateLabel("CART_NOTE_CUSTOMER_SERVICE_POPUP_TITLE")}
      isCentered
    >
      <ModalContainer>
        <WrapMessage>
          <CustomText as="p" fontSizePx={13} font="font-medium" color="red" lineHeightPx={18}>
            {translateLabel("CART_NOTE_CUSTOMER_SERVICE_INPUT_WARNING")}
          </CustomText>
        </WrapMessage>
        <WrapTextArea>
          <InputComponent
            id="textarea"
            as="textarea"
            type="text"
            placeholder={translateLabel("CART_NOTE_CUSTOMER_SERVICE_INPUT_PLACEHOLDER")}
            name="textarea"
            helperText={translateLabel("CART_NOTE_CUSTOMER_SERVICE_INPUT_HELPER_TEXT")}
            maxLength={500}
            value={customerNotes}
            onChange={(event) => setCustomerNotes(event.currentTarget.value)}
          />
        </WrapTextArea>
        <PopupButtonContainer>
          <Button type="primary" onClick={onConfirm}>
            {translateLabel("CONFIRM")}
          </Button>
        </PopupButtonContainer>
      </ModalContainer>
    </Popup>
  );
};

const ModalContainer = styled.div`
  padding: 2rem;
  min-width: 42.375rem;
`;

const WrapMessage = styled.div`
  margin-bottom: 1.5rem;
`;

const WrapTextArea = styled.div`
  margin-bottom: 1.5rem;
  height: 13.75rem;
`;

const PopupButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default CartNotesPopup;
