import React from "react";
import { scrollTo } from "../../utils/utils";
import styled from "styled-components/macro";

interface Props {
  toId?: string;
  toRef?: any;
  children: any;
  noAnalytics?: boolean;
  "data-element-id"?: string;
}

const ScrollToButton = ({
  toId,
  toRef,
  children,
  noAnalytics = false,
  "data-element-id": dataElementId,
}: Props): JSX.Element => {
  const handleClick = () => scrollTo(toId, toRef);

  return (
    <button
      onClick={handleClick}
      {...(noAnalytics && { "data-analytics_available_call": "0" })}
      data-element-id={dataElementId}
    >
      {children}
    </button>
  );
};

export default ScrollToButton;
