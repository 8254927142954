import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as Unchecked } from "../../assets/icons/checkbox-unchecked.svg";
import { ReactComponent as Checked } from "../../assets/icons/checkbox-checked.svg";
import TransparentIcon from "../../assets/icons/Transparent.svg";
import clsx from "clsx";
import { ReactComponent as MinusIcon } from "../../assets/icons/checkbox-minus-icon.svg";
import { FrameShape } from "../../interfaces/customFiltersInterfaces";

type CheckboxProps = {
  label: string;
  name: string;
  showIcon?: boolean; // Show the filter icon
  iconName?: string; // The icon name that is showed in filters
  disabled?: boolean;
  hideLabel?: boolean;
  bold?: boolean;
  color?: "primary" | "white";
  isSelectAll?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  controlled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  notDisabledStyle?: boolean;
  isError?: boolean;
  labelJSX?: JSX.Element; //N.B: If you use this element, it doesn't inherit styles from Checkbox
  alignLabel?: "start" | "end" | "center";
  lazyIcon?: "lazy" | "eager";
} & Omit<React.InputHTMLAttributes<HTMLLabelElement>, "onChange">;

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      disabled = false,
      name,
      showIcon,
      iconName,
      hideLabel = false,
      bold = false,
      color = "primary",
      isSelectAll = false,
      checked,
      defaultChecked,
      onChange,
      controlled = false,
      notDisabledStyle = false,
      isError = false,
      labelJSX,
      alignLabel = "center",
      lazyIcon,
    },
    forwardedRef
  ) => {
    const classNameText = clsx(
      disabled && !notDisabledStyle && "checkbox-disabled",
      bold && "label-bold",
      isError && "checkbox-error",
      `color-${color}`
    );

    // ATTENTION! If you would like to use the prop showIcon and iconName, you must add the case to the below function
    const printIcons = (icon: string) => {
      switch (icon) {
        case "Transparent":
        case "Clear":
          return <SpecialIcon src={TransparentIcon} alt={icon} loading={lazyIcon} />;
        case Object.values(FrameShape).includes(icon as FrameShape) && icon:
          return <SpecialIcon src={`/images/${icon}.svg`} alt={icon} shape loading={lazyIcon} />;
        default:
          return <StyledIcon backgroundColor={icon} />;
      }
    };

    return (
      <StyledLabel className={clsx(`color-${color}`)} alignLabel={alignLabel}>
        {controlled && (
          <StyledInput
            type="checkbox"
            disabled={disabled}
            ref={forwardedRef}
            name={name}
            defaultChecked={defaultChecked}
            onChange={onChange}
            checked={checked}
          />
        )}
        {!controlled && (
          <StyledInput
            type="checkbox"
            disabled={disabled}
            ref={forwardedRef}
            name={name}
            defaultChecked={defaultChecked}
            onChange={onChange}
          />
        )}
        <div
          className={clsx(
            "checkbox-checked",
            disabled && "checkbox-disabled",
            isError && "checkbox-error"
          )}
        >
          <Checked />
        </div>
        <div
          className={clsx(
            "checkbox-unchecked",
            disabled && "checkbox-disabled",
            isError && "checkbox-error"
          )}
        >
          {isSelectAll ? <MinusIcon /> : <Unchecked />}
        </div>
        {showIcon && iconName && printIcons(iconName)}
        {!hideLabel && (
          <StyledText className={classNameText} showIcon={showIcon ?? false}>
            {label || labelJSX}
          </StyledText>
        )}
      </StyledLabel>
    );
  }
);

const StyledText = styled.span<{ showIcon: boolean }>`
  font-family: ${(props) => props.theme.fonts.fontMedium};
  font-size: 0.8125rem;
  line-height: 1.125rem;
  margin-left: ${(props) => (props.showIcon ? "0.25rem" : "0.75rem")};

  &.color-primary {
    color: ${(props) => props.theme.palette.primary};
  }

  &.color-white {
    color: ${(props) => props.theme.palette.white};
  }

  &.checkbox-disabled {
    color: ${(props) => props.theme.palette.gray.medium};
  }

  &.label-bold {
    font-family: ${(props) => props.theme.fonts.fontBold};
  }

  &.checkbox-error {
    color: ${(props) => props.theme.palette.feedback.error};
  }

  &.checkbox-hidden {
    display: none;
  }
`;

const StyledIcon = styled.div<{ backgroundColor: any }>`
  height: 0.75rem;
  width: 0.75rem;
  background: ${(props) => props.backgroundColor};
  border-radius: 50%;
  margin-left: 0.75rem;
  border: 1px solid ${(props) => props.theme.palette.gray.medium};
`;

const SpecialIcon = styled.img<{ shape?: boolean }>`
  height: ${(props) => (props.shape ? "1.2rem" : "0.75rem")};
  width: ${(props) => (props.shape ? "1.2rem" : "0.75rem")};
  margin-left: 0.75rem;
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label<{ alignLabel: "start" | "end" | "center" }>`
  display: flex;
  align-items: ${(props) => props.alignLabel};

  .checkbox-unchecked,
  .checkbox-checked,
  svg {
    height: 1.25rem;
  }

  svg {
    fill: ${(props) => props.theme.palette.gray.dark};
    &:hover {
      fill: ${(props) => props.theme.palette.primary};
    }
  }

  &.color-white {
    svg {
      fill: ${(props) => props.theme.palette.gray.medium};
      &:hover {
        fill: ${(props) => props.theme.palette.white};
      }
    }

    .checkbox-checked {
      svg {
        fill: ${(props) => props.theme.palette.gray.medium};
      }
    }

    .checkbox-disabled {
      svg {
        fill: ${(props) => props.theme.palette.gray.dark};
        &:hover {
          fill: ${(props) => props.theme.palette.gray.dark};
        }
      }
    }
  }

  .checkbox-checked {
    display: none;
    svg {
      fill: ${(props) => props.theme.palette.primary};
    }
  }

  .checkbox-disabled {
    svg {
      fill: ${(props) => props.theme.palette.gray.medium};
      &:hover {
        fill: ${(props) => props.theme.palette.gray.medium};
      }
    }
  }

  .checkbox-error {
    svg {
      fill: ${(props) => props.theme.palette.feedback.error};
      &:hover {
        fill: ${(props) => props.theme.palette.feedback.error};
      }
    }
  }

  input:checked ~ .checkbox-checked {
    display: block;
  }

  input:checked ~ .checkbox-unchecked {
    display: none;
  }
`;

Checkbox.displayName = "Checkbox";
export default Checkbox;
