import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";
import { getPagePath } from "../../routing/routesUtils";
import { DecouplingDetails } from "../../store/store/storeInterfaces";
import Button from "../styled-UI/Button";
import CustomText from "../styled-UI/CustomText";

interface Props {
  sapStatus: DecouplingDetails | null;
  close: () => void;
}

const SapDecouplingSection = ({ sapStatus, close }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const history = useHistory();

  const goToHome = () => {
    history.push(getPagePath("/homepage"));
    close();
  };

  return (
    <Container>
      <Center>
        <CustomText as="p" fontSizePx={18} font="font-bold" marginBottomPx={5}>
          {translateLabel("SAP_TITLE")}
        </CustomText>
        <CustomText as="p" fontSizePx={16} font="font-regular" marginBottomPx={20}>
          {sapStatus?.details?.decouplingActiveTo
            ? translateLabel("SAP_MESSAGE")
            : translateLabel("SAP_MESSAGE_WITH_DATE")}
        </CustomText>
        {sapStatus?.details?.decouplingActiveTo && (
          <div>
            <CustomText as="p" fontSizePx={16} font="font-regular" marginBottomPx={20}>
              {translateLabel("SAP_FROM")} {sapStatus?.details?.decouplingActiveFrom}
            </CustomText>
            <CustomText as="p" fontSizePx={16} font="font-regular" marginBottomPx={20}>
              {translateLabel("SAP_TO")} {sapStatus?.details?.decouplingActiveTo}
            </CustomText>
          </div>
        )}
        <Button type="primary" onClick={goToHome}>
          {translateLabel("SAP_GO_TO_HOMEPAGE")}
        </Button>
      </Center>
    </Container>
  );
};

const Container = styled.div`
  padding: 3rem;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25rem;
  min-width: 44rem;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default SapDecouplingSection;
