import { AxiosResponse } from "axios";

import { createAndExecuteService } from "../serviceUtils";
import {
  adtAssetPlatformSocialMediaUrl,
  adtAssetPlatformWebsiteUrl,
  adtCategoriesUrl,
  adtMetaDataUrl,
  adtGetAssetsSavedUrl,
  adtGetAssetsDownloadedUrl,
  adtGetAssetsDetailsUrl,
  adtGetAssetsUrl,
  adtGetAssetsRelatedUrl,
  addToSavedAssetsUrl,
  deleteFromSavedAssetsUrl,
  adtGetFiltersUrl,
  adtTemplateDownload,
  adtOrderAssortmentDownload,
  addToDownloadedAssetsUrl,
  adtProductsImagesByMocoUrl,
  adtGetAssetsSavedFiltersUrl,
  adtGetAssetsDownloadedFiltersUrl,
  adtRequestAssetsDownloadUrl,
} from "./adtUrls";
import {
  AssetPlatformSocialMedia,
  GetAssetsPayload,
  GetPdpAssetPayload,
  MetadataProperty,
  GetOrderAssortmentPayload,
  GetPdpFeaturedProductsPayload,
  SendRequestDownloadAssetsPayload,
} from "./adtInterfaces";
import { base64toBlob } from "../../utils/utils";

export default {
  /////////////////// common
  getAdtCategories: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(adtCategoriesUrl, "GET");
  },

  getAdtAssetPlatformWebsite: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(adtAssetPlatformWebsiteUrl, "GET");
  },

  postAdtAssetPlatformWebsite: async (payload: string | null): Promise<AxiosResponse> => {
    const requestBody = {
      websiteUrl: payload,
    };
    return createAndExecuteService(adtAssetPlatformWebsiteUrl, "POST", null, requestBody);
  },

  getAdtAssetPlatformSocialMedia: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(adtAssetPlatformSocialMediaUrl, "GET");
  },

  postAdtAssetPlatformSocialMedia: async (
    payload: AssetPlatformSocialMedia[]
  ): Promise<AxiosResponse> => {
    const requestBody = {
      socialPlatforms: payload.map((_) => {
        return {
          platformName: _?.platformName,
          platformUserName: _?.platformUserName,
        };
      }),
    };

    return createAndExecuteService(adtAssetPlatformSocialMediaUrl, "POST", null, requestBody);
  },
  getMetaData: async (payload: MetadataProperty): Promise<AxiosResponse> => {
    const url = adtMetaDataUrl.replace("{propertyId}", payload);
    return createAndExecuteService(url, "GET");
  },

  addToSavedAssets: async (payload: string[]): Promise<AxiosResponse> => {
    return createAndExecuteService(addToSavedAssetsUrl, "POST", null, { assetIds: payload });
  },

  deleteFromSavedAssets: async (payload: string[]): Promise<AxiosResponse> => {
    return createAndExecuteService(deleteFromSavedAssetsUrl, "POST", null, { assetIds: payload });
  },

  addToDownloadedAssets: async (payload: string[]): Promise<AxiosResponse> => {
    return createAndExecuteService(addToDownloadedAssetsUrl, "POST", null, { assetIds: payload });
  },

  /////////////////// plp

  getAdtAssets: async (payload: GetAssetsPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(adtGetAssetsUrl, "POST", null, payload);
  },

  getAdtFilters: async (payload: GetAssetsPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(adtGetFiltersUrl, "POST", null, payload);
  },

  /////////////////// pdp
  getAdtAssetsDetails: async (payload: GetPdpAssetPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(adtGetAssetsDetailsUrl, "POST", null, {
      assetIds: [payload.assetId],
    });
  },

  getAdtAssetsRelated: async (payload: GetPdpAssetPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(adtGetAssetsRelatedUrl, "POST", null, {
      assetId: payload.assetId,
    });
  },

  getAdtProductsImagesByMoco: async (
    payload: GetPdpFeaturedProductsPayload
  ): Promise<AxiosResponse> => {
    return createAndExecuteService(adtProductsImagesByMocoUrl, "POST", null, {
      products: payload.products,
    });
  },

  /////////////////// myaccount

  getAdtAssetsSaved: async (payload: GetAssetsPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(adtGetAssetsSavedUrl, "POST", null, payload);
  },

  getAdtAssetsDownloaded: async (payload: GetAssetsPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(adtGetAssetsDownloadedUrl, "POST", null, payload);
  },

  getAdtAssetsSavedFilters: async (payload: GetAssetsPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(adtGetAssetsSavedFiltersUrl, "POST", null, payload);
  },

  getAdtAssetsDownloadedFilters: async (payload: GetAssetsPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(adtGetAssetsDownloadedFiltersUrl, "POST", null, payload);
  },

  sendAdtRequestAssetsDownload: async (
    payload: SendRequestDownloadAssetsPayload
  ): Promise<AxiosResponse> => {
    return createAndExecuteService(adtRequestAssetsDownloadUrl, "POST", null, payload);
  },

  /////////////////// request image

  getAdtOrderAssortmentDownload: async (
    payload: GetOrderAssortmentPayload
  ): Promise<AxiosResponse> => {
    const formData = new FormData();
    payload.file &&
      formData.append(
        "file",
        base64toBlob(payload.file?.base64 as string, payload.file?.file?.type),
        payload.file?.file?.name
      );
    formData.append(
      "req",
      new Blob([JSON.stringify(payload.req)], {
        type: "application/json",
      })
    );
    return createAndExecuteService(adtOrderAssortmentDownload, "POST", null, formData, undefined, {
      "Content-Type": "multipart/form-data",
    });
  },

  getAdtTemplateDownload: async (payload: string): Promise<AxiosResponse> => {
    const getParams = new URLSearchParams();
    getParams.append("downloadType", payload);
    return createAndExecuteService(
      adtTemplateDownload,
      "GET",
      getParams,
      undefined,
      undefined,
      undefined,
      undefined,
      "blob"
    );
  },
};
