import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import { useBreakpoint } from "../../../assets/theme/breakpoint";

import BrandImage from "../../styled-UI/BrandImage";
import CarouselDot from "../../styled-UI/CarouselDot";

import { OrderItem } from "../../../store/cart/cartInterfaces";
import { capitalizeFirstLetter } from "../../../utils/utils";
import CustomText from "../../styled-UI/CustomText";
import IconButton from "../../styled-UI/IconButton";

import { ReactComponent as ArrowHeadRight } from "../../../assets/icons/arrowhead-right-icon.svg";

interface Props {
  currentPage: number;
  updateCurrentPage: (page: number) => void;
  totalVariants?: number;
  variantsPerPage: number;
  orderItem: OrderItem;
}

const CartAlternativePopupHeader = ({
  currentPage,
  updateCurrentPage,
  variantsPerPage,
  totalVariants = 0,
  orderItem,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const breakpoints = useBreakpoint();
  const [dots, setDots] = useState<number[]>();

  const totalPages = Math.ceil(totalVariants / variantsPerPage);

  useEffect(() => {
    const newArrayDots = [];
    for (let i = 1; i <= totalPages; i++) {
      newArrayDots.push(i);
    }
    setDots(newArrayDots);
  }, [totalPages]);

  const getNextVariants = (page: "prev" | "next") => {
    const newPage = page === "prev" ? currentPage - 1 : currentPage + 1;
    updateCurrentPage(newPage);
  };

  const goNextVariantsWithDot = (index: number) => {
    const newPage = index;
    updateCurrentPage(newPage);
  };
  const renderPageStatus = (): ReactNode => {
    return totalVariants ? (
      <PageStatus>
        {dots &&
          dots.map((dot) => {
            return (
              <CarouselDot
                currentPage={currentPage}
                navigationType={"central"}
                changeSlide={() => goNextVariantsWithDot(dot)}
                key={dot}
                dot={dot}
                index={dot}
              />
            );
          })}
      </PageStatus>
    ) : null;
  };
  const renderCarouselNavbar = () =>
    totalVariants && totalVariants > variantsPerPage ? (
      <BoxButtons className="d-flex align-items-center">
        <ButtonSpacer>
          <IconButton
            type="primary"
            size={breakpoints["md"] ? "md" : "lg"}
            onClick={() => getNextVariants("prev")}
            disabled={currentPage === 1}
            icon={<ArrowHeadRight />}
          />
        </ButtonSpacer>
        <IconButton
          type="primary"
          size={breakpoints["md"] ? "md" : "lg"}
          onClick={() => getNextVariants("next")}
          disabled={currentPage === totalPages}
          icon={<ArrowHeadRight />}
        />
      </BoxButtons>
    ) : null;

  return (
    <Header>
      <div className="d-flex align-items-center">
        <LogoContainer>
          <BrandImage brandOrBrandGroup={orderItem.sku.brand ? orderItem.sku.brand : ""} />
        </LogoContainer>
        <FlexContainer>
          <CustomText as="span" fontSizePx={22} font="font-bold">
            {translateLabel(orderItem.sku.brand + "_LABEL")} {orderItem.productCode?.toUpperCase()}
          </CustomText>
          <div>
            <CustomText
              as="span"
              fontSizePx={16}
              font="font-regular"
              color="gray-dark"
              marginRightPx={37}
              marginLeftPx={12}
            >
              {capitalizeFirstLetter(orderItem?.sku?.frontColorDescription?.values)}
            </CustomText>
          </div>
        </FlexContainer>
      </div>
      <div>{renderPageStatus()}</div>
      <Box className="d-flex align-items-center">{renderCarouselNavbar()}</Box>
    </Header>
  );
};

//#region Style
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
  padding: 1rem 2.5rem;
  align-items: center;
  img {
    height: 2.625rem;
    margin-right: 2rem;
  }
`;
const PageStatus = styled.div`
  margin-right: 3rem;
  display: flex;
`;

const LogoContainer = styled.div`
  min-height: 2.7rem;
  height: 100%;
  margin-right: 1.75rem;
  width: 5rem;
  flex-shrink: 0;
`;
const Box = styled.div`
  position: absolute;
  width: 104%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
`;
const BoxButtons = styled.div`
  width: 100%;
  justify-content: space-between;
`;
const ButtonSpacer = styled.div`
  margin-right: 0.8125rem;

  svg {
    transform: rotate(180deg);
  }
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  /* @media ${(props) => props.theme.queries.md} {
    flex-direction: column;
    align-items: flex-start;
  } */
`;

export default CartAlternativePopupHeader;
