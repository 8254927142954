import React from "react";
import LoaderElement from "../LoaderElement";

const LoaderHomepageMostUsed = (): JSX.Element => {
  return (
    <>
      <LoaderElement height={44} width={172} radius={8} style="default" />
      <LoaderElement height={44} width={172} radius={8} style="default" />
      <LoaderElement height={44} width={172} radius={8} style="default" />
    </>
  );
};

export default LoaderHomepageMostUsed;
