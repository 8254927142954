import { useMemo } from "react";

import { MostUsedItem } from "../interfaces/cmsInterfaces";
import useCanRender from "./useCanRender";

const useMostUsedLensesCMS = (): MostUsedItem[] => {
  const canRender = useCanRender();

  const canRenderItems = (): MostUsedItem[] => {
    const items: MostUsedItem[] = [];
    if (canRender("LENS_DIGITAL_CATALOGUE"))
      items.push({
        id: "DIGITAL_CATALOGUE",
        name: "DIGITAL_CATALOGUE",
        type: "LENSES",
        title: "MOST_USED_DIGITAL_CATALOGUE",
        modificationDate: "",
        linkString: "/digital-catalog",
        creationDate: "",
        analyticsTag: [
          {
            id: "",
            name: "MostUsedDigitalCatalog",
            externalReference: "MostUsedDigitalCatalog",
          },
        ],
      });
    if (canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY") || canRender("RX"))
      items.push({
        id: "LENS_CONFIGURATOR",
        name: "LENS_CONFIGURATOR",
        type: "LENSES",
        title: "MOST_USED_LENS_CONFIGURATOR",
        modificationDate: "",
        linkString: canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY")
          ? "/rx-prescription?rxFlow=Essilor"
          : canRender("RX")
          ? "/rx-prescription?rxFlow=Authentics"
          : "",
        creationDate: "",
        analyticsTag: [
          {
            id: "",
            name: "MostUsedLensConfigurator",
            externalReference: "MostUsedLensConfigurator",
          },
        ],
      });

    return items;
  };

  return useMemo(() => {
    return canRenderItems();
  }, []);
};

export default useMostUsedLensesCMS;
