import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

import { RootState } from "../store/storeConfig";

/**
 * Generic hook used to only get updated when the desired element of a selector returning an array changes
 *  - T is such that the selector returns T[]
 *  - filter is the filter applied to the array to get the desired element
 *
 * @template T
 * @param {(state: RootState) => T[]} selector
 * @param {(_: T) => boolean} filter
 * @return {*}  {(T | null)}
 */
const useFilteredSelector = <T>(
  selector: (state: RootState) => T[],
  filter: (_: T) => boolean
): T | null => {
  const selectedArray = useSelector(selector);

  const [filteredValue, setFilteredValue] = useState<T | null>(null);

  useEffect(() => {
    const newValue = selectedArray.filter((_) => filter(_))?.[0] ?? null;

    if (!isEqual(filteredValue, newValue)) setFilteredValue(newValue);
  }, [selectedArray]);

  return filteredValue;
};

export default useFilteredSelector;
