import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useCanRender from "../../hooks/useCanRender";
import useCarouselPagination from "../../hooks/useCarouselPagination";

import {
  saveProductRecommendation,
  selectProductRecommendation,
  selectProductRecommendationStatus,
} from "../../store/catalogue/catalogueSlice";
import { addItemsPerVariantToPrecart, getPrecart } from "../../store/cart/cartSagas";
import { selectIsMultidoor } from "../../store/user/userSlice";
import { trackEvent } from "../../store/analytics/analyticsSaga";
import {
  cancelProductRecommendation,
  getProductRecommendation,
} from "../../store/catalogue/catalogueSaga";
// import { getPagePath } from "../../routing/routesUtils";
import { Variant } from "../../interfaces/productInterface";
import WrapperLayout from "../layouts/WrapperLayout";
import HomeCarousel from "../styled-UI/HomeCarousel";
import LoaderTile from "../styled-UI/loader/LoaderTile";
import LoaderCarousel from "../styled-UI/loader/LoaderTileCarousel";
import RouteLeavingGuardPrecart from "../widgets/route-leaving-guard/RouteLeavingGuardPrecart";
import Tile from "./Tile/Tile";
import ConditionalRender from "./ConditionalRender";
import { getPagePath } from "../../routing/routesUtils";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

export const ProductRecommendation = (): JSX.Element => {
  const canRender = useCanRender();
  const dispatch = useDispatch();

  const isMultidoor = useSelector(selectIsMultidoor); //CAROUSEL SHOULD NOT BE VISIBLE FOR MULTIDOOR
  const productRec = useSelector(selectProductRecommendation);
  const requestStatus = useSelector(selectProductRecommendationStatus);

  const addToCart = (variant: Variant) => {
    dispatch(trackEvent({ id: "AddToCart" }));
    dispatch(
      addItemsPerVariantToPrecart({
        variant: variant,
        doSimulate: true,
        callback: () => dispatch(getPrecart()),
      })
    );
  };

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// PAGINATION ///////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const carouselPagination = useCarouselPagination({
    resultsTotal: productRec?.recordSetTotal ?? 0,
    resultListLength: productRec?.catalogEntryView.length ?? 0,
    slidesPerCarousel: 3,
    maxSlides: 12,
    dispatchCallback: (newPage: number) => {
      dispatch(getProductRecommendation(newPage));
    },
  });

  //////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////// LAZY LOADING //////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////
  // !!!!!!!!!! ATTENZIONE: CAPIRE PERCHE' IL LAZY LOADING ERA STATO COMMENTATO !!!!!!!!!!!

  const [isProdRecLoaded, setIsProdRecLoaded] = useState<boolean>(false);

  const { containerRef, isVisible: isProdRecVisible } = useIntersectionObserver<HTMLDivElement>({
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  useEffect(() => {
    if (!isProdRecLoaded && isProdRecVisible) setIsProdRecLoaded(true);
  }, [isProdRecVisible]);

  useEffect(() => {
    if (
      !isMultidoor &&
      canRender("ADD_TO_CART") &&
      !productRec?.catalogEntryView &&
      requestStatus === "IDLE" &&
      isProdRecLoaded
    )
      dispatch(getProductRecommendation(carouselPagination.currentPage));

    return () => {
      dispatch(cancelProductRecommendation());
      dispatch(saveProductRecommendation({ type: "reset" }));
    };
  }, [isProdRecLoaded]);

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// RENDER /////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const renderTiles = (variants: Variant[]): JSX.Element[] => {
    const tiles: JSX.Element[] = [];

    for (let i = 0; i < carouselPagination.totalSlidesMaxed; i++) {
      const product = variants[i];

      if (product) {
        tiles.push(
          <Slide key={i}>
            <TileContainer>
              <Tile
                data={product}
                hideSeeVariants
                showBrandInVariant
                showCodeInVariant
                showAddToCartButton
                addToCart={addToCart}
                hideColorsVariant
                cssStyle="space-between"
                data-element-id-img="Products_SelectedForYou"
                data-description-img={product.variantCode}
              />
            </TileContainer>
          </Slide>
        );
      } else {
        tiles.push(
          <Slide key={i}>
            <LoaderTile />
          </Slide>
        );
      }
    }
    return tiles;
  };

  const renderProductRec = () => {
    if (productRec && productRec?.catalogEntryView?.length > 0)
      return (
        // id is for Recommended button integration
        <Section id="carousel-for-you">
          <WrapperLayout>
            <HomeCarousel
              slidesToScroll={3}
              slidesToShow={3}
              title="SELECTED_FOR_YOU"
              colorSelect={"gray-medium"}
              hideDisplay={true}
              showViewAll={productRec?.catalogEntryView.length >= 12}
              url={getPagePath("/for-you")}
              enableDataDescriptionNavigation
              data-element-id="Products_SelectedForYou_Navigation"
              callbackOnChangeSlide={(page: number) =>
                carouselPagination.getNextCarousel(
                  page >= carouselPagination.currentPage * 3 ? "next" : "prev"
                )
              }
            >
              {renderTiles(productRec?.catalogEntryView)}
            </HomeCarousel>{" "}
          </WrapperLayout>
        </Section>
      );

    if (requestStatus === "LOADING")
      return (
        <Section>
          <WrapperLayout>
            <LoaderCarousel />
          </WrapperLayout>
        </Section>
      );

    return <></>;
  };

  if (isMultidoor) return <></>;
  return (
    <ConditionalRender privilege="ADD_TO_CART">
      <div ref={containerRef}>{renderProductRec()}</div>
      <RouteLeavingGuardPrecart allowedPath={`/cart`} />
    </ConditionalRender>
  );
};

const Section = styled.div`
  padding: 3rem 0;
  background-color: ${(props) => props.theme.palette.gray.medium};
`;

const Slide = styled.div`
  padding-right: 1.25rem;
  box-sizing: border-box;

  @media ${(props) => props.theme.queries.md} {
    padding-right: 0;
  }
`;

const TileContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 41.5rem;
`;

export default ProductRecommendation;

export const MemoizedProductRecommendation = React.memo(ProductRecommendation);
