import { useState } from "react";
import { AxiosResponse } from "axios";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import { useDispatch } from "react-redux";
import { handleError } from "../store/storeSagas";

/**
 * Custom Hook used to call services that have no impact on app-wide store.
 *
 * Usage: calling hook inside component returns a *requestStatus* object (to track whether the request is loading,
 * if it was successful, the data in the response and eventual errors) and a *makeRequest* function, that requires a
 * service to be called as a parameter.
 *
 * @return {*}  {{
 *   requestStatus: {
 *     status: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
 *     error?: Error;
 *     data?: any;
 *   };
 *   makeRequest: (service: () => Promise<AxiosResponse>) => Promise<void>;
 * }}
 */

export interface RequestStatusStateless {
  status: RequestStatus;
  error?: Error;
  data?: any;
}

const useStatelessService = (): {
  requestStatus: RequestStatusStateless;
  makeRequest: (service: (param?: any) => Promise<AxiosResponse>, param?: any) => Promise<void>;
  resetRequestState: () => void;
} => {
  const initialState: RequestStatusStateless = { status: "IDLE" };
  const dispatch = useDispatch();
  const [requestStatus, setRequestStatus] = useState<RequestStatusStateless>(initialState);

  const resetRequestState = () => {
    setRequestStatus(initialState);
  };

  const makeRequest = async (service: (param?: any) => Promise<AxiosResponse>, param?: any) => {
    setRequestStatus({ status: "LOADING" });
    try {
      await service(param).then((response) => {
        setRequestStatus({ status: "SUCCESS", data: response.data });
      });
    } catch (error) {
      dispatch(handleError(error));
      setRequestStatus({ status: "ERROR", error });
    }
  };

  return { requestStatus, makeRequest, resetRequestState };
};

export default useStatelessService;
