import { AxiosResponse } from "axios";
import { DeletePrecartItemsPayload, PutPrecartItemsPayload } from "../cart/cartInterfaces";
import { createAndExecuteService } from "../serviceUtils";
import {
  OrdersToApproveFilters,
  ApproveRejectOrdersPayload,
  SimulateOrdersToApprovePayload,
  SuborderApprovalIdsPayoad,
  ExportOrderToApproveCSVPayload,
} from "./ordersToApproveInterface";
import {
  approvalSubordersItemsUrl,
  exportOrderToApproveCSVUrl,
  exportOrderToApproveTYPCSVUrl,
  getOrdersToApproveUrl,
  getOrderToApproveDetailsUrl,
  getOrderToApproveHeaderUrl,
  simulateOrdersToApproveUrl,
  updateOrdersToApproveUrl,
} from "./ordersToApproveUrls";

const orderService = {
  // get list of orders to be approved/rejected
  getOrdersToApprove: async (params: OrdersToApproveFilters): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => value && searchParams.append(key, value));

    return createAndExecuteService(getOrdersToApproveUrl, "GET", searchParams);
  },

  // approve/reject selected orders
  updateOrdersToApprove: async (params: ApproveRejectOrdersPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(updateOrdersToApproveUrl, "PUT", null, params);
  },

  // run simulate on specified orders before approving
  simulateOrdersToApprove: async (
    params: SimulateOrdersToApprovePayload
  ): Promise<AxiosResponse> => {
    return createAndExecuteService(simulateOrdersToApproveUrl, "POST", null, params);
  },

  // get order details header
  getOrderToApproveHeader: async (payload: SuborderApprovalIdsPayoad): Promise<AxiosResponse> => {
    return createAndExecuteService(getOrderToApproveHeaderUrl, "POST", null, payload);
  },

  // get order details main content
  getOrderToApproveDetails: async (subOrderId: string): Promise<AxiosResponse> => {
    const url = getOrderToApproveDetailsUrl.replace("{subOrderId}", subOrderId);
    return createAndExecuteService(url, "GET");
  },

  // update order items in suborder approval
  putOrderToApproveItems: async (payload: PutPrecartItemsPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(approvalSubordersItemsUrl, "PUT", null, payload);
  },

  // delete order items in suborder approval
  deleteOrderToApproveItems: async (payload: DeletePrecartItemsPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(approvalSubordersItemsUrl, "DELETE", null, payload);
  },

  // export orders csv
  exportOrderToApproveCSV: async (
    payload: ExportOrderToApproveCSVPayload
  ): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();
    payload.subOrderId.forEach((id) => searchParams.append("subOrderId", id));

    const url = payload?.isTYP ? exportOrderToApproveTYPCSVUrl : exportOrderToApproveCSVUrl;

    return createAndExecuteService(url, "GET", searchParams);
  },
};

export default orderService;
