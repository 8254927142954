import React from "react";
import styled from "styled-components/macro";

import LoaderElement from "../LoaderElement";
import LoaderElementCircle from "../LoaderElementCircle";
import { TileStyle } from "../LoaderTile";

interface Props {
  style: TileStyle;
}

const SIZE_GUIDE: { [key in TileStyle]: { title: number; subtitle: number } } = {
  default: {
    title: 200,
    subtitle: 100,
  },
  horizontal: {
    title: 100,
    subtitle: 80,
  },
};

const TileHeaderLoader = ({ style = "default" }: Props): JSX.Element => {
  const sizes = SIZE_GUIDE[style];

  return (
    <TileHeader className={style}>
      <LoaderElement height={30} width={sizes.title} />
      {style === "default" && <LoaderElementCircle height={30} width={30} />}
      <LoaderElement height={15} width={sizes.subtitle} />
    </TileHeader>
  );
};

const TileHeader = styled.div`
  display: flex;
  flex-wrap: wrap;

  &.default {
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    row-gap: 0.3rem;
  }

  &.horizontal {
    flex-direction: column;
    row-gap: 0.3rem;
  }
`;

export default TileHeaderLoader;
