import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";
import { capitalizeFirstLetter } from "../../utils/utils";

interface Props {
  tag: string;
  size?: "md" | "sm";
  type?: "one-tile";
  color?: string;
  isEllipsis?: boolean;
}

const Tag = ({ tag, size = "md", type, color, isEllipsis }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  const tagContent = capitalizeFirstLetter(translateLabel(tag) ? translateLabel(tag) : tag);
  return (
    <TagItem
      key="tag"
      className={clsx(
        size === "sm" && "tag-sm",
        type === "one-tile" && "tag-one-tile",
        isEllipsis === true && "tag-is-ellipsis"
      )}
      color={color}
      isEllipsis
      title={tagContent}
    >
      {tagContent}
    </TagItem>
  );
};

const TagItem = styled.div<{ color?: string; isEllipsis: boolean }>`
  display: inline-block;
  border: 1px solid ${(props) => props.theme.palette.gray.medium};
  border-radius: 0.875rem;
  padding: 0.5rem 0.75rem;
  /* margin: 0 0.5rem 0.5rem 0; */
  font-size: 0.75rem;
  font-family: ${(props) => props.theme.fonts.fontMedium};
  color: ${(props) => props.color ?? props.theme.palette.primary};

  &.tag-sm {
    padding: 0 0.5rem;
    line-height: 1.25rem;
  }

  &.tag-one-tile {
    color: ${(props) => props.color ?? props.theme.palette.white};
    text-transform: uppercase;
  }

  &.tag-is-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 7.125rem;
  }
`;

export default Tag;
