import React from "react";
import styled from "styled-components/macro";
import CustomText from "../styled-UI/CustomText";
import Button from "../styled-UI/Button";
import useTranslation from "../../hooks/useTranslation";
import { getPagePath } from "../../routing/routesUtils";
import { useHistory } from "react-router-dom";
import WrapperLayout from "../layouts/WrapperLayout";

const UnauthorizedSection = (): JSX.Element => {
  const history = useHistory();

  const { translateLabel } = useTranslation();

  const goToHome = () => {
    history.push(getPagePath("/homepage"));
  };

  return (
    <WrapperLayout>
      <Container>
        <Center>
          <CustomText as="p" fontSizePx={18} font="font-bold" marginBottomPx={5}>
            {translateLabel("NO_AUTH_TITLE")}
          </CustomText>
          <CustomText as="p" fontSizePx={16} font="font-regular" marginBottomPx={20}>
            {translateLabel("NO_AUTH_MESSAGE")}
          </CustomText>
          <ButtonContainer>
            <Button type="primary" onClick={goToHome}>
              {translateLabel("NO_AUTH_BUTTON")}
            </Button>
          </ButtonContainer>
        </Center>
      </Container>
    </WrapperLayout>
  );
};

const Container = styled.div`
  padding: 3rem;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25rem;
  margin-bottom: 2rem;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export default UnauthorizedSection;
