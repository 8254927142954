import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import Popup from "../../styled-UI/Popup";
import CustomText from "../../styled-UI/CustomText";
import { useDispatch, useSelector } from "react-redux";
import { getStartSR } from "../../../store/serviceRequests/serviceRequestSagas";
import ServiceRequestPopupSimple from "./ServiceRequestPopupSimple";
import ServiceRequestPopupAddress from "./ServiceRequestPopupAddress";
import { selectSelectedDoor, selectUserName } from "../../../store/user/userSlice";
import {
  resetPopup,
  selectGetServiceRequestIdentifierStatus,
  selectServiceRequestIdentifier,
  setForceUpdateCounterSR,
  setForceUpdateSRPage,
} from "../../../store/serviceRequests/serviceRequestSlice";
import LoaderSRPopup from "../../styled-UI/loader/service-request/LoaderSRPopup";
import ServiceRequestPopupBrands from "./ServiceRequestPopupBrands";
import { PopupServiceRequest } from "../../../interfaces/popupInterfaces";
import ServiceRequestPopupSR11 from "./ServiceRequestPopupSR11";
import ServiceRequestPopupSR07 from "./ServiceRequestPopupSR07";
import ServiceRequestPopupSR08 from "./ServiceRequestPopupSR08";
import { useLocation } from "react-router-dom";

interface Props {
  data: PopupServiceRequest;
  close: () => void;
}

const ServiceRequestPopup = ({ data, close }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { translateLabel } = useTranslation();
  const selectedDoor = useSelector(selectSelectedDoor);
  const username = useSelector(selectUserName);
  const getServiceRequestIdentifierStatus = useSelector(selectGetServiceRequestIdentifierStatus);
  const serviceRequestIdentifier = useSelector(selectServiceRequestIdentifier);

  const {
    open,
    addressOf,
    requestType,
    addressDetails,
    orderIdentifier,
    shippingCode,
    b2bOrderIdentifier,
  } = data;
  const [hasSubmittedRequest, setHasSubmittedRequest] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [location]);
  //Get service request id at opening of popup
  useEffect(() => {
    if (open && selectedDoor && username)
      dispatch(getStartSR({ doorId: selectedDoor.orgentityId, username }));
  }, [open, selectedDoor]);

  const handleClose = () => {
    close();
    dispatch(resetPopup());

    if (hasSubmittedRequest) {
      dispatch(setForceUpdateSRPage(true));
      dispatch(setForceUpdateCounterSR(true));
      setHasSubmittedRequest(false);
    }
  };

  useEffect(() => {
    if (hasSubmittedRequest) {
      setTimeout(() => {
        handleClose();
      }, 2500);
    }
  }, [hasSubmittedRequest]);

  const renderPopupContent = () => {
    if (selectedDoor)
      switch (requestType) {
        case "simpleRequest":
          return (
            <ServiceRequestPopupSimple
              serviceRequestIdentifier={serviceRequestIdentifier}
              selectedDoor={selectedDoor}
              setHasSubmittedRequest={setHasSubmittedRequest}
            />
          );
        case "brandsRequest":
          return (
            <ServiceRequestPopupBrands
              serviceRequestIdentifier={serviceRequestIdentifier}
              selectedDoor={selectedDoor}
              setHasSubmittedRequest={setHasSubmittedRequest}
            />
          );
        case "addAddressRequest":
        case "modifyAddressRequest":
          return (
            <ServiceRequestPopupAddress
              type={requestType === "addAddressRequest" ? "add" : "modify"}
              addressOf={addressOf}
              serviceRequestIdentifier={serviceRequestIdentifier}
              addressDetails={addressDetails}
              selectedDoor={selectedDoor}
              setHasSubmittedRequest={setHasSubmittedRequest}
            />
          );
        case "simpleRequestReceiveDime":
          return (
            <ServiceRequestPopupSR11
              serviceRequestIdentifier={serviceRequestIdentifier}
              selectedDoor={selectedDoor}
              setHasSubmittedRequest={setHasSubmittedRequest}
            />
          );
        case "orderSupportRequest":
          return (
            <ServiceRequestPopupSR07
              serviceRequestIdentifier={serviceRequestIdentifier}
              selectedDoor={selectedDoor}
              orderIdentifier={orderIdentifier}
              b2bOrderIdentifier={b2bOrderIdentifier}
              setHasSubmittedRequest={setHasSubmittedRequest}
            />
          );
        case "shipmentSupportRequest":
          return (
            <ServiceRequestPopupSR08
              serviceRequestIdentifier={serviceRequestIdentifier}
              selectedDoor={selectedDoor}
              orderIdentifier={orderIdentifier}
              shippingCode={shippingCode}
              b2bOrderIdentifier={b2bOrderIdentifier}
              setHasSubmittedRequest={setHasSubmittedRequest}
            />
          );
      }
  };

  return (
    <Popup title={translateLabel("SERVICE_REQUEST_TITLE")} isOpen={open} close={handleClose}>
      <StyledPopupContent>
        <CustomText as="div" fontSizePx={18} lineHeightPx={24} font="font-bold" marginBottomPx={18}>
          {translateLabel("CLIENT_CODE")}: {selectedDoor?.orgentityName}
        </CustomText>
        {getServiceRequestIdentifierStatus === "LOADING" && <LoaderSRPopup />}
        {getServiceRequestIdentifierStatus === "SUCCESS" && renderPopupContent()}
        {getServiceRequestIdentifierStatus === "ERROR" && (
          <CustomText as="p" fontSizePx={13} lineHeightPx={18} marginTopPx={16} color="red">
            {translateLabel("ERROR_POPUP_DEFAULT_MESSAGE")}
          </CustomText>
        )}
      </StyledPopupContent>
    </Popup>
  );
};

//#region styles
const StyledPopupContent = styled.div`
  min-width: 42rem;
  min-height: 18rem;
  padding: 1rem 2rem 2rem;

  .textarea-wrapper {
    textarea {
      min-height: 7.6rem;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: end;

    button {
      min-width: 8.75rem;
    }
  }
`;
//#endregion

export default ServiceRequestPopup;
