import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../storeConfig";

import { DoorList, MultidoorLoading, MultidoorState } from "./multidoorInterface";

export const sliceName = "multidoor";

const defaultMultidoorLoading = {
  doorList: false,
};

const initialState: MultidoorState = {
  doorList: null,
  doorsPerPage: 20,
  loading: { ...defaultMultidoorLoading },
};

export const multidoorSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveDoorList: (state, action: PayloadAction<DoorList>) => {
      state.doorList = action.payload;
      state.loading = {
        ...state.loading,
        doorList: false,
      };
    },
    resetDoorList: (state) => {
      state.doorList = null;
      state.loading = {
        ...state.loading,
        doorList: false,
      };
    },
    startMultidoorLoading: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case "doorList":
          state.loading = {
            ...state.loading,
            doorList: true,
          };
          break;
      }
    },
    stopMultidoorLoading: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case "doorList":
          state.loading = {
            ...state.loading,
            doorList: false,
          };
          break;
      }
    },
    resetState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  saveDoorList,
  resetDoorList,
  startMultidoorLoading,
  stopMultidoorLoading,
} = multidoorSlice.actions;

export const selectDoorList = (state: RootState): DoorList | null => state.multidoor.doorList;

export const selectDoorLoading = (state: RootState): MultidoorLoading => state.multidoor.loading;

export const selectPageSize = (state: RootState): number => state.multidoor.doorsPerPage;

export default multidoorSlice.reducer;
