import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";
import { EditorialProductTile as EditorialProductTileInterface } from "../../interfaces/cmsInterfaces";
import { ItemPrice, Seo } from "../../interfaces/productInterface";
import { getPagePath } from "../../routing/routesUtils";
import { sendAnalyticsData } from "../../store/analytics/analyticsService";
import { Attachment } from "../../store/catalogue/catalogueInterface";
import { selectStickyBarType } from "../../store/store/storeSlice";
import { getImageByLayoutType, getInfoFromModelOrVariant, showTileCms } from "../../utils/cmsUtils";
import { capitalizeFirstLetter } from "../../utils/utils";
import CustomProgressiveImage from "../styled-UI/CustomProgressiveImage";
import CustomText from "../styled-UI/CustomText";
import PriceTile from "./PriceTile";
import { FontFamilies } from "../../store/store/storeInterfaces";

export interface CmsProductInfo {
  image?: string;
  brand?: string;
  name?: string;
  price?: ItemPrice;
  productCode?: string;
  seo?: Seo;
  attachments?: Attachment[];
}
interface Props {
  data: EditorialProductTileInterface;
  position?: number;
  rowNumber?: number;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const EditorialProductTile = ({
  data,
  position,
  rowNumber,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const layout = data.layoutVariant?.[0]?.name;
  const history = useHistory();
  const { model, modelColor } = data;
  const { translateLabel } = useTranslation();

  const { seo, price, brand, name, attachments, productCode } = getInfoFromModelOrVariant(
    model,
    modelColor
  );
  const attachment = getImageByLayoutType(attachments, layout);
  const code = model ? model?.productCode : modelColor?.variantCode;

  const stickyBarType = useSelector(selectStickyBarType);
  const ref: any = useRef();

  useEffect(() => {
    const product = model ?? modelColor;
    const showTile = showTileCms(stickyBarType, product);
    if (showTile === false) {
      ref.current.parentElement.parentElement.style = "display: none";
    } else {
      ref.current.parentElement.parentElement.style = "display: block";
    }
  }, [stickyBarType]);

  return (
    <Container ref={ref}>
      <BoxGlass
        onClick={() => {
          sendAnalyticsData({
            id: "Click",
            Tracking_Type: "link",
            data_element_id: `EditorialTile_Tiles_Tile${rowNumber}x${position}`,
            data_description: code,
          });
          data?.inAssortment && history.push(getPagePath("/pdp" + seo?.href));
        }}
      >
        <ImageContainer>
          <CustomProgressiveImage src={attachment?.url ?? ""} type="glasses" />
        </ImageContainer>

        <InnerBox>
          <BoxProductName isDarkMode={isDarkMode} customFont={customFont}>
            {brand && translateLabel(`${brand}_LABEL`) + " "}
            {capitalizeFirstLetter(name)}
          </BoxProductName>

          <BoxProductCode isDarkMode={isDarkMode} customFont={customFont}>
            {productCode}
          </BoxProductCode>
          {data?.inAssortment ? (
            <PriceContainer>
              <PriceTile price={price} isDarkMode={isDarkMode} customFont={customFont} />
            </PriceContainer>
          ) : (
            <PriceContainer className="no-assortment">
              <CustomText
                as="h1"
                color={isDarkMode ? "white" : "primary"}
                fontSizePx={13}
                font={customFont ? "custom-bold" : "font-bold"}
                textAlign="center"
              >
                {translateLabel("LANDING_PAGE_NO_ASSORTMENT")}
              </CustomText>
            </PriceContainer>
          )}
        </InnerBox>
      </BoxGlass>
    </Container>
  );
};

export default EditorialProductTile;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50rem;
`;

const BoxGlass = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 0 1.8rem 0 0; */
  /* padding-right: 1.5rem; */
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
`;

const InnerBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxProductName = styled.div<{ isDarkMode: boolean; customFont: FontFamilies | null }>`
  margin: 0 3.75rem 0.625rem;
  font-family: ${(props) =>
    props.customFont
      ? props.customFont + " " + props.theme.fonts.customBold
      : props.theme.fonts.fontBold};
  font-size: 2.25rem;
  line-height: 1;
  text-align: center;
  color: ${(props) => (props.isDarkMode ? props.theme.palette.white : props.theme.palette.primary)};
`;

const BoxProductCode = styled.div<{ isDarkMode: boolean; customFont: FontFamilies | null }>`
  margin: 0.625rem 7rem 3.438rem 6.938rem;
  font-family: ${(props) =>
    props.customFont
      ? props.customFont + " " + props.theme.fonts.customLight
      : props.theme.fonts.fontLight};
  font-size: 1.125rem;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: center;
  color: ${(props) => (props.isDarkMode ? props.theme.palette.white : props.theme.palette.primary)};
`;

const PriceContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  margin: 0 auto;
  padding-top: 1rem;
  min-height: 6rem;
  max-width: 18.75rem;
  padding-left: 1.5rem;
  &.no-assortment {
    border-top: 1px solid ${(props) => props.theme.palette.gray.medium};
  }
`;
