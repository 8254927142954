import { cloneDeep } from "lodash";
import { CUSTOM_PRIVILEGES_TEMPLATE_OPTION } from "../components/pages/handle-subuser/HandleSubuserPrivileges";
import { CustomOptions } from "../components/styled-UI/CustomSelect";
import {
  SubuserType,
  SubuserList,
  Subuser,
  _Subuser,
  Privilege,
  PrivilegeGroup,
  PrivilegesTemplate,
  PrivilegeSubgroup,
  PrivilegeDiff,
  SubuserItem,
} from "../store/subuser/subuserInterfaces";

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////// TYPES /////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Custom type guard, check if object implements Subuser<"MyELSubusers"> interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(any)} object
 * @return {*}  {object is Subuser<"MyELSubusers">}
 */
export function instanceOfMyELSubusers(object: any): object is Subuser<"MyELSubusers"> {
  return object?.type === "MyELSubusers";
}

/**
 * Custom type guard, check if object implements Subuser<"LeoSubusers"> interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(any)} object
 * @return {*}  {object is Subuser<"LeoSubusers">}
 */
export function instanceOfLeoSubusers(object: any): object is Subuser<"LeoSubusers"> {
  return object?.type === "LeoSubusers";
}

export const isSubuserLeoOnlyLight = (privilegeGroups: PrivilegeGroup[]): boolean => {
  const activePrivileges: Privilege[] = [];

  privilegeGroups?.forEach((privilegeGroup) => {
    const active = privilegeGroup?.privileges?.filter((privilege) => privilege.active);
    activePrivileges.push(...active);
  });

  return (
    activePrivileges.length <= 1 && activePrivileges?.[0]?.userPrivilegeKey === "LEONARDO_SECTION"
  );
};

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// DATA MAPPINGS /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const mapSubuserList = <T extends SubuserType>(
  data: any,
  subuserType: T
): SubuserList<T> => {
  const mapSubuser = <T extends SubuserType>(data: any, subuserType: T): Subuser<T> => {
    const commonData: _Subuser = {
      type: subuserType,
      firstName: (data?.firstName ?? data?.firstname) as string,
      lastName: (data?.lastName ?? data?.lastname) as string,
      email: data?.email as string,
      userName: (data?.userName ?? data?.username) as string,
      locked: data?.locked ?? (!data?.active as boolean),
      hasLoggedIn: data?.hasLoggedIn ?? data?.lastLogin ?? false,
    };

    if (subuserType === "MyELSubusers") {
      const user: Subuser<"MyELSubusers"> = {
        ...commonData,
        type: "MyELSubusers",
        locked: data?.locked as boolean,
        userId: data?.userId as string,
        emailFlag: data?.emailFlag as "RED" | "YELLOW",
        onlyLeoPrivilege: data?.onlyLeoPrivilege ?? false,
      };

      return user as Subuser<T>;
    } else {
      const user: Subuser<"LeoSubusers"> = {
        ...commonData,
        type: "LeoSubusers",
        hasLoggedIn: data?.hasLoggedIn as boolean,
        door: data?.door as string,
        suffix: data?.suffix as string,
        phone: data?.phone as string,
      };

      return user as Subuser<T>;
    }
  };

  return {
    pageNumber: data?.pageNumber,
    pageSize: data?.pageSize,
    totSubusers: data?.totSubusers ?? data?.totUsers,
    subusers: data?.subusers?.map((subuser: any) => mapSubuser(subuser, subuserType)),
  };
};

export const mapMyELSubuserItem = (data: any): SubuserItem => {
  return {
    userName: data?.address?.nickName,
    firstName: data?.address?.firstName,
    lastName: data?.address?.lastName,
    phone: data?.address?.phone1,
    email: data?.address?.email1,
  };
};

export const mapLeoSubuserItem = (data: any): SubuserItem => {
  return {
    userName: data?.username,
    firstName: data?.firstname,
    lastName: data?.lastname,
    phone: data?.phone,
    email: data?.email,
    door: data?.door,
  };
};

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////// MISC //////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

// usernames have shape {logonId}.{orgentityName}.{country}
// we remove the elements following the last two dots and keep the rest, as the logonId may contain dots
export const getLogonIdFromUsername = (username: string): string =>
  username.split(".").slice(0, -2).join(".");

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// PRIVILEGES ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const getPrivilegesTemplateOptions = (
  templatesList: PrivilegesTemplate[],
  isCreatingUser: boolean,
  translatingFunc: (key: string) => string
): CustomOptions[] => {
  const options: CustomOptions[] = templatesList.map((template) => ({
    label: translatingFunc(template.templateName + "_LABEL"),
    value: template.templateId,
  }));

  if (!isCreatingUser)
    options.push({
      label: translatingFunc(CUSTOM_PRIVILEGES_TEMPLATE_OPTION.label),
      value: CUSTOM_PRIVILEGES_TEMPLATE_OPTION.value,
    });

  return options;
};

// with fallback to first option if match is not found
const getSelectedTemplate = (
  selectedTemplateId: string | number | undefined,
  templateOptions: CustomOptions[]
): CustomOptions | undefined => {
  return templateOptions.find((templateOpt) => templateOpt.value === selectedTemplateId);
};

export const getSelectedPrivilegesTemplate = (
  privilegesTemplatesList: PrivilegesTemplate[],
  templateOptions: CustomOptions[],
  isCreatingUser: boolean,
  isLeoOnly: boolean,
  isUpgrade: boolean
): CustomOptions | null => {
  const preSelectedTemplate = privilegesTemplatesList.find((privilege) => privilege.selected);
  const preSelectedPrivileges =
    getSelectedTemplate(preSelectedTemplate?.templateId, templateOptions) || templateOptions[0];

  if (isLeoOnly) return getSelectedTemplate(4, templateOptions) || preSelectedPrivileges; // for LeoOnly users we get the Leonardo Template, or the preselected one
  if (isCreatingUser || isUpgrade) return preSelectedPrivileges; // for creating users, we get either the preselected template or the first one in the list

  return getSelectedTemplate("custom", templateOptions) || preSelectedPrivileges; // for all other cases we get the custom template
};

export const initializeSelectedPrivileges = (
  privilegesTemplatesList: PrivilegesTemplate[],
  privilegeGroups: PrivilegeGroup[],
  selectedTemplate: CustomOptions | null
): PrivilegeGroup[] => {
  if (selectedTemplate?.value === "custom") return privilegeGroups;

  const isPrivilegeActiveFromTemplate = (privilege: Privilege, template: PrivilegesTemplate) =>
    template.activePrivilegeIds.includes(privilege.userPrivilegeId);

  const currentTemplate = privilegesTemplatesList.find(
    (_) => _.templateId === selectedTemplate?.value
  );
  if (!currentTemplate) return [];

  const selectedPrivileges = cloneDeep(privilegeGroups);

  selectedPrivileges.forEach((group) =>
    // select all privileges relevant for current template
    group.privileges.forEach((_) => (_.active = isPrivilegeActiveFromTemplate(_, currentTemplate)))
  );

  return selectedPrivileges;
};

export const isSubgroupMutuallyExclusive = (subgroup: PrivilegeSubgroup | undefined): boolean =>
  subgroup?.privilegeSubGroupType === "ONLY_ONE";

export const getPrivilegeDiff = (
  selectedPrivileges: PrivilegeGroup[],
  privilegeGroups: PrivilegeGroup[] | null
): PrivilegeDiff => {
  const privilegeToEnable: number[] = [];
  const privilegeToDisable: number[] = [];

  selectedPrivileges?.forEach((activePrivGroup, groupI) => {
    activePrivGroup.privileges.forEach((activePrivilege, privI) => {
      const isPrivChanged =
        activePrivilege.active !== privilegeGroups?.[groupI].privileges[privI].active;

      if (isPrivChanged) {
        activePrivilege.active
          ? privilegeToEnable.push(activePrivilege.userPrivilegeId)
          : privilegeToDisable.push(activePrivilege.userPrivilegeId);
      }
    });
  });

  return {
    privilegeToEnable,
    privilegeToDisable,
  };
};

export const isPrivilegeDiffNotEmpty = (diff: PrivilegeDiff): boolean => {
  return diff.privilegeToDisable.length !== 0 || diff.privilegeToEnable.length !== 0;
};
