import React, { useContext, useEffect, useState } from "react";
import { Route, RouteComponentProps, withRouter } from "react-router-dom";
import { parseUrlInfo, replaceLocaleInUrl } from "../../routing/routesUtils";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  saveRedirectPublicLocale,
  selectCurrentLang,
  selectPublicSupportedLanguages,
  selectRedirectPublicLocale,
} from "../../store/store/storeSlice";
import { isSetAsLanguage, isSetLanguageSupported } from "../../utils/languageUtils";
import {
  selectIsLogged,
  selectLocale,
  selectRedirectUrlAfterLogin,
  selectStoreIdentifier,
} from "../../store/user/userSlice";
import { updatePublicLanguage } from "../../store/store/storeSagas";
import { areEqualString } from "../../utils/utils";
import LoadingBackground from "../styled-UI/LoadingBackground";
import { LocalStorageUtils } from "../../utils/storageUtils";
import { logout } from "../../store/user/userSagas";

interface Props extends RouteComponentProps<any> {
  path: string;
  exact: boolean;
  render: (props: any) => JSX.Element;
}

const GuardRoute = ({ render, history, path, exact }: Props) => {
  const [canAccess, setCanAccess] = useState<boolean>(false);
  const location = useLocation();
  const isLogged = useSelector(selectIsLogged);
  const publicSupportedLanguages = useSelector(selectPublicSupportedLanguages);
  const storeIdentifier = useSelector(selectStoreIdentifier);
  const currentLocale = useSelector(selectLocale);
  const currentLang = useSelector(selectCurrentLang);
  const dispatch = useDispatch();
  const redirectPublicLocale = useSelector(selectRedirectPublicLocale);
  const redirectUrlAfterLogin = useSelector(selectRedirectUrlAfterLogin);

  const checkIfLogin = path === "/:locale/login";

  //CHECK LANGUAGE THE FIRST TIME AND WHEN LOCATION CHANGES
  useEffect(() => {
    if (!isLogged && !checkIfLogin) {
      // console.log(
      //   `[DEBUG][SITESETTER][PUBLIC LANG] url changed: { ${location.pathname} } dispatching updatePublicLanguage`
      // );
      const site = parseUrlInfo();
      //THE URL SHOULD BE AT LEAST /locale/pageType TO CHECK IF IS VALID
      if (site.locale && site.pageType) dispatch(updatePublicLanguage());
    }
  }, [isLogged, location]);

  // LOGOUT IF WE ARE IN CHANGEPASSWORD ---OLD LOGIC
  // useEffect(() => {
  //   if (isLogged && path === "/:locale/change-password") {
  //     dispatch(logout());
  //   }
  // }, [path]);

  //REDIRECT BASED ON REDUX'S LOCALE (b/c of unsupported locale in the url)
  useEffect(() => {
    if (!isLogged) {
      const site = parseUrlInfo();

      if (
        redirectPublicLocale &&
        site.locale &&
        !areEqualString(redirectPublicLocale, site.locale)
      ) {
        history.push(replaceLocaleInUrl(redirectPublicLocale));
        dispatch(saveRedirectPublicLocale(null));
      }
    }
  }, [redirectPublicLocale, isLogged]);

  //CHECK IF LOCALE IS CORRECT AND SHOW PAGE
  useEffect(() => {
    if (!isLogged) {
      const site = parseUrlInfo();

      if (checkIfLogin) {
        if (!canAccess) setCanAccess(true);
        return;
      }

      if (
        currentLang &&
        site.locale &&
        publicSupportedLanguages.length > 0 &&
        isSetLanguageSupported(currentLang, publicSupportedLanguages) &&
        isSetAsLanguage(currentLang, site.locale)
      ) {
        if (!canAccess) setCanAccess(true);
      } else {
        if (canAccess) setCanAccess(false);
      }
    }
  }, [currentLang, publicSupportedLanguages, location]);

  //CHECK IF REDIRECT TO HOMEPAGE
  useEffect(() => {
    if (isLogged) {
      const site = parseUrlInfo();
      if (storeIdentifier && currentLocale) {
        const locale = site.locale ? site.locale : currentLocale;

        const urlToRedirect = redirectUrlAfterLogin ?? `/${storeIdentifier}/${locale}/homepage`;
        history.push(urlToRedirect);
      }
    }
  }, [isLogged, storeIdentifier, currentLocale, redirectUrlAfterLogin]);

  return (
    <>{canAccess ? <Route exact={exact} path={path} render={render} /> : <LoadingBackground />}</>
  );
};

export default withRouter(GuardRoute);
