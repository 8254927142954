import React from "react";
import ContentLoader from "react-content-loader";

const LoaderPrivilegeSection = (): JSX.Element => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={155}
    viewBox="0 0 100% 155"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="24" y="66" rx="0" ry="0" width="102" height="20" />
    <rect x="24" y="100" rx="0" ry="0" width="268" height="20" />
    <rect x="24" y="137" rx="0" ry="0" width="268" height="20" />
    <rect x="530" y="100" rx="0" ry="0" width="268" height="20" />
    <rect x="530" y="137" rx="0" ry="0" width="268" height="20" />
  </ContentLoader>
);

export default LoaderPrivilegeSection;
