import { AxiosResponse } from "axios";

import { createAndExecuteService } from "../serviceUtils";
import {
  PrepareMassiveInvoiceDownloadPayload,
  GetInvoicesPayload,
  UpdatePinPayload,
  ValidatePinPayload,
  NotificationCountByServicesIdsPayload,
} from "./accountingInterfaces";
import {
  creditDetailsUrl,
  creditSummaryUrl,
  getInvoicesUrl,
  downloadInvoiceUrl,
  resetPinUrl,
  updatePinUrl,
  validatePinUrl,
  optionsInvoiceUrl,
  prepareMassiveInvoiceDownloadUrl,
  massiveInvoiceDownloadUrl,
  sectionsAlertsUrl,
  notificationsAlertsUrl,
} from "./accountingUrls";

export default {
  /////////////////// pin management
  validatePin: async (payload: ValidatePinPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(validatePinUrl, "POST", null, payload);
  },
  updatePin: async (payload: UpdatePinPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(updatePinUrl, "PUT", null, payload);
  },
  resetPin: async (payload: string): Promise<AxiosResponse> => {
    return createAndExecuteService(resetPinUrl, "POST", null, payload);
  },

  /////////////////// invoices
  getInvoices: async (payload: GetInvoicesPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();
    Object.entries(payload.filters).forEach(([key, value]) => {
      if (key === "division" || key === "issuerCompany") {
        //Division and issuerCompany are passed as a concatenation of strings divided by ","
        value.split(",").forEach((_: string) => searchParams.append(key, _));
      } else {
        searchParams.append(key, value);
      }
    });

    searchParams.append("pageSize", String(payload.pageSize));

    return createAndExecuteService(getInvoicesUrl, "GET", searchParams);
  },

  downloadInvoice: async (payload: string): Promise<AxiosResponse> => {
    const url = downloadInvoiceUrl.replace("{invoiceIdentifier}", payload);
    return createAndExecuteService(url, "GET");
  },

  optionsInvoice: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(optionsInvoiceUrl, "GET");
  },

  prepareMassiveInvoiceDownload: async (
    payload: PrepareMassiveInvoiceDownloadPayload
  ): Promise<AxiosResponse> => {
    return createAndExecuteService(prepareMassiveInvoiceDownloadUrl, "POST", undefined, payload);
  },

  massiveInvoiceDownload: async (payload: string): Promise<AxiosResponse> => {
    const url = massiveInvoiceDownloadUrl.replace("{invoiceRequestId}", payload);
    return createAndExecuteService(url, "GET");
  },

  /////////////////// credit
  getCreditSummary: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(creditSummaryUrl, "GET");
  },
  getCreditDetails: async (id: string): Promise<AxiosResponse> => {
    const url = creditDetailsUrl.replace("{branch}", id);
    return createAndExecuteService(url, "GET");
  },

  /////////////////// notifications
  receiveSectionsAlerts: async (
    payload: NotificationCountByServicesIdsPayload | null
  ): Promise<AxiosResponse> => {
    return createAndExecuteService(sectionsAlertsUrl, "POST", null, payload);
  },

  getNotificationsAlerts: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(notificationsAlertsUrl, "GET");
  },
};
