import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import CustomText from "../../../styled-UI/CustomText";
import Popup from "../../../styled-UI/Popup";
import { MenuLinkButtonType } from "./NavbarMenu";

interface PopupProps {
  closePopup: () => void;
  popupTitle: string;
  popupContent: string;
}

const NavbarMenuErrorPopup = ({
  closePopup,
  popupTitle,
  popupContent,
}: PopupProps): JSX.Element | null => {
  const { translateLabel } = useTranslation();

  const mount = document.getElementById("error-popup-portal");
  const el = document.createElement("div");

  useEffect(() => {
    mount?.appendChild(el);
    return () => {
      mount?.removeChild(el);
    };
  }, [el, mount]);

  const errorPopup = (
    <Popup
      isOpen={true}
      title={translateLabel(popupTitle)}
      close={() => {
        closePopup();
      }}
    >
      <PopupContent>
        <CustomText as="span">{translateLabel(popupContent)}</CustomText>
      </PopupContent>
    </Popup>
  );

  return createPortal(errorPopup, el);
};

interface Props {
  popupTitle?: string;
  popupContent?: string;
  onDispatch: (type: MenuLinkButtonType, callback: () => void) => void;
  text: string;
  type: MenuLinkButtonType;
  "data-element-id"?: string;
}
const NavbarMenuLinkButton = ({
  onDispatch,
  popupTitle = "ERROR_POPUP_TITLE",
  popupContent = "HOMEPAGE_PAIRING_ERROR_GENERIC",
  text = "ESSILOR_PRO",
  type,
  "data-element-id": dataElementId,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState<boolean>(false);

  const handleOnClick = () => {
    onDispatch(type, () => setIsErrorPopupOpen(true));
  };

  return (
    <>
      <CustomText
        {...(dataElementId ? { "data-element-id": dataElementId } : {})}
        as="button"
        fontSizePx={13}
        color="primary"
        font="font-bold"
        uppercase
        onClick={handleOnClick}
      >
        {translateLabel(text)}
      </CustomText>
      {isErrorPopupOpen && (
        <NavbarMenuErrorPopup
          popupContent={popupContent}
          popupTitle={popupTitle}
          closePopup={() => setIsErrorPopupOpen(false)}
        />
      )}
    </>
  );
};

const PopupContent = styled.div`
  padding: 3rem 2rem;
`;

export default NavbarMenuLinkButton;
