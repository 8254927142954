import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { handleError, showErrorPopup } from "../store/storeSagas";
import {
  savePrivateUserPolicy,
  saveProfileAddresses,
  savePublicUserPolicy,
  setGetPublicUserPolicyStatus,
  setPrivateUserPolicyDispatched,
  setPrivateUserPolicyStatus,
  setPrivateUserPolicyUpdateStatus,
  setSalesRepList,
  setSalesRepListStatus,
  setSubmitPublicUserPolicyStatus,
  sliceName,
  startProfileLoading,
  stopProfileLoading,
} from "../profile/profileSlice";
import profileService from "./profileServices";

import {
  GenericPrivateUserPolicyPayload,
  GetProfileAddressesSagaPayload,
  getPublicUserPolicyPayload,
  privateUserPolicyPayload,
  submitPublicUserPolicyPayload,
} from "./profileInterfaces";
import { selectStoreIdentifier } from "../user/userSlice";
import { saveErrorStatus } from "../store/storeSlice";

/* ACTIONS */
export const getUserSalesRep = createAction<string>(`${sliceName}/getUserSalesRep`);
export const getProfileAddresses = createAction<GetProfileAddressesSagaPayload>(
  `${sliceName}/getProfileAddresses`
);
export const getPublicUserPolicy = createAction<getPublicUserPolicyPayload>(
  `${sliceName}/getPublicUserPolicy`
);
export const getPrivateUserPolicy = createAction(`${sliceName}/getPrivateUserPolicy`);
export const putPrivateUserPolicy = createAction<privateUserPolicyPayload>(
  `${sliceName}/putPrivateUserPolicy`
);
export const putPreferenceUserPolicy = createAction<GenericPrivateUserPolicyPayload[]>(
  `${sliceName}/putPreferenceUserPolicy`
);
export const submitPublicUserPolicy = createAction<submitPublicUserPolicyPayload>(
  `${sliceName}/submitPublicUserPolicy`
);

/* SAGAS */

/**
 * Get brands for user
 *
 * @return {*}  {SagaIterator}
 */
function* getUserSalesRepSaga({ payload }: PayloadAction<string>): SagaIterator {
  try {
    yield put(setSalesRepListStatus("LOADING"));
    const { data } = yield call(profileService.getUserSalesRep, payload);
    const brandObj = data.data;
    //sort object keys
    const ordered = Object.keys(brandObj)
      .sort()
      .reduce((obj: any, key) => {
        obj[key as any] = brandObj[key];
        return obj;
      }, {});

    yield put(setSalesRepList(ordered));
    yield put(setSalesRepListStatus("SUCCESS"));
  } catch (error) {
    yield put(setSalesRepListStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* getProfileAddressesSaga(
  action: PayloadAction<GetProfileAddressesSagaPayload>
): SagaIterator {
  try {
    yield put(startProfileLoading("addresses"));

    const storeIdentifier = yield select(selectStoreIdentifier);

    const { data } = yield call(profileService.getProfileAddresses, {
      storeIdentifier,
      doorIds: action.payload.doorIds,
    });
    yield put(saveProfileAddresses(data.data.addressList));
    yield put(stopProfileLoading("addresses"));
  } catch (error) {
    yield put(stopProfileLoading("addresses"));
    yield put(handleError(error));
  }
}

function* submitPublicUserPolicySaga({
  payload,
}: PayloadAction<submitPublicUserPolicyPayload>): SagaIterator {
  try {
    yield put(setSubmitPublicUserPolicyStatus("LOADING"));
    const { data } = yield call(profileService.submitPublicUserPolicy, payload);
    yield put(savePublicUserPolicy(data.data?.contextAttribute ? data.data : null));
    yield put(setSubmitPublicUserPolicyStatus("SUCCESS"));
  } catch (error) {
    yield put(setSubmitPublicUserPolicyStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* getPublicUserPolicySaga({
  payload,
}: PayloadAction<getPublicUserPolicyPayload>): SagaIterator {
  try {
    yield put(setGetPublicUserPolicyStatus("LOADING"));
    const { data } = yield call(profileService.getPublicUserPolicy, payload);
    yield put(savePublicUserPolicy(data.data?.contextAttribute ? data.data : null));
    yield put(setGetPublicUserPolicyStatus("SUCCESS"));
  } catch (error) {
    if (error?.response?.status === 403) {
      yield put(
        showErrorPopup({
          status: error?.response?.status,
          message: "EXPIRED_LINK_ERROR",
        })
      );
      yield put(saveErrorStatus({ status: error?.response?.status }));
    }
    yield put(setGetPublicUserPolicyStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* getPrivateUserPolicySaga(): SagaIterator {
  try {
    yield put(setPrivateUserPolicyStatus("LOADING"));
    const { data } = yield call(profileService.getPrivateUserPolicy);
    yield put(savePrivateUserPolicy(data.data));
    yield put(setPrivateUserPolicyStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(setPrivateUserPolicyStatus("ERROR"));
  }
}

function* putPrivateUserPolicySaga({
  payload,
}: PayloadAction<privateUserPolicyPayload>): SagaIterator {
  try {
    yield call(profileService.putPrivateUserPolicy, payload);
  } catch (error) {
    yield put(handleError(error));
  }
}

function* putPreferenceUserPolicySaga({
  payload,
}: PayloadAction<GenericPrivateUserPolicyPayload[]>): SagaIterator {
  try {
    yield put(setPrivateUserPolicyUpdateStatus("LOADING"));
    yield put(setPrivateUserPolicyDispatched(payload));
    yield call(profileService.putGenericPrivateUserPolicy, payload);
    yield put(setPrivateUserPolicyUpdateStatus("SUCCESS"));
  } catch (error) {
    yield put(setPrivateUserPolicyDispatched(null));
    yield put(handleError(error));
    yield put(setPrivateUserPolicyUpdateStatus("ERROR"));
  }
}

export function* profileSaga(): SagaIterator {
  yield takeLatest(getUserSalesRep.type, getUserSalesRepSaga);
  yield takeLatest(getProfileAddresses.type, getProfileAddressesSaga);
  yield takeLatest(getPrivateUserPolicy.type, getPrivateUserPolicySaga);
  yield takeLatest(putPreferenceUserPolicy.type, putPreferenceUserPolicySaga);
  yield takeLatest(putPrivateUserPolicy.type, putPrivateUserPolicySaga);
  yield takeLatest(submitPublicUserPolicy.type, submitPublicUserPolicySaga);
  yield takeLatest(getPublicUserPolicy.type, getPublicUserPolicySaga);
}
