import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as Unselected } from "../../assets/icons/radio-button-unselected.svg";
import { ReactComponent as Selected } from "../../assets/icons/radio-button-selected.svg";
import clsx from "clsx";
import { CustomOptions } from "./CustomSelect";

type RadioButtonProps = {
  label: string;
  disabled?: boolean;
  name: string;
  value: string | number;
  checked?: boolean;
  onChange?: (e: CustomOptions) => void;
  alignLabel?: "start" | "end" | "center";
  textAlign?: "left" | "center";
} & Omit<React.InputHTMLAttributes<HTMLLabelElement>, "onChange">;

const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    {
      label,
      disabled = false,
      name,
      value,
      checked,
      onChange,
      alignLabel = "center",
      textAlign = "center",
    },
    forwardedRef
  ) => {
    const classNameText = clsx(disabled && "radiobutton-disabled");

    return (
      <StyledLabel alignLabel={alignLabel} textAlign={textAlign}>
        <StyledInput
          onChange={(e) => {
            onChange?.({ value: e.target.value, label });
          }}
          checked={checked}
          type="radio"
          disabled={disabled}
          ref={forwardedRef}
          value={value}
          name={name}
        />
        <IconContainer className={clsx(disabled && "radiobutton-disabled", "radiobutton-selected")}>
          <Selected />
        </IconContainer>
        <IconContainer
          className={clsx(disabled && "radiobutton-disabled", "radiobutton-unselected")}
        >
          <Unselected />
        </IconContainer>
        <StyledText className={classNameText}>{label}</StyledText>
      </StyledLabel>
    );
  }
);

const StyledText = styled.span`
  color: ${(props) => props.theme.palette.primary};
  line-height: 1.38;
  font-family: ${(props) => props.theme.fonts.fontMedium};
  font-size: 0.8125rem;
  padding-left: 0.75rem;

  &.radiobutton-disabled {
    color: ${(props) => props.theme.palette.gray.medium};
  }
`;

const IconContainer = styled.div`
  height: 1.25rem;

  svg {
    width: 1.25rem;
    fill: ${(props) => props.theme.palette.gray.dark};
    &:hover {
      fill: ${(props) => props.theme.palette.primary};
    }
  }

  &.radiobutton-selected {
    display: none;
    svg {
      fill: ${(props) => props.theme.palette.primary};
    }
  }

  &.radiobutton-disabled {
    svg {
      fill: ${(props) => props.theme.palette.gray.light};
      &:hover {
        fill: ${(props) => props.theme.palette.gray.light};
      }
    }
    &.radiobutton-selected {
      svg {
        fill: ${(props) => props.theme.palette.gray.light};
      }
    }
  }
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label<{
  alignLabel: "start" | "end" | "center";
  textAlign: "left" | "center";
}>`
  display: flex;
  align-items: ${(props) => props.alignLabel};
  text-align: ${(props) => props.textAlign};

  input:checked ~ .radiobutton-selected {
    display: inline-block;
  }

  input:checked ~ .radiobutton-unselected {
    display: none;
  }
`;

RadioButton.displayName = "RadioButton";
export default RadioButton;
