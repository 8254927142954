import { AxiosResponse } from "axios";
import { createAndExecuteService } from "../serviceUtils";
import {
  downloadLegalDocumentsUrl,
  getCMSContentsUrl,
  getDocumentsUrl,
  getLegalDocumentsUrl,
  getPublicDocumentsUrl,
  getUnsignedLegalDocumentsUrl,
  saveUserActionUrl,
} from "./documentsUrls";
import {
  GetDocumentListParams,
  LegalDocumentsToDownloadPayload,
  SaveUserActionPayload,
} from "./documentsInterfaces";

export default {
  getDocumentList: async (payload: GetDocumentListParams): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    Object.entries(payload).forEach(([key, value]) => value && qparams.append(key, value));
    return createAndExecuteService(getDocumentsUrl, "GET", new URLSearchParams(qparams));
  },
  getPublicDocumentList: async (payload: GetDocumentListParams): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    Object.entries(payload).forEach(([key, value]) => value && qparams.append(key, value));
    return createAndExecuteService(getPublicDocumentsUrl, "GET", new URLSearchParams(qparams));
  },

  getLegalDocuments: async (doorId: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    qparams.append("doorId", doorId);
    return createAndExecuteService(getLegalDocumentsUrl, "GET", new URLSearchParams(qparams));
  },

  getUnsignedLegalDocuments: async (doorId: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    qparams.append("doorId", doorId);
    return createAndExecuteService(
      getUnsignedLegalDocumentsUrl,
      "GET",
      new URLSearchParams(qparams)
    );
  },

  saveUserAction: async (payload: SaveUserActionPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(saveUserActionUrl, "POST", undefined, payload);
  },

  downloadLegalDocuments: async (
    payload: LegalDocumentsToDownloadPayload
  ): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ doorId: payload.doorId });

    return createAndExecuteService(downloadLegalDocumentsUrl, "POST", qparams, {
      documentsToDownload: payload.documentsToDownload,
    });
  },
  getCMSContents: async (docName: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ docName });
    return createAndExecuteService(getCMSContentsUrl, "GET", qparams);
  },
};
