import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import clsx from "clsx";

import { useTrackView } from "../../../hooks/useTrackView";
import useTranslation from "../../../hooks/useTranslation";
import useStandaloneLabels from "../../../language/useStandaloneLabels";

import { selectCurrentLang, selectFallbackLang } from "../../../store/store/storeSlice";
import { selectIsLogged, selectLocale, selectStoreIdentifier } from "../../../store/user/userSlice";
import Button from "../../styled-UI/Button";
import CustomText from "../../styled-UI/CustomText";
import LoadingBackground from "../../styled-UI/LoadingBackground";

import logo from "../../../../src/assets/images/mylux.png";
import sky from "../../../../src/assets/images/sky.jpg";
import Logo from "../../layouts/Logo";

interface Props {
  hasHeaderFooter?: boolean;
}

const NotFound = ({ hasHeaderFooter = false }: Props): JSX.Element => {
  const { translateLabel, labels } = useTranslation();
  const [canSee404, setCanSee404] = useState<boolean>(false);
  const isLogged = useSelector(selectIsLogged);
  const history = useHistory();
  const storeIdentifier = useSelector(selectStoreIdentifier);
  const currentLocale = useSelector(selectLocale);
  const currentLang = useSelector(selectCurrentLang);
  const fallbackLang = useSelector(selectFallbackLang);

  const { translateStandaloneLabel, getStandaloneLabels } = useStandaloneLabels();

  useTrackView({ Page_Type: "Error", Page_Section1: "Other", Page_Section2: "ErrorHttp404" });

  useEffect(() => {
    if (location.pathname === "/") redirectToHome();
    else if (!canSee404) setCanSee404(true);
  }, [currentLocale, storeIdentifier, currentLang]);

  useEffect(() => {
    // workaround to get labels even when users directly land in a 404 page, and we don't have any information regarding the locale
    if (canSee404 && currentLocale) {
      getStandaloneLabels(currentLocale);
    }
  }, [canSee404, currentLocale]);

  const redirectToHome = () => {
    if (isLogged) {
      if (storeIdentifier && currentLocale)
        history.push(`/${storeIdentifier}/${currentLocale}/homepage`);
    } else {
      const lang = currentLang ? currentLang : fallbackLang;
      const loginUrl = "/" + lang + "/login";
      history.push(loginUrl);
    }
  };

  const getTranslation = (labelKey: string) => {
    return Object.values(labels)?.length > 0 // only use the workaround labels if no standard ones are found
      ? translateLabel(labelKey)
      : translateStandaloneLabel(labelKey);
  };

  return canSee404 ? (
    <Wrapper>
      <OuterBox className={clsx(hasHeaderFooter && "has-header-footer")}>
        <div>
          <Logo heightPx={42} isLink={false} maxWidthRem={18} />
        </div>
        <CustomText as="h1" color="primary" fontSizePx={60} font="font-bold">
          {getTranslation("PAGE_NOT_FOUND")}
        </CustomText>
        <CustomText as="p" color="primary" fontSizePx={24}>
          {getTranslation("PAGE_NOT_FOUND_DESCRIPTION")}
        </CustomText>
        <Button type="primary" onClick={redirectToHome}>
          {getTranslation("PAGE_NOT_FOUND_GO_TO_MAIN_PAGE")}
        </Button>
      </OuterBox>
    </Wrapper>
  ) : (
    <LoadingBackground />
  );
};

export default NotFound;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${sky});
  height: 100%;
`;

const OuterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 39.063rem;
  padding-top: 10.938rem;

  &.has-header-footer {
    padding: 7rem 0;
  }
`;
