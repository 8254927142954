import React from "react";
import styled from "styled-components/macro";
import { Product, Variant } from "../../interfaces/productInterface";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { TechnologyIcons } from "../../store/catalogue/catalogueInterface";
import { filterIconByAttribute } from "../../utils/AFAutils";
import { selectBrandGroupByBrandMap, selectTechIconContents } from "../../store/store/storeSlice";

interface Props {
  variant?: Variant | Product;
  type?: "PDP" | "vertical-tile" | "horizontal-tile";
  isAfaGogglesHelmetsVertical?: boolean;
  noMargins?: boolean;
}

const AFAIconRender = ({
  type,
  variant,
  isAfaGogglesHelmetsVertical,
  noMargins,
}: Props): JSX.Element => {
  const classNames = clsx(
    type === "vertical-tile" && "vertical-grid",
    type === "horizontal-tile" && "horizontal-grid",
    //variant?.tags && variant.tags.length > 0 && "vertical-margin",
    variant?.tags && variant?.tags.length === 0 && "hide-tags-margin",
    isAfaGogglesHelmetsVertical && "afa-goggles-helmets"
  );

  const techIcons = useSelector(selectTechIconContents);
  const brandGroupByBrandMap = useSelector(selectBrandGroupByBrandMap);

  const techIconsMapped =
    techIcons &&
    variant?.brand &&
    filterIconByAttribute(techIcons[brandGroupByBrandMap[variant.brand]], variant);

  const getAFAKeySellingPoint = (techIconsMapped: TechnologyIcons) => {
    const kspTechIcons = techIconsMapped["ksp-technology"] ?? [];
    const kspSustIcons = techIconsMapped["ksp-sustainability"] ?? [];

    if (type === "PDP") {
      return [...kspTechIcons, ...kspSustIcons];
    } else if (type === "vertical-tile" || type === "horizontal-tile") {
      if (kspSustIcons?.length === 0) return [...kspTechIcons.slice(0, 3)];
      else if (kspTechIcons.length === 0) return [...kspSustIcons.slice(0, 3)];
      else if (kspTechIcons.length > 0 && kspSustIcons?.length > 0) {
        if (kspTechIcons.length >= 2)
          return [...kspSustIcons.slice(0, 1), ...kspTechIcons.slice(0, 2)];
        else return [...kspSustIcons.slice(0, 2), ...kspTechIcons.slice(0, 1)];
      }
    }
  };

  const iconsToShow = techIconsMapped && getAFAKeySellingPoint(techIconsMapped);

  return (
    <>
      {iconsToShow &&
      iconsToShow?.length > 0 &&
      (variant?.productCategory === "afa" || variant?.productCategory === "helmet") ? (
        <Container className={classNames} noMargins={noMargins}>
          {iconsToShow?.map((_) => (
            <IconWrapper key={_?.id} isAfaGogglesHelmets={isAfaGogglesHelmetsVertical}>
              {!_?.data?.uri ? _?.name : <img src={_?.data.uri} alt={_?.name} />}
            </IconWrapper>
          ))}
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

const Container = styled.div<{ noMargins?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  row-gap: 1.625rem;
  margin: 1.875rem 0 1.625rem;
  align-items: center;
  &.vertical-grid,
  &.horizontal-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: none;
  }
  &.vertical-grid {
    grid-template-rows: 3rem;
    margin: 0.75rem 0 0;
    > :nth-child(3n-2) {
      justify-self: start;
    }

    &.hide-tags-margin {
      margin: 2.4044rem 0 0;
    }

    &.afa-goggles-helmets {
      margin: ${(props) => (props.noMargins ? "0" : "1.250rem 0 0")};
    }

    > :nth-child(3n-1) {
      justify-self: center;
    }

    > :nth-child(3n) {
      justify-self: end;
    }
    /* &.vertical-margin {
      margin: 0.75rem 0 0;
    } */
  }
  &.horizontal-grid {
    grid-template-rows: 4.1556rem;
    margin: 0 0 0 1rem;
    column-gap: 1rem;
  }
  &.afa-goggles-helmets {
    margin: ${(props) => (props.noMargins ? "0" : "1.250rem 0 0")};
    grid-template-rows: unset;
    display: grid;
    justify-content: space-between;
    row-gap: unset;
  }
`;

const IconWrapper = styled.div<{ isAfaGogglesHelmets?: boolean }>`
  max-width: 4rem;
  ${Container}.vertical-grid & {
    max-width: ${(prop) => (prop.isAfaGogglesHelmets ? `4.688rem` : `4rem`)};
    ${(prop) =>
      prop.isAfaGogglesHelmets &&
      `width:4.688rem; display: flex;
    justify-content: center`};

    max-height: 3rem;

    img {
      max-height: 3rem;
    }
  }
  ${Container}.horizontal-grid & {
    max-width: 4.5rem;

    img {
      max-height: 3rem;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
`;

export default AFAIconRender;
