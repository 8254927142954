import React from "react";
import styled, { keyframes } from "styled-components/macro";
import { ReactComponent as NotificationIcon } from "../../../../assets/icons/notification-outline.svg";
import useTranslation from "../../../../hooks/useTranslation";
import CustomText from "../../../styled-UI/CustomText";

interface Props {
  clickFeatures: (isClicked: boolean) => void;
  "data-element-id"?: string;
}

function TagNewFeatures({ clickFeatures, "data-element-id": dataElementId }: Props): JSX.Element {
  const { translateLabel } = useTranslation();

  return (
    <TagContainer>
      <ButtonIconContainer
        {...(dataElementId ? { "data-element-id": dataElementId } : {})}
        onClick={() => clickFeatures(true)}
      >
        <NotificationIcon />
        <TransitionText>
          <CustomText
            as="span"
            color="white"
            fontSizePx={14}
            font="font-regular"
            lineHeightPx={25.12}
            isEllipsis={true}
            marginLeftPx={33}
            fontWeight="bold"
          >
            {translateLabel("TUTORIAL_PILLS_POPUP_EXPLORE_FEATURES")}
          </CustomText>
        </TransitionText>
      </ButtonIconContainer>
    </TagContainer>
  );
}

const TransitionText = styled.div`
  transition: opacity 0.5s ease 1s;
  opacity: 0;
`;
const TagContainer = styled.div`
  position: absolute;
  top: 25.75rem;
  z-index: 9999;
`;

const rotateAnimation = keyframes`

0% {
  transform: rotate(0deg);
}

25% {
  transform:  rotate(+15deg);
}

50% {
  transform: rotate(0deg);
}

75% {
  transform: rotate(-15deg);
}

100% {
  transform: rotate(0deg);
}

`;

const ButtonIconContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  width: 2.063rem;
  height: 2.25rem;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.palette.green};
  cursor: pointer;
  overflow: hidden;
  svg {
    position: absolute;
    left: 0.469rem;
    height: 1.1rem;
    width: 1.063rem;
    animation: ${rotateAnimation} 1s linear infinite;
  }
  &:hover {
    transition: width 1s ease;
    width: calc(100% + 0.75rem);
    ${TransitionText} {
      opacity: 1;
    }
    &:after {
      ${TransitionText} {
        opacity: 0;
      }
    }
  }
`;

export default TagNewFeatures;
