import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../storeConfig";

import {
  DocumentsState,
  Document,
  LegalDocument,
  DocumentLinksObj,
  UnsignedLegalDocument,
  CMSDocumentsContent,
  SaveUserActionStatus,
} from "./documentsInterfaces";
import { orderBy } from "lodash";
import { RequestStatus } from "../../interfaces/mainInterfaces";

export const sliceName = "documents";
const initialState: DocumentsState = {
  documentsUrl: null,
  legalDocuments: null,
  termsConditionDocument: null,
  unsignedLegalDocuments: null,
  documentLinksObj: {},
  CMSContents: null,

  isDocumentsLoading: false,
  legalDocumentsLoading: false,
  isUnsignedLegalDocumentsLoading: "IDLE",
  documentsListsStatus: "IDLE",

  fitToDriveDocumentStatus: "IDLE",
  saveUserActionStatusEmail: "IDLE",
  saveUserActionStatusPostpone: "IDLE",
  saveUserActionStatusSign: "IDLE",

  unsignedPdfUrl: null,
};

export const documentsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveDocumentsList: (state, action: PayloadAction<Document[]>) => {
      state.documentsUrl = action.payload;
    },
    setLegalDocuments: (state, action: PayloadAction<LegalDocument[]>) => {
      if (action.payload) {
        state.legalDocuments = action.payload;
      }
    },
    saveTermsConditionDocument: (state, action: PayloadAction<Document>) => {
      state.termsConditionDocument = action.payload;
    },
    setUnsignedLegalDocuments: (state, { payload }: PayloadAction<UnsignedLegalDocument[]>) => {
      if (payload) {
        const orderedDocuments = orderBy(payload, (item) => item.docConf.popupPriorityLevel, "asc");
        state.unsignedLegalDocuments = orderedDocuments;
      }
    },
    setDocumentInObj: (state, { payload }: PayloadAction<{ document?: Document; key: string }>) => {
      if (payload.document !== undefined) {
        state.documentLinksObj[payload.key] = payload.document;
        return;
      }
      delete state.documentLinksObj[payload.key];
    },
    setLegalDocumentsLoading: (state, action: PayloadAction<boolean>) => {
      state.legalDocumentsLoading = action.payload;
    },
    setDocumentsLoading: (state, action: PayloadAction<boolean>) => {
      state.isDocumentsLoading = action.payload;
    },
    setDocumentsListsStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.documentsListsStatus = action.payload;
    },
    setFitToDriveDocumentStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.fitToDriveDocumentStatus = action.payload;
    },
    setIsUnsignedLegalDocumentsLoading: (state, action: PayloadAction<RequestStatus>) => {
      state.isUnsignedLegalDocumentsLoading = action.payload;
    },
    setCMSContents: (state, { payload }: PayloadAction<CMSDocumentsContent | null>) => {
      state.CMSContents = payload;
    },
    setSaveUserActionStatus: (state, { payload }: PayloadAction<SaveUserActionStatus>) => {
      if (payload.action === "MAIL") {
        state.saveUserActionStatusEmail = payload.status;
      }
      if (payload.action === "SIGN") {
        state.saveUserActionStatusSign = payload.status;
      }
      if (payload.action === "DISPOSE") {
        state.saveUserActionStatusPostpone = payload.status;
      }
    },
    saveUnsignedPdfUrl: (state, { payload }: PayloadAction<string | null>) => {
      state.unsignedPdfUrl = payload;
    },
    resetDocumentsState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  resetDocumentsState,
  saveDocumentsList,
  setLegalDocuments,
  saveTermsConditionDocument,
  setUnsignedLegalDocuments,
  setDocumentInObj,
  setLegalDocumentsLoading,
  setDocumentsLoading,
  setDocumentsListsStatus,
  setFitToDriveDocumentStatus,
  setIsUnsignedLegalDocumentsLoading,
  setCMSContents,
  setSaveUserActionStatus,
  saveUnsignedPdfUrl,
} = documentsSlice.actions;

export const selectDocumentList = (state: RootState): Document[] | null =>
  state.documents.documentsUrl;

export const selectLegalDocument = (state: RootState): LegalDocument[] | null =>
  state.documents.legalDocuments;

export const selectUnsignedLegalDocuments = (state: RootState): UnsignedLegalDocument[] | null =>
  state.documents.unsignedLegalDocuments;

export const selectTermsConditionDocument = (state: RootState): Document | null =>
  state.documents.termsConditionDocument;

export const selectDocumentLinksObj = (state: RootState): DocumentLinksObj | null =>
  state.documents.documentLinksObj;

export const selectCMSContents = (state: RootState): CMSDocumentsContent | null =>
  state.documents.CMSContents;

export const selectLegalDocumentsLoading = (state: RootState): boolean =>
  state.documents.legalDocumentsLoading;

export const selectdocumentsListsStatus = (state: RootState): RequestStatus =>
  state.documents.documentsListsStatus;

export const selectIsUnsignedLegalDocumentsLoading = (state: RootState): RequestStatus =>
  state.documents.isUnsignedLegalDocumentsLoading;

export const selectfitToDriveDocumentStatus = (state: RootState): RequestStatus =>
  state.documents.fitToDriveDocumentStatus;

export const selectSaveUserActionStatusEmail = (state: RootState): RequestStatus =>
  state.documents.saveUserActionStatusEmail;

export const selectSaveUserActionStatusPostpone = (state: RootState): RequestStatus =>
  state.documents.saveUserActionStatusPostpone;

export const selectSaveUserActionStatusSign = (state: RootState): RequestStatus =>
  state.documents.saveUserActionStatusSign;

export const selectUnsignedPdfUrl = (state: RootState): string | null =>
  state.documents.unsignedPdfUrl;

export default documentsSlice.reducer;
