import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useCanRender from "../../../hooks/useCanRender";
import useCMSAnalyticsTag from "../../../hooks/useCMSAnalyticsTag";
import { useTrackView } from "../../../hooks/useTrackView";

import { Page } from "../../../interfaces/gridInterfaces";
import { RequestStatus } from "../../../interfaces/mainInterfaces";
import { cancelBestSellers } from "../../../store/catalogue/catalogueSaga";
import { setBestSeller } from "../../../store/catalogue/catalogueSlice";
import { mapUrl } from "../../../store/store/storeSagas";
import { selectHomePageLayout, selectLoadingHp } from "../../../store/store/storeSlice";
import HomepageLayout from "../../cms-layouts/page-layouts/HomepageLayout";
import { MemoizedRowComponent } from "../../cms-layouts/RowComponent";
import { LoaderHomepage } from "../../styled-UI/LoaderHomepage";

const ROWS_TO_IGNORE_ANALYTICS = ["best-sellers", "university"];

const Homepage = (): JSX.Element => {
  const dispatch = useDispatch();
  const layout = useSelector(selectHomePageLayout);
  const hpLoading = useSelector(selectLoadingHp);
  const canRender = useCanRender();

  const { pageCreativities } = useCMSAnalyticsTag({
    layout,
    rowsToIgnore: ROWS_TO_IGNORE_ANALYTICS,
  });

  useTrackView({ Page_Type: "Home", Page_Section1: "Home", Page_Creativities: pageCreativities }, [
    pageCreativities,
  ]);

  useEffect(() => {
    dispatch(
      mapUrl({
        canRenderPrivileges: {
          LEONARDO_SECTION: canRender("LEONARDO_SECTION"),
          CATALOGUE: canRender("CATALOGUE"),
        },
      })
    );

    return () => {
      dispatch(cancelBestSellers());
      dispatch(setBestSeller({ type: "reset" }));
    };
  }, []);

  useEffect(() => {
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "PLP" */
      "../PLP/PLP"
    );

    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "PDP" */
      "../PDP/PDP"
    );
  }, []);

  return (
    <HomepageLayout>
      <Content layout={layout} hpLoading={hpLoading} />
    </HomepageLayout>
  );
};

const Content = ({
  layout,
  hpLoading,
}: {
  layout: Page;
  hpLoading: RequestStatus;
}): JSX.Element => {
  if (layout && layout.rows) {
    return (
      <>
        {layout?.rows
          ?.filter((row) => row.name !== "most-used" && row.name !== "university")
          ?.map((row) => (
            <MemoizedRowComponent row={row} key={row.name} />
          ))}
      </>
    );
  }

  if (hpLoading === "LOADING" || hpLoading === "IDLE") {
    return (
      <div>
        <LoaderHomepage />
      </div>
    );
  }

  return <></>;
};

export default Homepage;
