import React from "react";
import styled from "styled-components/macro";
import clsx from "clsx";

import CustomText from "../../components/styled-UI/CustomText";
import IconButton from "../../components/styled-UI/IconButton";

import { ReactComponent as CloseIcon } from "../../assets/icons/x-icon.svg";
import theme from "../../assets/theme/theme";

interface Props {
  isOpen: boolean;
  close: () => void;
  children: any;
  title?: string;
  isCentered?: boolean;
  isFullSize?: boolean;
  top?: number;
  zindex?: number;
  hideCloseButton?: boolean;
  disableCloseButton?: boolean;
  disableCloseOutsidePopup?: boolean;
  "data-element-id"?: string;
  "data-element-id-close"?: string;
  "data-description"?: string;
  enableOverflowAuto?: boolean;
  widthPx?: number;
  heightPx?: number;
  customPaddingContent?: string;
  customColorHeader?: string;
  customPaddingHeader?: string;
  backgroundOpacity?: string;
  backgroundColor?: string;
  backgroundContainerWithNoTitle?: string;
}

const Popup = ({
  close,
  title = "",
  isOpen,
  children,
  isCentered = true,
  isFullSize = false,
  top,
  zindex = 2000,
  hideCloseButton = false,
  disableCloseButton,
  disableCloseOutsidePopup,
  widthPx,
  heightPx,
  customPaddingContent,
  customColorHeader,
  customPaddingHeader,
  "data-element-id": dataElementId,
  "data-element-id-close": dataElementIdClose,
  "data-description": dataDescription,
  enableOverflowAuto = false,
  backgroundOpacity,
  backgroundColor,
  backgroundContainerWithNoTitle = theme.palette.gray.light,
}: Props): JSX.Element => {
  return (
    <>
      {isOpen && (
        <>
          <BackgroundLayer
            onClick={() => {
              if (disableCloseOutsidePopup) return;
              !disableCloseButton && close();
            }}
            zindex={zindex}
            opacity={backgroundOpacity}
            backgroundColor={backgroundColor}
          ></BackgroundLayer>
          <Container
            className={clsx(
              "popup",
              isCentered && "popup--centered",
              isFullSize && "full-size",
              enableOverflowAuto && "popup--overflow",
              widthPx && "custom-width",
              heightPx && "custom-height",
              customPaddingContent && "custom-padding-content"
            )}
            top={top}
            zindex={zindex}
            widthPx={widthPx}
            heightPx={heightPx}
            customPaddingContent={customPaddingContent}
            customColorHeader={customColorHeader}
            customPaddingHeader={customPaddingHeader}
            {...(dataElementId ? { "data-element-id": dataElementId } : {})}
            {...(dataDescription ? { "data-description": dataDescription } : {})}
            title={title}
            backgroundContainerWithNoTitle={backgroundContainerWithNoTitle}
          >
            {!(!title && hideCloseButton) && (
              <div className="popup-header">
                <CustomText as="h1" fontSizePx={24}>
                  {title}
                </CustomText>
                <div className="icon-container">
                  {!hideCloseButton && (
                    <IconButton
                      {...(dataElementIdClose ? { "data-element-id": dataElementIdClose } : {})}
                      size="md"
                      type="white-no-border"
                      icon={<CloseIcon />}
                      onClick={close}
                      disabled={disableCloseButton}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="popup-content-container">
              <div className="popup-content">{children}</div>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

interface BackgroundProps {
  zindex: number;
  opacity?: string;
  backgroundColor?: string;
}

const BackgroundLayer = styled.div<BackgroundProps>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? `${props.backgroundColor}` : `rgba(0, 0, 0, 0.7)`};
  z-index: ${(props) => props.zindex};
  padding-bottom: 2rem;

  ${(props) => props.opacity && `opacity: ${props.opacity}`};
`;

interface Container {
  top: number | undefined;
  zindex: number;
  widthPx?: number;
  heightPx?: number;
  customPaddingContent?: string;
  customPaddingHeader?: string;
  title?: string;
  backgroundContainerWithNoTitle?: string;
  customColorHeader?: string;
}

const Container = styled.div<Container>`
  position: fixed;
  min-width: 32.44rem;
  top: ${(props) => (props.top ? `${props.top / props.theme.baseFontSize}rem` : "6.25rem")};
  left: 50%;
  border-radius: 0.25rem;
  transform: translate(-50%, 0);
  background-color: ${(props) =>
    props.title ? props.theme.palette.white : props.backgroundContainerWithNoTitle};
  z-index: ${(props) => props.zindex};

  &.full-size {
    height: 100vh;
    width: 100vw;
  }

  &.popup--centered {
    transform: translate(-50%, -50%);
    top: 50%;
  }

  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) =>
      props.customColorHeader ? props.customColorHeader : props.theme.palette.gray.light};
    padding: 2rem 1.5rem 1.5rem 2rem;
    ${(props) => props.customPaddingHeader && `padding:${props.customPaddingHeader}`};
    border-radius: 0.25rem 0.25rem 0 0;
    min-height: 6rem;
    h1 {
      line-height: initial;
    }
  }

  .popup-content-container {
    max-width: calc(100vw - 1.25rem);
  }

  .popup-content {
    padding: 1rem;
    ${(props) => props.customPaddingContent && `padding:${props.customPaddingContent}`};
  }

  &.popup--overflow {
    max-height: calc(100vh - 6rem - 2rem);
    overflow: auto;
  }

  &.custom-height {
    ${(props) => props.heightPx && `height:${props.heightPx / props.theme.baseFontSize}rem`};
  }

  &.custom-width {
    ${(props) => props.widthPx && `width:${props.widthPx / props.theme.baseFontSize}rem`};
  }
`;

export default Popup;
