import { AxiosResponse } from "axios";
import { createAndExecuteService } from "../serviceUtils";
import {
  ApprovedRequestPayload,
  GetCoursesPayload,
  RejectRequestPayload,
} from "./leonardoInterface";
import {
  getSubusersUrl,
  postApproveRequest,
  postRejectAllRequest,
  postRejectRequest,
  getCoursesUrl,
  postApproveAllRequest,
  getPendingCount,
} from "./leonardoUrls";

const leonardoService = {
  getRequestSubuserApprovalList: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getSubusersUrl, "GET");
  },

  postApproveAllRequest: async (payload: ApprovedRequestPayload[]): Promise<AxiosResponse> => {
    return createAndExecuteService(postApproveAllRequest, "POST", null, payload);
  },

  postRejectAllRequest: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(postRejectAllRequest, "POST");
  },

  postApproveRequest: async (payload: ApprovedRequestPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(postApproveRequest, "POST", null, payload);
  },

  postRejectRequest: async (payload: RejectRequestPayload): Promise<AxiosResponse> => {
    const data = { requestId: payload.requestId };
    return createAndExecuteService(postRejectRequest, "POST", null, data);
  },

  getCountSubuserRequest: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getPendingCount, "GET");
  },

  getCourses: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getCoursesUrl, "GET");
  },
};

export default leonardoService;
