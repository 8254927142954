import React from "react";
import styled from "styled-components/macro";
import CustomText from "../styled-UI/CustomText";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { BulletPointList as Interface } from "../../interfaces/cmsInterfaces";
import { useHandleCmsLink } from "../../utils/cmsUtils";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  data: Interface;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}
const BulletPointList = ({ data, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  const { collectionTitle, pictures, externalLink, openInNewTab } = data;
  const { handleCmsLink } = useHandleCmsLink();

  const handleRedirect = (link: string): void => {
    handleCmsLink(link, externalLink, openInNewTab);
  };

  return (
    <Container>
      <OuterBoxTitle>
        {collectionTitle && (
          <CustomText
            as="h1"
            color={isDarkMode ? "white" : "primary"}
            fontSizePx={28}
            font={customFont ? "custom-regular" : "font-regular"}
            fontFamily={customFont}
          >
            {collectionTitle}
          </CustomText>
        )}
      </OuterBoxTitle>

      <OuterBox>
        <BoxText>
          {data?.result?.map((item) => {
            return (
              <InnerBoxText
                key={item.id}
                onClick={() => item?.linkString && handleRedirect(item?.linkString)}
              >
                {item.linkLabel && (
                  <>
                    <CustomText
                      as="span"
                      color={isDarkMode ? "white" : "primary"}
                      fontSizePx={18}
                      font={customFont ? "custom-bold" : "font-bold"}
                      fontFamily={customFont}
                      customColor={item.linkLabelColour}
                    >
                      {item.linkLabel}
                    </CustomText>
                    <ArrowContainer isDarkMode={isDarkMode}>
                      <Arrow />
                    </ArrowContainer>
                  </>
                )}
              </InnerBoxText>
            );
          })}
        </BoxText>
        <BoxImg src={pictures?.[0]?.data?.uri} />
      </OuterBox>
    </Container>
  );
};

const Container = styled.div`
  padding: 4rem;
`;

const OuterBoxTitle = styled.div`
  width: 100%;
  border-bottom: 2px solid ${(props) => props.theme.palette.gray.dark};
  padding-bottom: 2rem;
`;

const OuterBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  padding-left: 1.5rem;
  height: 22.5rem;
`;

const BoxText = styled.div`
  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ArrowContainer = styled.div<{ isDarkMode: boolean }>`
  svg path {
    fill: ${(props) => (props.isDarkMode ? "white" : "primary")} !important;
  }
`;

const InnerBoxText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  span {
    width: 75%;
  }
  svg {
    width: 1.125rem;
  }
`;

const BoxImg = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  width: 65%;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default BulletPointList;
