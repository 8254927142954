import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Slider, { LazyLoadTypes } from "react-slick";
import useDataHref from "../../../../hooks/useDataHref";
import CustomText from "../../../styled-UI/CustomText";
import CarouselNavBar from "../../../styled-UI/CarouselNavBar";
import SetInnerHTMLWithSanitize from "../../SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../../../store/store/storeInterfaces";

interface Props {
  children: any;
  slidesToShow?: number;
  slidesToScroll?: number;
  title?: string;
  titleColour?: string;
  description?: string;
  description1Colour?: string;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LandingPageCarousel = ({
  children,
  slidesToShow = 1,
  slidesToScroll = 1,
  title,
  titleColour,
  description,
  description1Colour,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [totalSlides, setTotalSlides] = useState<number>(children.length);

  const descrWithHref = useDataHref({ content: description });

  const currentPage = Math.ceil(currentSlide / slidesToScroll);
  const lastPage = Math.ceil((totalSlides - slidesToShow) / slidesToScroll);

  const defaultSettings = {
    dots: false,
    arrows: false,
    draggable: false, //to set it to true --> prevent click on drag https://github.com/akiran/react-slick/issues/848
    speed: 1000,
    infinite: false,
    onSwipe: () => {
      console.log("swipe");
    },
    lazyLoad: "ondemand" as LazyLoadTypes,
    slidesToShow: slidesToShow < totalSlides ? slidesToShow : totalSlides,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    beforeChange: (_: any, next: any) => setCurrentSlide(next),
  };

  const changeSlide = (val: number) => {
    sliderRef.slickGoTo(val);
  };

  useEffect(() => {
    if (sliderRef?.props?.children && Array.isArray(sliderRef.props.children))
      setTotalSlides(sliderRef.props.children.length);
  }, [sliderRef]);

  return (
    <SliderContainer>
      <Container>
        <DescriptionContainer>
          <TextSection>
            {title && (
              <CustomText
                as="p"
                font={customFont ? "custom-regular" : "font-regular"}
                fontFamily={customFont}
                fontSizePx={30}
                marginBottomPx={46}
                lineHeightPx={46}
                color={isDarkMode ? "white" : "primary"}
                customColor={titleColour}
              >
                {title}
              </CustomText>
            )}
            {description && (
              <CustomText
                as="div"
                font={customFont ? "custom-regular" : "font-regular"}
                fontFamily={customFont}
                fontSizePx={14}
                lineHeightPx={24}
                color={isDarkMode ? "white" : "primary"}
                customColor={description1Colour}
              >
                <SetInnerHTMLWithSanitize text={descrWithHref.updatedContent ?? description} />
              </CustomText>
            )}
          </TextSection>

          <NavigationSection>
            {totalSlides > 1 && totalSlides > slidesToShow ? (
              <CarouselNavBar
                currentSlide={currentSlide}
                slidesToScroll={slidesToScroll}
                changeSlide={changeSlide}
                currentPage={currentPage}
                lastPage={lastPage}
                navigationType="default"
              />
            ) : (
              <div></div>
            )}
          </NavigationSection>
        </DescriptionContainer>
        <ColumnRightContainer>
          {totalSlides >= slidesToShow ? (
            <Slider ref={(val) => setSliderRef(val)} {...defaultSettings}>
              {children}
            </Slider>
          ) : (
            <NoCarouselContainer>{children}</NoCarouselContainer>
          )}
        </ColumnRightContainer>
      </Container>
    </SliderContainer>
  );
};
const SliderContainer = styled.div`
  position: relative;
`;

const Container = styled.div`
  display: flex;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 380px;
  flex-shrink: 0;
  padding-right: 3.5rem;
`;

const ColumnRightContainer = styled.div`
  width: calc(100% - 380px);
  flex-shrink: 0;
`;

const TextSection = styled.div`
  @media ${(props) => props.theme.queries.md} {
    max-width: 100%;
  }
`;

const NavigationSection = styled.div`
  justify-content: flex-start;
  padding-top: 1rem;

  @media ${(props) => props.theme.queries.md} {
    padding-right: 1rem;
  }
`;

const NoCarouselContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export default LandingPageCarousel;
