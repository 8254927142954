import {
  ConversationalModelResult,
  UpcResult,
  ConversationalVariantResult,
  ConversationalModelOptions,
  SparePartsVariant,
  SparePartsSku,
  SparePartsFamilies,
  SparePartsGroupedFamilies,
  WarrantyInboxDetails,
  WarrantyAction,
  DetailsData,
  DetailsInfoValue,
} from "../store/aftersales/aftersalesInterface";
import { CustomOptions } from "../components/styled-UI/CustomSelect";
import { getAttributeValues } from "./productUtils";
import { Variant } from "../interfaces/productInterface";
import { capitalizeFirstLetter } from "./utils";
import { orderBy } from "lodash";

/**
 * Custom type guard, check if object implements Product interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(Product | Variant)} object
 * @return {*}  {object is Product}
 */
export function instanceOfSparePartsSku(object: SparePartsSku | any): object is SparePartsSku {
  return !!object.sparePartsCode;
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// CONVERSATIONAL //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

////////////////////////////// SERVICES PAYLOADS /////////////////////////////////

/**
 * Map list of available brands for conversational
 *
 * @param {any[]} brandArray
 * @return {*}  {CustomOptions[]}
 */
export const mapConversationalBrandsResult = (brandArray: any[]): CustomOptions[] => {
  const brandOptions = brandArray.map((brand) => {
    return { label: brand.label, value: brand.identifier };
  });

  return brandOptions.sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * Map conversational results from UPC search
 *
 * @param {*} upc
 * @return {*}  {UpcResult}
 */
export const mapConversationalUpcResult = (upc: any, entryDataLength: number): UpcResult => {
  return {
    catalogEntryTypeCode: upc?.catalogEntryTypeCode,
    hasSingleSKU: upc?.hasSingleSKU,
    relationshipProductId: upc?.relationshipProductId,
    relationshipVariantId: upc?.relationshipVariantId,
    uniqueID: upc?.uniqueID,
    brandGroup: upc?.brandGroup,
    entryDataLength,
    highlightField: upc?.highlightField?.[0],
  };
};

/**
 * Map list of models from selected brand for aftersales conversational
 *
 * @param {any[]} modelArray
 * @return {*}  {ConversationalModelResult[]}
 */
export const mapConversationalModelResult = (modelArray: any[]): ConversationalModelResult[] => {
  return modelArray.map((_: any) => {
    return {
      hasSingleSKU: _?.hasSingleSKU,
      uniqueID: _?.uniqueID,
      partNumber: _?.partNumber,
      name: _?.name,
    };
  });
};

/**
 * Map list of variants from selected model for aftersales conversational
 *
 * @param {any[]} variantArray
 * @return {*}  {ConversationalVariantResult[]}
 */
export const mapConversationalVariantResult = (
  variantArray: any[]
): ConversationalVariantResult[] => {
  return variantArray.map((_: any) => {
    return {
      hasSingleSKU: _?.hasSingleSKU,
      uniqueID: _?.uniqueID,
      partNumber: _?.partNumber,
      colorCode: getAttributeValues(_?.attributes, "DL_COLOR_CODE")?.values ?? "",
      colorName: getAttributeValues(_?.attributes, "FRONT_COLOR_DESCRIPTION")?.values ?? "",
    };
  });
};

////////////////////////////// MISC /////////////////////////////////

/**
 * Map list of models displayed in dropdown for aftersales conversational
 *
 * @param {ConversationalModelResult[]} modelArray
 * @return {*}  {CustomOptions[]}
 */
export const mapConversationalModelOptions = (
  modelArray: ConversationalModelResult[]
): ConversationalModelOptions[] => {
  return modelArray.map((_: ConversationalModelResult) => {
    return {
      value: _?.partNumber,
      label: (_?.partNumber ?? "") + (_?.name ? " - " + capitalizeFirstLetter(_?.name) : ""),
      uniqueID: _?.uniqueID,
    };
  });
};

/**
 * Map list of variants displayed in dropdown for aftersales conversational
 *
 * @param {ConversationalVariantResult[]} variantArray
 * @return {*}  {CustomOptions[]}
 */
export const mapConversationalVariantOptions = (
  variantArray: ConversationalVariantResult[]
): CustomOptions[] => {
  return variantArray.map((_: ConversationalVariantResult) => {
    return {
      value: _?.uniqueID,
      label: (_?.colorCode ?? "") + " - " + (_?.colorName ?? ""),
    };
  });
};

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// SPARE-PARTS PDP //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

////////////////////////////// SERVICES PAYLOADS /////////////////////////////////

/**
 * Map list of spareparts families from service into SparePartsFamilies[] array
 *
 * @param {any[]} families
 * @return {*}  {SparePartsFamilies[]}
 */
export const mapSparePartsFamilies = (families: any[]): SparePartsFamilies[] => {
  return families.map(
    (_): SparePartsFamilies => {
      return {
        label: capitalizeFirstLetter(_.description),
        idFamily: _.idFamily,
        idParent: _.idParent,
        uom: _.uom,
        ordnum: _.ordnum,
      };
    }
  );
};

/**
 *
 *
 * @param {SparePartsFamilies[]} families
 * @param {{ key: string; value: string }[]} groupings
 * @return {*}  {SparePartsGroupedFamilies[]}
 */
export const mapSparePartsGroupedFamilies = (
  families: SparePartsFamilies[]
): SparePartsGroupedFamilies[] => {
  const groupedFamilies: SparePartsGroupedFamilies[] = [];

  families.forEach((family) => {
    let currentGroup: SparePartsGroupedFamilies = groupedFamilies.filter(
      (_) => _.idParent === family.idParent
    )[0];

    if (currentGroup) currentGroup.families.push(family);
    else {
      currentGroup = {
        ordnum: family.ordnum,
        icon: "",
        idParent: family.idParent,
        families: [family],
      };
      groupedFamilies.push(currentGroup);
    }
  });
  const orderedGroupedFamilies = orderBy(groupedFamilies, "ordnum", "asc");

  return orderedGroupedFamilies;
};

/**
 * Map list spareparts items returned from service by grouping them based on their
 * color (colorCode = griDim1), and creating an array of SparePartsVariant(s)
 * containing arrays of SparePartsSku(s)
 *
 * @param {*} itemResponse
 * @return {*}  {SparePartsVariant[]}
 */
export const mapSparePartsItems = (
  itemResponse: any,
  fallbackVariant: Variant,
  frameVariants?: Variant[]
): SparePartsVariant[] => {
  return itemResponse?.skuDetailList.reduce((variants: SparePartsVariant[], item: any) => {
    const variant: SparePartsVariant = variants.filter((_) => _?.colorCode === item?.gridDim1)?.[0];
    const frameVariant = frameVariants?.filter((_) => _.colorCode?.values === item?.gridDim1)[0];
    const newSku: SparePartsSku = {
      uniqueID: "-1",
      partNumber: itemResponse.product + item?.gridValue,
      sparePartsCode: itemResponse.product,
      colorCode: { name: item?.gridDim1, values: item?.gridDim1 },
      gridValue: item?.gridValue,
      gridDim2: item?.gridDim2,
      size: {
        identifier: "DL_SIZE_CODE",
        values: [
          {
            identifier: item?.asta || item?.gridDim2,
            value: item?.asta || item?.gridDim2,
            uniqueID: item?.asta || item?.gridDim2,
          },
        ],
      },
      asta: item?.asta,
      availQty: item?.availQty,
      notOrderFlag: item?.notOrderFlag?.toLowerCase() === "true",
      uom: itemResponse?.uom as "NR" | "PAI",
      sparePartsDescription: itemResponse.description,
      idFamily: itemResponse.idFamily,
      component: item?.component,
      SPprice: Number(item?.unitPrice),
      SPparentSkuCatentry: frameVariant?.uniqueID ?? fallbackVariant.uniqueID,
      SPproductCategory: "SPARE_PARTS",
      currency: item?.currency,
    };

    if (variant) {
      variant.skus.push(newSku);
      return variants;
    } else {
      const newVariant: SparePartsVariant = {
        uom: itemResponse?.uom as "NR" | "PAI",
        colorCode: item?.gridDim1,
        SPprice: Number(item?.unitPrice),
        skus: [newSku],
        frontColorDescription: frameVariant?.frontColorDescription,
        attributes: frameVariant?.attributes,
        colorLabel:
          item?.gridDim1 +
          (frameVariant?.frontColorDescription?.values
            ? " - " + capitalizeFirstLetter(frameVariant?.frontColorDescription?.values)
            : ""),
        img: frameVariant?.img,
        idFamily: itemResponse.idFamily,
        lensProperties: frameVariant?.lensProperties,
        lensColor: frameVariant?.lensColor,
        uniqueId: frameVariant?.uniqueID,
        name: frameVariant?.name,
        brand: frameVariant?.brand,
      };

      variants.push(newVariant);
      return variants;
    }
  }, []);
};

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// WARRANTY INBOX //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

////////////////////////////// SERVICES PAYLOADS /////////////////////////////////

export const mapWarrantyInboxDetails = (data: any): WarrantyInboxDetails => {
  return {
    warrantyTitleLabelKey: data?.warrantyTitleLabelKey,
    warrantyIdentifier: data?.warrantyIdentifier,
    responseTitleLabelKey: data?.responseTitleLabelKey,
    responseValueLabelKey: data?.responseValueLabelKey,
    substitutionTitleLabelKey: data?.substitutionTitleLabelKey,
    substitutionValue: data?.substitutionValue,
    statusSectionTitleLabelKey: data?.statusSectionTitleLabelKey,
    statusValueLabelKey: data?.statusValueLabelKey,
    statusDescriptionLabelKey: data?.statusDescriptionLabelKey,
    statusIdentifier: data?.statusIdentifier,
    isPending: data?.isPending,
    statusSectionActions: data?.statusSectionActions?.map(
      (_: any): WarrantyAction => {
        return {
          actionId: _?.actionId,
          actionTitleLabelKey: _?.actionTitleLabelKey,
          fileUrl: _?.fileUrl,
          active: _?.active,
        };
      }
    ),
    detailsTitleLabelKey: data?.detailsTitleLabelKey,
    detailsData: data?.detailsData?.map(
      (_: any): DetailsData => {
        return {
          detailsInfoNameLabelKey: _?.detailsInfoNameLabelKey,
          detailsInfoValue: _?.detailsInfoValue?.map(
            (__: any): DetailsInfoValue => {
              return {
                valueLabelKey: __?.valueLabelKey,
                value: __?.value,
                fileUrl: __?.fileUrl,
              };
            }
          ),
        };
      }
    ),
    productTitleLabelKey: data?.productTitleLabelKey,
    alternativeProductTitleLabelKey: data?.alternativeProductTitleLabelKey,
    alternativeStatusTitleLabelKey: data?.alternativeStatusTitleLabelKey,
    alternativeStatusValueLabelKey: data?.alternativeStatusValueLabelKey,
    productItemCode: data?.productItemCode,
    alternativeProductItemCode: data?.alternativeProductItemCode,
    showPrivacyPolicy: data?.showPrivacyPolicy,
    ratingLevel: data?.ratingLevel,
  };
};
