import { selectUserName } from "./../user/userSlice";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FacetViewEntry } from "../../interfaces/facetInterfaces";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import { RootState } from "../storeConfig";
import { Door } from "../user/userInterfaces";
import { selectSelectedDoor, selectStoreIdentifier, selectIsMultidoor } from "../user/userSlice";
import {
  DigitalWindowProductsResponse,
  DownloadDocumentStatus,
  ItemMasterDataResponse,
  PlaylistWithProductResponse,
  SendItemMasterDataResponse,
  ServicesState,
  SubmitBrandList,
  TransportDocumentsList,
} from "./servicesInterface";

export const sliceName = "services";

const initialState: ServicesState = {
  brands: null,
  submitBrandList: null,
  getBrandsStatus: "IDLE",
  submitBrandsStatus: "IDLE",

  //transport documents
  transportDocumentsList: null,
  getTransportDocumentsStatus: "IDLE",
  downloadDocumentStatus: null,

  //item master data
  itemMasterData: null,
  getItemMasterDataStatus: "IDLE",
  sendItemMasterDataResponse: null,
  postItemMasterDataStatus: "IDLE",

  // price list
  articlePriceInformationStatus: "IDLE",
  //Digital-Screen
  videoPlaylist: null,
  digitalWindowProducts: null,
  getVideoPlaylistStatus: "IDLE",
  getDigitalWindowProductsStatus: "IDLE",
  //Digital Discounts Voucher
  digitalDiscountsVoucherLink: null,
};

export const orderHistorySlice = createSlice({
  name: sliceName,
  initialState,

  reducers: {
    saveGetBrandsStatusPos: (state, action: PayloadAction<RequestStatus>) => {
      state.getBrandsStatus = action.payload;
    },
    saveSubmitBrandsStatusPos: (state, action: PayloadAction<RequestStatus>) => {
      state.submitBrandsStatus = action.payload;
    },
    saveBrandsPos: (state, action: PayloadAction<FacetViewEntry[] | null>) => {
      state.brands = action.payload;
    },
    setSubmitBrandListServices: (state, action: PayloadAction<SubmitBrandList | null>) => {
      state.submitBrandList = action.payload;
    },
    resetPosSourceFile: (state) => {
      state.brands = null;
      state.submitBrandList = null;
      state.getBrandsStatus = "IDLE";
      state.submitBrandsStatus = "IDLE";
    },
    //transport documents
    setTransportDocumentsServices: (
      state,
      action: PayloadAction<TransportDocumentsList[] | null>
    ) => {
      state.transportDocumentsList = action.payload;
    },
    saveGetTransportDocumentsStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getTransportDocumentsStatus = action.payload;
    },
    saveDownloadDocumentStatus: (state, action: PayloadAction<DownloadDocumentStatus | null>) => {
      state.downloadDocumentStatus = action.payload;
    },
    saveItemMasterData: (state, action: PayloadAction<ItemMasterDataResponse | null>) => {
      state.itemMasterData = action.payload;
    },
    setGetItemMasterDataStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getItemMasterDataStatus = action.payload;
    },
    setSendItemMasterDataResponse: (
      state,
      action: PayloadAction<SendItemMasterDataResponse | null>
    ) => {
      state.sendItemMasterDataResponse = action.payload;
    },

    setPostItemMasterDataStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.postItemMasterDataStatus = action.payload;
    },

    setArticlePriceInformationStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.articlePriceInformationStatus = action.payload;
    },
    resetTransportDocuments: (state) => {
      state.transportDocumentsList = null;
      state.getTransportDocumentsStatus = "IDLE";
    },

    //Digital-screen
    saveVideoPlaylist: (state, action: PayloadAction<PlaylistWithProductResponse[] | null>) => {
      state.videoPlaylist = action.payload;
    },
    saveDigitalWindowProducts: (
      state,
      action: PayloadAction<DigitalWindowProductsResponse | null>
    ) => {
      state.digitalWindowProducts = action.payload;
    },
    saveVideoPlaylistStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getVideoPlaylistStatus = action.payload;
    },
    saveDigitalWindowProductsStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getDigitalWindowProductsStatus = action.payload;
    },
    saveDigitalDiscountsVoucherLink: (state, action: PayloadAction<string>) => {
      state.digitalDiscountsVoucherLink = action.payload;
    },
    resetVideoPlaylist: (state) => {
      state.videoPlaylist = null;
      state.getVideoPlaylistStatus = "IDLE";
    },
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  saveGetBrandsStatusPos,
  saveSubmitBrandsStatusPos,
  saveBrandsPos,
  setSubmitBrandListServices,
  setTransportDocumentsServices,
  saveGetTransportDocumentsStatus,
  resetTransportDocuments,
  resetPosSourceFile,
  saveDownloadDocumentStatus,
  saveItemMasterData,
  setGetItemMasterDataStatus,
  setSendItemMasterDataResponse,
  setArticlePriceInformationStatus,
  setPostItemMasterDataStatus,
  //Digital-screen
  saveVideoPlaylist,
  saveDigitalWindowProducts,
  saveVideoPlaylistStatus,
  saveDigitalWindowProductsStatus,
  saveDigitalDiscountsVoucherLink,
  resetVideoPlaylist,
} = orderHistorySlice.actions;

export const selectBrandPos = (state: RootState): FacetViewEntry[] | null => {
  return state.services.brands;
};

export const selectGetBrandPosStatus = (state: RootState): RequestStatus => {
  return state.services.getBrandsStatus;
};

export const selectSubmitBrandPosStatus = (state: RootState): RequestStatus => {
  return state.services.submitBrandsStatus;
};

export const selectSubmitBrandList = (state: RootState): SubmitBrandList | null => {
  return state.services.submitBrandList;
};

export const selectTransportDocumentsList = (state: RootState): TransportDocumentsList[] | null => {
  return state.services.transportDocumentsList;
};

export const selectGetTransportDocumentsStatus = (state: RootState): RequestStatus => {
  return state.services.getTransportDocumentsStatus;
};

export const selectItemMasterData = (state: RootState): ItemMasterDataResponse | null => {
  return state.services.itemMasterData;
};

export const selectGetItemMasterDataStatus = (state: RootState): RequestStatus => {
  return state.services.getItemMasterDataStatus;
};

export const selectSendItemMasterDataResponse = (
  state: RootState
): SendItemMasterDataResponse | null => {
  return state.services.sendItemMasterDataResponse;
};

export const selectArticlePriceInformationStatus = (state: RootState): RequestStatus => {
  return state.services.articlePriceInformationStatus;
};

export const selectPostItemMasterDataStatus = (state: RootState): RequestStatus => {
  return state.services.postItemMasterDataStatus;
};

/*
This version of the selectItemMasterDataUsername was replaced with a simpler one because now subusers can have multidoor
export const selectItemMasterDataUsername = createSelector(
  selectSelectedDoor,
  selectStoreIdentifier,
  selectIsMultidoor,
  selectUserName,
  (
    selectedDoor: Door | null,
    storeId: string,
    isMultidoor: boolean,
    userName: string | null
  ): string => {
    const country = storeId.split("-")?.pop();
    const multidoorUsername = `user.${selectedDoor?.orgentityName}.${country}`;
    const currentDoorUsername = userName ?? multidoorUsername; // fallback just in case we don't have the username in activeDoor
    return isMultidoor ? multidoorUsername : currentDoorUsername;
  }
);*/

export const selectItemMasterDataUsername = (state: RootState): string | null => {
  return state.user.username;
};

//Digital-screen
export const selectVideoPlaylist = (state: RootState): PlaylistWithProductResponse[] | null => {
  return state.services.videoPlaylist;
};

export const selectDigitalWindowProducts = (
  state: RootState
): DigitalWindowProductsResponse | null => {
  return state.services.digitalWindowProducts;
};

export const selectVideoPlaylistStatus = (state: RootState): RequestStatus => {
  return state.services.getVideoPlaylistStatus;
};

export const selectDigitalWindowProductsStatus = (state: RootState): RequestStatus => {
  return state.services.getDigitalWindowProductsStatus;
};

export const selectDigitalDiscountsVoucherLink = (state: RootState): string | null => {
  return state.services.digitalDiscountsVoucherLink;
};

export default orderHistorySlice.reducer;
