import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import clsx from "clsx";

import useTranslation from "../../../hooks/useTranslation";
import { ItemPrice, Sku } from "../../../interfaces/productInterface";
import { PreCartProduct } from "../../../store/catalogue/catalogueInterface";
import { savePreCart, selectPreCart } from "../../../store/catalogue/catalogueSlice";
import { Door } from "../../../store/user/userInterfaces";
import { instanceOfSparePartsSku } from "../../../utils/aftersalesUtils";
import CustomScrollbar from "../../styled-UI/CustomScrollbar";
import CustomText from "../../styled-UI/CustomText";
import AddSize from "./AddSize";

interface Props {
  skus: Sku[];
  isLoading?: boolean;
  price?: ItemPrice;
  currentDoor: Door | null;
  isMultidoor?: boolean;
  compactMultidoorSelect?: boolean;
  isAfa?: boolean;
  starsSkus?: string[];
  isSpareParts?: boolean;

  // analytics
  "data-element-id"?: string;
  enableDataDescription?: boolean;
  dataElementIdRXPLP?: string;
  dataElementIdFromMainBox?: string;
  code?: string;
  showFamilySizeFrames?: boolean;
  hideAvailabilitySize?: boolean;
}

const AddSizeList = ({
  skus,
  isLoading,
  price,
  currentDoor,
  isMultidoor,
  compactMultidoorSelect,
  isAfa,
  starsSkus,
  hideAvailabilitySize,
  isSpareParts,
  // analytics
  "data-element-id": dataElementId,
  enableDataDescription,
  dataElementIdRXPLP,
  dataElementIdFromMainBox,
  code,
  showFamilySizeFrames,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();

  const preCart = useSelector(selectPreCart);

  const saveItemInPreCart = (obj: PreCartProduct) => dispatch(savePreCart(obj));

  const sizesNumber = skus.length;
  const upc = skus.map((_) => _.upc).join(",");

  if (sizesNumber > 0)
    return (
      <ScrollWrapper showScroll={sizesNumber > 6}>
        <CustomScrollbar active={sizesNumber > 6} offsetRight>
          {skus?.map((sku) => {
            if (sku.size?.values?.[0]) {
              const currentInfo = preCart?.find(
                (element) =>
                  element.partNumber === sku.partNumber &&
                  element.multidoorId === currentDoor?.orgentityId
              );

              return (
                <AddSizeContainer
                  key={sku.partNumber + currentDoor?.orgentityId}
                  className={clsx(
                    isMultidoor && !compactMultidoorSelect && "add-size-container-multidoor"
                  )}
                >
                  <AddSize
                    hideAvailabilitySize={hideAvailabilitySize}
                    isSpareParts={isSpareParts}
                    sku={sku}
                    onClick={saveItemInPreCart}
                    quantity={currentInfo?.quantity ?? 0}
                    customerReference={currentInfo?.customerReference}
                    {...(instanceOfSparePartsSku(sku)
                      ? sku.notOrderFlag
                        ? { availabilityStatus: "NOT_AVAILABLE" }
                        : sku.availQty == "0"
                        ? { availabilityStatus: "BACKORDER" }
                        : { availabilityStatus: "AVAILABLE" }
                      : {})} // forced to not available (notOrderFlag = true) or available (notOrderFlag = false) for spareparts
                    isEmpty={preCart?.length == 0}
                    price={price}
                    door={currentDoor ?? undefined}
                    isLoading={isLoading}
                    dataElementIdMainBox={dataElementIdFromMainBox}
                    dataElementIdRXPLP={dataElementIdRXPLP}
                    isAfa={isAfa}
                    isStarsNew={!!starsSkus?.find((_) => _ === sku.uniqueID)}
                    {...(dataElementId ? { "data-element-id": dataElementId } : {})}
                    {...(enableDataDescription ? { "data-description": `${code}_${upc} ` } : {})}
                    showFamilySizeFrames={showFamilySizeFrames}
                  />
                </AddSizeContainer>
              );
            }
          })}
        </CustomScrollbar>
      </ScrollWrapper>
    );
  else
    return (
      <FrameNotAvailable>
        <CustomText as="span" fontSizePx={13} font="font-bold" color="primary">
          {translateLabel("DIGITAL_SCREEN_FRAME_NOT_AVAILABLE")}
        </CustomText>
        <CustomText as="span" fontSizePx={13} font="font-bold" color="primary">
          {translateLabel("DIGITAL_SCREEN_CONTACT_YOUR_SALES_AGENT")}
        </CustomText>
      </FrameNotAvailable>
    );
};

const ScrollWrapper = styled.div<{ showScroll: boolean }>`
  height: ${(props) => (props.showScroll ? "18rem" : "unset")}; //18rem
`;

const AddSizeContainer = styled.div`
  &.add-size-container-multidoor {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;

const FrameNotAvailable = styled.div`
  display: flex;
  flex-direction: column;
`;

export default AddSizeList;
