import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useTranslation from "../../../hooks/useTranslation";

import { clearPreCart, selectTotalInPreCart } from "../../../store/catalogue/catalogueSlice";
import { addItemsToPrecart } from "../../../store/cart/cartSagas";
import RouteLeavingGuard from "./RouteLeavingGuard";
import { trackEvent } from "../../../store/analytics/analyticsSaga";

interface Props {
  allowedPath?: string;
}

export const RouteLeavingGuardPrecart = ({ allowedPath }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const total = useSelector(selectTotalInPreCart);
  const { translateLabel } = useTranslation();

  return (
    <RouteLeavingGuard
      when={true}
      navigate={(path) => history.push(path)}
      shouldBlockNavigation={(location) => {
        if (total == 0) return false;
        if (allowedPath && location.pathname.includes(allowedPath)) return false;
        return true;
      }}
      title={translateLabel("PRECART_PROMPT_TITLE")}
      content={translateLabel("PRECART_PROMPT_CONTENT")}
      cancel={translateLabel("PRECART_PROMPT_CANCEL")}
      confirm={translateLabel("PRECART_PROMPT_CONFIRM")}
      onConfirm={() => dispatch(clearPreCart())} // empty local precart
      onCancel={() => {
        //adding items to precart in BE
        dispatch(trackEvent({ id: "AddToCart" }));
        dispatch(addItemsToPrecart({}));
      }}
      alwaysRedirect
      data-element-id="AbandonOverlay_AddToCart"
    />
  );
};

export default RouteLeavingGuardPrecart;
