import { BFFApiDomain } from "../serviceUtils";

/////////////////// common

export const adtAssetPlatformWebsiteUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/websiteInfo";

export const adtAssetPlatformSocialMediaUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/socialMediaPlatforms";

export const adtCategoriesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/categories";

export const adtMetaDataUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/metadata/{propertyId}";

export const addToSavedAssetsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/saved/add";

export const addToDownloadedAssetsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/downloaded/add";

export const deleteFromSavedAssetsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/saved/delete";

export const deleteFromDownloadedAssetsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/downloaded/delete";

/////////////////// plp

export const adtGetAssetsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/get";

export const adtGetFiltersUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/filters";

/////////////////// pdp
export const adtGetAssetsDetailsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/details/get";

export const adtGetAssetsRelatedUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/related/get";

export const adtProductsImagesByMocoUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/productImages/byMoco";

/////////////////// myaccount
export const adtGetAssetsSavedUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/saved/get";

export const adtGetAssetsDownloadedUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/downloaded/get";

export const adtGetAssetsSavedFiltersUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/saved/filters";

export const adtGetAssetsDownloadedFiltersUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/downloaded/filters";

export const adtRequestAssetsDownloadUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/assets/download";

/////////////////// request image
export const adtOrderAssortmentDownload =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/orderAssortment/download";

export const adtTemplateDownload =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/adt/orderAssortment/template";
