import React from "react";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";

import { AttributeValue } from "../../interfaces/productInterface";
import CustomText from "../styled-UI/CustomText";
import AvailabilityStatus from "./AvailabilityStatus";

import Loader from "../styled-UI/Loader";

interface SplitFramesProps {
  size?: AttributeValue;
  stockNum: number;
  status: string;
  inventoryStatus?: string;
  id: string;
  isLoading?: boolean;
}

const SplitFramesComponent = ({
  size,
  stockNum,
  status,
  inventoryStatus,
  id,
  isLoading,
}: SplitFramesProps): JSX.Element => {
  const { translateLabel } = useTranslation();

  return (
    <Container>
      <FlexContainer>
        <AvailabilityStatus inventoryStatus={inventoryStatus} id={id} />
        <CustomText as="span" fontSizePx={13} marginLeftPx={8} marginRightPx={8}>
          {translateLabel("SIZE")}
        </CustomText>
        <CustomText as="span" fontSizePx={13} font="font-bold" marginRightPx={42}>
          {size?.value}
        </CustomText>
        <NumberBox>
          <CustomText as="span" fontSizePx={13} font="font-bold">
            {stockNum}
          </CustomText>
        </NumberBox>
        {isLoading && (
          <BoxLoader>
            <Loader sizePx={30} />
          </BoxLoader>
        )}
      </FlexContainer>
      <FlexContainer>
        <MessageBox>
          <CustomText as="span" fontSizePx={13} font="font-medium">
            {translateLabel(status)}
          </CustomText>
        </MessageBox>
      </FlexContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  margin-bottom: 1.125rem;
  position: relative;
  max-width: 20rem;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;

  &:last-child {
    justify-content: flex-end;
  }
`;

const NumberBox = styled.div`
  padding: 0.625rem 0 0.625rem 0;
  background-color: ${(props) => props.theme.palette.gray.light};
  border: solid 1px ${(props) => props.theme.palette.gray.medium};
  margin-bottom: 0.375rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
`;

const MessageBox = styled.div``;

const BoxLoader = styled.div`
  position: absolute;
  right: -3.75rem;
`;

export default SplitFramesComponent;
