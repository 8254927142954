import { useMemo } from "react";
import { useSelector } from "react-redux";

import { Col, ItemsMap, Page, Row } from "../interfaces/gridInterfaces";
import { PrePLPCarousels } from "../store/catalogue/catalogueInterface";
import { selectItemsMap } from "../store/store/storeSlice";
import { getAnalyticsTagId } from "../utils/cmsUtils";
import { instanceOfProduct } from "../utils/productUtils";

interface Props {
  layout: Page;
  rowsToIgnore: string[];
  prePLPCarousels?: PrePLPCarousels;
}

const useCMSAnalyticsTag = ({
  layout,
  rowsToIgnore,
  prePLPCarousels,
}: Props): { pageCreativities: string } => {
  const itemsMap = useSelector(selectItemsMap);

  const filteredRows = useMemo(
    () => layout?.rows?.filter((_) => !(_.name && rowsToIgnore.includes(_.name))),
    [layout, rowsToIgnore]
  );

  return {
    pageCreativities: useMemo(
      () =>
        [
          ...getPageCreativities(filteredRows, itemsMap),
          ...getPreplpCreativities(prePLPCarousels),
        ].join(","),
      [filteredRows, itemsMap, prePLPCarousels]
    ),
  };
};

const getIds = (filteredRows: Row[] | undefined, itemsMap: ItemsMap) => {
  const analyticsTags: string[] = [];

  filteredRows?.map((row) =>
    row?.cols?.map((item: Col) => {
      const singleItem = item?.id ? itemsMap[item.id] : undefined;
      const itemContent =
        singleItem?.result && singleItem.result.length > 0
          ? singleItem?.result
          : [singleItem?.content];

      // console.log("ROW:", row, "\nITEM:", item, "\nSINGLEITEM:", singleItem);

      itemContent?.map((_) => {
        const analyticsTag = getAnalyticsTagId(_, _?.id);
        if (analyticsTag) analyticsTags.push(analyticsTag);
      });
    })
  );

  // console.log("\n\n\n---------- RESULT", analyticsTags);

  return analyticsTags;
};

const getPageCreativities = (filteredRows: Row[] | undefined, itemsMap: ItemsMap): string[] => {
  return getIds(filteredRows, itemsMap);
};

const getPreplpCreativities = (prePLPCarousels?: PrePLPCarousels): string[] => {
  const analyticsTags: string[] = [];

  if (prePLPCarousels) {
    Object.values(prePLPCarousels)?.forEach((carousel) =>
      carousel.resultList?.forEach((_) => {
        if (!instanceOfProduct(_)) {
          const analyticsTag = getAnalyticsTagId(_, _?.id);
          if (analyticsTag) analyticsTags.push(analyticsTag);
        }
      })
    );
  }

  return analyticsTags;
};

export default useCMSAnalyticsTag;
