import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import clsx from "clsx";
import { getAdvancedSearch, getPersonificationCustomers } from "../../../store/user/userSagas";
import {
  selectAdvancedSearch,
  selectAdvancedSearchLoading,
  selectGetPersonificationStatus,
  selectPersonificationCustomers,
} from "../../../store/user/userSlice";
import PersonificationUserRadio from "./personification-user/PersonificationUserRadio";
import CustomText from "../../styled-UI/CustomText";
import { CustomOptions } from "../../styled-UI/CustomSelect";
import Button from "../../styled-UI/Button";
import InputComponent from "../../styled-UI/InputComponent";
import CustomScrollbar from "../../styled-UI/CustomScrollbar";
import LinkButton from "../../styled-UI/LinkButton";
import RadioButton from "../../styled-UI/RadioButton";
import { ReactComponent as Arrow } from "../../../assets/icons/arrowhead-down-icon.svg";
import useTranslation from "../../../hooks/useTranslation";
import LoaderElement from "../../styled-UI/loader/LoaderElement";
import Loader from "../../styled-UI/Loader";

export interface SelectList {
  salesChannel: CustomOptions | null;
  gtmRating: CustomOptions | null;
}
export type SelectNameList = keyof SelectList;

interface Props {
  selectCustomer: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedCustomer: string | null;
  selectedCountry: CustomOptions | null;
  setSubmitPopup: (submit: boolean) => void;
}

const PersonificationPopupSearch = ({
  selectCustomer,
  selectedCustomer,
  selectedCountry,
  setSubmitPopup,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { translateLabel } = useTranslation();

  const customers = useSelector(selectPersonificationCustomers);
  const advancedSearch = useSelector(selectAdvancedSearch);
  const advancedSearchLoading = useSelector(selectAdvancedSearchLoading);
  const getCustomersStatus = useSelector(selectGetPersonificationStatus);

  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectList, setSelectList] = useState<SelectList>({
    salesChannel: null,
    gtmRating: null,
  });
  const [userListFocused, setUserListFocused] = useState<boolean>(false);

  // const isSearchDisabled =
  //   searchTerm === "" && selectList.salesChannel === null && selectList.gtmRating === null;

  //get radio buttons info
  useEffect(() => {
    if (openSearch && !advancedSearch) {
      dispatch(getAdvancedSearch());
    }
  }, [openSearch, advancedSearch]);

  //update radio button
  const handleSelectChange = (value: CustomOptions, type: SelectNameList) => {
    const newSelectList = { ...selectList };
    newSelectList[type] = value;
    setSelectList(newSelectList);
  };

  //update search input
  const handleCustomerTermInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  //search
  const onCustomerSearch = () => {
    dispatch(
      getPersonificationCustomers({
        searchCustomer: searchTerm.trim(),
        storeSelected: selectedCountry?.value.toString(),
      })
    );
  };

  const searchAdvanced = () => {
    dispatch(
      getPersonificationCustomers({
        searchCustomer: "",
        ratingGTM: selectList.gtmRating?.value as string | undefined,
        salesChannel: selectList.salesChannel?.value as string | undefined,
        storeSelected: selectedCountry?.value.toString(),
      })
    );
  };

  const resetFilters = () => {
    setSelectList({
      salesChannel: null,
      gtmRating: null,
    });
  };

  const handlePopupSubmit = (e: KeyboardEvent) => {
    if (userListFocused) {
      if (e.key === "Enter") setSubmitPopup(true); //submitPopup(selectedCustomer);
    }
  };

  useEffect(() => {
    document.getElementById("userList")?.addEventListener("keypress", (e) => handlePopupSubmit(e));

    return () => {
      document
        .getElementById("userList")
        ?.removeEventListener("keypress", (e) => handlePopupSubmit(e));
    };
  });

  return (
    <>
      <SearchSection>
        <div>
          <InputComponent
            type="text"
            placeholder={translateLabel("PERSONIFICATION_POPUP_PERSONIFIED_CUSTOMER")}
            onChange={handleCustomerTermInput}
            onKeyPress={(e: React.KeyboardEvent<Element>) => {
              if (e.key === "Enter") {
                onCustomerSearch();
              }
            }}
            onFocus={() => setUserListFocused(false)}
            value={searchTerm}
          />
          <ToggleContainer className={clsx(openSearch && "arrow-rotate")}>
            <LinkButton onClick={() => setOpenSearch(!openSearch)} underline={false}>
              {translateLabel("PERSONIFICATION_POPUP_ADVANCED")} <Arrow />
            </LinkButton>
          </ToggleContainer>
        </div>
        <Button onClick={onCustomerSearch} type="primary" disabled={searchTerm === ""}>
          {translateLabel("PERSONIFICATION_POPUP_SEARCH")}
        </Button>
        {openSearch && (
          <FiltersSearch>
            <CustomText
              as="span"
              font="font-bold"
              fontSizePx={18}
              lineHeightPx={18}
              marginBottomPx={12}
            >
              {translateLabel("PERSONIFICATION_POPUP_GTM")}
            </CustomText>
            <CustomText
              as="span"
              font="font-bold"
              fontSizePx={18}
              lineHeightPx={18}
              marginBottomPx={12}
            >
              {translateLabel("PERSONIFICATION_POPUP_SALES")}
            </CustomText>
            {!advancedSearchLoading ? (
              <>
                <RadioButtonBox>
                  {advancedSearch?.gtmRating?.map((rating) => (
                    <RadioButton
                      onChange={(e) => {
                        handleSelectChange(e, "gtmRating");
                      }}
                      name="gtmRating"
                      key={rating.code}
                      value={rating.code}
                      label={rating.label}
                      checked={selectList.gtmRating?.value === rating.code}
                    />
                  ))}
                </RadioButtonBox>
                <RadioButtonBox>
                  {advancedSearch?.salesChannels?.map((channel) => {
                    return (
                      <RadioButton
                        onChange={(e) => {
                          handleSelectChange(e, "salesChannel");
                        }}
                        name="salesChannel"
                        key={channel}
                        label={channel}
                        value={channel}
                        checked={selectList.salesChannel?.value === channel}
                      />
                    );
                  })}
                </RadioButtonBox>
              </>
            ) : (
              <BoxLoader>
                <LoaderElement height={200} />
                <LoaderElement height={200} />
              </BoxLoader>
            )}
            <ButtonContainer>
              <LinkButton onClick={resetFilters} disabled={advancedSearchLoading}>
                {translateLabel("PERSONIFICATION_POPUP_RESET")}
              </LinkButton>
              <Button onClick={searchAdvanced} type="primary" disabled={advancedSearchLoading}>
                {translateLabel("PERSONIFICATION_POPUP_SEARCH")}
              </Button>
            </ButtonContainer>
          </FiltersSearch>
        )}
      </SearchSection>
      <CustomText as="span" fontSizePx={18} font="font-bold" marginBottomPx={14} marginTopPx={32}>
        {translateLabel("PERSONIFICATION_POPUP_SELECTION")}
      </CustomText>
      <UserListContainer
        onClick={() => {
          !userListFocused && setUserListFocused(true);
        }}
        id={"userList"}
        tabIndex={0}
      >
        {getCustomersStatus === "LOADING" && (
          <LoaderContainer>
            <Loader sizePx={40} />
          </LoaderContainer>
        )}
        {getCustomersStatus === "SUCCESS" &&
          (customers && customers.length ? (
            <CustomScrollbar>
              {customers.map((customer) => (
                <PersonificationUserRadio
                  key={customer.orgentityId}
                  customer={customer}
                  onChange={selectCustomer}
                  checked={selectedCustomer === customer.orgentityId}
                />
              ))}
            </CustomScrollbar>
          ) : (
            <LoaderContainer>
              <CustomText as="p">
                {translateLabel("PERSONIFICATION_POPUP_NO_USER_FOUND")}
              </CustomText>
            </LoaderContainer>
          ))}
      </UserListContainer>
    </>
  );
};

const SearchSection = styled.div`
  display: grid;
  grid-template-columns: 34.44rem auto;
  column-gap: 1.5rem;
  margin-top: 2rem;
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;

  svg {
    display: inline-block;
    height: 0.75rem;
    width: 0.75rem;
    fill: ${(props) => props.theme.palette.primary};
    vertical-align: middle;
  }

  &.arrow-rotate {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 1rem;

  & > button:first-child {
    margin-right: 1rem;
  }
`;

const FiltersSearch = styled.div`
  background-color: ${(props) => props.theme.palette.gray.light};
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  padding: 2rem;
  grid-column-start: 1;
  grid-column-end: 3;
`;

const RadioButtonBox = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  padding: 1.5rem 2rem;
  border: 1px solid ${(props) => props.theme.palette.gray.medium};
  border-radius: 0.25rem;

  > label:not(:last-child) {
    margin-bottom: 1.125rem;
  }
`;

const UserListContainer = styled.div`
  border: 1px solid ${(props) => props.theme.palette.gray.medium};
  border-radius: 0.25rem;
  height: 10.13rem;
`;

const BoxLoader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  grid-column-start: 1;
  grid-column-end: 3;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export default PersonificationPopupSearch;
