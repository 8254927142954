import React from "react";
import { useSelector } from "react-redux";
import useTranslation from "../../hooks/useTranslation";
import styled from "styled-components/macro";
import CustomText from "../styled-UI/CustomText";
import LinkButton from "../styled-UI/LinkButton";
import { getPagePath } from "../../routing/routesUtils";
import { useHistory } from "react-router-dom";
import { News } from "../../interfaces/cmsInterfaces";
import { selectLocale } from "../../store/user/userSlice";
import { formatDateNews } from "../../utils/dateUtils";
import useDataHref from "../../hooks/useDataHref";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { getAnalyticsTagId } from "../../utils/cmsUtils";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  news: News;
  numberTile: number;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const HomepageNewsBox = ({
  news,
  numberTile,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const history = useHistory();
  const { translateLabel } = useTranslation();
  const currentLocale = useSelector(selectLocale);

  const descrWithHref = useDataHref({ content: news.description });
  const analyticsTag = getAnalyticsTagId(news, news.id);

  return (
    <BoxTextWithBorder>
      <CustomText
        as="span"
        fontSizePx={13}
        font={customFont ? "custom-light" : "font-light"}
        fontFamily={customFont}
        color="gray-dark"
        marginBottomPx={6}
      >
        {news.creationDate && formatDateNews(news.creationDate, currentLocale)}
      </CustomText>

      {news?.description && (
        <CustomText
          as="span"
          fontSizePx={21}
          font={customFont ? "custom-light" : "font-light"}
          fontFamily={customFont}
          color={isDarkMode ? "white" : "primary"}
          lineHeightPx={28}
          customColor={news.description1Colour}
        >
          <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? news.description} />
        </CustomText>
      )}
      <BoxButton>
        <LinkButton
          onClick={() => history.push(getPagePath("/news/" + news.id))}
          uppercase
          underline
          data-element-id={`News_List_News${numberTile}`}
          data-description={`${analyticsTag}_${news.name}`}
          color={isDarkMode ? "white" : "primary"}
          customFont={customFont}
        >
          {translateLabel("READ_MORE")}
        </LinkButton>
      </BoxButton>
    </BoxTextWithBorder>
  );
};

export default HomepageNewsBox;

const BoxTextWithBorder = styled.div`
  margin-top: 52px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 1rem;
`;

const BoxButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;
