import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import { AttributeSlim } from "../../../../interfaces/productInterface";
import { selectModelDetailsWarranty } from "../../../../store/warranty-wizard/warrantyWizardSlice";
import { getAttributeValues } from "../../../../utils/productUtils";
import { capitalizeFirstLetter } from "../../../../utils/utils";
import CustomText from "../../../styled-UI/CustomText";

export const ModelDetailsSchema: string[] = [
  "PRODUCT_TYPE",
  "GENDER",
  "FRAME_SHAPE",
  "FRAME_MATERIAL",
  "TEMPLE_MATERIAL",
  "BRIDGE_TYPE",
  "GEOFIT",
  "LENS_BASE",
  "RX_COMPLETE_JOB",
];

export const SizeGuideSchema: string[] = [
  "DL_SIZE_CODE",
  "BRIDGE_WIDTH",
  "LENS_HEIGHT",
  "LENS_DIAGONAL",
  "TEMPLE_LENGTH",
];

const StepSelectVariantDetails = (): JSX.Element => {
  const { translateLabel } = useTranslation();

  const modelDetailsWarranty = useSelector(selectModelDetailsWarranty);

  const renderModelDetailsContent = () => {
    return (
      <ModelDetailsWrapper>
        {ModelDetailsSchema?.map((attribute: string) => {
          const attributeData =
            modelDetailsWarranty?.modelAttributes &&
            getAttributeValues(modelDetailsWarranty?.modelAttributes, attribute);

          if (attributeData && attributeData.name && attributeData.values) {
            return (
              <ModelDetailsLine>
                <CustomText
                  key={attribute}
                  as="span"
                  fontSizePx={13}
                  lineHeightPx={18}
                  font="font-regular"
                >
                  {capitalizeFirstLetter(attributeData.name)}:
                </CustomText>

                <CustomText
                  key={attribute}
                  as="span"
                  fontSizePx={13}
                  lineHeightPx={18}
                  font="font-bold"
                >
                  {capitalizeFirstLetter(attributeData.values)}
                </CustomText>
              </ModelDetailsLine>
            );
          }
        })}
      </ModelDetailsWrapper>
    );
  };

  const renderSizeGuideContent = () => {
    return (
      <div>
        {/* Header */}
        <SizeGuideLine>
          {modelDetailsWarranty?.skuAttributes?.[0]?.map((attribute: AttributeSlim) => {
            return (
              <CustomText
                key={attribute.name}
                as="span"
                fontSizePx={13}
                lineHeightPx={18}
                font="font-regular"
              >
                {attribute.name}
              </CustomText>
            );
          })}
        </SizeGuideLine>

        {/* Table content */}
        {modelDetailsWarranty?.skuAttributes?.map((attributes, index) => (
          <SizeGuideLine key={index}>
            {attributes?.map((attribute: AttributeSlim) => {
              return (
                <CustomText
                  key={attribute.name}
                  as="span"
                  fontSizePx={13}
                  lineHeightPx={18}
                  font="font-bold"
                >
                  {attribute.values}
                </CustomText>
              );
            })}
          </SizeGuideLine>
        ))}
      </div>
    );
  };

  return (
    <ProductDetailsWrapper>
      <ModelDetailsColumn>
        <CustomText as="p" font="font-medium" fontSizePx={18}>
          {translateLabel("WARRANTY_SELECT_SKU_MODEL_DETAILS")}
        </CustomText>
        {renderModelDetailsContent()}
      </ModelDetailsColumn>

      <SizeGuideColumn>
        <CustomText as="p" font="font-medium" fontSizePx={18}>
          {translateLabel("WARRANTY_SELECT_SKU_SIZE_GUIDE")}
        </CustomText>
        {renderSizeGuideContent()}
      </SizeGuideColumn>
    </ProductDetailsWrapper>
  );
};

const ProductDetailsWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  margin-bottom: 1.25rem;
  border-radius: 0.25rem;
  padding: 2rem;

  @media ${(props) => props.theme.queries.md} {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }
`;

const ModelDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.0625rem;
`;

const ModelDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.75rem;
`;

const ModelDetailsLine = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
`;

const SizeGuideColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.0625rem;
`;

const SizeGuideLine = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  padding-bottom: 0.5rem;

  span {
    display: inline-block;
  }

  @media ${(props) => props.theme.queries.md} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default StepSelectVariantDetails;
