import { RequestStatus } from "./../../interfaces/mainInterfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorObjInvoicePayload, ErrorObjPayload } from "../../interfaces/mainInterfaces";

import { RootState } from "../storeConfig";

import {
  AccountingError,
  AccountingLoading,
  AccountingState,
  Credit,
  CreditDetailsData,
  Invoice,
  InvoiceDownload,
  InvoiceOptions,
  AlertsCounts,
  PinValidated,
} from "./accountingInterfaces";

const emptyError = {
  type: "",
  status: false,
};

const emptyErrorInvoice = {
  type: "",
  status: false,
  code: null,
};

const defaultAccountingLoading = {
  accountingList: false,
  accountingFilters: false,
  accountingPinValidate: false,
};

const defaultAccountingError = {
  pinError: { ...emptyError },
  paymentSectionError: { ...emptyErrorInvoice },
};

export const sliceName = "accounting";

const initialState: AccountingState = {
  invoices: null,
  invoiceOptions: {
    documentTypeList: [],
    customerList: [],
    companyList: [],
    divisionList: [],
  },
  prepareMassiveInvoiceDownloadStatus: "IDLE",
  massiveInvoiceDownload: null,
  massiveInvoiceDownloadStatus: "IDLE",
  creditSummary: null,
  creditDetails: null,
  temporaryPin: "",
  emailSentFlag: false,
  pinValidated: null,
  requestStatus: "IDLE",
  error: { ...defaultAccountingError },
  loading: { ...defaultAccountingLoading },
  orgentityDoor: "",
  sectionsAlerts: undefined,
  notificationAlerts: 0,
  sectionsAlertsStatus: "IDLE",
  notificationAlertsStatus: "IDLE",
};

export const accountingSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    /////////////////// invoices
    saveInvoices: (state, action: PayloadAction<Invoice>) => {
      state.invoices = action.payload;
    },
    setPrepareMassiveInvoiceDownloadStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.prepareMassiveInvoiceDownloadStatus = action.payload;
    },
    setMassiveInvoiceDownload: (state, action: PayloadAction<InvoiceDownload | null>) => {
      state.massiveInvoiceDownload = action.payload;
    },
    setMassiveInvoiceDownloadStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.massiveInvoiceDownloadStatus = action.payload;
    },
    resetInvoices: (state) => {
      state.invoices = null;
      state.invoiceOptions = {
        documentTypeList: [],
        customerList: [],
        companyList: [],
        divisionList: [],
      };
      state.error = { ...defaultAccountingError };
      state.prepareMassiveInvoiceDownloadStatus = "IDLE";
    },
    /////////////////// credit
    saveCreditSummary: (state, action: PayloadAction<Credit>) => {
      state.creditSummary = action.payload;
    },
    saveCreditDetails: (state, action: PayloadAction<CreditDetailsData[]>) => {
      state.creditDetails = action.payload;
    },
    saveInvoiceOptions: (state, action: PayloadAction<InvoiceOptions>) => {
      state.invoiceOptions = action.payload;
    },
    resetCredit: (state) => {
      state.creditSummary = null;
      state.error = { ...defaultAccountingError };
    },
    ///////////////// credit details
    resetCreditDetails: (state) => {
      state.creditDetails = null;
      state.error = { ...defaultAccountingError };
    },
    ///////////////// common
    saveTemporaryPin: (state, action: PayloadAction<string>) => {
      state.temporaryPin = action.payload;
    },
    setError: (state, action: PayloadAction<ErrorObjPayload>) => {
      state.error = { ...state.error, [action.payload.errorName]: action.payload.error };
    },
    setErrorInvoice: (state, action: PayloadAction<ErrorObjInvoicePayload>) => {
      state.error = { ...state.error, [action.payload.errorName]: action.payload.error };
    },
    toggleEmailSentFlag: (state, action: PayloadAction<boolean>) => {
      state.emailSentFlag = action.payload;
    },
    clearAcccountingError: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case "pinError":
          state.error = {
            ...state.error,
            pinError: { ...emptyError },
          };
          break;
        case "paymentSectionError":
          state.error = {
            ...state.error,
            paymentSectionError: { ...emptyErrorInvoice },
          };
          break;
        default:
          break;
      }
    },
    startAccountingLoading: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case "accountingList":
          state.loading = {
            ...state.loading,
            accountingList: true,
          };
          break;
        case "accountingFilters":
          state.loading = {
            ...state.loading,
            accountingFilters: true,
          };
          break;
        case "accountingPinValidate":
          state.loading = {
            ...state.loading,
            accountingPinValidate: true,
          };
          break;
      }
    },
    stopAccountingLoading: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case "accountingList":
          state.loading = {
            ...state.loading,
            accountingList: false,
          };
          break;
        case "accountingFilters":
          state.loading = {
            ...state.loading,
            accountingFilters: false,
          };
          break;
        case "accountingPinValidate":
          state.loading = {
            ...state.loading,
            accountingPinValidate: false,
          };
          break;
      }
    },
    savePinValidated: (state, action: PayloadAction<PinValidated>) => {
      state.pinValidated = action.payload;
    },
    setRequestStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.requestStatus = action.payload;
    },
    saveOrgentityDoor: (state, action: PayloadAction<string>) => {
      state.orgentityDoor = action.payload;
    },
    saveSectionsAlerts: (state, { payload }: PayloadAction<AlertsCounts | undefined>) => {
      state.sectionsAlerts = payload;
    },
    setNotificationsAlerts: (state, { payload }: PayloadAction<number>) => {
      state.notificationAlerts = payload;
    },
    setSectionsAlertsStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.sectionsAlertsStatus = payload;
    },
    setNotificationsAlertsStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.notificationAlertsStatus = payload;
    },
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  saveInvoices,
  saveCreditSummary,
  saveCreditDetails,
  saveTemporaryPin,
  saveInvoiceOptions,
  setPrepareMassiveInvoiceDownloadStatus,
  setMassiveInvoiceDownloadStatus,
  setMassiveInvoiceDownload,
  toggleEmailSentFlag,
  setError,
  setErrorInvoice,
  startAccountingLoading,
  stopAccountingLoading,
  clearAcccountingError,
  savePinValidated,
  setRequestStatus,
  resetCreditDetails,
  resetCredit,
  resetInvoices,
  saveOrgentityDoor,
  saveSectionsAlerts,
  setNotificationsAlerts,
  setSectionsAlertsStatus,
  setNotificationsAlertsStatus,
} = accountingSlice.actions;

/////////////////// invoices
export const selectInvoices = (state: RootState): Invoice | null => {
  return state.accounting.invoices;
};

export const selectPrepareMassiveInvoiceDownloadStatus = (state: RootState): RequestStatus => {
  return state.accounting.prepareMassiveInvoiceDownloadStatus;
};

export const selectMassiveInvoiceDownload = (state: RootState): InvoiceDownload | null => {
  return state.accounting.massiveInvoiceDownload;
};

export const selectMassiveInvoiceDownloadStatus = (state: RootState): RequestStatus => {
  return state.accounting.massiveInvoiceDownloadStatus;
};

/////////////////// credit
export const selectCreditSummary = (state: RootState): Credit | null => {
  return state.accounting.creditSummary;
};

export const selectCreditDetails = (state: RootState): CreditDetailsData[] | null => {
  return state.accounting.creditDetails;
};

export const selectTemporaryPin = (state: RootState): string => {
  return state.accounting.temporaryPin;
};

export const selectEmailSentFlag = (state: RootState): boolean => {
  return state.accounting.emailSentFlag;
};

export const selectAccountingError = (state: RootState): AccountingError => {
  return state.accounting.error;
};

export const selectAccountingLoading = (state: RootState): AccountingLoading =>
  state.accounting.loading;

export const selectInvoiceOptions = (state: RootState): InvoiceOptions => {
  return state.accounting.invoiceOptions;
};

export const selectPinValidated = (state: RootState): PinValidated => {
  return state.accounting.pinValidated;
};

export const selectRequestStatus = (state: RootState): RequestStatus => {
  return state.accounting.requestStatus;
};

export const selectOrgentityDoor = (state: RootState): string => {
  return state.accounting.orgentityDoor;
};

export const selectSectionAlerts = (state: RootState): AlertsCounts | undefined =>
  state.accounting.sectionsAlerts;

export const selectNotificationAlerts = (state: RootState): number =>
  state.accounting.notificationAlerts;

export const selectSectionsAlertsStatus = (state: RootState): RequestStatus =>
  state.accounting.sectionsAlertsStatus;

export const selectNotificationsAlertsStatus = (state: RootState): RequestStatus =>
  state.accounting.sectionsAlertsStatus;

export default accountingSlice.reducer;
