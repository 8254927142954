import React from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import CustomText from "../../../styled-UI/CustomText";
import { getPagePath } from "../../../../routing/routesUtils";

interface Props {
  url?: string;
  onClick?: () => void;
  children: any;
}
const TileFooterButton = ({ children, url, onClick }: Props): JSX.Element => {
  const history = useHistory();

  const handleClick = () => {
    if (url) {
      history.push(getPagePath(url));
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <FooterItemContent onClick={handleClick}>
      <CustomText as="span" fontSizePx={13} font="font-bold" color="primary">
        {children}
      </CustomText>
    </FooterItemContent>
  );
};

const FooterItemContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  border-radius: 0.25rem;
  background-color: rgba(3, 20, 52, 0.03);
  height: 2.375rem;
  min-width: 11rem;
`;

export default TileFooterButton;
