import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { orderBy, cloneDeep } from "lodash";

import { selectBrandGroups, selectBrandLabels } from "../store/storeSlice";
import { RootState } from "../storeConfig";
import {
  RxState,
  Brand,
  JobType,
  FocalTypesDetail,
  Partnumber,
  Upc,
  Color,
  Size,
  CheckedFrames,
  RecivedLenses,
  MadeIn,
  RxSubmitError,
  RxPrice,
  Lens,
  CheckIncludedItemsPayload,
  CustomerReferencePayload,
  CustomerReference,
  Frame,
  PrescriptionObj,
  SelectList,
  Makeability,
  LensesSections,
} from "./rxInterface";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import { CustomOptions } from "../../components/styled-UI/CustomSelect";
import {
  PostPrecartItemsPayload,
  PostPrecartOrderItemPayload,
  PostRxPrecartOrderItemPayload,
} from "../cart/cartInterfaces";
import { selectSelectedDoor, selectUsersPrivileges } from "../user/userSlice";
import { BrandGroups } from "../../interfaces/menuInterfaces";
import { Door, UserPrivileges } from "../user/userInterfaces";
import { getLensAttr } from "../../utils/rxUtils";
import { computeSelectedDoor } from "../../utils/userUtils";

export const sliceName = "rx";

const initialState: RxState = {
  brandGroups: null,
  brands: null,
  isCollection: false,
  jobTypeList: null,
  customerOwnFrame: null,
  partnumbers: null,
  colors: null,
  upcs: null,
  sizes: null,
  lensImg: "",
  focalTypeDetails: null,
  // checkFrames
  checkedFrames: null,
  compatibleFrames: null,
  rxSubmitError: null,
  selectedFrame: null,
  // getLenses
  recivedLenses: null,
  lensesSections: null,
  selectedLens: null, // selected checkbox state

  madeInAndItemsInPackage: null,
  otherDescription: "",
  makeability: null,
  includedItems: ["MADEIN_FRAME"], // checkbox value
  customerReference: { name: "", surname: "" },
  rxPrices: null,
  // cart
  rxCartItem: null,

  // request statuses
  isFrameAutoSelectionComplete: "IDLE",
  checkFrameStatus: "IDLE",
  getLensesStatus: "IDLE",
  postMakeabilityStatus: "IDLE",
  getRxPricesStatus: "IDLE",
  addRxToPrecartStatus: "IDLE",
  rxAutoselectionStatus: "IDLE",
};

export const rxSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setBrandGroups: (state, action: PayloadAction<Brand[] | null>) => {
      state.brandGroups = action.payload;
    },
    setBrands: (state, action: PayloadAction<Brand[] | null>) => {
      state.brands = action.payload;
    },
    setIsCollection: (state, action: PayloadAction<boolean>) => {
      state.isCollection = action.payload;
    },
    setJobTypeList: (state, action: PayloadAction<JobType[] | null>) => {
      state.jobTypeList = action.payload;
    },
    setCustomerOwnFrame: (state, action: PayloadAction<boolean>) => {
      state.customerOwnFrame = action.payload;
    },
    setUpcs: (state, action: PayloadAction<Upc[] | null>) => {
      state.upcs = action.payload;
    },
    setPartnumbers: (state, action: PayloadAction<Partnumber[] | null>) => {
      state.partnumbers = action.payload;
    },
    setColors: (state, action: PayloadAction<Color[] | null>) => {
      state.colors = action.payload;
    },
    setSizes: (state, action: PayloadAction<Size[] | null>) => {
      state.sizes = action.payload;
    },
    setFocalTypeDetails: (state, action: PayloadAction<FocalTypesDetail[] | null>) => {
      state.focalTypeDetails = action.payload;
    },
    setLensImg: (state, action: PayloadAction<string>) => {
      state.lensImg = action.payload;
    },
    setRxAutoselectionStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.rxAutoselectionStatus = action.payload;
    },
    setCheckedFrames: (state, action: PayloadAction<CheckedFrames | null>) => {
      state.checkedFrames = action.payload;
    },
    setCompatibleFrames: (state, action: PayloadAction<Frame[] | null>) => {
      state.compatibleFrames = action.payload;
      if (action.payload) {
        state.rxSubmitError = {
          errorType: "FRAME_COMPATIBILITY_ERROR",
          errorCode: 0,
          errorMessage: "TO_TRANSLATE:RX_FRAME_COMPATIBILITY_ERROR",
        };
      }
    },
    setRxSubmitError: (state, action: PayloadAction<RxSubmitError | null>) => {
      state.rxSubmitError = action.payload;
    },
    setSelectedFrame: (state, action: PayloadAction<Frame | null>) => {
      state.selectedFrame = action.payload;
    },
    setCheckFrameStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.checkFrameStatus = action.payload;
    },
    setRecivedLenses: (state, { payload }: PayloadAction<RecivedLenses | null>) => {
      state.recivedLenses = payload;
    },
    setLensesSections: (state, { payload }: PayloadAction<LensesSections[] | null>) => {
      if (!state.lensesSections || !payload) state.lensesSections = payload;
    },
    setGetLensesStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.getLensesStatus = payload;
    },
    setMadeInAndItemsInPackage: (state, { payload }: PayloadAction<MadeIn | null>) => {
      state.madeInAndItemsInPackage = payload;
    },
    setIncludedItems: (state, { payload }: PayloadAction<string[]>) => {
      state.includedItems = payload;
    },
    setOtherDescription: (state, { payload }: PayloadAction<string>) => {
      state.otherDescription = payload;
    },
    checkIncludedItems: (state, { payload }: PayloadAction<CheckIncludedItemsPayload>) => {
      if (payload.includedOpt === "MADEIN_FRAME") return;

      const newIncludedItems = [...state.includedItems];
      if (payload.shouldCheck) {
        newIncludedItems.push(payload.includedOpt);
      } else {
        const includedOptIndex = newIncludedItems.findIndex((item) => item === payload.includedOpt);
        newIncludedItems.splice(includedOptIndex, 1);
      }
      state.includedItems = newIncludedItems;
    },
    setMakeability: (state, { payload }: PayloadAction<Makeability | null>) => {
      state.makeability = payload;
    },
    setPostMakeabilityStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.postMakeabilityStatus = payload;
    },
    setRxPrices: (state, { payload }: PayloadAction<RxPrice[] | null>) => {
      state.rxPrices = payload;
    },
    setGetRxPricesStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.getRxPricesStatus = payload;
    },
    setCustomerReference: (state, { payload }: PayloadAction<CustomerReferencePayload>) => {
      state.customerReference[payload.key] = payload.str;
    },
    setAllCustomerReference: (state, { payload }: PayloadAction<CustomerReference>) => {
      state.customerReference = payload;
    },
    setSelectedLens: (state, { payload }: PayloadAction<Lens | null>) => {
      state.selectedLens = payload;
    },
    setRxCartItem: (state, { payload }: PayloadAction<PostRxPrecartOrderItemPayload | null>) => {
      state.rxCartItem = payload;
    },
    setAddRxToPrecartStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.addRxToPrecartStatus = payload;
    },
    setIsFrameAutoSelectionComplete: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.isFrameAutoSelectionComplete = payload;
    },
    resetStep2: (state) => {
      state.recivedLenses = initialState.recivedLenses;
      state.lensesSections = initialState.lensesSections;
      state.selectedLens = initialState.selectedLens;
      state.checkFrameStatus = initialState.checkFrameStatus;
      state.getLensesStatus = initialState.getLensesStatus;
      state.madeInAndItemsInPackage = initialState.madeInAndItemsInPackage;
    },
    resetStep3: (state) => {
      state.makeability = initialState.makeability;
      state.postMakeabilityStatus = initialState.postMakeabilityStatus;
      state.includedItems = initialState.includedItems;
      state.customerReference = initialState.customerReference;
    },
    resetStep4: (state) => {
      state.getRxPricesStatus = initialState.getRxPricesStatus;
      state.rxAutoselectionStatus = initialState.rxAutoselectionStatus;
    },
    resetRxState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  setBrandGroups,
  setBrands,
  setIsCollection,
  setJobTypeList,
  setCustomerOwnFrame,
  setPartnumbers,
  setColors,
  setUpcs,
  setSizes,
  setLensImg,
  setFocalTypeDetails,
  setRxAutoselectionStatus,
  setCheckedFrames,
  setCompatibleFrames,
  setRecivedLenses,
  setSelectedFrame,
  setCheckFrameStatus,
  setLensesSections,
  setSelectedLens,
  setGetLensesStatus,
  setMadeInAndItemsInPackage,
  setRxSubmitError,
  setIncludedItems,
  setOtherDescription,
  checkIncludedItems,
  setCustomerReference,
  setAllCustomerReference,
  setMakeability,
  setPostMakeabilityStatus,
  setRxPrices,
  setGetRxPricesStatus,
  setRxCartItem,
  setAddRxToPrecartStatus,
  resetStep2,
  resetStep3,
  resetStep4,
  resetRxState,
  setIsFrameAutoSelectionComplete,
} = rxSlice.actions;

export const selectBrandGroup = (state: RootState): Brand[] | null => state.rx.brandGroups;

export const selectBrandGroupsOpts = createSelector(
  selectBrandLabels,
  selectBrandGroup,
  selectBrandGroups, // list of subbrands in store
  selectUsersPrivileges,
  selectSelectedDoor,
  (
    brandLabels: { [brand: string]: string },
    brandGroups: Brand[] | null,
    brandGroupList: BrandGroups,
    userPrivileges: UserPrivileges[],
    selectedDoor: Door | null
  ) => {
    const unOrderedBrandGroups: CustomOptions[] | null =
      brandGroups
        ?.map((brand) => ({
          label: brandLabels[brand.term] || brand.term,
          value: brand.term,
        }))
        .filter((brand: CustomOptions) => {
          return userPrivileges
            .find((user) => user.orgentityName === selectedDoor?.orgentityName)
            ?.privileges.some((priv: string) => {
              if (priv.includes("RX_")) {
                const privSplitted = priv.split("_");
                const privBrand = privSplitted[privSplitted.length - 1];
                return brandGroupList[brand.value]?.some(
                  (subBrand) => subBrand.brand === privBrand
                );
              }
            });
        }) || null;

    if (brandGroups?.[0]?.term && brandLabels[brandGroups?.[0].term]) {
      return orderBy(unOrderedBrandGroups, ["label"]);
    }
    return unOrderedBrandGroups;
  }
);

export const selectBrands = (state: RootState): Brand[] | null => state.rx.brands;

export const selectIsCollection = (state: RootState): boolean => state.rx.isCollection;

export const selectJobTypeList = (state: RootState): JobType[] | null => state.rx.jobTypeList;

export const selectCustomerOwnFrame = (state: RootState): boolean | null =>
  state.rx.customerOwnFrame;

export const selectFocalTypeDetails = (state: RootState): FocalTypesDetail[] | null =>
  state.rx.focalTypeDetails;

export const selectPartnumbers = (state: RootState): Partnumber[] | null => state.rx.partnumbers;

export const selectColors = (state: RootState): Color[] | null => state.rx.colors;

export const selectUpcs = (state: RootState): Upc[] | null => state.rx.upcs;

export const selectSizes = (state: RootState): Size[] | null => state.rx.sizes;

export const selectLensImg = (state: RootState): string => state.rx.lensImg;

export const selectRxAutoselectionStatus = (state: RootState): RequestStatus =>
  state.rx.rxAutoselectionStatus;

export const selectCheckedFrames = (state: RootState): CheckedFrames | null =>
  state.rx.checkedFrames;

export const selectCompatibleFrames = (state: RootState): Frame[] | null =>
  state.rx.compatibleFrames;

export const selectRxSubmitError = (state: RootState): RxSubmitError | null =>
  state.rx.rxSubmitError;

export const selectSelectedFrame = (state: RootState): Frame | null => state.rx.selectedFrame;

export const selectCheckFrameStatus = (state: RootState): RequestStatus =>
  state.rx.checkFrameStatus;

export const selectRecivedLenses = (state: RootState): RecivedLenses | null =>
  state.rx.recivedLenses;

export const selectLensesSectionsOpts = (state: RootState): CustomOptions[] | null =>
  state.rx.lensesSections?.map((section) => ({
    label: section.lenscategorytranslatedname,
    value: section.lensCategoryIdentifier,
  })) || null;

export const selectGetLensesStatus = (state: RootState): RequestStatus => state.rx.getLensesStatus;

export const selectMadeInAndItemsInPackage = (state: RootState): MadeIn | null =>
  state.rx.madeInAndItemsInPackage;

export const selectIncludedItems = (state: RootState): string[] => state.rx.includedItems;

export const selectOtherDescription = (state: RootState): string => state.rx.otherDescription;

export const selectCustomerReference = (state: RootState): CustomerReference =>
  state.rx.customerReference;

export const selectSelectedLens = (state: RootState): Lens | null => state.rx.selectedLens;

export const selectMakeability = (state: RootState): Makeability | null => state.rx.makeability;

export const selectPostMakeabilityStatus = (state: RootState): RequestStatus =>
  state.rx.postMakeabilityStatus;

export const selectRxPrices = (state: RootState): RxPrice[] | null => state.rx.rxPrices;

export const selectGetRxPricesStatus = (state: RootState): RequestStatus =>
  state.rx.getRxPricesStatus;

export const selectRxCartItem = (state: RootState): PostRxPrecartOrderItemPayload | null =>
  state.rx.rxCartItem;

export const selectIsFrameAutoSelectionComplete = (state: RootState): RequestStatus =>
  state.rx.isFrameAutoSelectionComplete;

export const selectAddRxToPrecartStatus = (state: RootState): RequestStatus =>
  state.rx.addRxToPrecartStatus;

export const selGenRxCartPayload = (
  selectList: SelectList,
  prescriptionDetails: PrescriptionObj | null,
  orderItemId: string | null,
  lens: Lens | null,
  selectedDoor: Door | null,
  frame?: Size
) => {
  return (state: RootState): PostPrecartItemsPayload => {
    const lensPrices = state.rx.rxPrices?.find((price) => price.itemIdentifier.includes("LSA"));
    const framePrices = state.rx.rxPrices?.find((price) => !price.itemIdentifier.includes("LSA"));

    let objToSend: PostPrecartOrderItemPayload = {
      productId: frame?.uniqueID as string,
      quantity: "1",
      xitem_multidoor: computeSelectedDoor(
        state.user.selectedDoor,
        state.user.activeDoor,
        state.user.multiDoors
      )?.orgentityId,
      // RX item
      xitem_RXbrandGroup: selectList.brandGroup?.value as string,
      xitem_RXbrand: state.rx.isCollection
        ? (selectList.brandGroup?.value as string)
        : (selectList?.brand?.value as string),
      xitem_RXjobType: selectList.jobType?.value as string,
      xitem_RXfocalType: selectList.focalType?.value.toString(),
      xitem_RXfocalTypeName: selectList.focalType?.value as string,
      xitem_RXlensCategory: selectList.lensType?.value as string,
      xitem_RXlensId: state.rx.selectedLens?.lensIdentifier as string,
      xitem_RXlensDescription: state.rx.selectedLens?.lensDescription as string,
      xitem_RXlensSrPrice: lensPrices?.srPrice.toString() as string,
      xitem_RXlensWhsPrice: lensPrices?.whsPrice.toString() as string,
      xitem_RXlensBrand: getLensAttr(lens, "BRAND"),
      xitem_RXlensMaterial: getLensAttr(lens, "MATERIAL"),
      xitem_RXframeSrPrice: (framePrices?.srPrice.toString() ?? "0") as string,
      xitem_RXframeWhsPrice: (framePrices?.whsPrice.toString() ?? "0") as string,
      xitem_RXcustomerReference1: state.rx.customerReference.name,
      xitem_RXcustomerReference2: state.rx.customerReference.surname,

      // RX prescription
      // RIGHT
      xitem_RXpRadd: prescriptionDetails?.ADD?.right,
      xitem_RXpRaxis: prescriptionDetails?.AXIS?.right,
      xitem_RXpRcylinder: prescriptionDetails?.CYLINDER?.right,
      xitem_RXpRdirection: prescriptionDetails?.DIRECTION?.right,
      xitem_RXpRdirectionUd: prescriptionDetails?.DIRECTIONUD?.right,
      xitem_RXpRfaceformTilt: prescriptionDetails?.FACEFORMTILT?.right,
      xitem_RXpRnear: prescriptionDetails?.NEAR?.right,
      xitem_RXpRocht: prescriptionDetails?.OCHT?.right,
      xitem_RXpRpantoTilt: prescriptionDetails?.PANTOTILT?.right,
      xitem_RXpRpd: prescriptionDetails?.PD?.right,
      xitem_RXpRprism: prescriptionDetails?.PRISM?.right,
      xitem_RXpRprismUd: prescriptionDetails?.PRISMUD?.right,
      xitem_RXpRseght: prescriptionDetails?.SEGHT?.right,
      xitem_RXpRsphere: prescriptionDetails?.SPHERE?.right,
      xitem_RXpRvertexFitted: prescriptionDetails?.VERTEXFITTED?.right,

      // LEFT
      xitem_RXpLadd: prescriptionDetails?.ADD?.left,
      xitem_RXpLaxis: prescriptionDetails?.AXIS?.left,
      xitem_RXpLcylinder: prescriptionDetails?.CYLINDER?.left,
      xitem_RXpLdirection: prescriptionDetails?.DIRECTION?.left,
      xitem_RXpLdirectionUd: prescriptionDetails?.DIRECTIONUD?.left,
      xitem_RXpLfaceformTilt: prescriptionDetails?.FACEFORMTILT?.left,
      xitem_RXpLnear: prescriptionDetails?.NEAR?.left,
      xitem_RXpLocht: prescriptionDetails?.OCHT?.left,
      xitem_RXpLpantoTilt: prescriptionDetails?.PANTOTILT?.left,
      xitem_RXpLpd: prescriptionDetails?.PD?.left,
      xitem_RXpLprism: prescriptionDetails?.PRISM?.left,
      xitem_RXpLprismUd: prescriptionDetails?.PRISMUD?.left,
      xitem_RXpLseght: prescriptionDetails?.SEGHT?.left,
      xitem_RXpLsphere: prescriptionDetails?.SPHERE?.left,
      xitem_RXpLvertexFitted: prescriptionDetails?.VERTEXFITTED?.left,
    };

    if (selectList.jobType?.value === "RX_FRAME_TO_COME") {
      const newIncluded = cloneDeep(state.rx.includedItems);
      for (let index = 0; index < newIncluded.length; ++index) {
        if (newIncluded[index] === "ITEMPKG_OTHERS") {
          newIncluded[index] += "---" + state.rx.otherDescription;
        }
      }

      const frameToComeObj = {
        xitem_RXisCustomerFrame: state.rx.customerOwnFrame ? "Y" : "N",
        xitem_RXmadeIn: selectList.madeIn?.value as string,
        xitem_RXlaterality: selectList.laterality?.value as string,
        xitem_RXitemsInPackage: newIncluded.join(";"),
        xitem_RXselectOptName: "MADEIN_NOLENSES",
        xitem_RXselectOptValue: selectList.selectableOpt?.value as string,
      };
      objToSend = { ...objToSend, ...frameToComeObj };
    }
    if (orderItemId) {
      objToSend.xitem_RXfromOrderItemId = orderItemId;
    }

    return {
      orderId: "$",
      orderItem: [objToSend],
    };
  };
};

export default rxSlice.reducer;
