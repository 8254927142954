import React from "react";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";
import { FocalTypePrescription, Eye, EyeError } from "../../store/rx/rxInterface";
import { cylinderIsFilled } from "../../utils/rxUtils";
import Button from "../styled-UI/Button";
import CustomText from "../styled-UI/CustomText";
import Tooltip from "../styled-UI/Tooltip";
import { eyes } from "./Rx/Rx";

interface Props {
  prescriptionDetails: FocalTypePrescription[] | null;
  handleDetailChange?: (value: string, detailIndex: number, isAddition: boolean, eye: Eye) => void;
  readOnly?: boolean;
  readOnlyPlano?: boolean;
  hasCopyButtons?: boolean;
  handleCopyValues?: (eye: Eye) => void;
  hasEyeLabels?: boolean;
  maxWidtInputPx?: number;
  isAdditional?: boolean;
  translateNames?: boolean;
}

const PrescriptionTable = ({
  prescriptionDetails,
  handleDetailChange,
  readOnly = false,
  readOnlyPlano = false,
  hasCopyButtons = false,
  handleCopyValues,
  hasEyeLabels = true,
  maxWidtInputPx,
  isAdditional = false,
  translateNames = false,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  const inputHasError = (presc: FocalTypePrescription, eye: Eye): boolean => {
    return !!presc[`${eye}Error` as EyeError];
  };

  const shoulShowCol = (isAdditionalCol?: boolean) => {
    if (isAdditional) return true;
    return !isAdditionalCol;
  };

  const isPrescRequired = (presc: FocalTypePrescription) => {
    return presc.isRequired ||
      ((cylinderIsFilled(prescriptionDetails).right ||
        cylinderIsFilled(prescriptionDetails).left) &&
        presc.parameterName === "AXIS")
      ? "*"
      : "";
  };

  return (
    <Table>
      {hasEyeLabels && (
        <Column className="column-int" maxWidtInputPx={maxWidtInputPx}>
          <Cell className="empty-cell">
            <CustomText as="p" fontSizePx={13} font="font-bold" marginRightPx={14}>
              {translateLabel("RX_PRESCRIPTION_RE")}
            </CustomText>
          </Cell>
          <Cell className="empty-cell">
            <CustomText as="p" fontSizePx={13} font="font-bold" marginRightPx={14}>
              {translateLabel("RX_PRESCRIPTION_LE")}
            </CustomText>
          </Cell>
        </Column>
      )}
      {prescriptionDetails?.map((presc: FocalTypePrescription, i) => {
        return (
          shoulShowCol(presc.isAdditionalParameter) && (
            <Column key={presc.parameterName} maxWidtInputPx={maxWidtInputPx}>
              <ColumnHeader>
                <CustomText as="p" fontSizePx={11} lineHeightPx={16} font="font-regular">
                  {translateNames
                    ? translateLabel(presc.parameterName)
                    : presc.prescriptionParameterTranslatedName !== ""
                    ? presc.prescriptionParameterTranslatedName
                    : presc.parameterName}
                  {isPrescRequired(presc)}
                </CustomText>
              </ColumnHeader>
              <ColumnContent>
                {eyes.map((eye) => (
                  <Tooltip
                    content={translateLabel(presc[`${eye}Error` as EyeError] || "")}
                    key={eye}
                    canShow={inputHasError(presc, eye)}
                    maxWidth={true}
                  >
                    <Cell>
                      <CellValue
                        tabIndex={eye === "right" ? i + 1 : i + 30}
                        type="text"
                        isReadOnly={readOnly}
                        isReadOnlyPlano={readOnlyPlano}
                        readOnly={readOnly || readOnlyPlano}
                        value={presc[eye]}
                        hasError={inputHasError(presc, eye)}
                        onChange={(e) =>
                          handleDetailChange?.(
                            e.target.value,
                            i,
                            presc.isAdditionalParameter || false,
                            eye
                          )
                        }
                      ></CellValue>
                    </Cell>
                  </Tooltip>
                ))}
              </ColumnContent>
            </Column>
          )
        );
      })}
      {hasCopyButtons && !readOnly && (
        <Column className="column-end" maxWidtInputPx={maxWidtInputPx}>
          <Cell className="empty-cell">
            <Button
              type="primary"
              onClick={() => {
                handleCopyValues?.("right");
              }}
              disabled={readOnlyPlano}
            >
              {translateLabel("RX_COPY_TO_LE")}
            </Button>
          </Cell>
          <Cell className="empty-cell">
            <Button
              type="primary"
              onClick={() => {
                handleCopyValues?.("left");
              }}
              disabled={readOnlyPlano}
            >
              {translateLabel("RX_COPY_TO_RE")}
            </Button>
          </Cell>
        </Column>
      )}
    </Table>
  );
};

const Table = styled.div`
  display: flex;
`;
const ColumnContent = styled.div`
  padding-right: 0.5rem;
`;

interface ColumpProps {
  maxWidtInputPx?: number;
}

const Column = styled.div<ColumpProps>`
  min-width: 3rem;
  width: ${(props) => (props.maxWidtInputPx ? "unset" : "auto")};
  max-width: ${(props) => (props.maxWidtInputPx ? props.maxWidtInputPx / 16 + "rem" : "4.1rem")};
  display: flex;
  flex-direction: column;

  &.column-int {
    margin-top: 6rem;
  }
  &.column-end {
    margin-top: 6rem;
    margin-left: 1rem;
    width: unset;
    max-width: unset;
  }
`;

const ColumnHeader = styled.div`
  max-width: 3.5rem;
  min-height: 5rem;
  margin-bottom: 1rem;
  p {
    align-items: flex-end;
    min-height: 5rem;
  }
`;

const Cell = styled.div`
  width: 100%;
  height: 3.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.empty-cell {
    width: 100%;
    background-color: transparent;
    border: 0;
  }

  .mandatory {
    color: red;
  }

  button {
    flex: 1;
  }
`;

interface CellValueProps {
  hasError: boolean;
  isReadOnly: boolean;
  isReadOnlyPlano: boolean;
}

const CellValue = styled.input<CellValueProps>`
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
  color: ${(props) =>
    props.isReadOnlyPlano ? props.theme.palette.gray.dark : props.theme.palette.primary};
  font-size: 0.8125rem;
  font-family: "Gilmer Medium";
  border: solid 1px
    ${(props) =>
      props.hasError ? props.theme.palette.feedback.error : props.theme.palette.gray.medium};
  border-radius: 0.25rem;
  background-color: ${(props) =>
    props.isReadOnly || props.isReadOnlyPlano
      ? props.theme.palette.gray.light
      : props.theme.palette.gray.white};

  &.input-read-only {
    cursor: auto;
  }
`;

export default PrescriptionTable;
