import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import theme from "../../../../../assets/theme/theme";
import { MostUsedChip, MostUsedChipLocal } from "../../../../../interfaces/mostUsedInterfaces";
import { selectGetMenuStatus } from "../../../../../store/store/storeSlice";
import Chip from "../../../../styled-UI/Chip";
import CustomText from "../../../../styled-UI/CustomText";
import { LoaderMostUsed } from "../../../../styled-UI/loader/most-used/LoaderCategoriesAndServices";
import { ChipsContainer } from "../Services&MyAccount/ChipsServicesMyAccount";
import { ChipsHandler } from "../useSelectedMostUsed";

interface Props {
  chipsHandler: ChipsHandler;
  isMaxChipsReached: boolean;
  isLoading: boolean;
  chipsToShow: MostUsedChipLocal[];
  title?: string;
}

const MostUsedBrandsCatalogue = ({
  chipsHandler,
  isMaxChipsReached,
  isLoading,
  chipsToShow,
  title,
}: Props): JSX.Element => {
  const menuStatus = useSelector(selectGetMenuStatus);
  const showLoader = menuStatus === "LOADING" || isLoading;

  const isChipNotSelected = (x: MostUsedChip) =>
    !chipsHandler.selectedChips.find((y) => y.name === x.name);

  const handleChipClick = (chip: MostUsedChip) => {
    isChipNotSelected(chip) && !isMaxChipsReached && chipsHandler.selectChip(chip);
    !isChipNotSelected(chip) && chipsHandler.deselectChip(chip);
  };

  if (!showLoader && chipsToShow?.length === 0) return <></>;
  return (
    <ChipsCatalogueContainer>
      {showLoader ? (
        <ChipsContainer>
          <LoaderMostUsed />
        </ChipsContainer>
      ) : (
        <>
          {chipsToShow.length !== 0 && (
            <Wrapper>
              <CustomText
                as="span"
                font="font-bold"
                fontSizePx={18}
                marginBottomPx={8}
                lineHeightPx={32}
                uppercase
              >
                {title}
              </CustomText>
              <BrandsChipsContainer>
                {chipsToShow.map((chip, i) => (
                  <ChipContainer key={i}>
                    <Chip
                      text={chip.label}
                      letterCase="capitalize"
                      active={!isChipNotSelected(chip)}
                      onClick={() => handleChipClick(chip)}
                      showTitle
                      style={isChipNotSelected(chip) ? "border" : "blue-on-selected"}
                      borderRadiusPx={4}
                      widthPx={200}
                      heightPx={48}
                      paddingLeftPx={16}
                      paddingRightPx={16}
                      gapPx={16}
                      justifyContent="center"
                      fontSize={12}
                      lineHeightPx={18}
                      letterSpacingPx={0.2}
                      fontWeight={isChipNotSelected(chip) ? 400 : 700}
                      showFileIcon={!isChipNotSelected(chip)}
                      typeIcon="checkmark"
                      hideIcon
                      iconWithoutMargin
                      widthText={156}
                      spacedContent
                      border={`1px solid ${theme.palette.chip.blue}`}
                      noHover
                    />
                  </ChipContainer>
                ))}
              </BrandsChipsContainer>
            </Wrapper>
          )}
        </>
      )}
    </ChipsCatalogueContainer>
  );
};

const ChipsCatalogueContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;

const Wrapper = styled.div``;

const ChipContainer = styled.div`
  display: inline-block;
`;

const BrandsChipsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 0fr);
  gap: 1rem;

  @media ${(props) => props.theme.queries.md} {
    grid-template-columns: repeat(4, 0fr);
  }

  @media ${(props) => props.theme.queries.sm} {
    grid-template-columns: repeat(3, 0fr);
  }
`;

export default MostUsedBrandsCatalogue;
