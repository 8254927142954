import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import useCanRender from "../../../../hooks/useCanRender";
import { getPagePath } from "../../../../routing/routesUtils";
import {
  CartInfo,
  OrderItemSelected,
  OrderMultidoorInfo,
  OrderMultidoorSelected,
  OrderMultidoorSummary,
  ShippingAddressSummaryMultidoor,
} from "../../../../store/cart/cartInterfaces";
import {
  saveOrderItemsOnPrivileges,
  selectAddToCartRequestStatus,
  selectCartLoading,
  selectCartSummary,
  selectHaveAFAOrHelmetCat,
  selectIsAFAValidated,
  selectIsRxBrasilValidated,
  selectSelectedItems,
  selectShippingAddressList,
  selectShippingAddressSelected,
} from "../../../../store/cart/cartSlice";
import { selectProductRecommendation } from "../../../../store/catalogue/catalogueSlice";
import { startCheckout } from "../../../../store/checkout/checkoutSagas";
import { externalReferenceTooltip } from "../../../../utils/cmsUtils";
import { scrollTo } from "../../../../utils/utils";
import Button from "../../../styled-UI/Button";
import CustomText from "../../../styled-UI/CustomText";
import LinkButton from "../../../styled-UI/LinkButton";
import Loader from "../../../styled-UI/Loader";
import ConditionalRender from "../../../widgets/ConditionalRender";
import SetDeliveryPopup from "../../../widgets/set-delivery-popup/SetDeliveryPopup";
import FavoritesTooltipContainer from "../../../widgets/tutorial-pills/manage-favorites/FavoritesTooltipContainer";
import CartPriceSummary from "./CartPriceSummary";

interface Props {
  cartInfo: CartInfo;
  isMultidoor?: boolean;
  currencyPrice?: string;
}

const CartSummary = ({ cartInfo, isMultidoor = false, currencyPrice }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const canRender = useCanRender();

  // track checkout attempt to show error if shipping addresses are not selected
  const [showError, setShowError] = useState<boolean>(false);
  const selectedItems = useSelector(selectSelectedItems);
  const cartPriceSummary = useSelector(selectCartSummary); // structured list of info about cart prices
  const shippingAddressMultidoor = useSelector(selectShippingAddressList); // structured list of shipping addresses
  const shippingAddressSelected = useSelector(selectShippingAddressSelected); // selected shipping addresses for each door
  const haveAFAOrHelmetCat = useSelector(selectHaveAFAOrHelmetCat);
  const productRec = useSelector(selectProductRecommendation);

  const cartLoading = useSelector(selectCartLoading);
  const addToPrecartLoading = useSelector(selectAddToCartRequestStatus);
  const checkoutLoading = cartLoading.filter((_) => _.id === "checkout")?.[0] ?? null;

  ///////// DELIVERY POPUP
  const [isOpenDeliveryPopup, setIsOpenDeliveryPopup] = useState<boolean>(false);

  useEffect(() => {
    const itemsBasedOnPrivileges: OrderItemSelected[] = [];
    selectedItems.forEach((orderMultidoor: OrderMultidoorSelected) => {
      orderMultidoor.categorySelected.forEach((_) => {
        _.orderItemSelected.forEach((_) => {
          if (_.isRxItem && canRender("RX_MY_PRICE")) itemsBasedOnPrivileges.push(_);
          if (!_.isRxItem && canRender("WHOLESALE_PRICE")) itemsBasedOnPrivileges.push(_);
        });
      });
    });
    dispatch(
      saveOrderItemsOnPrivileges(
        selectedItems.map((_) => {
          return {
            multidoorId: _.multidoorId,
            subOrderId: _.subOrderId,
            orgentityName: _.orgentityName,
            categorySelected: _.categorySelected.map((_) => {
              return {
                productCategory: _.productCategory,
                productCategoryIdentifier: _.productCategoryIdentifier,
                orderItemSelected: itemsBasedOnPrivileges,
              };
            }),
          };
        })
      )
    );
  }, [selectedItems]);

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// CHECKOUT /////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const isRxBrasilValidated = useSelector(selectIsRxBrasilValidated);
  const isAFAValidated = useSelector(selectIsAFAValidated);

  const handleCheckoutClick = () => {
    const isCheckoutInhibited =
      shippingAddressSelected.length !== cartPriceSummary?.length || // if at least one door doesn't have a selected address
      cartInfo.selectedNumber === 0 || // if no checkboxes are selected
      !isRxBrasilValidated || // if prescriptions haven't been uploaded for all selected items (NOTE privilege check is implicit in selector, returns true if privilege is absent)
      !isAFAValidated;

    if (isCheckoutInhibited) setShowError(true);
    // else start checkout
    else {
      dispatch(
        startCheckout({
          redirect: () => {
            history.push(getPagePath("/cart/order-confirmation/"));
          },
        })
      );
    }
  };

  const renderErrorMessage = () => {
    let message = "";

    if (!isRxBrasilValidated) message = translateLabel("RX_PRESCR_BRASIL_CHECKOUT_BUTTON_ERROR");
    if (cartInfo.selectedNumber === 0) message = translateLabel("CART_SUMMARY_NO_PRODUCT_SELECTED");
    if (!isAFAValidated) message = translateLabel("CART_SUMMARY_AFA_QUANTITY_ERROR");

    return (
      <ErrorMessageContainer>
        <CustomText as="span" fontSizePx={14} font="font-medium" lineHeightPx={24}>
          {message}
        </CustomText>
      </ErrorMessageContainer>
    );
  };

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////// RENDER //////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////
  const disabled = cartLoading.length > 0 || addToPrecartLoading === "LOADING";

  return (
    <>
      <Container data-element-id="Summary">
        <TooltipContainer>
          <CustomText as="span" color="white" fontSizePx={28} font="font-medium" lineHeightPx={28}>
            {translateLabel("CART_SUMMARY_TITLE")}
          </CustomText>
          <FavoritesTooltipContainer
            position={externalReferenceTooltip["CART_SUMMARY"]}
            isFlipped
          />
        </TooltipContainer>
        <div>
          {shippingAddressMultidoor.length !== 0 &&
            cartPriceSummary?.map((orderMultidoorSummary: OrderMultidoorSummary) => (
              <CartPriceSummary
                key={orderMultidoorSummary.multidoorId}
                orderMultidoorSummary={orderMultidoorSummary}
                orderMultidoorInfo={
                  cartInfo.multidoorSelected.filter(
                    (_: OrderMultidoorInfo) => _.multidoorId == orderMultidoorSummary.multidoorId
                  )[0]
                }
                shippingAddressMultidoor={
                  shippingAddressMultidoor.filter(
                    (_: ShippingAddressSummaryMultidoor) =>
                      _.multidoorId === orderMultidoorSummary.multidoorId
                  )[0]
                }
                isMultidoor={isMultidoor}
                showError={showError}
                currencyPrice={currencyPrice}
              />
            ))}
        </div>

        <div>
          <ButtonContainer>
            <Button
              type="secondary"
              isLoading={checkoutLoading !== null}
              disabled={disabled}
              onClick={handleCheckoutClick}
            >
              {translateLabel("CART_SUMMARY_CHECKOUT_BUTTON")}
              {disabled && <Loader sizePx={15} styleLoader={"dark-background"} />}
            </Button>
          </ButtonContainer>
        </div>
      </Container>

      {showError && renderErrorMessage()}

      <ConditionalRender privilege="CANCELLATION_DATE">
        <>
          <DeliveryContainer>
            <Button
              type="primary"
              fullWidth
              onClick={() => setIsOpenDeliveryPopup(true)}
              disabled={!haveAFAOrHelmetCat}
            >
              {translateLabel("CART_SET_CANCELLATION")}
            </Button>
          </DeliveryContainer>

          <SetDeliveryPopup
            isPopupOpen={isOpenDeliveryPopup}
            closePopup={() => setIsOpenDeliveryPopup(false)}
          />
        </>
      </ConditionalRender>

      {productRec && (
        <RecommendedContainer>
          <TooltipContainer>
            <LinkButton
              color="primary"
              onClick={() => scrollTo("carousel-for-you")}
              uppercase
              data-element-id="AlsoLikeToAdd"
            >
              {translateLabel("CART_SUMMARY_GO_TO_CAROUSEL_BUTTON")}
            </LinkButton>
            <FavoritesTooltipContainer
              position={externalReferenceTooltip["CART_SUMMARY_COMPLETE_CART"]}
              isFlipped
            />
          </TooltipContainer>
        </RecommendedContainer>
      )}
    </>
  );
};

const Container = styled.div`
  padding: 2rem;
  background-color: ${(props) => props.theme.palette.primary};

  @media ${(props) => props.theme.queries.md} {
    padding: 1rem;
  }
`;

const ButtonContainer = styled.div`
  button {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;

    &:hover {
      border: 2px solid white;
    }
  }
`;

const ErrorMessageContainer = styled.div`
  margin-top: 1rem;

  & > span {
    font-family: ${(props) => props.theme.fonts.fontRegular};
    color: ${(props) => props.theme.palette.feedback.error};
  }
`;

const DeliveryContainer = styled.div`
  margin-top: 1rem;
`;

const RecommendedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.5rem;
`;

const TooltipContainer = styled.div`
  display: flex;
  column-gap: 1.5rem;
`;

export default CartSummary;
