import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import { getMostUsedContent, updateMostUsedChips } from "../../../../store/user/userSagas";
import { selectMostUsedContent, selectMostUsedLoading } from "../../../../store/user/userSlice";
import Button from "../../../styled-UI/Button";
import CustomText from "../../../styled-UI/CustomText";
import Modal from "../../../styled-UI/Modal";
import MostUsedCatalogue from "./Catalogue/MostUsedCatalogue";
import MostUsedServiceAccount from "./Services&MyAccount/ChipsServicesMyAccount";
import useSelectedMostUsed from "./useSelectedMostUsed";

import { isEqual } from "lodash";
import { useBreakpoint } from "../../../../assets/theme/breakpoint";
import { FirstLevelMenu } from "../../../../interfaces/menuInterfaces";
import { MostUsedChipLocal } from "../../../../interfaces/mostUsedInterfaces";
import { selectMenu } from "../../../../store/store/storeSlice";
import { getMostUsedBrandsFromMenu } from "../../../../utils/mostUsedUtils";
import MostUsedBrandsCatalogue from "./Catalogue/MostUsedBrandsCatalogue";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const MOST_USED_MODAL_WIDTH = {
  md: "50rem",
  lg: "62rem",
  custom: "77.5rem",
};

const CustomizeHomeModal = ({ isOpen, close }: Props): JSX.Element => {
  const mostUsedData = useSelector(selectMostUsedContent);
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const menuData = useSelector(selectMenu);
  const [tempMenuData, setTempMenuData] = useState<FirstLevelMenu[]>();
  const [availableBrandsChips, setAvailableBrandsChips] = useState<{
    brands: MostUsedChipLocal[];
    brandsEssilor: MostUsedChipLocal[];
  }>();

  ///////////////// modal size
  const breakpoints = useBreakpoint();
  const modalWidth = breakpoints["md"]
    ? MOST_USED_MODAL_WIDTH["md"]
    : MOST_USED_MODAL_WIDTH["custom"];

  ///////////////// most used data
  const chipsHandler = useSelectedMostUsed();
  const MAX_SELECTED_CHIPS = 5;

  const isMaxChipsReached = chipsHandler.selectedChips.length > MAX_SELECTED_CHIPS;
  const isNoChipsSelected = chipsHandler.selectedChips.length === 0;

  const mostUsedLoading = useSelector(selectMostUsedLoading);
  const isLoading = mostUsedLoading.get === "LOADING";

  useEffect(() => {
    mostUsedData.bookmarks.length == 0 &&
      mostUsedData.unused === false &&
      dispatch(getMostUsedContent());
  }, []);

  useEffect(() => {
    menuData &&
      !isEqual(tempMenuData, menuData) &&
      setAvailableBrandsChips(getMostUsedBrandsFromMenu(menuData, translateLabel));
    setTempMenuData(menuData);
  }, [menuData]);
  ///////////////// confirmation / error handling

  const [error, setError] = useState<string>("");

  const handleConfirm = () => {
    if (isNoChipsSelected) {
      // show error: "Select at least one preference to confirm"
      setError("MOST_USED_POPUP_SELECTION_NO_PREFERENCES");
      setTimeout(() => setError(""), 3000);
    } else {
      // save most used
      dispatch(
        updateMostUsedChips({
          bookmarks: chipsHandler.selectedChips.filter((_) => !_.showAsDefault),
        })
      );
      close();
    }
  };

  ///////////////// render

  const renderSelectionLabel = () => {
    const selectionLabel = isNoChipsSelected
      ? translateLabel("MOST_USED_POPUP_SELECTION_NO_PREFERENCES")
      : translateLabel("MOST_USED_POPUP_SELECTION");

    return (
      <SelectionSection>
        <SelectionText isMaxChipsReached>
          <CustomText
            as="span"
            font="font-bold"
            fontSizePx={14}
            lineHeightPx={18}
            fontWeightNum={600}
          >
            {selectionLabel}
          </CustomText>
          {!isNoChipsSelected && (
            <CustomText
              as="span"
              font="font-bold"
              fontSizePx={14}
              lineHeightPx={18}
              fontWeightNum={600}
            >
              {`${chipsHandler.selectedChips.length} / ${MAX_SELECTED_CHIPS}`}
            </CustomText>
          )}
        </SelectionText>
        {isMaxChipsReached && (
          <CustomText
            color="red"
            as="span"
            fontSizePx={14}
            lineHeightPx={18}
            fontWeightNum={600}
            font="font-regular"
          >
            {translateLabel("MOST_USED_POPUP_SELECTION_LIMIT_REACHED")}
          </CustomText>
        )}
      </SelectionSection>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title={translateLabel("MOST_USED_POPUP_TITLE")}
      isCentered={true}
      padding="2rem 2rem 0 2rem"
    >
      <ModalContent width={modalWidth}>
        <CustomText
          as="span"
          fontSizePx={14}
          lineHeightPx={24}
          fontWeightNum={400}
          font="font-regular"
        >
          {translateLabel("MOST_USED_POPUP_SUBTITLE")}
        </CustomText>

        <MostUsedCatalogue
          chipsHandler={chipsHandler}
          isMaxChipsReached={isMaxChipsReached}
          isLoading={isLoading}
        />

        {availableBrandsChips?.brands && (
          <MostUsedBrandsCatalogue
            chipsHandler={chipsHandler}
            isMaxChipsReached={isMaxChipsReached}
            isLoading={isLoading}
            chipsToShow={availableBrandsChips.brands}
            title={translateLabel("MOST_USED_POPUP_BRANDS")}
          />
        )}
        {availableBrandsChips?.brandsEssilor && (
          <MostUsedBrandsCatalogue
            chipsHandler={chipsHandler}
            isMaxChipsReached={isMaxChipsReached}
            isLoading={isLoading}
            chipsToShow={availableBrandsChips.brandsEssilor}
            title={translateLabel("MOST_USED_POPUP_BRANDS_ESSILOR")}
          />
        )}

        <MostUsedServiceAccount
          chipsHandler={chipsHandler}
          isMaxChipsReached={isMaxChipsReached}
          isLoading={isLoading}
          type="mostUsedService"
        />
        <MostUsedServiceAccount
          chipsHandler={chipsHandler}
          isMaxChipsReached={isMaxChipsReached}
          isLoading={isLoading}
          type="mostUsedAccount"
        />
      </ModalContent>
      <ButtonContainer>
        <TextContainer>
          <CustomText
            uppercase
            as="span"
            font="font-bold"
            fontSizePx={14}
            fontWeight="600"
            lineHeightPx={18}
          >
            {renderSelectionLabel()}
          </CustomText>
        </TextContainer>
        <Button
          type="primary"
          onClick={handleConfirm}
          disabled={isLoading || isMaxChipsReached}
          data-element-id="MostUsed_Settings_Panel"
          width="180px"
        >
          {translateLabel("MOST_USED_POPUP_BUTTON_CONFIRM")}
        </Button>
        {error && (
          <CustomText color="red" as="span" fontSizePx={14} font="font-regular">
            {translateLabel(error)}
          </CustomText>
        )}
      </ButtonContainer>
    </Modal>
  );
};

const ModalContent = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 12rem);
  min-width: ${(props) => props.width};
  overflow: auto;
  gap: 2rem;
  max-height: calc(100vh - 20rem);
`;

const SelectionSection = styled.div``;
const SelectionText = styled.div<{ isMaxChipsReached?: boolean }>`
  display: flex;
  gap: 1rem;
  ${(props) => props?.isMaxChipsReached && `margin-bottom: 0.313rem`};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 5.5rem;
  justify-content: space-between;
  box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
  margin: 0 -2rem;
  padding: 0 2rem;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export default CustomizeHomeModal;
