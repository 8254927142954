interface SchemaValues {
  check: (value: string | undefined) => boolean;
  label: string;
}

interface CustomSchema {
  required: SchemaValues;
  valid: SchemaValues;
}

type FormValues = keyof typeof CUSTOM_SCHEMA;

const CUSTOM_SCHEMA: { [key: string]: CustomSchema } = {
  website: {
    required: {
      check: (value) => !!value,
      label: "REQUIRED_FIELD",
    },
    valid: {
      check: (value) => !!value,
      label: "REQUIRED_FIELD",
    },
  },
};

export const isWebsiteInvalid = (type: FormValues, value: string | undefined): string => {
  if (!CUSTOM_SCHEMA[type].required.check(value)) return CUSTOM_SCHEMA[type].required.label;
  if (!CUSTOM_SCHEMA[type].valid.check(value)) return CUSTOM_SCHEMA[type].valid.label;
  return "";
};

export const checkIsWebsiteValid = (webSiteURL: string, noWebsite: boolean): boolean => {
  return !isWebsiteInvalid("website", webSiteURL) || noWebsite;
};
