import { toUpper } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import useCanRender from "../../../../../../hooks/useCanRender";
import useTranslation from "../../../../../../hooks/useTranslation";
import { getPagePath } from "../../../../../../routing/routesUtils";
import {
  LensItemEssilor,
  LensesColumnEssilor as LensesColumnEssilorInterface,
} from "../../../../../../store/store/storeInterfaces";
import { selectBrandGroups } from "../../../../../../store/store/storeSlice";
import { externalReferenceNewTag } from "../../../../../../utils/cmsUtils";
import CustomText from "../../../../../styled-UI/CustomText";
import NewTag from "../../../../../widgets/tutorial-pills/new-tag/NewTag";
import { HeaderListContainer } from "./MenuColumn";

interface Props {
  lensesMenu: LensesColumnEssilorInterface;
  identifier: string;
  tileName: string;
  hasEssilorBrands?: boolean;
  setToggleMenu: (identifier: string) => void;
}

const LensesColumnEssilor = ({
  lensesMenu,
  identifier,
  tileName,
  hasEssilorBrands = false,
  setToggleMenu,
}: Props): JSX.Element => {
  const canRender = useCanRender();
  const { translateLabel } = useTranslation();
  const brandGroups = useSelector(selectBrandGroups);

  const manageLinkString = (item: LensItemEssilor) => {
    const isThereBrands = item.additionalInfo ? item.additionalInfo?.length > 0 : false;
    let redirectionURL = "";

    if (lensesMenu.identifier === "ORDER_NOW") {
      redirectionURL = item.linkString || "";
      return getPagePath(redirectionURL);
    }

    if (lensesMenu.identifier === "AUTHENTICS_BRANDS") {
      redirectionURL =
        item?.linkString ||
        `/rx-prescription?rxFlow=Authentics${
          isThereBrands ? `&brands=${item.additionalInfo?.[0].value}` : "" //Authentics flow only works with one-brand filter
        }`;
    } else {
      if (item?.type?.includes("STORYTELLING")) {
        redirectionURL = item?.linkString || `/contents-corporate/${item.name}`;
      } else if (item?.type?.includes("LENS_CONFIGURATOR")) {
        redirectionURL =
          `/rx-prescription?rxFlow=${
            canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY") ? `Essilor` : `Authentics`
          }` + isThereBrands
            ? `&brands=${item.additionalInfo?.map((info) => info.value).join(",")}`
            : "";
      } else if (item?.type?.includes("DIGITAL_CATALOGUE")) {
        if (item?.linkString) redirectionURL = item.linkString;
        else
          redirectionURL = isThereBrands
            ? `/digital-catalog?brands=${item.additionalInfo?.map((info) => info.value).join(",")}`
            : "/digital-catalog?";
      } else if (item?.type?.includes("SAVED_ORDERS")) {
        redirectionURL = "/essilor-draft-orders";
      }
    }

    //NEL CASO IN CUI NON FUNZIONI IL TYPE (non rientra in nessuno di quei casi e quindi l'url rimane vuoto) -> redirect di default
    if (redirectionURL === "") redirectionURL = "/rx-prescription";
    return getPagePath(redirectionURL);
  };

  const canShowItem = (item: LensItemEssilor) => {
    if (lensesMenu.identifier === "AUTHENTICS_BRANDS") {
      const brandIds = brandGroups[item.id].map((brand) => brand.brand);
      const canRenderBrand = brandIds.some(
        (brandId) =>
          canRender("RX_COMPLETE_JOB_" + brandId) ||
          canRender("RX_FRAME_TO_COME_" + brandId) ||
          canRender("RX_LENS_ONLY_" + brandId)
      );
      return canRenderBrand;
    }
    if (lensesMenu.identifier === "ORDER_NOW") {
      if (item.id === "LENS_CONFIGURATOR") {
        return canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY") || canRender("RX");
      } else if (item.id === "DIGITAL_CATALOGUE") {
        return canRender("LENS_DIGITAL_CATALOGUE");
      } else if (item.id === "SAVED_ORDERS") {
        return canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY");
      } else {
        return canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY") || canRender("RX");
      }
    }
    return true;
  };

  return (
    <Container isAuthentics={lensesMenu.identifier === "AUTHENTICS_BRANDS" && hasEssilorBrands}>
      {lensesMenu && (
        <UrlList>
          <HeaderListContainer>
            {(lensesMenu.identifier !== "AUTHENTICS_BRANDS" ||
              (lensesMenu.identifier === "AUTHENTICS_BRANDS" &&
                (!hasEssilorBrands || !canRender("RX_ESSILOR")))) && (
              <>
                <CustomText
                  fontSizePx={18}
                  as="span"
                  marginBottomPx={20}
                  data-element-id="LensesEssilor"
                >
                  {translateLabel("LENS_SUBCATEGORY_TITLE_" + toUpper(lensesMenu.name))}
                </CustomText>
                <NewTag
                  position={externalReferenceNewTag[`${identifier}.${lensesMenu.identifier}`]}
                ></NewTag>
              </>
            )}
          </HeaderListContainer>
          <ListItemsContainer>
            {lensesMenu.items.map((item, index: number) => {
              return (
                canShowItem(item) &&
                item.type && (
                  <React.Fragment key={item.id + identifier}>
                    <div>
                      <li
                        key={item.id + identifier}
                        onClick={() => {
                          setToggleMenu("");
                        }}
                      >
                        <Link
                          to={manageLinkString(item)}
                          data-element-id={`MainNav_Products_${tileName}_${item.id}`}
                        >
                          <CustomText
                            fontSizePx={14}
                            as="span"
                            marginBottomPx={20}
                            font="font-regular"
                            lineHeightPx={17}
                          >
                            {translateLabel("LENS_OPTION_" + toUpper(item.id))}
                          </CustomText>
                        </Link>
                      </li>
                    </div>
                  </React.Fragment>
                )
              );
            })}
          </ListItemsContainer>
        </UrlList>
      )}
    </Container>
  );
};

const Container = styled.div<{ isAuthentics: boolean }>`
  //padding: 1.875rem 0.5rem 0rem;
  //margin-left: ${(props) => props.isAuthentics && "-56px"};
`;

const UrlList = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 2.75rem 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem;
  min-width: 10rem;
  padding: 1.875rem 0rem 0rem;

  li {
    min-width: 10rem;
    /*max-width: 14rem;*/
  }
`;

const ListItemsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 2rem 2rem 2rem 2rem 2rem 2rem;
  column-gap: 1.5rem;

  @media ${(props) => props.theme.queries.minLaptop} {
    grid-template-rows: repeat(6, min-content);
  }
  @media (max-width: 1144px) {
    column-gap: 1rem;
  }
  div span {
    /*max-width: 14rem;*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media ${(props) => props.theme.queries.minLaptop} {
      white-space: normal;
    }
  }

  span {
    &:hover {
      font-weight: bold;
    }
  }
`;

export default LensesColumnEssilor;
