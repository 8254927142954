import { checkIfIsObject } from "./utils";

export class LocalStorageUtils {
  private PREFIX_STORE = "MYL";
  private STATE = "USER";

  get = (key: string): any => {
    let parsedValue;

    try {
      const value = localStorage.getItem(`${this.PREFIX_STORE}-${key.toUpperCase()}`);
      if (value) parsedValue = JSON.parse(value);
    } catch (e) {
      console.warn(e);
    }
    return parsedValue;
  };

  getState = (): any => {
    return this.get(this.STATE);
  };

  saveState = (value: any): void => {
    this.set(this.STATE, value);
  };

  set = (key: string, value: any): void => {
    const currentKey = `${this.PREFIX_STORE}-${key.toUpperCase()}`;

    try {
      localStorage.setItem(currentKey, JSON.stringify(value));
    } catch (e) {
      console.warn(e);
    }
  };

  remove = (key: string): void => {
    const currentKey = `${this.PREFIX_STORE}-${key.toUpperCase()}`;

    try {
      localStorage.removeItem(currentKey);
    } catch (e) {
      console.warn(e);
    }
  };

  clear = (): any => {
    return this.clear();
  };
}

/**
 * Check if object in local storage is in the correct format or not
 *
 * @param {*} newState
 * @return {*}  {boolean}
 */
export const validateLoadedState = (newState: any): boolean => {
  let stateIsSafe = false;

  if (newState && newState.user) {
    const user = newState.user;
    if (
      checkIfIsObject(user) &&
      Object.keys(user).length > 0 &&
      Object.keys(user).length === 5 //to update every time you add or remove something
    ) {
      const { isLogged, storeIdentifier, languageId, username } = user;
      if (
        isLogged !== undefined &&
        typeof isLogged == "boolean" &&
        storeIdentifier !== undefined &&
        typeof storeIdentifier == "string" &&
        username !== undefined &&
        typeof username == "string" &&
        languageId !== undefined &&
        (languageId == null || typeof languageId == "number")
      ) {
        stateIsSafe = true;
      }
    }
  }

  return stateIsSafe;
};

// use local storage for messaging. Set message in local storage and clear it right away
// This is a safe way how to communicate with other tabs while not leaving any traces
//
export function broadcastMessage(message: string): void {
  localStorage.setItem("message", message);
  localStorage.removeItem("message");
}

// receive message
// export function receiveMessage(ev: any): void {
//   if (ev) {
//     if (ev?.key != "message") return; // ignore other keys
//     const message = JSON.parse(ev?.newValue);
//     if (!message) return; // ignore empty msg or msg reset

//     console.log("message", ev?.newValue);
//     // here you act on messages.
//     // you can send objects like { 'command': 'doit', 'data': 'abcd' }
//     // if (message?.command == "logout") alert(message?.data);

//     // etc.
//   }
// }
