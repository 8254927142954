import React from "react";
import styled from "styled-components/macro";
import LoaderCart from "../cart/LoaderCart";
import LoaderSummary from "../cart/LoaderSummary";

const LoaderPageCart = (): JSX.Element => (
  <Row>
    <MainColumn>
      <LoaderCart />
      <LoaderCart />
    </MainColumn>
    <SummaryColumn>
      <LoaderSummary />
    </SummaryColumn>
  </Row>
);

const Row = styled.div`
  display: flex;
`;

const MainColumn = styled.div`
  flex-grow: 1;
  margin-right: 1.8125rem;
`;

const SummaryColumn = styled.div`
  width: 100%;
  max-width: 20rem;
  flex-shrink: 0;
`;

export default LoaderPageCart;
