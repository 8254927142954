import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { select, call, put, take, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/types";

import { handleError } from "../store/storeSagas";
import searchService from "./searchService";
import {
  sliceName,
  saveSuggestions,
  saveSearchResult,
  saveSearchCategories,
  setLoadingSearch,
  DEFAULT_SEARCH_RESULTS,
  saveSearchFacetView,
  saveSearchedTerm,
  selectSearchedTerm,
} from "./searchSlice";
import {
  SearchParams,
  SuggestionParams,
  SuggestionService,
  SearchService,
  QueryParams,
  SearchCategories,
  SearchResults,
} from "./searchInterfaces";
import {
  selectHasPricePrivilege,
  selectMultidoorHasPricePrivilege,
  selectPageSize,
} from "../catalogue/catalogueSlice";
import { Product } from "../../interfaces/productInterface";
import { addPricesToSearchResults, mapSearchResults } from "../../utils/searchUtils";
import catalogueService from "../catalogue/catalogueService";
import { mapPriceResponse } from "../../utils/catalogueUtils";
import { setLoadingAnalyticsData } from "../analytics/analyticsSlice";
import { checkIsAFAOrHelmet } from "../../utils/AFAutils";
import { getPLPInstagramBadges, getProductAvailability } from "../catalogue/catalogueSaga";
import { FacetView } from "../../interfaces/facetInterfaces";
import { selectIsMultidoor } from "../user/userSlice";

/* ACTIONS */
export const getSuggestions = createAction<SuggestionParams>(sliceName + "/getSuggestion");
export const getSearchResult = createAction<SearchParams>(sliceName + "/getSearchResult");
export const getSearchCategories = createAction(sliceName + "/getSearchCategories");

/* SAGAS */
/**
 * Get suggestions to autocomplete the typed term on search
 * @param  {PayloadAction<string>} action term to get suggested
 */
function* getSuggestionsSaga(action: PayloadAction<SuggestionParams>): SagaIterator {
  const qparams: SuggestionService = {
    searchParams: {
      limit: "6",
      catalogType: "active",
    },
    term: action.payload.term,
    filters: action.payload.filters,
  };
  try {
    const { data } = yield call(searchService.getSuggestions, qparams);
    yield put(saveSuggestions(data.data.suggestionView[0].entry));
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get results for a searched terms passed as params
 * @param  {PayloadAction<string>} action tearm to get searched
 */
function* getSearchResultSaga(action: PayloadAction<SearchParams>): SagaIterator {
  try {
    yield put(setLoadingSearch({ type: "catalogue", value: "LOADING" }));
    yield put(setLoadingSearch({ type: "facets", value: "LOADING" }));

    yield put(setLoadingAnalyticsData(true));
    yield put(saveSearchResult(DEFAULT_SEARCH_RESULTS));
    const currentSearchedTerm = yield select(selectSearchedTerm);
    if (currentSearchedTerm !== action.payload.term) {
      yield put(saveSearchFacetView(null));
      yield put(saveSearchedTerm(action.payload.term));
    }

    const pageSize = yield select(selectPageSize);

    const params: QueryParams = action.payload.params;
    const filters: QueryParams = {};
    let pageNumber = 1;
    let orderBy;

    Object.keys(params).forEach((param) => {
      if (param === "pageNumber") {
        pageNumber = Number(params.pageNumber[0]);
        return;
      }
      if (param === "orderBy") {
        orderBy = params.orderBy[0];
        return;
      }
      filters[param] = params[param];
    });

    const qparams: SearchService = {
      searchParams: {
        pageSize,
        pageNumber,
        orderBy: orderBy ?? null,
      },
      term: action.payload.term,
      filters,
    };

    ////////////////////////////////////// get search results

    const { data } = yield call(searchService.getSearchResult, qparams);

    const mappedData: { catalogue: SearchResults; facetView: FacetView[] } | undefined =
      data?.data && mapSearchResults(data.data);

    // if present, save results (and stop loading)
    if (mappedData?.catalogue?.resultList && mappedData?.catalogue?.resultList?.length > 0) {
      yield put(saveSearchResult(mappedData?.catalogue));
      yield put(setLoadingSearch({ type: "catalogue", value: "SUCCESS" }));

      yield put(saveSearchFacetView(mappedData?.facetView ?? null));
      yield put(
        setLoadingSearch({
          type: "facets",
          value: mappedData?.facetView?.length > 0 ? "SUCCESS" : "ERROR",
        })
      );

      const productIds: string[] = []; // get ids
      mappedData?.catalogue.resultList.forEach((result: Product) => {
        result.uniqueID && productIds.push(result.uniqueID);
      });

      ////////////////////////////////////// get prices
      try {
        const isMultidoor = yield select(selectIsMultidoor);
        let hasPricePrivilege = false;
        if (isMultidoor) {
          hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
        } else {
          hasPricePrivilege = yield select(selectHasPricePrivilege);
        }

        if (hasPricePrivilege && productIds.length > 0) {
          if (productIds.length > 0) {
            const { data: price } = yield call(catalogueService.getPriceService, productIds);
            const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array

            const newResults = addPricesToSearchResults(mappedData?.catalogue, priceData); // add prices to current search results
            yield put(saveSearchResult(newResults)); // save updated results
          }
        }
      } catch (error) {
        yield put(handleError(error));
      }

      ////////////////////////////////////// get availability for AFA or helmet
      if (mappedData?.catalogue?.resultList?.length > 0) {
        const productCategory = mappedData?.catalogue?.resultList?.[0].productCategory;

        if (productCategory && checkIsAFAOrHelmet(productCategory)) {
          const idsForAFA: string[] = []; // get ids
          mappedData?.catalogue?.resultList?.forEach((result: any) => {
            result?.skuUniqueID && idsForAFA.push(result.skuUniqueID);
          });
          yield put(getProductAvailability({ ids: idsForAFA }));
        }
      }

      ///////////////////////////////////// get Instagram badges
      const parNumbers: string[] = [];
      mappedData?.catalogue.resultList?.forEach((_: any) => {
        _?.productCode && parNumbers.push(_.productCode);
      });
      yield put(getPLPInstagramBadges({ partNumbers: parNumbers }));
    } else {
      yield put(saveSearchResult(DEFAULT_SEARCH_RESULTS)); // save empty catalogue
      yield put(saveSearchFacetView(null));
      yield put(setLoadingSearch({ type: "catalogue", value: "ERROR" }));
      yield put(setLoadingSearch({ type: "facets", value: "ERROR" }));
    }

    yield put(setLoadingAnalyticsData(false));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveSearchResult(DEFAULT_SEARCH_RESULTS)); // save empty catalogue
    yield put(saveSearchFacetView(null));
    yield put(setLoadingSearch({ type: "catalogue", value: "ERROR" }));
    yield put(setLoadingSearch({ type: "facets", value: "ERROR" }));
  }
}

/**
 * Get categories to display in dropdown next to search bar
 *
 * @return {*}  {SagaIterator}
 */
function* getSearchCategoriesSaga(): SagaIterator {
  try {
    const { data } = yield call(searchService.getSearchCategories);

    const filterKey: string | undefined = data?.data?.facetView?.[0]?.extendedData?.propertyvalue;

    const searchCategories: SearchCategories[] = data?.data?.facetView?.[0].entry.map((_: any) => {
      return {
        label: _.label,
        value: _.identifier,
        filterKey,
      };
    });

    if (searchCategories) yield put(saveSearchCategories(searchCategories));
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* searchSaga(): SagaIterator {
  yield takeLatest(getSuggestions.type, getSuggestionsSaga);
  yield takeLatest(getSearchResult.type, getSearchResultSaga);
  yield takeLatest(getSearchCategories.type, getSearchCategoriesSaga);
}
