import React from "react";
import styled from "styled-components/macro";
import WrapperLayout from "../../layouts/WrapperLayout";

interface Props {
  firstColumn: JSX.Element;
  secondColumn: JSX.Element;
  marginPx?: number | null;
  landingNavbar?: boolean;
  isWrappedLanding?: boolean;
}

const TwoColumnRow = ({
  firstColumn,
  secondColumn,
  marginPx,
  landingNavbar = false,
  isWrappedLanding = false,
}: Props): JSX.Element => {
  return (
    <WrapperLayout landingNavbar={landingNavbar} isWrappedLanding={isWrappedLanding}>
      <TwoColumn marginPx={marginPx}>
        <div>{firstColumn}</div>
        <div>{secondColumn}</div>
      </TwoColumn>
    </WrapperLayout>
  );
};

const TwoColumn = styled.div<{ marginPx?: number | null }>`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 0.625rem));
  grid-column-gap: 0.938rem;
  margin: 2rem 0;

  //override from props
  margin-top: ${(props) => props.marginPx && `${props.marginPx / props.theme.baseFontSize}rem`};
  margin-bottom: ${(props) => props.marginPx && `${props.marginPx / props.theme.baseFontSize}rem`};
`;

export default TwoColumnRow;
