import createSagaMiddleware from "redux-saga";
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { throttle } from "lodash";

import userReducer from "./user/userSlice";
import storeReducer from "./store/storeSlice";
import cartReducer from "./cart/cartSlice";
import searchReducer from "./search/searchSlice";
import catalogueReducer from "./catalogue/catalogueSlice";
import checkoutReducer from "./checkout/checkoutSlice";
import aftersalesReducer from "./aftersales/aftersalesSlice";
import accountingReducer from "./accounting/accountingSlice";
import documentsReducer from "./documents/documentsSlice";
import subuserReducer from "./subuser/subuserSlice";
import profileReducer from "./profile/profileSlice";
import rxReducer from "./rx/rxSlice";
import orderHistoryReducer from "./order-history/orderHistorySlice";
import ordersToApproveReducer from "./orders-to-approve/ordersToApproveSlice";
import servicesReducer from "./services/servicesSlice";
import multidoorReducer from "./multidoor/multidoorSlice";
import analyticsReducer from "./analytics/analyticsSlice";
import warrantyWizardReducer from "./warranty-wizard/warrantyWizardSlice";
import leonardoReducer from "./leonardo/leonardoSlice";
import smartshopperReducer from "./smartshopper/smartshopperSlice";
import serviceRequestReducer from "./serviceRequests/serviceRequestSlice";
import orderUploadReducer from "./order-upload/orderUploadSlice";
import notificationsReducer from "./messages/messagesSlice";
import paymentReducer from "./payment/paymentSlice";
import messagesReducer from "./messages/messagesSlice";
import adtReducer from "./adt/adtSlice";

import { initialState } from "./user/userSlice";
import { showError } from "./store/storeSlice";
import rootSaga from "./rootSaga";
import { SagaError } from "./store/storeInterfaces";
import { validateLoadedState, LocalStorageUtils } from "../utils/storageUtils";

const localStorageUtils = new LocalStorageUtils();
const sagaMiddleware = createSagaMiddleware({
  onError: (err: SagaError) => {
    console.log({
      message: err.message,
      source: "sagaError",
      stacktrace: err.stack,
      config: err.config ? err.config : null,
    });
    store.dispatch(showError(err));
  },
});

const newState = localStorageUtils.getState();
//Validate loaded state, if is not valid, set initial state
const preloadedState = validateLoadedState(newState)
  ? { user: { ...initialState, ...newState.user } }
  : { user: initialState };

if (!validateLoadedState(newState))
  console.log(
    "[NEW MYL] ATTENTION! Validation local storage has failed. Please check the validation function or local storage saving"
  );

export const store = configureStore({
  preloadedState: preloadedState,
  reducer: {
    store: storeReducer,
    cart: cartReducer,
    search: searchReducer,
    catalogue: catalogueReducer,
    aftersales: aftersalesReducer,
    checkout: checkoutReducer,
    documents: documentsReducer,
    rx: rxReducer,
    user: userReducer,
    subuser: subuserReducer,
    accounting: accountingReducer,
    profile: profileReducer,
    orderHistory: orderHistoryReducer,
    ordersToApprove: ordersToApproveReducer,
    services: servicesReducer,
    multidoor: multidoorReducer,
    analytics: analyticsReducer,
    warrantyWizard: warrantyWizardReducer,
    leonardo: leonardoReducer,
    smartshopper: smartshopperReducer,
    serviceRequest: serviceRequestReducer,
    orderUpload: orderUploadReducer,
    payment: paymentReducer,
    notifications: notificationsReducer,
    messages: messagesReducer,
    adt: adtReducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);
store.subscribe(
  throttle(() => {
    /******************************************************
      ATTENTION!! WARNING!! IMPORTANT!! READ THIS!!
      Every time the following saving operation is modified, the validateLoadedState function (see above) should also be updated accordingly.
      This function checks the format of the state saved in localstorage: 
      if the validation checks are not passed, the localstorage state will not be loaded in redux.
    *******************************************************/
    localStorageUtils.saveState({
      user: {
        isLogged: store.getState().user.isLogged,
        username: store.getState().user.username,
        storeIdentifier: store.getState().user.storeIdentifier,
        languageId: store.getState().user.languageId,
        doors:
          store
            .getState()
            .user.multiDoors?.map((_) => _.orgentityName)
            .join(",") || "",
        // currency: store.getState().user.currency,
      },
    });
    /**************************************
      ATTENTION!! WARNING!! IMPORTANT!! READ THIS!!
      Every time the saving operation is modified, the validateLoadedState function (see above) should also be updated accordingly.
      This function checks the format of the state saved in localstorage: 
      if the validation checks are not passed, the localstorage state will not be loaded in redux.
    ********************************************/
  }, 1000)
);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
