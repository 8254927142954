import React from "react";
import styled from "styled-components/macro";
import Loader from "./Loader";

const LoadingBackground = (): JSX.Element => {
  return (
    <Page>
      <Loader sizePx={65} styleLoader="light-background" />
    </Page>
  );
};

export default LoadingBackground;

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.light};
  height: 100%;
  width: 100%;
`;
