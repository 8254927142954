import {
  Items,
  StagingOrder,
  StagingOrdersItems,
} from "../store/smartshopper/smartshopperInterfaces";

export const createFilters = (data: any, name: string): [] => {
  const filters = data?.filter((x: { filterType: string }) => {
    return x?.filterType === name;
  });

  const options = filters[0].filterValue?.map((item: { code: string; label: string }) => {
    return {
      value: item.code,
      label: item.label ?? item.code,
    };
  });

  return options;
};

export const getDeviceName = (name: string): string => name.split(".")[0];

export const saveUnavailableInfoInStagingOrder = (
  stagingOrder: StagingOrder,
  itemIds: string[]
): StagingOrder => {
  const newStagingOrders = stagingOrder.stagingOrders?.map((_: StagingOrdersItems) => {
    const findItem = _.items?.find((_: Items) => itemIds?.includes(_.itemId));

    if (findItem) {
      const newItems = _.items?.map((_) => {
        if (itemIds?.includes(_.itemId)) {
          return {
            ..._,
            unavailable: true,
          };
        } else {
          return _;
        }
      });

      return {
        ..._,
        items: newItems,
      };
    } else {
      return _;
    }
  });

  return {
    ...stagingOrder,
    stagingOrders: newStagingOrders,
  };
};
