import React from "react";
import styled from "styled-components/macro";
import CustomText from "../../styled-UI/CustomText";
import LinkButton from "../../styled-UI/LinkButton";
import clsx from "clsx";
import { DotInterface } from "../../../interfaces/cmsInterfaces";
import { useHandleCmsLink } from "../../../utils/cmsUtils";
import useDataHref from "../../../hooks/useDataHref";
import SetInnerHTMLWithSanitize from "../SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface DotsProps {
  data: DotInterface;
  isOpen: boolean;
  imgHeight: number;
  imgWidth: number;
  setOpen: (dotId: string) => void;
  customFont?: FontFamilies | null;
  isDarkMode?: boolean;
  focusOnPopup: (focusOnPopup: boolean) => void;
}

const DotsWithPopup = ({
  data,
  isOpen,
  imgHeight,
  imgWidth,
  setOpen,
  customFont = null,
  isDarkMode = false,
  focusOnPopup,
}: DotsProps): JSX.Element => {
  const { handleCmsLink } = useHandleCmsLink();
  const [x1, y1, x4, y4] = data.coords.split(",");

  const dotPercX = (+x1 / imgWidth) * 100;
  const docPercY = (+y1 / imgHeight) * 100;

  const descrWithHref = useDataHref({ content: data.linkedContent?.description });

  const handleLinkClick = () => {
    handleCmsLink(data.linkedContent?.linkString);
  };

  return (
    <Dot
      x={dotPercX}
      y={docPercY}
      onMouseOver={() => setOpen(data.coords)}
      isOpen={isOpen}
      isDarkMode={isDarkMode}
    >
      <Popup
        className={clsx("dots-popup", dotPercX < 50 && "dots-popup--left")}
        onMouseEnter={() => focusOnPopup(true)}
        onMouseLeave={() => focusOnPopup(false)}
      >
        <PopupContent isDarkMode={isDarkMode}>
          <CustomText
            as="p"
            fontSizePx={18}
            font={customFont ? "custom-bold" : "font-bold"}
            fontFamily={customFont}
            marginBottomPx={10}
            color={isDarkMode ? "white" : "primary"}
          >
            {data.linkedContent?.title}
          </CustomText>
          <CustomText
            as="p"
            fontSizePx={14}
            lineHeightPx={24}
            font={customFont ? "custom-regular" : "font-regular"}
            fontFamily={customFont}
            marginBottomPx={8}
            color={isDarkMode ? "white" : "primary"}
          >
            <SetInnerHTMLWithSanitize
              text={descrWithHref.updatedContent ?? data.linkedContent?.description}
            />
          </CustomText>
          <LinkButton
            uppercase
            underline
            onClick={handleLinkClick}
            customFont={customFont}
            color={isDarkMode ? "white" : "primary"}
          >
            {data.linkedContent?.linkLabel}
          </LinkButton>
        </PopupContent>
      </Popup>
    </Dot>
  );
};

export default DotsWithPopup;

//percenutale delle coordiante > 50% , dimensione immagine
const Dot = styled.div<{ x?: number; y?: number; isOpen: boolean; isDarkMode: boolean }>`
  position: absolute;
  top: ${(props) => props.y + "%"};
  left: ${(props) => props.x + "%"};
  height: 1.625rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isDarkMode ? props.theme.palette.primary : props.theme.palette.white};
  width: 1.625rem;

  border: ${(props) =>
    props.isOpen && "1px solid " + props.isDarkMode
      ? props.theme.palette.white
      : props.theme.palette.primary};
  .dots-popup {
    display: ${(props) => props.isOpen && "block"};
  }
`;

const Popup = styled.div`
  display: none;
  position: absolute;
  padding: 0 1.85rem;
  right: 0;

  &.dots-popup--left {
    left: 0;
    right: auto;
  }
`;

const PopupContent = styled.div<{ isDarkMode: boolean }>`
  background-color: ${(props) =>
    props.isDarkMode ? props.theme.palette.primary : props.theme.palette.white};
  box-shadow: 2px 2px 47px -10px rgba(0, 0, 0, 0.68);
  padding: 1.25rem;
  width: 13.5rem;
`;
