import React from "react";
import styled from "styled-components/macro";
import clsx from "clsx";

import Loader from "./Loader";

import { ReactComponent as PlusIcon } from "../../assets/icons/plus-icon.svg";

type ButtonCartProps = {
  text: string;
  type: "light" | "dark";
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  "data-element-id"?: string | null;
  "data-description"?: string;
};

const ButtonCart = ({
  onClick,
  text,
  type,
  disabled = false,
  isLoading = false,
  "data-element-id": dataElementId,
  "data-description": dataDescription,
}: ButtonCartProps): JSX.Element => {
  return (
    <Container
      className={type}
      onClick={onClick}
      disabled={disabled}
      {...(dataElementId ? { "data-element-id": dataElementId } : {})}
      {...(dataDescription ? { "data-description": dataDescription } : {})}
    >
      {text}

      <IconContainer
        className={clsx("size-small", type === "light" ? "color-primary" : "color-white")}
        disabled={disabled}
      >
        {isLoading ? (
          <Loader
            sizePx={15}
            styleLoader={`${type}-background` as "light-background" | "dark-background"}
          />
        ) : (
          <PlusIcon />
        )}
      </IconContainer>
    </Container>
  );
};

const Container = styled.button<{ disabled: boolean }>`
  display: inline-flex;
  border-radius: 1.1rem;
  border: solid 0.1rem ${(props) => props.theme.palette.bordersBlue};
  background-color: transparent;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  font-size: 0.875rem;
  color: ${(props) => (props.disabled ? props.theme.palette.textBlue : props.theme.palette.white)};
  padding: 0 0.75rem;
  height: 2rem;
  font-size: 0.75rem;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.fontMedium} !important;
  box-sizing: border-box;

  &.light {
    border: solid 0.1rem ${(props) => props.theme.palette.gray.medium};
    color: ${(props) =>
      props.disabled ? props.theme.palette.gray.dark : props.theme.palette.primary};
  }
`;

const IconContainer = styled.div<{ disabled: boolean }>`
  margin-left: 0.5rem;

  &.color-white {
    svg {
      fill: ${(props) =>
        props.disabled ? props.theme.palette.textBlue : props.theme.palette.white};
    }
    &.size-small {
      width: 1rem;
      height: 1rem;
    }
  }

  &.color-primary {
    svg {
      fill: ${(props) =>
        props.disabled ? props.theme.palette.gray.dark : props.theme.palette.primary};
    }
    &.size-small {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export default ButtonCart;
