import { BFFApiDomain } from "../serviceUtils";

export const getSubusersUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/leonardo/subuserRequests";

export const postApproveAllRequest =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/leonardo/subuserRequests/approveAll";

export const postRejectAllRequest =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/leonardo/subuserRequests/rejectAll";

export const postApproveRequest =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/leonardo/subuserRequests/approve";

export const postRejectRequest =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/leonardo/subuserRequests/reject";

export const getPendingCount =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/leonardo/subuserRequests/pendingCount";

// export const getCoursesUrl = globalEnvVariables.leonardoUrl + "/newuni/bff/course/catalog/myl";

export const getCoursesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/contents/leonardo/carousel";
