import React from "react";
import styled from "styled-components/macro";
import Button from "../styled-UI/Button";
import CustomText from "../styled-UI/CustomText";
import { LpTextualBanner as Interface } from "../../interfaces/cmsInterfaces";
import useDataHref from "../../hooks/useDataHref";
import { useHandleCmsLink } from "../../utils/cmsUtils";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  data: Interface;
  position: "left" | "center";
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LpTextualBanner = ({
  data,
  position,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const { handleCmsLink } = useHandleCmsLink();

  const {
    description,
    description1Colour,
    description2,
    description2Colour,
    linkLabel,
    linkString,
    externalLink,
    openInNewTab,
  } = data;

  const descrWithHref = useDataHref({ content: description });
  const descrWithHref2 = useDataHref({ content: description2 });

  const handleRedirect = (): void => {
    handleCmsLink(linkString, externalLink, openInNewTab);
  };

  return (
    <OuterBox position={position}>
      {description && (
        <CustomText
          as="span"
          color={isDarkMode ? "white" : "primary"}
          fontSizePx={36}
          font={customFont ? "custom-medium" : "font-medium"}
          fontFamily={customFont}
          marginBottomPx={description2 || linkLabel ? 30 : 0}
          textAlign={position === "left" ? "left" : "center"}
          lineHeightPx={36}
          customColor={description1Colour}
        >
          <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? description} />
        </CustomText>
      )}
      {description2 && (
        <CustomText
          as="span"
          color={isDarkMode ? "white" : "primary"}
          fontSizePx={18}
          font={customFont ? "custom-regular" : "font-regular"}
          fontFamily={customFont}
          textAlign={position === "left" ? "left" : "center"}
          marginBottomPx={linkLabel ? 40 : 0}
          lineHeightPx={26}
          customColor={description2Colour}
        >
          <SetInnerHTMLWithSanitize text={descrWithHref2?.updatedContent ?? description2} />
        </CustomText>
      )}
      {linkLabel && linkString && (
        <BoxButton position={position}>
          <Button
            onClick={handleRedirect}
            type={isDarkMode ? "primary-dark" : "primary"}
            customFont={customFont}
          >
            {linkLabel}
          </Button>
        </BoxButton>
      )}
    </OuterBox>
  );
};

const OuterBox = styled.div<{ position: string }>`
  padding: 3.75rem 10%;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.position === "left" ? "flex-start" : "center")};
  height: 100%;
`;

const BoxButton = styled.div<{ position: string }>`
  min-width: 16rem;
  display: flex;
  justify-content: ${(props) => (props.position === "left" ? "flex-start" : "center")};
`;

export default LpTextualBanner;
