import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { useTrackView } from "../../../hooks/useTrackView";
import useTranslation from "../../../hooks/useTranslation";
import { OrderMultidoorSummary } from "../../../store/cart/cartInterfaces";
import { checkPrescriptionBrasil, simulatePrecart } from "../../../store/cart/cartSagas";
import {
  clearCartWhenLeavingPage,
  selectCartInfo,
  selectCartSummary,
  selectIsCartEmpty,
  selectIsCartInitialized,
  selectOrderMultidoorAddress,
  selectOrderMultidoorList,
  selectOutOfStockItems,
  selectRxBrasilCheckPayload,
} from "../../../store/cart/cartSlice";
import { selectIsMultidoor } from "../../../store/user/userSlice";
import WrapperLayout from "../../layouts/WrapperLayout";
import Button from "../../styled-UI/Button";
import CustomText from "../../styled-UI/CustomText";
import Popup from "../../styled-UI/Popup";
import LoaderPageCart from "../../styled-UI/loader/cart/LoaderPageCart";
import PreviewConditionalRender from "../../widgets/PreviewConditionalRender";
import { MemoizedProductRecommendation } from "../../widgets/ProductRecommendation";
import RepeatOrderPopup from "../../widgets/RepeatOrderPopup";
import CartEmptyPage from "./CartEmptyPage";
import CartContent from "./cart-content/CartContent";
import CartSummary from "./cart-summary/CartSummary";

const Cart = (): JSX.Element => {
  const dispatch = useDispatch();

  const isCartEmpty = useSelector(selectIsCartEmpty);
  const isCartInitialized = useSelector(selectIsCartInitialized);
  const isMultidoor = useSelector(selectIsMultidoor);
  const orderMultidoorAddress = useSelector(selectOrderMultidoorAddress);

  // structured list of orderItems (OrderMultidoor > OrderCategory > OrderItem)
  const cartItems = useSelector(selectOrderMultidoorList);
  // structured list of info about cart (totalNumber of items, selectedNumber of items, which orderItems are selected)
  const cartInfo = useSelector(selectCartInfo);
  const { translateLabel } = useTranslation();

  // get out of stock items to remove them from list and show popup
  const outOfStockItems = useSelector(selectOutOfStockItems);
  const [isOpenPopUp, setIsOpenPopUp] = useState<boolean>(false);

  /**
   * PRE_CART_ITEMS_REMOVED_OFFSET 	-> Message key for generic message when there are too many Partnumbers to report
   *PRE_CART_ITEMS_REMOVED	-> Message key  when the list of partnumbers it's populated
   *PRE_CART_NO_ITEMS_REMOVED -> Message key when no items has been deleted
   */
  enum OutOfStockStatus {
    PRE_CART_ITEMS_REMOVED_OFFSET = "PRE_CART_ITEMS_REMOVED_OFFSET",
    PRE_CART_ITEMS_REMOVED = "PRE_CART_ITEMS_REMOVED",
    PRE_CART_NO_ITEMS_REMOVED = "PRE_CART_NO_ITEMS_REMOVED",
  }

  useEffect(() => {
    dispatch(simulatePrecart()); // call simulatePrecart + getPrecart + getShippingInfo

    return () => {
      dispatch(clearCartWhenLeavingPage());
    };
  }, []);

  useEffect(() => {
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "orderConfirmation" */
      "../order-confirmation/OrderConfirmation"
    );
  }, []);

  useEffect(() => {
    if (
      outOfStockItems &&
      outOfStockItems.label &&
      outOfStockItems.label !== OutOfStockStatus.PRE_CART_NO_ITEMS_REMOVED
    )
      return setIsOpenPopUp(true);
    return setIsOpenPopUp(false);
  }, [outOfStockItems]);

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////// RX PRESCRIPTION BRASIL /////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const rxBrasilCheckPayload = useSelector(selectRxBrasilCheckPayload);

  // check if all rx order items have an uploaded prescription for brasil
  // NOTE that privilege check is implicit in selector, as it skips any computation and returns []
  // if RX_PRESCRIPTION_UPLOAD is not present for the active door
  useEffect(() => {
    if (rxBrasilCheckPayload.length > 0) dispatch(checkPrescriptionBrasil(rxBrasilCheckPayload));
  }, [rxBrasilCheckPayload]);

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////// ANALYTICS ///////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const cartPriceSummary = useSelector(selectCartSummary);

  const getCartPrice = (cartPriceSummary: OrderMultidoorSummary[] | null) => {
    let cartPrice = "";

    cartPriceSummary?.forEach((price) => {
      cartPrice += `${!!cartPrice ? "," : ""}${String(price?.totalPrice)}`;
    });

    return cartPrice;
  };

  const deletePopup = (
    <Popup
      close={() => setIsOpenPopUp(false)}
      isOpen={isOpenPopUp}
      title={translateLabel("PRE_CART_ITEMS_POPUP_TITLE")}
      isCentered
      disableCloseOutsidePopup={true}
    >
      <ModalContainer>
        {outOfStockItems?.label === OutOfStockStatus.PRE_CART_ITEMS_REMOVED_OFFSET && (
          <WrapMessageOffset>
            <CustomText as="p" fontSizePx={18} font="font-bold" color="primary" lineHeightPx={24}>
              {translateLabel(outOfStockItems.label)}
            </CustomText>
          </WrapMessageOffset>
        )}
        {outOfStockItems?.partNumbers?.length &&
          outOfStockItems?.label === OutOfStockStatus.PRE_CART_ITEMS_REMOVED && (
            <>
              <WrapMessage>
                <CustomText
                  as="p"
                  fontSizePx={18}
                  font="font-bold"
                  color="primary"
                  lineHeightPx={24}
                >
                  {translateLabel(outOfStockItems.label)}
                </CustomText>
              </WrapMessage>
              <table className="partnumber_table">
                <thead>
                  <tr>
                    <th>{translateLabel("PRE_CART_BRANDS")}</th>
                    <th>{translateLabel("PRE_CART_PART_NUMBERS")}</th>
                  </tr>
                </thead>
                <tbody>
                  {outOfStockItems?.partNumbers.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{row.manufacturer}</td>
                      <td>{row.partNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        <ButtonContainer>
          <Button type="primary" onClick={() => setIsOpenPopUp(false)}>
            {translateLabel("PRE_CART_POPUP_CONFIRM")}
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </Popup>
  );

  useTrackView(
    {
      Page_Section1: "Checkout",
      Page_Section2: "Cart",
      Page_Type: "CartPage",
      Order_ProductsAmount: getCartPrice(cartPriceSummary),
    },
    { cartItems, isCartInitialized, cartPriceSummary }
  );

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// RENDER /////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  if (!isCartInitialized) {
    return (
      <CartPage isCartEmpty={isCartEmpty}>
        <WrapperLayout>
          <LoaderPageCart />
        </WrapperLayout>
      </CartPage>
    );
  } else
    return (
      <CartPage isCartEmpty={isCartEmpty}>
        {isOpenPopUp && deletePopup}
        <Container>
          <WrapperLayout>
            <PreviewConditionalRender>
              {isCartEmpty ? (
                <CartEmptyPage isMultidoor={isMultidoor} />
              ) : (
                <Row>
                  <MainColumn>
                    <CartContent
                      cartItems={cartItems}
                      cartInfo={cartInfo}
                      orderMultidoorAddress={orderMultidoorAddress}
                      isMultidoor={isMultidoor}
                    />
                  </MainColumn>
                  <SummaryColumn>
                    <CartSummary
                      cartInfo={cartInfo}
                      isMultidoor={isMultidoor}
                      currencyPrice={
                        cartItems[0]?.categoryList[0]?.orderItemList[0]?.price.opt.currency
                      }
                    />
                  </SummaryColumn>
                </Row>
              )}
            </PreviewConditionalRender>
          </WrapperLayout>
          <RepeatOrderPopup />
        </Container>
        <MemoizedProductRecommendation />
      </CartPage>
    );
};

const CartPage = styled.div<{ isCartEmpty: boolean }>`
  margin: 0 auto;
  background-color: ${(props) => (props.isCartEmpty ? "trasparent" : props.theme.palette.white)};
`;

const Container = styled.div`
  padding: 2.5rem 0 6rem;
`;

const Row = styled.div`
  display: flex;
`;

const MainColumn = styled.div`
  flex-grow: 1;
  margin-right: 1.8125rem;

  @media ${(props) => props.theme.queries.md} {
    margin-right: 0.5rem;
  }
`;

const SummaryColumn = styled.div`
  width: 100%;
  max-width: 20rem;
  flex-shrink: 0;

  @media ${(props) => props.theme.queries.md} {
    width: 17.75rem;
  }
`;

//popup
const ModalContainer = styled.div`
  padding: 1rem 1rem 0.5rem 1rem;
  .partnumber_table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin-bottom: 6rem;
  }
  .partnumber_table tbody {
    display: block;
    width: 100%;
    overflow: auto;
    max-height: 250px;
  }
  .partnumber_table tr {
    display: block;
    padding: 1rem;
    color: ${(props) => props.theme.palette.primary};
  }
  .partnumber_table tbody tr {
    border-bottom: solid 1px ${(props) => props.theme.palette.gray.medium};
    &:nth-child(even) {
      background-color: #f2f2f2;
    }
  }
  .partnumber_table thead {
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
    font-weight: bold;
  }
  .partnumber_table th,
  .partnumber_table td {
    padding: 5px;
    text-align: left;
    width: 200px;
  }
`;

const WrapMessage = styled.div`
  justify-content: center;
  border-bottom: solid 3px ${(props) => props.theme.palette.gray.medium};
  padding-bottom: 1rem;
  margin-bottom: 3rem;
`;

const WrapMessageOffset = styled.div`
  justify-content: center;
  padding: 1rem 0rem 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  padding-right: 1rem;
`;

export default Cart;
