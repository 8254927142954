import { MenuStructureKeys } from "../components/layouts/header/navbar/categories-menu/category-item/menuStructure";

export interface MostUsedContent {
  unused: boolean;
  canEdit: boolean;
  bookmarks: MostUsedChip[];
}

export type MostUsedChip = MostUsedChipCatalogue | MostUsedChipServicesMyAccount;
export type MostUsedChipLocal = MostUsedChipCatalogueLocal | MostUsedChipServicesMyAccountLocal;
export type MostUsedLoading = "get" | "update";

export interface MostUsedChipCatalogue {
  name: string;
  type: "Catalogue" | "Brands" | "Lenses" | "Essilor";
  url: string;
  catalogueInfo: MostUsedCatalogueInfo;
  attributes?: MostUsedAttributes[];
}

export interface MostUsedChipCatalogueLocal extends MostUsedChipCatalogue {
  label: string;
  showAsDefault?: boolean;
}

export interface MostUsedChipServicesMyAccount {
  name: string;
  type: "Services&MyAccount";
  attributes?: MostUsedAttributes[];
}

export interface MostUsedChipServicesMyAccountLocal extends MostUsedChipServicesMyAccount {
  label: string;
  url: string;
  showAsDefault?: boolean;
}

/**
 * Custom type guard, check if object implements MostUsedChipCatalogue interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(any)} object
 * @return {*}  {object is MostUsedChipCatalogue}
 */
export function instanceOfMostUsedChipCatalogue(object: any): object is MostUsedChipCatalogue {
  return object.type === "Catalogue" || object.type === "Lenses";
}

/**
 * Custom type guard, check if object implements MostUsedChipServicesMyAccount interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(any)} object
 * @return {*}  {object is MostUsedChipServicesMyAccount}
 */
export function instanceOfMostUsedChipServicesMyAccount(
  object: any
): object is MostUsedChipServicesMyAccount {
  return object.type === "Services&MyAccount";
}

///////////////////////////////////

export interface MostUsedCatalogueInfo {
  menuCategory: MenuStructureKeys;
  facets: MostUsedCatalogueInfoFacets[];
}

export interface MostUsedCatalogueInfoFacets {
  facetName: string;
  facetValue: string;
}

export interface MostUsedAttributes {
  attrKey: string;
  attrValue: string;
}

export interface MostUsedOption {
  label: string;
  url: string;
  facet: MostUsedCatalogueInfoFacets;
  columnTitle: string;
  columnFacet: string | undefined;
}

export interface MostUsedCategory {
  title: string;
  isRadio: boolean;
  key: string;
  columnFacet: string | undefined;
  options: MostUsedOption[];
}

export interface MostUsedPayload {
  bookmarks: MostUsedChip[];
}
