import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components/macro";

import CarouselDot from "../../../styled-UI/CarouselDot";
import IconButton from "../../../styled-UI/IconButton";
import { ReactComponent as ArrowHeadRight } from "../../../../assets/icons/arrowhead-right-icon.svg";

import { useBreakpoint } from "../../../../assets/theme/breakpoint";

interface Props {
  currentPage: number;
  updateCurrentPage: (page: number) => void;
  totalVariants: number;
  variantsPerPage: number;
}

const LpVariantsPopupFooter = ({
  currentPage,
  updateCurrentPage,
  totalVariants = 0,
  variantsPerPage,
}: Props): JSX.Element => {
  const breakpoints = useBreakpoint();
  const [dots, setDots] = useState<number[]>();
  const totalPages = Math.ceil(totalVariants / variantsPerPage);

  useEffect(() => {
    const newArrayDots = [];
    for (let i = 1; i <= totalPages; i++) {
      newArrayDots.push(i);
    }
    setDots(newArrayDots);
  }, [totalPages]);

  const getNextVariants = (page: "prev" | "next") => {
    const newPage = page === "prev" ? currentPage - 1 : currentPage + 1;
    updateCurrentPage(newPage);
  };

  const goNextVariantsWithDot = (index: number) => {
    const newPage = index;
    updateCurrentPage(newPage);
  };
  const renderPageStatus = (): ReactNode => {
    return totalVariants ? (
      <PageStatus>
        {dots &&
          dots.map((dot) => {
            return (
              <CarouselDot
                currentPage={currentPage}
                navigationType={"central"}
                changeSlide={() => goNextVariantsWithDot(dot)}
                key={dot}
                dot={dot}
                index={dot}
              />
            );
          })}
      </PageStatus>
    ) : null;
  };
  const renderCarouselNavbar = () =>
    totalVariants && totalVariants > variantsPerPage ? (
      <BoxButtons className="d-flex align-items-center">
        <ButtonSpacer>
          <IconButton
            type="primary"
            size={breakpoints["md"] ? "md" : "lg"}
            onClick={() => getNextVariants("prev")}
            disabled={currentPage === 1}
            icon={<ArrowHeadRight />}
          />
        </ButtonSpacer>
        <IconButton
          type="primary"
          size={breakpoints["md"] ? "md" : "lg"}
          onClick={() => getNextVariants("next")}
          disabled={currentPage === totalPages}
          icon={<ArrowHeadRight />}
        />
      </BoxButtons>
    ) : null;

  return (
    <Footer>
      {renderPageStatus()}
      <Box className="d-flex align-items-center">{renderCarouselNavbar()}</Box>
    </Footer>
  );
};
const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 2.5rem;
  align-items: center;
  margin-bottom: 5rem;
`;
const PageStatus = styled.div`
  display: flex;
`;
const Box = styled.div`
  position: absolute;
  width: 104%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
`;
const BoxButtons = styled.div`
  width: 100%;
  justify-content: space-between;
`;
const ButtonSpacer = styled.div`
  margin-right: 0.8125rem;

  svg {
    transform: rotate(180deg);
  }
`;

export default LpVariantsPopupFooter;
