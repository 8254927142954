import { Site } from "./routesInterfaces";
import { CMS_ROUTES } from "../store/store/storeSlice";

/**
 * Get site information from url
 *
 * @return {*}  {Site}
 */
export const parseUrlInfo = (): Site => {
  const siteInfo: Site = {
    storeIdentifier: "",
    locale: "",
    pageType: "",
  };

  const pathnameArray = window.location.pathname.split("/");
  if (pathnameArray.length > 2) {
    if (pathnameArray.length < 4) {
      siteInfo.locale = pathnameArray[1];
      siteInfo.pageType = pathnameArray[2];
    } else {
      siteInfo.storeIdentifier = pathnameArray[1];
      siteInfo.locale = pathnameArray[2];
      siteInfo.pageType = pathnameArray[3];
    }
  }

  return siteInfo;
};

/**
 * Returns whether the given page is not found
 *
 * @param {string} page
 * @return {*}  {boolean}
 */
export const isPageNotFound = (page: string): boolean => {
  let isFound = false;
  (Object.keys(CMS_ROUTES) as Array<keyof typeof CMS_ROUTES>).map((key) => {
    if (CMS_ROUTES[key] === page) isFound = true;
  });

  return !isFound;
};

/**
 * Return whether current page is the login page, or a public page in general, respectively
 *
 * @return {*}  {boolean}
 */
export const isLoginpage = (): boolean => window.location.pathname.includes(CMS_ROUTES.LOGIN_PAGE);
export const isPublicPage = (): boolean => isLocationPublic(window.location);

export const isLocationPublic = (location: Location): boolean => {
  return (
    location.pathname.includes(CMS_ROUTES.LOGIN_PAGE) ||
    location.pathname.includes(CMS_ROUTES.NEED_HELP)
  );
};

/**
 * Returns dynamic login path, according to locale
 * @return {string}
 */
export const getLoginPagePath = (): string => getPublicPagePath("/login");

/**
 * Returns dynamic public page path, according to locale
 * @return {string}
 */
export const getPublicPagePath = (pathEnd: string): string => {
  const siteInfo = parseUrlInfo();

  return `/${siteInfo.locale ? siteInfo.locale : "en-US"}${pathEnd}`;
};

/**
 * Returns dynamic path, according to locale and store identifier
 * @return {string}
 */
export function getPagePath(pathEnd: string, storeIdentifier?: string, locale?: string): string {
  const siteInfo = parseUrlInfo();

  return `/${storeIdentifier ?? siteInfo.storeIdentifier}/${locale ?? siteInfo.locale}${pathEnd}`;
}

/**
 * Returns dynamic path, according to the page path and last path param
 * @param {string} pageType
 * @param {string} pathEnd
 * @return {string}
 */
export function getPartialPagePath(pageType: string, pathEnd: string): string {
  return `/${pageType}/${pathEnd}`;
}

/**
 * Returns same url but with locale updated
 * @return {string}
 */
export const replaceLocaleInUrl = (newLocale: string): string => {
  const siteInfo = parseUrlInfo();
  if (siteInfo.locale)
    return (
      window.location.pathname.replace(siteInfo.locale, newLocale.replace("_", "-")) +
      window.location.search
    );
  else return "";
};

/**
 * Returns same url but with storeId updated
 * @return {string}
 */
export const replaceStoreIdInUrl = (newStoreId: string): string => {
  const siteInfo = parseUrlInfo();
  if (siteInfo.storeIdentifier)
    return (
      window.location.pathname.replace(siteInfo.storeIdentifier, newStoreId) +
      window.location.search
    );
  else return "";
};
