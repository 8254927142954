import React from "react";
import styled from "styled-components/macro";
import { SocialProductTile as Interface } from "../../../interfaces/cmsInterfaces";
import { getMultiMedia } from "../../../utils/cmsUtils";
import CustomText from "../../styled-UI/CustomText";
import EditorialTileContent from "./SocialProductTileContent";

interface Props {
  data: Interface;
}

const FlipSocialProductTile = ({ data }: Props): JSX.Element => {
  const { cover, collection, year } = data;
  const { srcImg } = getMultiMedia(cover);

  return (
    <OuterTile>
      <FlipCardFront>
        {srcImg && <BackgroundImg image={srcImg} />}
        <OuterBoxTextImg>
          <InnerBoxTextImg>
            <CustomText font="font-regular" fontSizePx={14} as="span" color="primary">
              {collection}
            </CustomText>
            <CustomText font="font-bold" fontSizePx={14} as="span" color="primary">
              {year}
            </CustomText>
          </InnerBoxTextImg>
        </OuterBoxTextImg>
      </FlipCardFront>
      <FlipCardBack>
        <EditorialTileContent data={data} />
      </FlipCardBack>
    </OuterTile>
  );
};

export default FlipSocialProductTile;

const FlipCardFront = styled.div`
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-transition: 0.6s;
  transition: 0.6s;

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;

  height: 30.63rem;
  /* padding: 1.5rem 0.125rem 2.875rem 0.1875rem; */
`;

const FlipCardBack = styled.div`
  position: absolute;

  transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-transition: 0.6s;
  transition: 0.6s;

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;

  top: 0;
  height: 30.63rem;
  padding: 1.5rem 0.125rem 2.875rem 0.1875rem;
`;

const OuterTile = styled.div`
  transition: transform 1s;
  transform-style: preserve-3d;
  width: 19.94rem;
  height: 30.63rem;
  background-color: white;
  /* margin: 0 1.25rem 1.25rem 0; */
  padding: 1.5rem 0.125rem 2.875rem 0.1875rem;
  &:hover {
    transform: rotateY(180deg);
  }
`;

const BackgroundImg = styled.div<{ image: string }>`
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.image && props.image});
  width: 16.06rem;
  height: 23rem;
  margin: 10px 1.938rem 1.5rem 1.938rem;
`;

const OuterBoxTextImg = styled.div`
  margin: 31px 31px;
  display: flex;
  flex-direction: column;
`;

const InnerBoxTextImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
