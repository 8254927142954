import React, { useEffect, useState } from "react";
import Slider, { LazyLoadTypes } from "react-slick";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";

import DisplayPrices from "../widgets/DisplayPrices";
import LinkButton from "./LinkButton";
import CarouselNavBar from "./CarouselNavBar";
import CustomText from "./CustomText";
import { useHistory } from "react-router";
import { FontFamilies } from "../../store/store/storeInterfaces";
import FavoritesTooltipContainer from "../widgets/tutorial-pills/manage-favorites/FavoritesTooltipContainer";
import { externalReferenceTooltip } from "../../utils/cmsUtils";

interface Props {
  children: any;
  slidesToShow?: number;
  slidesToScroll?: number;
  isTitleLabel?: boolean;
  title: string;
  colorSelect: "gray-medium" | "bg-light-blue";
  hideDisplay: boolean;
  showViewAll?: boolean;
  "data-element-id"?: string;
  "data-description"?: string;
  url?: string;
  dataElementIdSlider?: string;
  dataElementIdFilters?: string;
  dataElementIdViewAll?: string;
  enableDataDescriptionNavigation?: boolean;
  callbackOnChangeSlide?: (page: number) => void;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const HomeCarousel = ({
  children,
  slidesToShow = 1,
  slidesToScroll = 1,
  isTitleLabel = true,
  title,
  colorSelect,
  hideDisplay,
  url,
  showViewAll = true,
  "data-element-id": dataElementId,
  "data-description": dataDescription,
  dataElementIdSlider,
  dataElementIdFilters,
  dataElementIdViewAll,
  enableDataDescriptionNavigation = false,
  callbackOnChangeSlide,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  const [sliderRef, setSliderRef] = useState<any>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [totalSlides, setTotalSlides] = useState<number>(children?.length);
  const currentPage = Math.ceil(currentSlide / slidesToScroll);
  const lastPage = Math.ceil((totalSlides - slidesToShow) / slidesToScroll);
  const history = useHistory();

  const defaultSettings = {
    dots: false,
    arrows: false,
    draggable: false, //to set it to true --> prevent click on drag https://github.com/akiran/react-slick/issues/848
    speed: 1000,
    infinite: false,
    onSwipe: () => {
      console.log("swipe");
    },
    lazyLoad: "ondemand" as LazyLoadTypes,
    slidesToShow: slidesToShow < totalSlides ? slidesToShow : totalSlides,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    beforeChange: (_: any, next: any) => setCurrentSlide(next),
  };

  const changeSlide = (val: number) => {
    sliderRef.slickGoTo(val);
    callbackOnChangeSlide && callbackOnChangeSlide(val);
  };

  useEffect(() => {
    if (sliderRef?.props?.children && Array.isArray(sliderRef.props.children))
      setTotalSlides(sliderRef.props.children.length);
  }, [sliderRef]);

  useEffect(() => {
    if (children?.length !== totalSlides) setTotalSlides(children.length);
  }, [children]);

  return (
    <SliderContainer>
      <MediumScreen>
        <MediumSectionFirstRow>
          <TooltipContainer>
            <CustomText
              as="span"
              font={customFont ? "custom-regular" : "font-regular"}
              fontFamily={customFont}
              fontSizePx={50}
              lineHeightPx={46}
              color={isDarkMode ? "white" : "primary"}
            >
              {isTitleLabel ? translateLabel(title) : title}
            </CustomText>
            <FavoritesTooltipContainer position={externalReferenceTooltip[title]} />
          </TooltipContainer>
          {!hideDisplay && (
            <DisplayContainer>
              <DisplayPrices
                colorSelect={colorSelect}
                data-element-id={dataElementIdFilters}
                enableDataDescription
                style={isDarkMode ? "dark" : "default"}
                customFont={customFont}
              />
            </DisplayContainer>
          )}
        </MediumSectionFirstRow>
        <MediumSectionSecondRow>
          <div>
            {showViewAll && url && (
              <SubTitle>
                <LinkButton
                  uppercase
                  onClick={() => {
                    history.push(url);
                  }}
                  data-element-id={dataElementIdViewAll}
                  color={isDarkMode ? "white" : "primary"}
                  customFont={customFont}
                >
                  {translateLabel("VIEW_ALL")}
                </LinkButton>
              </SubTitle>
            )}
          </div>
          <div>
            <NavigationSection>
              {totalSlides > 1 && totalSlides > slidesToShow ? (
                <CarouselNavBar
                  currentSlide={currentSlide}
                  slidesToScroll={slidesToScroll}
                  changeSlide={changeSlide}
                  currentPage={currentPage}
                  lastPage={lastPage}
                  navigationType="default"
                  {...(dataElementId ? { "data-element-id": dataElementId } : {})}
                  enableDataDescriptionNavigation={enableDataDescriptionNavigation}
                />
              ) : (
                <div></div>
              )}
            </NavigationSection>
          </div>
        </MediumSectionSecondRow>
      </MediumScreen>

      {!hideDisplay && (
        <FirstRow>
          <DisplayPrices
            colorSelect={colorSelect}
            data-element-id={dataElementIdFilters}
            enableDataDescription
          />
        </FirstRow>
      )}

      <div className="row g-0">
        <ColumnLeftContainer className="col-3 flex-column">
          <CustomRow>
            <TitleSection>
              <TooltipContainer>
                <CustomText
                  as="span"
                  font="font-regular"
                  fontSizePx={50}
                  lineHeightPx={46}
                  color={isDarkMode ? "white" : "primary"}
                >
                  {isTitleLabel ? translateLabel(title) : title}
                </CustomText>
                <FavoritesTooltipContainer position={externalReferenceTooltip[title]} />
              </TooltipContainer>
            </TitleSection>
            {showViewAll && url && (
              <SubTitle>
                <LinkButton
                  uppercase
                  onClick={() => {
                    history.push(url);
                  }}
                  data-element-id={dataElementIdViewAll}
                >
                  {translateLabel("VIEW_ALL")}
                </LinkButton>
              </SubTitle>
            )}
          </CustomRow>
          <CustomRow className="bottom-row">
            <NavigationSection>
              {totalSlides > 1 && totalSlides > slidesToShow ? (
                <CarouselNavBar
                  currentSlide={currentSlide}
                  slidesToScroll={slidesToScroll}
                  changeSlide={changeSlide}
                  currentPage={currentPage}
                  lastPage={lastPage}
                  navigationType="default"
                  {...(dataElementId ? { "data-element-id": dataElementId } : {})}
                  enableDataDescriptionNavigation={enableDataDescriptionNavigation}
                />
              ) : (
                <div></div>
              )}
            </NavigationSection>
          </CustomRow>
        </ColumnLeftContainer>

        <ColumnRightContainer className="col-9" data-element-id={dataElementIdSlider}>
          {totalSlides >= slidesToShow ? (
            <Slider ref={(val) => setSliderRef(val)} {...defaultSettings}>
              {children}
            </Slider>
          ) : (
            <NoCarouselContainer>{children}</NoCarouselContainer>
          )}
        </ColumnRightContainer>
      </div>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  position: relative;
`;

const MediumScreen = styled.div`
  display: none;
  @media ${(props) => props.theme.queries.md} {
    display: flex;
    flex-direction: column;
  }
`;

const MediumSectionFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DisplayContainer = styled.div`
  align-self: flex-start;
  margin-bottom: 0rem;
`;

const MediumSectionSecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.68rem;
`;

const FirstRow = styled.div`
  margin-bottom: 2.68rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media ${(props) => props.theme.queries.md} {
    display: none;
  }
`;

const NavigationSection = styled.div`
  @media ${(props) => props.theme.queries.md} {
    padding-right: 1rem;
  }
`;

const ColumnLeftContainer = styled.div`
  @media ${(props) => props.theme.queries.md} {
    display: none;
  }
`;

const CustomRow = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;

  &.bottom-row {
    justify-content: flex-end;
    align-items: flex-start;
  }
`;

const ColumnRightContainer = styled.div`
  @media ${(props) => props.theme.queries.md} {
    width: 100%;
  }
`;

const TitleSection = styled.div`
  max-width: 80%;
  word-wrap: break-word;

  @media ${(props) => props.theme.queries.md} {
    max-width: 100%;
  }
`;

const SubTitle = styled.div`
  padding-top: 2.8125rem;
`;

const NoCarouselContainer = styled.div`
  display: flex !important;
`;

const TooltipContainer = styled.div`
  display: flex;
  column-gap: 1.5rem;
  align-items: center;
`;

export default HomeCarousel;
