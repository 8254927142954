import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../../../../hooks/useTranslation";

import { getSearchCategories } from "../../../../store/search/searchSagas";
import { selectSearchCategories } from "../../../../store/search/searchSlice";
import { SearchCategories } from "../../../../store/search/searchInterfaces";
import CustomSelect from "../../../styled-UI/CustomSelect";
import Search from "../../../styled-UI/Search";
import { useParams } from "react-router";
import { selectCanBuyModelsGlobal, selectLocale } from "../../../../store/user/userSlice";

interface Props {
  closeExpandMenu: () => void;
  "data-element-id"?: string;
}

const SearchContainer = ({
  closeExpandMenu,
  "data-element-id": dataElementId,
}: Props): JSX.Element => {
  const { translateLabel, labels } = useTranslation();
  const dispatch = useDispatch();
  const [currentValueSearch, setCurrentValueSearch] = useState<string>("");
  const [defaultCategory, setDefaultCategory] = useState<SearchCategories>({
    label: translateLabel("CATEGORIES_ALL_CATEGORIES"),
    value: "DEFAULT",
  });
  const [selectedCategory, setSelectedCategory] = useState<SearchCategories>(defaultCategory);
  const receivedCategories = useSelector(selectSearchCategories);
  const [categoryOptions, setCategoryOptions] = useState<SearchCategories[]>([defaultCategory]);

  const currentLocale = useSelector(selectLocale);
  const searchCategories = useSelector(selectSearchCategories);
  const canBuyModelsGlobal = useSelector(selectCanBuyModelsGlobal);

  const { term } = useParams() as {
    term: string;
  };

  //set category according to filter in url
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams && receivedCategories) {
      let chipValue: string | undefined;
      queryParams.forEach((value, key) => {
        if (key === "PRODUCT_CATEGORY_CHIPS") {
          chipValue = value;
        }
      });

      if (chipValue) {
        const option = receivedCategories.find((_) => _.value === chipValue);
        if (option) setSelectedCategory(option);
      }
    }
  }, [location, receivedCategories]);

  useEffect(() => {
    if (searchCategories.length === 0) {
      dispatch(getSearchCategories());
    }
  }, [currentLocale]);

  //when label changes update search select
  useEffect(() => {
    if (labels) {
      setDefaultCategory({
        label: translateLabel("CATEGORIES_ALL_CATEGORIES"),
        value: "DEFAULT",
      });
      setSelectedCategory({
        label: translateLabel("CATEGORIES_ALL_CATEGORIES"),
        value: "DEFAULT",
      });
    }
  }, [labels]);

  //set value in search from url
  useEffect(() => {
    if (term) {
      setCurrentValueSearch(decodeURIComponent(term));
    } else {
      currentValueSearch && setCurrentValueSearch("");
    }
  }, [term]);

  //save options once they arrive from service
  useEffect(() => {
    if (canBuyModelsGlobal) {
      setCategoryOptions([defaultCategory, ...receivedCategories]);
    }
  }, [defaultCategory, receivedCategories, canBuyModelsGlobal]);

  return (
    <>
      <SelectContainer>
        <SelectWrapper>
          <CustomSelect
            // defaultValue={defaultCategory}
            // placeholder={translateLabel("CATEGORIES_ALL_CATEGORIES")}
            type="header"
            onChange={(option) => setSelectedCategory(option as SearchCategories)}
            options={categoryOptions}
            value={selectedCategory}
            isSearchable={false}
          ></CustomSelect>
        </SelectWrapper>
      </SelectContainer>

      <Search
        placeholder={translateLabel("HEADER_SEARCH_PLACEHOLDER")}
        category={selectedCategory}
        closeExpandMenu={closeExpandMenu}
        value={currentValueSearch}
        {...(dataElementId ? { "data-element-id": dataElementId } : {})}
      />
    </>
  );
};

const SelectContainer = styled.div`
  width: 11.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-shrink: 0;
  /* border-left: 1px solid ${(props) => props.theme.palette.gray.medium}; */
`;

const SelectWrapper = styled.div`
  width: 100%;

  &:focus {
    border: unset;
  }
`;

export default SearchContainer;
