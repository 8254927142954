import React from "react";
import QRCode from "react-qr-code";
import styled from "styled-components/macro";
import CustomText from "../styled-UI/CustomText";
import { LpBannerQrCode as Interface } from "../../interfaces/cmsInterfaces";
import useDataHref from "../../hooks/useDataHref";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  data: Interface;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LpBannerQrCode = ({ data, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  const { description, description1Colour, linkString } = data;
  const descrWithHref = useDataHref({ content: description });

  return (
    <Container>
      <OuterBox>
        <CustomText
          as="span"
          color={isDarkMode ? "white" : "gray-dark"}
          fontSizePx={14}
          font={customFont ? "custom-medium" : "font-medium"}
          fontFamily={customFont}
          marginBottomPx={80}
          textAlign="center"
          customColor={description1Colour}
        >
          {description && (
            <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? description} />
          )}
        </CustomText>
        <QRCodeContainer>
          <QRCode value={linkString ?? ""} size={186} />
        </QRCodeContainer>
      </OuterBox>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QRCodeContainer = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  padding: 2rem;
`;

const OuterBox = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default LpBannerQrCode;
