import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useCanRender from "../../hooks/useCanRender";
import { selectCurrentLang, selectLxConfigurations } from "../../store/store/storeSlice";
import {
  selectActiveDoor,
  selectAtLeastOneTruePrivileges,
  selectStoreIdentifier,
  selectUserHavingPrivilege,
} from "../../store/user/userSlice";

interface Props {
  setQualtricsRendered: () => void;
}

const QualtricsSurvey = ({ setQualtricsRendered }: Props): JSX.Element => {
  const atLeastOneTruePrivileges = useSelector(selectAtLeastOneTruePrivileges);
  const userHavingPrivilege = useSelector(selectUserHavingPrivilege);
  const storeIdentifier = useSelector(selectStoreIdentifier);
  const activeDoor = useSelector(selectActiveDoor);
  const currentLang = useSelector(selectCurrentLang);
  const LXConfiguration = useSelector(selectLxConfigurations);
  const [qualtricsReady, setQualtricsReady] = useState(false);

  const canRender = useCanRender();

  const qualtricsKey = LXConfiguration?.filter(
    (element) => element.key === "com.luxottica.qualtrics.parameter"
  )?.[0]?.value;

  useEffect(() => {
    if (canRender("QUALTRICS_SURVEY") && !qualtricsReady) {
      import("../../qualtrics").then((qualtrics) => {
        qualtrics.init(qualtricsKey);
        setQualtricsReady(true);
        setQualtricsRendered();
      });
    }
    if (!canRender("QUALTRICS_SURVEY")) {
      //If the user doesn't need the qualtrics survey, set it as default rendered to skip the check
      setQualtricsRendered();
    }
  }, [atLeastOneTruePrivileges, userHavingPrivilege]);

  useEffect(() => {
    if (currentLang) {
      window.qualtricsLanguage = currentLang;
    }
  }, [currentLang]);

  return qualtricsReady && currentLang && activeDoor ? (
    <>
      <input type="hidden" id="qualtricsBukrs" value={storeIdentifier} />
      <input type="hidden" id="qualtricsKunnr" value={activeDoor?.orgentityName} />
      <input type="hidden" id="qualtricsUsername" value={activeDoor?.username} />
      <input type="hidden" id="qualtricsLanguage" value={currentLang} />

      <div id={qualtricsKey}></div>
    </>
  ) : (
    <></>
  );
};

export default QualtricsSurvey;
