import { cloneDeep } from "lodash";
import { RequestStatus } from "../interfaces/mainInterfaces";
import { ItemPrice, Variant } from "../interfaces/productInterface";
import { SparePartsSku } from "../store/aftersales/aftersalesInterface";
import {
  Address,
  CheckPrescriptionBrasilPayloadByDoor,
  OrderCategory,
  OrderCategoryInfo,
  OrderCategorySelected,
  OrderCategorySummary,
  OrderItem,
  OrderItemExtendAttribute,
  OrderItemPut,
  OrderItemSelected,
  OrderItemToKeep,
  OrderMultidoor,
  OrderMultidoorAddress,
  OrderMultidoorInfo,
  OrderMultidoorSelected,
  PartNumbers,
  PostEssilorPrecartItemsPayload,
  PostPrecartItemsPayload,
  PostPrecartOrderItemPayload,
  PutPrecartItemsPayload,
  RxPrescriptionBrasilStatus,
  ShippingAddressSummary,
  ShippingAddressSummaryMultidoor,
  SplitFrames,
  SubOrderPayload,
  UpdatePrecartItemPayload,
  UpdatePrecartItemsPayload,
} from "../store/cart/cartInterfaces";
import {
  AvailabilityStatusByDoor,
  EssilorOrderData,
  PreCartProduct,
} from "../store/catalogue/catalogueInterface";
import {
  CheckCarnetOutcomeOrderItem,
  CheckoutOrderCategoryInfo,
  CheckoutOrderMultidoorInfo,
} from "../store/checkout/checkoutInterfaces";
import { checkIsAFAOrHelmet, orderAFASize } from "./AFAutils";
import { instanceOfSparePartsSku } from "./aftersalesUtils";
import { getAttributeValues, mapSkuObj } from "./productUtils";
import { covertStringToInt } from "./utils";
import { Sku } from "../interfaces/productInterface";
import { useSelector } from "react-redux";
import { selectLxConfigurations } from "../store/store/storeSlice";

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// ORDER ITEMS ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get extendAttribute specified as parameter
 *
 * @param {OrderItemExtendAttribute[]} attributes
 * @param {string} attrId
 * @return {*}  {(OrderItemExtendAttribute | undefined)}
 */
export const getExtendAttribute = (
  attributes: OrderItemExtendAttribute[],
  attrId: string
): OrderItemExtendAttribute | undefined => {
  if (!attributes) return undefined; //check if array is undefined
  return attributes.find((_: OrderItemExtendAttribute) => _.attributeName === attrId);
};

/**
 * get number of availableQuantity for AFA products
 *
 * @param {OrderItemExtendAttribute[]} attributes
 * @param {string} attrId
 * @return {*}  {string}
 */
export const getAvailableQuantity = (orderItem: any): string => {
  const mainAvailDate = getExtendAttribute(orderItem?.orderItemExtendAttribute, "mainAvail")
    ?.attributeValue;
  const secondaryAvailDate = getExtendAttribute(
    orderItem?.orderItemExtendAttribute,
    "secondaryAvail"
  )?.attributeValue;

  let availability = covertStringToInt(mainAvailDate) + covertStringToInt(secondaryAvailDate);

  //fix for negative quantity
  if (availability < 0) {
    availability = 0;
  }
  return availability.toString();
};

export const getAvailableQuantityTotal = (orders: OrderItem[]): string => {
  const totalQuantity = orders
    .map((order) => order.availableQuantity ?? "0")
    .reduce((prevQuantity, nextQuantity) => {
      const total = covertStringToInt(prevQuantity) + covertStringToInt(nextQuantity);

      return total.toString();
    });

  return totalQuantity;
};

export const getTomorrowDate = (): Date => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const getOutOfStock = (orderItem: any): boolean => {
  if (checkIsAFAOrHelmet(orderItem.productsDetails.productCategory)) {
    const quantity = getAvailableQuantity(orderItem);

    if (parseInt(quantity) <= 0) return true;
  }
  if (orderItem?.orderItemExtendAttribute) {
    return (
      getExtendAttribute(orderItem?.orderItemExtendAttribute, "isOutOfStock")?.attributeValue ===
      "Y"
    );
  }

  return false;
};

const mapOrderItemPrice = (orderItem: any): ItemPrice => {
  const mappedPrice: ItemPrice = {
    opt: {
      orderItemPrice: orderItem?.price?.opt?.orderItemPrice,
      unitPrice: orderItem?.price?.opt?.unitPrice,
    },
    pub: {
      orderItemPrice: orderItem?.price?.pub?.orderItemPrice,
      unitPrice: orderItem?.price?.pub?.unitPrice,
    },
  };

  const isRxPrice =
    Object.keys(orderItem).some((item) => item.includes("xitem_RXp")) &&
    orderItem.xitem_RXframeSrPrice &&
    orderItem?.xitem_RXlensSrPrice;

  if (isRxPrice) {
    const isLensOnlyOrFrameToCome =
      orderItem?.xitem_field1 === "4" || orderItem?.xitem_field1 === "5";

    const publicPrice = isLensOnlyOrFrameToCome
      ? +orderItem?.xitem_RXlensSrPrice
      : +orderItem?.xitem_RXframeSrPrice + +orderItem?.xitem_RXlensSrPrice;

    return {
      opt: mappedPrice?.opt,
      pub: {
        orderItemPrice: publicPrice,
        unitPrice: publicPrice,
      },
    };
  } else return mappedPrice;
};

/**
 * Map orderItemList array of api objects to our OrderItem array
 *
 * @param {any[]} orderItemArray // NOTE: needs to remain any, as this utils maps from the result of the service!!
 * @return {*}  {OrderItem[]}
 */
const mapOrderItemArrayAFA = (orderItemArray: any[]): OrderItem[] => {
  //DIVIDE ORDER ITEM PER xitem_fromRequestId, variantCode and availableDate

  const fakeOrderItemArray: OrderItem[] = [];
  const normalOrderItemArray: OrderItem[] = [];

  orderItemArray.forEach((orderItem) => {
    const sku = mapSkuObj(orderItem?.productsDetails);

    if (checkIsAFAOrHelmet(sku.productCategory)) {
      const variantCode = orderItem?.productsDetails?.["identifier.mpn.raw"] ?? "";
      const availableDate = orderItem?.availableDate
        ? new Date(orderItem.availableDate)
        : getTomorrowDate();

      const requestedShipDate = orderItem?.requestedShipDate
        ? new Date(orderItem.requestedShipDate)
        : getTomorrowDate();

      const key = orderItem.xitem_fromRequestId + variantCode + availableDate + requestedShipDate;

      let index = fakeOrderItemArray.findIndex((_) => _.orderItemId === key);
      const singleOrder = mapOrderItem(orderItem);

      if (index !== -1) {
        fakeOrderItemArray[index].quantity += +orderItem.quantity;
        fakeOrderItemArray[index].price.opt.orderItemPrice += orderItem?.price?.opt?.orderItemPrice;
        fakeOrderItemArray[index]?.orders?.push(singleOrder);
      } else {
        const item: OrderItem = {
          orderItemId: key,
          xitem_fromRequestId: orderItem.xitem_fromRequestId,
          productCode: orderItem?.productsDetails?.["identifier.pn.parent"] ?? "",
          variantCode: variantCode,
          productName: orderItem.productsDetails?.name,
          quantity: +orderItem.quantity,
          requestedShipDate: requestedShipDate, // fallback to tomorrow if requestedShipDate is not specified
          availableDate: availableDate, // fallback to tomorrow if availableDate is not specified
          price: mapOrderItemPrice(orderItem),
          sku: sku,
          skuCode: orderItem?.partNumber ?? "",
          customerReference:
            orderItem?.orderItemExtendAttribute &&
            getExtendAttribute(orderItem?.orderItemExtendAttribute, "customerReference")
              ?.attributeValue,
          split: mapSplit(orderItem?.split),
          orders: [singleOrder],
          outOfStock: false,
        };

        fakeOrderItemArray.push(item);
      }

      if (index === -1) index = fakeOrderItemArray.findIndex((_) => _.orderItemId === key);
      fakeOrderItemArray[index].orders = orderAFASize(
        fakeOrderItemArray[index]?.orders ?? [],
        "xitem_modelSize"
      );
    } else {
      const normalOrderItem = mapOrderItem(orderItem);

      normalOrderItemArray.push(normalOrderItem);
    }
  });

  return [...fakeOrderItemArray, ...normalOrderItemArray];
};

/**
 * Map orderItemList array of api objects to our OrderItem array
 *
 * @param {any[]} orderItemArray // NOTE: needs to remain any, as this utils maps from the result of the service!!
 * @return {*}  {OrderItem[]}
 */
const mapOrderItemArray = (orderItemArray: any[]): OrderItem[] => {
  return orderItemArray?.map((orderItem: any): OrderItem => mapOrderItem(orderItem));
};

const mapOrderItem = (orderItem: any): OrderItem => {
  const isOnePortal = orderItem.productId === "-3";
  return {
    orderItemId: orderItem?.orderItemId,
    productName: isOnePortal ? "" : orderItem?.productsDetails?.name,
    productCode: isOnePortal ? "" : orderItem?.productsDetails?.["identifier.pn.parent"] ?? "",
    variantCode: isOnePortal ? "" : orderItem?.productsDetails?.["identifier.mpn.raw"] ?? "",
    skuCode: orderItem?.partNumber ?? "",
    quantity: +orderItem?.quantity,
    requestedShipDate: orderItem?.requestedShipDate
      ? new Date(orderItem.requestedShipDate)
      : getTomorrowDate(), // fallback to tomorrow if requestedShipDate is not specified
    availableDate: orderItem?.availableDate ? new Date(orderItem.availableDate) : getTomorrowDate(), // fallback to tomorrow if availableDate is not specified
    price: mapOrderItemPrice(orderItem),
    sku: isOnePortal
      ? mapOnePortalSku(orderItem)
      : orderItem.productId !== "-1"
      ? mapSkuObj(orderItem?.productsDetails)
      : mapSparePartsSku(orderItem),
    customerReference:
      orderItem?.orderItemExtendAttribute &&
      getExtendAttribute(orderItem?.orderItemExtendAttribute, "customerReference")?.attributeValue,
    outOfStock: isOnePortal ? false : getOutOfStock(orderItem),
    split: mapSplit(orderItem?.split),
    xitemRXbrand: orderItem?.xitem_RXbrand ?? "",
    xitemField1: orderItem?.xitem_field1 ?? "",
    xitemRXfocalTypeName: orderItem?.xitem_RXfocalTypeName ?? "",
    xitemM4CbagId: orderItem?.xitem_M4CbagId,
    xitemM4CCustomVendorId: orderItem?.xitem_M4CCustomVendorId,
    xitemM4CCustomModelCode: orderItem?.xitem_M4CCustomModelCode,
    xitemM4CCustomRegion: orderItem?.xitem_M4CCustomRegion,
    xitemM4CCustomHierarchy: orderItem?.xitem_M4CCustomHierarchy,
    xitemM4CCustomProductId: orderItem?.xitem_M4CCustomProductId,
    xitemM4CConfigurationId: orderItem?.configurationID,
    rightadd: orderItem?.xitem_RXpRadd,
    rightaxis: orderItem?.xitem_RXpRaxis,
    rightcylinder: orderItem?.xitem_RXpRcylinder,
    rightdirection: orderItem?.xitem_RXpRdirection,
    rightdirectionud: orderItem?.xitem_RXpRdirectionUd,
    rightfaceformtilt: orderItem?.xitem_RXpRfaceformTilt,
    rightnear: orderItem?.xitem_RXpRnear,
    rightocht: orderItem?.xitem_RXpRocht,
    rightpantotilt: orderItem?.xitem_RXpRpantoTilt,
    rightpd: orderItem?.xitem_RXpRpd,
    rightprism: orderItem?.xitem_RXpRprism,
    rightprismud: orderItem?.xitem_RXpRprismUd,
    rightseght: orderItem?.xitem_RXpRseght,
    rightsphere: orderItem?.xitem_RXpRsphere,
    rightvertexfitted: orderItem?.xitem_RXpRvertexFitted,
    leftadd: orderItem?.xitem_RXpLadd,
    leftaxis: orderItem?.xitem_RXpLaxis,
    leftcylinder: orderItem?.xitem_RXpLcylinder,
    leftdirection: orderItem?.xitem_RXpLdirection,
    leftdirectionud: orderItem?.xitem_RXpLdirectionUd,
    leftfaceformtilt: orderItem?.xitem_RXpLfaceformTilt,
    leftnear: orderItem?.xitem_RXpLnear,
    leftocht: orderItem?.xitem_RXpLocht,
    leftpantotilt: orderItem?.xitem_RXpLpantoTilt,
    leftpd: orderItem?.xitem_RXpLpd,
    leftprism: orderItem?.xitem_RXpLprism,
    leftprismud: orderItem?.xitem_RXpLprismUd,
    leftseght: orderItem?.xitem_RXpLseght,
    leftsphere: orderItem?.xitem_RXpLsphere,
    leftvertexfitted: orderItem?.xitem_RXpLvertexFitted,
    lensId: orderItem?.xitem_RXlensId ?? "",
    lensDescription: orderItem?.xitem_RXlensDescription ?? "",
    rxcustomerReference:
      `${orderItem?.xitem_RXcustomerReference1} ${orderItem?.xitem_RXcustomerReference2}` ?? "",
    xitem_modelSize: orderItem.xitem_modelSize,
    carnet: mapCarnetInfo(orderItem),
    receivePattern:
      orderItem?.orderItemExtendAttribute &&
      getExtendAttribute(orderItem?.orderItemExtendAttribute, "receivePattern")?.attributeValue,
    availableQuantity: getAvailableQuantity(orderItem),
    currency: orderItem?.currency,
  };
};

export const mapSparePartsSku = (orderItem: any): SparePartsSku => {
  const sku = {
    uniqueID: orderItem?.productId, // TODO: -1 schiantato
    partNumber: orderItem?.partNumber,
    // catEntry: "string",
    brand: orderItem?.productsDetails?.manufacturer,
    SPproductCategory: "SPARE_PARTS",
    img:
      getAttributeValues(orderItem?.productsDetails?.attributes, "DL_COLOR_CODE")?.values ===
      orderItem?.xitem_SPgridDim1
        ? orderItem?.productsDetails?.fullImage
        : undefined,
    // name: "string",
    // description: "string",
    // seo: Seo,
    size: {
      identifier: "DL_SIZE_CODE",
      values: [
        {
          identifier: orderItem?.xitem_SPasta || orderItem?.xitem_SPgridDim2,
          value: orderItem?.xitem_SPasta || orderItem?.xitem_SPgridDim2,
          uniqueID: orderItem?.xitem_SPasta || orderItem?.xitem_SPgridDim2,
        },
      ],
    },
    // upc: "string",
    colorCode: orderItem?.xitem_SPgridDim1,
    // frontColorDescription: AttributeSlim,
    // attributes: Attribute[],
    asta: orderItem?.xitem_SPasta,
    gridValue: orderItem?.xitem_SPgridValue,
    gridDim2: orderItem?.xitem_SPgridDim2,
    sparePartsCode: orderItem?.xitem_SPproduct,
    sparePartsDescription: orderItem?.xitem_SPdescription,
    notOrderFlag: orderItem?.xitem_SPnotOrderFlag?.toLowerCase() === "true",
    idFamily: orderItem?.xitem_SPidFamily,
    component: orderItem?.xitem_SPcomponent,
    uom: orderItem?.xitem_SPuom,
    availQty: orderItem?.xitem_SPavailQty,
  };

  return sku;
};

export const mapOnePortalSku = (orderItem: any): Sku => {
  const sku = {
    uniqueID: orderItem?.productId, // TODO: -1 schiantato
    partNumber: orderItem?.partNumber,
  };

  return sku;
};

/**
 * Map order categoryList array of api objects to our OrderCategory array
 *
 * @param {any[]} orderCategoryArray
 * @return {*}  {OrderCat[]}
 */
export const mapOrderCategoryArray = (orderCategoryArray: any[]): OrderCategory[] => {
  return orderCategoryArray?.map(
    (orderCategory: any): OrderCategory => {
      return {
        productCategory: orderCategory?.productCategory,
        productCategoryIdentifier: orderCategory?.productCategoryIdentifier,
        orderItemList:
          orderCategory?.productCategoryIdentifier?.toLowerCase() === "afa" ||
          orderCategory?.productCategoryIdentifier?.toLowerCase() === "goggles&helmets" ||
          orderCategory?.productCategoryIdentifier?.toLowerCase() === "goggle" ||
          orderCategory?.productCategoryIdentifier?.toLowerCase() === "helmet"
            ? mapOrderItemArrayAFA(orderCategory?.orderResponseItemList)
            : mapOrderItemArray(orderCategory?.orderResponseItemList),
      };
    }
  );
};

/**
 * Map order multidoor array of api objects to our OrderMultidoor array
 *
 * @param {any[]} orderMultidoorArray
 * @return {*}  {OrderMultidoor[]}
 */
export const mapOrderMultidoorArray = (orderMultidoorArray: any[]): OrderMultidoor[] => {
  return (
    orderMultidoorArray?.map(
      (orderMultidoor: any): OrderMultidoor => {
        return {
          multidoorId: orderMultidoor?.multidoor_id,
          subOrderId: orderMultidoor?.subOrderId,
          orgentityName: orderMultidoor?.orgentityName,
          categoryList: putOutOfStockFirst(
            mapOrderCategoryArray(orderMultidoor?.categoryResponseList)
          ),
        };
      }
    ) ?? []
  );
};

/**
 * Map out of stock part numbers array PartNumbers array
 *
 * @param {any[]} partNumbersArray
 * @return {*}  {PartNumbers[]}
 */
export const mapOutOfStockPartNumbersArray = (partNumbersArray: any[]): PartNumbers[] => {
  return (
    partNumbersArray?.map(
      (partNumber: any): PartNumbers => {
        return {
          partNumber: partNumber?.partNumber,
          manufacturer: partNumber?.manufacturer,
        };
      }
    ) ?? []
  );
};

/**
 * Reorder order categories to put OutOfStock section first if available
 *
 * @export
 * @param {OrderCategory[]} orderCategoryArray
 * @return {*}  {OrderCategory[]}
 */
export function putOutOfStockFirst(orderCategoryArray: OrderCategory[]): OrderCategory[] {
  const outOfStockCategory = orderCategoryArray?.find((_) => isOutOfStock(_?.productCategory));

  if (outOfStockCategory)
    return [
      outOfStockCategory,
      ...orderCategoryArray?.filter((_) => !isOutOfStock(_?.productCategory)),
    ];
  else return orderCategoryArray;
}

/**
 * Map carnet, if present
 *
 * @param {*} orderItem
 * @return {*}  {(CarnetInfo | undefined)}
 */
export const mapCarnetInfo = (orderItem: any): CheckCarnetOutcomeOrderItem | undefined => {
  if (orderItem?.xitem_RXcarnetCode)
    return {
      carnetCode: orderItem?.xitem_RXcarnetCode ?? "",
      carnetNumber: orderItem?.xitem_RXcarnetNumber ?? "",
      carnetDescription: orderItem?.xitem_RXcarnetDescription,
      carnetTotalValue: orderItem?.xitem_RXcarnetTotalValue,
      carnetResidualValue: orderItem?.xitem_RXcarnetResidualValue,
      lensPrice: orderItem?.xitem_RXcarnetLensPrice,
      lensDiscountPercentage: orderItem?.xitem_RXcarnetLensDiscountPercentage,
      lensDiscountValue: orderItem?.xitem_RXcarnetLensDiscountValue,
      lensDiscountedPrice: orderItem?.xitem_RXcarnetLensDiscountedPrice,
      currency: orderItem?.currency,
    };
  else return undefined;
};

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// SELECTED ITEMS //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Map array of OrderItem objects to our OrderItemSelected array
 *
 * @param {OrderItem[]} orderItemArray
 * @return {*}  {OrderItemSelected[]}
 */
export const mapOrderItemSelected = (orderItemArray: OrderItem[]): OrderItemSelected[] => {
  return orderItemArray.map(
    (orderItem: OrderItem): OrderItemSelected => {
      const obj: OrderItemSelected = {
        orderItemId: orderItem?.orderItemId,
        selected: !orderItem?.outOfStock, // only select it if it's not outofstock
        quantity: orderItem?.quantity,
        isRxItem: isOrderItemRx(orderItem),
      };

      if (orderItem?.orders && orderItem?.orders.length > 0) {
        // obj.availableQuantity = getAvailableQuantityTotal(orderItem?.orders);
        obj.singleOrderItemSelected = orderItem?.orders.map((_) => {
          return {
            orderItemId: _.orderItemId,
            quantity: _.quantity,
            availableQuantity: _.availableQuantity,
          };
        });
      }

      return obj;
    }
  );
};

/**
 * Map array of OrderCategory objects to our OrderCategorySelected array
 *
 * @param {OrderCategory[]} orderCategoryArray
 * @return {*}  {OrderCategorySelected[]}
 */
export const mapOrderCategorySelected = (
  orderCategoryArray: OrderCategory[]
): OrderCategorySelected[] => {
  return orderCategoryArray?.map(
    (orderCategory: OrderCategory): OrderCategorySelected => {
      return {
        productCategory: orderCategory?.productCategory,
        productCategoryIdentifier: orderCategory.productCategoryIdentifier,
        orderItemSelected: mapOrderItemSelected(orderCategory?.orderItemList),
      };
    }
  );
};

/**
 * Map array of OrderMultidoor objects to our OrderMultidoorSelected array
 *
 * @param {OrderMultidoor[]} orderMultidoorArray
 * @return {*}  {OrderMultidoorSelected[]}
 */
export const mapOrderMultidoorSelected = (
  orderMultidoorArray: OrderMultidoor[]
): OrderMultidoorSelected[] => {
  return orderMultidoorArray?.map(
    (orderMultidoor: OrderMultidoor): OrderMultidoorSelected => {
      return {
        multidoorId: orderMultidoor?.multidoorId,
        orgentityName: orderMultidoor?.orgentityName,
        subOrderId: orderMultidoor?.subOrderId,
        categorySelected: mapOrderCategorySelected(orderMultidoor?.categoryList),
      };
    }
  );
};

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// CART INFO ////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get number of selected orderItems inside given category
 *
 * @param {OrderItemSelected[]} orderItemArray
 * @return {*}  {number}
 */
export const getSelectedNumberCategory = (orderItemArray: OrderItemSelected[]): number => {
  return Object.values(orderItemArray)?.reduce((totItems, orderItem) => {
    if (orderItem?.selected) return totItems + orderItem?.quantity;
    else return totItems;
  }, 0);
};

/**
 * Get total number of orderItems inside given category
 *
 * @param {OrderItemSelected[]} orderItemArray
 * @return {*}  {number}
 */
export const getTotalNumberCategory = (
  orderItemArray: OrderItemSelected[] | OrderItem[]
): number => {
  return Object.values(orderItemArray)?.reduce((totItems, orderItem) => {
    return totItems + orderItem?.quantity;
  }, 0);
};

/**
 * Get number of selected orderItems inside given door
 *
 * @param {OrderCategoryInfo[]} orderCategoryArray
 * @return {*}  {number}
 */
export const getSelectedNumberMultidoor = (orderCategoryArray: OrderCategoryInfo[]): number => {
  return Object.values(orderCategoryArray)?.reduce((totItems, orderCategory) => {
    return totItems + orderCategory?.selectedNumber;
  }, 0);
};

/**
 * Get total number of orderItems inside given door
 *
 * @param {OrderCategoryInfo[]} orderCategoryArray
 * @return {*}  {number}
 */
export const getTotalNumberMultidoor = (
  orderCategoryArray: OrderCategoryInfo[] | CheckoutOrderCategoryInfo[]
): number => {
  return Object.values(orderCategoryArray)?.reduce((totItems, orderCategory) => {
    return totItems + orderCategory?.totalNumber;
  }, 0);
};

/**
 * Get total number of out of Stock items inside given door
 *
 * @param {(OrderCategoryInfo[])} orderCategoryArray
 * @return {*}  {number}
 */
export const getTotalOutOfStockNumberMultidoor = (
  orderCategoryArray: OrderCategoryInfo[]
): number => {
  return Object.values(orderCategoryArray)?.reduce((totItems, orderCategory) => {
    const totOutOfStock =
      orderCategory?.productCategory === "Unallocated" ? orderCategory?.totalNumber : 0;
    return totItems + totOutOfStock;
  }, 0);
};

/**
 * Get number of selected orderItems inside whole cart
 *
 * @param {OrderMultidoorInfo[]} orderMultidoorArray
 * @return {*}  {number}
 */
export const getSelectedNumberCart = (orderMultidoorArray: OrderMultidoorInfo[]): number => {
  return Object.values(orderMultidoorArray)?.reduce((totItems, orderMultidoor) => {
    return totItems + orderMultidoor?.selectedNumber;
  }, 0);
};

/**
 * Get total number of orderItems inside whole cart
 *
 * @param {OrderMultidoorInfo[] | CheckoutOrderMultidoorInfo[]} orderMultidoorArray
 * @return {*}  {number}
 */
export const getTotalNumberCart = (
  orderMultidoorArray: OrderMultidoorInfo[] | CheckoutOrderMultidoorInfo[]
): number => {
  return (
    orderMultidoorArray &&
    Object.values(orderMultidoorArray)?.reduce((totItems, orderMultidoor) => {
      return totItems + orderMultidoor?.totalNumber;
    }, 0)
  );
};

/**
 * Get total number of out of stock items inside whole cart
 *
 * @param {OrderMultidoorInfo[]} orderMultidoorArray
 * @return {*}  {number}
 */
export const getOutOfStockNumberCart = (orderMultidoorArray: OrderMultidoorInfo[]): number => {
  return Object.values(orderMultidoorArray)?.reduce((totItems, orderMultidoor) => {
    return totItems + orderMultidoor?.outOfStockNumber;
  }, 0);
};

/**
 * Check if the given orderItem is present in array orderItemsToKeep for the corresponding orderCategory
 *
 * @param {OrderItemToKeep[]} orderItemsToKeep
 * @param {string} orderItemId
 * @param {string} orderCategory
 * @return {*}  {boolean}
 */
export const isOrderItemToKeep = (
  orderItemsToKeep: OrderItemToKeep[],
  orderItemId: string,
  orderCategory: string
): boolean => {
  return (
    orderItemsToKeep?.find(
      (_) => _?.orderItemId === orderItemId && _?.orderCategory == orderCategory
    ) !== undefined
  );
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// CART SUMMARY ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get the total sum of selected orderItems in given category
 *
 * @param {OrderItem[]} orderItemArray
 * @param {string[]} [selectedOrderItemsIds]
 * @return {*}  {number}
 */
export const getTotalPriceCategory = (
  orderItemArray: OrderItem[],
  selectedOrderItemsIds?: string[]
): number => {
  if (selectedOrderItemsIds) {
    return Object.values(orderItemArray)?.reduce((totalPrice, orderItem) => {
      if (
        selectedOrderItemsIds.includes(orderItem.orderItemId) &&
        orderItem?.price?.opt?.orderItemPrice
      )
        return round(totalPrice + orderItem?.price?.opt?.orderItemPrice);
      else return totalPrice;
    }, 0);
  } else {
    return Object.values(orderItemArray)?.reduce((totalPrice, orderItem) => {
      return orderItem?.price?.opt?.orderItemPrice
        ? round(totalPrice + orderItem?.price?.opt?.orderItemPrice)
        : round(totalPrice);
    }, 0);
  }
};

/**
 * Get the total sum of selected orderItems in given door
 *
 * @param {OrderCategorySummary[]} orderCategoryArray
 * @return {*}  {number}
 */
export const getTotalPriceMultidoor = (orderCategoryArray: OrderCategorySummary[]): number => {
  return Object.values(orderCategoryArray)?.reduce((totalPrice, orderCategory) => {
    return round(totalPrice + orderCategory?.totalPrice);
  }, 0);
};

/**
 * Round numbers
 *
 * @param {number} x
 * @return {*}  {number}
 */
export const round = (x: number): number => parseFloat(x?.toFixed(2));

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////// CART POST & UPDATE /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Map any array into a OrderItemExtendedAttributes object,
 * by picking only the relevant values
 *
 * @param {*} attributes
 * @return {*}
 */
export const mapExtendAttributes = (attributes: any) => {
  const attrIds = ["customerReference", "receivePattern"];

  const filterAttrs = attrIds.filter((_) => attributes?.[_] || attributes?.[_] === "");

  return filterAttrs.map((_) => {
    return {
      attributeName: _ as string,
      attributeValue: attributes?.[_],
    };
  });
};

/**
 * Map any array into a OrderItemExtendedAttributes object,
 * by picking only the relevant values
 *
 * @param {*} attributes
 * @return {*}
 */
export const mapEssilorOrderExtendAttributes = (
  orderData: EssilorOrderData | undefined,
  isAPIFallback: boolean
): OrderItemExtendAttribute[] => {
  if (orderData) {
    const orderParse = orderData?.Order && JSON.parse(orderData?.Order);
    const result = [
      {
        attributeName: "OnePortalConfigurationId",
        attributeValue: (orderParse.OrderIdBase64 as string) ?? "",
      },
    ];
    if (isAPIFallback) {
      result.push({
        attributeName: "OnePortalXml",
        attributeValue: orderData.Xml ?? "",
      });
      result.push({
        attributeName: "OnePortalJson",
        attributeValue: orderData.Order ?? "",
      });
    }
    return result;
  }
  return [];
};

/**
 * Get payload for putPrecartItems service
 *
 * @param {UpdatePrecartItemPayload} sagaPayload
 * @return {*}  {PutPrecartItemsPayload}
 */
export const getPutPrecartItemsArrayPayload = (
  sagaPayload: UpdatePrecartItemsPayload,
  orderId?: string
): PutPrecartItemsPayload => {
  const orderItems = sagaPayload.orderItems.map((_) => mapOrderItemForPut(_));
  const payload: PutPrecartItemsPayload = {
    orderId,
    orderItem: orderItems,
  };

  return payload;
};

/**
 * Get payload for putPrecartItems service
 *
 * @param {UpdatePrecartItemPayload} sagaPayload
 * @return {*}  {PutPrecartItemsPayload}
 */
export const getPutPrecartItemsPayload = (
  sagaPayload: UpdatePrecartItemPayload,
  orderId?: string
): PutPrecartItemsPayload => {
  const orderItem = mapOrderItemForPut(sagaPayload);
  const payload: PutPrecartItemsPayload = {
    orderId,
    orderItem: [orderItem],
  };

  return payload;
};

export const mapOrderItemForPut = (sagaPayload: UpdatePrecartItemPayload): OrderItemPut => {
  const orderItem: OrderItemPut = {
    orderItemId: sagaPayload?.orderItemId,
    // xitem_startShipDate: "",
  };

  // add quantity, if provided
  if (sagaPayload?.quantity) orderItem.quantity = String(sagaPayload.quantity);

  // add requested shipdate, if provided
  if (sagaPayload?.requestedShipDate)
    orderItem.xitemRequestedShipDate = sagaPayload.requestedShipDate.toISOString();

  // add xitem_configurationId, if provided
  if (sagaPayload?.xitem_configurationId)
    orderItem.xitem_configurationId = sagaPayload.xitem_configurationId;

  // add extendAttributes, if any
  const extendAttributes = mapExtendAttributes(sagaPayload);
  if (extendAttributes.length > 0) orderItem.orderItemExtendAttribute = extendAttributes;

  return orderItem;
};

/**
 * Get payload for postPrecartItems service
 *
 * @param {string} orderId
 * @param {PreCartProduct[]} sagaPayload
 * @return {*}  {PostPrecartItemsPayload}
 */
export const getPostPrecartItemsPayload = (
  orderId: string,
  sagaPayload: PreCartProduct[],
  customerReferenceAFA?: string | null
): PostPrecartItemsPayload => {
  const payload: PostPrecartItemsPayload = {
    orderId: orderId,
    orderItem: sagaPayload.map((_) => {
      const itemPayload: PostPrecartOrderItemPayload = {
        productId: _?.sku?.uniqueID,
        quantity: String(_?.quantity),
        orderItemExtendAttribute: mapExtendAttributes(_),
        xitem_upc: _?.sku?.upc,
        xitem_multidoor: _?.multidoorId,
      };

      if (customerReferenceAFA) {
        itemPayload.orderItemExtendAttribute = mapExtendAttributes({
          customerReference: customerReferenceAFA,
        });
      }

      if (_?.shoppingBagId) {
        itemPayload.xitem_bagId = _?.shoppingBagId;
      }

      if (_?.brandCode) {
        itemPayload.xitem_brandCode = _.brandCode;
      }

      if (_?.recipeId) {
        itemPayload.xitem_configurationId = _?.recipeId;
      }

      if (_?.requestedShipDate) {
        itemPayload.xitem_requestedShipDate = _?.requestedShipDate;
      }

      if (instanceOfSparePartsSku(_.sku)) {
        itemPayload["xitem_SPparentSkuCatentry"] = _?.sku?.SPparentSkuCatentry;
        itemPayload["xitem_SPproduct"] = _?.sku?.sparePartsCode;
        itemPayload["xitem_SPdescription"] = _?.sku?.sparePartsDescription;
        itemPayload["xitem_SPidFamily"] = _?.sku?.idFamily;
        itemPayload["xitem_SPasta"] = _?.sku?.asta;
        itemPayload["xitem_SPgridValue"] = _?.sku?.gridValue;
        itemPayload["xitem_SPgridDim1"] = _?.sku?.colorCode?.values;
        itemPayload["xitem_SPgridDim2"] = _?.sku?.gridDim2;
        itemPayload["xitem_SPcomponent"] = _?.sku?.component;
        itemPayload["xitem_SPunitPrice"] = _?.sku?.SPprice ? String(_?.sku?.SPprice) : undefined;
        // itemPayload["xitem_SPcurrency"] = _?.sku?."";
        itemPayload["xitem_SPnotOrderFlag"] = String(_?.sku?.notOrderFlag);
        itemPayload["xitem_SPavailQty"] = _?.sku?.availQty;
        itemPayload["xitem_SPuom"] = _?.sku?.uom;
      }
      return itemPayload;
    }),
  };

  return payload;
};

/**
 * Get payload for postEssilorPrecartItems service
 */
export const getPostEssilorPrecartItemsPayload = (
  orderId: string,
  sagaPayload: EssilorOrderData,
  isAPIFallback: boolean
): PostEssilorPrecartItemsPayload => {
  const payload: PostEssilorPrecartItemsPayload = {
    orderId: orderId,
    orderItem: [
      {
        productId: "-3", //FOR ESSILOR ORDERS, WE USE THE -3 IDENTIFIER
        quantity: "1", //FOR ESSILOR, QUANTITY IS ALWAYS 1
      },
    ],
    orderExtendAttribute: mapEssilorOrderExtendAttributes(sagaPayload, isAPIFallback),
  };

  return payload;
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////// CART SHIPPING INFO /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Map shipping address info for dropdown in cart summary
 *
 * @param {*} address
 * @return {*}  {ShippingAddress}
 */
export const mapShippingAddressSummary = (address: any): ShippingAddressSummary => {
  return {
    addressId: address?.id,
    address1: address?.address1,
    city: address?.city,
    zipcode: address?.zipCode,
    country: address?.country,
    state: address?.state,
    nickName: address?.nickName,
    firstName: address?.firstName,
    orgentityName: address?.orgentityName,
    customerName: address?.customerName,
    addressType: address?.addressType,
    memberId: address?.memberId,
    selfAddress: address?.selfAddress,
    email1: address?.email1,
  };
};

export const mapShippingAddressSummaryMultidoor = (
  shippingList: any
): ShippingAddressSummaryMultidoor[] => {
  return shippingList?.map(
    (_: any): ShippingAddressSummaryMultidoor => {
      return {
        multidoorId: _?.multidoor_id,
        addressList: _?.addressTypeListDTO?.addressList?.map(
          (address: any): ShippingAddressSummary => {
            return mapShippingAddressSummary(address);
          }
        ),
      };
    }
  );
};

/**
 * Print address as show in dropdown in cart summary
 *
 * @param {ShippingAddressSummary} address
 * @return {*}  {string}
 */
export const printShippingAddressSummary = (shippingAddress: ShippingAddressSummary): string => {
  const nickName = shippingAddress?.nickName ? shippingAddress.nickName + " - " : "";
  const address = shippingAddress?.address1 ? shippingAddress.address1 + ", " : "";
  const city = shippingAddress?.city ? shippingAddress.city + " " : "";
  const zipcode = shippingAddress?.zipcode ? shippingAddress.zipcode + " " : "";
  const country = shippingAddress?.country ? "(" + shippingAddress.country + ")" : "";

  return nickName + address + city + zipcode + country;
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// ADDRESSES //////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const mapAddress = (address: any): Address => {
  return {
    lastname: address?.lastname,
    address: address?.address,
    city: address?.city,
    zipcode: address?.zipcode,
    country: address?.country,
    orgentityName: address?.orgentityName,
    customerName: address?.customerName,
  };
};

export const mapMultidoorAddress = (multidoorList: any[]): OrderMultidoorAddress[] => {
  return multidoorList?.map(
    (_): OrderMultidoorAddress => {
      return {
        multidoorId: _?.multidoor_id,
        orgentityName: _?.orgentityName,
        subOrderId: _?.subOrderId,
        shipTo: _?.address?.shipTo && mapAddress(_.address.shipTo),
        soldTo: _?.address?.soldTo && mapAddress(_.address.soldTo),
      };
    }
  );
};

export const printAddress = (shippingAddress?: Address): string | null => {
  if (!shippingAddress) return null;

  const orgentityName = shippingAddress?.orgentityName ? shippingAddress?.orgentityName : "";
  const address = shippingAddress?.address ? " - " + shippingAddress.address + ", " : "";
  const zipcode = shippingAddress?.zipcode ? shippingAddress.zipcode + " " : "";
  const city = shippingAddress?.city ? shippingAddress.city + " " : "";
  const country = shippingAddress?.country ? "- " + shippingAddress.country : "";

  return orgentityName + address + zipcode + city + country;
};

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// SPLIT FRAMES /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const mapSplit = (split: any[]): SplitFrames[] => {
  return split?.map((_) => {
    return {
      quantity: Number(_?.quantity),
      splitCode: _?.splitCode,
      semaphore: _?.semaphore,
    };
  });
};

export const getSplitFramesLabel = (
  splitCode: string,
  semaphore: string | undefined,
  isRxItem: boolean,
  isM4C: boolean
): string => {
  const splitCodeMap: { [key: string]: string } = {
    split1: "WH1",
    split2: "WH2",
    splitBo: "WH3",
  };

  if (semaphore === "NOT_AVAILABLE") return ""; // empty label
  if (semaphore === "PREORDER") return "LUX_CART_LEAD_TIME_COMING_SOON"; // Coming Soon
  if (isM4C) return "LUX_CART_LEAD_TIME_M4C_CUSTOM"; // Estimated Delivery Time: 7 Days
  if (isRxItem) return `LUX_CART_LEAD_TIME_RX0_AFS2_${splitCodeMap[splitCode]}`; // Up to 16 business days // Up to 16 business days // Frame currently not available in North America. Expect delivery 18+ business days
  return `LUX_CART_LEAD_TIME_${splitCodeMap[splitCode]}`; // 3 - 5 Business Days USA // 10 - 15 Business Days OVERSEAS (these orders  CANNOT be cancelled) // Backorder (Approximately 15 Business Days)
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////// MISC ///////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const isOutOfStock = (value: string): boolean => value === "Unallocated";

export const mapSubOrdersNotesAndPO = (orderMultidoorArray: any[]): SubOrderPayload[] => {
  const getAttr = (attrList: OrderItemExtendAttribute[], type: string): string => {
    if (attrList) {
      return getExtendAttribute(attrList, type)?.attributeValue ?? "";
    } else return "";
  };

  return orderMultidoorArray?.map(
    (orderMultidoor: any): SubOrderPayload => {
      return {
        subOrderId: orderMultidoor?.subOrderId,
        payload: {
          customerServiceNote: getAttr(orderMultidoor?.attributes, "customerServiceNote"),
          purchaseOrderNumber: getAttr(orderMultidoor?.attributes, "purchaseOrderNumber"),
        },
      };
    }
  );
};

export const getCartTileTitle = (
  orderItem: OrderItem,
  translateLabel: (label: string) => string,
  hideBrand = false
): string => {
  let title = "";
  if (!hideBrand && orderItem?.sku?.brand)
    title += translateLabel(`${orderItem?.sku?.brand}_LABEL`) + " ";

  if (instanceOfSparePartsSku(orderItem?.sku)) {
    title += (orderItem?.sku as SparePartsSku)?.sparePartsCode + " ";
    title += (orderItem?.sku as SparePartsSku)?.gridValue;
  } else {
    title += (orderItem?.variantCode || orderItem?.skuCode)
      ?.replace("__", " ")
      .replace("_", " ")
      .toUpperCase();
  }

  return title;
};

// renderCartError
export const renderCartError = (
  errorCode: string,
  translatingFunc: (key: string) => string
): string => {
  // error message
  switch (errorCode) {
    case "NO_SELECTED_PRODUCTS":
      return translatingFunc("STICKY_BAR_SELECT_PRODUCTS");
    case "_ERR_THRESHOLD_SHOPPING_CART_QUANTITY":
      return translatingFunc(`STICKY_BAR${errorCode}`);
    default:
      return translatingFunc("STICKY_BAR_GENERIC_CART_ERROR");
  }
};

export const isOrderItemRx = (orderItem: OrderItem | undefined | null): boolean => {
  return !!orderItem?.xitemField1 && +orderItem.xitemField1 >= 4 && +orderItem.xitemField1 <= 6;
};

export const isOrderItemM4C = (orderItem: OrderItem | undefined | null): boolean => {
  return !!orderItem?.xitemField1 && +orderItem.xitemField1 >= 7 && +orderItem.xitemField1 <= 8;
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////// RX PRESCRIPTION BRASIL /////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const updateRxBrasilStatus = (
  statusList: RxPrescriptionBrasilStatus[],
  status: RequestStatus,
  errorMessage: string,
  listOfOrderItems: string[] | "ALL"
): RxPrescriptionBrasilStatus[] => {
  const oldStatusList = cloneDeep(statusList);

  // if all statuses should be updated
  if (listOfOrderItems === "ALL") {
    oldStatusList?.forEach((_) => {
      _.requestStatus = status;
      _.errorMessage = errorMessage;
    });
  } // otherwise, updated only those specified in listOfOrderItems
  else {
    listOfOrderItems?.forEach((orderItem) => {
      // look for status of current orderItem
      const statusToModify = oldStatusList.find((_) => _.rxBrasilId === orderItem);

      //////// if found, set status to loading
      if (statusToModify) {
        statusToModify.requestStatus = status;
        statusToModify.errorMessage = errorMessage;
      } //////// otherwise, add it to array
      else {
        oldStatusList.push({
          orderItemId: orderItem.split("/")?.pop() ?? orderItem,
          rxBrasilId: orderItem,
          requestStatus: status,
          errorMessage: errorMessage,
        });
      }
    });
  }
  return oldStatusList;
};

export const getFailedRxBrasilStatus = (
  statusList: RxPrescriptionBrasilStatus[],
  error?: any,
  listOfOrderItems?: string[]
): RxPrescriptionBrasilStatus[] => {
  // if something went wrong, set ALL items to error, and try to use the received value as error message, if available
  // otherwise fallback to "NO_RESPONSE"
  const errorMessage = error?.response?.data?.errors?.[0]?.message;

  const failedRxBrasilStatus = updateRxBrasilStatus(
    statusList,
    "ERROR",
    typeof errorMessage === "string" ? errorMessage : "NO_RESPONSE", // important check, to avoid breaking everything w/ an object that it's not a string
    listOfOrderItems ? listOfOrderItems : "ALL"
  );

  return failedRxBrasilStatus;
};

export const mapRxPrescriptionBrasilResult = (
  data: any,
  statusList: RxPrescriptionBrasilStatus[]
): RxPrescriptionBrasilStatus[] => {
  const oldStatusList = cloneDeep(statusList);

  Object.entries(data)?.forEach(([key, value]) => {
    // look for status of current orderItem
    const statusToModify = oldStatusList.find((_) => _.rxBrasilId === key);

    //////// if found, update based on result
    if (statusToModify) {
      statusToModify.requestStatus = value ? "SUCCESS" : "ERROR";
      statusToModify.errorMessage = value ? "" : "NO_PRESCRIPTION";
    } //////// otherwise, add it to array
    else {
      oldStatusList.push({
        orderItemId: key.split("/")?.pop() ?? key,
        rxBrasilId: key,
        requestStatus: value ? "SUCCESS" : "ERROR",
        errorMessage: value ? "" : "NO_PRESCRIPTION",
      });
    }
  });

  return oldStatusList;
};

export const getCheckPrescriptionBrasilPayload = (
  listOfOrderItems: string[]
): CheckPrescriptionBrasilPayloadByDoor[] => {
  const payload: CheckPrescriptionBrasilPayloadByDoor[] = [];

  listOfOrderItems?.forEach((orderCode) => {
    const orgentityName = orderCode?.split("/")?.[0];

    const currPayload = payload.find((_) => _.customerCode === orgentityName);

    if (currPayload) currPayload.orders.push(orderCode);
    else
      payload.push({
        customerCode: orgentityName,
        orders: [orderCode],
      });
  });

  return payload;
};
/**
 * Filter alternative variants received from API to only consider those available
 *  - skus with avail !== AVAILABLE are removed
 *  - variants with no skus are removed
 *
 * @param {Variant[]} variants
 * @param {AvailabilityStatusByDoor} doorAvailability
 * @return {*}  {Variant[]}
 */
export const getAvailableAlternativeVariants = (
  variants: Variant[],
  doorAvailability: AvailabilityStatusByDoor
): Variant[] => {
  const alternativeVariants = variants.filter((variant) => {
    variant.skus = variant?.skus?.filter((sku) => {
      const skuAvail = doorAvailability?.availabilityStatus.filter(
        (status) => status?.[sku.uniqueID]
      )[0]?.[sku.uniqueID]; // look for current id

      return skuAvail === "AVAILABLE";
    });

    return variant.skus && variant.skus.length > 0;
  });

  return alternativeVariants;
};
