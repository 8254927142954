import React, { useEffect } from "react";
import styled from "styled-components/macro";
import CustomProgressiveImage from "../../../styled-UI/CustomProgressiveImage";
import FeatureTileInfoSection from "./FeatureTileInfoSection";
import { sendAnalyticsData } from "../../../../store/analytics/analyticsService";

interface Props {
  title?: string;
  subtitle?: string;
  description?: string;
  linkString?: string;
  linkLabel?: string;
  openInNewTab?: boolean;
  externalLink?: boolean;
  srcImg?: string;
  slideNumber?: number;
  currentPage?: number;
  totalSlide?: number;
  id?: string;
  closePopup: () => void;
  "data-element-id"?: string;
}

const FeatureTile = ({
  title,
  description,
  linkString,
  linkLabel,
  openInNewTab,
  externalLink,
  srcImg,
  slideNumber,
  totalSlide,
  currentPage,
  id,
  closePopup,
  "data-element-id": dataElementId,
}: Props): JSX.Element => {
  useEffect(() => {
    if (currentPage !== undefined && slideNumber !== undefined) {
      currentPage === slideNumber - 1 &&
        sendAnalyticsData({
          id: "Impression",
          Page_Section1: "TutorialPills",
          Page_Section2: id ?? "",
        });
    }
  }, [currentPage]);

  return (
    <StyledTile>
      <Col>
        <FeatureTileInfoSection
          {...(dataElementId ? { "data-element-id": dataElementId } : {})}
          title={title}
          description={description}
          linkString={linkString}
          linkLabel={linkLabel}
          openInNewTab={openInNewTab}
          externalLink={externalLink}
          closePopup={closePopup}
          slideNumber={slideNumber}
          totalSlide={totalSlide}
        />
      </Col>
      <Col className="margin-left">
        <ImageTile>
          {srcImg && <CustomProgressiveImage type="portrait" src={srcImg} isHeight100 />}
        </ImageTile>
      </Col>
    </StyledTile>
  );
};

const StyledTile = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 1.5rem 2rem 2.5rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
`;

const Col = styled.div`
  width: 25rem;
  height: 18.5rem;
  &.margin-left {
    margin-left: 3.5rem;
    width: 27.5rem;
  }
`;

const ImageTile = styled.div`
  height: 100%;
  img {
    height: 18.5rem;
    width: 27.5rem;
    object-fit: cover;
  }
`;

export default FeatureTile;
