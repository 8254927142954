import { all } from "redux-saga/effects";

import { userSaga } from "./user/userSagas";
import { storeSaga } from "./store/storeSagas";
import { searchSaga } from "./search/searchSagas";
import { catalogueSaga } from "./catalogue/catalogueSaga";
import { cartSaga } from "./cart/cartSagas";
import { checkoutSaga } from "./checkout/checkoutSagas";
import { analyticsSaga } from "./analytics/analyticsSaga";
import { aftersalesSaga } from "./aftersales/aftersalesSaga";
import { subuserSaga } from "./subuser/subuserSagas";
import { accountingSaga } from "./accounting/accountingSagas";
import { documentsSaga } from "./documents/documentsSagas";
import { rxSaga } from "./rx/rxSagas";
import { profileSaga } from "./profile/profileSagas";
import { orderHistorySaga } from "./order-history/orderHistorySaga";
import { ordersToApproveSaga } from "./orders-to-approve/ordersToApproveSaga";
import { servicesSaga } from "./services/servicesSaga";
import { multidoorSaga } from "./multidoor/multidoorSagas";
import { warrantyWizardSaga } from "./warranty-wizard/warrantyWizardSaga";
import { leonardoSaga } from "./leonardo/leonardoSagas";
import { smartshopperSaga } from "./smartshopper/smartshopperSaga";
import { serviceRequestSaga } from "./serviceRequests/serviceRequestSagas";
import { orderUploadSaga } from "./order-upload/orderUploadSaga";
import { notificationsSaga } from "./messages/messagesSagas";
import { paymentSaga } from "./payment/paymentSaga";
import { adtSaga } from "./adt/adtSagas";

export default function* rootSaga() {
  yield all([
    userSaga(),
    storeSaga(),
    searchSaga(),
    catalogueSaga(),
    cartSaga(),
    subuserSaga(),
    checkoutSaga(),
    analyticsSaga(),
    aftersalesSaga(),
    accountingSaga(),
    documentsSaga(),
    rxSaga(),
    profileSaga(),
    orderHistorySaga(),
    ordersToApproveSaga(),
    servicesSaga(),
    multidoorSaga(),
    warrantyWizardSaga(),
    leonardoSaga(),
    smartshopperSaga(),
    serviceRequestSaga(),
    orderUploadSaga(),
    paymentSaga(),
    notificationsSaga(),
    adtSaga(),
  ]);
}
