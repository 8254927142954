import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/types";

import { handleError, takeEveryUnlessSameKey } from "../store/storeSagas";
import {
  saveSubuserListMyEL,
  saveSubuserItem,
  sliceName,
  selectPageSize,
  saveIsSubuserUpdateSuccess,
  setIsEmailSuccessful,
  setPrivilegeGroups,
  setPrivilegesTemplatesList,
  setGetUserPrivilegesStatus,
  cleanSubuserItem,
  saveSubuserUpdateError,
  saveSubuserListLeo,
  setSubuserListLoading,
} from "./subuserSlice";
import subuserService from "./subuserService";
import {
  GetSubuserListPayload,
  EditSubuserStatusPayload,
  GetSubuserItemPayload,
  SendSubuserEmailPayload,
  CreateSubuserPayload,
  GetSubuserListByType,
  getSubuserStatusStringFromNumber,
  UpdateSubuserPayload,
  UpdateSubuserPayloadServiceLeo,
} from "./subuserInterfaces";
import { mapDuplicatedEmailErrorLabel } from "../../utils/utils";
import {
  instanceOfMyELSubusers,
  isPrivilegeDiffNotEmpty,
  mapLeoSubuserItem,
  mapMyELSubuserItem,
  mapSubuserList,
} from "../../utils/manageSubusersUtils";

/* ACTIONS */
export const getSubuserList = createAction<GetSubuserListByType>(sliceName + "/getSubuserList");
export const getSubuserItem = createAction<GetSubuserItemPayload>(sliceName + "/getSubuserItem");
export const editSubuser = createAction<UpdateSubuserPayload>(sliceName + "/editSubuser");
export const createSubuser = createAction<CreateSubuserPayload>(sliceName + "/createSubuser");
export const editSubuserStatus = createAction<EditSubuserStatusPayload>(
  sliceName + "/statusSubuser"
);
export const sendSubuserEmail = createAction<SendSubuserEmailPayload>(
  sliceName + "/sendSubuserEmail"
);
export const getUserPrivileges = createAction<string | undefined>(sliceName + "/getUserPrivileges");
export const getSubuserTemplates = createAction(sliceName + "/getSubuserTemplates");

/* SAGAS */

/**
 * Get the entire list of subusers
 *
 * @param {PayloadAction<GetSubuserListPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getSubuserListSaga(action: PayloadAction<GetSubuserListByType>): SagaIterator {
  const { payload, subuserType } = action.payload;

  try {
    yield put(setSubuserListLoading({ type: subuserType, value: "LOADING" }));

    const pageSize = yield select(selectPageSize); // get default page size
    const qparams: GetSubuserListPayload = {
      ...payload,
      search: payload.search !== "" ? payload.search : undefined,
      pageSize: pageSize,
    };

    switch (subuserType) {
      case "MyELSubusers":
        const responseMyEL = yield call(subuserService.getSubuserList, qparams);
        const MyELSubusers = mapSubuserList(responseMyEL?.data?.data, subuserType);
        yield put(saveSubuserListMyEL(MyELSubusers));
        break;

      case "LeoSubusers":
        const responseLeo = yield call(subuserService.getSubuserListLeoOnly, qparams);
        const LeoSubusers = mapSubuserList(responseLeo?.data?.data, subuserType);
        yield put(saveSubuserListLeo(LeoSubusers));
        break;

      default:
        break;
    }
    yield put(setSubuserListLoading({ type: subuserType, value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setSubuserListLoading({ type: subuserType, value: "ERROR" }));

    yield put(cleanSubuserItem());
  }
}

/**
 * Get given Subuser information
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* getSubuserItemSaga(action: PayloadAction<GetSubuserItemPayload>): SagaIterator {
  const { userId, userName, redirect } = action.payload;
  try {
    yield put(setSubuserListLoading({ type: "subuserItem", value: "LOADING" }));

    if (userId) {
      // if userId is available, it means we are dealing with a MyEL subuser
      const response = yield call(subuserService.getSubuserItem, userId);
      yield put(saveSubuserItem(mapMyELSubuserItem(response.data.data)));
    } else {
      // if only username is available, it means we are dealing with a LeoOnly subuser
      const response = yield call(subuserService.getSubuserItemLeoOnly, userName);
      yield put(saveSubuserItem(mapLeoSubuserItem(response.data.data)));
    }

    yield put(setSubuserListLoading({ type: "subuserItem", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    redirect?.(); // if the user wasn't found, redirect (to subuser list)
  }
}

/**
 * Edit subuser info
 *
 * @param {PayloadAction<EditSubuserPayload>} action
 * @return {*}  {SagaIterator}
 */
function* editSubuserSaga(action: PayloadAction<UpdateSubuserPayload>): SagaIterator {
  const { payload, privilegeDiff, callback, isUnblockWithDuplicatedEmail } = action.payload;

  try {
    yield put(setSubuserListLoading({ type: "subuserUpdate", value: "LOADING" }));

    if (payload.subuserType === "MyELSubusers") {
      // edit subuser MyEL
      yield call(subuserService.putEditSubuser, payload);

      // edit privileges
      yield call(subuserService.submitUserPrivileges, {
        userId: payload.userId,
        privilegeDiff: privilegeDiff,
      });
    } else yield call(subuserService.putEditSubuserLeoOnly, payload); // edit subuser Leo only

    if (!isUnblockWithDuplicatedEmail) yield put(saveIsSubuserUpdateSuccess(true));
    callback?.(true);
  } catch (error) {
    yield put(handleError(error));
    const errorArray = error?.response?.data?.errors;
    const firstError = errorArray?.[0]?.responseBody?.errors?.[0];
    if (firstError?.errorKey) {
      const key = mapDuplicatedEmailErrorLabel(firstError?.errorKey);
      if (!isUnblockWithDuplicatedEmail) {
        yield put(saveSubuserUpdateError(key));
        yield put(saveIsSubuserUpdateSuccess(false));
      }
      callback?.(false, key);
    } else {
      yield put(saveIsSubuserUpdateSuccess(false));
      callback?.(false);
    }
  } finally {
    yield put(setSubuserListLoading({ type: "subuserUpdate", value: "SUCCESS" }));
  }
}

/**
 * Create new subuser
 *
 * @param {PayloadAction<SubuserForm>} action
 * @return {*}  {SagaIterator}
 */
function* createSubuserSaga({ payload }: PayloadAction<CreateSubuserPayload>): SagaIterator {
  try {
    yield put(setSubuserListLoading({ type: "subuserUpdate", value: "LOADING" }));
    const { data } = yield call(subuserService.postCreateSubuser, {
      data: payload.data,
      doorId: payload.doorId,
    }); // create subuser

    if (!payload.data.leo_only && isPrivilegeDiffNotEmpty(payload.privilegeDiff)) {
      yield call(subuserService.submitUserPrivileges, {
        userId: data.data.userId,
        privilegeDiff: payload.privilegeDiff,
      }); // edit privileges
    }

    yield put(saveIsSubuserUpdateSuccess(true));
  } catch (error) {
    yield put(handleError(error));

    const errorArray = error?.response?.data?.errors;
    const firstError = errorArray?.[0]?.responseBody?.errors?.[0];
    if (firstError?.errorKey) {
      const key = mapDuplicatedEmailErrorLabel(firstError?.errorKey);
      yield put(saveSubuserUpdateError(key));
    }
    yield put(saveIsSubuserUpdateSuccess(false));
  } finally {
    yield put(setSubuserListLoading({ type: "subuserUpdate", value: "SUCCESS" }));
  }
}

/**
 * Edit the status (blocked - unblocked) for the subuser
 *
 * @param  {PayloadAction<EditSubuserStatusPayload>} action
 */
function* editSubuserStatusSaga(action: PayloadAction<EditSubuserStatusPayload>): SagaIterator {
  const { callback, ...payload } = action.payload;
  try {
    if (instanceOfMyELSubusers(payload.user)) {
      // MyEL Subuser
      yield call(subuserService.postEditSubuserStatus, {
        userId: payload.user.userId,
        userStatus: getSubuserStatusStringFromNumber[payload.userStatus],
      });
    } else {
      // Leo Subuser
      const leoPayload: UpdateSubuserPayloadServiceLeo = {
        subuserType: "LeoSubusers",
        username: payload.user.userName,
        data: {
          userStatus: payload.userStatus,
          email1: payload.user.email,
          firstName: payload.user.firstName,
          lastName: payload.user.lastName,
          phone1: payload.user.phone,
        },
      };
      yield call(subuserService.putEditSubuserLeoOnly, leoPayload);
    }
    callback?.(true); // to reload subuser table on success
  } catch (error) {
    yield put(handleError(error));

    const errorArray = error?.response?.data?.errors;
    const firstError = errorArray?.[0]?.responseBody?.errors?.[0];
    if (firstError?.errorKey) {
      const key = mapDuplicatedEmailErrorLabel(firstError?.errorKey);
      yield put(saveSubuserUpdateError(key));
    } else callback?.(false); // handle generic error
  }
}

function* sendSubuserEmailSaga(action: PayloadAction<SendSubuserEmailPayload>): SagaIterator {
  const { subuserType, ...servicePayload } = action.payload;

  try {
    if (subuserType === "MyELSubusers")
      yield call(subuserService.sendSubuserEmailService, servicePayload);
    else yield call(subuserService.sendSubuserEmailLeoOnly, servicePayload.username);
    yield put(setIsEmailSuccessful(true));
  } catch (error) {
    yield put(handleError(error));
    yield put(setIsEmailSuccessful(false));
  }
}

/**
 * Get user priviliges group list
 *
 * @param  {PayloadAction<string>} action isuser
 */
function* getUserPrivilegesSaga(action: PayloadAction<string | undefined>): SagaIterator {
  const userId = action.payload;
  try {
    yield put(setGetUserPrivilegesStatus("LOADING"));

    if (userId) {
      // if userId is available, we need to request the current privileges of the provided user
      const { data } = yield call(subuserService.getUserPrivileges, userId);
      yield put(setPrivilegeGroups(data.data.userPrivileges));
    } else {
      // if it's not, it means we are creating a new user and we need the generic privileges to start with
      const { data } = yield call(subuserService.getNewUserPrivileges);
      yield put(setPrivilegeGroups(data.data.userPrivileges));
    }

    yield put(setGetUserPrivilegesStatus("SUCCESS"));
  } catch (error) {
    yield put(setGetUserPrivilegesStatus("ERROR"));
    yield put(handleError(error));
  }
}

/**
 * get the privilege templates
 *
 * @param  {}
 */
function* getSubuserTemplatesSaga(): SagaIterator {
  try {
    const { data } = yield call(subuserService.getSubuserTemplates);

    yield put(setPrivilegesTemplatesList(data.data.templates));
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* subuserSaga(): SagaIterator {
  yield takeEveryUnlessSameKey<GetSubuserListByType>({
    actionPattern: getSubuserList.type,
    saga: getSubuserListSaga,
    getUniqueKey: (actionPayload: GetSubuserListByType) => actionPayload?.subuserType,
  });
  yield takeLatest(getSubuserItem.type, getSubuserItemSaga);
  yield takeLatest(editSubuser.type, editSubuserSaga);
  yield takeLatest(createSubuser.type, createSubuserSaga);
  yield takeLatest(editSubuserStatus.type, editSubuserStatusSaga);
  yield takeLatest(sendSubuserEmail.type, sendSubuserEmailSaga);
  yield takeLatest(getUserPrivileges.type, getUserPrivilegesSaga);
  yield takeLatest(getSubuserTemplates.type, getSubuserTemplatesSaga);
}
