import { BFFApiDomain } from "../serviceUtils";

export const needHelpUrl = BFFApiDomain + "/fo-bff/api/pub/v1/help/";
export const getLabelsPublicUrl = BFFApiDomain + "/fo-bff/api/pub/v2/{locale}/labels";
export const getLabelsPrivateUrl = BFFApiDomain + "/fo-bff/api/priv/v2/{locale}/labels";
export const getDecouplingResourceUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/endpoints/groups/{groupId}/status";
export const getTokenForUploadUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/users/uploadService/token";
export const uploadFileUrl =
  globalEnvVariables.dlEndpoint +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/uploadFile";
export const getTokenWCSUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/orders/massive/upload/token";
