import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import { useForm } from "react-hook-form";

import {
  CLOSED_POPUP_ACCESS_ACCOUNTING,
  selectPopupAccessAccounting,
  togglePopupAccessAccounting,
} from "../../../store/store/storeSlice";
import { resetPin, validatePin } from "../../../store/accounting/accountingSagas";
import {
  clearAcccountingError,
  selectAccountingLoading,
  selectEmailSentFlag,
  selectAccountingError,
  toggleEmailSentFlag,
  selectRequestStatus,
  setRequestStatus,
} from "../../../store/accounting/accountingSlice";
import Popup from "../../styled-UI/Popup";
import Button from "../../styled-UI/Button";
import InputComponent from "../../styled-UI/InputComponent";
import LinkButton from "../../styled-UI/LinkButton";
import CustomText from "../../styled-UI/CustomText";
import AccountingUpdatePinPopup from "./AccountingUpdatePinPopup";
import { checkSameDigitsInsideNumber } from "../../../utils/utils";

import { ReactComponent as Checkmark } from "../../../assets/icons/checkmark-icon.svg";
import { ReactComponent as XCircle } from "../../../assets/icons/x-circle-icon.svg";
import { selectIsBackOfficeUser, selectIsNotMigrated } from "../../../store/user/userSlice";
import clsx from "clsx";

interface FormValues {
  pinCode: string;
}

function AccountingPinPopup(): JSX.Element {
  const { translateLabel } = useTranslation();
  const [feedbackMessage, setFeedbackMessage] = useState<string>("");
  const [updateContent, setUpdateContent] = useState<boolean>(false);
  const isNotMigrated = useSelector(selectIsNotMigrated);

  const dispatch = useDispatch();

  const { register, watch } = useForm<FormValues>();
  const watchPin = watch("pinCode");

  const popupAccessAccounting = useSelector(selectPopupAccessAccounting);
  const pinErrorMessage = useSelector(selectAccountingError);

  const emailSentFlag = useSelector(selectEmailSentFlag);
  const isLoadingValidate = useSelector(selectAccountingLoading);

  const requestStatus = useSelector(selectRequestStatus);
  const isBackOfficeUser = useSelector(selectIsBackOfficeUser);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  useEffect(() => {
    if (pinErrorMessage.pinError?.type === "POPUP_PIN_TEMPORARY_PIN") {
      setUpdateContent(true);
    } else if (pinErrorMessage.pinError?.type !== "emailError") {
      setFeedbackMessage(
        translateLabel(pinErrorMessage.pinError?.type ? pinErrorMessage.pinError?.type : "")
      );
    }
  }, [pinErrorMessage.pinError?.type]);

  ///// successful validation calls callback and resets request status
  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      popupAccessAccounting?.callback?.(true); // callback where redirects can be set
      dispatch(setRequestStatus("IDLE"));
    }
  }, [requestStatus]);

  const handleNumericTypeSubmit = () => {
    if (!disabledPaymentAccess) {
      if (!/[a-z]/i.test(watchPin)) {
        if (watchPin && watchPin.length > 6) {
          setFeedbackMessage(translateLabel("PIN_NOT_VALID"));
        } else if (watchPin && watchPin.length < 6) {
          setFeedbackMessage(translateLabel("PIN_NOT_VALID"));
        } else {
          if (checkSameDigitsInsideNumber(watchPin)) {
            dispatch(
              validatePin({
                pin: watchPin,
                type: popupAccessAccounting.type,
              })
            );
          } else setFeedbackMessage(translateLabel("PIN_NOT_VALID"));
        }
      } else setFeedbackMessage(translateLabel("PIN_NOT_VALID"));
    }
  };

  const handleResetPin = () => dispatch(resetPin(watchPin));

  const handleClose = () => {
    dispatch(clearAcccountingError("pinError"));
    dispatch(toggleEmailSentFlag(false));
    dispatch(togglePopupAccessAccounting(CLOSED_POPUP_ACCESS_ACCOUNTING));
  };

  const disabledPaymentAccess = popupAccessAccounting.type === "payment" && isNotMigrated;
  return (
    <>
      {!updateContent ? (
        <Popup
          isOpen={popupAccessAccounting.open}
          title={translateLabel("POPUP_PIN_INSERT")}
          close={handleClose}
        >
          <ContentPopup className={clsx(disabledPaymentAccess && "accounting-pin-popup--disabled")}>
            {disabledPaymentAccess && (
              <CustomText as="p" color="red" marginBottomPx={24} fontSizePx={14}>
                {translateLabel("POPUP_PIN_USER_NOT_MIGRATED")}
              </CustomText>
            )}
            <Row>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleNumericTypeSubmit();
                }}
              >
                <WrapInput>
                  <InputComponent
                    ref={register()}
                    id="pinCode"
                    type="password"
                    required
                    placeholder={translateLabel("POPUP_PIN_CODE")}
                    name="pinCode"
                    onChange={() => {
                      if (pinErrorMessage.pinError?.status !== false)
                        dispatch(clearAcccountingError("pinError"));
                      if (feedbackMessage) setFeedbackMessage("");
                    }}
                    helperText={feedbackMessage.length !== 0 ? feedbackMessage : ""}
                    colorStyle={feedbackMessage.length !== 0 ? "error" : "enabled"}
                    disabled={disabledPaymentAccess}
                  />
                </WrapInput>
              </form>
              <Button
                type="primary"
                onClick={handleNumericTypeSubmit}
                isLoading={isLoadingValidate.accountingPinValidate}
                disabled={disabledPaymentAccess}
              >
                {translateLabel("ENTER")}
              </Button>
            </Row>

            {emailSentFlag || pinErrorMessage.pinError?.type === "emailError" ? (
              <MessageContainer>
                <IconContainer className={emailSentFlag ? "" : "error-status"}>
                  {emailSentFlag ? <Checkmark /> : <XCircle />}
                </IconContainer>
                <CustomText as="p" fontSizePx={13} font="font-medium">
                  {translateLabel(emailSentFlag ? "POPUP_PIN_SENT_MAIL" : "POPUP_PIN_ERROR_MAIL")}
                </CustomText>
              </MessageContainer>
            ) : (
              <>
                <CustomText as="p" fontSizePx={13} font="font-medium">
                  {translateLabel("FORGOT_PIN")}
                </CustomText>
                <LinkButton uppercase onClick={handleResetPin} disabled={isBackOfficeUser}>
                  {translateLabel("FOLLOWING_LINK")}
                </LinkButton>
              </>
            )}
          </ContentPopup>
        </Popup>
      ) : (
        <AccountingUpdatePinPopup resetPopup={() => setUpdateContent(false)} />
      )}
    </>
  );
}

const ContentPopup = styled.div`
  padding: 3rem;
  width: 42.375rem;

  &.accounting-pin-popup--disabled {
    padding-top: 1.5rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

const WrapInput = styled.div`
  width: 25.625rem;
`;

const MessageContainer = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  display: flex;
  margin-right: 1rem;
  fill: ${(props) => props.theme.palette.feedback.success};

  svg {
    height: 1.125rem;
    width: 1.125rem;
  }

  &.error-status {
    fill: ${(props) => props.theme.palette.feedback.error};
  }
`;

export default AccountingPinPopup;
