import React from "react";

import styled from "styled-components/macro";

import LpVariantTile from "../LpVariantTile";

import { Variant } from "../../../../interfaces/productInterface";

interface Props {
  variants: Variant[];
  variantsPerPage: number;
}

const LpVariantsPopupBody = ({ variants, variantsPerPage }: Props): JSX.Element => {
  return (
    <VariantsListContainer variantsPerPage={variantsPerPage}>
      {variants.map((item) => {
        return <LpVariantTile key={item.uniqueID} variant={item} />;
      })}
    </VariantsListContainer>
  );
};

export const VariantsListContainer = styled.div<{ variantsPerPage: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.variantsPerPage}, 18rem);
  column-gap: 1.125rem;
  max-height: calc(100vh - 85px - 77px - 3rem);
  padding: 1.25rem 0 3.4375rem;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
`;
export default LpVariantsPopupBody;
