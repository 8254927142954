import React from "react";
import styled from "styled-components/macro";

import useTranslation from "../../../../hooks/useTranslation";

import CustomText from "../../../styled-UI/CustomText";
import { ReactComponent as MulticolorCircle } from "../../../../assets/icons/multicolor-circle.svg";

import { replaceStringWithElement } from "../../../../utils/utils";

interface Props {
  totalVariants: number;
}

const LpVariantsPopupHeader = ({ totalVariants = 0 }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  return (
    <Header>
      <MulticolorCircle />
      <CustomText as="p" font="font-medium" fontSizePx={24} lineHeightPx={24} color="primary">
        {replaceStringWithElement(
          translateLabel("LANDING_PAGE_TOTAL_VARIANTS"),
          "TOTAL_VARIANTS",
          <CustomText
            as="p"
            font="font-medium"
            fontSizePx={24}
            lineHeightPx={24}
            color="primary"
            marginLeftPx={12}
            marginRightPx={4}
          >
            {totalVariants}
          </CustomText>
        )}
      </CustomText>
    </Header>
  );
};
const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2.5rem;
  margin: 5rem 0 1.75rem;
  overflow-x: hidden;
`;
export default LpVariantsPopupHeader;
