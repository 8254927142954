import React, { useEffect, useState } from "react";
import { CartLoading, OrderItem } from "../../../../store/cart/cartInterfaces";
import { PreCartProduct } from "../../../../store/catalogue/catalogueInterface";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrowhead-down-icon.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/icons/arrowhead-up-icon.svg";
import { Door } from "../../../../store/user/userInterfaces";
import CartTileAFASize from "./CartTileAFASize";
import { useDispatch, useSelector } from "react-redux";
import { addDirectlyToPreCart, getOtherSkus } from "../../../../store/cart/cartSagas";
import { selectGetOtherSkusStatus, selectOtherSkus } from "../../../../store/cart/cartSlice";
import Button from "../../../styled-UI/Button";
import useTranslation from "../../../../hooks/useTranslation";
import { filterNewSkus } from "../../../../utils/AFAutils";
import Loader from "../../../styled-UI/Loader";
import { compareDay } from "../../../../utils/dateUtils";
import CustomText from "../../../styled-UI/CustomText";

interface Props {
  orderItem: OrderItem;
  currentDoor: Door;
  availabilityStatus?: string;
  handleDelete: (orderItem: OrderItem) => void;
  onClick: (obj: PreCartProduct, orderItemId: string) => void;
  disabled: boolean;
  loading: CartLoading | null;
  hideShowOtherSizes?: boolean;
}

const CartTileAFASizes = ({
  orderItem,
  currentDoor,
  availabilityStatus,
  handleDelete,
  disabled,
  loading,
  onClick,
  hideShowOtherSizes = false,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const otherSkus = useSelector(selectOtherSkus);
  const getOtherSkusStatus = useSelector(selectGetOtherSkusStatus);
  const [isLoadingOtherSkus, setIsLoadingOtherSkus] = useState<boolean>(false);
  const [showOtherSizes, setShowOtherSizes] = useState<boolean>(false);
  const [currentOtherSkus, setCurrentOtherSkus] = useState<OrderItem[]>([]);
  const isLoadingLocal = getOtherSkusStatus === "LOADING" && isLoadingOtherSkus;
  const key = currentDoor.orgentityId + orderItem.sku.parentCatalogEntryID;

  //SAVE OTHER SKUS WHEN THE CALL FOR SKU IS FINISHED
  useEffect(() => {
    const currentOtherSkus = otherSkus[key];

    if (currentOtherSkus) {
      setIsLoadingOtherSkus(false);
    }
    if (
      currentOtherSkus &&
      showOtherSizes &&
      orderItem.orders &&
      getOtherSkusStatus === "SUCCESS"
    ) {
      saveOtherSkus();
    }
  }, [otherSkus, getOtherSkusStatus]);

  //SAVE OTHER SKUS WHEN THE PRECART HAS BEEN UPDATED
  useEffect(() => {
    const currentOtherSkus = otherSkus[key];
    if (
      currentOtherSkus &&
      showOtherSizes &&
      orderItem.orders &&
      getOtherSkusStatus === "SUCCESS"
    ) {
      saveOtherSkus();
    }
  }, [orderItem]);

  const saveOtherSkus = () => {
    const currentOtherSkus = otherSkus[key];
    const skufilteredByDate = currentOtherSkus.skus.filter((_) => {
      const firstDate = orderItem.availableDate ? new Date(orderItem.availableDate) : new Date();
      const secondDate = _.firstShippingDate ? new Date(_.firstShippingDate) : new Date();
      return compareDay(firstDate, secondDate, "asc") === 0;
    });
    const newSkus = filterNewSkus(skufilteredByDate, orderItem?.orders ?? []);
    const orderItems: OrderItem[] = newSkus.map((_) => {
      return {
        orderItemId: key,
        productCode: _.skuId,
        quantity: 0,
        sku: _.sku,
        requestedShipDate: new Date(),
        availableDate: new Date(),
        price: {
          opt: { unitPrice: 0 },
          pub: { unitPrice: 0 },
        },
      };
    });

    setCurrentOtherSkus(orderItems);
  };

  const addToCart = (obj: PreCartProduct) => {
    if (obj.quantity && orderItem.xitem_fromRequestId)
      dispatch(
        addDirectlyToPreCart({
          orderItem: [
            {
              quantity: obj.quantity?.toString(),
              productId: obj.sku.uniqueID,
              xitem_multidoor: obj.multidoorId,
              xitem_fromRequestId: orderItem.xitem_fromRequestId,
            },
          ],
        })
      );
  };

  const handleClickShowSizes = () => {
    const currentOtherSkusGeneral = otherSkus[key];
    setShowOtherSizes(!showOtherSizes);

    if (
      currentOtherSkusGeneral &&
      showOtherSizes === false &&
      orderItem.orders &&
      getOtherSkusStatus === "SUCCESS" &&
      currentOtherSkus.length === 0
    ) {
      return saveOtherSkus();
    }
    if (!showOtherSizes && !currentOtherSkusGeneral) {
      if (orderItem.sku.parentCatalogEntryID)
        dispatch(
          getOtherSkus({
            variantId: orderItem.sku.parentCatalogEntryID,
            doorId: currentDoor.orgentityId,
            doorName: currentDoor.orgentityName,
          })
        );
      setIsLoadingOtherSkus(true);
    }
  };

  return orderItem.orders && orderItem.orders?.length > 0 ? (
    <div>
      {orderItem.orders?.map((order: OrderItem) => {
        return (
          <CartTileAFASize
            key={order.sku.uniqueID + order.orderItemId}
            orderItem={order}
            currentDoor={currentDoor}
            availabilityStatus={availabilityStatus}
            handleDelete={handleDelete}
            onClick={onClick}
            isSingleSize={orderItem.orders?.length === 1}
            disabled={disabled}
          />
        );
      })}
      {isLoadingLocal && <Loader sizePx={18} />}
      {showOtherSizes && !isLoadingLocal && (
        <>
          {(getOtherSkusStatus === "SUCCESS" || getOtherSkusStatus === "ERROR") &&
            (currentOtherSkus.length > 0 ? (
              currentOtherSkus?.map((order: OrderItem) => {
                return (
                  <CartTileAFASize
                    key={order.sku.uniqueID + order.orderItemId}
                    orderItem={order}
                    currentDoor={currentDoor}
                    availabilityStatus={availabilityStatus}
                    handleDelete={handleDelete}
                    onClick={addToCart}
                    isSingleSize={orderItem.orders?.length === 1}
                    disabled={disabled}
                    showOtherSku
                  />
                );
              })
            ) : (
              <CustomText as="p" fontSizePx={13} textAlign="center" isFlex={false}>
                {translateLabel("AFA_SHOW_OTHER_SIZES_NO_SIZES")}
              </CustomText>
            ))}
        </>
      )}

      {!hideShowOtherSizes && (
        <Button
          onClick={handleClickShowSizes}
          type="transparent"
          noTextTransform
          endIcon={showOtherSizes ? <ArrowUp /> : <Arrow />}
        >
          {translateLabel("AFA_SHOW_OTHER_SIZES")}
        </Button>
      )}
    </div>
  ) : (
    <></>
  );
};

export default CartTileAFASizes;
