import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Item } from "../interfaces/gridInterfaces";
import { RequestStatus } from "../interfaces/mainInterfaces";
import { getPagePath } from "../routing/routesUtils";
import { getCmsContent } from "../store/store/storeSagas";
import { selectItemsMap } from "../store/store/storeSlice";

interface Props {
  content?: string;
}

export interface Href {
  dataHref?: string;
  id?: string;
  href?: string;
  status: RequestStatus;
}

export interface DataHrefResult {
  updatedContent?: string;
  hrefs?: Href[];
}

//////////////////// IMPORTANT: CR C380309L ////////////////////////
// The condition dataHref.includes("https://") was introducted
// with the ticket A1722019L - CR: C380309L in order to avoid
// the problem that CMS sends in the field data-href the direct
// link for the image. So, if the data-href sent from CMS contains
// the direct link we don't have to do the CMS call (getCMSContent)
// with the id of the image, so, for this reason, in every useEffect
// we introducted the if condition.
////////////////////////////////////////////////////////////////////

const REGEX_DATA_HREF = /data-href="[\w:\-./]+"/g;
const REGEX_DATA_SHOW_NEW = /data-show="new"/g;
const REGEX_DATA_SHOW_REPLACE = /data-show="replace"/g;

// get different urls depending on content type
const getHrefFromItemContent = (itemContent: Item): string | undefined => {
  if (itemContent.type === "CMDownload") {
    return itemContent?.content?.data?.uri;
  }
  if (itemContent.type === "CMPicture") {
    return itemContent?.content?.data?.uri;
  }
  if (itemContent.type === "CMChannel") {
    return getPagePath(itemContent?.content?.linkString);
  }
};

// replace data-href with retrieved href value in complete content
const replaceHref = (content?: string, dataHref?: string, href?: string, itemContent?: Item) => {
  // IF CONDITION introducted as resolution of CR C380309L
  if (content && dataHref && dataHref.includes("https://"))
    return content.replace(dataHref, `src="${dataHref.split('"')[1]}"`);
  if (content && dataHref && href) {
    if (itemContent?.type === "CMPicture") return content.replace(dataHref, `src="${href}"`);
    else return content.replace(dataHref, `href="${href}"`);
  } else return content;
};

const replaceDataShow = (content?: string) => {
  if (content)
    return content
      .replace(REGEX_DATA_SHOW_REPLACE, "")
      .replace(REGEX_DATA_SHOW_NEW, 'target="_blank"');
};

const useDataHref = ({ content }: Props): DataHrefResult => {
  const dispatch = useDispatch();
  const items = useSelector(selectItemsMap);

  const [hrefs, setHrefs] = useState<Href[]>();
  const [updatedContent, setUpdatedContent] = useState<string>();

  // get data-href contents' and isolate the ids that need to be retrieved
  useEffect(() => {
    if (content) {
      const dataHrefs = content?.match(REGEX_DATA_HREF); // find all instances of data-href
      const hrefs = dataHrefs?.map(
        (data): Href => {
          return {
            dataHref: data,
            id: data?.split("/")?.pop()?.replace('"', ""),
            status: "IDLE",
          };
        }
      ); // get their ids
      hrefs && setHrefs(hrefs);
      content && setUpdatedContent(replaceDataShow(content)); // set initial updated content by replacing data-show
    }
  }, [content]);

  // call ids found in all data-href, but only if we don't already have the corresponding itemContent
  useEffect(() => {
    hrefs?.forEach((href) => {
      const itemContent = href.id && items[href.id]; // check if we already have corresponding itemContent

      // IF CONDITION introducted as resolution of CR C380309L
      if (!href.dataHref?.includes("https://")) {
        // if we don't have itemContent and it's IDLE
        if (href.status === "IDLE" && href.id && !itemContent) {
          dispatch(getCmsContent({ id: href.id })); // dispatch request
          href.status = "LOADING"; // so we know the itemContent is being retrieved
        } // if we have itemContent but it's not already set (ie. !== SUCCESS)
        else if (href.status !== "SUCCESS" && itemContent) {
          href.status = "SUCCESS";
          href.href = getHrefFromItemContent(itemContent); // get href

          // update content
          if (href.href && href.dataHref) {
            setUpdatedContent((oldContent) => {
              return replaceHref(oldContent, href.dataHref, href.href);
            });
          }
        }
      } else {
        setUpdatedContent((oldContent) => {
          return replaceHref(oldContent, href.dataHref, href.href);
        });
      }
    });
  }, [hrefs]);

  // each time items is updated, check if we can set some new hrefs (ie. those that are still LOADING)
  useEffect(() => {
    hrefs?.forEach((href) => {
      // IF CONDITION introducted as resolution of CR C380309L
      if (!href.dataHref?.includes("https://")) {
        if (href.id && !href.href) {
          const itemContent = items[href.id]; // check if we already have corresponding itemContent

          // if it's not loading we either haven't made the request, or we have already set href
          if (href.status === "LOADING" && itemContent) {
            href.status = "SUCCESS";
            href.href = getHrefFromItemContent(itemContent); // get href

            // update content
            if (href.href && href.dataHref) {
              setUpdatedContent((oldContent) => {
                return replaceHref(oldContent, href.dataHref, href.href, itemContent);
              });
            }
          }
        }
      }
    });
  }, [items]);

  return {
    hrefs,
    updatedContent,
  };
};

export default useDataHref;
