import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { getPersonificationDummy, getPersonificationStores } from "../../../store/user/userSagas";
import {
  selectPersonificationStoresOptions,
  selectGetPersonificationStoresStatus,
  selectPersonificationDummy,
  selectGetPersonificationDummyStatus,
  selectPostPersonificationStatus,
  setPersonificationCustomers,
  setPersonificationCustomersStatus,
  selectStoreIdentifier,
} from "../../../store/user/userSlice";
import PersonificationUser from "./personification-user/PersonificationUser";
import CustomText from "../../styled-UI/CustomText";
import CustomSelect from "../../styled-UI/CustomSelect";
import useTranslation from "../../../hooks/useTranslation";
import { PersonificationStoresOption } from "../../../store/user/userInterfaces";
import _ from "lodash";

interface Props {
  selectCountry: (option: PersonificationStoresOption) => void;
  selectedCountry: PersonificationStoresOption | null;
  submitPersonificationCustomer: (customer: string | null) => void;
}

const PersonificationPopupDummy = ({
  selectCountry,
  selectedCountry,
  submitPersonificationCustomer,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { translateLabel } = useTranslation();
  const stores = useSelector(selectPersonificationStoresOptions);
  const storeIdentifier = useSelector(selectStoreIdentifier);
  const dummy = useSelector(selectPersonificationDummy);
  const getStoresStatus = useSelector(selectGetPersonificationStoresStatus);
  const getDummyStatus = useSelector(selectGetPersonificationDummyStatus);
  const postPersonificationStatus = useSelector(selectPostPersonificationStatus);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  //get stores
  useEffect(() => {
    dispatch(getPersonificationStores());
  }, []);

  //select first store as default
  useEffect(() => {
    if (stores && stores.length > 0) {
      const selectedStore = stores.filter((_) => _.storeIdentifier === storeIdentifier);
      if (selectedStore.length > 0) selectCountry(selectedStore?.[0]);
    }
  }, [stores]);

  //get dummy customer of selected store
  useEffect(() => {
    if (selectedCountry) {
      dispatch(setPersonificationCustomers(null));
      dispatch(getPersonificationDummy({ storeSelected: selectedCountry?.value?.toString() }));
      dispatch(setPersonificationCustomersStatus("IDLE"));
    }
  }, [selectedCountry]);

  const selectDummy = () => {
    setHasSubmitted(true);
    submitPersonificationCustomer(dummy?.orgentityId ?? "");
  };

  return (
    <div>
      <CustomText as="span" fontSizePx={18} font="font-bold" marginBottomPx={14} marginTopPx={32}>
        {translateLabel("PERSONIFICATION_POPUP_CHOOSE_COUNTRY")}
      </CustomText>
      <OuterSelect>
        <CustomSelect
          type="white"
          options={stores ? _.orderBy(stores, ["label"], ["asc"]) : []}
          isLoading={getStoresStatus === "LOADING"}
          placeholder={translateLabel("PERSONIFICATION_POPUP_COUNTRY")}
          onChange={(o) => selectCountry(o as PersonificationStoresOption)}
          value={selectedCountry}
          colorNoOutline="gray-medium"
        />
      </OuterSelect>
      {getDummyStatus === "SUCCESS" && dummy && (
        <div>
          <CustomText
            as="span"
            fontSizePx={18}
            font="font-bold"
            marginBottomPx={14}
            marginTopPx={32}
          >
            {translateLabel("PERSONIFICATION_POPUP_DUMMY")}
          </CustomText>

          <UserContainer>
            <PersonificationUser
              hasCta
              selectCustomer={selectDummy}
              user={dummy}
              isLoading={postPersonificationStatus === "LOADING" && hasSubmitted}
            />
          </UserContainer>
        </div>
      )}
      {/* {getDummyStatus === "LOADING" && <div>loading</div>} */}
    </div>
  );
};

const OuterSelect = styled.div`
  width: 24.63rem;
`;

const UserContainer = styled.div`
  border: 1px solid ${(props) => props.theme.palette.gray.medium};
  border-radius: 0.25rem;
`;

export default PersonificationPopupDummy;
