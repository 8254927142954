import { AxiosResponse } from "axios";

import { QueryParams } from "../search/searchInterfaces";
import {
  getPublicPageLayoutUrl,
  getPageLayoutUrl,
  getPublicCmsContentUrl,
  getCmsContentUrl,
  getMenuUrl,
  getThirdLevelMenuUrl,
  getStoreConfigurationUrl,
  getPublicStoreConfigurationUrl,
  getBrandListUrl,
  getLensListUrl,
  getFaqUrl,
  getNewsUrl,
  getSingleNewsUrl,
  getCountriesUrl,
  getBrandImagesUrl,
  getLandingPageUrl,
  getContactInfoUrl,
  getUrlWithTokenEl360Url,
  getUrlWithTokenRewardsUrl,
  redirectToEssilorProUrl,
  getStarDoorsForBrandUrl,
  getLensSimulatorRedirectUrl,
  getInstrumentsListUrl,
  getTutorialPillsUrl,
  getEyemedUrl,
} from "./storeUrls";
import { appendFiltersToURLSearchParams } from "../../utils/filterUtils";
import { createAndExecuteService } from "../serviceUtils";
import {
  BrandsImagesPayload,
  CmsContentPayload,
  GetMenuPayload,
  getCmsTutorialPills,
  getCmsTutorialPillsKeys,
  getPageLayoutService,
  getPageLayoutServiceKeys,
  getPublicPageLayoutService,
  getPublicPageLayoutServiceKeys,
} from "./storeInterfaces";
import { getSearchTermAssociationUrl } from "../search/searchUrls";
import { getPDPCmsContentsUrl } from "../catalogue/catalogueUrls";
import { PDPCmsContents } from "../catalogue/catalogueInterface";

export default {
  getPublicPageLayout: async (params: getPublicPageLayoutService): Promise<AxiosResponse> => {
    const url = getPublicPageLayoutUrl.replace("{pageName}", params.page);
    const paramsUrl = new URLSearchParams();

    (Object.keys(params) as Array<keyof typeof params>).forEach((key) => {
      if (key !== "page") {
        const value = params[key as getPublicPageLayoutServiceKeys];
        if (value) paramsUrl.append(key, value);
      }
    });

    return createAndExecuteService(url, "GET", null, null, true);
  },
  getPageLayout: async (params: getPageLayoutService): Promise<AxiosResponse> => {
    const url = getPageLayoutUrl.replace("{pageName}", params.page);
    const paramsUrl = new URLSearchParams();

    (Object.keys(params) as Array<keyof typeof params>).forEach((key) => {
      if (key !== "page") {
        const value = params[key as getPageLayoutServiceKeys];
        if (value && Array.isArray(value))
          value.forEach((element) => paramsUrl.append(key, element));
        else if (value) paramsUrl.append(key, value.toString());
      }
    });
    return createAndExecuteService(url, "GET", paramsUrl);
  },
  getPageLayoutContentHub: async (params: PDPCmsContents): Promise<AxiosResponse> => {
    const paramsUrl = new URLSearchParams();
    (Object.keys(params) as Array<keyof typeof params>).map((key) => {
      const value = params[key];
      if (value && Array.isArray(value)) value.forEach((element) => paramsUrl.append(key, element));
      else if (value) paramsUrl.append(key, value.toString());
    });
    return createAndExecuteService(getPDPCmsContentsUrl, "GET", paramsUrl);
  },
  getPublicCmsContent: async (id: string): Promise<AxiosResponse> => {
    const url = getPublicCmsContentUrl.replace("{contentId}", id);
    return createAndExecuteService(url, "GET");
  },

  getCmsContent: async (payload: CmsContentPayload): Promise<AxiosResponse> => {
    const { id, brand, fallback, starsRequired } = payload;
    const url = getCmsContentUrl.replace("{contentId}", id);
    const searchParams = new URLSearchParams();

    brand &&
      brand.forEach((_) => {
        if (_ === "noBrand") {
          searchParams.append("facet", "noBrand");
        } else {
          searchParams.append("facet", `${"manufacturer.raw"}:"${_}"`);
        }
      }); // more than one brand could be appended
    fallback && searchParams.append("fallback", fallback.toString());
    starsRequired && searchParams.append("starsRequired", starsRequired.toString());

    return createAndExecuteService(url, "GET", searchParams);
  },

  getLandingPageContent: async (id: string): Promise<AxiosResponse> => {
    const url = getLandingPageUrl.replace("{id}", id);

    return createAndExecuteService(url, "GET");
  },

  getStoreConfiguration: async (storeIdentifier: string): Promise<AxiosResponse> => {
    const url = getStoreConfigurationUrl.replace("{storeIdentifier}", storeIdentifier);
    return createAndExecuteService(url, "GET");
  },
  getPublicStoreConfiguration: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getPublicStoreConfigurationUrl, "GET");
  },
  getMenu: async (params: GetMenuPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();

    if (params.starsRequired) {
      searchParams.append("starsRequired", params.starsRequired?.toString());
    }
    return createAndExecuteService(getMenuUrl, "GET", searchParams);
  },
  getThirdLevelMenu: async (
    id: string,
    param: QueryParams,
    filters?: QueryParams
  ): Promise<AxiosResponse> => {
    const url = getThirdLevelMenuUrl.replace("{categoryId}", id);
    return createAndExecuteService(url, "GET", appendFiltersToURLSearchParams(param, filters));
  },
  getBrandList: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getBrandListUrl, "GET");
  },
  getLensList: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getLensListUrl, "GET");
  },
  getInstrumentsList: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getInstrumentsListUrl, "GET");
  },
  getFaq: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getFaqUrl, "GET");
  },
  getNewsUrl: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getNewsUrl, "GET");
  },
  getTutorialPillsUrl: async (params: getCmsTutorialPills): Promise<AxiosResponse> => {
    const paramsUrl = new URLSearchParams();

    (Object.keys(params) as Array<keyof typeof params>).forEach((key) => {
      const value = params[key as getCmsTutorialPillsKeys];
      if (value && Array.isArray(value)) value.forEach((element) => paramsUrl.append(key, element));
      else if (value) paramsUrl.append(key, value.toString());
    });
    return createAndExecuteService(getTutorialPillsUrl, "GET", paramsUrl);
  },

  getSingleNewsUrl: async (id: string): Promise<AxiosResponse> => {
    const url = getSingleNewsUrl.replace("{id}", id);
    return createAndExecuteService(url, "GET");
  },
  getCountries: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getCountriesUrl, "GET");
  },
  getBrandImages: async (params: BrandsImagesPayload): Promise<AxiosResponse> => {
    const paramsUrl = new URLSearchParams();
    params.brands && paramsUrl.append("identifier", params.brands.join(","));
    paramsUrl.append("isEssilor", params.isEssilor ? "true" : "false");
    return createAndExecuteService(getBrandImagesUrl, "GET", paramsUrl);
  },
  getSearchTermAssociation: async (): Promise<AxiosResponse> => {
    const qparams = appendFiltersToURLSearchParams({
      q: "byAssociationType",
      associationType: "LandingPageURL",
    });

    return createAndExecuteService(getSearchTermAssociationUrl, "GET", qparams);
  },

  getUrlWithTokenEl360: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getUrlWithTokenEl360Url, "GET");
  },

  getUrlWithTokenRewards: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getUrlWithTokenRewardsUrl, "GET");
  },

  getLensSimulatorUrl: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getLensSimulatorRedirectUrl, "GET");
  },

  getContactInfo: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getContactInfoUrl, "GET");
  },

  redirectToEssilorPro: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(redirectToEssilorProUrl, "GET");
  },

  getEyemed: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getEyemedUrl, "GET");
  },

  getStarDoorsForBrand: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getStarDoorsForBrandUrl, "GET");
  },
};
