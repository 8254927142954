import { BFFApiDomain } from "../serviceUtils";

export const getUserSalesRepUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/salesrep/{doorId}";

export const getProfileAddressesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/doors/addresses";

export const publicUserPolicyUrl =
  BFFApiDomain + "/fo-bff/api/pub/v1/{locale}/users/{username}/policy";

export const privateUserPolicyUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/users/policy";
