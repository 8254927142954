import { BFFApiDomain } from "../serviceUtils";

/////// GET
export const getStagingOrdersUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/stagingOrders";
export const getStagingOrdersCountUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/stagingOrders/items/count";
export const getCustomDetailsStagingOrdersUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/stagingOrders/items/{recipeId}/details";

/////// PUT
export const putStagingOrderNoteUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/stagingOrders/{stagingOrderId}/items/{itemId}/note";
export const putStagingOrderConfirmationUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/stagingOrders/items/confirmation";

/////// DELETE
export const deleteStagingOrdersUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/stagingOrders/items";
export const deleteAllStagingOrdersUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/stagingOrders";

/////// GET | DEVICES |
export const getDevicesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/doors/{doorId}/devices";

////// POST | EDIT PIN |
export const postEditPin =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/user/quickorder/pin";
