import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  canShow?: boolean;
  isInstagram?: boolean;
  maxWidth?: boolean;
  position?: "left" | "right" | "center";
  variant?: string;
}

// eslint-disable-next-line react/display-name
const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      children,
      content,
      canShow = true,
      isInstagram = false,
      maxWidth = false,
      position = "center",
      variant = "",
    }: TooltipProps,
    ref
  ): JSX.Element => {
    return (
      <StyledContainer
        ref={ref}
        className={isInstagram ? "see-on-instagram" : ""}
        maxWidth={maxWidth}
        position={position}
      >
        {children}
        {canShow && (
          <span className={`tooltip-children ${variant === "bottom" ? "bottom" : ""}`}>
            {content}
          </span>
        )}
      </StyledContainer>
    );
  }
);

export const useTooltipInTablePosition = <T extends Element, E extends Element, I extends Element>(
  tooltipLength: number
): {
  tableWrapperRef: React.RefObject<T>;
  tableFirstRowRef: React.RefObject<E>;
  tooltipRef: React.MutableRefObject<React.RefObject<I>[] | null>;
  tooltipPosition: string[];
} => {
  const [tooltipPosition, setTooltipPosition] = useState<string[]>([]);

  const tableWrapperRef = useRef<T>(null);
  const tableFirstRowRef = useRef<E>(null);
  const tooltipRef = useRef<React.RefObject<I>[] | null>(null);

  useEffect(() => {
    const parentContainer = tableWrapperRef.current;
    if (!parentContainer) return;

    const handleScroll = () => {
      if (tableFirstRowRef && tableFirstRowRef.current) {
        const tableFirstRowBoundingRect = tableFirstRowRef.current.getBoundingClientRect();

        const newArray = [...tooltipPosition];
        tooltipRef.current?.map((t: React.RefObject<I | null>, index: number) => {
          if (!!t && !!t.current) {
            const currentBoundingRect = t.current.getBoundingClientRect();

            if (
              tableFirstRowBoundingRect.top + tableFirstRowBoundingRect.height >
              currentBoundingRect.top - 120
            ) {
              newArray[index] = "bottom";
            } else {
              newArray[index] = "";
            }
          }
        });
        setTooltipPosition(newArray);
      }
    };

    parentContainer.addEventListener("scroll", handleScroll);

    document.getElementById("root")?.addEventListener("scroll", handleScroll);

    return () => {
      parentContainer.removeEventListener("scroll", handleScroll);
      document.getElementById("root")?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    tooltipRef.current = Array.from({ length: tooltipLength }, () => React.createRef());
  }, [tooltipLength]);

  return { tableWrapperRef, tableFirstRowRef, tooltipRef, tooltipPosition };
};

const StyledContainer = styled.div<{ maxWidth: boolean; position: "left" | "right" | "center" }>`
  position: relative;
  display: inline-block;
  border-radius: 0.25rem;

  &.see-on-instagram {
    .tooltip-children {
      width: 12.5rem;
      padding: 0;

      &:before {
        left: unset;
      }
    }
  }

  .tooltip-children {
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    padding: 1rem 1.25rem;
    width: max-content;
    min-height: 3.375rem;
    color: ${(props) => props.theme.palette.primary};
    background-color: ${(props) => props.theme.palette.gray.medium};
    position: absolute;
    bottom: calc(100% + 1.625rem);
    ${(props) => props.position == "center" && { left: "50%" }};
    ${(props) => props.position == "center" && { transform: "translateX(-50%)" }};

    ${(props) => props.position == "left" && { left: "-100%" }};
    ${(props) => props.position == "left" && { transform: "translateX(-80%)" }};

    ${(props) => props.position == "right" && { right: "-100%" }};
    ${(props) => props.position == "right" && { transform: "translateX(80%)" }};

    overflow-y: visible;
    z-index: 2;
    max-width: ${(props) => (props.maxWidth ? "17rem" : "unset")};

    &.bottom {
      transform: translate(-50%, 200%);

      &:before {
        top: -8.27px;
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: -0.625rem;
      z-index: -1;
      transform: rotate(45deg) translateX(-50%);
      -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      /* left: 7.8rem; */
      bottom: -8.27px;
      transform: rotate(45deg);
      background-color: ${(props) => props.theme.palette.gray.medium};
      height: 1.125rem;
      width: 1.125rem;
      ${(props) => props.position == "left" && { left: "85%" }};
      ${(props) => props.position == "right" && { right: "85%" }};
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      bottom: -8.27px;
      background-color: transparent;
      height: 12.5rem;
      width: 12.5rem;
    }
  }

  &:hover {
    .tooltip-children {
      visibility: visible;
    }
  }
`;

export default Tooltip;
