import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { TutorialPill } from "../../../../store/catalogue/catalogueInterface";
import { selectTooltipMap } from "../../../../store/store/storeSlice";
import Carousel from "../../../styled-UI/Carousel";
import FavoritesTooltipSection from "./FavoritesTooltipSection";
import { ItemsTutorialPillMap } from "../../../../interfaces/gridInterfaces";
import clsx from "clsx";

interface Props {
  showAccountMenu: (showMenu: boolean) => void;
  tooltipContents: TutorialPill[];
  isFlipped?: boolean;
}

const FavoritesTooltip = ({ showAccountMenu, tooltipContents, isFlipped }: Props): JSX.Element => {
  const accountMenuRef = useRef<HTMLDivElement | null>(null);
  const tooltipMap = useSelector(selectTooltipMap);
  const [slideToShow, setSlideToShow] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const isEmpty = (obj: ItemsTutorialPillMap) => {
    return Object.keys(obj).length === 0;
  };

  //MANAGE THE CLICK OUTSIDE THE MENU IN ORDER TO CLOSE IT
  useEffect(() => {
    function handler(event: MouseEvent) {
      if (accountMenuRef.current) {
        const target = (event.target as Node) || null;
        if (!accountMenuRef.current.contains(target)) showAccountMenu(false);
        event.stopPropagation();
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  const renderTiles = (): JSX.Element[] => {
    const tiles: JSX.Element[] = [];

    if (tooltipContents)
      tooltipContents.map((content, index) => {
        tiles.push(
          <FavoritesTooltipSection
            title={tooltipMap?.[content.id]?.title}
            description={tooltipMap?.[content.id]?.description}
            linkString={tooltipMap?.[content.id]?.linkString}
            linkLabel={tooltipMap?.[content.id]?.linkLabel}
            openInNewTab={tooltipMap?.[content.id]?.openInNewTab}
            externalLink={tooltipMap?.[content.id]?.externalLink}
            slideNumber={index + 1}
            totalSlide={tooltipContents.length}
            nextSlide={setSlideToShow}
            showAccountMenu={showAccountMenu}
            currentPage={currentPage}
            id={content.id}
            data-element-id="Overlay_Tooltips"
          />
        );
      });

    return tiles.length ? tiles : [<></>];
  };

  return !isEmpty(tooltipMap) ? (
    <MenuContainer className={clsx(isFlipped && "flipped-horizontaly")}>
      <Menu ref={accountMenuRef} className={clsx(isFlipped && "flipped-horizontaly")}>
        <MenuSection>
          <Carousel
            key="images-carousel"
            lazyLoad="progressive"
            slidesToScroll={1}
            slidesToShow={1}
            goToSlide={slideToShow}
            navigationType="none"
            noPaddingBottom
            callbackOnChangeSlide={(page: number) => {
              setCurrentPage(page);
            }}
          >
            {renderTiles()}
          </Carousel>
        </MenuSection>
      </Menu>
    </MenuContainer>
  ) : (
    <></>
  );
};

const MenuContainer = styled.div`
  padding: 1.125rem;
  position: absolute;
  left: -2.95rem;
  z-index: 9998;
  cursor: auto;

  &.flipped-horizontaly {
    left: -20.95rem;
  }
`;

const Menu = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
  box-shadow: 0.5rem 0.1875rem 2.5rem 0 rgba(3, 20, 52, 0.1);
  width: 22.125rem;
  padding: 1.5rem;
  text-align: left;
  box-sizing: border-box;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: -1.5rem;
    width: 0;
    height: 0;
    border-style: solid;
    z-index: 300;
    border-color: transparent transparent ${(props) => props.theme.palette.white} transparent;
    border-width: 0.8125rem;
  }

  &.flipped-horizontaly {
    &:after {
      right: 1rem;
    }
  }
`;

const MenuSection = styled.div`
  box-sizing: border-box;
`;

export default FavoritesTooltip;
