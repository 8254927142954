import { useCallback, useState, useEffect } from "react";

export const useDragScroll = () => {
  const [node, setNode] = useState<HTMLElement | null>(null);
  const [isLeftGradientVisible, setIsLeftGradientVisible] = useState(false);
  const [isRightGradientVisible, setIsRightGradientVisible] = useState(false);
  const [isDragging, setIsDragging] = useState(false); // Stato per tracciare se si sta facendo drag

  const ref = useCallback((nodeEle: HTMLElement | null) => {
    setNode(nodeEle);
  }, []);

  const updateGradients = useCallback(() => {
    if (!node) return;

    setIsLeftGradientVisible(node.scrollLeft > 0);
    setIsRightGradientVisible(node.scrollLeft < node.scrollWidth - node.clientWidth);
  }, [node]);

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (!node || window.innerWidth > 1366) return;

      const startPos = {
        left: node.scrollLeft,
        top: node.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };

      setIsDragging(false); // Reset dragging at the start of the mouse down

      const handleMouseMove = (e: MouseEvent) => {
        const dx = e.clientX - startPos.x;
        const dy = e.clientY - startPos.y;

        if (Math.abs(dx) > 5 || Math.abs(dy) > 5) {
          setIsDragging(true); // Start dragging if the movement is significant
        }

        node.scrollTop = startPos.top - dy;
        node.scrollLeft = startPos.left - dx;
        updateGradients();
        node.style.cursor = "grabbing";
        node.style.userSelect = "none";
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        node.style.cursor = "grab";
        node.style.removeProperty("user-select");
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [node, updateGradients]
  );

  useEffect(() => {
    if (!node) return;

    const handleClick = (e: MouseEvent) => {
      if (isDragging) {
        e.preventDefault();
        e.stopPropagation(); // Stop the click event if dragging
        setIsDragging(false); // Reset dragging state after click prevention
      }
    };

    // Attach event listeners for dragging
    node.addEventListener("mousedown", handleMouseDown);
    node.addEventListener("click", handleClick, true); // Use capture phase to prevent click during drag

    // Initial gradient calculation after DOM updates
    const calculateInitialGradients = () => {
      requestAnimationFrame(updateGradients);
    };
    calculateInitialGradients();

    // Set up a MutationObserver to watch for changes in the container's children
    const observer = new MutationObserver(() => {
      requestAnimationFrame(updateGradients);
    });
    observer.observe(node, { childList: true, subtree: true });

    return () => {
      node.removeEventListener("mousedown", handleMouseDown);
      node.removeEventListener("click", handleClick, true);
      observer.disconnect();
    };
  }, [node, handleMouseDown, updateGradients, isDragging]);

  useEffect(() => {
    const handleResize = () => updateGradients();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [updateGradients]);

  return { ref, isLeftGradientVisible, isRightGradientVisible };
};
