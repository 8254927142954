import { cloneDeep } from "lodash";
import React, { ChangeEvent, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { v4 as uuid } from "uuid";
import useTranslation from "../../../../hooks/useTranslation";
import { AssetPlatformSocialMedia } from "../../../../store/adt/adtInterfaces";
import {
  getAdtSocialMediaOptions,
  updateAdtAssetPlatfomPopup,
} from "../../../../store/adt/adtSagas";
import { selectAssetPlatformStatus } from "../../../../store/adt/adtSlice";
import Button from "../../../styled-UI/Button";
import { CustomOptions } from "../../../styled-UI/CustomSelect";
import CustomText from "../../../styled-UI/CustomText";
import Popup from "../../../styled-UI/Popup";
import ADTPlatformSelect, { getDuplicatePlatforms } from "./ADT-platform/ADTPlatformSelect";
import ADTPlatformWebsite from "./ADT-platform/ADTPlatformWebsite";
import { checkIsWebsiteValid } from "./ADT-platform/isValidWebSite";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

const ADTPlatformPopup = ({ onClose, isOpen }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();

  const { setPopup: isLoading } = useSelector(selectAssetPlatformStatus);
  const { socialMediaOptions: isLoadingDropdown } = useSelector(selectAssetPlatformStatus);

  const [webSiteURL, setWebsiteURL] = useState<string>("");
  const [noWebsite, setNoWebsite] = useState<boolean>(false);
  const [showError, setShowError] = useState<"local" | "api" | null>(null);
  const [socialProfileList, setSocialProfileList] = useState<AssetPlatformSocialMedia[]>([]);

  useEffect(() => {
    dispatch(getAdtSocialMediaOptions());
  }, []);

  const onSubmit = () => {
    setShowError(null);

    if (
      !checkIsWebsiteValid(webSiteURL, noWebsite) || // either the website is filled in or the checkbox for "no website" is selected
      getDuplicatePlatforms(socialProfileList).length > 0 // no more than 1 handle for platform is provided
    ) {
      setShowError("local");
      return;
    } else {
      dispatch(
        updateAdtAssetPlatfomPopup({
          websiteUrl: noWebsite ? null : webSiteURL,
          socialPlatforms: socialProfileList.filter((_) => _.platformName !== ""),
          callback: (success?: boolean) => {
            if (success) onClose();
            else setShowError("api");
          },
        })
      );
    }
  };

  const handleWebSiteChange = (webSiteURL: string) => {
    setWebsiteURL(webSiteURL);
  };

  const handleCheckboxChange = (checked: boolean) => {
    setNoWebsite(checked);
    checked && setWebsiteURL("");
    setShowError(null);
  };

  const handlePlatformChange = (selectedOption: CustomOptions | null, id: string) => {
    setSocialProfileList((oldList) => {
      const newList = cloneDeep(oldList);
      const toUpdate = newList.find((_) => _.uuid === id);
      if (toUpdate) toUpdate.platformName = selectedOption?.value.toString() ?? "";

      return newList;
    });
  };

  const handleProfileChange = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    setSocialProfileList((oldList) => {
      const newList = cloneDeep(oldList);
      const toUpdate = newList.find((_) => _.uuid === id);
      if (toUpdate) toUpdate.platformUserName = event.target.value;

      return newList;
    });
  };

  const onAddSocialProfile = () => {
    setSocialProfileList([
      ...socialProfileList,
      { uuid: uuid(), platformName: "", platformUserName: "" },
    ]);
  };

  const duplicatedPlatform = useMemo(() => getDuplicatePlatforms(socialProfileList), [
    socialProfileList,
  ]);

  return (
    <>
      <Popup
        isOpen={isOpen}
        hideCloseButton
        close={() => {
          return;
        }}
        isCentered
        enableOverflowAuto
      >
        <ModalContainer>
          <CustomText as="h1" fontSizePx={32} lineHeightPx={32} marginBottomPx={24}>
            {translateLabel("ADT_PLATFORM_TITLE")}
          </CustomText>
          <CustomText as="p" fontSizePx={14} lineHeightPx={24}>
            {translateLabel("ADT_PLATFORM_SUBTITLE")}
          </CustomText>
          <Content>
            <ADTPlatformWebsite
              noWebsite={noWebsite}
              webSiteURL={webSiteURL}
              showError={showError === "local"}
              handleWebSiteChange={handleWebSiteChange}
              handleCheckboxChange={handleCheckboxChange}
            />
            <div>
              <ADTPlatformSelect
                platformChange={handlePlatformChange}
                profileChange={handleProfileChange}
                onAdd={onAddSocialProfile}
                socialProfileList={socialProfileList}
                isLoading={isLoadingDropdown === "LOADING"}
                showError={showError === "local"}
                duplicatedPlatform={duplicatedPlatform}
              />
            </div>
          </Content>
          <CustomText as="p" fontSizePx={14} lineHeightPx={24} marginBottomPx={32}>
            {translateLabel("ADT_PLATFORM_ADVERTISE")}.
          </CustomText>
          <ButtonContainer>
            {showError === "api" && (
              <CustomText as="p" fontSizePx={14} lineHeightPx={24} color="red">
                {translateLabel("ADT_PLATFORM_GENERIC_ERROR")}
              </CustomText>
            )}
            <Button type="primary" onClick={onSubmit} isLoading={isLoading === "LOADING"}>
              {translateLabel("ADT_PLATFORM_BTN_SAVE_CONTINUE")}
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </Popup>
    </>
  );
};

const ModalContainer = styled.div`
  width: 60.5rem;
  padding: 2rem 4.25rem;
  max-height: 48.9375rem;
  overflow-y: scroll;
  > div:last-child {
    display: flex;
    justify-content: flex-end;
  }
`;

const Content = styled.div`
  margin: 2.5rem 0 2rem;
  border-bottom: solid 1px ${(props) => props.theme.palette.gray.medium};
  padding-bottom: 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 1.5rem;
`;

export default ADTPlatformPopup;
