import { Product } from "./../../interfaces/productInterface";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../storeConfig";
import {
  LoadingSearch,
  MetaData,
  SearchCategories,
  SearchResults,
  SearchState,
  Suggestion,
} from "./searchInterfaces";
import { FacetView } from "../../interfaces/facetInterfaces";
import { MultipleRequestStatus, SetMultipleRequestStatus } from "../../interfaces/mainInterfaces";

export const DEFAULT_SEARCH_RESULTS: SearchResults = {
  resultList: [],
  resultsCount: null,
  resultsStartNumber: null,
  resultsTotal: null,
  metaData: null,
};

export const DEFAULT_LOADING_SEARCH: MultipleRequestStatus<LoadingSearch> = {
  catalogue: "IDLE",
  facets: "IDLE",
};

export const sliceName = "search";
const initialState: SearchState = {
  suggestions: [],
  searchedTerm: "",
  results: { ...DEFAULT_SEARCH_RESULTS },
  searchFacetView: null,
  searchLoading: DEFAULT_LOADING_SEARCH,
  isFromSuggestion: false,
  categories: [],
  isSearchOpen: false,
};

export const searchSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLoadingSearch: (state, action: PayloadAction<SetMultipleRequestStatus<LoadingSearch>>) => {
      state.searchLoading = { ...state.searchLoading, [action.payload.type]: action.payload.value };
    },
    setIsSearchOpen: (state, action: PayloadAction<boolean>) => {
      state.isSearchOpen = action.payload;
    },
    saveSuggestions: (state, action: PayloadAction<Suggestion[]>) => {
      state.suggestions = action.payload ? action.payload : [];
    },
    saveSearchedTerm: (state, action: PayloadAction<string>) => {
      state.searchedTerm = action.payload;
    },
    saveSearchResult: (state, action: PayloadAction<SearchResults>) => {
      state.results = action.payload;
    },
    saveSearchFacetView: (state, action: PayloadAction<FacetView[] | null>) => {
      state.searchFacetView = action.payload;
    },
    saveIsFromSuggestion: (state, action: PayloadAction<boolean>) => {
      state.isFromSuggestion = action.payload;
    },
    saveSearchCategories: (state, action: PayloadAction<SearchCategories[]>) => {
      state.categories = action.payload;
    },
    clearSearchResult: (state) => {
      state.results = { ...DEFAULT_SEARCH_RESULTS };
    },
    resetState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  setLoadingSearch,
  saveSuggestions,
  saveSearchedTerm,
  saveSearchResult,
  saveSearchFacetView,
  saveSearchCategories,
  saveIsFromSuggestion,
  clearSearchResult,
  setIsSearchOpen,
  resetState,
} = searchSlice.actions;

export const selectLoadingSearch = (state: RootState): MultipleRequestStatus<LoadingSearch> => {
  return state.search.searchLoading;
};

export const selectSuggestions = (state: RootState): Suggestion[] => {
  return state.search.suggestions;
};

export const selectSuggestionsMax3 = createSelector(
  selectSuggestions,

  (suggestions: Suggestion[]): Suggestion[] => {
    return suggestions.slice(0, 3);
  }
);

export const selectProducts = (state: RootState): Product[] => {
  return state.search.results.resultList;
};

export const selectFacetView = (state: RootState): FacetView[] | null => {
  return state.search.searchFacetView;
};

export const selectSearchedTerm = (state: RootState): string | null => {
  return state.search.searchedTerm;
};

export const selectTotalPages = (state: RootState): number => {
  const resultTotal: number = state.search.results.resultsTotal
    ? state.search.results.resultsTotal
    : 0;
  return Math.ceil(resultTotal / state.catalogue.productPerPage);
};

export const selectTotalProducts = (state: RootState): number => {
  return state.search.results.resultsTotal ? state.search.results.resultsTotal : 0;
};

export const selectProductPerPage = (state: RootState): number => {
  return state.catalogue.productPerPage;
};

//Did you mean state, if not null contains the correct term
export const selectMetaData = (state: RootState): MetaData | null => {
  return state.search.results.metaData ? state.search.results.metaData : null;
};

export const selectResultsCount = (state: RootState): number | undefined | null => {
  return state.search.results.resultsCount;
};

export const selectSearchCategories = (state: RootState): SearchCategories[] => {
  return state.search.categories;
};

export const selectIsFromSuggestion = (state: RootState): boolean | null => {
  return state.search.isFromSuggestion;
};

export const selectIsSearchOpen = (state: RootState): boolean => {
  return state.search.isSearchOpen;
};

export default searchSlice.reducer;
