import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Sku } from "../../../../interfaces/productInterface";
import { OrderItem } from "../../../../store/cart/cartInterfaces";
import { selectAddToCartRequestStatus } from "../../../../store/cart/cartSlice";
import { PreCartProduct } from "../../../../store/catalogue/catalogueInterface";
import { Door } from "../../../../store/user/userInterfaces";
import AddSizeAFA from "../../AFA-size-widget/AddSizeAFA";

interface Props {
  orderItem: OrderItem;
  currentDoor: Door;
  availabilityStatus?: string;
  handleDelete: (orderItem: OrderItem) => void;
  onClick: (obj: PreCartProduct, orderItemId: string) => void;
  isSingleSize: boolean;
  disabled: boolean;
  showOtherSku?: boolean;
}

const CartTileAFASize = ({
  orderItem,
  currentDoor,
  availabilityStatus,
  handleDelete,
  onClick,
  isSingleSize = false,
  disabled = false,
  showOtherSku = false,
}: Props): JSX.Element => {
  const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false);
  const addToPrecartLoading = useSelector(selectAddToCartRequestStatus);

  const sku = {
    productAvailable: orderItem.availableQuantity ?? "0",
    skuId: orderItem.sku.uniqueID,
    sku: orderItem.sku as Sku,
    sizeString: orderItem.sku.sizeString ?? "",
  };

  useEffect(() => {
    if (orderItem && isAddingToCart) {
      setIsAddingToCart(false);
    }
  }, [orderItem]);

  const handleOnClick = (newQuantity: number) => {
    setIsAddingToCart(true);
    onClick(
      {
        partNumber: orderItem.sku.partNumber,
        sku: orderItem.sku,
        multidoorId: currentDoor.orgentityId,
        orgentityName: currentDoor.orgentityName,
        quantity: newQuantity,
      },
      orderItem.orderItemId
    );
  };

  return (
    <AddSizeAFA
      key={orderItem.sku.uniqueID + orderItem.orderItemId}
      style="cart"
      AFAsku={sku}
      quantity={orderItem?.quantity ?? 0}
      availabilityStatus={availabilityStatus}
      handleDelete={handleDelete}
      handleOnClick={handleOnClick}
      orderItem={orderItem}
      isSingleSize={isSingleSize}
      disabled={disabled || (isAddingToCart && addToPrecartLoading === "LOADING")}
      showOtherSku={showOtherSku}
      isCart
      availableQuantityToShow={sku.productAvailable}
    />
  );
};

export default CartTileAFASize;
