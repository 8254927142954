import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import { Page } from "../../../interfaces/gridInterfaces";
import { selectItemsMap, CMS_ROUTES, selectPagesLayout } from "../../../store/store/storeSlice";
import LoginLayout from "../../cms-layouts/page-layouts/LoginLayout";
import Logo from "../../layouts/Logo";
import CustomText from "../../styled-UI/CustomText";
import LanguageSelector from "../../widgets/LanguageSelector";
import NeedHelp from "./NeedHelp";
import { mapUrl } from "../../../store/store/storeSagas";
import { isPublicPage, parseUrlInfo } from "../../../routing/routesUtils";
import { useLocation } from "react-router-dom";

interface LoginContent {
  title?: string;
  titleColour?: string;
  description?: string;
  description1Colour?: string;
  description2?: string;
  description2Colour?: string;
  imgHref?: string;
  videoHref?: string;
  rightContent: JSX.Element;
  documentHref?: string;
  videoTitle?: string;
}

const PublicPage = (): JSX.Element => {
  const { translateLabel } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const itemsMap = useSelector(selectItemsMap);
  const pagesLayout: { [page: string]: Page } = useSelector(selectPagesLayout);
  const siteInfo = parseUrlInfo();
  const layout = siteInfo.pageType ? pagesLayout[siteInfo.pageType] : null;
  const url = window.location.hostname;

  useEffect(() => {
    if (isPublicPage()) dispatch(mapUrl({}));
  }, [location]);

  const [renderInfo, setRenderInfo] = useState<LoginContent>({
    title: "",
    titleColour: "",
    description: "",
    description1Colour: "",
    description2: "",
    description2Colour: "",
    imgHref: "",
    videoHref: "",
    rightContent: <div></div>,
    videoTitle: "",
  });

  // Updates layout and items
  useEffect(() => {
    if (layout) {
      const renderInfo: LoginContent = {
        title: "",
        titleColour: "",
        description: "",
        description1Colour: "",
        description2: "",
        description2Colour: "",
        imgHref: "",
        videoHref: "",
        rightContent: <div></div>,
        documentHref: "",
      };
      if (layout.rows)
        layout.rows.map((row) => {
          if (row.cols && row.cols[0] && row.cols[0].id) {
            const item = itemsMap[row.cols[0].id];
            if (item && item.result && item.result.length > 0) {
              switch (row.name) {
                case "public-area-asset":
                  renderInfo.imgHref = item.result[0]?.data?.uri;
                  break;
                case "video":
                  renderInfo.videoHref = item.result[0]?.data?.uri;
                  renderInfo.videoTitle = item.result[0]?.title;
                  break;
                case "teaser":
                  renderInfo.title = item.result[0]?.title;
                  renderInfo.titleColour = item.result[0]?.titleColour;
                  renderInfo.description = item.result[0]?.description;
                  renderInfo.description1Colour = item.result[0]?.description1Colour;
                  renderInfo.description2 = item.result[0]?.description2;
                  renderInfo.description2Colour = item.result[0]?.description2Colour;
                  break;
                case "document":
                  renderInfo.documentHref = item.result[0]?.data.uri;
                  break;
              }
            }
          }
        });

      if (layout.page === CMS_ROUTES.NEED_HELP) {
        renderInfo.rightContent = <NeedHelp />;
      }

      setRenderInfo(renderInfo);
    }
  }, [layout, itemsMap]);

  return (
    <div>
      {layout?.page === CMS_ROUTES.NEED_HELP && (
        <LoginLayout
          title={renderInfo.title}
          titleColour={renderInfo.titleColour}
          description={renderInfo.description}
          description1Colour={renderInfo.description1Colour}
          description2={renderInfo.description2}
          description2Colour={renderInfo.description2Colour}
          imgHref={renderInfo.imgHref}
          videoHref={renderInfo.videoHref}
          rightContent={renderInfo.rightContent}
          videoTitle={renderInfo.videoTitle}
          page={layout?.page === CMS_ROUTES.NEED_HELP ? true : false}
        />
      )}

      <Footer>
        <FooterContent>
          <LogoFooter>
            <Logo color="white" heightPx={27} isLink={false} />
          </LogoFooter>
          <DisclaimerContainer>
            <CustomText
              as="span"
              color="text-blue"
              fontSizePx={10}
              lineHeightPx={14}
              font="font-regular"
            >
              {translateLabel(url.includes(".com.cn") ? "COPYRIGHT_CHINA" : "COPYRIGHT")} <br />
              {translateLabel("BROWSER_NOTE")}
            </CustomText>
          </DisclaimerContainer>
        </FooterContent>
        <SelectContainer>
          <LanguageSelector />
        </SelectContainer>
      </Footer>
    </div>
  );
};

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.palette.primary};
  height: 6.25rem;
  padding: 0 2.938rem;
`;

const FooterContent = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const LogoFooter = styled.footer`
  margin-right: 2rem;
`;

const SelectContainer = styled.footer`
  flex-shrink: 0;
`;

const DisclaimerContainer = styled.footer`
  span {
    padding-right: 2.938rem;
  }
`;

export default PublicPage;
