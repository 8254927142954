import React from "react";
import styled from "styled-components/macro";
import LoaderElement from "./LoaderElement";
import LoaderElementCircle from "./LoaderElementCircle";

const LoaderAddSize = (): JSX.Element => {
  const addSize = (
    <AddSize>
      <LoaderElementCircle height={30} />
      <Quantity>
        <LoaderElement height={30} />
      </Quantity>
      <LoaderElementCircle height={30} />
    </AddSize>
  );

  return (
    <Row>
      <LoaderElement height={30} />
      {addSize}
    </Row>
  );
};

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
`;
const AddSize = styled.div`
  display: flex;
  flex-direction: row;
  /* column-gap: 1rem; */
  align-items: flex-end;
  min-width: 150px;
`;

const Quantity = styled.div`
  margin-right: 15px;
  width: 100%;
`;

export default LoaderAddSize;
