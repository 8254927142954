import React from "react";
import styled from "styled-components/macro";
import CustomText from "../../styled-UI/CustomText";
import { LpInformationSheetItem } from "../../../interfaces/cmsInterfaces";
import useDataHref from "../../../hooks/useDataHref";
import SetInnerHTMLWithSanitize from "../SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  card: LpInformationSheetItem;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LpInformationSheetCard = ({
  card,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const descrWithHref = useDataHref({ content: card.description });
  return (
    <Card>
      {card?.multiMediaType?.[0]?.data?.uri && (
        <IconContainer src={card?.multiMediaType[0].data.uri} />
      )}
      <CustomText
        as="span"
        color={isDarkMode ? "white" : "primary"}
        fontSizePx={16}
        font={customFont ? "custom-bold" : "font-bold"}
        fontFamily={customFont}
        marginBottomPx={14}
        customColor={card?.titleColour}
      >
        {card?.title}
      </CustomText>
      {card?.description && (
        <CustomText
          as="span"
          color={isDarkMode ? "white" : "primary"}
          fontSizePx={14}
          font={customFont ? "custom-bold" : "font-bold"}
          fontFamily={customFont}
          customColor={card?.description1Colour}
        >
          <SetInnerHTMLWithSanitize text={descrWithHref.updatedContent ?? card.description} />
        </CustomText>
      )}
    </Card>
  );
};

const IconContainer = styled.div<{ src: string }>`
  background-size: cover;
  margin-bottom: 1.8rem;
  background-image: url(${(props) => props.src && props.src});
  width: 1.8rem;
  height: 1.8rem;
`;

const Card = styled.div`
  height: 19.8rem;
  width: 24%;
  background-color: ${(props) => props.theme.palette.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3.5rem 4.3rem 7.5rem 3rem;
  margin-bottom: 1rem;
`;

export default LpInformationSheetCard;
