import React, { useEffect, useState } from "react";

import { getAcceptCookie } from "../../../utils/cookieUtils";
import CookiesContent from "./CookiesContent";

const CookiesBanner = (): JSX.Element => {
  const [isBannerOpen, setIsBannerOpen] = useState<boolean>(false);

  useEffect(() => {
    // if cookies are not accepted, and banner is not already open, open it
    if (!getAcceptCookie()) !isBannerOpen && setIsBannerOpen(true);
    // if cookies are accepted, and banner is still open, close it
    else isBannerOpen && setIsBannerOpen(false);
  }, []);

  if (isBannerOpen) return <CookiesContent closeBanner={() => setIsBannerOpen(false)} />;
  return <></>;
};

export default CookiesBanner;
