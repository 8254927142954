import React, { useEffect } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../../../hooks/useTranslation";

import { useSelector } from "react-redux";
import theme from "../../../../../assets/theme/theme";
import useMostUsedLensesCMS from "../../../../../hooks/useMostUsedLensesCMS";
import { SecondLevelMenu } from "../../../../../interfaces/menuInterfaces";
import { MostUsedChip } from "../../../../../interfaces/mostUsedInterfaces";
import { selectGetThirdMenuStatus } from "../../../../../store/store/storeSlice";
import { selectMostUsedContent } from "../../../../../store/user/userSlice";
import Chip from "../../../../styled-UI/Chip";
import CustomText from "../../../../styled-UI/CustomText";
import { LoaderMostUsed } from "../../../../styled-UI/loader/most-used/LoaderCategoriesAndServices";
import useGenerateCatalogueMostUsed from "../useGenerateCatalogueMostUsed";
import { ChipsHandler } from "../useSelectedMostUsed";

interface Props {
  categoryMenu: SecondLevelMenu;
  chipsHandler: ChipsHandler;
  closeTooltip?: () => void;
  isMaxChipsReached: boolean;
}

const MostUsedChipsCatalogue = ({
  categoryMenu,
  chipsHandler,
  isMaxChipsReached,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  const identifier = categoryMenu?.identifier?.toUpperCase();
  const mostUsedData = useSelector(selectMostUsedContent);
  const isLensesCategory = identifier === "LENSES";
  const isLoading = useSelector(selectGetThirdMenuStatus)[identifier];
  const mostUsedLenses = useMostUsedLensesCMS();
  const selectableChips = useGenerateCatalogueMostUsed(categoryMenu);

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////// SAVING A NEW CHIP ///////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (selectableChips.length && mostUsedData.unused && isLensesCategory) {
      mostUsedLenses?.forEach((chip) => {
        const match = selectableChips.find((item) => item.name === chip.name);
        match && chipsHandler.selectChip(match);
      });
    }
  }, [selectableChips, mostUsedData]);

  const isChipNotSelected = (x: MostUsedChip): boolean => {
    return !chipsHandler.selectedChips.find((y) => y.name === x.name);
  };

  const handleChipClick = (chip: MostUsedChip) => {
    isChipNotSelected(chip) && !isMaxChipsReached && chipsHandler.selectChip(chip);
    !isChipNotSelected(chip) && chipsHandler.deselectChip(chip);
  };

  //////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////// RENDER ////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const renderColumns = (): JSX.Element => {
    return (
      <>
        {isLoading === "LOADING" ? (
          <ChipsContainer>
            <LoaderMostUsed />
          </ChipsContainer>
        ) : selectableChips.length > 0 ? (
          <Wrapper>
            <CustomText
              as="span"
              font="font-bold"
              fontSizePx={18}
              marginBottomPx={8}
              lineHeightPx={32}
              uppercase
            >
              {translateLabel(
                "MOST_USED_POPUP_" +
                  selectableChips[0]?.catalogueInfo.menuCategory?.replace(/\s+/g, "")
              )}
            </CustomText>
            <ChipsContainer>
              {selectableChips.map((chip, i) => (
                <ChipContainer key={i}>
                  <Chip
                    text={chip.label}
                    active={!isChipNotSelected(chip)}
                    onClick={() => handleChipClick(chip)}
                    showTitle
                    style={isChipNotSelected(chip) ? "border" : "blue-on-selected"}
                    borderRadiusPx={4}
                    widthPx={200}
                    heightPx={48}
                    paddingLeftPx={16}
                    paddingRightPx={16}
                    gapPx={16}
                    justifyContent="center"
                    fontSize={12}
                    lineHeightPx={18}
                    letterSpacingPx={0.2}
                    fontWeight={isChipNotSelected(chip) ? 400 : 700}
                    showFileIcon={!isChipNotSelected(chip)}
                    typeIcon="checkmark"
                    hideIcon
                    iconWithoutMargin
                    widthText={156}
                    spacedContent
                    border={`1px solid ${theme.palette.chip.blue}`}
                    noHover
                  />
                </ChipContainer>
              ))}
            </ChipsContainer>
          </Wrapper>
        ) : (
          <></>
        )}
      </>
    );
  };

  return renderColumns();
};

const Wrapper = styled.div``;

const ChipContainer = styled.div`
  display: inline-block;
`;

const ChipsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 0fr);
  gap: 1rem;

  @media ${(props) => props.theme.queries.md} {
    grid-template-columns: repeat(4, 0fr);
  }

  @media ${(props) => props.theme.queries.sm} {
    grid-template-columns: repeat(3, 0fr);
  }
`;
export default MostUsedChipsCatalogue;
