import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import useCanRender from "../../hooks/useCanRender";
import { authRoutes } from "../../routing/authRoutes";
import { SingleRoute } from "../../routing/routesInterfaces";
import { selectIsBackOfficeUser, selectIsSubuser } from "../../store/user/userSlice";
import AppLayout from "../layouts/AppLayout";
import LoadingBackground from "../styled-UI/LoadingBackground";
import UnauthorizedSection from "./UnautorithorizedSection";

interface Props {
  path: string;
  props: any;
  component: any;
}

const GenericConditionalRender = ({ path, props, component: C }: Props): JSX.Element => {
  const isBackOfficeUser = useSelector(selectIsBackOfficeUser);
  const isSubuser = useSelector(selectIsSubuser);

  const canRenderFunction = useCanRender();
  const [canRender, setCanRender] = useState<boolean | null>(null);

  const route = authRoutes
    // Get all routes that contain the current one.
    .filter(({ path }) => useRouteMatch(path));

  const checkIfRouteCanBeRendered = (singleRoute: SingleRoute): boolean => {
    // if route should not be shown to BO users, and it is one, it takes precedence over current privileges
    if (singleRoute.isNotBOUser && isBackOfficeUser) return false;

    // if route should not be shown to subusers, and it is one, it takes precedence over current privileges
    if (singleRoute.isNotSubuser && isSubuser) return false;

    // if a privilege is specified, return canRenderFunction result
    if (singleRoute.privilege)
      return canRenderFunction(singleRoute.privilege, null, singleRoute.onlySubuser);

    return true; // all other cases can be rendered
  };

  useEffect(() => {
    if (route && route.length > 0) {
      let singleRoute = route[0];
      if (path.includes("essilor-order-confirmation")) {
        singleRoute =
          route.find((currRoute) => currRoute.path.includes("essilor-order-confirmation")) ||
          route[0];
      }
      const status = checkIfRouteCanBeRendered(singleRoute);
      if (status !== canRender) setCanRender(status);
    }
  }, [path]);

  const printPage = () => {
    if (canRender === null) return <LoadingBackground />;

    if (!canRender) {
      return (
        <AppLayout>
          <UnauthorizedSection />
        </AppLayout>
      );
    }

    // if (sapStatus && !sapStatus?.isActive) {
    //   return <SapDecouplingSection sapStatus={sapStatus} />;
    // }

    return (
      <AppLayout>
        <C {...props} />
      </AppLayout>
    );
  };

  return printPage();
};

export default GenericConditionalRender;
