import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import useCanRender from "../../hooks/useCanRender";
import { selectGetPrivilegesStatus } from "../../store/user/userSlice";
import Loader from "../styled-UI/Loader";
import UnauthorizedSection from "./UnautorithorizedSection";

interface Props {
  children: ReactNode;
  privilege: string | null;
  loader?: () => JSX.Element;
}

export default function ConditionalComponentRender({
  children,
  privilege,
  loader,
}: Props): JSX.Element | null {
  const canRender = useCanRender();

  const getPrivilegesStatus = useSelector(selectGetPrivilegesStatus);

  const DefaultLoader = (): JSX.Element => {
    return (
      loader?.() || (
        <Center>
          <Loader sizePx={48} />
        </Center>
      )
    );
  };

  if (getPrivilegesStatus === "SUCCESS") {
    return canRender(privilege) ? <>{children}</> : <UnauthorizedSection />;
  }
  if (getPrivilegesStatus === "LOADING") {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }
  return null;
}

interface ContainerProps {
  marginBottomPx?: number;
}

const Container = styled.div<ContainerProps>`
  padding: 3rem;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
  margin-bottom: ${(props) => (props.marginBottomPx ? props.marginBottomPx / 16 + "rem" : "0")};
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
