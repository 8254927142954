import React from "react";
import ContentLoader from "react-content-loader";

const LoaderCartDetails = (): JSX.Element => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={"100%"}
    viewBox="0 0 830 75"
    backgroundColor="#f3f3f3"
    // foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="65" height="18" />
    <rect x="0" y="28" rx="0" ry="0" width="65" height="18" />
    <rect x="0" y="56" rx="0" ry="0" width="65" height="18" />
    <rect x="125" y="0" rx="0" ry="0" width="65" height="18" />
    <rect x="125" y="28" rx="0" ry="0" width="65" height="18" />
    <rect x="125" y="56" rx="0" ry="0" width="65" height="18" />
    <rect x="320" y="0" rx="0" ry="0" width="65" height="18" />
    <rect x="320" y="28" rx="0" ry="0" width="65" height="18" />
    <rect x="320" y="56" rx="0" ry="0" width="65" height="18" />
    <rect x="445" y="0" rx="0" ry="0" width="65" height="18" />
    <rect x="445" y="28" rx="0" ry="0" width="65" height="18" />
    <rect x="445" y="56" rx="0" ry="0" width="65" height="18" />
    <rect x="640" y="0" rx="0" ry="0" width="65" height="18" />
    <rect x="640" y="28" rx="0" ry="0" width="65" height="18" />
    <rect x="640" y="56" rx="0" ry="0" width="65" height="18" />
    <rect x="765" y="0" rx="0" ry="0" width="65" height="18" />
    <rect x="765" y="28" rx="0" ry="0" width="65" height="18" />
    <rect x="765" y="56" rx="0" ry="0" width="65" height="18" />
  </ContentLoader>
);

export default LoaderCartDetails;
