import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import {
  CartInfo,
  CartLoading,
  OrderMultidoor,
  OrderMultidoorAddress,
} from "../../../../store/cart/cartInterfaces";
import { selectCartLoading, toggleSelectedCart } from "../../../../store/cart/cartSlice";
import { deletePrecart } from "../../../../store/cart/cartSagas";
import Button from "../../../styled-UI/Button";
import Checkbox from "../../../styled-UI/Checkbox";
import CustomText from "../../../styled-UI/CustomText";
import Popup from "../../../styled-UI/Popup";
import CartDoor from "./CartDoor";

import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-icon.svg";
import useFilteredSelector from "../../../../hooks/useFilteredSelector";

interface Props {
  cartItems: OrderMultidoor[];
  cartInfo: CartInfo;
  orderMultidoorAddress: OrderMultidoorAddress[];
  isMultidoor?: boolean;
}

const CartContent = ({
  cartItems,
  cartInfo,
  orderMultidoorAddress,
  isMultidoor = false,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();

  const [isOpenPopUp, setIsOpenPopUp] = useState<boolean>(false);
  const handleClosePopUp = () => setIsOpenPopUp(false);

  const loading = useFilteredSelector<CartLoading>(
    selectCartLoading,
    (_) => _.id === "multiple-delete"
  );

  useEffect(() => {
    !loading && setIsOpenPopUp(false);
  }, [loading]);

  const deletePopup = (
    <Popup
      isOpen={isOpenPopUp}
      close={handleClosePopUp}
      title={translateLabel("CART_EMPTY_CART_POPUP_TITLE")}
      isCentered
    >
      <ModalContainer>
        <WrapMessage>
          <CustomText as="p" fontSizePx={18} font="font-bold" color="primary" lineHeightPx={24}>
            {translateLabel("CART_EMPTY_CART_POPUP_MESSAGE")}
          </CustomText>
        </WrapMessage>
        <ButtonContainer>
          <Button
            isLoading={loading !== null}
            type="primary"
            onClick={() => dispatch(deletePrecart())}
          >
            {translateLabel("CONFIRM")}
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </Popup>
  );

  return (
    <MainContent>
      {deletePopup}
      <Header>
        <Title>
          <div>
            <Checkbox
              label="Cart"
              name="cart"
              hideLabel
              controlled
              isSelectAll={cartInfo.selectedNumber != 0}
              checked={cartInfo.selectedNumber == cartInfo.totalNumber - cartInfo.outOfStockNumber}
              onChange={() =>
                dispatch(
                  toggleSelectedCart({
                    currentStatus:
                      cartInfo.selectedNumber == cartInfo.totalNumber - cartInfo.outOfStockNumber,
                  })
                )
              }
            />
          </div>
          <CustomText as="h1" fontSizePx={32} font="font-medium" marginLeftPx={24}>
            {translateLabel("CART_TITLE")}
          </CustomText>
          <CustomText as="span" fontSizePx={32} font="font-medium" marginLeftPx={8}>
            ({cartInfo.totalNumber})
          </CustomText>
        </Title>

        <Button onClick={() => setIsOpenPopUp(true)} type="tertiary" startIcon={<TrashIcon />}>
          {translateLabel("CART_EMPTY_CART_BUTTON_LABEL")}
        </Button>
      </Header>
      <div>
        {cartItems.map((orderMultidoor) => {
          return (
            <CartDoor
              key={orderMultidoor.multidoorId}
              title={orderMultidoor.orgentityName ?? ""}
              isMultidoor={isMultidoor}
              cartItems={orderMultidoor.categoryList}
              multidoorInfo={
                cartInfo?.multidoorSelected?.filter((_) => {
                  return _.multidoorId == orderMultidoor.multidoorId;
                })[0]
              }
              orderMultidoorAddress={
                orderMultidoorAddress.filter((_) => {
                  return _.multidoorId == orderMultidoor.multidoorId;
                })[0]
              }
            />
          );
        })}
      </div>
    </MainContent>
  );
};

const MainContent = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  padding-left: 1.375rem;
`;

const Title = styled.div`
  display: flex;
  align-items: flex-end;
`;

//PopUp
const ModalContainer = styled.div`
  padding: 3rem 0;
  width: 42.375rem;
`;

const WrapMessage = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export default CartContent;
