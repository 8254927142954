import React, { useEffect, useState } from "react";

const reCAPTCHA_V3_URL = globalEnvVariables.reCAPTCHAv3Url;
const SITE_KEY = globalEnvVariables.reCAPTCHAv3PublicKey;

const useReCaptchav3 = (): {
  reCaptchaLoaded: boolean;
  generateReCaptchaToken: (action: string, onCaptchaGenerated: (token: string) => void) => void;
} => {
  const [reCaptchaLoaded, setReCaptchaLoaded] = useState(false);

  // // Load ReCaptcha script
  useEffect(() => {
    if (typeof window === "undefined" || reCaptchaLoaded) return;
    if (window.grecaptcha) {
      showBadge();
      setReCaptchaLoaded(true);
      return;
    }

    const id = "recaptcha-key";
    const url = reCAPTCHA_V3_URL.replace("{SITE_KEY}", SITE_KEY);
    const onLoad = () => {
      setReCaptchaLoaded(true);
      showBadge();
    };

    loadScriptByURL(id, url, onLoad); // load the script by passing the URL
  }, [reCaptchaLoaded]);

  // Hide badge when unmount
  useEffect(() => hideBadge, []);

  // Get token
  const generateReCaptchaToken = (
    action: string,
    onCaptchaGenerated: (token: string) => void
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!reCaptchaLoaded) return reject(new Error("ReCaptcha not loaded"));
      if (typeof window === "undefined" || !window.grecaptcha) {
        setReCaptchaLoaded(false);
        return reject(new Error("ReCaptcha not loaded"));
      }
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(SITE_KEY, { action }).then((token: string) => {
          localStorage.setItem(SITE_KEY, token);
          onCaptchaGenerated(token);
          resolve(token);
        });
      });
    });
  };

  return { reCaptchaLoaded, generateReCaptchaToken };
};

const loadScriptByURL = (id: string, url: string, callback: () => void) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };

    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};

const showBadge = () => {
  if (!window.grecaptcha) return;
  window.grecaptcha.ready(() => {
    const badge = document.getElementsByClassName("grecaptcha-badge")[0] as HTMLElement;
    if (!badge) return;
    badge.style.display = "block";
    badge.style.zIndex = "1";
    badge.style.bottom = "115px";
  });
};

const hideBadge = () => {
  if (!window.grecaptcha) return;
  window.grecaptcha.ready(() => {
    const badge = document.getElementsByClassName("grecaptcha-badge")[0] as HTMLElement;
    if (!badge) return;
    badge.style.display = "none";
  });
};

export default useReCaptchav3;
