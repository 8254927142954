import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import { CartLoading, RxPrescriptionBrasilStatus } from "../../../../store/cart/cartInterfaces";
import { uploadPrescriptionBrasil } from "../../../../store/cart/cartSagas";
import { UploadedFile } from "../../../../store/warranty-wizard/warrantyWizardInterface";
import { selectIsBackOfficeUser } from "../../../../store/user/userSlice";
import { RequestStatus } from "../../../../interfaces/mainInterfaces";
import UploadFiles from "../../../styled-UI/UploadFiles";
import CustomText from "../../../styled-UI/CustomText";
import useFilteredSelector from "../../../../hooks/useFilteredSelector";
import { selectCartLoading, startCartLoading } from "../../../../store/cart/cartSlice";
import Loader from "../../../styled-UI/Loader";

const MAX_UPLOAD_SIZE = 2097152; // 2MB
const FILE_TYPES_SUPPORTED = ["image/x-png", "image/png", "image/jpeg", "application/pdf"];

type Props = {
  orderItemId: string;
  prescriptionStatus: RxPrescriptionBrasilStatus;
};

const RxPrescriptionBrasil = ({ orderItemId, prescriptionStatus }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();

  const isBackOfficeUser = useSelector(selectIsBackOfficeUser);

  const loading = useFilteredSelector<CartLoading>(selectCartLoading, (_) => _.id === orderItemId);

  const [uploadedPrescription, setUploadedPrescription] = useState<UploadedFile[]>([]);
  const [hideError, setHideError] = useState<boolean>(false);

  const getHelperText = (requestStatus: RequestStatus, errorMessage: string) => {
    if (requestStatus === "ERROR" && errorMessage === "NO_RESPONSE")
      return translateLabel("RX_PRESCR_BRASIL_UPLOADER_HELPER_TEXT_NO_RESPONSE");

    if (requestStatus === "ERROR" && errorMessage === "NO_PRESCRIPTION")
      return translateLabel("RX_PRESCR_BRASIL_UPLOADER_HELPER_TEXT_NO_PRESCRIPTION");

    if (requestStatus === "ERROR") return errorMessage;

    if (requestStatus === "SUCCESS")
      return translateLabel("RX_PRESCR_BRASIL_UPLOADER_HELPER_TEXT_ALREADY_UPLOADED");

    return "";
  };

  const getUploaderLabel = (requestStatus: RequestStatus) => {
    const fileName = uploadedPrescription.length > 0 && uploadedPrescription?.[0]?.file?.name;
    if (fileName) return fileName;
    else
      return requestStatus === "SUCCESS"
        ? "RX_PRESCR_BRASIL_UPLOADER_LABEL_ALREADY_UPLOADED"
        : "RX_PRESCR_BRASIL_UPLOADER_LABEL";
  };

  const getErrorFromUploader = (error: string) => setHideError(!!error);

  ////// upload prescription when base64 generation has ended
  useEffect(() => {
    if (
      uploadedPrescription.length > 0 &&
      uploadedPrescription?.[0]?.file?.type &&
      uploadedPrescription?.[0]?.base64
    ) {
      dispatch(startCartLoading({ id: orderItemId, type: "upload-rx" }));

      const extensionFromFileName = uploadedPrescription?.[0]?.file?.name?.split(".")?.pop(); // get extension
      const extensionFromType = uploadedPrescription?.[0]?.file?.type?.split("/")?.pop(); // fallback just in case
      const ext = "." + (extensionFromFileName ?? extensionFromType)?.toLowerCase();

      dispatch(
        uploadPrescriptionBrasil({
          orderCode: prescriptionStatus.rxBrasilId,
          document: {
            format: ext,
            content: uploadedPrescription?.[0]?.base64,
          },
        })
      );
    }
  }, [uploadedPrescription]);

  return (
    <Container>
      <UploaderWrapper>
        <UploadFiles
          imageLabel={translateLabel(getUploaderLabel(prescriptionStatus?.requestStatus))}
          uploadedFile={uploadedPrescription}
          setUploadedFile={setUploadedPrescription}
          errorCallback={getErrorFromUploader}
          isLoading={loading?.type === "upload-rx"}
          maxFileNumber={1}
          showRequiredError={false}
          fileMaxSize={MAX_UPLOAD_SIZE}
          fileTypesSupported={FILE_TYPES_SUPPORTED}
          disabled={
            (prescriptionStatus?.requestStatus === "ERROR" &&
              prescriptionStatus?.errorMessage === "NO_RESPONSE") ||
            isBackOfficeUser
          }
          hasWhiteBackground
          singleFileReplaceMode
          hideChips
          data-element-id="Products_RX_upload"
        />
        {prescriptionStatus?.requestStatus === "LOADING" ? (
          <Loader sizePx={20} />
        ) : (
          !hideError && (
            <CustomText
              as="span"
              fontSizePx={13}
              font="font-regular"
              color={prescriptionStatus?.requestStatus === "ERROR" ? "red" : "primary"}
            >
              {getHelperText(prescriptionStatus?.requestStatus, prescriptionStatus?.errorMessage)}
            </CustomText>
          )
        )}
      </UploaderWrapper>
      <FormatWrapper>
        <CustomText as="span" fontSizePx={13} font="font-regular">
          {translateLabel("RX_PRESCR_BRASIL_UPLOADER_FILE_FORMAT")}
        </CustomText>
      </FormatWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const UploaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2rem;
`;

const FormatWrapper = styled.div`
  padding: 1.375rem;
`;

export default RxPrescriptionBrasil;
