import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { add } from "date-fns";
import useTranslation from "../../../../hooks/useTranslation";
import useCanRender from "../../../../hooks/useCanRender";
import useFilteredSelector from "../../../../hooks/useFilteredSelector";
import useGenerateKeyFromObject from "../../../../hooks/useGenerateKeyFromObject";

import { PreCartProduct } from "../../../../store/catalogue/catalogueInterface";
import {
  CustomRx,
  CartLoading,
  OrderItem,
  OrderItemSelected,
  RxPrescriptionBrasilStatus,
  UpdatePrecartItemPayload,
} from "../../../../store/cart/cartInterfaces";
import {
  deletePrecartItem,
  deletePrecartItemsAFA,
  updatePrecartItem,
  updatePrecartItems,
} from "../../../../store/cart/cartSagas";
import {
  selectCartLoading,
  selectBackorderTrackedItems,
  selectRxBrasilStatus,
  startCartLoading,
  toggleSelectedItems,
} from "../../../../store/cart/cartSlice";
import {
  deleteOrderToApproveAFAItems,
  deleteOrderToApproveItems,
  updateOrderToApproveItem,
  updateOrderToApproveItems,
} from "../../../../store/orders-to-approve/ordersToApproveSaga";
import { getProductsDataFromOrderItems } from "../../../../store/analytics/analyticsUtility";
import { trackEvent } from "../../../../store/analytics/analyticsSaga";
import { Door } from "../../../../store/user/userInterfaces";

import {
  getCartTileTitle,
  getSplitFramesLabel,
  isOrderItemM4C,
  isOrderItemRx,
} from "../../../../utils/cartUtils";
import { filterRxPrescription } from "../../../../utils/rxUtils";
import { compareDay } from "../../../../utils/dateUtils";
import { instanceOfSparePartsSku } from "../../../../utils/aftersalesUtils";
import CustomText from "../../../styled-UI/CustomText";
import Checkbox from "../../../styled-UI/Checkbox";
import IconButton from "../../../styled-UI/IconButton";
import ButtonCart from "../../../styled-UI/ButtonCart";
import DatePicker from "../../../styled-UI/DatePicker";
import ButtonCartNote from "../../../styled-UI/ButtonCartNote";
import LinkButton from "../../../styled-UI/LinkButton";
import LinkButtonIcon from "../../../styled-UI/LinkButtonIcon";
import CustomProgressiveImage from "../../../styled-UI/CustomProgressiveImage";
import Loader from "../../../styled-UI/Loader";
import CartTileAlternativePopup from "../../cart-alternative-popup/CartAlternativePopup";
import SplitFramesComponent from "../../SplitFramesComponent";
import AddSize from "../../size-container/AddSize";
import PriceCartTile from "../../PriceCartTile";
import CartTileDetails from "./CartTileDetails";
import M4CEditPopup from "./M4CEditPopup";

import { useBreakpoint } from "../../../../assets/theme/breakpoint";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-icon.svg";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrowhead-down-icon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon.svg";
import { ReactComponent as StarIcon } from "../../../../assets/icons/star-icon.svg";
import clsx from "clsx";
import CustomerReferencePopup from "../CustomerReferencePopup";
import CartTileAFASizes from "./CartTileAFASizes";
import { checkIsAFAOrHelmet } from "../../../../utils/AFAutils";
import { selectIsBackOfficeUser } from "../../../../store/user/userSlice";

interface Props {
  orderItem: OrderItem;
  itemInfo?: OrderItemSelected;
  currentDoor: Door;
  isOrderApproval?: boolean;
  modelCodeAnalytics?: string;
  upcAnalytics?: string;
  outOfStock?: boolean;
}

const CartTile = ({
  orderItem,
  itemInfo,
  currentDoor,
  isOrderApproval,
  modelCodeAnalytics,
  upcAnalytics,
  outOfStock = false,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const canRender = useCanRender();
  const breakpoints = useBreakpoint();
  const keyGen = useGenerateKeyFromObject();
  const isBackofficeUser = useSelector(selectIsBackOfficeUser);

  const isAFAOrHelmet = checkIsAFAOrHelmet(orderItem.sku.productCategory);

  const loading = useFilteredSelector<CartLoading>(
    selectCartLoading,
    (_) => _.id === orderItem.orderItemId
  );

  const backorderTrackedItems = useSelector(selectBackorderTrackedItems);

  const [openDetails, setOpenDetails] = useState<boolean>(false);

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// SPLIT FRAMES /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const hasSplitFramesPrivilege = canRender(
    !instanceOfSparePartsSku(orderItem?.sku) ? "SPLIT_FRAMES" : "SPLIT_SPARE_PARTS", // check one or the other based on whether it's a spare part
    currentDoor
  );

  const isSplitFrames = orderItem?.split && orderItem?.split.length > 1;

  //////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////// ITEM UPDATES //////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const handleGenericUpdate = (payload: UpdatePrecartItemPayload, isMultiple = false) => {
    if (isMultiple) {
      const mainOrderItemId = orderItem?.orderItemId;
      const orderItems = orderItem?.orders?.map((_) => {
        return {
          ...payload,
          orderItemId: _.orderItemId,
        };
      });

      if (isOrderApproval) {
        dispatch(
          updateOrderToApproveItems({
            mainOrderItemId: mainOrderItemId,
            orderItems: orderItems ?? [],
          })
        );
      } else
        dispatch(
          updatePrecartItems({
            mainOrderItemId: mainOrderItemId,
            orderItems: orderItems ?? [],
            doSimulate: payload.doSimulate,
          })
        );
    } else {
      isOrderApproval
        ? dispatch(updateOrderToApproveItem(payload)) // TODO: doesn't handle eventual callback
        : dispatch(updatePrecartItem(payload));
    }
  };

  //////////////// REQUESTED SHIP DATE
  const [startDate, setStartDate] = useState<Date>(orderItem?.requestedShipDate);
  const isM4C = isOrderItemM4C(orderItem);

  const showDatePicker = isAFAOrHelmet
    ? canRender("FIRST_AVAILABILITY", currentDoor)
    : canRender("FIRST_AVAILABILITY", currentDoor) && !hasSplitFramesPrivilege && !isM4C;

  // sync requestedShipDate with the service, in case something went wrong
  useEffect(() => {
    if (orderItem?.requestedShipDate !== startDate) setStartDate(orderItem?.requestedShipDate);
  }, [orderItem?.requestedShipDate]);

  const handleDateUpdate = (date: any) => {
    dispatch(startCartLoading({ id: orderItem?.orderItemId, type: "date" }));
    handleGenericUpdate(
      {
        orderItemId: orderItem?.orderItemId,
        requestedShipDate: date,
        doSimulate: isAFAOrHelmet,
      },
      isAFAOrHelmet
    );

    setStartDate(date);
  };

  //////////////// CUSTOMER REFERENCE
  const [customerReference, setCustomerReference] = useState<string | undefined>(
    orderItem?.customerReference?.replace(/\n/g, " ").trim()
  );
  const [isReferencePopupOpen, setIsReferencePopupOpen] = useState<boolean>(false);

  // sync customerReference with the service, in case something went wrong
  useEffect(() => {
    const tmpCustomerReference = customerReference?.replace(/\n/g, " ").trim();
    setCustomerReference(tmpCustomerReference ?? "");
  }, [keyGen.getKey(orderItem)]);

  const handleCustomerReferenceConfirm = () => {
    const tmpCustomerReference = customerReference?.replace(/\n/g, " ").trim();
    dispatch(startCartLoading({ id: orderItem?.orderItemId, type: "reference" }));
    handleGenericUpdate(
      {
        orderItemId: orderItem?.orderItemId,
        customerReference: tmpCustomerReference,
      },
      isAFAOrHelmet
    );
    handleCloseReferencePopup();
  };

  const handleCloseReferencePopup = () => setIsReferencePopupOpen(false);

  //////////////// QUANTITY
  // manage timeout for quantity updates, in order to minimize number of dispatches
  const [timeoutQuantityUpdate, setTimeoutQuantityUpdate] = useState<NodeJS.Timeout>();

  // update order item quantity
  const handleQuantityUpdate = (obj: PreCartProduct, orderItemId?: string) => {
    if (timeoutQuantityUpdate) clearTimeout(timeoutQuantityUpdate);

    const newTimeout = setTimeout(() => {
      const mainOrderItemId = orderItem?.orderItemId;
      dispatch(startCartLoading({ id: mainOrderItemId, type: "quantity" }));

      const payload: UpdatePrecartItemPayload = {
        orderItemId: orderItemId ?? mainOrderItemId,
        quantity: obj?.quantity,
        doSimulate: hasSplitFramesPrivilege,
      };

      //mainOrderItemId is used for AFA, for loading logic
      if (orderItemId) payload.mainOrderItemId = mainOrderItemId;

      handleGenericUpdate(payload);
    }, 500);
    setTimeoutQuantityUpdate(newTimeout);
  };

  //////////////// DELETION

  const handleRemoveFromCart = (paramOrderItem?: OrderItem) => {
    const currentOrderItem = paramOrderItem ? paramOrderItem : orderItem;
    const mainOrderItemId = orderItem?.orderItemId;
    dispatch(startCartLoading({ id: mainOrderItemId, type: "delete" })); //GET ID FROM MAIN ORDER ITEM
    dispatch(
      trackEvent({
        id: "Prods-Delete",
        Products: getProductsDataFromOrderItems([currentOrderItem], canRender("STARS")),
      })
    );

    if (isAFAOrHelmet) {
      //FOR AFA ONLY: IF paramOrderItem IS NOT PRESENT, DELETE ALL OF THE ORDER ITEM IN THE "MAIN" ORDER ITEM
      const orderItemIds = currentOrderItem.orders?.map((_) => _.orderItemId) ?? [
        currentOrderItem?.orderItemId,
      ];

      if (isOrderApproval) {
        dispatch(deleteOrderToApproveAFAItems({ mainOrderItemId: mainOrderItemId, orderItemIds }));
      } else {
        dispatch(deletePrecartItemsAFA({ mainOrderItemId: mainOrderItemId, orderItemIds }));
      }
    } else {
      //NON-AFA PRODUCTS OR JUST DELETE SINGLE ITEM

      if (isOrderApproval) dispatch(deleteOrderToApproveItems(currentOrderItem?.orderItemId));
      else {
        dispatch(deletePrecartItem(currentOrderItem?.orderItemId));
      }
    }
  };

  //////////////// PATTERN CHECKBOX

  const handlePatternChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleGenericUpdate({
      orderItemId: orderItem?.orderItemId as string,
      receivePattern: e.target.checked ? "Y" : "N",
    });
  };

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// ALTERNATIVE PRODUCTS /////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const [showAlternativePopup, setShowAlternativePopup] = useState<boolean>(false);

  const handleClosePopupAlternative = () => setShowAlternativePopup(false);

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// RX PRESCRIPTION ////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const [rxMappedData, setRxMappedData] = useState<CustomRx>();

  const isRxItem = isOrderItemRx(orderItem);

  useEffect(() => {
    if (orderItem) setRxMappedData(filterRxPrescription(orderItem)); // get data for RX prescription
  }, [orderItem]);

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////// M4C //////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const [isOpenM4CPopUp, setIsOpenM4CPopUp] = useState<boolean>(false);

  const handleM4CUpdate = (xitemM4CConfigurationId: string) => {
    dispatch(startCartLoading({ id: orderItem?.orderItemId, type: "m4c" }));

    handleGenericUpdate({
      orderItemId: orderItem?.orderItemId,
      xitem_configurationId: xitemM4CConfigurationId,
      callback: (success: boolean) => {
        success && setIsOpenM4CPopUp(false);
      }, // close popup if add to cart is successful
    });
  };

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////// RX PRESCRIPTION BRASIL /////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const rxBrasilStatus = useFilteredSelector<RxPrescriptionBrasilStatus>(
    selectRxBrasilStatus,
    (status: RxPrescriptionBrasilStatus) => status.orderItemId === orderItem.orderItemId
  );

  // automatically open details if prescription needs to be uploaded
  useEffect(() => {
    if (rxBrasilStatus?.requestStatus === "ERROR" && !openDetails) setOpenDetails(true);
  }, [rxBrasilStatus]);

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////// RENDER //////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const isSparePartsAndBackOrder =
    instanceOfSparePartsSku(orderItem?.sku) && orderItem?.split?.[0]?.semaphore === "BACKORDER";

  const isStars =
    canRender("STARS", currentDoor) &&
    orderItem?.sku?.attributes?.find((_) => _.identifier === "STARS_NEW");

  const availabilityStatus = (): string | undefined => {
    if (
      orderItem?.split?.[0]?.semaphore === "BACKORDER" &&
      !instanceOfSparePartsSku(orderItem?.sku)
    ) {
      return backorderTrackedItems[orderItem.sku.uniqueID]
        ? "BACKORDER_FOLLOWING"
        : "BACKORDER_NOT_FOLLOWING";
    }
    return orderItem?.split?.[0]?.semaphore;
  };

  const renderCustomerReferenceButton = () => {
    return customerReference ? (
      <ButtonCartNote
        type="light"
        text={customerReference}
        disabled={
          outOfStock ||
          !orderItem?.sku?.uniqueID ||
          loading !== null ||
          (isBackofficeUser && isOrderApproval)
        } // button is disabled when outOfStock
        onClick={() => setIsReferencePopupOpen(true)}
        isLoading={loading?.type === "reference"}
        data-element-id="Products_AddCustomerReference"
        data-description={orderItem?.productCode}
      />
    ) : (
      <ButtonCart
        type="light"
        text={translateLabel("CART_ITEM_CUSTOMER_REFERENCE_BUTTON")}
        disabled={
          outOfStock ||
          !orderItem?.sku?.uniqueID ||
          loading !== null ||
          (isBackofficeUser && isOrderApproval)
        } // button is disabled when outOfStock
        onClick={() => setIsReferencePopupOpen(true)}
        isLoading={loading?.type === "reference"}
        data-element-id="Products_AddCustomerReference"
        data-description={orderItem?.productCode}
      />
    );
  };

  return (
    <StyledTile
      key={orderItem?.orderItemId}
      outOfStock={outOfStock}
      orderApproval={isOrderApproval}
      openDetails={openDetails}
    >
      <TileHeader outOfStock={outOfStock} className={clsx(isStars && "cart-tile-stars")}>
        <HeaderFirstRow>
          {/* TITLE  */}
          <OuterBoxTile>
            {isStars && (
              <IconContainer>
                <StarIcon />
              </IconContainer>
            )}
            <TileTitle>
              <CustomText
                as="span"
                fontSizePx={18}
                font="font-bold"
                lineHeightPx={24}
                className="cart-tile-stars--text-white"
              >
                {getCartTileTitle(orderItem, translateLabel)}
              </CustomText>
              <CustomText
                as="span"
                fontSizePx={14}
                font="font-regular"
                lineHeightPx={24}
                className="cart-tile-stars--text-white"
              >
                {orderItem?.productName}
              </CustomText>
            </TileTitle>
            {orderItem?.xitemM4CbagId && !isOrderApproval && (
              <OuterBoxSmartShopper>
                <BoxSmartShopper>
                  <CustomText
                    as="span"
                    fontSizePx={13}
                    font="font-bold"
                    lineHeightPx={24}
                    className="cart-tile-stars--text-white"
                  >
                    {translateLabel("CART_ITEM_SMART_SHOPPER_TAG")}
                  </CustomText>
                </BoxSmartShopper>
                <CustomText
                  as="span"
                  fontSizePx={13}
                  font="font-bold"
                  lineHeightPx={24}
                  marginLeftPx={14}
                  className="cart-tile-stars--text-white"
                >
                  {translateLabel("CART_ITEM_SMART_SHOPPER_BAG_ID")} {orderItem?.xitemM4CbagId}
                </CustomText>
                <CustomText
                  as="span"
                  fontSizePx={13}
                  font="font-bold"
                  lineHeightPx={24}
                  marginLeftPx={14}
                  className="cart-tile-stars--text-white"
                >
                  {translateLabel("M4C_AVAILABILITY")}
                </CustomText>
              </OuterBoxSmartShopper>
            )}
          </OuterBoxTile>

          {/* DATE PICKER  */}
          {!outOfStock && showDatePicker && (
            <DateWrapper>
              {loading?.type === "date" && <Loader sizePx={20} />}
              <CustomText
                as="span"
                fontSizePx={13}
                font="font-medium"
                className="cart-tile-stars--text-white"
              >
                {translateLabel("CART_ITEM_REQUESTED_DELIVERY_DATE")}
              </CustomText>
              <DatePickerContainer>
                <DatePicker
                  selected={startDate}
                  onChange={handleDateUpdate}
                  size="sm"
                  minDate={orderItem?.availableDate}
                  maxDate={add(orderItem?.availableDate, { months: 18 })}
                  disabled={outOfStock || !orderItem?.sku?.uniqueID || loading !== null}
                  isError={compareDay(orderItem?.availableDate, startDate, "desc") < 0} // error if availableDate > startdate (compareDay == -1)
                />
              </DatePickerContainer>
            </DateWrapper>
          )}
        </HeaderFirstRow>

        {/* PATTERN */}
        {/* Check privilege and if is frame */}
        {canRender("DIME_ORDER_CREATION") &&
          orderItem?.sku?.productCategory?.toLowerCase() === "frame" && (
            <PatternCheck>
              <Checkbox
                label={translateLabel("CART_ITEM_PATTERN_CHECKBOX")}
                name="patternCheck"
                onChange={handlePatternChange}
                defaultChecked={orderItem.receivePattern === "Y"}
              />
            </PatternCheck>
          )}
      </TileHeader>
      <TileContent>
        <TileContentFlex>
          {/* DELETE ICON  */}
          <DeleteIconContainer>
            {loading?.type === "delete" ? (
              <Loader sizePx={30} />
            ) : (
              <IconButton
                type="no-background-primary"
                size="md"
                icon={<TrashIcon />}
                disabled={loading !== null || (isBackofficeUser && isOrderApproval)}
                onClick={() => handleRemoveFromCart()}
              />
            )}
          </DeleteIconContainer>

          <ImageColumn>
            {/* CHECKBOX, Don't show checkbox if outOfStock  */}
            {!outOfStock && !isOrderApproval && (
              <CheckboxContainer>
                <Checkbox
                  label={orderItem?.productCode}
                  name={orderItem?.productCode}
                  hideLabel
                  controlled
                  checked={itemInfo?.selected}
                  onChange={() => dispatch(toggleSelectedItems(orderItem?.orderItemId))}
                />
              </CheckboxContainer>
            )}
            {/* IMAGE */}
            <ImageContainer>
              <CustomProgressiveImage
                type="landscape"
                src={orderItem?.sku?.img + "?impolicy=MYL_EYE&wid=174" ?? ""}
              />
            </ImageContainer>
          </ImageColumn>

          <div className="right-container">
            <TileContentMain>
              {isAFAOrHelmet && !outOfStock ? (
                <CartTileAFASizes
                  orderItem={orderItem}
                  currentDoor={currentDoor}
                  handleDelete={handleRemoveFromCart}
                  disabled={loading !== null}
                  loading={loading}
                  onClick={handleQuantityUpdate}
                  hideShowOtherSizes={isOrderApproval}
                  availabilityStatus={availabilityStatus()}
                />
              ) : (
                <SizeContainer>
                  {/* ADD SIZE BUTTONS */}
                  {orderItem?.sku?.uniqueID && (
                    <AddSize
                      key={keyGen.getKey(orderItem)}
                      sku={orderItem?.sku}
                      orderItemId={orderItem?.orderItemId}
                      quantity={orderItem?.quantity}
                      outOfStock={outOfStock}
                      onClick={handleQuantityUpdate}
                      availabilityStatus={availabilityStatus()}
                      price={orderItem?.price}
                      door={currentDoor}
                      isCart
                      disabled={
                        (loading !== null && loading.type !== "quantity") ||
                        (isBackofficeUser && isOrderApproval)
                      }
                      isRxItem={isRxItem}
                      isM4C={isM4C}
                      isSplitFrames={hasSplitFramesPrivilege && isSplitFrames}
                      isOrderApproval={isOrderApproval}
                      prescriptionUploadStatus={rxBrasilStatus?.requestStatus}
                      isPrescriptionUploadError={rxBrasilStatus?.requestStatus === "ERROR"}
                      dataElementIdRXCart="Products_RX"
                      data-description={`${orderItem?.productCode}_${orderItem?.skuCode}`}
                      data-element-id="Products_EditPrescription"
                      {...(hasSplitFramesPrivilege &&
                        !isSplitFrames &&
                        orderItem?.split && {
                          label: translateLabel(
                            getSplitFramesLabel(
                              orderItem?.split?.[0]?.splitCode,
                              orderItem?.split?.[0]?.semaphore,
                              isRxItem,
                              isM4C
                            )
                          ),
                        })}
                    />
                  )}
                  {/* SPIT FRAME */}
                  {hasSplitFramesPrivilege && isSplitFrames && (
                    <SplitFrameContainer>
                      {orderItem?.split?.map((_, index) => (
                        <SplitFramesComponent
                          key={index}
                          size={orderItem?.sku?.size?.values?.[0]}
                          stockNum={_?.quantity}
                          status={getSplitFramesLabel(_?.splitCode, _?.semaphore, isRxItem, isM4C)}
                          inventoryStatus={_?.semaphore && _?.semaphore}
                          id={orderItem.sku.uniqueID}
                          isLoading={loading?.type === "quantity"}
                        />
                      ))}
                    </SplitFrameContainer>
                  )}
                </SizeContainer>
              )}

              <ActionContainer>
                {!breakpoints["md"] && !isOrderApproval && renderCustomerReferenceButton()}

                {isM4C && !isOrderApproval && (
                  <Spacer>
                    <LinkButtonIcon
                      text={translateLabel("EDIT_M4C")}
                      onClick={() => setIsOpenM4CPopUp(true)}
                      icon={<EditIcon />}
                      disabled={loading !== null}
                    />
                  </Spacer>
                )}
              </ActionContainer>

              {/* PRICE */}
              <PriceCartTile
                price={orderItem?.price}
                isSpareParts={instanceOfSparePartsSku(orderItem?.sku)}
                isLoading={loading?.type === "quantity"}
                isRxItem={isRxItem}
              />

              {/* BUTTON OPEN DETAILS */}
              <OpenDetailsContainer>
                <IconButton
                  onClick={() => setOpenDetails(!openDetails)}
                  size="md"
                  disabled={!orderItem?.sku?.uniqueID}
                  icon={<Arrow />}
                  upsideDown={openDetails}
                  type="white-with-border"
                  data-element-id="Products_ExpandCollapse"
                  data-description={orderItem?.productCode}
                />
              </OpenDetailsContainer>
            </TileContentMain>
          </div>
        </TileContentFlex>
        {(breakpoints["md"] || isOrderApproval) && (
          <ResponsiveRefButton>{renderCustomerReferenceButton()}</ResponsiveRefButton>
        )}
      </TileContent>
      {/*OUT OF STOCK FOOTER */}
      {(orderItem?.split?.[0]?.semaphore === "NOT_AVAILABLE" ||
        orderItem?.split?.[0]?.semaphore === "BACKORDER") &&
        !isSparePartsAndBackOrder &&
        !isRxItem &&
        !isOrderApproval &&
        !isAFAOrHelmet && (
          <OutOfStockMessage>
            <CustomText as="span" font="font-regular" fontSizePx={14} marginRightPx={14}>
              {translateLabel("CART_ITEM_OUTOFSTOCK_MESSAGE")}
            </CustomText>

            <LinkButton
              uppercase
              underline
              onClick={() => setShowAlternativePopup(true)}
              data-element-id="Products_FindAlternative"
              data-description={`${modelCodeAnalytics}_${upcAnalytics}`}
            >
              {translateLabel("CART_ITEM_OUTOFSTOCK_LINK_LABEL")}
            </LinkButton>
          </OutOfStockMessage>
        )}
      {/* DETAILS */}
      <CartTileDetails
        currentOrderItem={orderItem}
        showDetails={openDetails}
        outOfStock={outOfStock ?? false}
        productId={orderItem?.sku?.uniqueID}
        category={orderItem?.sku?.productCategory}
        brand={orderItem?.xitemRXbrand ?? ""}
        jobType={orderItem?.xitemField1 ?? ""}
        xitemRXfocalTypeName={orderItem?.xitemRXfocalTypeName ?? ""}
        rxMappedData={rxMappedData}
        rxBrasilStatus={rxBrasilStatus}
        {...(instanceOfSparePartsSku(orderItem?.sku)
          ? { sparePartsDescription: orderItem?.sku?.sparePartsDescription }
          : {})}
      />

      {/* POPUPS */}

      {/* POPUP CUSTOMER REFERENCE */}
      {isReferencePopupOpen && (
        <CustomerReferencePopup
          isReferencePopupOpen={isReferencePopupOpen}
          handleClose={handleCloseReferencePopup}
          customerReference={customerReference}
          handleConfirm={handleCustomerReferenceConfirm}
          handleChange={(ref) => setCustomerReference(ref)}
        />
      )}
      {/* POPUP FIND AN ALTERNATIVE */}
      {showAlternativePopup && (
        <CartTileAlternativePopup
          isOpen={showAlternativePopup}
          close={handleClosePopupAlternative}
          title={translateLabel("FIND_AN_ALTERNATIVE_VARIANT_IN_YOUR_CART_POPUP_TITLE")}
          orderItem={orderItem}
          currentDoor={currentDoor}
        />
      )}
      {/* POPUP M4C */}
      {isOpenM4CPopUp && (
        <M4CEditPopup
          isOpenM4CPopUp={isOpenM4CPopUp}
          setIsOpenM4CPopUp={setIsOpenM4CPopUp}
          m4cConfiguration={{
            xitemM4CbagId: orderItem?.xitemM4CbagId,
            xitemM4CCustomVendorId: orderItem?.xitemM4CCustomVendorId,
            xitemM4CCustomModelCode: orderItem?.xitemM4CCustomModelCode,
            xitemM4CCustomRegion: orderItem?.xitemM4CCustomRegion,
            xitemM4CCustomHierarchy: orderItem?.xitemM4CCustomHierarchy,
            xitemM4CCustomProductId: orderItem?.xitemM4CCustomProductId,
            xitemM4CConfigurationId: orderItem?.xitemM4CConfigurationId,
          }}
          handleM4CUpdate={handleM4CUpdate}
          brand={orderItem?.sku?.brand ?? ""}
          isLoading={loading?.type === "m4c"}
        />
      )}
    </StyledTile>
  );
};

const StyledTile = styled.div<{
  outOfStock: boolean | undefined;
  orderApproval: boolean | undefined;
  openDetails: boolean;
}>`
  background-color: ${(prop) => prop.theme.palette.white};
  width: 100%;
  border-radius: 0.25rem;
  flex-shrink: 0;

  ${(props) =>
    props.outOfStock &&
    `
    border: 1px solid ${props.theme.palette.gray.medium};
    background-color: ${props.theme.palette.gray.light};
  `}

  ${(props) =>
    props.orderApproval &&
    `
    border: 1px solid ${props.theme.palette.gray.medium};
  `}

  ${(props) =>
    props.openDetails &&
    `
    border: 1px solid ${props.theme.palette.primary};
  `}
`;

const TileHeader = styled.div<{ outOfStock: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.light};
  padding: 1.125rem 1.5rem 1.125rem 1.875rem;
  box-sizing: border-box;

  ${(props) =>
    props.outOfStock &&
    `
    border-bottom: 1px solid ${props.theme.palette.gray.medium};
  `};

  &.cart-tile-stars {
    background-color: ${(props) => props.theme.palette.primary};
    border-radius: 0.25rem 0.25rem 0 0;

    .cart-tile-stars--text-white {
      color: ${(props) => props.theme.palette.white}!important;
    }
  }

  @media ${(props) => props.theme.queries.md} {
    padding: 1rem;
  }
`;

const TileContent = styled.div`
  position: relative;
  margin: 0 1.875rem;
  padding: 0.75rem 0 1.5rem;

  @media ${(props) => props.theme.queries.md} {
    padding: 1rem;
    margin: 0;
  }
`;

const TileContentFlex = styled.div`
  display: flex;

  .right-container {
    width: 100%;
    .second-row {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const TileContentMain = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  box-sizing: border-box;
  flex-grow: 1;
`;

const TileTitle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
`;

const OpenDetailsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 1rem;
`;

const DeleteIconContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 0;
`;

const ImageColumn = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 2rem;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-right: 1.5rem;

  img {
    width: 8.563rem;
  }

  @media ${(props) => props.theme.queries.md} {
    padding-right: 0.5rem;
  }
`;

const CheckboxContainer = styled.div`
  height: 100%;
  margin-right: 1.25rem;

  @media ${(props) => props.theme.queries.md} {
    margin-right: 0.5rem;
  }
`;

const SizeContainer = styled.div``;

const OutOfStockMessage = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem 1.5rem 1rem;
  padding: 0.88rem 0.69rem;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
`;

const SplitFrameContainer = styled.div`
  margin-top: 2.25rem;
`;

const PatternCheck = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
`;

const HeaderFirstRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const DatePickerContainer = styled.div`
  width: 9rem;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Spacer = styled.div`
  margin-top: 1rem;
`;

const OuterBoxSmartShopper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 3rem;
`;

const BoxSmartShopper = styled.div`
  background-color: ${(props) => props.theme.palette.gray.medium};
  padding: 0.125rem 1rem;
  border-radius: 1.875rem;
`;

const OuterBoxTile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ResponsiveRefButton = styled.div`
  padding-top: 1rem;
`;
const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  svg {
    fill: ${(props) => props.theme.palette.white};
  }
`;

export default CartTile;
