import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";
import { LayoutVariant, LpCarouselBanner as Interface } from "../../interfaces/cmsInterfaces";
import { getMultiMedia } from "../../utils/cmsUtils";
import Carousel from "../styled-UI/Carousel";

interface Props {
  data: Interface[];
  layout: LayoutVariant;
}

const LpCarouselBanner = ({ data, layout }: Props): JSX.Element => {
  const name = layout?.name;
  const renderTileCarousel = () => {
    return data?.map((singleSlide) => {
      const { multiMediaType } = singleSlide;
      const { srcImg, srcVideo, coverImg, placement } = getMultiMedia(multiMediaType);

      return (
        <BoxImg
          key={singleSlide.id}
          image={srcImg ?? ""}
          className={clsx(
            name === "Lp-carousel-banner" && "layout-full-height",
            name === "Lp-carousel-banner-half" && "layout-half-height"
          )}
        />
      );
    });
  };

  return data && data.length > 0 ? (
    <CarouselContainer
      className={clsx(
        name === "Lp-carousel-banner" && "layout-full-height",
        name === "Lp-carousel-banner-half" && "layout-half-height"
      )}
    >
      <Carousel slidesToScroll={1} slidesToShow={1} navigationType="lp-banner" noPaddingBottom>
        {renderTileCarousel()}
      </Carousel>
    </CarouselContainer>
  ) : (
    <></>
  );
};

const CarouselContainer = styled.div`
  &.layout-full-height {
    height: 50rem;
  }

  &.layout-half-height {
    height: 30.625rem;
  }
`;

const BoxImg = styled.div<{ image: string }>`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.image && props.image});

  &.layout-full-height {
    height: 50rem;
  }

  &.layout-half-height {
    height: 30.625rem;
  }
`;

const ImageContainer = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  img {
    height: 100%;
  }
`;

export default LpCarouselBanner;
