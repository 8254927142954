import { BFFApiDomain } from "../serviceUtils";

export const getMassiveOrdersUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/orders/massive";

export const deleteMassiveOrderUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/orders/massive/{uploadId}";

export const downloadMassiveOrderTemplateUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/orders/massive/template";

export const getUpoloadTokenUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/orders/massive/upload/token";

export const uploadMassiveOrderUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{storeId}/{locale}/orders/massive/upload";

export const uploadedProductUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/orders/massive/{uploadId}";

export const postMassiveOrderCompleteUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/orders/massive/{uploadId}/complete";

export const getAdrressbyAddressId =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/orders/address/{addressId}";
