import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";

import CustomText from "../../styled-UI/CustomText";
import { selectBrandGroupByBrandMap } from "../../../store/store/storeSlice";
import { Product, Variant } from "../../../interfaces/productInterface";
import { instanceOfVariant } from "../../../utils/productUtils";
import { capitalizeFirstLetter } from "../../../utils/utils";
import useTranslation from "../../../hooks/useTranslation";
import IconTile from "../IconTile";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  data: Product | Variant;
  isVariant?: boolean;
  url: string;
  noLinkImg?: boolean;
  showBrandInVariant?: boolean;
  showCodeInVariant?: boolean;
  showModelInVariant?: boolean;
  isHorizontal?: boolean;
  customFont?: FontFamilies | null;
}

const TileHeader = ({
  data,
  isVariant = false,
  url,
  noLinkImg = false,
  showBrandInVariant = false,
  showCodeInVariant = false,
  showModelInVariant = false,
  isHorizontal = false,
  customFont = null,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const history = useHistory();

  const brandGroupByBrandMap = useSelector(selectBrandGroupByBrandMap);

  const renderHeaderTitle = (): string => {
    const brand = data?.brand && translateLabel(`${data?.brand}_LABEL`);

    let title = "";

    if (data && instanceOfVariant(data) && isVariant) {
      const variant = data as Variant;
      const brandGroup: string = brandGroupByBrandMap[variant.brand as string];

      const model = variant?.variantCode?.split(brandGroup)?.[1]?.split("_")?.[0];
      if (showBrandInVariant && brand) title += brand + " ";
      if (showModelInVariant && model) title += model + " ";
      if (showCodeInVariant && data?.variantCodeNoUnderscore)
        title += data?.variantCodeNoUnderscore;
      else title += data?.colorCode?.values ?? "";
    } else {
      if (brand) title += brand + " ";
      title += (data as Product)?.productCode?.toUpperCase() ?? "";
    }

    return title;
  };

  return (
    <Header className={clsx(isVariant && "tile-variant-header")}>
      <FirstRow
        className={clsx(
          (showModelInVariant || showModelInVariant || showCodeInVariant || !isHorizontal) &&
            "tile-flex" // if horizontal tile, it should be flex only if additional info has to be shown
        )}
      >
        {noLinkImg ? (
          <CustomText
            as="span"
            fontSizePx={18}
            font={customFont ? "custom-bold" : "font-bold"}
            fontFamily={customFont}
          >
            {renderHeaderTitle()}
          </CustomText>
        ) : (
          <CustomText
            as="button"
            fontSizePx={18}
            font={customFont ? "custom-bold" : "font-bold"}
            fontFamily={customFont}
            onClick={() => history.push(url)}
          >
            {renderHeaderTitle()}
          </CustomText>
        )}
        {!isHorizontal && (
          <IconContainer>
            <IconTile data={data} />
          </IconContainer>
        )}
      </FirstRow>
      <SecondRow>
        <CustomText
          as="span"
          fontSizePx={13}
          font={customFont ? "custom-regular" : "font-regular"}
          fontFamily={customFont}
          marginTopPx={5}
        >
          {capitalizeFirstLetter(data?.name)}
        </CustomText>
      </SecondRow>
    </Header>
  );
};

const Header = styled.div`
  min-height: 3.875rem;

  &.tile-variant-header {
    min-height: 3rem;
  }
`;

const FirstRow = styled.div`
  &.tile-flex {
    display: flex;
    justify-content: space-between;
  }
`;

const SecondRow = styled.div`
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
`;

const IconContainer = styled.div`
  min-width: 4.875rem;
  display: flex;
  justify-content: flex-end;

  svg {
    height: 1.375rem;
    fill: ${(props) => props.theme.palette.secondary};
  }
  svg:not(:last-child) {
    margin-right: 0.25rem;
  }
`;

export default TileHeader;
