import { useSelector } from "react-redux";
import { Door } from "../store/user/userInterfaces";
import {
  selectAtLeastOneTruePrivileges,
  selectUserHavingPrivilege,
  selectIsSubuser,
  selectActiveDoor,
  selectSelectedDoor,
} from "../store/user/userSlice";
import {
  privilegesMapOR,
  privilegesMapAND,
  privilegesRX,
  checkUserHavingPrivilegeOR,
  checkUserHavingPrivilegeAND,
  checkRXPrivilege,
  subuserPrivilegesMapOR,
} from "../utils/privilegesUtils";
import { checkIfArrayBInArrayA } from "../utils/utils";

export default function useCanRender(): (
  privilege?: string | null,
  door?: Door | null,
  onlySubuser?: boolean
) => boolean {
  const atLeastOneTruePrivileges = useSelector(selectAtLeastOneTruePrivileges);
  const userHavingPrivilege = useSelector(selectUserHavingPrivilege);
  const isSubuser = useSelector(selectIsSubuser);
  const activeDoor = useSelector(selectActiveDoor);

  const localPrivilegesRX = privilegesRX;
  const localPrivilegesOR = isSubuser
    ? { ...privilegesMapOR, ...subuserPrivilegesMapOR }
    : privilegesMapOR;
  const localPrivilegesAND = privilegesMapAND;

  const canRender = (
    privilege?: string | null,
    door?: Door | null,
    onlySubuser?: boolean
  ): boolean => {
    let canRenderVar = false;

    if (!privilege) return true;

    //if is a privilege to be checked only for subusers, if not subuser return always true
    if (onlySubuser && !isSubuser) return true;

    //check only privileges of main account (which is the active door)
    if (privilege === "M4C" || privilegesMapOR["M4C"].includes(privilege)) {
      door = activeDoor;
    }

    // if we receive door, we check if THE SPECIFIED DOOR has that privilege
    if (door) {
      // don't remove double if! it's to ensure we don't use atLeastOneTruePrivileges if we don't receive userHavingPrivilege
      if (userHavingPrivilege) {
        if (privilege === "RX" || privilege === "RX_ICON") {
          const privilegeGroupRX = localPrivilegesRX[privilege];

          if (privilegeGroupRX)
            canRenderVar = checkRXPrivilege(userHavingPrivilege, privilegeGroupRX, door);
        } else if (privilege === "M4C") {
          //always has door (see previous lines)
          //check if MYL4C_CONFIGURATION is present
          let tempCanRender = false;

          tempCanRender = checkUserHavingPrivilegeAND(
            userHavingPrivilege,
            ["MYL4C_CONFIGURATION"],
            door
          );

          //if present, check other privileges
          if (tempCanRender) {
            const privilegeGroupOR = localPrivilegesOR[privilege];
            canRenderVar = checkUserHavingPrivilegeOR(
              userHavingPrivilege,
              privilegeGroupOR ?? [privilege],
              door
            );
          }
        } else {
          //ATTENTION!!!!!
          //THE ORDER IS IMPORTANT: WE CHECK FIRST THE "OR" AND THEN THE "AND" PRIVILEGES
          //CHECK FOR PRIVILEGES IN OR CONDITION, or single privileges

          const privilegeGroupOR = localPrivilegesOR[privilege];
          canRenderVar = checkUserHavingPrivilegeOR(
            userHavingPrivilege,
            privilegeGroupOR ?? [privilege],
            door
          );

          //CHECK FOR PRIVILEGES IN AND CONDITION
          const privilegeGroupAND = localPrivilegesAND[privilege];
          if (!canRenderVar && privilegeGroupAND) {
            canRenderVar = checkUserHavingPrivilegeAND(
              userHavingPrivilege,
              privilegeGroupAND,
              door
            );
          }
        }
      }
    }
    // otherwise, we check if AT LEAST ONE DOOR has that privilege
    else if (atLeastOneTruePrivileges) {
      //check for cart privilege (at least one feature between rx or spare parts or catalogue must be enabled)
      if (privilege === "CART") {
        const addtoCartPriv = localPrivilegesAND["ADD_TO_CART"];
        const addtoCartAFAPriv = localPrivilegesAND["ADD_TO_CART_AFA"];
        const addtoCartSPPriv = localPrivilegesAND["ADD_TO_CART_SPARE_PARTS"];

        // //CHECK IF AT LEAST ONE RX PRIVILEGE IS PRESENT
        canRenderVar = atLeastOneTruePrivileges.some((userPriv: string) => {
          const privilegeGroupRX = localPrivilegesRX["RX"];
          if (privilegeGroupRX)
            return privilegeGroupRX.some((priv) => {
              return userPriv.includes(priv);
            });
        });

        //CHECK IF ADD TO CART PRIVILEGES ARE PRESENT
        if (!canRenderVar) {
          canRenderVar = checkIfArrayBInArrayA(atLeastOneTruePrivileges, addtoCartPriv);
        }

        //CHECK IF ADD TO CART AFA PRIVILEGES ARE PRESENT
        if (!canRenderVar) {
          canRenderVar = checkIfArrayBInArrayA(atLeastOneTruePrivileges, addtoCartAFAPriv);
        }

        //CHECK IF ADD TO CART SPARE PARTS PRIVILEGES ARE PRESENT
        if (!canRenderVar) {
          canRenderVar = checkIfArrayBInArrayA(atLeastOneTruePrivileges, addtoCartSPPriv);
        }
      } else if (privilege === "RX" || privilege === "RX_ICON") {
        canRenderVar = atLeastOneTruePrivileges.some((userPriv: string) => {
          const privilegeGroupRX = localPrivilegesRX[privilege];
          if (privilegeGroupRX)
            return privilegeGroupRX.some((priv) => {
              return userPriv.includes(priv);
            });
        });
      } else {
        const privilegeGroupOR = localPrivilegesOR[privilege];
        canRenderVar = atLeastOneTruePrivileges.some((userPriv: string) => {
          //CHECK IF AT LEAST ONE PRIVILEGE IS PRESENT (EX. RX, WARRANTY)
          if (privilegeGroupOR) return privilegeGroupOR.includes(userPriv);

          //CHECK DIRECT MATCH
          return privilege === userPriv;
        });

        //CHECK FOR PRIVILEGES IN AND CONDITION
        const privilegeGroupAND = localPrivilegesAND[privilege];
        if (!canRenderVar && privilegeGroupAND) {
          canRenderVar = checkIfArrayBInArrayA(atLeastOneTruePrivileges, privilegeGroupAND);
        }
      }
    }

    return canRenderVar;
  };
  return canRender;
}
