import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";

import Loader from "./Loader";

import { ReactComponent as CheckIcon } from "../../assets/icons/checkmark-icon.svg";

type ButtonCartNoteProps = {
  text?: string;
  type: "light" | "dark";
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  "data-element-id"?: string | null;
  "data-description"?: string;
};

const ButtonCartNote = ({
  onClick,
  text,
  type,
  disabled,
  isLoading,
  "data-element-id": dataElementId,
  "data-description": dataDescription,
}: ButtonCartNoteProps): JSX.Element => {
  return (
    <Container
      className={type}
      onClick={onClick}
      disabled={disabled}
      {...(dataElementId ? { "data-element-id": dataElementId } : {})}
      {...(dataDescription ? { "data-description": dataDescription } : {})}
    >
      <>
        <TextContainer isDark={type === "dark"}>{text}</TextContainer>
        <IconContainer
          className={clsx(
            "size-small",
            "print-hide",
            type === "light" ? "color-success" : "color-white"
          )}
        >
          {isLoading ? (
            <Loader
              sizePx={15}
              styleLoader={`${type}-background` as "light-background" | "dark-background"}
            />
          ) : (
            <CheckIcon />
          )}
        </IconContainer>
      </>
    </Container>
  );
};
const Container = styled.button`
  display: inline-flex;
  border-radius: 1.1rem;
  border: solid 0.1rem ${(props) => props.theme.palette.bordersBlue};
  background-color: transparent;
  font-size: 0.875rem;
  color: ${(props) => props.theme.palette.white};
  padding: 0 0.75rem;
  height: 2rem;
  font-size: 0.75rem;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.fontMedium} !important;
  box-sizing: border-box;
  width: 183px;
  justify-content: flex-end;
  &.light {
    border: solid 0.1rem ${(props) => props.theme.palette.gray.medium};
    color: ${(props) => props.theme.palette.primary};
  }
`;

const IconContainer = styled.div`
  margin-left: 0.5rem;
  svg {
    fill: ${(props) => props.theme.palette.feedback.success};
  }
  &.size-small {
    width: 1rem;
    height: 1rem;
  }
`;

const TextContainer = styled.div<{ isDark: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 8.5625rem;
  font-family: ${(props) => props.theme.fonts.fontMedium};
  font-size: 0.75rem;
  color: ${(props) => (props.isDark ? props.theme.palette.white : props.theme.palette.primary)};
`;

export default ButtonCartNote;
