import clsx from "clsx";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import useTranslation from "../../../../../../hooks/useTranslation";

import { getPagePath } from "../../../../../../routing/routesUtils";
import { ColBrandGroup } from "../../../../../../utils/menuUtils";
import CustomText from "../../../../../styled-UI/CustomText";
import { useMenuRedirectMultidoorStars } from "../../useMenuRedirectMultidoorStars";

import { ReactComponent as StarIcon } from "../../../../../../assets/icons/star-icon.svg";
import { resetScrollPosition } from "../../../../../../hooks/useSaveScrollPosition";
import { HeaderListContainer } from "./MenuColumn";
import NewTag from "../../../../../widgets/tutorial-pills/new-tag/NewTag";
import { externalReferenceNewTag } from "../../../../../../utils/cmsUtils";

interface Props {
  isKids?: boolean;
  identifier: string;
  isStars?: boolean;
  toggleMenu: (show: boolean) => void;
  setToggleMenu: (identifier: string) => void;
  title: string;
  subtitle?: string;
  showStarIcon?: boolean;
  brandColumns?: ColBrandGroup[];
}

const BrandsColumn = ({
  isKids = false,
  identifier,
  toggleMenu,
  setToggleMenu,
  isStars = false,
  title,
  showStarIcon = false,
  subtitle,
  brandColumns = [],
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const history = useHistory();

  const type = isKids ? "kids" : "adults";
  const dataElementIdIsStars = isStars ? "_Stars_" : "_Brand_";

  const redirectToPLP = (brand: ColBrandGroup) => {
    resetScrollPosition();
    history.push(getPagePath(brand.link));

    toggleMenu(false);
    setToggleMenu("");
  };

  // useMenuRedirectMultidoorStars isolates the logic required to redirect to the prePLP / PLP corresponding to a specific brand
  // refer to the hook's file for more info regarding this logic
  const { handleClick: handleBrandClick } = useMenuRedirectMultidoorStars({
    redirect: redirectToPLP,
  });

  const handleClick = (brand: ColBrandGroup) =>
    handleBrandClick(brand, brand.brands?.[0], brand.label || "", brand.isStars);

  return (
    <>
      {brandColumns?.length > 0 && (
        <UrlList className={clsx(isStars && "brand-column-stars", subtitle && "brand-submenu")}>
          {subtitle ? (
            <li key={type + identifier}>
              <CustomText
                fontSizePx={18}
                as="span"
                marginBottomPx={20}
                color={isStars ? "white" : "primary"}
              >
                {showStarIcon && (
                  <IconContainer>
                    <StarIcon />
                  </IconContainer>
                )}
                {translateLabel(title)}
              </CustomText>
              <CustomText
                fontSizePx={16}
                as="span"
                marginBottomPx={20}
                color={isStars ? "white" : "primary"}
              >
                {translateLabel(subtitle)}
              </CustomText>
            </li>
          ) : (
            <HeaderListContainer key={type + identifier}>
              <CustomText
                fontSizePx={18}
                as="span"
                marginBottomPx={20}
                color={isStars ? "white" : "primary"}
              >
                {showStarIcon && (
                  <IconContainer>
                    <StarIcon />
                  </IconContainer>
                )}
                {translateLabel(title)}
              </CustomText>
              <NewTag position={externalReferenceNewTag[`${identifier}.${title}`]}></NewTag>
            </HeaderListContainer>
          )}

          <ListItemsContainer>
            {brandColumns.map((_: ColBrandGroup, index: number) => {
              return (
                <React.Fragment key={_.brandgroup + type + identifier}>
                  <li key={_.brandgroup + type + identifier}>
                    <a
                      data-element-id={`MainNav_Products_${identifier}${dataElementIdIsStars}${_.label}`
                        ?.replace(/-/g, "_")
                        ?.replace(/ /g, "_")}
                    >
                      <CustomText
                        fontSizePx={14}
                        as="span"
                        marginBottomPx={20}
                        font="font-regular"
                        lineHeightPx={17}
                        color={isStars ? "white" : "primary"}
                        isClickable
                        onClick={() => handleClick(_)}
                      >
                        {_.label || _.brandgroup}
                      </CustomText>
                    </a>
                  </li>
                </React.Fragment>
              );
            })}
          </ListItemsContainer>
        </UrlList>
      )}
    </>
  );
};

const UrlList = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 2.75rem 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem;
  grid-auto-columns: minmax(9rem, 1fr);
  min-width: 10.625rem;
  padding: 1.875rem 0.5rem 0rem;
  background-color: ${(props) => props.theme.palette.white};
  height: 100%;

  li {
    max-width: 11.25rem;
  }

  &.brand-column-stars {
    background-color: ${(props) => props.theme.palette.primary};
  }

  &.brand-submenu {
    grid-template-rows: 4rem 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem;
  }
`;

const ListItemsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 2rem 2rem 2rem 2rem 2rem 2rem;
  grid-auto-columns: minmax(10rem, 1fr);
  column-gap: 1.5rem;

  div span {
    max-width: 14rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    &:hover {
      font-weight: bold;
    }
  }
`;

const IconContainer = styled.div`
  svg {
    height: 1rem;
    fill: ${(props) => props.theme.palette.white};
  }
`;

export default BrandsColumn;
