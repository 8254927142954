import { useDispatch, useSelector } from "react-redux";
import { SecondLevelMenu } from "../interfaces/menuInterfaces";
import { QueryParams } from "../store/search/searchInterfaces";
import {
  getLensesSubMenu,
  getLensesEssilorSubMenu,
  getThirdLevelMenu,
  getInstrumentsSubMenu,
} from "../store/store/storeSagas";
import {
  selectInstrumentsMenu,
  selectLensesEssilorMenu,
  selectLensesMenu,
  selectLxConfigurations,
  selectThirdLevelMenu,
} from "../store/store/storeSlice";
import { getCompleteId } from "../utils/menuUtils";
import useCanRender from "./useCanRender";

export default function useGetThirdLevelMenu(): (open: boolean, menu?: SecondLevelMenu) => void {
  const dispatch = useDispatch();
  const lensesMenu = useSelector(selectLensesMenu);
  const lensesEssilorMenu = useSelector(selectLensesEssilorMenu);
  const instrumentsMenu = useSelector(selectInstrumentsMenu);
  const thirdLevelMenu = useSelector(selectThirdLevelMenu);
  const canRender = useCanRender();

  const LXConfiguration = useSelector(selectLxConfigurations);
  const isEssilorMode =
    LXConfiguration?.filter(
      (element) => element.key === "com.luxottica.oneportal.lenses.menu.enable"
    )?.[0]?.value === "Y";

  const tryGetThirdLevelMenu = (open: boolean, menu?: SecondLevelMenu): void => {
    if (!menu) {
      return;
    }

    const { identifier, uniqueID } = menu;
    const completeId = getCompleteId(uniqueID, identifier);
    const currentMenu = thirdLevelMenu[completeId];

    //Get information for third level menu (not lenses)
    if (
      open &&
      completeId &&
      !currentMenu &&
      identifier !== "LENSES" &&
      identifier !== "Lenses" &&
      identifier !== "INSTRUMENTS" &&
      identifier !== "Instruments"
    ) {
      const queryParams: QueryParams = {};
      if (menu.filters) {
        menu.filters.forEach((filter) => {
          if (filter.type && filter.identifier) {
            if (queryParams[filter.type]) {
              queryParams[filter.type].push(filter.identifier);
            } else {
              queryParams[filter.type] = [filter.identifier];
            }
          }
        });
      }
      dispatch(
        getThirdLevelMenu({
          id: uniqueID,
          customId: completeId,
          identifier: identifier,
          params: queryParams,
          starsRequired: canRender("STARS"),
          canRenderPrivileges: {
            AFA_SECTION: canRender("AFA_SECTION"),
          },
        })
      );
    }

    //Get information for third level menu for LENSES
    if (open && (identifier === "Lenses" || identifier === "LENSES")) {
      if (isEssilorMode && lensesEssilorMenu.length === 0) dispatch(getLensesEssilorSubMenu());
      else if (!isEssilorMode && lensesMenu.length === 0) dispatch(getLensesSubMenu());
    }

    if (
      open &&
      (identifier === "Instruments" || identifier === "INSTRUMENTS") &&
      instrumentsMenu.length === 0
    ) {
      dispatch(getInstrumentsSubMenu());
    }
  };

  return tryGetThirdLevelMenu;
}
