import { useState, useEffect } from "react";
import translateLabelFunc from "../language/translate";
import statelessServices from "../store/statelessServices/statelessServices";
import useStatelessService, {
  RequestStatusStateless,
} from "../store/statelessServices/useStatelessService";
import { AllLabels } from "./labelInterfaces";

/**
 * This custom hook was created to handle cases where we can't follow the default flow to find
 * a supported public/private language and use the LanguageProvider to get the labels.
 *
 * It follows a standalone flow where it retrieves the labels for the provided locale,
 * or, if unavailable, the default supported language, and provides a distinct translation function to be used.
 *
 * At this time it's only used as a fallback for NotFound.tsx (404 Page), which is required if the user lands
 * on a non-existing page directly, without an instance of the application already running.
 *
 * @return {*}  {({
 *   translateStandaloneLabel: (label: string) => string;
 *   getStandaloneLabels: (currentLocale: string | undefined) => void;
 *   standaloneLabels: AllLabels;
 *   lang: string;
 * })}
 */

/*******
 
  ATTENTION ONLY FOR PUBLIC LABELS 

  *******/
const useStandaloneLabels = (): {
  translateStandaloneLabel: (label: string) => string;
  getStandaloneLabels: (currentLocale: string | undefined) => void;
  standaloneLabels: AllLabels;
  lang: string;
  requestStatus: RequestStatusStateless;
} => {
  const [standaloneLabels, setStandaloneLabels] = useState<AllLabels>({} as AllLabels);
  const [lang, setLang] = useState<string>("");

  const { requestStatus, makeRequest, resetRequestState } = useStatelessService();

  useEffect(() => {
    saveLabels();
  }, [requestStatus.status]);

  const requestLang = async (newLang: string) => {
    if (newLang && !standaloneLabels[newLang]) {
      makeRequest(statelessServices.getLabelsPublic, newLang);
    }
  };

  const saveLabels = () => {
    if (lang && requestStatus.status === "SUCCESS") {
      resetRequestState();
      const { data } = requestStatus.data;
      setStandaloneLabels({
        ...standaloneLabels,
        [lang]: data,
      });
    }
  };

  const getStandaloneLabels = (currentLocale: string | undefined) => {
    const supportedLocale: string =
      currentLocale || globalEnvVariables?.defaultSupportedLanguages?.[0]?.locale;
    setLang(supportedLocale);
    requestLang(supportedLocale); // get labels for new language
  };

  const translateStandaloneLabel = (label: string): string => {
    return translateLabelFunc(lang ? standaloneLabels?.[lang] : {}, label, requestStatus.status);
  };

  return { translateStandaloneLabel, getStandaloneLabels, standaloneLabels, lang, requestStatus };
};
export default useStandaloneLabels;
