import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UploadedFileStatus,
  UploadFilePayload,
} from "../store/serviceRequests/serviceRequestInterfaces";
import { uploadFile } from "../store/serviceRequests/serviceRequestSagas";
import { selectUploadedFileStatus } from "../store/serviceRequests/serviceRequestSlice";
import { UploadServiceTokenPayload } from "../store/statelessServices/statelessInterfaces";
import statelessServices from "../store/statelessServices/statelessServices";
import useStatelessService from "../store/statelessServices/useStatelessService";

interface UploadStatus {
  jwtStatus: "ERROR" | "SUCCESS";
  uploadedFilesStatus: UploadedFileStatus[];
}

const useUploadFiles = (): {
  uploadFiles: (uploadInfo: UploadFilePayload[], payload: UploadServiceTokenPayload) => void;
  uploadStatus: UploadStatus | null;
} => {
  const { requestStatus: jwtRequestStatus, makeRequest, resetRequestState } = useStatelessService();
  const dispatch = useDispatch();
  const uploadFilesStatus = useSelector(selectUploadedFileStatus);

  //information to send in api
  const [uploadInfo, setUploadInfo] = useState<UploadFilePayload[] | null>(null);
  const [doorId, setDoorId] = useState<string | null>(null);

  //output of hook
  const [uploadStatus, setUploadStatus] = useState<UploadStatus | null>(null);

  //set info for api and start jwt request
  const uploadFiles = async (
    uploadInfo: UploadFilePayload[],
    payload: UploadServiceTokenPayload
  ) => {
    setUploadInfo(uploadInfo);
    setDoorId(payload.doorId);
    makeRequest(statelessServices.getTokenForUpload, payload);
  };

  //get response of jwt request
  useEffect(() => {
    //if it's successful, upload files
    if (jwtRequestStatus.status === "SUCCESS" && uploadInfo && doorId) {
      const { data } = jwtRequestStatus.data;
      const jwt = data?.jwt;

      uploadInfo.forEach((_) => {
        dispatch(
          uploadFile({
            ..._,
            jwt: jwt,
          })
        );
      });
    }
    //after jwt request if it failed
    if (jwtRequestStatus.status === "ERROR") {
      setUploadStatus({
        jwtStatus: "ERROR",
        uploadedFilesStatus: [],
      });
    }
  }, [jwtRequestStatus]);

  useEffect(() => {
    if (
      uploadInfo &&
      uploadFilesStatus.length > 0 &&
      uploadFilesStatus.length === uploadInfo.length
    ) {
      setUploadStatus({
        jwtStatus: "SUCCESS",
        uploadedFilesStatus: uploadFilesStatus,
      });
    }
  }, [uploadFilesStatus]);

  return { uploadFiles, uploadStatus };
};

export default useUploadFiles;
