import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addHours } from "date-fns";

import useTranslation from "../../../hooks/useTranslation";
import { getPagePath } from "../../../routing/routesUtils";
import { selectUnsignedLegalDocuments } from "../../../store/documents/documentsSlice";
import { selectActiveDoor, selectLocale } from "../../../store/user/userSlice";
import { getUnsignedLegalDocuments, saveUserAction } from "../../../store/documents/documentsSagas";
import { CookieUtils } from "../../../utils/cookieUtils";
import { formatDate } from "../../../utils/dateUtils";
import BottomBanner from "../../styled-UI/BottomBanner";
import { ReactComponent as BookIcon } from "../../../assets/icons/book-icon.svg";

const cookie = new CookieUtils();

export const setDocumentCookie = (
  documentId: string,
  dismissForHours: number,
  locale: string
): void => {
  cookie.set(
    documentId,
    documentId,
    formatDate(addHours(new Date(), dismissForHours), "PPPPpppp", locale)
  );
};

export default function WeAgreeBanner(): JSX.Element | null {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const currentLocale = useSelector(selectLocale);
  const activeDoor = useSelector(selectActiveDoor);
  const docs = useSelector(selectUnsignedLegalDocuments);

  const [isDocBannerDismissed, setIsDocBannerDismissed] = useState<boolean>(true);

  useEffect(() => {
    if (activeDoor && history.location.pathname.includes("homepage"))
      dispatch(getUnsignedLegalDocuments({ door: activeDoor }));
  }, [activeDoor]);

  useEffect(() => {
    if (docs?.length) {
      const areAllDocsDismissed = docs.every((doc) => cookie.get(doc.documentId) !== "");
      const isAcceptanceAggrPage = history.location.pathname.includes("acceptance-agreement");

      if (!areAllDocsDismissed && !isAcceptanceAggrPage) {
        setIsDocBannerDismissed(false);
      }
    }
  }, [docs]);

  const handleDismiss = () => {
    setIsDocBannerDismissed(true);

    docs?.forEach((doc) => {
      dispatch(
        saveUserAction({
          documentId: doc.documentId,
          action: "DISPOSE",
          mailSentTo: "",
          signFormFieldValueList: [],
        })
      );
      setDocumentCookie(
        doc.documentId,
        parseInt(doc.docConf.hourPopupShowInterval) as number,
        currentLocale
      );
    });
  };

  const handleSign = () => {
    setIsDocBannerDismissed(true);
    history.push(getPagePath("/acceptance-agreement"));
  };

  const showBanner = (): boolean => {
    if (!history.location.pathname.includes("homepage")) return false;
    if (!docs?.length) return false;
    if (isDocBannerDismissed) return false;
    return true;
  };

  return showBanner() ? (
    <BottomBanner
      data-element-id="WeAgree_Postpone"
      icon={<BookIcon />}
      text={translateLabel("WE_AGREE_UNSIGNED_BANNER_TEXT")}
      dismissText={translateLabel("WE_AGREE_UNSIGNED_BANNER_DISMISS_BUTTON")}
      dismissAction={handleDismiss}
      acceptText={translateLabel("WE_AGREE_UNSIGNED_BANNER_SIGN_BUTTON")}
      acceptAction={handleSign}
    />
  ) : null;
}
