import React from "react";
import styled from "styled-components/macro";
import CustomText from "../../styled-UI/CustomText";
import Tag from "../../styled-UI/Tag";
import Button from "../../styled-UI/Button";
import VideoComponent from "../../styled-UI/VideoComponent";
import { PromotionSlide as PromotionSlideInterface } from "../../../interfaces/cmsInterfaces";
import { getAnalyticsTagId, getMultiMedia, useHandleCmsLink } from "../../../utils/cmsUtils";
import IconButton from "../../styled-UI/IconButton";
import { ReactComponent as LeftArrowIcon } from "../../../assets/icons/arrow-prev-icon-white.svg";
import { ReactComponent as RightArrowIcon } from "../../../assets/icons/arrow-next-icon-white.svg";
import SetInnerHTMLWithSanitize from "../SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  slide: PromotionSlideInterface;
  tileNumber?: number;
  arrows?: boolean;
  goToPrevSlide: () => void;
  goToNextSlide: () => void;
  customFont?: FontFamilies | null;
}

const PromotionSlide = ({
  slide,
  tileNumber,
  arrows,
  goToPrevSlide,
  goToNextSlide,
  customFont = null,
}: Props): JSX.Element => {
  const { handleCmsLink } = useHandleCmsLink();

  const {
    externalLink,
    openInNewTab,
    linkString,
    title,
    description,
    linkLabel,
    titleColour,
    description1Colour,
    multiMediaType,
  } = slide;
  const { srcImg, srcVideo, coverImg, placement, contentType } = getMultiMedia(multiMediaType);
  const analyticsTag = getAnalyticsTagId(slide, slide.id);

  const handleRedirect = (): void => {
    handleCmsLink(linkString, externalLink, openInNewTab);
  };

  return (
    <Container srcImg={contentType?.includes("/gif") ? srcVideo : srcImg}>
      {title && (
        <OuterTag>
          <Tag tag={title} size="sm" type="one-tile" color={titleColour} />
        </OuterTag>
      )}
      {contentType?.includes("/gif")}
      <ImageOverlay />
      <LowerSection>
        <div className="row">
          <div className="col-12">
            <Divider />
          </div>
          <div className="col-9">
            {description && (
              <TextSection>
                <CustomText
                  as="span"
                  fontSizePx={28}
                  font={customFont ? "custom-medium" : "font-medium"}
                  fontFamily={customFont}
                  customColor={description1Colour}
                >
                  <SetInnerHTMLWithSanitize text={description} />
                </CustomText>
              </TextSection>
            )}
            {srcVideo && linkString && (
              <Button type="primary-light" onClick={handleRedirect} customFont={customFont}>
                {linkLabel}
              </Button>
            )}
          </div>
          <div className="col-3 d-flex align-items-end"></div>
        </div>
      </LowerSection>
      {srcVideo && !contentType?.includes("/gif") && (
        <OuterVideo>
          <VideoComponent
            srcVideo={srcVideo}
            coverImage={coverImg}
            previewMode={coverImg ? "cover" : "first-frame"}
            placement={placement}
          />
        </OuterVideo>
      )}
      {!srcVideo && (
        <OverlayHover>
          <OuterButton>
            {arrows && (
              <ArrowContainer left="2">
                <IconButton
                  onClick={goToPrevSlide}
                  size="md"
                  icon={<LeftArrowIcon />}
                  type="no-background-white"
                />
              </ArrowContainer>
            )}
            {linkString && linkLabel && (
              <Button
                data-element-id={`News_Tiles_Tile${tileNumber}`}
                data-description={`${analyticsTag}_${slide.name}`}
                type="secondary"
                onClick={handleRedirect}
                customFont={customFont}
              >
                {linkLabel}
              </Button>
            )}
            {arrows && (
              <ArrowContainer right="2">
                <IconButton
                  onClick={goToNextSlide}
                  size="md"
                  icon={<RightArrowIcon />}
                  type="no-background-white"
                />
              </ArrowContainer>
            )}
          </OuterButton>
        </OverlayHover>
      )}
    </Container>
  );
};

const OuterButton = styled.div`
  display: none;
`;

const OuterVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

const OverlayHover = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
`;
const ArrowContainer = styled.div<{ left?: string; right?: string }>`
  position: absolute;
  left: ${(props) => props.left && `${props.left}rem`};
  right: ${(props) => props.right && `${props.right}rem`};
`;

const Container = styled.div<{ srcImg: string | null | undefined }>`
  height: 34.375rem;
  ${(props) => props.srcImg && "background-image: url(" + props.srcImg + ");"}
  background-position: right;
  position: relative;
  background-size: cover;
  background-position: center;

  &:hover {
    ${OverlayHover} {
      display: block;
    }
    ${OuterButton} {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      z-index: 2;
    }
  }
`;

const OuterTag = styled.div`
  position: absolute;
  left: 2.75rem;
  top: 2.75rem;
`;

const LowerSection = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2.5rem;
  z-index: 20;
`;

const TextSection = styled.div`
  min-height: 5.9375rem;
  min-width: 18.5rem;
  max-width: 18.5rem;
  span {
    color: ${(props) => props.theme.palette.white};
    line-height: 1.14;
    text-overflow: ellipsis;
  }
`;

const Divider = styled.div`
  margin-bottom: 1.5rem;
  opacity: 0.3;
  border: solid 1px ${(props) => props.theme.palette.white};
`;

const ImageOverlay = styled.div`
  top: 0px;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.4;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 1) 100%
  );
  z-index: 10;
`;

export default PromotionSlide;
