import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  saveCartAlternativeVariants,
  selectCartAlternativeVariants,
  selectCartTotalAlternativeVariants,
} from "../../../store/cart/cartSlice";
import { OrderItem } from "../../../store/cart/cartInterfaces";
import { clearPreCart } from "../../../store/catalogue/catalogueSlice";
import { getCartAlternativeVariants } from "../../../store/cart/cartSagas";
import { Door } from "../../../store/user/userInterfaces";
import Variants from "./CartAlternativePopupVariants";
import Modal from "../../styled-UI/Modal";
import { useBreakpoint } from "../../../assets/theme/breakpoint";
import AlternativeHeader from "./CartAlternativePopupHeader";

interface Props {
  isOpen: boolean;
  close: () => void;
  title?: string;
  orderItem: OrderItem;
  currentDoor: Door;
}

const CartAlternativePopup = ({
  isOpen,
  close,
  title,
  orderItem,
  currentDoor,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [modalTop, setModalTop] = useState(0);
  const breakpoints = useBreakpoint();

  const alternativeVariants = useSelector(selectCartAlternativeVariants);
  const totalVariants = useSelector(selectCartTotalAlternativeVariants);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const countTotalVariants = totalVariants >= 12 ? 12 : totalVariants;
  const modelCodeAnalytics = orderItem.skuCode?.split("_")[0];
  const variantsPerPage = breakpoints["md"] ? 3 : 4;

  useEffect(() => {
    const top = window.scrollY;
    if (orderItem) setModalTop(top + 25);
  }, [orderItem]);

  useEffect(() => {
    // get alternative variants
    if (orderItem.sku?.uniqueID) {
      dispatch(
        getCartAlternativeVariants({
          id: orderItem.sku.uniqueID,
          doorId: currentDoor?.orgentityId,
          orgentityName: currentDoor?.orgentityName,
        })
      );
    }
  }, [orderItem.sku?.uniqueID]);

  useEffect(() => {
    return () => {
      dispatch(saveCartAlternativeVariants(null)); // clear alternative variants if it will unmont
      dispatch(clearPreCart()); // clear precart if it will unmont
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      isCentered={false}
      top={modalTop}
      customStyle={variantsPerPage === 3 ? "expanded-tile-modal-sm" : "expanded-tile-modal"}
      data-element-id="Variants_Alternative"
      data-description={`${modelCodeAnalytics}_${orderItem.sku.upc}`}
    >
      <StyledCartAlternative>
        <AlternativeHeader
          totalVariants={countTotalVariants}
          variantsPerPage={variantsPerPage}
          orderItem={orderItem}
          currentPage={currentPage}
          updateCurrentPage={(page: number) => setCurrentPage(page)}
        />
      </StyledCartAlternative>
      <Variants
        alternativeVariants={alternativeVariants}
        orderItem={orderItem}
        currentDoor={currentDoor}
        currentPage={currentPage}
      />
    </Modal>
  );
};

//#region Style
const StyledCartAlternative = styled.div`
  overflow-x: hidden;
  width: 100%;
`;

export default CartAlternativePopup;
