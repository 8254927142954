import { AxiosResponse } from "axios";

import { appendFiltersToURLSearchParams } from "../../utils/filterUtils";
import { QueryParamsWithString } from "../search/searchInterfaces";
import { createAndExecuteService } from "../serviceUtils";
import {
  BestSellersPayload,
  GetAttachmentsVariantPayload,
  GetLandingPageVariantsPayload,
  GetPlpStarsCatalogueService,
  GetPrePLPStarsContentsServicePayload,
  GetProductRecommendationPayload,
  GetProductWithVariantsPayload,
  GetSkuForVariantPayload,
  GetVariantDetailsPayload,
  KeylookProductsPayload,
  PDPCmsContents,
  PlpVariantsOptions,
  ProductsCompatiblePayload,
  QRPayload,
  SearchByFacetPayload,
  SimilarProductsPayload,
  getPlpCatalogueOptions,
  getPlpRecommendedOptions,
} from "./catalogueInterface";
import {
  QRUrl,
  VMMVTransitionsUrl,
  getAttachmentsVariantUrl,
  getBestSellerUrl,
  getKeylookProductsUrl,
  getLPVariantsUrl,
  getPDPCmsContentsUrl,
  getPlpCatalogueUrl,
  getPlpRecommendedUrl,
  getPlpStarsCatalogueUrl,
  getPlpVariantsUrl,
  getPrePLPStarsContentsUrl,
  getPrePlpCatalogueUrl,
  getPriceUrl,
  getProductAvailabilityUrl,
  getProductMerchandisingUrl,
  getProductRecommendationUrl,
  getProductWithVariantsUrl,
  getProductsCompatibleWithAccessoriesUrl,
  getRTRitem,
  getSimilarProductsUrl,
  getSkuForVariantUrl,
  getSkusByVariantUrl,
  getVariantDetailsUrl,
  getVideoAvailabilityUrl,
  resolveSlugUrl,
  searchByFacetUrl,
} from "./catalogueUrls";

export default {
  /////////////////// PREPLP
  getPrePlpCatalogue: async (payload: getPlpCatalogueOptions): Promise<AxiosResponse> => {
    const url = getPrePlpCatalogueUrl
      .replace("{categoryId}", payload.categoryId)
      .replace("{idCMS}", payload.contentId);

    const qParams = payload.params.pageNumber // if pageNumber is not present, default to 1
      ? appendFiltersToURLSearchParams(
          {
            pageSize: payload.productPerPage,
          },
          payload.params
        )
      : appendFiltersToURLSearchParams(
          {
            pageSize: payload.productPerPage,
            pageNumber: "1",
          },
          payload.params
        );

    return createAndExecuteService(url, "GET", qParams);
  },
  getPrePLPStarsContents: async (
    payload: GetPrePLPStarsContentsServicePayload
  ): Promise<AxiosResponse> => {
    const { brandGroup, facetName, contentId, facet, ...rest } = payload;

    const url = getPrePLPStarsContentsUrl.replace("{brandGroupId}", brandGroup);
    const params: QueryParamsWithString = {};
    Object.entries(rest).forEach(([key, value]) => {
      if (value) params[key] = value;
    });

    params.facetName = facetName ?? [];

    if (contentId) params.contents = contentId;

    const queryParams = appendFiltersToURLSearchParams(params, facet);

    return createAndExecuteService(url, "GET", queryParams);
  },
  /////////////////// PLP

  // get plp catalogue
  getPlpCatalogue: async (payload: getPlpCatalogueOptions): Promise<AxiosResponse> => {
    const url = getPlpCatalogueUrl
      .replace("{contentId}", payload.contentId)
      .replace("{categoryId}", payload.categoryId);

    const params: QueryParamsWithString = {
      pageSize: payload.productPerPage,
    };

    //add facetName to params if present
    payload.facetName ? (params["facetName"] = payload.facetName) : null;

    //add starsRequired
    payload.starsRequired ? (params["starsRequired"] = payload.starsRequired.toString()) : null;

    /* --- handle default params --- */
    const { pageNumber, orderBy, ...rest } = payload.params;

    // if pageNumber is not present, default to 1
    params["pageNumber"] = pageNumber ? pageNumber : "1";

    // if orderBy is not present, default to RELEVANCE
    params["orderBy"] = orderBy ? orderBy : "RELEVANCE";

    if (payload.params["manufacturer.raw"] === undefined) {
      params["facet"] = "noBrand";
    }

    if (payload.doorId) params["doorId"] = payload.doorId;

    return createAndExecuteService(url, "GET", appendFiltersToURLSearchParams(params, rest));
  },

  getPlpRecommended: async (payload: getPlpRecommendedOptions): Promise<AxiosResponse> => {
    const params: QueryParamsWithString = {
      pageSize: payload.productPerPage,
    };
    const { pageNumber, searchTerm, ...rest } = payload.params;
    params["pageNumber"] = pageNumber ?? payload.pageNumber?.toString() ?? 1;
    if (searchTerm) {
      params["term"] = searchTerm;
    }

    return createAndExecuteService(
      getPlpRecommendedUrl,
      "GET",
      appendFiltersToURLSearchParams(params, rest)
    );
  },

  // get variants for single product in expanded tile popup
  getPlpVariants: async (payload: PlpVariantsOptions): Promise<AxiosResponse> => {
    const { filters, partNumber, ...rest } = payload;

    const params: QueryParamsWithString = {};
    Object.entries(rest).forEach(([key, value]) => {
      if (value) params[key] = String(value);
    });

    const queryParams = appendFiltersToURLSearchParams(params, filters);

    const url = getPlpVariantsUrl.replace("{partNumber}", partNumber);
    return createAndExecuteService(url, "GET", queryParams);
  },

  // get plp catalogue
  getPlpStarsCatalogue: async (payload: GetPlpStarsCatalogueService): Promise<AxiosResponse> => {
    const url = getPlpStarsCatalogueUrl.replace("{categoryId}", payload.categoryId);
    const params: QueryParamsWithString = {};

    const { facetName, pageNumber, orderBy, ...rest } = payload.params;

    //add facetName to query params if present
    facetName ? (params["facetName"] = facetName) : null;

    //add page size
    payload.productPerPage ? (params["pageSize"] = payload.productPerPage) : null;

    if (payload.doorId) params["doorId"] = payload.doorId;

    /* --- handle default params --- */

    // if pageNumber is not present, default to 1
    if (pageNumber?.[0] !== "NONE") params["pageNumber"] = pageNumber ? pageNumber : "1";

    // if orderBy is not present, default to RELEVANCE
    params["orderBy"] = orderBy ? orderBy : "RELEVANCE";

    return createAndExecuteService(url, "GET", appendFiltersToURLSearchParams(params, rest));
  },

  /////////////////// PDP

  // get variant/moco for pdp page
  getVariantDetails: async (payload: GetVariantDetailsPayload): Promise<AxiosResponse> => {
    const params = new URLSearchParams();
    Object.entries(payload)
      .filter((_) => _[0] !== "variantId")
      .forEach(([key, value]) => {
        if (value) {
          params.append(key, value);
        }
      });

    const url = getVariantDetailsUrl.replace("{variantId}", payload.variantId);
    return createAndExecuteService(url, "GET", params);
  },

  // get video for SeeThemOn
  getVideoAvailability: async (payload: string): Promise<AxiosResponse> => {
    const params = new URLSearchParams();
    const url = getVideoAvailabilityUrl.replace("{partNumber}", encodeURIComponent(payload));
    return createAndExecuteService(url, "GET", params);
  },

  // get variants given  a product
  getProductWithVariants: async (
    payload: GetProductWithVariantsPayload
  ): Promise<AxiosResponse> => {
    const { productId, facets, ...rest } = payload;

    const queryParams: QueryParamsWithString = {};
    Object.keys(rest).forEach((key) => {
      const value = (rest as any)[key];
      if (value) queryParams[key] = value;
    });

    const url = getProductWithVariantsUrl.replace("{productId}", productId);
    return createAndExecuteService(url, "GET", appendFiltersToURLSearchParams(queryParams, facets));
  },

  getSkusByVariant: async (payload: GetVariantDetailsPayload): Promise<AxiosResponse> => {
    const params = new URLSearchParams();
    Object.entries(payload)
      .filter((_) => _[0] !== "variantId")
      .forEach(([key, value]) => {
        if (value) {
          params.append(key, value);
        }
      });

    const url = getSkusByVariantUrl.replace("{catentryId}", payload.variantId);
    return createAndExecuteService(url, "GET", params);
  },

  getRTRItem: async (partNumber: string): Promise<AxiosResponse> => {
    const url = getRTRitem.replace("{partNumber}", partNumber);
    return createAndExecuteService(url, "GET");
  },

  // get PDP cms contents
  getPdpCmsContents: async (qParams: PDPCmsContents): Promise<AxiosResponse> => {
    const params = new URLSearchParams();

    (Object.keys(qParams) as Array<keyof typeof qParams>).map((key) => {
      const value = qParams[key];
      if (value && Array.isArray(value)) value.forEach((element) => params.append(key, element));
      else if (value) params.append(key, value.toString());
    });
    return createAndExecuteService(getPDPCmsContentsUrl, "GET", params);
  },

  // get values for dropdown "Compatible With" in ACCESSORIES ONLY
  getProductMerchandising: async (productId: string): Promise<AxiosResponse> => {
    const url = getProductMerchandisingUrl.replace("{productId}", productId);
    return createAndExecuteService(url, "GET");
  },

  // get attachments for variant
  getAttachmentsVariant: async (payload: GetAttachmentsVariantPayload): Promise<AxiosResponse> => {
    const { variantId, attachments, ...params } = payload;
    const url = getAttachmentsVariantUrl
      .replace("{variantId}", variantId)
      .replace("{attachments}", attachments);

    const paramsUrl = new URLSearchParams();

    for (const [key, value] of Object.entries(params))
      if (Array.isArray(value)) value?.forEach((_) => paramsUrl.append(key, _));
      else paramsUrl.append(key, value);

    return createAndExecuteService(url, "GET", paramsUrl);
  },

  getQRCode: async (payload: QRPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(QRUrl, "POST", null, payload);
  },

  getVMMVTransitions: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(VMMVTransitionsUrl, "GET");
  },

  //get similar products
  getSimilarProducts: async (payload: SimilarProductsPayload): Promise<AxiosResponse> => {
    const { filters, uniqueID, ...rest } = payload;

    const url = getSimilarProductsUrl.replace("{id}", uniqueID);

    const queryParams: QueryParamsWithString = {};
    Object.keys(rest).forEach((key) => {
      const value = (rest as any)[key];
      if (value !== undefined) queryParams[key] = value;
    });

    return createAndExecuteService(
      url,
      "GET",
      appendFiltersToURLSearchParams(queryParams, filters)
    );
  },

  //get keylook products
  getKeylookProducts: async (payload: KeylookProductsPayload): Promise<AxiosResponse> => {
    const { keylook, ...rest } = payload;

    const url = getKeylookProductsUrl.replace("{id}", keylook);

    const queryParams: QueryParamsWithString = {};
    Object.keys(rest).forEach((key) => {
      const value = (rest as any)[key];
      if (value !== undefined) queryParams[key] = value;
    });

    return createAndExecuteService(url, "GET", appendFiltersToURLSearchParams(queryParams));
  },

  //get products compatible with accessories
  getProductsCompatibleWithAccessories: async (
    payload: ProductsCompatiblePayload
  ): Promise<AxiosResponse> => {
    const { filters, partNumber, ...rest } = payload;

    const url = getProductsCompatibleWithAccessoriesUrl.replace("{partNumber}", partNumber);

    const queryParams: QueryParamsWithString = {};
    Object.keys(rest).forEach((key) => {
      const value = (rest as any)[key];
      if (value !== undefined) queryParams[key] = value;
    });

    return createAndExecuteService(
      url,
      "GET",
      appendFiltersToURLSearchParams(queryParams, filters)
    );
  },

  /////////////////// COMMONS

  resolveSlug: async (slug: string): Promise<AxiosResponse> => {
    const url = resolveSlugUrl.replace("{slug}", slug);
    return createAndExecuteService(url, "GET");
  },

  getProductAvailability: async (productIds: string[]): Promise<AxiosResponse> => {
    const params = new URLSearchParams({ productId: productIds.join(",") });
    return createAndExecuteService(getProductAvailabilityUrl, "GET", params);
  },

  getPriceService: async (productIds: string[], doorId?: string): Promise<AxiosResponse> => {
    const currentUrlParams = new URLSearchParams();
    productIds.forEach((id: string) => {
      currentUrlParams.append("productId", id);
    });
    doorId && currentUrlParams.append("doorId", doorId);
    return createAndExecuteService(getPriceUrl, "GET", currentUrlParams);
  },

  searchByFacet: async (params: SearchByFacetPayload): Promise<AxiosResponse> => {
    const { term, facet, ...rest } = params;
    const currentUrlParams = new URLSearchParams();
    const url = searchByFacetUrl.replace("{term}", term ? term : "*");

    (Object.keys(rest) as Array<keyof typeof rest>).forEach((key) => {
      const value = rest[key];
      if (value) {
        if (Array.isArray(value)) {
          value.forEach((_) => {
            currentUrlParams.append(key, _.toString());
          });
        } else {
          currentUrlParams.append(key, value.toString());
        }
      }
    });

    return createAndExecuteService(url, "GET", currentUrlParams);
  },

  getSkuForVariant: async (payload: GetSkuForVariantPayload): Promise<AxiosResponse> => {
    const { variantId, doorName, ...rest } = payload;
    const url = getSkuForVariantUrl.replace("{catentryId}", variantId);
    const searchParams = new URLSearchParams();
    Object.entries(rest).forEach(([key, value]) => {
      if (value) {
        searchParams.append(key, value);
      }
    });

    return createAndExecuteService(url, "GET", searchParams);
  },

  // BESTSELLERS
  getBestSellers: async (payload: BestSellersPayload): Promise<AxiosResponse> => {
    const url = getBestSellerUrl.replace("{searchTerm}", payload.searchTerm);
    const searchParams = new URLSearchParams();
    Object.entries(payload)
      .filter((_) => _[0] !== "searchTerm")
      .forEach(([key, value]) => {
        if (value) {
          searchParams.append(key, value);
        }
      });

    return createAndExecuteService(url, "GET", searchParams);
  },

  // PRODUCT RECOMMENDATION
  getProductRecommendation: async (
    payload: GetProductRecommendationPayload
  ): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();
    Object.entries(payload).forEach(([key, value]) => {
      if (value) {
        searchParams.append(key, value);
      }
    });

    return createAndExecuteService(getProductRecommendationUrl, "GET", searchParams);
  },

  /////// LANDING PAGE
  // get variants for single product in landing page product popup
  getLPVariants: async (payload: GetLandingPageVariantsPayload): Promise<AxiosResponse> => {
    const { partNumber, ...rest } = payload;

    const params: QueryParamsWithString = {};
    Object.entries(rest).forEach(([key, value]) => {
      if (value) params[key] = String(value);
    });

    const queryParams = appendFiltersToURLSearchParams(params);
    const url = getLPVariantsUrl.replace("{partNumber}", partNumber);
    return createAndExecuteService(url, "GET", queryParams);
  },
};
