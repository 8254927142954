import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import useCanRender from "../../hooks/useCanRender";
import { Door } from "../../store/user/userInterfaces";
import {
  selectActiveDoor,
  selectIsBackOfficeUser,
  selectIsSubuser,
} from "../../store/user/userSlice";

interface Props {
  fallback?: JSX.Element;
  privilege?: string;
  children: ReactNode;
  door?: Door | null;
  isNotBOUser?: boolean;
  onlySubuser?: boolean;
  notShownForSubuser?: boolean;
}

/**
 * Conditionally render any component when specified conditions are met
 *
 * @param matchUserPrivileges User privileges should match any of matchUserPrivileges
 * @param fallback A fallback React tree to show (in place of children) if conditions aren't met
 */
const ConditionalRender = ({
  privilege,
  children,
  fallback,
  door,
  isNotBOUser,
  onlySubuser,
  notShownForSubuser,
}: Props): JSX.Element | null => {
  const canRender = useCanRender();
  let isUserEnabled = true;
  let isSubuserEnabled = true;

  const isBackOfficeUser = useSelector(selectIsBackOfficeUser);
  const isSubuser = useSelector(selectIsSubuser);
  if (isNotBOUser && isBackOfficeUser) isUserEnabled = false;
  if (isSubuser && notShownForSubuser) isSubuserEnabled = false;

  return canRender(privilege, door, onlySubuser) && isUserEnabled && isSubuserEnabled ? (
    <>{children}</>
  ) : fallback ? (
    <>{fallback}</>
  ) : null;
};

export default ConditionalRender;
