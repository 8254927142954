import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import useCanRender from "../../../hooks/useCanRender";

import { getPrecartCount } from "../../../store/cart/cartSagas";
import {
  getBrandGroups,
  getMenu,
  getSearchTermAssociation,
  getStarDoorsForBrand,
} from "../../../store/store/storeSagas";
import {
  selectBrandGroups,
  selectMenu,
  selectSearchTermAssociation,
  selectStarDoorsPerBrand,
} from "../../../store/store/storeSlice";
import Logo from "../Logo";
import MainNavbar from "./navbar/MainNavbar";
import SearchContainer from "./navbar/SearchContainer";
import SmallerNavbar from "./navbar/SmallerNavbar";

import { ReactComponent as SearchIcon } from "../../../assets/icons/search-icon.svg";
import {
  getNotificationsAlerts,
  getSectionsAlerts,
} from "../../../store/accounting/accountingSagas";
import { allowedServicesIds } from "../../../utils/accountingUtils";
import WrapperLayout from "../WrapperLayout";

interface Props {
  isMultiDoor: boolean;
  hideHeaderAnim: boolean;
}

const HeaderComponent = ({ isMultiDoor, hideHeaderAnim }: Props): JSX.Element => {
  const location = useLocation();
  const canRender = useCanRender();
  const dispatch = useDispatch();

  const brandGroups = useSelector(selectBrandGroups);
  const menu = useSelector(selectMenu);
  const searchTermAssociation = useSelector(selectSearchTermAssociation);
  const starDoorsPerBrand = useSelector(selectStarDoorsPerBrand);

  ///////////////// download menu for the first time
  useEffect(() => {
    if (Object.entries(starDoorsPerBrand)?.length === 0) dispatch(getStarDoorsForBrand());

    if (menu.length === 0) {
      dispatch(
        getMenu({
          starsRequired: canRender("STARS"),
        })
      );
    }

    if (brandGroups && !Object.keys(brandGroups).length) dispatch(getBrandGroups()); // get brand groups

    dispatch(getNotificationsAlerts()); // get notifications counts
    location?.pathname?.includes("/account/") && dispatch(getSectionsAlerts(allowedServicesIds)); // get sections counts

    if (canRender("CART")) dispatch(getPrecartCount(false)); // get number of items in cart
  }, []);

  ///////////////// handle expanded/collapsed search
  const [expanded, setExpanded] = useState<boolean>(false);

  // open search in header when search page
  useEffect(() => {
    if (location && location.pathname?.includes("/search-results")) setExpanded(true);
    else expanded && setExpanded(false);
  }, [location]);

  return (
    <>
      <Header
        id="header"
        className={clsx(isMultiDoor && "header-multidoor", hideHeaderAnim && "hide")}
      >
        <WrapperLayout width100Sm isNavbar>
          <Wrapper>
            <LogoContainer className="no-hover">
              <Logo heightPx={23} isLink />
            </LogoContainer>

            {expanded && (
              <SearchContainer
                closeExpandMenu={() => setExpanded(false)}
                data-element-id="MainNav_Search"
              />
            )}

            <LoggedSection>
              {!expanded && (
                <SearchWrapper data-element-id="MainNav_Search">
                  <IconContainer
                    data-element-id="MainNav_Search_SearchBox"
                    onClick={() => {
                      setExpanded(!expanded);
                      if (!searchTermAssociation) dispatch(getSearchTermAssociation());
                    }}
                  >
                    <SearchIcon />
                  </IconContainer>
                </SearchWrapper>
              )}

              <MainNavbar expanded={expanded} />
            </LoggedSection>
          </Wrapper>
        </WrapperLayout>
      </Header>

      <SmallerNavbar hideHeaderAnim={hideHeaderAnim} />
    </>
  );
};

const Header = styled.header`
  width: 100%;
  background-color: ${(props) => props.theme.palette.white};
  position: fixed;
  height: ${(props) => props.theme.headerHeightRem + "rem"};
  top: 0;
  z-index: 500;
  transition: top 0.3s;

  &.hide {
    top: -${(props) => props.theme.headerHeightRem + "rem"};
  }

  &.header-multidoor {
    top: ${(props) => props.theme.multidoorBannerHeightRem + "rem"};

    &.hide {
      top: -${(props) => props.theme.multidoorBannerHeightRem + props.theme.headerHeightRem + "rem"};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* max-width: ${(props) => props.theme.maxWrapperWidth}; */
  margin: 0 auto;
  height: 100%;
`;

const LogoContainer = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  padding-right: 1.5rem;
`;

const LoggedSection = styled.div`
  display: flex;
  z-index: 101;
  height: 100%;
  flex-shrink: 0;
`;

const SearchWrapper = styled.div`
  width: ${(props) => props.theme.headerHeightRem + "rem"};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  flex-shrink: 0;

  svg {
    fill: ${(props) => props.theme.palette.primary};
    height: 1.2rem;
    width: 1.2rem;
  }

  &:hover {
    position: relative;

    :after {
      position: absolute;
      bottom: 1rem;
      border-bottom: solid 2px ${(props) => props.theme.palette.primary};
      content: "";
      width: 40%;
    }
  }
`;

export default HeaderComponent;

export const MemoizedHeaderComponent = React.memo(HeaderComponent);
