import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import clsx from "clsx";
import useTranslation from "../../../hooks/useTranslation";
import useDisplayPrice from "../../../hooks/useDisplayPrice";
import { selectCurrencyFormat } from "../../../store/user/userSlice";
import { OrderItem } from "../../../store/cart/cartInterfaces";
import CustomText from "../../styled-UI/CustomText";
import IconButton from "../../styled-UI/IconButton";
import ButtonCartNote from "../../styled-UI/ButtonCartNote";
import CurrencyFormatter from "../CurrencyFormatter";
import { ReactComponent as Arrow } from "../../../assets/icons/arrowhead-down-icon.svg";
import { useBreakpoint } from "../../../assets/theme/breakpoint";
import { Sku } from "../../../interfaces/productInterface";
import { SparePartsSku } from "../../../store/aftersales/aftersalesInterface";
import { getAttributeValues } from "../../../utils/productUtils";
import { isOrderItemRx } from "../../../utils/cartUtils";
import useCanRender from "../../../hooks/useCanRender";

interface Props {
  orderItem: OrderItem;
  hideCustomerRef?: boolean;
  hideButton?: boolean;
  hidePrice?: boolean;
  totalPrice?: number;
  setOpenDetails: (val: boolean) => void;
  openDetails: boolean;
  currency?: string;
  sizeFromProps?: string;
}

const TYPTileRow = ({
  orderItem,
  hideCustomerRef = false,
  hideButton = false,
  hidePrice = false,
  totalPrice,
  setOpenDetails,
  openDetails,
  currency,
  sizeFromProps = "",
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const canRender = useCanRender();
  const { hideWholesale } = useDisplayPrice();
  const hidePricePrivileges = isOrderItemRx(orderItem)
    ? !canRender("RX_MY_PRICE")
    : !canRender("WHOLESALE_PRICE");
  const breakpoints = useBreakpoint();

  // price display settings
  const currencyFormat = useSelector(selectCurrencyFormat);

  const renderCustomerRefButton = () => (
    <ButtonCartNoteContainer
      className={clsx(!orderItem.customerReference && "hide-customerReference")}
      id="customer-reference"
    >
      {!hideCustomerRef && (
        <ButtonCartNote
          type="light"
          disabled
          text={orderItem.customerReference ?? ""}
          onClick={() => {
            return;
          }}
        />
      )}
    </ButtonCartNoteContainer>
  );

  return (
    <TileContentMain>
      <div>
        <CustomText as="span" fontSizePx={12} lineHeightPx={18} marginBottomPx={8}>
          {translateLabel("SIZE")}
        </CustomText>
        <CustomText as="span" fontSizePx={12} lineHeightPx={18} font="font-bold">
          {sizeFromProps || getSizeLabel(orderItem.sku)}
        </CustomText>
      </div>

      <div>
        <CustomText as="span" fontSizePx={12} lineHeightPx={18} marginBottomPx={8}>
          {translateLabel("QUANTITY")}
        </CustomText>
        <CustomText as="span" fontSizePx={12} lineHeightPx={18} font="font-bold">
          {orderItem.quantity}
        </CustomText>
      </div>

      {!breakpoints["md"] && renderCustomerRefButton()}
      {!hideWholesale &&
        (hidePrice || hidePricePrivileges ? (
          <div />
        ) : (
          <PriceContainer className={clsx(hideWholesale && "hide")}>
            <CustomText as="span" fontSizePx={12} lineHeightPx={18} marginBottomPx={4}>
              {translateLabel("CART_TOTAL")}
            </CustomText>
            {!hideWholesale && (
              <CustomText as="span" fontSizePx={18} font="font-bold" color="primary">
                <CurrencyFormatter
                  currency={currency || currencyFormat.currency.opt}
                  locale={currencyFormat.locale}
                  value={totalPrice ?? orderItem.price.opt.orderItemPrice}
                />
              </CustomText>
            )}
          </PriceContainer>
        ))}

      <div className="print-hide">
        {!hideButton && (
          <IconButton
            onClick={() => setOpenDetails(!openDetails)}
            size="md"
            icon={<Arrow />}
            upsideDown={openDetails}
            type="white-with-border"
          />
        )}
      </div>
    </TileContentMain>
  );
};

const getSizeLabel = (sku: Sku | SparePartsSku) => {
  if (sku.productCategory === "afa") {
    const sizeFamily = getAttributeValues(sku?.attributes ?? [], "DL_SIZE_CODE")?.values;
    const sizeFamilyLabel = sizeFamily ? ` ${sizeFamily}` : "";
    return `${sizeFamilyLabel}`;
  }
  if (sku.productCategory === "helmet" || sku.productCategory === "goggle") {
    const sizeFamily = getAttributeValues(sku?.attributes ?? [], "G_H_SIZE_FAMILY")?.values;
    const sizeFamilyLabel = sizeFamily ? ` ${sizeFamily}` : "";
    return `${sizeFamilyLabel}`;
  }
  if (sku.productCategory === "accessory") {
    const sizeFamily = getAttributeValues(sku?.attributes ?? [], "ACC_SIZE_FAMILY")?.values;
    const sizeFamilyLabel = sizeFamily ? ` ${sizeFamily}` : "";
    return `${sizeFamilyLabel}`;
  }
  return sku?.size?.values?.[0]?.value;
};

const TileContentMain = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr 1fr 2.5rem;
  padding-top: 1.25rem;
  flex-grow: 1;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.hide {
    visibility: hidden;
  }
`;

const ButtonCartNoteContainer = styled.div`
  &.hide-customerReference {
    visibility: hidden;
  }
`;

export default TYPTileRow;
