import React from "react";
import styled from "styled-components/macro";
import useDataHref from "../../../hooks/useDataHref";
import CustomText from "../../styled-UI/CustomText";
import LinkButton from "../../styled-UI/LinkButton";
import SetInnerHTMLWithSanitize from "../SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  title?: string;
  titleColour?: string;
  description?: string;
  description1Colour?: string;
  linkString?: string;
  linkLabel?: string;
  linkLabelColour?: string;
  handleRedirect: () => void;
  "data-element-id"?: string;
  "data-description"?: string;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const TrendTileInfoSection = ({
  title,
  titleColour,
  description,
  description1Colour,
  linkString,
  linkLabel,
  linkLabelColour,
  handleRedirect,
  "data-element-id": dataElementId,
  "data-description": dataDescription,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const descrWithHref = useDataHref({ content: description });

  return (
    <Container
      {...(dataElementId ? { "data-element-id": dataElementId } : {})}
      {...(dataDescription ? { "data-description": dataDescription } : {})}
      isDarkMode={isDarkMode}
    >
      <TitleBox>
        <CustomText
          as="p"
          fontSizePx={24}
          font={customFont ? "custom-medium" : "font-medium"}
          fontFamily={customFont}
          customColor={titleColour}
          color={isDarkMode ? "white" : "primary"}
          lineHeightPx={24}
        >
          {title}
        </CustomText>
      </TitleBox>
      <TextBox>
        <CustomText
          as="span"
          fontSizePx={14}
          font={customFont ? "custom-regular" : "font-regular"}
          fontFamily={customFont}
          lineHeightPx={24}
          color={isDarkMode ? "white" : "primary"}
          customColor={description1Colour}
        >
          {description && (
            <SetInnerHTMLWithSanitize text={descrWithHref.updatedContent ?? description} />
          )}
        </CustomText>
      </TextBox>
      <ButtonBox>
        {linkString && (
          <LinkButton
            uppercase
            onClick={handleRedirect}
            customColor={linkLabelColour}
            color={isDarkMode ? "white" : "primary"}
            customFont={customFont}
          >
            {linkLabel}
          </LinkButton>
        )}
      </ButtonBox>
    </Container>
  );
};

const Container = styled.div<{ isDarkMode: boolean }>`
  height: 100%;
  padding-top: 2.5rem;
  padding-bottom: 1.875rem;
  border-top: solid 1px
    ${(props) => (props.isDarkMode ? props.theme.palette.white : props.theme.palette.primary)};
  border-bottom: solid 1px
    ${(props) => (props.isDarkMode ? props.theme.palette.white : props.theme.palette.primary)};
  box-sizing: border-box;
  position: relative;
  margin: 1px 0;
`;

const TextBox = styled.div`
  margin-right: 1.75rem;
  margin-left: 2rem;
`;

const TitleBox = styled.div`
  margin-right: 1.75rem;
  margin-left: 2rem;
  margin-bottom: 1.6875rem;
`;

const ButtonBox = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 0px;
`;

export default TrendTileInfoSection;
