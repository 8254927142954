import React from "react";
import LoaderElement from "../LoaderElement";

export const LoaderMostUsed = (): JSX.Element => {
  return (
    <>
      <LoaderElement height={48} width={200} radius={8} style="default" />
      <LoaderElement height={48} width={200} radius={8} style="default" />
      <LoaderElement height={48} width={200} radius={8} style="default" />
      <LoaderElement height={48} width={200} radius={8} style="default" />
      <LoaderElement height={48} width={200} radius={8} style="default" />
      <LoaderElement height={48} width={200} radius={8} style="default" />
      <LoaderElement height={48} width={200} radius={8} style="default" />
      <LoaderElement height={48} width={200} radius={8} style="default" />
      <LoaderElement height={48} width={200} radius={8} style="default" />
      <LoaderElement height={48} width={200} radius={8} style="default" />
      <LoaderElement height={48} width={200} radius={8} style="default" />
      <LoaderElement height={48} width={200} radius={8} style="default" />
    </>
  );
};
