import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { matchPath } from "react-router-dom";
import { CmsProductInfo } from "../components/widgets/EditorialProductTile";
import useCanRender from "../hooks/useCanRender";
import {
  CmsContent,
  CropType,
  Image,
  Moodboard,
  MultiMediaType,
  OpticianTipsColumn,
} from "../interfaces/cmsInterfaces";
import { Filters, FirstLevelMenu, SecondLevelMenu } from "../interfaces/menuInterfaces";
import { Product, Variant } from "../interfaces/productInterface";
import { authRoutes } from "../routing/authRoutes";
import { getPagePath } from "../routing/routesUtils";
import {
  Attachment,
  TutorialPill,
  TutorialPillPopup,
  TutorialPillTooltip,
} from "../store/catalogue/catalogueInterface";
import { QueryParams } from "../store/search/searchInterfaces";
import { selectIsBackOfficeUser } from "../store/user/userSlice";
import { mapProductObj, mapVariantObj } from "./productUtils";

export const getFirstImageFromMoodBoard = (moodboard: Moodboard): Image | undefined => {
  if (moodboard.bigImages.length > 0) return moodboard.bigImages[0];
  return undefined;
};

export const getMoodBoard = (result: any[]): Moodboard => {
  if (result && result.length > 0) {
    const smallImages: Image[] = [];
    const descriptions: string[] = [];
    const bigImages: Image[] = [];

    Object.keys(result[0]).map((key) => {
      const singleContent = result[0][key];
      switch (key) {
        case "closeup1Image":
        case "closeup2Image":
        case "closeup3Image":
          if (singleContent && singleContent.length > 0 && singleContent[0].data?.uri) {
            smallImages.push({ imageUrl: singleContent[0].data?.uri });
          }
          break;
        case "closeup1Text":
        case "closeup2Text":
        case "closeup3Text":
          if (singleContent) {
            descriptions.push(singleContent);
          }
          break;
        case "content1Image":
        case "content2Image":
        case "content3Image":
          if (singleContent && singleContent.length > 0 && singleContent[0].data?.uri) {
            bigImages.push({ imageUrl: singleContent[0].data?.uri });
          }
          break;
      }
    });

    return {
      title: result[0]?.title,
      title1: result[0]?.title1,
      smallImages,
      bigImages,
      descriptions,
      brand: result[0]?.brand[0],
    };
  }

  return {
    title: "",
    title1: "",
    smallImages: [],
    bigImages: [],
    descriptions: [],
    brand: null,
  };
};

interface GetMultiMediaReturn {
  srcImg?: string;
  srcVideo?: string;
  coverImg?: string;
  contentType?: string;
  placement: "inset" | "popup";
  variant?: "landscape" | "portrait";
  titleVideo?: string;
  cropUrlTemplate?: string;
  crops?: CropType[];
}

export const getMultiMedia = (multiMedia?: MultiMediaType[] | null): GetMultiMediaReturn => {
  const firstMultimedia = multiMedia?.[0];
  let srcVideo;
  let srcImg;
  let coverImg;
  let titleVideo;
  let placement: "inset" | "popup" = "inset";
  let contentType;
  const variant = multiMedia?.[0]?.variant ?? "landscape";
  const cropUrlTemplate = multiMedia?.[0]?.cropUrlTemplate;
  const crops = multiMedia?.[0]?.crops;

  if (firstMultimedia) {
    if (firstMultimedia.type === "CMVideo" || firstMultimedia.type === "CMDownload") {
      srcVideo = firstMultimedia?.data?.uri ? firstMultimedia?.data?.uri : firstMultimedia?.dataUrl;
      coverImg = multiMedia?.[0]?.picture?.data?.uri;
      placement = multiMedia?.[0]?.openInPopup ? "popup" : "inset";
      titleVideo = multiMedia?.[0]?.picture?.title;
      contentType = multiMedia?.[0]?.data?.contentType;
    } else {
      srcImg = firstMultimedia?.data?.uri;
    }
  }

  return {
    srcImg,
    srcVideo,
    coverImg,
    contentType,
    placement,
    variant,
    titleVideo,
    cropUrlTemplate,
    crops,
  };
};

interface GetMultiMediaCroppedReturn {
  cropUrl: string;
}

export const getMultiMediaCropped = (
  crops: CropType[] | undefined,
  cropUrlTemplate: string | undefined,
  cropNameToFilter: string | undefined,
  widthToFilter: number | undefined,
  fallback: string | undefined
): GetMultiMediaCroppedReturn => {
  let cropUrl = fallback ?? "";

  if (cropUrlTemplate && crops && cropNameToFilter) {
    const correctCrop = crops.filter((crop) => crop.name == cropNameToFilter);
    if (correctCrop && correctCrop[0]) {
      const width = widthToFilter
        ? correctCrop[0].sizes?.filter((crop) => crop.width == widthToFilter)[0]?.width
        : correctCrop[0].sizes[0]?.width;
      const cropName = correctCrop[0]?.name;
      if (width) {
        cropUrl = cropUrlTemplate
          .replace("{width}", width.toString())
          .replace("{cropName}", cropName);
      }
    }
  }

  return { cropUrl };
};

export const getAnalyticsTagId = (content: CmsContent, fallback?: string): string | undefined => {
  return content?.analyticsTag?.[0]?.externalReference ?? fallback;
};

export const getCategoryInfo = (
  menu: FirstLevelMenu[],
  externalReference: string,
  name?: string
): {
  categoryId: string;
  title: string;
  url?: string;
} | null => {
  let menuItem: SecondLevelMenu | undefined;
  let termsToCheck = externalReference.toLowerCase();

  const isKids =
    externalReference === "sunglasses-kids" ||
    externalReference === "eyeglasses-kids" ||
    externalReference === "children";

  const isNuanceAudio =
    externalReference === "Electronics Audio Optical" ||
    externalReference === "Electronics Audio Sun" ||
    externalReference === "Electronics Accessories" ||
    externalReference === "Dummy";

  if (externalReference === "sunglasses-kids") {
    termsToCheck = "sunglasses";
  }

  if (externalReference === "eyeglasses-kids") {
    termsToCheck = "eyeglasses";
  }

  if (externalReference === "adult" || externalReference === "children") {
    termsToCheck = "goggles_helmets";
  }

  if (isNuanceAudio) termsToCheck = "audio";

  /* get second level menu */
  menu?.forEach((_) => {
    if (_.identifier === "PRODUCTS") {
      menuItem = _.catalogGroupView?.find((item) => {
        if (item.identifier.toLocaleLowerCase() === termsToCheck) {
          if (
            termsToCheck === "sunglasses" ||
            termsToCheck === "eyeglasses" ||
            termsToCheck === "goggles_helmets" ||
            termsToCheck === "audio"
          ) {
            return item.filters?.find(
              (_) =>
                _.identifier?.toLowerCase() === externalReference?.toLowerCase().replace("-", " ")
            );
          } else return true;
        }
        return false;
      });
    }
  });

  const completeUrl = isKids ? menuItem?.completeUrlKids : menuItem?.completeUrlAdults;
  let menuName = "";
  let menuType = "";

  /* check if category is present in the menu */
  if (menuItem && menuItem.filters && menuItem.filters.length > 0) {
    menuItem.filters?.forEach((filter) => {
      if (
        filter.identifier &&
        filter.identifier?.toLowerCase() === externalReference?.replace("-", " ").toLowerCase()
      )
        menuName = filter.value ?? "";
      menuType = filter.type ?? "";
    });
  } else if (menuItem) {
    menuName = menuItem.name;
  }

  let url: string | undefined = completeUrl;
  if (isNuanceAudio && url && menuType) {
    const paramsNuanceAudio = new URLSearchParams();
    paramsNuanceAudio.append(menuType, menuName ?? "");

    if (paramsNuanceAudio && paramsNuanceAudio.toString().length > 0)
      url = url + "?" + paramsNuanceAudio;
  }

  /* return info */
  if (menuItem) {
    return {
      categoryId: menuItem.uniqueID,
      title: menuName ?? "",
      url: url,
    };
  }
  return null;
};

export const getFiltersFromMenu = (
  menu: FirstLevelMenu[],
  externalReference: string,
  getProductCategoryFilters?: boolean
): QueryParams => {
  const params: QueryParams = {};
  let menuItem: SecondLevelMenu | undefined = undefined;
  let termsToCheck = externalReference.toLowerCase();

  if (externalReference === "sunglasses-kids") {
    termsToCheck = "sunglasses";
  } else if (externalReference === "eyeglasses-kids") {
    termsToCheck = "eyeglasses";
  } else if (externalReference === "adult" || externalReference === "children") {
    termsToCheck = "goggles_helmets";
  } else if (
    externalReference === "Electronics Audio Optical" ||
    externalReference === "Electronics Audio Sun" ||
    externalReference === "Electronics Accessories" ||
    externalReference === "Dummy"
  ) {
    termsToCheck = "audio";
  }

  menu.forEach((_: FirstLevelMenu) => {
    if (_.identifier === "PRODUCTS") {
      menuItem = _.catalogGroupView?.find(
        (item: SecondLevelMenu) => item.identifier.toLocaleLowerCase() === termsToCheck
      );
    }
  });

  const filters = (menuItem as SecondLevelMenu | undefined)?.filters;
  if (filters) {
    filters.forEach((_: Filters) => {
      if (_.identifier?.toLowerCase() === externalReference?.replace("-", " ").toLowerCase()) {
        if (params[_.type as string]) {
          params[_.type as string].push(_.identifier as string);
        } else {
          params[_.type as string] = [_.identifier as string];
        }
      }
    });
  }

  //add product category filer for stars (ugly ugly)
  if (getProductCategoryFilters) {
    switch (termsToCheck) {
      case "accessories":
        params["PRODUCT_CATEGORY_FILTER"] = ["Eyewear Accessories"];
        break;
      case "goggles_helmets":
        params["PRODUCT_CATEGORY_FILTER"] = ["Goggles&Helmets"];
    }
  }

  return params;
};

export const getAdvContents = (result: any[]): MultiMediaType[] => {
  const advContents: MultiMediaType[] = [];
  if (result && result.length > 0) {
    for (let i = 0; i < result.length; i++) {
      const currentItem = result[i];
      if (currentItem.landscapeVideo && currentItem.landscapeVideo.length > 0) {
        advContents.push({
          ...currentItem.landscapeVideo[0],

          variant: "landscape",
        });
        continue;
      } else if (currentItem.landscapeFallbackVideoPath) {
        advContents.push({
          ...currentItem.landscapeFallbackVideoPath,
          variant: "landscape",
        });
        continue;
      } else if (
        currentItem.landscapeFallbackImageFile &&
        currentItem.landscapeFallbackImageFile.length > 0
      ) {
        currentItem.landscapeFallbackImageFile.forEach((_: any) => {
          advContents.push({
            ..._,
            variant: "landscape",
          });
        });

        continue;
      } else if (currentItem.landscapeFallbackImagePath) {
        advContents.push({
          ...currentItem.landscapeFallbackImagePath,
          variant: "landscape",
        });
        continue;
      } else if (currentItem.portraitVideo && currentItem.portraitVideo.length > 0) {
        advContents.push({
          ...currentItem.portraitVideo[0],
          variant: "portrait",
        });
        continue;
      } else if (currentItem.portraitFallbackVideoPath) {
        advContents.push({
          ...currentItem.portraitFallbackVideoPath,
          variant: "portrait",
        });
        continue;
      } else if (
        currentItem.portraitFallbackImageFile &&
        currentItem.portraitFallbackImageFile.length > 0
      ) {
        currentItem.portraitFallbackImageFile.forEach((_: any) => {
          advContents.push({
            ..._,
            variant: "portrait",
          });
        });
        continue;
      } else if (currentItem.portraitFallbackImagePath) {
        advContents.push({
          ...currentItem.portraitFallbackImagePath,
          variant: "portrait",
        });
        continue;
      }
    }
  }

  return advContents;
};

/**
 * Get Optician tips info from cms
 *
 * @param {any[]} result
 * @return {*}  {OpticianTipsColumn[]}
 */
export const getOpticianTips = (result: any[]): OpticianTipsColumn[] => {
  if (result && result.length > 0) {
    const opticianTipsFrontal: OpticianTipsColumn = {
      type: "Frontal",
      title: "OPTICIAN_TIPS_FRONTAL",
      tips: [result[0].frontal1, result[0].frontal2, result[0].frontal3],
      hasValues: result[0].frontal1 || result[0].frontal2 || result[0].frontal3,
    };

    const opticianTipsTemple: OpticianTipsColumn = {
      type: "Temple",
      title: "OPTICIAN_TIPS_TEMPLE",
      tips: [result[0].temple1, result[0].temple2, result[0].temple3],
      hasValues: result[0].temple1 || result[0].temple2 || result[0].temple3,
    };

    const opticianTipsOthers: OpticianTipsColumn = {
      type: "Other",
      title: "OPTICIAN_TIPS_OTHERS",
      tips: [result[0].others1, result[0].others2, result[0].others3],
      hasValues: result[0].others1 || result[0].others2 || result[0].others3,
    };

    return [opticianTipsFrontal, opticianTipsTemple, opticianTipsOthers];
  }
  return [];
};

export const enrichModelAndVariantInfo = (
  singleContent: any
): { singleContent: any; priceIds: string[] } => {
  const { model, modelColor } = singleContent;
  const priceIds: string[] = [];

  //check if model is present
  if (model && Array.isArray(model) && model.length > 0 && model[0].productDetails) {
    const productDetails = model[0].productDetails;
    if (productDetails.uniqueID) priceIds.push(productDetails.uniqueID);
    singleContent = {
      ...singleContent,
      model: mapProductObj(productDetails),
      inAssortment: model[0].inAssortment,
    };
  }

  //check if variant is present
  if (
    modelColor &&
    Array.isArray(modelColor) &&
    modelColor.length > 0 &&
    modelColor[0].productDetails
  ) {
    const productDetails = modelColor[0].productDetails;
    if (productDetails.uniqueID) priceIds.push(productDetails.uniqueID);
    singleContent = {
      ...singleContent,
      modelColor: mapVariantObj(productDetails),
      inAssortment: modelColor[0].inAssortment,
    };
  }
  //else save as-is
  return { singleContent, priceIds };
};

export const getInfoFromModelOrVariant = (
  model: Product | undefined,
  variant: Variant | undefined
): CmsProductInfo => {
  const info: CmsProductInfo = {};
  if (model) {
    info.image = model.img;
    info.brand = model.brand;
    info.name = model.name;
    info.productCode = model.productCode;
    info.price = model.price;
    info.seo = model.seo;
    info.attachments = model.attachments;
  } else if (variant) {
    info.image = variant.img;
    info.brand = variant.brand;
    info.name = variant.name;
    info.productCode = variant.variantCodeNoUnderscore;
    info.price = variant.price;
    info.seo = variant.seo;
    info.attachments = variant.attachments;
  }

  return info;
};

export const getImageByLayoutType = (
  attachments?: Attachment[],
  layout?: string
): Attachment | null => {
  if (attachments && layout) {
    let attachment = null;
    switch (layout) {
      case "Lp-editorial-product-tile-1":
        attachment = attachments.find((_) => _.identifier.includes("cfr."));
        if (!attachment && attachments && attachments[0]) {
          attachment = attachments[0];
        }
        break;
      case "Lp-editorial-product-tile-2":
        attachment = attachments.find((_) => _.identifier.includes("lt."));
        if (!attachment && attachments && attachments[0]) {
          attachment = attachments[0];
        }
        break;
      case "Lp-editorial-product-tile-3":
        attachment = attachments.find((_) => _.identifier.includes("qt."));
        if (!attachment && attachments && attachments[0]) {
          attachment = attachments[0];
        }
        break;
      case "Lp-editorial-product-tile-4":
        attachment = attachments.find((_) => _.identifier.includes("al1."));
        if (!attachment && attachments && attachments[0]) {
          attachment = attachments[0];
        }
        break;
      default:
        attachment = attachments.find((_) => _.identifier.includes("030A"));
        if (!attachment && attachments && attachments[0]) {
          attachment = attachments[0];
        }
        break;
    }

    return attachment ? attachment : null;
  }

  return null;
};

//hook to handle links in cms contents
export const useHandleCmsLink = (): {
  handleCmsLink: (url?: string, externalLink?: boolean, openInNewTab?: boolean) => void;
} => {
  const history = useHistory();

  const handleCmsLink = (url?: string, externalLink?: boolean, openInNewTab?: boolean) => {
    if (url) {
      let finalLink = url;
      if (!externalLink) {
        finalLink = getPagePath(url);
      }

      openInNewTab ? window.open(finalLink, "_blank") : history.push(finalLink);
    }
  };

  return {
    handleCmsLink,
  };
};

export const showTileCms = (stickyBarType: string, tile: Product | Variant): boolean => {
  let showTile = true;
  switch (stickyBarType) {
    case "ALL":
      break;
    case "SUNGLASSES":
      if (!tile?.productCategoryFilter?.valuesIdentifier?.includes("Sunglasses")) showTile = false;
      break;
    case "EYEGLASSES":
      if (!tile?.productCategoryFilter?.valuesIdentifier?.includes("Eyeglasses")) showTile = false;
      break;
  }

  return showTile;
};

export const useCanUrlBeRendered = (): {
  canUrlBeRendered: (url: string) => boolean;
} => {
  const isBackOfficeUser = useSelector(selectIsBackOfficeUser);
  const canRender = useCanRender();

  const canUrlBeRendered = (url: string) => {
    // Find route that match the linkString
    const route = authRoutes.find(({ path }) => matchPath(url, { path: path, exact: true }));
    if (route) {
      // if route should not be shown to BO users, and it is one, it takes precedence over current privileges
      if (route.isNotBOUser && isBackOfficeUser) return false;
      // if a privilege is specified, return canRenderFunction result
      if (route.privilege) return canRender(route.privilege, null, route.onlySubuser);
    }
    return true; // all other cases can be rendered, if privilege is not specified, render
  };

  return {
    canUrlBeRendered,
  };
};
export const MARKETING_TYPES = [
  "marketingCommunications",
  "trainingCommunications",
  "commercialCommunications",
  "partnershipCommunications",
  "digitalCommunications",
  "eventsCommunications",
  "brandProductCommunications",
  "generalCommunications",
] as const;

export type MarketingTypes = typeof MARKETING_TYPES[number];

type MarketingConfig = {
  [key in MarketingTypes]: {
    value: string;
    label: string;
  };
};

export const MARKETING_PREFERENCES: MarketingConfig = {
  marketingCommunications: {
    value: "marketingCommunications",
    label: "MARKETING_COMMUNICATIONS",
  },
  trainingCommunications: {
    value: "trainingCommunications",
    label: "TRAINING_COMMUNICATIONS",
  },
  commercialCommunications: {
    value: "commercialCommunications",
    label: "COMMERCIAL_COMMUNICATIONS",
  },
  partnershipCommunications: {
    value: "partnershipCommunications",
    label: "PARTNERSHIP_COMMUNICATIONS",
  },
  digitalCommunications: {
    value: "digitalCommunications",
    label: "DIGITAL_COMMUNICATIONS",
  },
  eventsCommunications: {
    value: "eventsCommunications",
    label: "EVENTS_COMMUNICATIONS",
  },
  brandProductCommunications: {
    value: "brandProductCommunications",
    label: "BRAND_PRODUCTS_COMMUNICATIONS",
  },
  generalCommunications: {
    value: "generalCommunications",
    label: "GENERAL_COMMUNICATIONS",
  },
};

/**
 * Map tutorial pills items
 *
 * @param {any[]} items
 * @return {*}  {TutorialPill[]}
 */
export const mapTutorialPills = (items: any[]): TutorialPill[] => {
  return items?.map((_) => {
    return {
      id: _?.id,
      externalReference: _?.subjectTaxonomy.find((item: any) => {
        return item.externalReference !== "tutorial-pills";
      })?.externalReference,
      layoutVariant: _?.layoutVariant?.[0]?.name,
    };
  });
};

/**
 * Map tooltip items for tutorial pills
 *
 * @param {any[]} item
 * @return {*}  {TutorialPillTooltip}
 */
export const mapTooltip = (item: any): TutorialPillTooltip => {
  return {
    title: item?.title,
    description: item?.description,
    linkString: item?.linkString,
    linkLabel: item?.linkLabel,
    openInNewTab: item?.openInNewTab,
    externalLink: item?.externalLink,
  };
};

/**
 * Map popup items for tutorial pills
 *
 * @param {any[]} item
 * @return {*}  {TutorialPillPopup}
 */
export const mapPopup = (item: any): TutorialPillPopup => {
  return {
    title: item?.title,
    description: item?.description,
    linkString: item?.linkString,
    linkLabel: item?.linkLabel,
    openInNewTab: item?.openInNewTab,
    externalLink: item?.externalLink,
    srcImg: item?.multiMediaType?.[0]?.data?.uri,
  };
};

export interface ExternalReferenceObject {
  [key: string]: ExternalReference;
}

export type ExternalReference =
  | "menu.links.eyemed"
  | "menu.links.leonardo"
  | "menu.links.essilorpro"
  | "menu.sunglasses.categories"
  | "menu.sunglasses.brands"
  | "menu.sunglasses.children"
  | "menu.eyeglasses.categories"
  | "menu.eyeglasses.brands"
  | "menu.eyeglasses.children"
  | "menu.smartglasses.brands"
  | "menu.instruments.instruments"
  | "menu.gogglesAndHelmets.goggles"
  | "menu.gogglesAndHelmets.helmets"
  | "menu.lenses.categories"
  | "menu.lenses.brands"
  | "menu.lenses.actions"
  | "menu.accessories.accessories"
  | "menu.accessories.components"
  | "menu.accessories.other"
  | "menu.accessories.brands"
  | "menu.services.programsRewards"
  | "menu.services.aftersales"
  | "menu.services.other"
  | "menu.services.digitalApplication"
  | "menu.services.adt"
  | "account.menu.account"
  | "account.menu.messages"
  | "account.menu.orders"
  | "account.menu.aftersales"
  | "account.menu.accounting"
  | "account.menu.adt"
  | "account.menu.documents"
  | "account.menu.contact"
  | "account.menu.programRewards"
  | "account.menu.smartDashboard"
  | "homepage.bestSellers"
  | "cart.summary.completeCart"
  | "cart.summary"
  | "pages.plp.filterBy"
  | "pages.plp.displayPrices"
  | "pages.preplp.displayPrices"
  | "pages.homepage.displayPrices"
  | "pages.pdp.displayPrices"
  | "pages.cart.displayPrices"
  | "pages.plp.sorting"
  | "pages.pdp.variantFilters"
  | "pages.pdp.productDetails"
  | "pages.pdp.similarProducts"
  | "pages.pdp.compatibleWith"
  | "pages.pdp.buttons.dimensions"
  | "pages.pdp.buttons.explosionVideo"
  | "pages.pdp.buttons.seeLensEffect"
  | "pages.pdp.buttons.instagram"
  | "pages.pdp.buttons.360view"
  | "pages.pdp.buttons.rtr"
  | "account.page.notification"
  | "account.page.orderHistory.filters"
  | "account.page.orderHistory.results"
  | "account.page.orderDetails"
  | "account.page.orderDetails.repeatOrder"
  | "account.page.subusers"
  | "account.page.subusers.new";

export const externalReferenceNewTag: ExternalReferenceObject = {
  INSURANCE: "menu.links.eyemed",
  LEONARDO_SECTION: "menu.links.leonardo",
  ESSILOR_PRO: "menu.links.essilorpro",
  "Sunglasses.MENU_CATEGORY": "menu.sunglasses.categories",
  "Sunglasses.MENU_BRAND": "menu.sunglasses.brands",
  "Sunglasses.MENU_BRAND_KIDS": "menu.sunglasses.children",
  "Eyeglasses.MENU_CATEGORY": "menu.eyeglasses.categories",
  "Eyeglasses.MENU_BRAND": "menu.eyeglasses.brands",
  "Eyeglasses.MENU_BRAND_KIDS": "menu.eyeglasses.children",
  "Smart Glasses.MENU_BRAND": "menu.smartglasses.brands",
  Instruments: "menu.instruments.instruments",
  "GOGGLES_HELMETS.MENU_GOGGLES": "menu.gogglesAndHelmets.goggles",
  "GOGGLES_HELMETS.MENU_HELMETS": "menu.gogglesAndHelmets.helmets",
  "Lenses.CATEGORIES": "menu.lenses.categories",
  "Lenses.BRAND": "menu.lenses.brands",
  "ORDER_NOW.ORDER_NOW": "menu.lenses.actions",
  "ACCESSORIES.MENU_REPLACEMENT": "menu.accessories.accessories",
  "ACCESSORIES.MENU_FRAME_COMPONENTS": "menu.accessories.components",
  "ACCESSORIES.MENU_OTHER_ACCESSORIES": "menu.accessories.other",
  "ACCESSORIES.MENU_BRAND": "menu.accessories.brands",
  PROGRAMS_AND_REWARDS_SECTION: "menu.services.programsRewards",
  AFTERSALES_SECTION: "menu.services.aftersales",
  SERVICES_SECTION: "menu.services.other",
  DIGITAL_APPLICATION_SECTION: "menu.services.digitalApplication",
  ASSET_DOWNLOAD_SECTION: "menu.services.adt",
  "Account.ACCOUNT_SECTION": "account.menu.account",
  "Account.MESSAGES_SECTION": "account.menu.messages",
  "Account.ORDERS_SECTION": "account.menu.orders",
  "Account.AFTERSALES_SECTION": "account.menu.aftersales",
  "Account.ACCOUNTING_SECTION": "account.menu.accounting",
  "Account.ASSETS_SECTION": "account.menu.adt",
  "Account.DOCUMENTS_SECTION": "account.menu.documents",
  "Account.CONTACT_SECTION": "account.menu.contact",
  "Account.PROGRAMS_AND_REWARDS_SECTION": "account.menu.programRewards",
  "Account.SMART_DASHBOARD_SECTION": "account.menu.smartDashboard",
};

export const externalReferenceTooltip: ExternalReferenceObject = {
  BEST_SELLERS: "homepage.bestSellers",
  CART_SUMMARY_COMPLETE_CART: "cart.summary.completeCart",
  CART_SUMMARY: "cart.summary",
  PLP_FILTER_BY: "pages.plp.filterBy",
  PLP_DISPLAY_PRICES: "pages.plp.displayPrices",
  PREPLP_DISPLAY_PRICES: "pages.preplp.displayPrices",
  HOMEPAGE_DISPLAY_PRICES: "pages.homepage.displayPrices",
  PDP_DISPLAY_PRICES: "pages.pdp.displayPrices",
  CART_DISPLAY_PRICES: "pages.cart.displayPrices",
  PLP_SORTING: "pages.plp.sorting",
  PDP_VARIANT: "pages.pdp.variantFilters",
  PDP_PRODUCT_DETAILS: "pages.pdp.productDetails",
  PDP_SIMILAR_PRODUCTS: "pages.pdp.similarProducts",
  PDP_COMPATIBLE_WITH: "pages.pdp.compatibleWith",
  PDP_BUTTONS_DIMENSIONS: "pages.pdp.buttons.dimensions",
  PDP_BUTTONS_EXPLOSION_VIDEO: "pages.pdp.buttons.explosionVideo",
  PDP_BUTTONS_SEE_LENS_EFFECT: "pages.pdp.buttons.seeLensEffect",
  PDP_BUTTONS_INSTAGRAM: "pages.pdp.buttons.instagram",
  PDP_BUTTONS_360VIEW: "pages.pdp.buttons.360view",
  PDP_BUTTONS_RTR: "pages.pdp.buttons.rtr",
  ACCOUNT_PAGE_NOTIFICATION: "account.page.notification",
  ACCOUNT_PAGE_ORDER_HISTORY_FILTERS: "account.page.orderHistory.filters",
  ACCOUNT_PAGE_ORDER_HISTORY_RESULTS: "account.page.orderHistory.results",
  ACCOUNT_PAGE_ORDER_DETAILS: "account.page.orderDetails",
  ACCOUNT_PAGE_ORDER_DETAILS_REPEAT_ORDER: "account.page.orderDetails.repeatOrder",
  ACCOUNT_PAGE_SUBUSERS: "account.page.subusers",
  ACCOUNT_PAGE_SUBUSERS_NEW: "account.page.subusers.new",
};
