import React from "react";
import styled from "styled-components/macro";
import WrapperLayout from "../../layouts/WrapperLayout";

interface Props {
  firstColumn: JSX.Element;
  secondColumn: JSX.Element;
  thirdColumn: JSX.Element;
  forthColumn: JSX.Element;
  marginPx?: number | null;
  landingNavbar?: boolean;
  isWrappedLanding?: boolean;
}

const FourColumnRow = ({
  firstColumn,
  secondColumn,
  thirdColumn,
  forthColumn,
  marginPx,
  landingNavbar = false,
  isWrappedLanding = false,
}: Props): JSX.Element => {
  return (
    <WrapperLayout landingNavbar={landingNavbar} isWrappedLanding={isWrappedLanding}>
      <FourColumn marginPx={marginPx}>
        <div>{firstColumn}</div>
        <div>{secondColumn}</div>
        <div>{thirdColumn}</div>
        <div>{forthColumn}</div>
      </FourColumn>
    </WrapperLayout>
  );
};

const FourColumn = styled.div<{ marginPx?: number | null }>`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 0.625rem));
  grid-column-gap: 0.938rem;
  margin-bottom: 2.5rem;

  //override from props
  margin-top: ${(props) => props.marginPx && `${props.marginPx / props.theme.baseFontSize}rem`};
  margin-bottom: ${(props) => props.marginPx && `${props.marginPx / props.theme.baseFontSize}rem`};
`;

export default FourColumnRow;
