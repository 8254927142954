import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useCanRender from "../../../hooks/useCanRender";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import useTranslation from "../../../hooks/useTranslation";
import { getCourses } from "../../../store/leonardo/leonardoSagas";
import { selectGetCourses, selectGetCoursesStatus } from "../../../store/leonardo/leonardoSlice";
import { selectIsBackOfficeUser } from "../../../store/user/userSlice";
import CustomText from "../../styled-UI/CustomText";
import LoaderHomeTile from "../../styled-UI/loader/home-page/LoaderHomeTile";
import ConditionalRender from "../ConditionalRender";
import LeonardoTile from "./LeonardoTile";

const LeonardoSection = (): JSX.Element => {
  const canRender = useCanRender();
  const dispatch = useDispatch();
  const { translateLabel } = useTranslation();

  const getCoursesStatus = useSelector(selectGetCoursesStatus);
  const isBackOfficeUser = useSelector(selectIsBackOfficeUser);
  const data = useSelector(selectGetCourses);

  const [isCoursesLoaded, setIsCoursesLoaded] = useState<boolean>(false);

  const { containerRef, isVisible: isCoursesVisible } = useIntersectionObserver<HTMLDivElement>({
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  useEffect(() => {
    if (!isCoursesLoaded && isCoursesVisible) setIsCoursesLoaded(true);
  }, [isCoursesVisible]);

  useEffect(() => {
    if (canRender("LEONARDO_SECTION") && !data && isCoursesLoaded && !isBackOfficeUser)
      dispatch(getCourses());
  }, [isCoursesLoaded]);

  return (
    <ConditionalRender privilege="LEONARDO_SECTION" isNotBOUser>
      <div data-element-id="Training" ref={containerRef}>
        {getCoursesStatus === "LOADING" && (
          <div>
            <LoaderHomeTile />
          </div>
        )}
        {data?.items?.length && (
          <>
            <OuterTitle>
              <CustomText as="h1" fontSizePx={50} font="font-regular" color="primary">
                {translateLabel("LEONARDO_CAROUSEL_TITLE")}
              </CustomText>
            </OuterTitle>

            <WrapperTile>
              {data?.items?.map((course, index) => {
                const tileNumber = index + 1;
                return (
                  <LeonardoTile
                    data-element-id={`Training_Tiles_Tile${tileNumber}`}
                    key={course.title}
                    course={course}
                  />
                );
              })}
            </WrapperTile>
          </>
        )}
      </div>
    </ConditionalRender>
  );
};

const WrapperTile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1.25rem;
  grid-template-rows: 29.5rem;
  margin-bottom: 6.438rem;
`;

const OuterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4.875rem;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  margin-top: 7.375rem;
`;

export default LeonardoSection;
