import clsx from "clsx";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";
import { getPagePath } from "../../routing/routesUtils";
import CustomText from "./CustomText";
import LinkButton from "./LinkButton";

type BannerProps = {
  text: string;
  color: "blue" | "gray";
  selectedDoors: number;
  totalDoors: number;
  hideBannerAnim?: boolean;
  id?: string;
};

const Banner = ({
  text,
  color,
  selectedDoors,
  totalDoors,
  hideBannerAnim,
  id,
}: BannerProps): JSX.Element => {
  const history = useHistory();
  const { translateLabel } = useTranslation();

  return (
    <StyledBanner
      className={clsx(color === "blue" && "blue-banner", hideBannerAnim && "hide")}
      id={id}
    >
      <CustomText as="p" font="font-bold" fontSizePx={13} lineHeightPx={18} color="white">
        {text}
      </CustomText>
      <RightSection>
        <CustomText
          as="p"
          font="font-bold"
          fontSizePx={13}
          lineHeightPx={18}
          color="white"
          marginRightPx={8}
        >
          {translateLabel("BANNER_SELECTED_DOORS")}
        </CustomText>
        <CustomText
          as="p"
          font="font-bold"
          fontSizePx={13}
          lineHeightPx={18}
          color="white"
          marginRightPx={32}
        >
          {selectedDoors} / {totalDoors}
        </CustomText>
        <div>
          <LinkButton
            uppercase
            color="white"
            onClick={() => {
              history.push(getPagePath("/account/multidoor"));
            }}
          >
            {translateLabel("BANNER_EDIT_DOORS")}
          </LinkButton>
        </div>
      </RightSection>
    </StyledBanner>
  );
};

const StyledBanner = styled.div`
  position: fixed;
  z-index: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background-color: ${(props) => props.theme.palette.gray.dark};
  width: 100%;
  transition: top 0.3s;
  height: ${(props) => props.theme.multidoorBannerHeightRem + "rem"};

  &.blue-banner {
    background-color: ${(props) => props.theme.palette.secondary};
  }

  &.hide {
    top: -${(props) => props.theme.multidoorBannerHeightRem + "rem"};
  }

  @media print {
    position: relative;
  }
`;

const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default Banner;
