import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { uniq } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { base64toBlob, downloadBlobFile } from "../../utils/utils";
import { getProductAvailability } from "../catalogue/catalogueSaga";

import checkoutServices from "../checkout/checkoutServices";

import { handleError } from "../store/storeSagas";
import { selectStoreId } from "../store/storeSlice";
import { UploadedFile } from "../warranty-wizard/warrantyWizardInterface";
import {
  CompletedOrder,
  CompletedOrderProduct,
  EditProductPayload,
  MassiveOrderCheckoutPayload,
  OrderAddressInfo,
  PostMassiveOrderPayload,
  SuborderUpdatePayload,
  UploadedOrder,
} from "./orderUploadInterfaces";
import orderUploadService from "./orderUploadService";
import {
  saveCustomerAddressInfo,
  saveMassiveOrders,
  saveMassiveOrderTemplate,
  saveUploadedProducts,
  selectMassiveOrders,
  selectMassiveOrderTemplate,
  setCompletedOrder,
  setCustomerAddressStatus,
  setDownloadMassiveOrderTemplateStatus,
  setEditProductStatus,
  setGetProductsErrorStatus,
  setMassiveOrderCheckoutStatus,
  setMassiveOrderPricesStatus,
  setMassiveOrdersStatus,
  setPostMassiveOrderStatus,
  setUploadMassiveOrderStatus,
  sliceName,
} from "./orderUploadSlice";

/* ACTIONS */
export const getMassiveOrders = createAction(sliceName + "/getMassiveOrders");
export const deleteMassiveOrder = createAction<string>(sliceName + "/deleteMassiveOrder");
export const downloadMassiveOrderTemplate = createAction(
  sliceName + "/downloadMassiveOrderTemplate"
);
export const uploadMassiveOrder = createAction<UploadedFile[]>(sliceName + "/uploadMassiveOrder");
export const getProductsError = createAction<string>(sliceName + "/getProductsError");
export const editProduct = createAction<EditProductPayload>(sliceName + "/editProduct");
export const postMassiveOrderComplete = createAction<PostMassiveOrderPayload>(
  sliceName + "/postMassiveOrderComplete"
);
export const massiveOrderCheckout = createAction<MassiveOrderCheckoutPayload>(
  sliceName + "/massiveOrderCheckout"
);
export const getAddressByAddressId = createAction<(string | undefined)[]>(
  sliceName + "/getAddressByAddressId"
);

/* SAGAS */
function* getMassiveOrdersSaga(): SagaIterator {
  try {
    yield put(setMassiveOrdersStatus("LOADING"));
    const { data } = yield call(orderUploadService.getMassiveOrders);

    yield put(saveMassiveOrders(data.data.massiveOrders));
    yield put(setMassiveOrdersStatus("SUCCESS"));
  } catch (error) {
    yield put(setMassiveOrdersStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* deleteMassiveOrderSaga({ payload }: PayloadAction<string>): SagaIterator {
  try {
    yield put(setMassiveOrdersStatus("LOADING"));

    const { data } = yield call(orderUploadService.deleteMassiveOrder, payload);
    const deletedItemId = data.data.id;
    if (deletedItemId) {
      const massiveOrders = yield select(selectMassiveOrders);
      const updatedMassiveOrder = massiveOrders.filter(
        (order: UploadedOrder) => order.id !== deletedItemId
      );

      yield put(saveMassiveOrders(updatedMassiveOrder));
    }
    yield put(setMassiveOrdersStatus("SUCCESS"));
  } catch (error) {
    yield put(setMassiveOrdersStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* downloadMassiveOrderTemplateSaga(): SagaIterator {
  try {
    yield put(setDownloadMassiveOrderTemplateStatus("LOADING"));
    let templateFile = yield select(selectMassiveOrderTemplate);

    if (!templateFile) {
      const res = yield call(orderUploadService.downloadMassiveOrderTemplate);
      yield put(saveMassiveOrderTemplate(res.data));
      templateFile = res.data;
    }
    yield put(setDownloadMassiveOrderTemplateStatus("SUCCESS"));
    downloadBlobFile(templateFile, "massive-order-template", "xlsx");
  } catch (error) {
    yield put(setDownloadMassiveOrderTemplateStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* uploadMassiveOrderSaga({ payload }: PayloadAction<UploadedFile[]>): SagaIterator {
  try {
    yield put(setUploadMassiveOrderStatus("LOADING"));
    const storeId = yield select(selectStoreId);
    let res;

    for (let i = 0; i < payload.length; i++) {
      const formData = new FormData();
      formData.append(
        "file",
        base64toBlob(payload[i]?.base64 as string, payload[i]?.file?.type),
        payload[i]?.file?.name
      );
      const tokenRes = yield call(orderUploadService.getUploadToken, storeId);
      res = yield call(
        orderUploadService.uploadMassiveOrder,
        storeId,
        formData,
        tokenRes.data.data.jwt,
        payload[i]?.file?.name ?? "ND"
      );
    }

    yield put(getMassiveOrders());
    yield put(setUploadMassiveOrderStatus(res.data.maxRowsExcedded ? "WARNING" : "SUCCESS"));
  } catch (error) {
    yield put(setUploadMassiveOrderStatus("ERROR"));
    yield put(handleError(error));
    console.log(error);
  }
}

function* getProductsErrorSaga({ payload }: PayloadAction<string>): SagaIterator {
  try {
    yield put(setGetProductsErrorStatus("LOADING"));
    const { data } = yield call(orderUploadService.getProductsError, payload);

    yield put(saveUploadedProducts(data.data));
    yield put(setGetProductsErrorStatus("SUCCESS"));
  } catch (error) {
    yield put(setGetProductsErrorStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* editProductSaga({ payload }: PayloadAction<EditProductPayload>): SagaIterator {
  try {
    yield put(setEditProductStatus("LOADING"));
    yield put(setGetProductsErrorStatus("LOADING"));
    const { data } = yield call(orderUploadService.editProduct, payload);

    yield put(saveUploadedProducts(data.data));
    yield put(setEditProductStatus("SUCCESS"));
  } catch (error) {
    yield put(setEditProductStatus("ERROR"));
    yield put(handleError(error));
  } finally {
    yield put(setGetProductsErrorStatus("SUCCESS"));
  }
}

function* postMassiveOrderCompleteSaga({
  payload,
}: PayloadAction<PostMassiveOrderPayload>): SagaIterator {
  try {
    yield put(setPostMassiveOrderStatus("LOADING"));
    const { data } = yield call(orderUploadService.postMassiveOrderComplete, payload.uploadId);
    const completedOrder: CompletedOrder = data.data;

    let productsCont = 0;
    completedOrder.subOrders.forEach((order) => {
      productsCont += order.importedProducts?.length ?? 0;
    });

    const smooth = productsCont < 50;

    payload.callback(smooth);
    yield put(setCompletedOrder(completedOrder));
    yield put(setPostMassiveOrderStatus("SUCCESS"));

    // avalability
    const productIds: string[] = []; // collect ids
    (data.data as CompletedOrder).subOrders.map((subOrder) =>
      subOrder.importedProducts?.forEach((product: CompletedOrderProduct) => {
        product.catentryId && productIds.push(product.catentryId);
      })
    );

    yield put(getProductAvailability({ ids: uniq(productIds), numIds: true }));
  } catch (error) {
    yield put(setPostMassiveOrderStatus("ERROR"));
    yield put(setMassiveOrderPricesStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* getAddressbyAddressIdSaga({
  payload,
}: PayloadAction<(string | undefined)[]>): SagaIterator {
  try {
    yield put(setCustomerAddressStatus("LOADING"));
    const addressMap: { [x: string]: OrderAddressInfo } = {};
    for (let i = 0; i < payload.length; i++) {
      if (payload[i]) {
        const { data } = yield call(orderUploadService.getAdrressbyAddressId, payload[i] as string);
        addressMap[payload[i] as string] = data.data;
      }
    }
    yield put(saveCustomerAddressInfo(addressMap));
    yield put(setCustomerAddressStatus("SUCCESS"));
  } catch (error) {
    yield put(setCustomerAddressStatus("ERROR"));
    yield put(handleError(error));
  }
}

// edit massage and poNumber
function* massiveOrderCheckoutSaga({
  payload,
}: PayloadAction<MassiveOrderCheckoutPayload>): SagaIterator {
  try {
    yield put(setMassiveOrderCheckoutStatus("LOADING"));
    for (const suborder of payload.suborders) {
      if (suborder.customerServiceNote || suborder.purchaseOrderNumber) {
        const updatePayload: SuborderUpdatePayload = { orderId: payload.orderId };
        if (suborder.customerServiceNote) {
          updatePayload.customerServiceNote = suborder.customerServiceNote;
        }
        if (suborder.purchaseOrderNumber) {
          updatePayload.purchaseOrderNumber = suborder.purchaseOrderNumber;
        }
        yield call(orderUploadService.updatePrecart, {
          subOrderId: suborder.subOrderId,
          payload: updatePayload,
        });
      }
    }

    yield call(checkoutServices.postCheckout, {
      orderId: payload.orderId,
      orderItem: [],
    });

    payload.callback();

    yield put(setMassiveOrderCheckoutStatus("SUCCESS"));
  } catch (error) {
    yield put(setMassiveOrderCheckoutStatus("ERROR"));
    yield put(handleError(error));
  }
}

export function* orderUploadSaga(): SagaIterator {
  yield takeEvery(getMassiveOrders.type, getMassiveOrdersSaga);
  yield takeEvery(deleteMassiveOrder.type, deleteMassiveOrderSaga);
  yield takeEvery(downloadMassiveOrderTemplate.type, downloadMassiveOrderTemplateSaga);
  yield takeEvery(uploadMassiveOrder.type, uploadMassiveOrderSaga);
  yield takeEvery(getProductsError.type, getProductsErrorSaga);
  yield takeEvery(editProduct.type, editProductSaga);
  yield takeEvery(postMassiveOrderComplete.type, postMassiveOrderCompleteSaga);
  yield takeEvery(getAddressByAddressId.type, getAddressbyAddressIdSaga);
  yield takeEvery(massiveOrderCheckout.type, massiveOrderCheckoutSaga);
}
