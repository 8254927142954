import { AxiosResponse } from "axios";
import { appendFiltersToURLSearchParams } from "../../utils/filterUtils";
import { createAndExecuteService } from "../serviceUtils";
import { QueryParamsWithString } from "../../store/search/searchInterfaces";

import {
  WarrantyWizardParameters,
  SubmitWarrantyDamagesPayload,
  GetAlternativeModelServicePayload,
  WarrantyWizardProductParameters,
  SubmitNotesPayload,
  WarrantyToCRMPayload,
  SubmitWarrantySelectAlternativeActionPayload,
  GetWarrantyDamagesPayload,
  SubmitMoreInformationStepPayload,
  GetWarrantyModelDetailsPayload,
  GetAlternativeVariantServicePayload,
  WarrantyWizardDeliveryAddressPayload,
} from "./warrantyWizardInterface";
import {
  warrantyDamagesUrl,
  warrantyDetailsUrl,
  warrantyAlternativeModelUrl,
  warrantyNotesStepUrl,
  warrantyRecapUrl,
  warrantyShippingDetailsUrl,
  warrantyToCRMUrl,
  warrantySelectAlternativeActionUrl,
  warrantyModelDetailsUrl,
  warrantyInformationStepUrl,
  warrantySendInformationStepUrl,
  warrantyAlternativeWizardUrl,
  warrantyAlternativeVariantUrl,
} from "./warrantyWizardUrls";

export default {
  // get details regarding the product selected for the warranty request
  getWarrantyDetails: async (payload: WarrantyWizardProductParameters): Promise<AxiosResponse> => {
    const { uniqueID, callback, ...params } = payload;

    const url = warrantyDetailsUrl.replace("{identifier}", uniqueID);

    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(url, "GET", searchParams);
  },

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// DAMAGE INFO STEP ///////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  // provides digital warranty damages
  getWarrantyDamages: async (payload: GetWarrantyDamagesPayload): Promise<AxiosResponse> => {
    const { params, ...requestBody } = payload;
    const { uniqueID, ...queryParams } = params;

    const url = warrantyDamagesUrl.replace("{product}", uniqueID);

    const completeQueryParams = { ...queryParams, ...requestBody };

    const searchParams = new URLSearchParams();
    Object.entries(completeQueryParams).forEach(([key, value]) =>
      searchParams.append(key, value as any)
    );

    return createAndExecuteService(url, "GET", searchParams);
  },

  // submit digital warranty damages
  submitWarrantyDamages: async (payload: SubmitWarrantyDamagesPayload): Promise<AxiosResponse> => {
    const { params, ...requestBody } = payload;
    const { uniqueID, callback, ...queryParams } = params;

    const url = warrantyDamagesUrl.replace("{product}", uniqueID);

    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(url, "POST", searchParams, requestBody);
  },

  //////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////// ALTERNATIVE STEPS /////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  // get alternative model
  getWarrantyAlternativeModel: async (
    payload: GetAlternativeModelServicePayload
  ): Promise<AxiosResponse> => {
    const url = warrantyAlternativeModelUrl.replace("{term}", payload.search ?? "*");
    const { filters, ...rest } = payload;

    const queryParams: QueryParamsWithString = {};
    Object.keys(rest).forEach((key) => {
      const value = (rest as any)[key];
      if (value) queryParams[key] = value;
    });

    return createAndExecuteService(
      url,
      "GET",
      appendFiltersToURLSearchParams(queryParams, filters)
    );
  },

  // select alternative sku
  submitWarrantySelectAlternativeAction: async (
    payload: SubmitWarrantySelectAlternativeActionPayload
  ): Promise<AxiosResponse> => {
    const { params, alternativeProductItemIdentifier } = payload;
    const { warrantyIdentifier, callback, ...queryParams } = params;
    const requestBody = {
      alternativeProductItemIdentifier,
      warrantyIdentifier,
    };

    const url = warrantySelectAlternativeActionUrl.replace(
      "{warrantyIdentifier}",
      warrantyIdentifier
    );

    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(url, "POST", searchParams, requestBody);
  },

  // get model details + size guide for selected model alternative
  getWarrantyModelDetails: async (
    payload: GetWarrantyModelDetailsPayload
  ): Promise<AxiosResponse> => {
    const { uniqueID, ...queryParams } = payload;

    const url = warrantyModelDetailsUrl.replace("{catentryId}", uniqueID);

    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => searchParams.append(key, value as any));
    searchParams.append("catalogType", "aftersales");

    return createAndExecuteService(url, "GET", searchParams);
  },

  // get alternative model
  getWarrantyAlternativeVariant: async (
    payload: GetAlternativeVariantServicePayload
  ): Promise<AxiosResponse> => {
    const { filters, partNumber, ...rest } = payload;
    const url = warrantyAlternativeVariantUrl.replace("{term}", partNumber);

    const queryParams: QueryParamsWithString = {};
    Object.keys(rest).forEach((key) => {
      const value = (rest as any)[key];
      if (value) queryParams[key] = value;
    });

    return createAndExecuteService(
      url,
      "GET",
      appendFiltersToURLSearchParams(queryParams, filters)
    );
  },

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////// NOTES STEP //////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  // submit note step
  submitWarrantyNoteStep: async (payload: SubmitNotesPayload): Promise<AxiosResponse> => {
    const { params, ...requestBody } = payload;
    const { warrantyIdentifier, callback, ...queryParams } = params;

    const url = warrantyNotesStepUrl.replace("{warrantyIdentifier}", warrantyIdentifier);

    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => searchParams.append(key, value));

    console.log("WARRANTY ATTACHMENTS PAYLOAD", requestBody);

    return createAndExecuteService(url, "POST", searchParams, requestBody);
  },

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// SHIPPING DETAILS STEP ////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  // submit warranty shipping details
  submitWarrantyShippingDetails: async (
    payload: WarrantyWizardDeliveryAddressPayload
  ): Promise<AxiosResponse> => {
    const { warrantyIdentifier, callback, ...queryParams } = payload;

    const url = warrantyShippingDetailsUrl.replace("{warrantyIdentifier}", warrantyIdentifier);

    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(url, "POST", searchParams);
  },

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////// RECAP STEP //////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  // get warranty recap
  getWarrantyRecap: async (payload: WarrantyWizardParameters): Promise<AxiosResponse> => {
    const { warrantyIdentifier, callback, ...queryParams } = payload;

    const url = warrantyRecapUrl.replace("{warrantyIdentifier}", warrantyIdentifier);

    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(url, "GET", searchParams);
  },

  // send warranty to CRM
  submitWarrantyToCRM: async (payload: WarrantyToCRMPayload): Promise<AxiosResponse> => {
    const { params, ...requestBody } = payload;
    const { warrantyIdentifier, callback, ...queryParams } = params;

    const url = warrantyToCRMUrl.replace("{warrantyIdentifier}", warrantyIdentifier);

    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(url, "POST", searchParams, requestBody);
  },

  //////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////// STATUS ACTIONS ////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  // get step information when calling UPLOAD_NEW_ATTACHMENT status action
  getWarrantyInformationStep: async (payload: WarrantyWizardParameters): Promise<AxiosResponse> => {
    const { warrantyIdentifier, callback, ...queryParams } = payload;

    const url = warrantyInformationStepUrl.replace("{warrantyIdentifier}", warrantyIdentifier);

    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(url, "GET", searchParams);
  },

  // submit step information when calling UPLOAD_NEW_ATTACHMENT status action
  submitWarrantyInformationStep: async (
    payload: SubmitMoreInformationStepPayload
  ): Promise<AxiosResponse> => {
    const { params, wizardAttachedImagesList } = payload;
    const { warrantyIdentifier, callback, ...queryParams } = params;

    const requestBody = {
      wizardAttachedImagesList,
      warrantyIdentifier,
    };

    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(
      warrantySendInformationStepUrl,
      "POST",
      searchParams,
      requestBody
    );
  },

  // get step structure when calling SELECT_AN_ALTERNATIVE_PRODUCT status action
  getWarrantyAlternativeWizard: async (
    payload: WarrantyWizardParameters
  ): Promise<AxiosResponse> => {
    const { warrantyIdentifier, callback, ...queryParams } = payload;

    const url = warrantyAlternativeWizardUrl.replace("{warrantyIdentifier}", warrantyIdentifier);

    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => searchParams.append(key, value));

    return createAndExecuteService(url, "GET", searchParams);
  },
};
