import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import useTranslation from "../../hooks/useTranslation";
import {
  selectrepeatableOrders,
  selectRepeatedOrder,
  setRepeatableOrders,
  setRepeatedOrder,
} from "../../store/checkout/checkoutSlice";
import { selectBrandGroupByBrandMap } from "../../store/store/storeSlice";
import { getMCLFromPartnumber } from "../../utils/rxUtils";
import Button from "../styled-UI/Button";
import CustomText from "../styled-UI/CustomText";

import Popup from "../styled-UI/Popup";

export default function RepeatOrderPopup(): JSX.Element {
  const dispatch = useDispatch();
  const { translateLabel } = useTranslation();

  const brandGroupByBrandMap = useSelector(selectBrandGroupByBrandMap);
  const repeatedOrder = useSelector(selectRepeatedOrder);

  const repeatableOrders = useSelector(selectrepeatableOrders);
  const isRepeatable = !!repeatableOrders?.data.repeatedItems?.length;
  const orderList = isRepeatable
    ? repeatableOrders?.data.repeatedItems
    : repeatableOrders?.data.notRepeatedItems;

  const handleClose = () => {
    dispatch(setRepeatableOrders(null));
    dispatch(setRepeatedOrder(""));
  };

  return (
    <Popup
      isOpen={!!repeatableOrders}
      close={handleClose}
      title={translateLabel((isRepeatable ? "" : "UN") + "REPEATABLE_PRODUCTS_TITLE")}
    >
      <RepeatOrderContainer>
        <CustomText as="p">
          {translateLabel((isRepeatable ? "" : "UN") + "REPEATABLE_PRODUCTS_TEXT").replace(
            "{ORDER_ID}",
            repeatedOrder
          )}
        </CustomText>
        {orderList && (
          <div className="order-list-container">
            <div className="order-titles-container">
              <div className="order-model-container">
                <CustomText as="p">{translateLabel("REPEAT_ORDER_MODEL")}</CustomText>
              </div>
              <div className="order-quantity-container">
                <CustomText as="p">
                  {translateLabel("REPEAT_ORDER_QUANTITY" + (isRepeatable ? "" : "_MISSING"))}
                </CustomText>
              </div>
            </div>
            {orderList.map((order) => {
              if (isRepeatable && order.repeatedQuantity === "0") return;

              const orderBrand: string = translateLabel(
                brandGroupByBrandMap[order.partNumber.slice(1, 3)] + "_LABEL"
              );
              const { modelId, colorId, sizeId } = getMCLFromPartnumber(order.partNumber);

              return (
                <div className="order-item" key={order.partNumber}>
                  <div className="order-img-container">
                    <img src={order.fullImage} alt={order.partNumber} />
                  </div>
                  <div className="order-model-container">
                    <CustomText as="p" fontSizePx={18} marginBottomPx={5} font="font-bold">
                      {orderBrand} {modelId} {colorId} {sizeId}
                    </CustomText>
                    <CustomText as="p" fontSizePx={13} font="font-light">
                      {order.name}
                    </CustomText>
                  </div>
                  <div className="order-quantity-container">
                    <CustomText as="p">
                      {`${order.repeatedQuantity} / ${order.totalQuantity}`}
                    </CustomText>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="btn-container">
          <Button type="primary" onClick={handleClose}>
            {translateLabel("CONFIRM")}
          </Button>
        </div>
      </RepeatOrderContainer>
    </Popup>
  );
}

const RepeatOrderContainer = styled.div`
  padding: 1rem;
  .order-list-container {
    max-height: 48vh;
    overflow: auto;
    width: 48rem;
    background-color: ${(props) => props.theme.palette.gray.light};
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
    border-radius: 0.25rem;
  }

  .order-titles-container {
    padding-left: 9.5rem;
    height: 3rem;
    display: flex;
    align-items: center;
  }

  .order-item {
    background-color: white;
    border-radius: 0.25rem;
    display: flex;
  }

  .order-img-container {
    height: 6.5rem;
    width: 6.5rem;
    border-radius: 0.25rem;
    margin-right: 3rem;
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }
  }

  .order-model-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .order-quantity-container {
    display: flex;
    align-items: center;
    width: 8rem;
  }

  .btn-container {
    display: flex;
    justify-content: center;
  }
`;
