import { BFFApiDomain } from "../serviceUtils";

export const subuserCreateUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/subuser";

///////////////// MyEL SUBUSERS

// list of subusers for table
export const subuserListUrl = BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/subusers";

// subuser detail + edit
export const subuserItemUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/subuser/{userId}";

// subuser status edit
export const subuserStatusUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/subuser/{userId}/status/{userStatus}";

// send invitation email
export const subuserSendEmailUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/subusers/{username}/invitation";

///////////////// LeoOnly SUBUSERS

// list of subusers for table
export const subuserListLeoOnlyUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/subusers/leonardo";

// subuser detail + edit + edit status
export const subuserItemLeoOnlyUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/subuser/leonardo/{username}";

// send invitation email
export const subuserSendEmailLeoOnlyUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/subuser/leonardo/{username}/invitation";

///////////////// PRIVILEGES

export const userPrivilegesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/users/{iduser}/privileges";

export const newUserPrivilegesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/users/privileges";

export const getSubuserTemplatesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/users/privileges/templates";
