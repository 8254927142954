import React from "react";
import useTranslation from "../../../../hooks/useTranslation";
import styled from "styled-components";
import { OrderTYPEssilorPayload } from "../../../../store/checkout/orderConfirmationEssilorInterfaces";
import CustomText from "../../../styled-UI/CustomText";
import CustomProgressiveImage from "../../../styled-UI/CustomProgressiveImage";

interface Props {
  showDetails: boolean;
  typData: OrderTYPEssilorPayload | null;
}

const CartTileDetailsEssilor = ({ showDetails, typData }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  return showDetails ? (
    <Container>
      <Column>
        <CustomText as={"span"} font="font-bold">
          {translateLabel("THANK_YOU_PAGE_LENS_COLUMN")}
        </CustomText>
        <Row>
          <CustomText as={"span"} font="font-regular">
            {translateLabel("THANK_YOU_PAGE_DESIGN_LABEL")}
          </CustomText>
          <CustomText as={"span"} font="font-bold">
            {typData?.lensData?.design}
          </CustomText>
        </Row>
        <Row>
          <CustomText as={"span"} font="font-regular">
            {translateLabel("THANK_YOU_PAGE_MATERIAL_LABEL")}
          </CustomText>
          <CustomText as={"span"} font="font-bold">
            {typData?.lensData?.material}
          </CustomText>
        </Row>
        <Row>
          <CustomText as={"span"} font="font-regular">
            {translateLabel("THANK_YOU_PAGE_MASS_TINT_LABEL")}
          </CustomText>
          <CustomText as={"span"} font="font-bold">
            {typData?.lensData?.massTint}
          </CustomText>
        </Row>
        <Row>
          <CustomText as={"span"} font="font-regular">
            {translateLabel("THANK_YOU_PAGE_TREATMENTS_LABEL")}
          </CustomText>
          <CustomText as={"span"} font="font-bold">
            {typData?.lensData?.treatments}
          </CustomText>
        </Row>
        <Row>
          <CustomText as={"span"} font="font-regular">
            {translateLabel("THANK_YOU_PAGE_SHAPE_PREVIEW_LABEL")}
          </CustomText>
          <CustomProgressiveImage type="glasses" src={typData?.lensData?.shapePreviewUrl || ""} />
        </Row>
      </Column>
      <Column>
        <CustomText as={"span"} font="font-bold">
          {translateLabel("THANK_YOU_PAGE_FRAME_COLUMN")}
        </CustomText>
        <Row>
          <CustomText as={"span"} font="font-regular">
            {translateLabel("THANK_YOU_PAGE_KIND_OF_FRAME_LABEL")}
          </CustomText>
          <CustomText as={"span"} font="font-bold">
            {typData?.frameData?.kindOfFrame}
          </CustomText>
        </Row>
        <Row>
          <CustomText as={"span"} font="font-regular">
            {translateLabel("THANK_YOU_PAGE_MODEL_LABEL")}
          </CustomText>
          <CustomText as={"span"} font="font-bold">
            {typData?.frameData?.model}
          </CustomText>
        </Row>
        <Row>
          <CustomText as={"span"} font="font-regular">
            {translateLabel("THANK_YOU_PAGE_FRAME_COLOR_LABEL")}
          </CustomText>
          <CustomText as={"span"} font="font-bold">
            {typData?.frameData?.color}
          </CustomText>
        </Row>
        <Row>
          <CustomText as={"span"} font="font-regular">
            {translateLabel("THANK_YOU_PAGE_FRAME_PREVIEW_LABEL")}
          </CustomText>
          <CustomProgressiveImage type="glasses" src={typData?.frameData?.framePreviewUrl || ""} />
        </Row>
      </Column>
      <Column>
        <CustomText as={"span"} font="font-bold">
          {translateLabel("THANK_YOU_PAGE_ORDER_REF_COLUMN")}
        </CustomText>
        <Row>
          <CustomText as={"span"} font="font-regular">
            {translateLabel("THANK_YOU_PAGE_ORDER_REFERENCE_LABEL")}
          </CustomText>
          <CustomText as={"span"} font="font-bold">
            {typData?.orderRef?.orderReference}
          </CustomText>
        </Row>
        <Row>
          <CustomText as={"span"} font="font-regular">
            {translateLabel("THANK_YOU_PAGE_JOB_TYPE_LABEL")}
          </CustomText>
          <CustomText as={"span"} font="font-bold">
            {translateLabel(typData?.orderRef?.jobType || "")}
          </CustomText>
        </Row>
      </Column>
    </Container>
  ) : (
    <></>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.gray.light};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1rem;
  margin: 1rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  div,
  span {
    padding-bottom: 0.5rem;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export default CartTileDetailsEssilor;
