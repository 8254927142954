import { useEffect, useState } from "react";

import { detectScrollDirection } from "../../../../utils/utils";
import { useBreakpoint } from "../../../../assets/theme/breakpoint";

export const useNavbarAutoHide = (): { hideNavbar: boolean } => {
  const breakpoints = useBreakpoint();

  //show-hide navbar navigation for responsive
  let lastScrollTop = 0;
  const [hideNavbar, setHideNavbar] = useState<boolean>(false);

  const handleScroll = () => {
    if (breakpoints["md"]) {
      const scrollInfo = detectScrollDirection(lastScrollTop);
      lastScrollTop = scrollInfo.newScrollTop;

      if (scrollInfo.direction === "down") {
        setHideNavbar(true);
      } else {
        setHideNavbar(false);
      }
    }
  };

  useEffect(() => {
    document.getElementById("root")?.addEventListener("scroll", handleScroll);
    if (!breakpoints["md"]) setHideNavbar(false);

    return () => {
      document.getElementById("root")?.removeEventListener("scroll", handleScroll);
    };
  }, [breakpoints["md"]]);

  return { hideNavbar };
};
