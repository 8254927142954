import React from "react";
import styled from "styled-components/macro";
import SingleIconCard from "./SingleIconCard";
import { IconCardItem } from "../../../interfaces/cmsInterfaces";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  isDarkMode?: boolean;
  data: IconCardItem[];
  customFont?: FontFamilies | null;
}

const IconCardVertical = ({ isDarkMode = false, data, customFont = null }: Props): JSX.Element => {
  return (
    <Container>
      {data.map((singleItem) => {
        return (
          <SingleIconCard
            key={singleItem.id}
            data={singleItem}
            layout="vertical"
            isDarkMode={isDarkMode}
            customFont={customFont}
          />
        );
      })}
    </Container>
  );
};

export default IconCardVertical;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  flex-direction: column;
`;
