import React, { useRef } from "react";
import dompurify from "dompurify";
import useLinkClickHandlers from "../../hooks/useLinkClickHandlers";

interface Props {
  text?: string;
  anchorCallback?: () => void;
  convertLink?: boolean;
}

export const sanitizeHTML = (str?: string): string => {
  return str ? dompurify.sanitize(str) : "";
};

export default function SetInnerHTMLWithSanitize({
  text,
  anchorCallback,
  convertLink,
}: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  useLinkClickHandlers(ref, anchorCallback);

  return (
    <div
      ref={convertLink ? ref : undefined}
      dangerouslySetInnerHTML={{
        __html: sanitizeHTML(text),
      }}
    ></div>
  );
}
