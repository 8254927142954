import React from "react";
import styled from "styled-components/macro";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import NotificationsIconEmpty from "../../assets/images/notifications-icon-empty.svg";
import NotificationsIconFill from "../../assets/images/notifications-icon-fill.svg";
import PreorderIcon from "../../assets/icons/Blue.svg";
import AvailableIcon from "../../assets/icons/Green.svg";
import LastPiecesIcon from "../../assets/icons/Green_2.svg";
import BackOrderIcon from "../../assets/icons/Yellow.svg";
import OutOfStockIcon from "../../assets/icons/Red.svg";
import Tooltip from "../styled-UI/Tooltip";
import useTranslation from "../../hooks/useTranslation";
import CustomText from "../styled-UI/CustomText";
import { getLabelAvailability } from "../../utils/catalogueUtils";
import { addInventoryTracking, deleteInventoryTracking } from "../../store/messages/messagesSagas";
import {
  selectLoadingAvailability,
  selectProductAvailability,
} from "../../store/catalogue/catalogueSlice";
import { selectIsBackOfficeUser, selectSelectedDoor } from "../../store/user/userSlice";
import { selectBackorderTrackedItems } from "../../store/cart/cartSlice";
import useCanRender from "../../hooks/useCanRender";

interface Props {
  inventoryStatus?: string;
  hide?: boolean;
  showRedSemaphore?: boolean;
  id?: string;
  isCart?: boolean;
  isAfa?: boolean;
}

export default function AvailabilityStatus({
  inventoryStatus,
  hide = false,
  showRedSemaphore = false,
  id,
  isCart,
  isAfa,
}: Props): JSX.Element {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const canRender = useCanRender();

  const productAvailability = useSelector(selectProductAvailability);
  const currentDoor = useSelector(selectSelectedDoor);
  const availabilityLoading = useSelector(selectLoadingAvailability);
  const backorderTrackedItems = useSelector(selectBackorderTrackedItems);
  const isBackOfficeUser = useSelector(selectIsBackOfficeUser);

  const renderStatusIcon = (inventoryStatus: string) => {
    switch (inventoryStatus) {
      case "PREORDER":
        return isAfa ? AvailableIcon : PreorderIcon;
      case "AVAILABLE":
        return AvailableIcon;
      case "LAST_PIECES":
        return LastPiecesIcon;
      case "BACKORDER":
        return BackOrderIcon;
      case "BACKORDER_FOLLOWING":
        return canRender("GREEN_IS_BACK") ? NotificationsIconFill : BackOrderIcon;
      case "BACKORDER_NOT_FOLLOWING":
        return canRender("GREEN_IS_BACK") ? NotificationsIconEmpty : BackOrderIcon;
      case "NOT_AVAILABLE":
        return showRedSemaphore ? OutOfStockIcon : undefined;
      default:
        return undefined;
    }
  };

  const renderLabel = (inventoryStatus: string) => {
    const label = getLabelAvailability(inventoryStatus);
    return label ? translateLabel(label) : "";
  };

  const handleBackorderFollow = (inventoryStatus?: string): void => {
    if (
      id &&
      canRender("GREEN_IS_BACK") &&
      inventoryStatus?.includes("FOLLOWING") &&
      !availabilityLoading &&
      !isBackOfficeUser
    ) {
      if (inventoryStatus === "BACKORDER_NOT_FOLLOWING") {
        dispatch(addInventoryTracking({ id: id as string, isCart }));
        return;
      }
      const trackingId = getTrackingId();

      if (trackingId) {
        dispatch(
          deleteInventoryTracking({ inventoryTrackingId: trackingId, uniqueID: id, isCart })
        );
      }
    }
  };

  const getTrackingId = (): string | undefined => {
    if (isCart) {
      return backorderTrackedItems[id as string];
    }
    return productAvailability
      .find((door) => door.doorId === currentDoor?.orgentityName)
      ?.availabilityStatus.find((status) => Object.keys(status)[0] === id)?.trackingId;
  };

  const statusIcon = inventoryStatus && renderStatusIcon(inventoryStatus);
  const labelIcon = inventoryStatus && renderLabel(inventoryStatus);

  if (!statusIcon || hide)
    return (
      <ImgWrapper
        className={clsx(inventoryStatus?.includes("FOLLOWING") && !isBackOfficeUser && "bell")}
        onClick={() => handleBackorderFollow(inventoryStatus)}
      >
        <img className={clsx((!statusIcon || hide) && "hidden")} src={statusIcon} />
      </ImgWrapper>
    );
  else
    return (
      <Tooltip
        content={
          <Wrap>
            <div style={{ maxWidth: "0.75rem" }}>
              <img src={statusIcon} />
            </div>
            <CustomText as="span" fontSizePx={14} font="font-bold" marginLeftPx={16}>
              {labelIcon}
            </CustomText>
          </Wrap>
        }
      >
        <ImgWrapper
          className={clsx(inventoryStatus?.includes("FOLLOWING") && !isBackOfficeUser && "bell")}
          onClick={() => handleBackorderFollow(inventoryStatus)}
        >
          <img className={clsx((!statusIcon || hide) && "hidden")} src={statusIcon} />
        </ImgWrapper>
      </Tooltip>
    );
}

const ImgWrapper = styled.div`
  .img {
    height: 0.75rem;
    width: 0.75rem;

    &.hidden {
      visibility: hidden;
    }
  }

  &.bell {
    cursor: pointer;

    .img {
      height: 1rem;
      width: 1rem;
    }
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.hidden {
    visibility: hidden;
  }
`;
