import React from "react";
import styled from "styled-components/macro";
import useDataHref from "../../hooks/useDataHref";
import { LpSingleText as Interface } from "../../interfaces/cmsInterfaces";
import CustomText from "../styled-UI/CustomText";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  data: Interface;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}
const LpSingleText = ({ data, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  const { description, description1Colour } = data;
  const descrWithHref = useDataHref({ content: description });

  return (
    <OuterBox>
      <BoxTitle>
        {description && (
          <CustomText
            as="div"
            fontSizePx={18}
            lineHeightPx={24}
            font={customFont ? "custom-light" : "font-light"}
            fontFamily={customFont}
            color={isDarkMode ? "white" : "primary"}
            customColor={description1Colour}
          >
            <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? description} />
          </CustomText>
        )}
      </BoxTitle>
    </OuterBox>
  );
};

export default LpSingleText;

const OuterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 80%;
  width: 80%;
  margin: 0 auto;
  padding: 1.75rem 0;
`;

const BoxTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
`;
