import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import CustomSelect, { CustomOptions } from "../../styled-UI/CustomSelect";
import CustomText from "../../styled-UI/CustomText";
import Checkbox from "../../styled-UI/Checkbox";
import RadioButton from "../../styled-UI/RadioButton";
import InputComponent from "../../styled-UI/InputComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  checkIncludedItems,
  selectCustomerReference,
  selectIncludedItems,
  selectMadeInAndItemsInPackage,
  selectOtherDescription,
  selectSelectedLens,
  setCustomerReference,
  setOtherDescription,
} from "../../../store/rx/rxSlice";
import useTranslation from "../../../hooks/useTranslation";
import { CustomerReference, SelectList, SelectNameList } from "../../../store/rx/rxInterface";
import useCanRender from "../../../hooks/useCanRender";
import { mapTranslatedKeys } from "../../../utils/utils";

interface Props {
  selectList: SelectList;
  handleSelectChange: (value: CustomOptions, ...types: SelectNameList[]) => void;
  activeStep: number;
}

const lateralityOpts = [
  { label: "LEFT", value: "L" },
  { label: "RIGHT", value: "R" },
];

export default function AdditionalInfo({
  selectList,
  handleSelectChange,
  activeStep,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const { translateLabel } = useTranslation();
  const canRender = useCanRender();

  const madeIn = useSelector(selectMadeInAndItemsInPackage);
  const includedItems = useSelector(selectIncludedItems);
  const selectedLens = useSelector(selectSelectedLens);
  const customerReference = useSelector(selectCustomerReference);
  const otherDescription = useSelector(selectOtherDescription);
  const [madeInOpts, setMadeInOpts] = useState<CustomOptions[]>([]);
  const [itemsIncludedOpts, setItemsIncludedOpts] = useState<string[]>([]);
  const [selectableOpts, setSelectableOpts] = useState<CustomOptions[]>([]);

  useEffect(() => {
    if (madeIn && madeIn.itemsIncluded.length > 0) {
      const itemsIncluded = [...madeIn.itemsIncluded];
      setMadeInOpts(
        madeIn.madeInOptions.map((madeInOpt) => ({
          label: translateLabel(madeInOpt),
          value: madeInOpt,
        }))
      );
      // move "ITEMPKG_OTHERS" to last
      itemsIncluded.push(itemsIncluded.splice(itemsIncluded.indexOf("ITEMPKG_OTHERS"), 1)[0]);
      setItemsIncludedOpts(itemsIncluded);
      if (madeIn.selectableOptions.length) {
        setSelectableOpts(
          madeIn.selectableOptions[0].optionValues.map((selOpt) => ({
            label: translateLabel(selOpt),
            value: selOpt,
          }))
        );
      }
    }
  }, []);

  const handleCustomerRefChange = (key: keyof CustomerReference, str: string) => {
    const intruders = /\.|\+|\-|e/g;
    dispatch(
      setCustomerReference({
        key,
        str: canRender("RX_ALPHA_CHARACTERS") ? str : str.replace(intruders, ""),
      })
    );
  };

  const showRxAlphaCharNote = () => {
    return !(canRender("RX_ALPHA_CHARACTERS") && canRender("DISABLE_RX_ALPHA_CHARACTERS"));
  };

  const showLabelCheckbox = (opt: string) => {
    if (opt === "ITEMPKG_FRAME") return translateLabel(opt) + "*";
    else return translateLabel(opt);
  };

  return (
    <>
      {selectedLens?.lateralityRequired && (
        <ContentContainer>
          <CustomText
            as="h3"
            font="font-bold"
            fontSizePx={18}
            lineHeightPx={24}
            marginBottomPx={24}
          >
            {translateLabel("RX_LATERALITY")}*
          </CustomText>
          <div className="input-container">
            <CustomSelect
              type="white"
              options={mapTranslatedKeys(
                lateralityOpts.map((option) => option.label),
                translateLabel,
                "RX_ADDITIONAL_INFO_"
              ).map((option) => {
                return { label: option.label, value: option.value.toString().charAt(0) };
              })}
              placeholder={translateLabel("RX_LATERALITY_SELECT")}
              value={selectList.laterality}
              disabled={activeStep > 3}
              onChange={(e) => {
                handleSelectChange(e, "laterality");
              }}
            />
          </div>
        </ContentContainer>
      )}
      {madeInOpts.length > 0 && (
        <ContentContainer>
          <CustomText
            as="h3"
            font="font-bold"
            fontSizePx={18}
            lineHeightPx={24}
            marginBottomPx={24}
          >
            {translateLabel("RX_MADE_IN")}*
          </CustomText>
          <div className="input-container">
            <CustomSelect
              type="white"
              options={madeInOpts}
              placeholder={translateLabel("RX_MADE_IN_SELECT")}
              value={selectList.madeIn}
              disabled={activeStep > 3}
              onChange={(e) => {
                handleSelectChange(e, "madeIn");
              }}
            />
          </div>
        </ContentContainer>
      )}
      {itemsIncludedOpts && (itemsIncludedOpts.length > 0 || selectableOpts.length > 0) && (
        <ContentContainer>
          <CustomText
            as="h3"
            font="font-bold"
            fontSizePx={18}
            lineHeightPx={24}
            marginBottomPx={24}
          >
            {translateLabel("RX_ITEM_IN_PACKAGE")}*
          </CustomText>
          <div className="d-flex">
            {itemsIncludedOpts.length > 0 && (
              <div className="options-container">
                {activeStep === 3 ? (
                  itemsIncludedOpts.map((opt: string) => (
                    <div className="opt" key={opt}>
                      <Checkbox
                        label={showLabelCheckbox(opt)}
                        value={opt}
                        name="items-included"
                        controlled
                        onChange={(e) => {
                          dispatch(
                            checkIncludedItems({
                              shouldCheck: e.target.checked,
                              includedOpt: opt,
                            })
                          );
                        }}
                        checked={includedItems.some((item) => item === opt)}
                      />
                    </div>
                  ))
                ) : (
                  <div>
                    {includedItems.map((item) => (
                      <CustomText
                        key={item}
                        as="p"
                        font="font-regular"
                        fontSizePx={13}
                        lineHeightPx={18}
                        marginBottomPx={2}
                      >
                        {translateLabel(item)}
                      </CustomText>
                    ))}
                  </div>
                )}
                {includedItems.some((item) => item === "ITEMPKG_OTHERS") && (
                  <div className="d-flex">
                    <div className="input-container">
                      <InputComponent
                        id="otherDescr"
                        type="text"
                        maxLength={25}
                        placeholder=""
                        onChange={(e) => {
                          dispatch(setOtherDescription(e.target.value));
                        }}
                        disabled={activeStep > 3}
                        value={otherDescription}
                      />
                    </div>
                    *
                  </div>
                )}
              </div>
            )}
            {selectableOpts.length > 0 && (
              <div className="options-container">
                {activeStep === 3 ? (
                  <>
                    <CustomText
                      as="h4"
                      font="font-bold"
                      fontSizePx={13}
                      lineHeightPx={18}
                      marginBottomPx={16}
                    >
                      {translateLabel("RX_SELECTABLE_OPT")}*
                    </CustomText>
                    {selectableOpts.map((opt: CustomOptions) => (
                      <div className="opt" key={opt.label}>
                        <RadioButton
                          label={opt.label}
                          value={opt.value}
                          name="lenses-kind"
                          onChange={() => {
                            handleSelectChange(opt, "selectableOpt");
                          }}
                          checked={selectList.selectableOpt?.value === opt.value}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <div>
                    <CustomText
                      as="p"
                      font="font-regular"
                      fontSizePx={13}
                      lineHeightPx={18}
                      marginBottomPx={2}
                    >
                      {translateLabel("RX_SELECTABLE_OPT")}*
                    </CustomText>
                    <CustomText as="p" font="font-bold" fontSizePx={13} lineHeightPx={18}>
                      {selectList.selectableOpt?.label}
                    </CustomText>
                  </div>
                )}
              </div>
            )}
          </div>
        </ContentContainer>
      )}
      {activeStep >= 3 && (
        <ContentContainer>
          <CustomText
            as="h3"
            font="font-bold"
            fontSizePx={18}
            lineHeightPx={24}
            marginBottomPx={24}
          >
            {translateLabel("RX_CUSTOMER_REF")}*
          </CustomText>
          <div className="d-flex">
            <div className="input-container">
              <InputComponent
                id="customerReference1"
                type={canRender("RX_ALPHA_CHARACTERS") ? "text" : "number"}
                placeholder=""
                onChange={(e) => {
                  handleCustomerRefChange("name", e.target.value);
                }}
                disabled={activeStep > 3}
                value={customerReference.name}
              />
            </div>
            <div className="input-container">
              <InputComponent
                id="customerReference2"
                type={canRender("RX_ALPHA_CHARACTERS") ? "text" : "number"}
                placeholder=""
                onChange={(e) => {
                  handleCustomerRefChange("surname", e.target.value);
                }}
                disabled={activeStep > 3}
                value={customerReference.surname}
              />
            </div>
          </div>
          {showRxAlphaCharNote() && (
            <CustomText
              as="p"
              font="font-light"
              color="gray-dark"
              fontSizePx={14}
              lineHeightPx={24}
              marginTopPx={24}
            >
              {translateLabel("RX_ALPHA_CHAR_NOTE")}
            </CustomText>
          )}
        </ContentContainer>
      )}
    </>
  );
}

const ContentContainer = styled.div`
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
  margin-bottom: 2rem;
  .input-container {
    width: 25rem;
    margin-right: 1.5rem;
    &:last-child {
      margin-right: unset;
    }
  }
  .options-container {
    width: 13.5rem;
    .opt {
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: unset;
      }
    }
  }
  .note-container {
    width: 51.5rem;
  }
`;
