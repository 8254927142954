import React, { useEffect, useState } from "react";
import Slider, { LazyLoadTypes } from "react-slick";
import styled from "styled-components/macro";
import IconButton from "../styled-UI/IconButton";
import NavigationSingleTab from "./navigation-tab/NavigationSingleTab";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrowhead-left-icon.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrowhead-right-icon.svg";
import BrandImage from "../styled-UI/BrandImage";
import useTranslation from "../../hooks/useTranslation";
import { FontFamilies } from "../../store/store/storeInterfaces";
import { useHistory } from "react-router-dom";

interface Props {
  logoTabs: any;
  layout: "carousel" | "tabs";
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LogoShowcaseTabs = ({
  logoTabs,
  layout,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<any>(logoTabs?.[0]);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [sliderRef, setSliderRef] = useState<any>(null);
  const { translateLabel } = useTranslation();
  const history = useHistory();

  const slidesToScroll = 1;
  const slidesToShow = 4;
  const totalSlides = logoTabs?.length;
  const currentPage = Math.ceil(currentSlide / slidesToScroll);
  const lastPage = Math.ceil((totalSlides - slidesToShow) / slidesToScroll);

  const defaultSettings = {
    autoplay: false,
    adaptiveHeight: true,
    dots: false,
    arrows: false,
    draggable: layout === "tabs" ? true : false, //to set it to true --> prevent click on drag https://github.com/akiran/react-slick/issues/848
    infinite: false,
    lazyLoad: "progressive" as LazyLoadTypes,
    onSwipe: () => {
      console.log("swipe");
    },
    slidesToShow: slidesToShow < totalSlides ? slidesToShow : totalSlides,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    beforeChange: (_: any, next: any) => setCurrentSlide(next),
  };

  const changeSlide = (val: number) => {
    sliderRef.slickGoTo(val);
  };

  return (
    <Container>
      <TabsContainer>
        {layout === "tabs" && <TopRow></TopRow>}
        <LogoContainer>
          {layout === "carousel" && totalSlides > 1 && totalSlides > slidesToShow ? (
            <IconButton
              size="xl"
              onClick={() => changeSlide(currentSlide - slidesToScroll)}
              disabled={currentPage === 0}
              icon={<ArrowLeft />}
              type="no-background-primary"
            />
          ) : (
            <div></div>
          )}
          <Slider ref={(val) => setSliderRef(val)} {...defaultSettings}>
            {logoTabs?.map((tab: any) => {
              let brandCode = "";
              if (layout === "carousel") {
                brandCode = tab?.brand?.find((_: any) => _.externalReference !== "landingpage")
                  .externalReference;
              } else if (layout === "tabs") {
                brandCode = tab?.subjectTaxonomy?.find(
                  (_: any) => _.externalReference !== "landingpage"
                ).externalReference;
              }
              return (
                <SingleLogoContainer key={tab?.id}>
                  {layout === "tabs" && (
                    <SelectionLine
                      className={selectedTab === tab ? "active" : ""}
                      isDarkMode={isDarkMode}
                    ></SelectionLine>
                  )}

                  <BrandImageContainer
                    onClick={() => {
                      setSelectedTab(tab);
                    }}
                  >
                    {layout === "carousel" && (
                      <LinkContainer
                        onClick={() => {
                          history.push(tab?.linkString);
                        }}
                      >
                        <BrandImage
                          brandOrBrandGroup={brandCode}
                          fallbackLabel={translateLabel(brandCode + "_LABEL")}
                        ></BrandImage>
                      </LinkContainer>
                    )}
                    {layout === "tabs" && (
                      <BrandImage
                        brandOrBrandGroup={brandCode}
                        fallbackLabel={translateLabel(brandCode + "_LABEL")}
                      ></BrandImage>
                    )}
                  </BrandImageContainer>
                </SingleLogoContainer>
              );
            })}
          </Slider>
          {layout === "carousel" && totalSlides > 1 && totalSlides > slidesToShow ? (
            <IconButton
              size="xl"
              onClick={() => changeSlide(currentSlide + slidesToScroll)}
              disabled={currentPage === lastPage}
              icon={<ArrowRight />}
              type="no-background-primary"
            />
          ) : (
            <div></div>
          )}
        </LogoContainer>
      </TabsContainer>
      {layout === "tabs" && (
        <ContentContainer>
          <NavigationSingleTab
            layout={"horizontal"}
            data={selectedTab?.grid}
            isDarkMode={isDarkMode}
            customFont={customFont}
          ></NavigationSingleTab>
        </ContentContainer>
      )}
    </Container>
  );
};

export default LogoShowcaseTabs;

const Container = styled.div`
  width: 100%;
  padding-top: 1rem;
`;

const LinkContainer = styled.div``;

const TabsContainer = styled.div`
  width: 100%;
  padding-bottom: 1rem;
`;

const BrandImageContainer = styled.div``;

const SingleLogoContainer = styled.div`
  display: flex !important;
  flex-direction: column;
  max-width: 18.75rem;
`;

const SelectionLine = styled.div<{ isDarkMode: boolean }>`
  width: 100%;
  height: 7px;
  margin-bottom: 1rem;
  margin-top: -4px;

  &.active {
    background-color: ${(props) =>
      props.isDarkMode ? props.theme.palette.white : props.theme.palette.secondary};
  }
`;

const LogoContainer = styled.div`
  display: grid;
  grid-template-columns: 4.25rem calc(100% - 8.5rem) 4.25rem;
  align-items: center;
  height: 8rem;

  .slick-track {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
  }

  .slick-slide {
    display: flex !important;
    justify-content: center;
  }

  .slick-list {
    height: 9rem !important;
  }

  img {
    height: 6rem;
    align-self: center;
    width: 100%;
    padding: 1rem;
  }
`;

const ContentContainer = styled.div``;

const TopRow = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.palette.gray.medium};
`;
