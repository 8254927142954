import { cloneDeep, isEqual, sortBy, uniqWith } from "lodash";
import { SizeGuideSchema } from "../components/pages/warranty-wizard/Steps/StepSelectVariantDetails";
import { CustomOptions } from "../components/styled-UI/CustomSelect";
import { AttributeSlim } from "../interfaces/productInterface";
import {
  ModelDetailsWarranty,
  WarrantyWizardOptionsStepDamageInfo,
  WarrantyWizardStepStructure,
} from "../store/warranty-wizard/warrantyWizardInterface";
import { getAttributeValues } from "./productUtils";

export const mapSubmitWarrantyDamagesResponse = (payload: any): WarrantyWizardStepStructure[] => {
  const wizardSteps: WarrantyWizardStepStructure[] = [];

  payload?.wizardStepsList?.forEach((_: WarrantyWizardStepStructure) => {
    wizardSteps.push({
      wizardTitleIdentifierKey: _?.wizardTitleIdentifierKey,
      wizardInternaltitleKey: _?.wizardInternaltitleKey,
      wizardStepElementList: _?.wizardStepElementList?.map((element) => {
        return {
          wizardStepElementIdentifier: element?.wizardStepElementIdentifier,
          wizardStepElementLabelKey: element?.wizardStepElementLabelKey,
          wizardStepElementType: element?.wizardStepElementType,
          wizardAttachmentImagesIdentifierList: element?.wizardAttachmentImagesIdentifierList?.map(
            (attachment) => {
              return {
                imageIdentifierKey: attachment?.imageIdentifierKey,
                isMandatory: attachment?.isMandatory,
                imageLabelKey: attachment?.imageLabelKey,
                fileValidExtensionRegex: attachment?.fileValidExtensionRegex,
                fileNameMaxLength: attachment?.fileNameMaxLength,
                boxImageURL: attachment?.boxImageURL,
                imageReference: attachment?.imageReference,
              };
            }
          ),
          numberOfYearsBefore: element?.numberOfYearsBefore,
          isMandatory: element?.isMandatory,
        };
      }),
      wizardStepIdentifier: _?.wizardStepIdentifier,
      wizardStepOrder: (_?.wizardStepOrder ?? 10) / 10,
      wizardStepGetService: _?.wizardStepGetService,
      wizardStepSubmitService: _?.wizardStepSubmitService,
      isCurrentStep: _?.isCurrentStep,
    });
  });

  return wizardSteps.sort((a, b) => a.wizardStepOrder - b.wizardStepOrder);
};

export const mapWarrantyAddress = (addressList: any[]): CustomOptions[] => {
  const filteredAddressList = addressList.filter((_: any) => _.addressType === "S");

  return filteredAddressList.map(
    (_): CustomOptions => {
      const address = printWarrantyAddress(_);
      return {
        value: _?.nickName,
        label: address ?? "",
      };
    }
  );
};

export const printWarrantyAddress = (address?: any): string | null => {
  if (!address) return null;

  const street = address?.address1 ? address.address1 + ", " : "";
  const city = address?.city ? address.city + " " : "";
  const country = address?.country ? "- " + address.country : "";

  return street + city + country;
};

export const mapModelDetailsWarranty = (catalogEntry: any): ModelDetailsWarranty => {
  const skuAttributes: AttributeSlim[][] = catalogEntry?.[0]?.sKUs?.map(
    (_: any): AttributeSlim[] => {
      const currentSkuAttributes: AttributeSlim[] = [];

      SizeGuideSchema?.forEach((sizeGuideAttribute: string) => {
        const currentAttr = getAttributeValues(_.attributes, sizeGuideAttribute);
        currentAttr && currentSkuAttributes.push(currentAttr);
      });

      return currentSkuAttributes;
    }
  );

  return {
    modelAttributes: catalogEntry?.[0]?.attributes,
    uniqueID: catalogEntry?.[0]?.uniqueID,
    partNumber: catalogEntry?.[0]?.partNumber,
    skuAttributes: sortBy(
      uniqWith(skuAttributes, isEqual),
      (attributes) => attributes?.[0]?.values
    ),
  };
};

export const warrantyWizardOptionsStepDamageInfo = (
  data: any
): WarrantyWizardOptionsStepDamageInfo => {
  const xState =
    data?.availability?.x_state !== "AVAILABLE" &&
    data?.availability?.x_state !== "BACKORDER" &&
    data?.availability?.x_state !== "NOT_AVAILABLE"
      ? "AVAILABLE"
      : data?.availability?.x_state;

  const availability = {
    productId: data?.availability?.productId,
    inventoryStatus: data?.availability?.inventoryStatus,
    x_state: xState,
  };

  return {
    defectIdentifierList: data?.defectIdentifierList,
    stateOfSaleList: data?.stateOfSaleList,
    typeOfRequestList: data?.typeOfRequestList,
    preferencesCheckboxLabelKey: data?.preferencesCheckboxLabelKey,
    showAvailabilitySemaphoreWarrantyType: data?.showAvailabilitySemaphoreWarrantyType,
    errorLabelForUnavailabilityOnAssorment: data?.errorLabelForUnavailabilityOnAssorment,
    availability: availability,
  };
};

/**
 * Returns an array of value-label pairs from an array of values, where the labels are the values' translations.
 * Requires the translating function to be passed as parameter.
 *
 * @param {string[]} keys
 * @param {(key: string) => string} translatingFunc
 * @return {*}  {CustomOptions[]}
 */
export const mapTranslatedKeysWarranty = (
  keys: string[],
  translatingFunc: (key: string) => string,
  prefix?: string,
  suffix?: string
): CustomOptions[] => {
  return keys.map((key) => {
    const labelKey = (prefix ?? "") + key + (suffix ?? "");
    const labelSubtitle = (prefix ?? "") + key + "_DESCRIPTION";
    return {
      value: key,
      label: translatingFunc(labelKey),
      subtitle: translatingFunc(labelSubtitle),
    };
  });
};
