import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  sliceName,
  setSubmitBrandListServices,
  setTransportDocumentsServices,
  saveGetTransportDocumentsStatus,
  saveBrandsPos,
  saveGetBrandsStatusPos,
  saveSubmitBrandsStatusPos,
  saveItemMasterData,
  saveVideoPlaylistStatus,
  saveVideoPlaylist,
  setSendItemMasterDataResponse,
  saveDigitalWindowProductsStatus,
  saveDigitalWindowProducts,
  setGetItemMasterDataStatus,
  setPostItemMasterDataStatus,
  selectItemMasterDataUsername,
  setArticlePriceInformationStatus,
  saveDigitalDiscountsVoucherLink,
  selectDigitalDiscountsVoucherLink,
} from "./servicesSlice";
import { handleError } from "../store/storeSagas";
import {
  SubmitBrandListPayload,
  TransportDocumentsListPayload,
  DownloadDocPayload,
  SendItemMasterDataSagaPayload,
  SendItemMasterDataPayload,
  GetBrandList,
  ArticlePriceInformationPayload,
  PostItemMasterDataSagaPayload,
  PostItemMasterDataPayload,
  DigitalWindowProductsResponse,
  GetItemMasterDataPayload,
} from "./servicesInterface";
import servicesService from "./servicesServices";
import catalogueService from "../catalogue/catalogueService";
import { showErrorPopup } from "../store/storeSagas";
import {
  selectActiveDoor,
  selectIsBackOfficeUser,
  selectIsMultidoor,
  selectLocale,
  selectSelectedDoor,
  selectStoreIdentifier,
  selectUserName,
} from "../user/userSlice";
import { Door } from "../user/userInterfaces";
import { mapVariantsArray } from "../../utils/productUtils";
import { downloadURI } from "../../utils/utils";
import { mapPriceResponse } from "../../utils/catalogueUtils";
import {
  selectHasPricePrivilege,
  selectMultidoorHasPricePrivilege,
} from "../catalogue/catalogueSlice";
import { getProductAvailability } from "../catalogue/catalogueSaga";

import mockDigital from "../../components/pages/digital-window/digital_screen_playlist_mock.json";
import { CallbackPayload } from "../../interfaces/mainInterfaces";

/* ACTIONS */
export const getBrandsPos = createAction<GetBrandList>(sliceName + "/getBrandsPos");

export const submitBrandList = createAction<SubmitBrandListPayload>(`${sliceName}/submitBrandList`);
export const submitTransportDocuments = createAction<TransportDocumentsListPayload>(
  `${sliceName}/submitTransportDocuments`
);
export const downloadTransportDocument = createAction<DownloadDocPayload>(
  `${sliceName}/downloadTransportDocument`
);
export const getItemMasterData = createAction<GetItemMasterDataPayload>(
  `${sliceName}/getItemMasterData`
);
export const sendItemMasterData = createAction<SendItemMasterDataSagaPayload>(
  `${sliceName}/sendItemMasterData`
);
export const postItemMasterData = createAction<PostItemMasterDataSagaPayload>(
  `${sliceName}/postItemMasterData`
);

export const getArticlePriceInformation = createAction<ArticlePriceInformationPayload>(
  `${sliceName}/getArticlePriceInformation`
);
//Digital-Screen
export const getVideoPlaylist = createAction<string>(`${sliceName}/getVideoPlaylist`);
export const getDigitalWindowProducts = createAction<string[]>(
  `${sliceName}/getDigitalWindowProducts`
);

//Digital Discounts Voucher
export const getDigitalDiscountsVoucher = createAction<CallbackPayload>(
  `${sliceName}/getDigitalDiscountsVoucher`
);

/**
 * Get brands for source file dat
 *
 * @param {PayloadAction<string[]>} action
 * @return {*}  {SagaIterator}
 */
function* getBrandsPosSaga(action: PayloadAction<GetBrandList>): SagaIterator {
  const params = {
    catalogType: ["active", "aftersales"],
    inProductCategory: action.payload.inProductCategory ?? "",
    facetName: ["manufacturer.raw"],
    doorId: action.payload.doorId,
    pos: true,
  };
  try {
    yield put(saveGetBrandsStatusPos("LOADING"));
    const { data } = yield call(catalogueService.searchByFacet, params);

    const facetView = data.data?.facetView;
    if (facetView && facetView.length > 0) {
      yield put(saveBrandsPos(facetView[0].entry));
    } else {
      yield put(saveBrandsPos(null));
    }
    yield put(saveGetBrandsStatusPos("SUCCESS"));
  } catch (error) {
    yield put(saveBrandsPos(null));
    yield put(saveGetBrandsStatusPos("ERROR"));
    yield put(handleError(error));
  }
}

function* submitBrandListSaga({ payload }: PayloadAction<SubmitBrandListPayload>): SagaIterator {
  try {
    yield put(saveSubmitBrandsStatusPos("LOADING"));
    const { data } = yield call(servicesService.postSubmitBrandList, payload);
    yield put(setSubmitBrandListServices(data));
    yield put(saveSubmitBrandsStatusPos("SUCCESS"));
  } catch (error) {
    yield put(saveSubmitBrandsStatusPos("ERROR"));
    yield put(handleError(error));
  }
}

function* submitTransportDocumentsSaga({
  payload,
}: PayloadAction<TransportDocumentsListPayload>): SagaIterator {
  try {
    yield put(saveGetTransportDocumentsStatus("LOADING"));
    const { data } = yield call(servicesService.postSubmitTransportDocumentList, payload);
    yield put(setTransportDocumentsServices(data.output));
    yield put(saveGetTransportDocumentsStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveGetTransportDocumentsStatus("ERROR"));
    yield put(setTransportDocumentsServices(null));
  }
}

function* downloadTransportDocumentSaga({
  payload,
}: PayloadAction<DownloadDocPayload>): SagaIterator {
  try {
    const { data } = yield call(servicesService.downloadTransportDocument, payload);

    const { absoluteUri } = data.output;
    if (absoluteUri) window.open(absoluteUri, "_blank");
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
  }
}

function* getItemMasterDataSaga({
  payload,
}: PayloadAction<GetItemMasterDataPayload>): SagaIterator {
  try {
    yield put(setGetItemMasterDataStatus("LOADING"));
    if (payload.door?.orgentityId) {
      const username = yield select(selectItemMasterDataUsername);
      const isBackOfficeUser = yield select(selectIsBackOfficeUser);
      const isMultidoor = yield select(selectIsMultidoor);
      const activeDoor = yield select(selectActiveDoor);

      const { data } = yield call(servicesService.getItemMasterData, {
        doorId: isMultidoor ? payload?.door?.orgentityId : activeDoor.orgentityId,
        username: isMultidoor ? username : isBackOfficeUser ? activeDoor.username : username,
      });

      yield put(saveItemMasterData(data));
      yield put(setGetItemMasterDataStatus("SUCCESS"));
    }
  } catch (error) {
    yield put(setGetItemMasterDataStatus("ERROR"));
    yield put(handleError(error));
    yield put(saveItemMasterData(null));
  }
}

function* sendItemMasterDataSaga({
  payload,
}: PayloadAction<SendItemMasterDataSagaPayload>): SagaIterator {
  try {
    const selectedDoor: Door = yield select(selectSelectedDoor);
    const username = yield select(selectItemMasterDataUsername);
    const storeIdentifier: string = yield select(selectStoreIdentifier);
    const locale: string = yield select(selectLocale);
    const callPayload: SendItemMasterDataPayload = {
      customerEmail: payload.customerEmail,
      customerIdentifier: selectedDoor.orgentityName,
      storeIdentifier,
      locale,
      username: username,
      brandIdentifierList: payload.brandIdentifierList,
      articleFields: payload.articleFields,
    };
    const { data } = yield call(servicesService.sendItemMasterData, callPayload);
    yield put(
      setSendItemMasterDataResponse({
        message: data.output.errorMessage || "TO_TRANSLATE:ITEM_MASTER_DATA_RESULT_SUCCESS",
        isSuccess: data.output.result === "OK",
      })
    );
  } catch (error) {
    yield put(handleError(error));
    yield put(
      setSendItemMasterDataResponse({
        message: "TO_TRANSLATE:ITEM_MASTER_DATA_GENERIC_ERROR",
        isSuccess: false,
      })
    );
  }
}

function* postItemMasterDataSaga({
  payload,
}: PayloadAction<PostItemMasterDataSagaPayload>): SagaIterator {
  try {
    yield put(setPostItemMasterDataStatus("LOADING"));

    const selectedDoor: Door = yield select(selectSelectedDoor);
    const username = yield select(selectItemMasterDataUsername);
    const storeIdentifier: string = yield select(selectStoreIdentifier);
    const locale: string = yield select(selectLocale);
    const isMultidoor = yield select(selectIsMultidoor);
    const activeDoor = yield select(selectActiveDoor);
    const isBackOfficeUser = yield select(selectIsBackOfficeUser);
    const usernameBackOffice = yield select(selectUserName);

    const callPayload: PostItemMasterDataPayload = {
      qparams: {
        doorId: isMultidoor ? selectedDoor.orgentityId : activeDoor.orgentityId, // if multidoor payload.door.orgentityId <-- select option | else activeDoor from userContext
      },
      params: {
        customerIdentifier: selectedDoor.orgentityName,
        storeIdentifier,
        locale,
        username: isBackOfficeUser ? usernameBackOffice : username, // if BackOfficeUser username is usernameBackOffice | else username
        articleFields: payload.articleFields,
        brandConfigurationList: payload.brandConfigurationList,
      },
    };

    const { data } = yield call(servicesService.postItemMasterData, callPayload);

    if (data.fieldsResponse.result === "OK") {
      yield put(setPostItemMasterDataStatus("SUCCESS"));
      payload.callback();
    }

    if (data.fieldsResponse.result === "KO") {
      yield put(setPostItemMasterDataStatus("ERROR"));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setPostItemMasterDataStatus("ERROR"));
  }
}
/**
 * Get article price information
 *
 * @param {PayloadAction<ArticlePriceInformationPayload>} action
 * @return {*}  {SagaIterator}
 */

function* getArticlePriceInformationSaga({
  payload,
}: PayloadAction<ArticlePriceInformationPayload>): SagaIterator {
  try {
    yield put(setArticlePriceInformationStatus("LOADING"));
    const { data } = yield call(servicesService.getArticlePriceInformation, payload);
    yield put(setArticlePriceInformationStatus("SUCCESS"));
    downloadURI(data.zipFileLink);
  } catch (error) {
    yield put(setArticlePriceInformationStatus("ERROR"));
    yield put(handleError(error));
  }
}

//Digital-Screen
function* getVideoPlaylistSaga({ payload }: PayloadAction<string>): SagaIterator {
  try {
    yield put(saveVideoPlaylistStatus("LOADING"));
    const { data } = yield call(servicesService.getVideoPlaylist, payload);
    // yield put(saveVideoPlaylist(mockDigital.output.playlistWithProducts));
    yield put(saveVideoPlaylist(data.output.playlistWithProducts));
    yield put(saveVideoPlaylistStatus("SUCCESS"));
  } catch (error) {
    yield put(saveVideoPlaylistStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* getDigitalDiscountsVoucherSaga({
  payload,
}: PayloadAction<CallbackPayload>): SagaIterator {
  const callback = payload?.callback;
  try {
    //Requirement to use the link one-shot -> no saving
    const { data } = yield call(servicesService.getDigitalDiscountsVoucherLink);
    if (data?.data?.link) callback?.(true, data?.data.link);
    else callback?.(false);
    yield put(saveDigitalDiscountsVoucherLink(data?.data.link));
  } catch (error) {
    callback?.(false);
  }
}

function* getDigitalWindowProductsSaga({ payload }: PayloadAction<string[]>): SagaIterator {
  try {
    yield put(saveDigitalWindowProductsStatus("LOADING"));
    const { data } = yield call(servicesService.getDigitalWindowProducts, payload);
    let products: DigitalWindowProductsResponse = {
      ...data,
      catalogEntryView: mapVariantsArray(data.catalogEntryView),
    };

    yield put(saveDigitalWindowProducts(products));
    yield put(saveDigitalWindowProductsStatus("SUCCESS"));

    const isMultidoor = yield select(selectIsMultidoor);
    let hasPricePrivilege = false;
    if (isMultidoor) {
      hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
    } else {
      hasPricePrivilege = yield select(selectHasPricePrivilege);
    }

    // availability
    const skusIds: string[] = [];
    products.catalogEntryView.forEach((product) => {
      product.skus?.forEach((sku) => skusIds.push(sku.uniqueID));
    });
    yield put(getProductAvailability({ ids: skusIds }));

    // price
    if (hasPricePrivilege) {
      const priceIds: string[] = [];
      products.catalogEntryView.forEach((product: any) => {
        priceIds.push(product.uniqueID);
      });

      const { data: price } = yield call(catalogueService.getPriceService, priceIds);
      const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array

      const catalogEntryView = products.catalogEntryView.map((product: any) => {
        const productCopy = { ...product };
        productCopy.price = priceData.find((price) => price.id === product.uniqueID)?.price;
        return productCopy;
      });

      products = {
        ...data,
        catalogEntryView,
      };

      yield put(saveDigitalWindowProducts(products));
    }
  } catch (error) {
    yield put(saveDigitalWindowProductsStatus("ERROR"));
    yield put(handleError(error));
  }
}

export function* servicesSaga(): SagaIterator {
  yield takeEvery(getBrandsPos.type, getBrandsPosSaga);
  yield takeEvery(submitBrandList.type, submitBrandListSaga);
  yield takeEvery(submitTransportDocuments.type, submitTransportDocumentsSaga);
  yield takeEvery(downloadTransportDocument.type, downloadTransportDocumentSaga);
  yield takeEvery(getItemMasterData.type, getItemMasterDataSaga);
  yield takeEvery(sendItemMasterData.type, sendItemMasterDataSaga);
  yield takeEvery(postItemMasterData.type, postItemMasterDataSaga);
  yield takeEvery(getArticlePriceInformation.type, getArticlePriceInformationSaga);
  //Digital-Screen
  yield takeEvery(getVideoPlaylist.type, getVideoPlaylistSaga);
  yield takeEvery(getDigitalWindowProducts.type, getDigitalWindowProductsSaga);
  yield takeLatest(getDigitalDiscountsVoucher.type, getDigitalDiscountsVoucherSaga);
}
