import React from "react";
import useTranslation from "../../../../hooks/useTranslation";
import Button from "../../../styled-UI/Button";
import CustomText from "../../../styled-UI/CustomText";
import Popup from "../../../styled-UI/Popup";
import styled from "styled-components/macro";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

const ADTLegalDisclaimerPopup = ({ onClose, isOpen }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  return (
    <>
      <Popup
        isOpen={isOpen}
        title={translateLabel("ADT_LEGAL_DISCLAIMER_TITLE")}
        isCentered
        hideCloseButton
        disableCloseButton
        close={onClose}
      >
        <Wrapper>
          <CustomText as="p" fontSizePx={14} lineHeightPx={24} whiteSpace={"pre-line"}>
            {translateLabel("ADT_LEGAL_DISCLAIMER_CONTENT")}
          </CustomText>
          <ButtonWrapper>
            <Button type="primary" onClick={onClose}>
              {translateLabel("ADT_LEGAL_DISCLAIMER_BUTTON_LABEL")}
            </Button>
          </ButtonWrapper>
        </Wrapper>
      </Popup>
    </>
  );
};

const Wrapper = styled.div`
  padding: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
`;

export default ADTLegalDisclaimerPopup;
