import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";

import CustomText from "./CustomText";

import { ReactComponent as Selected } from "../../assets/icons/radio-button-selected.svg";
import { ReactComponent as Unselected } from "../../assets/icons/radio-button-unselected.svg";
import { Attribute } from "../../interfaces/productInterface";
import { getFamilySize } from "../../utils/utils";

type Props = {
  sizeNumber: string;
  label: string;
  name: string;
  value: string;
  disabled?: boolean;
  uppercase?: boolean;
  radioIcon?: boolean;
  whiteSpace?: string;
  familySize?: Attribute[];
} & Omit<React.InputHTMLAttributes<HTMLLabelElement>, "onChange">;

const SizeRadioButton = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      sizeNumber,
      label,
      disabled = false,
      name,
      value,
      radioIcon = true,
      uppercase,
      familySize,
      whiteSpace,
    },
    forwardedRef
  ) => {
    return (
      <StyledLabel className={clsx(!radioIcon && "radiobutton-none")}>
        <StyledInput
          type="radio"
          disabled={disabled}
          ref={forwardedRef}
          value={value}
          name={name}
        />
        {radioIcon && (
          <>
            <IconContainerRadio
              className={clsx(disabled && "radiobutton-disabled", "radiobutton-selected")}
            >
              <Selected />
            </IconContainerRadio>

            <IconContainerRadio
              className={clsx(disabled && "radiobutton-disabled", "radiobutton-unselected")}
            >
              <Unselected />
            </IconContainerRadio>
          </>
        )}

        <LabelWrapper className="d-flex">
          <CustomText
            as="span"
            fontSizePx={13}
            lineHeightPx={18}
            marginLeftPx={4}
            marginRightPx={4}
            uppercase={uppercase}
            whiteSpace={whiteSpace}
          >
            {label}
          </CustomText>
          <CustomText
            as="span"
            fontSizePx={13}
            lineHeightPx={18}
            font="font-bold"
            marginLeftPx={4}
            marginRightPx={4}
            uppercase={uppercase}
            whiteSpace={whiteSpace}
          >
            {sizeNumber}
          </CustomText>
          {familySize && (
            <CustomText
              as="span"
              fontSizePx={13}
              lineHeightPx={18}
              font="font-bold"
              marginLeftPx={4}
              marginRightPx={4}
              uppercase={uppercase}
              whiteSpace={whiteSpace}
            >
              {getFamilySize(familySize)}
            </CustomText>
          )}
        </LabelWrapper>
      </StyledLabel>
    );
  }
);

const LabelWrapper = styled.div`
  padding: 0 0.5rem;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: center;
  padding: 0.75rem 1rem;
  border: 1px solid ${(props) => props.theme.palette.gray.medium};
  border-radius: 0.25rem;

  input:checked ~ .radiobutton-selected {
    display: inline;
  }

  input:checked ~ .radiobutton-unselected {
    display: none;
  }

  &.radiobutton-none {
    background-color: ${(props) => props.theme.palette.white};
    padding: 0.938rem 1.25rem;
    justify-content: center;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 0.25rem;
  }
`;

const IconContainerRadio = styled.div`
  height: 1.25rem;
  display: flex;
  align-items: center;

  svg {
    width: 1.25rem;
    fill: ${(props) => props.theme.palette.gray.dark};
    &:hover {
      fill: ${(props) => props.theme.palette.primary};
    }
  }

  &.radiobutton-disabled {
    svg {
      fill: ${(props) => props.theme.palette.gray.light};
      &:hover {
        fill: ${(props) => props.theme.palette.gray.light};
      }
    }
  }

  &.radiobutton-selected {
    display: none;
    svg {
      fill: ${(props) => props.theme.palette.primary};
    }
  }
`;

const StyledInput = styled.input`
  display: none;
`;

SizeRadioButton.displayName = "SizeRadioButton";
export default SizeRadioButton;
