import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uniq } from "lodash";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import { RootState } from "../storeConfig";
import { CurrencyFormat, PreferredCurrencyType } from "../user/userInterfaces";
import { selectActiveDoor, selectUsersPrivileges, selectCurrencyFormat } from "../user/userSlice";
import { CustomOptions } from "../../components/styled-UI/CustomSelect";

import {
  PaymentAdvancedSelected,
  PaymentDeselectionReasons,
  PaymentDeselectionReasonsOptions,
  PaymentDocumentHistory,
  PaymentFilterPayload,
  PaymentHistoryDetail,
  PaymentHistoryList,
  PaymentManagementOpenDocument,
  PaymentManagementOverviewTotals,
  PaymentManagementSelected,
  PaymentManagementSimulationDoc,
  PaymentManagementTypesFull,
  PaymentSimulationResponse,
  PaymentMethod,
  PaymentState,
  PaymentStatement,
  SavePaymentManagementDocumentsListPayload,
  PaymentConfirmationResponse,
  SavePaymentMethodsPayload,
  PaymentProcessStatus,
  SetPaymentRequestStatus,
  PaymentMethodModalInfo,
  PaymentMethodsStatus,
  PaymentManagementSelectedMethodPayload,
  PaymentManagementSelectedMethod,
  PaymentErrors,
  SetPaymentError,
  PaymentPolicyStatus,
} from "./paymentInterface";

export const sliceName = "payment";

const DEFAULT_PAYMENT_PROCESS_STATUS: PaymentProcessStatus = {
  simulation: "IDLE",
  confirmation: "IDLE",
};

const DEFAULT_PAYMENT_METHODS_STATUS: PaymentMethodsStatus = {
  getList: "IDLE",
  addOrEdit: "IDLE",
  delete: "IDLE",
  getEditModalDetails: "IDLE",
};

const DEFAULT_PAYMENT_MANAGEMENT_SELECTED_METHOD = {
  AchAccount: null,
  CreditCard: null,
};

const DEFAULT_PAYMENT_ERRORS: PaymentErrors = {
  banner: null,
  simulation: null,
  confirmation: null,
  paymentMethod: null,
  paymentMethodEdit: null,
  paymentMethodSave: null,
  paymentMethodDelete: null,
  paymentMethodPreferred: null,
};

const initialState: PaymentState = {
  ////////// MISC
  paymentPolicyStatus: { isAccepted: false, status: "IDLE" },
  currency: null,
  getPaymentTableStatus: "IDLE",
  getPaymentSelectLoaderStatus: "IDLE",
  getPaymentManagementInfoStatus: "IDLE",
  paymentProcessStatus: DEFAULT_PAYMENT_PROCESS_STATUS,
  paymentErrors: DEFAULT_PAYMENT_ERRORS,

  ////////// PAYMENT MANAGEMENT
  paymentManagementOverview: null,
  payNowList: [],
  statementList: [],
  netBalanceList: [],
  deselectionReasons: [],
  deselectedDocs: [],
  paymentManagementSelected: null,
  temporaryPaymentMethodsCC: [],
  temporaryPaymentMethodsACH: [],
  paymentManagementSelectedMethod: DEFAULT_PAYMENT_MANAGEMENT_SELECTED_METHOD,

  paymentSimulationResponse: null,
  paymentConfirmationResponse: null,

  ////////// PAYMENT METHOD
  paymentMethodsCC: [],
  paymentMethodsACH: [],
  paymentMethodDetail: null,
  paymentMethodsStatus: DEFAULT_PAYMENT_METHODS_STATUS,
  paymentMethodListCountries: [],

  ////////// PAYMENT HISTORY
  paymentHistoryList: [],
  paymentHistoryDetail: null,
  storedPaymentFilters: null,

  ////////// PAYMENT DOCUMENT HISTORY
  paymentDocumentHistory: [],

  ////////// PAYMENT STATEMENT
  paymentStatement: [],
};

export const paymentSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setPaymentPolicyStatus: (state, action: PayloadAction<PaymentPolicyStatus>) => {
      state.paymentPolicyStatus = action.payload;
    },
    setCurrency: (state, action: PayloadAction<string | null>) => {
      state.currency = action.payload;
    },
    setPaymentTableStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getPaymentTableStatus = action.payload;
    },
    setPaymentSelectLoaderStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getPaymentSelectLoaderStatus = action.payload;
    },
    setPaymentManagementInfoStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getPaymentManagementInfoStatus = action.payload;
    },
    setPaymentProcessStatus: (
      state,
      action: PayloadAction<SetPaymentRequestStatus<PaymentProcessStatus>>
    ) => {
      if (action.payload.type === "default")
        state.paymentProcessStatus = DEFAULT_PAYMENT_PROCESS_STATUS;
      else state.paymentProcessStatus[action.payload.type] = action.payload.status;
    },
    setPaymentErrors: (state, action: PayloadAction<SetPaymentError>) => {
      if (action.payload.type === "default") state.paymentErrors = DEFAULT_PAYMENT_ERRORS;
      else state.paymentErrors[action.payload.type] = action.payload.error;
    },

    ////////// PAYMENT MANAGEMENT
    savePaymentManagementOverview: (
      state,
      action: PayloadAction<PaymentManagementOverviewTotals | null>
    ) => {
      state.paymentManagementOverview = action.payload;
    },

    savePaymentManagementDocumentsList: (
      state,
      action: PayloadAction<SavePaymentManagementDocumentsListPayload>
    ) => {
      state[action.payload.listName] = action.payload.list;
    },

    // NOTE: only the lists of data from API, NOT saved info such as selected/deselected docs etc.
    clearPaymentManagementViewAll: (state) => {
      state.paymentManagementOverview = null;
      state.payNowList = [];
      state.statementList = [];
      state.netBalanceList = [];
    },

    saveDeselectionReasons: (state, action: PayloadAction<PaymentDeselectionReasonsOptions[]>) => {
      state.deselectionReasons = action.payload;
    },

    saveDeselectedDocs: (state, action: PayloadAction<PaymentManagementSimulationDoc[]>) => {
      state.deselectedDocs = action.payload;
    },

    updateDeselectedDocs: (state, action: PayloadAction<PaymentManagementSimulationDoc>) => {
      if (action.payload.deselectReason) {
        state.deselectedDocs = [...state.deselectedDocs, action.payload];
      } else {
        state.deselectedDocs = state.deselectedDocs.filter(
          (_) => _.documentId !== action.payload.documentId
        );
      }
    },

    addTemporaryPaymentMethodsCC: (state, action: PayloadAction<PaymentMethod[]>) => {
      if (action.payload.length === 0) state.temporaryPaymentMethodsCC = action.payload;
      else
        state.temporaryPaymentMethodsCC = [...state.temporaryPaymentMethodsCC, ...action.payload];
    },

    addTemporaryPaymentMethodsACH: (state, action: PayloadAction<PaymentMethod[]>) => {
      if (action.payload.length === 0) state.temporaryPaymentMethodsACH = action.payload;
      else
        state.temporaryPaymentMethodsACH = [...state.temporaryPaymentMethodsACH, ...action.payload];
    },

    savePaymentManagementSelectedMethod: (
      state,
      action: PayloadAction<PaymentManagementSelectedMethodPayload>
    ) => {
      state.paymentManagementSelectedMethod[action.payload.type] = action.payload.method;
    },

    savePaymentManagementSelected: (
      state,
      action: PayloadAction<PaymentManagementSelected | null>
    ) => {
      state.paymentManagementSelected = action.payload;
    },

    savePaymentSimulationResponse: (
      state,
      action: PayloadAction<PaymentSimulationResponse | null>
    ) => {
      state.paymentSimulationResponse = action.payload;
    },

    clearPaymentManagementAfterConfirmation: (state) => {
      state.paymentManagementOverview = null;
      state.payNowList = [];
      state.statementList = [];
      state.netBalanceList = [];
      state.deselectionReasons = [];
      state.deselectedDocs = [];
      state.paymentManagementSelected = null;
      state.temporaryPaymentMethodsCC = [];
      state.temporaryPaymentMethodsACH = [];
      state.paymentManagementSelectedMethod = DEFAULT_PAYMENT_MANAGEMENT_SELECTED_METHOD;
      state.paymentSimulationResponse = null;
      state.paymentProcessStatus["simulation"] = "IDLE";
    },

    savePaymentConfirmationResponse: (
      state,
      action: PayloadAction<PaymentConfirmationResponse | null>
    ) => {
      state.paymentConfirmationResponse = action.payload;
    },

    clearPaymentTYP: (state) => {
      state.paymentConfirmationResponse = null;
      state.paymentProcessStatus["confirmation"] = "IDLE";
    },

    ////////// PAYMENT METHODS
    savePaymentMethods: (state, action: PayloadAction<SavePaymentMethodsPayload>) => {
      const { paymentMethodsCC, paymentMethodsACH } = action.payload;

      state.paymentMethodsCC = paymentMethodsCC;
      state.paymentMethodsACH = paymentMethodsACH;
    },

    savePaymentMethodDetail: (state, action: PayloadAction<PaymentMethodModalInfo | null>) => {
      state.paymentMethodDetail = action.payload;
    },

    setPaymentMethodsStatus: (
      state,
      action: PayloadAction<SetPaymentRequestStatus<PaymentMethodsStatus>>
    ) => {
      if (action.payload.type === "default")
        state.paymentMethodsStatus = DEFAULT_PAYMENT_METHODS_STATUS;
      else state.paymentMethodsStatus[action.payload.type] = action.payload.status;
    },

    savePaymentMethodListCountries: (state, action: PayloadAction<CustomOptions[]>) => {
      state.paymentMethodListCountries = action.payload;
    },

    clearPaymentMethod: (state) => {
      state.paymentMethodsCC = [];
      state.paymentMethodsACH = [];
      state.paymentMethodDetail = null;
      state.paymentMethodsStatus = DEFAULT_PAYMENT_METHODS_STATUS;
      state.paymentManagementSelectedMethod = DEFAULT_PAYMENT_MANAGEMENT_SELECTED_METHOD;
    },

    ////////// PAYMENT HISTORY
    savePaymentHistoryList: (state, action: PayloadAction<PaymentHistoryList[]>) => {
      state.paymentHistoryList = action.payload;
    },

    savePaymentHistoryDetail: (state, action: PayloadAction<PaymentHistoryDetail | null>) => {
      state.paymentHistoryDetail = action.payload;
    },

    saveStoredPaymentFilters: (state, action: PayloadAction<PaymentFilterPayload | null>) => {
      state.storedPaymentFilters = action.payload;
    },

    ////////// PAYMENT DOCUMENT HISTORY
    savePaymentDocumentHistory: (state, action: PayloadAction<PaymentDocumentHistory[]>) => {
      state.paymentDocumentHistory = action.payload;
    },

    ////////// PAYMENT STATEMENT
    savePaymentStatement: (state, action: PayloadAction<PaymentStatement[]>) => {
      state.paymentStatement = action.payload;
    },

    resetState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  ////////// MISC
  setPaymentPolicyStatus,
  setCurrency,
  setPaymentTableStatus,
  setPaymentSelectLoaderStatus,
  setPaymentManagementInfoStatus,
  setPaymentProcessStatus,
  setPaymentErrors,

  ////////// PAYMENT MANAGEMENT
  savePaymentManagementOverview,
  savePaymentManagementDocumentsList,
  clearPaymentManagementViewAll,
  saveDeselectionReasons,
  saveDeselectedDocs,
  updateDeselectedDocs,
  savePaymentManagementSelected,
  addTemporaryPaymentMethodsCC,
  addTemporaryPaymentMethodsACH,
  savePaymentManagementSelectedMethod,
  savePaymentSimulationResponse,
  clearPaymentManagementAfterConfirmation,
  savePaymentConfirmationResponse,
  clearPaymentTYP,

  ////////// PAYMENT METHOD
  savePaymentMethods,
  savePaymentMethodDetail,
  setPaymentMethodsStatus,
  savePaymentMethodListCountries,
  clearPaymentMethod,

  ////////// PAYMENT HISTORY
  savePaymentHistoryList,
  savePaymentHistoryDetail,
  saveStoredPaymentFilters,

  ////////// PAYMENT DOCUMENT HISTORY
  savePaymentDocumentHistory,

  ////////// PAYMENT STATEMENT
  savePaymentStatement,

  resetState,
} = paymentSlice.actions;

////////// MISC
export const selectPaymentPolicyStatus = (state: RootState): PaymentPolicyStatus => {
  return state.payment.paymentPolicyStatus;
};

export const selectCurrency = (state: RootState): string | null => {
  return state.payment.currency;
};

export const selectPaymentCurrencyFormat = createSelector(
  selectCurrency,
  selectCurrencyFormat,
  (paymentCurrency: string | null, storeCurrency: CurrencyFormat): CurrencyFormat => {
    return {
      currency: {
        opt: paymentCurrency ?? storeCurrency?.currency.opt,
        pub: paymentCurrency ?? storeCurrency?.currency.opt,
      },
      locale: storeCurrency?.locale,
    };
  }
);

export const selectPaymentTableStatus = (state: RootState): RequestStatus => {
  return state.payment.getPaymentTableStatus;
};

export const selectPaymentSelectLoaderStatus = (state: RootState): RequestStatus => {
  return state.payment.getPaymentSelectLoaderStatus;
};

export const selectPaymentManagementInfoStatus = (state: RootState): RequestStatus => {
  return state.payment.getPaymentManagementInfoStatus;
};

export const selectPaymentProcessStatus = (state: RootState): PaymentProcessStatus => {
  return state.payment.paymentProcessStatus;
};

export const selectPaymentErrors = (state: RootState): PaymentErrors => {
  return state.payment.paymentErrors;
};

////////// PRIVILEGES

// check which mode (full or view-only) is active for payment based on the corresponding privileges
export const selectPaymentActiveMode = createSelector(
  selectActiveDoor,
  selectUsersPrivileges,
  (activeDoor, usersPrivileges): "full-mode" | "view-only" | null => {
    const privileges =
      usersPrivileges?.find((user) => user?.orgentityName === activeDoor?.orgentityName)
        ?.privileges ?? [];

    const isFullMode = privileges?.some((userPriv: string) => userPriv === "PAYMENT_FULL_MODE");
    const isViewOnly = privileges?.some((userPriv: string) => userPriv === "PAYMENT_VIEW_ONLY");

    if (isFullMode) return "full-mode"; // full-mode "wins" even if also get view-only
    if (isViewOnly) return "view-only";
    return null; // if none is present then we don't have the privilege to view payment
  }
);

// check if among payment errors there is at least one that enforces the view-only privilege
// this is usually "PAYMENT_EXECUTION_DISABLED"
export const selectIsDisabledPaymentExecution = createSelector(
  selectPaymentErrors,
  (errors: PaymentErrors): boolean => {
    const disabledPaymentExecutionError = Object.values(errors)?.find(
      (_) => _?.enforcedPrivilege === "PAYMENT_VIEW_ONLY"
    );

    return !!disabledPaymentExecutionError;
  }
);

// quick util to check if we are in full-mode or not, based on both the privileges + payment execution disabled
export const selectIsPaymentFullMode = createSelector(
  selectIsDisabledPaymentExecution,
  selectPaymentActiveMode,
  (isDisabledPaymentExecution: boolean, paymentMode: "full-mode" | "view-only" | null): boolean => {
    return paymentMode === "full-mode" && !isDisabledPaymentExecution;
  }
);

////////// PAYMENT MANAGEMENT
export const selectPaymentManagementOverview = (
  state: RootState
): PaymentManagementOverviewTotals | null => {
  return state.payment.paymentManagementOverview;
};

export const selectPayNowList = (state: RootState): PaymentManagementOpenDocument[] => {
  return state.payment.payNowList;
};

export const selectStatementList = (state: RootState): PaymentManagementOpenDocument[] => {
  return state.payment.statementList;
};

export const selectNetBalanceList = (state: RootState): PaymentManagementOpenDocument[] => {
  return state.payment.netBalanceList;
};

export const selectDeselectionReasons = (state: RootState): PaymentDeselectionReasonsOptions[] => {
  return state.payment.deselectionReasons;
};

export const selectDeselectedDocs = (state: RootState): PaymentManagementSimulationDoc[] => {
  return state.payment.deselectedDocs;
};

export const selectDeselectedDocsReason = createSelector(
  selectDeselectedDocs,
  (deselectedDocs: PaymentManagementSimulationDoc[]): PaymentDeselectionReasons[] => {
    const deselectionReasons: PaymentDeselectionReasons[] = [];
    deselectedDocs.forEach((_) => _.deselectReason && deselectionReasons.push(_.deselectReason));

    return uniq(deselectionReasons);
  }
);

export const selectPaymentManagementSelected = (
  state: RootState
): PaymentManagementSelected | null => {
  return state.payment.paymentManagementSelected;
};

export const selectSelectedDocs = createSelector(
  selectPaymentManagementSelected,
  (
    paymentManagementSelected: PaymentManagementSelected | null
  ): PaymentManagementOpenDocument[] => {
    if (!paymentManagementSelected) return [];
    else {
      const { PAY_NOW, STATEMENT, NET_BALANCE } = paymentManagementSelected.selectedDocs;
      return [...PAY_NOW, ...STATEMENT, ...NET_BALANCE];
    }
  }
);

export const selectSelectedPaymentType = createSelector(
  selectPaymentManagementSelected,
  (
    paymentManagementSelected: PaymentManagementSelected | null
  ): PaymentManagementTypesFull | undefined => {
    return paymentManagementSelected?.selectedPaymentType;
  }
);

export const selectSelectedAdvancedPayment = createSelector(
  selectPaymentManagementSelected,
  (
    paymentManagementSelected: PaymentManagementSelected | null
  ): PaymentAdvancedSelected | undefined => {
    return paymentManagementSelected?.selectedAdvancedPayment;
  }
);

export const selectTemporaryPaymentMethodsCC = (state: RootState): PaymentMethod[] => {
  return state.payment.temporaryPaymentMethodsCC;
};

export const selectTemporaryPaymentMethodsACH = (state: RootState): PaymentMethod[] => {
  return state.payment.temporaryPaymentMethodsACH;
};

export const selectPaymentManagementSelectedMethod = (
  state: RootState
): PaymentManagementSelectedMethod => {
  return state.payment.paymentManagementSelectedMethod;
};

export const selectPaymentSimulationResponse = (
  state: RootState
): PaymentSimulationResponse | null => {
  return state.payment.paymentSimulationResponse;
};

export const selectPaymentConfirmationResponse = (
  state: RootState
): PaymentConfirmationResponse | null => {
  return state.payment.paymentConfirmationResponse;
};

////////// PAYMENT METHOD
export const selectPaymentMethodsCC = (state: RootState): PaymentMethod[] => {
  return state.payment.paymentMethodsCC;
};

export const selectPaymentMethodsCCWithTemp = createSelector(
  selectPaymentMethodsCC,
  selectTemporaryPaymentMethodsCC,
  (apiMethods: PaymentMethod[], temporaryMethods: PaymentMethod[]): PaymentMethod[] => {
    return [...apiMethods, ...temporaryMethods];
  }
);

export const selectPaymentMethodsACH = (state: RootState): PaymentMethod[] => {
  return state.payment.paymentMethodsACH;
};

export const selectPaymentMethodsACHWithTemp = createSelector(
  selectPaymentMethodsACH,
  selectTemporaryPaymentMethodsACH,
  (apiMethods: PaymentMethod[], temporaryMethods: PaymentMethod[]): PaymentMethod[] => {
    return [...apiMethods, ...temporaryMethods];
  }
);

export const selectPaymentMethodDetail = (state: RootState): PaymentMethodModalInfo | null => {
  return state.payment.paymentMethodDetail;
};

export const selectPaymentMethodsStatus = (state: RootState): PaymentMethodsStatus => {
  return state.payment.paymentMethodsStatus;
};

export const selectPaymentMethodListCountries = (state: RootState): CustomOptions[] => {
  return state.payment.paymentMethodListCountries;
};

////////// PAYMENT HISTORY
export const selectPaymentHistoryList = (state: RootState): PaymentHistoryList[] => {
  return state.payment.paymentHistoryList;
};

export const selectPaymentHistoryDetail = (state: RootState): PaymentHistoryDetail | null => {
  return state.payment.paymentHistoryDetail;
};

export const selectStoredPaymentFilters = (state: RootState): PaymentFilterPayload | null => {
  return state.payment.storedPaymentFilters;
};

////////// PAYMENT DOCUMENT HISTORY
export const selectPaymentDocumentHistory = (state: RootState): PaymentDocumentHistory[] => {
  return state.payment.paymentDocumentHistory;
};

////////// PAYMENT STATEMENT
export const selectPaymentStatement = (state: RootState): PaymentStatement[] => {
  return state.payment.paymentStatement;
};

export default paymentSlice.reducer;
