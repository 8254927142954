type metadata = { "data-cm-metadata": string };

/**
 * Generates the CoreMedia metadata JSON for Studio integration.
 *
 * @param contentId content id / string containing the id
 * @param propertyNames list of property names
 */
export const cmMetaData = (
  contentId: string | number | undefined,
  ...propertyNames: Array<string | number | undefined>
): metadata => {
  const metadata = [];

  if (contentId) {
    if (typeof contentId == "number" || !contentId.startsWith("content/")) {
      contentId = "content/" + contentId;
    }
    metadata.push({ _: { $Ref: contentId } });
  }

  if (propertyNames) {
    propertyNames
      .filter((propertyName) => typeof propertyName === "string")
      .forEach((propertyName) => {
        metadata.push({ _: "properties." + propertyName });
      });
  }

  return { "data-cm-metadata": JSON.stringify(metadata) };
};

/**
 * Generates the CoreMedia metadata JSON for Studio integration.
 *
 * @param placementName name of the placement
 */
export const cmPlacementMetaData = (placementName: string): metadata => {
  const metadata = [
    { _: `properties.placement-${placementName}` },
    {
      placementRequest: [{ isInLayout: true, hasItems: true, placementName: placementName }],
    },
  ];

  return { "data-cm-metadata": JSON.stringify(metadata) };
};
