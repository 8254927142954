import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { QueryParams } from "../store/search/searchInterfaces";
import { selectMenu } from "../store/store/storeSlice";
import { getFiltersFromParams } from "../utils/filterUtils";
import { getUrlWithFilters, GlassesCategoryKeys, isGlassesCategory } from "../utils/menuUtils";
import { capitalizeFirstLetter } from "../utils/utils";
import { useLocation } from "react-router";
import useTranslation from "./useTranslation";

const getUrlInfo = (url: string): { identifier: string; filters: QueryParams } => {
  const [pathname, params] = url.split("?");

  const identifier = pathname.split("/").pop() ?? ""; // get identifier based on url
  const filters = getFiltersFromParams(new URLSearchParams(params)); // get filters based on url

  return { identifier, filters };
};

const useCategoryTitle = (): { title: string; pageIdentifier: string } => {
  const { translateLabel } = useTranslation();

  const [title, setTitle] = useState("");
  const [pageIdentifier, setPageIdentifier] = useState("");
  const menu = useSelector(selectMenu);
  const urlParams = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const currentUrl = window.location.href; // get current url
    const { identifier: currentIdentifier, filters: currentFilters } = getUrlInfo(currentUrl);
    const menuItems = menu?.filter((_) => _.identifier === "PRODUCTS")?.[0]?.catalogGroupView; // get menu product items

    let newTitle = "";
    let newIdentifier = "";

    menuItems?.some((_) => {
      const menuItemUrl = getUrlWithFilters(_);
      const { identifier: menuItemIdentifier } = getUrlInfo(menuItemUrl);

      const isSameCategory = menuItemIdentifier === currentIdentifier; // check if category slug is the same (eg. frames, accessories, etc)

      if (isSameCategory && _.categoryIdentifier === "FRAMES") {
        // check if PRODUCT_CATEGORY_FILTER are the same (eg. sunglasses/eyeglasses)
        const isSameCategoryFilters =
          currentFilters["PRODUCT_CATEGORY_FILTER"]?.length > 0 &&
          isGlassesCategory(
            currentFilters["PRODUCT_CATEGORY_FILTER"],
            _.identifier as GlassesCategoryKeys,
            "all"
          );

        // match for both category + filters -> return new title
        newIdentifier = _.identifier;
        if (isSameCategoryFilters) {
          newTitle = _.name;

          return true; // return true to break and no longer look for matches
        } else {
          newTitle = _.categoryName ?? ""; // match for category, but not for filters: category is frames -> special case where we take parent name
          newIdentifier = _.categoryIdentifier ?? "";
        }
        // this way we take category name until (and if) we find a match for the category filters in the menu
      }
      // for all other categories we just need the category slugs to match
      else if (isSameCategory) {
        if (_.identifier === "GOGGLES_HELMETS" && urlParams.has("G_H_TYPE")) {
          const GHType = urlParams.getAll("G_H_TYPE");
          let title = "";
          if (GHType.length > 1) {
            title = _.name;
          } else if (GHType[0] === "G") {
            title = translateLabel("PLP_TITLE_GOGGLES");
          } else title = translateLabel("PLP_TITLE_HELMETS");
          newTitle = title;
        } else {
          newTitle = _.name;
        }
        newIdentifier = _.identifier;
      }
    });

    if (newTitle && title?.toLowerCase() !== newTitle?.toLowerCase()) {
      setTitle(capitalizeFirstLetter(newTitle));
    }

    if (newIdentifier && newIdentifier !== pageIdentifier) {
      setPageIdentifier(newIdentifier);
    }
  }, [window.location.href, menu]);

  return { title, pageIdentifier };
};

export default useCategoryTitle;
