import { call, put, select, take, takeEvery, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction, createAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";

import { CustomOptions } from "../../components/styled-UI/CustomSelect";
import { Product, Variant } from "../../interfaces/productInterface";
import {
  mapConversationalBrandsResult,
  mapConversationalModelResult,
  mapConversationalUpcResult,
  mapConversationalVariantResult,
  mapSparePartsFamilies,
  mapSparePartsGroupedFamilies,
  mapSparePartsItems,
  mapWarrantyInboxDetails,
} from "../../utils/aftersalesUtils";
import { mapAttachments, mapPlpCatalogueNoAdv } from "../../utils/catalogueUtils";
import { mapProductObj, mapSkuObj, mapVariantObj } from "../../utils/productUtils";
import { setLoadingAnalyticsData } from "../analytics/analyticsSlice";
import {
  GetAttachmentsVariantPayload,
  PlpCatalogue,
  PlpCatalogueNoAdv,
  SearchByFacetPayload,
} from "../catalogue/catalogueInterface";
import { getPLPInstagramBadges } from "../catalogue/catalogueSaga";
import catalogueService from "../catalogue/catalogueService";
import {
  savePlpCatalogue,
  savePlpFacetView,
  setLoadingPlp,
  setSelectedTile,
} from "../catalogue/catalogueSlice";
import { QueryParams } from "../search/searchInterfaces";
import { selectProductPerPage } from "../search/searchSlice";
import { getBrandImages, handleError } from "../store/storeSagas";
import { selectSelectedDoor, selectStoreIdentifier } from "../user/userSlice";
import {
  AftersalesSearchPayload,
  GetAftersalesByUpcPayload,
  GetAftersalesItemPayload,
  GetAftersalesPlpCatalogueSagaPayload,
  GetAftersalesPlpCatalogueSearchParams,
  GetSparePartsByFamilyPayload,
  GetSparePartsFamiliesPayload,
  GetSparePartsPDPTechDraftPayload,
  GetWarrantyProductDetailsByPartNumberPayload,
  PostWarrantyParcelDataSagaPayload,
  WarrantyActionPayload,
  WarrantyDoorIndentifierPayload,
  WarrantyInboxPayload,
  WarrantyReturnNotificationPayload,
  WarrantyReturnPayload,
  WarrantySparepartsActionPayload,
  WarrantyTextActionPayload,
} from "./aftersalesInterface";
import aftersalesService from "./aftersalesService";
import {
  saveAftersalesConversationalBrands,
  saveAftersalesConversationalError,
  saveAftersalesConversationalModelOptions,
  saveAftersalesConversationalSelectedModel,
  saveAftersalesConversationalSelectedVariant,
  saveAftersalesConversationalUpc,
  saveAftersalesVariantOptions,
  saveFastTrackParcelStatus,
  saveProductDetailsReturnParcel,
  saveReturnParcelDataStatus,
  saveSparePartsPdpCurrentItems,
  saveSparePartsPdpFamilies,
  saveSparePartsPdpLoading,
  saveSparePartsPdpModel,
  saveSparePartsPdpTechDraft,
  saveSparePartsPdpVariant,
  saveWarrantyAlternativeDetailByPartNumber,
  saveWarrantyInboxDetails,
  saveWarrantyInboxIdentifiers,
  saveWarrantyInboxList,
  saveWarrantyProductDetailByPartNumber,
  saveWarrantyReturn,
  saveWarrantyReturnNotification,
  selectSparePartsPdpModel,
  selectSparePartsPdpVariant,
  setFastTrackParcelData,
  setReturnParcelData,
  setSparePartsByFamilyStatus,
  setWarrantyInboxDetailsStatus,
  setWarrantyInboxIdentifiersStatus,
  setWarrantyInboxListStatus,
  setWarrantyReturnNotificationStatus,
  setWarrantyReturnStatus,
  setWarrantySendActionStatus,
  sliceName,
} from "./aftersalesSlice";
import { getAftersalesType } from "./aftersalesUtility";
import { getNotificationsAlerts, getSectionsAlerts } from "../accounting/accountingSagas";
import { allowedServicesIds } from "../../utils/accountingUtils";

/* ACTIONS */

/////////////////// conversational
export const getAftersalesByUpc = createAction<GetAftersalesByUpcPayload>(
  sliceName + "/getAftersalesByUpc"
);
export const getAftersalesBrands = createAction<string>(sliceName + "/getAftersalesBrands");
export const getAftersalesSearchProducts = createAction<AftersalesSearchPayload>(
  sliceName + "/getAftersalesSearchProducts"
);
export const getAftersalesSearchVariants = createAction<GetAftersalesItemPayload>(
  sliceName + "/getAftersalesSearchVariants"
);
export const getAftersalesProduct = createAction<GetAftersalesItemPayload>(
  sliceName + "/getAftersalesProduct"
);
export const getAftersalesVariant = createAction<GetAftersalesItemPayload>(
  sliceName + "/getAftersalesVariant"
);

/////////////////// spare-parts PDP
export const resolveSparePartsPdpSlug = createAction<string>(
  sliceName + "/resolveSparePartsPdpSlug"
);
export const getSparePartsPdpVariant = createAction<string>(sliceName + "/getSparePartsPdpVariant");
export const getSparePartsPdpProduct = createAction<string>(sliceName + "/getSparePartsPdpProduct");
export const getSparePartsPDPTechDraft = createAction<GetSparePartsPDPTechDraftPayload>(
  sliceName + "/getSparePartsPDPTechDraft"
);
export const getSparePartsPDPImagesCarousel = createAction<string>(
  sliceName + "/getSparePartsPDPImagesCarousel"
);
export const getSparePartsFamilies = createAction<GetSparePartsFamiliesPayload>(
  sliceName + "/getSparePartsFamilies"
);
export const getSparePartsByFamily = createAction<GetSparePartsByFamilyPayload>(
  sliceName + "/getSparePartsByFamily"
);

/////////////////// warranty inbox & returns
export const getWarrantyInboxList = createAction<WarrantyInboxPayload>(
  sliceName + "/getWarrantyInboxList"
);

export const getWarrantyInboxIdentifiers = createAction<string>(
  sliceName + "/getWarrantyInboxIdentifiers"
);

export const getWarrantyInboxDetails = createAction<WarrantyDoorIndentifierPayload>(
  sliceName + "/getWarrantyInboxDetails"
);

export const getWarrantyInboxCount = createAction<string>(sliceName + "/getWarrantyInboxCount");

export const getWarrantyReturn = createAction<WarrantyReturnPayload>(
  sliceName + "/getWarrantyReturn"
);

export const getWarrantyReturnNotification = createAction<WarrantyReturnNotificationPayload>(
  sliceName + "/getWarrantyReturnNotification"
);

export const postWarrantyInboxRead = createAction<WarrantyDoorIndentifierPayload>(
  sliceName + "/postWarrantyInboxRead"
);

export const sendWarrantyAction = createAction<WarrantyActionPayload>(
  sliceName + "/sendWarrantyAction"
);

export const sendWarrantyTextAction = createAction<WarrantyTextActionPayload>(
  sliceName + "/sendWarrantyTextActionSaga"
);

export const postWarrantySparepartsAction = createAction<WarrantySparepartsActionPayload>(
  sliceName + "/postWarrantySparepartsAction"
);

export const getWarrantyProductDetailsByPartNumber = createAction<GetWarrantyProductDetailsByPartNumberPayload>(
  sliceName + "/getWarrantyProductDetailsByPartNumber"
);

//////////// parcel
export const postWarrantyFastTrackParcelData = createAction<PostWarrantyParcelDataSagaPayload>(
  sliceName + "/postWarrantyFastTrackParcelData"
);

export const getWarrantyReturnParcelDataStatus = createAction(
  sliceName + "/getWarrantyReturnParcelDataStatus"
);

export const postWarrantyReturnParcelData = createAction<PostWarrantyParcelDataSagaPayload>(
  sliceName + "/postWarrantyReturnParcelData"
);

export const getWarrantyProductDetailsReturnParcel = createAction<GetWarrantyProductDetailsByPartNumberPayload>(
  sliceName + "/getWarrantyProductDetailsReturnParcel"
);

/////////////////// aftersales catalogue
export const getAftersalesPlpCatalogue = createAction<GetAftersalesPlpCatalogueSagaPayload>(
  sliceName + "/getAftersalesPlpCatalogue"
);

/* SAGAS */

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// CONVERSATIONAL //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get conversational results from UPC search
 *
 * @param {PayloadAction<GetAftersalesByUpcPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getAftersalesByUpcSaga(action: PayloadAction<GetAftersalesByUpcPayload>): SagaIterator {
  const qparams: GetAftersalesByUpcPayload = {
    ...action.payload,
  };

  const aftersalesType = getAftersalesType();
  if (aftersalesType) qparams.aftersalesType = aftersalesType;

  try {
    const { data } = yield call(aftersalesService.getAftersalesUpc, qparams);

    const entryDataLength = data?.data?.catalogEntryView?.length ?? 0;

    const upcResult =
      data?.data?.catalogEntryView?.[0] &&
      mapConversationalUpcResult(data.data.catalogEntryView[0], entryDataLength);
    yield put(saveAftersalesConversationalUpc(upcResult));

    yield put(
      getAftersalesProduct({
        productId: upcResult.relationshipProductId,
        doorId: action.payload.doorId,
      })
    ); // get model details
    yield put(
      getAftersalesVariant({
        variantId: upcResult.relationshipVariantId,
        doorId: action.payload.doorId,
      })
    ); // get product details

    //if there is a single product, then skip this and go in pdp.
    if (entryDataLength >= 1) {
      // get model list based on brandgroud
      yield put(
        getAftersalesSearchProducts({
          brandGroup: upcResult.brandGroup,
          term: "*",
          doorId: action.payload.doorId,
        })
      ); // get model dropdown values

      // intercept action to get part number of model, required to get variant list
      const modelAction = yield take(saveAftersalesConversationalSelectedModel.type);
      const model: Product = modelAction.payload;
      if (model.productCode)
        yield put(
          getAftersalesSearchVariants({
            productId: model.productCode,
            doorId: action.payload.doorId,
          })
        ); // get variant dropdown values
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveAftersalesConversationalUpc(null));
    yield put(
      saveAftersalesConversationalError({
        value: "upcResult",
        error: { type: "no-results", status: true },
      })
    );
  }
}

/**
 * Get list of brands for aftersales conversational
 *
 * @return {*}  {SagaIterator}
 */
function* getAftersalesBrandsSaga(action: PayloadAction<string>): SagaIterator {
  const qparams: SearchByFacetPayload = {
    doorId: action.payload,
    catalogType: ["aftersales"],
    facetName: ["brandGroup"],
  };

  const aftersalesType = getAftersalesType();
  if (aftersalesType) qparams.aftersalesType = aftersalesType;

  try {
    const { data } = yield call(catalogueService.searchByFacet, qparams);
    // const { data } = yield call(aftersalesService.getAftersalesBrands, qparams);

    const brandOptions =
      data?.data?.facetView?.[0]?.entry &&
      mapConversationalBrandsResult(data?.data?.facetView?.[0]?.entry);
    yield put(saveAftersalesConversationalBrands(brandOptions));

    if (brandOptions) {
      const brandList = brandOptions.map((brand: CustomOptions) => brand.value as string);
      yield put(getBrandImages({ brands: brandList }));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveAftersalesConversationalBrands([]));
    yield put(
      saveAftersalesConversationalError({
        value: "brandOptions",
        error: { type: "no-results", status: true },
      })
    );
  }
}

/**
 * Get list of models from selected brand for aftersales conversational
 *
 * @param {PayloadAction<AftersalesSearchPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getAftersalesSearchProductsSaga(
  action: PayloadAction<AftersalesSearchPayload>
): SagaIterator {
  const qparams = action.payload;

  const aftersalesType = getAftersalesType();
  if (aftersalesType) qparams.aftersalesType = aftersalesType;

  try {
    const { data } = yield call(aftersalesService.getAftersalesSearchProducts, qparams);
    const modelOptions =
      data?.data?.suggestionView?.[0]?.entry &&
      mapConversationalModelResult(data.data.suggestionView[0].entry);
    yield put(saveAftersalesConversationalModelOptions(modelOptions));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveAftersalesConversationalModelOptions([]));
    yield put(
      saveAftersalesConversationalError({
        value: "modelOptions",
        error: { type: "no-results", status: true },
      })
    );
  }
}

/**
 * Get list of variants from selected model for aftersales conversational
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* getAftersalesSearchVariantsSaga(
  action: PayloadAction<GetAftersalesItemPayload>
): SagaIterator {
  const payload: AftersalesSearchPayload = {
    ...action.payload,
    term: "*",
  };

  const aftersalesType = getAftersalesType();
  if (aftersalesType) payload.aftersalesType = aftersalesType;

  try {
    const { data } = yield call(aftersalesService.getAftersalesSearchVariants, payload);
    const variantOptions =
      data?.data?.suggestionView?.[0]?.entry &&
      mapConversationalVariantResult(data.data.suggestionView[0].entry);
    yield put(saveAftersalesVariantOptions(variantOptions));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveAftersalesVariantOptions([]));
    yield put(
      saveAftersalesConversationalError({
        value: "variantOptions",
        error: { type: "no-results", status: true },
      })
    );
  }
}

/**
 * Get tile details for selected model for aftersales conversational
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* getAftersalesProductSaga(action: PayloadAction<GetAftersalesItemPayload>): SagaIterator {
  try {
    const qparams = action.payload;
    const aftersalesType = getAftersalesType();
    if (aftersalesType) qparams.aftersalesType = aftersalesType;

    const { data } = yield call(aftersalesService.getAftersalesProduct, qparams);
    const selectedModel =
      data?.data?.catalogEntryView?.[0] && mapProductObj(data.data.catalogEntryView[0]);
    yield put(saveAftersalesConversationalSelectedModel(selectedModel));
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get tile details for selected variant for aftersales conversational
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* getAftersalesVariantSaga(action: PayloadAction<GetAftersalesItemPayload>): SagaIterator {
  try {
    const qparams = action.payload;
    const aftersalesType = getAftersalesType();
    if (aftersalesType) qparams.aftersalesType = aftersalesType;

    const { data } = yield call(aftersalesService.getAftersalesVariant, qparams);
    const selectedVariant =
      data?.data?.catalogEntryView?.[0] && mapVariantObj(data.data.catalogEntryView[0]);
    yield put(saveAftersalesConversationalSelectedVariant(selectedVariant));
  } catch (error) {
    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// SPARE-PARTS PDP //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/////////////////////////////// DETAILS

/**
 * Get info about the product/variant specified in slug (payload)
 * If the slug turns out to be a product, call getSparePartsPdpProduct to get the details
 * of the first variant available for that product.
 * Otherwise if it is a variant, call getSparePartsPdpVariant to get its details.
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* resolveSparePartsPdpSlugSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(saveSparePartsPdpLoading({ type: "pdpModel", value: true }));
    yield put(saveSparePartsPdpLoading({ type: "pdpVariant", value: true }));

    const { data } = yield call(catalogueService.resolveSlug, action.payload);

    if (data.data?.contents?.length > 0) {
      const type: string = data.data?.contents[0]?.page?.type;
      const tokenValue = data.data?.contents[0]?.tokenValue;

      switch (type) {
        case "ProductPage":
          yield put(getSparePartsPdpProduct(tokenValue));

          const saveModelAction = yield take(saveSparePartsPdpModel.type);
          const variantId = saveModelAction.payload.variants?.[0]?.uniqueID;
          if (variantId) yield put(getSparePartsPdpVariant(variantId)); // get details for first variant in product

          break;
        case "VariantPage":
          yield put(getSparePartsPdpVariant(tokenValue));

          const saveVariantAction = yield take(saveSparePartsPdpVariant.type);
          const productId = saveVariantAction.payload.parentCatalogEntryID;
          if (productId) yield put(getSparePartsPdpProduct(productId)); // get all variants for corresponding model

          break;
        default:
          break;
      }
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get details of the specified variant
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* getSparePartsPdpVariantSaga(action: PayloadAction<string>): SagaIterator {
  try {
    const { data } = yield call(catalogueService.getVariantDetails, {
      variantId: action.payload,
      catalogType: "aftersales",
    });

    if (data?.data?.catalogEntryView?.length > 0) {
      const variant = mapVariantObj(data.data.catalogEntryView[0]);
      yield put(saveSparePartsPdpVariant(variant)); // save main variant details

      yield put(
        getSparePartsPDPTechDraft({
          variantId: variant.uniqueID,
          modelId: variant.parentCatalogEntryID ?? "",
        })
      ); // get tech draft image
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get details of the first variant returned for the specified product
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* getSparePartsPdpProductSaga(action: PayloadAction<string>): SagaIterator {
  try {
    const { data } = yield call(catalogueService.getProductWithVariants, {
      productId: action.payload,
      catalogType: "aftersales",
    });

    if (data?.data?.catalogEntryView?.length > 0) {
      const product = mapProductObj(data.data.catalogEntryView[0]);
      if (product) yield put(saveSparePartsPdpModel(product)); // save model details w/ all its variants
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

/** TODO CAN REMOVE???
 * Get images for main PDP carousel
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* getSparePartsPDPImagesCarouselSaga(action: PayloadAction<string>): SagaIterator {
  try {
    const payload: GetAttachmentsVariantPayload = {
      variantId: action.payload,
      type: "PHOTO",
      catalogType: "aftersales",
      attachments: "attachments",
    };
    const { data } = yield call(catalogueService.getAttachmentsVariant, payload);
    // const imagesCarousel = mapAttachments(data?.data?.catalogEntryView?.[0]?.attachments);
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get technical draft for PDP (image on the right)
 *
 * @param {PayloadAction<GetSparePartsPDPTechDraftPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getSparePartsPDPTechDraftSaga(
  action: PayloadAction<GetSparePartsPDPTechDraftPayload>
): SagaIterator {
  try {
    const payload: GetAttachmentsVariantPayload = {
      variantId: action.payload.variantId,
      type: "TECH_DRAFT",
      catalogType: "aftersales",
      attachments: "attachments",
    };
    let res = yield call(catalogueService.getAttachmentsVariant, payload);
    if (!res.data.data.catalogEntryView[0]?.attachments) {
      payload.variantId = action.payload.modelId;
      res = yield call(catalogueService.getAttachmentsVariant, payload);
    }
    const imagesCarousel = mapAttachments(res.data.data.catalogEntryView[0]?.attachments);
    yield put(saveSparePartsPdpTechDraft(imagesCarousel));
  } catch (error) {
    yield put(handleError(error));
  }
}

/////////////////////////////// FAMILIES

/**
 * Get list of families for spareparts based on productId
 *
 * @param {PayloadAction<string>} action -> productId
 * @return {*}  {SagaIterator}
 */
function* getSparePartsFamiliesSaga(
  action: PayloadAction<GetSparePartsFamiliesPayload>
): SagaIterator {
  try {
    yield put(saveSparePartsPdpLoading({ type: "pdpFamilies", value: true }));

    const { data } = yield call(aftersalesService.getSparePartsFamilies, action.payload);
    const families = mapSparePartsFamilies(data.output);
    const groupedFamilies = mapSparePartsGroupedFamilies(families);

    yield put(saveSparePartsPdpFamilies(groupedFamilies));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveSparePartsPdpLoading({ type: "pdpFamilies", value: false }));
  }
}

/**
 * Get list of spareparts based on productId and selected family
 *
 * @param {PayloadAction<GetSparePartsByFamilyPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getSparePartsByFamilySaga(
  action: PayloadAction<GetSparePartsByFamilyPayload>
): SagaIterator {
  try {
    yield put(setSparePartsByFamilyStatus("LOADING"));
    const { data } = yield call(aftersalesService.getSparePartsByFamily, action.payload);

    const model: Product = yield select(selectSparePartsPdpModel); // get list of variants for the current model to fill in info for each tile by matching their color code
    const fallbackVariant: Variant = yield select(selectSparePartsPdpVariant); // get fallback variant (the one we landed in PDP w/) in case no match is found

    const sparePartsItems = mapSparePartsItems(data.output[0], fallbackVariant, model?.variants);

    yield put(saveSparePartsPdpCurrentItems(sparePartsItems));
    yield put(setSparePartsByFamilyStatus("SUCCESS"));
  } catch (error) {
    yield put(setSparePartsByFamilyStatus("ERROR"));
    yield put(handleError(error));
    yield put(saveSparePartsPdpCurrentItems(action.payload.idFamily));
  }
}

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// WARRANTY INBOX  //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 *
 *
 * @param {PayloadAction<WarrantyInboxPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getWarrantyInboxListSaga(action: PayloadAction<WarrantyInboxPayload>): SagaIterator {
  const params = { ...action.payload, pageSize: 10 };
  try {
    yield put(setWarrantyInboxListStatus("LOADING"));
    const data = yield call(aftersalesService.getWarrantyInboxList, params);

    yield put(saveWarrantyInboxList(data.data.output));
    yield put(setWarrantyInboxListStatus("SUCCESS"));
  } catch (error) {
    yield put(setWarrantyInboxListStatus("ERROR"));
    yield put(handleError(error));
  }
}

/**
 *
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* getWarrantyInboxIdentifiersSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setWarrantyInboxIdentifiersStatus("LOADING"));
    const { data } = yield call(aftersalesService.getWarrantyInboxSearchIdentifier, action.payload);

    if (data.output.searchIdentifierList)
      yield put(saveWarrantyInboxIdentifiers(data.output.searchIdentifierList));
    yield put(setWarrantyInboxIdentifiersStatus("SUCCESS"));
  } catch (error) {
    yield put(setWarrantyInboxIdentifiersStatus("ERROR"));
    yield put(saveWarrantyInboxIdentifiers([]));
    yield put(handleError(error));
  }
}

/**
 *
 *
 * @param {PayloadAction<WarrantyDoorIndentifierPayload>} action
 * @return {*}  {SagaIterator}
 */
function* postWarrantyInboxReadSaga(
  action: PayloadAction<WarrantyDoorIndentifierPayload>
): SagaIterator {
  try {
    yield call(aftersalesService.postWarrantyInboxRead, action.payload);
    yield put(getWarrantyInboxCount(action.payload.doorId));
    yield put(getSectionsAlerts(allowedServicesIds));
    yield put(getNotificationsAlerts());
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 *
 *
 * @param {PayloadAction<WarrantyDoorIndentifierPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getWarrantyInboxDetailsSaga(
  action: PayloadAction<WarrantyDoorIndentifierPayload>
): SagaIterator {
  try {
    yield put(setWarrantyInboxDetailsStatus("LOADING"));
    const data = yield call(aftersalesService.getWarrantyInboxDetails, action.payload);

    const inboxDetails = mapWarrantyInboxDetails(data?.data?.output);
    yield put(saveWarrantyInboxDetails(inboxDetails));
    yield put(setWarrantyInboxDetailsStatus("SUCCESS"));
  } catch (error) {
    yield put(setWarrantyInboxDetailsStatus("ERROR"));
    yield put(handleError(error));
  }
}

/**
 *
 *
 * @param {PayloadAction<WarrantyReturnPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getWarrantyReturnSaga(action: PayloadAction<WarrantyReturnPayload>): SagaIterator {
  const params = { ...action.payload, pageSize: 10, sortBy: "Newest" };
  try {
    yield put(setWarrantyReturnStatus("LOADING"));
    const data = yield call(aftersalesService.getWarrantyReturn, params);
    yield put(saveWarrantyReturn(data.data.output));
    yield put(setWarrantyReturnStatus("SUCCESS"));
  } catch (error) {
    yield put(setWarrantyReturnStatus("ERROR"));
    yield put(handleError(error));
  }
}

/**
 *
 *
 * @param {PayloadAction<WarrantyReturnNotificationPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getWarrantyReturnNotificationSaga(
  action: PayloadAction<WarrantyReturnNotificationPayload>
): SagaIterator {
  const params = { ...action.payload, pageSize: 10 };
  try {
    yield put(setWarrantyReturnNotificationStatus("LOADING"));
    const data = yield call(aftersalesService.getWarrantyReturnNotification, params);
    yield put(saveWarrantyReturnNotification(data.data.output));
    yield put(setWarrantyReturnNotificationStatus("SUCCESS"));
  } catch (error) {
    yield put(setWarrantyReturnNotificationStatus("ERROR"));
    yield put(handleError(error));
  }
}

/**
 *
 *
 * @param {PayloadAction<WarrantyActionPayload>} action
 * @return {*}  {SagaIterator}
 */
function* sendWarrantyActionSaga(action: PayloadAction<WarrantyActionPayload>): SagaIterator {
  try {
    yield put(setWarrantySendActionStatus("LOADING"));
    const callback = action.payload.callback; // save callback function
    delete action.payload.callback; // delete it from service payload

    const { data } = yield call(aftersalesService.sendWarrantyAction, action.payload);

    callback && callback(data?.output?.actionResult === "OK"); // execute callback, if present
    if (data?.output?.actionResult === "OK") yield put(setWarrantySendActionStatus("SUCCESS"));
    else yield put(setWarrantySendActionStatus("ERROR"));
  } catch (error) {
    yield put(setWarrantySendActionStatus("ERROR"));
    yield put(handleError(error));
  }
}

/**
 *
 *
 * @param {PayloadAction<WarrantyTextActionPayload>} action
 * @return {*}  {SagaIterator}
 */
function* sendWarrantyTextActionSaga(
  action: PayloadAction<WarrantyTextActionPayload>
): SagaIterator {
  try {
    yield put(setWarrantySendActionStatus("LOADING"));
    const callback = action.payload.callback; // save callback function
    delete action.payload.callback; // delete it from service payload

    const { data } = yield call(aftersalesService.sendWarrantyTextAction, action.payload);

    callback && callback(data?.output?.actionResult === "OK"); // execute callback, if present
    if (data?.output?.actionResult === "OK") yield put(setWarrantySendActionStatus("SUCCESS"));
    else yield put(setWarrantySendActionStatus("ERROR"));
  } catch (error) {
    yield put(setWarrantySendActionStatus("ERROR"));
    yield put(handleError(error));
  }
}

/**
 *
 *
 * @param {PayloadAction<WarrantySparepartsActionPayload>} action
 * @return {*}  {SagaIterator}
 */
function* postWarrantySparepartsActionSaga(
  action: PayloadAction<WarrantySparepartsActionPayload>
): SagaIterator {
  const doorId = yield select(selectSelectedDoor);
  const params = { ...action.payload, doorId: doorId.orgentityId };
  try {
    const { data } = yield call(aftersalesService.postWarrantySparepartsAction, params);
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get info for product and alternative product in warranty details page
 *
 * @param {PayloadAction<GetWarrantyProductDetailsByPartNumberPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getWarrantyProductDetailsByPartNumberSaga(
  action: PayloadAction<GetWarrantyProductDetailsByPartNumberPayload>
): SagaIterator {
  const { catalogType } = action.payload;
  const isAlternative = catalogType === "active";

  try {
    const { data } = yield call(
      aftersalesService.getWarrantyProductDetailsByPartNumber,
      action.payload
    );

    if (data?.data?.catalogEntryView.length > 0) {
      const rawSku = data?.data?.catalogEntryView[0];

      const sku = mapSkuObj(rawSku);

      if (isAlternative) yield put(saveWarrantyAlternativeDetailByPartNumber(sku));
      else yield put(saveWarrantyProductDetailByPartNumber(sku));
    }

    action.payload.callback && action.payload.callback();
  } catch (error) {
    if (isAlternative) yield put(saveWarrantyAlternativeDetailByPartNumber(null));
    else yield put(saveWarrantyProductDetailByPartNumber(null));
    yield put(handleError(error));
    action.payload.callback && action.payload.callback();
  }
}

/**
 * Save fast track parcel data
 *
 * @param {PayloadAction<PostWarrantyParcelDataSagaPayload>} {
 *   payload,
 * }
 * @return {*}  {SagaIterator}
 */
function* postWarrantyFastTrackParcelDataSaga({
  payload,
}: PayloadAction<PostWarrantyParcelDataSagaPayload>): SagaIterator {
  try {
    yield put(saveFastTrackParcelStatus("LOADING"));
    const storeIdentifier = yield select(selectStoreIdentifier);
    payload.storeIdentifier = storeIdentifier;
    const { data } = yield call(aftersalesService.postWarrantyFastTrackParcelDataSaga, payload);

    yield put(setFastTrackParcelData(data.output));
    yield put(saveFastTrackParcelStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(setFastTrackParcelData(null));
    yield put(saveFastTrackParcelStatus("ERROR"));
  }
}

/**
 * Save Return Parcel Data
 *
 * @param {PayloadAction<PostWarrantyParcelDataSagaPayload>} {
 *   payload,
 * }
 * @return {*}  {SagaIterator}
 */
function* postWarrantyReturnParcelDataSaga({
  payload,
}: PayloadAction<PostWarrantyParcelDataSagaPayload>): SagaIterator {
  try {
    yield put(
      saveReturnParcelDataStatus({
        id: `post-warranty-${payload.warrantyIdentifier}`,
        status: "LOADING",
      })
    );
    const storeIdentifier = yield select(selectStoreIdentifier);
    payload.storeIdentifier = storeIdentifier;
    const { data } = yield call(aftersalesService.postWarrantyReturnParcelData, payload);

    yield put(setReturnParcelData(data.output));
    yield put(
      saveReturnParcelDataStatus({
        id: `post-warranty-${payload.warrantyIdentifier}`,
        status: "SUCCESS",
      })
    );
  } catch (error) {
    yield put(handleError(error));
    yield put(setReturnParcelData(null));
    yield put(
      saveReturnParcelDataStatus({
        id: `post-warranty-${payload.warrantyIdentifier}`,
        status: "ERROR",
      })
    );
  }
}

/**
 * TODO REMOVE
 *
 * @return {*}  {SagaIterator}
 */
function* getWarrantyReturnParcelDataStatusSaga(): SagaIterator {
  try {
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get product details for return parcel label
 *
 * @param {PayloadAction<GetWarrantyProductDetailsByPartNumberPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getWarrantyProductDetailsReturnParcelSaga(
  action: PayloadAction<GetWarrantyProductDetailsByPartNumberPayload>
): SagaIterator {
  const { doorId, partNumber, catalogType } = action.payload;
  try {
    yield put(
      saveReturnParcelDataStatus({
        id: `get-warranty-product-details-${partNumber}`,
        status: "LOADING",
      })
    );

    const getProductDetailsPayload: GetWarrantyProductDetailsByPartNumberPayload = {
      doorId,
      partNumber: partNumber,
      catalogType: catalogType,
    };

    const { data } = yield call(
      aftersalesService.getWarrantyProductDetailsByPartNumber,
      getProductDetailsPayload
    );

    if (data?.data?.catalogEntryView.length > 0) {
      const rawSku = data?.data?.catalogEntryView[0];

      const sku = mapSkuObj(rawSku);

      yield put(saveProductDetailsReturnParcel(sku));
      yield put(
        saveReturnParcelDataStatus({
          id: `get-warranty-product-details-${partNumber}`,
          status: "SUCCESS",
        })
      );
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(
      saveReturnParcelDataStatus({
        id: `get-warranty-product-details-${partNumber}`,
        status: "ERROR",
      })
    );
  }
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////// AFTERSALES CATALOGUE ///////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getAftersalesPlpCatalogueSaga(
  action: PayloadAction<GetAftersalesPlpCatalogueSagaPayload>
): SagaIterator {
  try {
    yield put(setLoadingPlp({ type: "catalogue", value: "LOADING" }));
    yield put(setLoadingPlp({ type: "facets", value: "LOADING" }));

    yield put(setLoadingAnalyticsData(true));
    yield put(savePlpCatalogue(null));

    const { params, openExpandedTile } = action.payload;
    const pageSize = yield select(selectProductPerPage); // get number of products to display

    const searchParams: GetAftersalesPlpCatalogueSearchParams = {
      pageSize,
      pageNumber: "1",
      catalogType: "aftersales",
      orderBy: "RELEVANCE",
    };
    const filters: QueryParams = {};
    let term = "*";

    Object.keys(params).forEach((param) => {
      switch (param) {
        case "pageNumber":
          searchParams.pageNumber = params.pageNumber?.[0] ?? "1";
          break;
        case "orderBy":
          searchParams.orderBy = params.orderBy?.[0];
          break;
        case "doorId":
          searchParams.doorId = params.doorId?.[0];
          break;
        case "searchTerm":
          term = params.searchTerm?.[0];
          break;
        default:
          filters[param] = params[param];
          break;
      }
    });

    const aftersalesType = getAftersalesType();
    if (aftersalesType) searchParams.aftersalesType = aftersalesType;

    ////////////////////////////////////// get PLP items

    const { data } = yield call(aftersalesService.getAftersalesPlpCatalogue, {
      searchParams,
      term,
      filters,
    });

    const plpCatalogue: PlpCatalogueNoAdv = data?.data && mapPlpCatalogueNoAdv(data.data);
    if (plpCatalogue) {
      yield put(savePlpCatalogue(plpCatalogue as PlpCatalogue)); // if present, save results (and stop loading)
      yield put(setLoadingPlp({ type: "catalogue", value: "SUCCESS" }));

      yield put(savePlpFacetView(plpCatalogue?.facetView ?? null));
      yield put(
        setLoadingPlp({
          type: "facets",
          value: plpCatalogue?.facetView ? "SUCCESS" : "ERROR",
        })
      );

      if (term !== "*") {
        // if we landed in PLPAftersales with a selected model (through searchTerm)
        const searchedProduct = plpCatalogue.resultList.filter(
          (_) => _.seo?.href.substring(1).replace("-", " ") === term
        )?.[0]; // check is on seo, as we put that in searchTerm from conversational

        // and we find a matching product in the catalogue, open ExpandedTilePopup
        if (searchedProduct && openExpandedTile) yield put(setSelectedTile(searchedProduct));
      }
    } else {
      yield put(savePlpCatalogue(null)); // save empty catalogue
      yield put(savePlpFacetView(null));
      yield put(setLoadingPlp({ type: "catalogue", value: "ERROR" }));
      yield put(setLoadingPlp({ type: "facets", value: "ERROR" }));
    }

    ///////////////////////////////////// get Instagram badges
    const parNumbers: string[] = [];
    plpCatalogue?.resultList?.forEach((_: any) => {
      _?.productCode && parNumbers.push(_.productCode);
    });
    yield put(getPLPInstagramBadges({ partNumbers: parNumbers }));
    yield put(setLoadingAnalyticsData(false));
  } catch (error) {
    yield put(handleError(error));
    yield put(savePlpCatalogue(null)); // save empty catalogue
    yield put(savePlpFacetView(null));
    yield put(setLoadingPlp({ type: "catalogue", value: "ERROR" }));
    yield put(setLoadingPlp({ type: "facets", value: "ERROR" }));
  }
}

export function* aftersalesSaga(): SagaIterator {
  /////////////////// conversational
  yield takeEvery(getAftersalesByUpc.type, getAftersalesByUpcSaga);
  yield takeEvery(getAftersalesBrands.type, getAftersalesBrandsSaga);
  yield takeEvery(getAftersalesSearchProducts.type, getAftersalesSearchProductsSaga);
  yield takeEvery(getAftersalesSearchVariants.type, getAftersalesSearchVariantsSaga);
  yield takeEvery(getAftersalesProduct.type, getAftersalesProductSaga);
  yield takeEvery(getAftersalesVariant.type, getAftersalesVariantSaga);
  /////////////////// spare-parts PDP
  yield takeEvery(resolveSparePartsPdpSlug.type, resolveSparePartsPdpSlugSaga);
  yield takeEvery(getSparePartsPdpVariant.type, getSparePartsPdpVariantSaga);
  yield takeEvery(getSparePartsPdpProduct.type, getSparePartsPdpProductSaga);
  yield takeEvery(getSparePartsPDPImagesCarousel.type, getSparePartsPDPImagesCarouselSaga);
  yield takeEvery(getSparePartsPDPTechDraft.type, getSparePartsPDPTechDraftSaga);
  yield takeEvery(getSparePartsFamilies.type, getSparePartsFamiliesSaga);
  yield takeEvery(getSparePartsByFamily.type, getSparePartsByFamilySaga);

  /////////////////// warranty inbox
  yield takeLatest(getWarrantyInboxList.type, getWarrantyInboxListSaga);
  yield takeLatest(getWarrantyInboxIdentifiers.type, getWarrantyInboxIdentifiersSaga);
  yield takeLatest(getWarrantyInboxDetails.type, getWarrantyInboxDetailsSaga);
  yield takeLatest(getWarrantyReturn.type, getWarrantyReturnSaga);
  yield takeLatest(getWarrantyReturnNotification.type, getWarrantyReturnNotificationSaga);
  yield takeEvery(postWarrantyInboxRead.type, postWarrantyInboxReadSaga);
  yield takeEvery(sendWarrantyAction.type, sendWarrantyActionSaga);
  yield takeEvery(sendWarrantyTextAction.type, sendWarrantyTextActionSaga);
  yield takeEvery(postWarrantySparepartsAction.type, postWarrantySparepartsActionSaga);
  yield takeEvery(
    getWarrantyProductDetailsByPartNumber.type,
    getWarrantyProductDetailsByPartNumberSaga
  );

  /////////////////// warranty parcel
  yield takeEvery(postWarrantyFastTrackParcelData.type, postWarrantyFastTrackParcelDataSaga);
  yield takeEvery(
    getWarrantyProductDetailsReturnParcel.type,
    getWarrantyProductDetailsReturnParcelSaga
  );
  yield takeEvery(postWarrantyReturnParcelData.type, postWarrantyReturnParcelDataSaga);
  yield takeLatest(getWarrantyReturnParcelDataStatus.type, getWarrantyReturnParcelDataStatusSaga);

  /////////////////// aftersales catalogue
  yield takeEvery(getAftersalesPlpCatalogue.type, getAftersalesPlpCatalogueSaga);
}
