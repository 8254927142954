import { useMemo } from "react";
import { useParams } from "react-router-dom";

import usePLPGetCategories from "../components/pages/adt-plp/useGetAdtCategories";
import useTranslation from "./useTranslation";
import { useSelector } from "react-redux";
import { selectAdtCategories, selectCategoryLoadingStatus } from "../store/adt/adtSlice";

const useADTPlpTitle = (): {
  title: string;
  description: string;
  searchPlaceholder: string;
  isLoading: boolean;
} => {
  const { translateLabel } = useTranslation();

  const { categorySlug } = useParams() as {
    categorySlug: string;
  };

  // const { categories, status } = usePLPGetCategories();

  const categories = useSelector(selectAdtCategories);
  const loadingStatus = useSelector(selectCategoryLoadingStatus);

  return useMemo(() => {
    const categoryElem = categories.find((elem) => elem.id === categorySlug);
    const id = categoryElem ? categoryElem.id.toUpperCase() : "";

    return {
      title: translateLabel(`ADT_PLP_TITLE_${id}`),
      description: translateLabel(`ADT_PLP_DESCRIPTION_${id}`),
      searchPlaceholder: translateLabel(`ADT_PLP_SEARCH_PLACEHOLDER_${id}`),
      isLoading: loadingStatus === "LOADING",
    };
  }, [categories, loadingStatus, categorySlug]);
};

export default useADTPlpTitle;
