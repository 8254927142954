import { SagaIterator } from "@redux-saga/types";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { RedirectPayload } from "../../interfaces/mainInterfaces";
import {
  mapMultidoorAddress,
  mapOrderMultidoorArray,
  mapSubOrdersNotesAndPO,
} from "../../utils/cartUtils";
import {
  getAddCarnetRequestBody,
  mapOrderVoucher,
  mapVouchersOptions,
  updateCarnetMultidoor,
  updateCarnetOutcome,
} from "../../utils/checkoutUtils";
import { downloadURI } from "../../utils/utils";
import { OrderCategory, OrderItem, OrderMultidoor, SubOrderPayload } from "../cart/cartInterfaces";
import { getEssilorShippingInfo, getPrecartCount } from "../cart/cartSagas";
import cartService from "../cart/cartService";
import {
  selectCheckoutItems,
  selectPutPrecartShippingInfoPayload,
  selectShippingAddressSelected,
  startCartLoading,
  stopCartLoading,
} from "../cart/cartSlice";
import { rxServices } from "../rx/rxServices";
import { handleError, showErrorPopup } from "../store/storeSagas";
import { CurrencyFormat } from "../user/userInterfaces";
import { selectCurrencyFormat, selectLocale, selectStoreIdentifier } from "../user/userSlice";
import {
  CarnetMultidoor,
  CheckCarnetOutcome,
  GetOrderRecapPayload,
  OrderFieldsForVouchers,
  OrderItemParcel,
  SubmitEssilorOrderPayload,
  SubmitEssilorOrderPayloadWithRedirect,
  SubmitOrderPayload,
  SubmitOrderPayloadWithRedirect,
  VoucherValidationPayload,
} from "./checkoutInterfaces";
import checkoutServices from "./checkoutServices";
import {
  addCarnet,
  replaceSubOrdersNotesAndPO,
  saveCarnetOutcome,
  saveCheckCarnetStatus,
  saveCheckoutLoading,
  saveCheckoutMultidoorAddress,
  saveCheckoutOrderId,
  saveCheckoutOrderMultidoor,
  saveCheckoutOrderStatus,
  saveCheckoutTotalPrice,
  saveEssilorOrderConfirmationData,
  saveEssilorThankYouPageData,
  saveOrderParcelDetails,
  saveOrderPromotionVoucher,
  saveParcelIDs,
  saveVouchersOptions,
  saveVouchersOptionsStatus,
  saveVouchersValidation,
  saveVouchersValidationStatus,
  selectCarnetMultidoor,
  selectCarnetOutcome,
  selectCheckoutOrderId,
  selectCheckoutOrderMultidoor,
  selectEssilorOrderConfirmationHeaderData,
  selectEssilorOrderConfirmationLensDetails,
  selectSubOrdersNotesAndPO,
  setOrderParcelDetailsStatus,
  setRepeatableOrders,
  setRepeatedOrder,
  setRepeatOrderStatus,
  sliceName,
} from "./checkoutSlice";
import {
  mapOrderJsonHeaderDetails,
  mapOrderJsonLensDetails,
  mapOrderJsonThankYouPayload,
} from "../../utils/essilorUtils";
import { selectLxConfigurations } from "../store/storeSlice";

/************************************ ACTIONS ************************************/
/////////////// CHECKOUT
export const startCheckout = createAction<RedirectPayload>(sliceName + "/startCheckout");
export const getOrderConfirmation = createAction<RedirectPayload>(
  sliceName + "/getOrderConfirmation"
);
export const getEssilorOrderConfirmation = createAction<RedirectPayload>(
  sliceName + "/getEssilorOrderConfirmation"
);
export const submitOrder = createAction<SubmitOrderPayloadWithRedirect>(sliceName + "/submitOrder");
export const submitEssilorOrder = createAction<SubmitEssilorOrderPayloadWithRedirect>(
  sliceName + "/submitEssilorOrder"
);

export const repeatOrder = createAction<string>(sliceName + "/repeatOrder");

/////////////// vouchers
export const getVouchersOptions = createAction<OrderFieldsForVouchers>(
  sliceName + "/getVouchersOptions"
);
export const validateVouchers = createAction<VoucherValidationPayload>(
  sliceName + "/validateVouchers"
);

/////////////// carnet RX
export const carnetCheck = createAction<CarnetMultidoor>(sliceName + "/carnetCheck");

/////////////// THANK YOU PAGE
export const getOrderRecap = createAction<GetOrderRecapPayload>(sliceName + "/getOrderRecap");
export const exportCSVTYP = createAction<string>(sliceName + "/exportCSVTYP");

/////////////// parcel
export const getParcelIDs = createAction<OrderMultidoor[]>(sliceName + "/getParcelIDs");
export const getOrderParcelDetails = createAction<string[]>(sliceName + "/getOrderParcelDetails");

/************************************ SAGAS ************************************/

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// CHECKOUT /////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Checkout selected order items
 *
 * @return {*}  {SagaIterator}
 */
function* startCheckoutSaga(action: PayloadAction<RedirectPayload>): SagaIterator {
  try {
    yield put(startCartLoading({ id: "checkout", type: null }));

    // get shipping addresses for each door
    const shippingAddresses = yield select(selectPutPrecartShippingInfoPayload);
    yield call(cartService.putPrecartShippingInfo, shippingAddresses); // send shipping addresses

    // get selected items list to bring to checkout
    const checkoutItems = yield select(selectCheckoutItems);
    const { data } = yield call(checkoutServices.postCart, checkoutItems); // start checkout

    yield put(saveCheckoutOrderId(data.data.checkoutOrderId)); // update checkoutOrderId
    action.payload.redirect(); // redirect to order confirmation
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
  } finally {
    yield put(stopCartLoading({ id: "checkout", type: null }));
  }
}

/**
 * Get order confirmation page details
 *
 * @return {*}  {SagaIterator}
 */
function* getOrderConfirmationSaga(action: PayloadAction<RedirectPayload>): SagaIterator {
  try {
    const { data } = yield call(checkoutServices.getCart);

    yield put(saveCheckoutOrderId(data.data.orderId)); // update checkoutOrderId
    yield put(saveCheckoutOrderStatus(data.data.orderStatus)); // update checkoutOrderStatus
    yield put(saveCheckoutTotalPrice(Number(data.data.grandTotal))); // update checkoutTotalPrice

    const multidoorList = mapOrderMultidoorArray(data.data.multidoorResponseList);
    yield put(saveCheckoutOrderMultidoor(multidoorList)); // update checkoutOrderMultidoor

    const multidoorAddresses = mapMultidoorAddress(data.data.multidoorResponseList);
    yield put(saveCheckoutMultidoorAddress(multidoorAddresses)); // update checkoutMultidoorAddress

    const subOrdersNotesAndPO = mapSubOrdersNotesAndPO(data.data.multidoorResponseList);
    yield put(replaceSubOrdersNotesAndPO(subOrdersNotesAndPO)); // update subOrdersNotesAndPO
  } catch (error) {
    if (error?.response?.status === 404) {
      action.payload.redirect(); // redirect to cart if no checkout is active
    } else yield put(handleError(error));
  }
}

/**
 * Get order confirmation for Essilor cart
 *
 * @return {*}  {SagaIterator}
 */
function* getEssilorOrderConfirmationSaga(action: PayloadAction<RedirectPayload>): SagaIterator {
  try {
    const { data } = yield call(cartService.getPrecart, true); //True because is Essilor

    const lensDetails = yield select(selectEssilorOrderConfirmationLensDetails);
    const headerData = yield select(selectEssilorOrderConfirmationHeaderData);
    const isEmptyOrderData = lensDetails == null || headerData == null;

    const LXConfiguration = yield select(selectLxConfigurations);

    //if there is no data taken by SendSingleOrderEntry
    if (isEmptyOrderData) {
      const isAPIFallback =
        LXConfiguration?.filter(
          (element: any) => element.key === "com.luxottica.oneportal.api.fallback"
        )?.[0]?.value === "true";

      // if fallback is false by configuration it means orderJson is received in precart
      if (!isAPIFallback) {
        const order = data?.data?.orderExtendAttribute?.find(
          (_: any) => _?.attributeName === "OnePortalJson"
        )?.["attributeValue"];
        const orderJSON = order ? JSON.parse(order) : undefined;

        if (orderJSON) {
          yield put(
            saveEssilorOrderConfirmationData({
              lensDetails: mapOrderJsonLensDetails(orderJSON),
              headerData: mapOrderJsonHeaderDetails(orderJSON),
            })
          );
          yield put(saveOrderPromotionVoucher(mapOrderVoucher(orderJSON))); // update rx fields for vouchers
        } else action.payload.redirect();
      } else action.payload.redirect(); // redirect to cart if no checkout is active
    }

    yield put(getEssilorShippingInfo());

    yield put(saveCheckoutOrderId(data.data.orderId)); // update checkoutOrderId
    yield put(saveCheckoutOrderStatus(data.data.orderStatus)); // update checkoutOrderStatus
    yield put(saveCheckoutTotalPrice(Number(data.data.grandTotal))); // update checkoutTotalPrice

    const multidoorList = mapOrderMultidoorArray(data.data.multidoorResponseList);
    yield put(saveCheckoutOrderMultidoor(multidoorList)); // update checkoutOrderMultidoor

    const multidoorAddresses = mapMultidoorAddress(data.data.multidoorResponseList);
    yield put(saveCheckoutMultidoorAddress(multidoorAddresses)); // update checkoutMultidoorAddress

    const subOrdersNotesAndPO = mapSubOrdersNotesAndPO(data.data.multidoorResponseList);
    yield put(replaceSubOrdersNotesAndPO(subOrdersNotesAndPO)); // I need the subOrderId here
  } catch (error) {
    if (error?.response?.status === 404) {
      action.payload.redirect(); // redirect to cart if no checkout is active
    } else yield put(handleError(error));
  }
}

/**
 * Submit order
 *
 * @return {*}  {SagaIterator}
 */
function* submitOrderSaga(action: PayloadAction<SubmitOrderPayloadWithRedirect>): SagaIterator {
  try {
    yield put(saveCheckoutLoading({ type: "submitOrder", value: true }));

    let checkoutOrderId = yield select(selectCheckoutOrderId); // get checkout orderId
    const subOrderPayload: SubOrderPayload[] = yield select(selectSubOrdersNotesAndPO); // get notes and PO numbers to submit

    for (let i = 0; i < subOrderPayload.length; i++)
      yield call(checkoutServices.updateCart, subOrderPayload[i], checkoutOrderId); // calls to update PO number + notes for each suborder

    const { data } = yield call(checkoutServices.postCheckout, action.payload.order); // submit order

    checkoutOrderId = data.data.orderId; // get checkoutOrderId
    checkoutOrderId && action.payload.redirect(checkoutOrderId); // redirect to thank you page with correct checkoutOrderId
    yield put(getPrecartCount(true));
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
  } finally {
    yield put(saveCheckoutLoading({ type: "submitOrder", value: false }));
  }
}

/**
 * Submit Essilor order
 *
 * @return {*}  {SagaIterator}
 */
function* submitEssilorOrderSaga(
  action: PayloadAction<SubmitEssilorOrderPayloadWithRedirect>
): SagaIterator {
  try {
    yield put(saveCheckoutLoading({ type: "submitEssilorOrder", value: true }));

    const shippingAddress = yield select(selectShippingAddressSelected);
    const orderItems: OrderMultidoor[] = yield select(selectCheckoutOrderMultidoor);

    //Set up the selected shipping address
    yield call(
      cartService.putPrecartShippingInfo,
      {
        orderItem: [
          {
            addressId: shippingAddress[0].addressId,
            orderItemId: orderItems[0].categoryList[0].orderItemList[0].orderItemId,
          },
        ],
      },
      true
    );

    let checkoutOrderId = yield select(selectCheckoutOrderId); // get checkout orderId
    const order: SubmitEssilorOrderPayload = action.payload.order; // get notes and PO numbers to submit
    yield call(checkoutServices.updateCartEssilor, order, checkoutOrderId); // calls to update PO number + notes for each suborder

    const { data: cartData } = yield call(checkoutServices.postCart, {
      orderId: order.orderId,
      orderItem: [orderItems[0].categoryList[0].orderItemList[0].orderItemId],
    }); //True because is Essilor

    const postCheckoutPayload: SubmitOrderPayload = {
      orderId: cartData?.data?.checkoutOrderId,
      orderItem: [{ orderItemId: cartData?.data?.checkoutOrderItems?.[0] }],
    };

    // Add vouchers data if available
    if (action.payload?.voucher)
      postCheckoutPayload.orderItem[0].orderItemExtendAttribute = action.payload.voucher;

    const { data } = yield call(checkoutServices.postEssilorCheckout, postCheckoutPayload); // submit order

    checkoutOrderId = data.data.orderId; // get checkoutOrderId
    checkoutOrderId && action.payload.redirect(checkoutOrderId); // redirect to thank you page with correct checkoutOrderId
    yield put(getPrecartCount(true));
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
    if (error?.response?.data?.errors?.[0]?.code == "_ERR_OP_VOUCHER_ERROR")
      yield put(saveVouchersValidation(false));
  } finally {
    yield put(saveCheckoutLoading({ type: "submitEssilorOrder", value: false }));
  }
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// CARNET RX /////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* carnetCheckSaga(action: PayloadAction<CarnetMultidoor>): SagaIterator {
  try {
    yield put(
      saveCheckCarnetStatus({
        status: "LOADING",
        orderItem: action.payload.orderItems?.[0].orderItemIdentifier,
      })
    );

    // get info needed to fill in payload
    const currency: CurrencyFormat = yield select(selectCurrencyFormat);
    const storeId = yield select(selectStoreIdentifier);
    const locale = yield select(selectLocale);

    const currentCarnetMultidoor: CarnetMultidoor[] = yield select(selectCarnetMultidoor); // get current list of applied carnets (organized by door)

    // get updated list by adding carnet received as payload
    // it also updates the helper property keeping track of how many times a certain carnetCode has been applied
    // to allow the server to figure out which was the last carnet added chronologically
    const newCarnetMultidoor = updateCarnetMultidoor(
      cloneDeep(currentCarnetMultidoor),
      action.payload,
      action.payload.currencyFromOrderConfirmation || currency.currency.opt,
      storeId,
      locale
    );

    // get service request body based on the last carnet added
    // - only carnets applied to the same door (customerIdentifier) are relevant
    // - only carnets with the same carnetCode are relevant
    const requestBody = getAddCarnetRequestBody(
      newCarnetMultidoor,
      action.payload.doorId,
      action.payload.orderItems?.[0].carnetCode
    );

    const { data } = yield call(checkoutServices.postCarnetCheck, requestBody);

    ////////// update outcome
    const currentCarnetOutcome: CheckCarnetOutcome[] = yield select(selectCarnetOutcome);
    const { newCarnetOutcome, outcomeStatus } = updateCarnetOutcome(
      cloneDeep(currentCarnetOutcome),
      data?.data?.output,
      action.payload.orderItems?.[0].orderItemIdentifier,
      action.payload.orderItems?.[0].carnetCode
    ); // get updated outcome by adding/replacing the current order item's info
    yield put(saveCarnetOutcome(newCarnetOutcome));

    if (outcomeStatus === "success") yield put(addCarnet(newCarnetMultidoor)); // only add carnet to redux if result is positive

    yield put(
      saveCheckCarnetStatus({
        status: "SUCCESS",
        orderItem: action.payload.orderItems?.[0].orderItemIdentifier,
      })
    );
  } catch (error) {
    yield put(handleError(error));
    yield put(
      saveCheckCarnetStatus({
        status: "ERROR",
        orderItem: action.payload.orderItems?.[0].orderItemIdentifier,
      })
    );
  }
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// THANK YOU PAGE //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get order recap to display in thank you page
 *
 * @return {*}  {SagaIterator}
 */
function* getOrderRecapSaga(action: PayloadAction<GetOrderRecapPayload>): SagaIterator {
  try {
    const { data } = yield call(checkoutServices.getOrderRecap, action.payload.checkoutOrderId);

    yield put(saveCheckoutOrderId(data?.data?.orderId)); // update checkoutOrderId
    yield put(saveCheckoutOrderStatus(data?.data?.orderStatus)); // update checkoutOrderStatus
    yield put(saveCheckoutTotalPrice(data?.data?.grandTotal)); // update checkoutTotalPrice

    const multidoorList = mapOrderMultidoorArray(data?.data?.multidoorResponseList);
    if (multidoorList) yield put(saveCheckoutOrderMultidoor(multidoorList)); // update checkoutOrderMultidoor

    const multidoorAddresses = mapMultidoorAddress(data?.data?.multidoorResponseList);
    if (multidoorAddresses) yield put(saveCheckoutMultidoorAddress(multidoorAddresses)); // update checkoutMultidoorAddress

    const orderJSON = JSON.parse(
      data?.data?.orderExtendAttribute?.find((_: any) => _?.attributeName === "OnePortalJson")[
        "attributeValue"
      ]
    );
    yield put(saveEssilorThankYouPageData(mapOrderJsonThankYouPayload(orderJSON)));
  } catch (error) {
    if (
      error?.response?.status === 404 ||
      error?.response?.status === 400 ||
      error?.response?.status === 500
    ) {
      action.payload.redirect(); // redirect to order confirmation if thank you page is not found
    } else yield put(handleError(error));
  }
}

/**
 * Get order recap to display in thank you page
 *
 * @return {*}  {SagaIterator}
 */
function* exportCSVTYPSaga(action: PayloadAction<string>): SagaIterator {
  try {
    const { data } = yield call(checkoutServices.exportCSV, action.payload);

    if (data) {
      const csv = data;
      const csvContent = csv.replace(new RegExp(",", "g"), ";");
      const encodedUri = "data:text/csv;charset=utf-8,\uFEFF" + encodeURIComponent(csvContent);
      if (csv) downloadURI(encodedUri, `order-details-${action.payload}.csv`);
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// VOUCHERS ////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
/**
 * Get a list of vouchers associated with the given OnePortal.
 *
 * @return {*}  {SagaIterator}
 */
function* getVouchersOptionsSaga(action: PayloadAction<OrderFieldsForVouchers>): SagaIterator {
  try {
    yield put(saveVouchersOptionsStatus("LOADING"));
    const { data } = yield call(checkoutServices.retrieveVouchers, action.payload);
    const vouchersOptions = mapVouchersOptions(data?.data?.vouchers);
    if (vouchersOptions) yield put(saveVouchersOptions(vouchersOptions));
    else yield put(saveVouchersOptions(null));
    yield put(saveVouchersOptionsStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveVouchersOptions(null));
    yield put(saveVouchersOptionsStatus("ERROR"));
  }
}

/**
 * Validate vouchers.
 *
 * @return {*}  {SagaIterator}
 */
function* validateVouchersSaga(action: PayloadAction<VoucherValidationPayload>): SagaIterator {
  try {
    yield put(saveVouchersValidationStatus("LOADING"));

    const { data } = yield call(checkoutServices.validateVouchers, action.payload);
    if (data?.data?.errorCode === "0") {
      yield put(saveVouchersValidation(true));
      yield put(saveVouchersValidationStatus("SUCCESS"));
    } else {
      yield put(saveVouchersValidation(false));
      yield put(saveVouchersValidationStatus("ERROR"));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveVouchersValidation(false));
    yield put(saveVouchersValidationStatus("ERROR"));
  }
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// PARCEL //////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getParcelIDsSaga(action: PayloadAction<OrderMultidoor[]>): SagaIterator {
  const productIds: string[] = [];

  action.payload.forEach((orderMultidoor: OrderMultidoor) => {
    orderMultidoor.categoryList.forEach((orderCategory: OrderCategory) => {
      orderCategory.orderItemList.forEach((orderItem: OrderItem) => {
        if (orderItem.xitemField1 === "4") {
          productIds.push(orderItem.orderItemId);
        }
      });
    });
  });
  yield put(saveParcelIDs(productIds));
}

function* getOrderParcelDetailsSaga(action: PayloadAction<string[]>): SagaIterator {
  try {
    const temp: OrderItemParcel[] = [];
    yield put(setOrderParcelDetailsStatus("LOADING"));

    for (let i = 0; i < action.payload.length; i++) {
      try {
        const { data } = yield call(rxServices.getCartItem, action.payload[i]);
        temp.push(data.data.orderItem[0]);
      } catch (error) {
        yield put(setOrderParcelDetailsStatus("ERROR"));
        yield put(handleError(error));
      }
    }

    yield put(saveOrderParcelDetails(temp));
    yield put(setOrderParcelDetailsStatus("SUCCESS"));
  } catch (error) {
    yield put(setOrderParcelDetailsStatus("ERROR"));
    yield put(handleError(error));
  }
}
function* repeatOrderSaga({ payload }: PayloadAction<string>): SagaIterator {
  try {
    yield put(setRepeatOrderStatus("LOADING"));
    yield put(setRepeatedOrder(payload));
    const { data } = yield call(checkoutServices.repeatOrder, payload);
    yield put(setRepeatableOrders({ data: data?.data, errors: data?.errors }));
    yield put(setRepeatOrderStatus("SUCCESS"));
  } catch (error) {
    yield put(setRepeatOrderStatus("ERROR"));
    yield put(handleError(error));
  }
}

export function* checkoutSaga(): SagaIterator {
  yield takeLatest(startCheckout.type, startCheckoutSaga);
  yield takeLatest(getOrderConfirmation.type, getOrderConfirmationSaga);
  yield takeLatest(getEssilorOrderConfirmation.type, getEssilorOrderConfirmationSaga);
  yield takeLatest(submitOrder.type, submitOrderSaga);
  yield takeLatest(submitEssilorOrder.type, submitEssilorOrderSaga);
  yield takeLatest(getOrderRecap.type, getOrderRecapSaga);
  yield takeLatest(getVouchersOptions.type, getVouchersOptionsSaga);
  yield takeLatest(validateVouchers.type, validateVouchersSaga);
  yield takeEvery(exportCSVTYP.type, exportCSVTYPSaga);
  yield takeEvery(repeatOrder.type, repeatOrderSaga);

  /////////////// carnet RX
  yield takeEvery(carnetCheck.type, carnetCheckSaga);

  /////////////// parcel
  yield takeEvery(getParcelIDs.type, getParcelIDsSaga);
  yield takeEvery(getOrderParcelDetails.type, getOrderParcelDetailsSaga);
}
