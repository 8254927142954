import React from "react";
import ContentLoader from "react-content-loader";

const LoaderSummary = (): JSX.Element => (
  <ContentLoader
    speed={2}
    width={325}
    height={294}
    viewBox="0 0 325 294"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="342" y="90" rx="0" ry="0" width="294" height="126" />
    <rect x="653" y="90" rx="0" ry="0" width="294" height="126" />
    <rect x="793" y="41" rx="1" ry="1" width="152" height="18" />
    <rect x="0" y="0" rx="0" ry="0" width="325" height="249" />
  </ContentLoader>
);

export default LoaderSummary;
