import { sortBy } from "lodash";
import { FacetViewEntry } from "../interfaces/facetInterfaces";
import { GenericCustomObject } from "../interfaces/mainInterfaces";
import { ContactInfo } from "../store/store/storeInterfaces";
import { BrandGroups, FirstLevelMenu, SecondLevelMenu } from "./../interfaces/menuInterfaces";
import { composeUrlWithParams } from "./utils";

export interface BrandGroupByType {
  kids: ColBrandGroup[];
  adults: ColBrandGroup[];
}

export interface ColBrandGroup {
  brands: string[];
  brandgroup: string;
  label?: string;
  junior: boolean;
  isStars: boolean;
  link: string;
  facetName: string | undefined;
  facetValue: string | undefined;
}

export interface GlassesCategory {
  [key: string]: { all: string[]; adults: string[]; kids: string[] };
}

export const GLASSES_CATEGORY: GlassesCategory = {
  Eyeglasses: {
    all: ["Eyeglasses", "Eyeglasses Kids", "Reader"],
    adults: ["Eyeglasses", "Reader"],
    kids: ["Eyeglasses Kids", "Reader"],
  },
  Sunglasses: {
    all: ["Sunglasses", "Sunglasses Kids"],
    adults: ["Sunglasses"],
    kids: ["Sunglasses Kids"],
  },
  "Smart Glasses": {
    all: ["Smart Glasses"],
    adults: ["Smart Glasses"],
    kids: [],
  },
};

export type GlassesCategoryKeys = keyof typeof GLASSES_CATEGORY;

/**
 * Check if the considered filters belong to the specified glassesCategory,
 * also specifying whether we should consider the adults, kids or all subgroups.
 *
 * @param {string[]} filters
 * @param {GlassesCategory} glassesCategory
 * @param {("all" | "adults" | "kids")} age
 * @return {*}  {boolean}
 */
export const isGlassesCategory = (
  filters: string[],
  glassesCategory: GlassesCategoryKeys,
  age: "all" | "adults" | "kids"
): boolean => {
  return (
    filters?.every((filter) => GLASSES_CATEGORY[glassesCategory]?.[age]?.includes(filter)) &&
    GLASSES_CATEGORY[glassesCategory]?.[age]?.some((filter) => filters?.includes(filter))
  );
};

export interface GogglesHelmetsCateogory {
  [key: string]: { all: string[]; adults: string[]; kids: string[] };
}

export const GOGGLES_HELMETS_CATEGORY: GlassesCategory = {
  GOGGLES_HELMETS: {
    all: [],
    adults: ["Adult"],
    kids: ["Children"],
  },
};

export type GogglesHelmetsCategoryKeys = keyof typeof GOGGLES_HELMETS_CATEGORY;

/**
 * Check if the considered filters belong to the specified glassesCategory,
 * also specifying whether we should consider the adults, kids or all subgroups.
 *
 * @param {string[]} filters
 * @param {GlassesCategory} glassesCategory
 * @param {("all" | "adults" | "kids")} age
 * @return {*}  {boolean}
 */
export const isGogglesHelmetsCategory = (
  filters: string[],
  gogglesHelmetsCategory: GogglesHelmetsCategoryKeys,
  age: "all" | "adults" | "kids"
): boolean => {
  return (
    filters?.every((filter) =>
      GOGGLES_HELMETS_CATEGORY[gogglesHelmetsCategory]?.[age]?.includes(filter)
    ) &&
    GOGGLES_HELMETS_CATEGORY[gogglesHelmetsCategory]?.[age]?.some((filter) =>
      filters?.includes(filter)
    )
  );
};

/**
 * divide brands in brand group and by adult and kids
 * @param {BrandGroups} brandMenu
 * @param {FacetViewEntry[]} facet
 * @return {*}  {BrandGroupByType}
 */
export const groupBrandGroupByType = (
  brandGroupMap: BrandGroups,
  labels: { [brand: string]: string },
  facet: FacetViewEntry[],
  baseUrl: string,
  completeUrl: string,
  facetIdentifier: string | undefined,
  considerBrandsAsAdult = false
): BrandGroupByType => {
  let brandsGroups: ColBrandGroup[] = [];
  let brandsGroupsJunior: ColBrandGroup[] = [];

  if (brandGroupMap && facet) {
    const brandFacetArray = facet.map((element) => element.identifier);
    const uniqueBrandFacetArray = [...new Set(brandFacetArray)];

    const isStarsMap: { [key: string]: boolean } = {};
    facet.forEach((element) => {
      isStarsMap[element.identifier] = !!element.isStars;
    });

    //--- for each brand in filter, get specific brand group
    uniqueBrandFacetArray.map((brand) => {
      for (const [brandGroupKey, brandObj] of Object.entries(brandGroupMap)) {
        brandObj &&
          brandObj.map((_) => {
            if (_.brand === brand) {
              if (_.junior) {
                //---- GET INDEX BRANDGROUP
                const indexBG = brandsGroupsJunior.findIndex(
                  (brandgroup) => brandgroup.brandgroup === brandGroupKey
                );
                //---- IF ALREADY PRESENT
                if (indexBG !== -1) {
                  //---- ADD SINGLE BRAND IN BRANDGROUP
                  const singleBG = brandsGroupsJunior[indexBG];
                  if (!singleBG.brands.includes(brand)) {
                    singleBG.brands.push(brand);
                    //if one brand is stars --> brand group is stars
                    singleBG.isStars = singleBG.isStars || isStarsMap[brand];
                  }
                } else {
                  //---- ELSE ADD BRAND GROUP
                  const singleBG = {
                    brands: [brand],
                    brandgroup: brandGroupKey,
                    label: labels[brandGroupKey],
                    junior: _.junior,
                    isStars: isStarsMap[brand],
                    link: baseUrl,
                    facetName: facetIdentifier,
                    facetValue: brand,
                  };

                  brandsGroupsJunior.push(singleBG);
                }
              } else {
                //---- GET INDEX BRANDGROUP
                const indexBG = brandsGroups.findIndex(
                  (brandgroup) => brandgroup.brandgroup === brandGroupKey
                );
                //---- IF ALREADY PRESENT
                if (indexBG !== -1) {
                  //---- ADD SINGLE BRAND IN BRANDGROUP
                  const singleBG = brandsGroups[indexBG];
                  if (!singleBG.brands.includes(brand)) {
                    singleBG.brands.push(brand);
                    //if one brand is stars --> brand group is stars
                    singleBG.isStars = singleBG.isStars || isStarsMap[brand];
                  }
                } else {
                  //---- ELSE ADD BRAND GROUP
                  const singleBG = {
                    brands: [brand],
                    brandgroup: brandGroupKey,
                    label: labels[brandGroupKey],
                    junior: _.junior,
                    isStars: isStarsMap[brand],
                    link: baseUrl,
                    facetName: facetIdentifier,
                    facetValue: brand,
                  };

                  brandsGroups.push(singleBG);
                }
              }
            }
          });
      }
    });
  }

  if (considerBrandsAsAdult) {
    //merge kids into adult list
    //get kid or adult base url
    brandsGroupsJunior.forEach((brandsGroupJunior) => {
      const indexBG = brandsGroups.findIndex(
        (brandgroup) => brandgroup.brandgroup === brandsGroupJunior.brandgroup
      );
      if (indexBG >= 0) {
        //merge adult and kids info
        const brandsGroupAdult = brandsGroups[indexBG];
        brandsGroupAdult.isStars = brandsGroupAdult.isStars || brandsGroupJunior.isStars;
        //set complete url to get both kids and adults
        brandsGroupAdult.link = completeUrl;
        //merge kids and adults brands
        brandsGroupJunior.brands.forEach((brand) => {
          if (!brandsGroupAdult.brands.includes(brand)) {
            brandsGroupAdult.brands.push(brand);
          }
        });
      } else {
        //brand group not found in adult array --> push kid element
        brandsGroups.push(brandsGroupJunior);
      }
    });

    //empty kids list
    brandsGroupsJunior = [];
  }

  //set final link into ColBrandGroup
  brandsGroups = insertUrlIntoColBrandGroup(brandsGroups);
  brandsGroupsJunior = insertUrlIntoColBrandGroup(brandsGroupsJunior);

  //--- ORDER BY LABELS
  const adults = sortBy(brandsGroups, ["label"]);
  const kids = sortBy(brandsGroupsJunior, ["label"]);

  //--- SPLIT IN KIDS AND ADULTS
  // const [kids, adults] = partition(sortedBrandGroups, (_) => _.junior);

  return { kids, adults };
};

/**
 *
 * @param {ColBrandGroup[]} brandsGroups
 * @param {string} baseUrl
 * @returns brandsGroups with correct link
 */
const insertUrlIntoColBrandGroup = (brandsGroups: ColBrandGroup[]) => {
  return brandsGroups?.map((brand) => {
    let link = brand?.link;
    if (brand?.isStars && !link?.includes("plp-stars")) {
      link = link?.replace("plp", "plp-stars");
    }

    return {
      ...brand,
      link: composeUrlWithParams(
        link,
        brand.brands.map((b) => {
          return { key: "manufacturer.raw", value: b };
        })
      ),
    };
  });
};

/**
 * get brandGroup from brand
 * @param  {BrandGroups} brandGroup
 * @param  {string} brand
 * @returns string
 */
/* export const getBrandGroupByBrand = (brandGroup: GenericCustomObject, brand: string): string => {
  return brandGroup[brand];
}; */

// TODO: remove b/c not used anywhere? should be replaced by selectBrandGroupByBrandMap
// export const getBrandGroupByBrand = (brandGroup: BrandGroups, brand: string): string => {
//   if (brandGroup[brand]) {
//     return brand;
//   } else {
//     for (const [key, value] of Object.entries(brandGroup)) {
//       if (!!value.find((_) => _.brand === brand)) {
//         return key;
//       }
//     }
//   }
//   return "";
// };

export const mapBrandGroupByBrandUtil = (brandGroup: BrandGroups): any => {
  const mappedBrandGroup: GenericCustomObject = {};
  for (const [key, value] of Object.entries(brandGroup)) {
    value &&
      value.map((element) => {
        mappedBrandGroup[element.brand] = key;
      });
  }
  return mappedBrandGroup;
};

/**
 * Get menu items' urls
 *
 * @param {SecondLevelMenu} menu
 * @return {*}  {string}
 */
export const getUrlWithFilters = (
  menu: SecondLevelMenu,
  isSpecific?: "kids" | "adults"
): string => {
  if (menu.identifier !== "LENSES") {
    const params = new URLSearchParams();

    if (menu.filters && menu.filters.length > 0) {
      menu.filters.forEach((filter) => {
        if (
          filter.type &&
          filter.identifier &&
          isGlassesCategory(
            [filter.identifier],
            menu.identifier as GlassesCategoryKeys,
            isSpecific ?? "all"
          )
        )
          params.append(filter.type, filter.identifier);
      });
    }
    if (menu.categoryIdentifier === "GOGGLES_HELMETS") {
      menu.filters.forEach((filter) => {
        if (
          filter.type &&
          filter.identifier &&
          isGogglesHelmetsCategory(
            [filter.identifier],
            menu.identifier as GogglesHelmetsCategoryKeys,
            isSpecific ?? "all"
          )
        )
          params.append(filter.type, filter.identifier);
      });
    }

    let url = "/plp" + menu.seo?.href;

    if (params && params.toString().length > 0) {
      url += "?" + params.toString();
    }
    return url;
  }
  return "";
};

/**
 * Get uniqueId for frames category from menu
 *
 * @param {FirstLevelMenu} menu
 * @return {*}  {string}
 */
export const getUniqueIdFrames = (menu: FirstLevelMenu[]): string => {
  let uniqueId = "";
  menu.forEach((singleMenu) => {
    if (singleMenu.identifier === "PRODUCTS") {
      singleMenu.catalogGroupView?.every((secondMenu) => {
        if (secondMenu.categoryIdentifier === "FRAMES") {
          uniqueId = secondMenu.uniqueID;
          return false;
        }
        return true;
      });
    }
  });

  return uniqueId;
};
////////////// FOOTER MENU UTILS //////////////

export const mapContactInfo = (data: any): ContactInfo => {
  return {
    address1: data?.address?.addressLine.length ? data?.address?.addressLine[0] : null,
    address2: data?.address?.addressLine.length ? data?.address?.addressLine[1] : null,
    address3: data?.address?.addressLine.length ? data?.address?.addressLine[2] : null,
    phone: data?.telephone1,
    email: data?.emailAddress1,
    hours: data?.emailAddress2,
  };
};

export const getCompleteId = (uniqueID: string, identifier: string): string => {
  return uniqueID + "_" + identifier?.replace(" ", "_").toUpperCase();
};

export const getBrandBaseUrl = (brand: ColBrandGroup, baseUrl: string) => {
  if (brand?.isStars && !baseUrl?.includes("plp-stars")) {
    return baseUrl?.replace("plp", "plp-stars");
  } else {
    return baseUrl;
  }
};

export const LENSES_COLUMN_BASE_URL = "/contents-corporate/";
