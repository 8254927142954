import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MultipleRequestStatus,
  RequestStatus,
  SetMultipleRequestStatus,
} from "../../interfaces/mainInterfaces";
import { RootState } from "../storeConfig";

import {
  PrivilegesTemplate,
  SubuserItem,
  SubuserList,
  SubuserLoading,
  SubuserState,
  PrivilegeGroup,
} from "./subuserInterfaces";

export const sliceName = "subuser";

const DEFAULT_SUBUSER_LIST_LOADING: MultipleRequestStatus<SubuserLoading> = {
  MyELSubusers: "IDLE",
  LeoSubusers: "IDLE",
  subuserItem: "IDLE",
  subuserUpdate: "IDLE",
};

const initialState: SubuserState = {
  subuserListMyEL: null,
  subuserListLeo: null,
  subuserListLoading: DEFAULT_SUBUSER_LIST_LOADING,

  subuserItem: null,
  subusersPerPage: 11,
  isSubuserUpdateSuccess: null,
  subuserUpdateError: null,
  isEmailSuccessful: null,
  privilegeGroups: null,
  privilegesTemplatesList: null,
  getUserPrivilegesStatus: "IDLE",
};

export const subuserSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveSubuserListMyEL: (state, action: PayloadAction<SubuserList<"MyELSubusers">>) => {
      state.subuserListMyEL = action.payload;
    },
    saveSubuserListLeo: (state, action: PayloadAction<SubuserList<"LeoSubusers">>) => {
      state.subuserListLeo = action.payload;
    },
    saveSubuserItem: (state, action: PayloadAction<SubuserItem>) => {
      state.subuserItem = action.payload;
    },
    cleanSubuserItem: (state) => {
      state.subuserItem = null;
    },
    setSubuserListLoading: (
      state,
      action: PayloadAction<SetMultipleRequestStatus<SubuserLoading>>
    ) => {
      state.subuserListLoading = {
        ...state.subuserListLoading,
        [action.payload.type]: action.payload.value,
      };
    },
    saveIsSubuserUpdateSuccess: (state, action: PayloadAction<boolean | null>) => {
      state.isSubuserUpdateSuccess = action.payload;
    },
    saveSubuserUpdateError: (state, action: PayloadAction<string | null>) => {
      state.subuserUpdateError = action.payload;
    },
    setIsEmailSuccessful: (state, action: PayloadAction<boolean | null>) => {
      state.isEmailSuccessful = action.payload;
    },
    setPrivilegeGroups: (state, action: PayloadAction<PrivilegeGroup[]>) => {
      state.privilegeGroups = action.payload;
    },
    setPrivilegesTemplatesList: (state, action: PayloadAction<PrivilegesTemplate[]>) => {
      state.privilegesTemplatesList = action.payload;
    },
    setGetUserPrivilegesStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getUserPrivilegesStatus = action.payload;
    },
    resetSubuserState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  saveSubuserListMyEL,
  saveSubuserListLeo,
  saveSubuserItem,
  cleanSubuserItem,
  setSubuserListLoading,
  saveIsSubuserUpdateSuccess,
  setIsEmailSuccessful,
  setPrivilegeGroups,
  setPrivilegesTemplatesList,
  setGetUserPrivilegesStatus,
  resetSubuserState,
  saveSubuserUpdateError,
} = subuserSlice.actions;

export const selectSubuserListMyEL = (state: RootState): SubuserList<"MyELSubusers"> | null =>
  state.subuser.subuserListMyEL;

export const selectSubuserListLeo = (state: RootState): SubuserList<"LeoSubusers"> | null =>
  state.subuser.subuserListLeo;

export const selectSubuserItem = (state: RootState): SubuserItem | null =>
  state.subuser.subuserItem;

export const selectPageSize = (state: RootState): number => state.subuser.subusersPerPage;

export const selectSubuserLoading = (state: RootState): MultipleRequestStatus<SubuserLoading> =>
  state.subuser.subuserListLoading;

export const selectIsSubuserUpdateSuccess = (state: RootState): boolean | null =>
  state.subuser.isSubuserUpdateSuccess;

export const selectSubuserUpdateError = (state: RootState): string | null =>
  state.subuser.subuserUpdateError;

export const selectIsEmailSuccessful = (state: RootState): boolean | null =>
  state.subuser.isEmailSuccessful;

export const selectPrivilegeGroups = (state: RootState): PrivilegeGroup[] | null =>
  state.subuser.privilegeGroups;

export const selectPrivilegesTemplatesList = (state: RootState): PrivilegesTemplate[] | null =>
  state.subuser.privilegesTemplatesList;

export const selectGetUserPrivilegesStatus = (state: RootState): RequestStatus =>
  state.subuser.getUserPrivilegesStatus;

export default subuserSlice.reducer;
