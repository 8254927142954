import React from "react";
import styled from "styled-components/macro";

interface Props {
  sizePx: number;
  styleLoader?: "light-background" | "dark-background";
}
const Loader = ({ sizePx, styleLoader = "dark-background" }: Props): JSX.Element => (
  <WrapLoader sizePx={sizePx} styleLoader={styleLoader} />
);

export default Loader;

const WrapLoader = styled.div<Props>`
  font-size: ${(props) => props.sizePx / 8}px;
  border: 1em solid ${(props) => props.theme.palette.background.light};
  border-top: 1em solid ${(props) => props.theme.palette.primary};
  border-radius: 50%;
  width: ${(props) => props.sizePx}px;
  height: ${(props) => props.sizePx}px;
  animation: spin 1s linear infinite;

  ${(props) =>
    props.styleLoader === "light-background" &&
    `
    border: 1em solid ${props.theme.palette.gray.medium};
    border-top: 1em solid ${props.theme.palette.primary};  
  `}

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
