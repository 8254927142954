import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";
import useDisplayPrice from "../../../../hooks/useDisplayPrice";
import useFilteredSelector from "../../../../hooks/useFilteredSelector";
import useGenerateKeyFromObject from "../../../../hooks/useGenerateKeyFromObject";

import {
  OrderMultidoorSummary,
  OrderMultidoorInfo,
  ShippingAddressSummaryMultidoor,
  OrderCategoryInfo,
  OrderCategorySummary,
  ShippingAddressSummary,
  CartLoading,
  SubOrderPayload,
} from "../../../../store/cart/cartInterfaces";
import { CurrencyFormat } from "../../../../store/user/userInterfaces";
import { selectCurrencyFormat } from "../../../../store/user/userSlice";
import {
  saveShippingAddressSelected,
  selectCartLoading,
  selectSelectedItems,
  selectSubOrdersNotesAndPO,
} from "../../../../store/cart/cartSlice";
import { printShippingAddressSummary } from "../../../../utils/cartUtils";
import CustomSelect, { CustomOptions } from "../../../styled-UI/CustomSelect";
import ButtonCart from "../../../styled-UI/ButtonCart";
import CustomText from "../../../styled-UI/CustomText";
import ButtonCartNote from "../../../styled-UI/ButtonCartNote";
import CurrencyFormatter from "../../../widgets/CurrencyFormatter";
import { getSectionTitle } from "../cart-content/CartSection";
import CartNotesPopup from "./CartNotesPopup";
import ConditionalRender from "../../../widgets/ConditionalRender";

interface Props {
  orderMultidoorSummary: OrderMultidoorSummary;
  orderMultidoorInfo: OrderMultidoorInfo;
  shippingAddressMultidoor: ShippingAddressSummaryMultidoor;
  isMultidoor?: boolean;
  showError?: boolean;
  currencyPrice?: string;
}

interface CategoryPriceLineProps {
  orderCategorySummary: OrderCategorySummary;
  orderCategoryInfo: OrderCategoryInfo;
  currencyFromUserContext: CurrencyFormat;
  currencyFromSuborder?: string;
  currencyPrice?: string;
}

export const CategoryPriceLine = ({
  orderCategorySummary,
  orderCategoryInfo,
  currencyFromUserContext,
  currencyFromSuborder,
  currencyPrice,
}: CategoryPriceLineProps): JSX.Element => {
  const { translateLabel } = useTranslation();

  return (
    <PriceLine className="margin-top" key={orderCategorySummary.productCategory}>
      <CustomText as="p" color="text-blue" fontSizePx={14} font="font-regular" lineHeightPx={24}>
        {`${getSectionTitle(orderCategorySummary?.productCategory, false, translateLabel)} (${
          orderCategoryInfo?.selectedNumber
        })`}
      </CustomText>
      <CustomText as="p" color="white" fontSizePx={18} font="font-medium">
        <CurrencyFormatter
          currency={currencyPrice || currencyFromSuborder || currencyFromUserContext.currency.opt}
          locale={currencyFromUserContext.locale}
          value={orderCategorySummary.totalPrice}
        ></CurrencyFormatter>
      </CustomText>
    </PriceLine>
  );
};

const CartPriceSummary = ({
  orderMultidoorSummary,
  orderMultidoorInfo,
  shippingAddressMultidoor,
  isMultidoor = false,
  showError = false,
  currencyPrice,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const keyGen = useGenerateKeyFromObject();
  const selectedItems = useSelector(selectSelectedItems);

  // price display settings
  const { hideWholesale } = useDisplayPrice();
  const currencyFormat = useSelector(selectCurrencyFormat);

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// ADDRESSES ////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const selectRef = React.useRef<HTMLInputElement>(null);

  // map value-label pairs for CustomSelect
  const shippingAddressList: CustomOptions[] = shippingAddressMultidoor
    ? shippingAddressMultidoor?.addressList?.map((_: ShippingAddressSummary) => {
        return {
          value: _.addressId,
          label: printShippingAddressSummary(_),
        };
      })
    : [];

  useEffect(() => {
    if (shippingAddressList?.length === 1) saveAddress(shippingAddressList[0].value as string);
  }, [shippingAddressMultidoor]);

  const saveAddress = (selectedAddress: string) => {
    dispatch(
      saveShippingAddressSelected({
        multidoorId: orderMultidoorSummary.multidoorId,
        addressId: selectedAddress,
      })
    );
  };

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////// CUSTOMER SERVICE NOTES /////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const [customerNotes, setCustomerNotes] = useState<string>("");
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  // get notes from redux
  const subOrderNotes = useFilteredSelector<SubOrderPayload>(
    selectSubOrdersNotesAndPO,
    (_) => _.subOrderId === orderMultidoorInfo?.subOrderId
  );

  // sync customerNotes with the service, in case something went wrong
  useEffect(() => {
    setCustomerNotes(subOrderNotes?.payload.customerServiceNote ?? "");
  }, [keyGen.getKey(orderMultidoorSummary), subOrderNotes]);

  // loading
  const customerNotesLoading = useFilteredSelector<CartLoading>(
    selectCartLoading,
    (_) => _.id === orderMultidoorInfo?.subOrderId
  );

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////// RENDER //////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  return (
    <PriceSummary key={orderMultidoorSummary.multidoorId}>
      <CustomText
        as="span"
        color="text-blue"
        fontSizePx={14}
        font="font-regular"
        lineHeightPx={24}
        marginTopPx={32}
      >
        {isMultidoor && orderMultidoorSummary.orgentityName + " - "}
        {translateLabel("CART_SUMMARY_SELECT_ADDRESS_TITLE")}
      </CustomText>

      {/* ADDRESS SELECT */}
      <SelectContainer>
        <CustomSelect
          type="white"
          options={shippingAddressList}
          placeholder={translateLabel("CART_SUMMARY_SELECT_ADDRESS_PLACEHOLDER")}
          onChange={(selectedValue) => {
            if (selectRef.current) selectRef.current.value = selectedValue.value as string;
            saveAddress(selectedValue.value as string);
          }}
          {...(shippingAddressList?.length === 1 && { defaultValue: shippingAddressList[0] })}
          isError={showError && selectRef.current?.value === undefined}
          errorStyle="warning-white"
          helperText={
            showError && shippingAddressList?.length !== 1 && selectRef.current?.value === undefined
              ? translateLabel("CART_SUMMARY_SELECT_ADDRESS_HELPER_TEXT")
              : ""
          }
          ref={selectRef}
        />
      </SelectContainer>

      {/* CUSTOMER SERVICE NOTES */}
      <ConditionalRender privilege="NOTES_CS">
        {customerNotes ? (
          <ButtonCartNote
            type="dark"
            text={customerNotes}
            onClick={() => setIsPopupOpen(true)}
            disabled={customerNotesLoading?.type === "customer-service-notes"}
            isLoading={customerNotesLoading?.type === "customer-service-notes"}
            data-element-id="Summary_NoteToCustomer_Confirm"
          />
        ) : (
          <ButtonCart
            type="dark"
            text={translateLabel("CART_NOTE_CUSTOMER_SERVICE_BUTTON")}
            onClick={() => setIsPopupOpen(true)}
            disabled={customerNotesLoading?.type === "customer-service-notes"}
            isLoading={customerNotesLoading?.type === "customer-service-notes"}
            data-element-id="Summary_NoteToCustomer_Confirm"
          />
        )}

        <CartNotesPopup
          isPopupOpen={isPopupOpen}
          closePopup={() => setIsPopupOpen(false)}
          subOrderId={orderMultidoorInfo?.subOrderId as string}
          customerNotes={customerNotes}
          setCustomerNotes={(customerNotes: string) => setCustomerNotes(customerNotes)}
        />
      </ConditionalRender>

      {/* CATEGORIES PRICES */}
      {!hideWholesale &&
        orderMultidoorSummary.categoryPrice.map((orderCategorySummary: OrderCategorySummary) => {
          const selectedItemsForDoor = selectedItems.find(
            (item: any) => item.orgentityName === orderCategorySummary.orgentityName
          );
          const hasAtLeastOneItem = selectedItemsForDoor?.categorySelected?.some((catItems: any) =>
            catItems.orderItemSelected.some((item: any) => item.selected)
          );

          return (
            orderCategorySummary.productCategory !== "Unallocated" &&
            hasAtLeastOneItem && (
              <CategoryPriceLine
                key={orderCategorySummary.productCategory}
                orderCategorySummary={orderCategorySummary}
                orderCategoryInfo={
                  orderMultidoorInfo.categorySelected.filter(
                    (orderCategoryInfo: OrderCategoryInfo) =>
                      orderCategoryInfo.productCategory == orderCategorySummary.productCategory
                  )[0]
                }
                currencyFromUserContext={currencyFormat}
                currencyPrice={currencyPrice}
              />
            )
          );
        })}

      {/* TOTAL PRICE */}
      {!hideWholesale && orderMultidoorSummary.totalPrice !== 0 && (
        <TotalPrice>
          <CustomText as="p" color="white" fontSizePx={18} font="font-bold" lineHeightPx={24}>
            {translateLabel("CART_TOTAL")}
          </CustomText>
          <CustomText as="p" color="white" fontSizePx={18} font="font-bold" lineHeightPx={24}>
            <CurrencyFormatter
              currency={currencyPrice || currencyFormat.currency.opt}
              locale={currencyFormat.locale}
              value={orderMultidoorSummary.totalPrice}
            ></CurrencyFormatter>
          </CustomText>
        </TotalPrice>
      )}
    </PriceSummary>
  );
};

const SelectContainer = styled.div`
  margin: 0.5rem 0 2rem 0;
`;

const PriceLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.margin-top {
    margin-top: 2rem;
  }
`;

const TotalPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.palette.bordersBlue};
  margin-top: 2rem;
  padding-top: 2rem;
  box-sizing: border-box;
`;

const PriceSummary = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.bordersBlue};
  padding-bottom: 1.813rem;

  &:last-child {
    border-bottom: unset;
  }
`;

export default CartPriceSummary;
