import React from "react";
import styled from "styled-components/macro";

import CustomText from "../../styled-UI/CustomText";
import TileTags from "../Tile/TileTags";
import PriceTile from "../PriceTile";

import { Variant } from "../../../interfaces/productInterface";
import { useHistory } from "react-router-dom";
import { getPagePath } from "../../../routing/routesUtils";
import { resetLpVariantsList, togglePopupLpVariants } from "../../../store/store/storeSlice";
import { useDispatch } from "react-redux";

interface Props {
  variant: Variant;
}

const LpVariantTile = ({ variant }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = () => {
    dispatch(togglePopupLpVariants({ open: false, productCode: null }));
    dispatch(resetLpVariantsList());
    history.push(getPagePath("/pdp" + variant.seo?.href));
  };
  return (
    <Container onClick={handleClick}>
      <Content>
        <Header>
          <TitleContainer title={variant.frontColorDescription?.values}>
            <CustomText as="span" fontSizePx={13} font="font-bold" color="white" isEllipsis>
              {variant.frontColorDescription?.values}
            </CustomText>
          </TitleContainer>
        </Header>
        <ImageContainer src={variant.img ?? ""} />
        <TagsContainer>
          <TileTags tags={variant.tags} brand={variant.brand ?? ""} />
        </TagsContainer>
        <PriceTile price={variant.price} />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 18rem;
  min-height: 22.5rem;
  padding: 1.375rem 1.5625rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid ${(props) => props.theme.palette.primary};
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px ${(props) => props.theme.palette.gray.medium};
  box-sizing: border-box;
`;

const TitleContainer = styled.div`
  display: flex;
  border-radius: 0.125rem;
  color: ${(props) => props.theme.palette.white};
  background-color: ${(props) => props.theme.palette.primary};
  padding: 0 0.3125rem 0.125rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ImageContainer = styled.img`
  height: 8.125rem;
  margin: 2.125rem 0 2.0625rem;
  mix-blend-mode: multiply;
  object-fit: cover;
`;

const TagsContainer = styled.div`
  min-height: 3rem;
  max-height: 5rem;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-grow: 1;
`;

export default LpVariantTile;
