import { AxiosResponse } from "axios";

import { createAndExecuteService } from "../serviceUtils";
import {
  PaymentConfirmationRequest,
  PaymentPayload,
  PaymentSimulationRequest,
  PaymentFilterPayload,
  PaymentHistoryDetailRequestPayload,
  DeletePaymentMethodPayload,
  GetPaymentMethodDetailRequest,
  AddPaymentMethodOldB2BServiceRequest,
  EditPaymentMethodOldB2BServiceRequest,
} from "./paymentInterface";
import {
  deselectionReasonsUrl,
  paymentConfirmationUrl,
  paymentManagementInformationUrl,
  paymentSimulationUrl,
  paymentStatementListUrl,
  paymentDocumentHistoryListUrl,
  paymentHistoryUrl,
  paymentHistoryDetailsUrl,
  paymentMethodUrl,
  paymentMethodOldB2BCardUrl,
  paymentMethodOldB2BBankUrl,
  paymentMethodOldB2BUrl,
  paymentMethodOldB2BCardDetailsUrl,
  paymentMethodOldB2BBankDetailsUrl,
  paymentConfirmationOldB2BUrl,
  getPaymentMethodValidationUrl,
} from "./paymentUrls";

export default {
  ////////// PAYMENT MANAGEMENT
  getPaymentManagementInformation: async (data: any): Promise<AxiosResponse> => {
    return createAndExecuteService(paymentManagementInformationUrl, "POST", null, data);
  },

  getDeselectionReasons: async (doorParam: string): Promise<AxiosResponse> => {
    const params = new URLSearchParams({ door: doorParam });
    return createAndExecuteService(deselectionReasonsUrl, "GET", params);
  },

  postPaymentSimulation: async ({
    qparams,
    payload,
  }: PaymentSimulationRequest): Promise<AxiosResponse> => {
    const params = new URLSearchParams({ door: qparams.door });
    return createAndExecuteService(paymentSimulationUrl, "POST", params, payload);
  },

  postPaymentConfirmation: async ({
    qparams,
    payload,
  }: PaymentConfirmationRequest): Promise<AxiosResponse> => {
    const params = new URLSearchParams({ door: qparams.door });
    return createAndExecuteService(paymentConfirmationOldB2BUrl, "POST", params, payload);
  },

  ////////// PAYMENT METHOD
  getPaymentMethodValidation: async (doorParam: string): Promise<AxiosResponse> => {
    const params = new URLSearchParams({ door: doorParam });
    return createAndExecuteService(getPaymentMethodValidationUrl, "GET", params);
  },

  // proposal #1 - service to get/add/edit/delete main info from BE, but additional info is retrieved through cybersource

  getPaymentMethod: async (doorParam: string): Promise<AxiosResponse> => {
    const params = new URLSearchParams({ door: doorParam });
    return createAndExecuteService(paymentMethodUrl, "GET", params);
  },

  // addPaymentMethod: async (payload: AddPaymentMethodPayload): Promise<AxiosResponse> => {
  //   return createAndExecuteService(paymentMethodUrl, "POST", null, payload);
  // },

  // editPaymentMethod: async (payload: AddPaymentMethodPayload): Promise<AxiosResponse> => {
  //   return createAndExecuteService(paymentMethodUrl, "PUT", null, payload);
  // },

  // deletePaymentMethod: async (payload: DeletePaymentMethodPayload): Promise<AxiosResponse> => {
  //   return createAndExecuteService(paymentMethodUrl, "DELETE", null, payload);
  // },

  // proposal #2 - everything goes through BE
  getPaymentMethodOldB2BCard: async (doorParam: string): Promise<AxiosResponse> => {
    const params = new URLSearchParams({ door: doorParam });
    return createAndExecuteService(paymentMethodOldB2BCardUrl, "GET", params);
  },

  getPaymentMethodOldB2BBank: async (doorParam: string): Promise<AxiosResponse> => {
    const params = new URLSearchParams({ door: doorParam });
    return createAndExecuteService(paymentMethodOldB2BBankUrl, "GET", params);
  },

  getPaymentMethodOldB2BDetails: async ({
    qparams,
    payload,
  }: GetPaymentMethodDetailRequest): Promise<AxiosResponse> => {
    const url =
      payload.type === "cardId"
        ? paymentMethodOldB2BCardDetailsUrl
        : paymentMethodOldB2BBankDetailsUrl;

    const requestBody = { [payload.type]: payload.id };
    const params = new URLSearchParams({ door: qparams.door });

    return createAndExecuteService(url, "POST", params, requestBody);
  },

  addPaymentMethodOldB2B: async ({
    qparams,
    payload,
  }: AddPaymentMethodOldB2BServiceRequest): Promise<AxiosResponse> => {
    const { paymentMethodInfo, subscriptionType, ...rest } = payload;
    const requestBody = {
      ...rest,
      ...paymentMethodInfo,
    };
    const params = new URLSearchParams({ door: qparams.door });

    const url =
      subscriptionType === "CreditCard" ? paymentMethodOldB2BCardUrl : paymentMethodOldB2BBankUrl;

    return createAndExecuteService(url, "POST", params, requestBody);
  },

  editPaymentMethodOldB2B: async ({
    qparams,
    payload,
  }: EditPaymentMethodOldB2BServiceRequest): Promise<AxiosResponse> => {
    const { paymentMethodInfo, subscriptionType, ...rest } = payload;
    const requestBody = {
      ...rest,
      ...paymentMethodInfo,
    };
    const params = new URLSearchParams({ door: qparams.door });
    const url =
      subscriptionType === "CreditCard" ? paymentMethodOldB2BCardUrl : paymentMethodOldB2BBankUrl;

    return createAndExecuteService(url, "PUT", params, requestBody);
  },

  deletePaymentMethodOldB2B: async ({
    qparams,
    payload,
  }: DeletePaymentMethodPayload): Promise<AxiosResponse> => {
    const params = new URLSearchParams({ door: qparams.door });
    return createAndExecuteService(paymentMethodOldB2BUrl, "DELETE", params, payload);
  },

  ////////// PAYMENT HISTORY
  postPaymentHistoryList: async (payload: PaymentFilterPayload): Promise<AxiosResponse> => {
    const { doorId, ...params } = payload;
    const qparams = new URLSearchParams();
    qparams.append("doorId", doorId);
    return createAndExecuteService(paymentHistoryUrl, "POST", new URLSearchParams(qparams), params);
  },

  /////////// PAYMENT HISTORY DETAILS
  postPaymentHistoryDetails: async (
    payload: PaymentHistoryDetailRequestPayload
  ): Promise<AxiosResponse> => {
    return createAndExecuteService(paymentHistoryDetailsUrl, "POST", null, payload);
  },

  ////////// PAYMENT DOCUMENT HISTORY
  postPaymentDocumentHistoryList: async (payload: PaymentFilterPayload): Promise<AxiosResponse> => {
    const { doorId, ...params } = payload;
    const qparams = new URLSearchParams();
    qparams.append("doorId", doorId);
    return createAndExecuteService(
      paymentDocumentHistoryListUrl,
      "POST",
      new URLSearchParams(qparams),
      params
    );
  },

  ////////// PAYMENT STATEMENT
  postPaymentStatementList: async (payload: PaymentPayload): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    qparams.append("doorId", payload.doorId);
    return createAndExecuteService(
      paymentStatementListUrl,
      "POST",
      new URLSearchParams(qparams),
      {}
    );
  },

  ////////// MISC
};
