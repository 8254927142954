import { Door, UserHavingPrivilege } from "../store/user/userInterfaces";
export interface PrivilegeObject {
  [key: string]: string[];
}

export const pricePrivileges = ["SUGGESTED_RETAIL_PRICE", "WHOLESALE_PRICE", "ALL_PRICES"];
export type PrivilegesKeysOR = keyof PrivilegesMapOR;

export type PrivilegesMapOR = {
  WARRANTY: string[];
};

//it must have at least one of these privileges

//it must have at least one of these privileges
export const privilegesMapOR: PrivilegeObject = {
  ORDER_CREATION: ["FINISHED_PRODUCT_ORDER_CREATION", "AFA_ORDER_CREATION"],
  WARRANTY: ["WARRANTY_DIGITAL", "WARRANTY_FAST_TRACK", "WARRANTY_REPAIR", "WARRANTY_STANDARD"],
  M4C: ["SMART_SHOPPER_STAGING_AREA", "SMART_SHOPPER_QUICK_ORDER"],
  SIMPLE_SR: ["SR_1", "SR_2", "SR_3", "SR_4", "SR_5"],
  SERVICE_REQUEST_PAGE: [
    "SR_1",
    "SR_2",
    "SR_3",
    "SR_4",
    "SR_5",
    "SR_6",
    "SR_7",
    "SR_8",
    "SR_9",
    "SR_10",
    "SR_11",
  ],
  PAYMENT: ["PAYMENT_FULL_MODE", "PAYMENT_VIEW_ONLY"],
  CATALOGUE: ["FINISHED_PRODUCT_SECTION", "AFA_SECTION"],
  ADT_CATEGORY: [
    "ADT_CAMPAIGN_MATERIALS",
    "ADT_NEWSLETTER",
    "ADT_CATEGORY_SOCIAL_MEDIA_MATERIALS",
    "ADT_LOGOS",
    "ADT_ECOMM_PACKS",
  ],
  RX_WITH_ESSILOR: [
    "RX_COMPLETE_JOB",
    "RX_FRAME_TO_COME",
    "RX_LENS_ONLY",
    "LENS_DIGITAL_CATALOGUE",
    "LENS_DIGITAL_CATALOGUE_ORDER_ENTRY",
  ],
  RX_ESSILOR: ["LENS_DIGITAL_CATALOGUE", "LENS_DIGITAL_CATALOGUE_ORDER_ENTRY"],
  SHIP_DOCUMENTS: ["DELIVERY_NOTES_SECTION", "ORDER_HISTORY_AND_DELIVERY_NOTES_ESSILOR"],
  ORDER_HISTORY: ["MY_ORDER"],
};

//it must have at least one of these privileges
export const subuserPrivilegesMapOR: PrivilegeObject = {
  CATALOGUE: ["FINISHED_PRODUCT_SECTION", "ACCESSORIES_SECTION", "AFA_SECTION"],
  ORDER_CREATION: [
    "ACCESSORIES_ORDER_CREATION",
    "FINISHED_PRODUCT_ORDER_CREATION",
    "AFA_ORDER_CREATION",
  ],
};

export const privilegesRX: PrivilegeObject = {
  RX: ["RX_COMPLETE_JOB", "RX_FRAME_TO_COME", "RX_LENS_ONLY"],
  RX_ICON: ["RX_COMPLETE_JOB"],
};

//it should have all the privileges in the array
export const privilegesMapAND: PrivilegeObject = {
  ADD_TO_CART: ["FINISHED_PRODUCT_SECTION", "FINISHED_PRODUCT_ORDER_CREATION"],
  ADD_TO_CART_SPARE_PARTS: ["SPARE_PARTS_ORDER_CREATION", "SPARE_PARTS_SECTION"],
  ADD_TO_CART_AFA: ["AFA_SECTION", "AFA_ORDER_CREATION"],

  STARS: ["UX_STARS_EXPERIENCE", "STARS_SECTION"],
  LENS_TRADE_LINK: ["LENSES_TRADE_MARKETING_MATERIAL", "ADT"],
};

export const checkUserHavingPrivilegeAND = (
  userHavingPrivilege: UserHavingPrivilege[],
  requiredPrivileges: string[],
  door: Door
): boolean => {
  const relevantPrivileges = userHavingPrivilege.filter((priv) =>
    requiredPrivileges?.includes(priv?.privilege)
  );

  //if not all privileges required are present return false
  if (relevantPrivileges.length !== requiredPrivileges.length) {
    return false;
  }

  //check each which users have the privilege
  return relevantPrivileges.every((priv) =>
    priv?.users?.some((user) => user.orgentityName === door?.orgentityName)
  );
};

export const checkUserHavingPrivilegeOR = (
  userHavingPrivilege: UserHavingPrivilege[],
  requiredPrivileges: string[],
  door: Door
): boolean => {
  const relevantPrivileges = userHavingPrivilege.filter((priv) =>
    requiredPrivileges?.includes(priv?.privilege)
  );

  return relevantPrivileges.some((priv) =>
    priv?.users?.some((user) => user.orgentityName === door?.orgentityName)
  );
};

export const checkRXPrivilege = (
  userHavingPrivilege: UserHavingPrivilege[],
  requiredPrivileges: string[],
  door: Door
): boolean => {
  const relevantPrivileges = userHavingPrivilege.filter((priv) =>
    requiredPrivileges.some((_) => priv?.privilege.includes(_))
  );

  return relevantPrivileges.some((priv) =>
    priv?.users?.some((user) => user.orgentityName === door?.orgentityName)
  );
};

export const privilegesList = [
  "ADD_TO_CART_AFA",
  "AFA_SECTION",
  "BRANDED_CATALOGUE_DOCUMENT",
  "CREDIT_SECTION",
  "DAT_CATALOGUE_DOCUMENT",
  "DELIVERY_NOTES_SECTION",
  "PRODUCT_ORDER_CREATION",
  "INVOICE_SECTION",
  "ITEM_MASTER_DATA_DOCUMENT",
  "WE_AGREE",
  "PAYMENT_SECTION",
  "REWARDS",
  "RX_CARNET",
  "RX_COMPLETE_JOB",
  "RX_FRAME_TO_COME",
  "RX_LENS_ONLY",
  "PRODUCT_SECTION",
  "SPARE_PARTS_PURCHASE",
  "SPARE_PARTS_SECTION",
  "STARS_ALL_OTHERS_TRANSACTIONS",
  "STARS_ASSORTMENT",
  "STARS_INVENTORY",
  "STARS_RECALL",
  "STARS_SECTION",
  "STARS_SHIPMENT_ADJUSTMENT",
  "STARS_STATEMENT",
  "SUGGESTED_RETAIL_PRICE",
  "USER_MANAGEMENT_SECTION",
  "WARRANTY_DIGITAL",
  "WARRANTY_FAST_TRACK",
  "WARRANTY_REPAIR",
  "WARRANTY_STANDARD",
  "WARRANTY_DIGITAL_NO_SOLD_PRODUCT",
  "WARRANTY_FAST_TRACK_NO_SOLD_PRODUCT",
  "WARRANTY_STANDARD_NO_SOLD_PRODUCT",
  "WARRANTY_DIGITAL_LEGAL",
  "WARRANTY_HIDE_SPARE_PARTS",
  "WHOLESALE_PRICE",
  "EL_360",
  "QUALTRICS_SURVEY",
  "LEONARDO_SECTION",
  "LEONARDO_SUBUSERS_APPROVAL",
  "FINISHED_PRODUCT_ORDER_APPROVAL",
  "FINISHED_PRODUCT_ORDER_CREATION",
  "FINISHED_PRODUCT_SECTION",
  "MULTIDOOR",
  "WE_AGREE",
  "MY_ORDER",
  "RX_ALPHA_CHARACTERS",
  "RX_MY_PRICE",
  "RX_MY_PRICE_SRP",
  "RX_ORDER_APPROVAL",
  "SPARE_PARTS_ORDER_CREATION",
  "USER_MANAGEMENT_SECTION",
  "WHOLESALE_PRICE",
  "ADD_TO_CART_AFA",
  "DIGITAL_WINDOW",
  "ITEM_MASTER_DATA_DOCUMENT",
  "SHIP_TO_CONSUMER",
  "CUSTOM_PRODUCT_ORDER_APPROVAL",
  "SMART_SHOPPER_BOUTIQUE",
  "SMART_SHOPPER_CREATE_IPAD",
  "SMART_SHOPPER_CREATE_SCREEN",
  "SMART_SHOPPER_CUSTOM_OO",
  "SMART_SHOPPER_CUSTOM_RB",
  "SMART_SHOPPER_HIDE_BACKORDER",
  "SMART_SHOPPER_MONOBRAND_OO",
  "SMART_SHOPPER_MONOBRAND_RB",
  "SMART_SHOPPER_MULTIBRAND",
  "SMART_SHOPPER_OPTICIAN_LOGO",
  "SMART_SHOPPER_QUICK_ORDER",
  "SMART_SHOPPER_SRP_CUSTOM",
  "SMART_SHOPPER_SRP_STANDARD",
  "SMART_SHOPPER_STAGING_AREA",
  "APPOINTMENT_BOOKING_PAGE",
  "FINISHED_PRODUCT_ORDER_CREATION",
  "ADD_TO_CART_AFA",
  "REWARDS",
  "FIRST_AVAILABILITY",
  "NOTES_CS",
  "SIMULATION_AVAILABILITY",
  "CREDIT_INFO_BLOCK_STATUS",
  "DIME_ORDER_CREATION",
  "SHIPMENT_TRACKING",
];
