import React, { useState } from "react";
import Slider, { LazyLoadTypes } from "react-slick";
import styled from "styled-components/macro";
import CarouselDotBar from "../../styled-UI/CarouselDotBar";
import { FeatureCarouselVerticalItem } from "../../../interfaces/cmsInterfaces";
import FeatureVerticalSlide from "./FeatureVerticalSlide";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  isDarkMode?: boolean;
  items: FeatureCarouselVerticalItem[];
  customFont?: FontFamilies | null;
}

const FeatureCarouselVertical = ({
  isDarkMode = false,
  items,
  customFont = null,
}: Props): JSX.Element => {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const slidesToScroll = 1;
  const slidesToShow = 1;
  const currentPage = Math.ceil(currentSlide / slidesToScroll);
  const totalSlides = items?.length;
  const lastPage = Math.ceil((totalSlides - slidesToShow) / slidesToScroll);

  const defaultSettings = {
    autoplay: true,
    adaptiveHeight: true,
    dots: false,
    arrows: false,
    draggable: false, //to set it to true --> prevent click on drag https://github.com/akiran/react-slick/issues/848
    speed: 500,
    infinite: true,
    autoplaySpeed: 3000,
    onSwipe: () => {
      console.log("swipe");
    },
    slidesToShow: slidesToShow < totalSlides ? slidesToShow : totalSlides,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    beforeChange: (_: any, next: any) => setCurrentSlide(next),
    vertical: true,
    // lazyLoad: "on demand" as LazyLoadTypes,
  };

  const changeSlide = (val: number) => {
    sliderRef.slickGoTo(val);
    // callbackOnChangeSlide && callbackOnChangeSlide(val);
  };

  return (
    <Container>
      <Slider ref={(val) => setSliderRef(val)} {...defaultSettings}>
        {items?.map((slide) => {
          return (
            <div key={slide?.id}>
              <FeatureVerticalSlide item={slide} isDarkMode={isDarkMode} customFont={customFont} />
            </div>
          );
        })}
      </Slider>

      {totalSlides > 1 && totalSlides > slidesToShow ? (
        <CarouselDotBar
          lastPage={lastPage}
          currentPage={currentPage}
          changeSlide={changeSlide}
          navigationType={"central"}
          direction="vertical"
          backgroundDark={isDarkMode}
        />
      ) : (
        <div></div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: calc(100% - 15px) 15px;
  align-items: center;
  /* padding: 2.5%; */
`;

export default FeatureCarouselVertical;
