import React from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import Button from "../../styled-UI/Button";
import Popup from "../../styled-UI/Popup";
import InputComponent from "../../styled-UI/InputComponent";

interface Props {
  customerReference: string | undefined;
  isReferencePopupOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  handleChange: (customerReference: string | undefined) => void;
}

const CustomerReferencePopup = ({
  isReferencePopupOpen,
  handleClose,
  customerReference,
  handleConfirm,
  handleChange,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  const handleCustomerReferenceUpdate = (event: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(event?.currentTarget?.value);

  return (
    <Popup
      isOpen={isReferencePopupOpen}
      close={handleClose}
      title={translateLabel("CART_ITEM_CUSTOMER_REFERENCE_POPUP_TITLE")}
      isCentered
    >
      <ModalContainer>
        <WrapTextArea>
          <InputComponent
            id="textarea"
            as="textarea"
            type="text"
            placeholder={translateLabel("CART_ITEM_CUSTOMER_REFERENCE_INPUT_PLACEHOLDER")}
            name="textarea"
            helperText={translateLabel("CART_ITEM_CUSTOMER_REFERENCE_INPUT_HELPER_TEXT")}
            value={customerReference}
            onChange={handleCustomerReferenceUpdate}
            maxLength={250}
          />
        </WrapTextArea>
        <ButtonContainer>
          <Button type="primary" onClick={handleConfirm}>
            {translateLabel("CONFIRM")}
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </Popup>
  );
};

const ModalContainer = styled.div`
  padding: 2rem;
  width: 42.375rem;
`;

const WrapTextArea = styled.div`
  margin-bottom: 1.5rem;
  height: 13.75rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export default CustomerReferencePopup;
