import { getDoorListPayload, saveDoorListPayload } from "./multidoorInterface";
import { AxiosResponse } from "axios";
import { createAndExecuteService } from "../serviceUtils";
import { getDoorListUrl, saveDoorListUrl } from "./multidoorUrls";

export default {
  getDoorList: async (payload: getDoorListPayload): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    Object.entries(payload).forEach(
      // add all parameters only if they are not empty, and cast them to string
      ([key, value]) => value && qparams.append(key, value.toString())
    );
    return createAndExecuteService(getDoorListUrl, "GET", qparams);
  },
  saveDoorList: async (payload: saveDoorListPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(saveDoorListUrl, "POST", null, payload);
  },
};
