import { AxiosResponse } from "axios";
import { MostUsedPayload } from "../../interfaces/mostUsedInterfaces";

import { createAndExecuteService } from "../serviceUtils";
import {
  GetJWTPayload,
  ChangePasswordPayload,
  getPersonificationCustomersPayload,
  getPersonificationStoresPayload,
  getPersonificationDummyPayload,
  DoorAndStoreIdPayload,
  postPersonificationPayload,
  ChangePasswordPrivatePayload,
  DisplayPricesPayload,
  CatalogueLayoutPayload,
} from "./userInterfaces";
import {
  logoutUrl,
  loginKeysUrl,
  validationUrl,
  resetPasswordUrl,
  changePasswordUrl,
  userContextUrl,
  getPrivilegesUrl,
  getPersonificationCustomersUrl,
  postPersonificationUrl,
  getPersonificationAdvancedSearchUrl,
  getOrganizationDetailsUrl,
  getPersonificationStoresUrl,
  getPersonificationDummyUrl,
  changePasswordPrivateUrl,
  userMailCheckDuplicationUrl,
  mostUsedUrl,
  updateUserAttributesUrl,
} from "./userUrls";

export const userService = {
  getLoginKeys: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(loginKeysUrl, "GET");
  },
  getJWT: async (payload: GetJWTPayload): Promise<AxiosResponse> => {
    const env = payload.urlFO.substring(8).split("/")[1];
    const username = payload.userCredentials.username.trim();
    const formData = new FormData();

    // append params as form data
    formData.append("username", `${username}@${env}`);
    formData.append("password", payload.userCredentials.password.trim());
    formData.append("grant_type", "password");
    formData.append("scope", payload.scope);
    formData.append("client_id", payload.clientId);
    formData.append("response_type", "token");

    return createAndExecuteService(payload.urlFO, "POST", null, formData);
  },
  getValidation: async (payload: string): Promise<AxiosResponse> => {
    const params = {
      jwt: payload,
    };

    return createAndExecuteService(validationUrl, "POST", null, params);
  },

  logout: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(logoutUrl, "POST");
  },
  resetPassword: async (payload: string): Promise<AxiosResponse> => {
    const params = {
      username: payload,
    };
    return createAndExecuteService(resetPasswordUrl, "POST", null, params);
  },
  changePassword: async (payload: ChangePasswordPayload): Promise<AxiosResponse> => {
    let url = changePasswordUrl;

    if (payload.username) {
      url = changePasswordUrl.replace("{username}", payload.username);
    }
    const params = {
      uuid: payload.uuid,
      newPassword: payload.newPassword,
      confirmPassword: payload.confirmPassword,
    };

    return createAndExecuteService(url, "PUT", null, params);
  },
  changePasswordPrivate: async (payload: ChangePasswordPrivatePayload): Promise<AxiosResponse> => {
    let url = changePasswordPrivateUrl;

    if (payload.username) {
      url = changePasswordPrivateUrl.replace("{username}", payload.username);
    }
    const params = {
      newPassword: payload.newPassword,
      confirmPassword: payload.confirmPassword,
    };

    return createAndExecuteService(url, "PUT", null, params);
  },
  getUserContext: async (storeIdentifier: string): Promise<AxiosResponse> => {
    return createAndExecuteService(
      userContextUrl.replace("{storeIdentifier}", storeIdentifier),
      "GET"
    );
  },
  getPrivileges: async (storeIdentifier: string): Promise<AxiosResponse> => {
    const url = getPrivilegesUrl.replace("{storeIdentifier}", storeIdentifier);
    return createAndExecuteService(url, "GET");
  },
  getPersonificationDummy: async (
    payload: getPersonificationDummyPayload
  ): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    if (payload) {
      Object.entries(payload).forEach(([key, value]) => value && qparams.append(key, value));
    }

    return createAndExecuteService(getPersonificationDummyUrl, "GET", qparams);
  },
  getPersonificationStores: async (
    payload: getPersonificationStoresPayload
  ): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    if (payload) {
      Object.entries(payload).forEach(([key, value]) => value && qparams.append(key, value));
    }

    return createAndExecuteService(getPersonificationStoresUrl, "GET", qparams);
  },
  getPersonificationCustomers: async (
    payload: getPersonificationCustomersPayload | undefined
  ): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    if (payload) {
      Object.entries(payload).forEach(([key, value]) => value && qparams.append(key, value));
    }

    return createAndExecuteService(getPersonificationCustomersUrl, "GET", qparams);
  },
  postPersonification: async (payload: postPersonificationPayload): Promise<AxiosResponse> => {
    const { customerSelected, storeSelected } = payload;

    const qparams = new URLSearchParams();
    qparams.append("storeSelected", String(storeSelected.value));

    const url = postPersonificationUrl.replace("{id}", customerSelected);
    return createAndExecuteService(url, "POST", qparams);
  },
  getAdvancedSearch: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getPersonificationAdvancedSearchUrl, "GET");
  },
  getOrganizationDetails: async (payload: DoorAndStoreIdPayload): Promise<AxiosResponse> => {
    const { doorId, storeIdentifier } = payload;

    const url = getOrganizationDetailsUrl.replace("{storeIdentifier}", storeIdentifier);

    const qparams = new URLSearchParams();
    qparams.append("doorId", doorId.orgentityId);

    return createAndExecuteService(url, "GET", qparams);
  },
  getUserMailCheckDuplication: async (payload: string): Promise<AxiosResponse> => {
    const url = userMailCheckDuplicationUrl.replace("{storeIdentifier}", payload);
    return createAndExecuteService(url, "GET");
  },
  getMostUsedContent: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(mostUsedUrl, "GET");
  },
  updateMostUsedContent: async (payload: MostUsedPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(mostUsedUrl, "POST", null, payload);
  },
  updateSelectedDisplayPrices: async (payload: DisplayPricesPayload): Promise<AxiosResponse> => {
    const { selectedDisplayPrices } = payload;
    const qparams = new URLSearchParams();
    qparams.append("key", "PRICE_PREFERENCE");
    qparams.append("value", selectedDisplayPrices);
    return createAndExecuteService(updateUserAttributesUrl, "GET", qparams);
  },
  // update the catalogue layout for the pdp or the plp
  updateCataloguePageLayout: async (payload: CatalogueLayoutPayload): Promise<AxiosResponse> => {
    const { layout, pageType } = payload;
    const qparams = new URLSearchParams();
    qparams.append("key", `${pageType.toUpperCase()}_LAYOUT_PREFERENCE`);
    qparams.append("value", layout);
    return createAndExecuteService(updateUserAttributesUrl, "GET", qparams);
  },
  updateSrpOptInChoice: async (payload: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    qparams.append("key", "SRP_OPT_IN_CHOICE");
    qparams.append("value", payload);
    return createAndExecuteService(updateUserAttributesUrl, "GET", qparams);
  },
  updateSrpOptInChoiceOp: async (payload: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    qparams.append("key", "SRP_OPT_IN_CHOICE_OP");
    qparams.append("value", payload);
    return createAndExecuteService(updateUserAttributesUrl, "GET", qparams);
  },
  updateTutorialPills: async (payload: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    qparams.append("key", "TUTORIAL_PILLS_IDS");
    qparams.append("value", payload);
    return createAndExecuteService(updateUserAttributesUrl, "GET", qparams);
  },
  updateTermsOfServiceAcceptance: async (payload: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    qparams.append("key", "TERMS_OF_SERVICE");
    qparams.append("value", payload);
    return createAndExecuteService(updateUserAttributesUrl, "GET", qparams);
  },
};
