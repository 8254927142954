import React from "react";
import styled from "styled-components/macro";
import NavbarMenu from "./NavbarMenu";
import { useSelector } from "react-redux";
import { selectIsMultidoor } from "../../../../store/user/userSlice";
import theme from "../../../../assets/theme/theme";
import WrapperLayout from "../../WrapperLayout";
import clsx from "clsx";

interface Props {
  hideHeaderAnim: boolean;
}

const SmallerNavbar = ({ hideHeaderAnim }: Props): JSX.Element => {
  const isMultiDoor = useSelector(selectIsMultidoor);

  return (
    <Navbar className={clsx(isMultiDoor && "navbar-multidoor", hideHeaderAnim && "hide")}>
      <WrapperLayout isNavbar width100Sm>
        <NavbarContent>
          <NavbarMenu marginTop={theme.headerHeightRem + theme.smallScreenNavbarHeightRem} />
        </NavbarContent>
      </WrapperLayout>
    </Navbar>
  );
};

const Navbar = styled.nav`
  display: inline;
  position: fixed;
  width: 100%;
  background-color: ${(props) => props.theme.palette.white};
  height: ${(props) => props.theme.smallScreenNavbarHeightRem + "rem"};
  top: ${(props) => props.theme.headerHeightRem + "rem"};
  z-index: 400;
  border-top: 1px solid ${(props) => props.theme.palette.gray.medium};
  transition: top 0.3s;

  &.hide {
    top: -${(props) => props.theme.headerHeightRem + props.theme.smallScreenNavbarHeightRem + "rem"};
  }

  &.navbar-multidoor {
    top: ${(props) => props.theme.headerHeightRem + props.theme.multidoorBannerHeightRem + "rem"};

    &.hide {
      top: -${(props) => props.theme.headerHeightRem + props.theme.multidoorBannerHeightRem + props.theme.smallScreenNavbarHeightRem + "rem"};
    }
  }

  @media print {
    display: none;
  }
`;

const NavbarContent = styled.nav`
  display: flex;
  height: 100%;
  justify-content: space-between;
`;

export default SmallerNavbar;
