import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x-icon.svg";
import useCanRender from "../../../../hooks/useCanRender";
import useTranslation from "../../../../hooks/useTranslation";
import useWindowSize from "../../../../hooks/useWindowSize";
import { getWarrantyInboxCount } from "../../../../store/aftersales/aftersalesSaga";
import {
  selectForceUpdateCounterSR,
  setForceUpdateCounterSR,
} from "../../../../store/serviceRequests/serviceRequestSlice";
import { togglePopupPersonification } from "../../../../store/store/storeSlice";
import { logout } from "../../../../store/user/userSagas";
import {
  selectEssilorBadgeCode,
  selectEssilorBadgePicture,
  selectIsBackOfficeUser,
  selectOrganizationDetails,
  selectSelectedDoor,
  selectUserName,
} from "../../../../store/user/userSlice";
import { getPlaceholderImage } from "../../../../utils/catalogueUtils";
import { navbarSections } from "../../../pages/my-account/account-navbar/navbarSections";
import Button from "../../../styled-UI/Button";
import CustomText from "../../../styled-UI/CustomText";
import LinkButton from "../../../styled-UI/LinkButton";
import MassiveOrderModeToggle from "../../../widgets/MassiveOrderModeToggle";
import AccountMenuAccordion from "./AccontMenuAccordion";

interface Props {
  showAccountMenu: (showMenu: boolean) => void;
}

const AccountMenu = ({ showAccountMenu }: Props): JSX.Element => {
  const [smallResolution, setSmallResolution] = useState<boolean>(false);
  const [openedAccordion, setOpenedAccordion] = useState<string | null>(null);
  const dispatch = useDispatch();
  const canRender = useCanRender();
  const windowSize = useWindowSize();
  const accountMenuRef = useRef<HTMLDivElement | null>(null);
  const orgentityDetails = useSelector(selectOrganizationDetails);
  const { translateLabel } = useTranslation();
  const username = useSelector(selectUserName);
  const backOfficeUser = useSelector(selectIsBackOfficeUser);
  const selectedDoor = useSelector(selectSelectedDoor);
  const forceUpdateCounterWarrantySr = useSelector(selectForceUpdateCounterSR);
  const togglePersonificationPopup = () => {
    dispatch(togglePopupPersonification({ open: true }));
  };

  //ESSILOR BADGE
  const essilorBadgeCode = useSelector(selectEssilorBadgeCode);
  const showBadge =
    essilorBadgeCode === "ES1" || essilorBadgeCode === "ES2" || essilorBadgeCode === "ES3";
  const essilorBadgePicture = useSelector(selectEssilorBadgePicture);
  const placeholderBadge = getPlaceholderImage("portrait", "gray");

  //MANAGE THE CLICK OUTSIDE THE MENU IN ORDER TO CLOSE IT
  useEffect(() => {
    function handler(event: MouseEvent) {
      if (accountMenuRef.current) {
        const target = (event.target as Node) || null;
        accountMenuRef.current.contains(target) ? showAccountMenu(true) : showAccountMenu(false);
        event.stopPropagation();
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  //GET COUNTER FOR WARRANTY INBOX AND SERVICE REQUEST PAGE
  useEffect(() => {
    if (selectedDoor && forceUpdateCounterWarrantySr) {
      dispatch(getWarrantyInboxCount(selectedDoor.orgentityId));
      dispatch(setForceUpdateCounterSR(false));
    }
  }, [selectedDoor, forceUpdateCounterWarrantySr]);

  //HANDLE MENU FOR RESOLUTION < 1440
  useEffect(() => {
    if (window.screen.width < 1560) setSmallResolution(true);
    return () => {
      setSmallResolution(false);
    };
  }, [window.screen.width]);

  return (
    <MenuContainer
      className={clsx("account-menu", smallResolution && "small-resolution")}
      data-element-id="MainNav_MyAccount_Overlay"
    >
      <Menu ref={accountMenuRef} className={clsx(smallResolution && "small-resolution")}>
        <MassiveOrderModeToggle />
        <MenuSection>
          <LeftSection>
            <CustomText as="p" fontSizePx={13} lineHeightPx={18} font="font-bold" uppercase>
              {username}
            </CustomText>
            <div>
              <CustomText
                as="p"
                font="font-medium"
                fontSizePx={13}
                lineHeightPx={18}
                uppercase
                marginTopPx={8}
              >
                {orgentityDetails &&
                  orgentityDetails?.displayName + " - " + orgentityDetails?.firstName}
              </CustomText>
              <CustomText as="p" font="font-medium" fontSizePx={13} lineHeightPx={18} uppercase>
                {orgentityDetails &&
                  orgentityDetails?.address1 +
                    " " +
                    orgentityDetails?.address2 +
                    ", " +
                    orgentityDetails?.city +
                    " " +
                    orgentityDetails?.state +
                    " - " +
                    orgentityDetails?.country}
              </CustomText>
            </div>
            {(process.env.NODE_ENV === "development" || backOfficeUser) && (
              <ChangeContainer>
                <LinkButton
                  onClick={togglePersonificationPopup}
                  uppercase
                  data-element-id="MainNav_MyAccount_Overlay_Change"
                >
                  {translateLabel("ACCOUNT_MENU_CHANGE")}
                </LinkButton>
              </ChangeContainer>
            )}
          </LeftSection>
          <RightSection>
            <WrapperButton>
              <CloseButton
                data-element-id="AccountMenu_Close"
                onClick={() => showAccountMenu(false)}
              >
                <CloseIcon />
              </CloseButton>
            </WrapperButton>
            {showBadge && (
              <RightSection>
                <img src={essilorBadgePicture !== "" ? essilorBadgePicture : placeholderBadge} />
              </RightSection>
            )}
          </RightSection>
        </MenuSection>

        <Center
          data-element-id="MainNav_MyAccount_Overlay_Links"
          className={clsx(
            windowSize.height && windowSize.height >= 750 && "screen-big",
            windowSize.height && windowSize.height < 750 && "screen-medium",
            windowSize.height && windowSize.height < 550 && "screen-small"
          )}
        >
          {/* <CustomScrollbar> */}
          {navbarSections.map((section) => (
            <AccountMenuAccordion
              title={section.sectionTitle}
              contents={section.links}
              key={section.sectionTitle}
              isOpen={openedAccordion === section.sectionTitle}
              onToggle={() =>
                setOpenedAccordion((prev) =>
                  prev === section.sectionTitle ? null : section.sectionTitle
                )
              }
              showAccountMenu={showAccountMenu}
            />
          ))}
          {/* </CustomScrollbar> */}
        </Center>

        <ButtonWrapper>
          <Button
            type="secondary"
            onClick={() => {
              dispatch(logout({ buttonClicked: true }));
            }}
            data-element-id="MainNav_MyAccount_Overlay_Logout"
          >
            {translateLabel("LOGOUT")}
          </Button>
        </ButtonWrapper>
      </Menu>
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  padding: 1.125rem;
  position: absolute;
  top: ${(props) => props.theme.headerHeightRem + "rem"};
  right: -4.5rem;
  z-index: 2;
  cursor: auto;

  &.small-resolution {
    right: 0rem;
  }
`;

const Menu = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
  box-shadow: 0.5rem 0.1875rem 2.5rem 0 rgba(3, 20, 52, 0.1);
  width: 19.5rem;
  padding: 1.125rem 1.5rem;
  text-align: left;
  box-sizing: border-box;
  width: 25rem;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: -1.5rem;
    right: 5rem;
    width: 0;
    height: 0;
    border-style: solid;
    z-index: 300;
    border-color: transparent transparent ${(props) => props.theme.palette.white} transparent;
    border-width: 0.8125rem;
  }

  &.small-resolution {
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      z-index: 300;
      border-color: transparent transparent ${(props) => props.theme.palette.white} transparent;
      border-width: 0.8125rem;
      top: -1.5rem;
      right: 1rem;
    }
  }
`;

const Center = styled.div`
  overflow: auto;
  padding: 0 0.25rem;
  &.screen-big {
    max-height: 44vh;
  }

  &.screen-medium {
    max-height: 35vh;
  }

  &.screen-small {
    max-height: 12vh;
  }
`;

const MenuSection = styled.div`
  padding: 0 0 1.125rem 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 5fr 1fr;
  column-gap: 1rem;
`;

const LeftSection = styled.div``;

const RightSection = styled.div`
  img {
    margin-top: 0.5rem;
    max-width: 3.538rem;
    max-height: 5rem;
  }
`;

const WrapperButton = styled.div`
  cursor: pointer;
  text-align: right;
`;

const CloseButton = styled.button`
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

const ChangeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1.25rem;
`;

const ButtonWrapper = styled.div`
  padding: 1.375rem 0 0 0;
  display: flex;
  justify-content: flex-end;
`;

export default AccountMenu;
