import React from "react";
import { ReactComponent as StarIcon } from "../../assets/icons/star-icon.svg";
import { Variant, Product, Sku } from "../../interfaces/productInterface";
import useCanRender from "../../hooks/useCanRender";

interface Props {
  data: Product | Variant | Sku;
}

const StarIconComponent = ({ data }: Props): JSX.Element => {
  const canRender = useCanRender();
  const checkStar =
    (!canRender("UX_STARS_EXPERIENCE") && canRender("STARS_SECTION") && data.isStars) ||
    (canRender("STARS") && data.isStarsNew);
  return checkStar ? <StarIcon /> : <></>;
};

export default StarIconComponent;
