import React, { useState } from "react";
import useTranslation from "../../../hooks/useTranslation";
import InputComponent from "../../styled-UI/InputComponent";
import { useDispatch, useSelector } from "react-redux";
import { Door } from "../../../store/user/userInterfaces";
import { submitOrderSupportSR } from "../../../store/serviceRequests/serviceRequestSagas";
import { selectSubmitOrderSupportSRStatus } from "../../../store/serviceRequests/serviceRequestSlice";
import ServiceRequestPopupSubmit from "./ServiceRequestPopupSubmit";

interface Props {
  selectedDoor: Door;
  serviceRequestIdentifier: string | null;
  setHasSubmittedRequest: (val: boolean) => void;
  orderIdentifier?: string | null;
  b2bOrderIdentifier?: string | null;
}

const ServiceRequestPopupSR07 = ({
  selectedDoor,
  serviceRequestIdentifier,
  setHasSubmittedRequest,
  orderIdentifier,
  b2bOrderIdentifier,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const submitStatus = useSelector(selectSubmitOrderSupportSRStatus);

  //fields to send
  type FieldName = "NOTE";
  const [note, setNote] = useState("");
  const [errors, setErrors] = useState<FieldName[]>([]);
  const { translateLabel } = useTranslation();

  const handleNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);

    const index = errors.indexOf("NOTE");
    if (index !== -1) {
      const newErrors = [...errors];

      newErrors.splice(index, 1);
      setErrors(newErrors);
    }
  };

  const submitRequest = () => {
    //check for errors
    const newErrors = [...errors];
    if (!note) {
      newErrors.push("NOTE");
    }

    setErrors(newErrors);

    //send request
    if (newErrors.length === 0 && serviceRequestIdentifier) {
      dispatch(
        submitOrderSupportSR({
          doorId: selectedDoor.orgentityId,
          serviceRequestIdentifier: serviceRequestIdentifier,
          serviceRequestTypeIdentifier: "SR7_ORDER_SUPPORT",
          textNoteValue: note,
          orderIdentifier: orderIdentifier ?? "",
          b2bOrderIdentifier: b2bOrderIdentifier ?? "",
        })
      );
    }
  };

  return (
    <div>
      <div className="textarea-wrapper mt-4">
        <InputComponent
          type="text"
          as="textarea"
          placeholder={translateLabel("SERVICE_REQUEST_POPUP_NOTE")}
          resizableTextarea
          maxLength={500}
          required
          helperText={errors.includes("NOTE") ? translateLabel("REQUIRED_FIELD") : ""}
          colorStyle={errors.includes("NOTE") ? "error" : "enabled"}
          onChange={handleNote}
        />
      </div>

      <ServiceRequestPopupSubmit
        submitRequest={submitRequest}
        submitStatus={submitStatus}
        setHasSubmittedRequest={setHasSubmittedRequest}
      />
    </div>
  );
};

export default ServiceRequestPopupSR07;
