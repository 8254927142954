import { useEffect, useMemo, useState } from "react";
import useTranslation from "../../../../hooks/useTranslation";

import { isEqual } from "lodash";
import useMenuColumnsInfo, { MenuDivInfo } from "../../../../hooks/useMenuColumnsInfo";
import useMostUsedLenses from "../../../../hooks/useMostUsedLenses";
import { SecondLevelMenu } from "../../../../interfaces/menuInterfaces";
import {
  MostUsedCategory,
  MostUsedChipCatalogueLocal,
} from "../../../../interfaces/mostUsedInterfaces";
import {
  createMostUsedChildrenChip,
  createMostUsedChipFromSelection,
  getMostUsedCategoryFromMenuDivInfo,
  sortChips,
} from "../../../../utils/mostUsedUtils";

const useGenerateCatalogueMostUsed = (
  categoryMenu: SecondLevelMenu | undefined
): MostUsedChipCatalogueLocal[] => {
  const { translateLabel } = useTranslation();
  const [categoryContent, setCategoryContent] = useState<MostUsedCategory[]>([]);
  const [categories, setCategories] = useState<MostUsedCategory[]>([]);
  const [options, setOptions] = useState<MostUsedChipCatalogueLocal[]>([]);
  const identifier = categoryMenu?.identifier?.toUpperCase();
  const isSmartGlasses = identifier === "SMART GLASSES";
  const kidsLink =
    (identifier === "EYEGLASSES" || identifier === "SUNGLASSES") && categoryMenu?.completeUrlKids;
  const isLensesCategory = identifier === "LENSES";
  const lensesChips = isLensesCategory ? useMostUsedLenses() : [];
  const [selectableChips, setSelectableChips] = useState<MostUsedChipCatalogueLocal[]>([]);

  if (!categoryMenu) return [];

  //catalogue categories
  const menuContentInfo: MenuDivInfo[] = useMenuColumnsInfo({
    menu: categoryMenu,
    nameCategory: categoryMenu.categoryName,
    considerBrandsAsAdult: true,
  });

  useEffect(() => {
    menuContentInfo.length &&
      setCategoryContent(getMostUsedCategoryFromMenuDivInfo(menuContentInfo));
  }, [menuContentInfo]);

  useEffect(() => {
    if (categoryContent.length) {
      const { categories } = categoryContent.reduce<{
        categories: MostUsedCategory[];
      }>(
        (result, category) => {
          !category.isRadio && result.categories.push(category);
          return result;
        },
        { categories: [] }
      );
      setCategories(categories);
    }
  }, [categoryContent]);

  useEffect(() => {
    if (categories?.length) {
      let chips = categories
        ?.flatMap((col) => col.options)
        .map((option) =>
          createMostUsedChipFromSelection(
            categoryMenu,
            [option],
            menuContentInfo,
            isLensesCategory,
            translateLabel
          )
        );

      kidsLink && chips.push(createMostUsedChildrenChip(categoryMenu, translateLabel));
      chips = sortChips(chips) as MostUsedChipCatalogueLocal[];
      const allOptions = categories
        ?.flatMap((col) => col.options)
        ?.filter((option) => option.url != undefined);

      !isLensesCategory &&
        !isSmartGlasses &&
        allOptions?.length &&
        chips.unshift(
          createMostUsedChipFromSelection(
            categoryMenu,
            allOptions,
            menuContentInfo,
            isLensesCategory,
            translateLabel,
            true
          )
        );

      setOptions(chips);
    }
  }, [categories]);

  useEffect(() => {
    if (lensesChips.length > 0 && !isEqual(lensesChips, selectableChips)) {
      setSelectableChips(lensesChips);
    }
  }, [lensesChips]);

  useEffect(() => {
    if (options.length > 0 && !isEqual(options, selectableChips)) {
      setSelectableChips(options);
    }
  }, [options]);

  return useMemo(() => {
    return selectableChips;
  }, [selectableChips, categoryMenu]);
};

export default useGenerateCatalogueMostUsed;
