import React from "react";
import styled from "styled-components/macro";
import clsx from "clsx";

import {
  AttributeSlim,
  Variant,
  Sku,
  VariantAndSkuKeys,
} from "../../../interfaces/productInterface";
import { capitalizeFirstLetter } from "../../../utils/utils";
import {
  isEyeglassesVariant,
  isReaderGlassesVariant,
  isSunglassesVariant,
} from "../../../utils/productUtils";
import CustomText from "../../styled-UI/CustomText";

interface Props {
  data: Variant | Sku;
  style?: "tile" | "warranty-popup" | "row" | "column";
  hideColor?: boolean;
  isAftersale?: boolean;
  hideHeight?: boolean;
}

const PRODUCT_DETAILS: { [key: string]: VariantAndSkuKeys[] } = {
  frame: ["frontColorDescription", "powerRange", "lensProperties", "lensColor", "lensMaterial"],
  eyeglasses: [
    "frontColorDescription",
    "powerRange",
    "lensProperties",
    "frameMaterial",
    "lensMaterial",
  ],
  helmets: ["frontColorDescription"],
  goggles: ["lensProperties", "lensColor"],
  accessories: ["lensProperties", "lensColor"],
};

const SINGLE_LINE_HEIGHT = 18;

const TileLensInfo = ({
  data,
  style = "tile",
  hideColor = false,
  isAftersale,
  hideHeight = false,
}: Props): JSX.Element => {
  const details = getProductDetails(data, hideColor);

  const numberOfAttributes = details.filter((_) => data[_])?.length;
  const rowHeight = `${numberOfAttributes * SINGLE_LINE_HEIGHT}px`;

  if (isAftersale)
    return (
      <AftersalesContainer>
        <PropertiesContainer
          className={clsx(
            style === "row" && "tile-lens-info-row",
            style === "column" && "tile-lens-info-column"
          )}
          {...(style === "tile" && { rowHeight })}
        >
          {details.map((prop) => {
            if (
              data[prop] &&
              (data[prop] as AttributeSlim)?.name &&
              (data[prop] as AttributeSlim)?.values &&
              prop !== "lensProperties"
            ) {
              return (
                <SingleProperty
                  key={prop}
                  numberOfAttributes={numberOfAttributes}
                  className={clsx(
                    style === "row" && "tile-lens-info-row",
                    style === "tile" && "tile",
                    style === "warranty-popup" && "warranty-popup",
                    style === "column" && "tile-lens-info-column"
                  )}
                >
                  <CustomText
                    as="p"
                    color="primary"
                    font="font-regular"
                    fontSizePx={13}
                    title={capitalizeFirstLetter((data[prop] as AttributeSlim)?.name)}
                  >
                    {capitalizeFirstLetter((data[prop] as AttributeSlim)?.name)}
                  </CustomText>
                  <CustomText
                    as="span"
                    color="primary"
                    font="font-bold"
                    fontSizePx={13}
                    title={capitalizeFirstLetter((data[prop] as AttributeSlim)?.values)}
                  >
                    {capitalizeFirstLetter((data[prop] as AttributeSlim)?.values)}
                  </CustomText>
                </SingleProperty>
              );
            }
          })}
        </PropertiesContainer>
        {data.lensProperties && (
          <PropertiesContainer>
            <SingleProperty
              numberOfAttributes={numberOfAttributes}
              className={clsx(
                style === "row" && "tile-lens-info-row",
                style === "tile" && "tile",
                style === "warranty-popup" && "warranty-popup",
                style === "column" && "tile-lens-info-column"
              )}
            >
              <CustomText
                as="p"
                color="primary"
                font="font-regular"
                fontSizePx={13}
                title={capitalizeFirstLetter((data.lensProperties as AttributeSlim)?.name)}
              >
                {capitalizeFirstLetter((data.lensProperties as AttributeSlim)?.name)}
              </CustomText>
              <CustomText
                as="span"
                color="primary"
                font="font-bold"
                fontSizePx={13}
                title={capitalizeFirstLetter((data.lensProperties as AttributeSlim)?.values)}
              >
                {capitalizeFirstLetter((data.lensProperties as AttributeSlim)?.values)}
              </CustomText>
            </SingleProperty>
          </PropertiesContainer>
        )}
      </AftersalesContainer>
    );
  else
    return (
      <PropertiesContainer
        className={clsx(
          style === "row" && "tile-lens-info-row",
          style === "column" && "tile-lens-info-column",
          hideHeight && "hide-height"
        )}
        {...(style === "tile" && { rowHeight })}
      >
        {details.map((prop) => {
          if (
            data[prop] &&
            (data[prop] as AttributeSlim)?.name &&
            (data[prop] as AttributeSlim)?.values
          ) {
            return (
              <SingleProperty
                key={prop}
                numberOfAttributes={numberOfAttributes}
                className={clsx(
                  style === "row" && "tile-lens-info-row",
                  style === "tile" && "tile",
                  style === "warranty-popup" && "warranty-popup",
                  style === "column" && "tile-lens-info-column"
                )}
              >
                <CustomText
                  as="p"
                  color="primary"
                  font="font-regular"
                  fontSizePx={13}
                  title={capitalizeFirstLetter((data[prop] as AttributeSlim)?.name)}
                >
                  {capitalizeFirstLetter((data[prop] as AttributeSlim)?.name)}
                </CustomText>
                <CustomText
                  as="span"
                  color="primary"
                  font="font-bold"
                  fontSizePx={13}
                  title={capitalizeFirstLetter((data[prop] as AttributeSlim)?.values)}
                >
                  {capitalizeFirstLetter((data[prop] as AttributeSlim)?.values)}
                </CustomText>
              </SingleProperty>
            );
          }
        })}
      </PropertiesContainer>
    );
};

const getProductDetails = (data: Variant | Sku, hideColor: boolean) => {
  let details: VariantAndSkuKeys[] = [];

  if (data.productCategory)
    switch (data.productCategory) {
      case "frame":
        const isEyeglasses = isEyeglassesVariant(data);
        const isSunglasses = isSunglassesVariant(data);
        const isReaderGlasses = isReaderGlassesVariant(data);
        if (isSunglasses) details = PRODUCT_DETAILS["frame"];
        if (isEyeglasses) details = PRODUCT_DETAILS["eyeglasses"];
        if (isReaderGlasses) details = PRODUCT_DETAILS["frame"];
        break;
      case "afa":
        //TODO add afa
        break;
      case "smartglasses":
        details = PRODUCT_DETAILS["frame"];
        break;
      case "goggle":
        details = PRODUCT_DETAILS["goggles"];
        break;
      case "accessory":
        details = PRODUCT_DETAILS["accessories"];
        break;
      case "helmet":
        details = PRODUCT_DETAILS["helmets"];
        break;
      case "audio":
        details = PRODUCT_DETAILS["frame"];
        break;
    }

  if (hideColor) details = details.filter((_) => _ !== "frontColorDescription");
  return details;
};

const AftersalesContainer = styled.div`
  gap: 1.5rem;
  display: flex;

  @media ${(props) => props.theme.queries.md} {
    gap: unset;
    display: grid;
  }
`;
const PropertiesContainer = styled.div<{ rowHeight?: string }>`
  height: ${(props) => (props.rowHeight ? props.rowHeight : "100%")};

  &.tile-lens-info-row {
    display: flex;
    justify-content: space-between;

    flex-direction: row;
    align-items: flex-start;
    flex-grow: 1;
    column-gap: 0.5rem;
  }
  &.tile-lens-info-column {
    display: flex;
    justify-content: space-between;

    flex-direction: column;
    justify-content: flex-start;
  }

  &.hide-height {
    height: unset;
  }
`;

const SingleProperty = styled.div<{ numberOfAttributes: number }>`
  grid-template-columns: auto minmax(0, max-content);
  row-gap: 0.5rem;
  column-gap: 1.5rem;

  &.tile {
    display: flex;
    justify-content: space-between;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    span {
      text-align: right;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  &.tile-lens-info-row {
    min-width: ${(props) => (1 / props.numberOfAttributes) * 100 - 1}%;
  }

  &.tile-lens-info-column {
    margin-bottom: 0.625rem;
  }

  &.warranty-popup {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    column-gap: 1rem;
    justify-content: start;

    p {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }

    p:after {
      content: ":";
    }
  }
`;

export default TileLensInfo;
