import React from "react";
import ContentLoader from "react-content-loader";

const LoaderCart = (): JSX.Element => (
  <ContentLoader
    speed={2}
    width={976}
    height={251}
    viewBox="0 0 976 251"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <circle cx="52" cy="48" r="22" />
    <rect x="90" y="41" rx="1" ry="1" width="152" height="18" />
    <rect x="32" y="90" rx="0" ry="0" width="294" height="126" />
    <rect x="23" y="387" rx="0" ry="0" width="279" height="30" />
    <rect x="23" y="432" rx="0" ry="0" width="279" height="30" />
    <rect x="23" y="477" rx="0" ry="0" width="279" height="30" />
    <rect x="23" y="545" rx="0" ry="0" width="279" height="59" />
    <rect x="342" y="90" rx="0" ry="0" width="294" height="126" />
    <rect x="653" y="90" rx="0" ry="0" width="294" height="126" />
    <rect x="793" y="41" rx="1" ry="1" width="152" height="18" />
  </ContentLoader>
);

export default LoaderCart;
