import { GenericCustomObject } from "../interfaces/mainInterfaces";
import {
  CountServicesByIds,
  NotificationCountByServicesIdsPayload,
  AlertsCounts,
} from "../store/accounting/accountingInterfaces";

export const invoiceDocumentTypeList: GenericCustomObject = {
  Z1BX: "INVOICE_DOCUMENT_TYPE_CORE_INVOICE_NATION",
  Z1CC: "INVOICE_DOCUMENT_TYPE_CORE_INVOICE_NATION",
  Z1CN: "INVOICE_DOCUMENT_TYPE_CORE_INVOICE_NATION",
  Z1CX: "INVOICE_DOCUMENT_TYPE_CORE_INVOICE_NATION",
  Z1SC: "INVOICE_DOCUMENT_TYPE_SERVICE_INVOICE_UE",
  Z1SN: "INVOICE_DOCUMENT_TYPE_SERVICE_INVOICE_NAT",
  Z1SX: "INVOICE_DOCUMENT_TYPE_SERVICE_INVOICE_NUE",
  Z1XC: "INVOICE_DOCUMENT_TYPE_CEE_RX_INVOICE",
  Z1XN: "INVOICE_DOCUMENT_TYPE_NATIONAL_RX_INVOICE",
  Z1XX: "INVOICE_DOCUMENT_TYPE_EXTRACEE_RX_INVOICE",
  Z2CC: "INVOICE_DOCUMENT_TYPE_CEE_CM_CORE",
  Z2CN: "INVOICE_DOCUMENT_TYPE_NATIONAL_CM_CORE",
  Z2CX: "INVOICE_DOCUMENT_TYPE_CM_CORE_XCEE",
  Z2PC: "INVOICE_DOCUMENT_TYPE_REB_PART_SETT_UE",
  Z2PN: "INVOICE_DOCUMENT_TYPE_REB_PART_SETT_NAT",
  Z2PX: "INVOICE_DOCUMENT_TYPE_REB_PART_SETT_EXUE",
  Z2SC: "INVOICE_DOCUMENT_TYPE_CEE_CM_SERV",
  Z2SN: "INVOICE_DOCUMENT_TYPE_NAT_CM_SERV",
  Z2SX: "INVOICE_DOCUMENT_TYPE_CM_SERV_XCEE",
  Z2TC: "INVOICE_DOCUMENT_TYPE_EXP_REB_CRE_MEMO",
  Z2TN: "INVOICE_DOCUMENT_TYPE_EXP_REB_CRE_MEMO",
  Z2TX: "INVOICE_DOCUMENT_TYPE_EXP_REB_CRE_MEMO",
  Z2XC: "INVOICE_DOCUMENT_TYPE_CEE_RX_CREDIT",
  Z2XN: "INVOICE_DOCUMENT_TYPE_NAT_RX_CREDIT",
  Z2XX: "INVOICE_DOCUMENT_TYPE_EX_RX_CREDIT",
  Z3CC: "INVOICE_DOCUMENT_TYPE_DM_CUST_CEE_SUB",
  Z3CN: "INVOICE_DOCUMENT_TYPE_DM_CUST_NAT_SUB",
  Z3CX: "INVOICE_DOCUMENT_TYPE_DM_CUST_XCEE_SUB",
  Z3PC: "INVOICE_DOCUMENT_TYPE_REV_REB_CB_Z2PC",
  Z3PN: "INVOICE_DOCUMENT_TYPE_REV_REB_CB_Z2PN",
  Z3PX: "INVOICE_DOCUMENT_TYPE_REV_REB_CB_Z2PX",
  Z3SC: "INVOICE_DOCUMENT_TYPE_CEE_DM_SERV",
  Z3SN: "INVOICE_DOCUMENT_TYPE_NAT_DM_SERV",
  Z3SX: "INVOICE_DOCUMENT_TYPE_XCEE_DM_SERV",
  Z3TC: "INVOICE_DOCUMENT_TYPE_REV_REB_CN_Z2TC",
  Z3TN: "INVOICE_DOCUMENT_TYPE_REV_REB_CN_Z2TN",
  Z3TX: "INVOICE_DOCUMENT_TYPE_REV_REB_CN_Z2TX",
  Z3XC: "INVOICE_DOCUMENT_TYPE_CEE_RX_DEB_NOTE",
  Z3XN: "INVOICE_DOCUMENT_TYPE_NAT_RX_DEB_NO",
  Z3XX: "INVOICE_DOCUMENT_TYPE_EXTRA_RX_DEB_NO",
  Z4CN: "INVOICE_DOCUMENT_TYPE_AUTO_SUB",
  Z901: "INVOICE_DOCUMENT_TYPE_PRO_FORMA_INV",
  Z902: "INVOICE_DOCUMENT_TYPE_PRO_FORMA_RETURN",
  Z903: "INVOICE_DOCUMENT_TYPE_PRO_FORMA_INV_ALLOCAT",
  Z904: "INVOICE_DOCUMENT_TYPE_PRO_FORMA_ORDER_LTF",
  ZAGN: "INVOICE_DOCUMENT_TYPE_AUTO_OTH_GOODS",
  ZCSN: "INVOICE_DOCUMENT_TYPE_SERVICE_CM_NAT",
  ZIGN: "INVOICE_DOCUMENT_TYPE_OTH_GOODS_INV_NAT",
  ZISN: "INVOICE_DOCUMENT_TYPE_SERV_INV_NAT",
  ZP02: "INVOICE_DOCUMENT_TYPE_PRO_FORMA_INV_LTF",
  ZSCC: "INVOICE_DOCUMENT_TYPE_CANCEL_INV_CEE",
  ZSCN: "INVOICE_DOCUMENT_TYPE_CANCEL_INV_NAT",
  ZSCX: "INVOICE_DOCUMENT_TYPE_CANCEL_INV_XCEE",
  ZSSC: "INVOICE_DOCUMENT_TYPE_CANCEL_INV_NAT",
  ZSSN: "INVOICE_DOCUMENT_TYPE_CANCEL_INV_NAT",
  ZSSX: "INVOICE_DOCUMENT_TYPE_CANCEL_INV_NAT",
};

export const srpTranslations = {
  standard: {
    title: "PRIVACY_POLICY_SRP_OPT_IN_TITLE",
    profile: "SRP_OPT_IN_LEGAL_INFO_PROFILE",
    info: "SRP_OPT_IN_LEGAL_CHOICE_INFO",
    radioNo: "SRP_OPT_IN_DECLINE",
    radioYes: "SRP_OPT_IN_ACCEPT",
    changedOk: "CHANGES_SAVED_SRP",
    changedKo: "SRP_OPT_IN_UPDATE_ERROR",
    privilegeY: "SRP_OPT_IN_CHOICE_Y",
    privilegeN: "SRP_OPT_IN_CHOICE_N",
  },
  op: {
    title: "PRIVACY_POLICY_SRP_OP_OPT_IN_TITLE",
    profile: "SRP_OP_OPT_IN_LEGAL_INFO_PROFILE",
    info: "SRP_OP_OPT_IN_LEGAL_CHOICE_INFO",
    radioNo: "SRP_OP_OPT_IN_DECLINE",
    radioYes: "SRP_OP_OPT_IN_ACCEPT",
    changedOk: "CHANGES_SAVED_SRP_OP",
    changedKo: "SRP_OPT_IN_UPDATE_OP_ERROR",
    privilegeY: "SRP_OPT_IN_CHOICE_OP_Y",
    privilegeN: "SRP_OPT_IN_CHOICE_OP_N",
  },
};

// all services that must be shown in notification boxcounter
export const allowedServicesIds: NotificationCountByServicesIdsPayload = {
  serviceIds: [4, 5, 15, 17, 27, 28, 30, 31, 32, 33, 34, 35, 48, 49],
};

// used by saga after calling api to map all service and their counter
export const mapNotificationsCountsByIds = (
  servicesList: CountServicesByIds[]
): AlertsCounts | undefined => {
  return servicesList?.length
    ? servicesList?.reduce((acc, service: CountServicesByIds) => {
        acc[service.serviceId] = service.sectionCount;
        return acc;
      }, {} as AlertsCounts)
    : undefined;
};
