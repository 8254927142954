import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import clsx from "clsx";

import CustomText from "../../components/styled-UI/CustomText";

import { ReactComponent as XIcon } from "../../assets/icons/x-icon.svg";
import { ReactComponent as Checkmark } from "../../assets/icons/checkmark-icon.svg";
import { ReactComponent as Alert } from "../../assets/icons/alert-icon.svg";

interface Props {
  status: "error" | "progress" | "complete" | "warning";
  label: string;
  percentage: number;
}

const ProgressBar = ({ status, label, percentage }: Props): JSX.Element => {
  const progressClasses = clsx(
    status === "progress" && "bar-progress",
    status === "complete" && "bar-progress",
    status === "error" && "bar-error",
    status === "warning" && "bar-warning"
  );
  const [percentageControl, setPercentageControl] = useState<number>(percentage);

  useEffect(() => {
    if (status !== "progress") return setPercentageControl(100);
    else {
      if (percentage < 0) return setPercentageControl(0);
      if (percentage > 100) return setPercentageControl(100);
    }
  }, [percentage, status]);

  return (
    <Container>
      <Progress className={progressClasses} percentageControl={percentageControl}>
        <CustomText as="p" color="white" fontSizePx={13} font="font-bold">
          {status === "progress" && percentageControl + `%`}
        </CustomText>
        {status === "complete" && (
          <IconContainer>
            <Checkmark />
          </IconContainer>
        )}
        {status === "warning" && (
          <IconContainer>
            <Alert />
          </IconContainer>
        )}
        {status === "error" && (
          <IconContainer>
            <XIcon />
          </IconContainer>
        )}
      </Progress>
      <LabelContainer>
        <CustomText as="span" fontSizePx={13}>
          {label}
        </CustomText>
      </LabelContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 7rem;
  height: 2rem;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.palette.white};
  border: solid 2px ${(props) => props.theme.palette.gray.medium};
  z-index: 1;
`;

interface ProgressProps {
  percentageControl: number | undefined;
}

const Progress = styled.div<ProgressProps>`
  height: 100%;
  min-width: 35%;
  width: ${(props) => props.percentageControl}%;
  max-width: 100%;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.bar-progress {
    background-color: ${(props) => props.theme.palette.primary};
  }

  &.bar-error {
    background-color: ${(props) => props.theme.palette.feedback.error};
  }

  &.bar-warning {
    background-color: ${(props) => props.theme.palette.feedback.alert};
  }
`;

const LabelContainer = styled.div`
  width: 7rem;
  text-align: center;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  fill: ${(props) => props.theme.palette.white};

  svg {
    height: 1rem;
    width: 1rem;
  }
`;

export default ProgressBar;
