import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";
import CustomText from "../../styled-UI/CustomText";
import { IconCardItem } from "../../../interfaces/cmsInterfaces";
import useDataHref from "../../../hooks/useDataHref";
import { getMultiMedia } from "../../../utils/cmsUtils";
import SetInnerHTMLWithSanitize from "../SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  data: IconCardItem;
  layout: "vertical" | "horizontal";
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}
const SingleIconCard = ({
  data,
  layout,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const { description, description1Colour, title, titleColour, multiMediaType } = data;
  const descrWithHref = useDataHref({ content: description });
  const { srcImg } = getMultiMedia(multiMediaType);

  return (
    <Container className={clsx(layout === "vertical" && "lp-single-card-horizontal")}>
      <Circle isVertical={layout === "vertical"}>
        <img src={srcImg} />
      </Circle>

      <TextContainer
        className={clsx("lp-single-card-text", layout === "vertical" && "vertical-title")}
      >
        <Title className="lp-single-card-title">
          <CustomText
            as="p"
            font={customFont ? "custom-bold" : "font-bold"}
            fontFamily={customFont}
            fontSizePx={28}
            marginTopPx={8}
            isFlex={false}
            textAlign={layout === "vertical" ? "left" : "center"}
            customColor={titleColour}
            lineHeightPx={35}
            color={isDarkMode ? "white" : "primary"}
          >
            {title}
          </CustomText>
        </Title>

        <CustomText
          as="p"
          font={customFont ? "custom-regular" : "font-regular"}
          fontFamily={customFont}
          fontSizePx={14}
          lineHeightPx={24}
          marginTopPx={8}
          isFlex={false}
          textAlign={layout === "vertical" ? "left" : "center"}
          customColor={description1Colour}
          color={isDarkMode ? "white" : "primary"}
        >
          <SetInnerHTMLWithSanitize text={descrWithHref.updatedContent ?? description} />
        </CustomText>
      </TextContainer>
    </Container>
  );
};

export default SingleIconCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;

  &.lp-single-card-horizontal {
    flex-direction: row;
    justify-content: left;

    .lp-single-card-title {
      height: auto;
    }

    .lp-single-card-text {
      margin-left: 2rem;
    }
  }
`;

const TextContainer = styled.div`
  &.vertical-title {
    margin-top: 1.375rem;
  }
`;

const Title = styled.div`
  height: 4.25rem;
`;

const Circle = styled.div<{ isVertical: boolean }>`
  height: 4.75rem;
  width: 4.75rem;

  img {
    width: ${(props) => (props.isVertical ? "4.75rem" : "100%")};
    height: ${(props) => (props.isVertical ? "4.75rem" : "100%")};
    border-radius: 50%;
  }

  &.active {
    border: 1px solid ${(props) => props.theme.palette.primary};
  }
`;
