import { useDispatch, useSelector } from "react-redux";

import { selectStarDoorsPerBrand } from "../../../../store/store/storeSlice";
import { updateSelectedDoor } from "../../../../store/user/userSagas";
import {
  selectHasChangedDoor,
  selectIsStarsMultidoor,
  selectMultiDoors,
  selectSelectedDoor,
} from "../../../../store/user/userSlice";

const QUICK_CONSOLE_DEBUGGING = false;

interface Props<T> {
  redirect: (brand: T) => void;
}

interface ReturnObject<T> {
  handleClick: (
    brand: T,
    brandIdentifier: string,
    brandLabel: string,
    isBrandStars: boolean
  ) => void;
}

// useMenuRedirectMultidoorStars isolates the logic required to redirect to the prePLP / PLP corresponding to a specific brand
// specifically, the complexity is present when the user is navigating in multidoor stars, where we need to set a selected door before redirecting
// the logic is as follows:
//    1) when user IS NOT in multidoor stars, simply redirect without selecting a door
//    2) when user IS in multidoor stars, but the door has been explicitly selected using the dropdown, simply redirect without changing the door
//    3) when user IS in multidoor stars, and the selected BRAND IS STARS, we redirect to the first door that is stars for that brand, after selecting that door
//    4) when user IS in multidoor stars, and the selected BRAND IS NOT STARS, we redirect to the first door, which might need to be selected if it was previously changed when navigating another brand

export const useMenuRedirectMultidoorStars = <T>({ redirect }: Props<T>): ReturnObject<T> => {
  const dispatch = useDispatch();

  const isStarsMultidoor = useSelector(selectIsStarsMultidoor);
  const hasChangedDoor = useSelector(selectHasChangedDoor);
  const starDoorsPerBrand = useSelector(selectStarDoorsPerBrand);
  const selectedDoor = useSelector(selectSelectedDoor);
  const multidoorsList = useSelector(selectMultiDoors);

  const handleClick = (
    brand: T,
    brandIdentifier: string,
    brandLabel: string,
    isBrandStars: boolean
  ) => {
    // all cases but stars multidoor require a simple redirect to the correct prePLP / PLP
    // if it is stars multidoor, but the door has been explicitly set by the user, we still do a simple redirect, using the selected door
    if (!isStarsMultidoor || hasChangedDoor) {
      QUICK_CONSOLE_DEBUGGING &&
        console.log(
          `normal redirect for brand ${brandLabel} (isStarsMultidoor=${isStarsMultidoor}, hasChangedDoor=${hasChangedDoor})`
        );

      redirect(brand);
      return;
    }

    if (isBrandStars) {
      // if the clicked brand is stars, we look for the first door that is stars for that brand
      const starsDoor = starDoorsPerBrand[brandIdentifier]?.[0];

      // if found, we update the selectedDoor, and redirect to the correct PLP
      if (starsDoor) {
        QUICK_CONSOLE_DEBUGGING &&
          console.log(
            `selected brand ${brandLabel} is STARS for ${starsDoor?.orgentityName} - ${starsDoor?.customerName}, redirecting to PLP STARS...`
          );

        dispatch(
          updateSelectedDoor({
            door: starsDoor,
            callback: () => redirect(brand),
          })
        );
      }
    } else {
      if (selectedDoor?.orgentityId !== multidoorsList?.[0]?.orgentityId) {
        QUICK_CONSOLE_DEBUGGING &&
          console.log(
            `selected brand ${brandLabel} is NOT stars, redirecting to PLP STANDARD and RESETTING door to ${multidoorsList?.[0]?.orgentityName} - ${multidoorsList?.[0]?.customerName}...`
          );

        dispatch(
          updateSelectedDoor({
            door: multidoorsList?.[0],
            callback: () => redirect(brand),
          })
        );
      } else {
        QUICK_CONSOLE_DEBUGGING &&
          console.log(
            `selected brand ${brandLabel} is NOT stars, redirecting to PLP STANDARD without needing to reset door...`
          );
        redirect(brand);
      }
    }
  };

  return { handleClick };
};
