import { SagaIterator } from "@redux-saga/types";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { put, call, take, takeLatest, select } from "redux-saga/effects";

import { handleError } from "../store/storeSagas";
import {
  setBrandGroups,
  sliceName,
  setBrands,
  setJobTypeList,
  setPartnumbers,
  setColors,
  setUpcs,
  setSizes,
  selectBrandGroupsOpts,
  setRxAutoselectionStatus,
  setRecivedLenses,
  setLensesSections,
  setCompatibleFrames,
  setMadeInAndItemsInPackage,
  setRxPrices,
  setFocalTypeDetails,
  setCheckFrameStatus,
  setSelectedFrame,
  setGetLensesStatus,
  setMakeability,
  setGetRxPricesStatus,
  setPostMakeabilityStatus,
  setRxCartItem,
  setSelectedLens,
  setIncludedItems,
  setAllCustomerReference,
  setAddRxToPrecartStatus,
  setCustomerOwnFrame,
  selectSelectedFrame,
  selectSelectedLens,
  selectRxAutoselectionStatus,
  setIsCollection,
  selectIsCollection,
  setIsFrameAutoSelectionComplete,
  setRxSubmitError,
  setCheckedFrames,
  setOtherDescription,
  selectRxSubmitError,
} from "./rxSlice";
import { rxServices } from "./rxServices";
import {
  CheckFramePayload,
  GetBrandGroupPayload,
  GetBrandsPayload,
  GetColorsPayload,
  GetPartnumbersPayload,
  Brand,
  SelectProductOptsPayload,
  SizePayload,
  UpcCodePayload,
  JobType,
  Partnumber,
  Color,
  Size,
  MadeInPayload,
  GetLensesPayload,
  GetCartItemPayload,
  FocalTypePayload,
  RecivedLenses,
  GetPricesPayload,
  PostMakeabilityPayload,
  addRxItemsToPrecartPayload,
  Upc,
  FocalTypesDetail,
  FocalTypePrescription,
  CheckedFrames,
  Lens,
  MadeIn,
  MakeabilityPrescription,
  Eye,
  MatchingPartnumberPayload,
  PostPrecartOrderItem,
  Frame,
  RxPrice,
  Collection,
  Makeability,
  PostMakeabilityCallbackPayload,
} from "./rxInterface";
import { CustomOptions } from "../../components/styled-UI/CustomSelect";
import { selectSelectedDoor } from "../user/userSlice";
import { Door } from "../multidoor/multidoorInterface";
import { saveOrderId } from "../cart/cartSlice";
import cartService from "../cart/cartService";
import { getPrecartCount } from "../cart/cartSagas";
import { selectBrandGroupByBrandMap, selectBrandGroups } from "../store/storeSlice";
import {
  getErrorLabel,
  getJobTypeLabel,
  getMCLFromPartnumber,
  getPrescriptionDetails,
} from "../../utils/rxUtils";
import { eyes } from "../../components/widgets/Rx/Rx";

export const getBrandGroups = createAction<GetBrandGroupPayload>(`${sliceName}/brandGroups`);
export const saveBrandGroups = createAction<Brand[]>(`${sliceName}/saveBrandGroups`); // fake action
export const getBrands = createAction<GetBrandsPayload>(`${sliceName}/brands`);
export const saveBrands = createAction<Brand[]>(`${sliceName}/saveBrands`); // fake action
export const getJobTypeList = createAction<string>(`${sliceName}/jobTypeList`);
export const saveJobTypes = createAction<JobType[]>(`${sliceName}/saveJobTypes`); // fake action
export const getPartnumbers = createAction<GetPartnumbersPayload>(`${sliceName}/partnumbers`);
export const savePartnumbers = createAction<Partnumber[]>(`${sliceName}/savePartnumbers`); // fake action
export const getColors = createAction<GetColorsPayload>(`${sliceName}/colors`);
export const saveColors = createAction<Color[]>(`${sliceName}/saveColors`); // fake action
export const getSizes = createAction<SizePayload>(`${sliceName}/sizes`);
export const saveSizes = createAction<Size[]>(`${sliceName}/saveSizes`); // fake action
export const getUpcsCode = createAction<UpcCodePayload>(`${sliceName}/upcs`);
export const saveUpcs = createAction<Upc[]>(`${sliceName}/saveUpcs`); // fake action
export const selectMatchingPartnumber = createAction<MatchingPartnumberPayload>(
  `${sliceName}/selectMatchingPartnumber`
);
export const getFocalType = createAction<FocalTypePayload>(`${sliceName}/focalType`);
export const saveFocalTypes = createAction<FocalTypesDetail[]>(`${sliceName}/saveFocalTypes`); // fake action
export const checkFrame = createAction<CheckFramePayload>(`${sliceName}/checkFrame`);
export const saveFrame = createAction<CheckedFrames>(`${sliceName}/saveFrame`); // fake action
export const selectProductOpts = createAction<SelectProductOptsPayload>(
  `${sliceName}/selectProductOpts`
);
export const getLenses = createAction<GetLensesPayload>(`${sliceName}/getLenses`);
export const saveLenses = createAction<RecivedLenses | { result: string }>(
  `${sliceName}/saveLenses`
); // fake action
export const madeInAndItemsInPackage = createAction<MadeInPayload>(`${sliceName}/madeIn`);
export const saveMadeIn = createAction<MadeIn>(`${sliceName}/saveMadeIn`); // fake action
export const getRxPrices = createAction<GetPricesPayload>(`${sliceName}/getRxPrices`);
export const saveRxPrices = createAction<RxPrice[]>(`${sliceName}/saveRxPrices`);
export const postMakeability = createAction<PostMakeabilityCallbackPayload>(
  `${sliceName}/postMakeability`
);

export const getRxCartItem = createAction<GetCartItemPayload>(`${sliceName}/getCartItem`);
export const addRxItemsToPrecart = createAction<addRxItemsToPrecartPayload>(
  `${sliceName}/addRxItemsToPrecart`
);

/* SAGAS */
function* getBrandGroupsSaga({ payload }: PayloadAction<GetBrandGroupPayload>): SagaIterator {
  try {
    const door: Door = yield select(selectSelectedDoor);
    const res = yield call(rxServices.brandGroups, payload.term, door.orgentityId);
    const brandGorups = res.data.data.suggestionView[0].entry;

    yield put(setBrandGroups(brandGorups));
    yield put(saveBrandGroups(brandGorups));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getBrandsSaga({ payload }: PayloadAction<GetBrandsPayload>): SagaIterator {
  try {
    yield put(setIsCollection(false));

    let brands: Brand[] = [];
    const door: Door = yield select(selectSelectedDoor);
    const brandsRes = yield call(
      rxServices.brands,
      payload.brandGroupsValue,
      payload.term,
      door.orgentityId
    );
    brands = brandsRes.data.data.suggestionView[0].entry;

    const brandGroups = yield select(selectBrandGroups);
    if (brandGroups[payload.brandGroupsValue].length === 1 && payload.brandGroupsValue !== "AW") {
      const collectionRes = yield call(
        rxServices.getCollections,
        payload.brandGroupsValue,
        door.orgentityId
      );
      brands = collectionRes.data.data.facetView[0].entry.map(
        (brand: Collection): Brand => ({ term: brand.identifier, label: brand.label })
      );
      yield put(setIsCollection(true));
    }

    yield put(setBrands(brands));
    yield put(saveBrands(brands));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getJobTypeListSaga({ payload }: PayloadAction<string>): SagaIterator {
  try {
    const door: Door = yield select(selectSelectedDoor);
    const res = yield call(rxServices.getJobTypeList, payload, door.orgentityId);

    const jobTypes = res.data.data.jobType;
    yield put(setJobTypeList(jobTypes));
    yield put(saveJobTypes(jobTypes));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getPartnumbersSaga({ payload }: PayloadAction<GetPartnumbersPayload>): SagaIterator {
  try {
    const door: Door = yield select(selectSelectedDoor);
    const isCollection = yield select(selectIsCollection);
    let res: any = null;

    if (isCollection) {
      res = yield call(
        rxServices.getCollectionPartnumbers,
        payload.brandGroup,
        payload.brand,
        payload.jobType,
        door.orgentityId
      );
    } else {
      res = yield call(rxServices.getPartnumbers, payload.brand, payload.jobType, door.orgentityId);
    }

    const models = res.data.data.catalogEntryView;
    yield put(setPartnumbers(models));
    yield put(savePartnumbers(models));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getColorsSaga({ payload }: PayloadAction<GetColorsPayload>): SagaIterator {
  try {
    const door: Door = yield select(selectSelectedDoor);
    const res = yield call(
      rxServices.getColors,
      payload.partNumber,
      payload.jobType,
      door.orgentityId
    );
    const colors = res.data.data.catalogEntryView;
    yield put(setColors(colors));
    yield put(saveColors(colors));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getSizesSaga({ payload }: PayloadAction<SizePayload>): SagaIterator {
  try {
    const door: Door = yield select(selectSelectedDoor);
    const res = yield call(
      rxServices.getSize,
      payload.jobType,
      payload.catentryId,
      door.orgentityId
    );
    yield put(setSizes(res.data.data.catalogEntryView));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getUpcsCodeSaga({ payload }: PayloadAction<UpcCodePayload>): SagaIterator {
  try {
    const door: Door = yield select(selectSelectedDoor);
    const isCollection = yield select(selectIsCollection);

    const res = yield call(
      rxServices.getUpcCode,
      isCollection ? payload.brandGroup : payload.brand,
      payload.jobType,
      payload.term,
      door.orgentityId
    );
    const upcs = res.data.data.catalogEntryView || [];
    yield put(setUpcs(upcs));
    yield put(saveUpcs(upcs));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* selectMatchingPartnumberSaga({
  payload,
}: PayloadAction<MatchingPartnumberPayload>): SagaIterator {
  try {
    const selectValues: { [x: string]: CustomOptions } = {};
    let partNumbers = null;
    if (payload.partNumber) {
      partNumbers = getMCLFromPartnumber(payload.partNumber);
    }
    const modelId = partNumbers ? partNumbers.modelId : payload?.model;
    const colorId = partNumbers ? partNumbers.colorId : payload?.color;
    const sizeId = partNumbers ? partNumbers.sizeId : payload?.size;

    // model
    yield put(
      getPartnumbers({
        brandGroup: payload.brandGroup,
        brand: payload.brand,
        jobType: payload.jobType,
      })
    );
    const { payload: models } = yield take(savePartnumbers.type);
    const model: Partnumber = models.find((model: Partnumber) => model.partNumber === modelId);

    selectValues.model = {
      value: model.partNumber,
      label: `${model.partNumber}${model.name ? "-" + model.name : ""}`,
    };
    payload.handleMultiSelectChange(selectValues);

    // color
    if (!payload.color && !payload.partNumber) {
      return;
    }
    yield put(getColors({ partNumber: model.partNumber, jobType: payload.jobType }));
    const { payload: colors } = yield take(saveColors.type);
    const color: Color = colors.find((color: Color) => {
      return color.partNumber.split("_")[1] === colorId;
    });

    selectValues.color = {
      value: color.uniqueID,
      label: `${color.partNumber.split("_")[1]}${
        color.attributes?.[1].values[0].identifier
          ? "-" + color.attributes[1].values[0].identifier
          : ""
      }`,
    };
    payload.handleMultiSelectChange(selectValues);

    // size
    if (!payload.size && !payload.partNumber) {
      return;
    }
    selectValues.size = { value: sizeId as string, label: sizeId as string };
    payload.handleMultiSelectChange(selectValues);
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getFocalTypeSaga({ payload }: PayloadAction<FocalTypePayload>): SagaIterator {
  try {
    const door: Door = yield select(selectSelectedDoor);
    const isCollection = yield select(selectIsCollection);
    const res = yield call(
      rxServices.getFocalType,
      isCollection ? payload.brandGroup : payload.brand,
      payload.jobType,
      door.orgentityId
    );

    const focalTypes = res.data.data.output.focalTypeDetails;
    yield put(setFocalTypeDetails(focalTypes));
    yield put(saveFocalTypes(focalTypes));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* selectProductOptsSaga({
  payload: { product, handleMultiSelectChange },
}: PayloadAction<SelectProductOptsPayload>): SagaIterator {
  try {
    const door: Door = yield select(selectSelectedDoor);
    yield put(setRxAutoselectionStatus("LOADING"));
    const selectValues: { [x: string]: CustomOptions } = {};

    const { modelId, colorId } = getMCLFromPartnumber(product.partNumberSku);

    // brand groups
    const brandGroupsRes = yield call(rxServices.brandGroups, undefined, door.orgentityId);
    yield put(setBrandGroups(brandGroupsRes.data.data.suggestionView[0].entry));

    const brandGroupOpts = yield select(selectBrandGroupsOpts);
    const brandGroupOpt: CustomOptions = brandGroupOpts.find((brandGroup: CustomOptions) => {
      return brandGroup.value === product.brandGroup;
    });

    selectValues.brandGroup = brandGroupOpt;
    handleMultiSelectChange(selectValues);

    // brands
    yield put(getBrands({ brandGroupsValue: product.brandGroup as string }));
    const { payload: brands } = yield take(saveBrands.type);
    yield put(setBrands(brands));

    const isCollection = yield select(selectIsCollection);
    const brandToMatch = isCollection ? product.collection : product.brand;
    const brand: Brand = brands.find((brand: Brand) => brand.term.toUpperCase() === brandToMatch);
    const brandOpt = {
      label: brand.term,
      value: brand.term,
    };

    handleMultiSelectChange({
      brandGroup: brandGroupOpt,
      brand: brandOpt,
    });

    selectValues.brand = brandOpt;
    handleMultiSelectChange(selectValues);

    // job types
    const jobTypeRes = yield call(
      rxServices.getJobTypeList,
      isCollection ? (brandGroupOpt.value as string) : brand.term,
      door.orgentityId
    );
    const jobTypes = jobTypeRes.data.data.jobType;
    yield put(setJobTypeList(jobTypes));

    const jobType: JobType = jobTypes.find(
      (jobTypes: JobType) => jobTypes.identifier === product.jobType
    );
    const jobTypeOpt = { label: jobType.identifier, value: jobType.identifier };

    selectValues.jobType = jobTypeOpt;
    handleMultiSelectChange(selectValues);

    // focal types
    yield put(
      getFocalType({
        brandGroup: brandGroupOpt.value as string,
        brand: brand.term,
        jobType: jobType.identifier,
      })
    );

    // model
    yield put(
      getPartnumbers({
        brandGroup: brandGroupOpt.value as string,
        brand: brand.term,
        jobType: jobType.identifier,
      })
    );
    const { payload: models } = yield take(savePartnumbers.type);

    yield put(setPartnumbers(models));

    const model: Partnumber = models.find((model: Partnumber) => model.partNumber === modelId);
    const modelOpt = {
      label: model.partNumber + (model.name ? "-" + model.name : ""),
      value: model.partNumber,
    };

    selectValues.model = modelOpt;
    handleMultiSelectChange(selectValues);

    // color
    const colorsRes = yield call(
      rxServices.getColors,
      model.partNumber,
      jobType.identifier,
      door.orgentityId
    );
    const colors = colorsRes.data.data.catalogEntryView;
    yield put(setColors(colors));

    const color: Color = colors.find((color: Color) => {
      return color.partNumber.split("_")[1] === colorId;
    });

    const colorOpt = {
      value: color.uniqueID,
      label:
        (color.attributes?.[0].values[0].identifier
          ? color.attributes[0].values[0].identifier + "-"
          : "") + color.attributes?.[1].values[0].identifier ?? "",
    };

    selectValues.color = colorOpt;
    handleMultiSelectChange(selectValues);

    // size
    const sizeRest = yield call(
      rxServices.getSize,
      jobType.identifier,
      color.uniqueID,
      door.orgentityId
    );
    const sizes = sizeRest.data.data.catalogEntryView;
    yield put(setSizes(sizes));

    const size: Size = sizes.find((size: Size) => size.partNumber === product.partNumberSku);

    const sizeOpt = {
      value: size.partNumber.slice(-2),
      label: size.partNumber.slice(-2),
    };

    selectValues.size = sizeOpt;
    handleMultiSelectChange(selectValues);

    yield put(setRxAutoselectionStatus("SUCCESS"));
    yield put(setIsFrameAutoSelectionComplete("SUCCESS"));
  } catch (error) {
    yield put(setRxAutoselectionStatus("ERROR"));
    yield put(handleError(error));
    console.error(error);
  }
}

function* checkFrameSaga({ payload }: PayloadAction<CheckFramePayload>): SagaIterator {
  try {
    yield put(setCheckFrameStatus("LOADING"));
    const door: Door = yield select(selectSelectedDoor);
    const { data } = yield call(rxServices.checkFrame, payload.req, door.orgentityId);
    const checkedFrames = data.data.output;

    if (checkedFrames.result !== "SUCCESS") {
      yield put(setCompatibleFrames(checkedFrames.compatibleFrames));
      const rxSubmitError = getErrorLabel(checkedFrames.errorDetails, "FRAME");
      yield put(setRxSubmitError(rxSubmitError));
      throw { result: "ERROR" };
    }
    if (checkedFrames.result === "SUCCESS") {
      yield put(
        setSelectedFrame({
          itemIdentifier: payload.req.itemIdentifier,
          upc: payload.req.upc as string,
          height: checkedFrames.compatibleFrames[0].height,
          refraction: checkedFrames.compatibleFrames[0].refraction || 0,
        })
      );
      yield put(setCheckedFrames(checkedFrames));
      yield put(setCompatibleFrames(null));

      const getLensesPaylod: GetLensesPayload = {
        ...payload.req,
        lensCategoryIdentifier: null,
        height: checkedFrames.compatibleFrames[0].height,
        refraction: checkedFrames.compatibleFrames[0].refraction || 0,
        focalTypeName: payload.focalTypeName,
      };

      yield put(getLenses(getLensesPaylod));
      const { payload: recivedLenses } = yield take(saveLenses.type);
      yield put(saveFrame(checkedFrames));

      if (recivedLenses.result === "SUCCESS") {
        yield put(setCheckFrameStatus("SUCCESS"));
        payload.successHandler();
      }
      if (recivedLenses.result !== "SUCCESS") {
        throw { result: "ERROR" };
      }
    }
  } catch (error) {
    yield put(setCheckFrameStatus("ERROR"));
    yield put(handleError(error));
    const rxAutoselectionStatus = yield select(selectRxAutoselectionStatus);
    if (rxAutoselectionStatus === "LOADING") {
      yield put(setRxAutoselectionStatus("ERROR"));
    }

    const rxSubmitError = yield select(selectRxSubmitError);
    if (!rxSubmitError) {
      yield put(
        setRxSubmitError({
          errorType: error?.response?.status === 504 ? "TIMEOUT" : "GENERIC",
          errorCode: error?.response?.status,
          errorMessage:
            error?.response?.status === 504
              ? "TO_TRANSLATE:RX_TIMEOUT"
              : "TO_TRANSLATE:RX_GENERIC_ERROR",
        })
      );
    }
  }
}

function* getLensesSaga({ payload }: PayloadAction<GetLensesPayload>): SagaIterator {
  try {
    yield put(setGetLensesStatus("LOADING"));
    const door: Door = yield select(selectSelectedDoor);

    const { data }: { data: { data: { output: RecivedLenses } } } = yield call(
      rxServices.getLenses,
      payload,
      door.orgentityId
    );
    const recivedLenses = data.data.output;

    yield put(setRecivedLenses(recivedLenses));
    if (recivedLenses.result === "SUCCESS") {
      yield put(
        setLensesSections(
          recivedLenses.lensCategory.map((lensCat) => ({
            lensCategoryIdentifier: lensCat.lensCategoryIdentifier,
            lenscategorytranslatedname: lensCat.lenscategorytranslatedname,
          }))
        )
      );
      yield put(saveLenses(recivedLenses));
      yield put(setGetLensesStatus("SUCCESS"));
    }
    if (recivedLenses.result !== "SUCCESS") {
      const rxSubmitError = getErrorLabel(recivedLenses.errorDetails, "LENS");
      yield put(setRxSubmitError(rxSubmitError));
      yield put(setCheckFrameStatus("ERROR"));
      throw { result: "ERROR" };
    }
  } catch (error) {
    yield put(setGetLensesStatus("ERROR"));
    yield put(setCheckFrameStatus("ERROR"));
    yield put(saveLenses({ result: "ERROR" }));
    yield put(handleError(error));

    const rxAutoselectionStatus = yield select(selectRxAutoselectionStatus);
    if (rxAutoselectionStatus === "LOADING") {
      yield put(setRxAutoselectionStatus("ERROR"));
    }

    const rxSubmitError = yield select(selectRxSubmitError);
    if (!rxSubmitError) {
      if (error?.response?.errors?.[0]?.responseBody.output.errorDetails) {
        const errorDetails = error?.response?.errors?.[0]?.responseBody.output.errorDetails;
        yield put(
          setRxSubmitError({
            errorType: errorDetails.errorType,
            errorCode: errorDetails.errorCode,
            errorMessage: errorDetails.errorMessage,
          })
        );
        return;
      }
      if (error?.response?.data.errors[0].responseBody.output.errorDetails.errorMessage) {
        const errorDetails = error?.response?.data.errors[0].responseBody.output.errorDetails;
        yield put(
          setRxSubmitError({
            errorType: errorDetails.errorType,
            errorCode: errorDetails.errorCode,
            errorMessage: errorDetails.errorMessage,
          })
        );
        return;
      } else {
        yield put(
          setRxSubmitError({
            errorType: error?.response?.status === 504 ? "TIMEOUT" : "GENERIC",
            errorCode: error?.response?.status,
            errorMessage:
              error?.response?.status === 504
                ? "TO_TRANSLATE:RX_TIMEOUT"
                : "TO_TRANSLATE:RX_GENERIC_ERROR",
          })
        );
      }
    }
  }
}

function* madeInAndItemsInPackageSaga({ payload }: PayloadAction<MadeInPayload>): SagaIterator {
  try {
    const { data } = yield call(rxServices.madeIn, payload);
    const madeIn: MadeIn = data.data.output;
    yield put(setMadeInAndItemsInPackage(madeIn));
    yield put(saveMadeIn(madeIn));
  } catch (error) {
    yield put(handleError(error));
    console.error(error);
  }
}

function* getRxPricesSaga({ payload }: PayloadAction<GetPricesPayload>): SagaIterator {
  try {
    yield put(setGetRxPricesStatus("LOADING"));
    const { data } = yield call(rxServices.getPrices, payload.req, payload.doorId);

    const prices: RxPrice[] = data.data.output[0].prices;
    yield put(setRxPrices(prices));
    yield put(saveRxPrices(prices));
    yield put(setGetRxPricesStatus("SUCCESS"));
    payload.successHandler?.(4);
  } catch (error) {
    yield put(setGetRxPricesStatus("ERROR"));
    yield put(handleError(error));
    console.error(error);
  }
}

function* postMakeabilitySaga({
  payload,
}: PayloadAction<PostMakeabilityCallbackPayload>): SagaIterator {
  try {
    yield put(setPostMakeabilityStatus("LOADING"));
    const door: Door = yield select(selectSelectedDoor);

    const { data }: { data: { data: Makeability } } = yield call(
      rxServices.postMakeability,
      payload.makeabilityObject,
      door.orgentityId
    );

    yield put(setMakeability(data.data));
    yield put(
      setRxSubmitError(
        data.data.isBlockingError
          ? {
              errorType: "BLOCKING_ERROR",
              errorCode: 0,
              errorMessage: data.data.errorMessage ?? "",
            }
          : null
      )
    );
    if (data.data.isBlockingError) {
      payload.callback(false);
    } else {
      payload.callback(true);
    }
    yield put(setPostMakeabilityStatus("SUCCESS"));
  } catch (error) {
    yield put(setPostMakeabilityStatus("ERROR"));
    yield put(handleError(error));
    yield put(
      setRxSubmitError({
        errorType: error?.response?.status === 504 ? "TIMEOUT" : "GENERIC",
        errorCode: error?.response?.status,
        errorMessage:
          error?.response?.status === 504
            ? "TO_TRANSLATE:RX_TIMEOUT"
            : "TO_TRANSLATE:RX_GENERIC_ERROR",
      })
    );
    console.error(error);
  }
}

function* getRxCartItemSaga({ payload }: PayloadAction<GetCartItemPayload>): SagaIterator {
  try {
    yield put(setRxAutoselectionStatus("LOADING"));

    const selectValues: { [x: string]: CustomOptions } = {};

    // getRxCartItem
    const { data } = yield call(rxServices.getCartItem, payload.orderItemsId);
    const cartItem: PostPrecartOrderItem = data.data.orderItem[0];
    yield put(setRxCartItem(cartItem));

    // brandGroup
    yield put(getBrandGroups({ term: "*" }));
    const { payload: brandGroups } = yield take(saveBrandGroups.type);

    const brandGroupByBrandMap = yield select(selectBrandGroupByBrandMap);
    const selBrandGroup = cartItem.xitem_RXbrand && brandGroupByBrandMap[cartItem.xitem_RXbrand];
    const brandGroup: Brand = brandGroups.find(
      (brandGroup: Brand) => brandGroup.term === selBrandGroup
    );

    selectValues.brandGroup = {
      value: brandGroup.term,
      label: brandGroup.term,
    };
    payload.handleMultiSelectChange(selectValues);

    // brand
    yield put(getBrands({ brandGroupsValue: brandGroup.term }));
    const { payload: brands } = yield take(saveBrands.type);

    const brand: Brand = brands.find((brand: Brand) => brand.term === cartItem.xitem_RXbrand);

    selectValues.brand = { value: brand.term, label: brand.term };
    payload.handleMultiSelectChange(selectValues);

    // job type
    const isCollection = yield select(selectIsCollection);

    yield put(getJobTypeList(isCollection ? (brandGroup.term as string) : brand.term));
    const { payload: jobTypes } = yield take(saveJobTypes.type);
    const jobType: JobType = jobTypes.find(
      (jobType: JobType) => jobType.identifier === getJobTypeLabel[cartItem.xitem_field1 as string]
    );

    selectValues.jobType = {
      value: jobType.identifier,
      label: jobType.identifier,
    };
    payload.handleMultiSelectChange(selectValues);

    // customer own frame
    if (
      cartItem?.xitem_RXisCustomerFrame !== null &&
      cartItem?.xitem_RXisCustomerFrame !== undefined
    ) {
      yield put(setCustomerOwnFrame(cartItem?.xitem_RXisCustomerFrame === "true"));
    }

    // upc
    yield put(
      getUpcsCode({
        brandGroup: brandGroup.term,
        brand: brand.term,
        jobType: jobType.identifier,
        term: cartItem.productsDetails.upc,
      })
    );
    const { payload: upcs } = yield take(saveUpcs.type);

    const upc: Upc = upcs[0];

    selectValues.upc = { value: upc.upc, label: upc.upc };
    payload.handleMultiSelectChange(selectValues);

    // send analytics of frame
    yield put(setIsFrameAutoSelectionComplete("SUCCESS"));

    // focal type
    yield put(
      getFocalType({ brandGroup: brandGroup.term, brand: brand.term, jobType: jobType.identifier })
    );
    const { payload: focalTypes } = yield take(saveFocalTypes.type);
    const focalType: FocalTypesDetail = focalTypes.find(
      (focalType: FocalTypesDetail) => focalType.focalTypeName === cartItem.xitem_RXfocalTypeName
    );

    selectValues.focalType = {
      value: cartItem.xitem_RXfocalType as string,
      label: focalType.focalTypeName,
    };
    payload.handleMultiSelectChange(selectValues);

    // prescription details
    const { prescrDetails } = getPrescriptionDetails(focalType, cartItem);

    const prescriptions = eyes.map((eye: Eye) => ({
      eyeIdentifier: eye.toUpperCase(),
      parameters: prescrDetails?.map((detail) => ({
        parameterName: detail.parameterName,
        parameterValue: detail[eye] as string,
        isRequired: detail.isRequired,
        minValue: "",
        maxValue: "",
        stepBetweenMinAndMax: "",
        formatRegex: "",
      })) as FocalTypePrescription[],
    }));

    // check frame
    yield put(
      checkFrame({
        req: {
          itemIdentifier: upc.partNumber,
          jobType: jobType.identifier,
          prescriptions,
          upc: upc.upc,
        },
        focalTypeName: focalType.focalTypeName,
        successHandler: payload.goToNextStep,
      })
    );
    const { payload: checkedFrames } = yield take(saveFrame.type);
    if (!checkedFrames || checkedFrames?.result !== "SUCCESS") throw { result: "ERROR" };

    // get lenses
    yield put(
      getLenses({
        itemIdentifier: upc.partNumber,
        lensIdentifier: cartItem.xitem_RXlensId,
        upc: upc.upc,
        jobType: jobType.identifier,
        prescriptions,
        height: checkedFrames.compatibleFrames[0].height,
        refraction: checkedFrames.compatibleFrames[0].refrection || 0,
        lensCategoryIdentifier: cartItem.xitem_RXlensCategory as string,
        focalTypeName: cartItem.xitem_RXfocalTypeName as string,
      })
    );
    const { payload: recivedLenses } = yield take(saveLenses.type);
    if (recivedLenses.result !== "SUCCESS") throw { result: "ERROR" };

    const selectedLens: Lens | undefined = recivedLenses.lensCategory[0].lenses.find(
      (lens: Lens) => lens.lensIdentifier === cartItem.xitem_RXlensId
    );
    if (!selectedLens) throw { result: "ERROR" };
    yield put(setSelectedLens(selectedLens));

    selectValues.lensType = {
      value: cartItem.xitem_RXlensCategory as string,
      label: cartItem.xitem_RXlensCategory as string,
    };
    payload.handleMultiSelectChange(selectValues);

    // made in call
    yield put(
      madeInAndItemsInPackage({
        jobType: jobType.identifier,
        brand: brand.term,
      })
    );
    const { payload: madeIn } = yield take(saveMadeIn.type);

    // laterality
    if (cartItem.xitem_RXlaterality) {
      selectValues.laterality = {
        value: cartItem.xitem_RXlaterality,
        label: cartItem.xitem_RXlaterality === "R" ? "Right" : "Left",
      };
      payload.handleMultiSelectChange(selectValues);
    }

    // items in pkg
    if (madeIn?.itemsIncluded.length && cartItem.xitem_RXitemsInPackage) {
      const ItemsInPkg = cartItem.xitem_RXitemsInPackage.split(";");
      for (let index = 0; index < ItemsInPkg.length; index++) {
        if (ItemsInPkg[index].includes("ITEMPKG_OTHERS")) {
          yield put(setOtherDescription(ItemsInPkg[index].split("---")[1]));
          ItemsInPkg[index] = "ITEMPKG_OTHERS";
        }
      }
      if (!ItemsInPkg.every((ItemsInPkg) => madeIn.itemsIncluded.includes(ItemsInPkg))) {
        throw { result: "ERROR" };
      }
      yield put(setIncludedItems(ItemsInPkg));
    }

    // made in options
    if (madeIn?.madeInOptions.length && cartItem.xitem_RXmadeIn) {
      if (!madeIn.madeInOptions.includes(cartItem.xitem_RXmadeIn)) {
        throw { result: "ERROR" };
      }
      selectValues.madeIn = {
        value: cartItem.xitem_RXmadeIn,
        label: cartItem.xitem_RXmadeIn,
      };
      payload.handleMultiSelectChange(selectValues);
    }

    // selectable options
    if (madeIn?.selectableOptions.length && cartItem.xitem_RXselectOptValue) {
      if (!madeIn.selectableOptions[0].optionValues.includes(cartItem.xitem_RXselectOptValue)) {
        throw { result: "ERROR" };
      }
      selectValues.selectableOpt = {
        value: cartItem.xitem_RXselectOptValue,
        label: cartItem.xitem_RXselectOptValue,
      };
      payload.handleMultiSelectChange(selectValues);
    }

    // customer references
    yield put(
      setAllCustomerReference({
        name: cartItem.xitem_RXcustomerReference1 as string,
        surname: cartItem.xitem_RXcustomerReference2 as string,
      })
    );

    // makeability
    const mkPrescription = eyes.map((eye) => ({
      eyeIdentifier: eye.charAt(0).toUpperCase() as string,
      parameters: prescrDetails.map((detail) => ({
        parameterName: detail.parameterName.toLowerCase(),
        parameterValue: detail[eye] as string,
      })),
    })) as MakeabilityPrescription[];

    const selectedDoor = yield select(selectSelectedDoor);

    const makabilityReq: PostMakeabilityPayload = {
      billTo: selectedDoor?.orgentityName as string,
      shipTo: selectedDoor?.orgentityName as string,
      brand: brand.term,
      jobType: jobType.identifier,
      focalType: cartItem.xitem_RXfocalType as string,
      focalTypeName: focalType.focalTypeName,
      frameUpc: upc.upc,
      lensId: selectedLens?.lensIdentifier as string,
      customerReference1: cartItem.xitem_RXcustomerReference1 as string,
      customerReference2: cartItem.xitem_RXcustomerReference2 as string,
      prescription: mkPrescription,
    };
    if (cartItem.xitem_RXisCustomerFrame) {
      makabilityReq.isCustomerFrame = cartItem.xitem_RXisCustomerFrame === "true";
    }
    if (cartItem.xitem_RXlaterality) {
      makabilityReq.laterality = cartItem.xitem_RXlaterality as string;
    }

    yield put(
      postMakeability({
        makeabilityObject: makabilityReq,
        callback: (success?: boolean) => {
          return success ?? true;
        },
      })
    );

    // calculate prices
    if (!payload.hideAll) {
      const itemsToPrice: string[] = [selectedLens?.lensIdentifier as string, upc.partNumber];
      yield put(
        getRxPrices({
          req: itemsToPrice,
          doorId: selectedDoor.orgentityId,
          successHandler: payload.goToNextStep,
        })
      );
    }
    yield put(setRxAutoselectionStatus("SUCCESS"));
  } catch (error) {
    yield put(setRxAutoselectionStatus("ERROR"));
    yield put(handleError(error));
    console.error(error);
  }
}

function* addRxItemsToPrecartSaga({
  payload,
}: PayloadAction<addRxItemsToPrecartPayload>): SagaIterator {
  try {
    yield put(setAddRxToPrecartStatus("LOADING"));
    const objToSend = { ...payload.req };

    if (payload.itemsToPrice) {
      const selectedFrame: Frame = yield select(selectSelectedFrame);
      const selectedLens: Lens = yield select(selectSelectedLens);
      const itemsToSend = [selectedLens.lensIdentifier];
      const selectedDoor = yield select(selectSelectedDoor);
      if (payload.itemsToPrice.length > 1) {
        itemsToSend.push(selectedFrame.itemIdentifier);
      }
      yield put(getRxPrices({ req: itemsToSend, doorId: selectedDoor.orgentityId }));
      const { payload: prices } = yield take(saveRxPrices.type);
      // const framePrice: RxPrice = prices.find((price: RxPrice) => !price.itemIdentifier.includes("LSA"))
      const lensPrice: RxPrice = prices.find((price: RxPrice) =>
        price.itemIdentifier.includes("LSA")
      );
      const framePrice: RxPrice | undefined = prices.find(
        (price: RxPrice) => !price.itemIdentifier.includes("LSA")
      );

      objToSend.orderItem[0].xitem_RXlensSrPrice = lensPrice.srPrice.toString();
      objToSend.orderItem[0].xitem_RXlensWhsPrice = lensPrice.whsPrice.toString();
      objToSend.orderItem[0].xitem_RXframeSrPrice = framePrice?.srPrice.toString() ?? "0";
      objToSend.orderItem[0].xitem_RXframeWhsPrice = framePrice?.whsPrice.toString() ?? "0";
    }

    const { data } = yield call(cartService.postPrecartItems, objToSend);
    yield put(saveOrderId(data.data.orderId)); // update orderId
    yield put(getPrecartCount(true)); // force update the precart count
    payload.successHandler();
    yield put(setAddRxToPrecartStatus("SUCCESS"));
  } catch (error) {
    yield put(setAddRxToPrecartStatus("ERROR"));
    yield put(handleError(error));
    console.error(error);
    setRxSubmitError({
      errorType: error?.response?.status === 504 ? "TIMEOUT" : "GENERIC",
      errorCode: error?.response?.status,
      errorMessage:
        error?.response?.status === 504
          ? "TO_TRANSLATE:RX_TIMEOUT"
          : "TO_TRANSLATE:RX_ADD_TO_CART_GENERIC_ERROR",
    });
  }
}

export function* rxSaga(): SagaIterator {
  yield takeLatest(getBrandGroups.type, getBrandGroupsSaga);
  yield takeLatest(getBrands.type, getBrandsSaga);
  yield takeLatest(getJobTypeList.type, getJobTypeListSaga);
  yield takeLatest(getPartnumbers.type, getPartnumbersSaga);
  yield takeLatest(getColors.type, getColorsSaga);
  yield takeLatest(getUpcsCode.type, getUpcsCodeSaga);
  yield takeLatest(getSizes.type, getSizesSaga);
  yield takeLatest(getFocalType.type, getFocalTypeSaga);
  yield takeLatest(checkFrame.type, checkFrameSaga);
  yield takeLatest(selectProductOpts.type, selectProductOptsSaga);
  yield takeLatest(getLenses.type, getLensesSaga);
  yield takeLatest(madeInAndItemsInPackage.type, madeInAndItemsInPackageSaga);
  yield takeLatest(postMakeability.type, postMakeabilitySaga);
  yield takeLatest(getRxPrices.type, getRxPricesSaga);
  yield takeLatest(getRxCartItem.type, getRxCartItemSaga);
  yield takeLatest(addRxItemsToPrecart.type, addRxItemsToPrecartSaga);
  yield takeLatest(selectMatchingPartnumber.type, selectMatchingPartnumberSaga);
}
