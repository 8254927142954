import { formatDate } from "./dateUtils";
import { addMonths } from "date-fns";
import { AcceptCookieType } from "../hooks/useCookies";

export class CookieUtils {
  set = (name: string, value: string, exdays: any, domain = ""): void => {
    const d = new Date(exdays);
    const expires = "expires=" + d.toUTCString();
    const cookie = name + "=" + value + ";" + expires + ";path=/;";
    let newCookie = cookie;

    if (domain !== "") newCookie = cookie + "domain=" + domain;

    document.cookie = newCookie;
  };

  get = (name: string): string => {
    const nameCookie = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);

    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(nameCookie) == 0) {
        return c.substring(nameCookie.length, c.length);
      }
    }
    return "";
  };
}

/* Name of the cookie about cookie acceptance */
export const acceptCookieName = "myluxottica-cookie-accept";

/**
 * get cookie the save info about cookie acceptance
 * @return {*}  {string}
 */
export const getAcceptCookie = (): string => {
  const cookie = new CookieUtils();
  return cookie.get(acceptCookieName);
};

export const setAcceptCookie = (cookieValue: AcceptCookieType, locale: string): void => {
  const duration = formatDate(addMonths(new Date(), 13), "P", locale);
  const cookie = new CookieUtils();
  cookie.set(acceptCookieName, cookieValue, duration);
};
