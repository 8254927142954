import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import styled from "styled-components/macro";
import { selectIsLogged } from "../../../store/user/userSlice";
import Button from "../../styled-UI/Button";
import CustomText from "../../styled-UI/CustomText";
import Popup from "../../styled-UI/Popup";

interface Props {
  navigate: (path: string) => void;
  when: boolean;
  hideModal?: boolean;
  shouldBlockNavigation: (location: Location) => boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onLeaveCurrentRoute?: (nextLocation?: Location) => void;
  cancel: string;
  confirm: string;
  content: string;
  title: string;
  alwaysRedirect?: boolean;
  "data-element-id"?: string;
}

export const RouteLeavingGuard = ({
  navigate,
  when,
  hideModal = false,
  shouldBlockNavigation,
  onConfirm,
  onCancel,
  onLeaveCurrentRoute,
  cancel,
  confirm,
  content,
  title,
  alwaysRedirect = false,
  "data-element-id": dataElementId,
}: Props): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [lastLocation, updateLastLocation] = useState<Location | null>();
  const [confirmedNavigation, updateConfirmedNavigation] = useState<boolean>(false);
  const isLogged = useSelector(selectIsLogged);

  const showModal = (location: Location) => {
    setIsModalOpen(true);
    updateLastLocation(location);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (isLogged && !confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      //when shouldBlockNavigation is true, call callback if present

      if (onLeaveCurrentRoute) {
        onLeaveCurrentRoute(nextLocation);
        return true;
      }
      //else show modal
      showModal(nextLocation);
      return false;
    }
    //go on without doing anything
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setIsModalOpen(false);
    lastLocation && updateConfirmedNavigation(true);
    onConfirm && onConfirm();
  };

  const handleCancelNavigationClick = () => {
    setIsModalOpen(false);
    onCancel && onCancel();
    alwaysRedirect && lastLocation && updateConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation as any} />
      {!hideModal && (
        <Popup title={title} isOpen={isModalOpen} close={closeModal} isCentered zindex={3000}>
          <ModalContainer>
            <WrapMessage>
              <CustomText as="p" fontSizePx={18} font="font-bold" color="primary" lineHeightPx={24}>
                {content}
              </CustomText>
            </WrapMessage>

            <ButtonWrap>
              <Button type="secondary" fullWidth onClick={handleConfirmNavigationClick}>
                {confirm}
              </Button>
              <Button
                type="primary"
                fullWidth
                data-element-id={dataElementId}
                onClick={handleCancelNavigationClick}
              >
                {cancel}
              </Button>
            </ButtonWrap>
          </ModalContainer>
        </Popup>
      )}
    </>
  );
};

const ModalContainer = styled.div`
  padding: 3rem 6.6875rem 2rem 6.6875rem;
`;

const WrapMessage = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 25.125rem;
  margin: auto;
  margin-bottom: 2rem;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 29rem;

  & > button:first-child {
    margin-right: 0.75rem;
  }
  & > button:last-child {
    margin-left: 0.75rem;
  }
`;

export default RouteLeavingGuard;
