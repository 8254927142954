import React from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import CustomText from "../../styled-UI/CustomText";
import HighlightsCarousel from "./HighlightsCarousel";
import { HighlightSlide } from "../../../interfaces/cmsInterfaces";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  slides: HighlightSlide[];
  customFont?: FontFamilies | null;
}

const HighlightsSection = ({ slides, customFont = null }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  return slides && slides.length > 0 ? (
    <Highlights {...{ "data-element-id": "Highlights" }}>
      <TitleContainer>
        <CustomText
          as="span"
          font={customFont ? "custom-regular" : "font-regular"}
          fontFamily={customFont}
          fontSizePx={40}
        >
          {translateLabel("HIGHLIGHTS")}
        </CustomText>
      </TitleContainer>
      {slides && <HighlightsCarousel slides={slides} customFont={customFont} />}
    </Highlights>
  ) : (
    <></>
  );
};

const Highlights = styled.div`
  width: 100%;
  padding: 1.5625rem 0 1.5625rem 0;
  background: rgb(1, 31, 93);
  background: radial-gradient(
    farthest-corner at right bottom,
    rgba(1, 31, 93, 1) 0%,
    rgba(2, 35, 122, 1) 50%,
    rgba(0, 6, 40, 1) 100%
  );
  position: relative;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;

  span {
    line-height: normal;
    color: ${(props) => props.theme.palette.white};
  }
`;

export default HighlightsSection;

export const MemoizedHighlightsSection = React.memo(HighlightsSection);
