import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  menuStructure,
  MenuStructureKeys,
  MenuColumn as MenuColumnInterface,
  GogglesHelmetsType,
} from "../components/layouts/header/navbar/categories-menu/category-item/menuStructure";
import { FacetView, FacetViewEntry } from "../interfaces/facetInterfaces";
import { SecondLevelMenu } from "../interfaces/menuInterfaces";
import {
  selectBrandGroups,
  selectBrandLabels,
  selectThirdLevelMenu,
} from "../store/store/storeSlice";
import { isBrandFilter } from "../utils/filterUtils";
import { ColBrandGroup, getCompleteId, groupBrandGroupByType } from "../utils/menuUtils";
import { capitalizeFirstLetter, composeUrlWithParams } from "../utils/utils";
import useCanRender from "./useCanRender";
import { selectIsMultidoor } from "../store/user/userSlice";

export interface MenuDivInfo {
  key: string;
  isStars?: boolean; //only used by brand
  keyColumn: string;
  facetsBrand: FacetViewEntry[]; //only used by brand
  facetsCategory: FacetView[]; //only used by category
  baseUrl: string;
  isKids?: boolean; //only used by brand
  identifier: string;
  title: string; //only used by brand
  subtitle?: string; //only used by brand
  showStarIcon?: boolean; //only used by brand
  columnStructure: MenuColumnInterface; //only used by category
  nameCategory?: string; //only used by category
  isBrandColumn: boolean; //is column brand or not?
  brandColumns: ColBrandGroup[];
  categoryColumns: CategoryColumnInfo[];
}

export interface CategoryColumnInfo {
  label: string;
  keyColumn: string;
  link: string;
  dataElementId: string;
  facetName: string | undefined;
  facetValue: string | undefined;
}

interface Props {
  menu: SecondLevelMenu;
  nameCategory?: string;
  considerBrandsAsAdult?: boolean;
}

export default function useMenuColumnsInfo({
  menu,
  nameCategory = "",
  considerBrandsAsAdult = false,
}: Props): MenuDivInfo[] {
  const { identifier, uniqueID, completeUrl, completeUrlAdults, completeUrlKids } = menu;
  const canRender = useCanRender();
  const thirdLevelMenu = useSelector(selectThirdLevelMenu);
  const completeId = getCompleteId(uniqueID, identifier);
  const facets: FacetView[] | undefined = thirdLevelMenu[completeId];
  const brandGroups = useSelector(selectBrandGroups);
  const brandLabels = useSelector(selectBrandLabels);
  const isMultidoor = useSelector(selectIsMultidoor);

  const [menuContent, setMenuContent] = useState<MenuDivInfo[]>([]);

  useEffect(() => {
    const structure = menuStructure?.[identifier.toUpperCase() as MenuStructureKeys];
    const columns: MenuDivInfo[] = [];

    const brandsStars: FacetViewEntry[] = [];
    const brands: FacetViewEntry[] = [];

    let hasStars = false;

    facets?.forEach((_) => {
      if (_.value === "manufacturer.raw") {
        _.entry?.forEach((e) => {
          if (e.isStars) {
            hasStars = true;

            if (considerBrandsAsAdult) {
              //if considerBrandsAsAdult=true --> consider every brand as not stars
              brands.push(e);
            } else {
              brandsStars.push(e);
            }
          } else {
            brands.push(e);
          }
        });
      }
    });

    //filter out not accessible column because of privileges
    const structureFiltered = structure?.filter(
      (column) => !(column.privilege && !canRender(column.privilege))
    );

    structureFiltered?.forEach((column) => {
      switch (column.columnName) {
        case "Brands Stars":
          if (hasStars) {
            const brandColumn = getBrandColumnInfo(column, brandsStars, false, true, hasStars);
            if (brandColumn) columns.push(brandColumn);
          }

          break;
        case "Brands Stars Kids":
          if (hasStars) {
            const brandColumnKids = getBrandColumnInfo(column, brandsStars, true, true, hasStars);
            if (brandColumnKids) columns.push(brandColumnKids);
          }
          break;
        case "Brands":
          const brandColumn = getBrandColumnInfo(column, brands, false, false, hasStars);
          if (brandColumn) columns.push(brandColumn);
          break;
        case "Brands Kids":
          //ignore kids column if considerBrandsAsAdult=true
          if (!considerBrandsAsAdult) {
            const brandColumnKids = getBrandColumnInfo(column, brands, true, false, hasStars);
            if (brandColumnKids) columns.push(brandColumnKids);
          }
          break;
        default:
          if (!hasStars) {
            const menuColumn = getMenuColumnInfo(column);
            if (menuColumn) columns.push(menuColumn);
          }
      }
    });

    //HANDLE COLUMN CONTENT
    for (const columnIndex in columns) {
      const column = columns[columnIndex];
      column.brandColumns = [];
      column.categoryColumns = [];

      if (column.isBrandColumn) {
        //HANDLE BRAND COLUMN CONTENT
        const brandSubGroup = groupBrandGroupByType(
          brandGroups,
          brandLabels,
          column.facetsBrand,
          column.baseUrl,
          completeUrl || column.baseUrl,
          column.columnStructure.facet?.facetName,
          considerBrandsAsAdult
        );

        //get kids or adult content
        const type = column.isKids ? "kids" : "adults";

        if (brandSubGroup[type]?.length > 0) {
          column.brandColumns = brandSubGroup[type];
        } else {
          //delete column because empty
          delete columns[columnIndex];
        }
      } else {
        //HANDLE CATEGORY COLUMN CONTENT
        const { columnStructure, baseUrl } = column;

        const categoryItem = getAnalyticsIdForCategory(columnStructure.label);

        /*** PRINT ROWS ***/
        const menuContent: CategoryColumnInfo[] = [];

        /* IF ALL FACET ENTRIES SHOULD BE PRINT IN THIS COLUMN, PRINT ALL */
        // an exception should be made for brand columns (though they are not currently set as "viewAllFacet" in menu structure)
        if (
          columnStructure.viewAllFacet &&
          columnStructure.facet?.facetName &&
          !isBrandFilter(columnStructure.facet.facetName)
        ) {
          // look for the facet specified for the whole column among the data from the API
          const currentFacet = facets?.find(
            (facet) => facet.extendedData?.propertyvalue === columnStructure.facet?.facetName
          );

          if (currentFacet)
            menuContent.push(
              ...printAllFacetsEntry(
                currentFacet,
                currentFacet.extendedData?.propertyvalue,
                baseUrl,
                categoryItem
              )
            );
        }

        /* PRINT INFO FOR EACH FACET IN COLUMN STRUCTURE CONTENT */
        columnStructure.content.forEach((columnFacet) => {
          /* INDEX TO FORMAT COLUMN, THE 7TH ROW SHOULD BE EMPTY TO FIT IN GRID */

          facets?.forEach((facet) => {
            const facetId = facet.extendedData?.propertyvalue;

            /* DONT PRINT FACET OF THE COLUMN IS PRESENT */
            // check if either there's no specified facet for the whole column
            // or there is but it's for Googles and Helmets

            if (isCategoryOrGooglesHelmetsColumn(columnStructure, facets)) {
              if (
                facetId &&
                !isBrandFilter(facetId) &&
                facetId.toUpperCase() === columnFacet.facetName.toUpperCase()
              ) {
                /** PRINT COLUMN ROW **/

                /* IF IS viewAllFacetEntries, PRINT ALL ENTRIES */
                if (columnFacet.viewAllFacetEntries) {
                  menuContent.push(...printAllFacetsEntry(facet, facetId, baseUrl, categoryItem));
                } else {
                  /* IF IS FACET ENTRY, LOOP THROUGH ENTRIES TO PRINT IT */
                  if (columnFacet.facetEntry) {
                    columnFacet.facetEntry.forEach((columnEntry) => {
                      const entryIndex = facet.entry.findIndex(
                        (_) => _.identifier.toUpperCase() === columnEntry.toUpperCase()
                      );

                      if (entryIndex > -1) {
                        const entry = facet.entry[entryIndex];
                        const filters = [{ key: facetId, value: entry?.identifier }];
                        /* ADD FILTERS */
                        if (columnStructure.facet && columnStructure.facet?.value) {
                          filters.push({
                            key: columnStructure.facet?.facetName,
                            value: columnStructure.facet?.value,
                          });
                        }

                        if (columnFacet.facetName && columnFacet.facetName === "GENDER") {
                          const facetUnisex = facet.entry.find((_) => _.identifier === "Unisex");
                          if (facetUnisex && facetId)
                            filters.push({
                              key: facetId,
                              value: facetUnisex.identifier,
                            });
                        }

                        //nuance audio
                        const entryIdentifier =
                          identifier === "AUDIO"
                            ? entry.identifier.replaceAll(" ", "_")
                            : entry.identifier;
                        const underscoreNameCategory = identifier === "AUDIO" ? "" : "_";

                        menuContent.push({
                          label: capitalizeFirstLetter(entry.label),
                          keyColumn: facetId + entry?.label + identifier,
                          link: composeUrlWithParams(baseUrl, filters),
                          facetName: facetId,
                          facetValue: entry.identifier,
                          dataElementId: `MainNav_Products_${nameCategory}${underscoreNameCategory}${columnStructure.label.toLowerCase()}_${entryIdentifier}`,
                        });
                      }
                    });
                  } else {
                    /* IF IS FACET, PRINT SIMPLY THE FACET */
                    // this includes filters like "Bestseller", "New Arrivals", "Polarized"
                    // for which only specific entries of a facet are printed

                    if (isCategoryOrGooglesHelmetsRow(columnStructure, facet)) {
                      const currentFacet = facet.entry.find(
                        (_) => _.identifier === columnFacet.value
                      );

                      let filters = [{ key: facetId, value: facet.entry?.[0]?.identifier }];

                      if (columnFacet.value && facet.entry) {
                        filters = facet.entry
                          .filter((_) => _.identifier === columnFacet.value)
                          .map((_) => {
                            return { key: facetId, value: _.identifier };
                          });
                      }

                      /* ADD FILTERS */
                      if (columnStructure.facet && columnStructure.facet?.value) {
                        filters.push({
                          key: columnStructure.facet?.facetName,
                          value: columnStructure.facet?.value,
                        });
                      }

                      //Management for RECOMMENDATIONS and other similar PLPs
                      let entryUrl = "";
                      if (columnFacet.baseUrl) {
                        entryUrl = columnFacet.baseUrl;
                        const params = baseUrl.split("?")[1]; //Take params from generic baseUrl and put them on the specific URL
                        entryUrl += "?" + params;
                      } else {
                        entryUrl = composeUrlWithParams(baseUrl, filters);
                      }

                      const minCountCheck =
                        columnFacet.minCount && +facet.entry[0].count >= columnFacet.minCount;

                      if (minCountCheck || !columnFacet.minCount) {
                        if (
                          columnFacet.facetName !== "RECOMMENDED" ||
                          (columnFacet.facetName === "RECOMMENDED" &&
                            !isMultidoor &&
                            !canRender("HIDE_RECOMMENDATION")) //Recommended is not visibile in multidoor
                        ) {
                          menuContent.push({
                            label: capitalizeFirstLetter(facet.name),
                            keyColumn: facetId + facet.entry?.[0]?.label + identifier,
                            link: entryUrl,
                            facetName: facetId,
                            facetValue: currentFacet?.identifier,
                            dataElementId: `MainNav_Products_${identifier}_${columnStructure.label}_${facet.extendedData.propertyvalue}`,
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          });
        });

        let orderedMenuContent = menuContent;
        if (identifier.includes("ACCESSORIES")) {
          orderedMenuContent = orderBy(menuContent, ["label"]);
        }

        if (
          orderedMenuContent.length > 0 ||
          (orderedMenuContent.length === 0 && identifier === "GOGGLES_HELMETS")
        ) {
          orderedMenuContent = orderedMenuContent.filter(
            (value, index, self) =>
              index ===
              self.findIndex((_) => _.facetName === value.facetName && _.label === value.label)
          );
          column.categoryColumns = orderedMenuContent;
        } else {
          //delete column because empty
          delete columns[columnIndex];
        }
      }
    }

    // console.log("useMenuColumnsInfo", columns);
    setMenuContent(columns);
  }, [facets, brandGroups, brandLabels]);

  const getBrandColumnInfo = (
    column: MenuColumnInterface,
    brands: FacetViewEntry[],
    isKids = false,
    isStars = false,
    hasStars = false
  ) => {
    let title = "MENU_BRAND";
    let subtitle;
    if (isKids) {
      title = "MENU_BRAND_KIDS";
    }
    if (!isKids && !isStars && hasStars) {
      title = "MENU_BRAND_OTHERS";
    }
    if (!isKids && isStars) {
      title = "MENU_BRAND_STARS";
    }
    if (identifier === "GOGGLES_HELMETS" && hasStars) {
      subtitle = column.label;
    }

    let key = title;
    if (isKids && isStars) {
      key += "_STARS";
    }
    let newBaseUrl = completeUrl;

    if (isKids && completeUrlKids) {
      newBaseUrl = completeUrlKids;
    }

    if (!isKids && completeUrlAdults) {
      newBaseUrl = completeUrlAdults;
    }

    if (isStars) {
      newBaseUrl = newBaseUrl.replace("plp", "plp-stars");
    }

    if (brands.length > 0) {
      return {
        key: key,
        isStars: isStars,
        keyColumn: column.columnName + identifier,
        facetsBrand: brands,
        facetsCategory: [],
        baseUrl: newBaseUrl,
        isKids: isKids,
        identifier: identifier,
        title: title,
        subtitle: subtitle,
        showStarIcon: !isKids && isStars,
        columnStructure: column,
        isBrandColumn: true,
        brandColumns: [],
        categoryColumns: [],
      };
    }
  };

  const getMenuColumnInfo = (column: MenuColumnInterface) => {
    let nameCategoryTemp = undefined;

    if (column.facet) {
      /*------ 
        IF COLUMN SHOULD BE ONLY OF A CERTAIN FACET, 
        CHECK IF THAT FACET IS PRESENT,
        ELSE HIDE THE ENTIRE COLUMN 
        ----- */
      const index = facets?.findIndex((_) => {
        if (_.extendedData.propertyvalue === column.facet?.facetName) {
          if (column.facet?.value) {
            const index = _.entry.findIndex((e) => e.identifier === column.facet?.value);
            if (index > -1) return true;
          } else {
            return true;
          }
        }
        return false;
      });
      if (index > -1) {
        nameCategoryTemp = nameCategory;
      } else {
        //do not print this content
        return null;
      }
    }

    return {
      key: "CATEGORIES",
      isStars: false,
      keyColumn: column.columnName + identifier,
      facetsCategory: facets,
      baseUrl: completeUrl,
      title: column.label,
      columnStructure: column,
      identifier: identifier,
      nameCategory: nameCategoryTemp,
      isBrandColumn: false,
    } as MenuDivInfo;
  };

  const printAllFacetsEntry = (
    facet: FacetView,
    facetId: string,
    baseUrl: string,
    categoryItem: string
  ): CategoryColumnInfo[] => {
    const content: CategoryColumnInfo[] = [];

    facet.entry?.map((entry) => {
      content.push({
        label: capitalizeFirstLetter(entry.label),
        keyColumn: facetId + entry?.label + identifier,
        link: composeUrlWithParams(baseUrl, [{ key: facetId, value: entry?.identifier }]),
        facetName: facetId,
        facetValue: entry.identifier,
        dataElementId: `MainNav_Products_${nameCategory}_${categoryItem}_${entry.identifier}`,
      });
    });

    return content;
  };

  return menuContent;
}

const getAnalyticsIdForCategory = (label: string) => {
  const categoryItem = label
    .replace("MENU_", "")
    .toLowerCase()
    .replace(/_./g, (m) => m[1].toUpperCase());
  return categoryItem.charAt(0).toUpperCase() + categoryItem.slice(1);
};

/**
 * Check if either there's no specified facet for the whole column,
 * or there is but it's for Googles and Helmets
 *
 * This check is mostly redundant where used currently, as other kind of rows whose columns have a facet specified are
 *  -   brand columns -> already handled beforehand
 *   -  columns with viewAllFacet = true -> already handled beforehand
 *
 * @param {MenuColumnInterface} columnStructure
 * @param {FacetView} facet
 * @return {*}
 */
const isCategoryOrGooglesHelmetsRow = (columnStructure: MenuColumnInterface, facet: FacetView) => {
  return (
    !columnStructure?.facet?.value ||
    facet.type?.includes(columnStructure.facet.value as GogglesHelmetsType)
  );
};

/**
 * Check if either there's no specified facet for the whole column,
 * or there is but it's for Googles and Helmets
 *
 * This check is mostly redundant where used currently, as other kind of columns with a facet specified are
 *  -   brand columns -> already handled beforehand
 *   -  columns with viewAllFacet = true -> already handled beforehand
 *
 * @param {MenuColumnInterface} columnStructure
 * @param {FacetView[]} facets
 * @return {*}
 */
const isCategoryOrGooglesHelmetsColumn = (
  columnStructure: MenuColumnInterface,
  facets: FacetView[]
) => {
  return (
    !columnStructure.facet?.facetName ||
    !!facets?.some((_) => _.extendedData?.propertyvalue === columnStructure.facet?.facetName)
  );
};
