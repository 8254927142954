import { BFFApiDomain } from "../serviceUtils";

export const brandGroupsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/brandgroups/";
export const brandsUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/brandgroups/{brandGroupsValue}/brands/";

export const collectionUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/search/byFacets/*/";

export const jobTypeListUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/brandgroups/brands/{brand}/jobs/";

export const partnumbersUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/brandgroups/brands/{manufacturer}/partnumbers/";

export const colorsUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/brandgroups/brands/partnumbers/{partNumber}/";

export const upcUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/brandgroups/brands/{brand}/upc/{term}/";

export const sizeUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/brandgroups/brands/partnumbers/ids/{catentryId}/";

export const getFocalTypeUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/brands/{brand}/focalType";

export const checkFrameUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/checkFrame/";

export const getLensesUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/partnumber/{partnumber}/lenses";

export const madeInUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/brands/{brand}/madeInAndItems";

export const makeabilityUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/makeability/check";

export const getPricesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/rxo/prices/";

export const getCartItemUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/order/items/{orderItemsId}";
