import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components/macro";

import Modal from "../../../styled-UI/Modal";
import LoaderElement from "../../../styled-UI/loader/LoaderElement";

import VariantsHeader from "./LpVariantsPopupHeader";
import LpVariantsPopupBody, { VariantsListContainer } from "./LpVariantsPopupBody";
import VariantsFooter from "./LpVariantsPopupFooter";

import { useBreakpoint } from "../../../../assets/theme/breakpoint";
import useTranslation from "../../../../hooks/useTranslation";

import {
  saveLpVariantsList,
  selectLpVariantsList,
  selectLpVariantsListStatus,
} from "../../../../store/store/storeSlice";
import { getLPVariantsList } from "../../../../store/catalogue/catalogueSaga";

import { v4 as uuidv4 } from "uuid";

interface Props {
  productCode: string;
  isOpen: boolean;
  close: () => void;
}

const LpVariantsPopup = ({ productCode, isOpen, close }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const { translateLabel } = useTranslation();

  const lpVariants = useSelector(selectLpVariantsList);
  const lpVariantsListStatus = useSelector(selectLpVariantsListStatus);

  const [modalTop, setModalTop] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const breakpoints = useBreakpoint();

  const variantsPerPage = breakpoints["md"] ? 3 : 4;

  useEffect(() => {
    const top = window.scrollY;
    if (lpVariants) setModalTop(top + 25);
  }, [lpVariants]);

  useEffect(() => {
    dispatch(
      getLPVariantsList({
        partNumber: productCode,
        pageNumber: currentPage,
        pageSize: variantsPerPage,
      })
    );
  }, [currentPage]);

  useEffect(() => {
    return () => {
      dispatch(saveLpVariantsList(null));
    };
  }, []);

  const printLoader = () => {
    const loaders = [];
    for (let i = 0; i < variantsPerPage; i++) {
      loaders.push(<LoaderElement width={288} height={360} key={i} />);
    }
    return loaders;
  };

  const renderLpVariantsPopupContent = () => {
    if (lpVariantsListStatus === "SUCCESS" && lpVariants?.items && lpVariants?.items?.length > 0)
      return <LpVariantsPopupBody variants={lpVariants.items} variantsPerPage={variantsPerPage} />;

    if (lpVariantsListStatus === "LOADING")
      return (
        <VariantsListContainer variantsPerPage={variantsPerPage}>
          {printLoader()}
        </VariantsListContainer>
      );
    if (lpVariantsListStatus === "ERROR")
      return (
        <NoResultsContainer>
          <p>{translateLabel("VARIANTS_NO_RESULTS")}</p>
        </NoResultsContainer>
      );
  };

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      isCentered={false}
      top={modalTop}
      customStyle={variantsPerPage === 3 ? "expanded-tile-modal-sm" : "expanded-tile-modal"}
      data-element-id="LandingPage_Variants"
    >
      <VariantsHeader key={uuidv4()} totalVariants={lpVariants?.recordSetTotal ?? 0} />
      {renderLpVariantsPopupContent()}
      <VariantsFooter
        totalVariants={lpVariants?.recordSetTotal ?? 0}
        variantsPerPage={variantsPerPage}
        currentPage={currentPage}
        updateCurrentPage={(page: number) => setCurrentPage(page)}
      />
    </Modal>
  );
};

const NoResultsContainer = styled.div`
  height: 39.75rem;
  padding: 3rem;
  text-align: center;
`;

export default LpVariantsPopup;
