import { AxiosResponse } from "axios";
import { createAndExecuteService } from "../serviceUtils";
import {
  GetShipmentSupportSRPayload,
  getSimpleSRPayload,
  getStartSRPayload,
  MarkAsReadSRPayload,
  ReplySRPayload,
  SearchSRPayload,
  SubmitAddressSRPayload,
  SubmitBrandSRPayload,
  SubmitOrderSupportSRPayload,
  SubmitShipmentSupportSRPayload,
  SubmitSimpleSRPayload,
  UploadFilePayload,
  UrgeSRPayload,
} from "./serviceRequestInterfaces";
import {
  getStartSRUrl,
  getSimpleSRUrl,
  submitSimpleSRUrl,
  submitBrandSRUrl,
  getMenuKeysSRUrl,
  uploadFileUrl,
  submitAddressSRUrl,
  submitOrderSupportSRUrl,
  submitShipmentSupportSRUrl,
  getShipmentSupportSRUrl,
  urgeSRUrl,
  searchSRUrl,
  replySRUrl,
  markAsReadSRUrl,
  getBrandsSRUrl,
} from "./serviceRequestUrls";

export default {
  getStartSR: async (payload: getStartSRPayload): Promise<AxiosResponse> => {
    const { doorId, ...params } = payload;
    const qparams = new URLSearchParams({ doorId: doorId });

    return createAndExecuteService(getStartSRUrl, "POST", qparams, params);
  },
  getSimpleSR: async (payload: getSimpleSRPayload): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ doorId: payload.doorId });

    const params = {
      sitePageIdentifier: payload.sitePageIdentifier,
    };

    return createAndExecuteService(getSimpleSRUrl, "POST", qparams, params);
  },
  uploadFile: async (payload: UploadFilePayload): Promise<AxiosResponse> => {
    const { jwt, id, ...rest } = payload;

    const formData = new FormData();
    (Object.keys(rest) as Array<keyof typeof rest>).forEach((key) => {
      if (key && rest[key]) formData.append(key, rest[key] ?? "");
    });

    return createAndExecuteService(
      uploadFileUrl,
      "POST",
      null,
      formData,
      undefined,
      {
        "Content-Type": "multipart/form-data",
      },
      jwt
    );
  },
  submitSimpleSR: async (payload: SubmitSimpleSRPayload): Promise<AxiosResponse> => {
    const { doorId, ...params } = payload;
    const qparams = new URLSearchParams({ doorId });

    return createAndExecuteService(submitSimpleSRUrl, "POST", qparams, params);
  },
  getBrandsSR: async (doorId: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ doorId });

    return createAndExecuteService(getBrandsSRUrl, "GET", qparams);
  },
  submitBrandSR: async (payload: SubmitBrandSRPayload): Promise<AxiosResponse> => {
    const { doorId, ...params } = payload;
    const qparams = new URLSearchParams({ doorId });

    return createAndExecuteService(submitBrandSRUrl, "POST", qparams, params);
  },
  submitAddressSR: async (payload: SubmitAddressSRPayload): Promise<AxiosResponse> => {
    const { doorId, ...params } = payload;
    const qparams = new URLSearchParams({ doorId });

    return createAndExecuteService(submitAddressSRUrl, "POST", qparams, params);
  },
  submitOrderSupportSR: async (payload: SubmitOrderSupportSRPayload): Promise<AxiosResponse> => {
    const { doorId, ...params } = payload;
    const qparams = new URLSearchParams({ doorId });

    return createAndExecuteService(submitOrderSupportSRUrl, "POST", qparams, params);
  },
  getShipmentSupportSR: async (payload: GetShipmentSupportSRPayload): Promise<AxiosResponse> => {
    const { doorId, ...params } = payload;
    const qparams = new URLSearchParams({ doorId });

    return createAndExecuteService(getShipmentSupportSRUrl, "POST", qparams, params);
  },
  submitShipmentSupportSR: async (
    payload: SubmitShipmentSupportSRPayload
  ): Promise<AxiosResponse> => {
    const { doorId, ...params } = payload;
    const qparams = new URLSearchParams({ doorId });

    return createAndExecuteService(submitShipmentSupportSRUrl, "POST", qparams, params);
  },
  getMenuKeysSR: async (doorId: string): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams({ doorId: doorId });

    return createAndExecuteService(getMenuKeysSRUrl, "POST", qparams, {});
  },
  urgeSR: async (payload: UrgeSRPayload): Promise<AxiosResponse> => {
    const { doorId, ...params } = payload;
    const qparams = new URLSearchParams({ doorId });

    return createAndExecuteService(urgeSRUrl, "POST", qparams, params);
  },
  searchSR: async (payload: SearchSRPayload): Promise<AxiosResponse> => {
    const { doorId, ...params } = payload;
    const qparams = new URLSearchParams({ doorId });

    return createAndExecuteService(searchSRUrl, "POST", qparams, params);
  },
  replySR: async (payload: ReplySRPayload): Promise<AxiosResponse> => {
    const { doorId, ...params } = payload;
    const qparams = new URLSearchParams({ doorId });

    return createAndExecuteService(replySRUrl, "POST", qparams, params);
  },
  markAsReadSR: async (payload: MarkAsReadSRPayload): Promise<AxiosResponse> => {
    const { doorId, ...params } = payload;
    const qparams = new URLSearchParams({ doorId });
    return createAndExecuteService(markAsReadSRUrl, "POST", qparams, params);
  },
};
