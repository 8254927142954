import { PendingSubuserState } from "../components/pages/approve-subuser/pending-list/usePendingSubusersState";
import { ApprovedRequestPayload, CarouselData } from "../store/leonardo/leonardoInterface";

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// COURSES /////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

// export const getFallbackImage = (): string => {
//   const images = [
//     "https://media.leonardo.essilorluxottica.com/images/Course_Images/cover_01.jpg",
//     "https://media.leonardo.essilorluxottica.com/images/Course_Images/cover_02.jpg",
//     "https://media.leonardo.essilorluxottica.com/images/Course_Images/cover_03.jpg",
//     " https://media.leonardo.essilorluxottica.com/images/Course_Images/cover_04.jpg",
//   ];

//   const random = Math.floor(Math.random() * images.length - 1);

//   return images[random];
// };

export const truncateLabel = (label?: string, maxChars?: number, showEllipsis = true): string => {
  if (!label || label.length < 1 || !maxChars || maxChars < 1) {
    return "";
  }

  if (label.length <= maxChars) {
    return label;
  } else {
    if (showEllipsis) {
      return label.slice(0, maxChars - 1) + "...";
    } else {
      return label.slice(0, maxChars);
    }
  }
};

export const mapLeonardoCourses = (data: any): CarouselData => {
  return {
    id: data?.id,
    name: data?.name,
    type: data?.type,
    collectionTitle: data?.collectionTitle,
    collectionSubTitle: data?.collectionSubTitle,
    collectionMaxElementNumber: data?.collectionMaxElementNumber,
    layoutVariant: data?.layoutVariant,
    items: data?.items.map((course: any) => {
      return {
        title: course.text2 ?? "",
        courseLink: course.text1 ?? "",
        courseImage: course?.icon1[0]?.data?.uri ?? "",
      };
    }),
    pictures: data?.pictures,
    subjectTaxonomy: data?.subjectTaxonomy,
    creationDate: data?.creationDate,
    modificationDate: data?.modificationDate,
    title: data?.id,
  };
};

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// SUBUSER APPROVAL ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const getApprovalPayload = (data: PendingSubuserState): ApprovedRequestPayload => {
  const leoOnlyHard = data.subuserType === "LeoSubusers";

  const privileges = !leoOnlyHard
    ? {
        mylCatalogue: data.privilege === "mylCatalogue" || data.privilege === "mylCart",
        mylCart: data.privilege === "mylCart",
      }
    : {
        mylCatalogue: false,
        mylCart: false,
      };

  return { requestId: data.requestId, leoOnlyHard, ...privileges, phone: data.phone };
};
