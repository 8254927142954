import {
  PaymentMethod,
  PaymentMethodSubscriptionType,
} from "../../../../../store/payment/paymentInterface";
import {
  instanceOfPaymentMethodInfoCC,
  instanceOfPaymentMethodInfoACH,
} from "../../../../../utils/paymentUtils";
import { PaymentMethodTableField } from "./PaymentMethodRadioButtonTable";

export const methodLabelConfig: {
  [key in PaymentMethodSubscriptionType]: { addButtonLabel: string; chooseButtonLabel: string };
} = {
  AchAccount: {
    addButtonLabel: "PAYMENT_ADD_A_NEW_BANK_ACCOUNT",
    chooseButtonLabel: "PAYMENT_CHOOSE_BANK_ACCOUNT",
  },
  CreditCard: {
    addButtonLabel: "PAYMENT_ADD_A_NEW_CREDIT_CARD",
    chooseButtonLabel: "PAYMENT_CHOOSE_CREDIT_CARD",
  },
};

export const labelSubmitButton: {
  [key in PaymentMethodSubscriptionType]: {
    edit: string;
    "add-payment-method": string;
    "add-payment-management": string;
  };
} = {
  AchAccount: {
    edit: "PAYMENT_CONFIRM",
    "add-payment-method": "PAYMENT_ADD_BANK_ACCOUNT",
    "add-payment-management": "PAYMENT_ADD_AND_SELECT_BANK_ACCOUNT",
  },
  CreditCard: {
    edit: "PAYMENT_CONFIRM",
    "add-payment-method": "PAYMENT_ADD_CARD",
    "add-payment-management": "PAYMENT_ADD_AND_SELECT_CARD",
  },
};

export const CCtableEditable: PaymentMethodTableField<PaymentMethod>[] = [
  {
    fieldIdentifier: "radio-button-icon",
    fieldLabel: "PAYMENT_METHOD_PREFERRED",
    getValue: () => undefined,
  },
  {
    fieldIdentifier: "cardIcon",
    fieldLabel: "",
    getValue: (data?: PaymentMethod) =>
      instanceOfPaymentMethodInfoCC(data?.paymentMethodInfo)
        ? data?.paymentMethodInfo.cardType
        : undefined,
  },
  {
    fieldIdentifier: "cardNumber",
    fieldLabel: "PAYMENT_METHOD_CARD_NUMBER",
    getValue: (data?: PaymentMethod) =>
      instanceOfPaymentMethodInfoCC(data?.paymentMethodInfo)
        ? data?.paymentMethodInfo.cardNumber
        : undefined,
  },
  {
    fieldIdentifier: "description",
    fieldLabel: "PAYMENT_METHOD_DESCRIPTION_CC",
    getValue: (data?: PaymentMethod) => data?.description,
  },
  {
    fieldIdentifier: "expirationDate",
    fieldLabel: "PAYMENT_EXPIRATION_DATE",
    getValue: (data?: PaymentMethod) =>
      instanceOfPaymentMethodInfoCC(data?.paymentMethodInfo)
        ? data?.paymentMethodInfo.expirationDate
        : undefined,
  },
  {
    fieldIdentifier: "edit",
    fieldLabel: "",
    getValue: () => undefined,
  },
  {
    fieldIdentifier: "delete",
    fieldLabel: "",
    getValue: () => undefined,
  },
];

export const CCtableNonEditable: PaymentMethodTableField<PaymentMethod>[] = [
  {
    fieldIdentifier: "radio-button-icon",
    fieldLabel: "PAYMENT_METHOD_PREFERRED",
    getValue: () => undefined,
  },
  {
    fieldIdentifier: "cardIcon",
    fieldLabel: "",
    getValue: (data?: PaymentMethod) =>
      instanceOfPaymentMethodInfoCC(data?.paymentMethodInfo)
        ? data?.paymentMethodInfo.cardType
        : undefined,
  },
  {
    fieldIdentifier: "cardNumber",
    fieldLabel: "PAYMENT_METHOD_CARD_NUMBER",
    getValue: (data?: PaymentMethod) =>
      instanceOfPaymentMethodInfoCC(data?.paymentMethodInfo)
        ? data?.paymentMethodInfo.cardNumber
        : undefined,
  },
  {
    fieldIdentifier: "description",
    fieldLabel: "PAYMENT_METHOD_DESCRIPTION_CC",
    getValue: (data?: PaymentMethod) => data?.description,
  },
  {
    fieldIdentifier: "expirationDate",
    fieldLabel: "PAYMENT_EXPIRATION_DATE",
    getValue: (data?: PaymentMethod) =>
      instanceOfPaymentMethodInfoCC(data?.paymentMethodInfo)
        ? data?.paymentMethodInfo.expirationDate
        : undefined,
  },
];

const accountTableEditable: PaymentMethodTableField<PaymentMethod>[] = [
  {
    fieldIdentifier: "radio-button-icon",
    fieldLabel: "PAYMENT_METHOD_PREFERRED",
    getValue: () => undefined,
  },
  {
    fieldIdentifier: "description",
    fieldLabel: "PAYMENT_METHOD_DESCRIPTION_ACH",
    getValue: (data?: PaymentMethod) => data?.description,
  },
  {
    fieldIdentifier: "accountNumber",
    fieldLabel: "PAYMENT_METHOD_ACCOUNT_NUMBER_SHORT",
    getValue: (data?: PaymentMethod) =>
      instanceOfPaymentMethodInfoACH(data?.paymentMethodInfo)
        ? data?.paymentMethodInfo.accountNumber
        : undefined,
  },
  {
    fieldIdentifier: "transitRoutingNumber",
    fieldLabel: "PAYMENT_METHOD_TRANSIT_ROUTING_NUMBER_SHORT",
    getValue: (data?: PaymentMethod) =>
      instanceOfPaymentMethodInfoACH(data?.paymentMethodInfo)
        ? data?.paymentMethodInfo.transitRoutingNumber
        : undefined,
  },
  {
    fieldIdentifier: "edit",
    fieldLabel: "",
    getValue: () => undefined,
  },
  {
    fieldIdentifier: "delete",
    fieldLabel: "",
    getValue: () => undefined,
  },
];

const accountTableNonEditable: PaymentMethodTableField<PaymentMethod>[] = [
  {
    fieldIdentifier: "radio-button-icon",
    fieldLabel: "PAYMENT_METHOD_PREFERRED",
    getValue: () => undefined,
  },
  {
    fieldIdentifier: "description",
    fieldLabel: "PAYMENT_METHOD_DESCRIPTION_ACH",
    getValue: (data?: PaymentMethod) => data?.description,
  },
  {
    fieldIdentifier: "accountNumber",
    fieldLabel: "PAYMENT_METHOD_ACCOUNT_NUMBER_SHORT",
    getValue: (data?: PaymentMethod) =>
      instanceOfPaymentMethodInfoACH(data?.paymentMethodInfo)
        ? data?.paymentMethodInfo.accountNumber
        : undefined,
  },
  {
    fieldIdentifier: "transitRoutingNumber",
    fieldLabel: "PAYMENT_METHOD_TRANSIT_ROUTING_NUMBER_SHORT",
    getValue: (data?: PaymentMethod) =>
      instanceOfPaymentMethodInfoACH(data?.paymentMethodInfo)
        ? data?.paymentMethodInfo.transitRoutingNumber
        : undefined,
  },
];

export const paymentTabConfig: {
  [key in PaymentMethodSubscriptionType]: {
    empty: string;
    add: string;
    title: string;
    tableEditable: PaymentMethodTableField<PaymentMethod>[];
    tableNonEditable: PaymentMethodTableField<PaymentMethod>[];
    gridEditable: string;
    gridNonEditable: string;
  };
} = {
  AchAccount: {
    empty: "PAYMENT_NO_BANK_ACCOUNT",
    add: "PAYMENT_ADD_A_NEW_BANK_ACCOUNT",
    title: "PAYMENT_CHOOSE_A_BANK_ACCOUNT",
    tableEditable: accountTableEditable,
    tableNonEditable: accountTableNonEditable,
    gridEditable: ".5fr 1fr 1fr .8fr .2fr .2fr",
    gridNonEditable: ".3fr 1fr .8fr 1fr",
  },
  CreditCard: {
    empty: "PAYMENT_NO_CREDIT_CARD",
    add: "PAYMENT_ADD_A_NEW_CREDIT_CARD",
    title: "PAYMENT_CHOOSE_A_CREDIT_CARD",
    tableEditable: CCtableEditable,
    tableNonEditable: CCtableNonEditable,
    gridEditable: ".5fr .2fr 1fr 1fr .8fr .2fr .2fr",
    gridNonEditable: ".3fr 0.2fr 1fr 1fr 1fr",
  },
};
