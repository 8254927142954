import React, { useState } from "react";
import useTranslation from "../../../hooks/useTranslation";
import Popup from "../../styled-UI/Popup";
import styled from "styled-components";
import CustomText from "../../styled-UI/CustomText";
import RadioButton from "../../styled-UI/RadioButton";
import Button from "../../styled-UI/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateSrpOptInChoice } from "../../../store/user/userSagas";
import { selectSrpOptInChoiceLoading } from "../../../store/user/userSlice";

interface Props {
  isOpen: boolean;
  close: () => void;
}

const SRPOptInPopup = ({ isOpen, close }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const title = translateLabel("SRP_OPT_IN_POPUP_TITLE");
  const [acceptSRP, setAcceptSRP] = useState("");
  const [isError, setIsError] = useState<boolean>(false); //THE USER HAS TO MAKE A CHOICE
  const srpChoiceLoading = useSelector(selectSrpOptInChoiceLoading);

  const handleSubmit = () => {
    if (acceptSRP != "" && !isError) {
      dispatch(updateSrpOptInChoice(acceptSRP));
      close();
    } else {
      setIsError(true);
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      close={close}
      title={title}
      enableOverflowAuto
      disableCloseButton={true}
      hideCloseButton={true}
      zindex={500}
    >
      <Container>
        <LegalInfo>
          <CustomText as="p" font="font-bold" fontSizePx={14} color="primary" lineHeightPx={24}>
            {translateLabel("SRP_OPT_IN_LEGAL_INFO")}
          </CustomText>
        </LegalInfo>
        <ConfirmationSection>
          <CustomText as="p" font="font-bold" fontSizePx={14} color="primary" lineHeightPx={24}>
            {translateLabel("SRP_OPT_IN_CONFIRMATION_MESSAGE")}
          </CustomText>
          <RadioButtonsContainer isError={isError}>
            <RadioButton
              label={translateLabel("SRP_OPT_IN_DECLINE")}
              onChange={(e) => {
                setAcceptSRP("N");
                setIsError(false);
              }}
              checked={acceptSRP === "N"}
              value="yes"
              name="confirm"
              type="radio"
            />
            <RadioButton
              label={translateLabel("SRP_OPT_IN_ACCEPT")}
              onChange={(e) => {
                setAcceptSRP("Y");
                setIsError(false);
              }}
              checked={acceptSRP === "Y"}
              value="yes"
              name="confirm"
              type="radio"
            />
          </RadioButtonsContainer>
          {isError && (
            <CustomText as="span" font="font-regular" fontSizePx={14} color="red" lineHeightPx={24}>
              {translateLabel("SRP_OPT_IN_MANDATORY_FIELDS_ERROR")}
            </CustomText>
          )}
        </ConfirmationSection>
        <ButtonsContainer>
          <Button type="primary" onClick={handleSubmit} isLoading={srpChoiceLoading === "LOADING"}>
            {translateLabel("SRP_OPT_IN_CONFIRMATION_BUTTON")}
          </Button>
        </ButtonsContainer>
      </Container>
    </Popup>
  );
};

const Container = styled.div`
  width: 65.25rem;
  padding: 2rem;
`;

const LegalInfo = styled.div`
  padding-bottom: 1.75rem;
`;

const ConfirmationSection = styled.div`
  padding-bottom: 3.125rem;

  p {
    padding-bottom: 1.75rem;
  }
`;

const RadioButtonsContainer = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: row;
  padding-bottom: ${(props) => (props.isError ? "1rem" : "")};

  label {
    padding-right: 2rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export default SRPOptInPopup;
