import { BFFApiDomain } from "../serviceUtils";

export const getPublicPageLayoutUrl = BFFApiDomain + "/fo-bff/api/pub/v1/{locale}/pages/{pageName}";
export const getPublicCmsContentUrl =
  BFFApiDomain + "/fo-bff/api/pub/v1/{locale}/contents/{contentId}";
export const getPageLayoutUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/pages/{pageName}";
export const getCmsContentUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/contents/{contentId}";
export const getLandingPageUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/landing/contents/{id}";
export const getStoreConfigurationUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/store/{storeIdentifier}/configuration";
export const getPublicStoreConfigurationUrl = BFFApiDomain + "/fo-bff/api/pub/v1/languages";
export const getMenuUrl = BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/menu";
export const getThirdLevelMenuUrl =
  BFFApiDomain + `/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/menu/{categoryId}`;
export const getBrandListUrl =
  BFFApiDomain + `/fo-bff/api/priv/v1/{storeIdentifier}/brandgroup/brands`;
export const getLensListUrl =
  BFFApiDomain + `/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/menu/lenses`;
export const getInstrumentsListUrl =
  BFFApiDomain + `/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/menu/instruments`;
export const getFaqUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/contents/faqs";
export const getNewsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/contents/news";
export const getTutorialPillsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/generic/contents";
export const getSingleNewsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/contents/news/{id}";
export const getCountriesUrl = BFFApiDomain + "/fo-bff/api/pub/v1/store";
export const getBrandImagesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/brandgroups/logo";
export const getUrlWithTokenEl360Url =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/sso/el360/token";
export const getUrlWithTokenRewardsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/sso/rewards/token";
export const getLensSimulatorRedirectUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/sso/lensSimulator";
export const getContactInfoUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/store/contacts";
export const redirectToEssilorProUrl = BFFApiDomain + "/fo-bff/api/priv/v1/pairing";
export const getEyemedUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/sso/eyemed";
export const getStarDoorsForBrandUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/multidoor/selected-stars-brands";
