import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import CustomText from "../styled-UI/CustomText";
import IconButton from "../styled-UI/IconButton";
import { ReactComponent as VolumeUp } from "../../assets/icons/volume_up.svg";
import { ReactComponent as VolumeOff } from "../../assets/icons/volume_off.svg";
import Button from "../styled-UI/Button";
import { LpHeroVideo } from "../../interfaces/cmsInterfaces";
import useDataHref from "../../hooks/useDataHref";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../store/store/storeInterfaces";
import { useHandleCmsLink } from "../../utils/cmsUtils";

interface Props {
  isDarkMode?: boolean;
  data: LpHeroVideo;
  customFont?: FontFamilies | null;
}

const LpHeroVideoFull = ({ data, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  const { title, description, linkLabel, linkString, multiMediaType, subjectTaxonomy } = data;
  const [isMuted, setIsMuted] = useState(true);
  const [isGradient, setIsGradient] = useState(false);
  const [isAudioShown, setIsAudioShown] = useState(false);
  const [hasAudio, setHasAudio] = useState(true);
  const { handleCmsLink } = useHandleCmsLink();

  const descrWithHref = useDataHref({ content: description });

  useEffect(() => {
    if (subjectTaxonomy) {
      subjectTaxonomy.forEach((_) => {
        if (_.externalReference === "overlay") {
          setIsGradient(true);
        }
        if (_.externalReference === "muted") {
          setHasAudio(false);
          setIsMuted(true);
        }
      });
    }
  }, [subjectTaxonomy]);

  return (
    <Container
      onMouseEnter={() => setIsAudioShown(true)}
      onMouseLeave={() => setIsAudioShown(false)}
    >
      {multiMediaType?.[0]?.data && (
        <video
          src={multiMediaType?.[0]?.data?.uri}
          // ref={refVideo}
          // onEnded={onEnded}
          autoPlay={true}
          loop={true}
          muted={isMuted}
          controls={false}
        />
      )}
      <GradientLayer isGradient={isGradient} />
      <InnerContent>
        <TextBox>
          <CustomText
            as="span"
            font={customFont ? "custom-light" : "font-light"}
            fontFamily={customFont}
            fontSizePx={80}
            lineHeightPx={82}
            textAlign="center"
            marginBottomPx={16}
            color={isDarkMode ? "primary" : "white"}
          >
            {title}
          </CustomText>

          <CustomText
            as="span"
            font={customFont ? "custom-medium" : "font-medium"}
            fontFamily={customFont}
            fontSizePx={24}
            textAlign="center"
            lineHeightPx={24}
            marginBottomPx={50}
            color={isDarkMode ? "primary" : "white"}
          >
            <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? description} />
          </CustomText>
          {linkLabel && linkString && (
            <CTAContainer>
              <Button
                type={isDarkMode ? "primary" : "primary-dark"}
                onClick={() => handleCmsLink(linkString)}
                customFont={customFont}
              >
                {linkLabel}
              </Button>
            </CTAContainer>
          )}
        </TextBox>
        {hasAudio && isAudioShown && (
          <ButtonContainer>
            <IconButton
              type="white-no-border"
              size="xl"
              icon={isMuted ? <VolumeOff /> : <VolumeUp />}
              onClick={() => setIsMuted(!isMuted)}
            />
          </ButtonContainer>
        )}
      </InnerContent>
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  video {
    width: 100%;
  }
`;
const InnerContent = styled.div`
  z-index: 200;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  top: 0;
  justify-content: center;
  align-items: center;
`;

const GradientLayer = styled.div<{ isGradient: boolean }>`
  z-index: 100;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;

  //IF SOLID OPACITY LAYER
  opacity: ${(props) => !props.isGradient && "0.2"};
  background-color: ${(props) => !props.isGradient && "#111"};

  //IF GRADIENT OPACITY LAYER
  background: ${(props) =>
    props.isGradient && "linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))"};
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
`;

const CTAContainer = styled.div`
  z-index: 300;
`;

const ButtonContainer = styled.div`
  z-index: 250;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  bottom: 3.75rem;
  right: 3.75rem;
`;

export default LpHeroVideoFull;
