import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import {
  MenuKeys,
  ServiceRequestPage,
  ShipmentSupportSRItem,
  SimpleSRItem,
} from "../../interfaces/serviceRequests";
import { RootState } from "../storeConfig";
import {
  ServiceRequestFilters,
  ServiceRequestState,
  SubmitAddressSRStatus,
  UploadedFileStatus,
} from "./serviceRequestInterfaces";

export const sliceName = "servicerequest";
const initialState: ServiceRequestState = {
  serviceRequestIdentifier: null,
  simpleSRList: [],
  shipmentSupportSRList: [],
  brandSRList: [],

  getServiceRequestIdentifierStatus: "IDLE",
  getSimpleSRListStatus: "IDLE",
  submitSRStatus: "IDLE",
  submitAddressSRStatus: { status: "IDLE", errorCode: null },
  submitOrderSupportSRStatus: "IDLE",
  getShipmentSupportSRStatus: "IDLE",
  submitShipmentSupportSRStatus: "IDLE",
  getBrandsSRStatus: "IDLE",

  /////////////////// UPLOAD
  uploadedFileStatus: [],

  /////////////////// SERVICE REQUEST PAGE

  menuKeys: null,
  serviceRequestPage: null,
  serviceRequestFilters: null,
  serviceRequestShowSuccessPopup: false,
  forceUpdateSRPage: false,
  forceUpdateCounterSR: false,

  //LOADERS
  urgeSRStatus: "IDLE",
  searchSRStatus: "IDLE",
  replySRStatus: "IDLE",
  getMenuKeysSRStatus: "IDLE",
};

export const serviceRequestSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveServiceRequestIdentifier: (state, action: PayloadAction<string | null>) => {
      state.serviceRequestIdentifier = action.payload;
    },
    saveSimpleSRList: (state, action: PayloadAction<SimpleSRItem[]>) => {
      state.simpleSRList = action.payload;
    },
    saveGetServiceRequestIdentifierStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getServiceRequestIdentifierStatus = action.payload;
    },
    saveGetSimpleSRListStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getSimpleSRListStatus = action.payload;
    },
    saveSubmitSRStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.submitSRStatus = action.payload;
    },
    saveSubmitAddressSRStatus: (state, action: PayloadAction<SubmitAddressSRStatus>) => {
      state.submitAddressSRStatus = action.payload;
    },
    saveSubmitOrderSupportSRStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.submitOrderSupportSRStatus = action.payload;
    },
    saveGetShipmentSupportSRStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getShipmentSupportSRStatus = action.payload;
    },
    saveSubmitShipmentSupportSRStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.submitShipmentSupportSRStatus = action.payload;
    },
    saveShipmentSupportSRList: (state, action: PayloadAction<ShipmentSupportSRItem[]>) => {
      state.shipmentSupportSRList = action.payload;
    },
    saveBrandSRList: (state, action: PayloadAction<string[]>) => {
      state.brandSRList = action.payload;
    },
    saveGetBrandsSRStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getBrandsSRStatus = action.payload;
    },
    resetPopup: (state) => {
      state.serviceRequestIdentifier = null;
      state.simpleSRList = [];
      state.shipmentSupportSRList = [];
      state.brandSRList = [];
      state.getServiceRequestIdentifierStatus = "IDLE";
      state.getSimpleSRListStatus = "IDLE";
      state.submitSRStatus = "IDLE";
      state.submitAddressSRStatus = { status: "IDLE", errorCode: null };
      state.submitOrderSupportSRStatus = "IDLE";
      state.getShipmentSupportSRStatus = "IDLE";
      state.submitShipmentSupportSRStatus = "IDLE";
      state.getBrandsSRStatus = "IDLE";
      state.uploadedFileStatus = [];
    },
    //////////////////////////////// UPLOAD
    saveUploadedFileStatus: (state, action: PayloadAction<UploadedFileStatus>) => {
      const status = action.payload;
      const index = state.uploadedFileStatus.findIndex((_) => _.id === status.id);
      if (index !== -1) {
        state.uploadedFileStatus[index] = status;
      } else {
        state.uploadedFileStatus.push(status);
      }
    },

    //////////////////////////////// SERVICE REQUEST PAGE
    saveMenuKeys: (state, action: PayloadAction<MenuKeys | null>) => {
      state.menuKeys = action.payload;
    },
    saveServiceRequestPage: (state, action: PayloadAction<ServiceRequestPage | null>) => {
      state.serviceRequestPage = action.payload;
    },
    saveUrgeSRStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.urgeSRStatus = action.payload;
    },
    saveSearchSRStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.searchSRStatus = action.payload;
    },
    saveGetMenuKeysSRStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getMenuKeysSRStatus = action.payload;
    },
    saveReplySRStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.replySRStatus = action.payload;
    },
    setServiceRequestFilters: (state, action: PayloadAction<ServiceRequestFilters | null>) => {
      state.serviceRequestFilters = action.payload;
    },
    showServiceRequestSuccessPopup: (state, action: PayloadAction<boolean>) => {
      state.serviceRequestShowSuccessPopup = action.payload;
    },
    setForceUpdateSRPage: (state, action: PayloadAction<boolean>) => {
      state.forceUpdateSRPage = action.payload;
    },
    setForceUpdateCounterSR: (state, action: PayloadAction<boolean>) => {
      state.forceUpdateCounterSR = action.payload;
    },
    resetState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  resetState,
  resetPopup,
  saveGetServiceRequestIdentifierStatus,
  saveServiceRequestIdentifier,
  saveSimpleSRList,
  saveGetSimpleSRListStatus,
  saveSubmitSRStatus,
  saveSubmitAddressSRStatus,
  saveSubmitOrderSupportSRStatus,
  saveGetShipmentSupportSRStatus,
  saveSubmitShipmentSupportSRStatus,
  saveShipmentSupportSRList,
  saveUrgeSRStatus,
  saveSearchSRStatus,
  saveReplySRStatus,
  saveServiceRequestPage,
  saveGetMenuKeysSRStatus,
  saveMenuKeys,
  showServiceRequestSuccessPopup,
  setServiceRequestFilters,
  saveUploadedFileStatus,
  setForceUpdateSRPage,
  saveBrandSRList,
  setForceUpdateCounterSR,
  saveGetBrandsSRStatus,
} = serviceRequestSlice.actions;

export const selectServiceRequestIdentifier = (state: RootState): string | null => {
  return state.serviceRequest.serviceRequestIdentifier;
};

export const selectSimpleSRList = (state: RootState): SimpleSRItem[] => {
  return state.serviceRequest.simpleSRList;
};

export const selectGetSimpleSRListStatus = (state: RootState): RequestStatus => {
  return state.serviceRequest.getSimpleSRListStatus;
};

export const selectSubmitSRStatus = (state: RootState): RequestStatus => {
  return state.serviceRequest.submitSRStatus;
};

export const selectSubmitAddressSRStatus = (state: RootState): SubmitAddressSRStatus => {
  return state.serviceRequest.submitAddressSRStatus;
};

export const selectSubmitOrderSupportSRStatus = (state: RootState): RequestStatus => {
  return state.serviceRequest.submitOrderSupportSRStatus;
};

export const selectGetShipmentSupportSRStatus = (state: RootState): RequestStatus => {
  return state.serviceRequest.getShipmentSupportSRStatus;
};

export const selectSubmitShipmentSupportSRStatus = (state: RootState): RequestStatus => {
  return state.serviceRequest.submitShipmentSupportSRStatus;
};

export const selectShipmentSupportSRList = (state: RootState): ShipmentSupportSRItem[] => {
  return state.serviceRequest.shipmentSupportSRList;
};

export const selectBrandSRList = (state: RootState): string[] => {
  return state.serviceRequest.brandSRList;
};

export const selectGetBrandsSRStatus = (state: RootState): RequestStatus => {
  return state.serviceRequest.getBrandsSRStatus;
};

export const selectGetServiceRequestIdentifierStatus = (state: RootState): RequestStatus => {
  return state.serviceRequest.getServiceRequestIdentifierStatus;
};

export const selectServiceRequestPage = (state: RootState): ServiceRequestPage | null => {
  return state.serviceRequest.serviceRequestPage;
};

export const selectMenuKeys = (state: RootState): MenuKeys | null => {
  return state.serviceRequest.menuKeys;
};

export const selectMenuKeysSRStatus = (state: RootState): RequestStatus => {
  return state.serviceRequest.getMenuKeysSRStatus;
};

export const selectSearchSRStatus = (state: RootState): RequestStatus => {
  return state.serviceRequest.searchSRStatus;
};

export const selectServiceRequestFilters = (state: RootState): ServiceRequestFilters | null => {
  return state.serviceRequest.serviceRequestFilters;
};

export const selectReplySRStatus = (state: RootState): RequestStatus => {
  return state.serviceRequest.replySRStatus;
};

export const selectUrgeSRStatus = (state: RootState): RequestStatus => {
  return state.serviceRequest.urgeSRStatus;
};

export const selectShowServiceRequestSuccessPopup = (state: RootState): boolean => {
  return state.serviceRequest.serviceRequestShowSuccessPopup;
};

export const selectForceUpdateSRPage = (state: RootState): boolean => {
  return state.serviceRequest.forceUpdateSRPage;
};

export const selectForceUpdateCounterSR = (state: RootState): boolean => {
  return state.serviceRequest.forceUpdateCounterSR;
};

//UPLOAD
export const selectUploadedFileStatus = (state: RootState): UploadedFileStatus[] => {
  return state.serviceRequest.uploadedFileStatus;
};

export default serviceRequestSlice.reducer;
