import React from "react";
import { useSelector } from "react-redux";
import DatePickerPlugin from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components/macro";
import clsx from "clsx";

import { selectLocale } from "../../store/user/userSlice";
import { getDateFormatString, getMatchLocale } from "../../utils/dateUtils";

import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar-two-icon.svg";
import ArrowHead from "../../assets/icons/arrowhead-left-icon-white.svg";

interface Props {
  onChange: (e: any) => void;
  selected: Date | null;
  startDate?: Date;
  endDate?: Date;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  monthsShown?: number;
  placeholder?: string;
  required?: boolean;
  size?: "sm" | "md";
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  isError?: boolean;
  helperText?: string;
  position?: "right" | "left" | "left-popper";
}

const DatePicker = (props: Props): JSX.Element => {
  const selectedLocale = useSelector(selectLocale);
  const locale: Locale = getMatchLocale(selectedLocale); // get locale string in format compatible with dates
  const format = getDateFormatString(selectedLocale); // get localized date pattern to feed to DatePicker

  const renderDayContents = (day: any, date: any) => {
    return (
      <div
        className={
          !props.endDate && props.selectsEnd && props.startDate?.toString() === date.toString()
            ? "day--start"
            : ""
        }
      >
        <span>{day}</span>
      </div>
    );
  };

  const printHelperText = () => {
    return (
      <HelperText className={props.isError ? "error" : "enabled"}>
        <span>
          {props.required && "*"}
          {props.helperText}
        </span>
      </HelperText>
    );
  };

  return (
    <StyledDatePicker position={props.position ?? "right"}>
      <InputContainer>
        <DatePickerPlugin
          {...props}
          locale={locale}
          dateFormat={format ? format : undefined}
          disabledKeyboardNavigation
          focusSelectedMonth
          autoComplete="off"
          renderDayContents={renderDayContents}
          customInput={
            <StyledInput
              className={clsx(
                "input-color-enabled",
                props.placeholder && "has-placeholder",
                props.size === "sm" && "input-size-small",
                props.isError && "input-color-error"
              )}
              placeholder={props.placeholder}
              disabled={props.disabled ?? false}
            />
          }
          {...(props.position === "left-popper" && { popperPlacement: "left" })}
        />
        <IconContainer className={clsx(props.size === "sm" && "input-size-small")}>
          <CalendarIcon />
        </IconContainer>
        {props.placeholder && (
          <Label
            className={clsx(
              "input-color-enabled",
              props.selected === null && "input-with-placeholder"
            )}
          >
            {props.placeholder} {props.required && "*"}
          </Label>
        )}
      </InputContainer>
      {props.helperText && printHelperText()}
    </StyledDatePicker>
  );
};

const StyledDatePicker = styled.div<{ position: "right" | "left" | "left-popper" }>`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    box-shadow: 0 0 64px 0 rgb(3 20 52 / 16%);
    border-radius: 0.25rem;
    background-color: ${(props) => props.theme.palette.gray.light};
    z-index: 1000;

    &[data-placement^="bottom"] {
      margin-top: 1.125rem;
      ${(props) => (props.position === "left" ? { left: "-20rem !important" } : {})};

      .react-datepicker__triangle {
        top: -8.27px;
        ${(props) => (props.position === "left" ? { left: "80% !important" } : {})};
      }
    }

    &[data-placement^="top"] {
      margin-bottom: 1.125rem;
      z-index: 10000;
      top: 5px !important;
      ${(props) => (props.position === "left" ? { left: "-20rem !important" } : {})};

      .react-datepicker__triangle {
        bottom: -8.27px;
        ${(props) => (props.position === "left" ? { left: "80% !important" } : {})};
      }
    }

    &[data-placement^="left"] {
      .react-datepicker__triangle {
        left: auto;
        right: -8px;
      }
    }
  }

  .react-datepicker {
    display: flex;
    background-color: ${(props) => props.theme.palette.white};
    border: 0;
    font-family: ${(props) => props.theme.fonts.fontMedium};
    padding: 2rem;
    background-color: ${(props) => props.theme.palette.gray.light};
    box-sizing: border-box;

    &__day--today {
      span {
        position: relative;
        color: ${(props) => props.theme.palette.white};
        z-index: 80;
      }
      &::before {
        background-color: ${(props) => props.theme.palette.primary};
        color: ${(props) => props.theme.palette.white};
        content: "";
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        border: 2px solid;
        position: absolute;
        top: 0;
        left: 0.5rem;
        box-sizing: border-box;
        z-index: 50;
      }
    }

    .day--start {
      height: 100%;
      width: 50%;
      margin-right: auto;
      background-color: ${(props) => props.theme.palette.white};
      span {
        color: ${(props) => props.theme.palette.white};
        position: relative;
        z-index: 0;
        left: 50%;
        &::before {
          background-color: #7888a5;
          color: ${(props) => props.theme.palette.white};
          content: "";
          height: 2rem;
          width: 2rem;
          border-radius: 50%;
          border: 2px solid;
          position: absolute;
          left: -0.8rem;
          box-sizing: border-box;
          z-index: -1;
        }
      }
    }

    &__triangle {
      box-shadow: 0 0 64px 0 rgb(3 20 52 / 16%);
      border: 0 !important;
      z-index: 300;
      transform: rotate(45deg);
      background-color: ${(props) => props.theme.palette.gray.light};
      height: 1.125rem !important;
      width: 1.125rem !important;
      margin: 0 !important;

      &:before {
        display: none;
      }
    }

    &__month {
      margin: 0;
    }

    &__month-container {
      padding: 1.5rem;
      box-sizing: border-box;
      width: 100%;
      background-color: ${(props) => props.theme.palette.white};
      border-radius: 0.25rem;
      width: 24.19rem;
      min-height: 18.63rem;

      &:nth-child(1n + 5) {
        margin-left: 1rem;
      }
    }

    &__header {
      background-color: ${(props) => props.theme.palette.white};
      padding: 0;
      border: 0;
    }

    &__current-month {
      text-align: left;
      font-family: ${(props) => props.theme.fonts.fontBold};
      font-weight: normal;
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: ${(props) => props.theme.palette.primary};
    }

    &__current-month:first-letter {
      text-transform: capitalize;
    }

    &__navigation {
      background-color: ${(props) => props.theme.palette.primary};
      border-radius: 50%;
      height: 1.5rem;
      width: 1.5rem;
      border: 0;

      &:after {
        background: url(${ArrowHead});
        content: "";
        display: block;
        position: absolute;
        top: calc(50% - 0.34375rem);
        left: calc(50% - 0.34375rem);
        height: 0.6875rem;
        width: 0.6875rem;
      }
    }

    &__navigation--previous {
      left: auto;
      top: 3.5rem;
      right: 6rem;
    }

    &__navigation--next {
      top: 3.5rem;
      right: 4rem;

      &:after {
        transform: rotate(180deg);
      }
    }

    &__day-names {
      display: flex;
      justify-content: space-between;
      background-color: ${(props) => props.theme.palette.gray.light};
      margin-top: 1.125rem;
      height: 1.875rem;
    }

    &__day-name {
      font-family: ${(props) => props.theme.fonts.fontBold};
      font-weight: normal;
      font-size: 0.8125rem;
      line-height: 1.875rem;
      margin: 0;
      flex: 1;
    }

    &__week {
      display: flex;
      justify-content: space-between;
    }

    &__day {
      font-size: 0.8125rem;
      line-height: 2rem;
      color: ${(props) => props.theme.palette.primary};
      height: 2rem;
      margin: 0;
      flex: 1;
      margin-bottom: 3px;
      position: relative;
      border-radius: 0;

      &:hover {
        border-radius: 0;
        background-color: ${(props) => props.theme.palette.gray.medium};
      }

      &--outside-month {
        color: ${(props) => props.theme.palette.gray.dark};
        background-color: transparent;

        &:hover {
          background-color: transparent;
        }
      }

      &--in-range:not(.react-datepicker__day--outside-month) {
        background-color: ${(props) => props.theme.palette.primary};
        color: ${(props) => props.theme.palette.white};
        border-radius: 0;

        &:hover {
          border-radius: 0;
          background-color: ${(props) => props.theme.palette.textBlue};
        }
      }

      &--in-selecting-range:not(.react-datepicker__day--outside-month) {
        border-radius: 0;
        background-color: ${(props) => props.theme.palette.textBlue};
      }

      &--keyboard-selected:not(.react-datepicker__day--outside-month) {
        background-color: transparent;
        color: ${(props) => props.theme.palette.white};
        span {
          z-index: 80;
          position: relative;
        }

        &:before {
          content: "";
          height: 2rem;
          width: 2rem;
          border-radius: 50%;
          border: 2px solid ${(props) => props.theme.palette.color};
          position: absolute;
          top: 0;
          left: 0.5rem;
          box-sizing: border-box;
          z-index: 50;
          background: ${(props) => props.theme.palette.textBlue};
        }
      }

      &--today:not(.react-datepicker__day--outside-month):before {
        background-color: ${(props) => props.theme.palette.primary};
        color: ${(props) => props.theme.palette.white};
      }

      &:not(.react-datepicker__day--outside-month) {
        &.react-datepicker__day--selected {
          background-color: transparent;

          span {
            color: ${(props) => props.theme.palette.primary};
            z-index: 80;
            position: relative;
          }

          &:before {
            content: "";
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
            border: 2px solid ${(props) => props.theme.palette.primary};
            position: absolute;
            top: 0;
            left: 0.5rem;
            box-sizing: border-box;
            z-index: 50;
            background: ${(props) => props.theme.palette.white};
          }
        }

        &.react-datepicker__day--range-end,
        &.react-datepicker__day--range-start {
          position: relative;

          span {
            color: ${(props) => props.theme.palette.primary};
            z-index: 80;
            position: relative;
          }

          &:before {
            content: "";
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
            border: 2px solid ${(props) => props.theme.palette.primary};
            position: absolute;
            top: 0;
            left: 0.5rem;
            box-sizing: border-box;
            z-index: 50;
            background: ${(props) => props.theme.palette.white};
          }

          /* TO HIDE BORDERS IF SELECTED DATES ARE NOT IN ORDER (IF NEEDED, REMOVE COMMENT)
        &:not(.react-datepicker__day--in-range) {
          background-color: transparent;

          &:after {
            display: none;
          }
        } */
        }

        &.react-datepicker__day--selected:not(.react-datepicker__day--in-range) {
          background-color: transparent;
        }

        &.react-datepicker__day--disabled {
          color: ${(props) => props.theme.palette.gray.dark};

          &:hover {
            background-color: ${(props) => props.theme.palette.white};
          }
        }

        &.react-datepicker__day--range-start.react-datepicker__day--in-range {
          background-color: ${(props) => props.theme.palette.primary};
          color: ${(props) => props.theme.palette.primary};
          &:after {
            content: "";
            height: 2rem;
            width: 50%;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            z-index: 30;
            background: ${(props) => props.theme.palette.white};
          }
        }

        &.react-datepicker__day--range-end.react-datepicker__day--in-range {
          background-color: ${(props) => props.theme.palette.primary};
          color: ${(props) => props.theme.palette.primary};
          &:after {
            content: "";
            height: 2rem;
            width: 50%;
            position: absolute;
            top: 0;
            left: 50%;
            box-sizing: border-box;
            z-index: 30;
            background: ${(props) => props.theme.palette.white};
          }
        }
      }
    }
  }
`;

const InputContainer = styled.div`
  position: relative;

  &:focus-within ~ label {
    position: absolute;
    top: 0.625rem;
    display: block;
    transition: 0.2s;
    font-size: 1.7rem;
    font-family: ${(props) => props.theme.fonts.fontRegular};
  }
`;

const StyledInput = styled.input`
  color: ${(props) => props.theme.palette.primary};
  border-radius: 0.25rem;
  padding: 0rem 0.75rem 0 1.5rem;
  line-height: 1.125rem;
  font-size: 0.8125rem;
  font-family: ${(props) => props.theme.fonts.fontMedium};
  width: 100%;
  outline: none;
  box-sizing: border-box;
  height: 3.5rem;
  padding-right: 2.5625rem;

  &.has-placeholder {
    padding-top: 0.75rem;
  }

  /* &:placeholder-shown ~ label {
    color: ${(props) => props.theme.palette.primary};
    font-size: 0.8125rem;
    cursor: text;
    top: 1.4rem;
    font-family: ${(props) => props.theme.fonts.fontMedium};
  } */

  &:required,
  &:invalid {
    box-shadow: none;
  }

  &:active {
    color: ${(props) => props.theme.palette.primary}!important;
  }

  /* hover solo su enabled */
  &:not(.input-color-error, .input-color-warning, .input-color-success):hover {
    border: solid 1px ${(props) => props.theme.palette.gray.dark} !important;
  }

  &:not(.input-color-error, .input-color-warning, .input-color-success):focus {
    border: solid 1px ${(props) => props.theme.palette.primary} !important;
  }

  &:focus {
    /* ~ label {
      position: absolute;
      top: 0.625rem;
      display: block;
      transition: 0.2s;
      font-size: 0.6875rem;
      color: ${(props) => props.theme.palette.gray.dark};
      font-family: ${(props) => props.theme.fonts.fontRegular};
    }

    &.input-color-error ~ label {
      color: ${(props) => props.theme.palette.feedback.error};
    }
    &.input-color-warning ~ label {
      color: ${(props) => props.theme.palette.feedback.alert};
    }
    &.input-color-success ~ label {
      color: ${(props) => props.theme.palette.feedback.success};
    } */
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: ${(props) => props.theme.fonts.fontMedium};
    color: transparent;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-family: ${(props) => props.theme.fonts.fontMedium};
    color: transparent;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    font-family: ${(props) => props.theme.fonts.fontMedium};
    color: transparent;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    font-family: ${(props) => props.theme.fonts.fontMedium};
    color: transparent;
  }

  &.input-color-enabled {
    border: solid 1px ${(props) => props.theme.palette.gray.medium};
  }

  &.input-color-error {
    border: solid 1px ${(props) => props.theme.palette.feedback.error};
  }

  &.input-color-warning {
    border: solid 1px ${(props) => props.theme.palette.feedback.alert};
  }

  &.input-color-success {
    border: solid 1px ${(props) => props.theme.palette.feedback.success};
  }

  &:disabled {
    background-color: ${(props) => props.theme.palette.gray.light};
    box-shadow: none;
    border: solid 1px ${(props) => props.theme.palette.gray.medium};

    &:hover {
      border: solid 1px ${(props) => props.theme.palette.gray.medium} !important;
    }

    /* ~ label {
      color: ${(props) => props.theme.palette.gray.dark};
      background-color: ${(props) => props.theme.palette.gray.light};
    } */

    ~ span svg {
      fill: ${(props) => props.theme.palette.gray.dark} !important;
    }
  }

  &.input-read-only {
    color: ${(props) => props.theme.palette.gray.medium};
  }

  &.input-size-small {
    height: 3.125rem;
    padding: 0 1.125rem;
  }
`;

const Label = styled.label`
  display: block;
  font-size: 0.6875rem;
  font-weight: 500;
  position: absolute;
  top: 0.625rem;
  letter-spacing: 0.19px;
  font-family: ${(props) => props.theme.fonts.fontRegular};
  padding-bottom: 0.625rem;
  left: calc(1.5rem + 1px);
  transition: 0.2s;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
  white-space: nowrap;
  color: ${(props) => props.theme.palette.gray.dark};

  &.input-color-error {
    color: ${(props) => props.theme.palette.feedback.error};
  }

  &.input-color-warning {
    color: ${(props) => props.theme.palette.feedback.alert};
  }

  &.input-color-success {
    color: ${(props) => props.theme.palette.feedback.success};
  }

  &.input-with-placeholder {
    color: ${(props) => props.theme.palette.primary};
    font-size: 0.8125rem;
    cursor: text;
    top: 1.4rem;
    font-family: ${(props) => props.theme.fonts.fontMedium};
  }
`;

const IconContainer = styled.span`
  position: absolute;
  top: calc(50% - 0.5rem);
  right: 1.5625rem;

  svg {
    height: 1rem;
    width: 1rem;
    fill: ${(props) => props.theme.palette.primary};
  }

  &.error-color svg {
    fill: ${(props) => props.theme.palette.feedback.error};
  }
  &.warning-color svg {
    fill: ${(props) => props.theme.palette.feedback.alert};
  }
  &.success-color svg {
    fill: ${(props) => props.theme.palette.feedback.success};
  }

  &.input-size-small {
    right: 1.125rem;
  }
`;

const HelperText = styled.p`
  font-family: ${(props) => props.theme.fonts.fontRegular};
  margin-top: 0.5rem;
  font-size: 0.75rem;
  margin-left: 1.5rem;

  &.enabled {
    color: ${(props) => props.theme.palette.gray.dark};
  }
  &.error {
    color: ${(props) => props.theme.palette.feedback.error};
  }
  &.warning {
    color: ${(props) => props.theme.palette.feedback.alert};
  }
  &.success {
    color: ${(props) => props.theme.palette.feedback.success};
  }
`;

export default DatePicker;
