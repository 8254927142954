import React from "react";
import styled from "styled-components/macro";
import IconButton from "./IconButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/x-icon.svg";

interface Props {
  isOpen: boolean;
  close: () => void;
  srcVideo: string | null;
  top?: number | null;
}

const VideoPopup = ({ close, isOpen, srcVideo, top = null }: Props): JSX.Element | null => {
  return isOpen ? (
    <>
      <BackgroundLayer onClick={close}></BackgroundLayer>
      <Container className="modal" top={top}>
        <Content>
          <div className="icon-container">
            <IconButton size="lg" type="white-no-border" icon={<CloseIcon />} onClick={close} />
          </div>
          {srcVideo && <video src={srcVideo} muted autoPlay={true} controls />}
        </Content>
      </Container>
    </>
  ) : null;
};

const BackgroundLayer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.82);
  z-index: 200;
  padding-bottom: 2rem;
`;

const Container = styled.div<{ top: number | null }>`
  position: absolute;
  max-width: calc(100vw - 5.5rem);
  max-height: 88%;
  min-width: 20vw;
  z-index: 200;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  ${(props) =>
    props.top &&
    `
    top: ${props.top}px;
    transform: translate(-50%, 0);
  `}

  .icon-container {
    position: absolute;
    top: -1.5rem;
    right: -1rem;
    width: 2rem;
    height: 2rem;
    box-shadow: 0.125rem 0.875rem 1.0625rem 0 rgba(10, 35, 57, 0.28);
    z-index: 202;
  }
`;

const Content = styled.div`
  max-width: 46.875rem;
  max-height: 46.875rem;

  video {
    width: 100%;
    height: 100%;
  }
`;

export default VideoPopup;
