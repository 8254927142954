import React from "react";
import styled from "styled-components/macro";
import useDataHref from "../../hooks/useDataHref";
import { TitleCardData } from "../../interfaces/cmsInterfaces";
import CustomText from "../styled-UI/CustomText";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  data: TitleCardData;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const TitleCard = ({ data, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  const {
    description2,
    description2Colour,
    description,
    description1Colour,
    title,
    titleColour,
  } = data;
  const descrWithHref = useDataHref({ content: description });
  const descrWithHref2 = useDataHref({ content: description2 });

  return (
    <OuterBox>
      <div>
        {title && (
          <CustomText
            as="div"
            fontSizePx={80}
            font={customFont ? "custom-light" : "font-light"}
            fontFamily={customFont}
            color={isDarkMode ? "white" : "primary"}
            lineHeightPx={82}
            isFlex={false}
            textAlign="center"
            customColor={titleColour}
            marginBottomPx={20}
          >
            {title}
          </CustomText>
        )}
      </div>
      <BoxTitle>
        {description && (
          <CustomText
            as="div"
            fontSizePx={28}
            font={customFont ? "custom-medium" : "font-medium"}
            fontFamily={customFont}
            color={isDarkMode ? "white" : "primary"}
            lineHeightPx={30}
            isFlex={false}
            textAlign="center"
            customColor={description1Colour}
            marginBottomPx={16}
          >
            <SetInnerHTMLWithSanitize text={descrWithHref.updatedContent ?? description} />
          </CustomText>
        )}
      </BoxTitle>
      <BoxTitleDescription>
        {description2 && (
          <CustomText
            as="div"
            fontSizePx={14}
            lineHeightPx={24}
            font={customFont ? "custom-regular" : "font-regular"}
            fontFamily={customFont}
            color={isDarkMode ? "white" : "primary"}
            customColor={description2Colour}
            isFlex={false}
            textAlign="center"
          >
            <SetInnerHTMLWithSanitize text={descrWithHref2.updatedContent ?? description2} />
          </CustomText>
        )}
      </BoxTitleDescription>
    </OuterBox>
  );
};

export default TitleCard;

const OuterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`;

const BoxTitle = styled.div``;

const BoxTitleDescription = styled.div``;
