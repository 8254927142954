import { isString } from "lodash";
import {
  PaymentFieldModal,
  PaymentFieldRowModal,
} from "../components/pages/payment/payment-method/payment-method-selection/add-edit-modal/paymentModalConfiguration";
import {
  SAPPaymentTypesMapping,
  OpenTextDownloadId,
  PaymentDeselectionReasons,
  PaymentDeselectionReasonsOptions,
  PaymentDocumentHistory,
  PaymentManagementDocumentsList,
  PaymentManagementOpenDocument,
  PaymentManagementOverviewTotals,
  PaymentManagementSimulationDoc,
  PaymentManagementTypesFull,
  PaymentMethod,
  PaymentMethodLabel,
  paymentMethodLabelMapping,
  PaymentMethodValues,
  PaymentSimulationRequest,
  PaymentStatement,
  PaymentStatusLabel,
  paymentStatusLabelMapping,
  PaymentStatusValues,
  PaymentHistoryList,
  PaymentHistoryDetail,
  PaymentHistoryDetailItem,
  PaymentAdvancedSelected,
  PaymentSimulationResponse,
  PaymentSimulationResponseLock,
  PaymentSimulationSummary,
  PaymentConfirmationResponse,
  PaymentUsername,
  SavePaymentMethodsPayload,
  UseType,
  PaymentMethodError,
  PaymentMethodSubscriptionType,
  mapPaymentMethodFromSubscriptionType,
  PaymentMethodInfoCC,
  PaymentMethodInfoACH,
  PaymentMethodModalAddress,
  PaymentMethodModalBA,
  PaymentMethodModalCC,
  PaymentMethodModalForm,
  PaymentMethodModal,
  AddPaymentMethodOldB2BBank,
  AddPaymentMethodOldB2BServicePayload,
  AddPaymentMethodOldB2BCard,
  PaymentMethodModalInfo,
  GetPaymentMethodDetailRequest,
  PaymentMethodOldB2BBank,
  PaymentMethodOldB2BCredit,
  EditPaymentMethodOldB2BBank,
  EditPaymentMethodOldB2BCredit,
  EditPaymentMethodOldB2BServicePayload,
  PaymentErrorConfig,
  PaymentErrorLocation,
  PaymentMethodPopupAction,
} from "../store/payment/paymentInterface";
import { CurrencyFormat } from "../store/user/userInterfaces";
import { getDateFromGenericString, getStringFromDate } from "./dateUtils";
import { sanitizeBoolean } from "./utils";
import { CustomOptions } from "../components/styled-UI/CustomSelect";
import {
  DEFAULT_PAYMENT_ERROR,
  PAYMENT_ERRORS_CONFIG,
} from "../components/pages/payment/payment-guard/PaymentErrorsConfig";
import { labelSubmitButton } from "../components/pages/payment/payment-method/payment-method-selection/paymentMethodSelectionConfiguration";

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// PAYMENT MANAGEMENT //////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const mapPaymentManagementOverviewTotals = (data: any): PaymentManagementOverviewTotals => {
  return {
    totalStatement: data?.Accountoverview?.Totalstatement,
    totalPayNow: data?.Accountoverview?.Totalpaynow,
    totalReceivables: data?.Accountoverview?.Totalreceivables,
    totalFutureDue: data?.Accountoverview?.Totalfuturedue,
    totalPastDue: data?.Accountoverview?.Totalpastdue,
  };
};

export const mapPaymentManagementDocumentsList = (data: any): PaymentManagementDocumentsList => {
  return {
    payNowList: mapPaymentManagementOpenDocumentArray(data?.PayNowItems, data?.Archid),
    statementList: mapPaymentManagementOpenDocumentArray(data?.StatBalanceItems, data?.Archid),
    netBalanceList: mapPaymentManagementOpenDocumentArray(data?.NetBalanceItems, data?.Archid),
  };
};

export const mapPaymentManagementOpenDocumentArray = (
  data: any[],
  archId: string
): PaymentManagementOpenDocument[] => {
  return data?.map((_) => mapPaymentManagementOpenDocument(_, archId)) ?? [];
};

export const mapPaymentManagementOpenDocument = (
  data: any,
  archId: string
): PaymentManagementOpenDocument => {
  return {
    documentTypeLabel: getDocumentTypeLabel(data?.Documenttype),
    soldTo: data?.Soldto,
    referencePoNumber: getReferencePoNumber(data?.Reference, data?.Ponumber),
    documentId: data?.Documentid,
    originalDocNumber: data?.Reference,
    documentDate: getDateFromGenericString(data?.Documentdate, "yyyyMMdd"),
    dueDate: getDateFromGenericString(data?.Duedate, "yyyyMMdd"),
    docAmount: data?.Docamount ? +data?.Docamount : undefined,
    openAmount: data?.Openamount ? +data?.Openamount : undefined,
    openTextDownload: getOpenTextDownload(data?.Opentextid, archId),
    position: data?.Position,
    reference: data?.Reference,
    poNumber: data?.Ponumber,
    documentType: data?.Documenttype,
  };
};

export const mapPaymentManagementOpenDocumentFromConfirmation = (
  data: any
): PaymentManagementOpenDocument => {
  return {
    documentTypeLabel: getDocumentTypeLabel(data?.documentType),
    soldTo: data?.soldTo,
    referencePoNumber: getReferencePoNumber(data?.reference, data?.poNumber),
    documentId: data?.documentId,
    originalDocNumber: data?.reference,
    documentDate: getDateFromGenericString(data?.documentDate, "yyyyMMdd"),
    dueDate: getDateFromGenericString(data?.dueDate, "yyyyMMdd"),
    docAmount: data?.docAmount ? +data?.docAmount : undefined,
    openAmount: data?.openAmount ? +data?.openAmount : undefined,
    openTextDownload: getOpenTextDownload(data?.openTextId, data?.archiveId),
    position: data?.position,
    reference: data?.reference,
    poNumber: data?.poNumber,
    documentType: data?.documentType,
  };
};

export const mapDeselectionReasons = (data: any): PaymentDeselectionReasonsOptions[] => {
  return (
    Object.keys(data)?.map((_: any) => {
      return {
        value: _,
        label: `PAYMENT_MANAGEMENT_DESELECTION_REASON_${_}`,
      };
    }) ?? []
  );
};

/**
 * Sum openAmount column of an array of PaymentManagementOpenDocument
 * TODO: check that openAmount is the correct column!!
 *
 * @param {PaymentManagementOpenDocument[]} docs
 * @return {*}  {number}
 */
export const computePaymentOpenDocTotal = (docs: PaymentManagementOpenDocument[]): number => {
  return docs.reduce(
    (tableTotal: number, currentRow: PaymentManagementOpenDocument) =>
      currentRow?.docAmount ? tableTotal + currentRow.docAmount : tableTotal, // sum openAmount of each row, if it's !== undefined
    0 // initial value
  );
};

export const mapPaymentManagementSimulationDocs = (
  documents: PaymentManagementOpenDocument[],
  currency: string
): PaymentManagementSimulationDoc[] => {
  return documents.map((_) => mapPaymentManagementSimulationDoc(_, null, currency));
};

export const mapPaymentManagementSimulationDoc = (
  openDocument: PaymentManagementOpenDocument,
  deselectReason: PaymentDeselectionReasons | null,
  currency: string
): PaymentManagementSimulationDoc => {
  return {
    documentType: openDocument.documentType,
    soldTo: openDocument.soldTo,
    documentId: openDocument.documentId,
    referencePoNumber: openDocument.referencePoNumber ?? null,
    documentDate: getStringFromDate(openDocument.documentDate, "yyyyMMdd"), // change type
    dueDate: getStringFromDate(openDocument.dueDate, "yyyyMMdd"), // change type
    docAmount: String(openDocument.docAmount), // change type
    openAmount: String(openDocument.openAmount), // change type
    reference: openDocument.reference,
    poNumber: openDocument.poNumber,
    archiveId: openDocument.openTextDownload?.openTextArchId ?? undefined,
    openTextId: openDocument.openTextDownload?.openTextId,
    currency,
    boxId: openDocument.documentId,
    position: openDocument.position,

    selected: !deselectReason,
    deselectReason,
  };
};

export const mapPaymentSimulationRequest = (
  orgentityDoor: string,
  paymentType: PaymentManagementTypesFull | undefined,
  paidInvoices: PaymentManagementOpenDocument[],
  deselectedInvoices: PaymentManagementSimulationDoc[],
  deselectionReasonCode: PaymentDeselectionReasons[],
  paymentAmount: number,
  paymentInfo: PaymentMethod,
  advancedPayment: PaymentAdvancedSelected | undefined,
  isBackOfficeUser: boolean,
  currency: CurrencyFormat
): PaymentSimulationRequest => {
  return {
    qparams: {
      door: orgentityDoor,
    },
    payload: {
      isBackOfficeUser,
      advancedPaymentReason: advancedPayment?.advancedReason,
      currency: currency.currency.opt,
      paidInvoices: mapPaymentManagementSimulationDocs(paidInvoices, currency.currency.opt),
      deselectedInvoices: deselectedInvoices,
      deselectionReasonCode,
      paymentAmount:
        paymentType === "PAY_ADVANCED" ? advancedPayment?.advancedAmount : String(paymentAmount),
      cardType: instanceOfPaymentMethodInfoCC(paymentInfo.paymentMethodInfo)
        ? paymentInfo.paymentMethodInfo.cardType
        : undefined,
      paymentDescription: paymentInfo.description,
      paymentMethod: paymentInfo.paymentMethod,
      paymentType: paymentType ? SAPPaymentTypesMapping[paymentType] : undefined,
      subscriptionId: paymentInfo.subscriptionId,
    },
  };
};

export const mapPaymentSimulationResponse = (
  data: any,
  subscriptionId: string,
  paymentMethod: PaymentMethod
): PaymentSimulationResponse => {
  const getNumber = (string?: string | null): number | undefined =>
    string ? Number(string) : undefined;

  const summary: PaymentSimulationSummary = {
    paymentAmount: getNumber(data?.data?.amounts?.paymentAmount),
    fixDiscount: getNumber(data?.data?.amounts?.fixDiscount),
    eomDiscount: getNumber(data?.data?.amounts?.eomDiscount),
    discountedAmount: getNumber(data?.data?.amounts?.discountedAmount),
  };

  const lock: PaymentSimulationResponseLock = {
    lockedPayment: data?.data?.lockedPayment ?? false,
    lockingSimulationId: data?.data?.lockingSimulationId,
    lockingUsername: mapPaymentUsername(data?.data?.lockingUsername, data?.data?.lockingUserType),
  };

  return {
    subscriptionId,
    simulationId: data?.data?.simulationId,
    summary,
    lock,
    paymentMethod,
  };
};

export const mapPaymentConfirmationResponse = (data?: any): PaymentConfirmationResponse => {
  return {
    simulationId: data?.data?.simulationId,
    paidDocuments: data?.data?.paidDocuments?.map((_: any) =>
      mapPaymentManagementOpenDocumentFromConfirmation(_)
    ),
    paymentMethod: getPaymentMethodLabel(data?.data?.paymentMethod),
    totalPaidAmount: data?.data?.totalPaidAmount ? Number(data?.data?.totalPaidAmount) : undefined,
    netAmount: data?.data?.netAmount ? Number(data?.data?.netAmount) : undefined,
    fixDiscount: data?.data?.fixDiscount ? Number(data?.data?.fixDiscount) : undefined,
    eomDiscount: data?.data?.eomDiscount ? Number(data?.data?.eomDiscount) : undefined,
    errorType: data?.result?.errorType,
  };
};

export const getPaymentSimulationCardOrBankNumber = (
  paymentMethod: PaymentMethod | undefined | null
): string => {
  if (instanceOfPaymentMethodInfoCC(paymentMethod?.paymentMethodInfo))
    return getFormattedPaymentMethodNumber(
      paymentMethod?.paymentMethodInfo?.cardNumber,
      "CreditCard"
    );

  if (instanceOfPaymentMethodInfoACH(paymentMethod?.paymentMethodInfo))
    return getFormattedPaymentMethodNumber(
      paymentMethod?.paymentMethodInfo?.transitRoutingNumber,
      "AchAccount"
    );

  return "";
};

export const getFormattedPaymentMethodNumber = (
  data: string | undefined | null,
  subscriptionType: PaymentMethodSubscriptionType
): string => {
  let methodNumber = "";

  if (data) {
    switch (subscriptionType) {
      case "AchAccount":
        methodNumber = data.replace(/X/g, "*");
        break;
      case "CreditCard":
        methodNumber =
          data
            .replace(/ /g, "")
            .match(/.{1,4}/g)
            ?.join(" ")
            .replace(/X/g, "*")
            .substring(0, 19) || "";
        break;
    }
  }

  return methodNumber;
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// PAYMENT HISTORY ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const mapPaymentHistoryList = (data: any): PaymentHistoryList[] => {
  return data?.PaymentHistoryItems?.map((_: any) => mapPaymentHistory(_, data?.Archid)) ?? [];
};

export const mapPaymentHistory = (data: any, archId: string): PaymentHistoryList => {
  return {
    paymentDescription: data?.Paymentdescription,
    processedDate: getDateFromGenericString(data?.Processeddate, "yyyyMMdd"),
    paymentAmount: data?.Paymentamount,
    discountAmount: data?.Discountamount,
    paymentMethod: getPaymentMethodLabel(data?.Paymentmethod),
    confirmationNumber: data?.Confirmationid,
    username: mapPaymentUsername(data?.Username, data?.Usertype),
  };
};

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////// PAYMENT HISTORY DETAILS /////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const mapPaymentHistoryDetails = (data: any): PaymentHistoryDetail => {
  return {
    paymentDescription: data?.Paymentdescription,
    processedDate: getDateFromGenericString(data?.Processeddate, "yyyyMMdd"),
    paidAmount: data?.Paidamount,
    detailItems: mapPaymentHistoryDetailsItem(data?.PaymentDetailItems) ?? [],
  };
};
export const mapPaymentHistoryDetailsItem = (data: any): PaymentHistoryDetailItem[] => {
  return data?.map((_: any) => ({
    documentTypeLabel: getDocumentTypeLabel(_?.Documenttype),
    documentNumber: _?.Documentid,
    originalDocNumber: _?.DocumentidAfs2,
    documentAmount: _?.Docamount,
    paidAmount: _?.Paidamount,
    documentType: _?.Documenttype,
  }));
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// DOCUMENT HISTORY ///////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const mapPaymentDocumentHistoryList = (data: any): PaymentDocumentHistory[] => {
  return (
    data?.PaymentStatusItems?.map((_: any) => mapPaymentDocumentHistory(_, data?.Archid)) ?? []
  );
};

export const mapPaymentDocumentHistory = (data: any, archId: string): PaymentDocumentHistory => {
  return {
    documentTypeLabel: getDocumentTypeLabel(data?.Documenttype),
    documentNumber: data?.Documentid,
    originalDocNumber: data?.DocumentidAfs2,
    paymentDate: getDateFromGenericString(data?.Paymentdate, "yyyyMMdd"),
    dueDate: getDateFromGenericString(data?.Duedate, "yyyyMMdd"),
    documentAmount: data?.Docamount,
    paidAmount: data?.Paidamount,
    status: getPaymentStatusLabel(data?.Status),
    paymentMethod: getPaymentMethodLabel(data?.Paymentmethod),
    confirmationId: data?.Confirmationid,
    openTextDownload: getOpenTextDownload(data?.Opentextid, archId),
    // userType: data?.UserType,
    username: mapPaymentUsername(data?.Username, data?.Usertype),
    // customerCode: data?.Customercode,
    documentType: data?.Documenttype,
  };
};

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// STATEMENT ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const mapPaymentStatementList = (data: any): PaymentStatement[] => {
  return data?.Statements?.map((_: any) => mapPaymentStatement(_, data?.Archid)) ?? [];
};

export const mapPaymentStatement = (data: any, archId: string): PaymentStatement => {
  return {
    documentTypeLabel: getDocumentTypeLabel(data?.Documenttype),
    statementId: data?.Customercode + data?.Statementdate,
    statementDate: getDateFromGenericString(data?.Statementdate, "yyyyMMdd"),
    customerCode: data?.Customercode,
    customerName: data?.Customerdesc,
    openTextDownload: getOpenTextDownload(data?.Opentextid, archId),
    documentType: data?.Documenttype,
  };
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// PAYMENT METHOD /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

//////////////////////////////// TYPE GUARDS
/**
 * Custom type guard, check if object implements PaymentMethodInfoCC interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(any)} object
 * @return {*}  {object is PaymentMethodInfoCC}
 */
export function instanceOfPaymentMethodInfoCC(object: any): object is PaymentMethodInfoCC {
  const isNotNullOrUndefined = (data: any) => data !== undefined && data !== null;

  return (
    isNotNullOrUndefined(object?.cardNumber) &&
    isNotNullOrUndefined(object?.cardType) &&
    isNotNullOrUndefined(object?.expirationMonth) &&
    isNotNullOrUndefined(object?.expirationYear) &&
    isNotNullOrUndefined(object?.expirationDate)
  );
}

/**
 * Custom type guard, check if object implements PaymentMethodInfoACH interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(any)} object
 * @return {*}  {object is PaymentMethodInfoACH}
 */
export function instanceOfPaymentMethodInfoACH(object: any): object is PaymentMethodInfoACH {
  const isNotNullOrUndefined = (data: any) => data !== undefined && data !== null;

  return (
    isNotNullOrUndefined(object?.accountNumber) &&
    isNotNullOrUndefined(object?.transitRoutingNumber) &&
    isNotNullOrUndefined(object?.country)
  );
}

/**
 * Custom type guard, check if object implements PaymentMethodModalCC interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(any)} object
 * @return {*}  {object is PaymentMethodModalCC}
 */
export function instanceOfPaymentMethodModalCC(object: any): object is PaymentMethodModalCC {
  return (
    object?.description != undefined &&
    object?.saveMethod != undefined &&
    object?.creditCardNumber != undefined &&
    object?.expirationMonth != undefined &&
    object?.expirationYear != undefined
    // object?.cvc != undefined // it's optional in the interface b/c it's missing in the edit popup
  );
}

/**
 * Custom type guard, check if object implements PaymentMethodModalBA interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(any)} object
 * @return {*}  {object is PaymentMethodModalBA}
 */
export function instanceOfPaymentMethodModalBA(object: any): object is PaymentMethodModalBA {
  return (
    object?.description != undefined &&
    object?.saveMethod != undefined &&
    object?.transitNumber != undefined &&
    object?.accountNumber != undefined
  );
}

/**
 * Custom type guard, check if object implements PaymentMethodModalAddress interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(any)} object
 * @return {*}  {object is PaymentMethodModalAddress}
 */
export function instanceOfPaymentMethodModalAddress(
  object: any
): object is PaymentMethodModalAddress {
  return (
    object?.firstName != undefined &&
    object?.lastName != undefined &&
    object?.email != undefined &&
    object?.address != undefined &&
    object?.country != undefined &&
    object?.city != undefined &&
    object?.zipCode != undefined &&
    object?.state != undefined
  );
}

/**
 * Map part of the API result into PaymentMethodInfoCC object,
 * or return null if some vital data is missing.
 *
 * Used by both v1 and v2 ways to get payment methods.
 *
 * @param {*} [data]
 * @return {*}  {(PaymentMethodInfoCC | null)}
 */
export const mapPaymentMethodInfoCC = (data?: any): PaymentMethodInfoCC | null => {
  const mappedCard: PaymentMethodInfoCC = {
    cardNumber: getFormattedPaymentMethodNumber(data?.cardNumber, "CreditCard"),
    cardType: data?.cardType,
    expirationMonth: data?.expirationMonth,
    expirationYear: data?.expirationYear,
    expirationDate: `${data?.expirationMonth}/${data?.expirationYear}`, // TODO: is this correct?
  };

  if (instanceOfPaymentMethodInfoCC(mappedCard)) return mappedCard;
  else return null;
};

/**
 * Map part of the API result into PaymentMethodInfoACH object,
 * or return null if some vital data is missing.
 *
 * Used by both v1 and v2 ways to get payment methods.
 *
 * @param {*} [data]
 * @return {*}  {(PaymentMethodInfoACH | null)}
 */
export const mapPaymentMethodInfoACH = (data?: any): PaymentMethodInfoACH | null => {
  const mappedAccount: PaymentMethodInfoACH = {
    accountNumber: getFormattedPaymentMethodNumber(data?.accountNumber, "AchAccount"),
    country: data?.country,
    transitRoutingNumber: getFormattedPaymentMethodNumber(data?.transitRoutingNumber, "AchAccount"),
  };

  if (instanceOfPaymentMethodInfoACH(mappedAccount)) return mappedAccount;
  else return null;
};

const mapPaymentMethodForV1 = (data: any): PaymentMethod | null => {
  const subscriptionType =
    data?.subscriptionType === "AchAccount" || data?.subscriptionType === "CreditCard"
      ? (data?.subscriptionType as PaymentMethodSubscriptionType)
      : null;

  const paymentMethodInfo =
    subscriptionType === "CreditCard"
      ? mapPaymentMethodInfoCC(data)
      : mapPaymentMethodInfoACH(data);

  if (subscriptionType === null || paymentMethodInfo === null) return null; // payment method is not valid

  return {
    subscriptionId: data?.subscriptionId,
    subscriptionType, // AchAccount or CreditCard
    paymentMethod: mapPaymentMethodFromSubscriptionType[subscriptionType], // CC or BA
    description: data?.alias,
    preferred: sanitizeBoolean(data?.preferred),
    paymentMethodInfo,
  };
};

export const mapPaymentMethodsV1 = (data: any): SavePaymentMethodsPayload => {
  if (data instanceof Array) {
    const paymentMethodsCC: PaymentMethod[] = [];
    const paymentMethodsACH: PaymentMethod[] = [];

    data?.forEach((_) => {
      const method = mapPaymentMethodForV1(_);
      // check for nulls in case payment methods are missing some info that makes them unusable
      if (method && method.subscriptionType === "CreditCard") paymentMethodsCC.push(method);
      if (method && method.subscriptionType === "AchAccount") paymentMethodsACH.push(method);
    });

    return { paymentMethodsCC, paymentMethodsACH };
  } else return { paymentMethodsCC: [], paymentMethodsACH: [] };
};

export const mapPaymentMethodOldB2B = (
  data: any,
  subscriptionType: PaymentMethodSubscriptionType
): PaymentMethod[] => {
  const paymentMethods: PaymentMethod[] = [];

  data?.forEach((_: any) => {
    const paymentMethodInfo =
      subscriptionType === "CreditCard" ? mapPaymentMethodInfoCC(_) : mapPaymentMethodInfoACH(_);

    if (paymentMethodInfo === null || _?.id === null || _?.id === undefined) return; // payment method is not valid

    paymentMethods.push({
      subscriptionId: _?.id,
      subscriptionType,
      paymentMethod: mapPaymentMethodFromSubscriptionType[subscriptionType], // CC or BA
      description: _?.description,
      preferred: sanitizeBoolean(_?.preferred),
      paymentMethodInfo,
    });
  });

  return paymentMethods;
};

export const showRowPaymentModal = (
  row: PaymentFieldRowModal,
  actionType: string | null,
  useType: UseType
): boolean => row.hiddenIn !== actionType && (!row.useOnlyIn || row.useOnlyIn === useType);

export const showFieldPaymentModal = (
  field: PaymentFieldModal,
  actionType: string | null
): boolean => field.hiddenIn !== actionType;

export const printErrorLabelPaymentForm = (error: PaymentMethodError): string => {
  switch (error?.errorType) {
    case "REQUIRED":
      return "REQUIRED_FIELD";
  }
  return "";
};

export const printSubmitLabelPaymentMethodForm = (
  actionType: PaymentMethodPopupAction | null | undefined,
  useType: UseType | null | undefined,
  type: PaymentMethodSubscriptionType | null | undefined
): string => {
  if (!actionType || !useType || !type) return "";

  const labelConfig = labelSubmitButton[type];
  let configType = actionType; // "edit" or "add"

  if (actionType === "add") configType += "-" + useType; // "add-payment-management" or "add-payment-method"

  return labelConfig[configType as "edit" | "add-payment-method" | "add-payment-management"];
};

/**
 * Maps add/edit method modal form values into a PaymentMethodModalCC or PaymentMethodModalBA object
 * returns null if some vital data is missing
 *
 * @param {PaymentMethodModalForm} formData
 * @param {PaymentMethodSubscriptionType} subscriptionType
 * @return {*}  {(PaymentMethodModalCC | PaymentMethodModalBA | null)}
 */
export const getMethodInfoFromFormData = (
  formData: PaymentMethodModalForm,
  subscriptionType: PaymentMethodSubscriptionType,
  useType: UseType
): PaymentMethodModalCC | PaymentMethodModalBA | null => {
  let methodInfo = {};

  const defaultSaveMethod = useType === "payment-method" ? true : false;

  if (subscriptionType === "CreditCard") {
    methodInfo = {
      description: formData?.description,
      saveMethod: formData?.saveMethod ?? defaultSaveMethod,
      creditCardNumber: formData?.creditCardNumber,
      expirationMonth: formData?.expirationMonth,
      expirationYear: formData?.expirationYear,
      cvc: formData?.cvc,
    };
  }
  if (subscriptionType === "AchAccount") {
    methodInfo = {
      description: formData?.description,
      saveMethod: formData?.saveMethod ?? defaultSaveMethod,
      transitNumber: formData?.transitNumber,
      accountNumber: formData?.accountNumber,
    };
  }

  if (instanceOfPaymentMethodModalCC(methodInfo) || instanceOfPaymentMethodModalBA(methodInfo))
    return methodInfo;
  else return null;
};

/**
 * Maps add/edit method modal form values into a PaymentMethodModalAddress object
 * returns null if some vital data is missing
 *
 * @param {PaymentMethodModalForm} formData
 * @return {*}  {(PaymentMethodModalAddress | null)}
 */
export const getAddressInfoFromFormData = (
  formData: PaymentMethodModalForm
): PaymentMethodModalAddress | null => {
  const address = {
    firstName: formData?.firstName,
    lastName: formData?.lastName,
    email: formData?.email,
    address: formData?.address,
    country: formData?.country,
    city: formData?.city,
    zipCode: formData?.zipCode,
    state: formData?.state,
  };

  if (instanceOfPaymentMethodModalAddress(address)) return address;
  else return null;
};

/**
 * Map payload from add/edit method modal function to service payload for ADD
 * returns null if some vital data is missing
 *
 * @param {PaymentMethodModal} data
 * @return {*}  {(AddPaymentMethodOldB2BServicePayload | null)}
 */
export const mapPaymentMethodModalToAddServicePayload = (
  data: PaymentMethodModal,
  currency: CurrencyFormat
): AddPaymentMethodOldB2BServicePayload | null => {
  let paymentMethodInfo: AddPaymentMethodOldB2BBank | AddPaymentMethodOldB2BCard | null = null;

  ////////////// handle bank account
  if (data?.subscriptionType === "AchAccount" && instanceOfPaymentMethodModalBA(data?.methodInfo)) {
    paymentMethodInfo = {
      // common for add and edit
      ...mapPaymentMethodOldB2BBankFromModal(data),

      // only for add
      billingBankName: data?.address?.firstName,
      billingBankSurname: data?.address?.lastName,

      accountDescription: data?.methodInfo?.description,
      accountNumber: data?.methodInfo?.accountNumber,
      currencyCode: currency.currency.opt,
      transitRoutingNumber: data?.methodInfo?.transitNumber,
    };
  }

  ////////////// handle credit card
  if (data?.subscriptionType === "CreditCard" && instanceOfPaymentMethodModalCC(data?.methodInfo)) {
    paymentMethodInfo = {
      // common for add and edit
      ...mapPaymentMethodOldB2BCreditFromModal(data),

      // only for add
      billingName: data?.address?.firstName,
      billingSurname: data?.address?.lastName,
      cardNumber: data?.methodInfo?.creditCardNumber,
      currencyCode: currency.currency.opt,
      cvv: data?.methodInfo?.cvc,
    };
  }

  if (paymentMethodInfo)
    return {
      isPreferred: false, // when adding, only if we are saving the method it becomes the new preferred
      isVisible: data?.methodInfo?.saveMethod,
      subscriptionType: data?.subscriptionType,
      paymentMethodInfo,
    };
  return null;
};

/**
 * Map payload from add/edit method modal function to service payload for EDIT
 * returns null if some vital data is missing
 *
 * @param {PaymentMethodModal} data
 * @return {*}  {(EditPaymentMethodOldB2BServicePayload | null)}
 */
export const mapPaymentMethodModalToEditServicePayload = (
  data: PaymentMethodModal
): EditPaymentMethodOldB2BServicePayload | null => {
  let paymentMethodInfo: EditPaymentMethodOldB2BBank | EditPaymentMethodOldB2BCredit | null = null;

  ////////////// handle bank account
  if (data?.subscriptionType === "AchAccount") {
    paymentMethodInfo = {
      // common for add and edit
      ...mapPaymentMethodOldB2BBankFromModal(data),

      // only for edit
      subscriptionId: data?.subscriptionId ?? "",
      description: data?.methodInfo?.description,
    };
  }

  ////////////// handle credit card
  if (data?.subscriptionType === "CreditCard") {
    paymentMethodInfo = {
      // common for add and edit
      ...mapPaymentMethodOldB2BCreditFromModal(data),

      // only for edit
      subscriptionId: data?.subscriptionId ?? "",
    };
  }

  if (paymentMethodInfo)
    return {
      subscriptionType: data?.subscriptionType,
      paymentMethodInfo,
    };
  return null;
};

const mapPaymentMethodOldB2BBankFromModal = (data: PaymentMethodModal): PaymentMethodOldB2BBank => {
  return {
    bankCountry: data?.address?.country,
    billingBankCity: data?.address?.city,
    billingBankEmail: data?.address?.email,
    billingBankLine1: data?.address?.address,
    billingBankPostalCode: data?.address?.zipCode,
    billingBankState: data?.address?.state,
  };
};

const mapPaymentMethodOldB2BCreditFromModal = (
  data: PaymentMethodModal
): PaymentMethodOldB2BCredit => {
  return {
    ccCountry: data?.address?.country,
    billingCity: data?.address?.city,
    billingEmail: data?.address?.email,
    billingLine1: data?.address?.address,
    billingPostalCode: data?.address?.zipCode,
    billingState: data?.address?.state,

    description: data?.methodInfo?.description,
    expireMonth: instanceOfPaymentMethodModalCC(data?.methodInfo)
      ? data?.methodInfo?.expirationMonth
      : "",
    expireYear: instanceOfPaymentMethodModalCC(data?.methodInfo)
      ? data?.methodInfo?.expirationYear
      : "",
  };
};

/**
 * Map API response for payment method detail to the generic PaymentMethodModalInfo
 * that is used to populate the edit modal.
 * Returns null if the provided type has not match (shouldn't happen)
 *
 * @param {*} data
 * @param {GetPaymentMethodDetailRequest} payload
 * @return {*}  {(PaymentMethodModalInfo | null)}
 */
export const mapPaymentMethodDetailToModal = (
  data: any,
  { payload }: GetPaymentMethodDetailRequest
): PaymentMethodModalInfo | null => {
  if (payload.type === "cardId")
    return {
      firstName: data?.billingName,
      lastName: data?.billingSurname,
      email: data?.billingEmail,
      address: data?.billingLine1,
      country: data?.ccCountry,
      city: data?.billingCity,
      zipCode: data?.billingPostalCode,
      state: data?.billingState,
      description: data?.description,
      creditCardNumber: getFormattedPaymentMethodNumber(data?.cardNumber, "CreditCard"),
      expirationMonth: data?.expirationMonth,
      expirationYear: data?.expirationYear,
    };
  if (payload.type === "bankId")
    return {
      firstName: data?.billingBankName,
      lastName: data?.billingBankSurname,
      email: data?.billingBankEmail,
      address: data?.billingBankLine1,
      country: data?.bankCountry,
      city: data?.billingBankCity,
      zipCode: data?.billingBankPostalCode,
      state: data?.billingBankState,
      description: data?.description,
      transitNumber: data?.transitRoutingNumber,
      accountNumber: data?.accountNumber,
    };
  return null;
};

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////// MISC /////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Custom type guard, check if object implements OpenTextDownloadId interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(any)} object
 * @return {*}  {object is OpenTextDownloadId}
 */
export function instanceOfOpenTextDownload(object: any): object is OpenTextDownloadId {
  return (
    object?.openTextId != undefined &&
    object?.openTextId != null &&
    object?.openTextArchId != undefined &&
    object?.openTextArchId != null
  );
}

const getOpenTextDownload = (
  openTextId: string | undefined | null,
  openTextArchId: string | undefined | null
): OpenTextDownloadId | undefined => {
  if (!openTextId || !openTextArchId) return undefined;

  return {
    openTextId: openTextId,
    openTextArchId: openTextArchId,
  };
};

export const getPaymentMethodLabel = (
  value?: PaymentMethodValues
): PaymentMethodLabel | undefined => {
  if (!value) return undefined; // will appear as "-" in PaymentTableRow
  return paymentMethodLabelMapping[value] ?? `PAYMENT_METHOD_${value}`;
};

export const getPaymentStatusLabel = (
  value?: PaymentStatusValues
): PaymentStatusLabel | undefined => {
  if (!value) return undefined; // will appear as "-" in PaymentTableRow
  return paymentStatusLabelMapping[value] ?? `PAYMENT_STATUS_${value}`;
};

export const getReferencePoNumber = (
  reference: string | undefined,
  poNumber: string | undefined
): string | undefined => {
  if (!!reference && !!poNumber) return `${reference}-${poNumber}`;
  if (!reference) return poNumber;
  if (!poNumber) return reference;
  return undefined;
};

/**
 * Custom type guard, check if object implements OpenTextDownloadId interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(any)} object
 * @return {*}  {object is OpenTextDownloadId}
 */
export function instanceOfPaymentUsername(object: any): object is PaymentUsername {
  const isUserTypeValid =
    object?.userType === "1" ||
    object?.userType === "2" ||
    object?.userType === "3" ||
    object?.userType === undefined;

  const isUsernameValid = isString(object?.username) || object?.username === undefined;

  return isUserTypeValid && isUsernameValid;
}

export const mapPaymentUsername = (
  username: string | undefined,
  userType: string | number | undefined
): PaymentUsername => {
  const userTypeString = String(userType);

  return {
    username: username,
    userType:
      userTypeString === "1" || userTypeString === "2" || userTypeString === "3"
        ? userTypeString
        : undefined,
  };
};

/**
 * Payment column "username" (displayed in Payment History at this time) should not always show the username received from SAP.
 * Specifically, given:
 *    - the username parameter from API
 *    - the usertype parameter from API (1 - mainuser, 2 - subuser, 3 - bo user)
 *    - information whether the currently logged user is a backoffice user
 *
 *  The logic for displaying the column is as follows:
 *    1) If the payment was made by a mainuser/subuser (usertype == 1 || usertype == 2), always show username from API
 *     ) If the payment was made by a backoffice user (usertype == 3):
 *        2) if the logged user is a backoffice user, show username from API
 *        3) otherwise show a label translated to "Credit agent"
 *
 *  If no info is provided in usertype, fallback to 3).
 *
 * @param {(PaymentUsername | undefined)} username
 * @param {boolean} isBackOfficeUser
 * @return {*}  {(string | undefined)}
 */
export const getUsernameLabel = (
  username: PaymentUsername | undefined,
  isBackOfficeUser: boolean
): string | undefined => {
  if (username?.userType === "1" || username?.userType === "2" || isBackOfficeUser)
    return username?.username;
  else return "PAYMENT_TABLE_USERNAME_CREDIT_AGENT";
};

/**
 * Get document type label from SAP value by following these steps:
 *    - remove all characters that are not spaces or letters
 *    - replace all spaces with _ (also multiple ones!)
 *    - add the prefix: PAYMENT_TABLE_DOCUMENT_TYPE_
 *
 * @param {*} data
 */
export const getDocumentTypeLabel = (data: any) => {
  if (!data || data == "") {
    return "-";
  }
  const label = data
    ?.replace(/[^a-z A-Z]/g, "") // remove unwanted characters (only keep letters and spaces)
    ?.replace(/\s{1,}/g, "_") // replace one or more spaces with one underscore
    ?.toUpperCase(); // make uppercase

  return `PAYMENT_TABLE_DOCUMENT_TYPE_${label}`;
};

export const mapPaymentMethodCountriesList = (data: any): CustomOptions[] => {
  return data?.map((_: any) => {
    return {
      value: _?.key,
      label: "COUNTRY_" + _?.key,
    };
  });
};

/**
 * Utils to extract error configuration from received errorcode
 *
 * @param {(string | undefined | null)} errorCode
 * @param {PaymentErrorLocation} location
 * @return {*}  {(PaymentErrorConfig | undefined)}
 */
export const getPaymentErrorConfig = (
  errorCode: string | undefined | null,
  location: PaymentErrorLocation
): PaymentErrorConfig | undefined => {
  if (errorCode === undefined || errorCode === null) return undefined; // no error if no errorCode was provided

  let error: PaymentErrorConfig | undefined;

  if (errorCode in PAYMENT_ERRORS_CONFIG) {
    const errorCodeConfigs = PAYMENT_ERRORS_CONFIG?.[errorCode]; // get configurations for specified errorCode

    // from that, get configuration for specified location, if available
    if (location in errorCodeConfigs) error = errorCodeConfigs?.[location];
    else {
      // otherwise fallback to the first location available
      const firstKeyOfConfig = Object.keys(errorCodeConfigs)?.[0] as PaymentErrorLocation;
      error = errorCodeConfigs?.[firstKeyOfConfig];
    }
  }

  return error ?? DEFAULT_PAYMENT_ERROR; // return found error (or fallback to default if errorCode's config was not found)
};

export const checkForPaymentError = (
  data: any,
  location: PaymentErrorLocation
): PaymentErrorConfig | undefined => {
  const error = data?.error?.errorCode ?? data?.errorCode;
  return getPaymentErrorConfig(error, location);
};
