import React, { useEffect, useRef } from "react";
import WrapperLayout from "../layouts/WrapperLayout";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";

interface DangerouslySetHtmlContentProps extends React.HTMLAttributes<HTMLDivElement> {
  html: string;
}

const DangerouslySetHtmlContent = (props: DangerouslySetHtmlContentProps): JSX.Element => {
  const { html, ...rest } = props;

  // useEffect(() => {
  //   if (!html) return;

  //   const slotHtml = document.createRange().createContextualFragment(html); // Create a 'tiny' document and parse the html string

  //   if (divRef?.current) {
  //     divRef.current.innerHTML = ""; // Clear the container
  //     divRef.current.appendChild(slotHtml); // Append the new content
  //   }
  // }, [html]);

  const makeHtml = () => {
    const rootElement = document.createElement("div");
    const frag = document.createDocumentFragment();
    const displayAnchor = document.createElement("div");
    displayAnchor.innerHTML = html;
    frag.appendChild(displayAnchor);

    rootElement.appendChild(frag);

    return (
      <WrapperLayout>
        <SetInnerHTMLWithSanitize text={rootElement.innerHTML} />
      </WrapperLayout>
    );
  };

  return makeHtml();
};

export default DangerouslySetHtmlContent;
