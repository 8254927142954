import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import { RootState } from "../storeConfig";

import {
  AddressList,
  GenericPrivateUserPolicyPayload,
  ProfileLoading,
  profileState,
  SalesRep,
  UserPrefs,
} from "./profileInterfaces";

export const sliceName = "profile";

const defaultProfileLoading = {
  profileAddresses: false,
};

export const initialState: profileState = {
  salesRepList: null,
  salesRepListStatus: "IDLE",
  privateUserPolicyStatus: "IDLE",
  privateUserPolicyUpdateStatus: "IDLE",
  privateUserPolicyDispatched: null,
  profileAddresses: null,
  loading: { ...defaultProfileLoading },
  publicUserPolicy: null,
  privateUserPolicy: null,
  getPublicUserPolicyStatus: "IDLE",
  submitPublicUserPolicyStatus: "IDLE",
};

export const profileSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSalesRepList: (state, { payload }: PayloadAction<SalesRep>) => {
      // const salesRepList: SalesRep = {};
      // Object.keys(payload).forEach((brand) => {
      //   salesRepList[brand] = { email: "dummy@email.com" };
      // });
      state.salesRepList = payload;
    },
    setSalesRepListStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.salesRepListStatus = payload;
    },
    setPrivateUserPolicyStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.privateUserPolicyStatus = payload;
    },
    setPrivateUserPolicyUpdateStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.privateUserPolicyUpdateStatus = payload;
    },
    setPrivateUserPolicyDispatched: (
      state,
      { payload }: PayloadAction<GenericPrivateUserPolicyPayload[] | null>
    ) => {
      state.privateUserPolicyDispatched = payload;
    },
    saveProfileAddresses: (state, { payload }: PayloadAction<AddressList[]>) => {
      state.profileAddresses = payload;
    },
    startProfileLoading: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case "addresses":
          state.loading = {
            ...state.loading,
            profileAddresses: true,
          };
          break;
      }
    },
    stopProfileLoading: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case "addresses":
          state.loading = {
            ...state.loading,
            profileAddresses: false,
          };
          break;
      }
    },
    savePublicUserPolicy: (state, action: PayloadAction<UserPrefs | null>) => {
      if (action.payload?.contextAttribute) state.publicUserPolicy = action.payload;
    },
    savePrivateUserPolicy: (state, action: PayloadAction<UserPrefs | null>) => {
      state.privateUserPolicy = action.payload;
    },
    setSubmitPublicUserPolicyStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.submitPublicUserPolicyStatus = payload;
    },
    setGetPublicUserPolicyStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.getPublicUserPolicyStatus = payload;
    },
    resetProfileState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  setSalesRepList,
  setSalesRepListStatus,
  saveProfileAddresses,
  startProfileLoading,
  stopProfileLoading,
  savePublicUserPolicy,
  savePrivateUserPolicy,
  setSubmitPublicUserPolicyStatus,
  setGetPublicUserPolicyStatus,
  setPrivateUserPolicyStatus,
  setPrivateUserPolicyDispatched,
  setPrivateUserPolicyUpdateStatus,
  resetProfileState,
} = profileSlice.actions;

export const selectSalesRepList = (state: RootState): SalesRep | null => state.profile.salesRepList;
export const selectSalesRepListStatus = (state: RootState): RequestStatus =>
  state.profile.salesRepListStatus;

export const selectPrivateUserPolicyStatus = (state: RootState): RequestStatus =>
  state.profile.privateUserPolicyStatus;

export const selectPrivateUserPolicyUpdateStatus = (state: RootState): RequestStatus =>
  state.profile.privateUserPolicyUpdateStatus;

export const selectPrivateUserPolicyDispatched = (
  state: RootState
): GenericPrivateUserPolicyPayload[] | null => state.profile.privateUserPolicyDispatched;

export const selectProfileAddresses = (state: RootState): AddressList[] | null =>
  state.profile.profileAddresses;

export const selectProfileLoading = (state: RootState): ProfileLoading => state.profile.loading;

export const selectPublicUserPolicy = (state: RootState): UserPrefs | null =>
  state.profile.publicUserPolicy;

export const selectPrivateUserPolicy = (state: RootState): UserPrefs | null =>
  state.profile.privateUserPolicy;

export const selectGetPublicUserPolicyStatus = (state: RootState): RequestStatus =>
  state.profile.getPublicUserPolicyStatus;

export const selectSubmitPublicUserPolicyStatus = (state: RootState): RequestStatus =>
  state.profile.submitPublicUserPolicyStatus;

export default profileSlice.reducer;
