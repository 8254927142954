import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import clsx from "clsx";
import useTranslation from "../../../../hooks/useTranslation";
import useCanRender from "../../../../hooks/useCanRender";

import { getFocalType } from "../../../../store/rx/rxSagas";
import { selectFocalTypeDetails } from "../../../../store/rx/rxSlice";
import { FocalTypesDetail } from "../../../../store/rx/rxInterface";
import {
  CustomRx,
  OrderItem,
  RxPrescriptionBrasilStatus,
} from "../../../../store/cart/cartInterfaces";
import { selectActiveDoor } from "../../../../store/user/userSlice";
import { getAttributeValues } from "../../../../utils/productUtils";
import { getJobTypeLabel, mergeRxPrescription } from "../../../../utils/rxUtils";
import CustomText from "../../../styled-UI/CustomText";
import PrescriptionTable from "../../PrescriptionTable";
import RxPrescriptionBrasil from "./RxPrescriptionBrasil";

interface Props {
  xitemRXfocalTypeName?: string;
  rxMappedData?: CustomRx;
  currentOrderItem: OrderItem;
  brandGroup?: string;
  brand?: string;
  jobType?: string;
  outOfStock?: boolean;
  rxBrasilStatus?: RxPrescriptionBrasilStatus | null;
}

const CartTileRxDetails = ({
  xitemRXfocalTypeName,
  rxMappedData,
  currentOrderItem,
  brandGroup,
  brand,
  jobType,
  outOfStock,
  rxBrasilStatus,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const canRender = useCanRender();

  const activeDoor = useSelector(selectActiveDoor);
  const showBrasilPrescription = canRender("RX_PRESCRIPTION_UPLOAD", activeDoor);

  const focalTypeDetails = useSelector(selectFocalTypeDetails);
  const [rxPrescriptionData, setRxPrescriptionData] = useState<FocalTypesDetail>();

  // get job type
  useEffect(() => {
    if (brand && jobType) {
      dispatch(
        getFocalType({
          brandGroup: brandGroup as string,
          brand,
          jobType: getJobTypeLabel[jobType] ?? "",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (focalTypeDetails) {
      focalTypeDetails.find((_) => {
        _.focalTypeName === xitemRXfocalTypeName && setRxPrescriptionData(_);
      });
    }
  }, [focalTypeDetails]);

  const handlePrescriptionData = () => {
    if (rxMappedData) {
      return mergeRxPrescription(rxMappedData, rxPrescriptionData?.parameterValues ?? []);
    } else {
      return rxPrescriptionData?.parameterValues ?? [];
    }
  };

  return (
    <>
      <Container className={clsx(outOfStock && "out-of-stock")}>
        <CustomText as="span" fontSizePx={18} font="font-bold" marginBottomPx={18}>
          {translateLabel("RX_CART_DETAILS_ORDER")}
        </CustomText>

        <RelatedSection>
          <Box>
            <TitleContainer>
              <CustomText as="span" fontSizePx={13} font="font-bold" marginBottomPx={8}>
                {translateLabel("RX_CART_DETAILS_FRAME")}
              </CustomText>
            </TitleContainer>
            <CustomText as="span" fontSizePx={13} font="font-regular" marginBottomPx={8}>
              {translateLabel("RX_CART_DETAILS_KIND_FRAME")}:
            </CustomText>
            <CustomText as="span" fontSizePx={13} font="font-bold" marginBottomPx={8}>
              {
                getAttributeValues(
                  currentOrderItem?.sku?.attributes ?? [],
                  "PRODUCT_CATEGORY_CHIPS"
                )?.values
              }
            </CustomText>
            <CustomText as="span" fontSizePx={13} font="font-regular" marginBottomPx={8}>
              {translateLabel("RX_CART_DETAILS_MODEL")}:
            </CustomText>
            <CustomText as="span" fontSizePx={13} font="font-bold" marginBottomPx={8}>
              {currentOrderItem?.sku?.brand
                ? translateLabel(currentOrderItem.sku.brand + "_LABEL")
                : ""}{" "}
              {currentOrderItem?.skuCode} {currentOrderItem?.productName?.replace("__", " ")}
            </CustomText>
            <CustomText as="span" fontSizePx={13} font="font-regular" marginBottomPx={8}>
              {translateLabel("RX_CART_DETAILS_FRAME_COLOR")}:
            </CustomText>
            <CustomText as="span" fontSizePx={13} font="font-bold" marginBottomPx={8}>
              {currentOrderItem?.sku?.frontColorDescription?.values}
            </CustomText>
          </Box>
          <Box>
            <TitleContainer>
              <CustomText as="span" fontSizePx={13} font="font-bold" marginBottomPx={8}>
                {translateLabel("RX_CART_DETAILS_LENS")}
              </CustomText>
            </TitleContainer>

            <CustomText as="span" fontSizePx={13} font="font-regular" marginBottomPx={8}>
              {translateLabel("RX_CART_DETAILS_LENS_CODE")}:
            </CustomText>
            <CustomText as="span" fontSizePx={13} font="font-bold" marginBottomPx={8}>
              {currentOrderItem?.lensId ?? ""}
            </CustomText>
            <CustomText as="span" fontSizePx={13} font="font-regular" marginBottomPx={8}>
              {translateLabel("RX_CART_DETAILS_DESC")}:
            </CustomText>
            <CustomText as="span" fontSizePx={13} font="font-bold" marginBottomPx={8}>
              {currentOrderItem?.lensDescription ?? ""}
            </CustomText>
          </Box>
          <Box>
            <TitleContainer>
              <CustomText as="span" fontSizePx={13} font="font-bold" marginBottomPx={8}>
                {translateLabel("RX_CART_DETAILS_CUST_ORDER_REF")}
              </CustomText>
            </TitleContainer>
            <CustomText as="span" fontSizePx={13} font="font-regular" marginBottomPx={8}>
              {translateLabel("RX_CART_DETAILS_ORDER_REF")}:
            </CustomText>
            <CustomText as="span" fontSizePx={13} font="font-bold" marginBottomPx={8}>
              {currentOrderItem?.rxcustomerReference ?? ""}
            </CustomText>
            <CustomText as="span" fontSizePx={13} font="font-regular" marginBottomPx={8}>
              {translateLabel("RX_CART_DETAILS_KIND_MAN")}:
            </CustomText>
            <CustomText as="span" fontSizePx={13} font="font-bold" marginBottomPx={8}>
              {translateLabel(getJobTypeLabel[currentOrderItem?.xitemField1 ?? ""]) ?? ""}
            </CustomText>
          </Box>
        </RelatedSection>
      </Container>

      {rxPrescriptionData && (
        <Container>
          <CustomText as="span" fontSizePx={18} font="font-bold" marginBottomPx={18}>
            {translateLabel("RX_CART_PRESCRIPTION")}
          </CustomText>
          <PrescriptionTable prescriptionDetails={handlePrescriptionData()} readOnly isAdditional />
        </Container>
      )}

      {showBrasilPrescription && rxBrasilStatus && !outOfStock && (
        <Container>
          <CustomText as="span" fontSizePx={18} font="font-bold" marginBottomPx={18}>
            {translateLabel("RX_PRESCR_BRASIL_TITLE")}
          </CustomText>
          <RxPrescriptionBrasil
            orderItemId={currentOrderItem?.orderItemId}
            prescriptionStatus={rxBrasilStatus}
          />
        </Container>
      )}
    </>
  );
};

const RelatedSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 0.5rem;

  @media ${(props) => props.theme.queries.md} {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1rem;
  }
`;

const Container = styled.div`
  padding: 1.875rem;
  border-top: 1px solid ${(props) => props.theme.palette.gray.light};

  &.out-of-stock {
    border-top: 2px solid ${(props) => props.theme.palette.gray.medium};
  }
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 1fr;

  grid-column-gap: 0.5rem;
  align-items: start;
`;

const TitleContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;

export default CartTileRxDetails;
