import React from "react";
import styled from "styled-components/macro";
import WrapperLayout from "../../layouts/WrapperLayout";

interface Props {
  firstColumn: JSX.Element;
  secondColumn: JSX.Element;
  thirdColumn: JSX.Element;
  layout?: "33+33+33" | "25+25+50" | "50+25+25";
  marginPx?: number | null;
  landingNavbar?: boolean;
  isWrappedLanding?: boolean;
}
const grid33 = "repeat(3, calc(33.33% - 0.625rem))";
const grid2550 = "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 2fr)";
const grid5025 = "minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr)";

const ThreeColumnRow = ({
  firstColumn,
  secondColumn,
  thirdColumn,
  layout,
  marginPx,
  landingNavbar = false,
  isWrappedLanding = false,
}: Props): JSX.Element => {
  return (
    <WrapperLayout landingNavbar={landingNavbar} isWrappedLanding={isWrappedLanding}>
      <ThreeColumn layout={layout} marginPx={marginPx}>
        <div>{firstColumn}</div>
        <div>{secondColumn}</div>
        <div>{thirdColumn}</div>
      </ThreeColumn>
    </WrapperLayout>
  );
};

const ThreeColumn = styled.div<{
  layout?: "33+33+33" | "25+25+50" | "50+25+25";
  marginPx?: number | null;
}>`
  display: grid;
  grid-template-columns: ${(props) => {
    switch (props.layout) {
      case "33+33+33":
        return grid33;
      case "25+25+50":
        return grid2550;
      case "50+25+25":
        return grid5025;
      default:
        return grid33;
    }
  }};
  grid-column-gap: 0.938rem;

  margin-bottom: 1.5625rem;
  margin-top: 1.5625rem;

  //override from props
  margin-top: ${(props) => props.marginPx && `${props.marginPx / props.theme.baseFontSize}rem`};
  margin-bottom: ${(props) => props.marginPx && `${props.marginPx / props.theme.baseFontSize}rem`};
`;

export default ThreeColumnRow;
