import {
  PaymentErrorConfig,
  PaymentErrorConfigLocation,
} from "../../../../store/payment/paymentInterface";

export const DEFAULT_PAYMENT_ERROR: PaymentErrorConfig = {
  titleLabelKey: "PAYMENT_ERROR_GENERIC_ERROR", // Generic Error
  status: "error",
  stopExecution: true,
};

export const PAYMENT_ERRORS_CONFIG: { [error_code: string]: PaymentErrorConfigLocation } = {
  GENERIC_ERROR: {
    banner: {
      titleLabelKey: "PAYMENT_ERROR_GENERIC_ERROR", // Generic Error
      status: "error",
      stopExecution: true,
    }, // getPaymentHistory, getPaymentHistoryDetail, getPaymentDocumentStatus, GetPaymentInfoStatement

    simulation: {
      contentLabelKey: "PAYMENT_ERROR_GENERIC_ERROR", // Generic Error
      status: "error",
      stopExecution: true, // don't show summary next to methods, only error message
    }, // getPaymentSimulation

    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_GENERIC_ERROR", // Generic Error
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay

    paymentMethod: {
      status: "alert",
      stopExecution: false, // don't do anything
    }, // getCardDetails, getBankDetails

    paymentMethodEdit: {
      contentLabelKey: "PAYMENT_ERROR_GENERIC_ERROR", // Generic Error
      status: "error",
      stopExecution: true, // show error in edit popup
    }, // getCardInfo, getBankInfo

    paymentMethodSave: {
      contentLabelKey: "PAYMENT_ERROR_GENERIC_ERROR", // Generic Error
      status: "error",
      stopExecution: true, // show error besides confirm button in add/edit popup
    }, // addCardDetails, addBankDetails, editCardDetails, editBankDetails

    paymentMethodDelete: {
      contentLabelKey: "PAYMENT_ERROR_GENERIC_ERROR", // Generic Error
      status: "error",
      stopExecution: true, // show error in delete popup
    }, // deleteCardDetails, deleteBankDetails

    paymentMethodPreferred: {
      contentLabelKey: "PAYMENT_ERROR_GENERIC_ERROR", // Generic Error
      status: "error",
      stopExecution: false, // show error next to add button?
    }, // preferredCard, preferredBank
  },

  PARAMETER_NOT_FOUND: {
    banner: {
      contentLabelKey: "PAYMENT_ERROR_PARAMETER_NOT_FOUND", // Generic Error
      status: "error",
      stopExecution: true, // don't call sap? i'm pretty sure this error won't happen in the to-be
    }, // getPaymentHistory, getPaymentHistoryDetail, getPaymentDocumentStatus

    simulation: {
      contentLabelKey: "PAYMENT_ERROR_PARAMETER_NOT_FOUND", // Unexpected error
      status: "error",
      stopExecution: true, // don't show summary next to methods, only error message
    }, // getPaymentSimulation

    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_PARAMETER_NOT_FOUND", // Unexpected error
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay

    paymentMethod: {
      status: "alert",
      stopExecution: false, // don't do anything
    }, // getCardDetails, getBankDetails

    paymentMethodEdit: {
      contentLabelKey: "PAYMENT_ERROR_PARAMETER_NOT_FOUND", // Unexpected error
      status: "error",
      stopExecution: true, // show error in edit popup
    }, // getCardInfo, getBankInfo

    paymentMethodSave: {
      contentLabelKey: "PAYMENT_ERROR_PARAMETER_NOT_FOUND", // Unexpected error
      status: "error",
      stopExecution: true, // show error besides confirm button in add/edit popup
    }, // addCardDetails, addBankDetails, editCardDetails, editBankDetails

    paymentMethodDelete: {
      contentLabelKey: "PAYMENT_ERROR_PARAMETER_NOT_FOUND", // Unexpected error
      status: "error",
      stopExecution: true, // show error in delete popup
    }, // deleteCardDetails, deleteBankDetails

    paymentMethodPreferred: {
      contentLabelKey: "PAYMENT_ERROR_PARAMETER_NOT_FOUND", // Unexpected error
      status: "error",
      stopExecution: false, // show error next to add button?
    }, // preferredCard, preferredBank
  },

  IN_LOCK_PAYMENT_TABLE: {
    banner: {
      titleLabelKey: "PAYMENT_ERROR_TITLE_PAYMENT_HAS_SAP_ERRORS", // Sorry for the inconvenient
      subtitleLabelKey: "PAYMENT_ERROR_SUBTITLE_PAYMENT_HAS_SAP_ERRORS", // Your last payment is still in progress, please try again later
      status: "error",
      stopExecution: true, // don't call sap? i'm pretty sure this error won't happen in the to-be
    }, // getPaymentHistory, getPaymentHistoryDetail, getPaymentDocumentStatus

    simulation: {
      titleLabelKey: "PAYMENT_ERROR_TITLE_PAYMENT_HAS_SAP_ERRORS", // Sorry for the inconvenient
      subtitleLabelKey: "PAYMENT_ERROR_SUBTITLE_PAYMENT_HAS_SAP_ERRORS", // Your last payment is still in progress, please try again later
      status: "error",
      stopExecution: true, // don't show summary next to methods, only error message
    }, // getPaymentSimulation

    confirmation: {
      titleLabelKey: "PAYMENT_ERROR_TITLE_PAYMENT_HAS_SAP_ERRORS", // Sorry for the inconvenient
      subtitleLabelKey: "PAYMENT_ERROR_SUBTITLE_PAYMENT_HAS_SAP_ERRORS", // Your last payment is still in progress, please try again later
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay

    paymentMethod: {
      titleLabelKey: "PAYMENT_ERROR_TITLE_PAYMENT_HAS_SAP_ERRORS", // Sorry for the inconvenient
      subtitleLabelKey: "PAYMENT_ERROR_SUBTITLE_PAYMENT_HAS_SAP_ERRORS", // Your last payment is still in progress, please try again later

      status: "alert",
      stopExecution: false, // don't do anything
    }, // getCardDetails, getBankDetails

    paymentMethodEdit: {
      titleLabelKey: "PAYMENT_ERROR_TITLE_PAYMENT_HAS_SAP_ERRORS", // Sorry for the inconvenient
      subtitleLabelKey: "PAYMENT_ERROR_SUBTITLE_PAYMENT_HAS_SAP_ERRORS", // Your last payment is still in progress, please try again later
      status: "error",
      stopExecution: true, // show error in edit popup
    }, // getCardInfo, getBankInfo

    paymentMethodSave: {
      titleLabelKey: "PAYMENT_ERROR_TITLE_PAYMENT_HAS_SAP_ERRORS", // Sorry for the inconvenient
      subtitleLabelKey: "PAYMENT_ERROR_SUBTITLE_PAYMENT_HAS_SAP_ERRORS", // Your last payment is still in progress, please try again later
      status: "error",
      stopExecution: true, // show error besides confirm button in add/edit popup
    }, // addCardDetails, addBankDetails, editCardDetails, editBankDetails

    paymentMethodDelete: {
      titleLabelKey: "PAYMENT_ERROR_TITLE_PAYMENT_HAS_SAP_ERRORS", // Sorry for the inconvenient
      subtitleLabelKey: "PAYMENT_ERROR_SUBTITLE_PAYMENT_HAS_SAP_ERRORS", // Your last payment is still in progress, please try again later
      status: "error",
      stopExecution: true, // show error in delete popup
    }, // deleteCardDetails, deleteBankDetails

    paymentMethodPreferred: {
      titleLabelKey: "PAYMENT_ERROR_TITLE_PAYMENT_HAS_SAP_ERRORS", // Sorry for the inconvenient
      subtitleLabelKey: "PAYMENT_ERROR_SUBTITLE_PAYMENT_HAS_SAP_ERRORS", // Your last payment is still in progress, please try again later
      status: "error",
      stopExecution: false, // show error next to add button?
    }, // preferredCard, preferredBank
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////// LOCATION: BANNER /////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  SAP_GENERIC_ERROR: {
    banner: {
      titleLabelKey: "PAYMENT_ERROR_TITLE_SAP_GENERIC_ERROR", // Generic error in communication with application
      subtitleLabelKey: "PAYMENT_ERROR_SUBTITLE_SAP_GENERIC_ERROR", // Please call the contact center
      status: "error",
      stopExecution: true, // only show banner
    }, // getPayment (allInfo)
  },

  PAYMENT_HAS_SAP_ERRORS: {
    banner: {
      titleLabelKey: "PAYMENT_ERROR_TITLE_PAYMENT_HAS_SAP_ERRORS", // Sorry for the inconvenient
      subtitleLabelKey: "PAYMENT_ERROR_SUBTITLE_PAYMENT_HAS_SAP_ERRORS", // Your last payment is still in progress, please try again later
      status: "error",
      stopExecution: true, // only show banner
    }, // getPayment (allInfo)
  },

  PAYMENT_TO_BE_RESENT_GET_PAYMENT: {
    banner: {
      titleLabelKey: "PAYMENT_ERROR_TITLE_PAYMENT_TO_BE_RESENT_GET_PAYMENT", // Sorry for the inconvenient
      subtitleLabelKey: "PAYMENT_ERROR_SUBTITLE_PAYMENT_TO_BE_RESENT_GET_PAYMENT", // Your last payment is still in progress, please try again later
      status: "error",
      stopExecution: true, // only show banner
    }, // getPayment (allInfo)
  },

  PAYMENT_TO_BE_RESENT_PAY_HISTORY: {
    banner: {
      titleLabelKey: "PAYMENT_ERROR_TITLE_PAYMENT_TO_BE_RESENT_PAY_HISTORY", // Sorry for the inconvenient
      subtitleLabelKey: "PAYMENT_ERROR_SUBTITLE_PAYMENT_TO_BE_RESENT_PAY_HISTORY", // Your last payment is still in progress, please try again later
      status: "alert",
      stopExecution: false, // show content together with banner
    }, // getPaymentHistory
  },

  PAYMENT_TO_BE_RESENT_DOC_HISTORY: {
    banner: {
      titleLabelKey: "PAYMENT_ERROR_TITLE_PAYMENT_TO_BE_RESENT_DOC_HISTORY", // Sorry for the inconvenient
      subtitleLabelKey: "PAYMENT_ERROR_SUBTITLE_PAYMENT_TO_BE_RESENT_DOC_HISTORY", // Your last payment is still in progress, please try again later
      status: "alert",
      stopExecution: false, // show content together with banner
    }, // getPaymentDocumentStatus
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////// LOCATION: SIMULATION & CONFIRMATION ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  PAYMENT_EXECUTION_DISABLED: {
    banner: {
      contentLabelKey: "PAYMENT_ERROR_PAYMENT_EXECUTION_DISABLED", // Payment execution disabled for country, try later.
      status: "alert",
      stopExecution: false,
      enforcedPrivilege: "PAYMENT_VIEW_ONLY",
    },
    simulation: {
      contentLabelKey: "PAYMENT_ERROR_PAYMENT_EXECUTION_DISABLED", // Payment execution disabled for country, try later.
      status: "error",
      stopExecution: true, // don't show summary next to methods, only error message
    }, // getPaymentSimulation

    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_PAYMENT_EXECUTION_DISABLED", // Payment execution disabled for country, try later.
      status: "alert",
      stopExecution: false, // go to TYP and show alert there
    }, // confirmAndPay
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////// LOCATION: SIMULATION ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  PAYMENT_AMOUNT_NEGATIVE: {
    simulation: {
      contentLabelKey: "PAYMENT_ERROR_PAYMENT_AMOUNT_NEGATIVE", // Negative amount, impossible to proceed
      status: "error",
      stopExecution: true, // don't show summary next to methods, only error message
    }, // getPaymentSimulation
  },

  NO_PAYMENT_METHOD_SELECTED: {
    simulation: {
      contentLabelKey: "PAYMENT_ERROR_NO_PAYMENT_METHOD_SELECTED", // Please click on edit, select again a payment method and retry
      status: "error",
      stopExecution: true, // don't show summary next to methods, only error message
    }, // getPaymentSimulation
  },

  PAYMENT_LOCKED: {
    simulation: {
      contentLabelKey: "PAYMENT_ERROR_PAYMENT_LOCKED", // Payment action is locked by other user: {USERNAME}
      status: "error",
      stopExecution: false, // show summary with error message and disabled confirmation button
      additionalInfo: "simulationLockInfo", // when displaying the error we also need the username of those locking
    }, // getPaymentSimulation
  },

  SAP_COMUNICATION_ERROR: {
    simulation: {
      contentLabelKey: "PAYMENT_ERROR_SAP_COMUNICATION_ERROR", // We are sorry, an error has occured in comunication with other services
      status: "error",
      stopExecution: true,
    },
  },

  SAP_MISSING_BANK_ACCOUNT: {
    simulation: {
      contentLabelKey: "PAYMENT_ERROR_SAP_MISSING_BANK_ACCOUNT", // Nella posizione 0000000002 manca l'indicazione di un conto
      status: "error",
      stopExecution: true,
    },
  },

  SAP_SINTAX_ERROR: {
    simulation: {
      contentLabelKey: "PAYMENT_ERROR_SAP_SINTAX_ERROR", // Errore di sintassi in sostituzione 'FI_0002', fase ''

      status: "error",
      stopExecution: true,
    },
  },

  SAP_KEY_REF_1_BAD_FIELD: {
    simulation: {
      contentLabelKey: "PAYMENT_ERROR_SAP_KEY_REF_1_BAD_FIELD", // Pos.000 valorizzare correttamente il campo "Chiave rif. 1"
      status: "error",
      stopExecution: true,
    },
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////// LOCATION: CONFIRMATION //////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  SIMULATION_NOT_VALID: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_SIMULATION_NOT_VALID", // Payment simulation not valid anymore
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  PAYMENT_PROVIDER_ERROR: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_PAYMENT_PROVIDER_ERROR", // Payment provider error
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  SAP_CONFIRMATION_ERROR: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_SAP_CONFIRMATION_ERROR", // Unexpected error
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  SUBSCRIPTION_NOT_FOUND: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_SUBSCRIPTION_NOT_FOUND", // Subscription id not found
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  SIMULATION_NOT_FOUND: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_SIMULATION_NOT_FOUND", // Simulation id not found
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  SAP_COMMUNICATION_ERROR: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_SAP_COMMUNICATION_ERROR", // Error in communication with external service
      status: "alert",
      stopExecution: false, // go to TYP and show alert there
    }, // confirmAndPay
  },

  CANNOT_CREATE_PAYMENT: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_CANNOT_CREATE_PAYMENT", // Cannot create payment with specified confirmation id
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  CS_UNABLE_TO_PROCESS_REQUEST: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_CS_UNABLE_TO_PROCESS_REQUEST", // Unable to process you request, Please review your input
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  CS_CREDIT_CARD_DECLINED: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_CS_CREDIT_CARD_DECLINED", // Your credit card has declined
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  CS_UNABLE_TO_PROCESS_REQUEST_TRY_AGAIN: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_CS_UNABLE_TO_PROCESS_REQUEST_TRY_AGAIN", // Unable to process your request at this time, please try again later
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  CS_SYSTEM_ERROR: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_CS_SYSTEM_ERROR", // System error, to avoid duplication, please do not re-submit your request at this time and contact the Credit Department
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  CS_INCORRECT_BILLING_ADDRESS: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_CS_INCORRECT_BILLING_ADDRESS", // Incorrect billing address
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  CS_UNABLE_TO_PROCESS_CONTACT_ISSUING: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_CS_UNABLE_TO_PROCESS_CONTACT_ISSUING", // Unable to process you request, please call the issuing bank for further information
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  CS_INCORRECT_SECURITY_CODE: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_CS_INCORRECT_SECURITY_CODE", // Incorrect security code, please re-enter security code
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  CS_INVALID_CARD_NUMBER: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_CS_INVALID_CARD_NUMBER", // Invalid card number, please re-enter
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  CS_CARD_TYPE_NOT_ACCEPTED: {
    confirmation: {
      contentLabelKey: "PAYMENT_ERROR_CS_CARD_TYPE_NOT_ACCEPTED", // Credit Card Type not accepted at this time
      status: "error",
      stopExecution: true, // don't go to TYP, show error in recap
    }, // confirmAndPay
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// LOCATION: PAYMENT-METHOD-EDIT //////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ERROR_RETRIEVE_CREDIT_CARD: {
    paymentMethodEdit: {
      contentLabelKey: "PAYMENT_ERROR_ERROR_RETRIEVE_CREDIT_CARD", // Cannot retrieve credit card
      status: "error",
      stopExecution: true, // show error in edit popup
    }, // getCardInfo
  },

  ERROR_RETRIEVE_BANK_CARD: {
    paymentMethodEdit: {
      contentLabelKey: "PAYMENT_ERROR_ERROR_RETRIEVE_BANK_CARD", // Cannot retrieve bank account
      status: "error",
      stopExecution: true, // show error in edit popup
    }, // getBankInfo
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// LOCATION: PAYMENT-METHOD-SAVE //////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  CANNOT_SUBSCRIBE_CARD: {
    paymentMethodSave: {
      contentLabelKey: "PAYMENT_ERROR_CANNOT_SUBSCRIBE_CARD", // Cannot subscribe card
      status: "error",
      stopExecution: true, // show error besides confirm button in add/edit popup
    }, // addCardDetails
  },

  CANNOT_SUBSCRIBE_BANK_ACCOUNT: {
    paymentMethodSave: {
      contentLabelKey: "PAYMENT_ERROR_CANNOT_SUBSCRIBE_BANK_ACCOUNT", // Cannot subscribe bank account
      status: "error",
      stopExecution: true, // show error besides confirm button in add/edit popup
    }, // addBankDetails
  },

  CANNOT_MODIFY_CARD: {
    paymentMethodSave: {
      contentLabelKey: "PAYMENT_ERROR_CANNOT_MODIFY_CARD", // Cannot modify card
      status: "error",
      stopExecution: true, // show error besides confirm button in add/edit popup
    }, // editCardDetails
  },

  CANNOT_MODIFY_BANK_ACCOUNT: {
    paymentMethodSave: {
      contentLabelKey: "PAYMENT_ERROR_CANNOT_MODIFY_BANK_ACCOUNT", // Cannot modify bank account
      status: "error",
      stopExecution: true, // show error besides confirm button in add/edit popup
    }, // editCardDetails
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////// LOCATION: PAYMENT-METHOD-DELETE /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  CANNOT_DELETE_CARD: {
    paymentMethodDelete: {
      contentLabelKey: "PAYMENT_ERROR_CANNOT_DELETE_CARD", // Cannot delete card
      status: "error",
      stopExecution: true, // show error in delete popup
    }, // deleteCardDetails
  },

  CANNOT_DELETE_BANK_ACCOUNT: {
    paymentMethodDelete: {
      contentLabelKey: "PAYMENT_ERROR_CANNOT_DELETE_BANK_ACCOUNT", // Cannot delete bank account
      status: "error",
      stopExecution: true, // show error in delete popup
    }, // deleteBankDetails
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////// LOCATION: PAYMENT-METHOD-PREFERRED ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  CANNOT_SET_PREFERRED_CARD: {
    paymentMethodPreferred: {
      contentLabelKey: "PAYMENT_ERROR_CANNOT_SET_PREFERRED_CARD", // Cannot set preferred card
      status: "error",
      stopExecution: false, // show error next to add button?
    }, // preferredCard

    paymentMethodSave: {
      contentLabelKey: "PAYMENT_ERROR_CANNOT_SET_PREFERRED_CARD", // Cannot set preferred card
      status: "error",
      stopExecution: true, // show error besides confirm button in add/edit popup
    }, // addCardDetails
  },

  CANNOT_SET_PREFERRED_BANK_ACCOUNT: {
    paymentMethodPreferred: {
      contentLabelKey: "PAYMENT_ERROR_CANNOT_SET_PREFERRED_BANK_ACCOUNT", // Cannot set bank account
      status: "error",
      stopExecution: false, // show error next to add button?
    }, // preferredBank

    paymentMethodSave: {
      contentLabelKey: "PAYMENT_ERROR_CANNOT_SET_PREFERRED_BANK_ACCOUNT", // Cannot set bank account
      status: "error",
      stopExecution: true, // show error besides confirm button in add/edit popup
    }, // addBankDetails
  },
};
