import { Config, RequestStatus } from "../interfaces/mainInterfaces";
import { Labels } from "./labelInterfaces";
import IntlMessageFormat from "intl-messageformat";

export default function translateLabelFunc(
  labels: Labels,
  labelKey: string,
  status: RequestStatus,
  config?: Config
): string {
  const showKey =
    status === "ERROR" || status === "SUCCESS" || (labels && Object.keys(labels).length > 0);
  const fallback = showKey ? labelKey : "";

  //if plural config is present, handle plural
  if (config && labels?.[labelKey]) {
    const { plural } = config;

    //handle plural
    if (plural) {
      const placeholder = plural.placeholder;
      const locale = plural.locale;
      const message = new IntlMessageFormat(labels?.[labelKey], locale).format({
        [placeholder]: plural.counter,
      });

      return String(message);
    }
  }

  return labels?.[labelKey] || fallback;
}
