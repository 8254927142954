import React from "react";
import styled from "styled-components/macro";
import { useBarcode } from "react-barcodes";

interface Props {
  id: string;
  code?: "CODE128" | "CODE39";
}

export default function Barcode({ id, code = "CODE128" }: Props): JSX.Element {
  const { inputRef } = useBarcode({
    value: `${id}`,
    options: {
      format: code,
    },
  });

  return (
    <BarcodeContainer>
      <svg ref={inputRef} />
    </BarcodeContainer>
  );
}

const BarcodeContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  svg {
    width: 20rem;
    height: 9rem;
  }
`;
