import { cloneDeep } from "lodash";

import placeholderPortraitGray from "../assets/images/placeholders/placeholder_portrait_gray.svg";
import placeholderPortraitWhite from "../assets/images/placeholders/placeholder_portrait_white.svg";
import { AdvTile } from "../interfaces/cmsInterfaces";
import { ColorPalette, CustomFacet, FrameShape } from "../interfaces/customFiltersInterfaces";
import { FacetView, FacetViewMacroFamily } from "../interfaces/facetInterfaces";
import { Product, ProductCategory, Sku, Variant } from "../interfaces/productInterface";
import {
  Attachment,
  BestsellersCatalogue,
  DoorInfo,
  GetPricesResult,
  KeylookContents,
  PDPVariantDoorPayload,
  PDPVariantInfo,
  PDPVariantsInfo,
  PlpCatalogue,
  PlpCatalogueNoAdv,
  PlpStarsCatalogue,
  PlpVariants,
  PlpVariantsCatalogue,
  PrePlpCatalogue,
  TechnologyIcons,
  TechnologyIconsType,
  VideoAvailability,
} from "../store/catalogue/catalogueInterface";
import {
  instanceOfProduct,
  mapPriceObj,
  mapProductsAndAdvArray,
  mapProductsArray,
  mapSkusArray,
} from "./productUtils";

import placeholderLandscapeGray from "../assets/images/placeholders/placeholder_landscape_gray.svg";
import placeholderLandscapeWhite from "../assets/images/placeholders/placeholder_landscape_white.svg";

import placeholderSquareGray from "../assets/images/placeholders/placeholder_square_gray.svg";
import placeholderSquareWhite from "../assets/images/placeholders/placeholder_square_white.svg";

import placeholderMenuGray from "../assets/images/placeholders/placeholder_menu_gray.svg";
import placeholderMenuWhite from "../assets/images/placeholders/placeholder_menu_white.svg";

import placeholderGlassesGray from "../assets/images/placeholders/placeholder_occhiali_gray.svg";
import placeholderGlassesWhite from "../assets/images/placeholders/placeholder_occhiali_white.svg";

import { RequestStatus } from "../interfaces/mainInterfaces";
import { Door } from "../store/user/userInterfaces";

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////// UTILS FOR PDP PAGE ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

// export const getInfoFromPictures = (
//   id: string,
//   pictures: MultiMediaType[]
// ): MultiMediaType | undefined => {
//   const techInfoElement = pictures.find((element) => element.id === id);
//   if (techInfoElement) return techInfoElement;
// };

export const mapTechIconsResponse = (techIconsResult: any): TechnologyIcons => {
  const techIconsTypes = techIconsResult.grid.rows.reduce((acc: TechnologyIcons, row: any) => {
    if (row.placements.length > 0) {
      const iconList = row.placements?.[0];
      if (iconList && iconList.items?.length > 0) {
        acc[iconList.name as TechnologyIconsType] = iconList.items;
      }
    }
    return acc;
  }, {});
  return techIconsTypes;
};

export const mapKeylookResponse = (keylookResult: any): KeylookContents => {
  const result = keylookResult.grid.rows.reduce((acc: any, row: any) => {
    if (row.placements.length > 0) {
      const item = row.placements?.[0];
      if (item && item.items?.length > 0) {
        acc[item?.name] = item.items;
      }
    }
    return acc;
  }, {});
  return result;
};

/**
 * Map id and url for SeeThemOn videos
 *
 * @param {any[]} videosArray
 * @return {*}  {VideoAvailability[]}
 */
export const mapVideoAvailabilityArray = (videosArray: any[]): VideoAvailability[] | null => {
  return (
    videosArray?.map(
      (video: any): VideoAvailability => {
        return {
          id: video.id,
          url: video.url,
        };
      }
    ) ?? null
  );
};
//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// UTILS FOR PRICES /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Map response from getPriceSaga
 *
 * @param {any[]} priceData
 * @return {*}  {GetPricesResult[]}
 */
export const mapPriceResponse = (priceData: any[]): GetPricesResult[] => {
  return priceData?.map((item) => {
    const [id, price] = Object.entries(item)?.[0];
    return { id: id, price: mapPriceObj(price) };
  });
};

/**
 * Add price to a Product object from an array of GetPricesResult[]
 *
 * @param {Product} product
 * @param {GetPricesResult[]} prices
 * @return {*}  {Product}
 */
export const addPriceToProductorVariant = (
  product: Product | Variant | Sku,
  prices: GetPricesResult[]
): Product | Variant | Sku => {
  product.price = prices?.find((_) => _.id === product?.uniqueID)?.price ?? product?.price;
  return product;
};

//////////////////////////////////////////////////////////////////////////////////
//////////////////////// UTILS FOR getPlpCatalogueSaga ///////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Map API results from getPlpCatalogueSaga into the PlpCatalogue object to be saved in slice
 *
 * @param {CMSContent} result
 * @return {*}  {PlpCatalogue}
 */
export const mapPlpCatalogue = (
  result: any
): { catalogue: PlpCatalogue; facetView: FacetView[] } => {
  const results = result?.resultList ? mapProductsAndAdvArray(result.resultList) : [];

  return {
    catalogue: {
      id: result?.id,
      resultList: results,
      resultsCount: result?.recordSetCount,
      resultsStartNumber: result?.recordSetStartNumber,
      resultsTotal: result?.recordSetTotal,
      totalProducts: result?.totalProducts,
    },
    facetView: result?.facetView,
  };
};

export const mapPrePlpCatalogue = (result: any): PrePlpCatalogue => {
  const results = result?.resultList ? mapProductsAndAdvArray(result.resultList) : [];

  return {
    id: result?.id,
    facetView: result?.facetView,
    resultList: results,
    resultsCount: result?.recordSetCount,
    resultsStartNumber: result?.recordSetStartNumber,
    resultsTotal: result?.recordSetTotal,
    totalProducts: result?.totalProducts,
  };
};

export const mapPlpStarsCatalogue = (
  result: any
): { catalogue: PlpStarsCatalogue; facetView: FacetView[] } => {
  const results = result?.catalogEntryView ? mapSkusArray(result?.catalogEntryView) : [];

  return {
    catalogue: {
      catalogEntryView: results,
      recordSetCount: result?.recordSetCount,
      recordSetStartNumber: result?.recordSetStartNumber,
      recordSetTotal: result?.recordSetTotal,
    },
    facetView: result?.facetView,
  };
};

/**
 * Map API results from getBestSellersSaga into the BestsellersCatalogue object to be saved in slice
 *
 * @param {*} result
 * @return {*}  {BestsellersCatalogue}
 */
export const mapBestsellersCatalogue = (result: any): BestsellersCatalogue => {
  const results = mapProductsArray(result?.catalogEntryView);

  return {
    resultList: results,
    resultsCount: result?.recordSetCount,
    resultsStartNumber: result?.recordSetStartNumber,
    resultsTotal: result?.recordSetTotal,
    totalProducts: result?.totalProducts,
  };
};

/**
 * Map API results from getPlpCatalogueSaga into the PlpCatalogue object to be saved in slice
 *
 * @param {CMSContent} result
 * @return {*}  {PlpCatalogue}
 */
export const mapPlpCatalogueNoAdv = (result: any): PlpCatalogueNoAdv => {
  const results = mapProductsArray(result?.catalogEntryView);

  return {
    id: result?.id,
    facetView: result?.facetView,
    resultList: results,
    resultsCount: result?.recordSetCount,
    resultsStartNumber: result?.recordSetStartNumber,
    resultsTotal: result?.recordSetTotal,
  };
};

/**
 * Add prices to a PlpCatalogue object
 *
 * @param {PlpCatalogue} catalogue
 * @param {GetPricesResult[]} prices
 * @return {*}  {PlpCatalogue}
 */
export const addPricesToPlpCatalogue = <T extends PlpCatalogue | PrePlpCatalogue>(
  catalogue: T,
  prices: GetPricesResult[]
): T => {
  const newResultList = cloneDeep(catalogue?.resultList);

  newResultList?.map((product: Product | AdvTile) => {
    if (instanceOfProduct(product)) {
      return addPriceToProductorVariant(product, prices);
    }

    return product;
  });

  return {
    ...catalogue,
    resultList: newResultList,
  };
};

/**
 * Add prices to a PlpVariants object
 *
 * @param {PlpVariants} catalogue
 * @param {GetPricesResult[]} prices
 * @return {*}  {PlpVariants}
 */
export const addPricesToPlpVariants = (
  catalogue: PlpVariants,
  prices: GetPricesResult[]
): PlpVariants => {
  const newResultList = cloneDeep(catalogue?.items);

  newResultList?.map((product: Variant) => {
    return addPriceToProductorVariant(product, prices);
  });

  return {
    ...catalogue,
    items: newResultList,
  };
};

export const addPricesToPlpVariantsCatalogue = (
  catalogue: PlpVariantsCatalogue,
  prices: GetPricesResult[]
): PlpVariantsCatalogue => {
  const newResultList = cloneDeep(catalogue?.resultList);
  newResultList?.map((variant: Variant) => {
    return addPriceToProductorVariant(variant, prices);
  });
  return {
    ...catalogue,
    resultList: newResultList,
  };
};

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// PDP UTILS ////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Map attachments
 *
 * @param {any[]} attachments
 * @return {*}  {Attachment[]}
 */
export const mapAttachments = (attachments: any[]): Attachment[] => {
  return attachments?.map((_) => {
    return {
      identifier: _?.identifier,
      usage: _?.usage,
      name: _?.name,
      sequence: _?.sequence && Number(_.sequence),
      url: _?.attachmentAssetPathRaw,
    };
  });
};

export const setupUpdatedPDPVariantInfo = (
  PDPVariantDoor: PDPVariantDoorPayload[],
  existingVariantsInfo: PDPVariantsInfo,
  fallbackDoor: Door
): {
  variantsNeedingData: PDPVariantDoorPayload[];
  uniqueIDsNeedingData: string[];
  variantsInfoResult: PDPVariantInfo[];
} => {
  ////////////////// set selectedDoor for each variant & retrieve list of variants requiring API calls for prices + stars (if stars multidoor)

  const variantsNeedingData: PDPVariantDoorPayload[] = []; // for api calls
  const uniqueIDsNeedingData: string[] = []; // to set loading status

  const variantsInfoResult: PDPVariantInfo[] = PDPVariantDoor.map((variant) => {
    // get PDPVariantInfo for each variant from redux, if available, or initialize empty one
    const newVariantInfo: PDPVariantInfo = existingVariantsInfo?.[variant.uniqueID]
      ? cloneDeep(existingVariantsInfo?.[variant.uniqueID])
      : { selectedDoor: variant.door ?? fallbackDoor, doorInfo: [], uniqueID: variant.uniqueID };

    // if a door is explicitely given as payload, set as selectedDoor
    // if it's not, either keep the existing one, if available, or set to fallback
    newVariantInfo.selectedDoor = variant.door ?? (newVariantInfo.selectedDoor || fallbackDoor);

    // find info regarding the new selected door
    const currentDoorInfo = newVariantInfo.doorInfo.filter(
      (_) => _.doorId === newVariantInfo?.selectedDoor?.orgentityId
    )?.[0];

    // if there's not, add current variant to the list of those requiring API calls
    if (!currentDoorInfo) {
      variantsNeedingData.push(variant);
      uniqueIDsNeedingData.push(variant.uniqueID);
    }

    return newVariantInfo;
  });

  return {
    variantsNeedingData,
    uniqueIDsNeedingData,
    variantsInfoResult,
  };
};

export const showSkusLoadingInPDP = (
  isStarsMultidoor: boolean,
  PDPVariantInfoLoading: RequestStatus | undefined,
  doorInfo: DoorInfo | undefined
): boolean => {
  const isStarsInfoMissing = isStarsMultidoor && doorInfo?.starsSkus === undefined;
  return PDPVariantInfoLoading === "LOADING" && (!doorInfo || isStarsInfoMissing);
};

/**
 * the logic is moved to backend
 * Orders images for main carousel in PDP based on their file name
 * eg: 0RB2140F__1016__STD__shad__qt.png"
 * last slice before . indicates which image type it is
 * qt -> quarter
 * lt -> lateral
 * bk -> back
 * fr -> front
 * cfr -> closed front
 *
 * @param {Attachment[]} images
 * @return {*}  {Attachment[]}
 */
// export const orderPDPImagesCarousel = (images: Attachment[]): Attachment[] => {
//   const getImageType = (fileName: string): string => {
//     return fileName.split(".")[0].split("__").slice(-1)[0];
//   };

//   function sortFunc(a: Attachment, b: Attachment) {
//     const sortingArr = ["qt", "lt", "bk", "fr", "cfr"];
//     return sortingArr.indexOf(getImageType(a.name)) - sortingArr.indexOf(getImageType(b.name));
//   }

//   return images.sort(sortFunc);
// };

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// PLP FILTER UTILS /////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Check if exists a filter that matches with at least one enumerative value and return true in positive case otherwise false
 *
 * @param {string} filterKey
 * @return {*}  {boolean}
 */

export const isCustomFilter = (filterKey: string): boolean => {
  return (Object.values(CustomFacet) as string[])?.includes(filterKey);
};

/**
 * Find the hexadecimal color code that matches with the given value otherwise the svg name for the shape filter
 *
 * @param {string} value
 * @return {*}  {string}
 */

export const getFilterValue = (value: string): any => {
  const upperValue = value.toUpperCase().replace(/\s+/g, "");
  return Object.entries({ ...ColorPalette, ...FrameShape }).find(
    ([key, val]) => key === upperValue
  )?.[1];
};

export const getLabelAvailability = (inventoryStatus: string): string => {
  switch (inventoryStatus) {
    case "PREORDER":
      return "AVAILABILITY_STATUS_COMING_SOON";
    case "AVAILABLE":
      return "AVAILABILITY_STATUS_AVAILABLE";
    case "LAST_PIECES":
      return "AVAILABILITY_STATUS_END_OF_LINE";
    case "BACKORDER":
    case "BACKORDER_FOLLOWING":
    case "BACKORDER_NOT_FOLLOWING":
      return "AVAILABILITY_STATUS_ON_BACKORDER";
    case "NOT_AVAILABLE":
      return "AVAILABILITY_STATUS_OUT_OF_STOCK";

    default:
      return "";
  }
};

export const getLabelAvailabilityShipping = (inventoryStatus: string): string => {
  switch (inventoryStatus) {
    case "PREORDER":
      return "AFA_AVAILABILITY_FROM";
    case "AVAILABLE":
      return "AFA_SHIPPING_MESSAGE_AVAILABLE";
    default:
      return "";
  }
};
///////////////////// UTILITY STARS
const MACROFAMILIES_ORDERED_CONFIGURATION = [
  "BEST STARS",
  "NEWNESS",
  "FOR YOU",
  "SPORT",
  "LIFESTYLE",
  "BRAND LOVER",
  "XXL",
  "VIEW ALL",
];

export const sortMacroFamilies = <T extends FacetView[] | FacetViewMacroFamily[]>(
  macrofamilyFilters: T
): T => {
  const orderedFilters = macrofamilyFilters;
  const orderedMacroFamilies = [];

  if (orderedFilters.length > 0) {
    MACROFAMILIES_ORDERED_CONFIGURATION.forEach((_) => {
      const indexOf = orderedFilters[0].entry?.findIndex((fam) => fam.identifier === _);
      if (indexOf !== -1) {
        const entryArray = [...orderedFilters[0].entry];
        const newElement = entryArray.splice(indexOf, 1);
        newElement !== undefined && orderedMacroFamilies.push(newElement[0]);
        orderedFilters[0] = { ...orderedFilters[0], entry: entryArray };
      }
    });
    if (orderedFilters[0].entry.length > 0) {
      orderedMacroFamilies.push(...orderedFilters[0].entry);
    }
    orderedFilters[0] = { ...orderedFilters[0], entry: orderedMacroFamilies };
  }

  return orderedFilters;
};

const STATISTICS_VALUE = {
  SUNGLASSES_STATS: [
    "MARKETING_THEME",
    "FRAME_MATERIAL_FACET",
    "GENDER",
    "FRAME_SHAPE_FACET",
    "POLARIZED",
  ],
  OPTICAL_STATS: [
    "MARKETING_THEME",
    "FRAME_MATERIAL_FACET",
    "GENDER",
    "FRAME_SHAPE_FACET",
    "POLARIZED",
  ],
  GOGGLES_STATS: ["PRIZM", "MIRROR"],
};

export const STATISTICS_MAP = {
  SUNGLASSES: STATISTICS_VALUE.SUNGLASSES_STATS,
  SUNGLASSES_KIDS: STATISTICS_VALUE.SUNGLASSES_STATS,
  EYEGLASSES: STATISTICS_VALUE.OPTICAL_STATS,
  EYEGLASSES_KIDS: STATISTICS_VALUE.OPTICAL_STATS,
  GOGGLES: STATISTICS_VALUE.GOGGLES_STATS,
};

export const PREPLP_STARS_CONFIG = {
  "pre-plp-stars-latest-introduction": {
    filterPropertyValue: "STARS_ASSORTMENT",
    identifier: "LATEST_INTRODUCTION",
    values: ["Latest introduction"],
    title: "LATEST_INTRODUCTION",
    subtitle: "LATEST_INTRODUCTION_SUBTITLE",
    analyticsTag: "Categories_Latest_",
  },
  "pre-plp-stars-current-assortment": {
    filterPropertyValue: "STARS_ASSORTMENT",
    identifier: "CURRENT_ASSORTMENT",
    values: ["Current assortment", "Latest introduction"],
    title: "CURRENT_ASSORTMENT",
    subtitle: "CURRENT_ASSORTMENT_SUBTITLE",
    analyticsTag: "Categories_",
  },
};

export const getProductCategory = (list: (Product | AdvTile)[]): ProductCategory[] | null => {
  const prodCatList: ProductCategory[] = [];
  for (let i = 0; i < list.length; i++) {
    const product = list[i];
    if (instanceOfProduct(product) && product.productCategory) {
      prodCatList.push(product.productCategory as ProductCategory);
    }
  }
  return prodCatList.length > 0 ? prodCatList : null;
};

export const getPlaceholderImage = (
  type: "square" | "portrait" | "landscape" | "portrait-narrow" | "menu" | "glasses" | "afa",
  color: "gray" | "white"
): string => {
  let placeholder = "";

  switch (type) {
    case "square":
      placeholder = color === "gray" ? placeholderSquareGray : placeholderSquareWhite;
      break;
    case "portrait":
      placeholder = color === "gray" ? placeholderPortraitGray : placeholderPortraitWhite;
      break;
    case "landscape":
      placeholder = color === "gray" ? placeholderLandscapeGray : placeholderLandscapeWhite;
      break;
    case "menu":
      placeholder = color === "gray" ? placeholderMenuGray : placeholderMenuWhite;
      break;
    case "glasses":
      placeholder = color === "gray" ? placeholderGlassesGray : placeholderGlassesWhite;
      break;
    case "afa":
      placeholder = color === "gray" ? placeholderPortraitGray : placeholderPortraitWhite;
      break;
    default:
      placeholder = placeholderSquareGray;
      break;
  }
  return placeholder;
};

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// ADD TO PRECART //////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const getPrivilegesRequiredToAddToCart = (
  category: ProductCategory | undefined,
  isSubuser: boolean
): "ADD_TO_CART_AFA" | "ACCESSORIES_ORDER_CREATION" | "ADD_TO_CART" => {
  if (category === "afa") return "ADD_TO_CART_AFA";
  if (category === "accessory" && isSubuser) return "ACCESSORIES_ORDER_CREATION";
  return "ADD_TO_CART";
};
