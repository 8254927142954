import { AftersalesType } from "./aftersalesInterface";

const casesIdFamilies: string[] = ["40", "58", "Y10"];

export const IsCasesByIdFamily = (idFamily: string): boolean => {
  return casesIdFamilies.includes(idFamily.toUpperCase());
};

export const getAftersalesType = (): AftersalesType | undefined => {
  return location.pathname.includes("warranty")
    ? "warranty"
    : location.pathname.includes("spare-parts")
    ? "spareparts"
    : undefined;
};
