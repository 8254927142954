import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { detectScrollDirection } from "../../utils/utils";
import CustomText from "../styled-UI/CustomText";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import useDataHref from "../../hooks/useDataHref";
import NavigationSingleTab from "./navigation-tab/NavigationSingleTab";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  headerTitle: string;
  data: any[];
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const SideAnchor = ({
  headerTitle,
  data,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const id = "lp-anchor-link";
  const [activeTab, setActiveTab] = useState(data[0]);
  const tabRef: any = useRef();

  //HEADER HEIGHT
  const bannerHeight = document.getElementById("banner")?.offsetHeight ?? 0;
  const smallerNavbarHeight = document.getElementById("header-navbar")?.offsetHeight ?? 47;
  const headerHeight = document.getElementById("header")?.offsetHeight ?? 0;
  const totalHeaderHeight = bannerHeight + smallerNavbarHeight + headerHeight + 10;

  let lastScrollTop = 0;

  useEffect(() => {
    const scrollContainer = document.getElementById("root");
    const archorLinkTab = document.getElementById(id);
    const archorLinkTabHeight = archorLinkTab?.offsetHeight ?? 0;

    const onScroll = (e?: any) => {
      const distanceFromTop = tabRef?.current.getBoundingClientRect().top; //distance from top window
      const distanceBottomFromTop = tabRef?.current.getBoundingClientRect().bottom;
      const lastPoint = distanceBottomFromTop - totalHeaderHeight - archorLinkTabHeight;
      const lastPointBottom = distanceBottomFromTop - archorLinkTabHeight;

      if (archorLinkTab && tabRef?.current) {
        const scrollInfo = detectScrollDirection(lastScrollTop);
        lastScrollTop = scrollInfo.newScrollTop;

        // console.log("lastPoint", lastPoint);
        // console.log("distanceFromTop", distanceFromTop);
        // console.log("totalHeaderHeight", totalHeaderHeight);

        if (scrollInfo.direction === "down") {
          const checkFinish = totalHeaderHeight;

          if (lastPointBottom < checkFinish) {
            // console.log("--STOP DOWN");
            archorLinkTab.style.position = "absolute";
            archorLinkTab.style.top = "auto";
            archorLinkTab.style.bottom = "10px";
            return;
          }

          if (distanceFromTop < checkFinish) {
            // console.log("--START DOWN");
            archorLinkTab.style.position = "fixed";
            archorLinkTab.style.top = totalHeaderHeight + "px";
            archorLinkTab.style.bottom = "auto";
            return;
          }
        }

        if (scrollInfo.direction === "up") {
          if (distanceFromTop > totalHeaderHeight) {
            // console.log("--STOP UP");
            archorLinkTab.style.position = "absolute";
            archorLinkTab.style.top = "0";
            archorLinkTab.style.bottom = "auto";
            return;
          }

          if (lastPoint > 0) {
            // console.log("--START UP");
            archorLinkTab.style.position = "fixed";
            archorLinkTab.style.top = totalHeaderHeight + "px";
            archorLinkTab.style.bottom = "auto";
            return;
          }
        }
      }
    };

    scrollContainer?.addEventListener("scroll", onScroll);
    return () => scrollContainer?.removeEventListener("scroll", onScroll);
  }, []);

  const renderParenthesisNumber = (currentTab: any): JSX.Element => {
    const descrWithHref = useDataHref({ content: currentTab.teaserText });
    return (
      <>
        {currentTab.teaserText && <div>{"("}</div>}
        <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? currentTab.teaserText} />
        {currentTab.teaserText && <div>{")"}</div>}
      </>
    );
  };

  const scrollToAnchor = (id: string): void => {
    const scrollingElement = document.getElementById(id);
    const scrolledElement = document.getElementById("root");

    if (scrollingElement && scrolledElement) {
      const y =
        scrollingElement.getBoundingClientRect().top +
        scrolledElement.scrollTop -
        totalHeaderHeight;
      scrolledElement.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <Column ref={tabRef}>
      <Container id={id}>
        <TitleContainer>
          <CustomText
            as="p"
            fontSizePx={28}
            font={customFont ? "custom-bold" : "font-bold"}
            fontFamily={customFont}
            color="primary"
            lineHeightPx={32}
          >
            {headerTitle}
          </CustomText>
        </TitleContainer>
        {data?.map((tabData, index) => {
          return (
            <Tab
              key={tabData?.id}
              className={activeTab?.id === tabData?.id ? "active" : ""}
              onClick={() => {
                setActiveTab(tabData);
                scrollToAnchor(tabData?.id + "_" + index);
              }}
            >
              <CustomText
                as="p"
                fontSizePx={13}
                font={customFont ? "custom-medium" : "font-medium"}
                fontFamily={customFont}
                color={activeTab?.id === tabData?.id ? "white" : "primary"}
              >
                {tabData?.title}
                <CategoryNumbers className={activeTab?.id === tabData?.id ? "active" : ""}>
                  {renderParenthesisNumber(tabData)}
                </CategoryNumbers>
              </CustomText>
            </Tab>
          );
        })}
      </Container>
      <ContentContainer>
        {data?.map((tabData, index) => {
          return (
            <AnchorContainer key={tabData?.id} id={tabData?.id + "_" + index}>
              <NavigationSingleTab
                layout={"vertical"}
                data={tabData?.grid}
                customFont={customFont}
                isDarkMode={isDarkMode}
                isWrappedLanding={true}
              ></NavigationSingleTab>
            </AnchorContainer>
          );
        })}
      </ContentContainer>
    </Column>
  );
};

const Column = styled.div`
  height: 100%;
  position: relative;
  padding: 1rem 0;
`;

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.white};
  box-shadow: 2px 2px 47px -10px rgba(0, 0, 0, 0.68);
  padding: 1.5rem 2rem 1.25rem;
  //width: 25%;
  //width: 20.5rem;
  z-index: 100;
`;

const ContentContainer = styled.div`
  height: 100%;
  width: 70%;
  position: relative;
  left: 27.5%;
`;

const AnchorContainer = styled.div``;

const TitleContainer = styled.div`
  padding-bottom: 0.625rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
  margin-bottom: 0.625rem;
`;

const Tab = styled.button`
  color: ${(props) => props.theme.palette.primary};
  font-family: ${(props) => props.theme.fonts.fontMedium};
  padding: 0.375rem 0.625rem;
  margin: 0.25rem 0;

  &.active {
    color: ${(props) => props.theme.palette.white};
    background-color: ${(props) => props.theme.palette.primary};
    border-radius: 3px;
  }
`;

const CategoryNumbers = styled.span`
  display: flex;
  flex-direction: row;
  margin-left: 0.25rem;
  color: ${(props) => props.theme.palette.gray.dark};

  &.active {
    color: ${(props) => props.theme.palette.white};
  }
`;

export default SideAnchor;
