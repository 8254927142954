import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCanRender from "../../hooks/useCanRender";
import useCarouselPagination from "../../hooks/useCarouselPagination";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

import { getBestSellers } from "../../store/catalogue/catalogueSaga";
import {
  selectBestSellerRequestStatus,
  selectBestSellers,
} from "../../store/catalogue/catalogueSlice";
import BestSellers from "./BestSellers";
import ConditionalRender from "./ConditionalRender";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  bestsellerHomepage?: boolean;
  hideDisplay?: boolean;
  enableDataDescriptionNavigation?: boolean;
  hideWrapper?: boolean;
  dataElementIdSlider?: string;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

export const BestSellersContainer = ({
  bestsellerHomepage = false,
  hideDisplay = false,
  enableDataDescriptionNavigation = false,
  hideWrapper = false,
  dataElementIdSlider,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const canRender = useCanRender();
  const dispatch = useDispatch();

  const bestSellers = useSelector(selectBestSellers);
  const requestStatus = useSelector(selectBestSellerRequestStatus);

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// PAGINATION ///////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const carouselPagination = useCarouselPagination({
    resultsTotal: bestSellers?.resultsTotal ?? 0,
    resultListLength: bestSellers?.resultList.length ?? 0,
    slidesPerCarousel: 3,
    maxSlides: 12,
    dispatchCallback: (newPage: number) => {
      dispatch(getBestSellers(newPage));
    },
  });

  //////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////// LAZY LOADING //////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const [isBestSellersLoaded, setIsBestSellersLoaded] = useState<boolean>(false);

  const { containerRef, isVisible: isBestSellersVisible } = useIntersectionObserver<HTMLDivElement>(
    {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    }
  );

  useEffect(() => {
    if (!isBestSellersLoaded && isBestSellersVisible) setIsBestSellersLoaded(true);
  }, [isBestSellersVisible]);

  useEffect(() => {
    if (
      canRender("CATALOGUE") &&
      !bestSellers?.resultList &&
      requestStatus === "IDLE" &&
      isBestSellersLoaded
    )
      dispatch(getBestSellers(carouselPagination.currentPage));
  }, [isBestSellersLoaded]);

  return (
    <ConditionalRender privilege="CATALOGUE">
      <div ref={containerRef}>
        <BestSellers
          bestSellers={bestSellers?.resultList ?? []}
          bestsellerHomepage={bestsellerHomepage}
          hideDisplay={hideDisplay}
          hideWrapper={hideWrapper}
          enableDataDescriptionNavigation={enableDataDescriptionNavigation}
          dataElementIdSlider={dataElementIdSlider}
          currentPage={carouselPagination.currentPage}
          totalSlides={carouselPagination.totalSlidesMaxed}
          getNextCarousel={carouselPagination.getNextCarousel}
          isDarkMode={isDarkMode}
          customFont={customFont}
        />
      </div>
    </ConditionalRender>
  );
};

export default BestSellersContainer;
