import React from "react";
import useTranslation from "../../hooks/useTranslation";
import styled from "styled-components/macro";
import CustomText from "../styled-UI/CustomText";
import LinkButton from "../styled-UI/LinkButton";
import { getPagePath } from "../../routing/routesUtils";
import { useHistory } from "react-router-dom";
import { News } from "../../interfaces/cmsInterfaces";
import HomepageNewsBox from "./HomepageNewsBox";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  news: News[];
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const HomepageNews = ({ news, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  const history = useHistory();
  const { translateLabel } = useTranslation();

  const renderNews = () => {
    if (news && news.length > 0) {
      return news.slice(0, 2).map((_: News, index: number) => {
        const numberTile = index + 1;

        return (
          <HomepageNewsBox
            numberTile={numberTile}
            news={_}
            key={_.id}
            isDarkMode={isDarkMode}
            customFont={customFont}
          />
        );
      });
    }
  };

  return news && news.length > 0 ? (
    <BoxNews data-element-id="News" isDarkMode={isDarkMode}>
      <InnerBox>
        <CustomText
          as="span"
          fontSizePx={32}
          font={customFont ? "custom-bold" : "font-bold"}
          fontFamily={customFont}
          color={isDarkMode ? "white" : "primary"}
        >
          {translateLabel("NEWS")}
        </CustomText>
        <LinkButton
          data-element-id="News_ViewAll"
          onClick={() => history.push(getPagePath("/news"))}
          uppercase
          underline
          color={isDarkMode ? "white" : "primary"}
          customFont={customFont}
        >
          {translateLabel("VIEW_ALL")}
        </LinkButton>
      </InnerBox>
      {renderNews()}
    </BoxNews>
  ) : (
    <></>
  );
};

export default HomepageNews;

export const MemoizedHomepageNews = React.memo(HomepageNews);

const BoxNews = styled.div<{ isDarkMode: boolean }>`
  width: 100%;
  height: 100%;
  padding: 2.5rem;
  background-color: ${(props) => (props.isDarkMode ? "" : "white")};
  display: flex;
  flex-direction: column;
`;

const InnerBox = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;
