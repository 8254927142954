import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import useTranslation from "../../../../../../hooks/useTranslation";
import { selectThirdLevelMenu } from "../../../../../../store/store/storeSlice";
import { SecondLevelMenu } from "../../../../../../interfaces/menuInterfaces";
import MenuColumns from "./MenuColumns";
import WrapperLayout from "../../../../WrapperLayout";
import CustomText from "../../../../../styled-UI/CustomText";
import LinkButton from "../../../../../styled-UI/LinkButton";
import CustomProgressiveImage from "../../../../../styled-UI/CustomProgressiveImage";
import LoaderMenu from "../../../../../styled-UI/loader/menu/LoaderMenu";
import { getPagePath } from "../../../../../../routing/routesUtils";
import { selectIsMultidoor } from "../../../../../../store/user/userSlice";
import LensesMenu from "./LensesMenu";
import clsx from "clsx";
import { getCompleteId } from "../../../../../../utils/menuUtils";
import useGetThirdLevelMenu from "../../../../../../hooks/useGetThirdLevelMenu";
import { resetScrollPosition } from "../../../../../../hooks/useSaveScrollPosition";
import InstrumentsMenu from "./InstrumentsMenu";

interface Props {
  menu: SecondLevelMenu;
  setSubmenuShown: (show: boolean) => void;
  setCurrent: (category: string) => void;
  current: string;
  setToggleMenu: (identifier: string) => void;
  marginTop: number;
  openProp?: boolean; //DEV ONLY: for styling purposes
}

const CategoryItemComponent = ({
  setSubmenuShown,
  openProp = false,
  current,
  setCurrent,
  menu,
  setToggleMenu,
  marginTop = 0,
}: Props): JSX.Element => {
  const { name, uriPicture, identifier, uniqueID } = menu;
  const thirdLevelMenu = useSelector(selectThirdLevelMenu);
  const isMultidoor = useSelector(selectIsMultidoor);
  const getThirdLevelMenu = useGetThirdLevelMenu();

  const { translateLabel } = useTranslation();
  const history = useHistory();
  const completeId = getCompleteId(uniqueID, identifier);
  const [menuShown, setMenuShown] = useState<boolean>(openProp);
  const currentMenu = thirdLevelMenu[completeId];
  const faded = completeId === current ? false : true;

  //BRANDS
  const [infoBrands, setInfoBrands] = useState<{
    hasStars: boolean;
    hasBrands: boolean;
  } | null>(null);

  useEffect(() => {
    if (currentMenu) {
      let hasStars = false;
      let hasBrands = false;

      currentMenu?.forEach((_) => {
        if (_.value === "manufacturer.raw") {
          _.entry?.forEach((e) => {
            if (e.isStars) {
              hasStars = true;
            } else {
              hasBrands = true;
            }
          });
        }
      });

      setInfoBrands({
        hasStars,
        hasBrands,
      });
    }
  }, [thirdLevelMenu[completeId]]);

  /* When hover on menu, call api to get menu info only the first time */
  const toggleMenu = () => {
    const open = current === completeId ? false : true;
    if (!openProp) {
      setMenuShown(open);
      setSubmenuShown(open);
    }
    if (open) {
      setCurrent(completeId);
    } else {
      setCurrent("");
    }

    //Get information for third level menu
    getThirdLevelMenu(open, menu);
  };

  const showViewAll =
    menu.completeUrl &&
    !(
      identifier.includes("GOGGLES_HELMETS") ||
      identifier.includes("AFA") ||
      identifier.includes("LENSES")
    ) &&
    currentMenu &&
    infoBrands !== null &&
    !infoBrands.hasStars;

  return (
    <CategoryItem
      onClick={(e) => {
        e.stopPropagation();
        toggleMenu();
      }}
      className={current !== "" && faded ? "item-faded" : "item-not-faded"}
      data-element-id={`MainNav_Products_${menu.identifier.replace(/ /g, "_")}`}
    >
      <CustomProgressiveImage type="menu" src={uriPicture ? uriPicture : ""} />
      <CustomText
        font="font-bold"
        fontSizePx={13}
        lineHeightPx={18}
        marginTopPx={12}
        as="span"
        uppercase
      >
        <CategoryContainer opened={current === completeId}>
          <a>
            {menu.completeUrl &&
            identifier.toLowerCase() !== "lenses" &&
            identifier.toLowerCase() !== "instruments"
              ? name
              : translateLabel(name.toUpperCase())}
          </a>
        </CategoryContainer>
      </CustomText>
      {menuShown && current === completeId && (
        <Submenu
          marginTop={marginTop}
          className={clsx(
            isMultidoor && "submenu-multidoor",
            infoBrands?.hasStars && infoBrands?.hasBrands && "submenu-gradient",
            infoBrands?.hasStars && !infoBrands?.hasBrands && "submenu-stars"
          )}
        >
          <WrapperLayout>
            <SubmenuMenu className="submenuMenu">
              <SubmenuContent>
                {identifier === "Lenses" || identifier === "LENSES" ? (
                  <LensesMenu menu={menu} setToggleMenu={setToggleMenu} />
                ) : identifier === "Instruments" || identifier === "INSTRUMENTS" ? (
                  <InstrumentsMenu menu={menu} setToggleMenu={setToggleMenu} />
                ) : currentMenu ? (
                  <MenuColumns menu={menu} toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
                ) : (
                  <LoaderMenu />
                )}
              </SubmenuContent>
              {showViewAll && (
                <ViewAllContainer>
                  <LinkButton
                    uppercase
                    underline={false}
                    onClick={() => {
                      toggleMenu();
                      setToggleMenu("");
                      resetScrollPosition();
                      history.push(getPagePath(menu.completeUrl));
                    }}
                    data-element-id={`MainNav_Menu_ViewAll`}
                  >
                    {translateLabel("VIEW_ALL")}
                  </LinkButton>
                </ViewAllContainer>
              )}
            </SubmenuMenu>
          </WrapperLayout>
        </Submenu>
      )}
    </CategoryItem>
  );
};

const CategoryItem = styled.button`
  height: 100%;
  color: ${(props) => props.theme.palette.primary};
  margin-right: 1rem;
  box-sizing: border-box;
  width: 13.125rem;

  &:last-child {
    margin-right: unset;
  }

  &.item-not-faded {
    opacity: unset;
  }

  &.item-faded {
    opacity: 0.65;
  }

  img {
    width: 100%;
    cursor: pointer;
  }
`;

const Submenu = styled.div<{ marginTop?: number }>`
  background-color: ${(props) => props.theme.palette.white};
  box-shadow: 0 -1px 0 0 #d8d8d8;
  position: fixed;
  left: 0;
  width: 100%;
  min-height: 19.875rem;
  top: ${(props) => props.marginTop + "rem"};

  &.submenu-multidoor {
    top: ${(props) => props.theme.multidoorBannerHeightRem + props.marginTop + "rem"};
  }

  &.submenu-stars {
    background-color: ${(props) => props.theme.palette.primary};
  }

  &.submenu-gradient {
    ${(props) =>
      `background: linear-gradient(90deg,  ${props.theme.palette.primary} 25%,  ${props.theme.palette.white} 25%)`}
  }

  /* @media ${(props) => props.theme.queries.md} {
    min-height: 15rem;
  } */
`;

const CategoryContainer = styled.div<{ opened: boolean }>`
  border-bottom: ${(props) => (props.opened ? "2px solid" + props.theme.palette.primary : "")};

  &:hover {
    a {
      border-bottom: ${(props) => (!props.opened ? "2px solid" + props.theme.palette.primary : "")};
    }
  }
`;

const SubmenuMenu = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 19.875rem;

  /* @media ${(props) => props.theme.queries.md} {
    min-height: 15rem;
  } */
`;

const SubmenuContent = styled.div`
  display: flex;
  /* padding: 1.875rem 0.5rem 0rem; */
  flex-grow: 1;
`;

const ViewAllContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;

  button {
    &:hover {
      border-bottom: 2px solid ${(props) => props.theme.palette.primary};
    }
    border-bottom: 2px solid transparent;
  }
`;

export default CategoryItemComponent;
