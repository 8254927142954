import React, { useState } from "react";
import ProgressiveImage from "react-progressive-image";
import styled from "styled-components/macro";
import { CSSProperties, keyframes } from "styled-components";
import clsx from "clsx";

// import placeholderPortraitNarrow from "../../assets/images/placeholder-portrait-narrow.svg";
// import placeholderPortrait from "../../assets/images/placeholder-portrait.svg";
// import placeholderLandscape from "../../assets/images/placeholder-landscape.svg";
// import placeholderSquare from "../../assets/images/placeholder-square.svg";
// import placeholderMenu from "../../assets/images/placeholder-menu.svg";
// import placeholderGlasses from "../../assets/images/placeholder-occhiali.svg";
import { getPlaceholderImage } from "../../utils/catalogueUtils";

interface Props {
  type: "square" | "portrait" | "landscape" | "portrait-narrow" | "menu" | "glasses" | "afa";
  color?: "gray" | "white";
  src: string;
  altText?: string;
  callback?: (src: string) => unknown;
  style?: CSSProperties;
  isHeight100?: boolean;
  isADTPreview?: boolean;
}

const CustomProgressiveImage = ({
  type,
  color = "gray",
  src,
  altText,
  callback,
  style,
  isHeight100 = false,
  isADTPreview = false,
}: Props): JSX.Element => {
  const [failedLoad, setFailedLoad] = useState(false);

  const placeholder = getPlaceholderImage(type, color);

  return (
    <ProgressiveImage
      src={src}
      onError={() => setFailedLoad(true)}
      placeholder={placeholder}
      // delay={6000}
    >
      {(src: string, loading: boolean) => {
        return (loading && !failedLoad) || isADTPreview ? (
          <LoadingOverlay className={clsx(isHeight100 && "loading-overlay-100")}>
            <img src={src} alt={altText} style={style} />
          </LoadingOverlay>
        ) : (
          <img src={src} alt={altText} style={style} />
        );
      }}
    </ProgressiveImage>
  );
};

const placeholderShimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
`;

export const LoadingOverlay = styled.div`
  position: relative;
  overflow: hidden;
  height: inherit;

  &.loading-overlay-100 {
    height: 100%;
  }

  &:before {
    top: 0;
    right: -100%;
    bottom: 0;
    left: -100%;
    content: "";
    position: absolute;

    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 33.3%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 66.6%
    );

    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeholderShimmer};
    animation-timing-function: linear;
  }
`;

export default CustomProgressiveImage;
