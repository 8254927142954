import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { selectTutorialPills } from "../../../../store/store/storeSlice";
import { useSelector } from "react-redux";
import { TutorialPill } from "../../../../store/catalogue/catalogueInterface";
import { ExternalReference } from "../../../../utils/cmsUtils";
import useTranslation from "../../../../hooks/useTranslation";

interface NewTag {
  position: ExternalReference;
  layout?: string;
}

const NewTag = ({ position, layout = "Tp-new-icon" }: NewTag): JSX.Element => {
  const tutorialPills = useSelector(selectTutorialPills);
  const [showTag, setShowTag] = useState<boolean>(false);
  const { translateLabel } = useTranslation();

  useEffect(() => {
    if (tutorialPills) {
      setShowTag(
        tutorialPills.some((pill: TutorialPill) => {
          return pill?.externalReference === position && pill?.layoutVariant === layout;
        })
      );
    }
  }, [tutorialPills]);

  return showTag ? (
    <StyledContainer>
      <TextContainer>{translateLabel("TAG_NEW")}</TextContainer>
    </StyledContainer>
  ) : (
    <></>
  );
};

const StyledContainer = styled.div`
  width: 44px;
  height: 21px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin: 0 14px 13px 10px;
  padding: 13px 8px;
  border-radius: 100px;
  background-color: #55a63a;
`;

const TextContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 28px;
  height: 16px;
  flex-grow: 0;
  font-family: Gilmer;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  text-align: left;
  color: #fff;
`;

export default NewTag;
