import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import useTranslation from "../../../hooks/useTranslation";
import { selectLensImg, selectMakeability, selectSelectedLens } from "../../../store/rx/rxSlice";
import BrandImage from "../../styled-UI/BrandImage";
import { CustomOptions } from "../../styled-UI/CustomSelect";
import CustomText from "../../styled-UI/CustomText";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info-icon.svg";

interface Props {
  brandGroup: CustomOptions | null;
  activeStep: number;
  brand?: string | number;
  getUpcDetail: () => {
    model: CustomOptions;
    color: CustomOptions;
    size: CustomOptions;
  };
  jobType?: string;
  jobTypeIdentifier?: string;
  focalType?: string;
  focalTypeTranslated?: string;
}

export default function ModelSelectionSummary({
  getUpcDetail,
  brandGroup,
  brand,
  jobType,
  focalType,
  focalTypeTranslated,
  activeStep,
  jobTypeIdentifier,
}: Props): JSX.Element {
  const { translateLabel } = useTranslation();

  const selectedLens = useSelector(selectSelectedLens);
  const lensImg = useSelector(selectLensImg);
  const makeability = useSelector(selectMakeability);

  const { model, color, size } = getUpcDetail();

  const isLensOnlyOrFrameToCame =
    jobTypeIdentifier === "RX_LENS_ONLY" || jobTypeIdentifier === "RX_FRAME_TO_COME";

  return (
    <ContentContainer>
      <div>
        <div className="d-flex">
          <div className="model-label">
            <div className="brandgroup-img-container">
              <BrandImage brandOrBrandGroup={brandGroup?.value as string} isBrandGroup />
            </div>
          </div>
          <div className="model-description">
            <CustomText as="h3" font="font-bold" fontSizePx={18}>
              {model?.label}
            </CustomText>
          </div>
        </div>
        {/* BRAND */}
        <div className="d-flex">
          <div className="model-label">
            <CustomText
              as="p"
              font="font-light"
              fontSizePx={13}
              lineHeightPx={18}
              marginTopPx={38}
              marginBottomPx={8}
            >
              {translateLabel("RX_BRAND")}:
            </CustomText>
          </div>
          <div className="model-description">
            <CustomText
              as="p"
              font="font-bold"
              fontSizePx={13}
              lineHeightPx={18}
              marginTopPx={38}
              marginBottomPx={8}
            >
              {brand}
            </CustomText>
          </div>
        </div>
        {/* JOB TYPE */}
        <div className="d-flex">
          <div className="model-label">
            <CustomText
              as="p"
              font="font-light"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={8}
            >
              {translateLabel("RX_JOB_TYPE")}:
            </CustomText>
          </div>
          <div className="model-description">
            <CustomText
              as="p"
              font="font-bold"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={8}
            >
              {jobType}
            </CustomText>
          </div>
        </div>
        {/* MODEL */}
        <div className="d-flex">
          <div className="model-label">
            <CustomText
              as="p"
              font="font-light"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={8}
            >
              {translateLabel("RX_MODEL")}:
            </CustomText>
          </div>
          <div className="model-description">
            <CustomText
              as="p"
              font="font-bold"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={8}
            >
              {model?.label}
            </CustomText>
          </div>
        </div>
        {/* COLOR */}
        <div className="d-flex">
          <div className="model-label">
            <CustomText
              as="p"
              font="font-light"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={8}
            >
              {translateLabel("RX_COLOR")}:
            </CustomText>
          </div>
          <div className="model-description">
            <CustomText
              as="p"
              font="font-bold"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={8}
            >
              {color?.label}
            </CustomText>
          </div>
        </div>
        {/* SIZE */}
        <div className="d-flex">
          <div className="model-label">
            <CustomText
              as="p"
              font="font-light"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={8}
            >
              {translateLabel("RX_SIZE")}:
            </CustomText>
          </div>
          <div className="model-description">
            <CustomText
              as="p"
              font="font-bold"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={8}
            >
              {size?.label}
            </CustomText>
          </div>
        </div>
        {/* FOCAL TYPE */}
        <div className="d-flex">
          <div className="model-label">
            <CustomText
              as="p"
              font="font-light"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={8}
            >
              {translateLabel("RX_FOCAL_TYPE")}:
            </CustomText>
          </div>
          <div className="model-description">
            <CustomText
              as="p"
              font="font-bold"
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={8}
            >
              {focalTypeTranslated ?? focalType}
            </CustomText>
          </div>
        </div>
        {activeStep > 2 && (
          <>
            {/* SELECTED LENS */}
            <div className="d-flex">
              <div className="model-label">
                <CustomText
                  as="p"
                  font="font-light"
                  fontSizePx={13}
                  lineHeightPx={18}
                  marginBottomPx={8}
                >
                  {translateLabel("RX_SELECT_LENS")}:
                </CustomText>
              </div>
              <div className="model-description">
                <CustomText
                  as="p"
                  font="font-bold"
                  fontSizePx={13}
                  lineHeightPx={18}
                  marginBottomPx={8}
                >
                  {selectedLens?.lensIdentifier}
                </CustomText>
              </div>
            </div>
            {/* LENS DESCR */}
            <div className="d-flex">
              <div className="model-label">
                <CustomText
                  as="p"
                  font="font-light"
                  fontSizePx={13}
                  lineHeightPx={18}
                  marginBottomPx={8}
                >
                  {translateLabel("RX_LENS_DESCTRIPTION")}:
                </CustomText>
              </div>
              <div className="model-description">
                <CustomText
                  as="p"
                  font="font-bold"
                  fontSizePx={13}
                  lineHeightPx={18}
                  marginBottomPx={8}
                >
                  {selectedLens?.lensDescription}
                </CustomText>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="model-img-container">
        <img src={lensImg} />
        {makeability?.isSuccess &&
          makeability?.isFrameInStock === false &&
          !isLensOnlyOrFrameToCame &&
          activeStep > 3 && (
            <div className="d-flex">
              <IconContainer>
                <InfoIcon />
              </IconContainer>
              <CustomText as="p" fontSizePx={11} color="red">
                {translateLabel("RX_NOT_AVAILABLE_ITEM")}
              </CustomText>
            </div>
          )}
      </div>
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
  .brandgroup-img-container {
    height: 3rem;
    width: 6rem;
    .brandgroup-img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  .model-label {
    width: 7rem;
  }
  .model-description {
    width: 26.5rem;
  }
  .model-img-container {
    height: 14.5rem;
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const IconContainer = styled.div`
  svg {
    fill: ${(props) => props.theme.palette.feedback.error};
    width: 2rem;
  }
`;
