import React from "react";
import styled from "styled-components/macro";
import { LpCta as Interface } from "../../interfaces/cmsInterfaces";
import { useHandleCmsLink } from "../../utils/cmsUtils";
import Button from "../styled-UI/Button";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  data: Interface;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}
const LpCta = ({ data, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  const { handleCmsLink } = useHandleCmsLink();
  const { linkString, linkLabel, externalLink, openInNewTab } = data;

  const handleRedirect = (): void => {
    handleCmsLink(linkString, externalLink, openInNewTab);
  };
  return (
    <OuterBox>
      {linkString && linkLabel && (
        <Button
          onClick={handleRedirect}
          type={isDarkMode ? "primary-dark" : "primary"}
          data-element-id="ButtonViewAll"
          customFont={customFont}
        >
          {linkLabel}
        </Button>
      )}
    </OuterBox>
  );
};

export default LpCta;

export const MemoizedLpCta = React.memo(LpCta);

const OuterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 60px auto;
`;
