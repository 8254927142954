import { BFFApiDomain } from "../serviceUtils";

// get details regarding the product selected for the warranty request
export const warrantyDetailsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/{identifier}/detail";

// provides digital warranty damages + submit digital warranty damages
export const warrantyDamagesUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/{product}/warranty/damages";

// submit note step
export const warrantyNotesStepUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/{warrantyIdentifier}/notes/step";

// submit warranty shipping details
export const warrantyShippingDetailsUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/{warrantyIdentifier}/shipping";

// get alternative model
export const warrantyAlternativeModelUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/alternative/models/search/{term}";

// get alternative variant
export const warrantyAlternativeVariantUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/alternative/variants/search/{term}";

// select alternative sku
export const warrantySelectAlternativeActionUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/alternative/action";

// get warranty recap
export const warrantyRecapUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/{warrantyIdentifier}/recap";

// send warranty to CRM
export const warrantyToCRMUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/{warrantyIdentifier}/crm";

// get model details + size guide for selected model alternative
export const warrantyModelDetailsUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/partnumbers/ids/{catentryId}";

// get step structure when calling UPLOAD_NEW_ATTACHMENT status action
export const warrantyInformationStepUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/{warrantyIdentifier}/information/step";

// submit step information when calling UPLOAD_NEW_ATTACHMENT status action
export const warrantySendInformationStepUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/information";

// get step structure when calling SELECT_AN_ALTERNATIVE_PRODUCT status action
export const warrantyAlternativeWizardUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/{warrantyIdentifier}/wizard";
