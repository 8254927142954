import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import clsx from "clsx";
import useTranslation from "../../hooks/useTranslation";
import useDisplayPrice from "../../hooks/useDisplayPrice";

import { selectCurrencyFormat } from "../../store/user/userSlice";
import { ItemPrice } from "../../interfaces/productInterface";
import CustomText from "../styled-UI/CustomText";
import Loader from "../styled-UI/Loader";
import CurrencyFormatter from "./CurrencyFormatter";
import Tooltip from "../styled-UI/Tooltip";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-icon-filled.svg";
import useCanRender from "../../hooks/useCanRender";

interface Props {
  price?: ItemPrice;
  isSpareParts: boolean;
  isLoading?: boolean;
  hideSuggested?: boolean;
  isRxItem?: boolean;
}

const PriceCartTile = ({
  price,
  isSpareParts = false,
  isLoading = false,
  hideSuggested: hideSuggestedProps,
  isRxItem,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const canRender = useCanRender();
  const { hideWholesale, hideSuggested, hideAll } = useDisplayPrice();
  const hideWhs = isRxItem ? !canRender("RX_MY_PRICE") : !canRender("WHOLESALE_PRICE");
  const hideSrp = isRxItem ? !canRender("RX_MY_PRICE_SRP") : !canRender("SUGGESTED_RETAIL_PRICE");

  const currencyFormat = useSelector(selectCurrencyFormat);

  if (isLoading)
    return (
      <BoxLoader>
        <Loader sizePx={30} />
      </BoxLoader>
    );
  return (
    <PriceContainer className={clsx(hideAll && "hide")}>
      {!hideWholesale && !hideWhs && !!price?.opt?.orderItemPrice && (
        <CustomText
          as="span"
          fontSizePx={18}
          font="font-bold"
          color="primary"
          marginTopPx={8}
          marginBottomPx={20}
        >
          <CurrencyFormatter
            currency={price?.opt?.currency || currencyFormat?.currency.opt}
            locale={currencyFormat?.locale}
            value={price?.opt?.orderItemPrice}
          />
        </CustomText>
      )}
      {!hideSuggestedProps &&
        !hideSrp &&
        !isSpareParts &&
        !hideSuggested &&
        !!price?.pub?.orderItemPrice && (
          <>
            <CustomText
              as="span"
              fontSizePx={11}
              font="font-regular"
              color="gray-dark"
              marginBottomPx={5}
            >
              {translateLabel("CART_ITEM_SRP")}
              <SvgContainer>
                <Tooltip
                  content={
                    <TooltipContent>{translateLabel("SRP_DISCLAIMER_DESCRIPTION")}</TooltipContent>
                  }
                >
                  <div className="svg-container">
                    <InfoIcon />
                  </div>
                </Tooltip>
              </SvgContainer>
            </CustomText>
            <CustomText as="span" fontSizePx={18} font="font-regular" color="primary">
              <CurrencyFormatter
                currency={
                  price?.pub?.currency ||
                  (isRxItem ? currencyFormat?.currency.opt : currencyFormat?.currency.pub)
                }
                locale={currencyFormat?.locale}
                value={price?.pub?.unitPrice}
              ></CurrencyFormatter>
            </CustomText>
          </>
        )}
    </PriceContainer>
  );
};

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 7.5rem;

  &.hide {
    visibility: hidden;
  }
`;

const SvgContainer = styled.span`
  .svg-container {
    position: relative;

    :hover > svg {
      opacity: 1;
    }

    > svg {
      margin: 0 0 0 0.275rem;
      vertical-align: text-top;
      opacity: 0.4;
      width: 14px !important;
      height: 14px !important;
    }
  }
`;

const TooltipContent = styled.div`
  max-width: 180px;
  font-family: ${(props) => props.theme.fonts.fontMedium};
  font-size: 0.813rem;
`;

const BoxLoader = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  min-width: 7.5rem;
`;

export default PriceCartTile;
