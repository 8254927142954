import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { SecondLevelMenu } from "../../../../../interfaces/menuInterfaces";
import { selectGetMenuStatus, selectMenu } from "../../../../../store/store/storeSlice";
import { LoaderMostUsed } from "../../../../styled-UI/loader/most-used/LoaderCategoriesAndServices";
import { ChipsContainer } from "../Services&MyAccount/ChipsServicesMyAccount";
import { ChipsHandler } from "../useSelectedMostUsed";
import MostUsedChipsCatalogue from "./MostUsedChipsCatalogue";

interface Props {
  chipsHandler: ChipsHandler;
  isMaxChipsReached: boolean;
  isLoading: boolean;
}

const MostUsedCatalogue = ({ chipsHandler, isMaxChipsReached, isLoading }: Props): JSX.Element => {
  const menuStatus = useSelector(selectGetMenuStatus);
  const showLoader = menuStatus === "LOADING" || isLoading;

  const menuData = useSelector(selectMenu);
  const [secondLevelMenus, setSecondLevelMenus] = useState<SecondLevelMenu[]>([]);

  useEffect(() => {
    if (menuData) {
      const secondLevelMenus = menuData?.find((_) => _.identifier === "PRODUCTS")?.catalogGroupView;
      secondLevelMenus && setSecondLevelMenus(secondLevelMenus);
    }
  }, [menuData]);

  if (!showLoader && secondLevelMenus.length === 0) return <></>;
  return (
    <ChipsCatalogueContainer>
      {showLoader ? (
        <ChipsContainer>
          <LoaderMostUsed />
        </ChipsContainer>
      ) : (
        secondLevelMenus?.map((selectedCategoryMenu: SecondLevelMenu, index: number) => (
          <MostUsedChipsCatalogue
            key={index}
            categoryMenu={selectedCategoryMenu}
            chipsHandler={chipsHandler}
            isMaxChipsReached={isMaxChipsReached}
          />
        ))
      )}
    </ChipsCatalogueContainer>
  );
};

const ChipsCatalogueContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;

export default MostUsedCatalogue;
