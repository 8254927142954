import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { HighlightSlide } from "../../../interfaces/cmsInterfaces";
import CustomProgressiveImage from "../../styled-UI/CustomProgressiveImage";
import Button from "../../styled-UI/Button";
import { getAnalyticsTagId, useHandleCmsLink } from "../../../utils/cmsUtils";
import useDataHref from "../../../hooks/useDataHref";
import CustomText from "../../styled-UI/CustomText";
import SetInnerHTMLWithSanitize from "../SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  slide: HighlightSlide;
  tileNumber?: number;
  tileId?: string;
  titleName: string;
  customFont?: FontFamilies | null;
}

const HighlightsSlide = ({
  slide,
  tileNumber,
  tileId,
  titleName,
  customFont = null,
}: Props): JSX.Element | null => {
  const {
    linkString,
    externalLink,
    openInNewTab,
    linkLabel,
    title,
    titleColour,
    layoutVariant,
    description,
    description1Colour,
  } = slide;
  const { handleCmsLink } = useHandleCmsLink();
  const srcImg = slide.multiMediaType?.[0]?.data?.uri;
  const brand = slide.brand?.[0]?.url;

  const [content, setContent] = useState<JSX.Element | null>(null);

  const descriptionWithHref = useDataHref({ content: description });
  const analyticsTag = getAnalyticsTagId(slide, slide.id);

  useEffect(() => {
    let slideContent: JSX.Element | null = null;
    if (slide && layoutVariant && layoutVariant[0].name) {
      switch (layoutVariant[0].name) {
        case "Hp-highlights-1":
          slideContent = renderText();
          break;

        case "Hp-highlights-2":
          slideContent = (
            <div className="banner-container">
              {srcImg && <CustomProgressiveImage type="portrait" src={srcImg} />}
            </div>
          );
          break;

        case "Hp-highlights-3":
          slideContent = (
            <>
              {srcImg && (
                <div className="banner-container">
                  <CustomProgressiveImage type="portrait" src={srcImg} />
                </div>
              )}
              {renderText()}
            </>
          );
          break;
      }
      setContent(slideContent);
    }
  }, [slide]);

  const renderText = (): JSX.Element => {
    return (
      <>
        {description && (
          <CustomText
            className="main-text"
            as="div"
            textAlign="center"
            marginTopPx={"auto"}
            marginRightPx={"auto"}
            marginBottomPx={"auto"}
            marginLeftPx={"auto"}
            lineHeightPx={32}
            color={"white"}
            font={customFont ? "custom-regular" : "font-regular"}
            fontFamily={customFont}
            customColor={description1Colour}
          >
            <SetInnerHTMLWithSanitize text={descriptionWithHref.updatedContent ?? description} />
          </CustomText>
        )}
        {title && (
          <CustomText
            className="main-text"
            as="div"
            textAlign="center"
            marginTopPx={"auto"}
            marginRightPx={"auto"}
            marginBottomPx={"auto"}
            marginLeftPx={"auto"}
            lineHeightPx={32}
            color={"white"}
            font={customFont ? "custom-regular" : "font-regular"}
            fontFamily={customFont}
            customColor={titleColour}
          >
            {title}
          </CustomText>
        )}
      </>
    );
  };

  const handleRedirect = (): void => {
    handleCmsLink(linkString, externalLink, openInNewTab);
  };

  return (
    <Container
      className={layoutVariant ? layoutVariant[0].name : ""}
      data-element-id={`Highlights_Tiles_Tile${tileNumber}`}
      data-description={`${analyticsTag}_${titleName}`}
    >
      <ImageContainer>{brand && <img src={brand} />}</ImageContainer>

      {content}
      {linkString && linkLabel && (
        <div className="cta-container">
          <Button
            type="primary-light"
            onClick={handleRedirect}
            data-element-id={`Highlights_Tiles_Tile${tileNumber}_ViewAll`}
            data-description={title}
            customFont={customFont}
          >
            {linkLabel}
          </Button>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 27.5rem;
  height: 37.5rem;
  background-color: #132a55;
  padding-top: 2.69rem;

  .brand-logo {
    width: 5.94rem;
    height: 3.5rem;
  }

  .cta-container {
    margin-top: auto;
    margin-bottom: 2.5rem;
  }

  .banner-container {
    img {
      object-fit: cover;
      width: 14.688rem;
    }
  }

  &.Hp-highlights-1 {
    .main-text {
      font-size: 2rem;
      padding-top: 5rem;
    }
  }

  &.Hp-highlights-2 {
    .banner-container {
      margin-top: 2.57rem;

      img {
        height: 20.063rem;
      }
    }
  }

  &.Hp-highlights-3 {
    .banner-container {
      margin-top: 2.57rem;

      img {
        height: 14.6875rem;
      }
    }

    .main-text {
      font-size: 1.5rem;
      padding-top: 1rem;
    }
  }
`;

const ImageContainer = styled.div`
  img {
    height: 3.5rem;
    width: 9.375rem;
    filter: invert(1); // COLOR LOGO
  }
`;

export default HighlightsSlide;
