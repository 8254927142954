import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";

import { Privilege, PrivilegeGroup } from "../../../store/subuser/subuserInterfaces";
import { getSubuserTemplates, getUserPrivileges } from "../../../store/subuser/subuserSagas";
import {
  selectGetUserPrivilegesStatus,
  selectPrivilegeGroups,
  selectPrivilegesTemplatesList,
} from "../../../store/subuser/subuserSlice";
import { selectIsBackOfficeUser } from "../../../store/user/userSlice";
import {
  getPrivilegesTemplateOptions,
  getSelectedPrivilegesTemplate,
  initializeSelectedPrivileges,
  isSubgroupMutuallyExclusive,
} from "../../../utils/manageSubusersUtils";
import Button from "../../styled-UI/Button";
import Checkbox from "../../styled-UI/Checkbox";
import CustomSelect, { CustomOptions } from "../../styled-UI/CustomSelect";
import CustomText from "../../styled-UI/CustomText";
import LoaderPrivilegeSection from "../../styled-UI/loader/my-account/add-subuser/LoaderPrivilegeSection";

export const CUSTOM_PRIVILEGES_TEMPLATE_OPTION = {
  label: "SUBUSERS_MANAGE_PRIVILEGES_TEMPLATE_CUSTOM",
  value: "custom",
};

interface Props {
  userId: string | null;
  isCreatingUser: boolean;
  isLeoOnly: boolean;
  isUpgrade: boolean;
  selectedPrivileges: PrivilegeGroup[];
  changeSelectedPrivileges: (newObj: PrivilegeGroup[]) => void;
}

const HandleSubuserPrivileges = ({
  userId,
  isCreatingUser,
  isLeoOnly,
  isUpgrade,
  selectedPrivileges,
  changeSelectedPrivileges,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();

  const privilegeGroups = useSelector(selectPrivilegeGroups);
  const privilegesTemplatesList = useSelector(selectPrivilegesTemplatesList);
  const getUserPrivilegesStatus = useSelector(selectGetUserPrivilegesStatus);
  const backOfficeUser = useSelector(selectIsBackOfficeUser);

  const isCreatingUserOrLeoOnly = isCreatingUser || isLeoOnly;

  useEffect(() => {
    const getUserPrivilegesPayload = !isCreatingUserOrLeoOnly && userId ? userId : undefined;
    dispatch(getUserPrivileges(getUserPrivilegesPayload));
    dispatch(getSubuserTemplates());
  }, []);

  const translatedCustomTemplateOption: CustomOptions = {
    label: translateLabel(CUSTOM_PRIVILEGES_TEMPLATE_OPTION.label),
    value: CUSTOM_PRIVILEGES_TEMPLATE_OPTION.value,
  };

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////// TEMPLATES ///////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const [templateOptions, setTemplatesOptions] = useState<CustomOptions[] | null>(null);
  const [template, setTemplate] = useState<CustomOptions | null>(null);

  useEffect(() => {
    if (privilegesTemplatesList && privilegeGroups) {
      // get template options to show in dropdown
      const privilegeOpts = getPrivilegesTemplateOptions(
        privilegesTemplatesList,
        isCreatingUser,
        translateLabel
      );
      setTemplatesOptions(privilegeOpts);

      // get preselected template
      const selectedTemplate = getSelectedPrivilegesTemplate(
        privilegesTemplatesList,
        privilegeOpts,
        isCreatingUser,
        isLeoOnly,
        isUpgrade
      );
      setTemplate(selectedTemplate);

      // get preselected privileges based on template
      const selectedPrivileges = initializeSelectedPrivileges(
        privilegesTemplatesList,
        privilegeGroups,
        selectedTemplate
      );
      changeSelectedPrivileges(selectedPrivileges);
      setCustomPrivileges(selectedPrivileges);
    }
  }, [privilegesTemplatesList, privilegeGroups]);

  useEffect(() => {
    if (templateOptions && templateOptions.length > 0 && privilegesTemplatesList) {
      const selectedTemplate = getSelectedPrivilegesTemplate(
        privilegesTemplatesList,
        templateOptions,
        isCreatingUser,
        isLeoOnly,
        isUpgrade
      );
      changePrivilegeTemplate(selectedTemplate);
    }
  }, [isLeoOnly]);

  const changePrivilegeTemplate = (option: CustomOptions | null): void => {
    setTemplate(option);

    if (option?.value === "custom") changeSelectedPrivileges(customPrivileges);
    else {
      if (privilegesTemplatesList && privilegeGroups && template) {
        const newSelectedPrivileges = initializeSelectedPrivileges(
          privilegesTemplatesList,
          privilegeGroups,
          option
        );
        changeSelectedPrivileges(newSelectedPrivileges);
      }
    }
  };

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// UPDATES ////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  // keep track of the custom privileges selected, as they need to be restored
  // if we switch back to them from another template
  const [customPrivileges, setCustomPrivileges] = useState<PrivilegeGroup[]>([]);

  const updateSelectedPrivileges = (groupId: number, privilegeId: number, isChecked: boolean) => {
    addCustomTemplateIfRequired();
    const newCustomPrivileges = cloneDeep(selectedPrivileges);

    const groupToUpdate = newCustomPrivileges.find(
      (privilegeGroup) => privilegeGroup.privilegeGroupId === groupId
    );

    const privilegeToUpdate = groupToUpdate?.privileges.find(
      (privilege) => privilege.userPrivilegeId === privilegeId
    );

    if (groupToUpdate && privilegeToUpdate) {
      privilegeToUpdate.active = !isChecked; // update given privilege

      const subgroup = groupToUpdate.subGroups?.find((subgroup) =>
        subgroup.privileges.includes(privilegeId)
      );

      // if the subgroup to which the updated privilege belongs to is required to only have
      // a single privilege selected, we need to deselect all the other ones
      if (subgroup && isSubgroupMutuallyExclusive(subgroup)) {
        // get list of all other privileges
        const privilegesToUncheck = subgroup.privileges?.filter((privId) => privId !== privilegeId);

        privilegesToUncheck.forEach((id) => {
          // find each of them in the main object and set them to false
          const privilegeToUpdate = groupToUpdate.privileges.find((_) => _.userPrivilegeId === id);
          if (privilegeToUpdate) privilegeToUpdate.active = false;
        });
      }

      setCustomPrivileges(newCustomPrivileges); // save custom privileges for future restores
      changeSelectedPrivileges(newCustomPrivileges); // save currently selected privileges
      setTemplate(translatedCustomTemplateOption); // set template to custom
    }
  };

  const addCustomTemplateIfRequired = () => {
    if (!templateOptions?.some((_) => _.value === "custom"))
      setTemplatesOptions((oldOptions) =>
        oldOptions
          ? [...oldOptions, translatedCustomTemplateOption]
          : [translatedCustomTemplateOption]
      );
  };

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// SELECT/DESELECT ALL //////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const handleDeselectAll = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    handleChangeAllUserPrivileges(false);
  };

  const handleSelectAll = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    handleChangeAllUserPrivileges(true);
  };

  const handleChangeAllUserPrivileges = (checkedVal: boolean) => {
    addCustomTemplateIfRequired();

    const newCustomPrivileges = cloneDeep(selectedPrivileges);

    newCustomPrivileges.forEach((groupToUpdate) => {
      const privilegesToIgnore =
        groupToUpdate.subGroups?.find(isSubgroupMutuallyExclusive)?.privileges ?? [];

      groupToUpdate.privileges.forEach((privilege) => {
        const privilegeCanBeChecked = !privilegesToIgnore.includes(privilege.userPrivilegeId); // = true if privilege shouldn't be checked automatically

        if (
          privilege.editable && // if privilege is editable AMD
          (!checkedVal || privilegeCanBeChecked) // either we are deselecting everything, or we are selecting and the privilege is not mutually exclusive with others
        )
          privilege.active = checkedVal; // then we can select/deselect the current privilege
      });
    });

    setCustomPrivileges(newCustomPrivileges); // save custom privileges for future restores
    changeSelectedPrivileges(newCustomPrivileges); // save currently selected privileges
    setTemplate(translatedCustomTemplateOption); // set template to custom
  };

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// RENDER /////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const isCheckboxDisabled = (privilege: Privilege): boolean => {
    return isLeoOnly || !isCheckboxEditable(privilege) || backOfficeUser;
  };

  const isCheckboxEditable = (privilege: Privilege): boolean => {
    if (!privilege.editable) return false;

    if (privilege.dependOnPrivilegeIds && privilege.dependOnPrivilegeIds.length > 0 && template) {
      return privilege.dependOnPrivilegeIds.every((privId) =>
        selectedPrivileges?.some((privilegeGroups) => {
          const matchingPriv = privilegeGroups.privileges.find(
            (privilegeFromGroup) => privilegeFromGroup.userPrivilegeId === privId
          );
          return matchingPriv?.active;
        })
      );
    }
    return true;
  };

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// RENDER /////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  if (isLeoOnly) return <></>;
  return (
    <div>
      {getUserPrivilegesStatus === "SUCCESS" && template && (
        <>
          <PrivilegeHeader>
            <CustomText fontSizePx={18} as="h4" lineHeightPx={18}>
              {translateLabel("SUBUSER_PRIVILEGES_TITLE")}
            </CustomText>
            <PrivilegeSelectContainer>
              <CustomSelect
                type="white"
                options={templateOptions}
                onChange={changePrivilegeTemplate}
                value={template}
                disabled={backOfficeUser || isLeoOnly}
              />
            </PrivilegeSelectContainer>
          </PrivilegeHeader>

          <PrivilegeContent>
            <ButtonContainer>
              <Button
                type="tertiary"
                onClick={handleDeselectAll}
                disabled={backOfficeUser || isLeoOnly}
              >
                {translateLabel("DESELECT_ALL")}
              </Button>
              <Button
                type="tertiary"
                onClick={handleSelectAll}
                disabled={backOfficeUser || isLeoOnly}
              >
                {translateLabel("SELECT_ALL")}
              </Button>
            </ButtonContainer>

            {selectedPrivileges?.map((privilegeGroup) => (
              <PrivilegeGroupContainer key={privilegeGroup.privilegeGroupKey}>
                <CustomText
                  fontSizePx={13}
                  marginBottomPx={16}
                  as="h5"
                  font="font-bold"
                  lineHeightPx={18}
                >
                  {translateLabel(privilegeGroup.privilegeGroupKey + "_SUBUSER_SECTION")}
                </CustomText>
                <PrivilegeCheckbox>
                  {privilegeGroup.privileges.map((privilege) => (
                    <Checkbox
                      label={translateLabel(privilege.userPrivilegeKey + "_LABEL")}
                      name={privilege.userPrivilegeKey}
                      key={privilege.userPrivilegeId}
                      controlled
                      onChange={() =>
                        updateSelectedPrivileges(
                          privilegeGroup.privilegeGroupId,
                          privilege.userPrivilegeId,
                          privilege.active
                        )
                      }
                      checked={privilege.active}
                      disabled={isCheckboxDisabled(privilege)}
                      notDisabledStyle
                    />
                  ))}
                </PrivilegeCheckbox>
              </PrivilegeGroupContainer>
            ))}
            <CustomText
              fontSizePx={13}
              lineHeightPx={18}
              marginBottomPx={40}
              as="span"
              font="font-regular"
            >
              {translateLabel("SUBUSER_CHECKBOX_FORM")}
            </CustomText>
          </PrivilegeContent>
        </>
      )}

      {getUserPrivilegesStatus === "LOADING" && !template && (
        <Loader>
          <LoaderPrivilegeSection />
          <LoaderPrivilegeSection />
        </Loader>
      )}
    </div>
  );
};

const PrivilegeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
`;

const PrivilegeSelectContainer = styled.div`
  width: 12.5rem;
`;

const PrivilegeContent = styled.div`
  padding-top: 0.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 0.75rem;
  column-gap: 1.5rem;
`;

const PrivilegeGroupContainer = styled.div`
  padding-bottom: 2.5rem;
`;

const PrivilegeCheckbox = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
`;

const Loader = styled.div`
  margin-bottom: 2rem;
`;

export default HandleSubuserPrivileges;
