import React from "react";
import styled from "styled-components/macro";
import useDataHref from "../../hooks/useDataHref";
import { LpTitleText as Interface } from "../../interfaces/cmsInterfaces";
import CustomText from "../styled-UI/CustomText";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  data: Interface;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LpTitleText = ({ data, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  const { description2, description2Colour, description, description1Colour } = data;
  const descrWithHref = useDataHref({ content: description });
  const descrWithHref2 = useDataHref({ content: description2 });

  return (
    <OuterBox>
      <BoxTitle>
        {description && (
          <CustomText
            as="div"
            fontSizePx={36}
            font={customFont ? "custom-bold" : "font-bold"}
            fontFamily={customFont}
            color={isDarkMode ? "white" : "primary"}
            lineHeightPx={42}
            className="cms-title"
            customColor={description1Colour}
          >
            <SetInnerHTMLWithSanitize text={descrWithHref.updatedContent ?? description} />
          </CustomText>
        )}
      </BoxTitle>
      <BoxTitleDescription>
        {description2 && (
          <CustomText
            as="div"
            fontSizePx={18}
            lineHeightPx={24}
            font={customFont ? "custom-light" : "font-light"}
            fontFamily={customFont}
            color={isDarkMode ? "white" : "primary"}
            customColor={description2Colour}
          >
            <SetInnerHTMLWithSanitize text={descrWithHref2.updatedContent ?? description2} />
          </CustomText>
        )}
      </BoxTitleDescription>
    </OuterBox>
  );
};

export default LpTitleText;

const OuterBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  min-height: 21.88rem;
  height: 100%;
`;

const BoxTitle = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-end;

  .cms-title {
    max-width: 80%;
  }
`;

const BoxTitleDescription = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  max-width: 80%;
`;
