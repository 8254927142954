import React from "react";
import styled from "styled-components";
import useTranslation from "../../../../hooks/useTranslation";
import CustomText from "../../../styled-UI/CustomText";

interface Props {
  isDisabled?: boolean;
  attribute: { attributeName?: string; attributeValue?: string };
}

const CustomAttributesRow = ({ attribute, isDisabled = false }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  return (
    <OuterBoxAttributes>
      <CustomText
        as="p"
        fontSizePx={13}
        font="font-regular"
        color={isDisabled ? "gray-dark" : "primary"}
      >
        {translateLabel("FLUID_CATEGORY_" + attribute?.attributeName?.toUpperCase())}:
      </CustomText>
      <CustomText
        as="p"
        fontSizePx={13}
        font="font-bold"
        marginLeftPx={20}
        color={isDisabled ? "gray-dark" : "primary"}
      >
        {attribute?.attributeValue}
      </CustomText>
    </OuterBoxAttributes>
  );
};

const OuterBoxAttributes = styled.div`
  display: flex;
  flex-direction: row;

  & > p {
    flex-shrink: 0;
  }
`;

export default CustomAttributesRow;
