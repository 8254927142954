import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Popup from "../../styled-UI/Popup";
import CustomText from "../../styled-UI/CustomText";
import useTranslation from "../../../hooks/useTranslation";
import useQuery from "../../../hooks/useQuery";
import { getPagePath } from "../../../routing/routesUtils";
import { cleanStringForLabel } from "../../../utils/utils";

interface ErrorPopup {
  open: boolean;
  errorCode: string | null;
}

const defaultPopup = { open: false, errorCode: null };

const HomepageErrorPopup = (): JSX.Element => {
  const [popupConfig, setPopupConfig] = useState<ErrorPopup>(defaultPopup);
  const { translateLabel } = useTranslation();
  const query = useQuery();

  useEffect(() => {
    if (query.get("essilorProLogin") === "false") {
      setPopupConfig({
        open: true,
        errorCode: cleanStringForLabel(query.get("errorCode")),
      });
    }
  }, []);

  const closePopup = () => {
    setPopupConfig(defaultPopup);
    //clean url
    window.location.replace(getPagePath("/homepage"));
  };

  return (
    <Popup isOpen={popupConfig.open} title={translateLabel("ERROR_POPUP_TITLE")} close={closePopup}>
      <PopupContent>
        <CustomText as="span">
          {translateLabel(
            popupConfig.errorCode && popupConfig.errorCode !== "500"
              ? "HOMEPAGE_PAIRING_ERROR_" + popupConfig.errorCode //NOT USED, for the future if it is needed
              : "HOMEPAGE_PAIRING_ERROR_GENERIC"
          )}
        </CustomText>
      </PopupContent>
    </Popup>
  );
};

const PopupContent = styled.div`
  padding: 3rem 2rem;
`;

export default HomepageErrorPopup;
