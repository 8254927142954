import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as InstagramIcon } from "../../../assets/icons/instagram.svg";
import CustomText from "../../styled-UI/CustomText";
import IconButton from "../../styled-UI/IconButton";
import { ReactComponent as ArrowHeadRight } from "../../../assets/icons/arrowhead-right-icon.svg";
import Button from "../../styled-UI/Button";
import { SocialProductTile as Interface } from "../../../interfaces/cmsInterfaces";
import BrandImage from "../../styled-UI/BrandImage";
import { getMultiMedia } from "../../../utils/cmsUtils";
import useTranslation from "../../../hooks/useTranslation";
import { replaceStringWithElement } from "../../../utils/utils";
import { getPagePath } from "../../../routing/routesUtils";
import { useHistory } from "react-router";
import CustomProgressiveImage from "../../styled-UI/CustomProgressiveImage";
import { sendAnalyticsData } from "../../../store/analytics/analyticsService";

interface Props {
  data: Interface;
  position?: number;
  rowNumber?: number;
}

const SocialProductTileContent = ({ data, position, rowNumber }: Props): JSX.Element => {
  const history = useHistory();
  const { translateLabel } = useTranslation();
  const { brand, instagramLink, linkLabelIL, numFollowers, celebrityName, cover, model } = data;

  const { srcImg } = getMultiMedia(cover);

  return (
    <OuterTile>
      {brand?.[0]?.name && (
        <LogoContainer>
          <BrandImage brandOrBrandGroup={brand?.[0]?.name} />
        </LogoContainer>
      )}

      <GlassImg>
        {model?.img && <CustomProgressiveImage type="glasses" src={model?.img} />}
        <BoxHover hasCover={srcImg ? true : false}>
          {model && (
            <CustomText as="p" fontSizePx={14} font="font-light" color="gray-dark">
              {model.productCode}
            </CustomText>
          )}

          {model && (
            <>
              {data.inAssortment ? (
                <Button
                  type="primary"
                  onClick={() => {
                    sendAnalyticsData({
                      id: "Click",
                      Tracking_Type: "link",
                      data_element_id: `SocialMediaProductTile_Tiles_Tile${rowNumber}x${position}_ShopNow`,
                      data_description: model.productCode,
                    });
                    history.push(getPagePath("/pdp" + model?.seo?.href));
                  }}
                >
                  {translateLabel("SOCIAL_TILE_SHOP_NOW")}
                </Button>
              ) : (
                <NoAssortmentContainer>
                  <CustomText
                    as="h1"
                    color="primary"
                    fontSizePx={13}
                    font="font-bold"
                    lineHeightPx={13}
                    textAlign="center"
                  >
                    {translateLabel("LANDING_PAGE_NO_ASSORTMENT")}
                  </CustomText>
                </NoAssortmentContainer>
              )}
            </>
          )}
        </BoxHover>
      </GlassImg>

      <FooterTile>
        <OuterBoxText>
          <InnerBoxText>
            {instagramLink && (
              <>
                <InstagramIcon />
                <button
                  onClick={() => {
                    sendAnalyticsData({
                      id: "Click",
                      Tracking_Type: "link",
                      data_element_id: `SocialMediaProductTile_Tiles_Tile${rowNumber}x${position}_Instagram`,
                      data_descpription: model?.productCode,
                    });
                    window.open(instagramLink, "_blank");
                  }}
                >
                  <BoxIcon>
                    <CustomText font="font-bold" as="span">
                      {translateLabel(linkLabelIL?.[0] ?? "")}
                    </CustomText>
                    <IconButton type="primary" icon={<ArrowHeadRight />} size="sm" />
                  </BoxIcon>
                </button>
              </>
            )}
          </InnerBoxText>
        </OuterBoxText>
        <OuterBoxText>
          <InnerBoxText className="no-padding-bottom">
            {celebrityName && (
              <CustomText font="font-light" fontSizePx={14} as="span" color="primary">
                {celebrityName}
              </CustomText>
            )}
            {numFollowers && (
              <CustomText
                font="font-bold"
                fontSizePx={14}
                as="span"
                color="primary"
                marginLeftPx={8}
              >
                {replaceStringWithElement(
                  translateLabel("SOCIAL_TILE_FOLLOWERS"),
                  "N",
                  <CustomText
                    font="font-bold"
                    fontSizePx={14}
                    as="span"
                    color="primary"
                    marginLeftPx={2}
                    marginRightPx={5}
                  >
                    {numFollowers}
                  </CustomText>
                )}
              </CustomText>
            )}
          </InnerBoxText>
        </OuterBoxText>
      </FooterTile>
    </OuterTile>
  );
};

export default SocialProductTileContent;

const BoxHover = styled.div<{ hasCover: boolean }>`
  display: ${(props) => (props.hasCover ? "flex" : "none")};

  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 1rem;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  p {
    margin-bottom: 1rem;
  }

  button {
    width: 16.06rem;
    height: 2.375rem;
  }
`;

const OuterTile = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  &:hover {
    ${BoxHover} {
      display: flex;
      /* position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      p {
        margin-bottom: 1rem;
      }

      button {
        width: 16.06rem;
        height: 2.375rem;
      } */
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;

  & > div {
    width: 4.25rem;
  }
`;

const GlassImg = styled.div`
  flex-grow: 1;
  position: relative;
  img {
    width: 100%;
  }
`;

const FooterTile = styled.div`
  display: flex;
  flex-direction: column;
`;

const OuterBoxText = styled.div`
  border-top: 1px solid #ededed;
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
`;

const InnerBoxText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.125rem 0;

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  svg {
    width: 2.125rem;
    height: 2.125rem;
  }
`;

const BoxIcon = styled.div`
  display: flex;

  span {
    margin-right: 0.5rem;
  }

  button {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
`;

const NoAssortmentContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 1.875rem;
  align-items: center;
`;
