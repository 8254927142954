import { AxiosResponse } from "axios";
import { createAndExecuteService } from "../serviceUtils";
import { Door } from "../user/userInterfaces";
import {
  GenericPrivateUserPolicyPayload,
  GetProfileAddressesPayload,
  getPublicUserPolicyPayload,
  privateUserPolicyPayload,
  submitPublicUserPolicyPayload,
} from "./profileInterfaces";
import {
  getUserSalesRepUrl,
  getProfileAddressesUrl,
  privateUserPolicyUrl,
  publicUserPolicyUrl,
} from "./profileUrls";

const userService = {
  getUserSalesRep: async (doorId: string): Promise<AxiosResponse> => {
    const url = getUserSalesRepUrl.replace("{doorId}", doorId);
    return createAndExecuteService(url, "GET");
  },
  getProfileAddresses: async (payload: GetProfileAddressesPayload): Promise<AxiosResponse> => {
    const { doorIds, storeIdentifier } = payload;

    const url = getProfileAddressesUrl.replace("{storeIdentifier}", storeIdentifier);

    const searchParams = new URLSearchParams();
    doorIds?.forEach((_) => {
      searchParams.append("doorId", _);
    });
    return createAndExecuteService(url, "GET", searchParams);
  },
  getPublicUserPolicy: async (payload: getPublicUserPolicyPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ uuid: payload.uuid });
    const url = publicUserPolicyUrl.replace("{username}", payload.username);

    return createAndExecuteService(url, "GET", searchParams);
  },
  submitPublicUserPolicy: async (
    payload: submitPublicUserPolicyPayload
  ): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams({ uuid: payload.uuid });
    const url = publicUserPolicyUrl.replace("{username}", payload.username);
    const data = {
      contextAttribute: [
        {
          attributName: "marketingCommunications",
          attributValue: payload.marketingCommunications,
        },
        {
          attributName: "privacyPersonalData",
          attributValue: payload.privacyPersonalData,
        },
      ],
    };
    return createAndExecuteService(url, "PUT", searchParams, data);
  },

  getPrivateUserPolicy: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(privateUserPolicyUrl, "GET");
  },

  putPrivateUserPolicy: async (payload: privateUserPolicyPayload): Promise<AxiosResponse> => {
    let data: any;

    if (payload.method === "PUT") {
      data = {
        contextAttribute: [
          {
            attributName: "marketingCommunications",
            attributValue: payload.marketingCommunications,
          },
        ],
      };
    }

    return createAndExecuteService(privateUserPolicyUrl, payload.method, undefined, data);
  },

  putGenericPrivateUserPolicy: async (
    payload: GenericPrivateUserPolicyPayload[]
  ): Promise<AxiosResponse> => {
    const data = {
      contextAttribute: payload,
    };

    return createAndExecuteService(privateUserPolicyUrl, "PUT", undefined, data);
  },
};

export default userService;
