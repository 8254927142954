import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import clsx from "clsx";
import useTranslation from "../../../../hooks/useTranslation";
import useFilteredSelector from "../../../../hooks/useFilteredSelector";

import {
  CartLoading,
  OrderCategory,
  OrderCategoryInfo,
  OrderMultidoorAddress,
  OrderMultidoorInfo,
} from "../../../../store/cart/cartInterfaces";
import { deletePrecartMultidoor } from "../../../../store/cart/cartSagas";
import { selectCartLoading, toggleSelectedMultidoor } from "../../../../store/cart/cartSlice";
import {
  CheckoutOrderCategoryInfo,
  CheckoutOrderMultidoorInfo,
} from "../../../../store/checkout/checkoutInterfaces";
import { Door } from "../../../../store/user/userInterfaces";
import { printAddress } from "../../../../utils/cartUtils";
import CustomText from "../../../styled-UI/CustomText";
import IconButton from "../../../styled-UI/IconButton";
import Checkbox from "../../../styled-UI/Checkbox";
import Popup from "../../../styled-UI/Popup";
import Button from "../../../styled-UI/Button";
import CartSection from "./CartSection";

import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-icon.svg";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrowhead-down-icon.svg";
import { selectIsBackOfficeUser } from "../../../../store/user/userSlice";

interface Props {
  title: string;
  isMultidoor: boolean;
  cartItems: OrderCategory[];
  multidoorInfo?: OrderMultidoorInfo | CheckoutOrderMultidoorInfo;
  orderMultidoorAddress?: OrderMultidoorAddress;
  onlyView?: boolean;
  isOrderApproval?: boolean;
  currencyTYP?: string;
  isEssilor?: boolean;
}

const CartDoor = ({
  title,
  isMultidoor = false,
  cartItems,
  multidoorInfo,
  orderMultidoorAddress,
  onlyView = false,
  isOrderApproval,
  currencyTYP,
  isEssilor = false,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const isBackofficeUser = useSelector(selectIsBackOfficeUser);

  const [openSection, setOpenSection] = useState<boolean>(true);
  const [isOpenPopUp, setIsOpenPopUp] = useState<boolean>(false);

  const handleClosePopUp = () => setIsOpenPopUp(false);

  const door: Door | undefined =
    !!multidoorInfo?.multidoorId && multidoorInfo?.orgentityName
      ? {
          orgentityId: multidoorInfo?.multidoorId,
          orgentityName: multidoorInfo?.orgentityName,
        }
      : undefined;

  const isOutOfStock =
    (multidoorInfo as OrderMultidoorInfo)?.totalNumber ===
    (multidoorInfo as OrderMultidoorInfo)?.outOfStockNumber;

  const loading = useFilteredSelector<CartLoading>(
    selectCartLoading,
    (_) => _.id === "multiple-delete"
  );

  useEffect(() => {
    !loading && setIsOpenPopUp(false);
  }, [loading]);

  const deletePopup = (
    <Popup
      isOpen={isOpenPopUp}
      close={handleClosePopUp}
      title={translateLabel("CART_EMPTY_DOOR_POPUP_TITLE")}
      isCentered
    >
      <ModalContainer>
        <WrapMessage>
          <CustomText as="p" fontSizePx={18} font="font-bold" color="primary" lineHeightPx={24}>
            {translateLabel("CART_EMPTY_DOOR_POPUP_MESSAGE")}
          </CustomText>
        </WrapMessage>
        <ButtonContainer>
          <Button
            isLoading={loading !== null}
            type="primary"
            onClick={() => dispatch(deletePrecartMultidoor(cartItems))}
          >
            {translateLabel("CONFIRM")}
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </Popup>
  );

  const categoryTiles = (
    <>
      {cartItems?.map((orderCategory) => {
        // get categoryInfo based on whether we are in cart or TYP (ie. onlyView)
        const categoryInfo = !onlyView
          ? ((multidoorInfo as OrderMultidoorInfo)?.categorySelected.filter((_) => {
              return _.productCategory == orderCategory.productCategory;
            })[0] as OrderCategoryInfo)
          : ((multidoorInfo as CheckoutOrderMultidoorInfo)?.category.filter((_) => {
              return _.productCategory == orderCategory.productCategory;
            })[0] as CheckoutOrderCategoryInfo);

        if (door)
          return (
            <CartSection
              key={orderCategory.productCategory}
              title={orderCategory.productCategory}
              outOfStock={orderCategory.productCategory == "Unallocated"}
              isMultidoor={isMultidoor}
              cartItems={orderCategory.orderItemList}
              categoryInfo={categoryInfo}
              onlyView={onlyView}
              currentDoor={door}
              isOrderApproval={isOrderApproval}
              currencyTYP={currencyTYP}
              isEssilor={isEssilor}
            />
          );
      })}
    </>
  );

  if (!isMultidoor) return categoryTiles;
  else
    return (
      <Section>
        {deletePopup}
        <Header>
          <div className={clsx(openSection && "showBorder")}>
            <HeaderFirstLine>
              <Title>
                {!onlyView && !isOutOfStock && !isOrderApproval && (
                  <Checkbox
                    label="sunglasses"
                    name="sunglasses"
                    hideLabel
                    controlled
                    isSelectAll={(multidoorInfo as OrderMultidoorInfo)?.selectedNumber != 0}
                    checked={
                      (multidoorInfo as OrderMultidoorInfo)?.selectedNumber ==
                      (multidoorInfo as OrderMultidoorInfo)?.totalNumber -
                        (multidoorInfo as OrderMultidoorInfo)?.outOfStockNumber
                    }
                    onChange={() =>
                      multidoorInfo &&
                      dispatch(
                        toggleSelectedMultidoor({
                          multidoorId: multidoorInfo?.multidoorId,
                          currentStatus:
                            (multidoorInfo as OrderMultidoorInfo)?.selectedNumber ==
                            (multidoorInfo as OrderMultidoorInfo)?.totalNumber -
                              (multidoorInfo as OrderMultidoorInfo)?.outOfStockNumber,
                        })
                      )
                    }
                  />
                )}
                <CustomText
                  as="span"
                  color="primary"
                  fontSizePx={28}
                  font="font-medium"
                  lineHeightPx={28}
                  marginLeftPx={onlyView ? 0 : 16}
                >
                  {title}{" "}
                  {orderMultidoorAddress?.soldTo && printAddress(orderMultidoorAddress.soldTo)} (
                  {multidoorInfo?.totalNumber})
                </CustomText>
              </Title>
              <HeaderButtons className="print-hide">
                {!onlyView && (
                  <IconButtonContainer>
                    <IconButton
                      onClick={() => setIsOpenPopUp(true)}
                      size="md"
                      icon={<TrashIcon />}
                      type="no-background-primary"
                      disabled={isBackofficeUser && isOrderApproval}
                    />
                  </IconButtonContainer>
                )}
                <IconButton
                  onClick={() => setOpenSection(!openSection)}
                  size="md"
                  icon={<Arrow />}
                  type="primary"
                  upsideDown={openSection}
                />
              </HeaderButtons>
            </HeaderFirstLine>
            <CustomText
              as="span"
              color="primary"
              fontSizePx={14}
              font="font-regular"
              lineHeightPx={28}
              marginLeftPx={onlyView ? 0 : 38}
            >
              {!onlyView &&
                !isOutOfStock &&
                !isOrderApproval &&
                (multidoorInfo as OrderMultidoorInfo)?.selectedNumber +
                  " " +
                  translateLabel("CART_ITEMS_SELECTED")}
              {onlyView &&
                translateLabel("CART_ORDER_NUMBER") +
                  " #" +
                  (multidoorInfo as CheckoutOrderMultidoorInfo)?.subOrderId}
            </CustomText>
          </div>
        </Header>
        {openSection && categoryTiles}
      </Section>
    );
};

const Section = styled.div`
  background-color: ${(props) => props.theme.palette.gray.light};
  border-radius: 0.25rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
`;

const Header = styled.div`
  padding-top: 2.125rem;
  padding-left: 1.375rem;
  padding-right: 1.375rem;

  & > div {
    padding-bottom: 1.75rem;
    &.showBorder {
      border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium} !important;
    }
  }
`;

const HeaderFirstLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const IconButtonContainer = styled.div`
  margin-right: 0.125rem;
`;

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
`;

//popup
const ModalContainer = styled.div`
  padding: 3rem 0;
  width: 42.375rem;
`;

const WrapMessage = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default CartDoor;
