import { AxiosResponse } from "axios";
import { precartUpdateUrl } from "../cart/cartUrls";
import { createAndExecuteService } from "../serviceUtils";
import { EditProductPayload, EditSuborderUploadPrecartPayload } from "./orderUploadInterfaces";
import {
  deleteMassiveOrderUrl,
  downloadMassiveOrderTemplateUrl,
  getAdrressbyAddressId,
  getMassiveOrdersUrl,
  getUpoloadTokenUrl,
  postMassiveOrderCompleteUrl,
  uploadedProductUrl,
  uploadMassiveOrderUrl,
} from "./orderUploadUrls";

const orderUploadService = {
  getMassiveOrders: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getMassiveOrdersUrl, "GET");
  },
  deleteMassiveOrder: async (uploadId: string): Promise<AxiosResponse> => {
    const url = deleteMassiveOrderUrl.replace("{uploadId}", uploadId);
    return createAndExecuteService(url, "DELETE");
  },
  downloadMassiveOrderTemplate: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(
      downloadMassiveOrderTemplateUrl,
      "GET",
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      "blob"
    );
  },
  getUploadToken: async (storeId: string): Promise<AxiosResponse> => {
    const url = getUpoloadTokenUrl.replace("{storeId}", storeId);
    return createAndExecuteService(url, "GET");
  },
  uploadMassiveOrder: async (
    storeId: string,
    formData: FormData,
    bearerToken: string,
    filename: string
  ): Promise<AxiosResponse> => {
    const url = uploadMassiveOrderUrl.replace("{storeId}", storeId);
    return createAndExecuteService(
      url,
      "POST",
      undefined,
      formData,
      undefined,
      {
        "Content-Disposition": filename,
      },
      bearerToken
    );
  },
  getProductsError: async (uploadId: string): Promise<AxiosResponse> => {
    const url = uploadedProductUrl.replace("{uploadId}", uploadId);
    return createAndExecuteService(url, "GET");
  },
  editProduct: async (payload: EditProductPayload): Promise<AxiosResponse> => {
    const url = uploadedProductUrl.replace("{uploadId}", payload.uploadId as string);
    delete payload.uploadId;

    return createAndExecuteService(url, "PUT", undefined, payload);
  },
  postMassiveOrderComplete: async (uploadId: string): Promise<AxiosResponse> => {
    const url = postMassiveOrderCompleteUrl.replace("{uploadId}", uploadId as string);
    return createAndExecuteService(url, "POST", undefined, uploadId);
  },
  getAdrressbyAddressId: async (addressId: string): Promise<AxiosResponse> => {
    const url = getAdrressbyAddressId.replace("{addressId}", addressId as string);
    return createAndExecuteService(url, "GET");
  },
  updatePrecart: async (payload: EditSuborderUploadPrecartPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(
      precartUpdateUrl.replace("{subOrderId}", payload.subOrderId),
      "PATCH",
      null,
      payload.payload
    );
  },
};

export default orderUploadService;
