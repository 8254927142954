import { BFFApiDomain } from "../serviceUtils";

export const getStartSRUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/startServiceRequest";

export const getSimpleSRUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/simpleServiceRequest";

export const uploadFileUrl = globalEnvVariables.dlEndpoint + "/uploadFile";

export const submitSimpleSRUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/submitSimpleServiceRequest";

export const getBrandsSRUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/brandgroup/brands/new";

export const submitBrandSRUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/submitBrandServiceRequest";

export const submitAddressSRUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/submitUserAddressServiceRequest";

export const submitOrderSupportSRUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/submitOrderServiceRequest";

export const getShipmentSupportSRUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/orderItemShipmentSupportServiceRequest";

export const submitShipmentSupportSRUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/submitOrderItemShipmentSupportServiceRequest";

/////////////////// SERVICE REQUEST PAGE
export const getMenuKeysSRUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/serviceRequestSearchMenuKeys";

export const urgeSRUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/urgeServiceRequest";

export const searchSRUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/serviceRequestSearch";

export const replySRUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/serviceRequestReply";

export const markAsReadSRUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/serviceRequest/serviceRequestSearchMarkAsRead";
