import React, { useEffect, useState } from "react";
import clsx from "clsx";
import styled from "styled-components/macro";
import CarouselDot from "../styled-UI/CarouselDot";
import { NavigationType } from "./Carousel";

interface Props {
  lastPage: number;
  currentPage?: number;
  navigationType: NavigationType;
  isAnimated?: boolean;
  changeSlide: (arg: number) => void;
  "data-element-id"?: string | null;
  "data-description"?: string;
  direction?: "horizontal" | "vertical";
  backgroundDark?: boolean;
  dotSelectedColor?: string;
}

const CarouselDotBar = ({
  lastPage,
  currentPage = 1,
  changeSlide,
  navigationType,
  isAnimated = false,
  direction = "horizontal",
  backgroundDark = false,

  "data-element-id": dataElementId,
  "data-description": dataDescription,
  dotSelectedColor,
}: Props): JSX.Element => {
  const [dots, setDots] = useState<number[]>();

  const NavigationClasses = clsx(
    (navigationType === "central" ||
      navigationType === "highlights" ||
      navigationType === "multidoor-add-size" ||
      navigationType === "minimal") &&
      "no-margin",
    navigationType === "lp-banner" && "center-dot-bar",
    direction === "vertical" && "carousel-dot-bar-vertical"
  );

  useEffect(() => {
    if (lastPage) {
      const temp = [];
      for (let i = 0; i <= lastPage; i++) {
        temp.push(i);
      }
      setDots(temp);
    }
  }, [lastPage]);

  const renderDots = () => {
    return (
      dots &&
      dots.map((dot, index) => {
        return (
          <CarouselDot
            key={index}
            dot={dot}
            index={index}
            currentPage={currentPage}
            navigationType={navigationType}
            changeSlide={changeSlide}
            isAnimated={isAnimated}
            backgroundDark={backgroundDark}
            {...(dataElementId ? { "data-element-id": dataElementId } : {})}
            {...(dataDescription ? { "data-description": dataDescription } : {})}
            dotSelectedColor={dotSelectedColor}
          />
        );
      })
    );
  };

  return (
    <DotsContainer
      className={NavigationClasses}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
      }}
    >
      {renderDots()}
    </DotsContainer>
  );
};

const DotsContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  /* justify-content: center; */

  &.no-margin {
    margin-top: unset;
  }

  &.center-dot-bar {
    width: 100%;
    margin-top: -3rem;
    display: flex;
    justify-content: center;
  }

  &.carousel-dot-bar-vertical {
    flex-direction: column;
  }
`;

export default CarouselDotBar;
