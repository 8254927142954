import axios, { AxiosRequestConfig, Method, AxiosResponse, ResponseType } from "axios";
import { parseUrlInfo } from "../routing/routesUtils";
import { LocalStorageUtils } from "../utils/storageUtils";

const JSONbigNative = require("json-bigint")({ storeAsString: true });

/* BASE URL CONFIGURATION */
export const BFFApiDomain = "";
// process.env.NODE_ENV === "development" ? "" : process.env.REACT_APP_BFF_URL;

type SearchParamsPayload = string | number | boolean | undefined | null;

export const getSearchParamsFromPayload = <T, E extends { [key in keyof T]: SearchParamsPayload }>(
  payload: E
): URLSearchParams => {
  const qparams = new URLSearchParams();

  Object.entries(payload).forEach(
    // add all parameters only if they are not empty, and cast them to string
    ([key, value]) => value !== undefined && value !== null && qparams.append(key, String(value))
  );

  return qparams;
};

/**
 * Headers interface for axios api request
 * @export
 * @interface CustomHeaders
 */
export interface CustomHeaders {
  Authorization?: string;
  Preview?: string;
  "x-csrf-token"?: string;
  "Content-Type"?: string;
  "Content-Disposition"?: string;
  Doors?: string;
}

/**
 * Wrapper class for axios library
 * @export
 * @class HttpClient
 */
export class HttpClient {
  private headers: CustomHeaders = {
    Authorization: "",
  };

  private requestConfig: AxiosRequestConfig = {};

  /**
   * Execute axios request and return the response
   * @memberof HttpClient
   */
  executeRequest = (): Promise<AxiosResponse> => {
    axios.defaults.transformResponse = [
      (data) => {
        if (typeof data === "string") {
          try {
            data = JSONbigNative.parse(data);
          } catch (e) {
            console.error(e);
          } // Added this Ignore as it's the same in the Axios
        } else console.error(data);
        return data;
      },
    ];

    return new Promise((resolve, reject) => {
      axios(this.requestConfig)
        .then((response) => resolve(this.responseHandler(response)))
        .catch((error) => reject(this.errorHandler(error)));
    });
  };

  /**
   * Set request params for axios
   * @param {string} url
   * @param {Method} method ex. POST, GET
   * @param {(URLSearchParams | string | null)} [params] query parameters for GET api
   * @param {(CustomHeaders | null)} [newHeaders] headers
   * @param {*} [data] object params for POST api
   *
   * @memberof HttpClient
   */
  setRequestConfig = (
    url: string,
    method: Method,
    params?: URLSearchParams | string | null,
    newHeaders?: CustomHeaders | null,
    data?: any,
    withCredentials?: boolean,
    responseType?: ResponseType
  ): void => {
    this.requestConfig = {
      url,
      method,
      headers: newHeaders ? newHeaders : this.headers,
    };

    if (params) {
      this.requestConfig.params = params;
    }

    if (data) {
      this.requestConfig.data = data;
    }

    if (withCredentials) {
      this.requestConfig.withCredentials = withCredentials;
    }
    if (responseType) {
      this.requestConfig.responseType = responseType;
    }
  };

  setHeaders = (newHeaders: CustomHeaders): void => {
    this.headers = newHeaders;
  };

  private responseHandler = (response: AxiosResponse) => {
    return response;
  };

  private errorHandler = (error: AxiosResponse) => {
    return error;
  };
}

/**
 * Replace url placeholder with values saved in site object
 *
 * @param  {string} url complete url for the api
 * @param  {Site} site for ex. POST, GET, PUT
 */
export const getDynamicUrl = (url: string): string => {
  const site = parseUrlInfo();

  return url
    .replace("{storeIdentifier}", site.storeIdentifier ? site.storeIdentifier : "")
    .replace("{locale}", site.locale ? site.locale : "");
};

/**
 * Helper function to create and execute api
 *
 * @param  {string} url complete url for the api
 * @param  {Method} method for ex. POST, GET, PUT
 * @param  {URLSearchParams | null} queryParams query parameter for GET api
 * @param  {unknown} data object params for POST & PUT api
 * @param  {Method} isPublic if true, doesn't set header with token
 */
export const createAndExecuteService = async (
  url: string,
  method: Method,
  queryParams: URLSearchParams | null = null,
  data?: unknown,
  withCredentials?: boolean,
  newHeaders?: CustomHeaders,
  token?: string,
  responseType?: ResponseType
): Promise<AxiosResponse> => {
  const parsedUrl = getDynamicUrl(url);
  const http = new HttpClient();
  let headers: CustomHeaders = {};
  let csrfToken;
  let doorsHeader = "";

  const localStorageUtils = new LocalStorageUtils();
  if (localStorageUtils) {
    doorsHeader = localStorageUtils.getState()?.user?.doors;
    headers["Doors"] = doorsHeader;
  }

  try {
    csrfToken = sessionStorage.getItem("csrfToken");
  } catch (e) {
    console.warn(e);
  }

  if (token) headers["Authorization"] = "Bearer " + token;

  if (
    url.includes("/fo-bff/") &&
    method !== "GET" &&
    method !== "HEAD" &&
    method !== "OPTIONS" &&
    csrfToken &&
    csrfToken !== "undefined"
  ) {
    headers["x-csrf-token"] = csrfToken;
  }

  if (newHeaders)
    headers = {
      ...headers,
      ...newHeaders,
    };

  http.setRequestConfig(
    parsedUrl,
    method,
    queryParams,
    headers,
    data,
    withCredentials,
    responseType
  );
  return await http.executeRequest();
};
