import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useCanRender from "../../hooks/useCanRender";
import useTranslation from "../../hooks/useTranslation";

import { DisplayPricesOptions, DisplayPricesPayload } from "../../store/user/userInterfaces";
import { updateSelectedDisplayPrices } from "../../store/user/userSagas";
import {
  selectActiveDoor,
  selectDisplayPrices,
  selectDisplayPricesLoading,
  selectIsSubuser,
  selectSelectedDoor,
  selectSrpOptInChoice,
  selectSupportedDisplayPrices,
  setDisplayPrices,
  setSupportedDisplayPrices,
} from "../../store/user/userSlice";
import { mapTranslatedKeys } from "../../utils/utils";
import CustomSelect, { CustomOptions } from "../styled-UI/CustomSelect";
import CustomText from "../styled-UI/CustomText";
import { FontFamilies } from "../../store/store/storeInterfaces";
import FavoritesTooltipContainer from "./tutorial-pills/manage-favorites/FavoritesTooltipContainer";
import { externalReferenceTooltip } from "../../utils/cmsUtils";
import theme from "../../assets/theme/theme";

interface Props {
  colorSelect?: "gray-medium" | "bg-light-blue";
  enableDataDescription?: boolean;
  "data-element-id"?: string;
  style?: "default" | "dark";
  customFont?: FontFamilies | null;
}

const DisplayPrices = ({
  colorSelect,
  enableDataDescription = false,
  "data-element-id": dataElementId,
  style = "default",
  customFont = null,
}: Props): JSX.Element => {
  const { translateLabel, labels } = useTranslation();
  const dispatch = useDispatch();
  const canRender = useCanRender();

  const selectedDoor = useSelector(selectSelectedDoor);
  const mainDoor = useSelector(selectActiveDoor);
  const selectedSetting = useSelector(selectDisplayPrices);
  const srpOptInChoice = useSelector(selectSrpOptInChoice);
  const supportedDisplayPrices = useSelector(selectSupportedDisplayPrices);
  const isDisplayPricesLoading = useSelector(selectDisplayPricesLoading);
  const [options, setOptions] = useState<CustomOptions[]>([]);

  const isRxPage: boolean = location.pathname.includes("/rx-prescription");
  const isSparePartsPage: boolean = location.pathname.includes("/spare-parts");
  const isRxIncludedPage =
    location.pathname.includes("/cart") || location.pathname.includes("/thank-you-page");
  const isSubuser = useSelector(selectIsSubuser);
  const tutorialPillsReference = location.pathname.includes("homepage")
    ? "HOMEPAGE_DISPLAY_PRICES"
    : location.pathname.includes("cart")
    ? "CART_DISPLAY_PRICES"
    : location.pathname.includes("preplp")
    ? "PREPLP_DISPLAY_PRICES"
    : location.pathname.includes("plp")
    ? "PLP_DISPLAY_PRICES"
    : location.pathname.includes("pdp")
    ? "PDP_DISPLAY_PRICES"
    : "";
  const backgroundColorSelector = isSparePartsPage ? theme.palette.gray.secondaryGray : undefined;

  useEffect(() => {
    if (labels) {
      const newSupportedSetting = ["NO_PRICES"];
      const isSubuserWithoutWHS = isSubuser && !canRender("WHOLESALE_PRICE", mainDoor);
      const isSubuserWithoutSRP = isSubuser && !canRender("SUGGESTED_RETAIL_PRICE", mainDoor);
      const isSubuserWithoutRXWHS = isSubuser && !canRender("RX_MY_PRICE", mainDoor);
      const isSubuserWithoutRXSRP = isSubuser && !canRender("RX_MY_PRICE_SRP", mainDoor);

      if (isRxPage) {
        if (canRender("RX_MY_PRICE_SRP", selectedDoor) && !isSubuserWithoutRXSRP) {
          newSupportedSetting.unshift("SUGGESTED_PRICES");
        }
        if (canRender("RX_MY_PRICE", selectedDoor) && !isSubuserWithoutRXWHS) {
          newSupportedSetting.unshift("WHOLESALE_PRICES");
        }
        if (
          canRender("RX_MY_PRICE", selectedDoor) &&
          canRender("RX_MY_PRICE_SRP", selectedDoor) &&
          !isSubuserWithoutRXWHS &&
          !isSubuserWithoutRXSRP
        ) {
          newSupportedSetting.unshift("ALL_PRICES");
        }
      } else if (isRxIncludedPage) {
        if (
          (canRender("RX_MY_PRICE_SRP", selectedDoor) ||
            canRender("SUGGESTED_RETAIL_PRICE", selectedDoor)) &&
          (!isSubuserWithoutSRP || !isSubuserWithoutRXSRP)
        )
          newSupportedSetting.unshift("SUGGESTED_PRICES");
        if (
          (canRender("RX_MY_PRICE", selectedDoor) || canRender("WHOLESALE_PRICE", selectedDoor)) &&
          (!isSubuserWithoutWHS || !isSubuserWithoutRXWHS)
        )
          newSupportedSetting.unshift("WHOLESALE_PRICES");

        if (
          (canRender("RX_MY_PRICE", selectedDoor) || canRender("WHOLESALE_PRICE", selectedDoor)) &&
          (canRender("RX_MY_PRICE_SRP", selectedDoor) ||
            canRender("SUGGESTED_RETAIL_PRICE", selectedDoor)) &&
          (!isSubuserWithoutSRP || !isSubuserWithoutRXSRP) &&
          (!isSubuserWithoutWHS || !isSubuserWithoutRXWHS)
        )
          newSupportedSetting.unshift("ALL_PRICES");
      } else {
        if (!isSparePartsPage && canRender("SUGGESTED_RETAIL_PRICE") && !isSubuserWithoutSRP) {
          newSupportedSetting.unshift("SUGGESTED_PRICES");
        }
        if (canRender("WHOLESALE_PRICE") && !isSubuserWithoutWHS) {
          newSupportedSetting.unshift("WHOLESALE_PRICES");
        }
        if (
          canRender("WHOLESALE_PRICE") &&
          canRender("SUGGESTED_RETAIL_PRICE") &&
          !isSubuserWithoutWHS &&
          !isSubuserWithoutSRP
        ) {
          newSupportedSetting.unshift("ALL_PRICES");
        }
      }

      const supportedSettingTranslated = mapTranslatedKeys(newSupportedSetting, translateLabel);

      setOptions(supportedSettingTranslated);
      dispatch(setSupportedDisplayPrices(newSupportedSetting as DisplayPricesOptions[]));
    }
  }, [labels, srpOptInChoice]);

  useEffect(() => {
    if (options.length > 0 && !selectedSetting) {
      dispatch(setDisplayPrices(options?.[0]?.value?.toString() as DisplayPricesOptions));
    }
  }, [options]);

  useEffect(() => {
    //If the selected settings received from BE is in the supportedDisplayPrices, set it.
    if (supportedDisplayPrices && selectedSetting) {
      if (supportedDisplayPrices.includes(selectedSetting)) {
        dispatch(setDisplayPrices(selectedSetting));
      } else {
        //Otherwise, select the highest privilege for the active door.
        if (supportedDisplayPrices.includes("ALL_PRICES")) {
          dispatch(setDisplayPrices("ALL_PRICES"));
        } else if (supportedDisplayPrices.includes("SUGGESTED_PRICES")) {
          dispatch(setDisplayPrices("SUGGESTED_PRICES"));
        } else if (supportedDisplayPrices.includes("WHOLESALE_PRICES")) {
          dispatch(setDisplayPrices("WHOLESALE_PRICES"));
        } else {
          dispatch(setDisplayPrices("NO_PRICES"));
        }
      }
    }
  }, [selectedSetting, supportedDisplayPrices]);

  const handleChangeOptions = (selectedOption: string) => {
    dispatch(
      updateSelectedDisplayPrices({
        selectedDisplayPrices: selectedOption,
      } as DisplayPricesPayload)
    );
  };

  return (
    <Container>
      <TooltipContainer>
        {tutorialPillsReference && (
          <FavoritesTooltipContainer position={externalReferenceTooltip[tutorialPillsReference]} />
        )}
        <CustomText
          as="span"
          marginRightPx={8}
          marginBottomPx={4}
          color={style === "dark" ? "white" : "primary"}
          font={customFont ? "custom-regular" : "font-regular"}
          fontFamily={customFont}
        >
          {translateLabel("DISPLAY")}
        </CustomText>
      </TooltipContainer>
      <SelectContainer>
        <CustomSelect
          type={style === "dark" ? "dark" : "no-outline"}
          colorNoOutline={colorSelect}
          onChange={(selectedOption) =>
            handleChangeOptions(selectedOption.value as DisplayPricesOptions)
          }
          options={options}
          value={
            selectedSetting
              ? { value: selectedSetting, label: translateLabel(selectedSetting) }
              : null
          }
          enableDataDescription={enableDataDescription}
          {...(dataElementId ? { "data-element-id": dataElementId } : {})}
          isSearchable={false}
          isLoading={isDisplayPricesLoading === "LOADING"}
          backgroundColor={backgroundColorSelector}
        />
      </SelectContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const TooltipContainer = styled.div`
  display: flex;
  column-gap: 1.5rem;
`;

const SelectContainer = styled.div`
  width: 10rem;
`;

export default DisplayPrices;
