import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { CategoryColumnInfo } from "../../../../../../hooks/useMenuColumnsInfo";
import { resetScrollPosition } from "../../../../../../hooks/useSaveScrollPosition";
import useTranslation from "../../../../../../hooks/useTranslation";
import { getPagePath } from "../../../../../../routing/routesUtils";
import { composeUrlWithParams } from "../../../../../../utils/utils";
import CustomText from "../../../../../styled-UI/CustomText";
import LinkButton from "../../../../../styled-UI/LinkButton";
import NewTag from "../../../../../widgets/tutorial-pills/new-tag/NewTag";
import { MenuColumn as MenuColumnInterface } from "./menuStructure";
import { externalReferenceNewTag } from "../../../../../../utils/cmsUtils";

interface Content {
  label: string;
  content: JSX.Element;
}
interface Props {
  baseUrl: string;
  columnStructure: MenuColumnInterface;
  identifier: string;
  toggleMenu: (show: boolean) => void;
  setToggleMenu: (identifier: string) => void;
  nameCategory?: string;
  categoryColumns: CategoryColumnInfo[];
  title: string;
}

const MenuColumn = ({
  baseUrl,
  columnStructure,
  identifier,
  toggleMenu,
  setToggleMenu,
  nameCategory,
  categoryColumns,
  title,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const [menuContent, setMenuContent] = useState<Content[]>([]);
  const history = useHistory();

  useEffect(() => {
    const categoryItemTemp: Content[] = [];

    //get column content to print by its info
    categoryColumns?.forEach((info) => {
      categoryItemTemp.push({
        label: info.label,
        content: (
          <li
            key={info.keyColumn}
            onClick={() => {
              toggleMenu(false);
              setToggleMenu("");
            }}
          >
            <Link
              onClick={() => resetScrollPosition()}
              to={getPagePath(info.link)}
              data-element-id={info.dataElementId}
            >
              <CustomText
                fontSizePx={14}
                as="span"
                marginBottomPx={20}
                font="font-regular"
                lineHeightPx={17}
              >
                {info.label}
              </CustomText>
            </Link>
          </li>
        ),
      });
    });

    setMenuContent(categoryItemTemp);
  }, [categoryColumns]);

  /* PRINT VIEW ALL BUTTON */
  const printViewAll = () => {
    const filters: { key: string; value: string }[] = [];

    /*  ADD FILTERS IF PRESENT IN COLUMN  */
    if (columnStructure.facet?.facetName && columnStructure.facet?.value) {
      filters.push({
        key: columnStructure.facet?.facetName as string,
        value: columnStructure.facet?.value as string,
      });
    }

    /*  PRINT VIEW ALL BUTTON FOR EACH COLUMN */
    return (
      <ViewAllContainer>
        <LinkButton
          uppercase
          underline={false}
          onClick={() => {
            toggleMenu(false);
            setToggleMenu("");
            resetScrollPosition();
            history.push(getPagePath(composeUrlWithParams(baseUrl, filters)));
          }}
          data-element-id={`MainNav_Products_${identifier}_${title}_ViewAll`}
        >
          {translateLabel("VIEW_ALL")}
        </LinkButton>
      </ViewAllContainer>
    );
  };

  /* RENDER */
  return (
    <div>
      <UrlList>
        <HeaderListContainer>
          <CustomText
            fontSizePx={18}
            as="span"
            marginBottomPx={20}
            data-element-id={`MainNav_Products_${identifier}_${title}`}
          >
            {translateLabel(title)}
          </CustomText>
          <NewTag position={externalReferenceNewTag[`${identifier}.${title}`]}></NewTag>
        </HeaderListContainer>
        <ListItemsContainer>
          {menuContent.map((_) => {
            return (
              <React.Fragment key={identifier + _.label + nameCategory + title}>
                {<div key={identifier + _.label + nameCategory + title}>{_.content}</div>}
              </React.Fragment>
            );
          })}
        </ListItemsContainer>
      </UrlList>
      {columnStructure.viewAllButton && printViewAll()}
    </div>
  );
};

export const HeaderListContainer = styled.li`
  display: flex;
`;

const UrlList = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 2.75rem 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem;
  min-width: 13rem;
  padding: 1.875rem 0.5rem 0rem;

  li {
    max-width: 14rem;
  }
`;

const ViewAllContainer = styled.div`
  display: flex;
  justify-content: left;
  padding: 2rem 0.5rem 0;

  button {
    &:hover {
      border-bottom: 2px solid ${(props) => props.theme.palette.primary};
    }
  }
`;

const ListItemsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 2rem 2rem 2rem 2rem 2rem 2rem;
  grid-auto-columns: minmax(9rem, 1fr);
  column-gap: 1.5rem;

  div span {
    max-width: 14rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    &:hover {
      font-weight: bold;
    }
  }
`;

export default MenuColumn;
