export const sendAnalyticsData = (obj: any): void => {
  let data2track;
  try {
    data2track = obj;
  } catch (error) {
    console.log("Error pushing data to tealium", error);
  } finally {
    if (data2track) {
      window.tealium_data2track.push(data2track);
    }
  }
};
