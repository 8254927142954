import { Address } from "../store/cart/cartInterfaces";
import {
  SuborderApprovalHeader,
  SuborderApprovalHeaderAddress,
} from "../store/orders-to-approve/ordersToApproveInterface";

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// ORDER DETAIL //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////// MAPPINGS ////////////////////////////////////

export const mapSuborderApprovalHeader = (headers: any[]): SuborderApprovalHeader[] => {
  return headers?.map((header) => {
    return {
      suborderId: header?.suborderApproval?.subOrderId,
      suborderApproval: {
        subOrderId: header?.suborderApproval?.subOrderId,
        reference: header?.suborderApproval?.reference, // order reference
        date: header?.suborderApproval?.date, // order date
        shopcode: header?.suborderApproval?.shopcode, // web shop order number
        items: header?.suborderApproval?.items, // items
        username: header?.suborderApproval?.username, // ordered by
        status: header?.suborderApproval?.status, // status: 0 - wait for approval; 1 - approved
      },
      suborderApprovalShipTo: mapSuborderApprovalHeaderAddress(header?.suborderApprovalShipTo),
      suborderApprovalSoldTo: mapSuborderApprovalHeaderAddress(header?.suborderApprovalSoldTo),
    };
  });
};

export const mapSuborderApprovalHeaderAddress = (address: any): Address => {
  return {
    address: address?.address1,
    city: address?.city,
    zipcode: address?.zipCode,
    country: address?.country,
    orgentityName: address?.orgentityName,
    customerName: address?.customerName,
    lastname: address?.lastname,
  };
};

////////////////////////////////////// MISC //////////////////////////////////////

export const printSuborderApprovalHeaderAddress = (
  _?: SuborderApprovalHeaderAddress
): string | undefined => {
  if (!_) return undefined;

  const address = _?.address ? _.address + ", " : "";
  const zipcode = _?.zipcode ? _.zipcode + " " : "";
  const city = _?.city ? _.city + " " : "";
  const country = _?.country ? "- " + _.country : "";

  return address + zipcode + city + country;
};

export const printSuborderApprovalHeaderCustomerId = (
  _?: SuborderApprovalHeaderAddress
): string | undefined => {
  if (!_) return undefined;

  const orgentityName = _?.orgentityName ? _.orgentityName + " - " : "- ";
  const customerName = _?.customerName ? _.customerName : "";

  return orgentityName + customerName;
};

export const getStatusLabel = (status?: string): string => {
  if (status === "0") return "ORDER_TO_BE_APPROVED_DETAILS_STATUS_WAITING";
  if (status === "1") return "ORDER_TO_BE_APPROVED_DETAILS_STATUS_APPROVED";
  return "-";
};
