import { Status, Step } from "../components/styled-UI/Stepper";
import { GenericCustomObject } from "../interfaces/mainInterfaces";
import {
  BrandDetail,
  Delivery,
  DeliveryDetail,
  OrderDetailsResult,
  OrderDetailsSummary,
  OrderPrescription,
  SkuDetail,
  SkuDetailSummary,
  TrackingData,
  TrackingMetroGlobal,
  TrackingMetroSingleShipment,
} from "../store/order-history/orderHistoryInterface";
import { formatDate } from "./dateUtils";

export const orderHistoryStatusList: GenericCustomObject = {
  "00": "ORDER_HISTORY_STATUS_PLACED",
  "01": "ORDER_HISTORY_STATUS_ACQUIRED",
  "02": "ORDER_HISTORY_STATUS_ACQUIRED",
  "03": "ORDER_HISTORY_STATUS_ACQUIRED",
  "04": "ORDER_HISTORY_STATUS_CONFIRMED",
  "05": "ORDER_HISTORY_STATUS_CONFIRMED",
  "06": "ORDER_HISTORY_STATUS_PART_SHIPPED",
  "07": "ORDER_HISTORY_STATUS_SHIPPED",
  "08": "ORDER_HISTORY_STATUS_REJECTED",
  "09": "ORDER_HISTORY_STATUS_BLOCKED",
  "10": "ORDER_HISTORY_STATUS_BLOCKED",
  "11": "ORDER_HISTORY_STATUS_ACQUIRED",
  "26": "ORDER_HISTORY_STATUS_IN_PROGRESS",
  "41": "ORDER_HISTORY_STATUS_RECEIVED",
  "42": "ORDER_HISTORY_STATUS_ENTERED",
  "43": "ORDER_HISTORY_STATUS_REORCHESTRATION",
  "44": "ORDER_HISTORY_STATUS_WORK_IN_PROGRESS",
  "45": "ORDER_HISTORY_STATUS_LAB_ERROR",
  "46": "ORDER_HISTORY_STATUS_READY_TO_BE_SHPD",
  "47": "ORDER_HISTORY_STATUS_SHIPPED",
  "48": "ORDER_HISTORY_STATUS_INVOICED",
  "49": "ORDER_HISTORY_STATUS_CANCELLED",
  R: "ORDER_HISTORY_STATUS_ACQUIRED",
  S: "ORDER_HISTORY_STATUS_RETURNED",
  Z: "ORDER_HISTORY_STATUS_DELETE",
  A: "ORDER_HISTORY_STATUS_ERROR",
  W: "ORDER_HISTORY_STATUS_APP_WAITING",
  N: "ORDER_HISTORY_STATUS_APP_DENIED",
  M: "ORDER_HISTORY_STATUS_ACQUIRING",
  FALLBACK: "ORDER_HISTORY_STATUS_FALLBACK",
};

export const orderHistoryOrderTypeList: GenericCustomObject = {
  1: "ORDER_HISTORY_ORDER_TYPE_STANDARD",
  2: "ORDER_HISTORY_ORDER_TYPE_AFA",
  3: "ORDER_HISTORY_ORDER_TYPE_SPARE_PARTS",
  4: "ORDER_HISTORY_ORDER_TYPE_RX_FRAME_TO_COME",
  5: "ORDER_HISTORY_ORDER_TYPE_RX_LENS_ONLY",
  6: "ORDER_HISTORY_ORDER_TYPE_RX_COMPLETE_JOB",
  7: "ORDER_HISTORY_ORDER_TYPE_FLUID",
  8: "ORDER_HISTORY_ORDER_TYPE_RMX",
  9: "ORDER_HISTORY_ORDER_TYPE_SMARTGLASSES",
  10: "ORDER_HISTORY_ORDER_TYPE_ONE_PORTAL",
  M4C_CUSTOM: "ORDER_HISTORY_ORDER_TYPE_M4C_CUSTOM",
  SALES: "ORDER_HISTORY_ORDER_TYPE_SALES",
  SALES_TO_SHOP: "ORDER_HISTORY_ORDER_TYPE_SALES_TO_SHOP",
  SUBSTITUTION: "ORDER_HISTORY_ORDER_TYPE_SUBSTITUTION",
  RX_COMPLETE_JOB: "ORDER_HISTORY_ORDER_TYPE_RX_COMPLETE_JOB",
  "EL LENSES": "ORDER_HISTORY_ORDER_TYPE_EL_LENSES",
  ONE_PORTAL: "ORDER_HISTORY_ORDER_TYPE_ONE_PORTAL",
};

export const orderHistoryProductCategoryList: GenericCustomObject = {
  FRAME: "ORDER_HISTORY_PRODUCT_CATEGORY_FRAME",
  GOGGLE: "ORDER_HISTORY_PRODUCT_CATEGORY_GOGGLE",
  HELMET: "ORDER_HISTORY_PRODUCT_CATEGORY_HELMET",
  AFA: "ORDER_HISTORY_PRODUCT_CATEGORY_AFA",
  SMARTGLASSES: "ORDER_HISTORY_PRODUCT_CATEGORY_SMARTGLASSES",
  SPARE_PARTS: "ORDER_HISTORY_PRODUCT_CATEGORY_SPARE_PARTS",
  ONE_PORTAL: "ORDER_HISTORY_PRODUCT_CATEGORY_ONE_PORTAL",
  LENSES: "ORDER_HISTORY_PRODUCT_CATEGORY_LENSES",
  SAFETY: "ORDER_HISTORY_PRODUCT_CATEGORY_SAFETY",
  INSTRUMENTS: "ORDER_HISTORY_PRODUCT_CATEGORY_INSTRUMENTS",
};

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// MISC UTILS ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const getStepStatus = (
  step: TrackingMetroSingleShipment | TrackingMetroGlobal
): Status | undefined => {
  if (step.iswarningstep) return "warning";
  if (step.isshowerror) return "error";
  if (step.isrxcell) return "progress"; // order is IMPORTANT: warning and error should take precedence
};

export const getStepsFromTrackingMetro = (
  trackingMetro: TrackingMetroSingleShipment[] | TrackingMetroGlobal[],
  translateFunc: (label: string) => string
): Step[] => {
  const newSteps: Step[] = [];

  trackingMetro.forEach((_: TrackingMetroSingleShipment | TrackingMetroGlobal) => {
    const percentage =
      _.isrxcell && _.rxcompletionpercentage !== undefined && _.rxcompletionpercentage !== null
        ? Number(_.rxcompletionpercentage)
        : undefined;

    newSteps.push({
      label: translateFunc(`ORDER_HISTORY_STATUS_${_.stepmessagekey.replace(/ /g, "_")}`),
      identifier: _.stepmessagekey,
      status: getStepStatus(_),
      type: _.isrxcell ? "progress-bar" : "default",
      percentage: percentage,
    });
  });
  return newSteps;
};

export const getLastStepFromTrackingMetro = (
  trackingMetro?: TrackingMetroSingleShipment[] | TrackingMetroGlobal[]
): number => {
  if (!trackingMetro) return 0;

  let currentStep = 0;

  trackingMetro.forEach((_, index: number) => {
    if (_.stepfilled === "X") {
      currentStep =
        index + 1 < trackingMetro.length ? Number(_.steporder ?? 0) : Number(_.steporder ?? 0) + 1;
    }
  });
  return currentStep;
};

export const getStatusLabel = (status?: string): string => {
  if (!status || status === "null") return orderHistoryStatusList["FALLBACK"];
  if (status in orderHistoryStatusList) return orderHistoryStatusList[status];
  else return `ORDER_HISTORY_STATUS_${status.replace(/ /g, "_")}`;
};

export const getOrderTypeLabel = (orderType?: string): string => {
  if (!orderType) return "";
  if (orderType.toUpperCase() in orderHistoryOrderTypeList)
    return orderHistoryOrderTypeList[orderType.toUpperCase()];
  else return `ORDER_HISTORY_ORDER_TYPE_${orderType.replace(/ /g, "_").toUpperCase()}`;
};

export const getOrderProductCategoryLabel = (productCategory?: string): string => {
  if (!productCategory) return "";
  if (productCategory.toUpperCase() in orderHistoryProductCategoryList) {
    return orderHistoryProductCategoryList[productCategory.toUpperCase()];
  } else
    return `ORDER_HISTORY_PRODUCT_CATEGORY_${productCategory.replace(/ /g, "_").toUpperCase()}`;
};

export const getQuantityShipped = (delivery: Delivery[] | undefined): string => {
  let quantityShipped = 0;

  if (!delivery || delivery.length === 0) return quantityShipped.toFixed();
  else
    delivery.forEach((_) => {
      if (_?.deliveryQty) quantityShipped = quantityShipped + parseInt(_.deliveryQty);
    });

  return quantityShipped.toFixed();
};

export const isOrderRowRX = (orderType?: string): boolean => {
  return (
    orderType === "RX_LENS_ONLY" ||
    orderType === "RX_FRAME_TO_COME" ||
    orderType === "RX_COMPLETE_JOB" ||
    orderType === "ZX00"
  );
};

export const getIsEssilorRow = (orderType?: string): boolean => {
  return (
    orderType === "EL LENSES" ||
    orderType === "ONE_PORTAL" ||
    orderType === "RX_UNCUT_LENSES" ||
    orderType === "RX_COMPJOB_WO_EM"
  );
};

export const isOrderRowCustom = (orderType?: string): boolean => {
  return (
    orderType === "ZCP1" ||
    orderType === "ZCP2" ||
    orderType === "CUSTOM" ||
    orderType === "FLUID" ||
    orderType === "RMX" ||
    orderType === "M4C_CUSTOM"
  );
};

export const isOrderEssilor = (orderType?: string): boolean => {
  return (
    orderType === "ONE_PORTAL" ||
    orderType === "EL LENSES" ||
    orderType === "RX_UNCUT_LENSES" ||
    orderType === "RX_COMPJOB_WO_EM"
  );
};

export const showMoreDetailsButton = (
  orderType?: string,
  prescription?: OrderPrescription | null
): boolean => {
  return (
    isOrderRowCustom(orderType) ||
    (isOrderRowRX(orderType) && !!prescription) ||
    (isOrderEssilor(orderType) && !!prescription)
  );
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// ORDER DETAILS //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const mapOrderDetailsResult = (data: any): OrderDetailsResult => {
  return {
    orderType: data?.orderType,
    orderNo: data?.orderNo,
    customer: data?.customer,
    orderReferenceDate: data?.orderReferenceDate,
    orderStatus: data?.orderStatus,
    glassesNo: data?.glassesNo,
    nonGlassesNo: data?.nonGlassesNo,
    street: data?.street,
    city: data?.city,
    country: data?.country,
    postalCode: data?.postalCode,
    phone: data?.phone,
    email: data?.email,
    name: data?.name,
    user: data?.user,
    customerServiceNote: data?.customerServiceNote,
    reference: data?.reference,
    rxId: data?.rxId,
    trackingMetroGlobal: mapTrackingMetroGlobal(data?.trackingMetroGlobal),
    deliveryDetail: mapDeliveryDetail(data?.deliveryDetail),
    repeatable: data?.repeatable,
    approvalComment: data?.approvalComment,
    shipToConsumer: data?.shipToConsumer,
    orderReference: data?.orderReference,
    poNumber: data?.poNumber,
    shipTo: data?.shipTo,
    voucher: data?.voucher,
    customerReference: data?.customerReference,
  };
};

export const mapTrackingData = (data: any): TrackingData => {
  return {
    showTrackingBar: data?.showTrackingBar,
    showErrorMessageKey: data?.showErrorMessageKey,
    showTrackingLink: data?.showTrackingLink,
    trackingLink: data?.trackingLink,
    trackingLabel: data?.trackingLabel,
  };
};

export const mapTrackingMetroGlobal = (data: any): TrackingMetroGlobal[] => {
  return data?.map(
    (_: any): TrackingMetroGlobal => {
      return {
        stepmessagekey: _?.stepmessagekey,
        isrxcell: _?.isrxcell,
        rxcompletionpercentage: _?.rxcompletionpercentage,
        stepfilled: _?.stepfilled,
        steporder: _?.steporder,
        isshowerror: _?.isshowerror,
        showerrormessagekey: _?.showerrormessagekey,
        iswarningstep: _?.iswarningstep,
      };
    }
  );
};

export const mapTrackingMetroSingleShipment = (data: any): TrackingMetroSingleShipment[] => {
  return data?.map(
    (_: any): TrackingMetroSingleShipment => {
      return {
        stepmessagekey: _?.stepmessagekey,
        isrxcell: _?.isrxcell,
        rxcompletionpercentage: _?.rxcompletionpercentage,
        stepfilled: _?.stepfilled,
        steporder: _?.steporder,
        isshowerror: _?.isshowerror,
        showerrormessagekey: _?.showerrormessagekey,
        iswarningstep: _?.iswarningstep,
      };
    }
  );
};

export const mapDeliveryDetail = (data: any[]): DeliveryDetail[] => {
  return data?.map(
    (_): DeliveryDetail => {
      return {
        orderNo: _?.orderNo,
        deliveryNotes: _?.deliveryNotes,
        deliveryQty: _?.deliveryQty,
        trackingMetroSingleShipment: mapTrackingMetroSingleShipment(_?.trackingMetroSingleShipment),
        trackingData: mapTrackingData(_?.trackingData),
        brandDetails: mapBrandDetail(_?.brandDetails),
      };
    }
  );
};

export const mapBrandDetail = (data: any[]): BrandDetail[] => {
  return data?.map(
    (_): BrandDetail => {
      return {
        brand: _?.brand,
        brandGlassesNo: _?.brandGlassesNo,
        notGlassesNo: _?.notGlassesNo,
        zskuDetail: mapSkuDetail(_?.zskuDetail, _),
      };
    }
  );
};

export const mapSkuDetail = (data: any[], brandDetail: BrandDetail): SkuDetail[] => {
  return data?.map(
    (_): SkuDetail => {
      return {
        itemId: _?.itemId,
        collection: _?.collection,
        schedLine: _?.schedLine,
        description: _?.description,
        quantity: _?.quantity,
        deliveryQuantity: _?.deliveryQuantity ?? "0",
        itemNote: _?.itemNote,
        revokeDetail: _?.revokeDetail,
        webItemNoDetail: _?.webItemNoDetail,
        itemIdentifier: _?.itemIdentifier,
        netValueDetail: _?.netValueDetail,
        status: _?.status,
        trackingMetroSingleShipment: _?.trackingMetroSingleShipment, // TODO: map
        prescription: _?.prescription, // TODO
        j3asize: _?.j3asize,
        j3arqda: _?.j3arqda,
        edatu: _?.edatu,
        absta: _?.absta,
        url: _?.url,
        channel: _?.channel,
        orderTypeSku: _?.orderTypeSku,
        price: _?.price,
        customerReference: _?.customerReference,
        customInfo: _?.customInfo,
        currency: _?.currency,
        upc: _?.upc,
        size: _?.size,
        brand: _?.brandDetail?.brand,
        color: _?.color,
        model: _?.model,
        productCode: _?.productCode,
      };
    }
  );
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// ORDER SUMMARY //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const mapOrderDetailsSummary = (data: any[]): OrderDetailsSummary[] => {
  return data?.map(
    (_): OrderDetailsSummary => {
      return {
        brand: _?.brand,
        zskuDetail: mapSkuDetailSummary(_.zskuDetail),
      };
    }
  );
};

export const mapSkuDetailSummary = (data: any[]): SkuDetailSummary[] => {
  return data?.map(
    (_): SkuDetailSummary => {
      return {
        itemId: _?.itemId,
        schedLine: _?.schedLine,
        description: _?.description,
        quantity: _?.quantity,
        currency: _?.currency,
        deliveryQuantity: _?.deliveryQuantity ?? "0",
        itemNote: _?.itemNote,
        revokeDetail: _?.revokeDetail,
        webItemNoDetail: _?.webItemNoDetail,
        itemIdentifier: _?.itemIdentifier,
        netValueDetail: _?.netValueDetail,
        status: _?.status,
        trackingMetroSingleShipment: _?.trackingMetroSingleShipment, // TODO: map
        prescription: _?.prescription, // TODO
        j3asize: _?.j3asize,
        j3arqda: _?.j3arqda,
        edatu: _?.edatu,
        absta: _?.absta,
        url: _?.url,
        channel: _?.channel,
        orderTypeSku: _?.orderTypeSku,
        price: _?.price,
        delivery: mapDelivery(_?.delivery),
        customerReference: _?.customerReference,
        customInfo: _?.customInfo,
        productCode: _?.productCode,
      };
    }
  );
};

export const mapDelivery = (data: any): Delivery[] => {
  return data?.map(
    (__: any): Delivery => {
      return {
        orderNo: __?.orderNo,
        deliveryItem: __?.deliveryItem,
        deliveryQty: __?.deliveryQty,
        goodIssueDate: __?.goodIssueDate,
        carrierCode: __?.carrierCode,
        carrierName: __?.carrierName,
        deliveryBlock: __?.deliveryBlock,
        url: __?.url,
      };
    }
  );
};

export const formatSkuDetailDate = (date: any, currentLocale: string): string => {
  return date ? formatDate(new Date(String(date)), "P", currentLocale, "Europe/London") : "";
};

export const isPrescriptionWithoutSpecialParams = (prescription: OrderPrescription): boolean => {
  return (
    !prescription?.itemLeft?.headCapeAngle &&
    !prescription?.itemLeft?.eyecode &&
    !prescription?.itemLeft?.ercd &&
    !prescription?.itemLeft?.wearerInitial &&
    !prescription?.itemLeft?.inset &&
    !prescription?.itemRight?.inset &&
    !prescription?.itemLeft?.progressionLength
  );
};
