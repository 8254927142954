import React, { useEffect } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import CustomText from "../../styled-UI/CustomText";
import Button from "../../styled-UI/Button";
import { RequestStatus } from "../../../interfaces/mainInterfaces";
import { cleanStringForLabel } from "../../../utils/utils";

interface Props {
  submitRequest: () => void;
  submitStatus: RequestStatus;
  setHasSubmittedRequest?: (val: boolean) => void;
  errorInUploading?: boolean;
  isLoading?: boolean;
  errorLabel?: string | null;
}

const ServiceRequestPopupSubmit = ({
  submitRequest,
  submitStatus,
  errorInUploading = false,
  isLoading = false,
  setHasSubmittedRequest,
  errorLabel,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  useEffect(() => {
    if (submitStatus === "SUCCESS") {
      setHasSubmittedRequest && setHasSubmittedRequest(true);
    }
  }, [submitStatus]);

  return (
    <ButtonContainer>
      <CustomText as="p" fontSizePx={13} lineHeightPx={18} marginBottomPx={16} color="red">
        {(submitStatus === "ERROR" || errorInUploading) &&
          translateLabel(cleanStringForLabel(errorLabel) || "ERROR_POPUP_DEFAULT_MESSAGE")}
      </CustomText>

      <CustomText as="p" fontSizePx={13} lineHeightPx={18} marginBottomPx={16} color="green">
        {submitStatus === "SUCCESS" && translateLabel("SERVICE_REQUEST_POPUP_REQUEST_SENT")}
      </CustomText>
      <Button
        type="primary"
        buttonType="submit"
        onClick={submitRequest}
        isLoading={submitStatus === "LOADING" || isLoading}
      >
        {translateLabel("SERVICE_REQUEST_POPUP_SEND")}
      </Button>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

export default ServiceRequestPopupSubmit;
