import React from "react";
import styled from "styled-components/macro";
import WrapperLayout from "../../layouts/WrapperLayout";

interface Props {
  firstColumn: JSX.Element;
  backgroundColor?: "none" | "light" | "lightBlue";
  noWrapper?: boolean;
  marginPx?: number | null;
  landingNavbar?: boolean;
  isWrappedLanding?: boolean;
}

const OneColumnRow = ({
  firstColumn,
  backgroundColor,
  noWrapper = false,
  marginPx,
  landingNavbar = false,
  isWrappedLanding = false,
}: Props): JSX.Element => {
  return noWrapper ? (
    <OneColumn backgroundColor={backgroundColor} marginPx={marginPx}>
      {firstColumn}
    </OneColumn>
  ) : (
    <WrapperLayout landingNavbar={landingNavbar} isWrappedLanding={isWrappedLanding}>
      <OneColumn backgroundColor={backgroundColor} marginPx={marginPx}>
        {firstColumn}
      </OneColumn>
    </WrapperLayout>
  );
};

const OneColumn = styled.div<{
  backgroundColor: "none" | "light" | "lightBlue" | undefined;
  marginPx?: number | null;
}>`
  background-color: ${(props) => {
    switch (props.backgroundColor) {
      case "light":
        return props.theme.palette.background.light;
      case "lightBlue":
        return props.theme.palette.background.lightBlue;
    }
  }};

  //override from props
  margin-top: ${(props) => props.marginPx && `${props.marginPx / props.theme.baseFontSize}rem`};
  margin-bottom: ${(props) => props.marginPx && `${props.marginPx / props.theme.baseFontSize}rem`};
`;
export default OneColumnRow;
