import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";
import { selectCurrencyFormat } from "../../store/user/userSlice";
import { ItemPrice } from "../../interfaces/productInterface";
import CustomText from "../styled-UI/CustomText";
import CurrencyFormatter from "./CurrencyFormatter";
import clsx from "clsx";
import useDisplayPrice from "../../hooks/useDisplayPrice";
import useCanRender from "../../hooks/useCanRender";
import Loader from "../styled-UI/Loader";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-icon-filled.svg";
import Tooltip from "../styled-UI/Tooltip";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  price?: ItemPrice;
  horizontal?: boolean;
  noBorder?: boolean;
  isLoading?: boolean;
  hideFrom?: boolean;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
  isKeylookOrSimilarTile?: boolean;
  isAfaGogglesHelmetsVertical?: boolean;
}

const PriceTile = ({
  price,
  horizontal,
  noBorder,
  isLoading = false,
  hideFrom: hideFromProps = false,
  isDarkMode = false,
  customFont = null,
  isKeylookOrSimilarTile = false,
  isAfaGogglesHelmetsVertical,
}: Props): JSX.Element | null => {
  const { translateLabel } = useTranslation();
  const hideFrom =
    hideFromProps ||
    (window.location.pathname.includes("pdp") && !isKeylookOrSimilarTile) ||
    window.location.pathname.includes("digital-screen");
  const currencyFormat = useSelector(selectCurrencyFormat);
  const { hideSuggested, hideWholesale, hideAll, priceDisplaySetting } = useDisplayPrice();
  const canRender = useCanRender();

  const showSuggestedPrice =
    priceDisplaySetting === "SUGGESTED_PRICES" && canRender("SUGGESTED_PRICES");

  if (hideAll) return null;
  if (isLoading)
    return (
      <BoxLoader className={clsx(horizontal && "horizontal-tile", noBorder && "no-border")}>
        <Loader sizePx={30} />
      </BoxLoader>
    );
  return price?.pub.unitPrice || price?.opt.unitPrice ? (
    <PriceContainer
      showSuggestedPrice={showSuggestedPrice}
      suggestedPrice={price?.pub.unitPrice as number}
      className={clsx(
        horizontal && "horizontal-tile",
        noBorder && "no-border",
        isAfaGogglesHelmetsVertical && "afa-goggles-helmets"
      )}
    >
      {!hideSuggested && price?.pub.unitPrice ? (
        <ContainerAlignLeft>
          <CustomText
            as="span"
            fontSizePx={11}
            font={customFont ? "custom-regular" : "font-regular"}
            fontFamily={customFont}
            marginBottomPx={7}
            color={isDarkMode ? "white" : "gray-dark"}
            isFlex={false}
          >
            <span>{translateLabel("SUGGESTED_RETAIL_PRICE")}:</span>

            <SvgContainer>
              <Tooltip
                content={
                  <TooltipContent>{translateLabel("SRP_DISCLAIMER_DESCRIPTION")}</TooltipContent>
                }
              >
                <div className="svg-container">
                  <InfoIcon />
                </div>
              </Tooltip>
            </SvgContainer>
          </CustomText>

          <CustomText
            as="span"
            fontSizePx={18}
            font={customFont ? "custom-regular" : "font-regular"}
            fontFamily={customFont}
            color={isDarkMode ? "white" : "primary"}
          >
            <CurrencyFormatter
              currency={price?.pub?.currency || currencyFormat?.currency.pub}
              locale={currencyFormat.locale}
              value={price?.pub.unitPrice}
            />
          </CustomText>
        </ContainerAlignLeft>
      ) : (
        <PriceContainerEmpty />
      )}
      {!hideWholesale && price?.opt.unitPrice && (
        <ContainerAlignRight
          className={clsx(horizontal && "horizontal-tile", hideFrom && "price-hide-from")}
        >
          <CustomText
            as="span"
            fontSizePx={11}
            font={customFont ? "custom-regular" : "font-regular"}
            fontFamily={customFont}
            marginBottomPx={7}
            color={isDarkMode ? "white" : "gray-dark"}
            className="price-from"
          >
            {`${translateLabel("FROM_PRICE")}:`}
          </CustomText>

          <CustomText
            as="span"
            fontSizePx={18}
            font={customFont ? "custom-bold" : "font-bold"}
            fontFamily={customFont}
            color={isDarkMode ? "white" : "primary"}
          >
            <CurrencyFormatter
              currency={price?.opt?.currency || currencyFormat.currency.opt}
              locale={currencyFormat.locale}
              value={price?.opt.unitPrice}
            />
          </CustomText>
        </ContainerAlignRight>
      )}
    </PriceContainer>
  ) : (
    <PriceContainerEmpty />
  );
};

const PriceContainer = styled.div<{ suggestedPrice: number; showSuggestedPrice: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${(props) =>
    props.showSuggestedPrice && !props.suggestedPrice ? "0" : "0.8125rem 0 1rem"};
  border-top: 1px solid
    ${(props) =>
      props.showSuggestedPrice && !props.suggestedPrice
        ? "unset"
        : props.theme.palette.gray.medium};
  width: 100%;

  &.horizontal-tile {
    flex-direction: column-reverse;
    border-top: unset;
    padding: 0;
  }

  &.no-border {
    border-top: unset;
  }

  &.afa-goggles-helmets {
    padding: ${(props) =>
      props.showSuggestedPrice && !props.suggestedPrice ? "0" : "1rem 0 1rem"};
  }
`;

const PriceContainerEmpty = styled.div`
  height: 2.68rem;
`;

const ContainerAlignLeft = styled.div`
  text-align: left;
  max-width: 70%;

  &.horizontal-tile {
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
`;

const SvgContainer = styled.span`
  margin-left: 0.2rem;

  .svg-container {
    position: relative;

    :hover > svg {
      opacity: 1;
    }

    > svg {
      margin: unset;
      vertical-align: text-top;
      opacity: 0.4;
      width: 14px !important;
      height: 14px !important;
    }
  }
`;

const TooltipContent = styled.div`
  max-width: 180px;
  font-family: ${(props) => props.theme.fonts.fontMedium};
  font-size: 0.813rem;
`;

const ContainerAlignRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.horizontal-tile {
    align-items: baseline;
    margin-bottom: 1rem;
  }

  &.price-hide-from {
    justify-content: "flex-end";

    .price-from {
      visibility: hidden;
    }
  }
`;

const BoxLoader = styled.div`
  border-top: 1px solid ${(props) => props.theme.palette.gray.medium};
  display: flex;
  justify-content: center;
  padding: 0.5rem;

  &.horizontal-tile {
    flex-direction: column-reverse;
    border-top: unset;
    padding: 0;
  }

  &.no-border {
    border-top: unset;
  }
`;

export default PriceTile;
