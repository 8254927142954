import React, { useEffect } from "react";
import styled from "styled-components/macro";

import ProgressBar from "./ProgressBar";
import Step, { StepType } from "./Step";

export type Status = "error" | "progress" | "complete" | "warning";
export type BackgroungColor = "gray-light" | "background-light" | "white";

export type Step = {
  label: string;
  identifier: string;
  type?: "default" | "progress-bar";
  status?: Status;
  percentage?: number;
};

interface Props {
  steps: Step[];
  active: number;
  setActive?: (i: number) => void;
  backgroundColor: BackgroungColor;
  maxWidth?: boolean;
  backgroundVariant?: boolean; //When set, active step will have primary background
}

const getStepType = (index: number, active: number): StepType => {
  if (index === active) return "active";
  if (index > active) return "next";
  return "prev";
};

const Stepper = ({
  steps,
  active,
  setActive,
  backgroundColor,
  maxWidth,
  backgroundVariant = false,
}: Props): JSX.Element => {
  return (
    <StyledContainer backgroundColor={backgroundColor}>
      {steps.map((step, index) => {
        const stepType = getStepType(index + 1, active);

        return (
          // key = index instead of label to avoid issues w/ translation (label is translated AFTER the first render of Stepper, so it's initially "")
          <div className="step" key={step.identifier || index}>
            {step.type === "progress-bar" ? (
              <ProgressBar
                status={step.status ?? "progress"}
                label={step.label}
                percentage={step.percentage ? step.percentage : 0}
              />
            ) : (
              <Step
                index={index + 1}
                label={step.label}
                status={step.status}
                stepType={stepType}
                backgroundVariant={backgroundVariant}
                hasOnClick={!!setActive}
                onClick={() => {
                  stepType === "prev" && setActive?.(index + 1);
                }}
                maxWidth={maxWidth}
              />
            )}
          </div>
        );
      })}
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ backgroundColor: BackgroungColor }>`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  @media print {
    background-color: ${(props) => {
      switch (props.backgroundColor) {
        case "white":
          return props.theme.palette.white;
        case "gray-light":
          return props.theme.palette.gray.light;
        case "background-light":
          return props.theme.palette.background.light;
      }
    }};
    -webkit-print-color-adjust: exact;
  }

  &::before {
    position: absolute;
    top: calc(1rem + 1px);
    left: 50%;
    transform: translateX(-50%);
    content: "";
    width: 100%;
    height: 0.125rem;
    background-color: ${(props) => props.theme.palette.gray.medium};
    z-index: 1;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;

    &:first-child::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 50%;
      height: 2rem;
      background-color: ${(props) => {
        switch (props.backgroundColor) {
          case "white":
            return props.theme.palette.white;
          case "gray-light":
            return props.theme.palette.gray.light;
          case "background-light":
            return props.theme.palette.background.light;
        }
      }};
      z-index: 1;
    }

    &:last-child::after {
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      width: 50%;
      height: 2rem;
      background-color: ${(props) => {
        switch (props.backgroundColor) {
          case "white":
            return props.theme.palette.white;
          case "gray-light":
            return props.theme.palette.gray.light;
          case "background-light":
            return props.theme.palette.background.light;
        }
      }};
      z-index: 1;
    }
  }
`;

export default Stepper;
