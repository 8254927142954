import { isEqual, union, xorWith } from "lodash";
import {
  MenuFacet,
  MenuStructureKeys,
  MenuStructureKeysWithLenses,
  menuStructure,
} from "../components/layouts/header/navbar/categories-menu/category-item/menuStructure";
import { serviceMenu } from "../components/layouts/header/navbar/services-menu/serviceSections";
import {
  CategoriesDataHandler,
  CategoriesInfo,
} from "../components/pages/homepage/most-used/useCategoriesData";
import { navbarSections } from "../components/pages/my-account/account-navbar/navbarSections";
import { CategoryColumnInfo, MenuDivInfo } from "../hooks/useMenuColumnsInfo";
import { FacetView } from "../interfaces/facetInterfaces";
import {
  Brand,
  BrandsAndEssilor,
  FirstLevelMenu,
  SecondLevelMenu,
} from "../interfaces/menuInterfaces";
import {
  MostUsedCatalogueInfo,
  MostUsedCatalogueInfoFacets,
  MostUsedCategory,
  MostUsedChip,
  MostUsedChipCatalogue,
  MostUsedChipCatalogueLocal,
  MostUsedChipLocal,
  MostUsedOption,
  instanceOfMostUsedChipCatalogue,
} from "../interfaces/mostUsedInterfaces";
import { getPagePath } from "../routing/routesUtils";
import { QueryParams } from "../store/search/searchInterfaces";
import { LensLink, LensesColumn } from "../store/store/storeInterfaces";
import { Door } from "../store/user/userInterfaces";
import { appendFiltersToURLSearchParams, getFiltersFromParams } from "./filterUtils";
import { ColBrandGroup, LENSES_COLUMN_BASE_URL } from "./menuUtils";
import { composeUrlWithParams } from "./utils";

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// MISC UTILS //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const formatMostUsedIdentifier = (id?: string): string =>
  id
    ? id
        .toUpperCase()
        .replace(/\s/g, "-")
        .replace(/,/g, "")
        .replace(/[\s-]+$/, "")
    : "";

export const getCategoryFromCatalogueBookmark = (
  bookmark: MostUsedChipCatalogue
): MenuStructureKeysWithLenses =>
  bookmark.catalogueInfo.menuCategory?.toUpperCase() as MenuStructureKeysWithLenses;

//////////////////////////////////////////////////////////////////////////////////
////////////////////// LOCAL CHIP GENERATION FROM API RESULT /////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Generate local chips from the API's data by:
 *    - adding urls + labels for Services&MyAccount bookmarks
 *    - reverse-engineering the labels for Catalogue bookmarks from the third-level menu
 *
 * @param {MostUsedChip[]} mostUsedChips
 * @param {CategoriesDataHandler} categoriesData
 * @param {MostUsedChipLocal[]} availableServicesMyAccountChips
 * @param {BrandsAndEssilor} brandAndEssilor
 * @param {{
 *     [brand: string]: string;
 *   }} brandLabels
 * @param {LensesColumn[]} lensesMenu
 * @return {*}  {MostUsedChipLocal[]}
 */
export const getSelectedChips = (
  mostUsedChips: MostUsedChip[],
  availableServicesMyAccountChips: MostUsedChipLocal[],
  categoriesData: CategoriesDataHandler,
  brandAndEssilor: BrandsAndEssilor
): MostUsedChipLocal[] => {
  const newSelectedChips: MostUsedChipLocal[] = availableServicesMyAccountChips.filter(
    (_) => _.showAsDefault
  );

  mostUsedChips.forEach((bookmark) => {
    let matchedChip: MostUsedChipLocal | undefined;

    switch (bookmark.type) {
      case "Catalogue":
        matchedChip =
          mostUsedAvailableChipsInHP.includes(bookmark.name) &&
          isCurrentCategoryInCatalogue(categoriesData.categoriesInfo, bookmark)
            ? getCatalogueChipFromMenu(bookmark)
            : undefined;
        break;
      case "Lenses":
        matchedChip = isCurrentCategoryInCatalogue(categoriesData.categoriesInfo, bookmark)
          ? getLensesChipFromMenu(bookmark)
          : undefined;
        break;
      case "Services&MyAccount":
        matchedChip = getServicesMyAccountChipFromId(
          bookmark.name,
          availableServicesMyAccountChips
        );
        break;
      case "Brands":
        matchedChip = isBrandOrEssilorInCatalogue(
          bookmark.catalogueInfo.facets[0].facetValue,
          bookmark.name,
          brandAndEssilor.brands
        )
          ? getBrandsChipFromId(bookmark)
          : undefined;
        break;
      case "Essilor":
        matchedChip = isBrandOrEssilorInCatalogue(
          bookmark.catalogueInfo.facets[0].facetValue,
          bookmark.name,
          brandAndEssilor.brandsEssilor
        )
          ? getEssilorChipFromId(bookmark)
          : undefined;
        break;
    }
    if (matchedChip) newSelectedChips.push(matchedChip);
  });

  return newSelectedChips;
};

/**
 * IMPORTANT!!!! : this utils check if chips is available according to catalogue. Used only for catalogue chips NOT SERVICE&ACCOUNT
 * EX. If Eyeglasses is sent in /mostused API but user has no items to show in EYEGLASSES, then chip must not be showed
 *
 * @param {MostUsedChipCatalogue} bookmark
 * @return {*}  {(MostUsedChipLocal | undefined)}
 */
export const isCurrentCategoryInCatalogue = (
  categoriesInfo: CategoriesInfo[],
  bookmark: MostUsedChipCatalogue
): boolean => {
  const currentCategory = categoriesInfo.find(
    (_) => _.category === getCategoryFromCatalogueBookmark(bookmark)
  );

  if (!currentCategory || currentCategory.status === "ERROR") return false;
  return true;
};

/**
 * IMPORTANT!!!! : this utils check if chips is available according to catalogue. Used only for catalogue chips NOT SERVICE&ACCOUNT
 * EX. If Eyeglasses is sent in /mostused API but user has no items to show in EYEGLASSES, then chip must not be showed
 *
 * @param {MostUsedChipCatalogue} bookmark
 * @return {*}  {(MostUsedChipLocal | undefined)}
 */
export const isBrandOrEssilorInCatalogue = (id: string, label: string, brands: Brand): boolean => {
  return brands[id] === label;
};

/**
 * Generate catalogue chips
 *
 * @param {MostUsedChipCatalogue} bookmark
 * @return {*}  {(MostUsedChipLocal | undefined)}
 */
export const getCatalogueChipFromMenu = (
  chip: MostUsedChipCatalogue
): MostUsedChipLocal | undefined => {
  return {
    name: chip.name,
    type: "Catalogue",
    label: chip.name,
    url: chip.url,
    catalogueInfo: chip.catalogueInfo,
  };
};

/**
 * Generate lenses chips
 *
 * @param {MostUsedChipCatalogue} bookmark
 * @return {*}  {(MostUsedChipLocal | undefined)}
 */
export const getLensesChipFromMenu = (
  chip: MostUsedChipCatalogue
): MostUsedChipLocal | undefined => {
  return {
    name: chip.name,
    type: "Lenses",
    label: chip.name,
    url: chip.url,
    catalogueInfo: chip.catalogueInfo,
  };
};

/**
 * Generate local chips from the API's data for Services&MyAccount by:
 *    - matching them to the chips available for selection, to ensure only those for which
 *      the corresponding privilege is active are displayed
 *    - retrieving the labels and urls from the menu structure
 *      (this is done indirectly, through the chips available for selection, which are built from the menu structure)
 *
 * @param {string} id
 * @param {MostUsedChipLocal[]} availableChips
 * @return {*}  {(MostUsedChipLocal | undefined)}
 */
export const getServicesMyAccountChipFromId = (
  id: string,
  availableChips: MostUsedChipLocal[]
): MostUsedChipLocal | undefined => {
  const matchFromAvailable = availableChips.find((_) => _.name === id);
  if (matchFromAvailable)
    return {
      name: id,
      type: "Services&MyAccount",
      label: matchFromAvailable.label,
      url: matchFromAvailable.url,
      showAsDefault: matchFromAvailable.showAsDefault,
    };
};

/**
 * Generate brands chips
 *
 * @param {MostUsedChipLocal[]} availableChips
 * @return {*}  {(MostUsedChipLocal | undefined)}
 */
export const getBrandsChipFromId = (chip: MostUsedChipCatalogue): MostUsedChipLocal | undefined => {
  return {
    name: chip.name,
    type: "Brands",
    label: chip.name,
    url: chip.url,
    catalogueInfo: chip.catalogueInfo,
  };
};

/**
 * Generate brands chips
 *
 * @param {MostUsedChipLocal[]} availableChips
 * @return {*}  {(MostUsedChipLocal | undefined)}
 */
export const getEssilorChipFromId = (
  chip: MostUsedChipCatalogue
): MostUsedChipLocal | undefined => {
  return {
    name: chip.name,
    type: "Essilor",
    label: chip.name,
    url: chip.url,
    catalogueInfo: chip.catalogueInfo,
  };
};

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////// CATALOGUE LABELS GENERATION //////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Generate labels for Catalogue Chips of all categories except "Lenses"
 *
 * @param {MostUsedChipCatalogue} bookmark
 * @param {FacetView[]} currentCategoryFacets
 * @param {{
 *     [brand: string]: string;
 *   }} brandLabels
 * @return {*}  {(string | undefined)}
 */
const getMostUsedLabelFromCategoryMenu = (
  categoryLabel: string,
  facets: MostUsedCatalogueInfoFacets[],
  currentCategoryFacets: FacetView[],
  brandLabels: {
    [brand: string]: string;
  }
): string | undefined => {
  const labels: string[] = [];
  try {
    facets?.forEach((facet) => {
      const menuFacet = currentCategoryFacets?.find(
        (_) => _.extendedData?.propertyvalue === facet.facetName
      );
      const entry = menuFacet?.entry?.find((_) => _.identifier === facet.facetValue);

      if (menuFacet && entry) {
        if (facet.facetName === "manufacturer.raw") labels.push(brandLabels[facet.facetValue]);
        else labels.push(facet.facetValue === "TRUE" ? menuFacet.name : entry.label);
      } else {
        // if facet was not found we should interrupt and skip the chip
        throw new Error("Facet not found");
      }
    });
  } catch (error) {
    return;
  }

  return categoryLabel + (labels.length > 0 ? "-" + labels.join("-") : "");
};

//////////////////////////////////////////////////////////////////////////////////
////////////////////// SERVICES&MYACCOUNT OPTIONS GENERATION /////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Generate all chips for Services & MyAccount available for selection, by:
 *    - retrieving all relevant links from the serviceMenu
 *    - retrieving all relevant links form the navbarSections
 *    - filtering those whose associated privilege is active
 *    - mapping them into a MostUsedChipLocal object
 *
 * @param {((
 *     privilege?: string | null | undefined,
 *     door?: Door | null | undefined,
 *     onlySubuser?: boolean | undefined
 *   ) => boolean)} canRender
 * @return {*}  { mostUsedService: MostUsedChipLocal[]; mostUsedAccount: MostUsedChipLocal[] }
 */
export const generateServicesAndMyAccountChips = (
  canRender: (
    privilege?: string | null | undefined,
    door?: Door | null | undefined,
    onlySubuser?: boolean | undefined
  ) => boolean,
  translateLabel: (label: string) => string,
  isSubuser?: boolean | null | string,
  mainDoor?: Door | null
): { mostUsedService: MostUsedChipLocal[]; mostUsedAccount: MostUsedChipLocal[] } => {
  const serviceMenuChips = serviceMenu.map((x) => x.links).reduce((x, y) => x.concat(y));
  const navbarSectionsChips = navbarSections.map((x) => x.links).reduce((x, y) => x.concat(y));

  const mostUsedService: MostUsedChipLocal[] = serviceMenuChips
    .filter(
      (x) =>
        x.showInMostUsed && (isSubuser ? canRender(x.privilege, mainDoor) : canRender(x.privilege))
    )
    .map((x) => {
      return {
        name: x.identifier,
        type: "Services&MyAccount",
        label: translateLabel(normalizeChipLabel(x.title, "SERVICE_")),
        url: x.url,
        showAsDefault: x.showAsDefaultInMostUsed,
      };
    });

  const mostUsedAccount: MostUsedChipLocal[] = navbarSectionsChips
    .filter(
      (x) =>
        x.showInMostUsed && (isSubuser ? canRender(x.privilege, mainDoor) : canRender(x.privilege))
    )
    .map((x) => {
      return {
        name: x.identifier,
        type: "Services&MyAccount",
        label: translateLabel(normalizeChipLabel(x.title, "ACCOUNT_")),
        url: x.url,
        showAsDefault: x.showAsDefaultInMostUsed,
      };
    });

  return { mostUsedService, mostUsedAccount };
};

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////// CATALOGUE OPTIONS GENERATION /////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/////////////////// helper functions to map the two different types of catalogue columns (generic categories and lenses)
/////////////////// into a common type that can be managed inside ChipsCatalogueTooltip

/**
 * CATEGORIES CHIPS
 * Generate object used to render checkboxes and radiobuttons in ChipsCatalogueTooltip
 * from the data provided by the useMenuColumnsInfo hook
 *
 * @param {MenuDivInfo[]} menuContentInfo
 * @return {*}  {MostUsedCategory[]}
 */
export const getMostUsedCategoryFromMenuDivInfo = (
  menuContentInfo: MenuDivInfo[]
): MostUsedCategory[] => {
  return menuContentInfo.map((column) => {
    const content: (ColBrandGroup | CategoryColumnInfo)[] = column.isBrandColumn
      ? column.brandColumns
      : column.categoryColumns;

    const columnFacet = column.columnStructure?.facet?.facetName;
    const columnFacetValue = column.columnStructure?.facet?.value;
    let isBrandColumn = column.isBrandColumn;
    const identifier = column.identifier;
    const options: MostUsedOption[] = [];

    switch (identifier.toUpperCase()) {
      case "AFA":
        if (existsInStructure(identifier, column.columnStructure.columnName)) {
          const catInfo: CategoryColumnInfo = getMostUsedAFACategoryColumnInfo(column);
          options.push(getMostUsedOptionFromGenericColumnInfo(catInfo, column.title, columnFacet));
        }
        break;
      case "SMART GLASSES":
        const catInfo: CategoryColumnInfo = getMostUsedSmartGlassesCategoryColumnInfo(column);
        options.push(getMostUsedOptionFromGenericColumnInfo(catInfo, column.title, columnFacet));
        isBrandColumn = false;
        break;
      default:
        content.map((_) => {
          existsInStructure(identifier, _?.facetName, _?.facetValue) &&
            options.push(getMostUsedOptionFromGenericColumnInfo(_, column.title, columnFacet));
        });
    }

    return {
      title: column.title,
      key: column.title,
      isRadio: isBrandColumn,
      columnFacet: columnFacet,
      options: options,
    };
  });
};

const getMostUsedSmartGlassesCategoryColumnInfo = (col: MenuDivInfo): CategoryColumnInfo => {
  return {
    label: col.identifier ?? "",
    keyColumn: col?.keyColumn ?? "",
    link: composeUrlWithParams(col.baseUrl, [
      { key: "PRODUCT_CATEGORY_FILTER" ?? "", value: col.identifier ?? "" },
    ]),
    dataElementId: "",
    facetName: "SMART GLASSES",
    facetValue: "TRUE",
  };
};

const getMostUsedAFACategoryColumnInfo = (col: MenuDivInfo): CategoryColumnInfo => {
  const columnFacetValue = col.columnStructure?.facet?.value;
  const columnFacet = col.columnStructure?.facet?.facetName;

  return {
    label: columnFacetValue ?? "",
    keyColumn: col?.keyColumn ?? "",
    link: composeUrlWithParams(col.baseUrl, [
      { key: columnFacet ?? "", value: columnFacetValue ?? "" },
    ]),
    dataElementId: "",
    facetName: columnFacetValue ?? "",
    facetValue: col.columnStructure.columnName ?? "",
  };
};

const getMostUsedOptionFromGenericColumnInfo = (
  col: ColBrandGroup | CategoryColumnInfo,
  columnTitle: string,
  columnFacet: string | undefined
): MostUsedOption => {
  return {
    label: col.label ?? "",
    url: col.link,
    columnTitle,
    columnFacet,
    facet: { facetName: col.facetName ?? "", facetValue: col.facetValue ?? "" },
  };
};

/**
 * LENSES CHIPS
 * Generate object used to render checkboxes and radiobuttons in ChipsCatalogueTooltip
 * from the data provided by the selectLensesMenu selector
 *
 * @param {LensesColumn[]} lensesColumns
 * @return {*}  {MostUsedCategory[]}
 */
export const getMostUsedCategoryFromLensesColumn = (
  lensesColumns: LensesColumn[]
): MostUsedCategory[] => {
  return lensesColumns.map((column) => {
    return {
      title: column.collectionTitle,
      key: column.collectionTitle,
      isRadio: true,
      columnFacet: undefined,
      options: column.links.map((_) => getMostUsedOptionFromLensesLink(_, column.collectionTitle)),
    };
  });
};

/**
 * BRANDS CHIPS
 *
 * @param {FirstLevelMenu[]} menu
 * @return {*}  {MostUsedCategory[]}
 */
export const getMostUsedBrandsFromMenu = (
  menu: FirstLevelMenu[],
  translateLabel: (label: string) => string
): {
  brands: MostUsedChipLocal[];
  brandsEssilor: MostUsedChipLocal[];
} => {
  const brands = generateMostUsedBrandChipFromMenu(menu, translateLabel, "BRANDS");
  const brandsEssilor = generateMostUsedBrandChipFromMenu(menu, translateLabel, "ESSILOR");

  return { brands: sortChips(brands), brandsEssilor: sortChips(brandsEssilor) };
};

const generateMostUsedBrandChipFromMenu = (
  firstLevelMenu: FirstLevelMenu[],
  translateLabel: (label: string) => string,
  type: "BRANDS" | "ESSILOR"
): MostUsedChipLocal[] => {
  const menu = firstLevelMenu.find((_: FirstLevelMenu) => {
    return _.identifier === type;
  });
  const chipType = type === "BRANDS" ? "Brands" : "Essilor";
  const brands =
    menu?.catalogGroupView?.map((item) => {
      const catalogueInfo: MostUsedCatalogueInfo = {
        menuCategory: type as MenuStructureKeys,
        facets: [{ facetName: "manufacturer.raw", facetValue: item.identifier }],
      };
      const url =
        type === "BRANDS"
          ? `/preplp${item.seo?.href ?? `/${item.identifier}`}`
          : `/digital-catalog?brands=${item.brandCodes?.join(",")}`;
      const newChip: MostUsedChipCatalogueLocal = {
        name: item.name,
        label: translateLabel(item.name),
        type: chipType,
        url: getPagePath(url),
        catalogueInfo,
      };
      return newChip;
    }) ?? [];
  return brands;
};

const getMostUsedOptionFromLensesLink = (
  lensLink: LensLink,
  columnTitle: string
): MostUsedOption => {
  return {
    label: lensLink.title,
    url: lensLink.url,
    columnTitle,
    columnFacet: undefined,
    facet: { facetName: lensLink.id, facetValue: lensLink.title },
  };
};

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////// CATALOGUE CHIP GENERATION ///////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/////////////////// helper functions to create the Most Used chips to be saved from the filters selected

/**
 * Generate new Catalogue Chip based on the current selection of facets in ChipsCatalogueTooltip
 *
 * @param {SecondLevelMenu} categoryMenu
 * @param {MostUsedOption[]} selectedFacets
 * @param {MenuDivInfo[]} menuContentInfo
 * @param {boolean} isLensesCategory
 * @return {*}  {MostUsedChipCatalogueLocal}
 */
export const createMostUsedChipFromSelection = (
  categoryMenu: SecondLevelMenu,
  selectedFacets: MostUsedOption[],
  menuContentInfo: MenuDivInfo[],
  isLensesCategory: boolean,
  translateLabel: (label: string) => string,
  isAllCategory?: boolean
): MostUsedChipCatalogueLocal => {
  const facets = selectedFacets.map((_) => _.facet);
  const identifier = categoryMenu.identifier.toUpperCase();
  const isSmartGlasses = categoryMenu.identifier.toUpperCase() === "SMART GLASSES";
  let name = categoryMenu.identifier.toUpperCase() === "SMART GLASSES" ? "SMART-GLASSES" : "";
  const catalogueInfo: MostUsedCatalogueInfo = {
    menuCategory: identifier as MenuStructureKeys,
    facets,
  };

  const url = isAllCategory
    ? categoryMenu.completeUrl
    : getMostUsedCategoryUrl(selectedFacets, menuContentInfo?.[0]?.baseUrl, isLensesCategory);

  name = isAllCategory
    ? identifier
    : isSmartGlasses
    ? name
    : getMostUsedCatalogueIdentifier(catalogueInfo, isLensesCategory, isAllCategory);
  const newChip: MostUsedChipCatalogueLocal = {
    name,
    label: translateLabel(normalizeChipLabel(name)),
    type: "Catalogue",
    url: getPagePath(url),
    catalogueInfo,
  };

  return newChip;
};

/**
 * Generate new Catalogue Children Chip
 *
 * @param {SecondLevelMenu} categoryMenu
 * @return {*}  {MostUsedChipCatalogueLocal}
 */
export const createMostUsedChildrenChip = (
  categoryMenu: SecondLevelMenu,
  translatedLabel: (label: string) => string
): MostUsedChipCatalogueLocal => {
  const url = categoryMenu?.completeUrlKids ?? "";
  const catalogueInfo: MostUsedCatalogueInfo = {
    menuCategory: categoryMenu.identifier.toUpperCase() as MenuStructureKeys,
    facets: [
      {
        facetName: "CHILDREN",
        facetValue: "TRUE",
      },
    ],
  };

  const name = getMostUsedCatalogueIdentifier(catalogueInfo, false, true);
  const newChip: MostUsedChipCatalogueLocal = {
    name,
    label: translatedLabel(normalizeChipLabel(name)),
    type: "Catalogue",
    url: getPagePath(url),
    catalogueInfo,
  };

  return newChip;
};

/**
 * Compute identifier for the chip, with the following structure:
 * [category's identifier]-[facet1]-[facet2]-...
 *
 * eg: SUNGLASSES-BESTSELLER-WOMAN-RAYBAN
 *
 * Usual rules re: whether displaying facet name or facet value apply
 * (facetName for facets w/ "TRUE" or "FALSE" values, facetValue otherwise)
 *
 * @param {MostUsedCatalogueInfo} catalogueInfo
 * @param {boolean} isLensesCategory
 * @return {*}  {string}
 */
export const getMostUsedCatalogueIdentifier = (
  catalogueInfo: MostUsedCatalogueInfo,
  isLensesCategory: boolean,
  isKids?: boolean
): string => {
  if (isKids) return catalogueInfo.menuCategory + "-CHILDREN";

  if (isLensesCategory)
    return formatMostUsedIdentifier(
      catalogueInfo.menuCategory + "-" + catalogueInfo?.facets?.[0]?.facetValue
    );

  const potentialMatches: MenuFacet[] = [];
  menuStructure[catalogueInfo.menuCategory]?.forEach((column) => {
    if (column.content.length) potentialMatches.push(...column.content);
    if (column.facet) potentialMatches.push(column.facet);
  });

  const facets = catalogueInfo.facets.map((facet) => {
    const facetMatch = potentialMatches.find((_) => _.facetName === facet.facetName);

    if (facetMatch?.facetEntry) return facet.facetValue;
    if (facetMatch?.value) return facet.facetName;
    return facet.facetValue;
  });

  return formatMostUsedIdentifier(
    catalogueInfo.menuCategory + (facets.length ? "-" : "") + facets.join("-")
  );
};

/**
 * Compute url for the chip
 *
 * Note that not only the facets specified in the identifier/label will be applied,
 * but also all facets that depend on the column (eg. CATEGORY_FILTER = SUNGLASSES)
 * and all facets that are tacitely added in the menu (eg. GENDER=UNISEX when selecting GENDER=WOMAN)
 *
 * @param {MostUsedOption[]} selectedFacets
 * @param {string} baseUrl
 * @param {boolean} isLensesCategory
 * @return {*}  {string}
 */
export const getMostUsedCategoryUrl = (
  selectedFacets: MostUsedOption[],
  baseUrl: string,
  isLensesCategory: boolean
): string => {
  if (isLensesCategory) return LENSES_COLUMN_BASE_URL + selectedFacets?.[0]?.url;

  const facetsUrls = selectedFacets.map((_) => _.url);
  const [page, params] = baseUrl?.split("?") ?? [facetsUrls?.[0]?.split("?")?.[0]];

  const filtersArray = getArrayOfFilters([...facetsUrls, params]);
  const filters = getUnionOfFilters(filtersArray);

  const urlSearchParams = appendFiltersToURLSearchParams(filters, undefined);
  const queryParams = decodeURIComponent(urlSearchParams.toString());

  return page + (queryParams.length ? "?" : "") + queryParams;
};

const getArrayOfFilters = (urls: string[]): QueryParams[] => {
  return urls.map((_) => {
    const param = getParamsFromUrls(_);
    return getFiltersFromParams(param);
  });
};

const getParamsFromUrls = (url: string) => {
  const stringParams = url?.split("?")?.[1] ?? url;
  return new URLSearchParams(stringParams) as URLSearchParams;
};

const getUnionOfFilters = (filtersArray: QueryParams[]) => {
  return filtersArray.reduce((previousValue: QueryParams, currentValue: QueryParams) => {
    const previousKeys = Object.keys(previousValue);
    const currentKeys = Object.keys(currentValue);
    const newKeys = [...previousKeys, ...currentKeys];

    const newQueryParams: QueryParams = {};

    newKeys.forEach((key) => {
      newQueryParams[key] = union(previousValue?.[key] ?? [], currentValue?.[key] ?? []);
    });

    return newQueryParams;
  }, {});
};

export const checkIfChipAlreadyExists = (
  newChip: MostUsedChip,
  selectedChips: MostUsedChipLocal[]
): boolean => {
  return selectedChips.some((_) => areCatalogueChipsTheSame(newChip, _));
};

const areCatalogueChipsTheSame = (newChip: MostUsedChip, oldChip: MostUsedChip) => {
  if (!instanceOfMostUsedChipCatalogue(newChip) || !instanceOfMostUsedChipCatalogue(oldChip))
    return false;

  if (newChip.catalogueInfo.menuCategory !== oldChip.catalogueInfo.menuCategory) return false;
  if (newChip.catalogueInfo.facets.length !== oldChip.catalogueInfo.facets.length) return false;

  return xorWith(newChip.catalogueInfo.facets, oldChip.catalogueInfo.facets, isEqual).length === 0;
};

function existsInStructure(identifier: string, facetName?: string, facetValue?: string): boolean {
  const valuesSet = mostUsedFacetStructure[identifier.toUpperCase()];
  return valuesSet
    ? valuesSet.has(facetName?.toUpperCase() ?? "") ||
        valuesSet.has(facetValue?.toUpperCase() ?? "")
    : false;
}

export const sortChips = (chips: MostUsedChipLocal[]): MostUsedChipLocal[] => {
  return chips.sort((a, b) => a.label.localeCompare(b.label));
};

const mostUsedFacetStructure: { [key: string]: Set<string> } = {
  SUNGLASSES: new Set(["NEW", "BESTSELLER", "WOMAN", "MAN", "POLARIZED", "RECOMMENDED"]),
  EYEGLASSES: new Set(["NEW", "BESTSELLER", "WOMAN", "MAN"]),
  GOGGLES_HELMETS: new Set(["SNOW GOGGLES", "MX GOGGLES", "SNOW HELMETS", "CYCLING & MTB HELMETS"]),
  AFA: new Set(["APPAREL", "FOOTWEAR", "ACCESSORIES"]),
};

const mostUsedAvailableChipsInHP: string[] = [
  "SUNGLASSES",
  "SUNGLASSES-POLARIZED",
  "SUNGLASSES-MAN",
  "SUNGLASSES-WOMAN",
  "SUNGLASSES-CHILDREN",
  "SUNGLASSES-BESTSELLER",
  "SUNGLASSES-NEW",
  "SUNGLASSES-RECOMMENDED",
  "EYEGLASSES",
  "EYEGLASSES-MAN",
  "EYEGLASSES-WOMAN",
  "EYEGLASSES-BESTSELLER",
  "EYEGLASSES-NEW",
  "EYEGLASSES-CHILDREN",
  "GOGGLES_HELMETS",
  "GOGGLES_HELMETS-SNOW-GOGGLES",
  "GOGGLES_HELMETS-SNOW-HELMETS",
  "GOGGLES_HELMETS-CYCLING-&-MTB-HELMETS",
  "GOGGLES_HELMETS-MX-GOGGLES",
  "SMART-GLASSES",
  "AFA",
  "AFA-APPAREL",
  "AFA-FOOTWEAR",
  "AFA-ACCESSORIES",
];

const mostUsedMatchCMS = new Map<string, string>([
  ["SUNGLASSES", "SUNGLASSES"],
  ["EYEGLASSES", "EYEGLASSES"],
  ["GOGGLES-HELMETS", "GOGGLES_HELMETS"],
  ["SMART+GLASSES", "SMART GLASSES"],
  ["AFA", "AFA"],
]);

export const mostUsedLenses = ["PROGRESSIVE_LENSES", "SINGLE_VISION_LENSES"];

export const deniedThirdMenuSection = ["LENSES", "INSTRUMENTS", "ACCESSORIES"];

export const normalizeChipLabel = (label: string, type = ""): string => {
  return "MOST_USED_" + type + label.replace(/-|\s+/g, "_");
};

export const matchCMSChip = (link?: string) => {
  for (const [key, value] of mostUsedMatchCMS) {
    if (link?.toUpperCase().includes(key)) {
      return value;
    }
  }
  return undefined;
};
