import { AxiosResponse } from "axios";
import { createAndExecuteService } from "../serviceUtils";
import {
  GetDeliveryNotePayload,
  GetOrderDetailsPayload,
  GetOrderHistoryPayload,
} from "./orderHistoryInterface";
import {
  getOrderHistoryUrl,
  getOrderDetailsUrl,
  getOrderDetailsWithDeliveryUrl,
  getDeliveryNoteUrl,
} from "./orderHistoryUrls";

const orderService = {
  getOrderHistory: async (params: GetOrderHistoryPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();

    if (params.status) {
      const statusCodeList = [...params.status];
      delete params.status;
      statusCodeList.forEach((item) => {
        searchParams.append("status", item);
      });
    }

    if (params.orderMode) {
      const orderMode = [...params.orderMode];
      delete params.orderMode;
      orderMode.forEach((item) => {
        searchParams.append("orderMode", item);
      });
    }

    if (params.orderType) {
      const orderType = [...params.orderType];
      delete params.orderType;
      orderType.forEach((item) => {
        searchParams.append("orderType", item);
      });
    }

    // if (params.orderProductCategory) {
    //   const orderType = [...params.orderProductCategory];
    //   delete params.orderProductCategory;
    //   orderType.forEach((item) => {
    //     searchParams.append("orderProductCategory", item);
    //   });
    // }

    if (params.deliveryMethod) {
      const deliveryMethod = [...params.deliveryMethod];
      delete params.deliveryMethod;
      deliveryMethod.forEach((item) => {
        searchParams.append("deliveryMethod", item);
      });
    }

    Object.entries(params).forEach(([key, value]) => value && searchParams.append(key, value));
    return createAndExecuteService(getOrderHistoryUrl, "GET", searchParams);
  },

  getOrderDetails: async (params: GetOrderDetailsPayload): Promise<AxiosResponse> => {
    const url = getOrderDetailsUrl.replace("{orderId}", params.id);
    if (params.summary) {
      const qparams = new URLSearchParams({ summary: "true" });
      return createAndExecuteService(url, "GET", qparams);
    } else {
      return createAndExecuteService(url, "GET");
    }
  },

  getOrderDetailsWithDelivery: async (params: GetOrderDetailsPayload): Promise<AxiosResponse> => {
    const url = getOrderDetailsWithDeliveryUrl.replace("{orderId}", params.id);
    if (params.summary) {
      const qparams = new URLSearchParams({ summary: "true" });
      return createAndExecuteService(url, "GET", qparams);
    } else {
      return createAndExecuteService(url, "GET");
    }
  },

  getDeliveryNote: async (params: GetDeliveryNotePayload): Promise<AxiosResponse> => {
    const url = getDeliveryNoteUrl.replace("{doorId}", params.doorId);
    const qparams = new URLSearchParams({
      sold_to: params.sold_to,
      delv_crea_dt_from: params.delv_crea_dt_from,
      delv_crea_dt_to: params.delv_crea_dt_to,
      delivery_number: params.delivery_number,
    });
    return createAndExecuteService(url, "GET", qparams);
  },
};

export default orderService;
