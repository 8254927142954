import React, { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import Popup from "../styled-UI/Popup";
import Button from "../styled-UI/Button";
import useTranslation from "../../hooks/useTranslation";
import {
  selectCheckMailDuplication,
  selectPopupDuplicatedEmail,
  togglePopupDuplicatedEmail,
} from "../../store/store/storeSlice";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { selectAtLeastOneTruePrivileges, selectIsSubuser } from "../../store/user/userSlice";
import useCanRender from "../../hooks/useCanRender";
import { strSplice } from "../../utils/utils";
import { LanguageContext } from "../../language/languageContext";

export default function DuplicatedEmailPopup(): JSX.Element | null {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const canRender = useCanRender();

  const isSubuser = useSelector(selectIsSubuser);
  const popup = useSelector(selectPopupDuplicatedEmail);
  const checkMailDuplication = useSelector(selectCheckMailDuplication);
  const privileges = useSelector(selectAtLeastOneTruePrivileges);
  const { labels } = useContext(LanguageContext);

  const close = () => {
    dispatch(togglePopupDuplicatedEmail({ open: false }));
  };

  const { description, errortype } = useMemo(() => {
    if (checkMailDuplication !== null) {
      let errortype = "_SUB_DUPLICATED";

      if (!isSubuser) {
        {
          if (checkMailDuplication?.subusersMailDuplicated.length !== 0) {
            errortype = checkMailDuplication?.selfMailDuplication
              ? "_MAIN_AND_SUB_DUPLICATED"
              : "_SUBS_DUPLICATED";
          } else {
            errortype = "_MAIN_DUPLICATED";
          }
        }
      }

      let desc = translateLabel("POPUP_DUPLICATED_EMAIL_DESCRIPTION" + errortype);

      if (!canRender("USER_MANAGEMENT_SECTION")) {
        const startAnchor = desc.indexOf("<a");
        const endAnchor = desc.indexOf("</a>");

        if (startAnchor !== -1 && endAnchor !== -1) {
          desc = strSplice(desc, startAnchor, endAnchor - startAnchor + 4);
        }
      }
      return { description: desc, errortype };
    }
    return { description: "", errortype: "" };
  }, [checkMailDuplication, isSubuser, privileges, labels]);

  return popup.open ? (
    <Popup isOpen close={close} title={translateLabel("POPUP_DUPLICATED_EMAIL_TITLE" + errortype)}>
      <Container>
        <Content>
          <ContentText>
            <SetInnerHTMLWithSanitize text={description} convertLink anchorCallback={close} />
          </ContentText>
          <Button onClick={close} type="primary">
            {translateLabel("POPUP_CLOSE")}
          </Button>
        </Content>
      </Container>
    </Popup>
  ) : null;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 37.5rem;
  padding: 1.5rem;
`;

const ContentText = styled.div`
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: ${(props) => props.theme.fonts.fontRegular};

  a {
    padding-bottom: 1px;
    border-bottom: 1px solid ${(props) => props.theme.palette.primary};
    font-family: ${(props) => props.theme.fonts.fontBold};
  }
  b {
    font-family: ${(props) => props.theme.fonts.fontBold};
  }
`;
