import { useSelector } from "react-redux";
import { DisplayPricesOptions } from "../store/user/userInterfaces";
import { selectActiveDoor, selectDisplayPrices } from "../store/user/userSlice";
import useCanRender from "./useCanRender";

export default function useDisplayPrice(
  displaySettingExternal?: DisplayPricesOptions | null
): {
  hideSuggested: boolean;
  hideWholesale: boolean;
  hideAll: boolean;
  priceDisplaySetting: DisplayPricesOptions | null;
} {
  const isRx = location.pathname.includes("/rx-prescription");
  const isRxIncluded =
    location.pathname.includes("/cart") || location.pathname.includes("/thank-you-page");
  const displaySettingSlice = useSelector(selectDisplayPrices);
  const displaySetting = displaySettingExternal || displaySettingSlice;
  const canRender = useCanRender();

  // srp
  const hideSuggested =
    (displaySetting === "ALL_PRICES" && !canRender("SUGGESTED_RETAIL_PRICE")) ||
    displaySetting === "WHOLESALE_PRICES";
  const hideRxSuggestd =
    (displaySetting === "ALL_PRICES" && !canRender("RX_MY_PRICE_SRP")) ||
    displaySetting === "WHOLESALE_PRICES";
  const hideRxIncludedSrp = displaySetting === "WHOLESALE_PRICES";

  // whs
  const hideWholesale =
    !canRender("WHOLESALE_PRICE") ||
    displaySetting === "NO_PRICES" ||
    displaySetting === "SUGGESTED_PRICES";
  const hideRxWholesale =
    !canRender("RX_MY_PRICE") ||
    displaySetting === "NO_PRICES" ||
    displaySetting === "SUGGESTED_PRICES";
  const hideRxIncludedWhs = displaySetting === "NO_PRICES" || displaySetting === "SUGGESTED_PRICES";

  // all
  const hideAll =
    displaySetting === "NO_PRICES" ||
    (!canRender("WHOLESALE_PRICE") && !canRender("SUGGESTED_RETAIL_PRICE"));
  const rxHideAll =
    displaySetting === "NO_PRICES" || (!canRender("RX_MY_PRICE_SRP") && !canRender("RX_MY_PRICE"));
  const rxIncludedHideAll = displaySetting === "NO_PRICES";

  return {
    hideSuggested: isRx ? hideRxSuggestd : isRxIncluded ? hideRxIncludedSrp : hideSuggested,
    hideWholesale: isRx ? hideRxWholesale : isRxIncluded ? hideRxIncludedWhs : hideWholesale,
    hideAll: isRx ? rxHideAll : isRxIncluded ? rxIncludedHideAll : hideAll,
    priceDisplaySetting: displaySetting,
  };
}
