import React, { useEffect, useState } from "react";
import Slider, { LazyLoadTypes } from "react-slick";
import IconButton from "../styled-UI/IconButton";
import styled from "styled-components/macro";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrowhead-down-icon.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrowhead-up-icon.svg";

interface Props {
  children: any;
  slidesToShow?: number;
  slidesToScroll?: number;
  callbackOnChangeSlide?: (page: number) => void;
  isDarkMode?: boolean;
}

const VerticalCarousel = ({
  children,
  slidesToShow = 1,
  slidesToScroll = 1,
  callbackOnChangeSlide,
  isDarkMode = false,
}: Props): JSX.Element => {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [totalSlides, setTotalSlides] = useState<number>(children?.length);

  const currentPage = Math.ceil(currentSlide / slidesToScroll);
  const lastPage = Math.ceil((totalSlides - slidesToShow) / slidesToScroll);

  useEffect(() => {
    if (sliderRef?.props?.children && Array.isArray(sliderRef.props.children))
      setTotalSlides(sliderRef.props.children?.length);
  }, [sliderRef]);

  const defaultLeftSettings = {
    dots: false,
    arrows: false,
    draggable: false, //to set it to true --> prevent click on drag https://github.com/akiran/react-slick/issues/848
    speed: 500,
    infinite: false,
    lazyLoad: "ondemand" as LazyLoadTypes,
    onSwipe: () => {
      console.log("swipe");
    },
    slidesToShow: slidesToShow < totalSlides ? slidesToShow : totalSlides,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    beforeChange: (_: any, next: any) => setCurrentSlide(next),
    vertical: true,
  };

  const changeSlide = (val: number) => {
    sliderRef.slickGoTo(val);
    callbackOnChangeSlide && callbackOnChangeSlide(val);
  };
  if (!children) {
    return <></>;
  }

  return (
    <Container>
      {totalSlides > 1 && totalSlides > slidesToShow && (
        <IconButton
          size="lg"
          onClick={() => changeSlide(currentSlide - slidesToScroll)}
          disabled={currentPage === 0}
          icon={<ArrowUp />}
          isCarousel
          type={isDarkMode ? "no-background-white" : "no-background-primary"}
        />
      )}

      <Slider ref={(val) => setSliderRef(val)} {...defaultLeftSettings}>
        {children}
      </Slider>
      {totalSlides > 1 && totalSlides > slidesToShow && (
        <IconButton
          size="lg"
          onClick={() => changeSlide(currentSlide + slidesToScroll)}
          disabled={currentPage === lastPage}
          icon={<ArrowDown />}
          isCarousel
          type={isDarkMode ? "no-background-white" : "no-background-primary"}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .slick-slide {
    width: 100% !important;
  }
`;

export default VerticalCarousel;
