import { SagaIterator } from "@redux-saga/types";
import { call, select, takeLatest } from "redux-saga/effects";
import { AnalyticsData, ErrorData, PdpData, SearchData, UserData } from "./analyticsInterfaces";
import { sendAnalyticsData } from "./analyticsService";
import { loginSuccess } from "../user/userSlice";
import {
  selectCommonUserData,
  selectPdpData,
  selectQuickViewOverlayProductData,
  selectSearchData,
  sliceName,
} from "./analyticsSlice";
import { getDataForAddToCart } from "./analyticsUtility";
import { selectPreCart } from "../catalogue/catalogueSlice";
import { PreCartProduct } from "../catalogue/catalogueInterface";
import { handleError } from "../store/storeSagas";
import { createAction, PayloadAction } from "@reduxjs/toolkit";

/* ACTIONS */
export const trackEvent = createAction<AnalyticsData>(sliceName + "/trackEvent");

/* SAGAS */
function* loginEventSaga(): SagaIterator {
  let obj: AnalyticsData = {
    id: "Login",
  };
  try {
    const userData: UserData = yield select(selectCommonUserData);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { User_LoginStatus, User_EmailOptin, ...otherUserData } = userData;

    obj = {
      ...obj,
      ...otherUserData,
    };

    yield call(sendAnalyticsData, obj);
  } catch (error) {
    console.error(`Error tracking ${obj.id}`, error);
  }
}

function* trackEventSaga(action: PayloadAction<AnalyticsData>): SagaIterator {
  const trackEvent = action.payload;
  try {
    let obj;

    switch (trackEvent.id) {
      case "SearchPageReady": {
        const searchData: SearchData = yield select(selectSearchData);
        obj = { ...searchData };
        break;
      }
      case "AddToCart": {
        const preCartData: PreCartProduct[] = yield select(selectPreCart);
        obj = { Products: getDataForAddToCart(action.payload.data ?? preCartData) };
        break;
      }
      case "QuickViewOverlay": {
        const overlayData = yield select(selectQuickViewOverlayProductData);
        obj = { ...overlayData };
        break;
      }
      case "VirtualMirror": {
        const pdpData: PdpData = yield select(selectPdpData);
        obj = { ...pdpData };
        break;
      }
    }

    yield call(sendAnalyticsData, { ...obj, ...trackEvent });
  } catch (error) {
    console.error(`Error tracking ${trackEvent.id}`, error);
  }
}

function* analyticsErrorSaga(action: PayloadAction<any>): SagaIterator {
  const error = action.payload;

  let errorData: ErrorData = { id: "Error" };
  if (error?.config?.url?.includes("precart/items")) {
    errorData = {
      ...errorData,
      ...{
        Error_Code: "AddToCart",
        Error_Source: "Server",
        Error_Details: "Add to cart no response",
      },
    };
  } else if (error?.config?.url?.includes("/precart")) {
    errorData = {
      ...errorData,
      ...{
        Error_Code: "Cart",
        Error_Source: "Server",
        Error_Details: "Cart Loading Error",
      },
    };
  }

  if (errorData?.Error_Code) {
    sendAnalyticsData(errorData);
  }
}

export function* analyticsSaga(): SagaIterator {
  yield takeLatest(loginSuccess.type, loginEventSaga);
  yield takeLatest(trackEvent.type, trackEventSaga);
  yield takeLatest(handleError.type, analyticsErrorSaga);
}
