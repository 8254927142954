import React, { useState } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import useDataHref from "../../../hooks/useDataHref";

import Logo from "../../layouts/Logo";
import CustomText from "../../styled-UI/CustomText";
import VideoPopup from "../../styled-UI/VideoPopup";
import IconButton from "../../styled-UI/IconButton";

import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/icons/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/icons/linkedin.svg";
// import { ReactComponent as PinterestIcon } from "../../../assets/icons/pinterest.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/icons/twitter.svg";
// import { ReactComponent as YoutubeIcon } from "../../../assets/icons/youtube.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/play-icon.svg";
import SetInnerHTMLWithSanitize from "../../widgets/SetInnerHTMLWithSanitize";

interface Props {
  title?: string;
  titleColour?: string;
  description?: string;
  description1Colour?: string;
  description2?: string;
  description2Colour?: string;
  imgHref?: string;
  videoHref?: string;
  videoTitle?: string;
  rightContent: JSX.Element;
  page: boolean;
}

const LoginLayout = ({
  title,
  titleColour,
  description,
  description1Colour,
  description2,
  description2Colour,
  imgHref,
  videoHref,
  rightContent,
  videoTitle,
  page = false,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const descrWithHref = useDataHref({ content: description });
  const descr2WithHref = useDataHref({ content: description2 });

  return (
    <div>
      <Page backgroundImage={imgHref}>
        <Content>
          <div className="RightContent">
            <div>
              {title && (
                <CustomText
                  as="h1"
                  color="white"
                  fontSizePx={60}
                  font="font-regular"
                  marginBottomPx={40}
                  lineHeightPx={72}
                  customColor={titleColour}
                >
                  {title}
                </CustomText>
              )}
            </div>
            <div>
              {description && (
                <CustomText
                  as={title ? "span" : "h1"}
                  color="white"
                  fontSizePx={title ? 16 : 60}
                  font="font-regular"
                  marginBottomPx={title ? 61 : 40}
                  lineHeightPx={title ? 18 : 72}
                  customColor={description1Colour}
                >
                  <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? description} />
                </CustomText>
              )}
              {description2 && (
                <CustomText
                  as="span"
                  color="white"
                  fontSizePx={16}
                  font="font-regular"
                  marginBottomPx={61}
                  customColor={description2Colour}
                >
                  <SetInnerHTMLWithSanitize text={descr2WithHref?.updatedContent ?? description2} />
                </CustomText>
              )}
            </div>
            {videoHref && (
              <PlayButton>
                <IconButton
                  type="white-no-border"
                  size="xl"
                  icon={<PlayIcon />}
                  onClick={() => setShowVideo(true)}
                  data-element-id="VideoPlay"
                  data-description={videoTitle}
                />
              </PlayButton>
            )}
          </div>
          <div>
            <LogoContainer>
              <Logo color="white" heightPx={24} isLink={page} />
            </LogoContainer>
            {rightContent}
          </div>
        </Content>

        <Socials>
          {translateLabel("LOGIN_FOLLOW_US")}
          <IconContainer>
            <Icon
              data-element-id="FollowUs"
              data-description="facebook"
              onClick={() => window.open("https://www.facebook.com/EssilorLuxottica/", "_blank")}
            >
              <FacebookIcon />
            </Icon>
            <Icon
              data-element-id="FollowUs"
              data-description="instagram"
              onClick={() => window.open("https://www.instagram.com/essilorluxottica/ ", "_blank")}
            >
              <InstagramIcon />
            </Icon>
            <Icon
              data-element-id="FollowUs"
              data-description="linkedin"
              onClick={() =>
                window.open("https://www.linkedin.com/company/essilorluxottica/", "_blank")
              }
            >
              <LinkedinIcon />
            </Icon>
            <Icon
              data-element-id="FollowUs"
              data-description="twitter"
              onClick={() => window.open("https://twitter.com/EssiLux", "_blank")}
            >
              <TwitterIcon />
            </Icon>
          </IconContainer>
        </Socials>
      </Page>
      {videoHref && (
        <VideoPopup isOpen={showVideo} close={() => setShowVideo(false)} srcVideo={videoHref} />
      )}
    </div>
  );
};

const Page = styled.div<{ backgroundImage: string | undefined }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  min-height: calc(100vh - 6.25rem);
  box-sizing: border-box;
  padding: 1rem 0;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;

  > .RightContent {
    width: 35.625rem;

    @media ${(props) => props.theme.queries.md} {
      width: 35%;
    }
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 1.125rem;
`;

const PlayButton = styled.div``;

const Socials = styled.div`
  color: ${(props) => props.theme.palette.white};
  font-size: 0.6875rem;
  font-family: ${(props) => props.theme.fonts.fontRegular};
  position: absolute;
  display: flex;
  bottom: 7.75rem;
  left: 3.125rem;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;

  > .Icon {
    margin-right: 1.25rem;
    cursor: pointer;

    svg {
      height: 1.5rem;
      width: 1.5rem;
      fill: ${(props) => props.theme.palette.white};
    }
  }
`;

const Icon = styled.button`
  margin-right: 1.25rem;
  cursor: pointer;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: ${(props) => props.theme.palette.white};
  }
`;

export default LoginLayout;
