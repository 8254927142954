import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components/macro";

import useQuery from "../../../hooks/useQuery";
import WrapperLayout from "../../layouts/WrapperLayout";
import PrescriptionTable from "../PrescriptionTable";
import { CustomOptions } from "../../styled-UI/CustomSelect";
import CustomText from "../../styled-UI/CustomText";
import Stepper, { Step } from "../../styled-UI/Stepper";
import Button from "../../styled-UI/Button";
import LinkButton from "../../styled-UI/LinkButton";
import ModelSelection from "./ModelSelection";
import ModelSelectionSummary from "./ModelSelectionSummary";
import LensSelection from "./LensSelection";
import AdditionalInfo from "./AdditionalInfo";
import Recap from "./Recap";
import {
  resetRxState,
  resetStep2,
  resetStep3,
  resetStep4,
  selectAddRxToPrecartStatus,
  selectRxSubmitError,
  selectCheckFrameStatus,
  selectCustomerOwnFrame,
  selectCustomerReference,
  selectBrandGroup,
  selectFocalTypeDetails,
  selectGetRxPricesStatus,
  selectIncludedItems,
  selectMadeInAndItemsInPackage,
  selectRxCartItem,
  selectSelectedFrame,
  selectSelectedLens,
  selectSizes,
  selectUpcs,
  selGenRxCartPayload,
  setBrands,
  setColors,
  setFocalTypeDetails,
  setJobTypeList,
  setPartnumbers,
  setSizes,
  setUpcs,
  setBrandGroups,
  selectIsFrameAutoSelectionComplete,
  selectRxPrices,
  setRxSubmitError,
  selectRxAutoselectionStatus,
  setRxAutoselectionStatus,
  selectMakeability,
  selectOtherDescription,
  selectPostMakeabilityStatus,
} from "../../../store/rx/rxSlice";
import {
  CheckFrameReq,
  GetLensesPayload,
  FocalTypePrescription,
  RxProduct,
  MakeabilityPrescription,
  PrescriptionObj,
  SelectList,
  SelectNameList,
  Eye,
  EyeError,
  addRxItemsToPrecartPayload,
  PostMakeabilityPayload,
  RxPrice,
  ResetFormParams,
} from "../../../store/rx/rxInterface";
import useTranslation from "../../../hooks/useTranslation";
import {
  getBrandGroups,
  addRxItemsToPrecart,
  checkFrame,
  getRxCartItem,
  getRxPrices,
  postMakeability,
  selectProductOpts,
} from "../../../store/rx/rxSagas";
import { getPagePath } from "../../../routing/routesUtils";
import usePrevious from "../../../hooks/usePrevious";
import { selectBrandGroupByBrand, selectCurrentLang } from "../../../store/store/storeSlice";
import Loader from "../../styled-UI/Loader";
import ConditionalComponentRender from "../ConditionalComponentRender";
import LoaderInputs from "../../styled-UI/loader/rx/LoaderInputs";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info-icon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash-icon.svg";
import { Door } from "../../../store/user/userInterfaces";
import {
  checkRightLeftRequiredPrescription,
  cylinderIsFilled,
  getJobTypeLabel,
  getPartnumberFromMCL,
  getPrescriptionDetails,
} from "../../../utils/rxUtils";
import useDisplayPrice from "../../../hooks/useDisplayPrice";
import { useTrackView } from "../../../hooks/useTrackView";
import { Products } from "../../../store/analytics/analyticsInterfaces";
import { sendAnalyticsData } from "../../../store/analytics/analyticsService";
import { regexFromStr } from "../../../utils/utils";
import useCanRender from "../../../hooks/useCanRender";
import { selectIsMultidoor, selectSelectedDoor } from "../../../store/user/userSlice";
import RouteLeavingLensConfigurator from "../route-leaving-guard/RouteLeavingLensConfigurator";

interface Props {
  selectedBrand?: string[];
}

export interface RxLocation {
  pathname: string;
  search?: string;
  state?: {
    selectedProduct?: RxProduct;
    orderItemId?: string;
  };
}

export const eyes: Eye[] = ["right", "left"];
const selectInitialState = {
  brandGroup: null,
  brand: null,
  model: null,
  color: null,
  size: null,
  upc: null,
  jobType: null,
  focalType: null,
  lensType: null,
  laterality: null,
  madeIn: null,
  selectableOpt: null,
};

export default function Rx({ selectedBrand = [] }: Props): JSX.Element {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const location: RxLocation = useLocation();
  const { hideAll } = useDisplayPrice();
  const canRender = useCanRender();

  const [activeStep, setActiveStep] = useState(1);
  const [locationKeys, setLocationKeys] = useState<any[]>([]);
  const [orderItemId, setOrderItemId] = useState<string | null>(null);
  const [prescriptionDetails, setPrescriptionDetails] = useState<{
    [focalType: string]: FocalTypePrescription[];
  } | null>(null);
  const [prescriptionAdditionalDetails, setPrescriptionAdditionalDetails] = useState<
    FocalTypePrescription[] | null
  >(null);
  const [selectList, setSelectList] = useState<SelectList>(selectInitialState);
  const [selectedJobType, setSelectedJobType] = useState<string | null>(null);

  const step = query.get("step") || 1;

  const config = {
    steps: [
      { label: translateLabel("RX_STEP1_TITLE"), identifier: "1" },
      { label: translateLabel("RX_STEP2_TITLE"), identifier: "2" },
      { label: translateLabel("RX_STEP3_TITLE"), identifier: "3" },
      { label: translateLabel("RX_STEP4_TITLE"), identifier: "4" },
    ],
  };

  // fallback to label in case is old saved focaltype
  const selectedPrescriptionDetails =
    (selectList.focalType &&
      ((prescriptionDetails?.[selectList.focalType.value] as FocalTypePrescription[]) ??
        (prescriptionDetails?.[selectList.focalType.label] as FocalTypePrescription[]))) ??
    [];

  const prescriptionDetailsObj: PrescriptionObj = {};
  selectedPrescriptionDetails.forEach((detail) => {
    const left = (detail?.left as string) || "";
    const right = (detail?.right as string) || "";
    prescriptionDetailsObj[detail.parameterName] = {
      left,
      right,
    };
  });
  prescriptionAdditionalDetails?.forEach((detail) => {
    const left = (detail?.left as string) || "";
    const right = (detail?.right as string) || "";
    if (left && right)
      prescriptionDetailsObj[detail.parameterName] = {
        left,
        right,
      };
  });

  const focalTypeDetails = useSelector(selectFocalTypeDetails);
  const brandGroupByType: { [x: string]: string } = useSelector(selectBrandGroupByBrand);
  const rxSubmitError = useSelector(selectRxSubmitError);
  const selectedLens = useSelector(selectSelectedLens);
  const checkFrameStatus = useSelector(selectCheckFrameStatus);
  const customerReference = useSelector(selectCustomerReference);
  const selectedFrame = useSelector(selectSelectedFrame);
  const madeInAndItemsInPackage = useSelector(selectMadeInAndItemsInPackage);
  const includedItems = useSelector(selectIncludedItems);
  const sizes = useSelector(selectSizes);
  const customerOwnFrame = useSelector(selectCustomerOwnFrame);
  const getRxPricesStatus = useSelector(selectGetRxPricesStatus);
  const rxCartItem = useSelector(selectRxCartItem);
  const addRxToPrecartStatus = useSelector(selectAddRxToPrecartStatus);
  const upcs = useSelector(selectUpcs);
  const brandGroups = useSelector(selectBrandGroup);
  const selectedDoor = useSelector(selectSelectedDoor);
  const isFrameAutoSelectionComplete = useSelector(selectIsFrameAutoSelectionComplete);
  const rxPrices = useSelector(selectRxPrices);
  const currentLang = useSelector(selectCurrentLang);
  const rxAutoselectionStatus = useSelector(selectRxAutoselectionStatus);
  const makeability = useSelector(selectMakeability);
  const otherDescription = useSelector(selectOtherDescription);
  const makeabilityStatus = useSelector(selectPostMakeabilityStatus);
  const isMultidoor = useSelector(selectIsMultidoor);

  const [selectedDoorInternal, setSelectedDoorInternal] = useState<Door | null>(selectedDoor);

  // selectors for cart
  const size = sizes?.find((size) => size.partNumber.slice(-2) === selectList.size?.value);
  const upc = upcs?.find((upc) => upc.upc === selectList.upc?.value);

  const rxCartPayload = useSelector(
    selGenRxCartPayload(
      selectList,
      prescriptionDetailsObj,
      orderItemId,
      selectedLens,
      selectedDoor,
      size
    )
  );

  const prevSelectedFocal = usePrevious(selectList.focalType);
  const prevStep = usePrevious(activeStep);
  const prevLang = usePrevious(currentLang);

  const getUpcDetail = (): {
    model: CustomOptions;
    size: CustomOptions;
    color: CustomOptions;
    upc: string;
  } => {
    const upc = upcs?.find((item) => item.upc === selectList.upc?.value);
    const upcCode = upc
      ? upc.upc
      : sizes?.find((item) => item.partNumber.slice(-2) === selectList.size?.value)?.upc;
    const model = upc
      ? {
          value: upc?.partNumber.split("__")[0] as string,
          label: upc?.partNumber.split("__")[0] + (upc?.name ? "-" + upc?.name : ""),
        }
      : (selectList.model as CustomOptions);
    const colorAttribute = upc?.attributes?.find(
      (attribute) => attribute.identifier === "DL_COLOR_CODE"
    );
    const color = upc
      ? {
          value: upc?.uniqueID,
          label: colorAttribute?.values?.[0]?.identifier
            ? colorAttribute?.values?.[0]?.identifier + ""
            : "",
        }
      : (selectList.color as CustomOptions);
    const sizeAttribute = upc?.attributes?.find(
      (attribute) => attribute.identifier === "DL_SIZE_CODE"
    );
    const size = upc
      ? {
          value: sizeAttribute?.values?.[0]?.identifier || "",
          label: sizeAttribute?.values?.[0]?.identifier || "",
        }
      : (selectList.size as CustomOptions);

    return { model, color, size, upc: upcCode as string };
  };

  const handleSelectChange = (value: CustomOptions | null, ...types: SelectNameList[]) => {
    const newSelectList = { ...selectList };
    types.forEach((type) => {
      newSelectList[type] = value;
    });
    setSelectList(newSelectList);
  };

  const handleMultiSelectChange = (changes: { [key in SelectNameList]?: CustomOptions | null }) => {
    const newSelectList = { ...selectList };
    (Object.keys(changes) as Array<keyof typeof changes>).forEach((type: SelectNameList) => {
      newSelectList[type] = changes[type] || null;
    });
    setSelectList(newSelectList);
  };

  const selectProduct = (
    product: RxProduct,
    handleMultiSelectChange: (changes: { [key in SelectNameList]?: CustomOptions | null }) => void
  ) => {
    const brandGroup = brandGroupByType?.[product.brand];
    product.brandGroup = brandGroup;

    dispatch(selectProductOpts({ product, handleMultiSelectChange, setRxAutoselectionStatus }));
  };

  //# Analytics start
  const getPageType = () => {
    switch (activeStep) {
      case 1:
        return "LensPrescription";
      case 2:
        return "LensMaterial";
      case 3:
        return "Addon";
      case 4:
        return "LensReview";
    }
  };
  const pageType = getPageType();

  const getProductsData = () => {
    const { upc } = getUpcDetail();
    if (upc) {
      const productData: Products = {
        [upc]: {
          Status: "Available",
          Preorder: "1",
          Category: "RX",
          FrameType: "STD",
          LensType: "RX",
          ShippingTo: selectedDoor?.orgentityName as string,
          Units: "1",
          InsuranceAmount: "",
          TaxRate: "",
          LensUPC: selectedLens?.lensDescription.replace(" ", "_"),
        },
      };
      const price = rxPrices?.find((rxPrice: RxPrice) => {
        return rxPrice.itemIdentifier.includes("LSA");
      });
      if (price) {
        productData[upc].Price = price.whsPrice.toString();
        productData[upc].PriceFull = price.whsPrice.toString();
      }
      return productData;
    }
    return undefined;
  };
  const selectingProductDependcy: boolean =
    !location.state?.selectedProduct ||
    (location.state?.selectedProduct && rxAutoselectionStatus === "SUCCESS");

  useTrackView(
    {
      Page_Section1: "LensPanel",
      Page_Section2: "",
      Page_Type: pageType,
      Products: getProductsData(),
    },
    { activeStep, isFrameAutoSelectionComplete, selectingProductDependcy }
  );
  //# Analytics end

  useEffect(() => {
    if (+step > 1 && !prescriptionDetails) {
      window.location.replace(getPagePath("/rx-prescription?rxFlow=Authentics"));
    }

    if (location.state && Object.keys(brandGroupByType).length) {
      // sets order item id to send to back end on add to cart
      const orderItemId = location.state.selectedProduct?.orderItemId || location.state.orderItemId;
      if (orderItemId) {
        setOrderItemId(orderItemId);
      }
      // lanch frame auto selection
      if (location.state.selectedProduct) {
        selectProduct(location.state.selectedProduct, handleMultiSelectChange);
        if (location.state.selectedProduct.isFromCart) {
          setSelectedJobType("RX_COMPLETE_JOB");
        }
      }
      // launch prescription autocompletion
      if (location.state.orderItemId) {
        dispatch(
          getRxCartItem({
            orderItemsId: location.state.orderItemId,
            handleMultiSelectChange,
            goToNextStep,
            setRxAutoselectionStatus,
            hideAll,
          })
        );
      }
      // history.replace({ ...history.location, state: undefined });
    }

    return () => {
      dispatch(resetRxState());
    };
  }, []);

  useEffect(() => {
    if (rxCartItem && !selectedJobType) {
      setSelectedJobType(getJobTypeLabel[rxCartItem.xitem_field1 as string]);
    }
  }, [rxCartItem]);

  useEffect(() => {
    if (prevLang !== undefined && prevLang !== currentLang) {
      resetAll();
    }
  }, [currentLang]);

  useEffect(() => {
    if (
      brandGroups === null &&
      rxAutoselectionStatus !== "LOADING" &&
      selectedDoor &&
      canRender("RX", selectedDoor)
    ) {
      setSelectList(selectInitialState);
      dispatch(getBrandGroups({ term: "*" }));
      setActiveStep(1);
    }
    if (isMultidoor && selectedDoor && selectedDoor !== selectedDoorInternal) {
      setSelectedDoorInternal(selectedDoor);
      resetAll();
      dispatch(getBrandGroups({ term: "*" }));
      setActiveStep(1);
    }
  }, [selectedDoor]);

  useEffect(() => {
    if (prevStep && activeStep !== prevStep) {
      if (activeStep > 1)
        history.push(getPagePath(`/rx-prescription?step=${activeStep}&rxFlow=Authentics`));
      else history.push(getPagePath("/rx-prescription?rxFlow=Authentics"));
    }

    if (prevStep > activeStep) {
      const selectsToClear: (keyof SelectList)[] = [];
      if (activeStep < 2) {
        dispatch(resetStep2());
        selectsToClear.push("lensType");
      }
      if (activeStep < 3) {
        dispatch(resetStep3());
        selectsToClear.push("laterality", "madeIn", "selectableOpt");
      }
      if (activeStep === 2) {
        handleClearPrescription();
      }
      handleSelectChange(null, ...selectsToClear);
      dispatch(resetStep4());
    }
  }, [activeStep]);

  useEffect(() => {
    return history.listen((location: any) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
        } else {
          if (+step > 1) {
            setLocationKeys((keys) => [location.key, ...keys]);

            // Handle back event
            setActiveStep(+step - 1);
          }
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    // go when new focal type get selected
    if (
      focalTypeDetails &&
      selectList.focalType &&
      selectList.focalType.value !== prevSelectedFocal?.value &&
      !prescriptionDetails?.[selectList.focalType.value]
    ) {
      // fallback to label in case is old saved focaltype
      const selfocalTypeDetails = focalTypeDetails.find(
        (focalTypeDetail) =>
          focalTypeDetail.focalTypeName === selectList.focalType?.value ||
          focalTypeDetail.focalTypeName === selectList.focalType?.label
      );

      if (selfocalTypeDetails) {
        const { prescrDetails, addPrescrDetails } = getPrescriptionDetails(
          selfocalTypeDetails,
          rxCartItem,
          false
        );
        const newPrescrDetails =
          selectList.focalType.value !== "PLANO"
            ? addPrevSphereAndPd(prescrDetails)
            : prescrDetails;

        const newPrescriptionDetails = { ...prescriptionDetails };
        newPrescriptionDetails[selectList.focalType.value] = newPrescrDetails ?? prescrDetails;

        setPrescriptionDetails(newPrescriptionDetails);
        setPrescriptionAdditionalDetails(addPrescrDetails);
      }
    }

    // go when focal type changes and prev focal type exists
    if (
      focalTypeDetails &&
      prescriptionDetails &&
      selectList.focalType &&
      prevSelectedFocal &&
      selectList.focalType.value !== prevSelectedFocal.value &&
      prescriptionDetails[prevSelectedFocal.value]
    ) {
      const newSelectedPrescriptionDetails = addPrevSphereAndPd(selectedPrescriptionDetails);

      if (newSelectedPrescriptionDetails) {
        const newPrescrDetails = { ...prescriptionDetails };
        newPrescrDetails[selectList.focalType.value] = newSelectedPrescriptionDetails;
        setPrescriptionDetails(newPrescrDetails);
      }
    }
  }, [selectList.focalType]);

  const addPrevSphereAndPd = (
    prescrDetails: FocalTypePrescription[]
  ): FocalTypePrescription[] | null => {
    if (prescriptionDetails && prevSelectedFocal) {
      const newPrescrDetails: FocalTypePrescription[] | null = [...prescrDetails];
      const prevSphere = prescriptionDetails[prevSelectedFocal.value].find(
        (presc) => presc.parameterName === "SPHERE"
      );
      const prevPd = prescriptionDetails[prevSelectedFocal.value].find(
        (presc) => presc.parameterName === "PD"
      );
      const prevOct = prescriptionDetails[prevSelectedFocal.value].find(
        (presc) => presc.parameterName === "OCHT"
      );

      const sphereI = newPrescrDetails.findIndex((presc) => presc.parameterName === "SPHERE");
      const pdI = newPrescrDetails.findIndex((presc) => presc.parameterName === "PD");
      const ochtI = newPrescrDetails.findIndex((presc) => presc.parameterName === "OCHT");
      if (prevSphere && prevPd && sphereI !== -1 && pdI !== -1) {
        newPrescrDetails[sphereI] =
          selectList.focalType?.value !== "PLANO"
            ? (prevSphere as FocalTypePrescription)
            : { ...prevSphere, left: "+0.00", right: "+0.00" };
        newPrescrDetails[pdI] =
          selectList.focalType?.value !== "PLANO"
            ? (prevPd as FocalTypePrescription)
            : { ...prevPd, left: "32", right: "32" };
        if (selectList.focalType?.value !== "PROGRESSIVE" && prevOct && ochtI !== -1) {
          newPrescrDetails[ochtI] =
            selectList.focalType?.value !== "PLANO"
              ? (prevOct as FocalTypePrescription)
              : { ...prevOct, left: "22", right: "22" };
        }
        return newPrescrDetails;
      }
      if (prevOct && ochtI !== -1) {
        newPrescrDetails[ochtI] =
          selectList.focalType?.value !== "PLANO"
            ? (prevOct as FocalTypePrescription)
            : { ...prevOct, left: "22", right: "22" };
      }
    }
    return null;
  };

  const resetForm = ({ fields, preventModelSelectWipe }: ResetFormParams) => {
    if (fields.includes("brandGroup")) {
      dispatch(setBrandGroups(null));
    }
    if (fields.includes("brand")) {
      dispatch(setBrands(null));
    }
    if (fields.includes("jobType")) {
      dispatch(setJobTypeList(null));
    }
    if (fields.includes("model") && !preventModelSelectWipe) {
      dispatch(setPartnumbers(null));
    }
    if (fields.includes("color")) {
      dispatch(setColors(null));
    }
    if (fields.includes("size")) {
      dispatch(setSizes(null));
    }
    if (fields.includes("upc")) {
      dispatch(setUpcs(null));
    }
    if (fields.includes("focalType")) {
      dispatch(setFocalTypeDetails(null));
    }
    handleSelectChange(null, ...fields);
  };

  const getFrameObjToSend = (): CheckFrameReq => {
    const { model, size, color, upc } = getUpcDetail();
    let setStringColor;
    const colorCode = color?.label?.split("-");
    const splitColor = `${colorCode[0]}-${colorCode[1]}`;

    if (colorCode.length > 2 && splitColor.length < 7) {
      setStringColor = splitColor;
    } else {
      setStringColor = colorCode[0];
    }

    return {
      itemIdentifier: getPartnumberFromMCL(
        model?.value as string,
        setStringColor,
        size?.value as string
      ),
      upc,
      jobType: selectList?.jobType?.value as string,
      prescriptions: eyes?.map((eye: Eye) => ({
        eyeIdentifier: eye?.toUpperCase(),
        parameters: selectedPrescriptionDetails?.map((presc) => ({
          parameterName: presc?.parameterName,
          parameterValue: presc[eye],
          isRequired: presc?.isRequired,
          minValue: "",
          maxValue: "",
          stepBetweenMinAndMax: "",
          formatRegex: "",
        })) as FocalTypePrescription[],
      })),
    };
  };

  const getLensObjToSend = (lensCategoryIdentifier?: string): GetLensesPayload => {
    const lensObjToAdd: {
      lensCategoryIdentifier: string | null;
      refraction: number;
      height: number;
      focalTypeName: string;
    } = {
      lensCategoryIdentifier: null,
      refraction: 0,
      height: 0,
      focalTypeName: "",
    };

    lensObjToAdd.lensCategoryIdentifier = lensCategoryIdentifier || null;
    lensObjToAdd.height = selectedFrame?.height as number;
    lensObjToAdd.refraction = (selectedFrame?.refraction || 0) as number;
    lensObjToAdd.focalTypeName = selectList.focalType?.value as string;

    const frameObj = getFrameObjToSend();

    return { ...frameObj, ...lensObjToAdd };
  };

  const getMakabilityObjToSend = (): PostMakeabilityPayload => {
    const prescription = eyes.map((eye: Eye) => ({
      eyeIdentifier: eye.charAt(0).toUpperCase(),
      parameters: selectedPrescriptionDetails.map((detail) => {
        let parameterName = detail.parameterName.toLocaleLowerCase();
        if (parameterName === "directionud") {
          parameterName = "directionUd";
        }
        if (parameterName === "faceformtilt") {
          parameterName = "faceformTilt";
        }
        if (parameterName === "pantotilt") {
          parameterName = "pantoTilt";
        }
        if (parameterName === "prismud") {
          parameterName = "prismUd";
        }
        if (parameterName === "vertexfitted") {
          parameterName = "vertexFitted";
        }
        return {
          parameterName,
          parameterValue: detail[eye] as string,
        };
      }),
    })) as MakeabilityPrescription[];

    const makabilityObjToSend: PostMakeabilityPayload = {
      billTo: selectedDoor?.orgentityName as string,
      shipTo: selectedDoor?.orgentityName as string,
      brand: selectList.brand?.value as string,
      jobType: selectList.jobType?.value as string,
      focalType: selectList.focalType?.value.toString() as string,
      focalTypeName: selectList.focalType?.value as string,
      frameUpc: selectedFrame?.upc as string,
      lensId: selectedLens?.lensIdentifier as string,
      customerReference1: customerReference.name,
      customerReference2: customerReference.surname,
      prescription,
    };
    if (customerOwnFrame !== null) {
      makabilityObjToSend.isCustomerFrame = customerOwnFrame;
    }
    if (selectList.laterality) {
      makabilityObjToSend.laterality = selectList.laterality.value as string;
    }
    return makabilityObjToSend;
  };

  const handleNextStep = () => {
    dispatch(setRxSubmitError(null));
    switch (activeStep) {
      case 1:
        fillSphereInput();
        dispatch(
          checkFrame({
            req: getFrameObjToSend(),
            focalTypeName: selectList.focalType?.value as string,
            successHandler: goToNextStep,
          })
        );
        break;
      case 3:
        dispatch(
          postMakeability({
            makeabilityObject: getMakabilityObjToSend(),
            callback: (success?: boolean) => {
              if (success != false) {
                if (isMultidoor && selectedDoor)
                  dispatch(
                    getRxPrices({
                      req: itemsToPrice(),
                      doorId: selectedDoor.orgentityId,
                      successHandler: goToNextStep,
                    })
                  );
                else
                  dispatch(
                    getRxPrices({
                      req: itemsToPrice(),
                      successHandler: goToNextStep,
                    })
                  );
              }
            },
          })
        );
        break;
      default:
        goToNextStep();
        break;
    }
  };

  const fillSphereInput = () => {
    const newSelectedPrescriptionDetails = [...selectedPrescriptionDetails];
    const SphereI = newSelectedPrescriptionDetails?.findIndex(
      (presc) => presc.parameterName === "SPHERE"
    );

    if (SphereI || SphereI === 0) {
      const SPHERE = newSelectedPrescriptionDetails[SphereI];
      SPHERE.left = SPHERE.left === "" ? "+0.00" : SPHERE.left;
      SPHERE.right = SPHERE.right === "" ? "+0.00" : SPHERE.right;
    }
    const newPrescrDetails = { ...prescriptionDetails };
    if (selectList.focalType && newPrescrDetails[selectList.focalType.value]) {
      newPrescrDetails[selectList.focalType.value] = newSelectedPrescriptionDetails;
    }
    setPrescriptionDetails(newPrescrDetails);
  };

  const goToPrevStep = () => {
    dispatch(setRxSubmitError(null));
    setActiveStep(activeStep - 1);
  };

  const goToNextStep = (step?: number) => {
    const stepToGo = step || activeStep + 1;
    setActiveStep(stepToGo);
  };

  const itemsToPrice = (): string[] => {
    return [selectedLens?.lensIdentifier as string, selectedFrame?.itemIdentifier as string];
  };

  const handleAddToCart = () => {
    if (activeStep === 3 && hideAll) {
      //Case in which user cannot see prices so it doesn't have a step 4, but still needs to call makeability service
      dispatch(
        postMakeability({
          makeabilityObject: getMakabilityObjToSend(),
          callback: (success?: boolean) => {
            if (success != false) {
              sendAnalyticsData({
                id: "AddToCart",
                Products: getProductsData(),
              });
              const cartItemToSend: addRxItemsToPrecartPayload = {
                req: rxCartPayload,
                itemsToPrice: itemsToPrice(),
                successHandler: redirectToCart,
              };
              dispatch(addRxItemsToPrecart(cartItemToSend));
            }
          },
        })
      );
    } else {
      sendAnalyticsData({
        id: "AddToCart",
        Products: getProductsData(),
      });
      const cartItemToSend: addRxItemsToPrecartPayload = {
        req: rxCartPayload,
        itemsToPrice: itemsToPrice(),
        successHandler: redirectToCart,
      };
      dispatch(addRxItemsToPrecart(cartItemToSend));
    }
  };

  const redirectToCart = () => {
    history.push(getPagePath("/cart"));
  };

  const isNextStepEnabled = () => {
    switch (activeStep) {
      case 1:
        return (
          isUpcValid() &&
          (arePrescriptionValid() || selectList.focalType?.value === "PLANO") &&
          isConstumerOwnFrameValid()
        );
      case 2:
        return selectedLens !== null;
      case 3:
        return (
          areAdditionalInfoValid() &&
          (areAddPrescriptionValid() || (hideAll && !makeability?.isBlockingError))
        );
      case 4:
        return !makeability?.isBlockingError;
      default:
        return true;
    }
  };

  const isNextStepLoading = () => {
    switch (activeStep) {
      case 1:
        return checkFrameStatus === "LOADING";
      case 3: {
        if (hideAll) {
          return addRxToPrecartStatus === "LOADING" || makeabilityStatus === "LOADING";
        }
        return getRxPricesStatus === "LOADING" || makeabilityStatus == "LOADING";
      }
      case 4: {
        return addRxToPrecartStatus === "LOADING";
      }
      default:
        return false;
    }
  };

  // conditionals
  const isUpcValid = (): boolean => {
    return !!((selectList.size && selectList.model && selectList.color) || selectList.upc);
  };

  const arePrescriptionValid = (): boolean => {
    const hasError = selectedPrescriptionDetails?.some((presc) => {
      const hasError = presc.leftError || presc.rightError;
      return hasError;
    });

    // check default required parameters
    const requiredPrescriptionsAreEvaluated = selectedPrescriptionDetails
      ?.filter((presc) => presc.isRequired && presc.parameterName !== "SPHERE")
      .every((presc) => presc.right && presc.left);

    // check conditionally required parameters
    // specifically that AXIS is filled if CYLINDER is, but indipendently for right/left lenses
    const isCylinderFilled = cylinderIsFilled(selectedPrescriptionDetails);
    const condRequiredPrescriptionsAreEvaluated = selectedPrescriptionDetails
      ?.filter(
        (presc) =>
          (isCylinderFilled.right || isCylinderFilled.left) && presc.parameterName === "AXIS"
      )
      .every((presc) => checkRightLeftRequiredPrescription(isCylinderFilled, presc));

    return !!(
      !hasError &&
      requiredPrescriptionsAreEvaluated &&
      condRequiredPrescriptionsAreEvaluated
    );
  };

  const areAddPrescriptionValid = (): boolean => {
    return !prescriptionAdditionalDetails?.some((presc) => {
      const hasError = presc.leftError || presc.rightError;
      return hasError;
    });
  };

  const isConstumerOwnFrameValid = (): boolean => {
    if (selectList.jobType?.value !== "RX_FRAME_TO_COME") {
      return true;
    }
    return customerOwnFrame !== null;
  };

  const areAdditionalInfoValid = () => {
    const isMadeInSel = !madeInAndItemsInPackage?.madeInOptions.length || selectList.madeIn;
    const areItemsIncludedSel =
      !madeInAndItemsInPackage?.itemsIncluded.length || includedItems.length;
    const areSelectableOptSel =
      !madeInAndItemsInPackage?.selectableOptions.length || selectList.selectableOpt;
    const areCustomerRefFilled = Object.values(customerReference).every(
      (customerRef) => customerRef !== ""
    );

    const framesIsIncluded =
      selectList.jobType?.value !== "RX_FRAME_TO_COME" ||
      (selectList.jobType?.value === "RX_FRAME_TO_COME" &&
        includedItems?.includes("ITEMPKG_FRAME"));

    const othersInput =
      selectList.jobType?.value !== "RX_FRAME_TO_COME" ||
      (selectList.jobType?.value === "RX_FRAME_TO_COME" &&
        !includedItems.includes("ITEMPKG_OTHERS")) ||
      (selectList.jobType?.value === "RX_FRAME_TO_COME" &&
        includedItems?.includes("ITEMPKG_OTHERS") &&
        otherDescription.length > 0);

    const lateralitySel = !selectedLens?.lateralityRequired || selectList.laterality;

    return !!(
      isMadeInSel &&
      areCustomerRefFilled &&
      areItemsIncludedSel &&
      areSelectableOptSel &&
      lateralitySel &&
      framesIsIncluded &&
      othersInput
    );
  };

  // handlers
  const handleDetailChange = (
    value: string,
    detailIndex: number,
    isAddition: boolean,
    eye: Eye
  ) => {
    const newDetails = isAddition
      ? [...(prescriptionAdditionalDetails as FocalTypePrescription[])]
      : [...(selectedPrescriptionDetails as FocalTypePrescription[])];
    newDetails[detailIndex][eye] = value;
    newDetails[detailIndex][`${eye}Error` as EyeError] = detailHasError(
      newDetails[detailIndex],
      eye
    )
      ? "LUX_RXO_PROCESS_PRESCRIPTION_INVALID_" + newDetails[detailIndex].parameterName
      : undefined;

    if (isAddition) {
      setPrescriptionAdditionalDetails(newDetails);
      return;
    }
    const newPrescrDetails = { ...prescriptionDetails };
    if (selectList.focalType && newPrescrDetails[selectList.focalType.value]) {
      newPrescrDetails[selectList.focalType.value] = newDetails;
    }
    setPrescriptionDetails(newPrescrDetails);
  };

  const handleCopyValues = (eye: Eye) => {
    let newDetails =
      activeStep > 1
        ? [...(prescriptionAdditionalDetails as FocalTypePrescription[])]
        : [...(selectedPrescriptionDetails as FocalTypePrescription[])];

    newDetails = newDetails.map((presc) => ({
      ...presc,
      [eye === "right" ? "left" : "right"]: presc[eye],
      [eye === "right" ? "leftError" : "rightError"]: presc[`${eye}Error` as EyeError],
    }));
    const newPrescrDetails = { ...prescriptionDetails };
    if (selectList.focalType && newPrescrDetails[selectList.focalType.value]) {
      newPrescrDetails[selectList.focalType.value] = newDetails;
    }
    activeStep > 1
      ? setPrescriptionAdditionalDetails(newDetails)
      : setPrescriptionDetails(newPrescrDetails);
  };

  const handleClearPrescription = (...prescNames: string[]) => {
    if (!prescNames) {
      prescNames = [];
    }
    let newDetails =
      activeStep > 1
        ? [...(prescriptionAdditionalDetails as FocalTypePrescription[])]
        : [...(selectedPrescriptionDetails as FocalTypePrescription[])];
    newDetails = newDetails.map((presc) => {
      if (prescNames.length === 0 || prescNames.includes(presc.parameterName))
        return {
          ...presc,
          right: "",
          rightError: undefined,
          left: "",
          leftError: undefined,
        };
      return presc;
    });

    const newPrescrDetails = { ...prescriptionDetails };
    if (selectList.focalType && newPrescrDetails[selectList.focalType.value]) {
      newPrescrDetails[selectList.focalType.value] = newDetails;
    }
    activeStep > 1
      ? setPrescriptionAdditionalDetails(newDetails)
      : setPrescriptionDetails(newPrescrDetails);
  };

  const detailHasError = (presc: FocalTypePrescription, eye: Eye): boolean => {
    let prescNum = 0;
    const regex = regexFromStr(presc.formatRegex);
    const prescValue = presc[eye] as string;

    if (prescValue === "") return false;

    const prescValueConvert = `${prescValue}`.replace(",", ".");
    if (!isNaN(+prescValueConvert)) {
      // format the string to match regex
      const regexIsPassing = regex?.test(prescValue);
      if (!regexIsPassing) return true;
      // regex end
      prescNum = +prescValueConvert;
    } else if (typeof prescValue === "string") {
      return !presc.allowedValues?.some(
        (value) => prescValue.toUpperCase() === value.toLocaleUpperCase()
      );
    }

    const isTooLow = prescNum < +presc.minValue;
    const isTooHight = prescNum > +presc.maxValue;
    let isStepCorrect = true;
    if (presc.stepBetweenMinAndMax) {
      isStepCorrect = prescNum % +presc.stepBetweenMinAndMax === 0;
    }

    return isTooLow || isTooHight || !isStepCorrect;
  };

  const resetAll = () => {
    // store
    dispatch(resetRxState());
    //state
    setActiveStep(1);
    setOrderItemId(null);
    setSelectList(selectInitialState);
    setRxAutoselectionStatus("IDLE");
    setPrescriptionDetails(null);
    setPrescriptionAdditionalDetails(null);
    setSelectedJobType(null);
  };

  const handleSelectDoor = (_: Door) => {
    resetAll();
  };

  const getNextStepLabel = () => {
    switch (activeStep) {
      case 1:
        return translateLabel("RX_DISPLAY_LENS");
      case 2:
        return translateLabel("RX_CONTINUE");
      case 3:
        return hideAll ? translateLabel("RX_ADD_TO_CART") : translateLabel("RX_CALCULATE_PRICE");
      case 4:
        return translateLabel("RX_ADD_TO_CART");
      default:
        return;
    }
  };

  return (
    <WrapperLayout>
      <StyledContainer>
        <ConditionalComponentRender privilege="RX" loader={LoaderInputs}>
          <StepperContainer>
            <Stepper
              steps={config.steps}
              active={activeStep}
              backgroundVariant={true}
              setActive={setActiveStep}
              backgroundColor="background-light"
              maxWidth
            />
          </StepperContainer>
          <ContentContainer data-element-id={`LensPanel_Step${activeStep}`}>
            {rxAutoselectionStatus === "LOADING" ? (
              <div className="loader-container">
                <Loader sizePx={48} />
              </div>
            ) : (
              <>
                {activeStep < 2 ? (
                  <ModelSelection
                    isFromCart={!!location.state?.orderItemId}
                    isFromPdpOrPlp={!!location.state?.selectedProduct}
                    selectedJobType={selectedJobType}
                    activeStep={activeStep}
                    resetForm={resetForm}
                    resetAll={resetAll}
                    prescriptionDetails={selectedPrescriptionDetails}
                    handleClearPrescription={handleClearPrescription}
                    selectList={selectList}
                    handleSelectChange={handleSelectChange}
                    handleMultiSelectChange={handleMultiSelectChange}
                    selectedBrand={selectedBrand[0]} //Authentics prescription only takes one brand
                  />
                ) : (
                  <ModelSelectionSummary
                    getUpcDetail={getUpcDetail}
                    brandGroup={selectList.brandGroup}
                    brand={selectList.brand?.label}
                    jobType={translateLabel((selectList.jobType?.value as string) || "")}
                    jobTypeIdentifier={(selectList.jobType?.value as string) || ""}
                    focalType={selectList.focalType?.value as string}
                    focalTypeTranslated={
                      focalTypeDetails?.find((_) => _.focalTypeName === selectList.focalType?.value)
                        ?.focaltypenametranslatedname
                    }
                    activeStep={activeStep}
                  />
                )}
                {!!selectList.brand && (
                  <>
                    <div className="prescription-container">
                      <div className="prescription-header d-flex">
                        <div className="prescription-title">
                          <CustomText
                            as="h3"
                            font="font-bold"
                            fontSizePx={18}
                            lineHeightPx={24}
                            marginBottomPx={10}
                          >
                            {translateLabel("RX_PRESCRIPTION")}
                          </CustomText>
                          <CustomText
                            as="span"
                            font="font-light"
                            color="gray-dark"
                            fontSizePx={14}
                            lineHeightPx={24}
                          >
                            {translateLabel("RX_NOTE")}
                          </CustomText>
                        </div>
                        {activeStep === 1 && selectList.focalType?.value !== "PLANO" && (
                          <div className="prescription-clear">
                            <Button
                              type="tertiary"
                              onClick={() => handleClearPrescription()}
                              startIcon={<TrashIcon />}
                            >
                              {translateLabel("RX_CLEAR")}
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="d-flex">
                        <PrescriptionTable
                          prescriptionDetails={selectedPrescriptionDetails}
                          handleDetailChange={handleDetailChange}
                          handleCopyValues={handleCopyValues}
                          readOnly={activeStep > 1}
                          readOnlyPlano={selectList.focalType?.value === "PLANO"}
                          hasCopyButtons
                          maxWidtInputPx={64}
                        />
                        {activeStep > 2 && selectedLens?.additionalParameterRequired && (
                          <PrescriptionTable
                            prescriptionDetails={prescriptionAdditionalDetails}
                            handleDetailChange={handleDetailChange}
                            handleCopyValues={handleCopyValues}
                            readOnly={activeStep > 3}
                            hasCopyButtons
                            maxWidtInputPx={64}
                            hasEyeLabels={false}
                            isAdditional
                          />
                        )}
                      </div>
                      <CustomText
                        as="p"
                        font="font-light"
                        color="gray-dark"
                        fontSizePx={14}
                        lineHeightPx={24}
                        marginTopPx={52}
                        marginBottomPx={24}
                      >
                        {translateLabel("RX_THE_HEIGHTS")}
                      </CustomText>
                      <CustomText
                        as="p"
                        font="font-light"
                        color="gray-dark"
                        fontSizePx={14}
                        lineHeightPx={24}
                      >
                        {translateLabel("RX_VALUE_IS_NOT_NECESSARY")}
                      </CustomText>
                      <CustomText
                        as="p"
                        font="font-light"
                        color="gray-dark"
                        fontSizePx={14}
                        lineHeightPx={24}
                        marginBottomPx={54}
                      >
                        {translateLabel("RX_VALUE_OF_THE_HEIGHT")}
                      </CustomText>
                    </div>
                    {activeStep === 2 && (
                      <LensSelection
                        selectList={selectList}
                        selectedDoor={selectedDoor}
                        handleSelectChange={handleSelectChange}
                        getLensObjToSend={getLensObjToSend}
                      />
                    )}
                    {activeStep > 2 && (
                      <AdditionalInfo
                        handleSelectChange={handleSelectChange}
                        selectList={selectList}
                        activeStep={activeStep}
                      />
                    )}
                    {activeStep === 4 && <Recap selectList={selectList} />}
                    <div className="complete-step-container">
                      <div>
                        {activeStep === 4 && (
                          <CustomText as="span" color="primary">
                            {translateLabel("RX_SERVICE_CHARGES")}
                          </CustomText>
                        )}
                      </div>
                      <OuterBoxButton>
                        {activeStep > 1 && (
                          <div className="prev-step">
                            <LinkButton
                              onClick={goToPrevStep}
                              data-element-id={`LensPanel_Step${activeStep}_Back`}
                            >
                              {translateLabel("RX_EDIT_PREVIOUS_CHOICES")}
                            </LinkButton>
                          </div>
                        )}
                        <Button
                          type="primary"
                          onClick={
                            activeStep === 4 || (activeStep === 3 && hideAll)
                              ? handleAddToCart
                              : handleNextStep
                          }
                          disabled={!isNextStepEnabled()}
                          isLoading={isNextStepLoading()}
                          data-element-id={
                            activeStep === 1
                              ? "LensPanel_Step1_Display"
                              : activeStep === 2
                              ? "LensPanel_Step2_Continue"
                              : activeStep === 3
                              ? "LensPanel_Step3_Continue"
                              : activeStep === 4
                              ? "LensPanel_Step4_AddToCart"
                              : ""
                          }
                        >
                          {getNextStepLabel()}
                        </Button>
                      </OuterBoxButton>
                    </div>
                    {rxSubmitError && (
                      <div className="error-container">
                        <div className="error-text">
                          <CustomText
                            as="p"
                            color="red"
                            fontSizePx={11}
                            lineHeightPx={16}
                            marginTopPx={3}
                            isFlex={false}
                            textAlign="right"
                          >
                            {rxSubmitError?.errorMessage?.includes("TO_TRANSLATE:")
                              ? translateLabel(
                                  rxSubmitError.errorMessage.replace("TO_TRANSLATE:", "")
                                )
                              : rxSubmitError.errorMessage.toUpperCase().split(" ").join("_")}
                          </CustomText>
                        </div>
                        <IconContainer>
                          <div className="error-icon">
                            <InfoIcon />
                          </div>
                        </IconContainer>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </ContentContainer>
        </ConditionalComponentRender>
      </StyledContainer>
    </WrapperLayout>
  );
}

const Header = styled.div`
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;

  .multidoor-container {
    width: 19rem;
  }
`;

const StepperContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.75rem 0 4.563rem;
  box-sizing: border-box;
  > div {
    max-width: 90%;
  }
`;

const StyledContainer = styled.div`
  padding-bottom: 5rem;
  .text-center,
  .text-center span {
    text-align: center;
    justify-content: center;
  }
`;
const ContentContainer = styled.div`
  padding: 3rem;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
  min-height: 30rem;

  .loader-container {
    display: flex;
    justify-content: center;
  }

  .prescription-container {
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
    margin-bottom: 2rem;
    .prescription-header {
      justify-content: space-between;
      margin-bottom: 2rem;
    }
    .prescription-form-header,
    .prescription-form-input-list {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .prescription-form-item,
      span {
        display: block;
        width: 3.5rem;
        margin-right: 0.5rem;
      }
      .prescription-form-label span {
        width: 2.625rem;
        margin-right: unset;
      }
    }
    .prescription-form-header {
      align-items: flex-end;
      margin-left: 2.625rem;
    }
  }

  .complete-step-container {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .prev-step {
      display: flex;
      margin-right: 4.5rem;
    }
  }

  .error-container {
    color: ${(props) => props.theme.palette.feedback.error};
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;

    .error-text {
      width: 40%;
    }
    .error-icon {
      width: 2rem;
      svg {
        fill: ${(props) => props.theme.palette.feedback.error};
        width: 2rem;
      }
    }
  }
`;

const IconContainer = styled.div`
  svg {
    fill: ${(props) => props.theme.palette.feedback.error};
    width: 2rem;
  }
`;

const OuterBoxButton = styled.div`
  display: flex;
  flex-direction: row;
`;
