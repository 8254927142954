import React, { useEffect, useState } from "react";
import SizeRadioButton from "../styled-UI/SizeRadioButton";
import styled from "styled-components/macro";
import { useForm } from "react-hook-form";
import useTranslation from "../../hooks/useTranslation";

import { Sku } from "../../interfaces/productInterface";
import CustomScrollbar from "../styled-UI/CustomScrollbar";

interface Props {
  skus: Sku[];
  updateSelectedSku: (uniqueId: string) => void;
  uppercase?: boolean;
  whiteSpace?: string;
  familySize?: boolean;
  radioIcon?: boolean;
  autoSelection?: boolean;
  uniqueID?: string;
}

interface FormValues {
  size: string;
}

const SizeSelector = ({
  skus,
  updateSelectedSku,
  uppercase,
  familySize,
  whiteSpace,
  radioIcon = true,
  autoSelection,
  uniqueID,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const { register, watch } = useForm<FormValues>();
  const watchRadioButton = watch("size");

  useEffect(() => {
    if (autoSelection && uniqueID) updateSelectedSku(skus[0].uniqueID);
    else updateSelectedSku(watchRadioButton);
  }, [watchRadioButton, autoSelection, uniqueID]);

  const sizesNumber = skus.length;

  const printSizes = () => {
    return (
      <Container>
        {skus?.map(
          (sku) =>
            sku.size?.values?.[0] && (
              <Wrap isSelected={(!radioIcon && watchRadioButton === sku.uniqueID) || autoSelection}>
                <SizeRadioButton
                  sizeNumber={sku.size?.values?.[0].value}
                  label={translateLabel("SIZE")}
                  name="size"
                  value={sku.uniqueID}
                  ref={register}
                  uppercase={uppercase}
                  whiteSpace={whiteSpace}
                  radioIcon={radioIcon}
                  {...(familySize ? { familySize: sku?.attributes } : {})}
                />
              </Wrap>
            )
        )}
      </Container>
    );
  };

  return (
    <>
      {sizesNumber > 6 && (
        <FixedHeightContainer>
          <CustomScrollbar offsetRight>{printSizes()}</CustomScrollbar>
        </FixedHeightContainer>
      )}
      {sizesNumber <= 6 && printSizes()}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FixedHeightContainer = styled.div`
  height: 20.375rem;
`;

const Wrap = styled.div<{ isSelected?: boolean }>`
  margin: 0.5rem 0;
  ${(props) =>
    props.isSelected && `border: 1px solid ${props.theme.palette.primary}; border-radius: 0.25rem;`}
`;

export default SizeSelector;
