import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import clsx from "clsx";
import useTranslation from "../../../../hooks/useTranslation";
import useFilteredSelector from "../../../../hooks/useFilteredSelector";

import { OrderItem, OrderCategoryInfo, CartLoading } from "../../../../store/cart/cartInterfaces";
import { selectCartLoading, toggleSelectedCategory } from "../../../../store/cart/cartSlice";
import { deletePrecartCategory } from "../../../../store/cart/cartSagas";
import { CheckoutOrderCategoryInfo } from "../../../../store/checkout/checkoutInterfaces";
import { Door } from "../../../../store/user/userInterfaces";
import { deleteOrderToApproveCategory } from "../../../../store/orders-to-approve/ordersToApproveSaga";
import { capitalizeFirstLetter } from "../../../../utils/utils";
import CustomText from "../../../styled-UI/CustomText";
import IconButton from "../../../styled-UI/IconButton";
import Checkbox from "../../../styled-UI/Checkbox";
import Popup from "../../../styled-UI/Popup";
import Button from "../../../styled-UI/Button";
import CartTile from "../../../widgets/Tile/cart-tile/CartTile";
import TYPTile from "../../../widgets/Tile/TYPTile";

import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-icon.svg";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrowhead-down-icon.svg";
import { selectIsBackOfficeUser } from "../../../../store/user/userSlice";
import TYPTileEssilor from "../../../widgets/Tile/TYPTileEssilor";

interface Props {
  title: string;
  outOfStock?: boolean;
  isMultidoor: boolean;
  cartItems: OrderItem[];
  categoryInfo?: OrderCategoryInfo | CheckoutOrderCategoryInfo;
  onlyView?: boolean;
  currentDoor: Door;
  isOrderApproval?: boolean;
  currencyTYP?: string;
  isEssilor?: boolean;
}

export const getSectionTitle = (
  title: string,
  outOfStock: boolean,
  translateFunc: (label: string) => string // this is so that we can reuse the same logic in CartPriceSummary (right-side summary)
): string => {
  if (outOfStock) return translateFunc("OUT_OF_STOCK");

  if (title?.toUpperCase() === "SPARE_PARTS") return translateFunc("CART_SPARE_PARTS");
  if (title?.toLowerCase() === "frame:custom") return translateFunc("CART_CUSTOM");
  if (title?.toUpperCase() === "ONE_PORTAL") return translateFunc("CART_ONE_PORTAL");

  return capitalizeFirstLetter(title);
};

const CartSection = ({
  title,
  outOfStock = false,
  isMultidoor = false,
  cartItems,
  categoryInfo,
  onlyView = false,
  currentDoor,
  isOrderApproval,
  currencyTYP,
  isEssilor = false,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();

  const [openSection, setOpenSection] = useState<boolean>(true);
  const [isOpenPopUp, setIsOpenPopUp] = useState<boolean>(false);
  const handleClosePopUp = () => setIsOpenPopUp(false);
  const isBackofficeUser = useSelector(selectIsBackOfficeUser);

  const loading = useFilteredSelector<CartLoading>(
    selectCartLoading,
    (_) => _.id === "multiple-delete"
  );

  useEffect(() => {
    !loading && setIsOpenPopUp(false);
  }, [loading]);

  const deletePopup = (
    <Popup
      isOpen={isOpenPopUp}
      close={handleClosePopUp}
      title={translateLabel("CART_EMPTY_CATEGORY_POPUP_TITLE")}
      isCentered
    >
      <ModalContainer>
        <WrapMessage>
          <CustomText as="p" fontSizePx={18} font="font-bold" color="primary" lineHeightPx={24}>
            {translateLabel("CART_EMPTY_CATEGORY_POPUP_MESSAGE")}
          </CustomText>
        </WrapMessage>
        <ButtonContainer>
          <Button
            isLoading={loading !== null}
            type="primary"
            onClick={() => {
              isOrderApproval
                ? dispatch(deleteOrderToApproveCategory(cartItems))
                : dispatch(deletePrecartCategory(cartItems));
            }}
            data-element-id="Products_Empty_Confirm"
          >
            {translateLabel("CONFIRM")}
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </Popup>
  );

  return (
    <Section
      whiteBackground={isOrderApproval}
      className={clsx(isMultidoor && "multidoor")}
      data-element-id="Products"
    >
      {deletePopup}
      <Header>
        <Title>
          {/* Don't show checkbox if outOfStock */}
          {!outOfStock && !onlyView && !isOrderApproval && (
            <Checkbox
              label="sunglasses"
              name="sunglasses"
              hideLabel
              controlled
              isSelectAll={(categoryInfo as OrderCategoryInfo)?.selectedNumber != 0}
              checked={
                (categoryInfo as OrderCategoryInfo)?.selectedNumber == categoryInfo?.totalNumber
              }
              onChange={() => {
                categoryInfo &&
                  dispatch(
                    toggleSelectedCategory({
                      productCategory: categoryInfo.productCategory,
                      multidoorId: categoryInfo.multidoorId,
                      currentStatus:
                        (categoryInfo as OrderCategoryInfo).selectedNumber ==
                        categoryInfo.totalNumber,
                    })
                  );
              }}
            />
          )}
          <CustomText
            as="span"
            color="primary"
            fontSizePx={28}
            font="font-medium"
            lineHeightPx={28}
            marginLeftPx={onlyView ? 0 : 16}
          >
            {getSectionTitle(title, outOfStock, translateLabel)}
          </CustomText>
          <CustomText
            as="span"
            color="primary"
            fontSizePx={28}
            font="font-medium"
            lineHeightPx={28}
            marginLeftPx={8}
          >
            ({categoryInfo?.totalNumber})
          </CustomText>
          {/* Don't show amount of selected if outOfStock */}
          {!onlyView && !outOfStock && !isOrderApproval && (
            <CustomText
              as="span"
              color="primary"
              fontSizePx={14}
              font="font-regular"
              lineHeightPx={28}
              marginLeftPx={16}
            >
              {`${(categoryInfo as OrderCategoryInfo)?.selectedNumber} ${translateLabel(
                "CART_ITEMS_SELECTED"
              )}`}
            </CustomText>
          )}
        </Title>
        <HeaderButtons className="print-hide">
          {!onlyView && (
            <IconButtonContainer>
              <IconButton
                onClick={() => setIsOpenPopUp(true)}
                size="md"
                icon={<TrashIcon />}
                type="no-background-primary"
                disabled={isBackofficeUser && isOrderApproval}
              />
            </IconButtonContainer>
          )}
          <IconButton
            onClick={() => setOpenSection(!openSection)}
            size="md"
            icon={<Arrow />}
            type="primary"
            upsideDown={openSection}
          />
        </HeaderButtons>
      </Header>
      {openSection && (
        <div>
          {!onlyView &&
            cartItems.map((cartItem) => {
              const modelCode = cartItem.skuCode?.split("_")[0];
              const upc = cartItem.sku.upc;

              return (
                <CartTileWrapper key={cartItem.orderItemId}>
                  {cartItem.sku && currentDoor && (
                    <CartTile
                      key={cartItem.orderItemId}
                      orderItem={cartItem}
                      currentDoor={currentDoor}
                      itemInfo={
                        (categoryInfo as OrderCategoryInfo)?.orderItemSelected?.filter((_) => {
                          return _.orderItemId == cartItem.orderItemId;
                        })[0]
                      }
                      isOrderApproval={isOrderApproval}
                      modelCodeAnalytics={modelCode}
                      upcAnalytics={upc}
                      outOfStock={outOfStock ?? cartItem?.outOfStock}
                    />
                  )}
                </CartTileWrapper>
              );
            })}
          {onlyView &&
            cartItems.map((cartItem) => (
              <CartTileWrapper key={cartItem.orderItemId}>
                {!isEssilor && <TYPTile orderItem={cartItem} currencyTYP={currencyTYP} />}
                {isEssilor && (
                  <TYPTileEssilor orderItem={cartItem} currencyTYP={currencyTYP}></TYPTileEssilor>
                )}
              </CartTileWrapper>
            ))}
        </div>
      )}
    </Section>
  );
};

const Section = styled.div<{ whiteBackground?: boolean }>`
  background-color: ${(props) =>
    props.whiteBackground ? props.theme.palette.white : props.theme.palette.gray.light};
  border-radius: 0.25rem;
  padding: 2.125rem 1.375rem 1.75rem;
  box-sizing: border-box;
  margin-bottom: 2rem;

  &.multidoor {
    margin-bottom: unset;
    padding-bottom: unset;
    padding-top: 1.875rem;

    &:last-child {
      padding-bottom: 1.75rem;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const IconButtonContainer = styled.div`
  margin-right: 0.125rem;
`;

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
`;

const CartTileWrapper = styled.div`
  margin-top: 1.5rem;

  &:last-child {
    margin-bottom: unset;
  }
`;

//popup
const ModalContainer = styled.div`
  padding: 3rem 0;
  width: 42.375rem;
`;

const WrapMessage = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export default CartSection;
