import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { selectSelectedDoor } from "../../../store/user/userSlice";
import { postWarrantyReturnParcelData } from "../../../store/aftersales/aftersalesSaga";
import CustomText from "../../styled-UI/CustomText";
import {
  resetReturnParcel,
  saveIsReturnParcelLoading,
  savePrintReturnParcel,
  selectReturnParcelData,
  selectReturnParcelDataStatus,
  selectWarrantyReturn,
} from "../../../store/aftersales/aftersalesSlice";
import ParcelBox from "./ParcelBox";
import useTranslation from "../../../hooks/useTranslation";
import Barcode from "../../widgets/Barcode";
import { ReactComponent as ScissorIcon } from "../../../assets/icons/scissors-icon.svg";
import Logo from "../../layouts/Logo";
import WrapperLayout from "../../layouts/WrapperLayout";
import { WarrantyReturnFrame } from "../../../store/aftersales/aftersalesInterface";

export interface Props {
  id: string;
}

export default function ReturnParcelData({ id }: Props): JSX.Element {
  const dispatch = useDispatch();
  const selectedDoor = useSelector(selectSelectedDoor);
  const returnParcelDataStatus = useSelector(selectReturnParcelDataStatus);
  const returnParcelData = useSelector(selectReturnParcelData);
  const selectedWarrantyReturn = useSelector(selectWarrantyReturn);

  const [parcelWarrantyItems, setParcelWarrantyItems] = useState<WarrantyReturnFrame[] | null>(
    null
  );

  const { translateLabel } = useTranslation();
  let timeout: any = null;

  useEffect(() => {
    if (returnParcelData && selectedWarrantyReturn) {
      const items: WarrantyReturnFrame[] = [];
      selectedWarrantyReturn.items.forEach((item) => {
        return returnParcelData?.warrantyReturnFrameList?.forEach((x) => {
          if (item.skuCode === x.warrantyProductItemIdentifier.replace("__", " ")) {
            return items.push({ ...x, notificationId: item.notificationId });
          }
        });
      });
      setParcelWarrantyItems(items);
    }
  }, [returnParcelData, selectedWarrantyReturn]);

  useEffect(() => {
    dispatch(saveIsReturnParcelLoading(true));
    //get return parcel info
    if (selectedDoor)
      dispatch(
        postWarrantyReturnParcelData({
          warrantyIdentifier: id,
          doorId: selectedDoor?.orgentityId,
        })
      );
  }, [selectedDoor]);

  useEffect(() => {
    //Check if all api are completed before printing
    const totalApis = (returnParcelData?.warrantyReturnFrameList?.length ?? 0) + 1;
    if (
      returnParcelDataStatus.length === totalApis &&
      !returnParcelDataStatus.find((_) => _ === "LOADING")
    ) {
      timeoutCallback();
    }
  }, [returnParcelDataStatus]);

  const timeoutCallback = useCallback(() => {
    //timeout to have time to dowload image
    timeout = setTimeout(() => {
      dispatch(saveIsReturnParcelLoading(false));
      window.print();
    }, 1500);
  }, []);

  const hideParcel = () => dispatch(savePrintReturnParcel({ open: false, warrantyId: null }));

  useEffect(() => {
    window.addEventListener("afterprint", hideParcel);

    return () => {
      if (timeout) clearTimeout(timeout);
      dispatch(resetReturnParcel());
      window.removeEventListener("afterprint", hideParcel);
    };
  }, []);

  return (
    <WrapperLayout>
      <BackgroundWrapper>
        <ContentWrapper>
          <HeaderContainer>
            <Logo heightPx={23} />
          </HeaderContainer>
          <SubHeader>
            <CustomText as="p">{translateLabel("PARCEL_RETURN_DEAR_CUSTOMER_MESS")},</CustomText>
            <CustomText as="p">{translateLabel("PARCEL_RETURN_BELOW_MESSAGE")}</CustomText>
          </SubHeader>
        </ContentWrapper>
        <ItemContainer>
          {parcelWarrantyItems?.map((item) => (
            <ParcelBox
              key={item.warrantyProductItemIdentifier + item.order}
              item={item}
              skuWithBarCode
            />
          ))}
        </ItemContainer>
        <AltertSection>
          <CustomText as="p" underline uppercase fontSizePx={26} lineHeightPx={26}>
            {translateLabel("PARCEL_RETURN_INSERT_PARCEL")}
          </CustomText>
        </AltertSection>
        <CutSection>
          <CutRow>
            <SpacerCut>
              <CustomText as="p" fontSizePx={16} lineHeightPx={16}>
                {translateLabel("PARCEL_RETURN_CUT_MES_ONE")}&nbsp;
              </CustomText>
              <CustomText as="p" underline fontSizePx={16} lineHeightPx={16}>
                {translateLabel("PARCEL_RETURN_CUT_MES_TWO")}&nbsp;
                <CustomText as="p" underline font="font-bold" fontSizePx={16} lineHeightPx={16}>
                  {translateLabel("PARCEL_RETURN_CUT_MES_THREE")}&nbsp;
                </CustomText>
                {translateLabel("PARCEL_RETURN_CUT_MES_FOUR")}
              </CustomText>
              <BoxIcon>
                <ScissorIcon />
              </BoxIcon>
            </SpacerCut>
          </CutRow>
          <CutRow>
            <CustomText as="p" fontSizePx={14} lineHeightPx={14}>
              {translateLabel("PARCEL_RETURN_PRINT_MES_ONE")}&nbsp;
            </CustomText>
            <CustomText as="p" underline fontSizePx={14} lineHeightPx={14}>
              {translateLabel("PARCEL_RETURN_PRINT_MES_TWO")}&nbsp;
              <CustomText as="p" underline font="font-bold" fontSizePx={14} lineHeightPx={14}>
                {translateLabel("PARCEL_RETURN_PRINT_MES_THREE")}&nbsp;
              </CustomText>
              {translateLabel("PARCEL_RETURN_PRINT_MES_FOUR")}
            </CustomText>
          </CutRow>
        </CutSection>
        <LastSectionGrid>
          <ContentSectionGridColumn>
            <BarcodeBox>
              <StampContainer>
                <GreenDot>
                  <CharContainer>
                    <CustomText as="p" fontSizePx={22} lineHeightPx={22} marginBottomPx={16}>
                      W
                    </CustomText>
                  </CharContainer>
                </GreenDot>
                <EmptyDot>
                  <CharContainer>
                    <CustomText as="p" fontSizePx={22} lineHeightPx={22} marginBottomPx={16}>
                      M
                    </CustomText>
                  </CharContainer>
                </EmptyDot>
                <BlueDot>
                  <CharContainer className="blue">
                    <CustomText as="p" fontSizePx={22} lineHeightPx={22} marginBottomPx={16}>
                      DW
                    </CustomText>
                  </CharContainer>
                </BlueDot>
              </StampContainer>
            </BarcodeBox>
            <ServiceRow>
              <CustomText as="p" fontSizePx={16} lineHeightPx={16} marginBottomPx={16}>
                {translateLabel("PARCEL_RETURN_REQ_SERV")}
              </CustomText>
              <CustomText as="p" fontSizePx={16} lineHeightPx={16} uppercase marginLeftPx={16}>
                {translateLabel("WARRANTY_DIGITAL")}
              </CustomText>
            </ServiceRow>
          </ContentSectionGridColumn>
          <ContentSectionGridColumn>
            <CustomText
              as="p"
              underline
              font="font-bold"
              fontSizePx={16}
              lineHeightPx={16}
              uppercase
            >
              {translateLabel("PARCEL_RETURN_PRODUCT_RECIPIENT")}
            </CustomText>
            <AddressBox>
              <CustomText as="p" fontSizePx={28} lineHeightPx={28}>
                {translateLabel("PARCEL_FAST_TRACK_ADDRESS_ONE")}
              </CustomText>
              <CustomText as="p" fontSizePx={28} lineHeightPx={28}>
                {translateLabel("PARCEL_FAST_TRACK_ADDRESS_TWO")}
              </CustomText>
              <CustomText as="p" fontSizePx={28} lineHeightPx={28}>
                {translateLabel("PARCEL_FAST_TRACK_ADDRESS_THREE")}
              </CustomText>
            </AddressBox>
          </ContentSectionGridColumn>
        </LastSectionGrid>
      </BackgroundWrapper>
    </WrapperLayout>
  );
}

const BackgroundWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  height: 100%;
  width: 85%;
  padding: 2.5rem;
`;

const ContentWrapper = styled.div`
  margin: 0 auto;

  span,
  label,
  p {
    color: ${(props) => props.theme.palette.brandBlue};
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media print {
    svg {
      height: 2.875rem;
      max-width: 16rem;
    }
  }
`;

const SubHeader = styled.div`
  margin-bottom: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;

  p {
    color: black;
  }
`;

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

const BarcodeContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  svg {
    width: 12.625rem;
    height: 4.5rem;
  }

  &.bottom {
    svg {
      width: 25.25rem;
      height: 9rem;
    }
  }
`;

const AltertSection = styled.div`
  margin: 1rem;
`;

const CutSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CutRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const LastSectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
`;

const ContentSectionGridColumn = styled.div`
  padding: 1.5rem 1rem;
  border: 2px solid ${(props) => props.theme.palette.brandBlue};
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const AddressBox = styled.div`
  height: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BarcodeBox = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ServiceRow = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
`;

const GreenDot = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: ${(props) => props.theme.palette.feedback.success};
  border-radius: 100%;
  position: relative;

  @media print {
    background-color: #55a63a !important;
    -webkit-print-color-adjust: exact;
  }
`;

const CharContainer = styled.div`
  position: absolute;
  top: 1.375rem;
  right: 1.375rem;

  &.blue {
    right: 0.625rem;
    top: 1.0625rem;
    p {
      color: black;
    }
  }
`;

const StampContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmptyDot = styled.div`
  position: relative;
  width: 4rem;
  height: 4rem;
`;

const BlueDot = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: ${(props) => props.theme.palette.brandBlue};
  border-radius: 100%;
  position: relative;
  border: 4px solid ${(props) => props.theme.palette.primary};

  @media print {
    background-color: #005191 !important;
    -webkit-print-color-adjust: exact;
  }
`;

const SpacerCut = styled.div`
  position: relative;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 3px dashed ${(props) => props.theme.palette.brandBlue};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const BoxIcon = styled.div`
  position: absolute;
  width: 1rem;
  height: 1rem;
  right: 0;
  bottom: -0.5625rem;

  svg {
    fill: ${(props) => props.theme.palette.brandBlue};
  }
`;
