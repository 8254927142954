import { BFFApiDomain } from "../serviceUtils";

// adding, updating and deleting items from the pre-cart
export const precartItemsUpdateUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/precart/items";

// getting or deleting all items from the pre-cart
export const precartUrl = BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/precart";

// setting PO number and notes for each suborder
export const precartUpdateUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/precart/{subOrderId}";

// getting and updating shipping info for the pre-cart
export const precartShippingInfoUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/precart/shipping";

// getting pre-cart count for icon in menu
export const precartCountUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/precart/count";

// delete out of stock precart items
export const precartCleanUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/precart/clean";

// getting product details for productId
export const cartProductDetailsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/{productId}/cartdetails";

// simulate cart
export const precartSimulateUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/simulate";

// getting cart alternative variants
export const cartAlternativeVariantsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/{id}/alternative";

////////////////// RX Brasil

// provides prescription Login
export const cartPrescriptionBrasilLoginUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/prescription/brasil/token";

// provides list of uploaded document for orderItemsId
export const cartPrescriptionBrasilDocumentsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/prescription/brasil/documents";

// upload of documents to prescription brasil
export const cartPrescriptionBrasilUploadUrl =
  globalEnvVariables.rxBrasilEndpoint + "/api/document/json";

////////////////// Set Delivery

//get and save delivery information
export const deliveryUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/order/delivery";
