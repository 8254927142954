import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import LandingPageCarousel from "./landing-page-carousel/LandingPageCarousel";
import LpColourProductTile from "./LpColourProductTile";
import {
  LpColourProductTileInterface,
  LpTextAndColourProductTile as Interface,
} from "../../../interfaces/cmsInterfaces";
import { useSelector } from "react-redux";
import { selectStickyBarType } from "../../../store/store/storeSlice";
import CustomText from "../../styled-UI/CustomText";
import useTranslation from "../../../hooks/useTranslation";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  data: Interface;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LpTextAndColourProductTile = ({
  data,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const [tiles, setTiles] = useState<LpColourProductTileInterface[]>([]);
  const stickyBarType = useSelector(selectStickyBarType);

  useEffect(() => {
    if (data?.linkTarget) {
      switch (stickyBarType) {
        case "ALL":
          setTiles(data?.linkTarget);
          break;
        case "SUNGLASSES":
          const filtered = data?.linkTarget?.filter((_) =>
            _.model?.productCategoryFilter?.valuesIdentifier?.includes("Sunglasses")
          );
          setTiles(filtered);
          break;
        case "EYEGLASSES":
          const filteredEye = data?.linkTarget?.filter((_) =>
            _.model?.productCategoryFilter?.valuesIdentifier?.includes("Eyeglasses")
          );
          setTiles(filteredEye);
          break;
      }
    }
  }, [stickyBarType]);

  return (
    <WrapperLpCarousel>
      {tiles.length === 0 && ( //DOUBLE RENDER BECAUSE OF RERENDERING PROBLEM WITH REACT SLICK
        <LandingPageCarousel
          slidesToShow={2}
          slidesToScroll={2}
          title={data?.title}
          titleColour={data?.titleColour}
          description={data?.description}
          description1Colour={data?.description1Colour}
          isDarkMode={isDarkMode}
          customFont={customFont}
        >
          <div>
            <CustomText
              as="span"
              color={isDarkMode ? "white" : "primary"}
              font={customFont ? "custom-medium" : "font-medium"}
              fontFamily={customFont}
            >
              {translateLabel("LP_NO_PRODUCTS")}
            </CustomText>
          </div>
        </LandingPageCarousel>
      )}
      {tiles.length > 0 && (
        <LandingPageCarousel
          slidesToShow={2}
          slidesToScroll={2}
          title={data?.title}
          titleColour={data?.titleColour}
          description={data?.description}
          description1Colour={data?.description1Colour}
          isDarkMode={isDarkMode}
          customFont={customFont}
        >
          {tiles.map((item) => {
            return <LpColourProductTile key={item.id} data={item} customFont={customFont} />;
          })}
        </LandingPageCarousel>
      )}
    </WrapperLpCarousel>
  );
};

const WrapperLpCarousel = styled.div`
  padding: 2.5rem;
`;

const TileContainer = styled.div`
  padding-right: 1rem;
`;
export default LpTextAndColourProductTile;
