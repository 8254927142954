import { getSuggestions } from "../store/search/searchSagas";
import { Dispatch } from "redux";
import { Action } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

import {
  QueryParams,
  SearchCategories,
  SearchResults,
  SuggestionParams,
} from "../store/search/searchInterfaces";
import { GetPricesResult } from "../store/catalogue/catalogueInterface";
import { Product } from "../interfaces/productInterface";
import { addPriceToProductorVariant } from "./catalogueUtils";
import { mapProductsArray } from "./productUtils";
import { FacetView } from "../interfaces/facetInterfaces";

export const getSuggestionResults = (
  term: string,
  dispatch: Dispatch<Action>,
  filters?: QueryParams
): void => {
  const params: SuggestionParams = {
    term: term,
    filters: filters,
  };
  if (term.length > 0) dispatch(getSuggestions(params));
};

export const getCategoryQueryParam = (category: SearchCategories): QueryParams | undefined => {
  if (category.value !== "DEFAULT" && category.filterKey) {
    return { [category.filterKey]: [category.value] };
  }
};

/**
 * Add prices to a SearchResults object
 *
 * @param {SearchResults} catalogue
 * @param {GetPricesResult[]} prices
 * @return {*}  {SearchResults}
 */
export const addPricesToSearchResults = (
  searchResults: SearchResults,
  prices: GetPricesResult[]
): SearchResults => {
  const newResultList = cloneDeep(searchResults.resultList);

  newResultList.map((product: Product) => {
    return addPriceToProductorVariant(product, prices);
  });

  return {
    ...searchResults,
    resultList: newResultList,
  };
};

export const mapSearchResults = (
  result: any
): { catalogue: SearchResults; facetView: FacetView[] } => {
  const results = result?.catalogEntryView ? mapProductsArray(result.catalogEntryView) : [];

  return {
    catalogue: {
      resultList: results,
      resultsCount: result?.recordSetCount,
      resultsStartNumber: result?.recordSetStartNumber,
      resultsTotal: result?.recordSetTotal,
      metaData: result?.metaData ?? null,
    },
    facetView: result?.facetView ?? [],
  };
};
