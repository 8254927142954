import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { keyframes } from "styled-components/macro";
import { ReactComponent as BulbIcon } from "../../../../assets/icons/bulb.svg";
import { ItemsTutorialPillMap } from "../../../../interfaces/gridInterfaces";
import { TutorialPill } from "../../../../store/catalogue/catalogueInterface";
import { getCmsContentTooltip } from "../../../../store/store/storeSagas";
import { selectTooltipMap, selectTutorialPills } from "../../../../store/store/storeSlice";
import { ExternalReference } from "../../../../utils/cmsUtils";
import FavoritesTooltip from "./FavoritesTooltip";
import { sendAnalyticsData } from "../../../../store/analytics/analyticsService";

interface Props {
  position: ExternalReference;
  layout?: string;
  isFlipped?: boolean;
}
function FavoritesTooltipContainer({
  position,
  layout = "Tp-tooltip-callout",
  isFlipped,
}: Props): JSX.Element {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const tutorialPills = useSelector(selectTutorialPills);
  const dispatch = useDispatch();
  const tooltipMap = useSelector(selectTooltipMap);
  const [tooltip, setTooltip] = useState<TutorialPill[] | undefined>(undefined);

  const isEmpty = (obj: ItemsTutorialPillMap) => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    if (tutorialPills) {
      setTooltip(
        tutorialPills.filter((pill: TutorialPill) => {
          return pill.externalReference === position && pill.layoutVariant === layout;
        })
      );
    }
  }, [tutorialPills]);

  useEffect(() => {
    if (tooltip?.length && showUserMenu)
      tooltip.map((content) => {
        if (!tooltipMap[content.id]) dispatch(getCmsContentTooltip({ id: content.id }));
      });
  }, [tooltip, showUserMenu]);

  return tooltip?.length ? (
    <FavoritesContainer>
      <FavoritesIconContainer
        onClick={(e) => {
          setShowUserMenu(!showUserMenu);
          e.stopPropagation();
          sendAnalyticsData({
            id: "Click",
            data_element_id: "TooltipsOpen",
          });
        }}
      >
        <ThirdCircle />
        <SecondCircle />
        <FirstCircle />
        <BulbIcon style={{ zIndex: 4 }} />
      </FavoritesIconContainer>
      {showUserMenu && tooltip?.length && !isEmpty(tooltipMap) && (
        <FavoritesTooltip
          tooltipContents={tooltip}
          showAccountMenu={setShowUserMenu}
          isFlipped={isFlipped}
        />
      )}
    </FavoritesContainer>
  ) : (
    <></>
  );
}
const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 0.3;
}

`;
const fadeOut = keyframes`
from {
  opacity: 0.3;
}
to {
  opacity: 0;
}

`;

const Circle = styled.div`
  background-color: #55a63a;
  border-radius: 50%;
  position: absolute;
`;

const FirstCircle = styled(Circle)`
  width: 24px;
  height: 24px;
  z-index: 3;
`;
const SecondCircle = styled(Circle)`
  width: 32px;
  height: 32px;
  opacity: 0;
  z-index: 2;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-name: ${fadeIn};
  animation-delay: 1s;
  animation-iteration-count: infinite;
`;
const ThirdCircle = styled(Circle)`
  width: 40px;
  height: 40px;
  opacity: 0;
  z-index: 1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation: ${fadeOut} 2s 2s forwards;
  animation-iteration-count: infinite;
`;

const FavoritesIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  // z-index: 9999;
`;

const FavoritesContainer = styled.div`
  position: relative;
  display: block;
  align-items: center;
  justify-content: center;
  align-self: center;
  // z-index: 9999;
`;

export default FavoritesTooltipContainer;
