export type MenuStructureKeys = keyof MenuStructure;
export type MenuStructureKeysWithLenses = MenuStructureKeys | "LENSES";

export interface MenuStructure {
  SUNGLASSES: MenuColumn[];
  EYEGLASSES: MenuColumn[];
  AFA: MenuColumn[];
  ACCESSORIES: MenuColumn[];
  GOGGLES_HELMETS: MenuColumn[];
  "SMART GLASSES": MenuColumn[];
  LENSES: MenuColumn[];
  AUDIO: MenuColumn[];
  BRANDS?: MenuColumn[];
  ESSILOR?: MenuColumn[];
}

export interface MenuColumn {
  columnName: string;
  label: string;
  facet?: MenuFacet;
  viewAllButton?: boolean;
  viewAllFacet?: boolean;
  content: MenuFacet[];
  privilege?: string;
}

export interface MenuFacet {
  facetName: string;
  order?: number;
  value?: string;
  facetEntry?: string[];
  viewAllFacetEntries?: boolean;
  minCount?: number;
  baseUrl?: string;
}

export type GogglesHelmetsType = "G" | "H";
export interface FacetNameForEachMenu {
  [category: string]: { facetName: string; type?: GogglesHelmetsType[] }[];
}

// the facets specified in this object will be added to an API request towards the Search
// which will answer only with the facets (and their values) that have an active
// association with a product in the product index
export const facetNameForEachMenu: FacetNameForEachMenu = {
  SUNGLASSES: [
    { facetName: "NEW" },
    { facetName: "BESTSELLER" },
    { facetName: "RECOMMENDED" },
    { facetName: "GENDER" },
    { facetName: "AGE_GROUP" },
    { facetName: "LENS_PROPERTIES" },
    { facetName: "manufacturer.raw" },
  ],
  EYEGLASSES: [
    { facetName: "NEW" },
    { facetName: "BESTSELLER" },
    { facetName: "RECOMMENDED" },
    { facetName: "GENDER" },
    { facetName: "AGE_GROUP" },
    { facetName: "manufacturer.raw" },
  ],
  AFA: [
    { facetName: "PRODUCT_TYPE" },
    { facetName: "PROD_HIERARCHY_2" },
    { facetName: "SPORT" },
    { facetName: "NEW" },
    { facetName: "manufacturer.raw" },
  ],
  ACCESSORIES: [
    { facetName: "ACC_PRODUCT_CATEGORY" },
    { facetName: "OTHER_ACCESSORIES" },
    { facetName: "FRAME_COMPONENTS" },
    { facetName: "manufacturer.raw" },
  ],
  GOGGLES_HELMETS: [
    { facetName: "NEW", type: ["G", "H"] },
    { facetName: "BESTSELLER", type: ["G", "H"] },
    { facetName: "GENDER", type: ["G", "H"] },
    { facetName: "AGE_GROUP", type: ["G", "H"] },
    // { facetName: "RECOMMENDED_USE", type:["G", "H"] },
    { facetName: "G_H_TYPE", type: ["G", "H"] },
    { facetName: "manufacturer.raw", type: ["G", "H"] },
    { facetName: "PROD_HIERARCHY_2", type: ["G", "H"] },
  ],
  "SMART GLASSES": [{ facetName: "manufacturer.raw" }],
  AUDIO: [{ facetName: "PRODUCT_CATEGORY_FILTER" }, { facetName: "manufacturer.raw" }],
};

// for each category, we specify the different columns we will display in the third-level menu, and their content
export const menuStructure: MenuStructure = {
  SUNGLASSES: [
    {
      columnName: "Category",
      label: "MENU_CATEGORY",
      // where the 'content' property is specified, we list all the facets we want to display
      // order indicates the order in which they are displayed
      content: [
        // if 'value' is specified, we display the 'facetName', but only if the 'value' is returned by the API
        { facetName: "NEW", order: 1, value: "TRUE" },
        { facetName: "BESTSELLER", order: 2, value: "TRUE" },
        {
          facetName: "RECOMMENDED",
          order: 3,
          value: "TRUE",
          minCount: 12,
          baseUrl: "/for-you",
        },
        // if 'facetEntry' property is specified, we list all the facetEntries listed, but only if they are returned by the API
        { facetName: "GENDER", order: 4, facetEntry: ["Woman", "Man"] },
        { facetName: "AGE_GROUP", order: 5, facetEntry: ["Children"] },
        { facetName: "LENS_PROPERTIES", order: 6, facetEntry: ["Polarized"] },
      ],
    },
    {
      columnName: "Brands Stars",
      label: "Brands",
      content: [],
      // if content is empty and facet is specified, we list all the entries returned by the API for the facet specified in 'facetName'
      facet: {
        facetName: "manufacturer.raw",
      },
    },
    {
      columnName: "Brands Stars Kids",
      label: "Brands",
      content: [],
      facet: {
        facetName: "manufacturer.raw",
      },
    },
    {
      columnName: "Brands",
      label: "Brands",
      content: [],
      facet: {
        facetName: "manufacturer.raw",
      },
    },
    {
      columnName: "Brands Kids",
      label: "Brands",
      content: [],
      facet: {
        facetName: "manufacturer.raw",
      },
    },
  ],
  EYEGLASSES: [
    {
      columnName: "Category",
      label: "MENU_CATEGORY",
      content: [
        { facetName: "NEW", order: 1, value: "TRUE" },
        { facetName: "BESTSELLER", order: 2, value: "TRUE" },
        {
          facetName: "RECOMMENDED",
          order: 3,
          value: "TRUE",
          minCount: 12,
          baseUrl: "/for-you",
        },
        { facetName: "GENDER", order: 3, facetEntry: ["Woman", "Man"] },
        { facetName: "AGE_GROUP", order: 4, facetEntry: ["Children"] },
        { facetName: "LENS_PROPERTIES", order: 5, facetEntry: ["Polarized"] },
      ],
    },
    {
      columnName: "Brands Stars",
      label: "Brands",
      content: [],
      facet: {
        facetName: "manufacturer.raw",
      },
    },
    {
      columnName: "Brands Stars Kids",
      label: "Brands",
      content: [],
      facet: {
        facetName: "manufacturer.raw",
      },
    },
    {
      columnName: "Brands",
      label: "Brands",
      content: [],
      facet: {
        facetName: "manufacturer.raw",
      },
    },
    {
      columnName: "Brands Kids",
      label: "Brands",
      content: [],
      facet: {
        facetName: "manufacturer.raw",
      },
    },
  ],
  GOGGLES_HELMETS: [
    {
      columnName: "Brands Stars",
      label: "MENU_GOGGLES",
      content: [],
      facet: {
        facetName: "manufacturer.raw",
      },
    },
    {
      columnName: "Category",
      label: "MENU_CATEGORY",
      content: [
        { facetName: "NEW", order: 1, value: "TRUE" },
        { facetName: "GENDER", order: 2, facetEntry: ["Unisex", "Woman", "Man"] },
        { facetName: "AGE_GROUP", order: 3, facetEntry: ["Children"] },
      ],
      viewAllButton: true,
    },
    {
      columnName: "Goggles",
      facet: {
        facetName: "G_H_TYPE",
        value: "G",
      },
      label: "MENU_GOGGLES",
      viewAllButton: true,
      content: [
        { facetName: "NEW", order: 1, value: "TRUE" },
        { facetName: "BESTSELLER", order: 2, value: "TRUE" },
        {
          facetName: "PROD_HIERARCHY_2",
          order: 3,
          facetEntry: ["Snow Goggles", "Mx Goggles"],
        },
      ],
    },
    {
      columnName: "Helmets",
      label: "MENU_HELMETS",
      viewAllButton: true,
      facet: {
        facetName: "G_H_TYPE",
        value: "H",
      },
      privilege: "AFA_SECTION",
      content: [
        { facetName: "NEW", order: 1, value: "TRUE" },
        { facetName: "BESTSELLER", order: 2, value: "TRUE" },
        {
          facetName: "PROD_HIERARCHY_2",
          order: 3,
          facetEntry: ["Snow Helmets", "Cycling & MTB Helmets", "Helmets"],
        },
      ],
    },
    {
      columnName: "Brands",
      label: "MENU_GOGGLES",
      content: [],
      facet: {
        facetName: "manufacturer.raw",
      },
    },
  ],
  ACCESSORIES: [
    {
      columnName: "Replacement",
      label: "MENU_REPLACEMENT",
      facet: {
        facetName: "ACC_PRODUCT_CATEGORY",
      },
      viewAllFacet: true,
      content: [],
    },
    {
      columnName: "Frame Components",
      label: "MENU_FRAME_COMPONENTS",
      facet: {
        facetName: "FRAME_COMPONENTS",
      },
      viewAllFacet: true,
      content: [],
    },
    {
      columnName: "Other accessories",
      label: "MENU_OTHER_ACCESSORIES",
      facet: {
        facetName: "OTHER_ACCESSORIES",
      },
      viewAllFacet: true,
      content: [],
    },
    {
      columnName: "Brands",
      label: "Brands",
      content: [],
      facet: {
        facetName: "manufacturer.raw",
      },
    },
  ],
  AFA: [
    {
      columnName: "Category",
      label: "MENU_CATEGORY",
      viewAllButton: true,
      content: [
        { facetName: "NEW", order: 1, value: "TRUE" },
        {
          facetName: "SPORT",
          order: 2,
          viewAllFacetEntries: true,
        },
      ],
    },
    {
      columnName: "Apparel",
      label: "MENU_AFA_APPAREL",
      viewAllButton: true,
      facet: {
        facetName: "PRODUCT_TYPE",
        value: "Afa Apparel",
      },
      content: [
        {
          facetName: "PROD_HIERARCHY_2",
          order: 1,
          facetEntry: ["Outerwear", "Topwear", "Bottomwear"],
        },
      ],
    },
    {
      columnName: "Footwear",
      label: "MENU_AFA_FOOTWEAR",
      viewAllButton: true,
      facet: {
        facetName: "PRODUCT_TYPE",
        value: "Afa Footwear",
      },
      content: [
        {
          facetName: "PROD_HIERARCHY_2",
          order: 1,
          facetEntry: ["Boots", "Flip Flops & Sandals", "Sneakers"],
        },
      ],
    },
    {
      columnName: "Accessories",
      label: "MENU_AFA_ACCESSORIES",
      viewAllButton: true,
      facet: {
        facetName: "PRODUCT_TYPE",
        value: "Afa Accessories",
      },
      content: [
        {
          facetName: "PROD_HIERARCHY_2",
          order: 1,
          facetEntry: ["Bags", "Gloves", "Headwear", "Belts", "Little Essentials"],
        },
      ],
    },
    {
      columnName: "Brands",
      label: "Brands",
      content: [],
      facet: {
        facetName: "manufacturer.raw",
      },
    },
  ],
  "SMART GLASSES": [
    {
      columnName: "Brands",
      label: "Brands",
      content: [],
      facet: {
        facetName: "manufacturer.raw",
      },
    },
  ],
  //FITTIZIO
  LENSES: [
    {
      columnName: "Lenses",
      label: "Lenses",
      content: [],
      facet: {
        facetName: "lenses",
      },
    },
  ],
  AUDIO: [
    {
      columnName: "Category",
      label: "MENU_CATEGORY",
      content: [
        {
          facetName: "PRODUCT_CATEGORY_FILTER",
          order: 4,
          facetEntry: [
            "Electronics Audio Sun",
            "Electronics Audio Optical",
            "Electronics Accessories",
            "Dummy",
          ],
        },
      ],
      viewAllButton: false,
    },
    {
      columnName: "Brands",
      label: "Brands",
      content: [],
      facet: {
        facetName: "manufacturer.raw",
      },
    },
  ],
};
