/* import { getWarrantyInboxSearchIdentifiers } from "./aftersalesSaga"; */
import { BFFApiDomain } from "../serviceUtils";

/////////////////// conversational

// Get conversational results from UPC search
export const aftersalesSearchByUPCUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/aftersales/upc";

// Get conversational results from UPC and MODEL search
export const aftersalesSearchByUPCModelUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/aftersales/term";

// Get list of brands for aftersales conversational
export const aftersalesBrandsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/aftersales/brandgroups";

// Get list of models from selected brand for aftersales conversational
export const aftersalesSearchProductsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/aftersales/search/products";

// Get list of variants from selected model for aftersales conversational
export const aftersalesSearchVariantsUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/aftersales/search/products/{productId}/variants";

// Get tile details for selected model for aftersales conversational
export const aftersalesProductUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/aftersales/products/{productId}";

// Get tile details for selected variant for aftersales conversational
export const aftersalesVariantUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/aftersales/search/variants/{variantId}";

/////////////////// spare-parts PDP

// Get list of families for spareparts based on productId
export const sparePartsFamiliesUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/{itemIdentifier}/spareparts/family"; //?pricingDate={}&doorId={}

// Get list of spareparts based on productId and selected family
export const sparePartsByFamilyUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/{itemIdentifier}/spareparts/family/{idFamily}"; // ?pricingDate={}&doorId={}

/////////////// warranty inbox & returns
export const warrantyInboxListUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/inbox/search";

export const warrantyInboxDetailsUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/{warrantyIdentifier}/details";

export const getWarrantyInboxSearchIdentifiersUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/search/identifiers";

export const warrantyInboxCountUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/inbox/count";

export const warrantyReturnUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/{warrantyIdentifier}/returns";

export const warrantyReturnNotificationUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/{warrantyIdentifier}/notification/info";

export const warrantyInboxReadUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/inbox/search/read";

export const sendWarrantyActionUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/action";

export const sendWarrantyTextActionUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/simpleTextAction";

export const postWarrantySparepartsActionUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/spareparts/action";

export const warrantyProductDetailsByPartNumberUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/partnumbers/{partNumber}/detail";

export const warrantyFastTrackParcelDataUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/fastTrackParcelData";

export const warrantyReturnParcelDataUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/warranty/returnParcelData";
