import React from "react";
import styled from "styled-components/macro";

import { SecondLevelMenu } from "../../interfaces/menuInterfaces";
import BrandImage from "./BrandImage";

import { ReactComponent as CloseIcon } from "../../assets/icons/x-icon.svg";
import clsx from "clsx";

interface Props {
  brandGroup: SecondLevelMenu;
  active?: boolean;
  onClick: () => void;
  fallbackLabel?: string;
  "data-element-id"?: string | null;
  enableDataDescription?: boolean;
  style?: "default" | "stars";
}

const BrandButton = ({
  brandGroup,
  active,
  onClick,
  fallbackLabel,
  "data-element-id": dataElementId,
  enableDataDescription = false,
  style = "default",
}: Props): JSX.Element => {
  return (
    <>
      {brandGroup && (
        <BrandButtonEl
          className={clsx(style === "stars" && "brand-button-stars")}
          onClick={onClick}
          {...(dataElementId ? { "data-element-id": dataElementId } : {})}
          {...(enableDataDescription ? { "data-description": brandGroup.identifier } : {})}
        >
          {active && (
            <IconContainer>
              <CloseIcon />
            </IconContainer>
          )}
          <BrandImage
            brandOrBrandGroup={brandGroup.identifier ?? brandGroup.brandCodes}
            fallbackLabel={fallbackLabel}
            isBrandGroup
            invert={style === "stars"}
          />
        </BrandButtonEl>
      )}
    </>
  );
};

const BrandButtonEl = styled.button`
  position: relative;
  background-color: ${(props) => props.theme.palette.white};
  height: 4.375rem;
  max-width: 11rem;
  min-width: 6.6875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  border: solid 1px ${(props) => props.theme.palette.gray.medium};
  cursor: pointer;

  img {
    width: 90%;
    min-width: 6rem;
    max-height: 3.375rem;
  }

  &.brand-button-stars {
    background-color: ${(props) => props.theme.palette.primary};
  }
`;

const IconContainer = styled.span`
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 1rem;
    width: 1rem;
    fill: ${(props) => props.theme.palette.white};
  }
`;

export default BrandButton;
