import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import Checkbox from "../../styled-UI/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBrandSRList,
  selectGetBrandsSRStatus,
  selectSubmitSRStatus,
} from "../../../store/serviceRequests/serviceRequestSlice";
import { Door } from "../../../store/user/userInterfaces";
import ServiceRequestPopupSubmit from "./ServiceRequestPopupSubmit";
import { getBrandsSR, submitBrandSR } from "../../../store/serviceRequests/serviceRequestSagas";
import CustomText from "../../styled-UI/CustomText";
import { orderBy } from "lodash";
import LoaderSRPopup from "../../styled-UI/loader/service-request/LoaderSRPopup";

interface Brand {
  label: string;
  value: string;
}

interface Props {
  selectedDoor: Door;
  serviceRequestIdentifier: string | null;
  setHasSubmittedRequest: (val: boolean) => void;
}

const ServiceRequestPopupBrands = ({
  selectedDoor,
  serviceRequestIdentifier,
  setHasSubmittedRequest,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [selectedBrand, setSelectedBrand] = useState<string | undefined>();
  const submitStatus = useSelector(selectSubmitSRStatus);
  const getBrandsStatus = useSelector(selectGetBrandsSRStatus);
  const { translateLabel } = useTranslation();
  const brands = useSelector(selectBrandSRList);
  const [brandList, setBrandList] = useState<Brand[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getBrandsSR(selectedDoor));
  }, [selectedDoor]);

  useEffect(() => {
    if (brands) {
      const newList = brands?.map((brand) => {
        return {
          label: translateLabel(brand + "_LABEL") || brand,
          value: brand,
        };
      });

      const orderedBrandList = orderBy(newList, "label");
      setBrandList(orderedBrandList);
    }
  }, [brands]);

  const submitRequest = () => {
    //check for errors
    if (!selectedBrand) {
      return setHasError(true);
    }

    //send request
    if (!hasError && serviceRequestIdentifier) {
      dispatch(
        submitBrandSR({
          doorId: selectedDoor.orgentityId,
          serviceRequestIdentifier: serviceRequestIdentifier,
          serviceRequestTypeIdentifier: "SR6_BRAND_VISIBILITY",
          brandIdentifier: selectedBrand,
        })
      );
    }
  };

  return (
    <>
      {getBrandsStatus === "LOADING" && <LoaderSRPopup />}
      <div className="checkbox-wrapper">
        {getBrandsStatus === "ERROR" && (
          <CustomText as="p" fontSizePx={13} lineHeightPx={18} marginTopPx={16} color="red">
            {translateLabel("ERROR_POPUP_DEFAULT_MESSAGE")}
          </CustomText>
        )}

        {getBrandsStatus === "SUCCESS" && (
          <>
            <CheckboxContainer>
              {brandList.map((brand) => {
                return (
                  <Checkbox
                    key={brand.value}
                    label={brand.label}
                    name={brand.value}
                    controlled
                    checked={selectedBrand === brand.value}
                    onChange={(e) => {
                      setSelectedBrand(e.target.name);
                    }}
                  />
                );
              })}
            </CheckboxContainer>
            {hasError && (
              <CustomText as="p" fontSizePx={13} lineHeightPx={18} marginBottomPx={16} color="red">
                {translateLabel("SERVICE_REQUEST_POPUP_BRAND_REQUIRED")}
              </CustomText>
            )}
            <ServiceRequestPopupSubmit
              submitRequest={submitRequest}
              submitStatus={submitStatus}
              setHasSubmittedRequest={setHasSubmittedRequest}
            />
          </>
        )}
      </div>
    </>
  );
};

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  height: 15.75rem;
  overflow: auto;
  margin-bottom: 1rem;
`;

export default ServiceRequestPopupBrands;
