import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import {
  getOutOfStockNumberCart,
  getSelectedNumberCart,
  getSelectedNumberCategory,
  getSelectedNumberMultidoor,
  getTotalNumberCart,
  getTotalNumberCategory,
  getTotalNumberMultidoor,
  getTotalOutOfStockNumberMultidoor,
  getTotalPriceCategory,
  getTotalPriceMultidoor,
  mapOrderItemSelected,
} from "../../utils/cartUtils";
import {
  CartInfo,
  OrderCategory,
  OrderCategoryInfo,
  OrderCategorySummary,
  OrderMultidoor,
  OrderMultidoorAddress,
  OrderMultidoorInfo,
  OrderMultidoorSummary,
  SubOrderPayload,
} from "../cart/cartInterfaces";
import {
  CheckoutOrderCategoryInfo,
  CheckoutOrderInfo,
  CheckoutOrderMultidoorInfo,
} from "../checkout/checkoutInterfaces";
import { RootState } from "../storeConfig";

import {
  OrdersToApproveList,
  OrdersToApproveState,
  ApproveRejectOrdersResult,
  SuborderApprovalHeader,
  ApproveRejectOrdersSimulate,
  ApproveRejectOrdersPayload,
  OrdersToApproveFilters,
} from "./ordersToApproveInterface";

export const sliceName = "ordersToApprove";

const initialState: OrdersToApproveState = {
  ///////// LIST OF ORDERS TO BE APPROVED
  ordersToApproveList: null,
  ordersToApproveStatus: "IDLE",

  //////// FILTERS
  ordersToApproveFilters: null,

  //////// APPROVING / REJECTING ORDERS
  approveRejectOrdersResult: null,
  approveRejectOrdersStatus: "IDLE",
  approveRejectOrdersSimulate: [],

  //////// ORDER DETAIL
  orderId: "",
  suborderApprovalHeader: [],
  suborderApprovalDetails: [],
  suborderApprovalAddress: [],
  subOrdersNotesAndPO: [],
  suborderApprovalTotalPrice: null,
  suborderDetailsStatus: "IDLE",

  //////// ORDER SIMULATION
  suborderInfoForSimulation: null,

  /////// EXPORT CSV
  exportOrderToApproveCSVStatus: "IDLE",
};

export const ordersToApproveSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ///////// LIST OF ORDERS TO BE APPROVED
    saveOrdersToApproveList: (state, action: PayloadAction<OrdersToApproveList | null>) => {
      state.ordersToApproveList = action.payload;
    },

    setOrdersToApproveStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.ordersToApproveStatus = action.payload;
    },

    //////// FILTERS
    saveOrdersToApproveFilters: (state, action: PayloadAction<OrdersToApproveFilters | null>) => {
      state.ordersToApproveFilters = action.payload;
    },

    //////// APPROVING / REJECTING ORDERS

    saveApproveRejectOrdersResult: (
      state,
      action: PayloadAction<ApproveRejectOrdersResult | null>
    ) => {
      state.approveRejectOrdersResult = action.payload;
    },

    setApproveRejectOrdersStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.approveRejectOrdersStatus = action.payload;
    },

    saveApproveRejectOrdersSimulate: (
      state,
      action: PayloadAction<ApproveRejectOrdersSimulate[]>
    ) => {
      state.approveRejectOrdersSimulate = action.payload;
    },

    resetOrderToBeApprovedPopup: (state) => {
      state.approveRejectOrdersStatus = "IDLE";
      state.approveRejectOrdersSimulate = [];
    },

    //////// ORDER DETAIL
    saveOrderId: (state, action: PayloadAction<string>) => {
      state.orderId = action.payload;
    },
    saveSuborderApprovalHeader: (state, action: PayloadAction<SuborderApprovalHeader[]>) => {
      state.suborderApprovalHeader = action.payload;
    },
    saveSuborderApprovalDetails: (state, action: PayloadAction<OrderMultidoor[]>) => {
      state.suborderApprovalDetails = action.payload;
    },
    saveSuborderApprovalAddress: (state, action: PayloadAction<OrderMultidoorAddress[]>) => {
      state.suborderApprovalAddress = action.payload;
    },
    saveSubOrdersNotesAndPO: (state, action: PayloadAction<SubOrderPayload[]>) => {
      state.subOrdersNotesAndPO = action.payload;
    },
    // only for simulation, not order details!!! (also counts out of stock probably)
    saveSuborderApprovalTotalPrice: (state, action: PayloadAction<number | null>) => {
      state.suborderApprovalTotalPrice = action.payload;
    },
    setSuborderDetailsStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.suborderDetailsStatus = action.payload;
    },
    resetOrderApprovalDetail: (state) => {
      state.suborderApprovalHeader = [];
      state.suborderApprovalDetails = [];
      state.suborderApprovalAddress = [];
      state.subOrdersNotesAndPO = [];
      state.suborderApprovalTotalPrice = null;
      state.approveRejectOrdersStatus = "IDLE";
      state.approveRejectOrdersSimulate = [];
    },

    //////// ORDER SIMULATION
    saveSuborderInfoForSimulation: (
      state,
      action: PayloadAction<ApproveRejectOrdersPayload | null>
    ) => {
      state.suborderInfoForSimulation = action.payload;
    },

    //////// EXPORT CSV
    saveExportOrderToApproveCSVStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.exportOrderToApproveCSVStatus = action.payload;
    },

    resetState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  ///////// LIST OF ORDERS TO BE APPROVED
  saveOrdersToApproveList,
  setOrdersToApproveStatus,

  //////// FILTERS
  saveOrdersToApproveFilters,

  //////// APPROVING / REJECTING ORDERS
  saveApproveRejectOrdersResult,
  setApproveRejectOrdersStatus,
  saveApproveRejectOrdersSimulate,
  resetOrderToBeApprovedPopup,

  //////// ORDER DETAIL
  saveOrderId,
  saveSuborderApprovalHeader,
  saveSuborderApprovalDetails,
  saveSuborderApprovalAddress,
  saveSubOrdersNotesAndPO,
  saveSuborderApprovalTotalPrice,
  resetOrderApprovalDetail,
  setSuborderDetailsStatus,

  //////// ORDER SIMULATION
  saveSuborderInfoForSimulation,

  /////// EXPORT CSV
  saveExportOrderToApproveCSVStatus,

  resetState,
} = ordersToApproveSlice.actions;

///////// LIST OF ORDERS TO BE APPROVED
export const selectOrdersToApproveList = (state: RootState): OrdersToApproveList | null => {
  return state.ordersToApprove.ordersToApproveList;
};

export const selectOrdersToApproveStatus = (state: RootState): RequestStatus => {
  return state.ordersToApprove.ordersToApproveStatus;
};

///////// FILTERS
export const selectOrdersToApproveFilters = (state: RootState): OrdersToApproveFilters | null => {
  return state.ordersToApprove.ordersToApproveFilters;
};

//////// APPROVING / REJECTING ORDERS

export const selectApproveRejectOrdersResult = (
  state: RootState
): ApproveRejectOrdersResult | null => {
  return state.ordersToApprove.approveRejectOrdersResult;
};

export const selectApproveRejectOrdersStatus = (state: RootState): RequestStatus => {
  return state.ordersToApprove.approveRejectOrdersStatus;
};

export const selectApproveRejectOrdersSimulate = (
  state: RootState
): ApproveRejectOrdersSimulate[] => {
  return state.ordersToApprove.approveRejectOrdersSimulate;
};

//////// ORDER DETAIL
export const selectOrderId = (state: RootState): string => {
  return state.ordersToApprove.orderId;
};

export const selectSuborderDetailsStatus = (state: RootState): RequestStatus => {
  return state.ordersToApprove.suborderDetailsStatus;
};

export const selectSuborderApprovalHeader = (state: RootState): SuborderApprovalHeader[] => {
  return state.ordersToApprove.suborderApprovalHeader;
};

export const selectSuborderApprovalDetails = (state: RootState): OrderMultidoor[] => {
  return state.ordersToApprove.suborderApprovalDetails;
};

export const selectSuborderApprovalAddress = (state: RootState): OrderMultidoorAddress[] => {
  return state.ordersToApprove.suborderApprovalAddress;
};

export const selectSubOrdersNotesAndPO = (state: RootState): SubOrderPayload[] => {
  return state.ordersToApprove.subOrdersNotesAndPO;
};

export const numberOfOrderItemsNotOutOfStock = createSelector(
  selectSuborderApprovalDetails,
  (selectedItems: OrderMultidoor[]): number => {
    let totalOrderItemNotOutOfStock = 0;

    selectedItems?.forEach((orderMultidoor: OrderMultidoor) => {
      let orderItemNotOutOfStockInDoor = 0;

      orderMultidoor.categoryList.forEach((orderCategory: OrderCategory) => {
        const orderItemSelected = mapOrderItemSelected(orderCategory.orderItemList);

        const orderItemNotOutOfStockInCategory = orderItemSelected.filter((_) => _.selected)
          ?.length;

        orderItemNotOutOfStockInDoor =
          orderItemNotOutOfStockInDoor + orderItemNotOutOfStockInCategory;
      });

      totalOrderItemNotOutOfStock = totalOrderItemNotOutOfStock + orderItemNotOutOfStockInDoor;
    });

    return totalOrderItemNotOutOfStock;
  }
);

export const selectSuborderApprovalInfo = createSelector(
  selectSuborderApprovalDetails,
  (selectedItems: OrderMultidoor[]): CartInfo => {
    // map each door adding the number of total and selected items in it
    const orderMultidoorInfo = selectedItems?.map(
      (orderMultidoor: OrderMultidoor): OrderMultidoorInfo => {
        // map each category adding the number of total and selected items in it
        const newOrderCategorySelected = orderMultidoor.categoryList.map(
          (orderCategory: OrderCategory): OrderCategoryInfo => {
            const orderItemSelected = mapOrderItemSelected(orderCategory.orderItemList);

            return {
              productCategory: orderCategory.productCategory,
              productCategoryIdentifier: orderCategory.productCategoryIdentifier,
              multidoorId: orderMultidoor.multidoorId,
              orgentityName: orderMultidoor.orgentityName,
              orderItemSelected: orderItemSelected,
              totalNumber: getTotalNumberCategory(orderItemSelected), // count total items in category
              selectedNumber: getSelectedNumberCategory(orderItemSelected), // count selected items in category
            };
          }
        );

        return {
          multidoorId: orderMultidoor.multidoorId,
          subOrderId: orderMultidoor.subOrderId,
          orgentityName: orderMultidoor.orgentityName,
          categorySelected: newOrderCategorySelected,
          totalNumber: getTotalNumberMultidoor(newOrderCategorySelected), // count total items in multidoor
          selectedNumber: getSelectedNumberMultidoor(newOrderCategorySelected), // count selected items in multidoor
          outOfStockNumber: getTotalOutOfStockNumberMultidoor(newOrderCategorySelected), // count out of stock items in multidoor
        };
      }
    );

    // return an object containing all doors' info + total and selected counters for the whole cart
    return {
      multidoorSelected: orderMultidoorInfo,
      totalNumber: getTotalNumberCart(orderMultidoorInfo), // count total items in cart
      selectedNumber: getSelectedNumberCart(orderMultidoorInfo), // count selected items in cart
      outOfStockNumber: getOutOfStockNumberCart(orderMultidoorInfo), // count out of stock items in cart
    };
  }
);

export const selectSuborderApprovalSummary = createSelector(
  selectSuborderApprovalDetails,
  (orderMultidoorList: OrderMultidoor[]): OrderMultidoorSummary[] | null => {
    const selectedOrderItemsIds: string[] = []; // store all orderItems' ids that appear in selectedItems

    orderMultidoorList.forEach((orderMultidoor) => {
      orderMultidoor.categoryList.forEach((orderCategory) => {
        orderCategory.orderItemList.forEach((orderItem) => {
          if (!orderItem.outOfStock) selectedOrderItemsIds.push(orderItem.orderItemId); // save the id if item is selected
        });
      });
    });

    // map each door
    const orderMultidoorSummary = orderMultidoorList?.map(
      (orderMultidoor: OrderMultidoor): OrderMultidoorSummary => {
        // map each category
        const orderCategorySummary = orderMultidoor.categoryList.map(
          (orderCategory: OrderCategory): OrderCategorySummary => {
            return {
              productCategory: orderCategory.productCategory,
              productCategoryIdentifier: orderCategory.productCategoryIdentifier,
              multidoorId: orderMultidoor.multidoorId,
              subOrderId: orderMultidoor.subOrderId,
              totalPrice: getTotalPriceCategory(orderCategory.orderItemList, selectedOrderItemsIds),
            };
          }
        );

        const currency = orderMultidoor.categoryList[0].orderItemList[0].currency;

        return {
          multidoorId: orderMultidoor.multidoorId,
          totalPrice: getTotalPriceMultidoor(orderCategorySummary),
          categoryPrice: orderCategorySummary,
          subOrderId: orderMultidoor.subOrderId,
          orgentityName: orderMultidoor.orgentityName,
          currency: currency,
        };
      }
    );

    return orderMultidoorSummary ?? null;
  }
);

export const selectSuborderApprovalCheckoutOrderInfo = createSelector(
  selectSuborderApprovalDetails,
  (orderMultidoor: OrderMultidoor[]): CheckoutOrderInfo => {
    // map each door adding the number of total items in it
    const orderMultidoorInfo = orderMultidoor?.map(
      (orderDoor: OrderMultidoor): CheckoutOrderMultidoorInfo => {
        // map each category adding the number of total items in it
        const newOrderCategory = orderDoor.categoryList.map(
          (orderCategory: OrderCategory): CheckoutOrderCategoryInfo => {
            return {
              productCategory: orderCategory.productCategory,
              multidoorId: orderDoor.multidoorId,
              totalNumber: getTotalNumberCategory(orderCategory.orderItemList), // count total sum of quanitities in category
            };
          }
        );

        return {
          multidoorId: orderDoor.multidoorId,
          subOrderId: orderDoor.subOrderId,
          orgentityName: orderDoor.orgentityName,
          category: newOrderCategory,
          totalNumber: getTotalNumberMultidoor(newOrderCategory), // count total sum of quanitities in door
        };
      }
    );

    // return an object containing all doors' info + total counter for the whole cart
    return {
      multidoor: orderMultidoorInfo,
      totalNumber: getTotalNumberCart(orderMultidoorInfo), // count total sum of quantities in cart
    };
  }
);

export const selectSuborderApprovalTotalPrice = (state: RootState): number | null => {
  return state.ordersToApprove.suborderApprovalTotalPrice;
};

//////// ORDER SIMULATION
export const selectSuborderInfoForSimulation = (
  state: RootState
): ApproveRejectOrdersPayload | null => {
  return state.ordersToApprove.suborderInfoForSimulation;
};

/////// EXPORT CSV
export const selectExportOrderToApproveCSVStatus = (state: RootState): RequestStatus => {
  return state.ordersToApprove.exportOrderToApproveCSVStatus;
};

export default ordersToApproveSlice.reducer;
