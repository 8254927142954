import React, { ReactNode } from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";

import usePreviewMode from "../../hooks/usePreviewMode";
import useTranslation from "../../hooks/useTranslation";
import { getPagePath } from "../../routing/routesUtils";
import Button from "../styled-UI/Button";
import CustomText from "../styled-UI/CustomText";

interface Props {
  children: ReactNode;
}

export default function PreviewConditionalRender({ children }: Props): JSX.Element {
  const { isPreview } = usePreviewMode();
  const { translateLabel } = useTranslation();
  const history = useHistory();

  const goToHome = () => {
    history.push(getPagePath("/homepage"));
  };

  if (!isPreview) {
    return <>{children}</>;
  }
  return (
    <Container>
      <Center>
        <CustomText as="p" fontSizePx={18} font="font-bold" marginBottomPx={5}>
          {translateLabel("HIDDEN_IN_PREVIEW_TITLE")}
        </CustomText>
        <CustomText as="p" fontSizePx={16} font="font-regular" marginBottomPx={20}>
          {translateLabel("HIDDEN_IN_PREVIEW_MESSAGE")}
        </CustomText>
        <ButtonContainer>
          <Button type="primary" onClick={goToHome}>
            {translateLabel("HIDDEN_IN_PREVIEW_BUTTON")}
          </Button>
        </ButtonContainer>
      </Center>
    </Container>
  );
}

const Container = styled.div`
  padding: 3rem;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
