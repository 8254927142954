import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import { UploadedFile } from "../../../store/warranty-wizard/warrantyWizardInterface";
import CustomSelect from "../../styled-UI/CustomSelect";
import InputComponent from "../../styled-UI/InputComponent";
import CustomText from "../../styled-UI/CustomText";
import UploadFiles from "../../styled-UI/UploadFiles";
import { SimpleSRItem } from "../../../interfaces/serviceRequests";
import { useDispatch, useSelector } from "react-redux";
import { Door } from "../../../store/user/userInterfaces";
import { getSimpleSR, submitSimpleSR } from "../../../store/serviceRequests/serviceRequestSagas";
import {
  selectGetSimpleSRListStatus,
  selectSimpleSRList,
  selectSubmitSRStatus,
} from "../../../store/serviceRequests/serviceRequestSlice";
import LoaderSRPopup from "../../styled-UI/loader/service-request/LoaderSRPopup";
import ServiceRequestPopupSubmit from "./ServiceRequestPopupSubmit";
import useUploadFiles from "../../../hooks/useUploadFiles";
import {
  SubmitSimpleSRPayload,
  UploadFilePayload,
} from "../../../store/serviceRequests/serviceRequestInterfaces";
import { v4 as uuidv4 } from "uuid";

interface Props {
  selectedDoor: Door;
  serviceRequestIdentifier: string | null;
  setHasSubmittedRequest: (val: boolean) => void;
}

const ServiceRequestPopupSimple = ({
  selectedDoor,
  serviceRequestIdentifier,
  setHasSubmittedRequest,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { translateLabel } = useTranslation();
  const simpleSRList = useSelector(selectSimpleSRList);
  const simpleSRListStatus = useSelector(selectGetSimpleSRListStatus);
  const submitStatus = useSelector(selectSubmitSRStatus);
  const [selectedType, setSelectedType] = useState<SimpleSRItem | undefined>();
  const [simpleSRListWithLabels, setSimpleSRListWithLabels] = useState<SimpleSRItem[]>([]);

  //fields to send
  type FieldName = "NOTE" | "ATTACHMENT";
  const [note, setNote] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<UploadedFile[]>([]);
  const [errors, setErrors] = useState<FieldName[]>([]);

  //error and loading upload request
  const [errorInUploading, setErrorInUploading] = useState<boolean>(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);

  //upload files
  const { uploadFiles, uploadStatus } = useUploadFiles();

  //get simple request structure info
  useEffect(() => {
    if (selectedDoor)
      dispatch(
        getSimpleSR({
          doorId: selectedDoor.orgentityId,
          sitePageIdentifier: "MY_ACCOUNT_CONTACT_US",
        })
      );
  }, []);

  //clean errors attachments
  useEffect(() => {
    if (uploadedFile.length > 0) {
      const index = errors.indexOf("ATTACHMENT");
      if (index !== -1) {
        const newErrors = [...errors];

        newErrors.splice(index, 1);
        setErrors(newErrors);
      }
    }

    setErrorInUploading(false);
  }, [uploadedFile]);

  //add label and values to select type
  useEffect(() => {
    if (simpleSRList.length > 0) {
      const newSimpleSRList: SimpleSRItem[] = simpleSRList.map((item) => {
        return {
          ...item,
          label: translateLabel("SERVICE_REQUEST_" + item.serviceRequestTypeIdentifier),
        };
      });
      setSelectedType(newSimpleSRList[0]);

      setSimpleSRListWithLabels(newSimpleSRList);
    }
  }, [simpleSRList]);

  //submit request or show error when upload files is finished
  useEffect(() => {
    // console.log("uploadStatus", uploadStatus);
    if (uploadStatus) {
      setIsLoadingUpload(false);

      // console.log("uploadStatus insife", uploadStatus);
      if (uploadStatus.jwtStatus === "ERROR") {
        setErrorInUploading(true);
      } else if (uploadStatus.uploadedFilesStatus.length > 0) {
        // console.log("uploadStatus has uploadedFilesStatus", uploadStatus);
        const blobNames: string[] = [];

        for (let i = 0; i < uploadStatus.uploadedFilesStatus.length; i++) {
          const current = uploadStatus.uploadedFilesStatus[i];

          if (current.status === "ERROR") {
            return setErrorInUploading(true);
          }
          if (current.blobName) {
            blobNames.push(current.blobName);
          }

          // console.log("uploadStatus blobNames", blobNames);
        }

        if (blobNames.length > 0) {
          // console.log("uploadStatus submit");
          submit(blobNames);
        }
      }
    }
  }, [uploadStatus]);

  const handleNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);

    const index = errors.indexOf("NOTE");
    if (index !== -1) {
      const newErrors = [...errors];

      newErrors.splice(index, 1);
      setErrors(newErrors);
    }
  };

  const submitRequest = () => {
    //check for errors
    const newErrors = [...errors];
    if (selectedType?.isMandatoryTextNote && !note) {
      newErrors.push("NOTE");
    }
    if (selectedType?.isMandatoryAttachment && uploadedFile.length === 0) {
      newErrors.push("ATTACHMENT");
    }

    if (
      selectedType?.isMandatoryAttachment &&
      uploadedFile.length > 0 &&
      selectedType?.minAttachmentQuantity !== undefined &&
      uploadedFile.length < selectedType?.minAttachmentQuantity
    ) {
      newErrors.push("ATTACHMENT");
    }

    setErrors(newErrors);

    //send request
    if (newErrors.length === 0 && serviceRequestIdentifier) {
      if (uploadedFile.length > 0) {
        const payload = uploadedFile.map((_) => {
          if (_ && _.file)
            return {
              file: _.file,
              resourceIdentifier: serviceRequestIdentifier,
              resourceType: "servicerequest",
              fileIdentifierKey: "it.luxottica.service.request.attachment",
              fileLabel: "genericfile",
              id: uuidv4(),
            };
        });

        setIsLoadingUpload(true);
        uploadFiles(payload as UploadFilePayload[], {
          doorId: selectedDoor.orgentityId,
          resourceType: "servicerequest",
          resourceIdentifier: serviceRequestIdentifier,
        });
      } else {
        submit([]);
      }
    }
  };

  const submit = (blobNames: string[]) => {
    if (serviceRequestIdentifier && selectedType?.serviceRequestTypeIdentifier) {
      const payload: SubmitSimpleSRPayload = {
        doorId: selectedDoor.orgentityId,
        serviceRequestIdentifier: serviceRequestIdentifier,
        serviceRequestTypeIdentifier: selectedType?.serviceRequestTypeIdentifier,
        textNoteValue: note,
      };
      if (blobNames.length > 0) {
        payload.attachmentBlobNameList = blobNames;
      }
      dispatch(submitSimpleSR(payload));
    }
  };

  return (
    <>
      {simpleSRListStatus === "LOADING" && <LoaderSRPopup />}
      {simpleSRListStatus === "ERROR" && (
        <CustomText as="p" fontSizePx={13} lineHeightPx={18} marginBottomPx={16} color="red">
          {submitStatus === "ERROR" && translateLabel("ERROR_POPUP_DEFAULT_MESSAGE")}
        </CustomText>
      )}

      {simpleSRListStatus === "SUCCESS" && selectedType && (
        <div>
          <CustomSelect
            type="white"
            options={simpleSRListWithLabels}
            value={selectedType}
            isSearchable={false}
            onChange={(option) => {
              setSelectedType(option as SimpleSRItem);
              setErrors([]);
            }}
          />
          {selectedType?.showTextNote && (
            <div className="textarea-wrapper mt-4">
              <InputComponent
                type="text"
                as="textarea"
                placeholder={translateLabel("SERVICE_REQUEST_POPUP_NOTE")}
                resizableTextarea
                maxLength={500}
                required={selectedType?.isMandatoryTextNote}
                helperText={errors.includes("NOTE") ? translateLabel("REQUIRED_FIELD") : ""}
                colorStyle={errors.includes("NOTE") ? "error" : "enabled"}
                onChange={handleNote}
                value={note}
              />
            </div>
          )}
          {selectedType?.showAttachmentSelector && (
            <AttatchmentContainer>
              <CustomText as="div" fontSizePx={13} lineHeightPx={18} marginBottomPx={16}>
                {translateLabel("SERVICE_REQUEST_POPUP_ATTACHMENT_TITLE")}
              </CustomText>
              <div>
                <UploadFiles
                  // direction="row"
                  imageLabel={translateLabel("SERVICE_REQUEST_POPUP_UPLOAD_A_FILE")}
                  uploadedFile={uploadedFile}
                  isMandatory={selectedType?.isMandatoryAttachment}
                  setUploadedFile={setUploadedFile} //TODO CLEAN ERROR
                  showRequiredError={errors.includes("ATTACHMENT")}
                  hasWhiteBackground //TODO se max upload
                  maxFileNumber={selectedType?.maxAttachmentQuantity ?? 1}
                  // fileTypesSupported={["xlsx", "xls", "csv"]}
                />
              </div>
            </AttatchmentContainer>
          )}
          <ServiceRequestPopupSubmit
            submitRequest={submitRequest}
            submitStatus={submitStatus}
            setHasSubmittedRequest={setHasSubmittedRequest}
            errorInUploading={errorInUploading}
            isLoading={isLoadingUpload}
          />
        </div>
      )}
    </>
  );
};

const AttatchmentContainer = styled.div`
  margin-top: 2rem;
  max-width: 60%;
`;

export default ServiceRequestPopupSimple;
