import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../../../hooks/useTranslation";
import Checkbox from "../../../../styled-UI/Checkbox";
import CustomText from "../../../../styled-UI/CustomText";
import InputComponent from "../../../../styled-UI/InputComponent";
import { checkIsWebsiteValid, isWebsiteInvalid } from "./isValidWebSite";

interface Props {
  noWebsite: boolean;
  webSiteURL: string;
  showError: boolean;
  handleWebSiteChange: (webSiteURL: string) => void;
  handleCheckboxChange: (checked: boolean) => void;
}

const ADTPlatformWebsite = ({
  noWebsite,
  webSiteURL,
  showError,
  handleWebSiteChange,
  handleCheckboxChange,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  return (
    <>
      <CustomText as="h2" fontSizePx={18} font="font-bold" lineHeightPx={18} marginBottomPx={16}>
        {translateLabel("ADT_PLATFORM_WEBSITE_TITLE")}
      </CustomText>
      <CustomText as="p" fontSizePx={14} lineHeightPx={24} marginBottomPx={24}>
        {translateLabel("ADT_PLATFORM_WEBSITE_SUBTITLE")}
      </CustomText>
      <ItemContent className={clsx(noWebsite && "disable-input")}>
        <InputComponent
          type="text"
          value={webSiteURL}
          onChange={(e) => handleWebSiteChange(e.target.value)}
          placeholder={translateLabel("ADT_PLATFORM_WEBSITE_PLACEHOLDER")}
          disabled={noWebsite}
          required
          {...(showError &&
            !checkIsWebsiteValid(webSiteURL, noWebsite) && {
              colorStyle: "error",
              helperText: translateLabel(isWebsiteInvalid("website", webSiteURL)),
            })}
        />
      </ItemContent>
      <CheckWrapper>
        <Checkbox
          label="website"
          name="website"
          hideLabel
          checked={noWebsite}
          onChange={(e) => handleCheckboxChange(e.target.checked)}
        />
        <CustomText as="p" fontSizePx={13} lineHeightPx={18}>
          {translateLabel("ADT_PLATFORM_WEBSITE_CHECK")}
        </CustomText>
      </CheckWrapper>
    </>
  );
};

const ItemContent = styled.div`
  width: 18.375rem;
  &.disable-input {
    width: 13.3125rem;
  }
`;

const CheckWrapper = styled.div`
  display: flex;
  gap: 1.0875rem;
  margin: 1rem 0 2.5rem;
`;

export default ADTPlatformWebsite;
