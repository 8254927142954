import { useState, useEffect } from "react";
import useStatelessService, {
  RequestStatusStateless,
} from "../store/statelessServices/useStatelessService";
import { AllLabels } from "./labelInterfaces";
import { useSelector } from "react-redux";
import { selectCurrentLang } from "../store/store/storeSlice";
import statelessServices from "../store/statelessServices/statelessServices";
import { selectIsLogged } from "../store/user/userSlice";

interface PrivateLabel {
  publicLabels: AllLabels;
  publicRequestStatus: RequestStatusStateless;
}

export default function usePublicLabels(): PrivateLabel {
  const [labels, setLabels] = useState<AllLabels>({} as AllLabels);
  const { requestStatus, makeRequest, resetRequestState } = useStatelessService();
  const lang = useSelector(selectCurrentLang);
  const isLogged = useSelector(selectIsLogged);

  const requestLang = async () => {
    if (lang && !labels[lang]) {
      makeRequest(statelessServices.getLabelsPublic, lang);
    }
  };

  const saveLang = () => {
    if (lang && requestStatus.status === "SUCCESS") {
      // resetRequestState();
      const { data } = requestStatus.data;
      setLabels({
        ...labels,
        [lang]: data,
      });
    }
  };

  useEffect(() => {
    if (!isLogged) requestLang();
  }, [lang]);

  useEffect(() => {
    saveLang();
  }, [requestStatus.status]);

  return { publicLabels: labels, publicRequestStatus: requestStatus };
}
