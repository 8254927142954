import React from "react";
import styled from "styled-components/macro";
import LoaderElement from "../LoaderElement";

const LoaderMenu = (): JSX.Element => {
  return (
    <>
      <Wrapper>
        <Row>
          <Column>
            <Title>
              <LoaderElement height={22} />
            </Title>
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
          </Column>
          <Column>
            <Title>
              <LoaderElement height={22} />
            </Title>
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
          </Column>
          <Column>
            <Title>
              <LoaderElement height={22} />
            </Title>
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
          </Column>
        </Row>
      </Wrapper>
    </>
  );
};

const Title = styled.div`
  margin-bottom: 0.5rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 10rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  padding: 1.875rem 0.5rem 0rem;
`;

const Wrapper = styled.div`
  width: 100%;
`;

export default LoaderMenu;
