import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import CustomText from "../../styled-UI/CustomText";
import DatePicker from "../../styled-UI/DatePicker";
import { selectIsMultidoor, selectLocale } from "../../../store/user/userSlice";
import { formatDate, addDate } from "../../../utils/dateUtils";
import { DeliveryDates } from "../../../store/cart/cartInterfaces";
import { selectCancellationDateOffset } from "../../../store/store/storeSlice";

interface Props {
  updatedDeliveryDates: DeliveryDates[];
  updateDate?: (orgentityName: string, requestedShipDate: string, date: Date) => void;
  readOnlyView?: boolean;
}

const DeliveryTable = ({
  updatedDeliveryDates,
  updateDate,
  readOnlyView = false,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const currentLocale = useSelector(selectLocale);
  const isMultidoor = useSelector(selectIsMultidoor);
  const offset = useSelector(selectCancellationDateOffset);

  return (
    <div>
      {updatedDeliveryDates.map((door) => (
        <div key={"delivery-" + door.orgentityName}>
          {!readOnlyView && isMultidoor && (
            <MultidoorContainer>
              <CustomText as="p" fontSizePx={19} font="font-bold" lineHeightPx={18} uppercase>
                {door.orgentityName}
              </CustomText>
            </MultidoorContainer>
          )}
          <Header>
            <div>
              <CustomText as="p" fontSizePx={13} font="font-medium" lineHeightPx={18} uppercase>
                {translateLabel("DELIVERY_TABLE_DELIVERY_DATE")}
              </CustomText>
            </div>
            <div>
              <CustomText as="p" fontSizePx={13} font="font-medium" lineHeightPx={18} uppercase>
                {translateLabel("DELIVERY_TABLE_CANCEL_DATE")}
              </CustomText>
            </div>
          </Header>
          <Content>
            {door.delivery.map((del) => (
              <React.Fragment key={"delivery-" + door.orgentityName + del.requestedShipDate}>
                <div>
                  <CustomText as="p" fontSizePx={13} font="font-medium">
                    {formatDate(new Date(del.requestedShipDate), "P", currentLocale)}
                  </CustomText>
                </div>
                <div>
                  {readOnlyView ? (
                    <CustomText as="p" fontSizePx={13} font="font-medium">
                      {formatDate(new Date(del.cancellationDate), "P", currentLocale)}
                    </CustomText>
                  ) : (
                    <DatePicker
                      selected={new Date(del.cancellationDate)}
                      onChange={(date: Date) =>
                        updateDate && updateDate(door.orgentityName, del.requestedShipDate, date)
                      }
                      minDate={addDate(new Date(del.requestedShipDate), {
                        days: offset ?? 10,
                      })}
                    />
                  )}
                </div>
              </React.Fragment>
            ))}
          </Content>
        </div>
      ))}
    </div>
  );
};

const MultidoorContainer = styled.div`
  margin: 1rem 0 1rem;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
  height: 2.5rem;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: center;
  margin: 1rem 0;
`;

export default DeliveryTable;
