import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPopupUserNotMigrated,
  togglePopupUserNotMigrated,
} from "../../store/store/storeSlice";
import Popup from "../styled-UI/Popup";
import Button from "../styled-UI/Button";
import CustomText from "../styled-UI/CustomText";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";

const UserNotMigratedPopup = (): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const popup = useSelector(selectPopupUserNotMigrated);

  const close = () => {
    if (popup.open) dispatch(togglePopupUserNotMigrated({ open: false }));
  };

  return popup.open ? (
    <Popup isOpen close={close} title={translateLabel("POPUP_USER_NOT_MIGRATED_TITLE")}>
      <Container>
        <Content>
          <CustomText as="p" marginBottomPx={24} fontSizePx={16} lineHeightPx={24}>
            {translateLabel("POPUP_USER_NOT_MIGRATED_DESCRIPTION")}
          </CustomText>

          <Button onClick={close} type="primary">
            {translateLabel("POPUP_CLOSE")}
          </Button>
        </Content>
      </Container>
    </Popup>
  ) : (
    <></>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding: 1.5rem;
`;

export default UserNotMigratedPopup;
