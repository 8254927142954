import React from "react";
import styled from "styled-components/macro";
import clsx from "clsx";
import { Scrollbar } from "react-scrollbars-custom";

interface Props {
  children: any;
  style?: "dark" | "light";
  onScroll?: (scrollValues: any) => void;
  offsetRight?: boolean;
  active?: boolean;
}

const CustomScrollbar = ({
  children,
  style = "dark",
  onScroll,
  offsetRight = false,
  active = true,
}: Props): JSX.Element => {
  if (active)
    return (
      <StyledScrollbar
        className={clsx(offsetRight && "offset-right", !offsetRight && "no-offset")}
        scrollbar={style}
        onScroll={onScroll}
      >
        {children}
      </StyledScrollbar>
    );
  else return <>{children}</>;
};

const StyledScrollbar = styled(Scrollbar)<{
  scrollbar: string;
  onScroll: any;
}>`
  &.offset-right > .ScrollbarsCustom-TrackY {
    right: -0.687rem !important;
  }

  &.no-offset > .ScrollbarsCustom-TrackY {
    right: 0.3125rem !important;
  }

  .ScrollbarsCustom-TrackY {
    width: 0.375rem !important;
    overflow: visible !important;
    border-radius: 0.1875rem;
    background-color: ${(props) =>
      props.scrollbar === "dark" ? props.theme.palette.gray.light : "transparent"}!important;

    .ScrollbarsCustom-Thumb {
      background-color: ${(props) =>
        props.scrollbar === "dark"
          ? props.theme.palette.primary
          : props.theme.palette.primary}!important;
      width: 0.375rem !important;
    }
  }

  .ScrollbarsCustom-TrackX {
    height: 0.375rem !important;
    overflow: visible !important;
    bottom: 0.375rem !important;
    background-color: ${(props) =>
      props.scrollbar === "dark" ? props.theme.palette.gray.light : "transparent"}!important;

    .ScrollbarsCustom-Thumb {
      background-color: ${(props) =>
        props.scrollbar === "dark"
          ? props.theme.palette.primary
          : props.theme.palette.gray.light}!important;
      height: 0.375rem !important;
    }
  }
`;
export default CustomScrollbar;
