import React, { useState } from "react";
import Slider, { LazyLoadTypes } from "react-slick";
import styled from "styled-components/macro";
import CarouselNavBar from "../../styled-UI/CarouselNavBar";
import { FeatureCarouselHorizontalItem } from "../../../interfaces/cmsInterfaces";
import FeatureHorizontalSlide from "./FeatureHorizontalSlide";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  isDarkMode?: boolean;
  items: FeatureCarouselHorizontalItem[];
  customFont?: FontFamilies | null;
}

const FeatureCarouselHorizontal = ({
  isDarkMode = false,
  items,
  customFont = null,
}: Props): JSX.Element => {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const slidesToScroll = 1;
  const currentPage = Math.ceil(currentSlide / slidesToScroll);
  const slidesToShow = 1;
  const totalSlides = items?.length;
  const lastPage = Math.ceil((totalSlides - slidesToShow) / slidesToScroll);

  const defaultSettings = {
    dots: false,
    arrows: false,
    draggable: false, //to set it to true --> prevent click on drag https://github.com/akiran/react-slick/issues/848
    speed: 500,
    infinite: false,
    lazyLoad: "ondemand" as LazyLoadTypes,
    onSwipe: () => {
      console.log("swipe");
    },
    slidesToShow: slidesToShow < totalSlides ? slidesToShow : totalSlides,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    beforeChange: (_: any, next: any) => setCurrentSlide(next),
  };

  const changeSlide = (val: number) => {
    sliderRef.slickGoTo(val);
  };

  return (
    <Container>
      <Slider ref={(val) => setSliderRef(val)} {...defaultSettings}>
        {items?.map((slide) => {
          return (
            <div key={slide?.id}>
              <FeatureHorizontalSlide
                item={slide}
                isDarkMode={isDarkMode}
                customFont={customFont}
              />
            </div>
          );
        })}
      </Slider>
      {totalSlides > 1 && totalSlides > slidesToShow ? (
        <CarouselNavBar
          currentSlide={currentSlide}
          slidesToScroll={slidesToScroll}
          changeSlide={changeSlide}
          currentPage={currentPage}
          lastPage={lastPage}
          navigationType={"central"}
          mode={isDarkMode ? "white" : "dark"}
        />
      ) : (
        <div></div>
      )}
    </Container>
  );
};

const Container = styled.div``;

export default FeatureCarouselHorizontal;
