import React, { useState } from "react";
import { Row, Col } from "../../interfaces/gridInterfaces";
import { useLocation } from "react-router-dom";
import { MemoizedItemComponent } from "./ItemComponent";
import FourColumnRow from "./row-layouts/FourColumnRow";
import OneColumnRow from "./row-layouts/OneColumnRow";
import ThreeColumnRow from "./row-layouts/ThreeColumnRow";
import TwoColumnRow from "./row-layouts/TwoColumnRow";
import LeonardoSection from "../widgets/leonardo-section/LeonardoSection";
import styled from "styled-components/macro";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  row: Row;
  landingNavbar?: boolean;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
  isWrappedLanding?: boolean;
}

const RowComponent = ({
  row,
  landingNavbar = false,
  isDarkMode = false,
  customFont = null,
  isWrappedLanding = false,
}: Props): JSX.Element => {
  const location = useLocation();
  const isLandingPage = location.pathname.includes("contents");
  const marginRow = isLandingPage ? 20 : null;
  const [backgroundImage, setBackgroundImage] = useState<string>("");
  const [backgroundColor, setBackgroundColor] = useState<string>("");
  const [isDarkModeRow, setIsDarkModeRow] = useState<boolean>(false);

  const printSafe = (content: JSX.Element) => {
    return content ? content : <div></div>;
  };

  const printContent = () => {
    if (row.name === "leonardo-carousel") {
      return (
        <div data-name={row.name}>
          <OneColumnRow
            firstColumn={<LeonardoSection />}
            data-element-id="Training"
            marginPx={marginRow}
            landingNavbar={landingNavbar}
          />
        </div>
      );
    }
    if (row.cols && row.cols.length > 0) {
      //GET ROW CONTENT
      const rowContent: JSX.Element[] = [];
      row.cols.map((col: Col, index: number) => {
        rowContent.push(
          <MemoizedItemComponent
            item={col}
            position={index}
            rowNumber={row.rowNumber}
            setRowBackgroundImage={setBackgroundImage}
            setRowBackgroundColor={setBackgroundColor}
            setRowIsDarkMode={setIsDarkModeRow}
            isDarkMode={isDarkMode || isDarkModeRow}
            customFont={customFont}
          />
        );
      });

      //CHOOSE ROW LAYOUT
      switch (row.layout) {
        case "33+33+33":
          return (
            <div data-name={row.name}>
              <ThreeColumnRow
                firstColumn={printSafe(rowContent[0])}
                secondColumn={printSafe(rowContent[1])}
                thirdColumn={printSafe(rowContent[2])}
                layout="33+33+33"
                marginPx={marginRow}
                landingNavbar={landingNavbar}
                isWrappedLanding={isWrappedLanding}
              />
            </div>
          );
        case "50+50":
          return (
            <div data-name={row.name}>
              <TwoColumnRow
                firstColumn={printSafe(rowContent[0])}
                secondColumn={printSafe(rowContent[1])}
                marginPx={marginRow}
                landingNavbar={landingNavbar}
                isWrappedLanding={isWrappedLanding}
              />
            </div>
          );
        case "25+25+25+25":
          return (
            <div data-name={row.name}>
              <FourColumnRow
                firstColumn={printSafe(rowContent[0])}
                secondColumn={printSafe(rowContent[1])}
                thirdColumn={printSafe(rowContent[2])}
                forthColumn={printSafe(rowContent[3])}
                marginPx={marginRow}
                landingNavbar={landingNavbar}
                isWrappedLanding={isWrappedLanding}
              />
            </div>
          );
        case "25+25+50":
          return (
            <div data-name={row.name}>
              <ThreeColumnRow
                firstColumn={printSafe(rowContent[0])}
                secondColumn={printSafe(rowContent[1])}
                thirdColumn={printSafe(rowContent[2])}
                layout="25+25+50"
                marginPx={marginRow}
                landingNavbar={landingNavbar}
                isWrappedLanding={isWrappedLanding}
              />
            </div>
          );
        case "50+25+25":
          return (
            <div data-name={row.name}>
              <ThreeColumnRow
                firstColumn={printSafe(rowContent[0])}
                secondColumn={printSafe(rowContent[1])}
                thirdColumn={printSafe(rowContent[2])}
                layout="50+25+25"
                marginPx={marginRow}
                landingNavbar={landingNavbar}
                isWrappedLanding={isWrappedLanding}
              />
            </div>
          );
        case "100":
          return (
            <div data-name={row.name}>
              <OneColumnRow
                firstColumn={printSafe(rowContent[0])}
                marginPx={marginRow}
                noWrapper={
                  row.name === "highlights" ||
                  row.name === "best-sellers" ||
                  row.name === "latest.trends"
                }
                landingNavbar={landingNavbar}
                isWrappedLanding={isWrappedLanding}
              />
            </div>
          );
        default:
          return (
            <div data-name={row.name}>
              <OneColumnRow
                firstColumn={printSafe(rowContent[0])}
                noWrapper={
                  row.name === "highlights" ||
                  row.name === "best-sellers" ||
                  row.name === "latest.trends"
                }
                landingNavbar={landingNavbar}
                isWrappedLanding={isWrappedLanding}
              />
            </div>
          );
      }
    }

    //FALLBACK: PRINT EMPTY ROW
    return <div></div>;
  };

  return (
    <RowContainer backgroundImage={backgroundImage} backgroundColor={backgroundColor}>
      {printContent()}
    </RowContainer>
  );
};

const RowContainer = styled.div<{
  backgroundImage: string;
  backgroundColor: string;
}>`
  background-color: ${(props) =>
    !props.backgroundColor.includes("linear-gradient") ? props.backgroundColor : ""};
  background: ${(props) =>
    props.backgroundColor.includes("linear-gradient") ? props.backgroundColor : ""};
  background-image: ${(props) => props.backgroundImage};
  background-position: center;
  background-size: cover;
`;

export default RowComponent;

export const MemoizedRowComponent = React.memo(RowComponent);
