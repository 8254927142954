import React from "react";
import styled from "styled-components/macro";

import useDataHref from "../../../hooks/useDataHref";

import CustomText from "../../styled-UI/CustomText";

import { LpHorizontalTitleText as Interface } from "../../../interfaces/cmsInterfaces";
import SetInnerHTMLWithSanitize from "../SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  data: Interface;
  id: string;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LpHorizontalTitleText = ({
  data,
  id,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const { titleColour, description, description2, description1Colour } = data;
  const descrWithHref = useDataHref({ content: description });
  const descr2WithHref = useDataHref({ content: description2 });

  return (
    <div id={id}>
      <Container>
        {description && (
          <CustomText
            as="span"
            font={customFont ? "custom-light" : "font-light"}
            fontFamily={customFont}
            fontSizePx={70}
            marginBottomPx={32}
            lineHeightPx={72}
            color={isDarkMode ? "white" : "primary"}
            customColor={titleColour}
          >
            <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? description} />
          </CustomText>
        )}
        {description2 && (
          <CustomText
            as="span"
            className="description"
            font={customFont ? "custom-regular" : "font-regular"}
            fontFamily={customFont}
            fontSizePx={18}
            lineHeightPx={26}
            color={isDarkMode ? "white" : "primary"}
            customColor={description1Colour}
          >
            <SetInnerHTMLWithSanitize text={descr2WithHref.updatedContent ?? description2} />
          </CustomText>
        )}
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 15rem;
  padding: 5.3125rem 2.8125rem 6.9375rem 2.1875rem;

  .description {
    max-width: 52.1875rem;
  }
`;

export default LpHorizontalTitleText;
