import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";
import { useSelector } from "react-redux";
import CustomText from "../../../styled-UI/CustomText";
import CustomAttributesRow from "../../../pages/smart-shopper/staging-area/CustomAttributes";
import { selectDetails } from "../../../../store/cart/cartSlice";

interface Props {
  recipeId: string;
}

interface Components {
  attributeName: string | undefined;
  attributeValue: string | undefined;
}

const CartTileDetailsM4CCustom = ({ recipeId }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const detailsM4C = useSelector(selectDetails);
  const [detail, setDetail] = useState<Components[] | null>(null);

  useEffect(() => {
    if (detailsM4C) {
      const components: Components[] = [];
      detailsM4C[recipeId]?.components?.forEach((component) => {
        if (component.visible) {
          const attributeLabel = component?.attributes?.find((x) => {
            return x?.attributeName === "M4CComponentType";
          })?.attributeValue;

          const attributeValue = component?.attributes?.find((x) => {
            return x?.attributeName === "M4CLabelValue";
          })?.attributeValue;

          components.push({ attributeName: attributeLabel, attributeValue: attributeValue });
        }
      });
      setDetail(components);
    }
  }, [detailsM4C]);

  return (
    <Container>
      <OuterBox>
        <InnerBoxDetails>
          <Title>
            <CustomText as="p" fontSizePx={18} font="font-bold">
              {translateLabel("ORDER_CUSTOM_DETAILS")}:
            </CustomText>
          </Title>
          <Grid>
            {detail?.map(
              (_: { attributeValue: string | undefined; attributeName?: string | undefined }) => {
                return <CustomAttributesRow key={_.attributeValue} attribute={_} />;
              }
            )}
          </Grid>
        </InnerBoxDetails>
      </OuterBox>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const InnerBoxDetails = styled.div`
  padding: 1rem 0rem 0rem 1rem;
`;

const Title = styled.div`
  margin-bottom: 0.75rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  row-gap: 0.5rem;
`;

const OuterBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export default CartTileDetailsM4CCustom;
