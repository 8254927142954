import { SagaIterator } from "@redux-saga/types";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { allowedServicesIds } from "../../utils/accountingUtils";
import { mapDuplicatedEmailErrorLabel } from "../../utils/utils";
import { getNotificationsAlerts, getSectionsAlerts } from "../accounting/accountingSagas";
import { handleError } from "../store/storeSagas";
import { showError } from "../store/storeSlice";
import { Door } from "../user/userInterfaces";
import {
  GetMenuKeysSRPayload,
  GetShipmentSupportSRPayload,
  getSimpleSRPayload,
  getStartSRPayload,
  MarkAsReadSRPayload,
  ReplySRPayload,
  SearchSRPayload,
  SubmitAddressSRPayload,
  SubmitBrandSRPayload,
  SubmitOrderSupportSRPayload,
  SubmitShipmentSupportSRPayload,
  SubmitSimpleSRPayload,
  UploadFilePayload,
  UrgeSRPayload,
} from "./serviceRequestInterfaces";
import serviceRequestService from "./serviceRequestService";
import {
  saveBrandSRList,
  saveGetBrandsSRStatus,
  saveGetMenuKeysSRStatus,
  saveGetServiceRequestIdentifierStatus,
  saveGetShipmentSupportSRStatus,
  saveGetSimpleSRListStatus,
  saveMenuKeys,
  saveReplySRStatus,
  saveSearchSRStatus,
  saveServiceRequestIdentifier,
  saveServiceRequestPage,
  saveShipmentSupportSRList,
  saveSimpleSRList,
  saveSubmitAddressSRStatus,
  saveSubmitOrderSupportSRStatus,
  saveSubmitShipmentSupportSRStatus,
  saveSubmitSRStatus,
  saveUploadedFileStatus,
  saveUrgeSRStatus,
  setForceUpdateCounterSR,
  setForceUpdateSRPage,
  showServiceRequestSuccessPopup,
  sliceName,
} from "./serviceRequestSlice";

/* ACTIONS */
export const getStartSR = createAction<getStartSRPayload>(sliceName + "/getStartSR");
export const getSimpleSR = createAction<getSimpleSRPayload>(sliceName + "/getSimpleSR");
export const submitSimpleSR = createAction<SubmitSimpleSRPayload>(sliceName + "/submitSimpleSR");
export const getBrandsSR = createAction<Door>(sliceName + "/getBrandsSR");
export const submitBrandSR = createAction<SubmitBrandSRPayload>(sliceName + "/submitBrandSR");
export const submitAddressSR = createAction<SubmitAddressSRPayload>(sliceName + "/submitAddressSR");
export const submitOrderSupportSR = createAction<SubmitOrderSupportSRPayload>(
  sliceName + "/submitOrderSupportSR"
);
export const getShipmentSupportSR = createAction<GetShipmentSupportSRPayload>(
  sliceName + "/getShipmentSupportSR"
);
export const submitShipmentSupportSR = createAction<SubmitShipmentSupportSRPayload>(
  sliceName + "/submitShipmentSupportSR"
);

export const uploadFile = createAction<UploadFilePayload>(sliceName + "/uploadFile");

///////////////////////////////// SERVICE REQUEST PAGE
export const getMenuKeysSR = createAction<GetMenuKeysSRPayload>(sliceName + "/getMenuKeysSR");
export const urgeSR = createAction<UrgeSRPayload>(sliceName + "/urgeSR");
export const searchSR = createAction<SearchSRPayload>(sliceName + "/searchSR");
export const replySR = createAction<ReplySRPayload>(sliceName + "/replySR");
export const markAsReadSR = createAction<MarkAsReadSRPayload>(sliceName + "/markAsReadSR");

/* SAGAS */

/**
 * Get id of request
 * @param  {PayloadAction<string>} action term to get suggested
 */
function* getStartSRSaga(action: PayloadAction<getStartSRPayload>): SagaIterator {
  try {
    yield put(saveGetServiceRequestIdentifierStatus("LOADING"));
    const { data } = yield call(serviceRequestService.getStartSR, action.payload);

    yield put(saveGetServiceRequestIdentifierStatus("SUCCESS"));
    if (data.data.output.serviceRequestIdentifier)
      yield put(saveServiceRequestIdentifier(data.data.output.serviceRequestIdentifier));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveServiceRequestIdentifier(null));
    yield put(saveGetServiceRequestIdentifierStatus("ERROR"));
  }
}

/**
 * Get simple service request structure info
 * @param  {PayloadAction<string>} action term to get suggested
 */
function* getSimpleSRSaga(action: PayloadAction<getSimpleSRPayload>): SagaIterator {
  try {
    yield put(saveGetSimpleSRListStatus("LOADING"));
    const { data } = yield call(serviceRequestService.getSimpleSR, action.payload);

    yield put(saveSimpleSRList(data.data?.output?.serviceRequestTypes));
    yield put(saveGetSimpleSRListStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveGetSimpleSRListStatus("ERROR"));
    yield put(saveSimpleSRList([]));
  }
}

/**
 * Upload file service (for warranty wizard and SR)
 *
 * @param {PayloadAction<UploadFilePayload>} action
 * @return {*}  {SagaIterator}
 */
function* uploadFileSaga(action: PayloadAction<UploadFilePayload>): SagaIterator {
  try {
    const { data } = yield call(serviceRequestService.uploadFile, action.payload);

    yield put(
      saveUploadedFileStatus({
        id: action.payload.id,
        status: "SUCCESS",
        blobName: data.output.blobName,
      })
    );
  } catch (error) {
    yield put(handleError(error));
    yield put(
      saveUploadedFileStatus({
        id: action.payload.id,
        status: "ERROR",
      })
    );
  }
}

/**
 * Get simple service request structure info
 * @param  {PayloadAction<string>} action term to get suggested
 */
function* submitSimpleSRSaga(action: PayloadAction<SubmitSimpleSRPayload>): SagaIterator {
  try {
    yield put(saveSubmitSRStatus("LOADING"));
    const { data } = yield call(serviceRequestService.submitSimpleSR, action.payload);

    const output = data.data.output;
    if (output.submitStatus === "OK") {
      yield put(saveSubmitSRStatus("SUCCESS"));
    } else {
      yield put(saveSubmitSRStatus("ERROR"));
      //TODO GESTIONE ERRORI
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveSubmitSRStatus("ERROR"));
  }
}

function* getBrandsSRSaga(action: PayloadAction<Door>): SagaIterator {
  try {
    yield put(saveGetBrandsSRStatus("LOADING"));
    const { data } = yield call(serviceRequestService.getBrandsSR, action.payload.orgentityId);

    yield put(saveBrandSRList(data.data.brandList));
    yield put(saveGetBrandsSRStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveBrandSRList([]));
    yield put(saveGetBrandsSRStatus("ERROR"));
  }
}

/**
 * Get simple service request structure info
 * @param  {PayloadAction<string>} action term to get suggested
 */
function* submitBrandSRSaga(action: PayloadAction<SubmitBrandSRPayload>): SagaIterator {
  try {
    yield put(saveSubmitSRStatus("LOADING"));
    const { data } = yield call(serviceRequestService.submitBrandSR, action.payload);
    const output = data.data.output;
    if (output.submitStatus === "OK") {
      yield put(saveSubmitSRStatus("SUCCESS"));
    } else {
      yield put(saveSubmitSRStatus("ERROR"));
      //TODO GESTIONE ERRORI
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveSubmitSRStatus("ERROR"));
  }
}

/**
 * Send create/modify address informations
 *
 * @param {PayloadAction<SubmitAddressSRPayload>} action
 * @return {*}  {SagaIterator}
 */
function* submitAddressSRSaga(action: PayloadAction<SubmitAddressSRPayload>): SagaIterator {
  try {
    yield put(saveSubmitAddressSRStatus({ errorCode: null, status: "LOADING" }));
    const { data } = yield call(serviceRequestService.submitAddressSR, action.payload);

    const output = data.data.output;
    if (output.submitStatus === "OK") {
      yield put(saveSubmitAddressSRStatus({ errorCode: null, status: "SUCCESS" }));
    } else {
      yield put(saveSubmitAddressSRStatus({ errorCode: "GENERIC", status: "ERROR" }));
    }
  } catch (error) {
    yield put(handleError(error));

    const errorArray = error?.response?.data?.errors;
    if (errorArray && errorArray.length > 0) {
      const firstError = errorArray[0]?.responseBody?.errors?.[0];
      const key = mapDuplicatedEmailErrorLabel(firstError.errorKey);
      yield put(saveSubmitAddressSRStatus({ errorCode: key, status: "ERROR" }));
    } else {
      yield put(saveSubmitAddressSRStatus({ errorCode: "GENERIC", status: "ERROR" }));
    }
  }
}

/**
 * Send order support request
 *
 * @param {PayloadAction<SubmitOrderSupportSRPayload>} action
 * @return {*}  {SagaIterator}
 */
function* submitOrderSupportSRSaga(
  action: PayloadAction<SubmitOrderSupportSRPayload>
): SagaIterator {
  try {
    yield put(saveSubmitOrderSupportSRStatus("LOADING"));
    const { data } = yield call(serviceRequestService.submitOrderSupportSR, action.payload);

    const output = data.data.output;
    if (output.submitStatus === "OK") {
      yield put(saveSubmitOrderSupportSRStatus("SUCCESS"));
    } else {
      yield put(saveSubmitOrderSupportSRStatus("ERROR"));
      //TODO GESTIONE ERRORI
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveSubmitOrderSupportSRStatus("ERROR"));
  }
}

/**
 * Get shipment support info
 *
 * @param {PayloadAction<GetShipmentSupportSRPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getShipmentSupportSRSaga(
  action: PayloadAction<GetShipmentSupportSRPayload>
): SagaIterator {
  try {
    yield put(saveGetShipmentSupportSRStatus("LOADING"));
    const { data } = yield call(serviceRequestService.getShipmentSupportSR, action.payload);
    yield put(saveShipmentSupportSRList(data.data?.output?.requestReasonList));
    yield put(saveGetShipmentSupportSRStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveGetShipmentSupportSRStatus("ERROR"));
  }
}

/**
 * Submit shipment support request
 *
 * @param {PayloadAction<SubmitShipmentSupportSRPayload>} action
 * @return {*}  {SagaIterator}
 */
function* submitShipmentSupportSRSaga(
  action: PayloadAction<SubmitShipmentSupportSRPayload>
): SagaIterator {
  try {
    yield put(saveSubmitShipmentSupportSRStatus("LOADING"));
    const { data } = yield call(serviceRequestService.submitShipmentSupportSR, action.payload);

    const output = data.data.output;
    if (output.submitStatus === "OK") {
      yield put(saveSubmitShipmentSupportSRStatus("SUCCESS"));
    } else {
      yield put(saveSubmitShipmentSupportSRStatus("ERROR"));
      //TODO GESTIONE ERRORI
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveSubmitShipmentSupportSRStatus("ERROR"));
  }
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// SERVICE REQUEST PAGE ///////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get keys to populate filters in Service request page
 *
 * @param {PayloadAction<GetMenuKeysSRPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getMenuKeysSRSaga(action: PayloadAction<GetMenuKeysSRPayload>): SagaIterator {
  try {
    yield put(saveGetMenuKeysSRStatus("LOADING"));
    const { data } = yield call(serviceRequestService.getMenuKeysSR, action.payload.doorId);
    yield put(saveMenuKeys(data.data?.output));
    yield put(saveGetMenuKeysSRStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveMenuKeys(null));
    yield put(saveGetMenuKeysSRStatus("ERROR"));
  }
}

function* urgeSRSaga(action: PayloadAction<UrgeSRPayload>): SagaIterator {
  try {
    yield put(saveUrgeSRStatus("LOADING"));
    const { data } = yield call(serviceRequestService.urgeSR, action.payload);
    const output = data.data.output;
    if (output.submitStatus === "OK") {
      yield put(saveUrgeSRStatus("SUCCESS"));
      yield put(showServiceRequestSuccessPopup(true));
      yield put(setForceUpdateSRPage(true));
    } else {
      yield put(saveUrgeSRStatus("ERROR"));
      yield put(showError({ message: undefined }));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveUrgeSRStatus("ERROR"));
  }
}

function* searchSRSaga(action: PayloadAction<SearchSRPayload>): SagaIterator {
  try {
    yield put(saveSearchSRStatus("LOADING"));
    const { data } = yield call(serviceRequestService.searchSR, action.payload);
    yield put(saveServiceRequestPage(data.data?.output));
    yield put(saveSearchSRStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveServiceRequestPage(null));
    yield put(saveSearchSRStatus("ERROR"));
  }
}

function* replySRSaga(action: PayloadAction<ReplySRPayload>): SagaIterator {
  try {
    yield put(saveReplySRStatus("LOADING"));
    const { data } = yield call(serviceRequestService.replySR, action.payload);
    const output = data.data.output;

    if (output.submitStatus === "OK") {
      yield put(saveReplySRStatus("SUCCESS"));
      yield put(showServiceRequestSuccessPopup(true));
      yield put(setForceUpdateSRPage(true));
    } else {
      yield put(saveReplySRStatus("ERROR"));
      yield put(showError({ message: undefined }));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveReplySRStatus("ERROR"));
  }
}

function* markAsReadSRSaga(action: PayloadAction<MarkAsReadSRPayload>): SagaIterator {
  try {
    const { data } = yield call(serviceRequestService.markAsReadSR, action.payload);
    yield put(setForceUpdateCounterSR(true));
    yield put(getSectionsAlerts(allowedServicesIds));
    yield put(getNotificationsAlerts());
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* serviceRequestSaga(): SagaIterator {
  yield takeLatest(getStartSR.type, getStartSRSaga);
  yield takeLatest(getSimpleSR.type, getSimpleSRSaga);
  yield takeLatest(submitSimpleSR.type, submitSimpleSRSaga);
  yield takeLatest(submitBrandSR.type, submitBrandSRSaga);
  yield takeEvery(getMenuKeysSR.type, getMenuKeysSRSaga);
  yield takeEvery(uploadFile.type, uploadFileSaga);
  yield takeEvery(submitAddressSR.type, submitAddressSRSaga);
  yield takeLatest(submitOrderSupportSR.type, submitOrderSupportSRSaga);
  yield takeEvery(getShipmentSupportSR.type, getShipmentSupportSRSaga);
  yield takeEvery(submitShipmentSupportSR.type, submitShipmentSupportSRSaga);
  yield takeEvery(urgeSR.type, urgeSRSaga);
  yield takeEvery(searchSR.type, searchSRSaga);
  yield takeEvery(replySR.type, replySRSaga);
  yield takeEvery(markAsReadSR.type, markAsReadSRSaga);
  yield takeEvery(getBrandsSR.type, getBrandsSRSaga);
}
