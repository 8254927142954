import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

const useSaveScrollPosition = ({ restoreScroll }: { restoreScroll: boolean }): void => {
  const scrollableElement = document.getElementById("root");
  const urlInfo = useRef({ url: "", search: "" });
  const scrollHeight = useRef(0);
  const history = useHistory();

  useEffect(() => {
    const lastScrolled = localStorage.getItem("scrolledElementHeight");
    const lastUrl = localStorage.getItem("plpUrl");
    const lastSearch = localStorage.getItem("plpSearch");

    if (lastScrolled && scrollableElement && lastUrl && restoreScroll) {
      resetScrollPosition();
      if (lastUrl === history.location.pathname && lastSearch === history.location.search) {
        setTimeout(() => {
          scrollableElement.scrollTop = Number(lastScrolled);
        }, 500);
      }
    }
  }, [history.location, restoreScroll]);

  const handleScroll = () => {
    if (scrollableElement?.scrollTop) {
      scrollHeight.current = scrollableElement?.scrollTop;
    }
  };

  useEffect(() => {
    scrollableElement && scrollableElement.addEventListener("scroll", handleScroll);
    return () => {
      scrollableElement && scrollableElement.removeEventListener("scroll", handleScroll);
    };
  }, [scrollableElement]);

  const saveVariabiles = () => {
    localStorage.setItem("scrolledElementHeight", scrollHeight.current.toString());
    localStorage.setItem("plpUrl", urlInfo.current.url);
    localStorage.setItem("plpSearch", urlInfo.current.search);
  };

  useEffect(() => {
    urlInfo.current = { url: history.location.pathname, search: history.location.search };
  }, [history.location]);

  useEffect(() => {
    return () => {
      saveVariabiles();
    };
  }, []);
};

export const resetScrollPosition = (): void => {
  localStorage.removeItem("scrolledElementHeight");
  localStorage.removeItem("plpUrl");
  localStorage.removeItem("plpSearch");
};

export default useSaveScrollPosition;
