import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";
import { getMultiMedia, useHandleCmsLink } from "../../utils/cmsUtils";
import Button from "../styled-UI/Button";
import CustomText from "../styled-UI/CustomText";
import { LpBasicBanner as Interface } from "../../interfaces/cmsInterfaces";
import useDataHref from "../../hooks/useDataHref";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  size: "small" | "full";
  data: Interface;
  customFont?: FontFamilies | null;
}

const LpBasicBanner = ({ size, data, customFont = null }: Props): JSX.Element => {
  const { handleCmsLink } = useHandleCmsLink();

  const {
    multiMediaType,
    description2,
    description2Colour,
    description,
    description1Colour,
    title,
    titleColour,
    linkLabel,
    linkString,
    externalLink,
    openInNewTab,
  } = data;
  const { srcImg } = getMultiMedia(multiMediaType);

  const descrWithHref = useDataHref({ content: description });
  const descrWithHref2 = useDataHref({ content: description2 });

  const handleRedirect = (): void => {
    handleCmsLink(linkString, externalLink, openInNewTab);
  };

  return (
    <OuterBoxCard className={clsx(size === "small" && "small", size === "full" && "full")}>
      <InnerBoxCard srcImg={srcImg}>
        <OuterBoxRow>
          <BoxRow>
            <BoxColumn>
              {title && (
                <CustomText
                  as="span"
                  color="white"
                  fontSizePx={18}
                  font={customFont ? "custom-regular" : "font-regular"}
                  fontFamily={customFont}
                  marginBottomPx={description ? 16 : 3}
                  customColor={titleColour}
                >
                  {title}
                </CustomText>
              )}
              {description && (
                <CustomText
                  as="span"
                  color="white"
                  fontSizePx={36}
                  lineHeightPx={36}
                  font={customFont ? "custom-medium" : "font-medium"}
                  fontFamily={customFont}
                  customColor={description1Colour}
                >
                  <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? description} />
                </CustomText>
              )}
            </BoxColumn>
            {description2 && (
              <CustomText
                as="span"
                color="white"
                fontSizePx={18}
                lineHeightPx={28}
                font={customFont ? "custom-regular" : "font-regular"}
                fontFamily={customFont}
                customColor={description2Colour}
              >
                <SetInnerHTMLWithSanitize text={descrWithHref2?.updatedContent ?? description2} />
              </CustomText>
            )}
            <BoxButton className={clsx(size === "small" && "small")}>
              <Button
                onClick={handleRedirect}
                type="primary-light"
                customFont={customFont}
                size="sm"
              >
                {linkLabel}
              </Button>
            </BoxButton>
          </BoxRow>
        </OuterBoxRow>
      </InnerBoxCard>
    </OuterBoxCard>
  );
};

const OuterBoxCard = styled.div`
  &.small {
    height: 30rem;
  }

  &.full {
    height: 50rem;
  }
`;

const InnerBoxCard = styled.div<{ srcImg?: string }>`
  height: 100%;
  background-image: url(${(props) => props.srcImg});
  background-size: cover;
`;

const OuterBoxRow = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
`;

const BoxRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 2.8rem 2.8rem 2.8rem;
  align-items: flex-end;
  border-top: 1px solid #ffffff80;
  width: 100%;
  padding-top: 1rem;

  button {
    min-width: 11rem;
  }
`;

const BoxColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxButton = styled.div`
  &.small {
    margin-top: 2rem;
  }
`;
export default LpBasicBanner;
