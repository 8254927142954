import React from "react";
import styled from "styled-components/macro";
import CustomText from "../../styled-UI/CustomText";
import { LpInformationSheet as Interface } from "../../../interfaces/cmsInterfaces";
import LpInformationSheetCard from "./LpInformationSheetCard";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  data: Interface;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LpInformationSheet = ({
  data,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const { collectionTitle } = data;
  return (
    <Container>
      {collectionTitle && (
        <CustomText
          as="span"
          color={isDarkMode ? "white" : "primary"}
          fontSizePx={28}
          font={customFont ? "custom-medium" : "font-medium"}
          fontFamily={customFont}
          marginBottomPx={40}
        >
          {collectionTitle}
        </CustomText>
      )}

      <OuterBoxCard>
        {data?.result?.map((card) => {
          return (
            <LpInformationSheetCard
              key={card.id}
              card={card}
              isDarkMode={isDarkMode}
              customFont={customFont}
            />
          );
        })}
      </OuterBoxCard>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const OuterBoxCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default LpInformationSheet;
