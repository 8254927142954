import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import BrandsColumn from "./BrandsColumn";
import MenuColumn from "./MenuColumn";
import useMenuColumnsInfo, { MenuDivInfo } from "../../../../../../hooks/useMenuColumnsInfo";
import { SecondLevelMenu } from "../../../../../../interfaces/menuInterfaces";

interface Props {
  menu: SecondLevelMenu;
  toggleMenu: (show: boolean) => void;
  setToggleMenu: (identifier: string) => void;
  nameCategory?: string;
}

interface MenuDiv {
  key: string;
  content: JSX.Element;
}

const MenuColumns = ({ menu, toggleMenu, setToggleMenu, nameCategory }: Props): JSX.Element => {
  const [menuContent, setMenuContent] = useState<MenuDiv[]>([]);

  const menuContentInfo: MenuDivInfo[] = useMenuColumnsInfo({
    menu,
    nameCategory,
    considerBrandsAsAdult: false,
  });

  useEffect(() => {
    const columns: MenuDiv[] = [];

    menuContentInfo.forEach((info: MenuDivInfo) => {
      columns.push({
        key: info.key,
        content: info.isBrandColumn ? (
          <BrandsColumn
            isStars={info.isStars}
            key={info.keyColumn}
            isKids={info.isKids}
            identifier={info.identifier}
            toggleMenu={toggleMenu}
            setToggleMenu={setToggleMenu}
            title={info.title || ""}
            subtitle={info.subtitle}
            showStarIcon={info.showStarIcon}
            brandColumns={info.brandColumns}
          />
        ) : (
          <MenuColumn
            key={info.keyColumn}
            baseUrl={info.baseUrl}
            columnStructure={info.columnStructure}
            identifier={info.identifier}
            toggleMenu={toggleMenu}
            setToggleMenu={setToggleMenu}
            title={info.title}
            nameCategory={info.nameCategory}
            categoryColumns={info.categoryColumns}
          />
        ),
      });
    });

    setMenuContent(columns);
  }, [menuContentInfo]);

  return menuContent.length > 0 ? (
    <Container>
      {menuContent?.map((_) => {
        return <div key={_.key}>{_.content}</div>;
      })}
    </Container>
  ) : (
    <></>
  );
};

const Container = styled.div`
  display: flex;
  flex-grow: 1;

  .column-container {
    display: flex;
    flex-grow: 1;

    &.column-container-stars {
      background-color: ${(props) => props.theme.palette.primary};
    }
  }
`;

export default MenuColumns;
