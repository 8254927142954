import React, { useState } from "react";
import styled from "styled-components/macro";
import CategoryItem from "./category-item/CategoryItem";
import { SecondLevelMenu } from "../../../../../interfaces/menuInterfaces";
import { useBreakpoint } from "../../../../../assets/theme/breakpoint";
import useCanRender from "../../../../../hooks/useCanRender";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/x-icon.svg";

interface Props {
  catMenuData?: SecondLevelMenu[];
  setToggleMenu: (identifier: string) => void;
  marginTop: number;
}

const CategoriesMenu = ({ catMenuData, setToggleMenu, marginTop = 0 }: Props): JSX.Element => {
  const canRender = useCanRender();
  const [submenuShown, setSubmenuShown] = useState<boolean>(false);
  const [current, setCurrent] = useState<string>("");
  const breakpoints = useBreakpoint();
  const heightRem = breakpoints["md"] ? 15 : 16.75;

  const canShowCategory = (identifier: string) => {
    if (identifier === "Lenses" || identifier === "LENSES") {
      return canRender("LENS_DIGITAL_CATALOGUE") || canRender("RX");
    } else if (identifier === "Instruments" || identifier === "INSTRUMENTS")
      return canRender("INSTRUMENTS");
    else return true;
  };

  let categoryCanRender = 0;
  catMenuData?.forEach((catmenuitem: SecondLevelMenu) => {
    if (canShowCategory(catmenuitem.identifier)) categoryCanRender++;
  });
  const respHeight = categoryCanRender > 6 ? -0.85 : 0.85;

  return (
    <MenuContainer heightRem={heightRem + respHeight}>
      <WrapperButton>
        <CloseButton
          data-element-id={`MainNav_Menu_Close`}
          onClick={() => {
            setCurrent("");
            setSubmenuShown(false);
          }}
        >
          <CloseIcon />
        </CloseButton>
      </WrapperButton>
      <Menu open={submenuShown}>
        {catMenuData &&
          catMenuData.map(
            (catmenuitem: SecondLevelMenu) =>
              canShowCategory(catmenuitem.identifier) && (
                <CategoryItem
                  menu={catmenuitem}
                  key={catmenuitem.name ? catmenuitem.identifier + catmenuitem.name : ""}
                  setSubmenuShown={setSubmenuShown}
                  setCurrent={setCurrent}
                  current={current}
                  setToggleMenu={setToggleMenu}
                  marginTop={marginTop + heightRem + respHeight}
                />
              )
          )}
      </Menu>
    </MenuContainer>
  );
};

const MenuContainer = styled.div<{ heightRem: number }>`
  height: ${(props) => props.heightRem + "rem"};
  box-sizing: border-box;
`;

const Menu = styled.div<{ open: boolean }>`
  display: flex;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.palette.white};
  box-sizing: border-box;
  padding: 1.813rem 0.5rem 0;
  @media ${(props) => props.theme.queries.md} {
    padding: 0.813rem 0.5rem 0.5rem;
  }
`;

const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0 0 0;
  cursor: pointer;
`;

const CloseButton = styled.button`
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export default CategoriesMenu;
