import React from "react";
import styled from "styled-components/macro";
import clsx from "clsx";

import Tile from "../Tile/Tile";
import TrendTileInfoSection from "./TrendTileInfoSection";
import { TrendTile as TrendTileInterface } from "../../../interfaces/cmsInterfaces";
import { getAnalyticsTagId, getMultiMedia, useHandleCmsLink } from "../../../utils/cmsUtils";
import CustomProgressiveImage from "../../styled-UI/CustomProgressiveImage";
import VideoComponent from "../../styled-UI/VideoComponent";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  data: TrendTileInterface;
  tileNumber?: number;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}
const TrendTile = ({
  data,
  tileNumber,
  isDarkMode = false,
  customFont = null,
}: Props): JSX.Element => {
  const {
    multiMediaType,
    title,
    titleColour,
    modelColor,
    linkString,
    externalLink,
    description,
    description1Colour,
    linkLabel,
    linkLabelColour,
    openInNewTab,
  } = data;
  const { srcImg, srcVideo, coverImg, placement } = getMultiMedia(multiMediaType);
  const { handleCmsLink } = useHandleCmsLink();
  const analyticsTag = getAnalyticsTagId(data, data.id);

  const handleRedirect = (): void => {
    handleCmsLink(linkString, externalLink, openInNewTab);
  };

  return (
    <StyledTile className={clsx(!modelColor && "two-column-grid")}>
      <Col>
        <ImageTile data-element-id="">
          {srcImg && !srcVideo && (
            <CustomProgressiveImage type="portrait" src={srcImg} isHeight100 />
          )}
          {srcVideo && (
            <VideoComponent
              srcVideo={srcVideo}
              coverImage={coverImg}
              previewMode={coverImg ? "cover" : "first-frame"}
              placement={placement}
            />
          )}
        </ImageTile>
      </Col>
      {modelColor && (
        <Col className="margin-left">
          <Tile
            data={modelColor}
            hideFooter
            hideSeeVariants
            cssStyle="space-between"
            data-element-id={`Trends_ProdsTile_${tileNumber}`}
            enableDataDescription
          />
        </Col>
      )}
      <Col className="margin-left">
        <TrendTileInfoSection
          title={title}
          titleColour={titleColour}
          description={description}
          description1Colour={description1Colour}
          linkString={linkString}
          linkLabel={linkLabel}
          linkLabelColour={linkLabelColour}
          data-element-id={`Trends_TextTile_${tileNumber}`}
          data-description={`${analyticsTag}_${title}`}
          handleRedirect={handleRedirect}
          isDarkMode={isDarkMode}
          customFont={customFont}
        />
      </Col>
    </StyledTile>
  );
};

const StyledTile = styled.div`
  height: 36.88rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  &.two-column-grid {
    grid-template-columns: 2fr 1fr;
  }
`;

const Col = styled.div`
  &.margin-left {
    margin-left: 1.25rem;
  }
`;

const ImageTile = styled.div`
  height: 100%;
  img {
    height: 36.88rem;
    width: 100%;
    object-fit: cover;
  }
`;

export default TrendTile;
