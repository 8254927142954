import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import catalogueService from "../../../../store/catalogue/catalogueService";
import useStatelessService from "../../../../store/statelessServices/useStatelessService";
import { getPagePath } from "../../../../routing/routesUtils";
import CustomSelect, { CustomOptions } from "../../../styled-UI/CustomSelect";

interface Props {
  uniqueId: string;
  isCarousel?: boolean;
}

const TileFooterAccessories = ({ uniqueId, isCarousel = false }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const { requestStatus, makeRequest, resetRequestState } = useStatelessService();
  const history = useHistory();

  const [options, setOptions] = useState<CustomOptions[]>([]);

  const mapProductMerchandising = (data: any[]): CustomOptions[] => {
    return data?.map((_) => {
      return {
        label: (_?.partNumber ?? "") + " " + (_?.name ?? ""),
        value: _?.partNumber?.toLowerCase(),
      };
    });
  };

  useEffect(() => {
    if (requestStatus.status === "SUCCESS") {
      const { data } = requestStatus.data;

      const newOptions =
        data?.merchandisingAssociations && mapProductMerchandising(data?.merchandisingAssociations);
      newOptions && setOptions(newOptions);
      resetRequestState();
    }
  }, [requestStatus.status]);

  return (
    <FooterContentFrames>
      <CustomSelect
        type="white"
        options={options}
        placeholder={translateLabel("COMPATIBLE_WITH")}
        onChange={(option) => {
          option.value && history.push(getPagePath("/pdp/" + option.value)); // redirect to corresponding PDP
        }}
        onFocus={() => {
          if (options.length === 0)
            // if options haven't already been fetched
            makeRequest(catalogueService.getProductMerchandising, uniqueId); // call service when focusing on dropdown
        }}
        isLoading={requestStatus.status === "LOADING"}
        menuPlacement={isCarousel ? "top" : "auto"}
      />
    </FooterContentFrames>
  );
};

const FooterContentFrames = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: inherit;
  > div {
    width: 100%;
  }
`;

export default TileFooterAccessories;
