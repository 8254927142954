import { SagaIterator } from "@redux-saga/types";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, intersectionBy } from "lodash";
import { call, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";

import {
  clearPreCart,
  removeItemsFromPrecart,
  saveAvailabileQuantityAFAInfo,
  saveCustomerReferenceAFA,
  saveProductAvailability,
  selectCustomerReferenceAFA,
  selectHasPricePrivilege,
  selectMultidoorHasPricePrivilege,
  selectPreCart,
  selectPreCartMassiveOrder,
  unfollowItemsAddedToCart,
} from "../catalogue/catalogueSlice";
import { handleError, showErrorPopup } from "../store/storeSagas";
import cartService from "./cartService";

import { checkIsAFAOrHelmet, orderAFASize } from "../../utils/AFAutils";
import {
  getAvailableAlternativeVariants,
  getCheckPrescriptionBrasilPayload,
  getFailedRxBrasilStatus,
  getPostEssilorPrecartItemsPayload,
  getPostPrecartItemsPayload,
  getPutPrecartItemsArrayPayload,
  getPutPrecartItemsPayload,
  mapMultidoorAddress,
  mapOrderMultidoorArray,
  mapOutOfStockPartNumbersArray,
  mapRxPrescriptionBrasilResult,
  mapShippingAddressSummaryMultidoor,
  mapSubOrdersNotesAndPO,
  updateRxBrasilStatus,
} from "../../utils/cartUtils";
import { addPriceToProductorVariant, mapPriceResponse } from "../../utils/catalogueUtils";
import { getSkuIdsFromVariants, mapSkusArray, mapVariantsArray } from "../../utils/productUtils";
import { cloneDeepSet } from "../../utils/utils";
import {
  AvailabilityStatusByDoor,
  GetSkuForVariantPayload,
  PreCartMassiveOrderProduct,
  PreCartProduct,
} from "../catalogue/catalogueInterface";
import { getProductAvailability } from "../catalogue/catalogueSaga";
import catalogueService from "../catalogue/catalogueService";
import { AvailableProduct } from "../messages/messagesInterfaces";
import messagesService from "../messages/messagesServices";
import { Door } from "../user/userInterfaces";
import {
  selectAtLeastOneTruePrivileges,
  selectIsBackOfficeUser,
  selectIsMassiveOrderModeActive,
  selectIsMultidoor,
  selectMultiDoors,
} from "../user/userSlice";
import { exceedsMinutes } from "./../../utils/dateUtils";
import {
  AddDirectlyToPreCartPayload,
  AddEssilorItemsToCart,
  AddItemsPerVariantToPrecartPayload,
  AddItemsToPrecartPayload,
  CartAlternativeVariantsPayload,
  CartCountQuantity,
  CustomDetailsPayload,
  DeletePrecartItemsAFAPayload,
  GetDeliveryPayload,
  OrderCategory,
  OrderItem,
  PostEssilorPrecartItemsPayload,
  RxPrescriptionBrasilStatus,
  SaveDeliveryPayload,
  SubOrderPayload,
  UpdatePrecartItemPayload,
  UpdatePrecartItemsPayload,
  UploadPrescriptionSagaPayload,
} from "./cartInterfaces";
import {
  clearCart,
  initializeSelectedItems,
  saveBackorderItems,
  saveBackorderTrackedItems,
  saveCartAlternativeVariants,
  saveCartTotalAlternativeVariants,
  saveCartTotalPrice,
  saveCountQuantity,
  saveDeliveryDates,
  saveDetails,
  saveGetDeliveryDatesStatus,
  saveGetOtherSkusStatus,
  saveOrderId,
  saveOrderMultidoorAddress,
  saveOrderMultidoorList,
  saveOtherSkus,
  saveOutOfStockItems,
  saveSaveDeliveryDatesStatus,
  saveShippingAddressList,
  saveSubOrdersNotesAndPO,
  selectBackorderItems,
  selectBackorderTrackedItems,
  selectCartLoading,
  selectCountQuantity,
  selectOrderId,
  selectRxBrasilCheckPayload,
  selectRxBrasilStatus,
  selectRxBrasilToken,
  selectSelectedItems,
  setAddToCartRequestStatus,
  setCartAlternativeVariantsLoading,
  setDetailsStatus,
  setDisabledAddToCart,
  setIsCartEmpty,
  setRxBrasilStatus,
  setRxBrasilToken,
  sliceName,
  startCartLoading,
  stopCartLoading,
  updateSelectedItems,
} from "./cartSlice";
import checkoutServices from "../checkout/checkoutServices";
import { SubmitOrderPayload } from "../checkout/checkoutInterfaces";
import { saveEssilorOrderConfirmationData } from "../checkout/checkoutSlice";
import { mapOrderJsonHeaderDetails, mapOrderJsonLensDetails } from "../../utils/essilorUtils";
import { selectLxConfigurations } from "../store/storeSlice";
import { mapOrderVoucher } from "../../utils/checkoutUtils";
import { saveOrderPromotionVoucher } from "../checkout/checkoutSlice";

/************************************ ACTIONS ************************************/

// ADDING, GETTING AND UPDATING PRE-CART ORDER ITEMS
export const addItemsToPrecart = createAction<AddItemsToPrecartPayload>(
  sliceName + "/addItemsToPrecart"
);
export const addItemsToEssilorCart = createAction<AddEssilorItemsToCart>(
  sliceName + "/addItemsToEssilorCart"
);
export const getPrecart = createAction(sliceName + "/getPrecart");
export const updatePrecartItem = createAction<UpdatePrecartItemPayload>(
  sliceName + "/updatePrecartItem"
);
export const updatePrecartItems = createAction<UpdatePrecartItemsPayload>(
  sliceName + "/updatePrecartItems"
);
export const getOtherSkus = createAction<GetSkuForVariantPayload>(sliceName + "/getOtherSkus");
export const addDirectlyToPreCart = createAction<AddDirectlyToPreCartPayload>(
  sliceName + "/addDirectlyToPreCart"
);
//ADD ITEMS TO PRECART -- PRODUCT RECOMMENDATION
export const addItemsPerVariantToPrecart = createAction<AddItemsPerVariantToPrecartPayload>(
  sliceName + "/addItemsPerVariantToPrecart"
);

// DELETING PRE-CART ORDER ITEMS
export const deletePrecart = createAction(sliceName + "/deletePrecart");
export const deleteOutOfStockPrecart = createAction(sliceName + "/deleteOutOfStock");
export const deletePrecartItem = createAction<string>(sliceName + "/deletePrecartItem");
export const deletePrecartItemsAFA = createAction<DeletePrecartItemsAFAPayload>(
  sliceName + "/deletePrecartItemsAFA"
);
export const deletePrecartCategory = createAction<OrderItem[]>(
  sliceName + "/deletePrecartCategory"
);
export const deletePrecartMultidoor = createAction<OrderCategory[]>(
  sliceName + "/deletePrecartMultidoor"
);

// SHIPPING INFO
export const getShippingInfo = createAction(sliceName + "/getShippingInfo");
export const getEssilorShippingInfo = createAction(sliceName + "getEssilorShippingInfo");

// MISC
export const updatePrecart = createAction<SubOrderPayload>(sliceName + "/updatePrecart");
export const getPrecartCount = createAction<boolean>(sliceName + "/getPrecartCount");
export const simulatePrecart = createAction(sliceName + "/simulatePrecart");

// CART ALTERNATIVE PRODUCTS
export const getCartAlternativeVariants = createAction<CartAlternativeVariantsPayload>(
  sliceName + "/getCartAlternativeVariants"
);

// M4C CUSTOM DETAILS
export const getCustomDetails = createAction<CustomDetailsPayload>(sliceName + "/getCustomDetails");

// RX PRESCRIPTION BRASIL
export const loginPrescriptionBrasil = createAction(sliceName + "/loginPrescriptionBrasil");

export const checkPrescriptionBrasil = createAction<string[]>(
  sliceName + "/checkPrescriptionBrasil"
);

export const uploadPrescriptionBrasil = createAction<UploadPrescriptionSagaPayload>(
  sliceName + "/uploadPrescriptionBrasil"
);

// SET DELIVERY
export const getDelivery = createAction<GetDeliveryPayload>(sliceName + "/getDelivery");

export const saveDelivery = createAction<SaveDeliveryPayload>(sliceName + "/saveDelivery");

/************************************ SAGAS ************************************/

//////////////////////////////////////////////////////////////////////////////////
////////////// ADDING, GETTING AND UPDATING PRE-CART ORDER ITEMS /////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Adds items to pre-cart
 *
 * @param {PayloadAction<any>} action
 * @return {*}  {SagaIterator}
 */
function* addItemsToPrecartSaga(action: PayloadAction<AddItemsToPrecartPayload>): SagaIterator {
  const { callback, doSimulate } = action.payload;

  const orderId = yield select(selectOrderId);
  const isMassiveOrderMode = yield select(selectIsMassiveOrderModeActive);
  const precart: PreCartProduct[] = yield select(selectPreCart); // get precart
  const newPreCart = isMassiveOrderMode ? cloneDeep(precart) : precart;

  //ADD PRECART MASSIVE ORDER MODE INTO NORMAL PRECART
  if (isMassiveOrderMode) {
    const precartMassiveMode: PreCartMassiveOrderProduct[] = yield select(
      selectPreCartMassiveOrder
    );
    const multiDoors: Door[] = yield select(selectMultiDoors);

    precartMassiveMode.forEach((_) => {
      _.doors.forEach((currentDoor) => {
        const door = multiDoors.filter((_) => _.orgentityName === currentDoor);
        if (door.length > 0) {
          const index = newPreCart.findIndex(
            (item) => item.partNumber === _.partNumber && item.multidoorId === door[0].orgentityId
          );
          if (index !== -1 && newPreCart[index]) {
            const item = newPreCart[index];
            if (item.quantity !== undefined && _.quantity) item.quantity += _.quantity;
          } else {
            const item: PreCartProduct = {
              quantity: _.quantity,
              partNumber: _.partNumber,
              multidoorId: door[0].orgentityId,
              orgentityName: door[0].orgentityName,
              sku: _.sku,
            };

            newPreCart.push(item);
          }
        }
      });
    });
  }

  //IF IS AFA GET CUSTOMER REFERENCE FROM SLICE AND SAVE IT IN EACH ITEM
  let customerReference = null;
  const isAFAOrHelmet =
    precart && precart.length > 0 ? checkIsAFAOrHelmet(precart[0].sku?.productCategory) : false;

  if (precart.length > 0 && isAFAOrHelmet) {
    customerReference = yield select(selectCustomerReferenceAFA);
  }

  //MAP PAYLOAD
  const payload = getPostPrecartItemsPayload(orderId, newPreCart, customerReference); // map precart into service payload

  yield put(setDisabledAddToCart(true));

  try {
    // yield put(setAddToCartLoading(true));
    yield put(setAddToCartRequestStatus("LOADING"));
    const { data } = yield call(cartService.postPrecartItems, payload);
    yield put(saveOrderId(data?.data?.orderId)); // update orderId
    yield put(clearPreCart()); // clear precart once it's been added to cart
    yield put(getPrecartCount(true)); // force update the precart count

    try {
      if (doSimulate) yield call(cartService.simulatePrecart);
    } catch (error) {
      yield put(handleError(error));
    }
    callback && callback(!!data?.data);

    if (isAFAOrHelmet) {
      yield put(saveCustomerReferenceAFA(null));
      yield put(saveAvailabileQuantityAFAInfo(precart));
    }

    yield put(setDisabledAddToCart(false));
    yield put(setAddToCartRequestStatus("SUCCESS"));

    // remove follow from product availability
    const isBackOfficeUser = yield select(selectIsBackOfficeUser);
    const privileges: string[] = yield select(selectAtLeastOneTruePrivileges);

    if (!isBackOfficeUser && privileges.includes("GREEN_IS_BACK")) {
      const cartIdList: string[] = precart.map((item) => item.sku.uniqueID);
      yield put(unfollowItemsAddedToCart(cartIdList));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setDisabledAddToCart(false));
    callback && callback(false, error?.response?.data?.errors?.[0]?.code);
    yield put(setAddToCartRequestStatus("ERROR"));

    if (error.response.status === 400) {
      const message = "ERROR" + error?.response?.data?.errors?.[0]?.code;
      yield put(showErrorPopup({ status: 400, message: message }));
    }
  } finally {
    // yield put(setAddToCartLoading(false));
  }
}

/**
 * Adds items to Essilor pre-cart
 *
 * @param {PayloadAction<any>} action
 * @return {*}  {SagaIterator}
 */
function* addItemsToEssilorCartSaga(action: PayloadAction<AddEssilorItemsToCart>): SagaIterator {
  const { callback } = action.payload;
  const orderId = yield select(selectOrderId);
  const LXConfiguration = yield select(selectLxConfigurations);
  const isAPIFallback =
    LXConfiguration?.filter(
      (element: any) => element.key === "com.luxottica.oneportal.api.fallback"
    )?.[0]?.value === "true";

  if (action.payload.product) {
    const payload: PostEssilorPrecartItemsPayload = getPostEssilorPrecartItemsPayload(
      orderId,
      action.payload.product,
      isAPIFallback
    ); // map precart into service payload

    if (action.payload.product.Order)
      yield put(
        saveEssilorOrderConfirmationData({
          lensDetails: mapOrderJsonLensDetails(JSON.parse(action.payload.product.Order)),
          headerData: mapOrderJsonHeaderDetails(JSON.parse(action.payload.product.Order)),
        })
      );

    try {
      yield put(setAddToCartRequestStatus("LOADING"));
      const { data } = yield call(cartService.postEssilorPrecartItems, payload);
      yield put(saveOrderId(data?.data?.orderId)); // update orderId

      if (action.payload?.product?.Order) {
        const parsedOrderJson = JSON.parse(action.payload?.product?.Order);
        yield put(saveOrderPromotionVoucher(mapOrderVoucher(parsedOrderJson)));
      }
      // yield put(clearPreCart()); // clear precart once it's been added to cart
      // yield put(getPrecartCount(true)); // force update the precart count
      // const updatedOrderId = data?.data?.orderId;
      // const orderItemId = data?.data?.orderItem[0]?.orderItemId; //The essilor order always has only one item
      // yield call(
      //   checkoutServices.postCart,
      //   {
      //     orderId: updatedOrderId,
      //     orderItem: [orderItemId],
      //   },
      //   true
      // );
      callback && callback(true);

      yield put(setAddToCartRequestStatus("SUCCESS"));
    } catch (error) {
      yield put(handleError(error));
      yield put(saveOrderPromotionVoucher(null));
      callback && callback(false, error?.response?.data?.errors?.[0]?.code);
      yield put(setAddToCartRequestStatus("ERROR"));

      if (error.response.status === 400) {
        const message = "ERROR" + error?.response?.data?.errors?.[0]?.code;
        yield put(showErrorPopup({ status: 400, message: message }));
      }
    }
  }
}

/**
 * Get items from pre-cart
 *
 * @return {*}  {SagaIterator}
 */
function* getPrecartSaga(): SagaIterator {
  try {
    yield put(startCartLoading({ id: "cart", type: null }));

    const { data } = yield call(cartService.getPrecart);
    yield put(saveOrderId(data?.data?.orderId)); // update orderId

    const multidoorList = mapOrderMultidoorArray(data?.data?.multidoorResponseList);

    if (multidoorList) {
      yield put(saveOrderMultidoorList(multidoorList));
      yield put(saveCartTotalPrice(data?.data?.grandTotal));
    }

    const multidoorAddresses = mapMultidoorAddress(data?.data?.multidoorResponseList);
    if (!multidoorAddresses || multidoorAddresses.length === 0) throw { response: { status: 400 } }; // handle empty cart

    yield put(saveOrderMultidoorAddress(multidoorAddresses)); // update checkoutMultidoorAddress

    const subOrdersNotesAndPO = mapSubOrdersNotesAndPO(data?.data?.multidoorResponseList);
    if (subOrdersNotesAndPO) yield put(saveSubOrdersNotesAndPO(subOrdersNotesAndPO)); // update subOrdersNotesAndPO

    const selectedItems = yield select(selectSelectedItems); // get info re: selected items from redux
    //TODO: get it from local storage, as well?
    if (selectedItems.length === 0) {
      yield put(initializeSelectedItems()); // if empty, initialize it
    } else {
      yield put(updateSelectedItems(multidoorList)); // else compare selectedItems with multidoorList to add new items
    }

    // BACKORDER TRACKING
    // search if there are backorder item
    const backorderItems = yield select(selectBackorderItems);
    const backorderItemsCopy = cloneDeepSet(backorderItems);

    // try to update products
    multidoorList?.forEach((door) => {
      // try to add new backorder products if they exist
      door.categoryList.forEach((cat) => {
        cat.orderItemList.forEach((item) => {
          if (item.split?.[0]?.semaphore === "BACKORDER") {
            backorderItemsCopy.add(item.sku.uniqueID as string);
          }
        });
      });
    });

    // make call only if there was no backorder product
    if (backorderItemsCopy.size > backorderItems.size) {
      try {
        // call backorder tracking service
        const trackedRes = yield call(
          messagesService.areProductsTracked,
          Array.from(backorderItemsCopy)
        );

        // update backorder product so that the call doesn't
        // get uncessary repeated
        yield put(saveBackorderItems(backorderItemsCopy));

        const backorderTrackedItems = {
          ...(yield select(selectBackorderTrackedItems)),
        };

        // add new followerd items
        trackedRes.data.data?.products?.forEach((item: AvailableProduct) => {
          backorderTrackedItems[item.catentryId] = item.id;
        });

        yield put(saveBackorderTrackedItems(backorderTrackedItems));
      } catch (error) {
        yield put(handleError(error));
      }
    }
  } catch (error) {
    if (error?.response?.status === 404) {
      yield put(clearCart());
      yield put(setIsCartEmpty(true));
    } else {
      yield put(clearCart());
      yield put(setIsCartEmpty(true));
      yield put(handleError(error));
    }
  } finally {
    //cancel delete loader
    const loading = yield select(selectCartLoading);

    for (let i = 0; i < loading.length; i++) {
      const currentLoading = loading[i];
      if (currentLoading.type === "delete") {
        yield put(stopCartLoading({ id: currentLoading.id, type: null }));
      }
    }
    //cancel precart loader
    yield put(stopCartLoading({ id: "cart", type: null }));
  }
}

/**
 * Update cart item
 *
 * @param {PayloadAction<UpdatePrecartItemPayload>} action
 * @return {*}  {SagaIterator}
 */
function* updatePrecartItemSaga(action: PayloadAction<UpdatePrecartItemPayload>): SagaIterator {
  const { callback, mainOrderItemId, doSimulate, ...payload } = action.payload;
  const id = mainOrderItemId ?? payload.orderItemId; //mainOrderItemId is used for AFA, for loading logic
  try {
    const servicePayload = getPutPrecartItemsPayload(payload as UpdatePrecartItemPayload);

    const { data } = yield call(cartService.putPrecartItems, servicePayload);
    callback?.(data?.message === "OK", data?.errors?.[0]?.code);
    if (doSimulate) yield call(cartService.simulatePrecart);
    yield put(getPrecart());

    // force update the precart count, if any quantity has been updated
    if (payload.quantity) yield put(getPrecartCount(true));
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
    callback?.(false, error?.response?.data?.errors?.[0]?.code);
    yield put(getPrecart());
  } finally {
    yield put(stopCartLoading({ id: id, type: null }));
  }
}

/**
 * Update cart items
 *
 * @param {PayloadAction<UpdatePrecartItemPayload>} action
 * @return {*}  {SagaIterator}
 */
function* updatePrecartItemsSaga(action: PayloadAction<UpdatePrecartItemsPayload>): SagaIterator {
  const { callback, mainOrderItemId, doSimulate } = action.payload;
  const id = mainOrderItemId; //mainOrderItemId is used for AFA, for loading logic
  try {
    const servicePayload = getPutPrecartItemsArrayPayload(action.payload);

    const { data } = yield call(cartService.putPrecartItems, servicePayload);
    callback?.(data?.message === "OK", data?.errors?.[0]?.code);
    if (doSimulate) yield call(cartService.simulatePrecart);
    yield put(getPrecart());

    // force update the precart count, if any quantity has been updated
    //PER ORA NON SERVE: if (payload.quantity) yield put(getPrecartCount(true));
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
    callback?.(false, error?.response?.data?.errors?.[0]?.code);
    yield put(getPrecart());
  } finally {
    yield put(stopCartLoading({ id: id, type: null }));
  }
}

function* getOtherSkusSaga(action: PayloadAction<GetSkuForVariantPayload>): SagaIterator {
  try {
    yield put(saveGetOtherSkusStatus("LOADING"));
    const { data } = yield call(catalogueService.getSkuForVariant, action.payload);

    if (data.data.catalogEntryView) {
      const skus = mapSkusArray(data.data.catalogEntryView);

      try {
        const skusId = skus.map((_) => _.uniqueID);
        const { data } = yield call(catalogueService.getProductAvailability, skusId);

        const doorsAvailability = data?.data?.doorInventoryAvailability;

        const singleDoorAvailability = doorsAvailability.find(
          (_: any) => _.door === action.payload.doorName
        );

        const afaSkus = skus.map((_) => {
          const availability = singleDoorAvailability?.inventoryAvailability.find(
            (av: any) => av.productId === _.uniqueID
          );
          return {
            productAvailable: availability?.availableQuantity ?? "",
            skuId: _.uniqueID,
            sku: _,
            sizeString: _.sizeString ?? "",
            firstShippingDate: availability.x_startShipDate,
          };
        });

        const orderedAFASkus = orderAFASize(afaSkus, "sizeString");

        yield put(
          saveOtherSkus({
            id: action.payload.doorId + action.payload.variantId,
            variantSkus: {
              skus: orderedAFASkus,
              doorId: action.payload.doorId,
              variantId: action.payload.variantId,
            },
          })
        );
      } catch (error) {
        console.log("[MYL]", error);
      }
    }

    yield put(saveGetOtherSkusStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveGetOtherSkusStatus("ERROR"));
  }
}

function* addDirectlyToPreCartSaga(
  action: PayloadAction<AddDirectlyToPreCartPayload>
): SagaIterator {
  const { callback } = action.payload;

  //MAP PAYLOAD

  yield put(setDisabledAddToCart(true));

  try {
    yield put(setAddToCartRequestStatus("LOADING"));
    const { data } = yield call(cartService.postPrecartItems, action.payload);
    yield put(saveOrderId(data?.data?.orderId)); // update orderId
    yield put(getPrecartCount(true)); // force update the precart count

    //simulate
    try {
      yield call(cartService.simulatePrecart);
    } catch (error) {
      yield put(handleError(error));
    }
    //update precart
    yield put(getPrecart());
    callback && callback(!!data?.data);

    yield put(setDisabledAddToCart(false));
    yield put(setAddToCartRequestStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(setDisabledAddToCart(false));
    callback && callback(false, error?.response?.data?.errors?.[0]?.code);
    yield put(setAddToCartRequestStatus("ERROR"));
  }
}

//////////////////////////////////////////////////////////////////////////////////
/////////////////// ADD TO PRECART PER VARIANT - PRODUCT RECOMMENDATION //////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Adds items to pre-cart
 *
 * @param {PayloadAction<any>} action
 * @return {*}  {SagaIterator}
 */
function* addItemsPerVariantToPrecartSaga(
  action: PayloadAction<AddItemsPerVariantToPrecartPayload>
): SagaIterator {
  const { callback, doSimulate } = action.payload;

  const orderId = yield select(selectOrderId);
  const precart: PreCartProduct[] = yield select(selectPreCart); // get precart
  const countCart = yield select(selectCountQuantity);

  const variant = action.payload.variant;
  if (variant?.skus) {
    const precartObjectsToAdd = intersectionBy(precart, variant?.skus, "partNumber");

    const payload = getPostPrecartItemsPayload(orderId, precartObjectsToAdd); // map precart into service payload
    yield put(setDisabledAddToCart(true));

    try {
      // yield put(setAddToCartLoading(true));
      yield put(setAddToCartRequestStatus("LOADING"));
      const { data } = yield call(cartService.postPrecartItems, payload);
      yield put(saveOrderId(data?.data?.orderId)); // update orderId
      yield put(getPrecartCount(true)); // force update the precart count

      yield put(removeItemsFromPrecart(precartObjectsToAdd));
      // yield put(getPrecart());
      try {
        if (doSimulate) yield call(cartService.simulatePrecart);

        if (countCart?.count === 0) yield put(getShippingInfo());
      } catch (error) {
        yield put(handleError(error));
      }
      callback && callback(!!data?.data);

      yield put(setDisabledAddToCart(false));
      yield put(setAddToCartRequestStatus("SUCCESS"));
    } catch (error) {
      yield put(handleError(error));
      yield put(setDisabledAddToCart(false));
      callback && callback(false, error?.response?.data?.errors?.[0]?.code);
      yield put(setAddToCartRequestStatus("ERROR"));
    } finally {
      // yield put(setAddToCartLoading(false));
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////// DELETING PRE-CART ORDER ITEMS ///////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Delete all items from pre-cart
 *
 * @return {*}  {SagaIterator}
 */
function* deletePrecartSaga(): SagaIterator {
  try {
    yield put(startCartLoading({ id: "multiple-delete", type: null }));
    yield call(cartService.deletePrecart);
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
  } finally {
    yield put(stopCartLoading({ id: "multiple-delete", type: null }));
    yield put(getPrecart());
    yield put(getPrecartCount(true)); // force update the precart count
  }
}

/**
 * Delete specified item from pre-cart
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* deletePrecartItemSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(startCartLoading({ id: action.payload, type: "delete" }));
    yield call(cartService.deletePrecartItems, {
      orderItemId: [action.payload],
    });
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
  } finally {
    yield put(getPrecart());
    // yield put(stopCartLoading({ id: action.payload, type: null }));
    yield put(getPrecartCount(true)); // force update the precart count
  }
}

/**
 * Delete specified item from pre-cart
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* deletePrecartItemsAFASaga(
  action: PayloadAction<DeletePrecartItemsAFAPayload>
): SagaIterator {
  try {
    yield put(startCartLoading({ id: action.payload.mainOrderItemId, type: "delete" }));
    yield call(cartService.deletePrecartItems, {
      orderItemId: action.payload.orderItemIds,
    });
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
  } finally {
    yield put(getPrecart());
    // yield put(stopCartLoading({ id: action.payload.mainOrderItemId, type: null }));
    yield put(getPrecartCount(true)); // force update the precart count
  }
}

/**
 * Delete entire category from pre-cart
 *
 * @param {PayloadAction<OrderItem[]>} action
 * @return {*}  {SagaIterator}
 */
function* deletePrecartCategorySaga(action: PayloadAction<OrderItem[]>): SagaIterator {
  try {
    yield put(startCartLoading({ id: "multiple-delete", type: null }));

    const orderItemId: string[] = [];

    action.payload.forEach((orderItem) => {
      if (orderItem?.orders && orderItem?.orders?.length > 0) {
        orderItem?.orders.forEach((_) => {
          //push ids of single afa order item
          orderItemId.push(_.orderItemId);
        });
      } else {
        //normal order item
        orderItemId.push(orderItem.orderItemId);
      }
    });

    yield call(cartService.deletePrecartItems, {
      orderItemId: orderItemId,
    });
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
  } finally {
    yield put(stopCartLoading({ id: "multiple-delete", type: null }));
    yield put(getPrecart());
    yield put(getPrecartCount(true)); // force update the precart count
  }
}

/**
 * Delete entire door from pre-cart
 *
 * @param {PayloadAction<OrderCategory[]>} action
 * @return {*}  {SagaIterator}
 */
function* deletePrecartMultidoorSaga(action: PayloadAction<OrderCategory[]>): SagaIterator {
  try {
    yield put(startCartLoading({ id: "multiple-delete", type: null }));

    const orderItemId: string[] = [];

    action.payload.forEach((orderCategory) => {
      orderCategory.orderItemList.forEach((orderItem) => {
        if (orderItem?.orders && orderItem?.orders?.length > 0) {
          orderItem?.orders.forEach((_) => {
            //push ids of single afa order item
            orderItemId.push(_.orderItemId);
          });
        } else {
          //normal order item
          orderItemId.push(orderItem.orderItemId);
        }
      });
    });

    yield call(cartService.deletePrecartItems, {
      orderItemId,
    });
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
  } finally {
    yield put(stopCartLoading({ id: "multiple-delete", type: null }));
    yield put(getPrecart());
    yield put(getPrecartCount(true)); // force update the precart count
  }
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// SHIPPING INFO ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get list of possible shipping addresses to display in summary
 *
 * @return {*}  {SagaIterator}
 */
function* getShippingInfoSaga(): SagaIterator {
  try {
    const { data } = yield call(cartService.getPrecartShippingInfo);

    const shippingAddressSummaryMultidoor = mapShippingAddressSummaryMultidoor(
      data?.data?.multidoorResponseList
    );
    if (shippingAddressSummaryMultidoor)
      yield put(saveShippingAddressList(shippingAddressSummaryMultidoor));
  } catch (error) {
    if (error?.response?.status === 404) {
    } else yield put(handleError(error));
  }
}

/**
 * Get list of possible shipping addresses to display in summary
 *
 * @return {*}  {SagaIterator}
 */
function* getEssilorShippingInfoSaga(): SagaIterator {
  try {
    const { data } = yield call(cartService.getPrecartShippingInfo, true);

    const shippingAddressSummaryMultidoor = mapShippingAddressSummaryMultidoor(
      data?.data?.multidoorResponseList
    );
    if (shippingAddressSummaryMultidoor)
      yield put(saveShippingAddressList(shippingAddressSummaryMultidoor));
  } catch (error) {
    if (error?.response?.status === 404) {
    } else yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////// MISC ///////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Update notes to customer service for given subOrder
 *
 * @param {PayloadAction<SubOrderPayload>} action
 * @return {*}  {SagaIterator}
 */
function* updatePrecartSaga(action: PayloadAction<SubOrderPayload>): SagaIterator {
  try {
    yield put(startCartLoading({ id: action.payload.subOrderId, type: "customer-service-notes" }));
    yield call(cartService.updatePrecart, action.payload);

    yield put(getPrecart());
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
    yield put(getPrecart());
  } finally {
    yield put(stopCartLoading({ id: action.payload.subOrderId, type: null }));
  }
}

/**
 * Get number of items currently in cart, to display in menu icon
 *
 * @param {PayloadAction<boolean>} action
 * @return {*}  {SagaIterator}
 */
function* getPrecartCountSaga(action: PayloadAction<boolean>): SagaIterator {
  try {
    const currentCount: CartCountQuantity = yield select(selectCountQuantity);

    // call is only made if at least 5 minutes have elapsed from the last one
    // unless a true value is passed as payload, which forces to ignore the elapsed time
    if (!currentCount || action.payload || exceedsMinutes(currentCount.dateSet as Date, 5)) {
      const { data } = yield call(cartService.getPrecartCount);

      if (data?.data) {
        yield put(
          saveCountQuantity({
            count: data?.data?.countQuantity,
            dateSet: new Date(),
          })
        );
      }
    }
  } catch (error) {
    if (error?.response?.status === 404) {
      yield put(
        saveCountQuantity({
          count: 0,
          dateSet: new Date(),
        })
      );
    } else yield put(handleError(error));
  }
}

/**
 * Simulate precart to get updates re: availability + shipping estimates
 *
 * @return {*}  {SagaIterator}
 */
function* simulatePrecartSaga(): SagaIterator {
  try {
    yield call(cartService.simulatePrecart);
    yield call(deleteOutOfStockPrecartSaga);
    yield put(getPrecart());
    yield put(getShippingInfo());
    yield put(getPrecartCount(true)); // force update the precart count
  } catch (error) {
    if (error?.response?.status === 404) {
      yield put(clearCart());
      yield put(setIsCartEmpty(true));
    } else yield put(handleError(error));
  }
}

/**
 * Clean out of stock items from pre-cart
 *
 * @return {*}  {SagaIterator}
 */
function* deleteOutOfStockPrecartSaga(): SagaIterator {
  try {
    const data = yield call(cartService.deleteOutOfStockPrecart);
    const label: string = data?.data?.data?.responseMessage;
    const partNumbers = mapOutOfStockPartNumbersArray(data?.data?.data?.partNumbers);
    if (label)
      if (partNumbers?.length) yield put(saveOutOfStockItems({ label, partNumbers }));
      else yield put(saveOutOfStockItems({ label, partNumbers: undefined }));
    else yield put(saveOutOfStockItems(null));
  } catch (error) {
    yield put(saveOutOfStockItems(null));
    yield put(handleError(error));
  }
}
//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// ALTERNATIVE ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get Cart alternative variants
 *
 *  * @param {PayloadAction<CartAlternativeVariantsPayload>}
 * @return {*}  {SagaIterator}
 */

function* getCartAlternativeVariantsSaga(
  action: PayloadAction<CartAlternativeVariantsPayload>
): SagaIterator {
  const PAGE_SIZE = 24;
  const MIN_VARIANTS_NUMBER = 12;
  const { orgentityName, ...payload } = action.payload;

  try {
    yield put(setCartAlternativeVariantsLoading(true));
    const { data } = yield call(cartService.getCartAlternativeVariants, {
      ...payload,
      pageNumber: 1,
      pageSize: PAGE_SIZE,
    });
    let alternativeVariants = mapVariantsArray(data?.data?.catalogEntryView);

    ////////////////////////////////////// get availability
    yield put(getProductAvailability({ ids: getSkuIdsFromVariants(alternativeVariants) }));
    const productAvailabilityAction = yield take(saveProductAvailability.type);
    const productAvailability: AvailabilityStatusByDoor = productAvailabilityAction.payload?.filter(
      (door: AvailabilityStatusByDoor) => door.doorId === orgentityName
    )?.[0]; // get availability for current door;

    ////////////////////////////////////// get only variants that have at least an available sku
    alternativeVariants = getAvailableAlternativeVariants(alternativeVariants, productAvailability);

    // save them
    yield put(saveCartAlternativeVariants(alternativeVariants));
    yield put(saveCartTotalAlternativeVariants(alternativeVariants.length));

    // if there aren't enough availabile variants to satisfy the requirement, make another call and repeat the process
    // standalone try-catch block so that if something goes wrong, at least we get to keep the previous variants
    if (alternativeVariants.length < MIN_VARIANTS_NUMBER) {
      try {
        const { data } = yield call(cartService.getCartAlternativeVariants, {
          ...payload,
          pageNumber: 2,
          pageSize: PAGE_SIZE,
        });

        let additionalAlternativeVariants = mapVariantsArray(data?.data?.catalogEntryView);

        ////////////////////////////////////// get availability
        yield put(
          getProductAvailability({ ids: getSkuIdsFromVariants(additionalAlternativeVariants) })
        );
        const productAvailabilityAction = yield take(saveProductAvailability.type);
        const productAvailability: AvailabilityStatusByDoor = productAvailabilityAction.payload?.filter(
          (door: AvailabilityStatusByDoor) => door.doorId === orgentityName
        )?.[0]; // get availability for current door;

        ////////////////////////////////////// get only variants that have at least an available sku
        additionalAlternativeVariants = getAvailableAlternativeVariants(
          additionalAlternativeVariants,
          productAvailability
        );

        // save them
        alternativeVariants = [...alternativeVariants, ...additionalAlternativeVariants];
        yield put(saveCartAlternativeVariants(alternativeVariants));
        yield put(saveCartTotalAlternativeVariants(alternativeVariants.length));
      } catch (error) {
        yield put(handleError(error));
      }
    }

    ////////////////////////////////////// get prices
    const isMultidoor = yield select(selectIsMultidoor);
    let hasPricePrivilege = false;
    if (isMultidoor) {
      hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
    } else {
      hasPricePrivilege = yield select(selectHasPricePrivilege);
    }
    if (hasPricePrivilege) {
      const priceIds: string[] = []; // get ids
      data?.data?.catalogEntryView.forEach((result: any) => {
        result.uniqueID && priceIds.push(result.uniqueID);
      });

      const { data: price } = yield call(catalogueService.getPriceService, priceIds);
      const priceData = mapPriceResponse(price?.data); // map response into a GetPriceResult[] array

      const newAlternativeVariants = cloneDeep(alternativeVariants);
      newAlternativeVariants.forEach((_) => addPriceToProductorVariant(_, priceData));

      yield put(saveCartAlternativeVariants(newAlternativeVariants));
    }
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(setCartAlternativeVariantsLoading(false));
  }
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////// M4C ///////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get M4C Custom Details
 *
 * @param {PayloadAction<CustomDetailsPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getCustomDetailsSaga(action: PayloadAction<CustomDetailsPayload>): SagaIterator {
  try {
    yield put(setDetailsStatus({ recipeId: action.payload.recipeId, status: true }));
    const { data } = yield call(cartService.getCustomDetails, action.payload);
    yield put(saveDetails({ details: data?.data, recipeId: action?.payload?.recipeId }));
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(setDetailsStatus({ recipeId: action.payload.recipeId, status: false }));
  }
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////// RX PRESCRIPTION BRASIL /////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get login token for RX Brasil
 *
 * @return {*}  {SagaIterator}
 */
function* loginPrescriptionBrasilSaga(): SagaIterator {
  let brasilToken = "";
  let attempts = 0;

  // try to login until token is found, or at most three times
  while (!brasilToken && attempts < 3) {
    attempts++;
    try {
      const { data } = yield call(cartService.loginPrescriptionBrasil);
      brasilToken = data?.data?.token;
    } catch (error) {}
  }

  yield put(setRxBrasilToken(brasilToken)); // either empty or the token, if login was successful
}

/**
 * Check whether prescription documents have already been uploaded for all RX order items in cart
 * NOTE - the requirements are:
 * - each login, if not successful, should be attempted again, at most 3 times
 * - if the check prescription API returns 401 using the supposed token, login should be attempted again, followed by the API request
 * - if no (successful) response is received from the login, a generic "NO_RESPONSE" error should be shown
 * - if a different error is received from check prescription, such error should be shown
 *
 * tldr: this is a nightmare but it's not my fault
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* checkPrescriptionBrasilSaga(action: PayloadAction<string[]>): SagaIterator {
  yield put(startCartLoading({ id: "check-upload-rx", type: null }));

  // get current status and set all specified items to "LOADING"
  const rxBrasilStatus: RxPrescriptionBrasilStatus[] = yield select(selectRxBrasilStatus);
  const updatedRxBrasilStatus = updateRxBrasilStatus(rxBrasilStatus, "LOADING", "", action.payload);
  yield put(setRxBrasilStatus(updatedRxBrasilStatus));

  const prescriptionPayload = getCheckPrescriptionBrasilPayload(action.payload);

  try {
    const savedToken = yield select(selectRxBrasilToken); // retrieve token from redux
    let brasilToken = savedToken;

    let attempts = 0; // only two attempts (and only if authentication has failed, otherwise errors are displayed)
    let success = false;
    let errorResult: unknown = undefined; // even if not successful, error could be set w/ service response, but also left empty ("NO_RESPONSE" message is used)

    while (!success && attempts < 2) {
      // NO TOKEN ALREADY AVAILABLE ---> attempt login
      if (!brasilToken) {
        yield put(loginPrescriptionBrasil()); // (at most 3 attempts, if all failed it will return token = "")
        const setRxBrasilTokenAction = yield take(setRxBrasilToken.type);
        brasilToken = setRxBrasilTokenAction.payload; // save new token
      }

      // if still not available, it means ALL ATTEMPTS AT LOGIN FAILED
      // ---> set ALL items to error, with "NO_RESPONSE" as message
      if (!brasilToken) break;
      else {
        // otherwise, EITHER TOKEN WAS ALREADY AVAILABLE OR SUBSEQUENT LOGIN WAS SUCCESSFUL
        // ---> check prescription for specified items
        let checkedRxBrasilStatus = updatedRxBrasilStatus;

        try {
          for (let i = 0; i < prescriptionPayload.length; i++) {
            // call check prescription
            const { data } = yield call(cartService.postCheckPrescriptionBrasil, {
              customerCode: prescriptionPayload[i]?.customerCode,
              orders: prescriptionPayload[i]?.orders,
              token: brasilToken,
            });

            checkedRxBrasilStatus = mapRxPrescriptionBrasilResult(
              data?.data,
              checkedRxBrasilStatus
            );
          }

          // save result
          yield put(setRxBrasilStatus(checkedRxBrasilStatus));
          success = true;
        } catch (error) {
          if (error?.response?.status === 401) {
            // IF SOMETHING WENT WRONG WITH AUTHENTICATION:
            // ---> try again login AND request
            brasilToken = "";
            attempts++;
          } else {
            // SOMETHING (ELSE) WENT WRONG
            // ---> set ALL items to error, with either the received error or "NO_RESPONSE" as message
            errorResult = error;
            break;
          }
        }
      }
    }

    // IF AT THE END PROCESS WASN'T SUCCESSFUL
    // ---> set ALL items to error, with either the received error or "NO_RESPONSE" as message, if it's not available
    if (!success)
      yield put(setRxBrasilStatus(getFailedRxBrasilStatus(updatedRxBrasilStatus, errorResult)));
  } catch (error) {
    // SOMETHING WENT SO WRONG WE ENDED UP HERE, INSTEAD
    // ---> set ALL items to error, with either the received error or "NO_RESPONSE" as message
    yield put(setRxBrasilStatus(getFailedRxBrasilStatus(updatedRxBrasilStatus, error)));
  } finally {
    yield put(stopCartLoading({ id: "check-upload-rx", type: null }));
  }
}

function* uploadPrescriptionBrasilSaga(
  action: PayloadAction<UploadPrescriptionSagaPayload>
): SagaIterator {
  // get current status and set current item to "LOADING"
  const rxBrasilStatus: RxPrescriptionBrasilStatus[] = yield select(selectRxBrasilStatus);
  const updatedRxBrasilStatus = updateRxBrasilStatus(rxBrasilStatus, "LOADING", "", [
    action.payload.orderCode,
  ]);
  yield put(setRxBrasilStatus(updatedRxBrasilStatus));

  try {
    const savedToken = yield select(selectRxBrasilToken); // retrieve token from redux
    let brasilToken = savedToken;

    let attempts = 0; // only two attempts (and only if authentication has failed, otherwise errors are displayed)
    let success = false;

    while (!success && attempts < 2) {
      // NO TOKEN ALREADY AVAILABLE ---> attempt call checkPrescription, which takes care of login + make sure checks are okay
      if (!brasilToken) {
        const allRxItems = yield select(selectRxBrasilCheckPayload);
        yield put(checkPrescriptionBrasil(allRxItems));
        const setRxBrasilTokenAction = yield take(setRxBrasilToken.type);
        brasilToken = setRxBrasilTokenAction.payload; // save new token ("" if failed)

        if (brasilToken) {
          // reset loading status that has been overwritten by checkPrescriptionBrasil
          // unless login has failed: in that case we need to keep the error response from checkPrescriptionBrasil
          yield put(
            setRxBrasilStatus(
              updateRxBrasilStatus(rxBrasilStatus, "LOADING", "", [action.payload.orderCode])
            )
          );
        }
      }

      // if still not available, it means ALL ATTEMPTS AT LOGIN FAILED
      // ---> errors have been handled by checkPrescriptionBrasil (all items w/ "NO_RESPONSE" as message)
      if (!brasilToken) break;
      else {
        // otherwise, EITHER TOKEN WAS ALREADY AVAILABLE OR SUBSEQUENT LOGIN + CHECK WAS SUCCESSFUL
        // --->  try and upload prescription for specified item
        try {
          const { data } = yield call(cartService.uploadPrescriptionBrasil, {
            ...action.payload,
            customerCode: action.payload.orderCode.split("/")?.[0],
            token: brasilToken,
          });

          if (data.code === 201) {
            // if response is positive, run checkPrescription on just specified items to get appropriate status
            yield put(checkPrescriptionBrasil([action.payload.orderCode]));
            success = true;
          } else {
            // otherwise set error based on response message
            yield put(
              setRxBrasilStatus(
                updateRxBrasilStatus(rxBrasilStatus, "ERROR", data.message, [
                  action.payload.orderCode,
                ])
              )
            );
          }
        } catch (error) {
          if (error?.response?.status === 401) {
            // IF SOMETHING WENT WRONG WITH AUTHENTICATION:
            // ---> try again login AND request
            brasilToken = "";
            attempts++;
          } else {
            // SOMETHING (ELSE) WENT WRONG
            // ---> set just the selected item to error, with either the received error or "NO_RESPONSE" as message
            yield put(
              setRxBrasilStatus(
                getFailedRxBrasilStatus(updatedRxBrasilStatus, error, [action.payload.orderCode])
              )
            );
            break;
          }
        }
      }
    }

    yield put(
      stopCartLoading({ id: action.payload.orderCode?.split("/")?.pop() ?? "", type: null })
    );
  } catch (error) {
    // SOMETHING WENT SO WRONG WE ENDED UP HERE, INSTEAD
    // ---> set just the selected item to error, with either the received error or "NO_RESPONSE" as message
    yield put(
      setRxBrasilStatus(
        getFailedRxBrasilStatus(updatedRxBrasilStatus, error, [action.payload.orderCode])
      )
    );
    yield put(
      stopCartLoading({ id: action.payload.orderCode?.split("/")?.pop() ?? "", type: null })
    );
  }
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////// SET DELIVERY //////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getDeliverySaga(action: PayloadAction<GetDeliveryPayload>): SagaIterator {
  try {
    yield put(saveGetDeliveryDatesStatus("LOADING"));
    const { data } = yield call(cartService.getDelivery, action.payload);

    const doors = data.data.doors;
    yield put(saveDeliveryDates(doors));
    yield put(saveGetDeliveryDatesStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveGetDeliveryDatesStatus("ERROR"));
    yield put(saveDeliveryDates([]));
  }
}

function* saveDeliverySaga(action: PayloadAction<SaveDeliveryPayload>): SagaIterator {
  try {
    yield put(saveSaveDeliveryDatesStatus("LOADING"));
    const { data } = yield call(cartService.saveDelivery, action.payload);
    yield put(saveSaveDeliveryDatesStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveSaveDeliveryDatesStatus("ERROR"));
  }
}

export function* cartSaga(): SagaIterator {
  yield takeLatest(addItemsToPrecart.type, addItemsToPrecartSaga);
  yield takeLatest(addItemsToEssilorCart.type, addItemsToEssilorCartSaga);
  yield takeEvery(addItemsPerVariantToPrecart.type, addItemsPerVariantToPrecartSaga);
  yield takeLatest(getPrecart.type, getPrecartSaga);
  yield takeLatest(deleteOutOfStockPrecart.type, deleteOutOfStockPrecartSaga);
  yield takeEvery(getOtherSkus.type, getOtherSkusSaga);
  yield takeEvery(addDirectlyToPreCart.type, addDirectlyToPreCartSaga);
  yield takeLatest(updatePrecartItem.type, updatePrecartItemSaga);
  yield takeEvery(updatePrecartItems.type, updatePrecartItemsSaga);
  yield takeLatest(deletePrecart.type, deletePrecartSaga);
  yield takeLatest(deletePrecartItem.type, deletePrecartItemSaga);
  yield takeEvery(deletePrecartItemsAFA.type, deletePrecartItemsAFASaga);
  yield takeLatest(deletePrecartCategory.type, deletePrecartCategorySaga);
  yield takeLatest(deletePrecartMultidoor.type, deletePrecartMultidoorSaga);
  yield takeLatest(getShippingInfo.type, getShippingInfoSaga);
  yield takeLatest(getEssilorShippingInfo.type, getEssilorShippingInfoSaga);
  yield takeEvery(getPrecartCount.type, getPrecartCountSaga);
  yield takeEvery(updatePrecart.type, updatePrecartSaga);
  yield takeEvery(simulatePrecart.type, simulatePrecartSaga);
  yield takeLatest(getCartAlternativeVariants.type, getCartAlternativeVariantsSaga);
  yield takeEvery(getCustomDetails.type, getCustomDetailsSaga);
  yield takeEvery(loginPrescriptionBrasil.type, loginPrescriptionBrasilSaga);
  yield takeEvery(checkPrescriptionBrasil.type, checkPrescriptionBrasilSaga);
  yield takeEvery(uploadPrescriptionBrasil.type, uploadPrescriptionBrasilSaga);
  yield takeEvery(getDelivery.type, getDeliverySaga);
  yield takeEvery(saveDelivery.type, saveDeliverySaga);
}
