import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../../../hooks/useTranslation";
import useCookies from "../../../hooks/useCookies";
import styled from "styled-components/macro";

import { getDocumentList } from "../../../store/documents/documentsSagas";
import { selectDocumentList } from "../../../store/documents/documentsSlice";
import { getDocumentFromType, replaceStringWithElement } from "../../../utils/utils";
import CustomText from "../../styled-UI/CustomText";
import Button from "../../styled-UI/Button";

const COOKIE_POLICY_DOC_TYPE = "cookie_policy";

const useCookiesPolicy = (): { openCookiesPolicy: () => void } => {
  const dispatch = useDispatch();

  const documentList = useSelector(selectDocumentList);
  const [cookiesPolicy, setCookiesPolicy] = useState<string>();

  // get cookie policy document when cookie banner is first opened
  useEffect(() => {
    const documentTypes = [COOKIE_POLICY_DOC_TYPE].toString();
    dispatch(getDocumentList(documentTypes));
  }, []);

  // save cookie policy document when API responds
  useEffect(() => {
    const newCookiesPolicy = getDocumentFromType(documentList ?? [], COOKIE_POLICY_DOC_TYPE)?.data
      ?.uri;
    if (newCookiesPolicy) setCookiesPolicy(newCookiesPolicy);
  }, [documentList]);

  // open cookie policy in new tab on link click
  const openCookiesPolicy = () => cookiesPolicy && window.open(cookiesPolicy, "_blank");

  return {
    openCookiesPolicy,
  };
};

interface Props {
  closeBanner: () => void;
}

const CookiesContent = ({ closeBanner }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const { setCookieWithoutToggle } = useCookies();
  const { openCookiesPolicy } = useCookiesPolicy();

  // helper ref to track whether cookies are set or not, to handle user navigating away without making a choice
  // NOTE: it's necessary to use a ref instead of a state, b/c a cleanup function captures the props as they were during mount and not unmount
  const areCookiesSetRef = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      // if cookies aren't already set when component is unmounting
      // (meaning we are navigating to a different page)
      if (!areCookiesSetRef.current) {
        setCookieWithoutToggle(false); // refuse cookies (ie. accept only technical)
        closeBanner();
      }
    };
  }, []);

  const acceptCookies = (accept: boolean) => {
    areCookiesSetRef.current = true;
    setCookieWithoutToggle(accept);
    closeBanner();
  };

  return (
    <Container>
      <LegalMessageWrapper>
        <CustomText
          as="span"
          font="font-regular"
          fontSizePx={14}
          color="white"
          lineHeightPx={24}
          isInline
        >
          {replaceStringWithElement(
            translateLabel("COOKIE_POLICY_BANNER_MESSAGE"),
            "COOKIE_POLICY_LINK",
            <CustomText
              as="span"
              font="font-regular"
              fontSizePx={14}
              color="white"
              lineHeightPx={24}
              onClick={openCookiesPolicy}
              isInline
              isClickable
              underline
            >
              {translateLabel("COOKIE_POLICY_BANNER_LINK")}
            </CustomText>
          )}
        </CustomText>
      </LegalMessageWrapper>

      <ButtonSection>
        <Button
          type="secondary"
          onClick={() => acceptCookies(false)} // refuse cookies (ie. accept only technical)
        >
          {translateLabel("COOKIE_POLICY_BANNER_REFUSE")}
        </Button>
        <Button
          type="secondary"
          onClick={() => acceptCookies(true)} // accept cookies
          underline
        >
          {translateLabel("COOKIE_POLICY_BANNER_ACCEPT")}
        </Button>
      </ButtonSection>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0rem;
  background-color: ${(props) => props.theme.palette.primary};
  z-index: 500;
  padding: 1rem 2rem;
`;

const LegalMessageWrapper = styled.div`
  padding-bottom: 1rem;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  column-gap: 1rem;
`;

export default CookiesContent;
