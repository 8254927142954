import React from "react";
import styled from "styled-components/macro";
import clsx from "clsx";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  color?: "primary" | "white";
  customColor?: string;
  customFont?: FontFamilies | null;
  children: any;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  uppercase?: boolean;
  bold?: boolean;
  underline?: boolean;
  disabled?: boolean;
  "data-element-id"?: string | null;
  "data-description"?: string | null;
  as?: "button" | "div";
  keepStyleWhenDisabled?: boolean;
  opacity?: number;
  marginTopUnderlinePx?: number;
}

const LinkButton = ({
  color = "primary",
  customColor,
  customFont = null,
  children,
  onClick,
  uppercase = false,
  bold = false,
  underline = true,
  disabled = false,
  "data-element-id": dataElementId,
  "data-description": dataDescription,
  as = "button",
  keepStyleWhenDisabled = false,
  opacity,
  marginTopUnderlinePx,
}: Props): JSX.Element => {
  const classNames = clsx(
    color === "primary" && "link-button-primary",
    color === "white" && "link-button-white",
    uppercase && "link-button-uppercase",
    bold && "link-button-bold",
    underline && "link-button-underline",
    disabled && !keepStyleWhenDisabled && "disabled"
  );
  return (
    <StyledButton
      {...(as === "button" && { disabled: disabled })}
      className={classNames}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        disabled || (onClick && onClick(e));
      }}
      as={as ?? "button"}
      {...(dataElementId ? { "data-element-id": dataElementId } : {})}
      {...(dataDescription ? { "data-description": dataDescription } : {})}
      customColor={customColor && customColor != "No Colour" ? customColor : ""}
      customFont={customFont}
      opacity={opacity}
      marginTopUnderlinePx={marginTopUnderlinePx}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button<{
  as: string;
  customColor?: string;
  customFont: FontFamilies | null;
  opacity?: number;
  marginTopUnderlinePx?: number;
}>`
  font-size: 0.875rem;
  line-height: 1.125rem;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  font-family: ${(props) =>
    props.customFont
      ? props.customFont + " " + props.theme.fonts.customMedium
      : props.theme.fonts.fontMedium};

  &.link-button-primary {
    color: ${(props) => props.theme.palette.primary};

    &:hover {
      color: ${(props) => props.theme.palette.primary};
    }
  }

  &.link-button-white {
    color: ${(props) => props.theme.palette.white};

    &:hover {
      color: ${(props) => props.theme.palette.white};
    }
  }

  &.link-button-uppercase {
    text-transform: uppercase;
  }

  &.link-button-bold {
    font-family: ${(props) =>
      props.customFont
        ? props.customFont + " " + props.theme.fonts.customBold
        : props.theme.fonts.fontBold};
  }

  &.link-button-underline {
    &:after {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      background-color: currentColor;
      ${(props) => props.marginTopUnderlinePx && `margin-top: ${props.marginTopUnderlinePx}px`}
    }
  }

  &.disabled {
    color: ${(props) => props.theme.palette.gray.medium};
    ${(props) => props.opacity && `opacity: ${props.opacity}`};

    &:hover {
      color: ${(props) => props.theme.palette.gray.medium};
    }
  }
  ${(props) => props.customColor && `color: ${props.customColor}!important`}
`;

export default LinkButton;
