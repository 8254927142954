import { Page, Row } from "../interfaces/gridInterfaces";

export const initializeGrid = (pageString: string, page: any): Page => {
  const rows: Row[] = [];
  const grid = page.grid;

  if (grid && grid.rows) {
    for (let i = 0; i < grid.rows.length; i++) {
      const row = grid.rows[i];
      if (row && row.placements && row.placements.length > 0) {
        rows.push({
          rowNumber: i,
          layout: row.placements[0].viewtype,
          name: row.placements[0].name,
          cols: row.placements[0].items,
        });
      }
    }
  }

  return {
    // metadata: { root: (grid && grid.id) || undefined },
    page: pageString,
    pageLayout: grid.pageLayout,
    title: page.title,
    id: page.id,
    backgroundImage: page.backgroundImage,
    backgroundColor: page.backgroundColour,
    font: page.font,
    subjectTaxonomy: page.subjectTaxonomy,
    rows,
  };
};
