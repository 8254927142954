import React, { useState, useEffect } from "react";
import useTranslation from "../../../hooks/useTranslation";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { useDebouncedEffect } from "../../../hooks/useDebouncedEffect";

import {
  selectAddToCartLoading,
  selectCartAlternativeVariantsLoading,
} from "../../../store/cart/cartSlice";
import { addItemsToPrecart, deletePrecartItem } from "../../../store/cart/cartSagas";
import { OrderItem } from "../../../store/cart/cartInterfaces";
import {
  selectPreCart,
  selectProductAvailability,
  selectTotalInPreCart,
} from "../../../store/catalogue/catalogueSlice";
import { Variant } from "../../../interfaces/productInterface";
import { Door } from "../../../store/user/userInterfaces";
import { trackEvent } from "../../../store/analytics/analyticsSaga";
import { getProductsDataFromVariant } from "../../../store/analytics/analyticsUtility";
import { renderCartError } from "../../../utils/cartUtils";
import { splitArrayInChunks } from "../../../utils/utils";
import LoaderVariantCart from "../../styled-UI/loader/cart/LoaderVariantCart";
import CustomText from "../../styled-UI/CustomText";
import Button from "../../styled-UI/Button";
import Tile from "../Tile/Tile";
import { useBreakpoint } from "../../../assets/theme/breakpoint";
import useCanRender from "../../../hooks/useCanRender";

interface Props {
  alternativeVariants: Variant[] | null;
  orderItem: OrderItem;
  currentDoor: Door;
  currentPage: number;
}

const CartAlternativePopupVariants = ({
  alternativeVariants,
  orderItem,
  currentDoor,
  currentPage,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();

  const alternativeVariantsLoading = useSelector(selectCartAlternativeVariantsLoading); //loader
  const preCart = useSelector(selectPreCart);
  const addToCartLoading = useSelector(selectAddToCartLoading);
  const total = useSelector(selectTotalInPreCart);
  const breakpoints = useBreakpoint();
  const canRender = useCanRender();

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// FAKE PAGINATION //////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const [paginatedVariants, setPaginatedVariants] = useState<Variant[][]>([]);

  useEffect(() => {
    const pageSize = breakpoints["md"] ? 3 : 4; // TODO: make dependant on breakpoint

    if (!alternativeVariants) setPaginatedVariants([]);
    else setPaginatedVariants(splitArrayInChunks(alternativeVariants, pageSize));
  }, [alternativeVariants, breakpoints]);

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////// ADDING TO CART //////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const [message, setMessage] = useState<{ text: string; status: string }>({
    text: "",
    status: "",
  });

  // add to cart new variant and delete product not available
  const handleUpdateCart = () => {
    dispatch(
      trackEvent({
        id: "AddToCart",
      })
    );

    dispatch(
      addItemsToPrecart({
        callback: (success: boolean, errorCode?: string) => {
          if (success) {
            dispatch(deletePrecartItem(orderItem.orderItemId));
            setMessage({
              text: translateLabel("FIND_AN_ALTERNATIVE_VARIANT_PRODUCTS_ADDED"),
              status: "success",
            });
          } else
            errorCode &&
              setMessage({ text: renderCartError(errorCode, translateLabel), status: "error" });
        },
        doSimulate: true,
      })
    );
  };

  //////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////// ANALYTICS /////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const productAvailability = useSelector(selectProductAvailability);

  useDebouncedEffect(
    () => {
      if (!alternativeVariantsLoading && alternativeVariants && productAvailability.length)
        dispatch(
          trackEvent({
            id: "QuickViewOverlay",
            Products: getProductsDataFromVariant(
              alternativeVariants,
              currentDoor,
              productAvailability,
              "",
              canRender("STARS")
            ),
          })
        );
    },
    [alternativeVariantsLoading, alternativeVariants, productAvailability],
    3000
  );

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////// RENDER //////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      <AlternativeSection>
        {(alternativeVariantsLoading || !alternativeVariants?.length) && <LoaderVariantCart />}

        {!alternativeVariantsLoading &&
          paginatedVariants?.[currentPage - 1]?.map((item) => {
            return (
              <AlternativeContainer key={item.uniqueID}>
                <InnerBoxTile className={orderItem.sku.uniqueID === item.uniqueID ? "active" : ""}>
                  <Tile data={item} noLinkImg showCodeInVariant currentDoor={currentDoor} />
                </InnerBoxTile>
              </AlternativeContainer>
            );
          })}
      </AlternativeSection>

      {!alternativeVariantsLoading && alternativeVariants?.length && (
        <OuterBoxButton>
          <BoxButton>
            <Button
              type="primary"
              onClick={() => handleUpdateCart()}
              disabled={!preCart?.length}
              isLoading={addToCartLoading}
              data-element-id="Products_FindAlternative_Confirm"
            >
              {translateLabel("ADD_TO_CART") + " (" + total + ")"}
            </Button>
          </BoxButton>
          {message?.text?.length > 0 && (
            <BoxText>
              <CustomText as="span" fontSizePx={18} color="primary">
                {message.text}
              </CustomText>
            </BoxText>
          )}
        </OuterBoxButton>
      )}
    </div>
  );
};

//#region Style
const AlternativeSection = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-height: calc(100vh - 85px - 77px - 3rem);
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
  overflow-y: auto;
  overflow-x: hidden;
`;

const AlternativeContainer = styled.div`
  border-right: 1px solid ${(props) => props.theme.palette.gray.medium};
  display: flex;
  justify-content: center;
`;

const InnerBoxTile = styled.div`
  &.active {
    border: 1px solid ${(props) => props.theme.palette.primary};
    border-radius: 4px;
  }
`;

const OuterBoxButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 2.5rem 0;
  position: relative;

  @media ${(props) => props.theme.queries.md} {
    margin: 0.5rem 0;
  }
`;

const BoxButton = styled.div`
  display: flex;
  align-items: center;
`;

const BoxText = styled.div`
  position: absolute;
  bottom: 4.5rem;
`;

export default CartAlternativePopupVariants;
