import React from "react";
import styled from "styled-components";
import CustomText from "../../../styled-UI/CustomText";
import { ReactComponent as Unselected } from "../../../../assets/icons/radio-button-unselected.svg";
import { ReactComponent as Selected } from "../../../../assets/icons/radio-button-selected.svg";
import clsx from "clsx";
import { PersonificationCustomer } from "../../../../store/user/userInterfaces";

interface Props {
  customer: PersonificationCustomer;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

const PersonificationUserRadio = React.forwardRef<HTMLInputElement, Props>(
  ({ customer, checked, onChange }, forwardedRef) => {
    return (
      <UserBox>
        <RadioButton>
          <input
            type="radio"
            ref={forwardedRef}
            value={customer.orgentityId}
            name="personificationUser"
            checked={checked}
            onChange={onChange}
          />
          <IconContainer className={clsx("radiobutton-selected")}>
            <Selected />
          </IconContainer>
          <IconContainer className={clsx("radiobutton-unselected")}>
            <Unselected />
          </IconContainer>
        </RadioButton>
        <UserInfo>
          <CustomText
            as="span"
            fontSizePx={18}
            lineHeightPx={18}
            font="font-bold"
            marginBottomPx={4}
          >
            {customer.orgentityName}
            {customer.name ? " - " + customer.name : ""}
          </CustomText>
          <CustomText
            as="span"
            fontSizePx={14}
            lineHeightPx={24}
            font="font-regular"
            color="gray-dark"
          >
            {customer.address1}
          </CustomText>
          <CustomText
            as="span"
            fontSizePx={14}
            font="font-regular"
            lineHeightPx={24}
            color="gray-dark"
          >
            {customer.city} {customer.country ? " - " + customer.country : ""}
          </CustomText>
        </UserInfo>
      </UserBox>
    );
  }
);

const UserBox = styled.label`
  display: flex;
  padding: 2rem 0;
  margin: 0 2rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};

  &:last-child {
    border-bottom: 0;
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  height: 1.25rem;
  width: 1.25rem;

  svg {
    height: 1.25rem;
    width: 1.25rem;
    fill: ${(props) => props.theme.palette.gray.dark};
    &:hover {
      fill: ${(props) => props.theme.palette.primary};
    }
  }

  &.radiobutton-selected {
    display: none;
    svg {
      fill: ${(props) => props.theme.palette.primary};
    }
  }
`;

const RadioButton = styled.div`
  display: flex;
  margin-right: 1rem;

  input:checked ~ .radiobutton-selected {
    display: inline-block;
  }

  input:checked ~ .radiobutton-unselected {
    display: none;
  }

  input {
    display: none;
  }
`;

PersonificationUserRadio.displayName = "PersonificationUserRadio";
export default PersonificationUserRadio;
