import React from "react";
import styled from "styled-components/macro";
import Button from "../../styled-UI/Button";
import CustomText from "../../styled-UI/CustomText";
import { FeatureCarouselVerticalItem } from "../../../interfaces/cmsInterfaces";
import { getMultiMedia, useHandleCmsLink } from "../../../utils/cmsUtils";
import useDataHref from "../../../hooks/useDataHref";
import SetInnerHTMLWithSanitize from "../SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  isDarkMode?: boolean;
  item: FeatureCarouselVerticalItem;
  customFont?: FontFamilies | null;
}

const FeatureVerticalSlide = ({
  isDarkMode = false,
  item,
  customFont = null,
}: Props): JSX.Element => {
  const {
    multiMediaType,
    description,
    description1Colour,
    linkLabel,
    title,
    titleColour,
    linkString,
    externalLink,
    openInNewTab,
  } = item;
  const { handleCmsLink } = useHandleCmsLink();
  const { srcImg } = getMultiMedia(multiMediaType);

  const descrWithHref = useDataHref({ content: description });
  const handleRedirect = (): void => {
    handleCmsLink(linkString, externalLink, openInNewTab);
  };

  return (
    <Slide>
      <ImgContainer>
        <img src={srcImg} />
      </ImgContainer>
      <div style={{ width: "60%" }}>
        <DescriptionContainer>
          <CustomText
            as="p"
            font={customFont ? "custom-regular" : "font-regular"}
            fontFamily={customFont}
            fontSizePx={50}
            lineHeightPx={50}
            color={isDarkMode ? "white" : "primary"}
            customColor={titleColour}
          >
            {title}
          </CustomText>
          <CustomText
            as="p"
            font={customFont ? "custom-light" : "font-light"}
            fontFamily={customFont}
            fontSizePx={20}
            lineHeightPx={26}
            marginTopPx={16}
            marginBottomPx={50}
            color={isDarkMode ? "white" : "primary"}
            customColor={description1Colour}
          >
            <SetInnerHTMLWithSanitize text={descrWithHref?.updatedContent ?? description} />
          </CustomText>
          {linkLabel && linkString && (
            <Button
              type={isDarkMode ? "primary-dark" : "primary"}
              onClick={handleRedirect}
              customFont={customFont}
            >
              {linkLabel}
            </Button>
          )}
        </DescriptionContainer>
      </div>
    </Slide>
  );
};

const Slide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgContainer = styled.div`
  width: 40%;
  img {
    width: 100%;
  }
`;

const DescriptionContainer = styled.div`
  margin-left: 3.75rem;
  width: 60%;
`;

export default FeatureVerticalSlide;
