import React, { useEffect } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";

import CustomText from "../styled-UI/CustomText";
import LanguageSelector from "../widgets/LanguageSelector";
import Logo from "./Logo";
import { getPagePath } from "../../routing/routesUtils";
import { useHistory } from "react-router-dom";
import { selectSelectedTile } from "../../store/catalogue/catalogueSlice";
import { useDispatch, useSelector } from "react-redux";
import ConditionalRender from "../widgets/ConditionalRender";
import { selectChatUrl, selectContactInfo } from "../../store/store/storeSlice";
import { getContactInfo } from "../../store/store/storeSagas";
import { selectLanguageId } from "../../store/user/userSlice";
import { selectChinaSpecificWebDomain } from "../../store/store/storeSlice";
import useCanRender from "../../hooks/useCanRender";

const FooterComponent = (): JSX.Element => {
  const { translateLabel } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedTile = useSelector(selectSelectedTile);
  const chatUrl = useSelector(selectChatUrl);
  const contactInfo = useSelector(selectContactInfo);
  const currentLanguageId = useSelector(selectLanguageId);
  const chinaSpecificWebDomain = useSelector(selectChinaSpecificWebDomain);
  const canRender = useCanRender();

  useEffect(() => {
    if (currentLanguageId && Object.keys(contactInfo).length === 0)
      dispatch(getContactInfo({ languageId: currentLanguageId }));
  }, []);

  return !selectedTile ? (
    <Footer id="sticky-footer">
      <TopSection className="row g-0">
        <div className="col-3">
          <FooterSectionTitle className="logo">
            <Logo color="white" heightPx={27} isLink={true} />
          </FooterSectionTitle>
          <ul>
            {contactInfo?.address1 && (
              <li>
                <CustomText
                  as="span"
                  font="font-regular"
                  fontSizePx={14}
                  color="text-blue"
                  lineHeightPx={24}
                >
                  {contactInfo.address1}
                </CustomText>
              </li>
            )}
            {contactInfo?.address2 && (
              <li>
                <CustomText
                  as="span"
                  font="font-regular"
                  fontSizePx={14}
                  color="text-blue"
                  lineHeightPx={24}
                >
                  {contactInfo.address2}
                </CustomText>
              </li>
            )}
            {contactInfo?.address3 && (
              <li>
                <CustomText
                  as="span"
                  font="font-regular"
                  fontSizePx={14}
                  color="text-blue"
                  lineHeightPx={24}
                >
                  {contactInfo.address3}
                </CustomText>
              </li>
            )}
            <li>
              <SelectWrapper>
                <LanguageSelector />
              </SelectWrapper>
            </li>
          </ul>
        </div>
        <div className="col-2">
          <FooterSectionTitle>
            <CustomText as="p" font="font-bold" fontSizePx={18} lineHeightPx={24} color="white">
              {translateLabel("LEGAL")}
            </CustomText>
          </FooterSectionTitle>
          <ul>
            <li>
              <CustomText
                as="button"
                font="font-regular"
                fontSizePx={14}
                color="text-blue"
                lineHeightPx={24}
                onClick={() => history.push(getPagePath("/account/contracts"))}
                data-element-id="Footer_Legal_PrivacyPolicy"
              >
                {translateLabel("PRIVACY_POLICY")}
              </CustomText>
            </li>
            <li>
              <CustomText
                as="button"
                font="font-regular"
                fontSizePx={14}
                color="text-blue"
                lineHeightPx={24}
                onClick={() => history.push(getPagePath("/account/contracts"))}
                data-element-id="Footer_Legal_PrivacyPolicy"
              >
                {translateLabel("TERMS_CONDITIONS")}
              </CustomText>
            </li>
            <li>
              <CustomText
                as="button"
                font="font-regular"
                fontSizePx={14}
                color="text-blue"
                onClick={() => history.push(getPagePath("/account/contracts"))}
                data-element-id="Footer_Legal_CookiePolicy"
                lineHeightPx={24}
              >
                {translateLabel("COOKIE_POLICY")}
              </CustomText>
            </li>
          </ul>
        </div>
        <div className="col-2">
          <FooterSectionTitle>
            <CustomText as="p" fontSizePx={18} font="font-bold" color="white">
              {translateLabel("SUPPORT")}
            </CustomText>
          </FooterSectionTitle>
          <ul>
            <li>
              <CustomText
                as="button"
                font="font-regular"
                fontSizePx={14}
                color="text-blue"
                lineHeightPx={24}
                onClick={() => history.push(getPagePath("/faq"))}
                data-element-id="Footer_Support_Faq"
              >
                {translateLabel("FAQ")}
              </CustomText>
            </li>
            <li>
              <CustomText
                as="button"
                font="font-regular"
                fontSizePx={14}
                color="text-blue"
                lineHeightPx={24}
                onClick={() => history.push(getPagePath("/contents-corporate/tutorial"))}
                data-element-id="Footer_Support_Tutorials"
              >
                {translateLabel("TUTORIALS_GUIDES")}
              </CustomText>
            </li>
          </ul>
        </div>
        <div className="col-3">
          <FooterSectionTitle>
            <CustomText as="p" fontSizePx={18} font="font-bold" color="white">
              {translateLabel("CUSTOMER_SERV")}
            </CustomText>
          </FooterSectionTitle>
          <ul>
            {contactInfo?.phone && (
              <li>
                <CustomText
                  as="span"
                  font="font-regular"
                  fontSizePx={14}
                  color="text-blue"
                  lineHeightPx={24}
                >
                  {`${translateLabel("CUSTOMER_SERV_PHONE_TITLE")} ${contactInfo.phone}`}
                </CustomText>
              </li>
            )}
            {contactInfo?.email && (
              <li>
                <CustomText
                  as="span"
                  font="font-regular"
                  fontSizePx={14}
                  color="text-blue"
                  lineHeightPx={24}
                >
                  {`${translateLabel("CUSTOMER_SERV_MAIL_TITLE")} ${contactInfo.email}`}
                </CustomText>
              </li>
            )}
            {contactInfo?.hours && (
              <li>
                <CustomText
                  as="span"
                  font="font-regular"
                  fontSizePx={14}
                  color="text-blue"
                  lineHeightPx={24}
                >
                  {contactInfo.hours}
                </CustomText>
              </li>
            )}
          </ul>
        </div>
        {(canRender("SUGGESTED_RETAIL_PRICE") || canRender("RX_MY_PRICE_SRP")) && (
          <div className="col-2">
            <FooterSectionTitle>
              <CustomText as="p" fontSizePx={18} font="font-bold" color="white">
                {translateLabel("SRP_DISCLAIMER_TITLE")}
              </CustomText>
            </FooterSectionTitle>
            <CustomText
              as="span"
              font="font-regular"
              fontSizePx={14}
              color="text-blue"
              lineHeightPx={24}
            >
              {translateLabel("SRP_DISCLAIMER_DESCRIPTION")}
            </CustomText>
          </div>
        )}
      </TopSection>
      <BottomSection>
        <BottomSectionContent>
          <CustomText as="p" font="font-regular" fontSizePx={11} color="text-blue">
            {`${translateLabel(
              chinaSpecificWebDomain ? "COPYRIGHT_CHINA" : "COPYRIGHT"
            )} ${translateLabel("BROWSER_NOTE")}`}
          </CustomText>
        </BottomSectionContent>
      </BottomSection>
    </Footer>
  ) : (
    <></>
  );
};

const Footer = styled.footer`
  background-color: ${(props) => props.theme.palette.primary};
  max-width: 100vw;
`;

const FooterSectionTitle = styled.div`
  padding-bottom: 2.5rem;

  &.logo {
    padding-bottom: 2.25rem;
  }
`;

const TopSection = styled.div`
  padding: 4.625rem 5.3125rem 0;
  max-width: ${(props) => props.theme.maxWrapperWidth};
  margin: 0 auto;

  @media ${(props) => props.theme.queries.md} {
    padding: 3rem 1.5rem 0;
  }
`;

const BottomSection = styled.div`
  margin-top: 2.625rem;
  border-top: solid 1px ${(props) => props.theme.palette.bordersBlue};
`;

const BottomSectionContent = styled.div`
  padding: 1.3125rem 5.3125rem;
  max-width: ${(props) => props.theme.maxWrapperWidth};
  margin: 0 auto;

  @media ${(props) => props.theme.queries.md} {
    padding: 1.3125rem 1.5rem;
  }
`;

const SelectWrapper = styled.div`
  width: 7.5rem;
  height: 2.5rem;
  margin-top: 2.75rem;
  margin-bottom: 1.25rem;
`;

export default FooterComponent;

export const MemoizedFooterComponent = React.memo(FooterComponent);
