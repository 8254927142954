import React, { useState } from "react";
import styled from "styled-components/macro";

import clsx from "clsx";

import useDataHref from "../../hooks/useDataHref";

import { getMultiMedia } from "../../utils/cmsUtils";

import CustomText from "../styled-UI/CustomText";
import VideoPopup from "../styled-UI/VideoPopup";
import IconButton from "../styled-UI/IconButton";

import { ReactComponent as PlayIcon } from "../../assets/icons/play-icon.svg";

import { LpVideoBanner as Interface } from "../../interfaces/cmsInterfaces";
import SetInnerHTMLWithSanitize from "./SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  size: "small" | "full";
  data: Interface;
  customFont?: FontFamilies | null;
}

const LpVideoBanner = ({ size, data, customFont = null }: Props): JSX.Element => {
  const {
    title,
    titleColour,
    description,
    description1Colour,
    description2,
    description2Colour,
    multiMediaType,
  } = data;
  const { coverImg, srcVideo, contentType } = getMultiMedia(multiMediaType);
  const descrWithHref = useDataHref({ content: description });
  const descr2WithHref = useDataHref({ content: description2 });

  const [showVideo, setShowVideo] = useState<boolean>(false);
  return (
    <Container
      className={clsx(size === "small" && "small", size === "full" && "full")}
      srcImg={coverImg}
    >
      <InnerContent>
        <TextBox>
          {title && (
            <CustomText
              as="span"
              font={customFont ? "custom-regular" : "font-regular"}
              fontFamily={customFont}
              fontSizePx={18}
              textAlign="center"
              marginBottomPx={15}
              color="white"
              customColor={titleColour}
            >
              {title}
            </CustomText>
          )}
          {description && (
            <CustomText
              as="span"
              font={customFont ? "custom-regular" : "font-regular"}
              fontFamily={customFont}
              fontSizePx={50}
              textAlign="center"
              lineHeightPx={50}
              marginBottomPx={22}
              color="white"
              customColor={description1Colour}
            >
              <SetInnerHTMLWithSanitize text={descrWithHref.updatedContent ?? description} />
            </CustomText>
          )}
          {description2 && (
            <CustomText
              as="span"
              font={customFont ? "custom-regular" : "font-regular"}
              fontFamily={customFont}
              fontSizePx={18}
              textAlign="center"
              marginBottomPx={15}
              color="white"
              customColor={description2Colour}
            >
              <SetInnerHTMLWithSanitize text={descr2WithHref.updatedContent ?? description2} />
            </CustomText>
          )}
        </TextBox>
        {!contentType?.includes("/gif") && (
          <>
            <OuterButton>
              {srcVideo && (
                <IconButton
                  type="white-no-border"
                  size="xl"
                  icon={<PlayIcon />}
                  onClick={() => setShowVideo(true)}
                  data-description={srcVideo}
                />
              )}
            </OuterButton>
            {srcVideo && (
              <VideoPopup
                isOpen={showVideo}
                close={() => setShowVideo(false)}
                srcVideo={srcVideo}
              />
            )}
          </>
        )}
        {contentType?.includes("/gif") && (
          <GifContainer>
            <img src={srcVideo} />
          </GifContainer>
        )}
      </InnerContent>
    </Container>
  );
};
const Container = styled.div<{ srcImg?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.srcImg && props.srcImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &.small {
    height: 100%;
  }

  &.full {
    height: fit-content;
  }
`;
const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const OuterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.875rem;
`;
const GifContainer = styled.div`
  margin-top: 2.875rem;
  img {
    width: 100%;
  }
`;
export default LpVideoBanner;
