import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  saveDetails,
  saveDevices,
  savePopupNotFoundItems,
  saveStagingOrders,
  saveUnavaiableItemsInfo,
  selectStagingOrders,
  setConfirmationStatus,
  setDetailsStatus,
  setDevicesLoading,
  setEditPinLoading,
  setEditPinStatus,
  setLoadingStatus,
  setOrderItemId,
  setSelectedItemIdInCart,
  // setSelectedItemIdInCart,
  setStagingOrderCount,
  setStagingOrderDeleteStatus,
  setStagingOrderNoteLoading,
  setStagingOrderNoteStatus,
  setStagingOrdersLoading,
  sliceName,
} from "./smartshopperSlice";
import smartshopperService from "./smartshopperService";
import { handleError } from "../store/storeSagas";
import {
  CartProductPayload,
  ConfimationPayload,
  CustomDetailsPayload,
  DevicesPayload,
  EditPinPayload,
  Items,
  StagingOrder,
  StagingOrderNotePayload,
  StagingOrdersItems,
  StagingOrdersItemsToDeletePayload,
  StagingOrdersPayload,
} from "./smartshopperInterfaces";
import { createFilters, saveUnavailableInfoInStagingOrder } from "../../utils/smartShopperUtils";

import { getPrecartCount } from "../cart/cartSagas";
import cartService from "../cart/cartService";
import { selectOrderId } from "../cart/cartSlice";
import { getPostPrecartItemsPayload } from "../../utils/cartUtils";
import { M4CPreCartProduct } from "../catalogue/catalogueInterface";

/* ACTIONS */

/////// GET
// STAGING ORDERS
export const getStagingOrders = createAction<StagingOrdersPayload>(sliceName + "/stagingOrders");
export const getStagingOrdersCount = createAction(sliceName + "/getStagingOrdersCount");
export const getCustomDetails = createAction<CustomDetailsPayload>(sliceName + "/getCustomDetails");

// DEVICES
export const getDevices = createAction<DevicesPayload>(sliceName + "/getDevices");
/////// POST
export const addItemsToCart = createAction<CartProductPayload>(sliceName + "/addItemsTocart");
// EDIT PIN
export const editPin = createAction<EditPinPayload>(sliceName + "/editPin");
/////// PUT
export const putStagingOrderNote = createAction<StagingOrderNotePayload>(
  sliceName + "/putStagingOrderNote"
);
export const putStagingOrderConfirmation = createAction<ConfimationPayload>(
  sliceName + "/putStagingOrderConfirmation"
);

////// DELETE
export const deleteStaginOrderItems = createAction<StagingOrdersItemsToDeletePayload>(
  sliceName + "/deleteStaginOrderItems"
);
export const deleteAllStaginOrderItems = createAction(sliceName + "/deleteAllStaginOrderItems");

/* SAGAS */
//////// GET STAGING ORDERS
function* getStagingOrdersSaga(action: PayloadAction<StagingOrdersPayload>): SagaIterator {
  try {
    yield put(setStagingOrdersLoading("LOADING"));
    const { data } = yield call(smartshopperService.getStagingOrders, action.payload);
    const { filters } = data.data;
    const brand = createFilters(filters, "brand");
    const device = createFilters(filters, "device");
    const category = createFilters(filters, "collection");
    data.data.filters = { brand, device, category };
    yield put(saveStagingOrders(data.data));
    yield put(setStagingOrdersLoading("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(setStagingOrdersLoading("ERROR"));
  }
}

//////// GET CUSTOM DETAILS
function* getCustomDetailsSaga(action: PayloadAction<CustomDetailsPayload>): SagaIterator {
  try {
    yield put(setDetailsStatus({ itemId: action.payload.itemId, status: true }));
    const { data } = yield call(smartshopperService.getCustomDetails, action.payload);
    yield put(saveDetails({ details: data?.data, itemId: action?.payload?.itemId }));
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(setDetailsStatus({ itemId: action.payload.itemId, status: false }));
  }
}

//////// GET STAGING ORDERS COUNT
function* getStagingOrdersCountSaga(): SagaIterator {
  try {
    const { data } = yield call(smartshopperService.getStagingOrderCount);
    yield put(setStagingOrderCount(data.data.count));
  } catch (error) {
    yield put(handleError(error));
    yield put(setStagingOrderCount(0));
  }
}

/////// GET DEVICES
function* getDevicesSaga(action: PayloadAction<DevicesPayload>): SagaIterator {
  try {
    yield put(setDevicesLoading("LOADING"));
    const { data } = yield call(smartshopperService.getDevices, action.payload);
    yield put(saveDevices(data.data));
    yield put(setDevicesLoading("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(setStagingOrderCount(0));
    yield put(setDevicesLoading("ERROR"));
  }
}
/////// PUT STAGING ORDERS NOTE
function* putStagingOrderNoteSaga(action: PayloadAction<StagingOrderNotePayload>): SagaIterator {
  try {
    yield put(setStagingOrderNoteLoading("LOADING"));
    const { data } = yield call(smartshopperService.putStagingOrderNote, action.payload);
    if (data.status === 200) {
      yield put(setStagingOrderNoteStatus({ status: true, itemId: action.payload.itemId }));
    }
    yield put(setStagingOrderNoteLoading("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    setStagingOrderNoteLoading("ERROR");
  }
}
////// PUT CONFIRMATION STAGING ORDER
function* putStagingOrderConfirmationSaga(action: PayloadAction<ConfimationPayload>): SagaIterator {
  try {
    const { data } = yield call(smartshopperService.putStagingOrderConfirmation, action.payload);
    if (data.status === 200) {
      yield put(setConfirmationStatus(true));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

/////// DELETE STAGIN ORDERS ITEMS
function* deleteStaginOrderItemsSaga(
  action: PayloadAction<StagingOrdersItemsToDeletePayload>
): SagaIterator {
  try {
    yield put(setLoadingStatus("LOADING"));
    const { data } = yield call(smartshopperService.deleteStaginOrderItems, action.payload);
    if (data.data) {
      yield put(setStagingOrderDeleteStatus(true));
    }
    yield put(setLoadingStatus("SUCCESS"));
  } catch (error) {
    //if item no longer present show popup and disable item
    if (error.response?.status === 404) {
      const itemIds = action.payload.items.map((_) => _.itemId);
      yield put(saveUnavaiableItemsInfo(itemIds));
    }
    yield put(handleError(error));
    yield put(setLoadingStatus("ERROR"));
  }
}

/////// DELETE ALL STAGIN ORDERS ITEMS
function* deleteAllStaginOrderItemsSaga(): SagaIterator {
  try {
    yield put(setLoadingStatus("LOADING"));
    const { data } = yield call(smartshopperService.deleteAllStaginOrderItems);
    if (data.status === 200) {
      yield put(setStagingOrderDeleteStatus(true));
    }
    yield put(setLoadingStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingStatus("ERROR"));
  }
}

/////// ADD ITEMS TO CART
function* addItemsToCartSaga(action: PayloadAction<CartProductPayload>): SagaIterator {
  try {
    yield put(setLoadingStatus("LOADING"));
    const orderId = yield select(selectOrderId);
    const { orderItem, stagingOrdersPayload } = action.payload;

    //GET ALL BAGS AND ITEM
    const response = yield call(smartshopperService.getStagingOrders, stagingOrdersPayload);

    if (response.data.data) {
      const stagingOrders = response.data.data?.stagingOrders;

      //CHECK WHICH ITEMS ARE AVAILABLE
      const availableItemIds: M4CPreCartProduct[] = [];
      orderItem.forEach((_) => {
        stagingOrders.forEach((order: StagingOrdersItems) => {
          order.items.forEach((i) => {
            if (i.itemId === _.itemId) {
              availableItemIds.push(_);
            }
          });
        });
      });

      if (availableItemIds.length === 0) {
        //SHOW POPUP AND DISABLED ITEMS
        const itemIds = orderItem.map((_) => _.itemId);
        yield put(saveUnavaiableItemsInfo(itemIds));
        yield put(setLoadingStatus("SUCCESS"));
      } else {
        //CONTINUE WITH ADD TO CART FOR AVAIABLE ITEMS

        //map precart items into service payload
        const payload = getPostPrecartItemsPayload(orderId, availableItemIds);
        const { data } = yield call(cartService.postPrecartItems, payload);

        //GET ORDER ITEMS FOR M4C CONFIRMATION
        const orderItemCart = data.data.orderItem?.sort(
          (a: { orderItemId: number }, b: { orderItemId: number }) => {
            return a.orderItemId - b.orderItemId;
          }
        );
        const itemId = availableItemIds?.map((item) => item?.itemId);
        yield put(setSelectedItemIdInCart(itemId));
        yield put(setOrderItemId(orderItemCart));
        yield put(getPrecartCount(true));
        yield put(setLoadingStatus("SUCCESS"));

        //SHOW POPUP IF SOME ITEM WAS NO LONGER AVAILABLE
        if (availableItemIds.length !== orderItem.length) {
          yield put(
            savePopupNotFoundItems({
              open: true,
            })
          );
        }
      }
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingStatus("ERROR"));
    //TODO POPUP IF IT FAILS
  }
}

////// EDIT PIN
function* editPinSage(action: PayloadAction<EditPinPayload>): SagaIterator {
  try {
    yield put(setEditPinLoading("LOADING"));
    const { data } = yield call(smartshopperService.postEditPin, action.payload);
    if (data.data.success) {
      yield put(setEditPinStatus(true));
    }
    yield put(setEditPinLoading("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(setEditPinLoading("ERROR"));
  }
}
export function* smartshopperSaga(): SagaIterator {
  /////// GET
  // STAGING AREA
  yield takeEvery(getStagingOrders.type, getStagingOrdersSaga);
  yield takeEvery(getStagingOrdersCount.type, getStagingOrdersCountSaga);
  yield takeEvery(getCustomDetails.type, getCustomDetailsSaga);
  // DEVICES
  yield takeEvery(getDevices.type, getDevicesSaga);
  ////// POST
  yield takeEvery(addItemsToCart.type, addItemsToCartSaga);
  // EDIT PIN
  yield takeEvery(editPin.type, editPinSage);
  /////// PUT
  yield takeEvery(putStagingOrderNote.type, putStagingOrderNoteSaga);
  yield takeEvery(putStagingOrderConfirmation.type, putStagingOrderConfirmationSaga);
  /////// DELETE
  yield takeEvery(deleteStaginOrderItems.type, deleteStaginOrderItemsSaga);
  yield takeEvery(deleteAllStaginOrderItems.type, deleteAllStaginOrderItemsSaga);
}
