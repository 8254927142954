import React, { ChangeEvent, useEffect, useState } from "react";
import ReactSelect, {
  components,
  IndicatorProps,
  MultiValueProps,
  OptionProps,
  OptionTypeBase,
  SingleValueProps,
  ValueContainerProps,
} from "react-select";
import clsx from "clsx";
import styled from "styled-components/macro";
import { ReactComponent as Arrow } from "../../assets/icons/arrowhead-down-icon.svg";
import TransparentIcon from "../../assets/icons/Transparent.svg";
import { sendAnalyticsData } from "../../store/analytics/analyticsService";
import CustomText from "./CustomText";
import useTranslation from "../../hooks/useTranslation";
import { getFilterValue } from "../../utils/catalogueUtils";
import { orderBy } from "lodash";
import Checkbox from "./Checkbox";
import { GenericSelectOptions } from "../../interfaces/mainInterfaces";
import BrandImage from "./BrandImage";

export type CustomOptions = {
  value: string | number;
  label: string;
};

interface Props {
  type: "header" | "dark" | "no-outline" | "white" | "no-outline-white";
  colorNoOutline?: "gray-medium" | "bg-light-blue";
  placeholder?: string;
  options: CustomOptions[] | null;
  menuIsOpen?: boolean;
  onChange: (option: CustomOptions) => void;
  isSearchable?: boolean;
  defaultValue?: CustomOptions | GenericSelectOptions[];
  disabled?: boolean;
  opacity?: number;
  helperText?: string;
  isError?: boolean;
  errorStyle?: "warning-red" | "warning-white";
  value?: CustomOptions | null;
  required?: boolean; // if true * is shown next to placeholder
  endIcon?: JSX.Element | null;
  dropdownIcon?: JSX.Element;
  showIcon?: boolean; // added for colors filters
  isLoading?: boolean;
  onFocus?: () => void;
  onMenuOpen?: () => void;
  onMenuClosed?: () => void;
  onInputChange?: (option: ChangeEvent<HTMLInputElement>) => void;
  noOptionsMessage?: (e: { inputValue: string }) => React.ReactNode;
  onDropdownIconClick?: () => void;
  enableDataDescription?: boolean;
  "data-element-id"?: string;
  menuPlacement?: "auto" | "bottom" | "top";
  isClearable?: boolean;
  isSearchResult?: boolean;
  noOrderOptions?: boolean;
  optionEndIcon?: string;
  showOptionEndIconFunc?: (option: CustomOptions) => boolean;
  isCheckboxSelector?: boolean;
  selectedOptions?: CustomOptions[] | GenericSelectOptions[] | null | undefined;
  imageSelector?: boolean;
  backgroundColor?: string;
}
interface CustomOptionType extends OptionTypeBase {
  label?: string;
}

const CustomSelect = (
  {
    type,
    placeholder,
    options,
    menuIsOpen,
    onChange,
    isSearchable = true,
    defaultValue,
    disabled = false,
    opacity,
    helperText,
    isError = false,
    errorStyle = "warning-red",
    colorNoOutline,
    value,
    required = false,
    endIcon = null,
    showIcon = false,
    isLoading = false,
    onFocus,
    onMenuOpen,
    onMenuClosed,
    onInputChange,
    noOptionsMessage,
    onDropdownIconClick,
    enableDataDescription,
    "data-element-id": dataElementId,
    menuPlacement = "auto",
    isClearable = false,
    isSearchResult = false,
    noOrderOptions = false,
    optionEndIcon,
    showOptionEndIconFunc,
    isCheckboxSelector = false,
    selectedOptions,
    imageSelector,
    dropdownIcon = <Arrow />,
    backgroundColor,
  }: Props,
  forwardedRef: unknown
): JSX.Element => {
  const { translateLabel } = useTranslation();
  const [orderedOptions, setOrderedOptions] = useState<CustomOptions[]>([]);

  //ORDER OPTIONS IN ALPHABETICAL ORDER
  useEffect(() => {
    if (options) {
      let orderedOptions = options;
      if (!noOrderOptions) {
        orderedOptions = orderBy(options, ["label"]);
      }

      setOrderedOptions(orderedOptions);
    }
  }, [options]);

  const SearchClassNames = clsx(
    type === "dark" && "select-type-dark",
    type === "header" && "select-type-header",
    type === "no-outline" && "select-type-no-outline",
    type === "no-outline-white" && "select-type-no-outline-white",
    type === "white" && "select-type-white",
    disabled && "select-disabled",
    isError && "select-error",
    colorNoOutline === "gray-medium" && "select-color-gray-medium",
    colorNoOutline === "bg-light-blue" && "select-color-bg-light-blue",
    type !== "dark" && backgroundColor && "select-background-color"
  );
  const printIcons = (iconName: string) => {
    switch (iconName) {
      case "Transparent":
      case "Clear":
        return <SpecialIcon src={TransparentIcon} />;
      default:
        return <ColorFilterIcon backgroundColor={iconName} />;
    }
  };

  const SingleValue = ({ children, ...props }: SingleValueProps<CustomOptions>) => {
    const showOptionEndIconBool = showOptionEndIconFunc ? showOptionEndIconFunc(props.data) : false;
    return (
      <components.SingleValue
        {...props}
        className={clsx(showOptionEndIconBool && "show-star-icon")}
      >
        {children}
      </components.SingleValue>
    );
  };

  const ValueContainer = ({ children, ...props }: ValueContainerProps<any, boolean>) => {
    const showOptionEndIconBool = getShowOptionEndIconBool(props);

    return (
      <components.ValueContainer
        {...props}
        className={clsx(showOptionEndIconBool && "show-star-icon")}
      >
        {children}
      </components.ValueContainer>
    );
  };

  const getShowOptionEndIconBool = (props: any): boolean =>
    showOptionEndIconFunc && props.getValue()?.[0]
      ? showOptionEndIconFunc(props.getValue()?.[0] as CustomOptions)
      : false;

  const MultiValue = ({ ...props }: MultiValueProps<any>) => {
    const showOptionEndIconBool = getShowOptionEndIconBool(props);

    return (
      <components.MultiValue
        {...props}
        className={clsx(showOptionEndIconBool && "show-star-icon")}
      />
    );
  };

  const DropdownIndicator = (props: IndicatorProps<OptionTypeBase, boolean>) => {
    const handleClick = () => {
      onDropdownIconClick?.();
    };

    return (
      <components.DropdownIndicator {...props}>
        <IconContainer onMouseDown={handleClick} className="custom-select-icon-container">
          {dropdownIcon}
        </IconContainer>
      </components.DropdownIndicator>
    );
  };

  const Option = (props: OptionProps<CustomOptionType, boolean>) => {
    const showOptionEndIconBool = showOptionEndIconFunc
      ? showOptionEndIconFunc(props.data as CustomOptions)
      : false;

    return (
      <button
        {...(dataElementId ? { "data-element-id": dataElementId } : {})}
        {...(enableDataDescription ? { "data-description": props.label } : {})}
        onClick={() =>
          dataElementId &&
          enableDataDescription &&
          sendAnalyticsData({
            id: "Click",
            Tracking_Type: "link",
            data_element_id: dataElementId,
            data_description: props.label,
          })
        }
      >
        <components.Option {...props}>
          <CustomOption className={clsx(props.data.subtitle && "with-subtitle")}>
            <ContainerOption
              className={clsx(
                props.data.subtitle && "with-subtitle",
                showOptionEndIconBool && "with-end-icon"
              )}
            >
              <div>
                <OptionContainer>
                  {isCheckboxSelector && (
                    <Checkbox
                      label={""}
                      name={props.label}
                      controlled={true}
                      checked={
                        !!selectedOptions &&
                        selectedOptions.findIndex((_: CustomOptions) => _.label === props.label) >
                          -1
                      }
                    />
                  )}
                  {showIcon &&
                    getFilterValue(props?.data?.identifier) &&
                    printIcons(getFilterValue(props?.data?.identifier))}
                  {props.label}
                </OptionContainer>
                {props.data.subtitle && <SubtitleOption>{props.data.subtitle}</SubtitleOption>}
              </div>
              {showOptionEndIconBool && (
                <IconContainerEndOption>
                  <img src={optionEndIcon} />
                </IconContainerEndOption>
              )}
            </ContainerOption>
          </CustomOption>
        </components.Option>
      </button>
    );
  };

  const MenuList = (props: any) => {
    const classNames = clsx(
      "menulist",
      props.children.constructor === Object && "menulist-one-line",
      props.children.length === 1 && "menulist-one-line",
      props.children.length === 2 && "menulist-two-line",
      props.children.length === 3 && "menulist-three-line"
    );
    return (
      <components.MenuList {...props}>
        <div className={classNames}>
          <div>{props.children}</div>
        </div>
      </components.MenuList>
    );
  };

  const loadingOptionsInSelect = (): React.ReactNode => {
    return (
      <CustomText as="span" color="gray-dark" fontSizePx={14}>
        {translateLabel("DROPDOWN_LOADING")}
      </CustomText>
    );
  };

  const errorOptionsInSelect = (): React.ReactNode => {
    return (
      <CustomText as="span" color="gray-dark" fontSizePx={14}>
        {translateLabel("DROPDOWN_NO_OPTIONS")}
      </CustomText>
    );
  };

  const getPlaceholder = () => {
    let placeholderTemp = placeholder || "";
    if (placeholderTemp && required) {
      placeholderTemp += " *";
    }

    if (isCheckboxSelector && selectedOptions && selectedOptions?.length > 0) {
      if (placeholderTemp) {
        placeholderTemp += " ";
      }
      placeholderTemp += "(" + selectedOptions.length.toString() + ")";
    }
    return placeholderTemp;
  };

  const CustomOptionFunc: React.FunctionComponent<OptionProps<CustomOptionType, false>> = ({
    data,
    ...props
  }) => {
    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      props.selectOption(data);
    };

    return (
      <ImageOption {...props} onClick={handleClick}>
        <BrandImage isBrandGroup brandOrBrandGroup={data.value}></BrandImage>
      </ImageOption>
    );
  };

  return (
    <>
      <ReactSelectCustom
        ref={forwardedRef}
        classNamePrefix="custom-select"
        className={SearchClassNames}
        options={orderedOptions}
        placeholder={getPlaceholder()}
        menuIsOpen={menuIsOpen}
        components={{
          DropdownIndicator,
          MenuList,
          Option: imageSelector ? CustomOptionFunc : Option,
          SingleValue,
          ValueContainer: isCheckboxSelector ? components.ValueContainer : ValueContainer,
          MultiValue: isCheckboxSelector ? MultiValue : components.MultiValue,
        }}
        isMulti={isCheckboxSelector}
        onChange={onChange}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        menuPlacement={menuPlacement}
        isDisabled={disabled}
        opacity={opacity}
        value={value}
        endIcon={endIcon}
        isLoading={isLoading}
        onFocus={onFocus}
        onInputChange={onInputChange}
        noOptionsMessage={noOptionsMessage ? noOptionsMessage : errorOptionsInSelect}
        isClearable={isClearable}
        isSearchResult={isSearchResult}
        loadingMessage={loadingOptionsInSelect}
        optionEndIcon={optionEndIcon}
        closeMenuOnSelect={!isCheckboxSelector}
        hideSelectedOptions={false}
        controlShouldRenderValue={isCheckboxSelector ? false : true}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClosed}
        backgroundColor={backgroundColor}
      />
      {helperText && (
        <HelperText className={`${isError ? errorStyle : ""}`}>
          {required && !isError && "*"}
          {helperText}
        </HelperText>
      )}
    </>
  );
};

const heightOptionMd = 3.5;
const heightOptionSm = 2.5;

const ReactSelectCustom = styled(ReactSelect)`
  width: 100%;

  &:focus {
    border: unset;
  }

  //SELECT CONTROL
  .custom-select__value-container {
    font-size: 0.8125rem;
    margin-left: ${(props) => (props.noMargin ? 0 : "1.5rem")};
    padding: 0px;
    &--has-value Input {
      font-family: ${(props) => props.theme.fonts.fontBold};
    }

    Input {
      font-size: 0.8125rem !important;
      font-family: ${(props) => props.theme.fonts.fontMedium};
      color: ${(props) => props.theme.palette.primary}!important;
    }
  }

  &.remove-typing {
    .custom-select__input {
      color: transparent;
    }
  }

  .custom-select__single-value.show-star-icon {
    position: relative;
    top: auto;
    transform: none;
    width: calc(100% - 26px);
  }

  .custom-select__value-container.show-star-icon {
    &:after {
      content: url(${(props) => props.optionEndIcon});
      height: 20px;
      width: 20px;
    }
  }

  .custom-select__control {
    &--menu-is-open {
      .custom-select__indicator {
        transform: ${(props) => (props.endIcon ? "none" : "rotate(-180deg)")};
      }
    }

    &--is-focused {
      border: 0;
      box-shadow: none;
    }
  }

  .custom-select__placeholder {
    font-family: ${(props) => props.theme.fonts.fontMedium};
    font-size: 0.8125rem;
    color: ${(props) => props.theme.palette.primary};
    height: 1rem;
    line-height: 1rem !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 8px);
  }

  .custom-select__indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0.5rem;
  }

  .custom-select__indicator-separator {
    display: none;
  }

  .custom-select__menu-notice--no-options,
  .custom-select__menu-notice--loading {
    height: ${heightOptionSm}rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8125rem;
  }

  //SELECT MENU
  .custom-select__menu {
    width: 100%;
    border-radius: 0.25rem;
    margin: 0;
    box-shadow: none;
    z-index: 20;
    margin-bottom: 2rem;

    &-list {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  .menulist {
    height: auto;
    max-height: ${(props) => (props.isSearchResult ? "12.5" : heightOptionSm * 4)} "rem";
    /* &.menulist-one-line {
      height: ${heightOptionSm + "rem"};
    }
    &.menulist-two-line {
      height: ${heightOptionSm * 2 + "rem"};
    }
    &.menulist-three-line {
      height: ${heightOptionSm * 3 + "rem"};
    } */

    button {
      width: 100%;
      text-align: left;
    }
  }

  .custom-select__option {
    width: 100%;
    background-color: transparent;
    font-family: ${(props) => props.theme.fonts.fontMedium};
    font-size: 0.8125rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    min-height: ${heightOptionMd}rem;
    height: auto;
    word-wrap: break-word;
    word-break: break-all;
    display: flex;
    align-items: center;
    outline: none;
    cursor: pointer;
    color: ${(props) => props.theme.palette.primary};
    border-bottom: solid 1px ${(props) => props.theme.palette.gray.medium};

    &:last-child {
      border-bottom: 0;
      border-radius: 0px 0rem 0 0.25rem;
    }

    &--is-selected {
      font-family: ${(props) => props.theme.fonts.fontBold};
    }
  }

  &.select-disabled {
    ${(props) => props.opacity && `opacity: ${props.opacity}`};

    .custom-select__control {
      background-color: ${(props) => props.theme.palette.gray.light};
    }

    .custom-select__placeholder {
      color: ${(props) => props.theme.palette.gray.dark};
    }

    svg {
      fill: ${(props) => props.theme.palette.gray.dark};
    }
  }

  //SELECT IN HEADER
  &.select-type-header {
    .custom-select__control {
      border: 0;
      border-left: 1px solid ${(props) => props.theme.palette.gray.medium};
      border-radius: 0;
      height: ${(props) => props.theme.headerHeightRem + "rem"};

      &--menu-is-open {
        .custom-select__indicator {
          transform: rotate(-180deg);
        }
      }
    }

    .custom-select__indicators {
      padding-right: 1rem;

      .custom-select-icon-container {
        height: 1.25rem;
      }

      .custom-select__indicator {
        padding: 0;
        svg {
          height: 1.25rem;
          width: 1.25rem;
        }
      }
    }

    .custom-select__value-container {
      padding: 0;
      padding-left: 1rem;
      margin: 0;
      justify-content: center;
    }

    .custom-select__single-value {
      padding-bottom: 4px;
      height: 1rem;
    }

    .custom-select__single-value,
    .custom-select__placeholder {
      color: ${(props) => props.theme.palette.primary};
      height: 1rem;
    }

    .menulist {
      border-top: solid 1px ${(props) => props.theme.palette.gray.medium};
      max-height: ${(props) => props.theme.headerHeightRem * 4 + "rem"};
      height: auto;
    }

    .custom-select__option {
      background-color: transparent;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      min-height: ${(props) => props.theme.headerHeightRem + "rem"};
      height: auto;

      &:hover {
        background-color: ${(props) => props.theme.palette.gray.light};
      }
    }

    &:focus {
      border: unset;
    }

    .custom-select__menu {
      position: absolute;
      top: 4rem;
      width: 100%;
      margin-top: 0rem;
      border-radius: 0rem 0rem 0.25rem 0.25rem;
      border: solid 1px ${(props) => props.theme.palette.gray.medium};
      border-top: 0rem;
      box-shadow: none;
    }
  }

  //SELECT WITH DARK BACKGROUND
  &.select-type-dark {
    .custom-select__control {
      border: solid 1px ${(props) => props.theme.palette.bordersBlue};
      background-color: ${(props) => props.theme.palette.primary};
      height: ${heightOptionSm}rem;
      cursor: pointer;

      &--menu-is-open {
        border-radius: 0.25rem;
        border-top: solid 1px ${(props) => props.theme.palette.white};
        border-right: solid 1px ${(props) => props.theme.palette.white};
        border-left: solid 1px ${(props) => props.theme.palette.white};
        border-bottom: solid 1px ${(props) => props.theme.palette.white};
      }
    }

    .custom-select__menu-notice--no-options {
      font-family: ${(props) => props.theme.fonts.fontRegular};
      color: ${(props) => props.theme.palette.white};
    }

    .custom-select__placeholder {
      color: ${(props) => props.theme.palette.white} !important;
    }

    .custom-select__value-container {
      Input {
        color: ${(props) => props.theme.palette.white}!important;
      }
    }

    .custom-select__single-value {
      line-height: 1rem !important;
      color: ${(props) => props.theme.palette.white} !important;
      text-overflow: ellipsis;
      height: 1rem;
    }

    .custom-select__menu {
      border: solid 0.063rem ${(props) => props.theme.palette.white};
      background-color: ${(props) => props.theme.palette.primary};
      border-radius: 0.25rem;
      margin: 0.25rem 0;
    }

    .custom-select__option {
      background-color: transparent;
      padding-left: 1.125rem;
      padding-right: 1.125rem;
      color: ${(props) => props.theme.palette.white};
      min-height: ${heightOptionSm}rem;
      height: auto;
      font-family: ${(props) => props.theme.fonts.fontRegular};
      border-bottom: 0;
      border-radius: 0.25rem;

      &:hover {
        text-decoration: underline;
      }
    }

    .custom-select-icon-container {
      display: contents;

      svg {
        height: 1rem;
        width: 1rem;
        fill: ${(props) => props.theme.palette.white};
      }
    }
  }

  //SELECT WITH NOT OUTLINE AND BACKGROUND GRAY
  &.select-type-no-outline {
    .custom-select__control {
      background-color: ${(props) => props.theme.palette.background.light};
      border: 0;
      height: ${heightOptionSm}rem;

      &--menu-is-open {
        /* border-radius: 0.25rem 0.25rem 0 0; */
        border-radius: 0.25rem;
        border: solid 1px ${(props) => props.theme.palette.primary};
      }
    }

    .custom-select__menu {
      border: solid 0.063rem ${(props) => props.theme.palette.primary};
      background-color: ${(props) => props.theme.palette.background.light};
      margin: 0.25rem 0;
      border-radius: 0.25rem;
    }

    .custom-select__single-value {
      font-size: 0.875rem;
      color: ${(props) => props.theme.palette.primary} !important;
      text-overflow: ellipsis;
      height: 1rem;
    }

    .custom-select__option {
      padding-left: 1.125rem;
      padding-right: 1.125rem;
      color: ${(props) => props.theme.palette.primary};
      min-height: ${heightOptionSm}rem;
      height: auto;
      background-color: ${(props) => props.theme.palette.background.light};

      &:hover {
        background-color: ${(props) => props.theme.palette.gray.medium};
      }
    }

    .menulist {
      border-top: 0;
    }

    .custom-select__menu-notice--no-options {
      font-family: ${(props) => props.theme.fonts.fontMedium};
      color: ${(props) => props.theme.palette.primary};
    }

    //COLORS
    &.select-color-gray-medium {
      .custom-select__control,
      .custom-select__menu,
      .custom-select__option {
        background-color: ${(props) => props.theme.palette.gray.medium};
      }

      .custom-select__menu {
        border: solid 0.063rem ${(props) => props.theme.palette.primary};
        /* background-color: ${(props) => props.theme.palette.background.light}; */
        margin: 0.25rem 0;
        border-radius: 0.25rem;
      }

      .custom-select__control--menu-is-open {
        border-bottom: solid 1px ${(props) => props.theme.palette.primary};
      }

      .custom-select__option {
        border-color: ${(props) => props.theme.palette.gray.dark};
        border-radius: 0.25rem;
        &:hover {
          background-color: ${(props) => props.theme.palette.gray.dark};
        }
      }
    }

    &.select-color-bg-light-blue {
      .custom-select__control,
      .custom-select__menu,
      .custom-select__option {
        background-color: ${(props) => props.theme.palette.background.lightBlue};
      }
      .custom-select__option {
        border-radius: 0.25rem;
        border-color: 1px ${(props) => props.theme.palette.primary};
        &:hover {
          background-color: ${(props) => props.theme.palette.gray.medium};
          border-radius: 0.25rem;
        }
      }
    }
  }

  //SELECT WITH NOT OUTLINE AND BACKGROUND WHITE
  &.select-type-no-outline-white {
    .custom-select__control {
      background-color: ${(props) => props.theme.palette.white};
      border: 0;
      height: ${heightOptionSm}rem;

      &--menu-is-open {
        /* border-radius: 0.25rem 0.25rem 0 0; */
        border-radius: 0.25rem;
        border: solid 1px ${(props) => props.theme.palette.primary};
      }
    }

    .custom-select__menu {
      border: solid 0.063rem ${(props) => props.theme.palette.primary};
      background-color: ${(props) => props.theme.palette.white};
      margin: 0.25rem 0;
      border-radius: 0.25rem;
    }

    .custom-select__single-value {
      font-size: 0.875rem;
      color: ${(props) => props.theme.palette.primary} !important;
      text-overflow: ellipsis;
      height: 1rem;
    }

    .custom-select__option {
      padding-left: 1.125rem;
      padding-right: 1.125rem;
      color: ${(props) => props.theme.palette.primary};
      min-height: ${heightOptionSm}rem;
      height: auto;
      background-color: ${(props) => props.theme.palette.white};

      &:hover {
        background-color: ${(props) => props.theme.palette.gray.medium};
      }
    }

    .menulist {
      border-top: 0;
    }

    .custom-select__menu-notice--no-options {
      font-family: ${(props) => props.theme.fonts.fontMedium};
      color: ${(props) => props.theme.palette.primary};
    }

    //COLORS
    &.select-color-gray-medium {
      .custom-select__control,
      .custom-select__menu,
      .custom-select__option {
        background-color: ${(props) => props.theme.palette.gray.medium};
      }

      .custom-select__menu {
        border: solid 0.063rem ${(props) => props.theme.palette.primary};
        /* background-color: ${(props) => props.theme.palette.background.light}; */
        margin: 0.25rem 0;
        border-radius: 0.25rem;
      }

      .custom-select__control--menu-is-open {
        border-bottom: solid 1px ${(props) => props.theme.palette.primary};
      }

      .custom-select__option {
        border-color: ${(props) => props.theme.palette.gray.dark};
        border-radius: 0.25rem;
        &:hover {
          background-color: ${(props) => props.theme.palette.gray.dark};
        }
      }
    }

    &.select-color-bg-light-blue {
      .custom-select__control,
      .custom-select__menu,
      .custom-select__option {
        background-color: ${(props) => props.theme.palette.background.lightBlue};
      }
      .custom-select__option {
        border-radius: 0.25rem;
        border-color: 1px ${(props) => props.theme.palette.primary};
        &:hover {
          background-color: ${(props) => props.theme.palette.gray.medium};
          border-radius: 0.25rem;
        }
      }
    }
  }

  //SELECT WITH BACKGROUND WHITE AND OUTLINE
  &.select-type-white {
    .custom-select__control {
      border: solid 1px ${(props) => props.theme.palette.gray.medium};
      height: ${heightOptionMd}rem;

      &:not(.custom-select__control--menu-is-open):hover {
        border: solid 1px ${(props) => props.theme.palette.gray.dark};
      }

      &--menu-is-open {
        border-radius: 0.25rem;
        border: solid 1px ${(props) => props.theme.palette.primary};
      }

      .custom-select__clear-indicator svg {
        fill: ${(props) => props.theme.palette.gray.dark};
      }
    }

    .custom-select__menu {
      border: solid 1px ${(props) => props.theme.palette.primary};
      margin: 0.25rem 0;
      border-radius: 0.25rem;
    }

    .custom-select__single-value {
      font-size: 0.875rem;
      color: ${(props) => props.theme.palette.primary} !important;
      text-overflow: ellipsis;
      height: 1rem;
    }

    .custom-select__option {
      padding-left: 1.125rem;
      padding-right: 1.125rem;
      color: ${(props) => props.theme.palette.primary};
      min-height: ${heightOptionMd}rem;
      height: auto;

      &:hover {
        background-color: ${(props) => props.theme.palette.gray.medium};
      }
    }

    .menulist {
      border-top: 0;
      max-height: ${heightOptionMd * 4 + "rem"};
      height: auto;
      /* &.menulist-one-line {
        height: ${heightOptionMd + "rem"};
      }
      &.menulist-two-line {
        height: ${heightOptionMd * 2 + "rem"};
      }
      &.menulist-three-line {
        height: ${heightOptionMd * 3 + "rem"};
      } */
    }

    .custom-select__menu-notice--no-options,
    .custom-select__menu-notice--loading {
      height: ${heightOptionMd}rem;
    }

    &.select-error {
      .custom-select__control {
        border: solid 1px ${(props) => props.theme.palette.feedback.error};

        &--menu-is-open {
          border-bottom: solid 1px ${(props) => props.theme.palette.gray.medium};
        }
      }

      .custom-select__menu {
        border: solid 1px ${(props) => props.theme.palette.feedback.error};
        border-top: unset;
      }

      .custom-select__indicators:before {
        content: "";
        background: url(CircleIcon);
      }
    }
  }

  //SELECT WITH CUSTOM BACKGROUND COLOR
  &.select-background-color {
    .custom-select__control,
    .custom-select__option {
      ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor}`};
    }
  }
`;

const IconContainer = styled.span`
  height: 1.25rem;

  svg {
    height: 1.25rem;
    width: 1.25rem;
    fill: ${(props) => props.theme.palette.primary};
  }
`;

const OptionContainer = styled.div`
  width: 100%;
  position: relative;
  word-break: normal;
  display: flex;
  align-items: center;
`;

const ColorFilterIcon = styled.div<{ backgroundColor: any }>`
  height: 0.75rem;
  width: 0.75rem;
  background: ${(props) => props.backgroundColor};
  border-radius: 50%;
  margin-right: 0.25rem;
  border: 1px solid ${(props) => props.theme.palette.gray.medium};
`;
const SpecialIcon = styled.img`
  height: 0.75rem;
  width: 0.75rem;
  margin-right: 0.25rem;
`;

const ContainerOption = styled.div`
  position: relative;

  &.with-end-icon {
    padding-right: 1.2rem;
  }
`;

const IconContainerEndOption = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;

  svg {
    height: 1rem;
    width: 1rem;
    fill: ${(props) => props.theme.palette.primary};
  }
`;

const HelperText = styled.div`
  font-family: ${(props) => props.theme.fonts.fontRegular};
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: ${(props) => props.theme.palette.gray.dark};

  &.warning-red {
    color: ${(props) => props.theme.palette.feedback.error};
    margin-left: 1.5rem;
  }

  &.warning-white {
    color: ${(props) => props.theme.palette.gray.light};
    margin-left: 1.5rem;
  }

  &.select-error {
    color: ${(props) => props.theme.palette.feedback.error};
    margin-left: 1.5rem;
  }
`;

const CustomOption = styled.div`
  width: 100%;

  &.with-subtitle {
    padding: 0.5rem 0;
  }
`;

const SubtitleOption = styled.div`
  padding: 0.25rem 0;
  font-size: 0.813rem;
  font-family: ${(prop) => prop.theme.fonts.fontRegular};
  word-break: break-word;
`;

const ImageOption = styled.div`
  display: flex;
  padding: 0.688rem 1rem;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  height: 5.75rem;
  border-top: ${(props) =>
    props?.theme?.palette?.gray?.medium
      ? `1px solid ${props.theme.palette.gray.medium}`
      : `1px solid #dbdce0`};

  div {
    display: flex;
    justify-content: center;
    overflow: hidden;

    max-width: 6.25rem;
    max-height: 3.125rem;
  }
`;

export default React.forwardRef(CustomSelect);
