import React from "react";
import styled from "styled-components/macro";

import useDataHref from "../../../hooks/useDataHref";
import CustomText from "../../styled-UI/CustomText";

import { LpLogoText as Interface } from "../../../interfaces/cmsInterfaces";
import SetInnerHTMLWithSanitize from "../SetInnerHTMLWithSanitize";
import { FontFamilies } from "../../../store/store/storeInterfaces";

interface Props {
  data: Interface;
  id: string;
  isDarkMode?: boolean;
  customFont?: FontFamilies | null;
}

const LpLogoText = ({ data, id, isDarkMode = false, customFont = null }: Props): JSX.Element => {
  const { description, description1Colour, brand } = data;
  const brandLogo = brand?.[0]?.url;
  const descrWithHref = useDataHref({ content: description });
  return (
    <div id={id}>
      <Container>
        {brandLogo && <Logo image={brandLogo} />}
        {description && (
          <CustomText
            as="span"
            className="description"
            font={customFont ? "custom-regular" : "font-regular"}
            fontFamily={customFont}
            fontSizePx={18}
            textAlign="center"
            lineHeightPx={26}
            color={isDarkMode ? "white" : "primary"}
            customColor={description1Colour}
          >
            <SetInnerHTMLWithSanitize text={descrWithHref.updatedContent ?? description} />
          </CustomText>
        )}
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.375rem;
  padding: 2% 24% 4% 24%; //3.75rem 24.1875rem 4.5rem 24.25rem;
`;
const Logo = styled.div<{ image: string }>`
  width: 7.5rem;
  height: 4.375rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(${(props) => props.image && props.image});
  mix-blend-mode: multiply;
`;
export default LpLogoText;
