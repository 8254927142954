import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import clsx from "clsx";

import { ItemPrice, Sku } from "../../../interfaces/productInterface";
import { Door } from "../../../store/user/userInterfaces";
import {
  selectDoorsForMultidoorAddSize,
  selectIsStarsMultidoor,
  selectSelectedDoor,
  selectTotalDoors,
} from "../../../store/user/userSlice";
import CustomText from "../../styled-UI/CustomText";
import LinkButton from "../../styled-UI/LinkButton";
import MultiDoorSelectPDP from "../MultidoorSelectPDP";
import AddSizeList from "./AddSizeList";

interface Props {
  skus: Sku[];
  "data-element-id"?: string;
  enableDataDescription?: boolean;
  price?: ItemPrice;
  togglePopup?: () => void;
  door?: Door;
  dataElementIdFromMainBox?: string;
  code?: string;
  dataElementIdRXPLP?: string;
  isLoading?: boolean;
  isMultidoor?: boolean;
  variantId?: string; // of the variant, required by MultiDoorSelectPDP to properly dispatch action on selected door change
  isAfa?: boolean;
  starsSkus?: string[];
  compactMultidoorSelect?: boolean;
  showFamilySizeFrames?: boolean;
  hideAvailabilitySize?: boolean;
  isSpareParts?: boolean;
}

const SizeContainer = ({
  skus,
  variantId,
  "data-element-id": dataElementId,
  enableDataDescription,
  price,
  togglePopup,
  door,
  isMultidoor = false,
  dataElementIdFromMainBox,
  code,
  dataElementIdRXPLP,
  isLoading,
  isAfa,
  starsSkus,
  compactMultidoorSelect = false,
  showFamilySizeFrames,
  hideAvailabilitySize,
  isSpareParts,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  const isStarsMultidoor = useSelector(selectIsStarsMultidoor);
  const totalDoors = useSelector(selectTotalDoors);
  const selectedDoor = useSelector(selectSelectedDoor);
  const currentDoor = door ?? selectedDoor;

  const doorsToDisplay = useSelector(selectDoorsForMultidoorAddSize); // ie.  canRender(isStarsMultidoor ? "CATALOGUE" : "ADD_TO_CART", door)

  return (
    <>
      <StyledSizeContainer
        className={clsx(isMultidoor && !compactMultidoorSelect && "size-container-multidoor")}
      >
        {/* MULTIDOOR */}
        {isMultidoor && (
          <MultiDoorContainer
            className={clsx(!compactMultidoorSelect && "size-container-multidoor")}
          >
            <div className="multidoor-header">
              <CustomText as="p" fontSizePx={13} font="font-bold">
                {translateLabel("PDP_MULTIDOORS")} ({doorsToDisplay?.length ?? 0}/{totalDoors})
              </CustomText>
              {togglePopup && (
                <LinkButton onClick={togglePopup}>
                  {translateLabel("PDP_SHOW_ALL_DOORS")}{" "}
                </LinkButton>
              )}
            </div>

            {variantId && currentDoor && doorsToDisplay.length > 0 && (
              <MultiDoorSelectPDP
                uniqueID={variantId}
                selectedDoor={currentDoor}
                privilege={isStarsMultidoor ? "CATALOGUE" : "ADD_TO_CART"}
                responsive
              />
            )}

            <AddSizeList
              isSpareParts={isSpareParts}
              skus={skus}
              isLoading={isLoading}
              price={price}
              currentDoor={currentDoor}
              isMultidoor
              compactMultidoorSelect={compactMultidoorSelect}
              isAfa={isAfa}
              starsSkus={starsSkus}
              {...(dataElementId ? { "data-element-id": dataElementId } : {})}
              enableDataDescription={enableDataDescription}
              dataElementIdRXPLP={dataElementIdRXPLP}
              dataElementIdFromMainBox={dataElementIdFromMainBox}
              code={code}
              showFamilySizeFrames={showFamilySizeFrames}
              hideAvailabilitySize={hideAvailabilitySize}
            />
          </MultiDoorContainer>
        )}

        {/* NOT MULTIDOOR */}
        {!isMultidoor && (
          <AddSizeList
            isSpareParts={isSpareParts}
            skus={skus}
            isLoading={isLoading}
            price={price}
            currentDoor={currentDoor}
            isAfa={isAfa}
            starsSkus={starsSkus}
            {...(dataElementId ? { "data-element-id": dataElementId } : {})}
            enableDataDescription={enableDataDescription}
            dataElementIdRXPLP={dataElementIdRXPLP}
            dataElementIdFromMainBox={dataElementIdFromMainBox}
            code={code}
            showFamilySizeFrames={showFamilySizeFrames}
            hideAvailabilitySize={hideAvailabilitySize}
          />
        )}
      </StyledSizeContainer>
    </>
  );
};

const StyledSizeContainer = styled.div`
  display: flex;
  flex-direction: column;

  &.size-container-multidoor {
    border: 1px solid ${(props) => props.theme.palette.gray.medium};
    border-radius: 0.25rem;
  }
`;

const MultiDoorContainer = styled.div`
  &.size-container-multidoor {
    padding: 1rem;
  }

  .multidoor-header {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
  }
`;

export default SizeContainer;
