import { useEffect, useState } from "react";

interface Props {
  resultsTotal: number;
  resultListLength: number;
  slidesPerCarousel: number;
  maxSlides: number;
  dispatchCallback: (newPage: number) => void;
  resetPaginationOnSlidesToShowChange?: boolean;
}

interface CarouselPagination {
  currentPage: number;
  totalPages: number;
  totalPagesMaxed: number;
  totalSlidesMaxed: number;
  getNextCarousel: (page: "prev" | "next") => void;
  resetPagination: () => void;
}

const useCarouselPagination = ({
  resultsTotal,
  resultListLength,
  slidesPerCarousel,
  maxSlides,
  dispatchCallback,
  resetPaginationOnSlidesToShowChange,
}: Props): CarouselPagination => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [maxPageReached, setMaxPageReached] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalPagesMaxed, setTotalPagesMaxed] = useState<number>(1);
  const totalSlidesMaxed = resultsTotal < maxSlides ? resultsTotal : maxSlides;

  useEffect(() => {
    const newTotalPages = getTotalPages();
    const newTotalPagesMaxes = getTotalPagesMaxed();

    setTotalPages(newTotalPages);
    setTotalPagesMaxed(newTotalPagesMaxes);

    if (resultListLength > 0) {
      const newMaxPageReached = Math.floor(resultListLength / slidesPerCarousel) || 1;
      newMaxPageReached !== maxPageReached && setMaxPageReached(newMaxPageReached);

      const expectedNumberOfResults = newMaxPageReached * slidesPerCarousel;

      if (
        expectedNumberOfResults > resultListLength &&
        expectedNumberOfResults < maxSlides &&
        resultListLength < totalSlidesMaxed
      )
        dispatchCallback(newMaxPageReached);
    }
  }, [resultsTotal, slidesPerCarousel, maxSlides]);

  useEffect(() => {
    if (resetPaginationOnSlidesToShowChange && currentPage !== 1) setCurrentPage(1);
  }, [slidesPerCarousel]);

  const getTotalPages = () => Math.ceil(resultsTotal / slidesPerCarousel);

  const getTotalPagesMaxed = () => {
    const totalPagesFromResults = Math.ceil(resultsTotal / slidesPerCarousel);
    const totalPagesFromMaxSlides = Math.ceil(maxSlides / slidesPerCarousel);

    return totalPagesFromResults < totalPagesFromMaxSlides
      ? totalPagesFromResults
      : totalPagesFromMaxSlides;
  };

  const getNextCarousel = (page: "prev" | "next") => {
    const newPage = page === "prev" ? currentPage - 1 : currentPage + 1;
    setCurrentPage(newPage);
    newPage > maxPageReached && setMaxPageReached(newPage);

    if (
      page === "next" &&
      newPage > maxPageReached &&
      resultListLength <= newPage * slidesPerCarousel &&
      resultListLength < totalSlidesMaxed
    )
      dispatchCallback(newPage);
  };

  const resetPagination = () => {
    if (currentPage !== 1 && maxPageReached !== 1) {
      setCurrentPage(1);
      setMaxPageReached(1);
      setTotalPages(getTotalPages());
      setTotalPagesMaxed(getTotalPagesMaxed());
    }
  };

  return {
    currentPage,
    totalPages,
    totalPagesMaxed,
    totalSlidesMaxed,
    getNextCarousel,
    resetPagination,
  };
};

export default useCarouselPagination;
