import LoginPage from "../components/pages/public-page/LoginPage";
import PublicPage from "../components/pages/public-page/PublicPage";
import { SingleRoute } from "./routesInterfaces";

export const publicRoutes: SingleRoute[] = [
  {
    path: "/:locale/need-help",
    breadCrumbPath: "/need-help",
    name: "Need Help",
    component: PublicPage,
  },
  {
    path: "/:locale/login",
    breadCrumbPath: "/login",
    name: "Login",
    component: LoginPage,
  },
];
