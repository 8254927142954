import { SagaIterator } from "@redux-saga/types";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { allowedServicesIds } from "../../utils/accountingUtils";
import { downloadURI } from "../../utils/utils";
import { getNotificationsAlerts, getSectionsAlerts } from "../accounting/accountingSagas";
import { DownloadDocPayload } from "../services/servicesInterface";
import servicesService from "../services/servicesServices";
import { handleError } from "../store/storeSagas";
import { showError } from "../store/storeSlice";
import { selectSelectedDoor, selectUserName } from "../user/userSlice";
import {
  GetDocumentListParams,
  GetUnsignedLegalDocumentsPayload,
  LegalDocumentsToDownloadPayload,
  SaveUserActionPayload,
  SaveUserActionSagaPayload,
} from "./documentsInterfaces";
import documentsService from "./documentsService";
import {
  saveDocumentsList,
  saveTermsConditionDocument,
  saveUnsignedPdfUrl,
  setCMSContents,
  setDocumentInObj,
  setDocumentsListsStatus,
  setDocumentsLoading,
  setFitToDriveDocumentStatus,
  setIsUnsignedLegalDocumentsLoading,
  setLegalDocuments,
  setLegalDocumentsLoading,
  setSaveUserActionStatus,
  setUnsignedLegalDocuments,
  sliceName,
} from "./documentsSlice";

/* ACTIONS */
export const getDocument = createAction<string>(sliceName + "/getDocuments");
export const getFitToDriveDocument = createAction<string>(sliceName + "/getFitToDriveDocument");
export const getDocumentList = createAction<string>(sliceName + "/getDocumentsList");
export const getPublicDocumentList = createAction<string>(sliceName + "getPublicDocumentsList");
export const getTermsConditionDocument = createAction<string>(
  sliceName + "/getTermsConditionDocument"
);
export const getLegalDocuments = createAction<string>(sliceName + "/getLegalDocuments");
export const getUnsignedLegalDocuments = createAction<GetUnsignedLegalDocumentsPayload>(
  sliceName + "/getUnsignedLegalDocuments"
);
export const saveUserAction = createAction<SaveUserActionSagaPayload>(
  sliceName + "/saveUserAction"
);
export const downloadLegalDocuments = createAction<LegalDocumentsToDownloadPayload>(
  sliceName + "/downloadLegalDocuments"
);
export const getCMSContents = createAction<string>(sliceName + "/getCMSContents");
export const getPdfUrl = createAction<DownloadDocPayload>(sliceName + "/getPdfUrl");

/* SAGAS */

/**
 * Get the entire list of documents
 *
 * @param {PayloadAction<GetDocumentsListPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getDocumentsSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setDocumentsLoading(true));
    yield put(setDocumentsListsStatus("LOADING"));
    const qparams: GetDocumentListParams = { docType: action.payload };

    const { data: res } = yield call(documentsService.getDocumentList, qparams);

    const documents = res.data.result ?? [];
    yield put(saveDocumentsList(documents));
    yield put(setDocumentsListsStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(setDocumentsListsStatus("ERROR"));
    yield put(setDocumentsLoading(false));
  }
}

function* getDocumentSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setDocumentsLoading(true));
    const qparams: GetDocumentListParams = { docType: action.payload };

    const { data: res } = yield call(documentsService.getDocumentList, qparams);

    yield put(setDocumentInObj({ document: res.data.result[0], key: action.payload }));
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(setDocumentsLoading(false));
  }
}

function* getFitToDriveDocumentSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setFitToDriveDocumentStatus("LOADING"));
    const qparams: GetDocumentListParams = { docType: action.payload };

    const { data: res } = yield call(documentsService.getDocumentList, qparams);

    yield put(setDocumentInObj({ document: res.data?.result?.[0] ?? null, key: action.payload }));
    yield put(setFitToDriveDocumentStatus("SUCCESS"));
  } catch (error) {
    yield put(setFitToDriveDocumentStatus("ERROR"));
    yield put(handleError(error));
  }
}

/**
 * Get the entire list of documents
 *
 * @param {PayloadAction<GetDocumentsListPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getPublicDocumentsSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setDocumentsLoading(true));

    const qparams: GetDocumentListParams = { docType: action.payload };
    const { data: res } = yield call(documentsService.getPublicDocumentList, qparams);

    yield put(saveDocumentsList(res.data.result));
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(setDocumentsLoading(false));
  }
}

/**
 * Get the entire list of documents
 *
 * @param {PayloadAction<GetDocumentsListPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getTermsConditionDocumentSaga(action: PayloadAction<string>): SagaIterator {
  try {
    const qparams: GetDocumentListParams = { docType: action.payload };
    const { data } = yield call(documentsService.getDocumentList, qparams);
    if (data?.data?.result) {
      yield put(saveTermsConditionDocument(data.data.result?.[0]));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}
/**
 * Get the entire list of legal documents
 *
 */
function* getLegalDocumentsSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setLegalDocumentsLoading(true));
    const { data: res } = yield call(documentsService.getLegalDocuments, action.payload);
    yield put(setLegalDocuments(res.data.output));
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(setLegalDocumentsLoading(false));
  }
}

/**
 * Get the entire list of unsigned legal documents
 *
 */
function* getUnsignedLegalDocumentsSaga(
  action: PayloadAction<GetUnsignedLegalDocumentsPayload>
): SagaIterator {
  const { door, callback } = action.payload;

  try {
    yield put(setIsUnsignedLegalDocumentsLoading("LOADING"));
    const { data } = yield call(documentsService.getUnsignedLegalDocuments, door.orgentityId);
    const docs = data.data.documentList.map((_: any) => {
      return { ..._, door };
    });
    yield put(setUnsignedLegalDocuments(docs));
    callback?.(true);
    yield put(setIsUnsignedLegalDocumentsLoading("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    callback?.(false);
    yield put(setIsUnsignedLegalDocumentsLoading("ERROR"));
  }
}

/**
 * make user action on documents
 *
 */
function* saveUserActionSaga({ payload }: PayloadAction<SaveUserActionSagaPayload>): SagaIterator {
  try {
    yield put(setSaveUserActionStatus({ action: payload.action, status: "LOADING" }));
    const { callback, ...data } = payload;

    const selectedDoor = yield select(selectSelectedDoor);
    const username = yield select(selectUserName);

    const saveUserData: SaveUserActionPayload = {
      ...data,
      customerId: selectedDoor.orgentityName,
      username,
    };

    yield call(documentsService.saveUserAction, saveUserData);
    callback?.();
    yield put(setSaveUserActionStatus({ action: payload.action, status: "SUCCESS" }));
    yield put(getSectionsAlerts(allowedServicesIds));
    yield put(getNotificationsAlerts());
  } catch (error) {
    yield put(setSaveUserActionStatus({ action: payload.action, status: "ERROR" }));
    yield put(handleError(error));
    if (error.response?.status !== 401) yield put(showError({ message: "WE_AGREE_ERROR" }));
  }
}

/**
 * download legal documents
 *
 */
function* downloadLegalDocumentsSaga(
  action: PayloadAction<LegalDocumentsToDownloadPayload>
): SagaIterator {
  try {
    const { data } = yield call(documentsService.downloadLegalDocuments, action.payload);

    const url = data.data.output?.downloadZippedDocumentListURL;
    downloadURI(url);
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get document content from CMS
 *
 */
function* getCMSContentsSaga({ payload }: PayloadAction<string>): SagaIterator {
  try {
    const { data: res } = yield call(documentsService.getCMSContents, payload);
    yield put(setCMSContents(res.data?.content?.result?.[0]));
  } catch (error) {
    yield put(handleError(error));
    yield put(setCMSContents(null));
  }
}

function* getPdfUrlSaga({ payload }: PayloadAction<DownloadDocPayload>): SagaIterator {
  try {
    const { data } = yield call(servicesService.downloadTransportDocument, payload);

    if (data.output.absoluteUri) yield put(saveUnsignedPdfUrl(data.output.absoluteUri));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveUnsignedPdfUrl(null));
  }
}

export function* documentsSaga(): SagaIterator {
  yield takeLatest(getDocument.type, getDocumentSaga);
  yield takeLatest(getFitToDriveDocument.type, getFitToDriveDocumentSaga);
  yield takeLatest(getDocumentList.type, getDocumentsSaga);
  yield takeLatest(getPublicDocumentList.type, getPublicDocumentsSaga);
  yield takeLatest(getTermsConditionDocument.type, getTermsConditionDocumentSaga);
  yield takeLatest(getLegalDocuments.type, getLegalDocumentsSaga);
  yield takeLatest(getUnsignedLegalDocuments.type, getUnsignedLegalDocumentsSaga);
  yield takeLatest(saveUserAction.type, saveUserActionSaga);
  yield takeLatest(downloadLegalDocuments.type, downloadLegalDocumentsSaga);
  yield takeLatest(getCMSContents.type, getCMSContentsSaga);
  yield takeLatest(getPdfUrl.type, getPdfUrlSaga);
}
