import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { hideError, selectError } from "../../store/store/storeSlice";
import Popup from "../styled-UI/Popup";
import CustomText from "../styled-UI/CustomText";
import useTranslation from "../../hooks/useTranslation";
import Button from "../styled-UI/Button";

const ErrorModal = (): JSX.Element => {
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const { translateLabel } = useTranslation();

  return (
    <Popup
      isOpen={error !== null}
      title={translateLabel("ERROR_POPUP_TITLE")}
      close={() => {
        dispatch(hideError());
      }}
    >
      <PopupContent>
        <CustomText as="span">
          {error && error.message
            ? translateLabel(error.message)
            : translateLabel("ERROR_POPUP_DEFAULT_MESSAGE")}
        </CustomText>
        {error?.showButton && (
          <ButtonContainer>
            <Button type="primary" onClick={() => dispatch(hideError())}>
              <ButtonLabel>
                {translateLabel(error.descriptionButton ?? "PAYMENT_CONFIRM")}
              </ButtonLabel>
            </Button>
          </ButtonContainer>
        )}
      </PopupContent>
    </Popup>
  );
};

const PopupContent = styled.div`
  padding: 3rem 2rem;
`;

const ButtonContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ButtonLabel = styled.span`
  max-width: 10rem;
  word-wrap: break-word;
`;

export default ErrorModal;
